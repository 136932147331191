import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { Checkbox } from "../../../Checkbox";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { CombinedFormValue, CompareTo } from "../models";

type CombinedTestEditorCompareToSectionProps = {
  isGroupWidget: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export function CombinedTestEditorCompareToSection({
  isGroupWidget,
}: CombinedTestEditorCompareToSectionProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<CombinedFormValue>();

  return (
    <Section>
      <SectionTitle title={t("compareTo")} />

      <Wrapper>
        <Checkbox
          text={t("previousDay")}
          typeIcon="circle"
          check={() => {
            setFieldValue("compareTo", CompareTo.PREVIOUS_DAY);
          }}
          checked={values.compareTo === CompareTo.PREVIOUS_DAY}
        />
        {!isGroupWidget && (
          <Checkbox
            text={t("latest")}
            typeIcon="circle"
            check={() => {
              setFieldValue("compareTo", CompareTo.LATEST);
            }}
            checked={values.compareTo === CompareTo.LATEST}
          />
        )}
        <Checkbox
          text={t("nothing")}
          typeIcon="circle"
          check={() => {
            setFieldValue("compareTo", CompareTo.NOTHING);
          }}
          checked={values.compareTo === CompareTo.NOTHING}
        />
      </Wrapper>
    </Section>
  );
}
