import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import _debounce from "lodash/debounce";
import styled, { css, useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useAthleteProfileContext } from "../../contexts/athleteProfile";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  useGetGroupsWhereReportTemplateIsSharedWithAthletesQuery,
  useSaveReportTemplateMutation,
} from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { parsePreferences } from "../../utils/preferences";
import { BasicButton, ButtonColor, GeneralButton } from "../Button/";
import { Checkbox } from "../Checkbox";
import Dropdown from "../Dropdown";
import Hoverable from "../Hoverable";
import { IconButton } from "../IconButton";
import { PrintIcon, ThreeDotsIcon, EditPencilIcon } from "../Icons";
import { OptionItemText } from "../Report/styled";
import { ConfirmModalText } from "../styled/ConfirmModalText";
import StyledText from "../StyledText";
import { StyledTextInput } from "../StyledTextInput";

interface IGridHeaderProps {
  parentReport: any;
  toggleEditMode: () => void;
  isEditMode: boolean;
  filteredWidgets: any[];
}

const GridHeaderWrapper = styled.div<{ isEditMode?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) =>
    props.isEditMode || props.theme.isMobile ? "auto" : "35px"};
  margin: ${({ theme, isEditMode }) =>
    isEditMode
      ? !theme.isMobile
        ? "0 10px"
        : "5px"
      : !theme.isMobile
        ? "0 10px 0px 0px"
        : "5px"};
  z-index: 1;
`;

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  top: 0;
  right: -5px;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex: none;
    `};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledTitle = styled(StyledText)`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 10px;
  margin: 6px 0;
`;

const HeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-top: 5px;
`;

const ReportControlSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const EditReportButton = styled(BasicButton)<{ isHovered: boolean }>`
  padding: 10px;
  height: 35px;
  margin: 5px 0;
  background-color: ${({ isHovered }) =>
    isHovered ? COLOR_GRAY : COLOR_WHITE};
`;

export function GridHeader({
  parentReport,
  isEditMode,
  filteredWidgets,
  toggleEditMode,
}: IGridHeaderProps) {
  const { t } = useTranslation();
  const { isMobile } = useTheme();
  const { actions: modalActions } = useModalContext();

  const { sessionId, isTrainer } = useUserContext();
  const hasAthleteProfileContext = useAthleteProfileContext() !== null;
  const {
    actions: { openModal },
  } = useModalContext();

  const canEdit = useCanEditStatistics();
  const [saveReport] = useSaveReportTemplateMutation();
  const { data: sharedWithGroupsData } =
    useGetGroupsWhereReportTemplateIsSharedWithAthletesQuery({
      variables: { reportId: parentReport?.id, sessionId },
      skip: !parentReport?.shareableWithAthletes,
    });

  const [title, setTitle] = useState("");
  const [isSingleDayReport, setIsSingleDayReport] = useState(false);
  const [isShareAbleWithAthletes, setIsShareAbleWithAthletes] = useState(false);
  const [titleInitialized, setTitleInitialized] = useState(false);
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  useEffect(() => {
    if (parentReport && !titleInitialized) {
      setTitle(parentReport.name);
      setTitleInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleInitialized, parentReport?.name]);

  useEffect(() => {
    setIsSingleDayReport(parentReport?.isSingleDay ?? false);
  }, [parentReport?.isSingleDay]);

  useEffect(() => {
    if (parentReport && isEditMode) {
      setIsShareAbleWithAthletes(parentReport.shareableWithAthletes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentReport?.shareableWithAthletes, isEditMode]);

  const updateReport = ({
    newTitle,
    preferences,
    shareableWithAthletes,
  }: {
    newTitle: string;
    shareableWithAthletes: boolean;
    preferences?: Record<string, any>;
  }) => {
    if (parentReport?.id && isEditMode) {
      saveReport({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          input: {
            id: parentReport.id,
            name: newTitle,
            shareableWithAthletes,
            preferences: JSON.stringify({
              ...parsePreferences(parentReport.preferences),
              ...preferences,
              updatedAt: dayjs().toISOString(),
            }),
          },
        },
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_debounce(updateReport, 500), [
    parentReport,
    isEditMode,
  ]);

  const onTitleChange = (newTitle: string) => {
    setTitle(newTitle);
    debounceFn({ newTitle, shareableWithAthletes: isShareAbleWithAthletes });
  };

  const onSaveShareableWithAthleteChange = (newShareableValue: boolean) => {
    setIsShareAbleWithAthletes(newShareableValue);
    updateReport({ newTitle: title, shareableWithAthletes: newShareableValue });
  };

  const onSaveSingleDayReportChange = (newSingleDayValue: boolean) => {
    setIsSingleDayReport(newSingleDayValue);
    updateReport({
      newTitle: title,
      shareableWithAthletes: isShareAbleWithAthletes,
      preferences: {
        isSingleDay: newSingleDayValue,
      },
    });
  };

  const onShareableWithAthleteChange = (newShareableValue: boolean) => {
    const isSharedInGroups =
      sharedWithGroupsData?.getGroupsWhereReportTemplateIsSharedWithAthletes
        ?.length > 0;

    if (isSharedInGroups && !newShareableValue) {
      const params = {
        content: (
          <>
            <ConfirmModalText marginBottom={10}>
              {t("reportSharedWarning")}
            </ConfirmModalText>
            {sharedWithGroupsData.getGroupsWhereReportTemplateIsSharedWithAthletes?.map(
              ({ name }) => (
                <ConfirmModalText color={COLOR_BLUE} marginBottom={5}>
                  {name}
                </ConfirmModalText>
              )
            )}
            <ConfirmModalText marginTop={15}>
              {t("reportSharedConfirmationQuestion")}
            </ConfirmModalText>
          </>
        ),
        onOK: () => onSaveShareableWithAthleteChange(newShareableValue),
        onCancel: modalActions.closeModal,
        okButtonText: t("unshare"),
        cancelButtonText: t("cancel"),
      };

      modalActions.openModal({
        modal: ModalType.CONFIRM,
        title: t("unshareAllTeams"),
        params,
      });
    } else {
      onSaveShareableWithAthleteChange(newShareableValue);
    }
  };

  const openPrintModal = () => {
    openModal({
      title: "",
      modal: ModalType.PRINT_GROUP_REPORT,
      params: { reportName: title, filteredWidgets, isSingleDayReport },
    });
  };

  return (
    <GridHeaderWrapper isEditMode={isEditMode}>
      {isEditMode && (
        <>
          <TitleWrapper>
            <StyledTitle>{t("reportName").toUpperCase()}</StyledTitle>
            <StyledTextInput
              name="name"
              value={title}
              onChangeText={onTitleChange}
            />
          </TitleWrapper>

          <div style={{ marginLeft: 20, marginBottom: 12, marginTop: 21 }}>
            <Checkbox
              text={t("singleDay")}
              check={onSaveSingleDayReportChange}
              checked={isSingleDayReport}
            />
          </div>

          <div style={{ marginLeft: 20, marginBottom: 12, marginTop: 21 }}>
            <Checkbox
              text={t("shareableWithAthletes")}
              check={onShareableWithAthleteChange}
              checked={isShareAbleWithAthletes}
            />
          </div>
        </>
      )}

      {!isMobile && (
        <ControlsWrapper>
          {hasAthleteProfileContext && isTrainer && canEdit && (
            <>
              <IconButton icon={<PrintIcon />} onClick={openPrintModal} />
              <Dropdown
                component={({ toggle, show }) => (
                  <IconButton
                    icon={<ThreeDotsIcon isActive={show} />}
                    enforceActiveState={show}
                    onClick={toggle}
                  />
                )}
                dropdownContentStyle={{
                  top: 10,
                  right: 10,
                  width: 170,
                  padding: 0,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <EditReportButton
                      isHovered={isHovered}
                      onClick={toggleEditMode}
                    >
                      <OptionItemText>{t("editReport")}</OptionItemText>
                    </EditReportButton>
                  )}
                </Hoverable>
              </Dropdown>
            </>
          )}
          {!hasAthleteProfileContext && isEditMode && (
            <GeneralButton
              colorVariant={ButtonColor.Blue}
              label={t("stopEditing")}
              onClick={toggleEditMode}
            />
          )}
          {!hasAthleteProfileContext && !isEditMode && (
            <HeaderWrapper>
              <StyledText
                fontSize={20}
                fontWeight="bold"
                style={{ lineHeight: "24px", marginTop: 5 }}
              >
                {title}
              </StyledText>
              <ReportControlSection>
                <IconButton
                  icon={<PrintIcon />}
                  mr={20}
                  onClick={openPrintModal}
                />
                {canEdit && (
                  <GeneralButton
                    label={t("edit")}
                    icon={<EditPencilIcon tintColor={COLOR_WHITE} />}
                    onClick={toggleEditMode}
                  />
                )}
              </ReportControlSection>
            </HeaderWrapper>
          )}
        </ControlsWrapper>
      )}

      {isMobile && !isEditMode && !hasAthleteProfileContext && (
        <HeaderWrapper>
          <StyledText
            fontSize={20}
            fontWeight="bold"
            style={{ lineHeight: "24px", marginTop: 5 }}
          >
            {title}
          </StyledText>
        </HeaderWrapper>
      )}
    </GridHeaderWrapper>
  );
}
