import React from "react";

import { IWidgetResizeProps } from "../../hooks";
import { InformationWidgetFormModel } from "../../screens/InformationWidgetEditor";
import { AthleteTablePeriodizationWidgetTableWithStatsData } from "../AthleteTablePeriodization/Widget/TableWithStatsData";
import { GroupTableWidget } from "../GroupTableWidget";
import {
  WidgetType,
  KeyValuesWidgetModel,
  AthleteTableWidgetModel,
  GroupTableWidgetModel,
  CombinedTestWidgetModel,
  AttendanceLogWidgetModel,
  ActivityLogWidgetModel,
  AcuteChronicWidgetModel,
} from "../ReportCreateTable/models";
import {
  AthleteTablePeriodizationWidget,
  TablePeriodizationWidget,
} from "../TablePeriodization/Widget";
import { TablePeriodizationWidgetTableWithStatsData } from "../TablePeriodization/Widget/TableWithStatsData";
import { TableWidget } from "../TableWidget";

import { ActivityWidget } from "./ActivityLogWidget/Widget";
import { AcuteChronicWidget } from "./AcuteChronicWidget/Widget";
import { AttendanceLogWidget } from "./AttendanceLogWidget";
import { BodyChartWidget } from "./BodyChartWidget/BodyChartWidget";
import { BodyChartWidgetModel } from "./BodyChartWidget/model";
import { ChartWidget } from "./ChartWIdget/ChartWidget";
import { ChartWidgetModel } from "./ChartWIdget/model";
import { CombinedTestWidget } from "./CombinedTest/CombinedTestWidget";
import { useTransformChartWidgetData } from "./hooks/useTransformChartWidgetData";
import { InformationWidget } from "./InformationWidget/Widget";
import { KeyValuesWidget } from "./KeyValues/KeyValuesWidget";
import { WidgetMode } from "./WidgetWithData";

type WidgetProps = {
  widget: any;
  mode: WidgetMode;
  readOnly: boolean;
} & IWidgetResizeProps;

export const Widget = ({
  widget: rawWidget,
  mode,
  gridItemId,
  readOnly,
  layoutRef,
  setItemToResize,
  setItemsToHideInViewMode,
}: WidgetProps) => {
  const { chartWidget } = useTransformChartWidgetData(rawWidget, {
    skip: rawWidget.type !== WidgetType.CHART,
  });

  const widget = rawWidget.type === WidgetType.CHART ? chartWidget : rawWidget;

  if (!widget && rawWidget.type === WidgetType.CHART) {
    return null;
  }

  switch (rawWidget.type) {
    case WidgetType.CHART:
      return (
        <ChartWidget
          widget={widget as ChartWidgetModel}
          isGroupReport={mode === WidgetMode.GROUP}
        />
      );
    case WidgetType.KEY_VALUES:
      return (
        <KeyValuesWidget
          widget={widget as KeyValuesWidgetModel}
          gridItemId={gridItemId}
          readOnly={readOnly}
          setItemToResize={setItemToResize}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.TABLE:
      return mode === WidgetMode.ATHLETE ? (
        <TableWidget
          widget={widget as AthleteTableWidgetModel}
          gridItemId={gridItemId}
          readOnly={readOnly}
          setItemToResize={setItemToResize}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      ) : (
        <GroupTableWidget
          widget={widget as GroupTableWidgetModel}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.COMBINED_TEST:
      return (
        <CombinedTestWidget
          widget={widget as CombinedTestWidgetModel}
          isGroupReport={mode === WidgetMode.GROUP}
          readOnly={readOnly}
          gridItemId={gridItemId}
          layoutRef={layoutRef}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          setItemToResize={setItemToResize}
        />
      );
    case WidgetType.ATTENDANCE_LOG:
      return (
        <AttendanceLogWidget
          widget={widget as AttendanceLogWidgetModel}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.INFORMATION_LOG:
      return (
        <InformationWidget
          widget={widget as InformationWidgetFormModel}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.ACTIVITY_LOG:
      return (
        <ActivityWidget
          widget={widget as ActivityLogWidgetModel}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.ACUTE_CHRONIC:
      return <AcuteChronicWidget widget={widget as AcuteChronicWidgetModel} />;
    case WidgetType.ATHLETE_TABLE_PERIODIZATION:
      return (
        <AthleteTablePeriodizationWidgetTableWithStatsData
          widget={widget as AthleteTablePeriodizationWidget}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.GROUP_TABLE_PERIODIZATION:
      return (
        <TablePeriodizationWidgetTableWithStatsData
          widget={widget as TablePeriodizationWidget}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    case WidgetType.BODY_CHART:
      return (
        <BodyChartWidget
          widget={widget as BodyChartWidgetModel}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
        />
      );
    default:
      return null;
  }
};
