import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
} from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { XpsUser, useUpdateParticipationMutation } from "../../../graphql";
import Avatar from "../../Avatar";
import Hoverable from "../../Hoverable";
import { RemoveIcon } from "../../Icons";
import StyledText from "../../StyledText";

type SessionEditMemberAttendanceItemProps = {
  user: XpsUser;
  practice: any;
  isFirst?: boolean;
  isLast?: boolean;
  refetch: any;
};

const Row = styled.div<{ isFirst: boolean; isLast: boolean }>`
  display: flex;
  padding: 10px 16px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-top-width: ${({ isFirst }) => `${!isFirst ? 1 : 0}px`};
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;
  border-bottom-right-radius: ${({ isLast }) => `${isLast ? 6 : 0}px`};
  border-bottom-left-radius: ${({ isLast }) => `${isLast ? 6 : 0}px`};
  justify-content: flex-start;
  background: white;
  cursor: pointer;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Name = styled(StyledText)`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
`;

const Badge = styled(StyledText)`
  margin-left: 20px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_BLUE};
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export function SessionEditMemberAttendanceItem({
  user,
  isFirst,
  isLast,
  practice,
  refetch,
}: SessionEditMemberAttendanceItemProps) {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const { actions: modalActions } = useModalContext();

  const [updateParticipation] = useUpdateParticipationMutation();

  const onUpdateMembers = async (e) => {
    e.stopPropagation();

    await updateParticipation({
      variables: {
        sessionId,
        input: {
          sessionGuid: practice.id,
          participates: false,
          userGuid: user.id,
        },
      },
    });

    await refetch();
  };

  const onRowClick = () => {
    modalActions.openModal({
      modal: ModalType.USER_PROFILE,
      title: "",
      params: { currentAthlete: user, groupsIds: user.jointGroupIds },
    });
  };

  return (
    <Hoverable>
      {(isHovered) => (
        <Row isFirst={isFirst} isLast={isLast} onClick={onRowClick}>
          <UserWrapper>
            <Avatar
              size={25}
              style={{ marginRight: 12 }}
              source={{ uri: user.thumb }}
              userName={user.name}
            />
            <Name>{user.name}</Name>
          </UserWrapper>

          <ItemsWrapper>
            {user.type === "Trainer" && (
              <Badge>{t("coach").toUpperCase()}</Badge>
            )}
            {isHovered && (
              <RemoveIcon
                onClick={onUpdateMembers}
                style={{ marginLeft: "auto", flexShrink: 0 }}
              />
            )}
          </ItemsWrapper>
        </Row>
      )}
    </Hoverable>
  );
}
