import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { AggregateFunction } from "../../../graphql";
import { FieldErrorMessage } from "../../Report/styled";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import {
  DragDropContainer,
  RemoveFunction,
} from "../../Wrappers/DragDropContainer";
import { ColoringConfig, GroupColumn as GroupColumnModel } from "../models";

import { GroupColumn } from "./components/GroupColumn";

export const DEFAULT_COLUMN = {
  measurementTemplateId: "",
  measurementTemplateName: "",
  attributeTemplateId: "",
  attributeTemplateName: "",
  showTrend: false,
  showValue: true,
  collectionId: "",
  aggregateFunctions: [AggregateFunction.Latest],
  comparison: {
    show: false,
    base: null,
    view: null,
  },
};

export const GroupColumnsSection = () => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } = useFormikContext<{
    columns: GroupColumnModel[];
    coloringConfig: ColoringConfig;
  }>();

  const handleRemoveOfDataItem =
    (remove: RemoveFunction) => (index: number) => {
      const itemToRemove = values.columns[index];
      const allPossibleConfigValues = itemToRemove.aggregateFunctions.map(
        (agg) =>
          `${itemToRemove.measurementTemplateId}:${itemToRemove.attributeTemplateId}:${agg}`
      );

      const newColoringConfig = values.coloringConfig?.filter(
        (config) => !allPossibleConfigValues.includes(config.id)
      );

      // Remove coloring config for removed item
      setFieldValue("coloringConfig", newColoringConfig || []);

      // Remove item
      remove(index);
    };

  return (
    <Section>
      <SectionTitle title={t("columns")} />
      <DragDropContainer
        fieldArrayName="columns"
        buttonText={t("addColumn")}
        defaultNewEntity={DEFAULT_COLUMN}
        items={values.columns.map(
          (currentColumn, index) =>
            ({ push, remove, dragHandleProps }) => (
              <GroupColumn
                key={`${currentColumn.measurementTemplateId}-${currentColumn.attributeTemplateId}-${index}`}
                columnIndex={index}
                push={push}
                remove={handleRemoveOfDataItem(remove)}
                dragHandleProps={dragHandleProps}
              />
            )
        )}
      />
      {typeof errors.columns === "string" && (
        <FieldErrorMessage>{errors.columns}</FieldErrorMessage>
      )}
    </Section>
  );
};
