import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useReportsContext } from "../../../contexts/Reports";
import { ReportTemplate } from "../../../graphql";
import { AthleteProfileTabsPreference } from "../../../models";
import { AvailableItem } from "../DraggableModal/AvailableItem";
import { EmptyList } from "../DraggableModal/EmptyList";

export const EditAthleteProfileTabsAvailableReportList = ({
  reportTemplatesWithoutTabTemplates,
  localPreferences,
  setLocalPreferences,
  search,
  setSearch,
  alternateSetter,
}: {
  reportTemplatesWithoutTabTemplates: ReportTemplate[];
  localPreferences: AthleteProfileTabsPreference;
  setLocalPreferences:
    | Dispatch<SetStateAction<AthleteProfileTabsPreference>>
    | ((_: AthleteProfileTabsPreference) => void);
  search: string;
  setSearch: (input: string) => void;
  alternateSetter?: boolean;
}) => {
  const { t } = useTranslation();
  const [{ selectedGroup }] = useReportsContext();
  const groupId = selectedGroup?.id;

  const addedReportIds = useMemo(
    () =>
      new Set((localPreferences[groupId] ?? []).map((template) => template.id)),
    [localPreferences, groupId]
  );

  const reportsAvailable = useMemo(
    () =>
      reportTemplatesWithoutTabTemplates.filter(
        (template) =>
          !addedReportIds.has(template.id) &&
          template.name.toLowerCase().includes(search.toLowerCase())
      ),
    [reportTemplatesWithoutTabTemplates, addedReportIds, search]
  );

  const onAddReportPress = useCallback(
    ({ id, name }: { id?: string; name?: string }) => {
      if (alternateSetter) {
        const setPreferences = setLocalPreferences as (
          _: AthleteProfileTabsPreference
        ) => void;
        setPreferences({
          ...localPreferences,
          [groupId]: [
            ...(localPreferences[groupId] ?? []),
            { id, name, title: name },
          ],
        });
      } else {
        const dispatchPreferences = setLocalPreferences as Dispatch<
          SetStateAction<AthleteProfileTabsPreference>
        >;
        dispatchPreferences((preferences) => ({
          ...preferences,
          [groupId]: [
            ...(preferences[groupId] ?? []),
            { id, name, title: name },
          ],
        }));
      }
      setSearch("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId]
  );

  if (!reportsAvailable?.length) {
    return <EmptyList label={t("noAvailableReportToAdd")} />;
  }

  return (
    <>
      {reportsAvailable.map((report, index) => (
        <AvailableItem
          key={report.id}
          report={report}
          index={index}
          onAdd={() => onAddReportPress(report)}
          showOwner
        />
      ))}
    </>
  );
};
