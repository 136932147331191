import * as React from "react";

import { ErrorBoundaryContent } from "./ErrorBoundaryContent";

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Add sentry logging here
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props?.ErrorComponent ?? <ErrorBoundaryContent />;
    }

    return this.props.children;
  }
}
