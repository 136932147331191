import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { ReportWidgetCellNumerical } from "../../../graphql";
import {
  isAttendanceCollection,
  isSoccerStatsCollection,
} from "../../../utils/reports";
import { TimeLine } from "../../Report/ChartWIdget/model";
import {
  ComplexHeaderValueWrapper,
  HeaderLine,
} from "../../ReportTableWidget/GroupTableHeader";
import {
  HeaderTitleTextWrapper,
  StyledTh,
} from "../../ReportTableWidget/styled";
import { Arrow, Direction, SortIconWrapper } from "../../styled";

import { GrouppedColumnHeader } from "./getGrouppedColumnHeader";
import { HeaderTitleButton } from "./styled";
import { SortByType } from "./Table";

interface TablePeriodizationHeaderProps {
  grouppedColumnHeaders: GrouppedColumnHeader[];
  timeLine: TimeLine;
  sortByConfig: SortByType;
  onHeaderTitlePress: (colIndex: number) => void;
  setHiddenHeaderHeight?: (height: number) => void;
}

const CustomStyledTh = styled(StyledTh)`
  font-size: 10px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const SecondRow = styled.tr`
  height: 35px;
  padding: 10px 0;
  z-index: 10;
`;

const AggregationText = styled(HeaderTitleTextWrapper)`
  display: flex;
  flex: 1;
  padding: 12px 0;
  min-width: 80px;
`;

const FirstRowWrapper = styled.div`
  padding: 0 20px;
`;

export function TablePeriodizationHeader({
  grouppedColumnHeaders,
  timeLine,
  sortByConfig,
  onHeaderTitlePress,
  setHiddenHeaderHeight,
}: TablePeriodizationHeaderProps) {
  const { t } = useTranslation();
  const firstRowRef = useRef();
  const secondRowRef = useRef();

  const getShouldShowAggregationSelectorForCollection = (
    columnHeader: GrouppedColumnHeader
  ) =>
    (!isSoccerStatsCollection(columnHeader.attributes[0].collectionId) &&
      !isAttendanceCollection(columnHeader.attributes[0].collectionId) &&
      columnHeader.attributes.length > 1) ||
    columnHeader.attributes.some(
      (attribute) => attribute.aggregateFunctions?.length !== 1
    );

  const hasOnlyColumnsWithoutAgg = grouppedColumnHeaders.every((column) =>
    getShouldShowAggregationSelectorForCollection(column)
  );

  useEffect(() => {
    if (setHiddenHeaderHeight) {
      // @ts-ignore
      const firstHiddenHeight = firstRowRef?.current?.clientHeight ?? 0;
      // @ts-ignore
      const secondHiddenHeight = secondRowRef?.current?.clientHeight ?? 0;

      setHiddenHeaderHeight(firstHiddenHeight + secondHiddenHeight);
    }
  }, [setHiddenHeaderHeight]);

  const directionIndicator =
    sortByConfig.order === 1 ? Direction.Up : Direction.Down;

  const getOrderOfSubColumn = (
    clickedMeasurementHeader: number,
    clickedAttributeColumn: number,
    clickedAggregationIndex: number
  ) => {
    let totalCount = 0;

    grouppedColumnHeaders.forEach((grouppedColumnHeader, indexOfColumn) => {
      if (indexOfColumn <= clickedMeasurementHeader) {
        grouppedColumnHeader.attributes.forEach(
          (attributeColumn, indexOfAttribute) => {
            if (
              indexOfAttribute <= clickedAttributeColumn ||
              indexOfColumn < clickedMeasurementHeader
            ) {
              attributeColumn.aggregateFunctions.forEach(
                (_, indexOfAggregation) => {
                  if (
                    indexOfAggregation <= clickedAggregationIndex ||
                    indexOfColumn < clickedMeasurementHeader ||
                    (indexOfColumn < clickedMeasurementHeader &&
                      indexOfAttribute < clickedAggregationIndex)
                  ) {
                    totalCount++;
                  }
                }
              );
            }
          }
        );
      }
    });

    return totalCount > 0 ? totalCount - 1 : totalCount;
  };

  return (
    <>
      <tr ref={firstRowRef} style={{ zIndex: 10 }}>
        <CustomStyledTh
          rowSpan={hasOnlyColumnsWithoutAgg ? 1 : 2}
          key="th-placeholder"
        >
          {t("athlete")}
        </CustomStyledTh>

        {grouppedColumnHeaders.map((columnHeader, index) => {
          const isSortIconVisible = sortByConfig.colIndex === index;

          if (columnHeader.attributes.length === 0) {
            return null;
          }

          const colSpan = columnHeader.attributes.flatMap(
            (attribute) => attribute.aggregateFunctions
          ).length;

          const shouldShowHeaderSplit =
            getShouldShowAggregationSelectorForCollection(columnHeader);

          const isSortable =
            columnHeader.attributes?.length === 1 &&
            columnHeader.attributes[0]?.aggregateFunctions?.length === 1;

          return (
            <StyledTh
              rowSpan={shouldShowHeaderSplit ? 1 : 2}
              colSpan={colSpan}
              key={`th-${columnHeader.templateId}`}
            >
              <HeaderTitleButton
                onClick={() =>
                  isSortable ? onHeaderTitlePress(index) : () => {}
                }
              >
                <FirstRowWrapper>
                  <ComplexHeaderValueWrapper>
                    {columnHeader.attributes[0].name}
                    {isSortIconVisible && isSortable && (
                      <SortIconWrapper isIconVisible={isSortIconVisible}>
                        <Arrow direction={directionIndicator} />
                      </SortIconWrapper>
                    )}
                  </ComplexHeaderValueWrapper>

                  {shouldShowHeaderSplit && <HeaderLine />}
                </FirstRowWrapper>
              </HeaderTitleButton>
            </StyledTh>
          );
        })}
      </tr>

      {!hasOnlyColumnsWithoutAgg && (
        <SecondRow ref={secondRowRef}>
          {grouppedColumnHeaders.map(
            (columnHeader, columnHeaderIndex) =>
              getShouldShowAggregationSelectorForCollection(columnHeader) &&
              columnHeader.attributes.flatMap(
                (
                  attributeStatItem: ReportWidgetCellNumerical & {
                    attributeTemplateName?: string;
                  },
                  attributeIndex
                ) =>
                  attributeStatItem.aggregateFunctions.flatMap(
                    (currentAggregation, aggregationIndex) => {
                      const isSortIconVisible =
                        sortByConfig.colIndex ===
                        getOrderOfSubColumn(
                          columnHeaderIndex,
                          attributeIndex,
                          aggregationIndex
                        );

                      return (
                        <th>
                          <HeaderTitleButton
                            onClick={() =>
                              onHeaderTitlePress(
                                getOrderOfSubColumn(
                                  columnHeaderIndex,
                                  attributeIndex,
                                  aggregationIndex
                                )
                              )
                            }
                          >
                            <AggregationText>
                              {timeLine !== TimeLine.CONTINUOUS
                                ? t(`aggregation${currentAggregation}`, {
                                    defaultValue: currentAggregation,
                                  }).toString()
                                : ""}

                              {` ${
                                attributeStatItem.attributeTemplate?.name ??
                                attributeStatItem.attributeTemplateName ??
                                ""
                              }`}
                              {isSortIconVisible && (
                                <SortIconWrapper
                                  isIconVisible={isSortIconVisible}
                                >
                                  <Arrow direction={directionIndicator} />
                                </SortIconWrapper>
                              )}
                            </AggregationText>
                          </HeaderTitleButton>
                        </th>
                      );
                    }
                  )
              )
          )}
        </SecondRow>
      )}
    </>
  );
}
