import React, { useMemo, useState } from "react";
import Select, { components, OptionProps, StylesConfig } from "react-select";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_SELECT_BORDER,
  COLOR_SELECT_BORDER_HOVER,
  COLOR_WHITE,
} from "../colors";

import { CustomDropdownIndicator } from "./CustomDropdownIndicator";
import Hoverable from "./Hoverable";
import { ChartBarIcon, ChartColumnIcon, ChartLineIcon } from "./Icons";
import { ChartType } from "./Report/ChartWIdget/model";
import { SelectOption } from "./SelectField";
import StyledText from "./StyledText";

const { Option, SingleValue } = components;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValueLabel = styled(StyledText)`
  margin-left: 16px;
  font-weight: bold;
  color: inherit;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function getChartIcon(type: ChartType, isActive?: boolean) {
  switch (type) {
    case ChartType.COLUMN:
      return <ChartColumnIcon isActive={isActive} />;
    case ChartType.LINE:
      return <ChartLineIcon isActive={isActive} />;
    case ChartType.BAR:
      return <ChartBarIcon isActive={isActive} />;
  }
}

const IconOption = (props: OptionProps<SelectOption>) => {
  const isActive = !!props.getStyles("option", props)?.color;

  return (
    <Option {...props}>
      <ValueContainer>
        {getChartIcon(props.data.value as ChartType, isActive)}
        <ValueLabel>{props.data.label}</ValueLabel>
      </ValueContainer>
    </Option>
  );
};

const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <ValueContainer>
      {getChartIcon(props.data.value as ChartType)}
      <ValueLabel>{props.data.label}</ValueLabel>
    </ValueContainer>
  </SingleValue>
);

export const IconSelectField = ({ options, field, form }) => {
  const [isSelected, setSelected] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const defaultOnSelectChange = (option: SelectOption) => {
    form.setFieldValue(field.name, option.value);
  };

  const selectStyles = useMemo(
    () => ({
      ...defaultSelectStyles,
      container: (provided) => ({
        ...provided,
        borderRadius: 6,
        overflow: "hidden",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: COLOR_SELECT_BORDER,
        ...(isSelected
          ? {
              borderColor: COLOR_BLUE,
            }
          : isHovered
            ? {
                borderColor: COLOR_SELECT_BORDER_HOVER,
              }
            : {}),
      }),
    }),
    [isHovered, isSelected]
  );

  return (
    <Hoverable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <SelectContainer>
        <Select
          defaultValue={options[0]}
          options={options}
          onMenuOpen={() => setSelected(true)}
          onMenuClose={() => setSelected(false)}
          components={{
            Option: IconOption,
            SingleValue: IconSingleValue,
            DropdownIndicator(props) {
              return (
                <CustomDropdownIndicator
                  {...props}
                  isHovered={isHovered}
                  isSelected={isSelected}
                />
              );
            },
          }}
          styles={selectStyles}
          value={options.find(
            (option) => (option as SelectOption).value === field.value
          )}
          onChange={defaultOnSelectChange}
          menuPortalTarget={document.body}
        />
      </SelectContainer>
    </Hoverable>
  );
};

const defaultSelectStyles: StylesConfig = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    color: isSelected ? COLOR_WHITE : undefined,
    backgroundColor: isSelected
      ? COLOR_BLUE
      : isFocused
        ? COLOR_GRAY
        : COLOR_WHITE,
    fontWeight: 700,
    fontSize: 12,
    lineHeight: "15px",
  }),
  menu: (provided) => ({
    ...provided,
    paddingTop: 5,
    paddingBottom: 5,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: COLOR_WHITE,
    border: "none",
    boxShadow: "none",
    height: "45px",
    width: "260px",
    paddingRight: 10,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    zoom: 0.8,
    color: COLOR_BLUE,
  }),
  valueContainer: () => ({
    fontWeight: "bold",
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
  }),
};
