import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { XpsUser } from "../../../graphql";
import { CardCollapsible } from "../../CardCollapsible";

import { SessionEditMemberAttendanceItem } from "./SessionEditMemberAttendanceItem";

type SessionEditMemberAttendanceListProps = {
  data: XpsUser[];
  practice: any;
  searchValue?: string;
  refetch: any;
};

export function SessionEditMemberAttendanceList({
  data,
  searchValue,
  practice,
  refetch,
}: SessionEditMemberAttendanceListProps) {
  const { t } = useTranslation();

  const userList = useMemo(() => {
    if (searchValue === undefined) {
      return data;
    }

    return (
      // @ts-ignore
      data.filter((user) =>
        user?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      ) ?? []
    );
  }, [data, searchValue]);

  return (
    <CardCollapsible
      title={t("members")}
      titleBadge={`${userList?.length}`}
      defaultIsCollapsed={false}
    >
      <div>
        {userList?.map((user, index) => (
          <SessionEditMemberAttendanceItem
            key={user?.id}
            user={user}
            isFirst={index === 0}
            isLast={index === userList?.length - 1}
            practice={practice}
            refetch={refetch}
          />
        ))}
      </div>
    </CardCollapsible>
  );
}
