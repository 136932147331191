import React from "react";

import { Dayjs } from "dayjs";

import { ExtendedPeriod, Period } from "../../contexts/Reports";

import { CustomPeriodCalendar } from "./CustomPeriodCalendar";
import { ReportPeriodSingleCalendar } from "./ReportPeriodSingleCalendar";

export function ReportPeriodCalendarComponent({
  selectedPeriod,
  ...rest
}: {
  selectedPeriod?: ExtendedPeriod;
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  firstInputLabel?: string;
  secondInputLabel?: string;
  setStartDate(date): void;
  setEndDate(date): void;
  selectPeriod?(period: Period): void;
}) {
  switch (selectedPeriod) {
    case "week":
    case "month":
    case "day":
      return (
        <ReportPeriodSingleCalendar selectedPeriod={selectedPeriod} {...rest} />
      );
    case "custom":
    case "last7days":
    case "last30days":
      return <CustomPeriodCalendar {...rest} />;
    default:
      return null;
  }
}
