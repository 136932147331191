import React, { useMemo } from "react";

import dayjs from "dayjs";
import useResizeObserver from "use-resize-observer";

import { useReportContext } from "../../../../contexts/report";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../../../hooks";
import { InformationWidgetFormModel } from "../../../../screens/InformationWidgetEditor";
import { getCookingValue } from "../../../../services";
import { useInformations2022Query } from "../../../../services/statistics/hooks";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetTitleContainer,
} from "../../styled";
import { WidgetLoader } from "../../WidgetLoader";

import { InformationTable } from "./InformationTable";
import { InformationWidgetDataModel } from "./model";

interface InformationWidgetProps extends IWidgetResizeProps {
  widget: InformationWidgetFormModel;
  readOnly?: boolean;
}

export const InformationWidget = ({
  widget,
  gridItemId,
  setItemToResize,
  setItemsToHideInViewMode,
  layoutRef,
  readOnly,
}: InformationWidgetProps) => {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const [{ selectedAthlete }] = useReportsContext();

  const { fromDate, toDate } = useReportPeriod(widget.period);

  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();

  useBottomWidgetResize({
    setItemToResize,
    setItemsToHideInViewMode,
    gridItemId,
    widgetHeight,
    id: widget.id,
    shouldResize: readOnly,
    alwaysResize: true,
    layoutRef,
  });

  const {
    data: informationsResponse,
    isLoading: informationsIsLoading,
    fetchStatus: informationsFetchStatus,
    refetch: informationsRefetch,
  } = useInformations2022Query(
    {
      athleteGuid: selectedAthlete?.id,
      templates: widget.informations.map((info) => ({
        guid: info.measurementTemplateId,
        cooking: getCookingValue(info.aggregation),
      })),
      range: {
        from: fromDate,
        to: toDate,
      },
      session: { sessionId },
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId && selectedAthlete?.id && widget.informations.length > 0,
    }
  );

  const dataToDisplay = useMemo<InformationWidgetDataModel[]>(
    () =>
      informationsResponse?.data?.templates.map((template) => ({
        title: template.name,
        templateId: template.templateGuid,
        options: template.options ?? [],
        data: template.items.map((item) => ({
          id: item._guid,
          value: item?._value,
          date: dayjs(item._time),
          label: item?._label,
        })),
      })) ?? [],
    [informationsResponse?.data?.templates]
  );

  if (informationsFetchStatus !== "idle" && informationsIsLoading) {
    return <WidgetLoader />;
  }

  return (
    <WidgetContainer showBorders={widget.widgetBorders}>
      <div ref={tableRef as any}>
        <WidgetTitleContainer style={{ paddingBottom: 7 }}>
          <WidgetTitle>{widget.name}</WidgetTitle>
        </WidgetTitleContainer>

        {dataToDisplay.map((information, index) => (
          <InformationTable
            key={information.title}
            information={information}
            order={index}
            refetchData={informationsRefetch}
            expandedByDefault={dataToDisplay.length === 1}
          />
        ))}
      </div>
    </WidgetContainer>
  );
};
