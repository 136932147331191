import React from "react";
import { useTranslation } from "react-i18next";

import { AthleteProfileIcon } from "../../components/Icons/AthleteProfileIcon";
import { DiagramAndAnimationIcon } from "../../components/Icons/DiagramAndAnimationIcon";
import { SendMessagesIcon } from "../../components/Icons/SendMessagesIcon";
import { StrengthIcon } from "../../components/Icons/StrengthIcon";
import { TeamAndGroupsIcon } from "../../components/Icons/TeamsAndGroupsIcon";
import { UserManagementIcon } from "../../components/Icons/UserManagementIcon";
import { useUIContext } from "../../contexts/UI";

import { RightSectionBox } from "./RightSectionBox";
import { RightSectionWrapper } from "./styles";

export const RightSection = () => {
  const { t } = useTranslation();
  const ui = useUIContext();

  const sectionToFullWidth = ui.width < 1000;

  const boxes = [
    {
      title: t("downloadXPSTitle"),
      text: t("downloadXPSText"),
      url: "https://sidelinesports.com/downloads",
      withSpecialIcon: true,
    },
    {
      title: t("teamAndGroupsTitle"),
      text: t("teamAndGroupsText"),
      icon: <TeamAndGroupsIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#team-group-sessions",
    },
    {
      title: t("diagramsAndAnimationsTitle"),
      text: t("diagramsAndAnimationsText"),
      icon: <DiagramAndAnimationIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#diagrams-animation",
    },
    {
      title: t("userManagementTitle"),
      text: t("userManagementText"),
      icon: <UserManagementIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#teams-user-management",
    },
    {
      title: t("messagesTitle"),
      text: t("messagesText"),
      icon: <SendMessagesIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#messages",
    },
    {
      title: t("strengthAndConditioningTitle"),
      text: t("strengthAndConditioningText"),
      icon: <StrengthIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#strength-conditioning",
    },
    {
      title: t("athleteProfileTitle"),
      text: t("athleteProfileText"),
      icon: <AthleteProfileIcon />,
      url: "https://xps.sidelinesports.com/en/tutorials#athlete-profile",
    },
  ];

  return (
    <RightSectionWrapper scrollable={sectionToFullWidth}>
      {boxes.map(({ title, text, icon, url, withSpecialIcon }) => (
        <RightSectionBox
          withSpecialIcon={withSpecialIcon}
          title={title}
          text={text}
          icon={icon}
          url={url}
        />
      ))}
    </RightSectionWrapper>
  );
};
