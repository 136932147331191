import { useState } from "react";

import { Dayjs, ManipulateType } from "dayjs";

import {
  ChartItemModel,
  TimeLinePeriod,
} from "../../components/Report/ChartWIdget/model";
import {
  StatisticsData,
  StatisticsDataItem,
} from "../../components/Report/models";
import { AggregateFunction } from "../../graphql";
import { getDummyValue } from "../../utils/dummyData";

export const useDummyChartData = (
  fromDate: Dayjs,
  toDate: Dayjs,
  timeLinePeriod = TimeLinePeriod.DAYS
) => {
  const [dummyValues, setDummyValue] = useState<Map<string, number>>(
    new Map<string, number>()
  );

  function generateValue(
    templateId: string,
    attributeName: string,
    addDays: number,
    athlete: string,
    aggregation: AggregateFunction
  ) {
    const time = fromDate
      .add(addDays, timeLinePeriod as ManipulateType)
      .valueOf();
    const key = `${templateId}:${attributeName}:${time}:${athlete}:${aggregation}`;
    let memoizedValue = dummyValues.get(key);

    if (!memoizedValue) {
      memoizedValue = getDummyValue(attributeName) as number;

      setDummyValue(new Map(dummyValues.set(key, memoizedValue)));
    }

    return { val: memoizedValue, time };
  }

  function generateValues(item: ChartItemModel, athlete: string) {
    const emptyValues = new Array(
      (timeLinePeriod || item.aggregation === AggregateFunction.Each) &&
      timeLinePeriod !== TimeLinePeriod.NONE
        ? toDate.diff(fromDate, timeLinePeriod) + 1 || 1
        : 1
    ).fill(0);

    return emptyValues.map((_, index) =>
      generateValue(
        item.templateGuid,
        item.attributeName,
        index,
        athlete,
        item.aggregation || AggregateFunction.Average
      )
    );
  }

  const generateDummyData = (items: ChartItemModel[], athletes: string[]) => {
    const athletesChartStatistics: StatisticsData = {
      perPerformer: athletes.map((athlete) => ({
        id: athlete,
        name: athlete,
        items: items.map<StatisticsDataItem>((item) => ({
          template: {
            id: item.templateGuid,
            name: item.templateName,
          },
          attribute: {
            id: item.attribute,
            name: item.attribute,
            unitName: item.attributeName,
          },
          aggregateFunction: item.aggregation,
          values: generateValues(item, athlete),
        })),
      })),
    };
    const teamAverageChartStatistics: StatisticsData = {
      perPerformer: [
        {
          name: "",
          items: items
            .filter((item) => item.showTeamAverage)
            .map<StatisticsDataItem>((item) => ({
              template: {
                id: item.templateGuid,
                name: item.templateName,
              },
              attribute: {
                id: item.attribute,
                name: item.attribute,
                unitName: item.attributeName,
              },
              aggregateFunction: item.aggregation,
              values: generateValues(item, ""),
            })),
        },
      ],
    };

    return {
      athletesData: athletesChartStatistics,
      teamAverageData: teamAverageChartStatistics,
    };
  };

  return {
    generateDummyData,
  };
};
