import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_DAY_BACKGROUND,
  COLOR_MEDIUM_GRAY,
} from "../../../colors";
import Hoverable from "../../Hoverable";
import StyledText from "../../StyledText";
import AgendaItem from "../AgendaItem";
import { AgendaSession, RefetchAgendaFunc } from "../model";

const Container = styled.div<{ hovered: boolean }>`
  flex-direction: row;
  display: flex;
  z-index: ${({ hovered }) => (hovered ? 1 : 0)};
`;

const DayContainer = styled.div<{ isToday: boolean }>`
  padding-top: 10px;
  width: 45px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-bottom: 5px;
  margin-right: 10px;
  background-color: ${({ isToday }) => isToday && COLOR_DAY_BACKGROUND};
  cursor: default;
`;

const DayLabel = styled(StyledText)<{ isToday: boolean }>`
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: ${({ isToday }) => (isToday ? COLOR_BLUE : COLOR_MEDIUM_GRAY)};
`;

const DayNameLabel = styled(StyledText)<{ isToday: boolean }>`
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: ${({ isToday }) => (isToday ? COLOR_BLUE : COLOR_MEDIUM_GRAY)};
`;

const AgendaContainer = styled.div`
  flex: 1;
`;

interface DayBlockProps {
  day: string;
  days: Record<string, AgendaSession[]>;
  refetch: RefetchAgendaFunc;
}

export function DayBlock({ day, days, refetch }: DayBlockProps) {
  // @ts-ignore
  const isToday = dayjs(day).isToday();

  return (
    <Hoverable>
      {(hovered) => (
        <Container hovered={hovered} key={day}>
          <DayContainer isToday={isToday}>
            <DayNameLabel isToday={isToday}>
              {dayjs(day).format("ddd").toUpperCase()}
            </DayNameLabel>
            <DayLabel isToday={isToday}>{dayjs(day).format("DD")}</DayLabel>
            <DayNameLabel isToday={isToday}>
              {dayjs(day).format("MMM").toUpperCase()}
            </DayNameLabel>
          </DayContainer>
          <AgendaContainer>
            {days[day].map((session) => (
              <AgendaItem
                key={session.id}
                refetch={refetch}
                session={session}
              />
            ))}
          </AgendaContainer>
        </Container>
      )}
    </Hoverable>
  );
}
