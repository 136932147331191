import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import { CalendarDottedIcon, HomeIcon } from "../Icons";

import { BaseNavSideWrapper } from "./BaseNavSideWrapper";
import { NavIcon } from "./NavIcon";
import { AthletesNavSideSection } from "./Sections/AthletesNavSideSection";
import { MessagesNavSideSection } from "./Sections/MessagesNavSideSection";
import { StoreNavSideSection } from "./Sections/StoreMessagesNavSideSection";

import { SectionTitleText } from "./index";

interface FamilyMemberNavSideProps {
  isCompact: boolean;
}

export const FamilyMemberNavSide = ({
  isCompact,
}: FamilyMemberNavSideProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <BaseNavSideWrapper isCompact={isCompact}>
      {isAcesNationEnv() ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
          iconSvg={
            <CalendarDottedIcon
              isActive={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
            />
          }
          isCompact={isCompact}
          text={t("calendar")}
          onClick={() => navigate(`/${ROUTING_CONFIG.calendar}`)}
        />
      ) : (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
          iconSvg={
            <HomeIcon
              isActive={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
            />
          }
          isCompact={isCompact}
          text={t("myXPS")}
          onClick={() => navigate(`/${ROUTING_CONFIG.myXPS}`)}
        />
      )}
      <MessagesNavSideSection isCompact={isCompact} />
      {!isCompact && (
        <SectionTitleText>{t("workspace").toUpperCase()}</SectionTitleText>
      )}
      <AthletesNavSideSection isCompact={isCompact} />
      <StoreNavSideSection isCompact={isCompact} />
    </BaseNavSideWrapper>
  );
};
