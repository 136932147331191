import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../colors";
import StyledText from "../../StyledText";

const SeenByWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 5px;
  margin-right: 10px;
`;

export const SeenByForUser = () => {
  const { t } = useTranslation();

  return (
    <SeenByWrapper>
      <StyledText fontWeight="semibold" fontSize={10} color={COLOR_MEDIUM_GRAY}>
        {t("seen")}
      </StyledText>
    </SeenByWrapper>
  );
};
