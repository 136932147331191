import { ApolloCache } from "@apollo/client";

import {
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryVariables,
} from "../graphql/generated";
import { MessageOverviewPaginated } from "../graphql/queries/MessageOverviewPaginated";

type EdgeNodeType =
  MessageOverviewPaginatedQuery["messageOverview"]["contactsPaginated"]["edges"][0]["node"];

// This should be used for all cache manipulation with specific contact in left list of contacts in the message module
export const changePaginatedContactInMessages = ({
  proxy,
  language,
  sessionId,
  contactId,
  edgeModifier,
}: {
  proxy: ApolloCache<any>;
  language: string;
  sessionId: string;
  contactId: string;
  edgeModifier: (edge: EdgeNodeType) => EdgeNodeType;
}) => {
  const variables = {
    sessionId,
    language,
    showAll: true,
    includeHidden: true,
    ignoreContactsWithoutChats: true,
  };
  const data = proxy.readQuery<
    MessageOverviewPaginatedQuery,
    MessageOverviewPaginatedQueryVariables
  >({
    query: MessageOverviewPaginated,
    variables,
  });

  const modifiedEdges = data?.messageOverview?.contactsPaginated?.edges?.map(
    (edge) => ({
      ...edge,
      node: edge?.node?.id === contactId ? edgeModifier(edge.node) : edge.node,
    })
  );

  proxy.writeQuery<
    MessageOverviewPaginatedQuery,
    MessageOverviewPaginatedQueryVariables
  >({
    query: MessageOverviewPaginated,
    variables,
    data: {
      ...data,
      messageOverview: {
        ...data?.messageOverview,
        contactsPaginated: {
          ...data?.messageOverview?.contactsPaginated,
          edges: modifiedEdges,
        },
      },
    },
  });
};
