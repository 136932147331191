import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const LogoAcesNationIcon = memo(
  ({ width = 64, height = 49, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 344 262"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometrictPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <g>
        <path
          fill="#000103"
          d="M 158.5,-0.5 C 167.833,-0.5 177.167,-0.5 186.5,-0.5C 225.085,6.72509 252.252,28.3918 268,64.5C 270.221,69.9934 271.721,75.6601 272.5,81.5C 272.167,81.5 271.833,81.5 271.5,81.5C 269.735,80.5386 267.735,80.2053 265.5,80.5C 253.888,39.0554 226.888,14.5554 184.5,7C 137.64,3.6763 104.14,23.1763 84,65.5C 69.162,109.659 79.3287,146.826 114.5,177C 148.847,200.712 184.514,202.712 221.5,183C 254.182,161.418 269.515,130.918 267.5,91.5C 269.735,91.7947 271.735,91.4614 273.5,90.5C 276.686,139.622 256.019,174.789 211.5,196C 163.899,212.227 123.732,201.393 91,163.5C 68.2598,131.098 64.2598,96.4309 79,59.5C 95.3692,25.951 121.869,5.95103 158.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#11529f"
          d="M 265.5,80.5 C 252.788,79.8536 240.455,80.5203 228.5,82.5C 227.85,84.4352 227.517,86.4352 227.5,88.5C 226.596,89.209 226.263,90.209 226.5,91.5C 240.167,91.5 253.833,91.5 267.5,91.5C 269.515,130.918 254.182,161.418 221.5,183C 184.514,202.712 148.847,200.712 114.5,177C 79.3287,146.826 69.162,109.659 84,65.5C 104.14,23.1763 137.64,3.6763 184.5,7C 226.888,14.5554 253.888,39.0554 265.5,80.5 Z"
        />
      </g>
      <g>
        <path
          fill="#95b2d4"
          d="M 101.5,81.5 C 111.653,80.5058 121.986,80.1724 132.5,80.5C 132.737,81.791 132.404,82.791 131.5,83.5C 121.889,81.5257 111.889,80.859 101.5,81.5 Z"
        />
      </g>
      <g>
        <path
          fill="#9db8d7"
          d="M 194.5,81.5 C 204.319,80.506 214.319,80.1726 224.5,80.5C 224.737,81.791 224.404,82.791 223.5,83.5C 214.225,81.5267 204.558,80.86 194.5,81.5 Z"
        />
      </g>
      <g>
        <path
          fill="#9db4cf"
          d="M 265.5,80.5 C 267.735,80.2053 269.735,80.5386 271.5,81.5C 257.088,81.1844 242.755,81.5178 228.5,82.5C 240.455,80.5203 252.788,79.8536 265.5,80.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f6f8fb"
          d="M 101.5,81.5 C 111.889,80.859 121.889,81.5257 131.5,83.5C 130.908,86.1272 130.575,88.7938 130.5,91.5C 121.494,91.3336 112.494,91.5002 103.5,92C 100.747,97.0991 99.08,102.599 98.5,108.5C 98.4534,110.038 99.1201,111.038 100.5,111.5C 108.316,112.492 116.316,112.826 124.5,112.5C 124.5,113.5 124.5,114.5 124.5,115.5C 123.781,117.764 123.448,120.097 123.5,122.5C 113.161,122.666 102.828,122.5 92.5,122C 88.736,120.404 86.736,117.571 86.5,113.5C 87.786,105.782 89.286,98.1157 91,90.5C 92.8534,85.4734 96.3534,82.4734 101.5,81.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f5f7fb"
          d="M 152.5,89.5 C 146.69,90.4896 140.69,90.8229 134.5,90.5C 135.482,87.2365 136.149,83.9032 136.5,80.5C 139.85,80.335 143.183,80.5017 146.5,81C 148.387,83.9502 150.387,86.7835 152.5,89.5 Z"
        />
      </g>
      <g>
        <path
          fill="#e7edf5"
          d="M 170.5,80.5 C 174.226,80.1774 177.893,80.5108 181.5,81.5C 180.449,84.757 179.782,88.0903 179.5,91.5C 175.833,91.5 172.167,91.5 168.5,91.5C 168.659,87.7091 169.325,84.0424 170.5,80.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fdfefe"
          d="M 194.5,81.5 C 204.558,80.86 214.225,81.5267 223.5,83.5C 223.326,85.861 222.993,88.1944 222.5,90.5C 209.656,90.8287 196.989,90.4954 184.5,89.5C 186.347,84.9876 189.68,82.321 194.5,81.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fefefe"
          d="M 271.5,81.5 C 271.833,81.5 272.167,81.5 272.5,81.5C 272.508,84.5448 272.841,87.5448 273.5,90.5C 257.797,91.1507 242.464,90.4841 227.5,88.5C 227.517,86.4352 227.85,84.4352 228.5,82.5C 242.755,81.5178 257.088,81.1844 271.5,81.5 Z"
        />
      </g>
      <g>
        <path
          fill="#84a5cd"
          d="M 152.5,89.5 C 153.043,89.56 153.376,89.8933 153.5,90.5C 147.012,91.8174 140.678,91.8174 134.5,90.5C 140.69,90.8229 146.69,90.4896 152.5,89.5 Z"
        />
      </g>
      <g>
        <path
          fill="#85a7ce"
          d="M 184.5,89.5 C 196.989,90.4954 209.656,90.8287 222.5,90.5C 209.661,91.8252 196.661,91.8252 183.5,90.5C 183.624,89.8933 183.957,89.56 184.5,89.5 Z"
        />
      </g>
      <g>
        <path
          fill="#84a1c4"
          d="M 227.5,88.5 C 242.464,90.4841 257.797,91.1507 273.5,90.5C 271.735,91.4614 269.735,91.7947 267.5,91.5C 253.833,91.5 240.167,91.5 226.5,91.5C 226.263,90.209 226.596,89.209 227.5,88.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f5f7fb"
          d="M 132.5,96.5 C 136.167,96.5 139.833,96.5 143.5,96.5C 142.321,104.878 140.821,113.211 139,121.5C 135.27,122.46 131.437,122.793 127.5,122.5C 129.484,113.896 131.151,105.23 132.5,96.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f8f9fc"
          d="M 145.5,97.5 C 149.311,96.3547 153.311,96.1881 157.5,97C 159.667,99.8333 161.833,102.667 164,105.5C 165.903,102.829 166.736,99.8294 166.5,96.5C 170.167,96.5 173.833,96.5 177.5,96.5C 176.049,105.203 174.383,113.87 172.5,122.5C 169.102,122.82 165.768,122.487 162.5,121.5C 156.811,113.433 151.145,105.433 145.5,97.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f6f8fb"
          d="M 182.5,96.5 C 186.226,96.1774 189.893,96.5108 193.5,97.5C 191.627,102.293 191.293,107.126 192.5,112C 200.827,112.5 209.16,112.666 217.5,112.5C 217.277,115.784 216.61,118.951 215.5,122C 205.5,122.667 195.5,122.667 185.5,122C 182.943,121.315 181.109,119.815 180,117.5C 179.313,110.294 180.147,103.294 182.5,96.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f5f7fb"
          d="M 240.5,96.5 C 244.167,96.5 247.833,96.5 251.5,96.5C 250.218,104.859 248.718,113.192 247,121.5C 243.27,122.46 239.437,122.793 235.5,122.5C 236.982,113.793 238.648,105.126 240.5,96.5 Z"
        />
      </g>
      <g>
        <path
          fill="#82a4cd"
          d="M 100.5,111.5 C 108.833,111.5 117.167,111.5 125.5,111.5C 125.768,113.099 125.434,114.432 124.5,115.5C 124.5,114.5 124.5,113.5 124.5,112.5C 116.316,112.826 108.316,112.492 100.5,111.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1052a0"
          d="M 65.5,261.5 C 60.8333,261.5 56.1667,261.5 51.5,261.5C 39.3136,255.107 36.1469,245.44 42,232.5C 47.7959,225.235 55.2959,222.735 64.5,225C 74.3944,227.9 74.8944,231.734 66,236.5C 57.7635,231.531 52.2635,233.698 49.5,243C 52.5439,253.321 58.3773,255.154 67,248.5C 69.2848,250.907 71.4514,253.407 73.5,256C 70.9086,258.117 68.2419,259.95 65.5,261.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1052a0"
          d="M 128.5,261.5 C 123.5,261.5 118.5,261.5 113.5,261.5C 111.432,260.625 109.432,259.459 107.5,258C 106.426,257.261 105.759,256.261 105.5,255C 106.776,252.719 108.443,250.719 110.5,249C 113.547,250.905 117.047,252.072 121,252.5C 123.348,252.742 125.182,251.908 126.5,250C 125.833,249.333 125.167,248.667 124.5,248C 119.194,247.757 114.194,246.424 109.5,244C 103.343,231.498 107.343,224.998 121.5,224.5C 126.558,224.129 130.891,225.629 134.5,229C 133.432,231.639 131.766,233.805 129.5,235.5C 127.137,234.323 124.637,233.657 122,233.5C 118.552,232.939 117.385,234.106 118.5,237C 125.319,237.378 131.152,239.878 136,244.5C 138.822,252.537 136.322,258.204 128.5,261.5 Z"
        />
      </g>
      <g>
        <path
          fill="#adadad"
          d="M 295.5,261.5 C 290.833,261.5 286.167,261.5 281.5,261.5C 268.79,254.687 265.623,244.687 272,231.5C 278.537,224.958 286.371,222.791 295.5,225C 306.302,229.096 310.469,236.929 308,248.5C 306.318,255.347 302.151,259.68 295.5,261.5 Z M 286.5,233.5 C 294.962,233.797 298.462,238.13 297,246.5C 293.984,252.372 289.484,253.872 283.5,251C 277.632,243.851 278.632,238.018 286.5,233.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1051a0"
          d="M 14.5,225.5 C 18.7675,225.203 22.9341,225.536 27,226.5C 31.8333,237.5 36.6667,248.5 41.5,259.5C 37.5546,260.491 33.5546,260.824 29.5,260.5C 29.5792,259.07 29.2458,257.736 28.5,256.5C 23.2079,255.505 17.8746,255.172 12.5,255.5C 12.5792,256.93 12.2458,258.264 11.5,259.5C 7.89272,260.489 4.22605,260.823 0.5,260.5C 4.88985,248.721 9.55651,237.054 14.5,225.5 Z M 20.5,237.5 C 22.0198,239.89 23.0198,242.557 23.5,245.5C 21.396,245.799 19.396,245.466 17.5,244.5C 18.7019,242.22 19.7019,239.886 20.5,237.5 Z"
        />
      </g>
      <g>
        <path
          fill="#0f529f"
          d="M 75.5,225.5 C 84.8333,225.5 94.1667,225.5 103.5,225.5C 103.5,228.5 103.5,231.5 103.5,234.5C 97.5,234.5 91.5,234.5 85.5,234.5C 85.5,235.833 85.5,237.167 85.5,238.5C 91.1667,238.5 96.8333,238.5 102.5,238.5C 102.5,241.5 102.5,244.5 102.5,247.5C 96.8333,247.5 91.1667,247.5 85.5,247.5C 85.5,248.833 85.5,250.167 85.5,251.5C 91.8333,251.5 98.1667,251.5 104.5,251.5C 104.5,254.5 104.5,257.5 104.5,260.5C 94.8333,260.5 85.1667,260.5 75.5,260.5C 75.5,248.833 75.5,237.167 75.5,225.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acacac"
          d="M 149.5,225.5 C 152.85,225.335 156.183,225.502 159.5,226C 164.046,230.877 168.213,236.044 172,241.5C 172.499,236.177 172.666,230.844 172.5,225.5C 176.167,225.5 179.833,225.5 183.5,225.5C 183.5,237.167 183.5,248.833 183.5,260.5C 180.15,260.665 176.817,260.498 173.5,260C 169.333,254.5 165.167,249 161,243.5C 161.289,249.121 161.456,254.788 161.5,260.5C 157.5,260.5 153.5,260.5 149.5,260.5C 149.5,248.833 149.5,237.167 149.5,225.5 Z"
        />
      </g>
      <g>
        <path
          fill="#adadad"
          d="M 199.5,225.5 C 203.555,225.176 207.555,225.509 211.5,226.5C 216.694,237.416 221.694,248.416 226.5,259.5C 222.5,260.833 218.5,260.833 214.5,259.5C 213.754,258.264 213.421,256.93 213.5,255.5C 208.167,255.5 202.833,255.5 197.5,255.5C 197.579,256.93 197.246,258.264 196.5,259.5C 192.5,260.833 188.5,260.833 184.5,259.5C 190.062,248.38 195.062,237.047 199.5,225.5 Z M 204.5,238.5 C 205.376,238.369 206.043,238.702 206.5,239.5C 209.892,244.724 208.559,246.39 202.5,244.5C 203.372,242.55 204.039,240.55 204.5,238.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acacac"
          d="M 221.5,225.5 C 231.833,225.5 242.167,225.5 252.5,225.5C 252.5,228.5 252.5,231.5 252.5,234.5C 249.167,234.5 245.833,234.5 242.5,234.5C 242.5,243.167 242.5,251.833 242.5,260.5C 238.833,260.5 235.167,260.5 231.5,260.5C 231.5,251.833 231.5,243.167 231.5,234.5C 228.167,234.5 224.833,234.5 221.5,234.5C 221.5,231.5 221.5,228.5 221.5,225.5 Z"
        />
      </g>
      <g>
        <path
          fill="#adadad"
          d="M 254.5,225.5 C 258.167,225.5 261.833,225.5 265.5,225.5C 265.5,237.167 265.5,248.833 265.5,260.5C 261.833,260.5 258.167,260.5 254.5,260.5C 254.5,248.833 254.5,237.167 254.5,225.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acacac"
          d="M 343.5,225.5 C 343.5,237.167 343.5,248.833 343.5,260.5C 340.813,260.664 338.146,260.497 335.5,260C 331.453,254.742 327.287,249.575 323,244.5C 322.501,249.823 322.334,255.156 322.5,260.5C 318.833,260.5 315.167,260.5 311.5,260.5C 311.5,248.833 311.5,237.167 311.5,225.5C 314.85,225.335 318.183,225.502 321.5,226C 325.667,231.5 329.833,237 334,242.5C 334.499,236.843 334.666,231.176 334.5,225.5C 337.5,225.5 340.5,225.5 343.5,225.5 Z"
        />
      </g>
    </svg>
  )
);
