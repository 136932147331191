import { useCallback } from "react";

import { CommonAttributesIds } from "../../../constants";
import {
  AggregateFunction,
  ReportWidgetTemplateHeader,
  TimeFrame,
  useSaveWidgetTemplateActivityLogMutation,
  useSaveWidgetTemplateAcuteChronicMutation,
  useSaveWidgetTemplateAthleteTableMutation,
  useSaveWidgetTemplateAttendanceLogMutation,
  useSaveWidgetTemplateBodyChartMutation,
  useSaveWidgetTemplateChartMutation,
  useSaveWidgetTemplateCombinedTestMutation,
  useSaveWidgetTemplateGroupTableMutation,
  useSaveWidgetTemplateHeaderMutation,
  useSaveWidgetTemplateInformationLogMutation,
  useSaveWidgetTemplateKeyValuesMutation,
  useSaveWidgetTemplateSchalkeAthleteTableMutation,
  useSaveWidgetTemplateSchalkeTeamTableMutation,
  WidgetCell,
  WidgetCellForManyAggregations,
  WidgetTemplateChartInput,
} from "../../../graphql";
import {
  KeyValuesWidgetModel,
  WidgetType,
} from "../../ReportCreateTable/models";
import { TimeLinePeriod } from "../ChartWIdget/model";
import { WidgetMode } from "../WidgetWithData";

const transformHeaderCell = (widget = {} as any) => {
  if (Object.keys(widget).length === 0) {
    return undefined;
  }

  const { attributeTemplate, aggregateFunctions, templateId, collectionId } =
    widget;

  return {
    attributeTemplateId:
      attributeTemplate?.id ?? CommonAttributesIds.TEXT_ATTRIBUTE_ID,
    itemId: templateId,
    collectionId,
    aggregateFunction: aggregateFunctions?.length ? aggregateFunctions[0] : "",
  };
};

export const useSaveWidgetToServer = (mode: WidgetMode) => {
  const [headerWidgetMutation] = useSaveWidgetTemplateHeaderMutation();
  const [chartWidgetMutation] = useSaveWidgetTemplateChartMutation();
  const [athleteTableWidgetMutation] =
    useSaveWidgetTemplateAthleteTableMutation();
  const [attendanceLogWidgetMutation] =
    useSaveWidgetTemplateAttendanceLogMutation();
  const [groupTableWidgetMutation] = useSaveWidgetTemplateGroupTableMutation();
  const [combinedTestWidgetMutation] =
    useSaveWidgetTemplateCombinedTestMutation();
  const [keyValuesWidgetMutation] = useSaveWidgetTemplateKeyValuesMutation();
  const [informationLogWidgetMutation] =
    useSaveWidgetTemplateInformationLogMutation();
  const [activityLogWidgetMutation] =
    useSaveWidgetTemplateActivityLogMutation();
  const [acuteChronicWidgetMutation] =
    useSaveWidgetTemplateAcuteChronicMutation();
  const [schalkeTeamTableMutation] =
    useSaveWidgetTemplateSchalkeTeamTableMutation();
  const [schalkeAthleteTableMutation] =
    useSaveWidgetTemplateSchalkeAthleteTableMutation();
  const [bodyChartWidgetMutation] = useSaveWidgetTemplateBodyChartMutation();

  const saveWidgetToServer = useCallback(
    (
      widget,
      sharedVariables: {
        sessionId: string;
        accountId?: string;
        input?: WidgetTemplateChartInput;
      } = { sessionId: "" },
      parsedPreferences = {},
      refetchConfig = {}
    ) => {
      switch (widget.type) {
        case WidgetType.CHART: {
          const { id, name, ...rest } = widget;
          const chartPreferences = { ...parsedPreferences, ...rest };

          return chartWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                timeFrame: {
                  [TimeLinePeriod.DAYS]: TimeFrame.Day,
                  [TimeLinePeriod.WEEKS]: TimeFrame.Week,
                  [TimeLinePeriod.MONTHS]: TimeFrame.Month,
                  [TimeLinePeriod.YEARS]: TimeFrame.Year,
                }[widget.timeLinePeriod],
                preferences: JSON.stringify(chartPreferences),
              },
            },
          });
        }
        case WidgetType.TABLE: {
          const {
            id: athleteTableId,
            name: athleteTableName,
            columnHeaders,
            ...groupTableRest
          } = widget;
          const {
            id: groupTableId,
            name: groupTableName,
            columns,
            rowHeaders,
            ...athleteTableRest
          } = widget;

          return mode === WidgetMode.GROUP
            ? groupTableWidgetMutation({
                ...refetchConfig,
                variables: {
                  ...sharedVariables,
                  input: {
                    ...sharedVariables.input,
                    columnHeaders: widget.columnHeaders.map(
                      (columnHeader) =>
                        ({
                          collectionId: columnHeader.collectionId,
                          id: columnHeader.id,
                          itemId: columnHeader.templateId,
                          attributeTemplateId:
                            columnHeader.__typename ===
                            "ReportWidgetCellTextual"
                              ? CommonAttributesIds.TEXT_ATTRIBUTE_ID
                              : columnHeader.attributeTemplate.id,
                          aggregateFunctions: columnHeader.aggregateFunctions,
                        }) as WidgetCellForManyAggregations
                    ),
                    preferences: JSON.stringify({
                      ...parsedPreferences,
                      ...groupTableRest,
                    }),
                  },
                },
              })
            : athleteTableWidgetMutation({
                ...refetchConfig,
                variables: {
                  ...sharedVariables,
                  input: {
                    ...sharedVariables.input,
                    columns: widget.columns,
                    rowHeaders: widget.rowHeaders.map(
                      (rowHeader) =>
                        ({
                          collectionId: rowHeader.collectionId,
                          id: rowHeader.id,
                          itemId: rowHeader.templateId,
                          attributeTemplateId:
                            rowHeader.__typename === "ReportWidgetCellTextual"
                              ? CommonAttributesIds.TEXT_ATTRIBUTE_ID
                              : rowHeader.attributeTemplate.id,
                        }) as WidgetCell
                    ),
                    preferences: JSON.stringify({
                      ...parsedPreferences,
                      ...athleteTableRest,
                    }),
                  },
                },
              });
        }
        case WidgetType.COMBINED_TEST: {
          const { id, name, ...rest } = widget;
          const combinedTestPreferences = { ...parsedPreferences, ...rest };

          return combinedTestWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                // We have mismatch in data, first one is used for creation, second one for duplicate
                templateID: widget.templateID ?? widget.testTemplateId,
                preferences: JSON.stringify(combinedTestPreferences),
              },
            },
          });
        }
        case WidgetType.ATTENDANCE_LOG: {
          return attendanceLogWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.KEY_VALUES: {
          const { id, name, grid, ...rest } = widget as KeyValuesWidgetModel;
          const combinedTestPreferences = { ...parsedPreferences, ...rest };

          return keyValuesWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                grid: grid.map((row) =>
                  row.map(
                    (item) =>
                      item && {
                        aggregateFunction:
                          item.aggregateFunctions?.[0] ??
                          AggregateFunction.Latest,
                        attributeTemplateId:
                          (item.__typename === "ReportWidgetCellNumerical"
                            ? item.attributeTemplate?.id
                            : "") ?? "",
                        collectionId: item.collectionId,
                        id: item.id,
                        itemId: item.templateId,
                      }
                  )
                ),
                preferences: JSON.stringify(combinedTestPreferences),
              },
            },
          });
        }
        case WidgetType.INFORMATION_LOG: {
          return informationLogWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.ACTIVITY_LOG: {
          return activityLogWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.ACUTE_CHRONIC: {
          return acuteChronicWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.HEADER: {
          const {
            name,
            preferences,
            cell1,
            cell2,
            cell3,
            cell4,
            cell5,
            cell6,
          } = widget as ReportWidgetTemplateHeader;

          return headerWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                name,
                preferences,
                cell1: transformHeaderCell(cell1 ?? {}),
                cell2: transformHeaderCell(cell2 ?? {}),
                cell3: transformHeaderCell(cell3 ?? {}),
                cell4: transformHeaderCell(cell4 ?? {}),
                cell5: transformHeaderCell(cell5 ?? {}),
                cell6: transformHeaderCell(cell6 ?? {}),
              },
            },
          });
        }
        case WidgetType.ATHLETE_TABLE_PERIODIZATION: {
          return schalkeAthleteTableMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                columnHeaders: widget.columnHeaders.map((columnHeader) => ({
                  attributeTemplateId:
                    columnHeader.__typename === "ReportWidgetCellTextual"
                      ? CommonAttributesIds.TEXT_ATTRIBUTE_ID
                      : columnHeader.attributeTemplate.id,
                  itemId: columnHeader.templateId,
                  collectionId: columnHeader.collectionId,
                  aggregateFunctions: columnHeader.aggregateFunctions,
                })),
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.GROUP_TABLE_PERIODIZATION: {
          const { __typename, ...template } = widget.measurementTemplate;

          return schalkeTeamTableMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                template: {
                  collectionId: template?.collectionId,
                  itemId: template?.templateId,
                  attributeTemplateId:
                    template?.attributeTemplate?.id ??
                    CommonAttributesIds.TEXT_ATTRIBUTE_ID,
                  aggregateFunction: template?.aggregateFunctions[0],
                },
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        case WidgetType.BODY_CHART: {
          const { informationTemplates, bodyChartTemplate } = widget;

          return bodyChartWidgetMutation({
            ...refetchConfig,
            variables: {
              ...sharedVariables,
              input: {
                ...sharedVariables.input,
                informationTemplateIds: informationTemplates?.map(
                  ({ id }) => id
                ),
                bodyChartTemplateID: bodyChartTemplate?.templateGuid,
                preferences: JSON.stringify(parsedPreferences),
              },
            },
          });
        }
        default: {
          return Promise.resolve({} as any);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode]
  );

  return { saveWidgetToServer };
};
