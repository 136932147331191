import React, { ReactNode } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_TEXT_DARK } from "../../colors";
import { useMobileNavSideContext } from "../../contexts/mobileNavSide";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { TabLabel } from "../../screens/AgendaScreen";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import { BasicButton } from "../Button";
import { LogoXpsIcon, LogoAcesNationIcon } from "../Icons";

interface BaseNavSideWrapperProps {
  isCompact: boolean;
  children: ReactNode;
}

const Container = styled.div`
  width: 260px;
  background-color: ${COLOR_TEXT_DARK};
  height: 100vh;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => (theme.isMobile ? "0 15px" : "0")};
  background-color: ${COLOR_ALMOST_BLACK};
  min-height: 55px;
`;

const LogoButton = styled(BasicButton)<{ isCompact: boolean }>`
  margin-right: auto;
  margin-left: ${({ isCompact }) => (isCompact ? "auto" : 0)};
`;

const ChildrenWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const BaseNavSideWrapper = ({
  isCompact,
  children,
}: BaseNavSideWrapperProps) => {
  const { isMobile } = useTheme();
  const { isMobileSidebarOpen, setIsMobileSideBarOpen } =
    useMobileNavSideContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAces = isAcesNationEnv();

  const onLogoPress = () => {
    const agendaTabId = getSearchParamsObject(searchParams)?.activeTabId;

    const homeRoute = isAces ? ROUTING_CONFIG.calendar : ROUTING_CONFIG.myXPS;

    if (!isCompact) {
      if (pathname === `/${ROUTING_CONFIG.calendar}`) {
        const scrollTopEvent = new CustomEvent("agendaScrollTop");
        document.dispatchEvent(scrollTopEvent);
        if (agendaTabId !== TabLabel.AGENDA) {
          setSearchParams({
            ...getSearchParamsObject(searchParams),
            activeTabId: TabLabel.AGENDA,
          });
        }
      } else {
        navigate(`/${homeRoute}`);
      }
    }

    navigate(`/${homeRoute}`);
    setIsMobileSideBarOpen((old) => !old);
  };

  return (
    <Container
      style={{
        ...(isCompact ? { width: 70 } : {}),
        ...(isMobile && !isMobileSidebarOpen ? { display: "none" } : {}),
      }}
    >
      <HeadWrapper>
        <LogoButton isCompact={isCompact} onClick={onLogoPress}>
          {isAces ? (
            <LogoAcesNationIcon
              style={{
                width: isCompact ? 40 : 64,
                height: isCompact ? 30 : 49,
                margin: isCompact ? "16px 0" : "16px 0 16px 26px",
              }}
            />
          ) : (
            <LogoXpsIcon
              style={{
                width: isCompact ? 40 : 64,
                height: isCompact ? 10 : 17,
                marginLeft: isCompact ? 0 : 26,
              }}
            />
          )}
        </LogoButton>
      </HeadWrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Container>
  );
};
