import React from "react";

import { useTransformChartWidgetData } from "../hooks/useTransformChartWidgetData";

import { ChartWidgetComponent } from "./ChartWidgetComponent";
import { useChartOptions } from "./hooks/useChartOptions";
import { ChartWidgetModel } from "./model";

interface ChartWidgetPreviewProps {
  widget: ChartWidgetModel;
  isGroupReport: boolean;
}

export function ChartWidgetPreviewWithData({
  widget,
  isGroupReport,
}: ChartWidgetPreviewProps) {
  const { options } = useChartOptions({
    widget,
    isGroupReport,
    isPreviewMode: true,
  });

  return (
    <ChartWidgetComponent
      widget={widget}
      isLoading={false}
      chartOptions={options}
    />
  );
}

export function ChartWidgetPreview({
  widget,
  isGroupReport,
}: ChartWidgetPreviewProps) {
  const { chartWidget } = useTransformChartWidgetData(widget, {
    skip: !widget,
  });

  if (!widget || !chartWidget) {
    return null;
  }

  return (
    <ChartWidgetPreviewWithData
      widget={chartWidget}
      isGroupReport={isGroupReport}
    />
  );
}
