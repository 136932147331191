import React from "react";

import styled from "styled-components";

import { COLOR_RED_SECONDARY } from "../../colors";

const ErrorContainer = styled.div`
  position: absolute;
  bottom: -20px;
`;

const ErrorText = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${COLOR_RED_SECONDARY};
`;

export function InputError({ errorMessage }: { errorMessage: string }) {
  return (
    <ErrorContainer>
      <ErrorText>{errorMessage}</ErrorText>
    </ErrorContainer>
  );
}
