import React from "react";

import { COLOR_BODY_FILL, COLOR_BODY_PART } from "../../colors";

import { BodyChartSide } from "./BodyChartSvg";
import { BodyPartsType, BodyRegistration } from "./model";
import { SECTION_CONFIG } from "./SectionConfig";

type BodyPartsProps = {
  sectionId: BodyPartsType;
  side: BodyChartSide;
  scale: number;
  focusedSection: BodyPartsType;
  registrations: BodyRegistration;
  onBodyPartClick?: (id: number, sectionId: BodyPartsType) => void;
};

const BODY_PART_SHAPES = {
  61: "M24.05,324.98 C24.47,314.01 27.85,214.1 11.08,198.35 C11.08,198.35 2.08,219.6 2.58,241.35 C3.1,264.12 10.58,324.85 10.58,328.85 C10.58,329.83 10.3,331.24 9.89,332.85 L9.89,332.85 C13.73,327.46 19.26,324.84 24.05,324.98 L24.05,324.98 L24.05,324.98 L24.05,324.98 Z",
  60: "M3.94,373.28 L3.94,373.28 C3.74,378.52 4.51,382.84 7.58,382.84 L56.08,382.84 C60.58,382.84 60.58,376.84 55.58,373.34 C54.51,372.59 53.33,371.49 52.09,370.19 L52.08,370.19 C52.08,370.2 29.14,354.72 3.94,373.28 L3.94,373.28 L3.94,373.28 L3.94,373.28 Z",
  58: "M6.58,355.85 C6.02,358.38 4.2,366.58 3.94,373.29 L3.94,373.29 C29.14,354.73 52.08,370.21 52.08,370.21 L52.09,370.21 C47.51,365.42 42.12,357.79 38.58,353.86 C21.58,334.35 6.58,355.85 6.58,355.85 L6.58,355.85 L6.58,355.85 L6.58,355.85 Z",
  57: "M26.08,203.68 C17.22,203.68 13.93,201.01 11.08,198.35 C27.85,214.1 24.48,314.01 24.05,324.98 L24.05,324.98 C24.52,314.7 28.98,226.62 46.57,188.35 C46.58,188.35 36.37,203.68 26.08,203.68 L26.08,203.68 L26.08,203.68 L26.08,203.68 Z",
  56: "M47.41,4.29 L47.22,4.02 C41.2,8.86 33.39,18.15 23.34,29.87 L23.49,30.38 C33.57,60.34 47.44,102.33 36.24,162.29 L36.23,162.35 C40.08,162.79 44.08,165.79 48.2,173.92 L48.2,173.92 C48.42,172.67 48.66,171.64 48.9,171.01 C67.47,68.09 47.41,4.29 47.41,4.29 L47.41,4.29 L47.41,4.29 L47.41,4.29 Z",
  55: "M36.24,162.36 C31.11,161.78 26.24,165.76 21.73,168.24 L21.73,168.24 L21.73,168.24 C17.52,170.55 13.62,171.56 10.08,166.35 C10.08,166.35 8.58,173.35 9.08,183.85 C9.58,194.35 11.08,198.35 11.08,198.35 C13.93,201.02 17.22,203.68 26.08,203.68 C36.37,203.68 46.58,188.35 46.58,188.35 C46.58,188.35 47.28,179.19 48.21,173.93 L48.21,173.93 C44.09,165.8 40.09,162.8 36.24,162.36 L36.24,162.36 L36.24,162.36 L36.24,162.36 Z",
  54: "M33.58,332.85 C32.58,324.85 51.66,252.51 52.25,234.02 C52.92,212.85 46.58,188.35 46.58,188.35 C28.98,226.61 24.52,314.7 24.06,324.98 C28.61,325.11 32.49,327.73 33.58,332.85 L33.58,332.85 L33.58,332.85 L33.58,332.85 Z",
  53: "M24.05,324.98 C19.26,324.84 13.73,327.46 9.88,332.85 L9.88,332.85 C8.59,337.83 5.94,344.71 5.82,346.6 C5.57,350.85 7.57,351.35 6.57,355.85 C6.57,355.85 21.57,334.35 38.57,353.85 C38.57,348.35 34.57,340.85 33.57,332.85 C32.49,327.73 28.61,325.11 24.05,324.98 L24.05,324.98 L24.05,324.98 L24.05,324.98 L24.05,324.98 Z",
  52: "M47.23,4.02 L47.42,4.29 C47.42,4.29 67.48,68.09 48.92,171.01 C53.75,158.34 61.59,146.34 65.59,93.84 C68.93,49.98 63.9,22.53 61.86,9.14 C59.03,-1.18 54.4,-1.74 47.23,4.02 L47.23,4.02 L47.23,4.02 L47.23,4.02 Z",
  46: "M22.48,45.48 C23.14,36.49 23.49,30.39 23.49,30.39 L23.34,29.88 C20.4,33.32 17.26,36.96 13.92,40.76 C9.14,46.2 5.89,51.28 0.57,53.36 C0.56,53.56 -0.09,75.59 0.49,97.53 C-0.19,75.94 20.52,61.6 22.48,45.48 L22.48,45.48 L22.48,45.48 L22.48,45.48 Z",
  45: "M22.48,45.48 C21.45,59.49 19.65,80.51 17.22,96.35 C13.24,122.35 10.57,149.02 21.72,168.24 C26.23,165.76 31.1,161.78 36.23,162.36 L36.24,162.3 C47.44,102.33 33.57,60.35 23.49,30.39 C23.5,30.39 23.15,36.49 22.48,45.48 L22.48,45.48 L22.48,45.48 L22.48,45.48 Z",
  43: "M21.73,168.24 C10.58,149.02 13.25,122.35 17.23,96.35 C19.66,80.51 21.45,59.49 22.49,45.48 C20.53,61.6 -0.19,75.95 0.51,97.53 C0.81,108.61 1.42,119.66 2.59,127.86 C6.09,152.36 10.09,166.36 10.09,166.36 C13.61,171.56 17.52,170.55 21.73,168.24 L21.73,168.24 L21.73,168.24 L21.73,168.24 Z",
  68: "M43.91,130.16 C43.91,130.16 45.91,185.01 68.24,220.01 C65.91,206.34 62.24,195.34 61.91,178.01 C61.58,160.68 62.58,142.01 51.58,121.34 C49.91,126.06 47.18,128.78 43.91,130.16 L43.91,130.16 L43.91,130.16 L43.91,130.16 Z",
  67: "M25.35,61.31 C32.31,64.74 39.39,69.88 43.9,74.65 C43.69,63.08 49.12,34.85 37.57,18.68 C25.9,2.35 1.24,0.35 1.24,0.35 C-2.06,11.41 2.7,24.36 6.87,36.88 L6.87,36.88 L6.87,36.88 C9.24,43.99 11.42,50.95 11.83,57.35 C15.67,57.35 20.48,58.91 25.35,61.31 L25.35,61.31 L25.35,61.31 L25.35,61.31 Z",
  65: "M25.35,61.31 C25.45,65.15 26.34,91.22 31.58,112.57 L31.6,112.66 C37.51,112.09 44.42,114 51.58,121.34 C54.25,104.34 44.25,86.34 43.91,75.01 L43.91,74.64 C39.39,69.88 32.32,64.74 25.35,61.31 L25.35,61.31 L25.35,61.31 L25.35,61.31 Z",
  51: "M13.58,122.68 C14.25,142.01 22.91,167.68 34.91,194.68 C46.91,221.68 47.58,225.01 47.58,225.01 C42.84,192.14 30.58,141.68 25.34,128.68 C18.74,126.14 13.58,122.68 13.58,122.68 L13.58,122.68 L13.58,122.68 L13.58,122.68 Z",
  50: "M48.54,257.09 L48.54,257.09 C51.31,271.17 53.88,278.07 54.74,281.35 C55.57,284.52 59.91,286.18 60.24,281.35 C60.91,284.02 61.95,286.46 62.74,289.52 C63.91,294.02 70.24,294.02 70.24,289.35 C71.74,294.35 78.91,295.18 77.57,283.02 C80.24,287.85 83.99,286.85 84.24,280.69 C84.91,264.02 83.24,248.69 83.24,247.02 C83.25,247.02 76.17,263.83 48.54,257.09 L48.54,257.09 L48.54,257.09 L48.54,257.09 Z",
  49: "M68.25,220.02 C45.92,185.02 43.92,130.17 43.92,130.17 C38.36,132.52 31.28,130.98 25.35,128.69 C30.59,141.7 42.85,192.15 47.59,225.02 C49.5,213.06 58.64,209.91 68.25,220.02 L68.25,220.02 L68.25,220.02 L68.25,220.02 Z",
  48: "M68.25,220.02 C68.25,220.02 62.42,229.69 47.58,225.02 C47.58,225.02 43.58,227.69 46.91,248.02 C47.45,251.34 48,254.35 48.54,257.09 L48.54,257.09 C76.17,263.83 83.24,247.02 83.24,247.02 C81.58,246.02 64.91,248.21 68.25,220.02 L68.25,220.02 L68.25,220.02 L68.25,220.02 Z",
  47: "M91.25,237.68 C86.25,231.01 78.25,219.68 68.25,220.01 C64.92,248.2 81.58,246.01 83.25,247.01 C83.25,245.34 87.92,247.34 90.58,249.68 C93.24,252.02 102.58,255.35 103.91,248.01 C99.58,246.68 96.25,244.35 91.25,237.68 L91.25,237.68 L91.25,237.68 L91.25,237.68 Z",
  44: "M31.6,112.67 L31.58,112.58 C26.34,91.22 25.46,65.16 25.35,61.32 C20.48,58.92 15.66,57.36 11.83,57.36 C11.83,57.36 12.86,62.04 10.3,71.54 C10.3,71.54 7.77,77.81 4.5,82.56 L6.4,89.12 L6.4,89.12 C7.14,98.11 8.98,111.53 13.57,122.69 C13.58,122.68 21,113.69 31.6,112.67 L31.6,112.67 L31.6,112.67 L31.6,112.67 Z",
  42: "M31.6,112.67 C21,113.69 13.58,122.69 13.58,122.69 C13.58,122.69 18.74,126.15 25.34,128.69 L25.34,128.69 L25.34,128.69 C31.27,130.97 38.35,132.52 43.91,130.17 L43.91,130.17 L43.91,130.17 C47.17,128.79 49.91,126.07 51.58,121.36 C44.42,114.01 37.51,112.1 31.6,112.67 L31.6,112.67 L31.6,112.67 L31.6,112.67 Z",
  41: "M68.25,220.02 C58.65,209.92 49.51,213.06 47.58,225.02 C62.41,229.68 68.25,220.02 68.25,220.02 L68.25,220.02 L68.25,220.02 L68.25,220.02 Z",
  29: "M43.11,324.98 C42.69,314.01 39.31,214.1 56.08,198.35 C56.08,198.35 65.08,219.6 64.58,241.35 C64.06,264.12 56.58,324.85 56.58,328.85 C56.58,329.83 56.86,331.24 57.27,332.85 L57.27,332.85 C53.43,327.46 47.9,324.84 43.11,324.98 L43.11,324.98 L43.11,324.98 L43.11,324.98 Z",
  28: "M63.21,373.28 L63.21,373.28 C63.41,378.52 62.64,382.84 59.57,382.84 L11.07,382.84 C6.57,382.84 6.57,376.84 11.57,373.34 C12.64,372.59 13.82,371.49 15.06,370.19 L15.07,370.19 C15.08,370.2 38.02,354.72 63.21,373.28 L63.21,373.28 L63.21,373.28 L63.21,373.28 Z",
  26: "M60.58,355.85 C61.14,358.38 62.96,366.58 63.22,373.29 L63.22,373.29 C38.02,354.73 15.08,370.21 15.08,370.21 L15.07,370.21 C19.65,365.42 25.04,357.79 28.58,353.86 C45.58,334.35 60.58,355.85 60.58,355.85 L60.58,355.85 L60.58,355.85 L60.58,355.85 Z",
  25: "M41.08,203.68 C49.94,203.68 53.23,201.01 56.08,198.35 C39.31,214.1 42.68,314.01 43.11,324.98 L43.11,324.98 C42.64,314.7 38.18,226.62 20.59,188.35 C20.58,188.35 30.79,203.68 41.08,203.68 L41.08,203.68 L41.08,203.68 L41.08,203.68 Z",
  24: "M19.75,4.29 L19.94,4.02 C25.96,8.86 33.77,18.15 43.82,29.87 L43.67,30.38 C33.59,60.34 19.72,102.33 30.92,162.29 L30.93,162.35 C27.08,162.79 23.08,165.79 18.96,173.92 L18.96,173.92 C18.74,172.67 18.5,171.64 18.26,171.01 C-0.32,68.09 19.75,4.29 19.75,4.29 L19.75,4.29 L19.75,4.29 L19.75,4.29 Z",
  23: "M30.92,162.36 C36.05,161.78 40.92,165.76 45.43,168.24 L45.43,168.24 L45.43,168.24 C49.64,170.55 53.54,171.56 57.08,166.35 C57.08,166.35 58.58,173.35 58.08,183.85 C57.58,194.35 56.08,198.35 56.08,198.35 C53.23,201.02 49.94,203.68 41.08,203.68 C30.79,203.68 20.58,188.35 20.58,188.35 C20.58,188.35 19.88,179.19 18.95,173.93 L18.95,173.93 C23.06,165.8 27.07,162.8 30.92,162.36 L30.92,162.36 L30.92,162.36 L30.92,162.36 Z",
  22: "M33.58,332.85 C34.58,324.85 15.5,252.51 14.91,234.02 C14.24,212.85 20.58,188.35 20.58,188.35 C38.18,226.61 42.64,314.7 43.1,324.98 C38.55,325.11 34.67,327.73 33.58,332.85 L33.58,332.85 L33.58,332.85 L33.58,332.85 Z",
  21: "M43.11,324.98 C47.9,324.84 53.43,327.46 57.28,332.85 L57.28,332.85 C58.57,337.83 61.22,344.71 61.34,346.6 C61.59,350.85 59.59,351.35 60.59,355.85 C60.59,355.85 45.59,334.35 28.59,353.85 C28.59,348.35 32.59,340.85 33.59,332.85 C34.67,327.73 38.55,325.11 43.11,324.98 L43.11,324.98 L43.11,324.98 L43.11,324.98 L43.11,324.98 Z",
  20: "M19.93,4.02 L19.74,4.29 C19.74,4.29 -0.32,68.09 18.24,171.01 C13.41,158.34 5.57,146.34 1.57,93.84 C-1.77,49.98 3.26,22.53 5.3,9.14 C8.13,-1.18 12.76,-1.74 19.93,4.02 L19.93,4.02 L19.93,4.02 L19.93,4.02 Z",
  14: "M44.67,45.48 C44.01,36.49 43.66,30.39 43.66,30.39 L43.81,29.88 C46.75,33.32 49.89,36.96 53.23,40.76 C58.01,46.2 61.26,51.28 66.58,53.36 C66.59,53.56 67.24,75.59 66.66,97.53 C67.35,75.94 46.64,61.6 44.67,45.48 L44.67,45.48 L44.67,45.48 L44.67,45.48 Z",
  13: "M44.67,45.48 C45.7,59.49 47.5,80.51 49.93,96.35 C53.91,122.35 56.58,149.02 45.43,168.24 C40.92,165.76 36.05,161.78 30.92,162.36 L30.91,162.3 C19.71,102.33 33.58,60.35 43.66,30.39 C43.66,30.39 44.01,36.49 44.67,45.48 L44.67,45.48 L44.67,45.48 L44.67,45.48 Z",
  11: "M45.43,168.24 C56.58,149.02 53.91,122.35 49.93,96.35 C47.5,80.51 45.71,59.49 44.67,45.48 C46.63,61.6 67.35,75.95 66.65,97.53 C66.35,108.61 65.74,119.66 64.57,127.86 C61.07,152.36 57.07,166.36 57.07,166.36 C53.55,171.56 49.64,170.55 45.43,168.24 L45.43,168.24 L45.43,168.24 L45.43,168.24 Z",
  36: "M60.25,130.16 C60.25,130.16 58.25,185.01 35.92,220.01 C38.25,206.34 41.92,195.34 42.25,178.01 C42.58,160.68 41.58,142.01 52.58,121.34 C54.25,126.06 56.98,128.78 60.25,130.16 L60.25,130.16 L60.25,130.16 L60.25,130.16 Z",
  35: "M78.8,61.31 C71.84,64.74 64.76,69.88 60.25,74.65 C60.46,63.08 55.03,34.85 66.58,18.68 C78.25,2.35 102.91,0.35 102.91,0.35 C106.21,11.41 101.45,24.36 97.28,36.88 L97.28,36.88 L97.28,36.88 C94.91,43.99 92.73,50.95 92.32,57.35 C88.49,57.35 83.68,58.91 78.8,61.31 L78.8,61.31 L78.8,61.31 L78.8,61.31 Z",
  33: "M78.8,61.31 C78.7,65.15 77.81,91.22 72.57,112.57 L72.55,112.66 C66.64,112.09 59.73,114 52.57,121.34 C49.9,104.34 59.9,86.34 60.24,75.01 L60.24,74.64 C64.77,69.88 71.84,64.74 78.8,61.31 L78.8,61.31 L78.8,61.31 L78.8,61.31 Z",
  19: "M90.58,122.68 C89.91,142.01 81.25,167.68 69.25,194.68 C57.25,221.68 56.58,225.01 56.58,225.01 C61.32,192.14 73.58,141.68 78.82,128.68 C85.42,126.14 90.58,122.68 90.58,122.68 L90.58,122.68 L90.58,122.68 L90.58,122.68 Z",
  18: "M55.62,257.09 L55.62,257.09 C52.85,271.17 50.28,278.07 49.42,281.35 C48.59,284.52 44.25,286.18 43.92,281.35 C43.25,284.02 42.21,286.46 41.42,289.52 C40.25,294.02 33.92,294.02 33.92,289.35 C32.42,294.35 25.25,295.18 26.59,283.02 C23.92,287.85 20.17,286.85 19.92,280.69 C19.25,264.02 20.92,248.69 20.92,247.02 C20.91,247.02 27.98,263.83 55.62,257.09 L55.62,257.09 L55.62,257.09 L55.62,257.09 Z",
  17: "M35.91,220.02 C58.24,185.02 60.24,130.17 60.24,130.17 C65.8,132.52 72.88,130.98 78.81,128.69 C73.57,141.7 61.31,192.15 56.57,225.02 C54.66,213.06 45.52,209.91 35.91,220.02 L35.91,220.02 L35.91,220.02 L35.91,220.02 Z",
  16: "M35.91,220.02 C35.91,220.02 41.74,229.69 56.58,225.02 C56.58,225.02 60.58,227.69 57.25,248.02 C56.71,251.34 56.16,254.35 55.62,257.09 L55.62,257.09 C27.99,263.83 20.92,247.02 20.92,247.02 C22.58,246.02 39.25,248.21 35.91,220.02 L35.91,220.02 L35.91,220.02 L35.91,220.02 Z",
  15: "M12.91,237.68 C17.91,231.01 25.91,219.68 35.91,220.01 C39.24,248.2 22.58,246.01 20.91,247.01 C20.91,245.34 16.24,247.34 13.58,249.68 C10.92,252.02 1.58,255.35 0.25,248.01 C4.58,246.68 7.91,244.35 12.91,237.68 L12.91,237.68 L12.91,237.68 L12.91,237.68 Z",
  12: "M72.56,112.67 L72.58,112.58 C77.82,91.22 78.7,65.16 78.81,61.32 C83.68,58.92 88.5,57.36 92.33,57.36 C92.33,57.36 91.3,62.04 93.86,71.54 C93.86,71.54 96.39,77.81 99.66,82.56 L97.76,89.12 L97.76,89.12 C97.02,98.11 95.18,111.53 90.59,122.69 C90.58,122.68 83.16,113.69 72.56,112.67 L72.56,112.67 L72.56,112.67 L72.56,112.67 Z",
  10: "M72.56,112.67 C83.16,113.69 90.58,122.69 90.58,122.69 C90.58,122.69 85.42,126.15 78.82,128.69 L78.82,128.69 L78.82,128.69 C72.89,130.97 65.81,132.52 60.25,130.17 L60.25,130.17 L60.25,130.17 C56.99,128.79 54.25,126.07 52.58,121.36 C59.74,114.01 66.65,112.1 72.56,112.67 L72.56,112.67 L72.56,112.67 L72.56,112.67 Z",
  9: "M35.91,220.02 C45.51,209.92 54.65,213.06 56.58,225.02 C41.75,229.68 35.91,220.02 35.91,220.02 L35.91,220.02 L35.91,220.02 L35.91,220.02 Z",
  66: "M72.94,151.01 L72.96,183.01 C99.28,183 126.23,170.35 140.88,174.88 C136.71,162.36 131.96,149.4 135.25,138.35 C135.25,138.35 112.92,145.68 104.25,147.68 C95.69,149.66 73.48,150.98 72.94,151.01 L72.94,151.01 L72.94,151.01 L72.94,151.01 Z",
  64: "M72.94,151.01 L72.94,151.01 C73.48,150.98 95.69,149.65 104.25,147.68 C112.92,145.68 135.25,138.35 135.25,138.35 C135.25,138.35 118.25,129.02 109.58,123.02 C100.91,117.02 97.91,116.02 96.91,114.02 C95.91,112.02 93.58,97.58 93.24,95.58 L93.24,95.58 C90.58,123.26 78.81,151.41 72.94,151.01 L72.94,151.01 L72.94,151.01 L72.94,151.01 Z",
  63: "M138.51,220.55 C141.78,215.8 144.31,209.53 144.31,209.53 C146.86,200.03 145.84,195.35 145.84,195.35 C145.43,188.95 143.25,181.99 140.88,174.88 C126.23,170.35 99.28,183 72.96,183.01 L72.99,228.81 C101.3,229.83 128.76,229.83 135.25,224.34 C136.37,223.4 137.48,222.05 138.51,220.55 L138.51,220.55 L138.51,220.55 L138.51,220.55 Z",
  62: "M72.94,151.01 C78.81,151.4 90.58,123.26 93.25,95.58 L93.25,95.58 C87.85,100.06 80.02,104.52 72.92,104.52 L72.94,151.01 L72.94,151.01 L72.94,151.01 L72.94,151.01 Z",
  59: "M104.08,248.85 C118.62,259.59 129.58,275.85 129.58,275.85 C127.58,263.85 138.16,250.61 140.41,227.11 L138.51,220.55 C137.48,222.05 136.37,223.4 135.25,224.35 C128.76,229.84 101.29,229.84 72.99,228.82 L72.99,234.92 C75.3,235.61 93.93,241.35 104.08,248.85 L104.08,248.85 L104.08,248.85 L104.08,248.85 Z",
  40: "M72.91,0.24 C72.91,0.24 56.17,0.32 45.84,10.65 C35.51,20.98 36.91,33.84 38.24,46.51 L72.91,46.51 L72.91,0.24 L72.91,0.24 L72.91,0.24 L72.91,0.24 Z",
  39: "M107.58,46.52 C108.91,33.85 110.32,21 99.98,10.66 C89.65,0.32 72.91,0.24 72.91,0.24 L72.91,46.51 L107.58,46.51 L107.58,46.52 L107.58,46.52 L107.58,46.52 Z",
  38: "M72.91,104.52 C80.01,104.52 87.85,100.07 93.24,95.58 C93.48,95.38 93.71,95.19 93.93,94.99 C93.94,94.98 93.95,94.97 93.97,94.96 C95.09,93.99 96.09,93.02 96.94,92.11 C96.97,92.08 97,92.04 97.03,92.01 C97.17,91.86 97.3,91.71 97.43,91.57 C97.48,91.52 97.53,91.46 97.57,91.41 C97.69,91.27 97.8,91.14 97.91,91.01 C97.96,90.95 98.01,90.89 98.06,90.82 C98.16,90.7 98.26,90.57 98.35,90.45 C98.4,90.38 98.45,90.32 98.5,90.25 C98.64,90.07 98.76,89.89 98.88,89.72 C98.95,89.62 99.02,89.52 99.08,89.43 C99.13,89.35 99.17,89.28 99.21,89.21 C99.27,89.12 99.32,89.02 99.37,88.93 C99.41,88.86 99.44,88.8 99.48,88.73 C99.53,88.64 99.57,88.55 99.61,88.46 C99.64,88.4 99.66,88.34 99.69,88.29 C99.73,88.2 99.76,88.11 99.79,88.02 C99.8,87.98 99.82,87.93 99.83,87.89 C99.87,87.76 99.89,87.64 99.91,87.53 C100.41,83.23 104.58,68.53 104.58,68.53 C104.58,68.53 108.25,67.53 109.91,62.53 C111.58,57.53 115.58,45.86 107.58,46.53 L72.91,46.53 L72.91,104.52 L72.91,104.52 L72.91,104.52 L72.91,104.52 Z",
  37: "M72.91,46.52 L38.24,46.52 C30.24,45.85 34.24,57.52 35.91,62.52 C37.58,67.52 41.24,68.52 41.24,68.52 C41.24,68.52 45.41,83.22 45.91,87.52 C45.92,87.63 45.95,87.75 45.99,87.88 C46,87.92 46.02,87.97 46.03,88.01 C46.06,88.1 46.09,88.19 46.13,88.28 C46.15,88.34 46.18,88.39 46.21,88.45 C46.25,88.54 46.29,88.63 46.34,88.72 C46.37,88.79 46.41,88.85 46.45,88.92 C46.5,89.01 46.56,89.11 46.61,89.2 C46.65,89.27 46.7,89.34 46.74,89.42 C46.8,89.51 46.87,89.61 46.93,89.71 C47.05,89.89 47.18,90.07 47.32,90.26 C47.37,90.32 47.41,90.38 47.46,90.44 C47.55,90.56 47.65,90.69 47.76,90.81 C47.81,90.87 47.86,90.93 47.91,90.99 C48.02,91.12 48.13,91.26 48.25,91.39 C48.3,91.44 48.34,91.5 48.39,91.55 C48.52,91.7 48.65,91.85 48.79,92 C48.82,92.03 48.85,92.07 48.88,92.1 C49.73,93.01 50.72,93.98 51.85,94.95 C51.86,94.96 51.87,94.97 51.89,94.98 C52.12,95.18 52.35,95.37 52.58,95.57 C57.98,100.05 65.81,104.51 72.91,104.51 L72.91,46.52 L72.91,46.52 L72.91,46.52 L72.91,46.52 Z",
  34: "M73.22,151.01 L73.2,183.01 C46.88,183 19.93,170.35 5.28,174.88 C9.45,162.36 14.2,149.4 10.91,138.35 C10.91,138.35 33.24,145.68 41.91,147.68 C50.47,149.66 72.68,150.98 73.22,151.01 L73.22,151.01 L73.22,151.01 L73.22,151.01 Z",
  32: "M73.22,151.01 L73.22,151.01 C72.68,150.98 50.47,149.65 41.91,147.68 C33.24,145.68 10.91,138.35 10.91,138.35 C10.91,138.35 27.91,129.02 36.58,123.02 C45.25,117.02 48.25,116.02 49.25,114.02 C50.25,112.02 52.58,97.58 52.92,95.58 L52.92,95.58 C55.58,123.26 67.35,151.41 73.22,151.01 L73.22,151.01 L73.22,151.01 L73.22,151.01 Z",
  31: "M7.65,220.55 C4.38,215.8 1.85,209.53 1.85,209.53 C-0.7,200.03 0.32,195.35 0.32,195.35 C0.73,188.95 2.91,181.99 5.28,174.88 C19.93,170.35 46.88,183 73.2,183.01 L73.17,228.81 C44.86,229.83 17.4,229.83 10.91,224.34 C9.79,223.4 8.68,222.05 7.65,220.55 L7.65,220.55 L7.65,220.55 L7.65,220.55 Z",
  30: "M73.22,151.01 C67.35,151.4 55.58,123.26 52.91,95.58 L52.91,95.58 C58.31,100.06 66.14,104.52 73.24,104.52 L73.22,151.01 L73.22,151.01 L73.22,151.01 L73.22,151.01 Z",
  27: "M42.08,248.85 C27.54,259.59 16.58,275.85 16.58,275.85 C18.58,263.85 8,250.61 5.75,227.11 L7.65,220.55 C8.68,222.05 9.79,223.4 10.91,224.35 C17.4,229.84 44.87,229.84 73.17,228.82 L73.17,234.92 C70.86,235.61 52.23,241.35 42.08,248.85 L42.08,248.85 L42.08,248.85 L42.08,248.85 Z",
  8: "M133.83,316.11 C131.88,310.27 130.07,304.84 130.07,304.84 C130.07,304.84 131.06,284.77 129.57,275.84 C129.57,275.84 118.61,259.58 104.07,248.84 C106.92,269.92 106.33,302.99 102.66,333.26 C102.68,333.26 102.69,333.25 102.71,333.25 C102.71,333.26 123.17,328.59 133.83,316.11 L133.83,316.11 L133.83,316.11 L133.83,316.11 Z",
  7: "M136.66,324.92 C136.48,324.19 136.3,323.48 136.08,322.85 C135.38,320.74 134.6,318.4 133.84,316.12 C123.18,328.6 102.72,333.26 102.72,333.26 C102.7,333.26 102.69,333.27 102.67,333.27 C101.84,340.08 100.86,346.76 99.73,353.12 C96.15,373.23 91.06,390.23 84.58,398.81 C82.78,401.12 80.74,403.73 77.9,405.19 L77.86,405.35 L78.58,405.35 L78.58,405.36 C83.9,403.28 87.15,398.2 91.93,392.76 C95.27,388.96 98.4,385.31 101.35,381.88 C111.39,370.16 119.21,360.86 125.23,356.03 C132.4,350.27 137.03,350.83 139.85,361.16 C139.45,358.52 139.16,356.42 139.08,354.86 C138.62,346.15 138.58,332.86 136.66,324.92 L136.66,324.92 L136.66,324.92 L136.66,324.92 Z",
  6: "M42.08,248.85 C27.54,259.59 16.58,275.85 16.58,275.85 C15.09,284.78 16.08,304.85 16.08,304.85 C16.08,304.85 14.78,308.76 13.19,313.51 C20.61,325.19 43.15,333.26 43.15,333.26 C43.26,333.28 43.38,333.31 43.49,333.33 C39.82,303.04 39.23,269.95 42.08,248.85 L42.08,248.85 L42.08,248.85 L42.08,248.85 Z",
  5: "M13.19,313.5 C12.18,316.54 11.05,319.92 10.08,322.84 C9.87,323.47 9.68,324.18 9.5,324.91 C7.58,332.85 7.53,346.14 7.08,354.84 C7,356.4 6.71,358.51 6.31,361.14 C9.13,350.81 13.76,350.25 20.93,356.01 C26.95,360.85 34.76,370.14 44.81,381.86 C47.75,385.3 50.89,388.94 54.23,392.74 C59.01,398.18 62.26,403.26 67.58,405.34 L67.58,405.33 L68.3,405.33 L68.26,405.17 C65.42,403.7 63.39,401.1 61.58,398.79 C55.11,390.22 50.01,373.21 46.43,353.1 C45.3,346.76 44.32,340.1 43.5,333.31 C43.39,333.29 43.27,333.27 43.16,333.24 C43.16,333.26 20.62,325.19 13.19,313.5 L13.19,313.5 L13.19,313.5 L13.19,313.5 Z",
  4: "M42.08,248.85 C39.23,269.95 39.83,303.04 43.5,333.33 C53.37,335.46 63.25,336.52 73.12,336.5 L73.18,234.92 C70.86,235.61 52.23,241.35 42.08,248.85 L42.08,248.85 L42.08,248.85 L42.08,248.85 Z",
  3: "M43.5,333.33 C44.32,340.12 45.3,346.78 46.43,353.12 C50.01,373.23 55.1,390.23 61.58,398.81 C64.09,402.02 67.03,405.81 71.99,406.3 C72.35,406.33 72.7,406.36 73.08,406.36 L73.12,336.51 C63.25,336.51 53.37,335.46 43.5,333.33 L43.5,333.33 L43.5,333.33 L43.5,333.33 Z",
  2: "M73.08,406.35 C73.46,406.35 73.82,406.32 74.17,406.29 C79.12,405.8 82.07,402.01 84.58,398.8 C91.05,390.23 96.15,373.22 99.73,353.11 C100.86,346.75 101.84,340.07 102.67,333.26 C92.79,335.41 82.92,336.49 73.04,336.49 L73.08,406.35 L73.08,406.35 L73.08,406.35 L73.08,406.35 Z",
  1: "M104.08,248.85 C93.93,241.36 75.3,235.62 72.99,234.92 L73.05,336.5 C82.93,336.49 92.8,335.41 102.68,333.27 C106.33,303 106.93,269.93 104.08,248.85 L104.08,248.85 L104.08,248.85 L104.08,248.85 Z",
  85: "M30.36,135.55 C29.65,174.22 10.32,185.55 10.32,185.55 L9.98,185.64 C10.62,192.73 11.67,195.55 11.67,195.55 C11.67,195.55 28.32,185.55 27.65,208.88 C28.98,180.88 46.98,187.55 46.98,187.55 L47.78,188.12 C47.4,186.48 47.16,185.55 47.16,185.55 C47.16,185.55 47.25,184.37 47.41,182.62 C44.94,173.02 29.14,172.77 30.36,135.55 L30.36,135.55 L30.36,135.55 Z",
  86: "M48.08,116.89 C47.91,95.71 47.76,51.65 47.75,48.41 C43.6,51.56 37.23,53.37 30.36,54.21 C30.38,59.16 30.66,118.93 30.36,135.55 C29.14,172.77 44.94,173.01 47.42,182.62 C47.81,178.32 48.61,170.56 49.5,168.22 C50.83,164.73 52.39,161.29 54.04,157.04 L54.04,157.04 C54.04,157.04 48.26,138.89 48.08,116.89 L48.08,116.89 L48.08,116.89 Z",
  87: "M27.65,208.89 C26.32,236.89 24.98,252.89 25.65,264.22 C26.32,275.55 37.65,290.89 43.65,279.55 L44.48,279 C48.58,259.97 52.55,240.14 52.83,231.21 C53.37,214.15 49.36,194.93 47.78,188.11 L46.98,187.54 C46.98,187.55 28.98,180.89 27.65,208.89 L27.65,208.89 L27.65,208.89 Z",
  94: "M47.75,48.41 C47.76,51.65 47.92,95.71 48.08,116.89 C48.25,138.89 54.03,157.04 54.03,157.04 L54.03,157.04 C58.35,145.87 63.26,129.09 66.16,91.05 C70.16,38.55 62.16,9.55 61.66,0.05 C54.97,11.55 52.31,41.88 52.31,41.88 C51.67,44.51 50.05,46.67 47.75,48.41 L47.75,48.41 L47.75,48.41 Z",
  95: "M18.7,54.78 C11.65,54.65 5.07,53.71 0.76,52.35 L0.76,52.35 C0.7,55.11 -0.24,101.29 3.16,125.05 C6.66,149.55 10.66,163.55 10.66,163.55 C10.66,163.55 10.63,163.72 10.57,164 L11.32,164.11 C13.31,110.51 11.63,61.4 18.7,54.78 L18.7,54.78 L18.7,54.78 Z",
  97: "M25.65,264.22 C24.98,252.89 26.32,236.89 27.65,208.89 C28.32,185.56 11.67,195.56 11.67,195.56 C11.67,195.56 2.67,216.81 3.17,238.56 C3.34,246.03 4.26,257.6 5.42,269.91 L6.32,270.89 C11.65,293.55 24.98,280.22 25.65,264.22 L25.65,264.22 L25.65,264.22 Z",
  98: "M30.36,135.55 C30.66,118.93 30.39,59.16 30.36,54.21 C26.53,54.68 22.55,54.85 18.71,54.77 C11.63,61.4 13.31,110.51 11.33,164.12 L10.58,164.01 C10.27,165.69 9.24,172.09 9.67,181.06 C9.75,182.76 9.86,184.28 9.98,185.65 L10.32,185.56 C10.32,185.55 29.65,174.22 30.36,135.55 L30.36,135.55 L30.36,135.55 Z",
  100: "M25.65,264.22 C24.98,280.22 11.65,293.55 6.32,270.89 L5.42,269.91 C7.79,295.09 11.17,323.37 11.17,326.06 C11.17,330.06 7.63,330 6.07,340.97 C5.94,341.86 5.86,342.82 5.8,343.92 L5.8,343.92 C7.07,332.6 31.03,322.54 36.24,348.95 C36.44,345.54 37.02,342.54 38.23,340.56 C38.23,335.06 34.19,334.57 35.17,323.07 C35.53,318.91 40.09,299.43 44.49,279.02 L43.66,279.57 C37.65,290.89 26.32,275.55 25.65,264.22 L25.65,264.22 L25.65,264.22 Z",
  102: "M15.77,411.31 L15.77,411.31 C15.77,411.31 15.7,430.09 21.74,434.87 C32.38,430.73 45.2,422.76 46.8,416.55 C48.55,409.77 47.54,403.51 45.67,397.28 L45.67,397.28 C38.1,404.39 25.89,408.63 15.77,411.31 L15.77,411.31 L15.77,411.31 Z",
  103: "M15.77,411.31 C25.89,408.63 38.1,404.4 45.68,397.29 L45.68,397.29 C43.34,389.47 39.64,381.71 38.32,373.06 C37.94,370.57 37.23,366.22 36.72,361.43 C33.01,371.92 9.07,369.92 5.2,383.63 C5.04,384.7 4.88,385.79 4.69,386.9 L4.69,386.9 L4.69,386.9 C3.14,396.07 0.66,406.46 0.65,414.89 C3.64,414.11 9.28,413.03 15.77,411.31 L15.77,411.31 L15.77,411.31 Z",
  104: "M15.77,411.31 L15.77,411.31 C9.28,413.03 3.64,414.11 0.64,414.89 C0.62,427.17 4.68,435.47 8.31,437.22 C10.54,438.3 15.8,437.18 21.74,434.87 C15.7,430.09 15.77,411.31 15.77,411.31 L15.77,411.31 L15.77,411.31 Z",
  105: "M5.8,343.91 L5.8,343.91 C5.53,349.03 5.91,357.11 5.92,375.36 C5.92,377.84 5.64,380.64 5.2,383.61 C9.07,369.9 33.01,371.9 36.72,361.41 C36.29,357.29 36.01,352.83 36.24,348.93 C31.02,322.53 7.07,332.59 5.8,343.91 L5.8,343.91 L5.8,343.91 Z",
  73: "M24.54,64.78 L24.54,64.78 C22.9,63.38 21.52,61.92 20.36,60.42 L20.1,61.02 L12.09,75.23 L12.09,75.23 C22.65,103.9 21.32,106.75 31.32,118.42 L31.32,118.43 C41.25,118.82 53.1,121.09 55.18,121.5 C55.19,121.45 55.19,121.39 55.2,121.34 C35.57,106.78 24.54,64.78 24.54,64.78 L24.54,64.78 L24.54,64.78 Z",
  76: "M24.54,64.78 C29.39,68.92 36.58,72.55 47.5,75.14 C47.2,63.75 52.81,35.18 41.17,18.89 C30.27,3.63 8.03,0.88 5.15,0.59 C-2.97,13.91 1.04,25.56 1.04,25.56 C16.81,30.19 9.86,46.86 20.37,60.42 C21.52,61.92 22.9,63.37 24.54,64.78 L24.54,64.78 L24.54,64.78 Z",
  78: "M24.54,64.78 C24.54,64.78 35.57,106.78 55.2,121.33 C57.74,104.4 47.83,86.51 47.5,75.22 L47.5,75.14 C36.58,72.55 29.39,68.92 24.54,64.78 L24.54,64.78 L24.54,64.78 L24.54,64.78 Z",
  79: "M31.32,134.14 C38.37,186.51 62.38,192.86 70.32,212.32 C68.17,201.93 65.77,192.11 65.5,178.22 C65.17,160.99 66.15,142.44 55.35,121.91 C51.83,131.16 40.84,135.75 31.32,134.14 L31.32,134.14 L31.32,134.14 Z",
  88: "M90.58,232.33 C85.75,226.33 79.35,219.97 71.83,220.23 C71.83,220.23 67.35,237.23 51.16,225.23 C51.16,225.23 47.16,227.9 50.49,248.23 C50.71,249.58 50.93,250.87 51.15,252.12 L51.49,252.14 C51.49,252.14 70.1,260.45 86.83,247.48 C86.83,247.38 86.82,247.3 86.82,247.24 C86.82,246.81 87.13,246.63 87.64,246.63 C89.02,244.79 92.67,239.05 90.58,232.33 L90.58,232.33 L90.58,232.33 Z",
  89: "M94.83,237.89 C93.59,236.24 92.17,234.3 90.58,232.33 C92.67,239.06 89.01,244.8 87.65,246.61 C89.11,246.61 92.18,248.16 94.16,249.89 C96.83,252.22 106.16,255.56 107.49,248.22 C103.17,246.89 99.83,244.55 94.83,237.89 L94.83,237.89 L94.83,237.89 Z",
  90: "M51.5,252.13 L51.16,252.11 C54.28,269.73 57.37,277.89 58.33,281.55 C59.16,284.72 63.5,286.38 63.83,281.55 C64.5,284.22 65.54,286.66 66.33,289.72 C67.5,294.22 73.83,294.22 73.83,289.55 C75.33,294.55 82.5,295.38 81.16,283.22 C83.83,288.05 87.58,287.05 87.83,280.89 C88.47,264.84 86.95,250.03 86.84,247.47 C70.11,260.44 51.5,252.13 51.5,252.13 L51.5,252.13 L51.5,252.13 Z",
  92: "M31.32,118.42 L31.32,118.42 C21.32,106.75 22.65,103.89 12.09,75.23 L12.09,75.23 L9.83,79.23 L10.05,89.99 C10.83,98.97 12.69,112.01 17.16,122.9 C16.81,118.98 23.46,118.11 31.32,118.42 L31.32,118.42 L31.32,118.42 Z",
  93: "M71.83,220.22 C71.37,217.51 70.85,214.9 70.32,212.32 C62.38,192.85 38.37,186.51 31.32,134.14 C25.19,133.1 19.67,129.5 17.17,122.89 C17.84,142.22 26.5,167.89 38.5,194.89 C50.5,221.89 51.17,225.22 51.17,225.22 C51.17,225.22 52.69,212.55 71.83,220.22 L71.83,220.22 L71.83,220.22 Z",
  106: "M71.83,220.22 C52.68,212.55 51.16,225.22 51.16,225.22 C67.35,237.22 71.83,220.22 71.83,220.22 L71.83,220.22 L71.83,220.22 Z",
  107: "M31.32,118.38 C23.46,118.07 16.81,118.94 17.17,122.85 C19.67,129.46 25.19,133.06 31.32,134.1 C40.84,135.71 51.82,131.12 55.35,121.88 C55.29,121.76 55.23,121.64 55.16,121.52 C55.16,121.5 55.17,121.48 55.17,121.46 C53.1,121.04 41.25,118.77 31.32,118.38 L31.32,118.38 L31.32,118.38 Z",
  74: "M145.83,217.22 L148.09,213.22 L156.1,199.01 L156.36,198.41 C145.85,184.85 152.8,168.18 137.03,163.55 C137.03,163.55 130.72,161.98 121.95,162.63 C105.86,168.59 83.88,253.75 113.29,239.82 L113.29,239.82 C126.2,233.14 141.14,229.5 145.83,217.22 L145.83,217.22 L145.83,217.22 Z",
  75: "M121.95,162.63 C115.86,163.08 108.58,164.61 101.38,168.47 C100.87,170.83 100.15,173.29 99.16,175.88 C82.14,220.42 88.28,256.92 92.29,272.09 L92.29,272.09 C92.29,253.81 102.01,245.65 113.28,239.81 L113.28,239.81 C83.88,253.76 105.86,168.6 121.95,162.63 L121.95,162.63 L121.95,162.63 Z",
  77: "M121.95,162.63 C130.73,161.98 137.03,163.55 137.03,163.55 C137.03,163.55 133.02,151.9 141.14,138.58 C140.94,138.56 140.82,138.55 140.82,138.55 C140.82,138.55 123.82,129.22 115.15,123.22 C106.48,117.22 103.48,116.22 102.48,114.22 C101.48,112.22 98.82,95.79 98.82,95.79 C98.91,95.71 99,95.63 99.1,95.56 C98.55,93.38 93.83,76.64 79.58,75.93 C87.91,76.56 86.73,86.1 89.3,110.56 C91.71,133.42 106.05,146.62 101.37,168.48 C108.58,164.61 115.86,163.09 121.95,162.63 L121.95,162.63 L121.95,162.63 Z",
  80: "M113.18,46.57 C114.51,33.95 115.86,21.15 105.57,10.86 C95.24,0.53 78.5,0.45 78.5,0.45 L78.5,39.22 L78.67,39.22 C96.43,39.22 110.92,45.53 113.18,46.57 L113.18,46.57 L113.18,46.57 Z",
  81: "M101.7,409.78 C105.55,409.85 109.53,409.68 113.35,409.22 C120.23,408.38 126.6,406.57 130.74,403.42 C133.04,401.68 134.66,399.52 135.3,396.9 C135.3,396.9 137.97,366.57 144.65,355.07 C144.59,353.99 144.54,352.83 144.49,351.63 L143.96,351.9 C129.29,343.9 103.81,331.18 99.15,321.18 L99.1,320.68 C87.33,329.09 78.49,341.15 78.49,345.23 C78.49,353.2 78.63,373.95 78.65,403.84 L78.65,403.81 C78.65,403.96 78.68,404.1 78.72,404.25 C78.73,404.28 78.74,404.31 78.75,404.35 C78.81,404.49 78.88,404.63 78.98,404.77 C78.99,404.78 79,404.79 79.01,404.8 C79.11,404.94 79.23,405.07 79.38,405.2 C79.4,405.22 79.42,405.24 79.45,405.27 C79.61,405.41 79.79,405.55 79.99,405.69 C80,405.7 80.02,405.71 80.03,405.71 C80.24,405.85 80.47,405.99 80.72,406.13 C80.73,406.13 80.74,406.14 80.74,406.14 C81.01,406.28 81.29,406.42 81.6,406.56 C81.61,406.56 81.61,406.56 81.62,406.57 C82.25,406.85 82.96,407.11 83.75,407.36 C88.08,408.71 94.66,409.64 101.7,409.78 L101.7,409.78 L101.7,409.78 Z",
  82: "M99.11,320.67 L99.16,321.17 C103.83,331.17 129.31,343.89 143.97,351.89 L144.5,351.62 C144.12,342.24 143.87,329.71 141.66,323.06 C129.47,306.34 112.42,311.16 99.11,320.67 L99.11,320.67 L99.11,320.67 Z",
  83: "M94.67,279.68 C94.67,279.68 95.38,281.01 95.04,283.1 L99.12,320.67 C112.43,311.16 129.47,306.34 141.67,323.06 L135.67,305.06 C135.67,305.06 136.66,284.99 135.17,276.06 C134.97,274.87 134.91,273.66 134.94,272.43 C131.57,275.21 106.45,295.15 94.67,279.68 L94.67,279.68 L94.67,279.68 Z",
  84: "M79.59,75.92 C93.84,76.63 98.56,93.37 99.11,95.55 C102.82,92.42 105.32,89.3 105.5,87.72 C106,83.42 110.17,68.72 110.17,68.72 C110.17,68.72 113.84,67.72 115.5,62.72 C117.14,57.8 121.03,46.44 113.54,46.71 L113.5,46.72 L113.48,46.71 C113.38,46.71 113.28,46.71 113.17,46.72 C113.18,46.67 113.18,46.62 113.19,46.57 C110.92,45.53 96.43,39.22 78.67,39.22 L78.5,39.22 L78.5,75.67 L78.67,75.89 C78.99,75.89 79.3,75.9 79.59,75.92 L79.59,75.92 L79.59,75.92 Z",
  91: "M113.29,239.82 C102.02,245.66 92.3,253.82 92.3,272.1 L92.3,272.1 C93.6,277 94.67,279.68 94.67,279.68 C106.46,295.15 131.57,275.21 134.94,272.42 C135.23,261.45 143.72,248.17 146.06,227.98 L145.84,217.22 C141.14,229.5 126.2,233.14 113.29,239.82 L113.29,239.82 L113.29,239.82 Z",
  96: "M99.11,320.67 L95.03,283.1 C94.5,286.34 91.45,291.42 79.31,296.28 L78.49,297.21 L78.49,345.22 C78.5,341.14 87.35,329.08 99.11,320.67 L99.11,320.67 L99.11,320.67 Z",
  99: "M101.38,168.48 C106.06,146.62 91.72,133.42 89.31,110.56 C86.74,86.1 87.92,76.56 79.59,75.93 C79.28,75.91 78.98,75.89 78.66,75.89 L78.49,75.67 L78.49,193.39 L78.66,193.39 C84.79,180.6 93.03,172.97 101.38,168.48 L101.38,168.48 L101.38,168.48 Z",
  101: "M92.3,272.1 C88.29,256.93 82.15,220.43 99.17,175.89 C100.16,173.3 100.88,170.84 101.39,168.48 C93.04,172.97 84.8,180.6 78.67,193.39 L78.5,193.39 L78.5,297.21 L79.32,296.28 C91.47,291.42 94.52,286.34 95.04,283.1 L94.67,279.68 C94.67,279.68 93.59,277 92.3,272.1 L92.3,272.1 L92.3,272.1 Z",
  113: "M11.17,217.22 L8.91,213.22 L0.9,199.01 L0.64,198.41 C11.15,184.85 4.2,168.18 19.97,163.55 C19.97,163.55 26.28,161.98 35.05,162.63 C51.14,168.59 73.12,253.75 43.71,239.82 L43.71,239.82 C30.8,233.14 15.86,229.5 11.17,217.22 L11.17,217.22 L11.17,217.22 Z",
  114: "M35.05,162.63 C41.14,163.08 48.42,164.61 55.62,168.47 C56.13,170.83 56.85,173.29 57.84,175.88 C74.86,220.42 68.72,256.92 64.71,272.09 L64.71,272.09 C64.71,253.81 54.99,245.65 43.72,239.81 L43.72,239.81 C73.12,253.76 51.14,168.6 35.05,162.63 L35.05,162.63 L35.05,162.63 Z",
  116: "M35.05,162.63 C26.27,161.98 19.97,163.55 19.97,163.55 C19.97,163.55 23.98,151.9 15.86,138.58 C16.06,138.56 16.18,138.55 16.18,138.55 C16.18,138.55 33.18,129.22 41.85,123.22 C50.52,117.22 53.52,116.22 54.52,114.22 C55.52,112.22 58.18,95.79 58.18,95.79 C58.09,95.71 58,95.63 57.9,95.56 C58.45,93.38 63.17,76.64 77.42,75.93 C69.09,76.56 70.27,86.1 67.7,110.56 C65.29,133.42 50.95,146.62 55.63,168.48 C48.42,164.61 41.14,163.09 35.05,162.63 L35.05,162.63 L35.05,162.63 Z",
  119: "M43.82,46.57 C42.49,33.95 41.14,21.15 51.43,10.86 C61.76,0.53 78.5,0.45 78.5,0.45 L78.5,39.22 L78.33,39.22 C60.57,39.22 46.08,45.53 43.82,46.57 L43.82,46.57 L43.82,46.57 Z",
  120: "M55.3,409.78 C51.45,409.85 47.47,409.68 43.65,409.22 C36.77,408.38 30.4,406.57 26.26,403.42 C23.96,401.68 22.34,399.52 21.7,396.9 C21.7,396.9 19.03,366.57 12.35,355.07 C12.41,353.99 12.46,352.83 12.51,351.63 L13.04,351.9 C27.71,343.9 53.19,331.18 57.85,321.18 L57.9,320.68 C69.67,329.09 78.51,341.15 78.51,345.23 C78.51,353.2 78.37,373.95 78.35,403.84 L78.35,403.81 C78.35,403.96 78.32,404.1 78.28,404.25 C78.27,404.28 78.26,404.31 78.25,404.35 C78.19,404.49 78.12,404.63 78.02,404.77 C78.01,404.78 78,404.79 77.99,404.8 C77.89,404.94 77.77,405.07 77.62,405.2 C77.6,405.22 77.58,405.24 77.55,405.27 C77.39,405.41 77.21,405.55 77.01,405.69 C77,405.7 76.98,405.71 76.97,405.71 C76.76,405.85 76.53,405.99 76.28,406.13 C76.27,406.13 76.26,406.14 76.26,406.14 C75.99,406.28 75.71,406.42 75.4,406.56 C75.39,406.56 75.39,406.56 75.38,406.57 C74.75,406.85 74.04,407.11 73.25,407.36 C68.92,408.71 62.34,409.64 55.3,409.78 L55.3,409.78 L55.3,409.78 Z",
  121: "M57.89,320.67 L57.84,321.17 C53.17,331.17 27.69,343.89 13.03,351.89 L12.5,351.62 C12.88,342.24 13.13,329.71 15.34,323.06 C27.53,306.34 44.58,311.16 57.89,320.67 L57.89,320.67 L57.89,320.67 Z",
  122: "M62.33,279.68 C62.33,279.68 61.62,281.01 61.96,283.1 L57.88,320.67 C44.57,311.16 27.53,306.34 15.33,323.06 L21.33,305.06 C21.33,305.06 20.34,284.99 21.83,276.06 C22.03,274.87 22.09,273.66 22.06,272.43 C25.43,275.21 50.55,295.15 62.33,279.68 L62.33,279.68 L62.33,279.68 Z",
  123: "M77.41,75.92 C63.16,76.63 58.44,93.37 57.89,95.55 C54.18,92.42 51.68,89.3 51.5,87.72 C51,83.42 46.83,68.72 46.83,68.72 C46.83,68.72 43.16,67.72 41.5,62.72 C39.86,57.8 35.97,46.44 43.46,46.71 L43.5,46.72 L43.52,46.71 C43.62,46.71 43.72,46.71 43.83,46.72 C43.82,46.67 43.82,46.62 43.81,46.57 C46.08,45.53 60.57,39.22 78.33,39.22 L78.5,39.22 L78.5,75.67 L78.33,75.89 C78.01,75.89 77.7,75.9 77.41,75.92 L77.41,75.92 L77.41,75.92 Z",
  130: "M43.71,239.82 C54.98,245.66 64.7,253.82 64.7,272.1 L64.7,272.1 C63.4,277 62.33,279.68 62.33,279.68 C50.54,295.15 25.43,275.21 22.06,272.42 C21.77,261.45 13.28,248.17 10.94,227.98 L11.16,217.22 C15.86,229.5 30.8,233.14 43.71,239.82 L43.71,239.82 L43.71,239.82 Z",
  135: "M57.89,320.67 L61.97,283.1 C62.5,286.34 65.55,291.42 77.69,296.28 L78.51,297.21 L78.51,345.22 C78.5,341.14 69.65,329.08 57.89,320.67 L57.89,320.67 L57.89,320.67 Z",
  138: "M55.62,168.48 C50.94,146.62 65.28,133.42 67.69,110.56 C70.26,86.1 69.08,76.56 77.41,75.93 C77.72,75.91 78.02,75.89 78.34,75.89 L78.51,75.67 L78.51,193.39 L78.34,193.39 C72.21,180.6 63.97,172.97 55.62,168.48 L55.62,168.48 L55.62,168.48 Z",
  140: "M64.7,272.1 C68.71,256.93 74.85,220.43 57.83,175.89 C56.84,173.3 56.12,170.84 55.61,168.48 C63.96,172.97 72.2,180.6 78.33,193.39 L78.5,193.39 L78.5,297.21 L77.68,296.28 C65.53,291.42 62.48,286.34 61.96,283.1 L62.33,279.68 C62.33,279.68 63.41,277 64.7,272.1 L64.7,272.1 L64.7,272.1 Z",
  124: "M37.64,135.55 C38.35,174.22 57.68,185.55 57.68,185.55 L58.02,185.64 C57.38,192.73 56.33,195.55 56.33,195.55 C56.33,195.55 39.68,185.55 40.35,208.88 C39.02,180.88 21.02,187.55 21.02,187.55 L20.22,188.12 C20.6,186.48 20.84,185.55 20.84,185.55 C20.84,185.55 20.75,184.37 20.59,182.62 C23.06,173.02 38.86,172.77 37.64,135.55 L37.64,135.55 L37.64,135.55 Z",
  125: "M19.92,116.89 C20.09,95.71 20.24,51.65 20.25,48.41 C24.4,51.56 30.77,53.37 37.64,54.21 C37.62,59.16 37.34,118.93 37.64,135.55 C38.86,172.77 23.06,173.01 20.58,182.62 C20.19,178.32 19.39,170.56 18.5,168.22 C17.17,164.73 15.61,161.29 13.96,157.04 L13.96,157.04 C13.96,157.04 19.74,138.89 19.92,116.89 L19.92,116.89 L19.92,116.89 Z",
  126: "M40.35,208.89 C41.68,236.89 43.02,252.89 42.35,264.22 C41.68,275.55 30.35,290.89 24.35,279.55 L23.52,279 C19.42,259.97 15.45,240.14 15.17,231.21 C14.63,214.15 18.64,194.93 20.22,188.11 L21.02,187.54 C21.02,187.55 39.02,180.89 40.35,208.89 L40.35,208.89 L40.35,208.89 Z",
  133: "M20.25,48.41 C20.24,51.65 20.08,95.71 19.92,116.89 C19.75,138.89 13.97,157.04 13.97,157.04 L13.97,157.04 C9.65,145.87 4.74,129.09 1.84,91.05 C-2.16,38.55 5.84,9.55 6.34,0.05 C13.03,11.55 15.69,41.88 15.69,41.88 C16.33,44.51 17.95,46.67 20.25,48.41 L20.25,48.41 L20.25,48.41 Z",
  134: "M49.3,54.78 C56.35,54.65 62.93,53.71 67.24,52.35 L67.24,52.35 C67.3,55.11 68.24,101.29 64.84,125.05 C61.34,149.55 57.34,163.55 57.34,163.55 C57.34,163.55 57.37,163.72 57.43,164 L56.68,164.11 C54.69,110.51 56.37,61.4 49.3,54.78 L49.3,54.78 L49.3,54.78 Z",
  136: "M42.35,264.22 C43.02,252.89 41.68,236.89 40.35,208.89 C39.68,185.56 56.33,195.56 56.33,195.56 C56.33,195.56 65.33,216.81 64.83,238.56 C64.66,246.03 63.74,257.6 62.58,269.91 L61.68,270.89 C56.35,293.55 43.02,280.22 42.35,264.22 L42.35,264.22 L42.35,264.22 Z",
  137: "M37.64,135.55 C37.34,118.93 37.61,59.16 37.64,54.21 C41.47,54.68 45.45,54.85 49.29,54.77 C56.37,61.4 54.69,110.51 56.67,164.12 L57.42,164.01 C57.73,165.69 58.76,172.09 58.33,181.06 C58.25,182.76 58.14,184.28 58.02,185.65 L57.68,185.56 C57.68,185.55 38.35,174.22 37.64,135.55 L37.64,135.55 L37.64,135.55 Z",
  139: "M42.35,264.22 C43.02,280.22 56.35,293.55 61.68,270.89 L62.58,269.91 C60.21,295.09 56.83,323.37 56.83,326.06 C56.83,330.06 60.37,330 61.93,340.97 C62.06,341.86 62.14,342.82 62.2,343.92 L62.2,343.92 C60.93,332.6 36.97,322.54 31.76,348.95 C31.56,345.54 30.98,342.54 29.77,340.56 C29.77,335.06 33.81,334.57 32.83,323.07 C32.47,318.91 27.91,299.43 23.51,279.02 L24.34,279.57 C30.35,290.89 41.68,275.55 42.35,264.22 L42.35,264.22 L42.35,264.22 Z",
  141: "M52.23,411.31 L52.23,411.31 C52.23,411.31 52.3,430.09 46.26,434.87 C35.62,430.73 22.8,422.76 21.2,416.55 C19.45,409.77 20.46,403.51 22.33,397.28 L22.33,397.28 C29.9,404.39 42.11,408.63 52.23,411.31 L52.23,411.31 L52.23,411.31 Z",
  142: "M52.23,411.31 C42.11,408.63 29.9,404.4 22.32,397.29 L22.32,397.29 C24.66,389.47 28.36,381.71 29.68,373.06 C30.06,370.57 30.77,366.22 31.28,361.43 C34.99,371.92 58.93,369.92 62.8,383.63 C62.96,384.7 63.12,385.79 63.31,386.9 L63.31,386.9 L63.31,386.9 C64.86,396.07 67.34,406.46 67.35,414.89 C64.36,414.11 58.72,413.03 52.23,411.31 L52.23,411.31 L52.23,411.31 Z",
  143: "M52.23,411.31 L52.23,411.31 C58.72,413.03 64.36,414.11 67.36,414.89 C67.38,427.17 63.32,435.47 59.69,437.22 C57.46,438.3 52.2,437.18 46.26,434.87 C52.3,430.09 52.23,411.31 52.23,411.31 L52.23,411.31 L52.23,411.31 Z",
  144: "M62.2,343.91 L62.2,343.91 C62.47,349.03 62.09,357.11 62.08,375.36 C62.08,377.84 62.36,380.64 62.8,383.61 C58.93,369.9 34.99,371.9 31.28,361.41 C31.71,357.29 31.99,352.83 31.76,348.93 C36.98,322.53 60.93,332.59 62.2,343.91 L62.2,343.91 L62.2,343.91 Z",
  112: "M83.46,64.78 L83.46,64.78 C85.1,63.38 86.48,61.92 87.64,60.42 L87.9,61.02 L95.91,75.23 L95.91,75.23 C85.35,103.9 86.68,106.75 76.68,118.42 L76.68,118.43 C66.75,118.82 54.9,121.09 52.82,121.5 C52.81,121.45 52.81,121.39 52.8,121.34 C72.43,106.78 83.46,64.78 83.46,64.78 L83.46,64.78 L83.46,64.78 Z",
  115: "M83.46,64.78 C78.61,68.92 71.42,72.55 60.5,75.14 C60.8,63.75 55.19,35.18 66.83,18.89 C77.73,3.63 99.97,0.88 102.85,0.59 C110.97,13.91 106.96,25.56 106.96,25.56 C91.19,30.19 98.14,46.86 87.63,60.42 C86.48,61.92 85.1,63.37 83.46,64.78 L83.46,64.78 L83.46,64.78 Z",
  117: "M83.46,64.78 C83.46,64.78 72.43,106.78 52.8,121.33 C50.26,104.4 60.17,86.51 60.5,75.22 L60.5,75.14 C71.42,72.55 78.61,68.92 83.46,64.78 L83.46,64.78 L83.46,64.78 L83.46,64.78 Z",
  118: "M76.68,134.14 C69.63,186.51 45.62,192.86 37.68,212.32 C39.83,201.93 42.23,192.11 42.5,178.22 C42.83,160.99 41.85,142.44 52.65,121.91 C56.17,131.16 67.16,135.75 76.68,134.14 L76.68,134.14 L76.68,134.14 Z",
  127: "M17.42,232.33 C22.25,226.33 28.65,219.97 36.17,220.23 C36.17,220.23 40.65,237.23 56.84,225.23 C56.84,225.23 60.84,227.9 57.51,248.23 C57.29,249.58 57.07,250.87 56.85,252.12 L56.51,252.14 C56.51,252.14 37.9,260.45 21.17,247.48 C21.17,247.38 21.18,247.3 21.18,247.24 C21.18,246.81 20.87,246.63 20.36,246.63 C18.98,244.79 15.33,239.05 17.42,232.33 L17.42,232.33 L17.42,232.33 Z",
  128: "M13.17,237.89 C14.41,236.24 15.83,234.3 17.42,232.33 C15.33,239.06 18.99,244.8 20.35,246.61 C18.89,246.61 15.82,248.16 13.84,249.89 C11.17,252.22 1.84,255.56 0.51,248.22 C4.83,246.89 8.17,244.55 13.17,237.89 L13.17,237.89 L13.17,237.89 Z",
  129: "M56.5,252.13 L56.84,252.11 C53.72,269.73 50.63,277.89 49.67,281.55 C48.84,284.72 44.5,286.38 44.17,281.55 C43.5,284.22 42.46,286.66 41.67,289.72 C40.5,294.22 34.17,294.22 34.17,289.55 C32.67,294.55 25.5,295.38 26.84,283.22 C24.17,288.05 20.42,287.05 20.17,280.89 C19.53,264.84 21.05,250.03 21.16,247.47 C37.89,260.44 56.5,252.13 56.5,252.13 L56.5,252.13 L56.5,252.13 Z",
  131: "M76.68,118.42 L76.68,118.42 C86.68,106.75 85.35,103.89 95.91,75.23 L95.91,75.23 L98.17,79.23 L97.95,89.99 C97.17,98.97 95.31,112.01 90.84,122.9 C91.19,118.98 84.54,118.11 76.68,118.42 L76.68,118.42 L76.68,118.42 Z",
  132: "M36.17,220.22 C36.63,217.51 37.15,214.9 37.68,212.32 C45.62,192.85 69.63,186.51 76.68,134.14 C82.81,133.1 88.33,129.5 90.83,122.89 C90.16,142.22 81.5,167.89 69.5,194.89 C57.5,221.89 56.83,225.22 56.83,225.22 C56.83,225.22 55.31,212.55 36.17,220.22 L36.17,220.22 L36.17,220.22 Z",
  145: "M36.17,220.22 C55.32,212.55 56.84,225.22 56.84,225.22 C40.65,237.22 36.17,220.22 36.17,220.22 L36.17,220.22 L36.17,220.22 Z",
  146: "M76.68,118.38 C84.54,118.07 91.19,118.94 90.83,122.85 C88.33,129.46 82.81,133.06 76.68,134.1 C67.16,135.71 56.18,131.12 52.65,121.88 C52.71,121.76 52.77,121.64 52.84,121.52 C52.84,121.5 52.83,121.48 52.83,121.46 C54.9,121.04 66.75,118.77 76.68,118.38 L76.68,118.38 L76.68,118.38 Z",
};

export function BodyParts({
  sectionId,
  side,
  scale,
  focusedSection,
  registrations,
  onBodyPartClick,
}: BodyPartsProps) {
  return (
    <>
      {SECTION_CONFIG[sectionId].ids[side]?.map((id: number) => {
        const opacity =
          focusedSection === null || focusedSection === sectionId ? 1 : 0.1;
        const fillColor = registrations[id]?.color ?? COLOR_BODY_FILL;

        return (
          <path
            opacity={opacity}
            origin={SECTION_CONFIG[focusedSection]?.origin as any}
            onClick={() => onBodyPartClick?.(id, sectionId)}
            key={id}
            scale={scale}
            stroke={COLOR_BODY_PART}
            strokeWidth="1"
            d={BODY_PART_SHAPES[id]}
            fill={fillColor}
            {...SECTION_CONFIG[sectionId].props[side]}
          />
        );
      })}
    </>
  );
}
