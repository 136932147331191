import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

type FolderStructureObjectType = Record<string, boolean>;

type FolderStructureContextValue = {
  folderStructureObject: FolderStructureObjectType;
  setFolderStructureObject: Dispatch<SetStateAction<FolderStructureObjectType>>;
};

const FolderStructureContext = createContext<FolderStructureContextValue>(
  {} as FolderStructureContextValue
);

export const FolderStructureContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [folderStructureObject, setFolderStructureObject] =
    useState<FolderStructureObjectType>({});

  return (
    <FolderStructureContext.Provider
      value={{
        folderStructureObject,
        setFolderStructureObject,
      }}
    >
      {children}
    </FolderStructureContext.Provider>
  );
};

export const useFolderStructureContext = () =>
  useContext(FolderStructureContext);
