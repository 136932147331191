import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_RED_ACTIVE,
  COLOR_TEXT_DARK,
} from "../../../colors";
import { useSettingsContext } from "../../../contexts/settingsContext";
import { useUserContext } from "../../../contexts/User";
import { isRealTrainer } from "../../../utils/isRealTrainer";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 8px;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AttendanceLabel = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: ${COLOR_RED_ACTIVE};
  text-transform: uppercase;
`;

const DeadlineMessage = styled.div<{ showDeadline: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ showDeadline }) =>
    showDeadline ? COLOR_RED_ACTIVE : COLOR_GREEN};
`;

interface TitleSectionProps {
  label: string;
  typename: string;
  attendanceAt: string;
  cancelled: boolean;
  sessionIsNotPaid: boolean;
  payments;
  replyBefore: string;
}

export function TitleSection({
  label,
  typename,
  payments,
  sessionIsNotPaid,
  replyBefore,
  attendanceAt,
  cancelled,
}: TitleSectionProps) {
  const { t } = useTranslation();
  const user = useUserContext();
  const { timeFormatTemplate } = useSettingsContext();

  const deadlineTime = dayjs(replyBefore).format(
    `D. MMM ${timeFormatTemplate}`
  );
  const deadlineExpired = dayjs(replyBefore).isBefore();

  const showDeadline = sessionIsNotPaid || !payments?.length;
  const showRegistrationDeadlineInfo =
    replyBefore && deadlineTime && !isRealTrainer(user);

  const deadlineMessage = showDeadline
    ? deadlineExpired
      ? `${t("deadline")} ${t("expired")}`
      : `${t("deadline")} ${deadlineTime}`
    : t("paid");

  return (
    <TitleContainer>
      <Title>{label || typename}</Title>
      {cancelled ? (
        <AttendanceLabel>{t("cancelled")}</AttendanceLabel>
      ) : attendanceAt ? (
        <AttendanceLabel>{`${t("attendanceAt", {
          x: attendanceAt,
        })}`}</AttendanceLabel>
      ) : null}
      {showRegistrationDeadlineInfo ? (
        <DeadlineMessage showDeadline={showDeadline}>
          {deadlineMessage}
        </DeadlineMessage>
      ) : null}
    </TitleContainer>
  );
}
