import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_WHITE,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED_ACTIVE,
} from "../../colors";

import { StyledHeading } from ".";

export function StatusOverview({
  available,
  modified,
  unavailable,
}: {
  available: number;
  modified: number;
  unavailable: number;
}) {
  const { t } = useTranslation();
  return (
    <>
      <StyledHeading>{t("status")}</StyledHeading>
      <StatusOverviewBlock>
        <StatusOverviewBlockItem>
          <SOBItemText>{t("available")}</SOBItemText>
          <SOBItemText large>{available}</SOBItemText>
        </StatusOverviewBlockItem>
        <StatusOverviewBlockItem severity={1}>
          <SOBItemText>{t("modified")}</SOBItemText>
          <SOBItemText large>{modified}</SOBItemText>
        </StatusOverviewBlockItem>
        <StatusOverviewBlockItem severity={2}>
          <SOBItemText>{t("unavailable")}</SOBItemText>
          <SOBItemText large>{unavailable}</SOBItemText>
        </StatusOverviewBlockItem>
      </StatusOverviewBlock>
    </>
  );
}

const StatusOverviewBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin: 0 -15px;
  padding: 15px;
  background: ${COLOR_WHITE};
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 12px;
  cursor: default;
`;

const StatusOverviewBlockItem = styled.div<{
  severity?: 0 | 1 | 2;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 7px;
  padding: 15px 0 8px 0;
  border-radius: 6px;
  background: ${({ severity }) => {
    if (severity === 1) return COLOR_ORANGE;
    if (severity === 2) return COLOR_RED_ACTIVE;
    return COLOR_GREEN;
  }};
  text-align: center;
`;

const SOBItemText = styled.span<{
  large?: boolean;
}>`
  display: block;
  font-weight: 700;
  color: ${COLOR_WHITE};
  font-size: ${({ large }) => (large ? "40px" : "14px")};
  height: ${({ large }) => (large ? "49px" : "17px")};
`;
