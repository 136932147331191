import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import {
  Group,
  useAddToTeamMutation,
  useGetBaseUsersDataForTeamQuery,
  useGetTheGroupsImInQuery,
  XpsUser,
} from "../../graphql";
import { sortList } from "../../utils/sortList";
import { BottomBar } from "../BottomBar";
import { TheWho } from "../Teams/components/TeamGroups";

import { AddCoachOrAthleteToGroupContent } from "./AddCoachOrAthleteToGroup/Content";
import { AddCoachOrAthleteToGroupHeader } from "./AddCoachOrAthleteToGroup/Header";

const BottomSection = styled.div`
  background: ${COLOR_WHITE};
  padding: 15px 20px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
`;

interface Props {
  who: TheWho;
  initialData?: XpsUser[];
  closeModal: () => void;
  groupId: string;
  ownerId: string;
  parentGroup: Group;
  alreadyAddedMembers: XpsUser[];
  onSetGroup: (group: Group) => void;
}

export const AddCoachOrAthleteToGroupModal = ({
  who,
  groupId,
  parentGroup,
  closeModal,
  ownerId,
  alreadyAddedMembers,
  onSetGroup,
}: Props) => {
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [addMember] = useAddToTeamMutation();

  const getTheGroupsImInVariables = {
    sessionId,
    language,
    requireEditability: false,
  };

  const { data = {}, loading: groupLoading } = useGetTheGroupsImInQuery({
    variables: getTheGroupsImInVariables,
    skip: !who,
  });

  const { data: baseUsersData, loading: userDataLoading } =
    useGetBaseUsersDataForTeamQuery({
      variables: {
        sessionId,
        language,
        forSearching: true,
        trainer: ownerId,
      },
      skip: !who,
    });

  const [search, setSearch] = useState("");

  const membersByType = useMemo(() => {
    const allUsers = [...(baseUsersData?.getUsers ?? [])];
    if (who === "athlete") {
      return allUsers?.filter((user) => user?.type === "Athlete");
    }
    if (who === "coach") {
      return allUsers?.filter((user) => user?.type === "Trainer");
    }
  }, [baseUsersData?.getUsers, who]);

  const membersToShow: XpsUser[] = useMemo(() => {
    const notAddedMembers = membersByType?.filter(
      (member) =>
        !alreadyAddedMembers.find(
          (addedMember) => member?.id === addedMember?.id
        )
    );
    return sortList(
      notAddedMembers?.filter(
        (member) =>
          `${member?.firstName} ${member?.lastName}`
            .toLowerCase()
            .includes(search.toLowerCase()),
        language
      )
    );
  }, [membersByType, language, alreadyAddedMembers, search]);

  const onUserSelect = (id: string) => {
    setSelectedUsers((current) => {
      const currentIndex = current.indexOf(id);
      // deselect
      if (currentIndex > -1) {
        const newUsers = [...current];
        newUsers.splice(currentIndex, 1);
        return newUsers;
      }
      //select
      return [...current, id];
    });
  };

  const onSubmit = async () => {
    for await (const memberToAdd of selectedUsers) {
      await addMember({
        variables: {
          sessionId,
          userID: memberToAdd,
          groupID: groupId,
        },
      });
    }

    onSetGroup(parentGroup);
    closeModal();
  };

  return (
    <>
      <AddCoachOrAthleteToGroupHeader search={search} setSearch={setSearch} />
      <AddCoachOrAthleteToGroupContent
        data={data?.getTheGroupsImIn}
        membersToShow={membersToShow}
        who={who}
        onUserSelect={onUserSelect}
        selectedUsers={selectedUsers}
        loading={groupLoading || userDataLoading}
        ownerId={ownerId}
      />
      <BottomSection>
        <BottomBar
          secondary={{
            text: t("cancel"),
            onClick: closeModal,
          }}
          primary={{
            text: t("done"),
            onClick: onSubmit,
          }}
        />
      </BottomSection>
    </>
  );
};
