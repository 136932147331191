import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const UserGroupIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#B1B7BC",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <>
          <circle cx="10.5" cy="8.5" r="2.5" fill={tintColor} />
          <circle cx="7" cy="3" r="2" fill={tintColor} />
          <circle cx="14" cy="3" r="2" fill={tintColor} />
          <path
            d="M8 14.5C8 13.1193 9.11929 12 10.5 12C11.8807 12 13 13.1193 13 14.5V18C13 19.1046 12.1046 20 11 20H10C8.89543 20 8 19.1046 8 18V14.5Z"
            fill={tintColor}
          />
          <circle cx="3.5" cy="8.5" r="2.5" fill={tintColor} />
          <path
            d="M1 14.5C1 13.1193 2.11929 12 3.5 12C4.88071 12 6 13.1193 6 14.5V18C6 19.1046 5.10457 20 4 20H3C1.89543 20 1 19.1046 1 18V14.5Z"
            fill={tintColor}
          />
          <circle cx="17.5" cy="8.5" r="2.5" fill={tintColor} />
          <path
            d="M15 14.5C15 13.1193 16.1193 12 17.5 12C18.8807 12 20 13.1193 20 14.5V18C20 19.1046 19.1046 20 18 20H17C15.8954 20 15 19.1046 15 18V14.5Z"
            fill={tintColor}
          />
        </>
      ) : (
        <>
          <circle cx="10.5" cy="8.5" r="2" stroke="white" />
          <circle cx="7" cy="3" r="1.5" stroke="white" />
          <circle cx="14" cy="3" r="1.5" stroke="white" />
          <path
            d="M8.5 14.5C8.5 13.3954 9.39543 12.5 10.5 12.5C11.6046 12.5 12.5 13.3954 12.5 14.5V18C12.5 18.8284 11.8284 19.5 11 19.5H10C9.17157 19.5 8.5 18.8284 8.5 18V14.5Z"
            stroke="white"
          />
          <circle cx="3.5" cy="8.5" r="2" stroke="white" />
          <path
            d="M1.5 14.5C1.5 13.3954 2.39543 12.5 3.5 12.5C4.60457 12.5 5.5 13.3954 5.5 14.5V18C5.5 18.8284 4.82843 19.5 4 19.5H3C2.17157 19.5 1.5 18.8284 1.5 18V14.5Z"
            stroke="white"
          />
          <circle cx="17.5" cy="8.5" r="2" stroke="white" />
          <path
            d="M15.5 14.5C15.5 13.3954 16.3954 12.5 17.5 12.5C18.6046 12.5 19.5 13.3954 19.5 14.5V18C19.5 18.8284 18.8284 19.5 18 19.5H17C16.1716 19.5 15.5 18.8284 15.5 18V14.5Z"
            stroke="white"
          />
        </>
      )}
    </svg>
  )
);
