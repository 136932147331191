import React from "react";
import { useSearchParams } from "react-router-dom";

import { Collections } from "../components/Collections";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";

export function AthleteCollectionsScreen() {
  const [searchParams] = useSearchParams();
  const focusedAthleteId = searchParams.get("focusedAthleteId");

  return (
    <ScreenContainer>
      <Header />
      <Collections focusedAthleteId={focusedAthleteId} />
    </ScreenContainer>
  );
}
