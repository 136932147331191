import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import _partition from "lodash/partition";
import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { UserPreferenceKeys } from "../../constants";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import {
  ReportType,
  usePutPreferenceMutation,
  useReportTemplatesQuery,
} from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { AthleteProfileTabsPreference } from "../../models";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { Loader } from "../Loader";

import { DraggableItem } from "./DraggableModal/DraggableItem";
import { EmptyList } from "./DraggableModal/EmptyList";
import { SearchBar } from "./DraggableModal/SearchBar";
import {
  ContentContainer,
  SectionTitle,
  SelectedSectionWrapper,
} from "./DraggableModal/styled";
import { EditAthleteProfileTabsAvailableReportList } from "./EditAthleteProfileTabs/AvailableReportList";

const LoaderWrapper = styled.div`
  height: 50vh;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const EditAthleteProfileTabs = ({
  preferences = {},
  onSave,
  onClose,
}: {
  preferences?: AthleteProfileTabsPreference;
  onSave?(): void;
  onClose?(): void;
}) => {
  const { t } = useTranslation();
  const [searchParams, setParams] = useSearchParams();

  const { sessionId, language } = useUserContext();
  const [{ selectedGroup }] = useReportsContext();

  const selectedGroupId = selectedGroup?.id;
  const overrideGroupId = searchParams.get("groupId");
  const accountId = useGetSelectedAccountIdInMonitoring();

  const [localPreferences, setLocalPreferences] =
    useState<AthleteProfileTabsPreference>(preferences);
  const [search, setSearch] = useState<string>("");

  const [setPreference] = usePutPreferenceMutation();
  const { data: { reportTemplates } = {}, loading } = useReportTemplatesQuery({
    variables: {
      sessionId,
      accountId,
      language,
    },
    skip: !accountId,
    onCompleted({ reportTemplates }) {
      // check if all reports from preferences exist in db
      // clean up preferences, remove deleted reports from tabs
      const allReportTemplateIds = new Set(
        (reportTemplates || []).map((report) => report.id)
      );
      setLocalPreferences((preferences) => ({
        ...preferences,
        [selectedGroupId]: (preferences[selectedGroupId] ?? []).filter(
          (report) => allReportTemplateIds.has(report.id)
        ),
      }));
    },
    // We need to refetch on every open due to deleting and adding new reports
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    return () => {
      onClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reportTemplatesWithoutTabTemplates = useMemo(
    () =>
      reportTemplates?.filter(
        (template) => template.reportType !== ReportType.Tab
      ) ?? [],
    [reportTemplates]
  );

  const reportTemplatesWithoutTabTemplateIds = useMemo(
    () =>
      new Map(
        reportTemplatesWithoutTabTemplates.map((template) => [
          template.id,
          template,
        ])
      ),
    [reportTemplatesWithoutTabTemplates]
  );

  const reportsAdded = useMemo(
    () =>
      (localPreferences[selectedGroupId] ?? [])
        .filter((t) => reportTemplatesWithoutTabTemplateIds.has(t.id))
        .map((t) => reportTemplatesWithoutTabTemplateIds.get(t.id)),
    [localPreferences, selectedGroupId, reportTemplatesWithoutTabTemplateIds]
  );

  const onRemoveReportPress = useCallback(
    ({ id }: { id?: string }) => {
      setLocalPreferences((preferences) => ({
        ...preferences,
        [selectedGroupId]: (preferences[selectedGroupId] ?? []).filter(
          (item) => item.id !== id
        ),
      }));
    },
    [selectedGroupId]
  );

  const onOrderChange = useCallback(
    (result: DropResult) => {
      setLocalPreferences((preferences) => {
        const groupTabs = preferences[selectedGroupId] ?? [];
        const removedElement = groupTabs.splice(result.source.index, 1);
        removedElement[0] &&
          groupTabs.splice(result.destination.index, 0, removedElement[0]);

        return {
          ...preferences,
          [selectedGroupId]: groupTabs,
        };
      });
    },
    [selectedGroupId]
  );

  useEffect(() => {
    if (overrideGroupId) {
      setLocalPreferences((preferences) => ({
        ...preferences,
        [selectedGroupId]: preferences[overrideGroupId] ?? [],
      }));
      setParams({ ...getSearchParamsObject(searchParams, ["groupId"]) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overrideGroupId]);

  useEffect(() => {
    setPreference({
      variables: {
        sessionId,
        accountId,
        key: UserPreferenceKeys.ATHLETE_PROFILE_TABS,
        value: JSON.stringify(localPreferences),
      },
      onCompleted() {
        onSave?.();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPreferences]);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader size="large" color={COLOR_BLUE} />
      </LoaderWrapper>
    );
  }

  const [groupReports, athleteReports] = _partition(
    reportTemplatesWithoutTabTemplates,
    (template) => template.reportType === ReportType.GroupReport
  );

  return (
    <DragDropContext onDragEnd={onOrderChange}>
      <ContentContainer>
        <SelectedSectionWrapper>
          <SectionTitle>{t("selected")}</SectionTitle>
          <Droppable droppableId="profileTabReports">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {reportsAdded.map((report, index) => (
                  <DraggableItem
                    key={report.id}
                    onRemove={onRemoveReportPress}
                    report={report}
                    index={index}
                  />
                ))}
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
          {reportsAdded.length === 0 && (
            <EmptyList label={t("clickReportsBelowToAdd")} />
          )}
        </SelectedSectionWrapper>

        <SearchBar searchValue={search} setSearchValue={setSearch} />

        <SectionTitle>{t("athleteReports")}</SectionTitle>
        <EditAthleteProfileTabsAvailableReportList
          // @ts-ignore
          reportTemplatesWithoutTabTemplates={athleteReports}
          localPreferences={localPreferences}
          setLocalPreferences={setLocalPreferences}
          search={search}
          setSearch={setSearch}
        />

        <SectionTitle style={{ marginTop: "11px" }}>
          {t("teamReports")}
        </SectionTitle>
        <EditAthleteProfileTabsAvailableReportList
          // @ts-ignore
          reportTemplatesWithoutTabTemplates={groupReports}
          localPreferences={localPreferences}
          setLocalPreferences={setLocalPreferences}
          search={search}
          setSearch={setSearch}
        />
      </ContentContainer>
    </DragDropContext>
  );
};
