import React, { Fragment } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSearchParams, useLocation } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { useNavigateBack } from "../../hooks/useNavigateBack";
import { BasicButton } from "../Button";
import { NextIcon } from "../Icons";
import StyledText from "../StyledText";

const Container = styled.div`
  flex-direction: row;
  flex: 1;
  display: flex;
  align-items: center;
`;

const BackButton = styled(BasicButton)`
  flex-direction: row;
  align-items: center;
  margin-right: 25px;
`;

const BackButtonLabel = styled(StyledText)`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_BLUE};
`;

const Title = styled(StyledText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
`;

const ItemLabel = styled(StyledText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

function getTranslatedTitle(t: TFunction<"common">, id: string) {
  switch (id) {
    case "session":
      return t("calendar");
    case "athlete":
      return t("athlete");
    case "teams":
      return t("teams");
    default:
      return null;
  }
}

interface HeaderNavigationProps {
  onBack?: () => void;
}

export function HeaderNavigation({ onBack }: HeaderNavigationProps) {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation();

  const sessionType = searchParams.get("sessionType");
  const userType = searchParams.get("userType");
  const pathName = location?.pathname;
  const pathArray = pathName?.split("/");
  const pathsAfterTitle = pathArray.slice(2);
  const title = getTranslatedTitle(t, pathArray[1]);
  const navigateBack = useNavigateBack();

  let teamPath = null;
  if (pathsAfterTitle[0] === "athlete") {
    if (userType === "Family") {
      teamPath = t("familyMember");
    } else if (userType === "Trainer") {
      teamPath = t("trainer");
    } else {
      teamPath = t("athlete");
    }
  }

  return (
    <Container>
      <BackButton onClick={() => (onBack ? onBack() : navigateBack())}>
        <NextIcon
          direction="left"
          strokeWidth="2"
          tintColor={COLOR_BLUE}
          style={{ width: 19, height: 19 }}
        />
        <BackButtonLabel>{t("back").toUpperCase()}</BackButtonLabel>
      </BackButton>
      <Title>{title}</Title>
      {pathArray[1] === "session" ? (
        <>
          <NextIcon
            tintColor={COLOR_SECONDARY_GREY_HOVER}
            style={{ width: 16, height: 16, marginLeft: 10, marginRight: 10 }}
          />
          <ItemLabel>{sessionType}</ItemLabel>
        </>
      ) : teamPath ? (
        <>
          <NextIcon
            tintColor={COLOR_SECONDARY_GREY_HOVER}
            style={{ width: 16, height: 16, marginLeft: 10, marginRight: 10 }}
          />
          <ItemLabel>{teamPath}</ItemLabel>
        </>
      ) : (
        pathsAfterTitle?.map((path) => (
          <Fragment key={path}>
            <NextIcon
              tintColor={COLOR_SECONDARY_GREY_HOVER}
              style={{ width: 16, height: 16, marginLeft: 10, marginRight: 10 }}
            />
            <ItemLabel>{path}</ItemLabel>
          </Fragment>
        ))
      )}
    </Container>
  );
}
