import _get from "lodash/get";

import { Cell } from "../../../graphql";

export function endsWithPunctuation(value: string) {
  const punctuation = [":", ".", ","];
  return punctuation.includes(value.slice(-1));
}

export function shouldBePlaceholderSplit(cell: Cell): boolean {
  const isEmpty = !cell?.valuePerformed || cell.valuePerformed === "";
  const chars = isEmpty
    ? _get(cell, "valuePrescribed.length", 0)
    : cell?.valuePerformed?.length;

  return chars >= 5;
}
