import React from "react";
import { useTranslation } from "react-i18next";

import { HoverableItemWithCheckbox } from "../../Modals/EditAthleteProfileTabs/HoverableItemWithCheckbox";

interface AthletesDropdownContentProps {
  toggle?: () => void;
  showOptionalAthletes: boolean;
  toggleShowOptionalAthletes: () => Promise<void>;
}

export const AthletesDropdownContent = ({
  toggle,
  showOptionalAthletes,
  toggleShowOptionalAthletes,
}: AthletesDropdownContentProps) => {
  const { t } = useTranslation();

  const checkOptional = async () => {
    await toggleShowOptionalAthletes();
    toggle?.();
  };

  return (
    <>
      <HoverableItemWithCheckbox
        text={t("showOptionalAthletes")}
        isChecked={showOptionalAthletes}
        onClick={checkOptional}
        textStyle={{ maxWidth: "150px" }}
      />
    </>
  );
};
