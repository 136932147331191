import React from "react";

import styled from "styled-components";

export function ReturnAndOptionsCell({ value }: { value: string }) {
  return (
    <ReturnCellWrap>
      <span>{value}</span>
    </ReturnCellWrap>
  );
}

export const ReturnCellWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  > div {
    cursor: pointer;
  }
`;
