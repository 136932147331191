import React, {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import { Event } from "react-big-calendar";

import dayjs from "dayjs";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { Tab } from "../../components/Tabs";
import {
  Practice,
  useSessionDetailQuery,
  useSessionPlanQuery,
} from "../../graphql";
import { useResourceCalendarContext } from "../resourceCalendar/ResourceCalendarProvider";
import { useUserContext } from "../User";

export type DroppableAreaProps = {
  id: string;
  practiceLines: PracticeLine[];
};

export type PracticeLineWithDraft = PracticeLine & { isDraft?: boolean };

export type PracticeContextModel = {
  handleCommentTextChange: (
    id: string,
    newText: string,
    splitAreaId?: string
  ) => void;
  handleDurationChange: (
    id: string,
    duration: number,
    splitAreaId?: string
  ) => void;
  handleMoveItem: (currentIndex: number, direction: string) => void;
  handleMoveSplitAreaItem: (
    currentIndex: number,
    direction: string,
    splitAreaId?: string
  ) => void;
  handleRemovePlanItem: (id: string, splitAreaId?: string) => void;
  handleRemovePlanItemSplitView: (id: string, splitAreaId: string) => void;
  onClear: () => void;
  onClearPlanSection: () => void;
  practiceLines: PracticeLineWithDraft[];
  originalPracticeLines: PracticeLine[];
  practiceSession: Practice;
  practiceStartDate: number;
  practiceTotalDuration: number;
  selectedEventId: string;
  selectedFolder: string;
  selectedTab: Tab;
  selectedSlot: Event;
  sessionDataLoading: boolean;
  setPracticeLines: Dispatch<SetStateAction<PracticeLineWithDraft[]>>;
  setPracticeSession: Dispatch<SetStateAction<Practice>>;
  setPracticeStartDate: Dispatch<SetStateAction<number>>;
  setSelectedEventId: Dispatch<SetStateAction<string>>;
  setSelectedSlot: Dispatch<SetStateAction<Event>>;
  setSelectedFolder: Dispatch<SetStateAction<string>>;
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
  currentGroupId: string;
  setCurrentGroupId: Dispatch<SetStateAction<string>>;
};

const PracticeContext = createContext<PracticeContextModel>(
  {} as PracticeContextModel
);

export function PracticeContextProvider({ children }: { children: ReactNode }) {
  const { sessionId, timezone, language } = useUserContext();
  const [selectedSlot, setSelectedSlot] = useState<Event>(null);
  const [selectedEventId, setSelectedEventId] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [practiceSession, setPracticeSession] = useState<Practice>(null);
  const [practiceLines, setPracticeLines] = useState([]);
  const [originalPracticeLines, setOriginalPracticeLines] = useState([]);
  const [practiceTotalDuration, setPracticeTotalDuration] = useState(0);
  const [practiceStartDate, setPracticeStartDate] = useState(0);
  const [selectedTab, setSelectedTab] = useState<Tab>(null);
  const [currentGroupId, setCurrentGroupId] = useState("");

  const { onClearSelectableResourceList, initializeSelectedResources } =
    useResourceCalendarContext();

  const { data: sessionData, loading: sessionDataLoading } =
    useSessionDetailQuery({
      variables: {
        sessionId,
        timezone,
        language,
        sessionGuid: selectedEventId,
      },
      skip: !selectedEventId,
    });

  useEffect(
    function initialSetPlanDrills() {
      if (sessionData) {
        setPracticeSession(sessionData?.practice as Practice);
      }
    },
    [sessionData]
  );

  useEffect(
    function initializeSelectedResourcesForEvent() {
      if (practiceSession?.id) {
        initializeSelectedResources(practiceSession);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [practiceSession]
  );

  const { data: planData } = useSessionPlanQuery({
    variables: {
      sessionId,
      timezone,
      language,
      sessionGuid: selectedEventId,
    },
    skip: !selectedEventId,
  });

  useEffect(
    function initialSetPlanDrills() {
      if (planData) {
        setPracticeLines(planData?.practice?.practiceLines);
      }
    },
    [planData]
  );
  /**** SESSION DATA ****/

  useEffect(() => {
    if (!originalPracticeLines?.length && practiceLines.length) {
      setOriginalPracticeLines(practiceLines);
    }
  }, [originalPracticeLines, practiceLines]);

  const onClear = useCallback(() => {
    onClearSelectableResourceList();
    setSelectedSlot(null);
    setSelectedEventId("");
    setPracticeSession(null);
    setPracticeLines([]);
    setPracticeTotalDuration(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearPlanSection = useCallback(() => {
    setPracticeLines([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemovePlanItem = (id: string, splitAreaId?: string) => {
    if (splitAreaId) {
      const updatedPracticeLines = practiceLines.map((practiceLine) => {
        if (
          "subPractices" in practiceLine &&
          practiceLine?.subPractices?.length
        ) {
          const updatedSubPractices = practiceLine?.subPractices?.filter(
            (subPractice) => {
              if (subPractice.id !== splitAreaId) {
                return subPractice;
              }
            }
          );

          return { ...practiceLine, subPractices: updatedSubPractices };
        }

        return practiceLine;
      });

      return setPracticeLines(updatedPracticeLines);
    } else {
      const filteredPracticeLines = practiceLines.filter(
        (practiceLine: PracticeLine) => {
          if (practiceLine?.id !== id) {
            return practiceLine;
          }
        }
      );

      setPracticeLines(filteredPracticeLines);
    }
  };

  const handleMoveItem = (currentIndex: number, direction: string) => {
    const practiceLinesCopy = [...practiceLines];

    if (
      (direction === "up" && currentIndex > 0) ||
      (direction === "down" && currentIndex < practiceLines.length - 1)
    ) {
      const newIndex = direction === "up" ? currentIndex - 1 : currentIndex + 1;

      [practiceLinesCopy[currentIndex], practiceLinesCopy[newIndex]] = [
        practiceLinesCopy[newIndex],
        { ...practiceLinesCopy[currentIndex], isEdited: true },
      ];

      setPracticeLines(practiceLinesCopy);
    }
  };

  const handleCommentTextChange = (
    id: string,
    newText: string,
    splitAreaId?: string
  ) => {
    if (splitAreaId) {
      const updatedPracticeLines = practiceLines.map((practiceLine) => {
        if (
          "subPractices" in practiceLine &&
          practiceLine?.subPractices?.length
        ) {
          const updatedSubPractices = practiceLine?.subPractices?.map(
            (subPractice) => {
              if (subPractice.id === splitAreaId) {
                return {
                  ...subPractice,
                  comment: newText,
                };
              }

              return subPractice;
            }
          );

          return { ...practiceLine, subPractices: updatedSubPractices };
        }

        return practiceLine;
      });

      return setPracticeLines(updatedPracticeLines);
    } else {
      const updatedPracticeLines = practiceLines.map((practiceLine) =>
        practiceLine.id === id
          ? { ...practiceLine, txt: newText }
          : practiceLine
      );

      setPracticeLines(updatedPracticeLines);
    }
  };

  const handleRemovePlanItemSplitView = (id: string, splitAreaId: string) => {
    const updatedPracticeLines = practiceLines.map((practiceLine) => {
      if (
        "subPractices" in practiceLine &&
        practiceLine?.subPractices?.length
      ) {
        const updatedSubPractices = practiceLine?.subPractices?.map(
          (subPractice) => {
            if (subPractice.id === splitAreaId) {
              const updatedSubPracticeLines = subPractice?.practiceLines.filter(
                (practiceLine: PracticeLine) => {
                  if (practiceLine?.id !== id) {
                    return practiceLine;
                  }
                }
              );

              return {
                ...subPractice,
                practiceLines: updatedSubPracticeLines,
                practiceLinesToRemove: subPractice?.practiceLinesToRemove
                  ? [...subPractice?.practiceLinesToRemove, { id }]
                  : [{ id }],
              };
            }

            return subPractice;
          }
        );

        return { ...practiceLine, subPractices: updatedSubPractices };
      }

      return practiceLine;
    });

    return setPracticeLines(updatedPracticeLines);
  };

  const handleMoveSplitAreaItem = (
    currentIndex: number,
    direction: string,
    splitAreaId?: string
  ) => {
    const updatedPracticeLines = practiceLines.map((practiceLine) => {
      if (
        "subPractices" in practiceLine &&
        practiceLine?.subPractices?.length
      ) {
        const updatedSubPractices = practiceLine?.subPractices?.map(
          (subPractice) => {
            if (subPractice.id === splitAreaId) {
              const practiceLinesCopy = [...subPractice.practiceLines];

              if (
                (direction === "up" && currentIndex > 0) ||
                (direction === "down" &&
                  currentIndex < subPractice?.practiceLines.length - 1)
              ) {
                const newIndex =
                  direction === "up" ? currentIndex - 1 : currentIndex + 1;

                [practiceLinesCopy[currentIndex], practiceLinesCopy[newIndex]] =
                  [
                    practiceLinesCopy[newIndex],
                    { ...practiceLinesCopy[currentIndex], isEdited: true },
                  ];
              }

              return { ...subPractice, practiceLines: practiceLinesCopy };
            }

            return subPractice;
          }
        );

        return { ...practiceLine, subPractices: updatedSubPractices };
      }

      return practiceLine;
    });

    return setPracticeLines(updatedPracticeLines);
  };

  const handleDurationChange = (
    id: string,
    duration: number,
    splitAreaId?: string
  ) => {
    if (splitAreaId) {
      const updatedPracticeLines = practiceLines.map((practiceLine) => {
        if (
          "subPractices" in practiceLine &&
          practiceLine?.subPractices?.length
        ) {
          const updatedSubPractices = practiceLine?.subPractices?.map(
            (subPractice) => {
              const updatedSubPractice = { ...subPractice };
              if (subPractice.id === splitAreaId) {
                updatedSubPractice.practiceLines =
                  subPractice?.practiceLines?.map((practiceLine) =>
                    practiceLine.id === id
                      ? { ...practiceLine, localDuration: duration }
                      : practiceLine
                  );
              }

              return updatedSubPractice;
            }
          );

          return { ...practiceLine, subPractices: updatedSubPractices };
        }

        return practiceLine;
      });

      return setPracticeLines(updatedPracticeLines);
    } else {
      const updatedPracticeLines = practiceLines.map((practiceLine) =>
        practiceLine.id === id
          ? { ...practiceLine, localDuration: duration }
          : practiceLine
      );
      setPracticeLines(updatedPracticeLines);
    }
  };

  useEffect(() => {
    const someOfPracticeLineWasChanged = practiceLines?.filter(
      (line) => line?.localDuration
    );

    if (someOfPracticeLineWasChanged?.length) {
      let total = 0;

      practiceLines?.map((practiceLine, index) => {
        const isLast = practiceLines?.length === index + 1;

        if (practiceLine?.__typename !== "Split") {
          const duration = practiceLine.localDuration || practiceLine?.minutes;
          const durationToAdd = dayjs.duration(duration, "minute").asMinutes();
          total += durationToAdd;
        }

        let longestSplitDuration = 0;

        if (practiceLine?.__typename === "Split") {
          practiceLine?.subPractices?.map((subPractice) => {
            let totalSplit = 0;
            subPractice?.practiceLines?.map((subPracticePracticeLine) => {
              const duration =
                subPracticePracticeLine.localDuration ||
                subPracticePracticeLine?.minutes;
              const durationToAdd = dayjs
                .duration(duration, "minute")
                .asMinutes();
              totalSplit += durationToAdd;
              if (totalSplit > longestSplitDuration) {
                longestSplitDuration = totalSplit;
              }
            });
          });
        }

        if (isLast) {
          setPracticeTotalDuration(total + longestSplitDuration);
        }
      });
    }
  }, [practiceLines, setPracticeTotalDuration]);

  return (
    <PracticeContext.Provider
      value={{
        handleCommentTextChange,
        handleDurationChange,
        handleMoveItem,
        handleMoveSplitAreaItem,
        handleRemovePlanItem,
        handleRemovePlanItemSplitView,
        onClear,
        onClearPlanSection,
        practiceLines,
        originalPracticeLines,
        practiceSession,
        practiceStartDate,
        practiceTotalDuration,
        selectedEventId,
        selectedSlot,
        selectedFolder,
        selectedTab,
        sessionDataLoading,
        setPracticeLines,
        setPracticeSession,
        setPracticeStartDate,
        setSelectedEventId,
        setSelectedSlot,
        setSelectedFolder,
        setSelectedTab,
        currentGroupId,
        setCurrentGroupId,
      }}
    >
      {children}
    </PracticeContext.Provider>
  );
}

export const usePracticeContext = () => useContext(PracticeContext);
