import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../../colors";

export const DataCell = styled.td<{
  color?: string;
  fontWeight?: number;
  fontSize?: number;
}>`
  height: 0;

  color: ${({ color }) => color ?? COLOR_MEDIUM_GRAY};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  line-height: 15px;
  font-size: ${({ fontSize }) => fontSize ?? 12}px;
  padding: 0 30px;
`;
export const HeaderCell = styled.th`
  color: ${COLOR_MEDIUM_GRAY};
  font-weight: 600;
  line-height: 12px;
  font-size: 10px;
`;
