import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Formik } from "formik";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../colors";
import { ConnectedFocusError } from "../components/FormikFocusError";
import { Header } from "../components/Header";
import { DataSection } from "../components/Report/InformationWidget/Editor/DataSection";
import { InformationWidgetPreview } from "../components/Report/InformationWidget/Widget/Preview";
import { PeriodSection } from "../components/Report/PeriodSection";
import { PreviewButtonSection } from "../components/Report/PreviewButtonSection";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { WidgetEditorTitleSection } from "../components/Report/WidgetEditorTitleSection";
import { AppearanceSection } from "../components/ReportCreateTable/AppearanceSection";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { Section } from "../components/Section";
import { SectionTitle } from "../components/SectionTitle";
import { DragDropContainer } from "../components/Wrappers/DragDropContainer";
import { useAccessLevelsContext } from "../contexts/accessLevels";
import { useUserContext } from "../contexts/User";
import {
  AggregateFunction,
  useSaveWidgetTemplateInformationLogMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import {
  useAddWidgetToReport,
  useNavigateToPreviousPage,
  useWidgetEditorUrlParams,
} from "../hooks";
import { Period, PeriodType } from "../models";

interface InformationDataSource {
  collectionId: string;
  measurementTemplateId: string;
  measurementTemplateName: string;
  attributeTemplateId: string;
  attributeTemplateName: string;
  aggregation:
    | AggregateFunction.Each
    | AggregateFunction.Latest
    | AggregateFunction.LatestForever;
}

export interface InformationWidgetFormModel {
  id?: string;
  name: string;
  informations: InformationDataSource[];
  period: Period;
  widgetBorders: boolean;
  type: WidgetType;
}

const WidgetWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 670px;
  box-sizing: border-box;
  background-color: ${COLOR_WHITE};
  border-right: 0.5px solid ${COLOR_GREY_ACTIVE};
  overflow-y: scroll;
`;

export const InformationWidgetEditor = () => {
  const { t } = useTranslation();
  const { sessionId, language, firstName, lastName } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
  } = useWidgetEditorUrlParams();

  const { data: widgetData, loading: loadingInformationLogWidget } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const [saveWidgetTemplateInformation, { loading: savingWidget }] =
    useSaveWidgetTemplateInformationLogMutation();

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const onSubmit = async ({
    id,
    name,
    ...rest
  }: InformationWidgetFormModel) => {
    try {
      const response = await saveWidgetTemplateInformation({
        variables: {
          sessionId,
          accountId: selectedAccount.id,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              name ||
              (isEditingWidgetTemplate
                ? t("defaultInformationWidgetName")
                : ""),
            reportTemplateId: reportId || parentReportId,
            preferences: JSON.stringify({
              type: WidgetType.INFORMATION_LOG,
              updatedAt: dayjs(),
              createdBy: `${firstName} ${lastName}`,
              ...rest,
            }),
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateInformationLog?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateInformationLog.id
        );
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const widgetEditData = React.useMemo(() => {
    if (widgetData?.reportWidgetTemplateById) {
      const { ...rest } = widgetData.reportWidgetTemplateById;

      const preferences =
        "preferences" in widgetData.reportWidgetTemplateById
          ? JSON.parse(widgetData.reportWidgetTemplateById.preferences)
          : {};

      return { ...rest, ...preferences };
    }
    return null;
  }, [widgetData]);

  if (loadingInformationLogWidget) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <Formik<InformationWidgetFormModel>
        initialValues={{ ...initialValues, ...widgetEditData }}
        onSubmit={onSubmit}
        validate={async (values) => {
          if (values.informations?.length < 1) {
            return { informations: "required" };
          }
        }}
        validateOnMount
      >
        {({
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          submitForm,
        }) => (
          <WidgetWrapper>
            <ConnectedFocusError />

            <FormWrapper>
              <form onSubmit={handleSubmit}>
                <WidgetEditorTitleSection
                  name="name"
                  value={values.name}
                  handleChange={handleChange}
                />

                <Section>
                  <SectionTitle title={t("informationLog")} />
                  <DragDropContainer
                    fieldArrayName="informations"
                    buttonText={t("addRow").toUpperCase()}
                    items={values.informations.map(
                      (information, index) =>
                        ({ remove, dragHandleProps }) => (
                          <DataSection
                            key={`${information.measurementTemplateId}-${information.attributeTemplateId}-${index}`}
                            index={index}
                            remove={remove}
                            dragHandleProps={dragHandleProps}
                          />
                        )
                    )}
                  />
                </Section>

                <AppearanceSection
                  widgetType={WidgetType.INFORMATION_LOG}
                  widgetBorders={values.widgetBorders}
                  setFieldValue={setFieldValue}
                />

                <PeriodSection
                  setFieldValue={setFieldValue}
                  period={values.period}
                />
              </form>
            </FormWrapper>

            <PreviewButtonSection
              isSaving={savingWidget}
              onSaveClick={submitForm}
              onCancelClick={navigateToPreviousPage}
            >
              <InformationWidgetPreview />
            </PreviewButtonSection>
          </WidgetWrapper>
        )}
      </Formik>
    </ScreenContainer>
  );
};

const initialValues: InformationWidgetFormModel = {
  name: "",
  informations: [
    {
      collectionId: "",
      measurementTemplateId: "",
      measurementTemplateName: "",
      attributeTemplateId: "",
      attributeTemplateName: "",
      aggregation: AggregateFunction.Latest,
    },
  ],
  type: WidgetType.INFORMATION_LOG,
  widgetBorders: false,
  period: {
    type: PeriodType.SELECTION,
    from: dayjs(),
    to: dayjs(),
  },
};
