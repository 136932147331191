import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CalendarDottedIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "white",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      {...props}
    >
      {isActive ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2H6V3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H17C18.1046 18 19 17.1046 19 16V5C19 3.89543 18.1046 3 17 3H15V2H14V3H11V2H10V3H7V2ZM7 9V10H6V9H7ZM15 10V9H14V10H15ZM11 9V10H10V9H11ZM7 14V13H6V14H7ZM15 13V14H14V13H15ZM11 14V13H10V14H11Z"
          fill="white"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 2H6V3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H17C18.1046 18 19 17.1046 19 16V5C19 3.89543 18.1046 3 17 3H15V2H14V3H11V2H10V3H7V2ZM6 4V5H7V4H10V5H11V4H14V5H15V4H17C17.5523 4 18 4.44772 18 5V16C18 16.5523 17.5523 17 17 17H4C3.44772 17 3 16.5523 3 16V5C3 4.44772 3.44772 4 4 4H6ZM7 9V10H6V9H7ZM15 10V9H14V10H15ZM11 9V10H10V9H11ZM7 14V13H6V14H7ZM15 13V14H14V13H15ZM11 14V13H10V14H11Z"
          fill={tintColor}
        />
      )}
    </svg>
  )
);
