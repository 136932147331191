import React, { memo } from "react";

export const UserManagementIcon = memo(() => (
  <svg
    width="54"
    height="44"
    viewBox="0 0 54 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 14C21.7614 14 24 11.7614 24 9C24 6.23858 21.7614 4 19 4C16.2386 4 14 6.23858 14 9C14 11.7614 16.2386 14 19 14ZM27 25C29.7614 25 32 22.7614 32 20C32 17.2386 29.7614 15 27 15C24.2386 15 22 17.2386 22 20C22 22.7614 24.2386 25 27 25ZM40 9C40 11.7614 37.7614 14 35 14C32.2386 14 30 11.7614 30 9C30 6.23858 32.2386 4 35 4C37.7614 4 40 6.23858 40 9ZM27 26C23.134 26 20 29.134 20 33V38C20 40.2091 21.7909 42 24 42H30C32.2091 42 34 40.2091 34 38V33C34 29.134 30.866 26 27 26ZM16 20C16 22.7614 13.7614 25 11 25C8.23858 25 6 22.7614 6 20C6 17.2386 8.23858 15 11 15C13.7614 15 16 17.2386 16 20ZM11 26C7.13401 26 4 29.134 4 33V38.2222C4 40.3086 5.69137 42 7.77778 42H14.2222C16.3086 42 18 40.3086 18 38.2222V33C18 29.134 14.866 26 11 26ZM48 20C48 22.7614 45.7614 25 43 25C40.2386 25 38 22.7614 38 20C38 17.2386 40.2386 15 43 15C45.7614 15 48 17.2386 48 20ZM43 26C39.134 26 36 29.134 36 33V38.2222C36 40.3086 37.6914 42 39.7778 42H46.2222C48.3086 42 50 40.3086 50 38.2222V33C50 29.134 46.866 26 43 26Z"
      fill="#A0BEEC"
    />
  </svg>
));
