import React from "react";
import { useTranslation } from "react-i18next";

import { COLOR_BLUE_ATTENDANCE_BG } from "../../../colors";
import { GroupTablePeriodizationStatsDataItem } from "../../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";
import { getFormattedTimeOrValueWithAttributeFromDecimal } from "../../ReportTableWidget/hooks/helpers/getFormattedTimeOrValueWithAttributeFromDecimal";
import { ValueCellText } from "../../ReportTableWidget/styled";

import { DataRow, HeaderLikeLabel, SummaryStatsTd } from "./styled";

interface TableSummarySectionProps {
  isAboveTable?: boolean;
  statsData:
    | {
        id: string;
        average: string | number;
        stdDev: string | number;
        tifn?: boolean;
      }[]
    | GroupTablePeriodizationStatsDataItem[];
}

export function TableSummarySection({
  statsData,
  isAboveTable = false,
}: TableSummarySectionProps) {
  const { t } = useTranslation();

  return (
    <>
      <DataRow style={{ backgroundColor: COLOR_BLUE_ATTENDANCE_BG }}>
        <SummaryStatsTd>
          <HeaderLikeLabel>{t("average").toUpperCase()}</HeaderLikeLabel>
        </SummaryStatsTd>
        {statsData.map((data, index) => (
          <SummaryStatsTd key={`${data?.average}-${index}`}>
            <ValueCellText style={{ paddingLeft: 4, paddingRight: 4 }}>
              {data.tifn
                ? getFormattedTimeOrValueWithAttributeFromDecimal(
                    Number(data.average.split(" ")?.[0]),
                    data.unitName,
                    data.unitName
                  )
                : data.average}
            </ValueCellText>
          </SummaryStatsTd>
        ))}
      </DataRow>
      <DataRow style={{ backgroundColor: COLOR_BLUE_ATTENDANCE_BG }}>
        <SummaryStatsTd isLastRow isAboveTable={isAboveTable}>
          <HeaderLikeLabel>{t("stdDev").toUpperCase()}</HeaderLikeLabel>
        </SummaryStatsTd>
        {statsData.map((data, index) => (
          <SummaryStatsTd
            key={`${data?.stdDev}-${index}`}
            isLastRow
            isAboveTable={isAboveTable}
          >
            <ValueCellText style={{ paddingLeft: 4, paddingRight: 4 }}>
              {data.tifn
                ? getFormattedTimeOrValueWithAttributeFromDecimal(
                    Number(data.stdDev.split(" ")?.[0]),
                    data.unitName,
                    data.unitName
                  )
                : data.stdDev}
            </ValueCellText>
          </SummaryStatsTd>
        ))}
      </DataRow>
    </>
  );
}
