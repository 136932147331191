import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const LocationPointIcon = memo(
  ({ width = 15, height = 15, color = "#0073D9", ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 15C7.5 15 13 9.84375 13 5.625C13 2.5184 10.5376 0 7.5 0C4.46243 0 2 2.5184 2 5.625C2 9.84375 7.5 15 7.5 15ZM7.5 9C9.433 9 11 7.433 11 5.5C11 3.567 9.433 2 7.5 2C5.567 2 4 3.567 4 5.5C4 7.433 5.567 9 7.5 9Z"
        fill={color}
      />
    </svg>
  )
);
