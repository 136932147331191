import React, { memo } from "react";

export const AddFileIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2773 8.30356C16.2718 7.30363 16.2719 5.73214 15.2773 4.73213C14.2828 3.73211 12.7199 3.73219 11.7254 4.73213L6.04235 10.4464C4.69269 11.8035 4.69261 14.0893 6.04235 15.4464C7.3921 16.8036 9.66538 16.8035 11.015 15.4464L15.6325 10.8036L16.3429 11.5178L11.7254 16.1607C9.94946 17.9464 7.10793 17.9464 5.33197 16.1607C3.55601 14.375 3.55601 11.5178 5.33197 9.73213L11.015 4.01784C12.3647 2.66076 14.638 2.66068 15.9877 4.01784C17.3375 5.375 17.3374 7.66077 15.9877 9.01785L10.3047 14.7321C9.31019 15.7321 7.74729 15.7322 6.75274 14.7321C5.75818 13.7321 5.75827 12.1606 6.75274 11.1607L11.3702 6.51784L12.0806 7.23213L7.46312 11.875C6.89478 12.4465 6.89478 13.4464 7.46312 14.0179C8.03146 14.5893 9.02593 14.5893 9.59427 14.0179L15.2773 8.30356Z"
      fill="#64707A"
    />
  </svg>
));
