import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const PlusIcon = memo(
  ({
    width = 13,
    height = 13,
    isActive = false,
    tintColor = "#0073D9",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {isActive ? (
        <>
          <path d="M0 6.5H13" stroke="white" />
          <path d="M6.5 13L6.5 0" stroke="white" />
        </>
      ) : (
        <>
          <path d="M0 6.5H13" stroke={tintColor} />
          <path d="M6.5 13L6.5 0" stroke={tintColor} />
        </>
      )}
    </svg>
  )
);
