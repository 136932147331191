export enum ButtonVariant {
  Button = "button",
  Submit = "submit",
  Reset = "reset",
}

export enum ButtonType {
  Primary = "Primary",
  Secondary = "Secondary",
  Tiny = "Tiny",
  Add = "Add",
  Remove = "Remove",
}

export enum ButtonColor {
  Green = "Green",
  Blue = "Blue",
  Light = "Light",
  Gray = "Gray",
  Red = "Red",
  Yellow = "Yellow",
}
