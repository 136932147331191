import React from "react";
import { isAndroid, isIOS, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_DARK_BLUE } from "../colors";
import { GeneralButton } from "../components/Button/";
import { LogoXpsNetworkIcon } from "../components/Icons/LogoXpsNetworkIcon";
import {
  Description,
  Title,
  TitleWrapper,
} from "../components/PasswordForm/styles";
import ScreenContainer from "../components/ScreenContainer";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 20px;
  margin-top: ${() => (isIOS && isSafari ? "-50px" : "auto")};
`;

export function DownloadMobileAppScreen() {
  const { t } = useTranslation();

  const buttonProps = {
    link: isAndroid
      ? "https://play.google.com/store/apps/details?id=com.xpsnetwork.android"
      : "https://apps.apple.com/cz/app/xps-network/id878771840",
    text: isAndroid
      ? t("downloadAppScreenDownloadButtonAndroid")
      : t("downloadAppScreenDownloadButtonIOS"),
  };

  return (
    <ScreenContainer
      style={{ backgroundColor: COLOR_DARK_BLUE, alignItems: "center" }}
    >
      <Container>
        <LogoXpsNetworkIcon style={{ width: "80%", maxWidth: 200 }} />
        <TitleWrapper
          style={{ marginTop: 40, marginBottom: 40, textAlign: "center" }}
        >
          <Title>{t("downloadAppScreenHeading")}</Title>
          <Description style={{ marginBottom: 0 }}>
            {t("downloadAppScreenSubtitle")}
          </Description>
          <Description style={{ marginTop: 4, marginBottom: 0 }}>
            {t("downloadAppScreenSecondarySubtitle")}
          </Description>
        </TitleWrapper>
        <GeneralButton
          fullWidth
          height={45}
          label={buttonProps.text}
          onClick={() => window.open(buttonProps.link, "_self")}
        />
      </Container>
    </ScreenContainer>
  );
}
