import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "./colors";

export const StyledCard = styled.div`
  background-color: ${COLOR_WHITE};
  border-radius: 12px;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
`;
