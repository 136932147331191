import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { StylesConfig } from "react-select";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_GRAY, COLOR_MEDIUM_GRAY } from "../../colors";
import { TimeFormat, useSettingsContext } from "../../contexts/settingsContext";
import { useUserContext } from "../../contexts/User";
import {
  useGetShareWithFamilyWhenOldQuery,
  useUpdatePreferencesMutation,
} from "../../graphql";
import { StyledSelect } from "../StyledSelect";
import { StyledSwitch } from "../StyledSwitch";

const SettingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0;
  gap: 5px;
`;

const LabelText = styled.span`
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOR_MEDIUM_GRAY};
`;

const ShareOptionWrap = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
`;

export function Settings() {
  const { t } = useTranslation();
  const {
    timeFormat,
    changeTimeFormat,
    language,
    changeLanguage,
    languageOptions,
    languagesFetchError,
    isLangOverrideActive,
  } = useSettingsContext();
  const { sessionId } = useUserContext();

  const [shareWithFamilyWhenOld, setShareWithFamilyWhenOld] = useState<
    boolean | null
  >(null);
  const [updatePreferences] = useUpdatePreferencesMutation();

  const { data: shareWithFamilyWhenOldData } =
    useGetShareWithFamilyWhenOldQuery({
      variables: {
        sessionId,
      },
    });

  useEffect(() => {
    if (
      shareWithFamilyWhenOldData?.UserProfile?.shareWithFamilyWhenOld !==
      undefined
    ) {
      setShareWithFamilyWhenOld(
        shareWithFamilyWhenOldData?.UserProfile?.shareWithFamilyWhenOld
      );
    }
  }, [shareWithFamilyWhenOldData?.UserProfile?.shareWithFamilyWhenOld]);

  const handleUpdateShareWithFamilyWhenOld = async (
    newShareWithFamilyWhenOld
  ) => {
    try {
      setShareWithFamilyWhenOld(newShareWithFamilyWhenOld);
      await updatePreferences({
        variables: {
          sessionId,
          input: {
            shareWithFamilyWhenOld: newShareWithFamilyWhenOld,
          },
        },
        refetchQueries: () => ["GetShareWithFamilyWhenOld"],
      });
    } catch {
      setShareWithFamilyWhenOld(!newShareWithFamilyWhenOld);
    }
  };

  const customStyles: StylesConfig = useMemo(
    () => ({
      indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "transparent",
      }),
      control: (baseStyles) => ({
        ...baseStyles,
        lineHeight: "40px",
        backgroundColor: COLOR_GRAY,
        borderColor: "transparent",
        fontWeight: 700,
        fontSize: "14px",
        fontFamily: "inherit",
      }),
      option: (baseStyles, { isSelected, isFocused }) => ({
        ...baseStyles,
        fontWeight: 700,
        lineHeight: "40px",
        borderRadius: isSelected || isFocused ? "6px" : "0",
        backgroundColor: isSelected
          ? COLOR_BLUE
          : isFocused
            ? COLOR_GRAY
            : "initial",
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        paddingLeft: "5px",
        paddingRight: "5px",
      }),
    }),
    []
  );
  const timeFormatOptions = useMemo(
    () => [
      { label: "12-hour time", value: TimeFormat.TwelveHour },
      { label: "24-hour time", value: TimeFormat.TwentyFourHour },
    ],
    []
  );

  async function handleChangeLanguage(
    value: string | string[] | null
  ): Promise<void> {
    if (Array.isArray(value) || !value?.length) return;
    await changeLanguage(value);
  }

  async function handleChangeTimeFormat(
    value: string | string[] | null
  ): Promise<void> {
    if (Array.isArray(value) || !value?.length) return;
    await changeTimeFormat(value as TimeFormat);
  }

  return (
    <SettingsWrap>
      <Label>
        <LabelText>{t("language")}</LabelText>
        <StyledSelect
          placeholder={languagesFetchError?.message}
          isDisabled={isLangOverrideActive}
          styles={customStyles}
          options={languageOptions}
          value={language}
          onChange={handleChangeLanguage}
        />
      </Label>
      <Label>
        <LabelText>{t("timeFormat")}</LabelText>
        <StyledSelect
          styles={customStyles}
          options={timeFormatOptions}
          value={timeFormat}
          onChange={handleChangeTimeFormat}
        />
      </Label>
      {shareWithFamilyWhenOld !== null ? (
        <ShareOptionWrap>
          <StyledSwitch
            value={shareWithFamilyWhenOld}
            onValueChange={(value) => handleUpdateShareWithFamilyWhenOld(value)}
            label={t("shareWithFamilyWhenOld")}
          />
        </ShareOptionWrap>
      ) : null}
    </SettingsWrap>
  );
}
