import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import BigInt from "big-integer";
import _debounce from "lodash/debounce";

import { useUserContext } from "../../contexts/User";
import {
  AgendaDocument,
  CalendarDocument,
  useRegisterQuestionnaireTestMutation,
} from "../../graphql";
import { Selection } from "../Athlete/Form/Selection";
import { StepInput } from "../Athlete/Form/StepInput";
import { TimeInput } from "../Athlete/Form/TimeInput";
import { Checkbox } from "../Checkbox";

import { NameText } from "./TrainingLoadRegistration";

const getRandomId = () =>
  BigInt.randBetween("-9.223e18", "9.223e18").toString();

export const TestItem = ({ item, questionnaireId }) => {
  const isDuration = !!item.testTemplate?.time;
  const isBoolean =
    !!item.testTemplate?.valueForTrue || !!item.testTemplate?.valueForFalse;
  const valueForTrue = item.testTemplate?.valueForTrue;
  const valueForFalse = item.testTemplate?.valueForFalse;
  const hasOptions = !!item.testTemplate?.options;

  const { timezone, sessionId, language } = useUserContext();
  const [registerQuestionnaireTestMutation, { data, error }] =
    useRegisterQuestionnaireTestMutation();

  const registeredValue = item.testRegistration?.value;
  const [registeredId, setRegisteredId] = useState<string | null>(
    item.testRegistration?.id
  );
  const [value, setValue] = useState(registeredValue);
  const valueRef = useRef(registeredValue);

  useEffect(() => {
    setValue(registeredValue);
  }, [registeredValue]);

  useEffect(() => {
    if (!registeredId) {
      setRegisteredId(getRandomId());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (
        isBoolean &&
        (valueRef.current === null || typeof valueRef.current === "undefined")
      ) {
        onCheckBoxPress(valueForFalse);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newRegisteredId =
      data?.registerQuestionnaireTest?.testRegistration.id;

    if (newRegisteredId) {
      setRegisteredId(newRegisteredId);
    }
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCheckBoxPress = useCallback(
    _debounce<any>((value) => {
      const randomId = getRandomId();
      registerQuestionnaireTestMutation({
        variables: {
          timezone,
          language: language || "",
          sessionId,
          input: {
            id: registeredId || randomId,
            templateId: item.testTemplate?.templateGuid || null,
            value,
            questionnaireId,
          },
        },
        refetchQueries: () => [CalendarDocument, AgendaDocument],
      });
    }, 1000),
    [registeredId, item, questionnaireId]
  );

  const onChange = (value) => {
    valueRef.current = value;
    setValue(value);
    if (!(isDuration && value.indexOf(":") !== -1)) {
      onCheckBoxPress(value);
    }
  };
  const options = useMemo(
    () =>
      hasOptions
        ? item.testTemplate.options.map((option) => {
            return {
              label: option.name,
              value: `${option.value}`,
            };
          })
        : [],
    [hasOptions, item.testTemplate.options]
  );

  const errorMessage = (error?.graphQLErrors?.[0] as any)?.exception
    ?.detailMessage;
  const numericalUnit = item.testTemplate?.numericalUnit;
  const isChecked = valueForTrue === +value;

  return (
    <div>
      <NameText>
        {item.testTemplate?.name} {numericalUnit ? `(${numericalUnit})` : ""}
      </NameText>
      {errorMessage ? <p>{errorMessage}</p> : null}
      {hasOptions ? (
        <Selection
          options={options}
          value={value}
          onChange={(_event, value) => onChange(value)}
        />
      ) : null}
      {/* BOOLEAN */}
      {!hasOptions && isBoolean ? (
        <Checkbox
          check={() => onChange(isChecked ? valueForFalse : valueForTrue)}
          checked={isChecked}
          text={item.testTemplate?.name}
        />
      ) : null}
      {/* NUMERICAL INPUT WITH +/- BUTTONS */}
      {!hasOptions && !isBoolean ? (
        <div>
          {!isDuration ? (
            <StepInput
              value={parseInt(value, 10)}
              onValueChange={(value) => onChange(value)}
            />
          ) : (
            <TimeInput
              onTimeChange={({ hours, minutes }) =>
                onChange(`${hours}:${minutes}`)
              }
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
