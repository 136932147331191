import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

import {
  RefetchAgendaFunc,
  RefetchAgendaFuncArgs,
} from "../../components/Agenda/model";
import { AGENDA_ITEM_HEIGHT, AGENDA_PAGE_SIZE } from "../../constants/Agenda";
import {
  AgendaQuery,
  CalendarQuery,
  useAgendaQuery,
  useCalendarQuery,
} from "../../graphql";
import { toggleGroupSelection } from "../../services/teams";
import { enforceUniqueArrayValues } from "../../utils/enforceUniqueArrayValues";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { ALL_ACCOUNTS_ID, useAccessLevelsContext } from "../accessLevels";
import { useUserContext } from "../User";

import { useAgendaContext } from "./AgendaProvider";

dayjs.extend(weekOfYear);

export type AllAgendaData = Pick<AgendaQuery, "__typename"> & {
  agenda?: Omit<AgendaQuery["agenda"], "pageInfo">;
};

export type AgendaDataContextModel = {
  agendaData: AgendaQuery;
  agendaLoading: boolean;
  allAgendaData: AllAgendaData | null;
  calendarLoading: boolean;
  isRefetchingData: boolean;
  calendarData: CalendarQuery;
  lastScrollPosition: number;
  refetchAgenda: RefetchAgendaFunc;
  onFocusedGroupChange: (newFocusedGroupId: string | undefined) => void;
  onVisibleGroupsFilterChange: (
    newSelectedGroupId: string[] | undefined
  ) => void;
  refetchAgendaHandler: (arg?: RefetchAgendaFuncArgs) => Promise<any>;
  refetchAgendaOnScrollHandler: (agendaWrapperScrollTop: number) => void;
  setLastScrollPosition: Dispatch<SetStateAction<number>>;
  setCalendarDateToCheck: Dispatch<SetStateAction<Date>>;
  calendarDateToCheck: Date;
  refetchCalendar: (args?: { from?: number; to?: number }) => void;
  onAthleteFilterChange: (newSelectedAthleteId?: string) => void;
  checkAllGroups: (type: "check" | "uncheck") => Promise<void>;
};

const AgendaDataContext = createContext<AgendaDataContextModel>(null);

export function AgendaDataContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const user = useUserContext();
  const { isTrainer, isFamilyMember, sessionId, timezone } = user;
  const { selectedAccount } = useAccessLevelsContext();

  const [allAgendaData, setAllAgendaData] = useState<AllAgendaData | null>(
    null
  );

  const [isRefetchingData, setIsRefetchingData] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [calendarDateToCheck, setCalendarDateToCheck] = useState();
  const pageCursorRef = useRef<string | null>(null);
  const hasNextPageRef = useRef<boolean>(false);
  const agendaDataItemsLength = useRef<number>(0);

  const {
    focusedAgendaGroupId,
    selectedAthleteId,
    setSelectedAthleteId,
    setFocusedAgendaGroupId,
    selectedVisibleGroupIds,
    setSelectedVisibleGroupIds,
    groupList,
  } = useAgendaContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const focusedAthleteIdFromUrl = searchParams.get("focusedAthleteId");
  const focusedGroupIdFromUrl = searchParams.get("focusedGroupId");
  const focusedUserAccount =
    isRealTrainer({ isFamilyMember, isTrainer }) &&
    selectedAccount &&
    selectedAccount.id !== ALL_ACCOUNTS_ID
      ? selectedAccount.id
      : null;

  const {
    data: agendaData,
    loading: agendaLoading,
    refetch: refetchAgenda,
  } = useAgendaQuery({
    variables: {
      focusedAthlete: focusedAthleteIdFromUrl,
      from: dayjs().startOf("day").valueOf(),
      sessionId,
      timezone: timezone || dayjs.tz.guess(),
      first: AGENDA_PAGE_SIZE,
      focusedGroup: focusedGroupIdFromUrl,
      focusedUserAccount,
    },
    skip: !sessionId,
    fetchPolicy: "network-only",
  });

  const {
    data: calendarData,
    loading: calendarLoading,
    refetch: refetchCalendar,
  } = useCalendarQuery({
    variables: {
      sessionId,
      timezone: timezone || dayjs.tz.guess(),
      from: dayjs(calendarDateToCheck)
        .startOf("month")
        .subtract(6, "day")
        .valueOf(),
      to: dayjs(calendarDateToCheck).endOf("month").add(6, "day").valueOf(),
      focusedAthlete: focusedAthleteIdFromUrl,
      focusedGroup: focusedGroupIdFromUrl,
      focusedUserAccount,
    },
    skip: !sessionId,
  });

  useEffect(() => {
    refetchCalendar({
      focusedAthlete: selectedAthleteId,
      focusedGroup: focusedAgendaGroupId,
    });

    refetchAgenda({
      focusedAthlete: selectedAthleteId,
      focusedGroup: focusedAgendaGroupId,
      first: AGENDA_PAGE_SIZE,
    });
  }, [
    calendarDateToCheck,
    focusedAgendaGroupId,
    refetchAgenda,
    refetchCalendar,
    selectedAthleteId,
  ]);

  const onAthleteFilterChange = useCallback(
    async (newSelectedAthleteId?: string) => {
      setIsRefetchingData(true);
      setSelectedAthleteId(newSelectedAthleteId);

      setAllAgendaData(null);

      if (newSelectedAthleteId && focusedAgendaGroupId) {
        onFocusedGroupChange("");
      }
      setIsRefetchingData(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSelectedAthleteId, refetchCalendar, refetchAgenda, focusedAgendaGroupId]
  );

  useEffect(() => {
    if (agendaData && agendaData.agenda && sessionId) {
      pageCursorRef.current = agendaData.agenda.pageInfo.endCursor;
      hasNextPageRef.current = agendaData.agenda.pageInfo.hasNextPage;

      if (allAgendaData && allAgendaData.agenda) {
        setAllAgendaData((prevData) => {
          if (prevData) {
            return {
              agenda: {
                edges: enforceUniqueArrayValues(
                  agendaData.agenda.edges,
                  prevData.agenda.edges,
                  true,
                  "cursor"
                ),
              },
            };
          }
          return { agenda: { edges: [...agendaData.agenda.edges] } };
        });
        agendaDataItemsLength.current =
          allAgendaData.agenda.edges.length + agendaData.agenda.edges.length;
      } else {
        setAllAgendaData(agendaData);
        agendaDataItemsLength.current = agendaData.agenda.edges.length;
      }
    } else {
      setAllAgendaData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agendaData, agendaLoading, sessionId, isRefetchingData]);

  const onVisibleGroupsFilterChange = async (
    newSelectedGroupIds: string[] | undefined
  ) => {
    if (!newSelectedGroupIds) {
      return null;
    }
    const updatedIds = [...selectedVisibleGroupIds];
    const existingIndex = updatedIds.indexOf(newSelectedGroupIds[0]);
    if (existingIndex > -1) {
      updatedIds.splice(existingIndex, 1);
    } else {
      updatedIds.push(newSelectedGroupIds[0]);
    }

    setIsRefetchingData(true);
    setAllAgendaData(null);
    setSelectedVisibleGroupIds(updatedIds);

    await toggleGroupSelection(user, [
      {
        _groupGuid: newSelectedGroupIds[0],
        _checked: existingIndex === -1,
      },
    ]);
    await Promise.all([
      refetchCalendar({}),
      refetchAgenda({
        first: AGENDA_PAGE_SIZE,
      }),
    ]);
    setIsRefetchingData(false);
  };

  const checkAllGroups = async (type: "check" | "uncheck") => {
    setIsRefetchingData(true);
    setAllAgendaData(null);
    if (type === "uncheck") {
      await toggleGroupSelection(
        user,
        [...(selectedVisibleGroupIds ?? [])]
          .filter((id) => !!id)
          .map((groupId) => ({
            _groupGuid: groupId,
            _checked: false,
          }))
      );
      setSelectedVisibleGroupIds([]);
      setSearchParams({});
    } else {
      const ids: string[] = groupList
        ?.flatMap((group) =>
          [
            group.id,
            [...(group?.subGroups ?? [])]?.map((subGroup) => subGroup.id),
          ].flatMap((id) => id)
        )
        // no duplicates or empty items
        .filter((id, index, array) => !!id && array.indexOf(id) === index);

      await toggleGroupSelection(
        user,
        ids.map((groupId) => ({
          _groupGuid: groupId,
          _checked: true,
        }))
      );
      setSelectedVisibleGroupIds([...(ids ?? [])]);
    }
    await Promise.all([
      refetchCalendar({}),
      refetchAgenda({
        first: AGENDA_PAGE_SIZE,
      }),
    ]);
    setIsRefetchingData(false);
  };

  const onFocusedGroupChange = async (
    newSelectedGroupId: string | undefined
  ) => {
    setIsRefetchingData(true);
    setFocusedAgendaGroupId(newSelectedGroupId);

    setAllAgendaData(null);

    if (newSelectedGroupId && selectedAthleteId) {
      setSelectedAthleteId("");
    }
    setIsRefetchingData(false);
  };

  useEffect(() => {
    if (
      focusedAthleteIdFromUrl ||
      (!focusedAthleteIdFromUrl && selectedAthleteId)
    ) {
      setSelectedAthleteId(focusedAthleteIdFromUrl);
    } else if (
      focusedGroupIdFromUrl ||
      (!focusedGroupIdFromUrl && focusedAgendaGroupId)
    ) {
      setFocusedAgendaGroupId(focusedGroupIdFromUrl);
    }
  }, [
    focusedAgendaGroupId,
    focusedAthleteIdFromUrl,
    focusedGroupIdFromUrl,
    selectedAthleteId,
    setFocusedAgendaGroupId,
    setSelectedAthleteId,
  ]);

  const refetchAgendaHandler = async (arg?: RefetchAgendaFuncArgs) => {
    if (arg?.updateAgendaItem?.id) {
      // update agenda item state
      const updatedItemIndex = allAgendaData?.agenda?.edges?.findIndex(
        (agendaItem) => agendaItem?.node?.id === arg.updateAgendaItem.id
      );

      if (updatedItemIndex !== undefined && updatedItemIndex > -1) {
        setAllAgendaData((current) => {
          if (!current?.agenda?.edges) {
            return current;
          }
          const updatedEdges = [...current.agenda.edges];
          const inScopeUpdatedItemIndex = current?.agenda?.edges?.findIndex(
            (agendaItem) => agendaItem?.node?.id === arg.updateAgendaItem.id
          );
          if (
            inScopeUpdatedItemIndex === undefined ||
            inScopeUpdatedItemIndex === -1
          ) {
            return current;
          }
          updatedEdges[inScopeUpdatedItemIndex] = {
            ...updatedEdges[inScopeUpdatedItemIndex],
            // @ts-ignore
            node: {
              ...updatedEdges[inScopeUpdatedItemIndex].node,
              ...arg.updateAgendaItem,
            },
          };

          return {
            ...current,
            agenda: {
              ...current.agenda,
              edges: updatedEdges,
            },
          };
        });
      }
      return null;
    } else {
      const isDeletedLastItem =
        arg?.deleteAgendaItem?.id && allAgendaData?.agenda?.edges?.length === 1;
      await refetchAgenda({
        before: isDeletedLastItem ? null : pageCursorRef.current,
        first: null,
        after: null,
      });
    }
  };

  const refetchAgendaOnScrollHandler = async (
    agendaWrapperScrollTop: number
  ) => {
    if (
      hasNextPageRef.current &&
      agendaDataItemsLength.current * AGENDA_ITEM_HEIGHT -
        agendaWrapperScrollTop <=
        1000
    ) {
      await refetchAgenda({
        after: pageCursorRef.current,
        first: AGENDA_PAGE_SIZE,
        before: null,
      });
      setLastScrollPosition(0);
    }
  };

  const values: AgendaDataContextModel = useMemo(
    () => ({
      agendaData,
      agendaLoading,
      allAgendaData,
      refetchAgenda,
      isRefetchingData,
      onFocusedGroupChange,
      onVisibleGroupsFilterChange,
      refetchAgendaHandler,
      refetchAgendaOnScrollHandler,
      lastScrollPosition,
      setLastScrollPosition,
      setCalendarDateToCheck,
      calendarDateToCheck,
      calendarData,
      refetchCalendar,
      calendarLoading,
      onAthleteFilterChange,
      checkAllGroups,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      agendaData,
      agendaLoading,
      allAgendaData,
      refetchAgenda,
      isRefetchingData,
      lastScrollPosition,
      setLastScrollPosition,
      setCalendarDateToCheck,
      calendarDateToCheck,
      calendarData,
      refetchCalendar,
      calendarLoading,
      checkAllGroups,
    ]
  );

  return (
    <AgendaDataContext.Provider value={values}>
      {children}
    </AgendaDataContext.Provider>
  );
}

export const useAgendaDataContext = () => useContext(AgendaDataContext);
