import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../colors";

import StyledText from "./StyledText";

export const Label = styled(StyledText)`
  height: 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 5px;
  text-transform: uppercase;
`;
