import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import StyledText from "../StyledText";

export const StyledInputWrapper = styled.div<{
  zIndexValue?: string;
  marginTop?: number;
  marginBottom?: number;
  centered?: boolean;
}>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  margin-bottom: ${({ marginBottom = 40 }) => marginBottom}px;
  line-height: 12px;
  width: 100%;
  position: relative;
  z-index: ${({ zIndexValue }) => zIndexValue ?? "unset"};

  ${({ centered = false }) =>
    centered
      ? `
    justify-content: center;
    align-items: center;
  `
      : ""}
`;

export const LabelText = styled(StyledText)<{
  labelColor?: string;
  topOffset?: number;
}>`
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
  color: ${({ labelColor }) => labelColor || COLOR_MEDIUM_GRAY};
  position: relative;
  top: ${({ topOffset = 0 }) => topOffset}px;
`;
