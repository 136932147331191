import React, { useEffect } from "react";

import { client, ReportDocument } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { TabsSettingsSection } from "../Report/AthleteHeaderSettings/TabsSettingsSection";

export const AddTabReportModal = ({ parentReportId, tabReports }) => {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  useEffect(() => {
    return () => {
      client.refetchQueries({
        include: [ReportDocument],
      });
    };
  }, []);

  return (
    <TabsSettingsSection
      accountId={selectedAccountId}
      parentReportId={parentReportId}
      tabs={tabReports}
    />
  );
};
