import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const BinIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3H9C8.44772 3 8 3.44772 8 4V5H13V4C13 3.44772 12.5523 3 12 3ZM7 4V5H5H3V6H5V17C5 18.1046 5.89543 19 7 19H14C15.1046 19 16 18.1046 16 17V6H18V5H16H14V4C14 2.89543 13.1046 2 12 2H9C7.89543 2 7 2.89543 7 4ZM8 8H7V16H8V8ZM10 8H11V16H10V8ZM14 8H13V16H14V8Z"
        fill={tintColor}
      />
    </svg>
  )
);
