import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSortBy, useTable } from "react-table";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";

import { DescriptionCell } from "./DescriptionCell";
import { InjuryOverviewOverlay } from "./InjuryOverviewOverlay";
import { PlayerCell } from "./PlayerCell";
import { ReturnAndOptionsCell } from "./ReturnAndOptionsCell";
import { StatusCell } from "./StatusCell";
import { Tooltip } from "./Tooltip";

import { SanitizedTrainingStatusCell, StyledHeading } from ".";

const StyledTableWrap = styled.div`
  display: block;
  margin: 0 -15px 30px -15px;
  overflow-x: scroll;
  overflow-y: hidden;
  background: ${COLOR_WHITE};
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 12px;
  table {
    border-spacing: 0;
  }
  thead {
    height: 35px;
  }
  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    text-align: left;
    font-size: 10px;
    line-height: 15px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${COLOR_MEDIUM_GRAY};
    span {
      margin-left: 5px;
    }
    :not(:first-child) {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 15px;
        margin: 10px 0 -10px 0;
        background-color: ${COLOR_GREY_ACTIVE};
      }
    }
  }
  th,
  td {
    margin: 0;
    padding: 10px;
    border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
    width: 1%;
    &.collapse {
      width: 0.0000000001%;
    }
    :last-child {
      border-right: 0;
    }
  }
`;

const TableHeaderColumnWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const Triangle = styled.div<{ isDown?: boolean }>`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: ${({ isDown }) =>
    isDown ? 0 : `5px solid ${COLOR_MEDIUM_GRAY}`};
  border-top: ${({ isDown }) =>
    isDown ? `5px solid ${COLOR_MEDIUM_GRAY}` : 0};
`;

export default function PlayerSummaryTable({
  sanitizedData,
}: {
  sanitizedData: SanitizedTrainingStatusCell[];
}) {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        header: t("player"),
        accessor: "playerCol",
        Cell: (props) => (
          <PlayerCell
            name={props?.value}
            thumbnail={props?.row?.original?.thumb}
          />
        ),
      },
      {
        header: t("status"),
        accessor: "statusCol",
        Cell: (props) => (
          <Tooltip
            component={
              <InjuryOverviewOverlay
                problems={props?.row?.original?.allProblems}
              />
            }
          >
            <StatusCell
              status={props?.value}
              severity={props?.row?.original?.severity}
            />
          </Tooltip>
        ),
      },
      {
        header: t("description"),
        accessor: "descCol",
        Cell: (props) => (
          <DescriptionCell
            value={props?.value}
            comment={props?.row?.original?.comment}
          />
        ),
      },
      {
        header: t("returnToTraining"),
        accessor: "returnCol",
        Cell: (props) => <ReturnAndOptionsCell value={props?.value} />,
      },
    ],
    [t]
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: sanitizedData,
        initialState: {
          // it's following the official documentation
          // @ts-ignore
          sortBy: [
            {
              id: "playerCol",
              desc: false,
            },
          ],
        },
        sortTypes: {
          alphanumeric: (row1, row2, columnName) => {
            return (row1.values[columnName] as string)?.localeCompare(
              row2.values[columnName],
              i18n.language
            );
          },
        },
      },
      useSortBy
    );

  return (
    <>
      <StyledHeading>{t("playerSummary")}</StyledHeading>
      <StyledTableWrap>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((hg) => (
              <tr {...hg.getHeaderGroupProps()}>
                {hg.headers.map((column: any) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <TableHeaderColumnWrap>
                      {column.render("header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Triangle />
                        ) : (
                          <Triangle isDown />
                        )
                      ) : (
                        ""
                      )}
                    </TableHeaderColumnWrap>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </StyledTableWrap>
    </>
  );
}
