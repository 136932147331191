import React, { useEffect } from "react";
import { useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREEN_SELECTED, COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { useAccessLevelsContext } from "../../../contexts/accessLevels/index";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import { TreeType, Document } from "../../../graphql";
import { useDocumentsLazyQuery } from "../../../graphql/generated";

import { ItemTypes, PracticeLine } from "./model";

type DroppableAreaProps = {
  id?: string;
};

const DropWrapper = styled.div<{ isActive: boolean }>`
  border: 1px dashed
    ${({ isActive }) =>
      isActive ? COLOR_GREEN_SELECTED : COLOR_TEXT_TABLE_LABEL};
  border-radius: 12px;
  height: 25px;
  padding: 15px;
  margin: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const DropLabel = styled.span<{ isActive: boolean }>`
  font-size: 18px;
  color: ${({ isActive }) =>
    isActive ? COLOR_GREEN_SELECTED : COLOR_TEXT_TABLE_LABEL};
`;

export function DroppableArea({ id }: DroppableAreaProps) {
  const { t } = useTranslation();
  const { selectedAccount } = useAccessLevelsContext();

  const [fetchDocumentList, { data: documentListData }] =
    useDocumentsLazyQuery();
  const { setPracticeLines, practiceLines } = usePracticeContext();

  const onQueryDocuments = async (folderId: string, collectionId: string) => {
    return await fetchDocumentList({
      variables: {
        collectionType: TreeType.Folders,
        collectionId: `false.${collectionId}.${selectedAccount?.id}`,
        folder: folderId,
      },
    });
  };

  const setSplitPracticeLines = (document: Document[]) => {
    const updatedPracticeLines = practiceLines?.map(
      (practiceLine: PracticeLine) => {
        if (
          "subPractices" in practiceLine &&
          practiceLine?.subPractices?.length
        ) {
          const updatedSubPractices = practiceLine?.subPractices?.map(
            (subPractice) => {
              if (subPractice.id === id) {
                return {
                  ...subPractice,
                  practiceLines: [...subPractice?.practiceLines, ...document],
                };
              }

              return subPractice;
            }
          );

          return { ...practiceLine, subPractices: updatedSubPractices };
        }

        return practiceLine;
      }
    );

    setPracticeLines(updatedPracticeLines as PracticeLine[]);
  };

  useEffect(
    function storeFetchedDocuments() {
      const documentList =
        documentListData?.user?.collection?.tree?.documents?.edges;

      if (documentList?.length) {
        const mappedDocument = documentList.map(({ node }) => ({
          ...node,
          localDuration: 10,
        }));

        if (id) {
          setSplitPracticeLines(mappedDocument);
        } else {
          setPracticeLines([...practiceLines, ...mappedDocument]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentListData]
  );

  const handleDrop = (item) => {
    if (item?.folder) {
      onQueryDocuments(item?.folder?.id, item?.collectionId);
    } else {
      if (id) {
        setSplitPracticeLines([{ ...item?.file, localDuration: 10 }]);
      } else {
        setPracticeLines([
          ...practiceLines,
          { ...item?.file, localDuration: 10 },
        ]);
      }
    }
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [ItemTypes.FILE, ItemTypes.FOLDER],
    drop: handleDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = canDrop && isOver;

  return (
    <DropWrapper isActive={isActive} ref={drop}>
      <DropLabel isActive={isActive}>
        {isActive ? t("releaseToDrop") : t("dragItemHere")}
      </DropLabel>
    </DropWrapper>
  );
}
