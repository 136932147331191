// All adjustments of the grid size should be done here by changing dimensions of the grid
// On XL displays we use this count of columns
export const MAX_COLUMN_COUNT = 10;

export const LAYOUT_SIZE = 60;

export const DEFAULT_ROW_HEIGHT = 100;

export const DEFAULT_VERTICAL_ROW_MARGIN = 10;

export const MIN_ROW_COUNT = LAYOUT_SIZE / MAX_COLUMN_COUNT;
