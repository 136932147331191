import React, { useCallback, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLACK,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { useDebounceHandler } from "../../hooks/useDebounceHandler";
import { CrossFilledIcon, SearchIcon } from "../Icons";
import { StyledTextInput } from "../StyledTextInput";

const Container = styled.div`
  display: flex;
  background-color: ${COLOR_GREY_ACTIVE};
  border-radius: 6px;
  margin: 10px 20px 20px 10px;
  padding: 10px 10px 10px 15px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  height: 45px;
`;

const SearchIconWrapper = styled.div`
  margin-right: 13px;
`;

const SearchField = styled(StyledTextInput)`
  color: ${COLOR_TEXT_TABLE_LABEL};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  color: ${COLOR_BLACK};
  background-color: transparent;
  padding: 0;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
`;

export function Search({
  searchValue,
  setSearchValue,
}: {
  searchValue?: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}) {
  const { t } = useTranslation();
  const { inputValue, setInputValue } = useDebounceHandler({
    value: searchValue,
    setValue: setSearchValue,
  });

  const handleClearPress = useCallback(() => {
    setSearchValue("");
  }, [setSearchValue]);

  return (
    <Container>
      <SearchIconWrapper>
        <SearchIcon width={19} height={19} />
      </SearchIconWrapper>
      <SearchField
        value={inputValue}
        onChangeText={setInputValue}
        placeholder={t("search")}
        placeholderTextColor={COLOR_TEXT_TABLE_LABEL}
      />
      {!!searchValue && (
        <CrossIconWrapper onClick={handleClearPress}>
          <CrossFilledIcon style={{ width: 15, height: 15 }} />
        </CrossIconWrapper>
      )}
    </Container>
  );
}
