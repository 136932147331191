import React, { createContext, ReactNode, useCallback } from "react";

import { Tab } from "../../components/Tabs";
import { ReportType, useReportTemplatesQuery } from "../../graphql";
import { useAutoSelectAthlete } from "../../hooks/report/useAutoSelectAthlete";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks/useGetSelectedAccountIdInMonitoring";
import { useReportsContext } from "../Reports";
import { useUserContext } from "../User";

import { useAthleteProfileContext } from "./index";

type OnRerouteCallback = (athleteId: string) => void;

type ApRerouteContextType = {
  onReroute: OnRerouteCallback | null;
};

export const ApRerouteContext = createContext<ApRerouteContextType>(null);

interface ApRerouteProviderProps {
  children: ReactNode;
}

export const ApRerouteProvider = (props: ApRerouteProviderProps) => {
  const { sessionId, language } = useUserContext();
  const { selectTab, groupTabs } = useAthleteProfileContext();
  const { sortedAthletes } = useAutoSelectAthlete();
  const actions = useReportsContext()[1];

  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { data: { reportTemplates } = {} } = useReportTemplatesQuery({
    variables: {
      sessionId,
      language,
      accountId: selectedAccountId,
    },
  });

  const callback = useCallback(
    (athleteId: string) => {
      const athleteTabs = groupTabs.filter((item: Tab) => {
        const foundReport = reportTemplates?.find(
          (report) => report.id === item.id
        );
        return (
          foundReport && foundReport.reportType === ReportType.AthleteReport
        );
      });
      if (athleteTabs.length) {
        const selectedAthlete = sortedAthletes.find(
          (athlete) => athlete.id === athleteId
        );
        if (selectedAthlete) {
          // Select first athlete report
          selectTab(athleteTabs[0]);
          actions.selectAthlete(selectedAthlete);
        }
      }
    },
    [selectTab, sortedAthletes, actions, groupTabs, reportTemplates]
  );

  return (
    <ApRerouteContext.Provider value={{ onReroute: callback }}>
      {props.children}
    </ApRerouteContext.Provider>
  );
};
