import React, { CSSProperties, memo } from "react";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_SECONDARY_RED,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { useSettingsContext } from "../../contexts/settingsContext";
import { Chatter } from "../../graphql";
import Avatar from "../Avatar";
import Hoverable from "../Hoverable";
import { AnnouncementIcon, MuteIcon, RoundedGroupProfileIcon } from "../Icons";
import StyledText from "../StyledText";

import { formatRelativeDate } from "./utils";

const CellContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 16px;
  width: calc(100% - 72px);
`;

type ChatterItemProps = {
  item: Chatter;
  onRowPress: (item) => void;
  isActive: boolean;
};

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 30px;
`;

const Container = styled.div`
  overflow: hidden;
  cursor: pointer;
  height: 85px;
  align-items: center;
  justify-content: center;
`;

const ChatterItem = ({ item, onRowPress, isActive }: ChatterItemProps) => {
  const { timeFormatTemplate } = useSettingsContext();
  const isAnnouncement = !!item?.allFriendsOf?.id;
  const isTeam = !!item.linkedGroup || !!item?.allFriendsOf?.id;

  const groupHasOwnerAvatar = isTeam && item?.groupOwner?.thumb;
  const isGroupChat =
    item.chatterType === "ChatGroup" || item.chatterType === "Group";

  const rightTitle =
    item.lastChatTime &&
    formatRelativeDate(item.lastChatTime, timeFormatTemplate);
  const showDefaultGroupIcon = isGroupChat && !groupHasOwnerAvatar;

  return (
    <Container
      id={"chatListItem" + item.id}
      test-id={"chatListItem" + item.id}
      onClick={() => onRowPress(item)}
    >
      <Hoverable>
        {(hovered) => (
          <div
            style={{
              overflow: "hidden",
              ...styles.cell,
              ...(hovered ? styles.cellHovered : {}),
              ...(isActive ? { backgroundColor: "#1b72d4" } : {}),
            }}
          >
            {showDefaultGroupIcon ? (
              isAnnouncement ? (
                <AnnouncementIcon width={40} height={40} />
              ) : (
                <RoundedGroupProfileIcon width={40} height={40} />
              )
            ) : (
              <Avatar
                size={40}
                userGuid={
                  !showDefaultGroupIcon
                    ? (isGroupChat ? item?.groupOwner?.id : item?.id) || ""
                    : ""
                }
                isThumb={true}
                userName={item.name || ""}
                uri={
                  !showDefaultGroupIcon
                    ? (isGroupChat
                        ? item?.groupOwner?.thumb
                        : item?.avatarThumbURL) || ""
                    : ""
                }
              />
            )}
            <CellContent>
              <div>
                <div style={styles.listItemTitleContainer}>
                  <StyledText
                    fontWeight={
                      item.unreadByMeInGroup || isActive ? "bold" : 600
                    }
                    numberOfLines={1}
                    fontSize={16}
                    style={{
                      ...styles.title,
                      ...(isActive ? { color: COLOR_WHITE } : {}),
                    }}
                  >
                    {item.name}
                  </StyledText>
                  <StyledText
                    numberOfLines={1}
                    style={{
                      ...styles.rightTitleStyle,
                      ...(isActive ? { color: COLOR_WHITE } : {}),
                    }}
                  >
                    {rightTitle}
                  </StyledText>
                </div>
              </div>
              <div>
                <div style={styles.listItemTitleContainer}>
                  <StyledText
                    numberOfLines={1}
                    fontSize={14}
                    fontWeight={
                      item.unreadByMeInGroup || isActive ? "bold" : "normal"
                    }
                    color={
                      item.unreadByMeInGroup
                        ? COLOR_TEXT_DEFAULT
                        : COLOR_MEDIUM_GRAY
                    }
                    style={{
                      ...styles.subtitle,
                      ...(isActive ? { color: COLOR_WHITE } : {}),
                    }}
                  >
                    {item.lastMessage}
                  </StyledText>
                  <IconsWrapper>
                    {item.muted ? (
                      <MuteIcon
                        color={isActive ? COLOR_WHITE : COLOR_MEDIUM_GRAY}
                      />
                    ) : null}
                    {item.unreadByMeInGroup ? (
                      <div style={styles.circle} />
                    ) : null}
                  </IconsWrapper>
                </div>
              </div>
            </CellContent>
          </div>
        )}
      </Hoverable>
    </Container>
  );
};

export default memo(ChatterItem);

const styles: Record<string, CSSProperties> = {
  circle: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 4,
    backgroundColor: COLOR_SECONDARY_RED,
    marginLeft: 4,
  },
  cell: {
    borderRadius: 6,
    margin: 5,
    marginTop: 0,
    padding: 15,
    display: "flex",
    flex: 1,
    flexDirection: "row",
  },
  cellHovered: {
    backgroundColor: COLOR_GRAY,
  },
  listItemTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  title: {
    paddingBottom: "2px",
    letterSpacing: -0.16,
    fontFamily: "Inter-bold",
  },
  subtitle: {
    paddingTop: "6px",
    letterSpacing: -0.14,
    lineHeight: "16px",
    fontFamily: "Inter-regular",
  },
  avatarContainer: {
    marginRight: -1,
    height: 40,
    width: 40,
    overflow: "visible",
  },
  rightTitleStyle: {
    fontSize: "9px",
    color: COLOR_SECONDARY_GREY,
    lineHeight: "11px",
    fontFamily: "Inter-regular",
    marginLeft: 10,
    overflow: "visible",
  },
};
