import React from "react";

import { COLOR_BODY_SECTION, COLOR_BODY_SECTION_FILL } from "../../colors";

import { BodyPartsType } from "./model";
import { SECTION_CONFIG } from "./SectionConfig";

type BodySectionProps = {
  sectionId: BodyPartsType;
  side: string;
  scale: number;
  focusedSection: BodyPartsType;
  onBodySectionClick: (sectionId: BodyPartsType) => void;
};

export function BodySection({
  sectionId,
  side,
  scale,
  focusedSection,
  onBodySectionClick,
}: BodySectionProps) {
  return (
    <g
      origin={SECTION_CONFIG[focusedSection]?.origin}
      fill="none"
      scale={scale}
      {...SECTION_CONFIG[sectionId].zoomProps[side]}
    >
      <circle
        onClick={() => onBodySectionClick(sectionId)}
        cx="27"
        cy="27"
        r="27"
      />
      <circle
        onClick={() => onBodySectionClick(sectionId)}
        stroke={COLOR_BODY_SECTION}
        fill={COLOR_BODY_SECTION_FILL}
        cx="26.5"
        cy="26.5"
        r="8.5"
      />
    </g>
  );
}
