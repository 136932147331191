import React from "react";
import { useSearchParams } from "react-router-dom";

import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { SessionDetail } from "../components/Session/SessionDetail";

export default () => {
  const [params] = useSearchParams();
  const sessionGuid = params.get("sessionGuid") ?? null;

  return (
    <ScreenContainer>
      <Header />
      <SessionDetail sessionGuid={sessionGuid} />
    </ScreenContainer>
  );
};
