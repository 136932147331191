import React from "react";

import StyledText from "../../StyledText";

import { NoDataTextWrapper } from "./styled";

export function EmptyList({ label }: { label: string }) {
  return (
    <NoDataTextWrapper>
      <StyledText>{label}</StyledText>
    </NoDataTextWrapper>
  );
}
