import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_WHITE,
} from "../../../colors";

import { COUNT_OF_TRAINING_DAYS } from "./TrainingDays";

const TrainingDayWrapper = styled.button<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  border-radius: 50%;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_GREEN : "transparent"};
  width: 35px;
  height: 35px;
  border-width: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TrainingDayLabel = styled.p<{ isSelected: boolean }>`
  color: ${({ isSelected }) =>
    isSelected ? COLOR_WHITE : COLOR_SECONDARY_GREY_HOVER};
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 600;
  margin: 0;
`;

export const TrainingDay = ({
  days,
  day,
  dayIndex,
  onDayPress,
  dayOfWeekIndex,
}) => {
  const onDayOfWeekForDate =
    dayIndex === 0 && dayOfWeekIndex < days[dayIndex].dayOfWeek
      ? dayOfWeekIndex + COUNT_OF_TRAINING_DAYS
      : dayOfWeekIndex;
  const date = dayjs(day.date.clone().day(onDayOfWeekForDate));
  const isSelected = days[dayIndex].dayOfWeek === dayOfWeekIndex;

  return (
    <TrainingDayWrapper
      key={dayOfWeekIndex}
      isSelected={isSelected}
      onClick={() => onDayPress(dayIndex, dayOfWeekIndex, date)}
    >
      <TrainingDayLabel isSelected={isSelected}>
        {date.format("ddd").toUpperCase()}
      </TrainingDayLabel>
    </TrainingDayWrapper>
  );
};
