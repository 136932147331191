import { StorageKeys } from "./storageKeys";

export default class Storage {
  static setItem(
    key: StorageKeys,
    value: any,
    storage = window.localStorage
  ): void {
    try {
      const jsonValue = JSON.stringify(value);
      storage.setItem(key, jsonValue);
    } catch (error) {
      console.error(`Error setting item ${key} in storage: ${error}`);
      throw error;
    }
  }

  static setItemSession(
    key: StorageKeys,
    value: any,
    storage = window.localStorage
  ): void {
    try {
      const jsonValue = JSON.stringify(value);
      storage.setItem(key, jsonValue);
    } catch (error) {
      console.error(`Error setting item ${key} in session storage: ${error}`);
      throw error;
    }
  }

  static getItem<T>(key: StorageKeys, storage = window.localStorage): T | null {
    try {
      const jsonValue = storage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (error) {
      console.error(`Error getting item ${key} from storage: ${error}`);
      throw error;
    }
  }

  static getItemSession<T>(
    key: StorageKeys,
    storage = window.localStorage
  ): T | null {
    try {
      const jsonValue = storage.getItem(key);
      return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (error) {
      console.error(`Error getting item ${key} from session storage: ${error}`);
      throw error;
    }
  }

  static removeItem(key: string, storage = window.localStorage): void {
    try {
      storage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item ${key} from storage: ${error}`);
      throw error;
    }
  }

  static clear(storage = window.localStorage): void {
    try {
      storage.clear();
    } catch (error) {
      console.error(`Error clearing storage: ${error}`);
      throw error;
    }
  }
}
