import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY,
} from "../../../colors";
import { WhoCanPost } from "../../../graphql";

const MainCircle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${COLOR_GRAY};
  width: 21px;
  height: 21px;
  border: 1px solid ${COLOR_GREY_ACTIVE};
  border-radius: 100%;
  cursor: pointer;
  &: hover {
    border-color: ${COLOR_SECONDARY_GREY};
  }
`;

const CheckedCircle = styled.div`
  background: ${COLOR_BLUE};
  width: 13px;
  height: 13px;
  border-radius: 100%;
`;

const RadionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

const RadioButtonLabel = styled.span`
  font-weight: 700;
  font-size: 12px;
  margin-left: 10px;
  align-self: center;
  cursor: default;
`;

export const RadioButton = ({ text, checked, check }) => {
  return (
    <RadionButtonWrapper onClick={check}>
      <MainCircle>{checked && <CheckedCircle />}</MainCircle>
      <RadioButtonLabel>{text}</RadioButtonLabel>
    </RadionButtonWrapper>
  );
};

export const PostRestriction = ({
  whoCanPost,
  onWhoCanPostUpdate,
}: {
  whoCanPost: WhoCanPost;
  onWhoCanPostUpdate?: (whoCanPost: WhoCanPost) => unknown;
}) => {
  const { t } = useTranslation();

  const handleEveryoneCanPost = useCallback(
    () => onWhoCanPostUpdate(WhoCanPost.All),
    [onWhoCanPostUpdate]
  );
  const handleAdminsCanPost = useCallback(
    () => onWhoCanPostUpdate(WhoCanPost.Admins),
    [onWhoCanPostUpdate]
  );
  const handleTrainersCanPost = useCallback(
    () => onWhoCanPostUpdate(WhoCanPost.Trainers),
    [onWhoCanPostUpdate]
  );

  return (
    <>
      <RadioButton
        text={t("withoutLimitation")}
        checked={whoCanPost === WhoCanPost.All}
        check={handleEveryoneCanPost}
      />
      <RadioButton
        text={t("adminLimitation")}
        checked={whoCanPost === WhoCanPost.Admins}
        check={handleAdminsCanPost}
      />
      <RadioButton
        text={t("coachLimitation")}
        checked={whoCanPost === WhoCanPost.Trainers}
        check={handleTrainersCanPost}
      />
    </>
  );
};
