import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { getAccounts } from "../../utils/switchAccounts";
import { ButtonColor, GeneralButton } from "../Button/";
import StyledText from "../StyledText";

const Container = styled.div`
  > * {
    display: block;
  }
  max-width: 530px;
  margin-inline: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;
  justify-content: flex-end;
`;

export const ConfirmAccountSwitchModal = ({ onConfirm, closeModal }) => {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const user = useUserContext();

  const onRememberAccount = () => {
    const accounts = getAccounts();
    const thisAccountIndex = accounts?.findIndex(
      (account) => account.sessionId === sessionId
    );
    if (thisAccountIndex > -1) {
      const newAccounts = [...accounts];
      newAccounts[thisAccountIndex].isTemporary = false;
      Storage.setItem(StorageKeys.accountsToSwitch, newAccounts);
    }
    onConfirm();
    closeModal();
  };

  const onDoNotRememberAccount = () => {
    onConfirm();
    closeModal();
  };

  return (
    <Container>
      <StyledText fontSize={16} fontWeight="bold" style={{ marginBottom: 10 }}>
        {t("confirmationTitleDescription", {
          x: `${user.firstName} ${user.lastName}`,
        })}
      </StyledText>
      <ButtonsContainer>
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={closeModal}
        />
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("doNotRemember")}
          outlined
          onClick={onDoNotRememberAccount}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("rememberAccount")}
          onClick={onRememberAccount}
        />
      </ButtonsContainer>
    </Container>
  );
};
