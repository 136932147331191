import React from "react";

import Color from "color";

const defaultCircleTextColor = "#777";

type CircleProgressProps = {
  progress: number;
  text?: string | number;
  size: number;
  strokeWidth?: string | number;
  radius?: number;
  isHalf?: boolean;
  textSize?: string | number;
  useColorForText?: boolean;
  diff?: number;
  style?: React.CSSProperties;
  color?: string;
};

export function CircleProgress({
  progress,
  text: propsText,
  size,
  strokeWidth,
  radius: propsRadius = null,
  isHalf = false,
  textSize = 18,
  useColorForText = false,
  diff,
  style,
  color: propsColor,
}: CircleProgressProps) {
  const halfSize = size / 2;
  const hasDiff = diff < 0 || diff > 0;
  const progressText =
    typeof propsText !== "undefined" ? propsText : Math.round(progress);
  const diffColor = diff > 0 ? "#0f0" : diff < 0 ? "#f00" : undefined;
  const diffSign = diff > 0 ? "+" : undefined;
  const textProps: Partial<React.SVGProps<SVGTextElement>> = {
    x: halfSize,
    y:
      halfSize -
      /* move text 10px up so it's still centered */ (hasDiff ? 10 : 0),
    textAnchor: "middle",
    alignmentBaseline: "central",
  };
  const color = propsColor ? "#" + propsColor : getBarColor(progress);
  const textColor = useColorForText ? color : defaultCircleTextColor;
  const radius =
    propsRadius === null ? halfSize - Number(strokeWidth) : propsRadius;

  return (
    <svg width={size} height={size} style={style}>
      <SvgCircle
        startAtHour={isHalf ? 6 : 12}
        progress={isHalf ? -50 : 100}
        r={radius}
        cx={halfSize}
        cy={halfSize}
        stroke="#f2f2f2"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <SvgCircle
        progress={isHalf ? -Math.abs(progress / 2) : progress}
        startAtHour={isHalf ? 6 : 12}
        r={radius}
        cx={halfSize}
        cy={halfSize}
        stroke={color}
        strokeWidth={strokeWidth}
        fill="none"
      />
      <text
        fill={textColor}
        fontSize={textSize}
        fontWeight={300}
        {...textProps}
      >
        {progressText}
      </text>
      {hasDiff && (
        <text
          dy={Number(textSize) / 2 + 10}
          fill={diffColor}
          fontSize={"13px"}
          {...textProps}
        >
          {diffSign}
          {diff}
        </text>
      )}
    </svg>
  );
}

function SvgCircle({
  progress = 100,
  startAtHour,
  ...rest
}: {
  progress?: number;
  startAtHour: number;
} & React.SVGProps<SVGCircleElement>) {
  const length = 2 * Math.PI * Number(rest.r);
  const rotDeg = hoursToDeg(startAtHour);
  const props = {
    ...rest,
    transform: rotDeg ? `rotate(${rotDeg}, ${rest.cx}, ${rest.cy})` : null,
    strokeDasharray: length,
    strokeDashoffset: length - length * (progress / 100),
  };

  return <circle {...props} />;
}

function getBarColor(progress: number) {
  const max = Color("#4dcc10");
  const min = Color("#f55140");
  const ratio = (progress + 100) / 200;

  return max.mix(min, ratio).hex();
}

function hoursToDeg(hour: number) {
  return -90 + hour * 30;
}
