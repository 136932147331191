import { useMemo } from "react";

import dayjs from "dayjs";
import _groupBy from "lodash/groupBy";

import { CommonAttributesIds } from "../../constants";
import { useReportContext } from "../../contexts/report";
import { useUserContext } from "../../contexts/User";
import {
  AggregateFunction,
  ReportWidgetTemplateHeader,
  XpsUser,
} from "../../graphql";
import { getCookingValue, UseInformations2022QueryArgs } from "../../services";
import {
  useAthleteStatisticsQuery,
  UseAthleteStatisticsQueryArgs,
  useInformations2022Query,
  useXpsMagicInfoStatisticsQuery,
  UseXpsMagicInfoStatisticsQueryArgs,
} from "../../services/statistics/hooks";
import {
  isInformationCollection,
  isXpsMagicCollection,
} from "../../utils/reports";
import {
  getDataKey,
  getValidUnitName,
  getValidUnitNameFromReportWidgetCell,
} from "../../utils/statistics";

type UseAthleteHeaderDataProps = {
  headerTemplate?: ReportWidgetTemplateHeader;
  athlete: XpsUser;
};

type DataValueType = [
  string,
  {
    name: string;
    value: string;
    unit: string;
  },
];

type AthleteHeaderDataItem = {
  label: string;
  value: string;
};

export function useAthleteHeaderData({
  athlete,
  headerTemplate,
}: UseAthleteHeaderDataProps) {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();

  const { cells, statisticsCells, informationCells, xpsMagicInfoCells } =
    useMemo(() => {
      const { cell1, cell2, cell3, cell4, cell5, cell6 } = headerTemplate ?? {};
      const cells = [cell1, cell2, cell3, cell4, cell5, cell6].filter(
        (w) => !!w
      );
      const {
        informationCells = [],
        xpsMagicInfoCells = [],
        statisticsCells = [],
      } = _groupBy(cells, (cell) => {
        if (isInformationCollection(cell.collectionId)) {
          return "informationCells";
        }
        if (isXpsMagicCollection(cell.collectionId)) {
          return "xpsMagicInfoCells";
        }

        return "statisticsCells";
      });

      return { cells, statisticsCells, informationCells, xpsMagicInfoCells };
    }, [headerTemplate]);

  const athleteStatisticsQueryArgs =
    useMemo<UseAthleteStatisticsQueryArgs>(() => {
      const fromDate = dayjs().subtract(1, "year").startOf("day");
      const toDate = dayjs().endOf("day");

      return {
        fromDate,
        toDate,
        period: "continuous",
        session: { sessionId },
        items: statisticsCells.map((cell) => ({
          dataType: cell.collectionId,
          attribute:
            cell.__typename === "ReportWidgetCellNumerical"
              ? cell.attributeTemplate.id
              : CommonAttributesIds.TEXT_ATTRIBUTE_ID,
          templateGuid: cell.templateId,
          aggregation: [AggregateFunction.LatestForever],
        })),
        focusedAthlete: athlete?.id,
        participants: [athlete],
        reportTemplateId: report?.id,
      };
    }, [athlete, sessionId, statisticsCells, report?.id]);

  const informationQueryArgs = useMemo<UseInformations2022QueryArgs>(() => {
    return {
      athleteGuid: athlete?.id,
      templates: informationCells.map((infoCell) => ({
        guid: infoCell.templateId,
        cooking: getCookingValue(AggregateFunction.LatestForever),
      })),
      session: { sessionId },
      reportTemplateId: report?.id,
    };
  }, [athlete?.id, sessionId, informationCells, report?.id]);

  const xpsMagicInfoQueryArgs = useMemo(() => {
    const result: UseXpsMagicInfoStatisticsQueryArgs = {
      athleteIds: [athlete?.id],
      reportTemplateId: report?.id,
      templates: xpsMagicInfoCells.map((xpsMagicInfoCell) => ({
        id: xpsMagicInfoCell.templateId.slice(
          xpsMagicInfoCell.templateId.indexOf(".") + 1
        ),
      })),
    };

    return result;
  }, [athlete?.id, report?.id, xpsMagicInfoCells]);

  const {
    data: statisticsData,
    isLoading: statisticsIsLoading,
    fetchStatus: statisticsFetchStatus,
  } = useAthleteStatisticsQuery(athleteStatisticsQueryArgs, {
    enabled: athlete?.id && statisticsCells.length > 0,
  });

  const {
    data: informationResponse,
    isLoading: informationIsLoading,
    fetchStatus: informationFetchStatus,
  } = useInformations2022Query(informationQueryArgs, {
    queryKey: [athlete?.id, ...informationCells.map((cell) => cell.id)],
    enabled: athlete?.id && informationCells.length > 0,
  });

  const {
    data: xpsMagicInfoResponse,
    isLoading: xpsMagicInfoIsLoading,
    fetchStatus: xpsMagicInfoFetchStatus,
  } = useXpsMagicInfoStatisticsQuery(xpsMagicInfoQueryArgs, {
    queryKey: [
      athlete?.id,
      ...xpsMagicInfoCells.map((cell) => cell.templateId),
    ],
    enabled: athlete?.id && xpsMagicInfoCells.length > 0,
  });

  const statisticsParsedData = useMemo<DataValueType[]>(() => {
    const athleteStats = statisticsData?._athletes?.find(
      (athleteStats) => athleteStats._guid === athlete?.id
    );

    // parse stats
    const dataValues: DataValueType[] = [
      ...(athleteStats?._tests ?? []),
    ].flatMap((measurement) =>
      (measurement._attributes ?? []).map<DataValueType>((attribute) => {
        const id = getDataKey(
          measurement._guid,
          attribute._guid,
          measurement._agg
        );
        const name = measurement._name;
        const hasTextValue = attribute._values[0]?._txt !== undefined;

        const value =
          (hasTextValue
            ? attribute._values[0]?._txt
            : attribute._values[0]?._val) ?? 0;
        const unit = hasTextValue ? "" : getValidUnitName(attribute._unitName);

        return [id, { name, value: value.toString(), unit }];
      })
    );

    return dataValues;
  }, [statisticsData, athlete?.id]);

  const informationParsedData = useMemo<DataValueType[]>(() => {
    return informationCells.map<DataValueType>((cell) => {
      const { name, items } =
        informationResponse?.data?.templates?.find(
          (template) => template.templateGuid === cell.templateId
        ) ?? {};

      const id = getDataKey(
        cell.templateId,
        CommonAttributesIds.TEXT_ATTRIBUTE_ID,
        AggregateFunction.LatestForever
      );

      return [id, { name, value: items?.[0]?._value ?? "", unit: "" }];
    });
  }, [informationResponse, informationCells]);

  const xpsMagicInfoParsedData = useMemo<DataValueType[]>(() => {
    return xpsMagicInfoCells.map<DataValueType>((cell) => {
      const value =
        xpsMagicInfoResponse?.data?.byAthletes?.[0]?.templates?.find(
          (template) =>
            template.id ===
            cell.templateId.slice(cell.templateId.indexOf(".") + 1)
        );

      const id = getDataKey(
        cell.templateId,
        CommonAttributesIds.TEXT_ATTRIBUTE_ID,
        AggregateFunction.LatestForever
      );

      return [
        id,
        { name: value?.name ?? value?.id, value: value?.value ?? "", unit: "" },
      ];
    });
  }, [xpsMagicInfoCells, xpsMagicInfoResponse?.data?.byAthletes]);

  const data = useMemo(() => {
    const dataMap = new Map([
      ...(statisticsParsedData ?? []),
      ...(informationParsedData ?? []),
      ...(xpsMagicInfoParsedData ?? []),
    ]);

    return cells.map<AthleteHeaderDataItem>((cell) => {
      const {
        name: label = cell.name,
        value,
        unit = getValidUnitNameFromReportWidgetCell(cell),
      } = dataMap.get(
        getDataKey(
          cell.templateId,
          cell.__typename === "ReportWidgetCellNumerical"
            ? cell.attributeTemplate.id
            : CommonAttributesIds.TEXT_ATTRIBUTE_ID,
          AggregateFunction.LatestForever
        )
      ) ?? {};

      return {
        label,
        value: `${value ?? " "}${value && unit ? ` ${unit}` : ""}`,
      };
    });
  }, [
    statisticsParsedData,
    informationParsedData,
    xpsMagicInfoParsedData,
    cells,
  ]);

  return {
    loading:
      (statisticsFetchStatus !== "idle" && statisticsIsLoading) ||
      (informationFetchStatus !== "idle" && informationIsLoading) ||
      (xpsMagicInfoFetchStatus !== "idle" && xpsMagicInfoIsLoading),
    data,
  };
}
