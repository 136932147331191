import React from "react";

import dayjs from "dayjs";
import { Formik } from "formik";

import { useUserContext } from "../../../../contexts/User";
import { ConnectedFocusError } from "../../../FormikFocusError";
import { AppearanceSection } from "../../../ReportCreateTable/AppearanceSection";
import {
  ActivityLogWidgetModel,
  WidgetType,
} from "../../../ReportCreateTable/models";
import { PreviewButtonSection } from "../../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../../styled";
import { WidgetEditorTitleSection } from "../../WidgetEditorTitleSection";
import { ActivityWidgetPreview } from "../Widget/Preview";

import { DataSection } from "./DataSection";
import { ActivityLogWidgetFormValue } from "./model";

interface ActivityLogWidgetEditorProps {
  widget: ActivityLogWidgetModel;
  isSavingWidget: boolean;
  onSubmit: (values: ActivityLogWidgetModel) => Promise<void>;
  onCancel: () => void;
}

export const ActivityLogWidgetEditor = ({
  widget,
  isSavingWidget,
  onSubmit,
  onCancel,
}: ActivityLogWidgetEditorProps) => {
  const { firstName, lastName } = useUserContext();

  const initialFormValues: ActivityLogWidgetFormValue = {
    name: widget?.name ?? "",
    maxCount: widget?.maxCount ?? 10,
    workoutsCompleted: widget?.workoutsCompleted ?? false,
    testsRegistered: widget?.testsRegistered ?? false,
    somethingRead: widget?.somethingRead ?? false,
    informationRegistered: widget?.informationRegistered ?? false,
    widgetBorders: widget?.widgetBorders ?? false,
    type: WidgetType.ACTIVITY_LOG,
  };

  const handleSubmit = (values: ActivityLogWidgetFormValue) => {
    onSubmit({
      ...values,
      type: WidgetType.ACTIVITY_LOG,
      createdBy: widget?.createdBy ?? `${firstName} ${lastName}`,
      updatedAt: widget?.updatedAt ?? dayjs().valueOf(),
    });
  };

  return (
    <Formik<ActivityLogWidgetFormValue>
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        if (
          !values.informationRegistered &&
          !values.workoutsCompleted &&
          !values.testsRegistered &&
          !values.somethingRead
        ) {
          return {
            data: "required",
          };
        }
      }}
      validateOnMount
    >
      {({ values, handleChange, submitForm, setFieldValue }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <WidgetEditorTitleSection
              value={values.name}
              name="name"
              handleChange={handleChange}
            />

            <DataSection />

            <AppearanceSection
              widgetType={WidgetType.ACTIVITY_LOG}
              widgetBorders={values.widgetBorders}
              setFieldValue={setFieldValue}
            />
          </FormWrapper>

          <PreviewButtonSection
            withoutPadding
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <ActivityWidgetPreview />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
};
