import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../../colors";
import { useAccessLevelsContext } from "../../../contexts/accessLevels";
import {
  ATHLETE_TAB_ID,
  LastActiveTab,
  TEAM_TAB_ID,
  useSelectedPerformersContext,
} from "../../../contexts/selectedAthletes";
import { useUserContext } from "../../../contexts/User";
import { useGetTheGroupsImInQuery } from "../../../graphql";
import Tabs from "../../Tabs";

import { AthletesTab } from "./AthletesTab";
import { TeamsTab } from "./TeamsTab";

const TabsWrapper = styled.div`
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
`;

export const AthletesSelectorBody = ({ search }) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();
  const { lastActiveTab, setLastActiveTab } = useSelectedPerformersContext();

  const tabItems = [
    { id: ATHLETE_TAB_ID, title: t("athletes").toUpperCase() },
    { id: TEAM_TAB_ID, title: t("teams").toUpperCase() },
  ];

  const { data, loading } = useGetTheGroupsImInQuery({
    variables: { sessionId, accountId: selectedAccount?.id, language },
  });

  return (
    <>
      <TabsWrapper>
        <Tabs
          tab={tabItems.find((item) => item.id === lastActiveTab)}
          setTab={(newTab) => setLastActiveTab(newTab.id as LastActiveTab)}
          tabs={tabItems}
          style={{ justifyContent: "center" }}
        />
      </TabsWrapper>

      {lastActiveTab === ATHLETE_TAB_ID && (
        <AthletesTab
          search={search}
          groups={data?.getTheGroupsImIn}
          loading={loading}
        />
      )}
      {lastActiveTab === TEAM_TAB_ID && (
        <TeamsTab
          search={search}
          teams={data?.getTheGroupsImIn}
          loading={loading}
        />
      )}
    </>
  );
};
