import React, { useCallback } from "react";

import { useFormikContext } from "formik";
import _get from "lodash/get";

import { COLOR_MEDIUM_GRAY } from "../../../../colors";
import { AggregateFunction, ReportItemType } from "../../../../graphql";
import { useHasSoccer } from "../../../../hooks/useHasSoccer";
import {
  isAcuteChronicCollection,
  isAttendanceCollection,
  isInformationCollection,
  isSoccerStatsCollection,
  isSumAttribute,
  isXpsMagicCollection,
} from "../../../../utils/reports";
import { IconButton } from "../../../IconButton";
import { BinIcon, DuplicateIcon, ReorderIcon } from "../../../Icons";
import {
  DataSource,
  DataSourceSelectorRow,
  DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
} from "../../../Report/DataSourceSelector";
import { DraggableLineWrapper } from "../../../styled";
import { StyledSwitch } from "../../../StyledSwitch";
import { DragHandlePropsType } from "../../../Wrappers/DragDropContainer";
import { GroupColumn as GroupColumnModel } from "../../models";
import {
  ColumnConfigWrapper,
  IconButtonWrapper,
  ReorderImageWrapper,
  SwitchWrapper,
} from "../../styled";

import { ComparisonSubSection } from "./ComparisonSubSection";
import { getAggregations, getPropertyNameInForm } from "./utils";
import { ValueSubSection } from "./ValueSubSection";

interface IGroupColumnProps {
  columnIndex: number;
  remove(rowIndex: number): void;
  push(newGroupColumn: GroupColumnModel): void;
  dragHandleProps: DragHandlePropsType;
}

const soccerAggregateFunctions = (
  measurementTemplateId: string,
  aggregateFunctions: AggregateFunction[] = []
) => {
  const supportedFunctions = getAggregations(measurementTemplateId);
  const filteredFunctions = aggregateFunctions.filter((af) =>
    supportedFunctions.some((sf) => sf === af)
  );
  return filteredFunctions.length ? filteredFunctions : [supportedFunctions[0]];
};

export const GroupColumn = ({
  columnIndex,
  dragHandleProps,
  push,
  remove,
}: IGroupColumnProps) => {
  const { values, setFieldValue } = useFormikContext<{
    columns: GroupColumnModel[];
  }>();
  const hasSoccer = useHasSoccer();

  const currentColumn = values.columns[columnIndex];
  const collectionId = _get(
    values,
    getPropertyNameInForm("collectionId", columnIndex),
    null
  );

  const onChangeColumnValues = useCallback(
    (newValues: Partial<DataSource>) => {
      const aggregateFunctions = isSumAttribute(newValues.attributeTemplateId)
        ? { aggregateFunctions: [AggregateFunction.Sum] }
        : isSoccerStatsCollection(newValues.measurementTemplateId)
          ? {
              aggregateFunctions: soccerAggregateFunctions(
                newValues.measurementTemplateId,
                currentColumn.aggregateFunctions
              ),
            }
          : {};

      setFieldValue(`columns[${columnIndex}]`, {
        ...currentColumn,
        ...newValues,
        ...aggregateFunctions,
      });
    },
    [setFieldValue, columnIndex, currentColumn]
  );

  const onClone = () => push({ ...currentColumn });

  const isAttendanceOrXpsMagicInfoCollection =
    isAttendanceCollection(collectionId) || isXpsMagicCollection(collectionId);

  return (
    <>
      <DraggableLineWrapper>
        <ReorderImageWrapper {...dragHandleProps}>
          <ReorderIcon />
        </ReorderImageWrapper>
        <DataSourceSelectorRow
          currentDataSource={currentColumn}
          dataSourceArrayItemAccessor={`columns[${columnIndex}]`}
          onChangeDataSourceValues={onChangeColumnValues}
          allowedReportItemTypes={[
            ...DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
            ReportItemType.AcuteChronic,
            ReportItemType.Attendance,
            ReportItemType.XpsMagic,
            ...(hasSoccer ? [ReportItemType.SoccerStat] : []),
          ]}
        />
        <>
          <IconButtonWrapper>
            <IconButton
              icon={<DuplicateIcon tintColor={COLOR_MEDIUM_GRAY} />}
              onClick={onClone}
            />
          </IconButtonWrapper>
          {values?.columns?.length > 1 && (
            <IconButtonWrapper>
              <IconButton
                icon={<BinIcon />}
                onClick={() => remove(columnIndex)}
              />
            </IconButtonWrapper>
          )}
        </>
      </DraggableLineWrapper>

      {collectionId && !isAttendanceOrXpsMagicInfoCollection && (
        <ColumnConfigWrapper>
          <ValueSubSection columnIndex={columnIndex} />

          {!isInformationCollection(collectionId) &&
            !isSoccerStatsCollection(collectionId) &&
            !isSumAttribute(currentColumn.attributeTemplateId) && (
              <>
                {!isAcuteChronicCollection(collectionId) && (
                  <ComparisonSubSection columnIndex={columnIndex} />
                )}

                <SwitchWrapper>
                  <StyledSwitch
                    value={currentColumn.showTrend}
                    onValueChange={(value) =>
                      setFieldValue(
                        getPropertyNameInForm("showTrend", columnIndex),
                        value
                      )
                    }
                    primary={true}
                    label="Show Trend"
                  />
                </SwitchWrapper>
              </>
            )}
        </ColumnConfigWrapper>
      )}
    </>
  );
};
