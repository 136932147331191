import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { useModalContext } from "../../contexts/UI/Modal";
import { GeneralButton } from "../Button/";
import { Loader } from "../Loader";
import { StyledTextInput } from "../StyledTextInput";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
`;

export function RenameWidget({ defaultValue, onChangeText, onDone, keepOpen }) {
  const [text, setText] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    actions: { closeModal },
  } = useModalContext();

  const onDoneClick = () => {
    setLoading(true);
    onDone?.(text);
    if (!keepOpen) {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <Wrapper>
      <StyledTextInput
        value={text}
        onChangeText={(t) => {
          onChangeText?.(t);
          setText(t);
        }}
        style={{ flex: 1, marginRight: 15 }}
      />
      {loading ? (
        <Loader color={COLOR_BLUE} size="large" />
      ) : (
        <GeneralButton label={t("done")} height={45} onClick={onDoneClick} />
      )}
    </Wrapper>
  );
}
