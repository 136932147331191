import React, { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { getReportPeriodLabel } from "../../components/Report/ReportPeriodText";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { usePrintGroupReportContext } from "../../contexts/Print/PrintGroupReportProvider";
import { useReportsContext } from "../../contexts/Reports";
import { useSelectedPerformersContext } from "../../contexts/selectedAthletes";
import { useDefaultDateFormat } from "../../hooks/useDateFormat";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { LandscapeOrientationStyleEnforcement } from "../LandscapeOrientationStyleEnforcement";
import { PrintReport } from "../PrintGroupReport";
import {
  ModalWrapper,
  Preview,
  PreviewSection,
  PreviewWrapper,
} from "../styled";

import { GroupControlSection } from "./GroupControlSection";
import { PrintHeader } from "./PrintHeader";

interface PrintGroupReportModalProps {
  reportName: string;
  isSingleDayReport: boolean;
  filteredWidgets: any[];
}
export const PrintGroupReportModal = ({
  reportName,
  filteredWidgets,
  isSingleDayReport,
}: PrintGroupReportModalProps) => {
  const printRef = useRef<any>();
  const { pathname } = useLocation();
  const dateFormats = useDefaultDateFormat();

  const { selectedAccount } = useAccessLevelsContext();
  const { attendingUsers } = useSelectedPerformersContext();
  const [
    {
      selectedPeriod,
      selectedStartDate,
      selectedEndDate,
      selectedGroup,
      selectedSingleDay,
    },
  ] = useReportsContext();
  const { experimentalGrid, landscapeOrientation } =
    usePrintGroupReportContext();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const periodOfReport = useMemo(() => {
    if (isSingleDayReport) {
      return getReportPeriodLabel({
        selectedPeriod: "day",
        selectedStartDate: selectedSingleDay,
        selectedEndDate: selectedSingleDay,
        ...dateFormats,
      });
    } else {
      return getReportPeriodLabel({
        selectedPeriod,
        selectedEndDate,
        selectedStartDate,
        ...dateFormats,
      });
    }
  }, [
    isSingleDayReport,
    selectedEndDate,
    selectedPeriod,
    selectedSingleDay,
    selectedStartDate,
    dateFormats,
  ]);

  return (
    <ModalWrapper>
      <PreviewSection>
        <PreviewWrapper landscapeOrientation={landscapeOrientation}>
          <Preview ref={printRef}>
            <LandscapeOrientationStyleEnforcement
              landscapeOrientation={landscapeOrientation}
            />
            <PrintHeader
              title={reportName}
              period={periodOfReport}
              athletes={[
                ...(pathname === `/${ROUTING_CONFIG.AthleteProfile}`
                  ? selectedGroup?.athletes
                  : attendingUsers),
              ].map((user) => `${user.firstName} ${user.lastName}`)}
              groupName={selectedGroup.name}
              accountLogo={selectedAccount?.img}
            />
            <PrintReport
              filteredWidgets={filteredWidgets}
              experimentalGrid={experimentalGrid}
              landscapeOrientation={landscapeOrientation}
            />
          </Preview>
        </PreviewWrapper>
      </PreviewSection>
      <GroupControlSection onPrint={handlePrint} />
    </ModalWrapper>
  );
};
