import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../../contexts/accessLevels";
import { useUserContext } from "../../../contexts/User";
import {
  useGetTrainingStatusGroupsQuery,
  useResourceGroupsQuery,
} from "../../../graphql";

export const useTrainerSectionsAvailability = () => {
  const { sessionId, language, timezone } = useUserContext();
  const {
    selectedAccount,
    selectedAccountStatisticsAccess,
    isAllAccountOverrideActive,
  } = useAccessLevelsContext();

  const { data: fetchedGroups } = useGetTrainingStatusGroupsQuery({
    variables: {
      sessionId,
      language,
      timezone,
      selectedAccountId: selectedAccount?.id,
    },
    skip: !selectedAccount?.id || selectedAccount?.id === ALL_ACCOUNTS_ID,
  });

  const { data: fetchedResourceGroups } = useResourceGroupsQuery({
    variables: {
      sessionId,
      club: selectedAccount?.id,
    },
    skip:
      !sessionId ||
      !selectedAccount?.id ||
      selectedAccount?.id === ALL_ACCOUNTS_ID,
    fetchPolicy: "cache-first",
  });

  return {
    canSeeTrainingStatusModule:
      fetchedGroups?.trainingStatusGroups?.length > 0 &&
      !isAllAccountOverrideActive,
    canSeeMonitoringModule: selectedAccountStatisticsAccess?.viewAll,
    canSeeResourceCalendarModule:
      fetchedResourceGroups?.resourceGroups?.length > 0 &&
      !isAllAccountOverrideActive,
  };
};
