import React from "react";

import { Dayjs } from "dayjs";
import styled, { useTheme } from "styled-components";

import { Period } from "../../contexts/Reports";
import { CalendarWithDateInput } from "../CalendarWithDateInput";

import { ReportPeriodDateButtons } from "./ReportPeriodDateButtons";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export function CustomPeriodCalendar({
  selectedStartDate,
  selectedEndDate,
  firstInputLabel,
  secondInputLabel,
  setStartDate,
  setEndDate,
  selectPeriod,
}: {
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  firstInputLabel?: string;
  secondInputLabel?: string;
  setStartDate(date): void;
  setEndDate(date): void;
  selectPeriod?(period: Period): void;
}) {
  const { isMobile } = useTheme();

  const onSetStartDate = (date) => {
    setStartDate(date);
    selectPeriod?.("custom");
  };

  const onSetEndDate = (date) => {
    setEndDate(date);
    selectPeriod?.("custom");
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: !isMobile ? "row" : "column",
          marginBottom: 10,
        }}
      >
        <CalendarWithDateInput
          value={selectedStartDate}
          from={selectedStartDate}
          to={selectedEndDate}
          maxDate={selectedEndDate}
          set={onSetStartDate}
          inputLabel={firstInputLabel}
        />
        <Box
          style={{
            marginLeft: !isMobile ? 20 : 0,
            marginTop: !isMobile ? 0 : 20,
          }}
        >
          <CalendarWithDateInput
            value={selectedEndDate}
            from={selectedStartDate}
            to={selectedEndDate}
            minDate={selectedStartDate}
            inputLabel={secondInputLabel}
            set={onSetEndDate}
          />
        </Box>
      </Box>
      <ReportPeriodDateButtons
        setStartDate={onSetStartDate}
        setEndDate={onSetEndDate}
      />
    </Box>
  );
}
