import React, { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

import { PrintAthleteReportContextProvider } from "./Print/PrintAthleteReportProvider";
import { PrintGroupReportContextProvider } from "./Print/PrintGroupReportProvider";
import { ReportContextProvider } from "./report";
import { ReportsContextProvider } from "./Reports";
import { SelectedPerformersContextProvider } from "./selectedAthletes";

export const MonitoringReportsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const activeProfileTabId = searchParams.get("activeProfileTabId") ?? "";

  return (
    <ReportsContextProvider>
      <ReportContextProvider reportId={activeProfileTabId}>
        <SelectedPerformersContextProvider>
          <PrintGroupReportContextProvider>
            <PrintAthleteReportContextProvider>
              {children}
            </PrintAthleteReportContextProvider>
          </PrintGroupReportContextProvider>
        </SelectedPerformersContextProvider>
      </ReportContextProvider>
    </ReportsContextProvider>
  );
};
