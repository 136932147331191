import React from "react";
import { useController } from "react-hook-form";

import { COLOR_TEXT_DARK } from "../../colors";
import { Checkbox } from "../Checkbox";

import { StyledInputWrapper } from "./StyledInputComponents";

interface TextInputHookProps {
  name: string;
  text: string;
  defaultValue?: string;
  textColor?: string;
  marginTop?: number;
  marginBottom?: number;
  centered?: boolean;
}

export function ControlledCheckbox({
  name,
  text,
  defaultValue,
  textColor = COLOR_TEXT_DARK,
  marginTop,
  marginBottom,
  centered,
}: TextInputHookProps) {
  const {
    field: { value, onChange },
  } = useController({ defaultValue, name });

  return (
    <StyledInputWrapper
      marginTop={marginTop}
      marginBottom={marginBottom}
      centered={centered}
    >
      <Checkbox
        text={text}
        check={onChange}
        checked={value ?? false}
        textStyle={{ color: textColor, fontSize: "14px", lineHeight: "16x" }}
        withMargin={false}
      />
    </StyledInputWrapper>
  );
}
