export function parsePreferences<TPreferences = any>(
  preferences: string,
  defaultValue?: TPreferences
): TPreferences | undefined {
  if (!preferences) {
    return defaultValue;
  }

  try {
    return JSON.parse(preferences);
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
}
