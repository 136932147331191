import React from "react";

import styled from "styled-components";

import { TrainingDay } from "./TrainingDay";

const TrainingDaysWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-right: 10px;
`;

export const COUNT_OF_TRAINING_DAYS = 7;

export const TrainingDays = ({ days, day, dayIndex, onDayPress }) => (
  <TrainingDaysWrapper>
    {Array.from({ length: COUNT_OF_TRAINING_DAYS }, (_, dayOfWeekIndex) => (
      <TrainingDay
        key={dayOfWeekIndex}
        days={days}
        day={day}
        dayIndex={dayIndex}
        onDayPress={onDayPress}
        dayOfWeekIndex={dayOfWeekIndex}
      />
    ))}
  </TrainingDaysWrapper>
);
