import React from "react";

interface Props {
  color?: string;
  direction?: "top" | "bottom" | "left" | "right";
}

export const ArrowInCircleIcon = ({ direction = "bottom" }: Props) => {
  const rotation = () => {
    switch (direction) {
      case "left":
        return "90";
      case "top":
        return "180";
      case "right":
        return "270";
      default:
        return "0";
    }
  };
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation()}deg)` }}
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#ECF2FB" />
      <path d="M4.5 6.5L7.5 9.5L10.5 6.5" stroke="#0073D9" strokeWidth="1.5" />
    </svg>
  );
};
