import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { COLOR_BLUE } from "../../colors";
import { useReportContext } from "../../contexts/report";
import { ReportType } from "../../graphql";
import { useNavigateToPreviousPage } from "../../hooks";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { isRouteParamTrue } from "../../utils/route";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import { AthleteReport } from "./AthleteReport";

export function AthleteReportContainer() {
  const { report, reportLoading } = useReportContext();

  const { t } = useTranslation();

  const [searchParams, setParams] = useSearchParams();
  const isEditMode = isRouteParamTrue(searchParams.get("isEditMode"));
  const activeProfileTabId = searchParams.get("activeProfileTabId");

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const handleSetReportEditMode = (isEditMode: boolean) => {
    if (isEditMode) {
      setParams({ ...getSearchParamsObject(searchParams), isEditMode: "true" });
    } else {
      if (activeProfileTabId) {
        navigateToPreviousPage();
      } else {
        setParams({
          ...getSearchParamsObject(searchParams),
          isEditMode: "false",
        });
      }
    }
  };

  if (reportLoading && !report) {
    return (
      <div style={{ flex: 1, paddingTop: 30, paddingBottom: 30 }}>
        <Loader color={COLOR_BLUE} size="large" />
      </div>
    );
  }

  if (!report) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <StyledText>{t("noContentAvailable")}</StyledText>
      </div>
    );
  }

  if (report.reportType !== ReportType.AthleteReport) {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <StyledText>
          NOT A ATHLETE REPORT (ID: <code>"{report?.id}"</code>, REPORT TYPE:{" "}
          <code>"{report?.reportType}"</code>)
        </StyledText>
      </div>
    );
  }

  return (
    <div style={{ flex: 1 }}>
      <AthleteReport
        // @ts-ignore
        reportTemplate={report}
        isReportInEditMode={isEditMode}
        setReportEditMode={handleSetReportEditMode}
      />
    </div>
  );
}
