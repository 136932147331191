import React, { useCallback, useEffect, useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { Field } from "formik";
import _debounce from "lodash/debounce";
import styled from "styled-components";

import { AggregateFunction } from "../../../graphql";
import { IconButton } from "../../IconButton";
import { BinIcon, DuplicateIcon } from "../../Icons";
import { SelectField } from "../../SelectField";
import { DraggableLineWrapper as LineWrapper } from "../../styled";
import StyledText from "../../StyledText";
import { StyledTextInput } from "../../StyledTextInput";
import { ColorPicker } from "../ColorPicker";
import { Comparator, ConditionStyling } from "../models";
import {
  IconButtonWrapper,
  InnerWrapper,
  OuterWrapper,
  Row,
  StyledRowItem,
} from "../styled";

import { ANY_VALUE_CONDITION } from "./useConditionsData";

const BoldRuleText = styled(StyledText)`
  display: flex;
  justify-content: right;
  align-items: center;
  font-weight: bold;
  width: 21px;
  height: 40px;
  margin-bottom: 3px;
`;

const getComparatorOptions = (
  t: TFunction<"common">,
  isInfo: boolean,
  isTestWithColorRanges?: boolean
) => {
  const commonXY = [{ label: t("equal"), value: Comparator.EQUAL }];

  if (!isInfo && !isTestWithColorRanges) {
    return [
      ...commonXY,
      { label: t("lessThan"), value: Comparator.LESS_THAN },
      { label: t("greaterThan"), value: Comparator.GREATER_THAN },
      { label: t("between"), value: Comparator.BETWEEN },
    ];
  }

  if (isTestWithColorRanges) {
    return [
      ...commonXY,
      { label: t("lessThan"), value: Comparator.LESS_THAN },
      { label: t("greaterThan"), value: Comparator.GREATER_THAN },
      { label: t("between"), value: Comparator.BETWEEN },
      { label: t("colorRange"), value: Comparator.COLOR_RANGE },
    ];
  }

  return [...commonXY, { label: t("includes"), value: Comparator.INCLUDES }];
};

const COLOR_PICKER_WIDTH = 40;

type RuleValue = {
  templateId: string;
  aggregation: AggregateFunction;
  comparator: {
    name: Comparator;
    value: string;
    valueTo?: string;
  };
  conditionStyle: {
    name: ConditionStyling;
    value: string;
  };
};

export const RuleRow = ({
  index,
  value,
  conditionsData,
  onChange,
  onClone,
  onRemove,
}: {
  index?: number;
  value: RuleValue;
  conditionsData: {
    templateId: string;
    templateName: string;
    isInfo: boolean;
    isTestWithColorRanges?: boolean;
    aggregations: AggregateFunction[];
  }[];
  onChange(value: RuleValue): void;
  onClone(): void;
  onRemove(): void;
}) => {
  const { t } = useTranslation();
  const [valueState, setValueState] = useState(value);

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    _debounce((value: RuleValue) => {
      onChangeRef.current?.(value);
    }, 250),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    handleChange(valueState);
  }, [valueState, handleChange]);

  useEffect(() => {
    setValueState(value);
    // Value is not memoized, so it causes loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value.templateId,
    value.aggregation,
    value.comparator.name,
    value.comparator.value,
    value.comparator.valueTo,
    value.conditionStyle.name,
    value.conditionStyle.value,
  ]);

  const templatesMap = new Map(
    conditionsData.map((condition) => [condition.templateId, condition])
  );

  const isTestWithColorRanges = templatesMap.get(
    value?.templateId
  )?.isTestWithColorRanges;

  const templatesOptions = conditionsData.map(
    ({ templateName, templateId }) => ({
      label: templateName,
      value: templateId,
    })
  );
  const aggregationsOptions = (
    templatesMap.get(value?.templateId)?.aggregations ?? []
  ).map((aggregation) => ({
    value: aggregation,
    label: t(`aggregation${aggregation}`, {
      defaultValue: aggregation,
    }),
  }));

  const comparatorsOptions = getComparatorOptions(
    t,
    templatesMap.get(value?.templateId)?.isInfo,
    isTestWithColorRanges
  );
  const conditionsOptions = [
    { label: t("textColor"), value: "color" },
    { label: t("backgroundColor"), value: "backgroundColor" },
  ];

  const shouldShowAdditionalConfigFields =
    !isTestWithColorRanges ||
    valueState.comparator.name !== Comparator.COLOR_RANGE;

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LineWrapper>
          <BoldRuleText>{t("if")}</BoldRuleText>

          <Row>
            <StyledRowItem>
              <Field
                value={valueState.templateId}
                options={templatesOptions}
                onChange={({ value: templateId }) => {
                  const template = templatesMap.get(templateId);
                  setValueState((value) => ({
                    ...value,
                    templateId,
                    aggregation:
                      template?.aggregations?.[0] ?? ("" as AggregateFunction),
                  }));
                }}
                component={SelectField}
              />
            </StyledRowItem>
            {valueState.templateId !== ANY_VALUE_CONDITION.templateId && (
              <StyledRowItem>
                <Field
                  value={valueState.aggregation}
                  onChange={({ value: aggregation }) =>
                    setValueState((value) => ({ ...value, aggregation }))
                  }
                  component={SelectField}
                  options={aggregationsOptions}
                />
              </StyledRowItem>
            )}
          </Row>
        </LineWrapper>
        <LineWrapper>
          <BoldRuleText>{t("is")}</BoldRuleText>

          <Row>
            <StyledRowItem>
              <Field
                value={valueState.comparator.name}
                onChange={({ value: comparatorName }) =>
                  setValueState((value) => ({
                    ...value,
                    comparator: { ...value.comparator, name: comparatorName },
                  }))
                }
                component={SelectField}
                options={comparatorsOptions}
              />
            </StyledRowItem>
            <StyledRowItem style={{ display: "flex", flexDirection: "row" }}>
              {shouldShowAdditionalConfigFields && (
                <StyledTextInput
                  value={valueState.comparator.value}
                  onChange={(e) => {
                    e.persist();
                    setValueState((value) => ({
                      ...value,
                      comparator: {
                        ...value.comparator,
                        value: e.target.value,
                      },
                    }));
                  }}
                  style={{
                    flex: 4,
                    width: "100%",
                  }}
                  placeholder={
                    valueState.comparator.name === Comparator.BETWEEN
                      ? t("lowValue")
                      : t("benchmarkValue")
                  }
                />
              )}
              {valueState.comparator.name === Comparator.BETWEEN && (
                <>
                  <BoldRuleText
                    style={{
                      flex: 2,
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {t("and")}
                  </BoldRuleText>
                  <StyledTextInput
                    value={value.comparator.valueTo}
                    onChange={(e) => {
                      e.persist();
                      setValueState((value) => ({
                        ...value,
                        comparator: {
                          ...value.comparator,
                          valueTo: e.target.value,
                        },
                      }));
                    }}
                    style={{ flex: 4, width: "100%" }}
                    placeholder={t("highValue")}
                  />
                </>
              )}
            </StyledRowItem>
          </Row>
        </LineWrapper>
        {/* We need to dynamically calculate z-index because of multiple rules are possible and color picker should be always on top */}
        <LineWrapper style={{ zIndex: 100 - index }}>
          <BoldRuleText>{t("set").toLowerCase()}</BoldRuleText>

          <Row>
            <StyledRowItem>
              <Field
                value={valueState.conditionStyle.name}
                onChange={({ value: conditionStyleName }) =>
                  setValueState((value) => ({
                    ...value,
                    conditionStyle: {
                      ...value.conditionStyle,
                      name: conditionStyleName,
                    },
                  }))
                }
                component={SelectField}
                options={conditionsOptions}
              />
            </StyledRowItem>
            {shouldShowAdditionalConfigFields && (
              <StyledRowItem style={{ width: COLOR_PICKER_WIDTH }}>
                <ColorPicker
                  graySectionSize={COLOR_PICKER_WIDTH}
                  onColorChange={(colorHex) =>
                    setValueState((value) => ({
                      ...value,
                      conditionStyle: {
                        ...value.conditionStyle,
                        value: colorHex,
                      },
                    }))
                  }
                  currentColor={valueState.conditionStyle.value}
                />
              </StyledRowItem>
            )}
          </Row>
        </LineWrapper>
      </InnerWrapper>
      <IconButtonWrapper>
        <IconButton icon={<DuplicateIcon />} onClick={onClone} />
        <IconButton icon={<BinIcon />} onClick={onRemove} />
      </IconButtonWrapper>
    </OuterWrapper>
  );
};
