import React from "react";

import styled, { css } from "styled-components";

import { DocumentPreview } from "../../contexts/workout/WorkoutEditorProvider";
import { InlineComment, WorkoutLine, Exercise } from "../../graphql";

import { CommentItem } from "./CommentItem";
import { WorkoutItem } from "./Workout/WorkoutItem";
import { WorkoutItemPreview } from "./Workout/WorkoutItemPreview";

const Container = styled.div<{ hasMinWidth?: boolean }>`
  ${({ hasMinWidth }) =>
    hasMinWidth
      ? css`
          width: 100%;
        `
      : undefined}
`;

type SessionLinesProps = {
  hideActionButtons?: boolean;
  data: (WorkoutLine | DocumentPreview)[];
  isWorkoutEditMode?: boolean;
  onBack?: () => void;
};

export function SessionLines({
  data,
  hideActionButtons = false,
  isWorkoutEditMode,
  onBack,
}: SessionLinesProps) {
  return (
    <Container hasMinWidth={isWorkoutEditMode}>
      {data?.map((line, index, array) => {
        switch (line.__typename) {
          case "Exercise": {
            const workoutData = line as Exercise;

            return (
              <WorkoutItem
                key={workoutData.id}
                data={workoutData}
                bottomOffset={index === array.length - 1}
                hideActionButtons={hideActionButtons}
                isWorkoutEditMode={isWorkoutEditMode}
                index={index}
                onBack={onBack}
              />
            );
          }

          case "InlineComment": {
            const comment = (line as InlineComment).txt;

            return <CommentItem key={line.id} comment={comment} />;
          }

          case "Document": {
            return (
              <WorkoutItemPreview
                key={line?.key ?? `Document-${index}`}
                data={line}
              />
            );
          }

          default:
            return null;
        }
      })}
    </Container>
  );
}
