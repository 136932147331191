import React, { memo } from "react";

export const AddImageIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect x="2.5" y="4.5" width="16" height="12" rx="1.5" stroke="#64707A" />
    <circle cx="6" cy="8" r="0.5" stroke="#64707A" />
    <path
      d="M3 16L7.36824 12.5054C7.73667 12.2107 8.261 12.2135 8.62617 12.5123L13.5 16.5"
      stroke="#64707A"
    />
    <path
      d="M10 14L14.3533 10.5174C14.7285 10.2172 15.264 10.2263 15.6288 10.539L18.5 13"
      stroke="#64707A"
    />
  </svg>
));
