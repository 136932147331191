import { TFunction } from "i18next";

import {
  isDocumentCollection,
  isDrillCollection,
  isExerciseSetCollection,
  isTestCollection,
} from "../../utils/reports";

import { TabLabel } from "./model";

export const COLORS = [
  ["#004E1B", "#9DED2E"],
  ["#000F2D", "#006D9C", "#6CC971"],
  ["#B70866", "#F19000"],
  ["#00CCD9", "#002E74"],
  ["#C140AC", "#003E75"],
  ["#FF5A5A", "#B72E7B", "#6B009D"],
  ["#FFB800", "#C36A29", "#462600"],
  ["#00D179", "#444C5B"],
  ["#C70000", "#444C5B"],
  ["#DEC700", "#39920F", "#00518B"],
];

export const COLLECTION_ROOT_TYPES = [
  "_drillRoot",
  "_exerciseRoot",
  "_documentRoot",
  "_receivedRoot",
  "_collectionsRoot",
  "_programRootForPractices",
  "_programRootForWorkouts",
];

export const COLLECTION_TYPES = [
  "DOCUMENT",
  "EXERCISE_SET",
  "DRILL",
  "PRACTICE_TEMPLATE_2020",
  "WORKOUT_TEMPLATE",
];

export const collectionTypes: { [root: string]: string[] } = {
  _collectionsRoot: COLLECTION_TYPES,
  _documentRoot: [COLLECTION_TYPES[0]],
  _drillRoot: [COLLECTION_TYPES[2]],
  _exerciseRoot: [COLLECTION_TYPES[1]],
  _receivedRoot: COLLECTION_TYPES,
  _programRootForPractices: [COLLECTION_TYPES[3]],
  _programRootForWorkouts: [COLLECTION_TYPES[4]],
};

export function convertIdToVariant(id: string) {
  const randomNumber = parseInt(id[id.length - 1], 10);
  const isNumber = isNaN(randomNumber) ? 0 : randomNumber;

  return Math.abs(isNumber % 10);
}

export function swapTypes(
  types: string[],
  firstType: string,
  secondType: string
) {
  const newTypes = [...types];
  const firstIndex = types.indexOf(firstType);
  const secondIndex = types.indexOf(secondType);

  if (firstIndex !== -1 && secondIndex !== -1) {
    newTypes[firstIndex] = secondType;
    newTypes[secondIndex] = firstType;

    return newTypes;
  }

  return newTypes;
}

export function getTypename(type: string, t: TFunction) {
  switch (type.toLowerCase()) {
    case TabLabel.EXERCISE_SET:
    case TabLabel.EXERCISES:
      return t("exercises").toLocaleUpperCase();
    case TabLabel.TEST:
    case TabLabel.TESTS:
      return t("tests").toLocaleUpperCase();
    case TabLabel.DRILL:
    case TabLabel.DRILLS:
      return t("drills").toLocaleUpperCase();
    case TabLabel.WORKOUTS:
    case TabLabel.WORKOUT_TEMPLATE:
      return t("workouts").toLocaleUpperCase();
    case TabLabel.PRACTICE_TEMPLATE_2020:
    case TabLabel.TEAM_SESSIONS:
      return t("teamPractices").toLocaleUpperCase();
    case TabLabel.DOCUMENT:
    case TabLabel.DOCUMENTS:
      return t("documents").toLocaleUpperCase();
    case TabLabel.FORMS:
    case TabLabel.QUESTIONNAIRE_COLLECTION:
      return t("forms").toLocaleUpperCase();
    default:
      return type;
  }
}

export function getFolderColors(id: string) {
  if (isExerciseSetCollection(id)) {
    return {
      color: "#7CC0FF",
      border: "#59A0E2",
    };
  }

  if (isDocumentCollection(id)) {
    return {
      color: "#FF8080",
      border: "#E45654",
    };
  }

  if (isDrillCollection(id)) {
    return null;
  }

  if (isTestCollection(id)) {
    return {
      color: "#54CE00",
      border: "#3F8C00",
    };
  }

  return {
    color: "#C786EE",
    border: "#AB65D6",
  };
}

export const getFolderTypeFromFolderId = (id: string) => {
  const idParts = id.split(".");
  return idParts?.[1] ?? id;
};
