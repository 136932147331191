import Storage from "./storage";
import { StorageKeys } from "./storageKeys";

export const isAcesNationEnv = (): boolean => {
  // For initial page load and REST API requests
  const { search, host } = window.location;
  const params = new URLSearchParams(search);
  const acesnationFromParams = !!params.get(StorageKeys.acesNation);

  //Reading from subdomain
  const acesnationSubdomain = host.includes(StorageKeys.acesNation);

  // Mostly for embedded version of XPS Desktop / mobile
  const acesnationFromStorage =
    Storage.getItemSession<string>(StorageKeys.acesNation) === "true";

  return acesnationSubdomain || acesnationFromParams || acesnationFromStorage;
};
