import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ProfileGroupIcon = memo(
  ({ width = 25, height = 25, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {isActive ? (
        <>
          <circle cx="12.5" cy="12.5" r="12.5" fill="#E6E8EB" />
          <circle
            cx="12.5"
            cy="12.5"
            r="12.25"
            stroke="black"
            strokeOpacity="0.1"
            strokeWidth="0.5"
          />
          <circle cx="12.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <circle cx="10.5" cy="8.5" r="1.5" fill="#B1B7BC" />
          <circle cx="14.5" cy="8.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M11 14.5C11 13.6716 11.6716 13 12.5 13C13.3284 13 14 13.6716 14 14.5V17C14 17.5523 13.5523 18 13 18H12C11.4477 18 11 17.5523 11 17V14.5Z"
            fill="#B1B7BC"
          />
          <circle cx="8.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M7 14.5C7 13.6716 7.67157 13 8.5 13C9.32843 13 10 13.6716 10 14.5V17C10 17.5523 9.55228 18 9 18H8C7.44772 18 7 17.5523 7 17V14.5Z"
            fill="#B1B7BC"
          />
          <circle cx="16.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M15 14.5C15 13.6716 15.6716 13 16.5 13C17.3284 13 18 13.6716 18 14.5V17C18 17.5523 17.5523 18 17 18H16C15.4477 18 15 17.5523 15 17V14.5Z"
            fill="#B1B7BC"
          />
        </>
      ) : (
        <>
          <circle cx="12.5" cy="12.5" r="12.5" fill="#E6E8EB" />
          <circle
            cx="12.5"
            cy="12.5"
            r="12.25"
            stroke="black"
            strokeOpacity="0.1"
            strokeWidth="0.5"
          />
          <circle cx="12.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <circle cx="10.5" cy="8.5" r="1.5" fill="#B1B7BC" />
          <circle cx="14.5" cy="8.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M11 14.5C11 13.6716 11.6716 13 12.5 13C13.3284 13 14 13.6716 14 14.5V17C14 17.5523 13.5523 18 13 18H12C11.4477 18 11 17.5523 11 17V14.5Z"
            fill="#B1B7BC"
          />
          <circle cx="8.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M7 14.5C7 13.6716 7.67157 13 8.5 13C9.32843 13 10 13.6716 10 14.5V17C10 17.5523 9.55228 18 9 18H8C7.44772 18 7 17.5523 7 17V14.5Z"
            fill="#B1B7BC"
          />
          <circle cx="16.5" cy="11.5" r="1.5" fill="#B1B7BC" />
          <path
            d="M15 14.5C15 13.6716 15.6716 13 16.5 13C17.3284 13 18 13.6716 18 14.5V17C18 17.5523 17.5523 18 17 18H16C15.4477 18 15 17.5523 15 17V14.5Z"
            fill="#B1B7BC"
          />
        </>
      )}
    </svg>
  )
);
