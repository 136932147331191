import React from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useWorkoutEditorContext } from "../../contexts/workout/WorkoutEditorProvider";
import { FolderStructure } from "../FolderStructure";
import { PlusIcon } from "../Icons/PlusIcon";
import { ThreeDotsIcon } from "../Icons/ThreeDotsIcon";

import { HeaderButton } from "./HeaderButton";

const Container = styled.div`
  width: 255px;
  flex-shrink: 0;
  border-right: 1px solid ${COLOR_GREY_ACTIVE};
  background-color: ${COLOR_WHITE};
`;

const FoldersHeader = styled.div`
  height: 45px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-inline: 10px;
  gap: 5px;
`;

export function WorkoutEditorFolders() {
  const {
    setSearchValue,
    setFilteredDocuments,
    setCurrentFolderTreeStructure,
    selectedFolder,
    setSelectedFolder,
    changeTabOrCollectionType,
    setCollectionId,
    selectedFolderFullId,
    setSelectedFolderFullId,
  } = useWorkoutEditorContext();
  return (
    <Container>
      <FoldersHeader>
        <HeaderButton>
          <PlusIcon />
        </HeaderButton>
        <HeaderButton>
          <ThreeDotsIcon />
        </HeaderButton>
      </FoldersHeader>
      <FolderStructure
        isWorkout
        setFilteredDocuments={setFilteredDocuments}
        saveSearchValue={setSearchValue}
        onFolderDocumentsChange={setCurrentFolderTreeStructure}
        selectedWorkoutFolder={selectedFolder}
        setSelectedWorkoutFolder={(folderId) =>
          setSelectedFolder((current) => {
            const splitId = folderId.split(":");
            const newId = splitId[splitId.length - 1];
            return newId === current ? null : newId;
          })
        }
        changeTabOrCollectionType={changeTabOrCollectionType}
        changeCollectionId={setCollectionId}
        setSelectedWorkoutFolderFullId={(id) =>
          setSelectedFolderFullId((current) => (current === id ? "" : id))
        }
        selectedWorkoutFolderFullId={selectedFolderFullId}
      />
    </Container>
  );
}
