import React, { useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../../contexts/accessLevels";
import { useSelectedPerformersContext } from "../../../contexts/selectedAthletes";
import { Group } from "../../../graphql";
import StyledText from "../../StyledText";
import { TeamRow } from "../Components/TeamRow";

import { TabContainer } from "./TabContainer";

export const TeamsTab = ({
  search,
  teams = [],
  loading,
}: {
  search: string;
  teams: Pick<
    Group,
    "id" | "athletes" | "requiredAthletes" | "name" | "owner"
  >[];
  loading: boolean;
}) => {
  const { attendingTeams, setAttendingTeams } = useSelectedPerformersContext();
  const { t } = useTranslation();
  const { selectedAccount } = useAccessLevelsContext();

  const filteredTeams = teams.filter(
    (group) =>
      group.name.includes(search) ||
      `${group.owner.firstName} ${group.owner.lastName}`.includes(search)
  );

  const groupedTeams = useMemo(() => {
    return filteredTeams?.reduce((acc, item) => {
      const teamName = `${
        selectedAccount?.id === ALL_ACCOUNTS_ID && item.owner
          ? `${item.owner.firstName} ${item.owner.lastName} - `
          : ""
      }${item.name === "all" ? t("outsideOfTeams") : item.name.toUpperCase()}`;
      acc[teamName] = {
        team: item,
      };
      return acc;
    }, {});
  }, [filteredTeams, selectedAccount?.id, t]);

  const onSelectGroup = (group) => (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const selectedGroups = attendingTeams.includes(group)
      ? attendingTeams.filter(
          (attendingGroup) => attendingGroup.id !== group.id
        )
      : [...attendingTeams, group];

    setAttendingTeams(selectedGroups);
  };

  return (
    <TabContainer loading={loading}>
      <>
        {Object.keys(groupedTeams)
          .sort()
          .map((groupedTeam, index) => {
            const { team } = groupedTeams[groupedTeam];
            return (
              <TeamRow
                key={`team-${index}`}
                isCreateGroupChat
                currentKey={team.name}
                groupsCount={teams.length}
                showAttendingImages
                collapsed={true}
                owner={team.owner}
                withArrow={false}
                selectedAll={Boolean(
                  attendingTeams.find(
                    (attendingGroup) => attendingGroup.id === team.id
                  )
                )}
                onSelectAllMembersOfGroupClick={onSelectGroup(team)}
                onRowClick={onSelectGroup(team)}
              />
            );
          })}
        {filteredTeams.length === 0 && (
          <StyledText
            style={{ textAlign: "center" }}
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
          >
            {t("noOne")}
          </StyledText>
        )}
      </>
    </TabContainer>
  );
};
