import React from "react";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";
import { WorkoutEditorContextProvider } from "../../contexts/workout/WorkoutEditorProvider";

import { WorkoutEditorDocuments } from "./WorkoutEditorDocuments";
import { WorkoutEditorFolders } from "./WorkoutEditorFolders";
import { WorkoutEditorForms } from "./WorkoutEditorForms";

dayjs.extend(utc);
dayjs.extend(timezone);

const Container = styled.div`
  display: flex;
  background-color: ${COLOR_GRAY};
  overflow-y: hidden;
  height: calc(100vh - 55px);
`;

export function WorkoutEditor() {
  return (
    <WorkoutEditorContextProvider>
      <Container>
        <WorkoutEditorFolders />
        <WorkoutEditorDocuments />
        <WorkoutEditorForms />
      </Container>
    </WorkoutEditorContextProvider>
  );
}
