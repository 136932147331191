import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_RED_SECONDARY,
  COLOR_TEXT_DARK,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
  COLOR_GREEN,
} from "../colors";

import { CheckmarkDoneIcon } from "./Icons";
import { SelectableResource } from "./Modals/AddResourceModal";
import StyledText from "./StyledText";

type SelectResourceProps = {
  resource: SelectableResource;
  onSelectResource: (resource: string) => void;
};

const SelectResourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
`;

const Title = styled(StyledText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Label = styled(StyledText)`
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_RED_SECONDARY};
  margin-right: 10px;
`;

const EmptyCircle = styled.div`
  width: 21px;
  height: 21px;
  background: ${COLOR_GRAY};
  border-radius: 50%;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
`;

const Button = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
`;

export function SelectResource({
  resource,
  onSelectResource,
}: SelectResourceProps) {
  const { t } = useTranslation();

  return (
    <SelectResourceWrapper>
      <Title>{resource.resourceTitle}</Title>
      <SelectWrapper>
        <Label>{resource.isOccupied ? t("occupied").toUpperCase() : ""}</Label>
        <Button onClick={() => onSelectResource(resource.resourceId)}>
          {resource.isSelected ? (
            <CheckmarkDoneIcon
              tintColor={COLOR_GREEN}
              strokeColor={COLOR_WHITE}
            />
          ) : (
            <EmptyCircle />
          )}
        </Button>
      </SelectWrapper>
    </SelectResourceWrapper>
  );
}
