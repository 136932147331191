import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Checkbox } from "../Checkbox";
import Dropdown from "../Dropdown";
import { EventType } from "../Filters/model";
import { EntryTypesIcon } from "../Icons/EntryTypesIcon";

const EventTypeButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

const DropdownOptionContainer = styled.div`
  margin: 5px 0;
`;

const IconWrapper = styled.div<{ show: boolean }>`
  margin: 5px 0;
  background-color: ${({ show }) => (show ? COLOR_BLUE : "transparent")};
  border-radius: ${({ show }) => show && 50}px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
`;

export function EventTypeFilter({
  onChange,
  value,
}: {
  onChange: (value: string[]) => void;
  value: string[];
}) {
  const { t } = useTranslation();
  const user = useUserContext();
  const isGolfer = user?.settings?._isGolfer;

  const EVENTS = useMemo(
    () => [
      { id: EventType.WORKOUT, label: t("workout") },
      { id: EventType.PRACTICE, label: t("practice") },
      { id: EventType.GAME, label: t(isGolfer ? "competition" : "game") },
      { id: EventType.EVENT, label: t("event") },
    ],
    [t, isGolfer]
  );

  function handleChecked(
    isChecked: boolean,
    option: { id: EventType; label: string }
  ) {
    onChange?.(
      isChecked
        ? typeof value === "string"
          ? [option.id]
          : [...value, option.id]
        : typeof value === "string"
          ? [""]
          : value.filter((value) => value !== option.id)
    );
  }

  return (
    <Dropdown
      dropdownContainerStyle={{
        top: 26,
        right: 7,
        minWidth: 196,
      }}
      component={({ toggle, show }) => (
        <EventTypeButton onClick={toggle}>
          <IconWrapper show={show}>
            <EntryTypesIcon tintColor={show && COLOR_WHITE} />
          </IconWrapper>
        </EventTypeButton>
      )}
    >
      <>
        {EVENTS.map((option) => (
          <DropdownOptionContainer key={option.id}>
            <Checkbox
              text={option.label}
              checked={value.includes(option.id)}
              check={(isChecked) => handleChecked(isChecked, option)}
              withMargin={false}
            />
          </DropdownOptionContainer>
        ))}
      </>
    </Dropdown>
  );
}
