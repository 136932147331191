import React, { useMemo, useRef } from "react";

import { getDummyValue, getRandomInt } from "../../../utils/dummyData";
import { roundToTwo } from "../../../utils/number";
import { isInformationCollection } from "../../../utils/reports";
import { KeyValuesWidgetModel } from "../../ReportCreateTable/models";

import {
  KeyValuesWidgetComponent,
  KeyValuesWidgetDataItem,
} from "./components/KeyValuesWidgetComponent";
import { KeyValuesWidgetFormValue } from "./models";
import { mapKeyValuesWidgetFormDataItemsToReportWidgetCellGrid } from "./utils";

type KeyValuesWidgetPreviewProps = {
  values: KeyValuesWidgetFormValue;
};

export function KeyValuesWidgetPreview({
  values,
}: KeyValuesWidgetPreviewProps) {
  const { current: dummyDataMap } = useRef(
    new Map<string, { value: number | string; diff: number }>()
  );

  const widget = useMemo<KeyValuesWidgetModel>(() => {
    return {
      name: values.title,
      grid: mapKeyValuesWidgetFormDataItemsToReportWidgetCellGrid(
        values.items,
        values.numberOfColumns
      ),
      itemsSettings: values.items.map((item) => ({
        customTitle: item.customTitle,
        forAllGroups: item.forAllGroups,
        compareLatestTo: item.compareLatestTo,
      })),
      widgetBorders: values.widgetBorders,
      showValuesAsText: values.showValuesAsText,
    };
  }, [values]);

  const dummyData: KeyValuesWidgetDataItem[] = useMemo(
    () =>
      widget?.grid?.flatMap((row) =>
        row
          .filter((item) => !!item)
          .map<KeyValuesWidgetDataItem>((item) => {
            const isInfo = isInformationCollection(item.collectionId);
            const attribute =
              item.__typename === "ReportWidgetCellNumerical"
                ? item.attributeTemplate
                : undefined;
            const aggregation = item.aggregateFunctions?.[0];
            const valueKey = [
              item.templateId,
              isInfo ? "TEXT" : attribute?.id,
              aggregation,
              item?.["textOptions"]?.length,
              widget.showValuesAsText,
            ].join(":");
            const textOptions = item?.["textOptions"];

            if (!dummyDataMap.has(valueKey)) {
              dummyDataMap.set(valueKey, {
                value: getDummyValue(
                  attribute?.unitName,
                  isInfo,
                  aggregation,
                  textOptions,
                  widget.showValuesAsText,
                  item.templateId
                ),
                diff: roundToTwo(getRandomInt({ min: -5, max: 5 })),
              });
            }

            const valueItem = dummyDataMap.get(valueKey);

            return {
              measurementId: item.templateId,
              attributeId: attribute?.id,
              aggregation,
              isInfo,
              value: valueItem.value,
              diff: valueItem.diff,
            };
          })
      ) ?? [],
    [dummyDataMap, widget?.grid, widget.showValuesAsText]
  );

  return <KeyValuesWidgetComponent widget={widget} data={dummyData} />;
}
