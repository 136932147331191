import React, { CSSProperties, Dispatch, SetStateAction } from "react";
import { TFunction, useTranslation } from "react-i18next";
import ReactJWPlayer from "react-jw-player";

import styled from "styled-components";

import {
  COLOR_FINISH_BUTTON_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { makeLinksClickable } from "../../../utils/makeLinksClickable";
import { BasicButton } from "../../Button";
import { NewDocumentIcon } from "../../Icons";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";
import { isContentVisible } from "../utils";

import { ChatMessageDropdown } from "./ChatMessageDropdown";
import { HoverableUserAvatar } from "./HoverableUserAvatar";

const DocumentIconWrapper = styled.div`
  margin: 0 15px 0 5px;
`;

const FileAttachmentButton = styled(BasicButton)<{ margin?: number }>`
  margin-bottom: ${({ margin }) => `${margin}px` ?? 0};
  flex-direction: row;
  align-items: center;
`;

const AttachmentButton = styled(BasicButton)`
  margin: 5px 0;
`;

const AttachedImage = styled.img<{ margin?: number; height?: number }>`
  width: 150px;
  object-fit: cover;
  height: ${({ height }) => height ?? 150}px;
  margin-bottom: ${({ margin }) => `${margin}px` ?? 0};
`;

const DocumentSubtitle = styled.span<{ isReceived?: boolean }>`
  line-height: 14px;
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  word-break: break-all;
  color: ${({ isReceived }) =>
    !isReceived ? COLOR_WHITE : COLOR_TEXT_DEFAULT};
`;

const Text = styled(StyledText)<{
  hasAttachments: boolean;
  isReceived: boolean;
}>`
  margin-bottom: ${({ hasAttachments }) => (hasAttachments ? "10px" : 0)};
  & > a {
    color: ${({ isReceived }) =>
      isReceived ? COLOR_TEXT_DEFAULT : COLOR_WHITE};
    text-decoration: underline;
    text-wrap: wrap;
  }
`;

const VideoWrapper = styled.div`
  width: 256px;
  height: 144px;
`;

const styleRecord: Record<string, CSSProperties> = {
  message: {
    borderRadius: 18,
    padding: 11,
    maxWidth: "100%",
    minWidth: 60,
    boxSizing: "border-box",
  },
  messageWrapper: {
    display: "flex",
    marginTop: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  receivedMessage: {
    backgroundColor: COLOR_GREY_ACTIVE,
  },
  sentMessage: {
    backgroundColor: COLOR_FINISH_BUTTON_BLUE,
  },
  documentContainer: {
    display: "flex",
    backgroundColor: "transparent",
    borderWidth: 0,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  documentTitleWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
};

const styles = {
  documentTitle: {
    fontSize: 10,
    marginBottom: 6,
    lineHeight: "10px",
    marginTop: 9,
  },
};

type ConversationProps = {
  selectedMessageId;
  setSelectedMessageId: Dispatch<SetStateAction<any>>;
  isSelectedMessage: boolean;
  item;
  isReceived: boolean;
  isGroupChat: boolean;
  edges: any[]; //MessageEdge[];
  msg;
  index: number;
  setIsAvatarHovered: (isHovered: boolean) => void;
  deletingMessage: boolean;
  onVideoPress: (video) => void;
  onRowPress: (contact) => void;
  onDeleteMessage: (messageId) => void;
  messageError;
};

enum CollectionType {
  EXERCISE = 2,
  DRILL = 3,
  DOCUMENT = 6,
  WORKOUT = 11,
  TEAM_PRACTICE = 49,
}

const canDownload = (file) => {
  let isImage, isVideo, isFile;
  if (file[0]?.mime) {
    const s = file[0].mime.split("/");
    const e = s.length ? s[s.length - 1] : null;
    isImage = e === "png" || e === "jpg" || e === "gif" || e === "jpeg";
    isVideo =
      e === "mp4" || e === "wmv" || e === "avi" || e === "mpeg" || e === "mov";
    isFile = !!e;
  }
  return isImage || isVideo || isFile;
};

const getTitle = (type: number, t: TFunction<"common", undefined>) => {
  switch (type) {
    case CollectionType.EXERCISE:
      return t("exercise");
    case CollectionType.DRILL:
      return t("drill");
    case CollectionType.DOCUMENT:
      return t("document");
    case CollectionType.WORKOUT:
      return t("workout");
    case CollectionType.TEAM_PRACTICE:
      return t("teamPractice");
    default:
      return t("unknown");
  }
};

const getMargin = (msg, index, collections, isImage = false) => {
  const attachments = msg.attachedFiles;
  const oldLinks = msg.oldLinks;

  if (attachments.length > 1 && !oldLinks)
    return attachments.length - 1 !== index ? 10 : 0;
  if (attachments.length === 0 && oldLinks)
    return oldLinks.length - 1 !== index ? 10 : 0;
  if (attachments.length > 0 && oldLinks) {
    if (attachments.length === 1 && oldLinks.length === 1) {
      if (!collections) return 10;
      else {
        return 0;
      }
    }
    if (!collections) {
      return 10;
    } else {
      return oldLinks.length - 1 !== index ? 10 : 0;
    }
  }

  if (isImage) {
    return 10;
  }
};

export function Conversation({
  selectedMessageId,
  setSelectedMessageId,
  isSelectedMessage,
  item,
  isReceived,
  isGroupChat,
  edges,
  msg,
  index,
  setIsAvatarHovered,
  deletingMessage,
  onVideoPress,
  onRowPress,
  onDeleteMessage,
  messageError,
}: ConversationProps) {
  const modal = useModalContext();
  const { t } = useTranslation();

  const getProgramType = (link) => {
    if (link.type === CollectionType.WORKOUT) {
      return "WORKOUT_TEMPLATE";
    }

    if (link.type === CollectionType.TEAM_PRACTICE) {
      return "PRACTICE_TEMPLATE_2020";
    }

    return link.type;
  };

  const handlePressDocument = (link) => {
    const program = {
      ...link,
      type: getProgramType(link),
      guid: link.guidOfUnderlying2,
    };

    modal.actions.openModal({
      title: link.itemName,
      modal: ModalType.DOCUMENT,
      params: { document: link, program },
    });
  };

  const handleOpenImage = (params) =>
    modal.actions.openModal({
      title: "",
      modal: ModalType.MEDIA,
      params,
    });

  return (
    <div
      style={{
        ...styleRecord.messageWrapper,
        zIndex: selectedMessageId === msg.id ? 1 : 0,
      }}
    >
      {isReceived && isGroupChat ? (
        isContentVisible(item, edges[index + 1]) ? (
          <HoverableUserAvatar
            fromName={item.msg.fromName}
            avatarUrl={item.msg.fromImgUrl}
            onHoverIn={() => setIsAvatarHovered(true)}
            onHoverOut={() => setIsAvatarHovered(false)}
          />
        ) : (
          <div style={{ width: "40px" }} />
        )
      ) : null}
      {!isReceived && deletingMessage && isSelectedMessage && (
        <Loader style={{ marginRight: 10 }} />
      )}
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: isReceived ? "flex-start" : "flex-end",
        }}
      >
        <div
          style={{
            ...styleRecord.message,
            ...(isReceived
              ? styleRecord.receivedMessage
              : styleRecord.sentMessage),
          }}
        >
          {!!item.msg.messageBody && (
            <Text
              color={isReceived ? COLOR_TEXT_DEFAULT : COLOR_WHITE}
              hasAttachments={!!msg.attachedFiles.length || msg.oldLinks}
              isReceived={isReceived}
              dangerouslySetInnerHTML={{
                __html: makeLinksClickable(item.msg.messageBody),
              }}
            />
          )}
          {msg.attachedFiles.map((file, index) => {
            const isImage =
              file.mime === "image/png" ||
              file.mime === "image/jpg" ||
              file.mime === "image/jpeg" ||
              file.mime === "image/gif";
            const isVideo =
              file.mime === "video/mp4" ||
              file.mime === "video/wmv" ||
              file.mime === "video/avi" ||
              file.mime === "video/mpeg" ||
              file.mime === "video/quicktime" ||
              file.mime === "video/mov";
            const isFile = !!file.mime;
            let fileName = "";
            if (isFile) {
              const nameArr = file.url.replace(".pdf", "").split("/");
              if (nameArr?.length > 1) {
                const nameWithExt = nameArr[nameArr.length - 1];
                const nameSplitted = nameWithExt.split(".");
                if (nameSplitted.length) {
                  nameSplitted.shift();
                  fileName = nameSplitted.join("");
                }
              }
            }
            return (
              <div key={index}>
                <div>
                  {isFile && !isImage && (
                    <FileAttachmentButton
                      margin={getMargin(msg, index, false, isImage)}
                      onClick={() =>
                        file.url ? window.open(file.url, "_blank") : null
                      }
                    >
                      <DocumentIconWrapper>
                        <NewDocumentIcon
                          tintColor={COLOR_WHITE}
                          style={{
                            transform: `scale(1.3)`,
                          }}
                        />
                      </DocumentIconWrapper>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <StyledText
                          fontSize={10}
                          color={!isReceived ? "white" : COLOR_MEDIUM_GRAY}
                          style={styles.documentTitle}
                        >
                          {t("file").toUpperCase()}
                        </StyledText>
                        <DocumentSubtitle isReceived={isReceived}>
                          {fileName.length > 30
                            ? `${fileName.slice(0, 29)}...`
                            : fileName}
                        </DocumentSubtitle>
                      </div>
                    </FileAttachmentButton>
                  )}
                  {isImage && (
                    <BasicButton onClick={() => handleOpenImage({ file })}>
                      <AttachedImage
                        src={file.thumb || file.url}
                        margin={getMargin(msg, index, false)}
                      />
                    </BasicButton>
                  )}

                  {isVideo && (
                    <VideoWrapper
                      style={{
                        marginBottom: getMargin(msg, index, false),
                      }}
                    >
                      <ReactJWPlayer
                        playerId={msg.id}
                        playerScript="https://cdn.jwplayer.com/libraries/g9KQjRPV.js"
                        file={file.url}
                        image={file.thumb}
                      />
                    </VideoWrapper>
                  )}
                </div>
              </div>
            );
          })}
          {msg.imageUrl ? (
            <AttachmentButton
              onClick={() =>
                handleOpenImage({
                  file: {
                    mime: "image/jpeg",
                    url: msg.imageUrl,
                    __typename: "AttachedFile",
                  },
                })
              }
            >
              <AttachedImage src={msg.imageThumbUrl} height={100} />
            </AttachmentButton>
          ) : null}
          {msg.videoUrl ? (
            <AttachmentButton onClick={() => onVideoPress(msg.videoUrl)}>
              <AttachedImage height={100} src={msg.videoThumbUrl} />
            </AttachmentButton>
          ) : null}
          <div>
            {msg?.oldLinks?.map((link, index) => {
              return link ? (
                link.type === CollectionType.EXERCISE ||
                link.type === CollectionType.DRILL ||
                link.type === CollectionType.DOCUMENT ||
                link.type === CollectionType.WORKOUT ||
                link.type === CollectionType.TEAM_PRACTICE ? (
                  <button
                    key={index}
                    style={{
                      ...styleRecord.documentContainer,
                      marginBottom: getMargin(msg, index, true),
                    }}
                    onClick={() => handlePressDocument(link)}
                  >
                    <DocumentIconWrapper>
                      <NewDocumentIcon
                        tintColor={COLOR_WHITE}
                        style={{
                          transform: `scale(1.3)`,
                        }}
                      />
                    </DocumentIconWrapper>
                    <div style={styleRecord.documentTitleWrapper}>
                      <StyledText
                        fontSize={10}
                        color={!isReceived ? "white" : COLOR_MEDIUM_GRAY}
                        style={styles.documentTitle}
                      >
                        {getTitle(link.type, t).toUpperCase()}
                      </StyledText>
                      <DocumentSubtitle isReceived={isReceived}>
                        {link.itemName}
                      </DocumentSubtitle>
                    </div>
                  </button>
                ) : null
              ) : null;
            })}
          </div>
        </div>
      </div>

      <ChatMessageDropdown
        setSelectedMessageId={setSelectedMessageId}
        isReceived={isReceived}
        msg={msg}
        canDownload={canDownload}
        onRowPress={onRowPress}
        onDeleteMessage={onDeleteMessage}
        selectedMessageId={selectedMessageId}
        messageError={messageError}
      />
    </div>
  );
}
