import React from "react";
import { useTranslation } from "react-i18next";
import { components } from "react-select";

import styled from "styled-components";

import { COLOR_BLUE } from "../colors";

const MoreLabel = styled.p`
  margin: 0;
  color: ${COLOR_BLUE};
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
`;

const MAX_TO_SHOW = 3;

export function MultiValue({ index, getValue, ...props }) {
  const { t } = useTranslation();

  const overflow = getValue()
    .slice(MAX_TO_SHOW)
    .map((value) => value.label);

  const length = overflow.length;

  return index < MAX_TO_SHOW ? (
    <components.MultiValue {...(props as any)} />
  ) : index === MAX_TO_SHOW ? (
    <MoreLabel>{t("moreSelect", { x: length })}</MoreLabel>
  ) : null;
}
