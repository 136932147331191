import React from "react";

import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";

import { CustomCalendarEvent } from "./CustomCalendarEvent";
import { CustomCalendarEventWrapper } from "./CustomCalendarEventWrapper";
import { CustomCalendarMonthDateHeader } from "./CustomCalendarMonthDateHeader";
import { CustomCalendarMonthHeader } from "./CustomCalendarMonthHeader";
import { CustomCalendarTimeGutterHeader } from "./CustomCalendarTimeGutterHeader";
import { CustomCalendarToolbar } from "./CustomCalendarToolbar";
import { CustomCalendarWeekHeader } from "./CustomCalendarWeekHeader";

const createToolbarComponent = () => (props) => {
  return (
    <div style={{ zIndex: 999 }}>
      <CustomCalendarToolbar {...props} />
    </div>
  );
};

const createTimeGutterHeader = () => (props) => {
  const { currentWeek } = useAgendaContext();

  return <CustomCalendarTimeGutterHeader {...props} week={currentWeek} />;
};

export const CALENDAR_COMPONENTS = {
  event: CustomCalendarEvent,
  eventWrapper: CustomCalendarEventWrapper,
  timeGutterHeader: createTimeGutterHeader(),
  toolbar: createToolbarComponent(),
  month: {
    header: CustomCalendarMonthHeader,
    dateHeader: CustomCalendarMonthDateHeader,
  },
  week: {
    header: CustomCalendarWeekHeader,
  },
};
