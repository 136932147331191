import _chunk from "lodash/chunk";

import {
  AggregateFunction,
  ReportWidgetCell,
  WidgetCell,
} from "../../../graphql";
import {
  isCustomTextSoccerAttribute,
  isInformationCollection,
  isSoccerStatsCollection,
} from "../../../utils/reports";

import { KeyValuesWidgetFormDataItem } from "./models";

export function mapKeyValuesWidgetFormDataItemsToReportWidgetCellGrid(
  items: KeyValuesWidgetFormDataItem[],
  numberOfColumns: number
): ReportWidgetCell[][] {
  const validColumns = items.filter(
    (column) => column.collectionId && column.measurementTemplateId
  );

  return _chunk(validColumns, numberOfColumns).map((chunk) => {
    return Array.from({ length: numberOfColumns }).map<ReportWidgetCell>(
      (_, i) => {
        const column = chunk[i];
        if (!column) {
          return null;
        }

        return isInformationCollection(column.collectionId)
          ? {
              __typename: "ReportWidgetCellTextual",
              id: column.id,
              templateId: column.measurementTemplateId,
              name: column.measurementTemplateName,
              collectionId: column.collectionId,
              aggregateFunctions: [column.aggregateFunction],
            }
          : {
              __typename: "ReportWidgetCellNumerical",
              id: column.id,
              templateId: column.measurementTemplateId,
              name: column.measurementTemplateName,
              collectionId: column.collectionId,
              aggregateFunctions: [column.aggregateFunction],
              textOptions: column.textOptions,
              attributeTemplate: {
                id: column.attributeTemplateId,
                name: column.attributeTemplateName,
                unitName: column.attributeTemplateUnitName,
              },
            };
      }
    );
  });
}

export function mapKeyValuesWidgetFormDataItemsToWidgetCellGrid(
  columns: KeyValuesWidgetFormDataItem[],
  numberOfColumns: number
): WidgetCell[][] {
  return _chunk(columns, numberOfColumns).map((chunk) => {
    return Array.from({ length: numberOfColumns }).map((_, i) => {
      const column = chunk[i] ?? null;

      return (
        column && {
          attributeTemplateId: column.attributeTemplateId,
          collectionId: column.collectionId,
          itemId: column.measurementTemplateId,
          aggregateFunction:
            column.aggregateFunction || AggregateFunction.Latest,
          id: column.id,
        }
      );
    });
  });
}

const GROUP_AGGREGATIONS = [AggregateFunction.Average, AggregateFunction.Sum];

const INFORMATION_COLLECTION_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LatestForever,
  AggregateFunction.LastDayInRange,
];

const SOCCER_COLLECTION_TEXT_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LatestForever,
];

const getSoccerAggregationOptions = (
  currentRow: Partial<KeyValuesWidgetFormDataItem>,
  isGroupWidget: boolean
): AggregateFunction[] | undefined => {
  const measurementTemplateId = currentRow?.measurementTemplateId;

  if (isCustomTextSoccerAttribute(measurementTemplateId)) {
    return SOCCER_COLLECTION_TEXT_AGGREGATIONS;
  }

  if (isGroupWidget) {
    switch (measurementTemplateId) {
      case "SoccerStat.GamesPlayed":
        return [AggregateFunction.Sum];
      case "SoccerStat.MinutesPerGame":
        return [AggregateFunction.Average];
      default:
        return GROUP_AGGREGATIONS;
    }
  }

  switch (measurementTemplateId) {
    case "SoccerStat.GamesPlayed":
      return [AggregateFunction.Sum];
    case "SoccerStat.MinutesPerGame":
      return [AggregateFunction.Average];
    case "SoccerStat.CleanSheets":
      return [AggregateFunction.Sum, AggregateFunction.Average];
    case "SoccerStat.RedCards":
    case "SoccerStat.YellowCards":
      return [
        AggregateFunction.Latest,
        AggregateFunction.Sum,
        AggregateFunction.Average,
      ];
    default:
      return undefined;
  }
};

export const getAggregationOptions = (
  currentRow: Partial<KeyValuesWidgetFormDataItem>,
  isGroupWidget: boolean,
  defaultAggregations: AggregateFunction[]
): AggregateFunction[] => {
  if (isSoccerStatsCollection(currentRow?.measurementTemplateId)) {
    return (
      getSoccerAggregationOptions(currentRow, isGroupWidget) ??
      defaultAggregations
    );
  }

  if (isGroupWidget) {
    return GROUP_AGGREGATIONS;
  }

  if (isInformationCollection(currentRow.collectionId)) {
    return INFORMATION_COLLECTION_AGGREGATIONS;
  }

  return defaultAggregations;
};
