import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";
import { XpsUser } from "../../graphql";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { AvatarSelectWrapper } from "../AvatarSelectWrapper";
import Tabs, { Tab } from "../Tabs";

type CollectionTrainerTabsProps = {
  isFromMessaging?: boolean;
  selectedAccount: XpsUser;
  setFolder: Dispatch<SetStateAction<string>>;
  setTab: Dispatch<SetStateAction<Tab>>;
  tab: Tab;
  tabs: Tab[];
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  padding-right: 30px;
  padding-bottom: 16px;
  margin-left: 30px;
  z-index: 2;
`;

const TabsWrapper = styled.div<{ hasAccount: boolean }>`
  display: flex;
  margin-left: ${({ hasAccount }) => (hasAccount ? 0 : "10px")};
`;

export function CollectionTrainerTabs({
  isFromMessaging,
  selectedAccount,
  setFolder,
  setTab,
  tab,
  tabs,
}: CollectionTrainerTabsProps) {
  return (
    <Wrapper>
      <AvatarSelectWrapper
        isFromMessaging={isFromMessaging}
        accessLevelArea="documents"
      />
      <TabsWrapper hasAccount={!selectedAccount?.id}>
        <Tabs
          tab={tab}
          setTab={async (t) => {
            Storage.setItem(StorageKeys.selectedCollectionTab, t);
            setFolder(null);
            setTab(t);
          }}
          tabs={tabs}
          style={{ alignItems: "flex-start" }}
          tabStyle={{ marginBottom: 0 }}
        />
      </TabsWrapper>
    </Wrapper>
  );
}
