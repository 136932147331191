import dayjs, { Dayjs } from "dayjs";

import * as api from "../api";
import * as endpoints from "../endpoints";

export type AthleteSoccerStatisticsAggregate = {
  _a2a: number;
  _assists: number;
  _cleanSheet: number;
  _games: number;
  _goals: number;
  _grade: number;
  _minutes: number;
  _points: number;
  _red: number;
  _yellow: number;
};

export type AthleteSoccerStatisticsResponse =
  AthleteSoccerStatisticsAggregate & {
    _minutesPerGame: number;
    minutesPerPos: Record<string, number>;
    extraCols: Record<string, number>;
    extraTextCols: Record<string, string>;
    aggregation: {
      average: AthleteSoccerStatisticsAggregate;
      last: AthleteSoccerStatisticsAggregate;
      max: AthleteSoccerStatisticsAggregate;
      min: AthleteSoccerStatisticsAggregate;
      sum: AthleteSoccerStatisticsAggregate;
    };
    aggregationTimeless?: {
      last: AthleteSoccerStatisticsAggregate;
      max: AthleteSoccerStatisticsAggregate;
      min: AthleteSoccerStatisticsAggregate;
    };
  };

export type FetchAthleteSoccerStatisticsArgs = {
  session: api.Session;
  from: Dayjs;
  to: Dayjs;
  allGroups?: boolean;
  focusedUserAccount?: string;
  withAllTimeResults?: boolean;
};

export function fetchAthleteSoccerStatistics({
  session,
  from,
  to,
  allGroups = false,
  focusedUserAccount,
  withAllTimeResults,
}: FetchAthleteSoccerStatisticsArgs) {
  const params = {
    _focusedUserAccount: focusedUserAccount,
    _from: dayjs(from).valueOf(),
    _to: dayjs(to).valueOf(),
    _allGroupsInAcccount: allGroups,
    withAllTimeResults,
    ...session,
  };

  return api.post<AthleteSoccerStatisticsResponse>(
    endpoints.JsonTemporarySoccerQuery,
    params,
    session
  );
}

export type GameStatistics = {
  a2a?: number;
  assists?: number;
  cleanSheet?: number;
  goals?: number;
  grade?: number;
  minutes?: number;
  points?: number;
  red?: number;
  yellow?: number;
};

export type SoccerGameStatistics = GameStatistics & {
  date?: number;
  gameId?: string;
  minutesPerPos?: Record<string, number>;
  extraCols?: Record<string, number>;
  extraTextCols?: Record<string, string>;
};

export type SoccerPeriodStatistics = GameStatistics & {
  start?: number;
  end?: number;
  numberOfGames?: number;
  minutesPerPos?: Record<string, number>;
  mpg?: number;
  extraCols?: Record<string, number>;
  extraTextCols?: Record<string, string>;
  aggregation?: {
    average: GameStatistics;
    last: GameStatistics;
    max: GameStatistics;
    min: GameStatistics;
    sum: GameStatistics;
  };
  aggregationTimeless?: {
    last: GameStatistics;
    max: GameStatistics;
    min: GameStatistics;
  };
};

export type FetchSoccerStatisticsResponse = {
  perAthletes?: {
    athleteId?: string;
    athleteName?: string;
    games?: SoccerGameStatistics[];
    periods?: SoccerPeriodStatistics[];
    aggregation?: {
      average: GameStatistics;
      last: GameStatistics;
      max: GameStatistics;
      min: GameStatistics;
      sum: GameStatistics;
    };
    aggregationTimeless?: {
      last: GameStatistics;
      max: GameStatistics;
      min: GameStatistics;
    };
  }[];
};

export type FetchSoccerStatisticsArgs = {
  session: api.Session;
  from: Dayjs;
  to: Dayjs;
  athleteGuids: string[];
  groupGuids?: string[];
  periodization?: "No" | "Day" | "Week" | "Month" | "Year";
  reportTemplateId: string;
  focusedUserAccount?: string;
  withAllTimeResults?: boolean;
};

export function fetchSoccerStatistics({
  session,
  from,
  to,
  athleteGuids,
  groupGuids,
  periodization,
  reportTemplateId,
  focusedUserAccount,
  withAllTimeResults,
}: FetchSoccerStatisticsArgs) {
  const params = {
    _focusedUserAccount: focusedUserAccount,
    ...session,
    _from: dayjs(from).valueOf(),
    _to: dayjs(to).valueOf(),
    _athleteGuids: athleteGuids,
    _groupGuids: groupGuids,
    periodization,
    reportTemplateId,
    withAllTimeResults,
  };

  return api.post<FetchSoccerStatisticsResponse>(
    endpoints.JsonSoccerQuery,
    params,
    session
  );
}

export type FetchSoccerTeamStatisticsArgs = {
  session: api.Session;
  from: Dayjs;
  to: Dayjs;
  groupGuids: string[];
  periodization?: "No" | "Day" | "Week" | "Month" | "Year";
  reportTemplateId: string;
  focusedUserAccount?: string;
};

export type FetchSoccerTeamStatisticsResponse = {
  perTeams?: {
    groupId?: string;
    groupName?: string;
    games?: SoccerGameStatistics[];
    periods?: SoccerPeriodStatistics[];
  }[];
};

export function fetchSoccerTeamStatistics({
  session,
  from,
  to,
  groupGuids,
  periodization,
  reportTemplateId,
  focusedUserAccount,
}: FetchSoccerTeamStatisticsArgs) {
  const params = {
    _focusedUserAccount: focusedUserAccount,
    _from: dayjs(from).valueOf(),
    _to: dayjs(to).valueOf(),
    _groupGuids: groupGuids,
    periodization,
    reportTemplateId,
    ...session,
  };

  return api.post<FetchSoccerTeamStatisticsResponse>(
    endpoints.JsonSoccerQuery,
    params,
    session
  );
}
