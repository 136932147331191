import React, { useEffect } from "react";

import styled from "styled-components";

import { PrintUnbreakableDiv } from "../components/PrintUnbreakableElement";
import { Widget } from "../components/Report/Widget";
import { WidgetMode } from "../components/Report/WidgetWithData";
import { WidgetType } from "../components/ReportCreateTable/models";

// Disclaimer: any changes executed in this file should be reviewed and check in browser print preview window.
// Do not change any display types and styling as it is set up to support printing in the best possible way
// Preferably discuss changes here with Miroslav Kubus - miroslav.kubus@techmates.io / kubus.miro@gmail.com

const PrintReportWrapper = styled.div`
  display: block;

  @media print {
    display: table;
    width: 100%;
  }

  @page {
    margin: 5mm !important;
  }
`;

const getWidgetCss = (
  width: string,
  height: string,
  experimentalGrid: boolean
) =>
  width !== "100%" && experimentalGrid
    ? `
  display: inline-block;
  margin: 30px 1.25px 0;
  max-width: 100%;
  width: calc(${width} - 2.5px);
  height: ${height};
  vertical-align: top;
`
    : `
  margin-bottom: 30px;
  height: ${height};
`;

type WidgetWrapperProps = {
  width?: string;
  height?: string;
  experimentalGrid?: boolean;
};

const UnbreakableWidget = styled(PrintUnbreakableDiv)<WidgetWrapperProps>`
  ${({ width, height, experimentalGrid }) =>
    getWidgetCss(width, height, experimentalGrid)};
`;

const MarginWrapper = styled.div<WidgetWrapperProps>`
  ${({ width, height, experimentalGrid }) =>
    getWidgetCss(width, height, experimentalGrid)};
`;

interface PrintReportProps {
  filteredWidgets: any[];
  experimentalGrid: boolean;
  landscapeOrientation: boolean;
  isAthlete?: boolean;
}

export const PrintReport = React.forwardRef<HTMLDivElement, PrintReportProps>(
  (
    { filteredWidgets, experimentalGrid, landscapeOrientation, isAthlete },
    ref
  ) => {
    // This is needed for react-highchart to rerender chart to take right width base on orientation or grid
    useEffect(() => {
      setTimeout(() => window.dispatchEvent(new Event("resize")));
    }, [experimentalGrid, landscapeOrientation]);

    return (
      // display table soooo important for printing - DO NOT CHANGE IT
      <PrintReportWrapper ref={ref}>
        {filteredWidgets.map((widget) => {
          const isHighchartWidget =
            widget.type === WidgetType.CHART ||
            widget.type === WidgetType.COMBINED_TEST ||
            widget.type === WidgetType.ACUTE_CHRONIC;

          const Wrapper = isHighchartWidget ? UnbreakableWidget : MarginWrapper;

          return (
            <Wrapper
              experimentalGrid={experimentalGrid}
              width={widget.printWidth ?? "100%"}
              height={
                isHighchartWidget && widget.printHeight
                  ? widget.printHeight
                  : "auto"
              }
              key={widget.id}
            >
              <Widget
                widget={widget}
                mode={isAthlete ? WidgetMode.ATHLETE : WidgetMode.GROUP}
                readOnly
                layoutRef={{ current: null }}
              />
            </Wrapper>
          );
        })}
      </PrintReportWrapper>
    );
  }
);
