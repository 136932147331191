import { ROUTING_CONFIG } from "../router/RoutingConfig";

export const useIsMonitoring = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const fromMonitoring = searchParams.get("fromMonitoring") ?? "";

  return (
    window.location.pathname.includes(ROUTING_CONFIG.AthleteProfile) ||
    Boolean(fromMonitoring)
  );
};
