import React, { useLayoutEffect, useRef, useState } from "react";

import { Dayjs } from "dayjs";
import { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import { COLOR_GRAY, COLOR_WHITE } from "../../colors";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../hooks";
import { WidgetContainer } from "../Report/styled";
import { TeamsAthletesDataComposition } from "../ReportCreateTable/hooks/useGroupStatsData";
import { GroupTableStatsData } from "../ReportCreateTable/hooks/useGroupStatsData/helpers/models";
import {
  ColoringConfig,
  GroupTableSortBy,
  IRule,
} from "../ReportCreateTable/models";
import { StickyColumn } from "../TableStickyColumn/StickyColumn";

import { GroupTableHeader } from "./GroupTableHeader";
import { GroupTableRow } from "./GroupTableValueRow";
import { useGroupTableRowsData } from "./hooks/useGroupTableRowsData";
import { StyledTableComponent } from "./styled";

interface ITableProps extends IWidgetResizeProps {
  id?: string;
  title: string;
  tableData: GroupTableStatsData;
  dataComposition?: TeamsAthletesDataComposition;
  alternatingRow?: boolean;
  coloringConfig?: ColoringConfig;
  alternatingColumns?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  widgetBorders?: boolean;
  outsideBorderDividers?: boolean;
  sumRow?: boolean;
  averageRow?: boolean;
  averageWithoutZeroes?: boolean;
  sortBy?: GroupTableSortBy;
  colorIndicator?: string;
  sortingOrder?: number;
  lastDayInRange?: Dayjs;
  isSingleDayReport?: boolean;
  isReportViewMode?: boolean;
  showValuesAsText?: boolean;
  onChangeSort?: (
    sort: GroupTableSortBy & {
      order: number;
    }
  ) => void;
  maxCount?: number;
  rules?: IRule[];
}

export const GroupTable = ({
  id,
  title,
  tableData,
  dataComposition = "athletes",
  alternatingRow,
  alternatingColumns,
  rowDividers,
  columnDividers,
  outsideBorderDividers,
  widgetBorders,
  sumRow,
  averageRow,
  averageWithoutZeroes,
  sortBy,
  colorIndicator,
  sortingOrder = null,
  lastDayInRange,
  isSingleDayReport,
  gridItemId,
  isReportViewMode,
  layoutRef,
  coloringConfig = [],
  onChangeSort,
  setItemToResize,
  setItemsToHideInViewMode,
  showValuesAsText,
  maxCount,
  rules = [],
}: ITableProps) => {
  const { isMobile } = useTheme();
  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();
  const [hiddenHeaderHeight, setHiddenHeaderHeight] = useState<number>();
  const [shouldAutoScroll, setShouldAutoScroll] = useState(false);

  const scrollContainerRef = useRef<any>();
  const hiddenItemContainerRef = useRef<any>();
  const tableRowRef = useRef<number[]>([]);

  const [shouldDisplayLongName, setShouldDisplayLongName] =
    useState<boolean>(true);
  const [sortByConfig, setSortByConfig] = useState<
    GroupTableSortBy & {
      order: number;
    }
  >({ statsIndex: null, valueIndex: null, attributeIndex: null, order: -1 });

  useBottomWidgetResize({
    id,
    shouldResize: isReportViewMode,
    gridItemId,
    widgetHeight,
    layoutRef,
    setItemToResize,
    setItemsToHideInViewMode,
    alwaysResize: true,
  });

  useLayoutEffect(
    () =>
      setSortByConfig({
        // Only for backward compatibility
        ...(typeof sortBy === "object"
          ? sortBy
          : { statsIndex: null, valueIndex: null, attributeIndex: null }),
        order: sortingOrder ?? -1,
      }),
    [sortBy, sortingOrder]
  );

  useLayoutEffect(() => {
    // To correctly display table with short names on first render
    if (
      isMobile &&
      scrollContainerRef.current?.scrollWidth >
        scrollContainerRef.current?.clientWidth
    ) {
      setShouldDisplayLongName(false);
      setShouldAutoScroll(true);
    }
  }, [isMobile]);

  useLayoutEffect(() => {
    // To correctly display table with short names on first render
    if (
      !shouldDisplayLongName &&
      scrollContainerRef.current &&
      hiddenItemContainerRef.current &&
      isMobile &&
      shouldAutoScroll
    ) {
      scrollContainerRef.current.scrollBy({
        left: hiddenItemContainerRef.current.offsetWidth - 10 || 100,
      });
      setShouldAutoScroll(false);
    }
  }, [shouldAutoScroll, shouldDisplayLongName, isMobile]);

  const rowsData = useGroupTableRowsData({
    tableData,
    sortByConfig,
    sumRow,
    averageRow,
    averageWithoutZeroes,
    maxCount,
  });

  const onHeaderTitleClick = ({
    statsIndex,
    valueIndex,
    attributeIndex,
  }: GroupTableSortBy) => {
    const newOrder =
      sortByConfig.statsIndex === statsIndex &&
      sortByConfig.valueIndex === valueIndex &&
      sortByConfig.attributeIndex === attributeIndex
        ? -1 * sortByConfig.order
        : -1;

    setSortByConfig({
      statsIndex,
      valueIndex,
      attributeIndex,
      order: newOrder,
    });

    onChangeSort?.({ statsIndex, valueIndex, attributeIndex, order: newOrder });
  };

  const paddingValue = !isMobile ? 30 : 15;

  return (
    <WidgetContainer
      showBorders={widgetBorders}
      id={`group-table-${gridItemId}`}
    >
      <div ref={tableRef as any}>
        <StickyColumn
          alternatingRow={alternatingRow}
          colorIndicator={colorIndicator}
          hiddenHeaderHeight={hiddenHeaderHeight}
          hiddenItemContainerRef={hiddenItemContainerRef}
          groupTableRowsData={rowsData}
          scrollContainerRef={scrollContainerRef}
          setShouldDisplayLongName={setShouldDisplayLongName}
          shouldDisplayLongName={shouldDisplayLongName}
          title={title}
          tableRowArray={tableRowRef?.current}
        >
          <StyledTableComponent
            id="group-table-container-table-el"
            paddingValue={paddingValue}
          >
            <thead>
              <GroupTableHeader
                tableData={tableData}
                dataComposition={dataComposition}
                setSortBy={onHeaderTitleClick}
                sortBy={sortByConfig}
                lastDayInRange={lastDayInRange}
                isSingleDayReport={isSingleDayReport}
                alternatingColumns={alternatingColumns}
                shouldDisplayLongName={shouldDisplayLongName}
                setHiddenHeaderHeight={setHiddenHeaderHeight}
              />
            </thead>

            <tbody>
              {rowsData.map((item, index) => (
                <GroupTableRow
                  tableRowRef={tableRowRef}
                  key={`${item.athleteName}-${index}`}
                  item={item}
                  color={
                    alternatingRow && index % 2 === 0 ? COLOR_GRAY : COLOR_WHITE
                  }
                  rowDividers={rowDividers}
                  columnDividers={columnDividers}
                  outsideBorderDividers={outsideBorderDividers}
                  alternatingColumns={alternatingColumns}
                  rules={rules}
                  colorIndicator={colorIndicator}
                  shouldDisplayLongName={shouldDisplayLongName}
                  showValuesAsText={showValuesAsText}
                  isFirstRow={index === 0}
                  isLastRow={index === rowsData.length - 1}
                  coloringConfig={coloringConfig}
                  index={index}
                />
              ))}
            </tbody>
          </StyledTableComponent>
        </StickyColumn>
      </div>
    </WidgetContainer>
  );
};
