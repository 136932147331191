import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useNotificationsContext } from "../contexts/notifications";
import { useTeamsContext } from "../contexts/teams";
import { ModalType, useModalContext } from "../contexts/UI/Modal";

import { HoverableItem } from "./HoverableItem";

export const CreateTeamOrGroupDropdownContent = ({ group }) => {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { onSetGroup, refetch } = useTeamsContext();
  const { showErrorNotification } = useNotificationsContext();

  const { accessLevels, selectedAccount } = useAccessLevelsContext();

  const { fullAccess, edit } = useMemo(() => {
    return (
      accessLevels.find(
        (accessLevel) => accessLevel?.user?.id === selectedAccount?.id
      )?.userManagement || {}
    );
  }, [accessLevels, selectedAccount?.id]);

  const handleCreateTeamOrGroupPress = (isTeam: boolean) => {
    if (selectedAccount?.id !== ALL_ACCOUNTS_ID) {
      if (isTeam && !fullAccess) {
        showErrorNotification(t("cannotCreateTeam"));
        return false;
      }

      if (!isTeam && !edit) {
        showErrorNotification(t("cannotCreateGroup"));
        return false;
      }
    }

    modal.actions.openModal({
      modal: ModalType.SAVE_GROUP,
      title: t(isTeam ? "addTeam" : "addGroup"),
      params: {
        disableCancel: true,
        parentGroup: isTeam ? null : group?.id,
        parentGroupOwnerId: group?.owner?.id,
        isTeam,
        onSetGroup,
        refetch,
      },
    });
  };

  return (
    <>
      <HoverableItem
        label={t("team")}
        onClick={() => handleCreateTeamOrGroupPress(true)}
      />
      <HoverableItem
        label={t("group")}
        onClick={() => handleCreateTeamOrGroupPress(false)}
      />
    </>
  );
};
