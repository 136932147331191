import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { GetCreateNewChatGroupsQuery } from "../../../graphql";
import { GroupSubGroupSelector } from "../../GroupSubGroupSelector";
import StyledText from "../../StyledText";
import { StyledTextInput } from "../../StyledTextInput";

const ControlSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ControlTitle = styled(StyledText)`
  margin-top: 15px;
  margin-bottom: 5px;
`;

interface ControlBarTeamChatProps {
  groupName: string;
  setGroupName: (_: string) => void;
  groupsData?: GetCreateNewChatGroupsQuery["getTheGroupsImIn"];
}

export const ControlBarTeamChat = ({
  groupName,
  setGroupName,
  groupsData,
}: ControlBarTeamChatProps) => {
  const { t } = useTranslation();

  return (
    <ControlSectionWrapper>
      {groupsData && (
        <>
          <ControlTitle fontSize={10} fontWeight="semibold">
            {t("team").toUpperCase()}
          </ControlTitle>
          <GroupSubGroupSelector groups={groupsData} />
        </>
      )}
      <ControlTitle fontSize={10} fontWeight="semibold">
        {t("title").toUpperCase()}
      </ControlTitle>
      <StyledTextInput
        onChangeText={setGroupName}
        value={groupName}
        placeholder={t("groupName")}
      />
    </ControlSectionWrapper>
  );
};
