import React, {
  CSSProperties,
  Dispatch,
  ReactNode,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../colors";

import { GeneralButton } from "./Button/";
import { CreateSessionDropdownContent } from "./CreateSessionDropdownContent";
import { CreateTeamOrGroupDropdownContent } from "./CreateTeamOrGroupDropdownContent";
import Dropdown from "./Dropdown";
import GroupSelector, { GroupValue } from "./GroupSelector";
import { PlusIcon } from "./Icons";
import StyledText from "./StyledText";
import Tabs, { Tab } from "./Tabs";
import TeamsGroupSelector from "./TeamsGroupSelector";

type Props = {
  tab: Tab;
  isButtonVisible?: boolean;
  isGroupSelectorVisible?: boolean;
  tabs: Tab[];
  shouldDisplaySubgroups?: boolean;
  focusedEntityComponent?: ReactNode;
  setTab: Dispatch<SetStateAction<Tab>>;
  group?: GroupValue;
  onSetGroup?: (value: GroupValue) => void;
  isCreateButtonForSession?: boolean;
  isTeamsModule?: boolean;
  groupSelectorLoading?: boolean;
  withoutTeams?: boolean;
};

const tabStyles = {
  backgroundColor: COLOR_WHITE,
  paddingTop: 10,
  paddingBottom: 5,
  justifyContent: "center",
  borderBottomColor: COLOR_GREY_ACTIVE,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
};

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9999;
`;

const GroupSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  z-index: 3;
  left: 20px;
  top: 4px;
`;

const GroupLabel = styled(StyledText)`
  color: ${COLOR_MEDIUM_GRAY};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin-right: 15px;
`;

export function SubHeader({
  tab,
  tabs,
  setTab,
  isButtonVisible = false,
  isGroupSelectorVisible = false,
  shouldDisplaySubgroups = true,
  focusedEntityComponent = null,
  group,
  onSetGroup,
  isCreateButtonForSession = true,
  isTeamsModule = false,
  groupSelectorLoading,
  withoutTeams = false,
}: Props) {
  const { t } = useTranslation();

  if (withoutTeams) {
    return null;
  }

  return (
    <HeaderContainer>
      {isGroupSelectorVisible && (
        <GroupSelectorWrapper>
          <GroupLabel>{t("team").toUpperCase()}</GroupLabel>
          {isTeamsModule ? (
            <TeamsGroupSelector
              isLoading={groupSelectorLoading}
              hideLabel
              value={group}
              onChange={onSetGroup}
            />
          ) : (
            <GroupSelector
              shouldDisplaySubgroups={shouldDisplaySubgroups}
              hideLabel
              value={group}
              onChange={onSetGroup}
            />
          )}
        </GroupSelectorWrapper>
      )}

      {focusedEntityComponent}

      <Tabs
        tab={tab}
        tabs={tabs}
        setTab={setTab}
        style={tabStyles as CSSProperties}
      />

      {isButtonVisible ? (
        <Dropdown
          dropdownMainContainerStyle={{
            position: "absolute",
            right: 20,
            top: 10,
          }}
          dropdownContainerStyle={{
            marginRight: 0,
            marginTop: 25,
            padding: 0,
            width: "fit-content",
            minWidth: 120,
          }}
          dropdownContentStyle={{
            padding: "5px 0",
          }}
          component={({ toggle }) => (
            <GeneralButton
              label={t("createNew")}
              icon={<PlusIcon isActive />}
              onClick={toggle}
            />
          )}
        >
          {isCreateButtonForSession ? (
            <CreateSessionDropdownContent />
          ) : (
            <CreateTeamOrGroupDropdownContent group={group} />
          )}
        </Dropdown>
      ) : null}
    </HeaderContainer>
  );
}
