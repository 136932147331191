import styled from "styled-components";

export const IconButtonWrapper = styled.div<{ mt?: number; ml?: number }>`
  display: flex;
  margin-top: ${({ mt }) => (mt ? mt : 0)}px;
  margin-left: ${({ ml }) => ml ?? 5}px;
`;

export const ReorderImageWrapper = styled.div<{ mr?: number }>`
  display: flex;
  margin-right: ${({ mr }) => mr ?? 4}px;
`;

export const ColumnConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  margin-bottom: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 0;
`;

export const StyledRowItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  min-height: 45px;
  flex: 1;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 18px;
  width: 33%;
`;

export const SectionWrapperForColorPicker = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 999;
`;

export const OuterWrapper = styled.div`
  display: flex;
`;

export const InnerWrapper = styled.div`
  flex: 1;
`;
