import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_DARK_BLUE, COLOR_WHITE } from "../../colors";
import { ButtonColor, GeneralButton } from "../../components/Button/";
import { LogoAcesNationIcon, LogoXpsIcon } from "../../components/Icons";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { useLogOut } from "../../hooks/useLogOut";
import { TermCategory, updateTerms } from "../../services/login/terms";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";

const TermsModalWrapper = styled.div`
  background-color: ${COLOR_DARK_BLUE};
  padding: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 30px 0 60px 0;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65vh;
`;

const TermsTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  color: ${COLOR_WHITE};
  text-align: center;
`;

const TermsSubTitle = styled.span`
  font-size: 16px;
  margin-bottom: 20px;
  color: ${COLOR_WHITE};
  text-align: center;
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  width: 100%;
  position: absolute;
  bottom: 30px;
`;

interface TermsModalProps {
  terms: TermCategory[];
}

export const TermsModal = ({ terms }: TermsModalProps) => {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const {
    actions: { closeModal },
  } = useModalContext();
  const [indexOfCurrentTerm, setIndexOfCurrentTerm] = useState(0);

  const { handleLogout } = useLogOut();

  if (!terms?.length || indexOfCurrentTerm >= terms.length) {
    return null;
  }

  const currentTerm = terms[indexOfCurrentTerm];

  const onFullTermsClick = () => window.open(currentTerm.url, "_blank");

  const moveToNextTerm = () => {
    if (indexOfCurrentTerm < terms.length - 1) {
      setIndexOfCurrentTerm((old) => old + 1);
    } else {
      closeModal();
    }
  };

  const onConfirmTerm = async () => {
    await updateTerms({
      session: { sessionId },
      termId: currentTerm.termId,
      accepted: true,
    });

    moveToNextTerm();
  };

  const onDeclineTerm = async () => {
    await updateTerms({
      session: { sessionId },
      termId: currentTerm.termId,
      accepted: false,
    });

    if (currentTerm.logOutIfNotAccepted) {
      handleLogout();
      closeModal();
    } else {
      moveToNextTerm();
    }
  };

  return (
    <TermsModalWrapper>
      {isAcesNationEnv() ? (
        <LogoAcesNationIcon width={128} height={98} />
      ) : (
        <LogoXpsIcon width={128} height={34} />
      )}
      <InfoWrapper>
        <TermsTitle>{currentTerm.title}</TermsTitle>
        <TermsSubTitle>{currentTerm.smallPrint}</TermsSubTitle>

        {currentTerm.url && (
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            label={t("readFullTerms")}
            onClick={onFullTermsClick}
          />
        )}
      </InfoWrapper>
      <ActionButtonsWrapper>
        <GeneralButton label={t("accept")} onClick={onConfirmTerm} />
        <GeneralButton
          colorVariant={ButtonColor.Red}
          label={t("decline")}
          onClick={onDeclineTerm}
        />
      </ActionButtonsWrapper>
    </TermsModalWrapper>
  );
};
