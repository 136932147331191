import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY_HOVER,
} from "../../colors";
import { CrossIcon, SearchIcon } from "../Icons";
import { StyledTextInput } from "../StyledTextInput";

const SearchBarWrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  margin-bottom: 18px;
  width: calc(100% - 12px);
  height: 45px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : COLOR_GREY_ACTIVE};
  border-radius: 6px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

const SearchIconWrapper = styled.div`
  margin-right: 10px;
`;

const Input = styled(StyledTextInput)`
  width: 100%;
  height: 25px;
  color: ${COLOR_SECONDARY_GREY_HOVER};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
  background-color: transparent;
`;

const CancelButton = styled.button`
  position: absolute;
  border: none;
  background: transparent;
  right: 10px;
  cursor: pointer;
`;

export function SearchBar({
  searchValue,
  placeholder,
  setSearchValue,
  backgroundColor,
}: {
  searchValue: string;
  placeholder: string;
  backgroundColor?: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
}) {
  return (
    <SearchBarWrapper backgroundColor={backgroundColor}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input
        onChangeText={setSearchValue}
        value={searchValue}
        placeholder={placeholder}
        autoFocus
      />
      {!!searchValue && (
        <CancelButton onClick={() => setSearchValue("")}>
          <CrossIcon tintColor={COLOR_MEDIUM_GRAY} />
        </CancelButton>
      )}
    </SearchBarWrapper>
  );
}
