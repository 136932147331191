import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { COLOR_BLUE } from "../../../colors";
import { UserPreferenceKeys } from "../../../constants";
import { useReportsContext } from "../../../contexts/Reports";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplate,
  useAthleteProfileTabsPreferenceQuery,
  useGetTheGroupsImInListQuery,
} from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { AthleteProfileTabsPreference } from "../../../models";
import { parsePreferences } from "../../../utils/preferences";
import { ConfirmProps } from "../../Modals/ConfirmationModal";
import { ConfirmModalText } from "../../styled/ConfirmModalText";

export const useReportWarningModal = ({
  title,
  confirmationQuestion,
  reportTemplate,
  okButtonText,
  cancelButtonText,
  onOK,
  onCancel,
}: {
  title: string;
  confirmationQuestion: string;
  reportTemplate: ReportTemplate;
  okButtonText: string;
  cancelButtonText: string;
  onOK: () => Promise<void>;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [{ selectedGroup }] = useReportsContext();
  const { sessionId, language, isTrainer, isFamilyMember } = useUserContext();
  const modal = useModalContext();
  const accountId = useGetSelectedAccountIdInMonitoring();

  const { data: groupsData } = useGetTheGroupsImInListQuery({
    variables: {
      sessionId,
      requireEditability: true,
      language,
      accountId,
    },
  });

  const { data } = useAthleteProfileTabsPreferenceQuery({
    variables: {
      preferenceKey: UserPreferenceKeys.ATHLETE_PROFILE_TABS,
      sessionId,
      accountId,
      groupIdForAthleteSharing: isTrainer ? undefined : selectedGroup?.id,
    },
    skip: isFamilyMember,
  });

  return useCallback(() => {
    const tabsPreference = parsePreferences<AthleteProfileTabsPreference>(
      data?.tabsPreference,
      {}
    );
    const groupTabs = Object.entries(tabsPreference);

    const groupsWithReport = groupTabs.filter(([, tabs]) => {
      return (typeof tabs === "object" && Array.isArray(tabs) ? tabs : []).some(
        ({ id }) => id === reportTemplate?.id
      );
    });
    const affectedGroups = groupsWithReport
      .map(
        ([groupId]) =>
          groupsData.getTheGroupsImIn.find(({ id }) => id === groupId)?.name
      )
      .filter((groupName) => groupName);

    const params: Omit<ConfirmProps, "closeModal"> = {
      content:
        affectedGroups.length > 0 ? (
          <>
            <ConfirmModalText marginBottom={10}>
              {t("reportWarning")}
            </ConfirmModalText>
            {affectedGroups.map((affectedGroup) => (
              <ConfirmModalText color={COLOR_BLUE} marginBottom={5}>
                {affectedGroup}
              </ConfirmModalText>
            ))}
            <ConfirmModalText marginTop={15}>
              {confirmationQuestion}
            </ConfirmModalText>
          </>
        ) : (
          <ConfirmModalText marginTop={15}>
            {confirmationQuestion}
          </ConfirmModalText>
        ),
      onOK,
      onCancel,
      okButtonText,
      cancelButtonText,
    };

    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title,
      params,
    });

    onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportTemplate,
    title,
    okButtonText,
    cancelButtonText,
    data?.tabsPreference,
    modal.actions,
    onOK,
    onCancel,
    t,
  ]);
};
