import React from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import Avatar from "../Avatar";
import Dropdown from "../Dropdown";
import { ChevronRightIcon } from "../Icons";
import StyledText from "../StyledText";

import { StyledChevronWrapper, StyledWrapper } from "./AccessLevelDropdown";
import { UserProfileOptionsDropdown } from "./UserProfileOptionsDropdown";

export const StyledAvatarWrapper = styled.div<{
  isOpen?: boolean;
  withBorder?: boolean;
}>`
  border: 3px solid;
  border-radius: 50%;
  border-color: ${({ isOpen, withBorder }) =>
    isOpen && withBorder ? COLOR_BLUE : "transparent"};
`;

export function UserDropdown() {
  const user = useUserContext();
  const { firstName, lastName, profileImageUrl } = user;
  const isTrainer = isRealTrainer(user);
  const isFamilyMember = user.isFamilyMember;

  return (
    <Dropdown
      dropdownContainerStyle={{
        top: 35,
        right: 0,
        minWidth: 196,
      }}
      dropdownContentStyle={{
        padding: "10px 0",
      }}
      component={({ toggle, show: isDropdownOpen }) => (
        <StyledWrapper
          onClick={toggle}
          isOpen={isDropdownOpen}
          withBorder={isTrainer || isFamilyMember}
        >
          <StyledAvatarWrapper
            isOpen={isDropdownOpen}
            withBorder={isTrainer || isFamilyMember}
          >
            <Avatar
              userName={`${firstName} ${lastName}`}
              size={35}
              uri={profileImageUrl}
              withOverlay={(isTrainer || isFamilyMember) && isDropdownOpen}
            />
          </StyledAvatarWrapper>
          {!isTrainer && !isFamilyMember && (
            <StyledText fontSize={12} fontWeight="bold">
              {firstName} {lastName}
            </StyledText>
          )}
          {!isTrainer && !isFamilyMember && (
            <StyledChevronWrapper isOpen={isDropdownOpen}>
              <ChevronRightIcon
                direction="bottom"
                tintColor={isDropdownOpen ? COLOR_WHITE : COLOR_BLUE}
              />
            </StyledChevronWrapper>
          )}
        </StyledWrapper>
      )}
    >
      {({ toggle }) => <UserProfileOptionsDropdown toggle={toggle} />}
    </Dropdown>
  );
}
