import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import _omit from "lodash/omit";

import { Header } from "../components/Header";
import { ChartEditor } from "../components/Report/ChartWIdget/ChartEditor";
import {
  ChartWidgetModel,
  ChartWidgetPreferences,
  TimeLinePeriod,
} from "../components/Report/ChartWIdget/model";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { useUserContext } from "../contexts/User";
import {
  ReportWidgetTemplateChart,
  TimeFrame,
  useSaveWidgetTemplateChartMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport } from "../hooks/data/useAddWidgetToReport";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useNavigateToPreviousPage } from "../hooks/useNavigateToPreviousPage";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { parsePreferences } from "../utils/preferences";
import { isRouteParamTrue } from "../utils/route";

export const ChartEditorScreen = () => {
  const { t } = useTranslation();
  const { sessionId, language, firstName, lastName } = useUserContext();
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
    searchParams,
  } = useWidgetEditorUrlParams();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const position = searchParams.get("position");
  const isGroupWidget = isRouteParamTrue(searchParams.get("isGroupWidget"));

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const { data: templateData, loading: loadingTemplate } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const widgetEditData = React.useMemo<ChartWidgetModel | null>(() => {
    if (templateData?.reportWidgetTemplateById) {
      const widget =
        templateData.reportWidgetTemplateById as ReportWidgetTemplateChart;

      const preferences = parsePreferences<ChartWidgetPreferences>(
        "preferences" in templateData.reportWidgetTemplateById
          ? templateData.reportWidgetTemplateById.preferences
          : "",
        {} as ChartWidgetPreferences
      );

      return { ...widget, ...preferences };
    }
    return null;
  }, [templateData]);

  const [saveChart, { loading: savingWidget }] =
    useSaveWidgetTemplateChartMutation();

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const onSubmit = async (values: ChartWidgetModel) => {
    const { id, name, ...rest } = values;

    const preferences: ChartWidgetPreferences = {
      performerSelectionMode: rest.performerSelectionMode,
      athlete: rest.athlete,
      athletes: rest.athletes,
      teams: rest.teams,
      chartType: rest.chartType,
      includeZero: rest.includeZero,
      createdBy: rest.createdBy,
      items: rest.items,
      limits: rest.limits,
      participants: rest.participants,
      period: rest.period,
      series: rest.series,
      showLegend: rest.showLegend,
      timeLine: rest.timeLine,
      timeLinePeriod: rest.timeLinePeriod,
      widgetBorders: rest.widgetBorders,
      type: rest.type,
      updatedAt: rest.updatedAt,
      isGroupWidget: rest.isGroupWidget,
    };

    const response = await saveChart({
      variables: {
        sessionId,
        accountId: selectedAccountId,
        input: {
          id: isEditingWidgetTemplate ? widgetId || null : null,
          reportTemplateId: reportId,
          name:
            name ||
            (isEditingWidgetTemplate ? t("defaultChartWidgetName") : ""),
          preferences: JSON.stringify({
            ..._omit(preferences, "id", "name"),
            type: WidgetType.CHART,
            position,
            participants: [],
            updatedAt: dayjs(),
            createdBy: `${firstName} ${lastName}`,
          }),
          timeFrame: {
            [TimeLinePeriod.DAYS]: TimeFrame.Day,
            [TimeLinePeriod.WEEKS]: TimeFrame.Week,
            [TimeLinePeriod.MONTHS]: TimeFrame.Month,
            [TimeLinePeriod.YEARS]: TimeFrame.Year,
          }[rest.timeLinePeriod],
        },
      },
    });

    if (!isEditingWidgetTemplate && response.data?.saveWidgetTemplateChart) {
      await addWidgetToReport(response.data.saveWidgetTemplateChart.id);
    }

    navigateToPreviousPage();
  };

  if (loadingTemplate) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer>
      <Header />
      <ChartEditor
        widget={widgetEditData}
        isSavingWidget={savingWidget}
        isGroupWidget={isGroupWidget || widgetEditData?.isGroupWidget}
        onSubmit={onSubmit}
        onCancel={navigateToPreviousPage}
      />
    </ScreenContainer>
  );
};
