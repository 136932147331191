import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREEN, COLOR_WHITE } from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { PaymentStatus } from "../../../graphql";
import {
  AttendanceButton,
  StyledAttendanceButton,
} from "../../AttendanceButton";
import Avatar from "../../Avatar";
import { AttendanceStatus } from "../../Modals/Attendance";
import StyledText from "../../StyledText";
import { AgendaSession } from "../model";

type SessionPaymentProps = {
  session: AgendaSession;
  showAthleteName?: boolean;
  refetch: () => Promise<any>;
};

const SessionPaymentWrapper = styled.tr<{
  shouldBeParticipantVisible: boolean;
}>`
  margin-bottom: ${({ shouldBeParticipantVisible }) =>
    shouldBeParticipantVisible ? 5 : 0}px;
`;

const ParticipantWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const ParticipantName = styled.div`
  display: flex;
  flex: 1;
  margin-left: 10px;
`;

export function PaymentSection({
  session,
  showAthleteName = false,
  refetch,
}: SessionPaymentProps) {
  const { t } = useTranslation();
  const user = useUserContext();
  const {
    actions: { openModal },
  } = useModalContext();

  const sessionName = session
    ? session?.label ?? ("name" in session ? (session?.name as string) : "")
    : "";

  const openPaymentModal = (payment) => (event) => {
    event.stopPropagation();
    event.preventDefault();

    openModal({
      title: sessionName,
      modal: ModalType.SESSION_PAYMENT,
      params: { session, payment, refetch },
    });
  };

  return (
    <>
      {session?.payments?.map((payment, index) => {
        const shouldBeParticipantVisible =
          payment.status !== PaymentStatus.Paid &&
          user.id !== payment.participant.id;
        const shouldShowAttendanceButton =
          payment.status === PaymentStatus.WillNotAttend;
        const shouldShowRespondButton =
          payment.status === PaymentStatus.Pending ||
          payment.status === PaymentStatus.Expired;

        return (
          <SessionPaymentWrapper
            shouldBeParticipantVisible={shouldBeParticipantVisible}
            key={`${payment.pricex100}-${index}`}
          >
            {shouldBeParticipantVisible ? (
              <td>
                <ParticipantWrapper>
                  <Avatar
                    userGuid={payment.participant.id}
                    userName={payment.participant.name}
                    isThumb={true}
                    size="small"
                  />
                  {showAthleteName ? (
                    <ParticipantName>
                      <StyledText
                        numberOfLines={1}
                        fontSize={12}
                        fontWeight="bold"
                      >
                        {payment.participant.name}
                      </StyledText>
                    </ParticipantName>
                  ) : null}
                </ParticipantWrapper>
              </td>
            ) : null}
            {shouldShowAttendanceButton ? (
              <td>
                <AttendanceButton
                  onClick={openPaymentModal(payment)}
                  attendanceStatus={AttendanceStatus.OTHER}
                />
              </td>
            ) : null}
            {shouldShowRespondButton ? (
              <td>
                <StyledAttendanceButton
                  color={COLOR_WHITE}
                  hasIcon={false}
                  bgColor={COLOR_GREEN}
                  onClick={openPaymentModal(payment)}
                >
                  <span>{t("respond").toUpperCase()}</span>
                </StyledAttendanceButton>
              </td>
            ) : null}
          </SessionPaymentWrapper>
        );
      })}
    </>
  );
}
