import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AttendanceQuestionMarkIcon = memo(
  ({ width = 13, height = 13, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill="#E6E8EB" />
      <path
        d="M5.47944 7.12997V6.67374C5.47944 6.32714 5.54631 6.03537 5.68004 5.79841C5.81377 5.55791 6.05115 5.32449 6.39218 5.09814C6.66299 4.91777 6.8569 4.75508 6.97392 4.61008C7.09428 4.46508 7.15446 4.29885 7.15446 4.11141C7.15446 3.96286 7.09094 3.84615 6.96389 3.76127C6.83684 3.67286 6.67135 3.62865 6.4674 3.62865C5.96255 3.62865 5.37245 3.81786 4.69709 4.19629L4 2.75332C4.8325 2.25111 5.70177 2 6.60782 2C7.35339 2 7.93848 2.1733 8.36309 2.51989C8.7877 2.86649 9 3.33864 9 3.93634C9 4.36428 8.90471 4.73563 8.71414 5.0504C8.52691 5.36163 8.22601 5.65694 7.81143 5.93634C7.46038 6.17683 7.23972 6.35367 7.14945 6.46684C7.06252 6.57648 7.01906 6.70734 7.01906 6.85942V7.12997H5.47944ZM5.26379 8.98674C5.26379 8.66136 5.35239 8.41026 5.52959 8.23342C5.71013 8.05659 5.97426 7.96817 6.32197 7.96817C6.6563 7.96817 6.91207 8.05836 7.08927 8.23873C7.26981 8.41556 7.36008 8.6649 7.36008 8.98674C7.36008 9.30858 7.26647 9.55791 7.07924 9.73475C6.89535 9.91158 6.64293 10 6.32197 10C5.99097 10 5.73186 9.91335 5.54463 9.74005C5.35741 9.56322 5.26379 9.31211 5.26379 8.98674Z"
        fill="#3C434C"
      />
    </svg>
  )
);
