import React, { memo } from "react";

export const SwitchAccountIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M15.5 10.5V3.5C15.5 2.94772 15.0523 2.5 14.5 2.5H6.5C5.94772 2.5 5.5 2.94772 5.5 3.5V6"
      stroke="#64707A"
    />
    <path d="M13.5 8.5L15.5 10.5L17.5 8.5" stroke="#64707A" />
    <path
      d="M5.5 10.5L5.5 17.5C5.5 18.0523 5.94772 18.5 6.5 18.5H14.5C15.0523 18.5 15.5 18.0523 15.5 17.5V15"
      stroke="#64707A"
    />
    <path d="M7.5 12.5L5.5 10.5L3.5 12.5" stroke="#64707A" />
  </svg>
));
