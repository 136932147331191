import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const LocationIcon = memo(
  ({ width = 21, height = 21, ...props }: SvgIconProps) => (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 16C10.5 16 16 10.8438 16 6.625C16 3.5184 13.5376 1 10.5 1C7.46243 1 5 3.5184 5 6.625C5 10.8438 10.5 16 10.5 16ZM10.5 10C12.433 10 14 8.433 14 6.5C14 4.567 12.433 3 10.5 3C8.567 3 7 4.567 7 6.5C7 8.433 8.567 10 10.5 10Z"
        fill="#0073D9"
      />
      <ellipse cx="10.5" cy="17" rx="3.5" ry="1" fill="#0073D9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.30724 14.3073C3.16321 14.7964 1 15.8184 1 17C1 18.6569 5.25329 20 10.5 20C15.7467 20 20 18.6569 20 17C20 15.8184 17.8368 14.7964 14.6928 14.3073L13.7969 15.2031C16.2862 15.5285 18 16.2108 18 17C18 18.1045 14.6421 19 10.5 19C6.35786 19 3 18.1045 3 17C3 16.2108 4.71382 15.5285 7.20305 15.2031L6.30724 14.3073Z"
        fill="#0073D9"
      />
    </svg>
  )
);
