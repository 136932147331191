import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { useUpdatePracticeAttendanceMutation } from "../../../graphql";
import { BasicButton } from "../../Button";
import { AttendingIcon, AwayIcon, InjuredIcon, SickIcon } from "../../Icons";
import { AttendanceStatus } from "../../Modals/Attendance";
import StyledText from "../../StyledText";

import { Member } from "./model";

type TeamListProps = {
  data: Omit<Member, "name">;
  practice: any;
  sessionGuid: string;
  refetch: () => Promise<any>;
};

const Row = styled.tr`
  height: 45px;
  background-color: ${COLOR_WHITE};
  padding: 10px;
`;

const Item = styled.td<{ paddingRight?: number }>`
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  text-align: left;
  padding-right: ${({ paddingRight = 0 }) => paddingRight}px;
  &:first-child {
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-bottom-right-radius: 12px;
  }
`;

const ItemContent = styled(BasicButton)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-left: 11px;
  color: ${COLOR_TEXT_DARK};
`;

const Text = styled(StyledText)`
  font-size: 12px;
  font-weight: 700;
`;

const TextBlock = styled.div`
  background: ${COLOR_GRAY};
  border-radius: 6px;
  display: flex;
  min-height: 25px;
  width: 100%;
  min-width: 120px;
  align-items: center;
  padding: 5px 8px;
  margin: 5px 0;
`;

const IconBlock = styled.div`
  align-items: center;
  background: ${COLOR_GRAY};
  border-radius: 50px;
  display: flex;
  width: 35px;
  height: 35px;
`;

export function AttendanceItem({
  data,
  practice,
  refetch,
  sessionGuid,
}: TeamListProps) {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { sessionId, timezone } = useUserContext();
  const [updatePracticeAttendanceMutation] =
    useUpdatePracticeAttendanceMutation();

  const onAwayClick = () => {
    modal.actions.openModal({
      modal: ModalType.ATTENDANCE,
      title: t("attendance"),
      style: { width: "15vw", minHeight: "auto" },
      params: {
        sessionGuid: practice.id,
        withoutAttendingStatus: true,
        refetch,
        ...data,
      },
    });
  };

  const onNoteClick = () => {
    modal.actions.openModal({
      modal: ModalType.ATTENDANCE,
      title: t("attendance"),
      style: { width: "15vw", minHeight: "auto" },
      params: {
        sessionGuid: practice.id,
        withoutAttendingStatus: true,
        stage: 2,
        refetch,
        ...data,
      },
    });
  };

  const onAttendingClick = async () => {
    await updatePracticeAttendanceMutation({
      variables: {
        sessionId,
        timezone,
        input: {
          practiceGuid: sessionGuid,
          athleteGuid: data.athleteGuid,
          attendance:
            data.attendance === AttendanceStatus.ATTENDED
              ? AttendanceStatus.UNKNOWN
              : AttendanceStatus.ATTENDED,
        },
      },
    });
    await refetch();
  };

  return (
    <Row>
      <Item>
        <ItemWrapper>
          <Label>{data.athleteName}</Label>
        </ItemWrapper>
      </Item>
      <Item>
        <ItemContent onClick={onAttendingClick}>
          <IconBlock>
            {data.attendance === AttendanceStatus.ATTENDED && (
              <AttendingIcon isActive />
            )}
          </IconBlock>
        </ItemContent>
      </Item>
      <Item paddingRight={20}>
        <ItemContent onClick={onAwayClick}>
          <IconBlock>
            {data.attendance === AttendanceStatus.SICK && <SickIcon isActive />}
            {data.attendance === AttendanceStatus.INJURED && (
              <InjuredIcon isActive />
            )}
            {data.attendance === AttendanceStatus.OTHER && (
              <AwayIcon isActive />
            )}
          </IconBlock>
        </ItemContent>
      </Item>
      <Item paddingRight={10}>
        <ItemContent onClick={onNoteClick}>
          <TextBlock>
            <Text>{data.comment}</Text>
          </TextBlock>
        </ItemContent>
      </Item>
      <Item paddingRight={10}>
        <ItemWrapper>
          <TextBlock>
            <Text>{data.athleteComment}</Text>
          </TextBlock>
        </ItemWrapper>
      </Item>
    </Row>
  );
}
