import React, { CSSProperties, useState } from "react";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DEFAULT, COLOR_WHITE } from "../../../colors";
import { Checkbox } from "../../Checkbox";
import { ItemContainer } from "../../HoverableItem";
import StyledText from "../../StyledText";

const ItemWrapper = styled.div`
  background-color: ${COLOR_WHITE};

  &:hover {
    background-color: ${COLOR_GRAY};
  }
  overflow: auto;
`;

export const HoverableItemWithCheckbox = ({
  text,
  onClick,
  isChecked,
  initialLoading = false,
  level = 1,
  showCheckbox = true,
  textStyle,
}: {
  text: string;
  isChecked: boolean;
  initialLoading?: boolean;
  level?: number;
  showCheckbox?: boolean;
  onClick: (checked: boolean) => Promise<void>;
  textStyle?: CSSProperties;
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const onCheckClick = async (newValue: boolean) => {
    setIsSaving(true);

    if (!isSaving) {
      await onClick(newValue);
    }

    setIsSaving(false);
  };

  return (
    <ItemWrapper>
      <ItemContainer level={level}>
        {showCheckbox ? (
          <Checkbox
            loading={initialLoading || isSaving}
            text={text}
            check={onCheckClick}
            checked={isChecked}
            withMargin={false}
            rightOrientation
            textStyle={textStyle}
          />
        ) : (
          <StyledText
            numberOfLines={1}
            style={{
              color: COLOR_TEXT_DEFAULT,
              fontSize: 12,
              fontWeight: "bold",
            }}
          >
            {text}
          </StyledText>
        )}
      </ItemContainer>
    </ItemWrapper>
  );
};
