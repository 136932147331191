import React, { CSSProperties } from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DEFAULT, COLOR_WHITE } from "../colors";

import Avatar from "./Avatar";
import { SmallGroupProfileIcon } from "./Icons";
import StyledText from "./StyledText";

type CheckboxLabelProps = {
  disabled?: boolean;
  isFocused?: boolean;
  onTextClick?: () => void;
  rightOrientation?: boolean;
  showGroupIcon?: boolean;
  text: string;
  textStyle?: CSSProperties;
  thumb?: string;
};

const IconWrapper = styled.div<{
  thumb?: string;
  rightOrientation?: boolean;
}>`
  height: ${({ thumb }) => (thumb ? 25 : 15)}px;
  width: ${({ thumb }) => (thumb ? 25 : 15)}px;
  border-radius: ${({ thumb }) => thumb && 50}px;
  margin-right: ${({ rightOrientation }) => (rightOrientation ? 0 : 5)}px;
  margin-left: ${({ rightOrientation }) => (rightOrientation ? 10 : 0)}px;
`;

const StyledAvatar = styled(Avatar)<{ rightOrientation: boolean }>`
  margin-right: ${({ rightOrientation }) => (rightOrientation ? 0 : 10)}px;
  margin-left: ${({ rightOrientation }) => (rightOrientation ? 10 : 0)}px;
`;

export function CheckboxLabel({
  disabled,
  isFocused,
  onTextClick,
  rightOrientation = false,
  showGroupIcon,
  text,
  textStyle,
  thumb,
}: CheckboxLabelProps) {
  return (
    <>
      {showGroupIcon && (
        <IconWrapper thumb={thumb as any} rightOrientation={rightOrientation}>
          <SmallGroupProfileIcon />
        </IconWrapper>
      )}
      {thumb && (
        <StyledAvatar
          size={25}
          rightOrientation={rightOrientation}
          source={{ uri: thumb as string }}
          // no need for first/lastName - thumb defined
          userName={text}
        />
      )}
      <StyledText
        numberOfLines={1}
        fontSize={12}
        fontWeight={"bold"}
        color={isFocused ? COLOR_WHITE : COLOR_TEXT_DEFAULT}
        style={{
          backgroundColor: isFocused ? COLOR_BLUE : "transparent",
          width: showGroupIcon ? "70%" : "100%",
          paddingBlock: onTextClick ? 5 : 0,
          paddingInline: onTextClick ? 3 : 0,
          borderRadius: 5,
          opacity: disabled ? 0.35 : 1,
          ...(rightOrientation ? { flex: 1 } : {}),
          ...textStyle,
        }}
        onClick={onTextClick}
      >
        {text}
      </StyledText>
    </>
  );
}
