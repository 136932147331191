import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../colors";
import { useUIContext } from "../contexts/UI";

import { Loader } from "./Loader";

const Loading = () => {
  const { loading } = useUIContext();

  if (!loading) {
    return null;
  }

  return (
    <Backdrop>
      <Container>
        <Loader size="large" color={COLOR_TEXT_DEFAULT} />
      </Container>
    </Backdrop>
  );
};

const Backdrop = styled.div`
  flex: 0;
  z-index: 9999;
  align-items: center;
  position: absolute;
  top: 0;
  left: 300px;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: #00000033;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loading;
