import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import _debounce from "lodash/debounce";

type DebounceHandlerProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  debounce?: number;
};

export const useDebounceHandler = ({
  value,
  setValue,
  debounce = 250,
}: DebounceHandlerProps) => {
  const [inputValue, setInputValue] = useState(value);

  const setDebouncedValue = React.useMemo(
    () =>
      _debounce((debouncedValue: string) => {
        setValue(debouncedValue);
      }, debounce),
    [debounce, setValue]
  );

  useEffect(() => {
    setDebouncedValue(inputValue);
  }, [setDebouncedValue, inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return { inputValue, setInputValue };
};
