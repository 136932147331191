import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { styled as muistyled, Input } from "@mui/material";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
} from "../../../colors";
import {
  useSettingsContext,
  TimeFormat,
} from "../../../contexts/settingsContext";

type TimeInputProps = {
  onTimeChange?: ({
    hours,
    minutes,
    timeFormat,
  }: {
    hours: number;
    minutes: number;
    timeFormat?: TimeFormat;
  }) => void;
  hoursValue?: number;
  minutesValue?: number;
};

export function TimeInput({
  onTimeChange,
  hoursValue = 0,
  minutesValue = 0,
}: TimeInputProps) {
  const [minutes, setMinutes] = useState(minutesValue);
  const [hours, setHours] = useState(hoursValue);
  const [minutesStringified, setMinutesStringified] = useState("00");
  const [hoursStringified, setHoursStringified] = useState("00");

  const { timeFormat } = useSettingsContext();

  function getSanitizedValueOverride(
    value: number,
    variant: "hours" | "minutes"
  ): number {
    if (
      variant === "hours" &&
      timeFormat === TimeFormat.TwentyFourHour &&
      value > 23
    ) {
      return 23;
    }
    if (
      variant === "hours" &&
      timeFormat === TimeFormat.TwelveHour &&
      value > 11
    ) {
      return 11;
    }
    if (variant === "minutes" && value > 59) {
      return 59;
    }
    return value;
  }
  function handleHours(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e?.currentTarget?.value, 10) || 0;
    setHours(newValue);
    setHoursStringified(
      newValue?.toString()?.length === 1 ? `0${newValue}` : `${newValue}`
    );
    const sanitizedValue = getSanitizedValueOverride(newValue, "hours");
    onTimeChange?.({ hours: sanitizedValue, minutes, timeFormat });
  }
  function handleMinutes(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e?.currentTarget?.value, 10) || 0;
    setMinutes(newValue);
    setMinutesStringified(
      newValue?.toString()?.length === 1 ? `0${newValue}` : `${newValue}`
    );
    const sanitizedValue = getSanitizedValueOverride(newValue, "minutes");
    onTimeChange?.({ hours, minutes: sanitizedValue, timeFormat });
  }
  function handleHoursBlur() {
    const sanitizedHours = getSanitizedValueOverride(hours, "hours");
    setHours(sanitizedHours);
    setHoursStringified(
      sanitizedHours?.toString()?.length === 1
        ? `0${sanitizedHours}`
        : `${sanitizedHours}`
    );
  }
  function handleMinutesBlur() {
    const sanitizedMinutes = getSanitizedValueOverride(minutes, "minutes");
    setMinutes(sanitizedMinutes);
    setMinutesStringified(
      sanitizedMinutes?.toString()?.length === 1
        ? `0${sanitizedMinutes}`
        : `${sanitizedMinutes}`
    );
  }

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (timeFormat === TimeFormat.TwentyFourHour) {
      setHours(23);
      setHoursStringified("23");
    } else if (timeFormat === TimeFormat.TwelveHour) {
      setHours(11);
      setHoursStringified("11");
    }
    setMinutes(59);
    setMinutesStringified("59");
  }, [timeFormat]);

  useEffect(() => {
    handleHoursBlur();
    handleMinutesBlur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursValue, minutesValue]);

  return (
    <StyledWrapper>
      <StyledInput
        disableUnderline
        type="number"
        onChange={handleHours}
        onBlur={handleHoursBlur}
        value={hoursStringified}
      />
      <StyledDivider>:</StyledDivider>
      <StyledInput
        disableUnderline
        type="number"
        onChange={handleMinutes}
        onBlur={handleMinutesBlur}
        value={minutesStringified}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
const StyledDivider = styled.strong`
  display: flex;
  align-self: center;
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  font-weight: 700;
`;
const StyledInput = muistyled(Input)(() => ({
  //   padding: "0 9px",
  border: `1px solid ${COLOR_GREY_ACTIVE}`,
  width: "85px",
  height: "55px",
  borderRadius: "6px",
  backgroundColor: COLOR_GRAY,
  "& .MuiInputBase-input": {
    color: COLOR_TEXT_DARK,
    fontSize: "25px",
    fontWeight: 700,
    lineHeight: "30px",
    textAlign: "center",
  },
  "input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "textfield",
    margin: 0,
  },
  "input::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "textfield",
    margin: 0,
  },
}));
