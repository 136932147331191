import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const PulseHeardIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.56696 10C1.88221 10.6777 2.34608 11.3461 3 12L10.5 19L18 12C19.5 10.5 20 8.92404 20 7.33333C20 4.38781 17.5695 2 14.5714 2C12.9501 2 11.4947 2.69834 10.5 3.80557C9.50529 2.69834 8.04994 2 6.42857 2C3.43045 2 1 4.38781 1 7.33333C1 7.89159 1.06158 8.44802 1.20636 9H5.19098L6.55279 6.27639L7 5.38197L7.44721 6.27639L10 11.382L11.0528 9.27639L11.191 9H11.5H16V10H11.809L10.4472 12.7236L10 13.618L9.55279 12.7236L7 7.61803L5.94721 9.72361L5.80902 10H5.5H1.56696Z"
          fill="white"
        />
      ) : (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.56696 9.99987C1.41286 9.66858 1.29427 9.33505 1.20636 8.99987H5.19098L6.55279 6.27626L7 5.38184L7.44722 6.27626L10 11.3818L11.0528 9.27626L11.191 8.99987H11.5H16V9.99987H11.809L10.4472 12.7235L10 13.6179L9.55279 12.7235L7 7.6179L5.94722 9.72348L5.80902 9.99987H5.5H1.56696Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.42858 2C7.68688 2 8.84519 2.4206 9.7658 3.12652C10.0314 3.3302 10.2773 3.55763 10.5 3.80557C10.7227 3.55763 10.9686 3.3302 11.2342 3.12652C12.1548 2.4206 13.3131 2 14.5714 2C17.5696 2 20 4.38781 20 7.33333C20 8.92404 19.5 10.5 18 12L10.5 19L3.00001 12C2.34609 11.3461 1.88222 10.6777 1.56697 10H2.69421C2.94387 10.4289 3.27188 10.8558 3.6953 11.2811L10.5 17.6321L17.3047 11.2811C18.596 9.98431 19 8.672 19 7.33333C19 4.95671 17.034 3 14.5714 3C13.2425 3 12.0548 3.57125 11.2439 4.47387L10.5 5.30191L9.75611 4.47387C8.94522 3.57125 7.75752 3 6.42858 3C4.08214 3 2.18657 4.77648 2.01297 7H1.01044C1.18558 4.20976 3.54441 2 6.42858 2Z"
            fill="white"
          />
        </>
      )}
    </svg>
  )
);
