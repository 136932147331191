import React from "react";

import styled from "styled-components";

import { useAgendaWithRegistration } from "../../hooks/useAgendaWithRegistration";

import { AgendaActionBlock } from "./components/AgendaActionBlock";
import { groupBy } from "./utils/groupBy";

const ActionWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  flex-direction: column;
`;

export function AgendaAction() {
  const {
    agendaWithRegistrationRefetch,
    agendaListNeedAttention,
    agendaListNotNeedAttention,
  } = useAgendaWithRegistration();

  const hasNeedAttentionItems = agendaListNeedAttention?.length;
  const hasNotNeedAttentionItems = agendaListNotNeedAttention?.length;

  const hasAttentionItemsToShow =
    hasNeedAttentionItems || hasNotNeedAttentionItems;
  const hasBothAttentionTypeItems =
    hasNeedAttentionItems && hasNotNeedAttentionItems;

  if (!hasAttentionItemsToShow) {
    return null;
  }

  const getMonthsWithDays = (agendaList) => {
    const gropedByMonth = groupBy(agendaList, "MMMM");

    return Object.keys(gropedByMonth).map((month) => {
      const groupedByDays = groupBy(gropedByMonth[month], "YYYY-MM-DD");

      return { month, days: groupedByDays };
    });
  };

  const monthsWithDaysNeedAttention = getMonthsWithDays(
    agendaListNeedAttention
  );

  const monthsWithDaysNotNeedAttention = getMonthsWithDays(
    agendaListNotNeedAttention
  );

  return (
    <ActionWrapper>
      <AgendaActionBlock
        agendaWithRegistrationRefetch={agendaWithRegistrationRefetch}
        monthsWithDays={monthsWithDaysNeedAttention}
      />
      <AgendaActionBlock
        withAnswerLabel={!!hasBothAttentionTypeItems}
        agendaWithRegistrationRefetch={agendaWithRegistrationRefetch}
        monthsWithDays={monthsWithDaysNotNeedAttention}
      />
    </ActionWrapper>
  );
}
