import React, { useEffect } from "react";
import { isMobile, isAndroid, isIOS, isTablet } from "react-device-detect";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  RouterProvider,
  createRoutesFromElements,
  Route,
  createBrowserRouter,
  Outlet,
  useLocation,
  matchPath,
  useNavigate,
} from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { registerRootComponent } from "expo";
import styled, { createGlobalStyle } from "styled-components";

import appJson from "./app.json";
import { COLOR_GRAY } from "./js/colors";
import { ErrorBoundary } from "./js/components/ErrorBoundary";
import { Loader, LoaderWrapper } from "./js/components/Loader";
import Loading from "./js/components/Loading";
import Modal from "./js/components/Modals/Modal";
import { ModalErrorComponent } from "./js/components/Modals/ModalErrorComponent";
import TopModal from "./js/components/Modals/TopModal";
import { TopModalErrorComponent } from "./js/components/Modals/TopModalErrorComponent";
import { NavSide } from "./js/components/NavSide/";
import Context from "./js/contexts";
import { useAccessLevelsModuleOverrideReset } from "./js/contexts/accessLevels/useAccessLevelsModuleOverrideReset";
import { AgendaCalendarContextProvider } from "./js/contexts/AgendaCalendarContextProvider";
import { MobileModalProvider } from "./js/contexts/mobileModal/MobileModalProvider";
import { MonitoringReportsContextProvider } from "./js/contexts/MonitoringReportsContextProvider";
import { TeamContextProvider } from "./js/contexts/teams";
import { useUserContext } from "./js/contexts/User";
import { client } from "./js/graphql";
import { useCheckTermsAndInvitations } from "./js/hooks/useCheckTermsAndInvitations";
import { useMessagingWebSocket } from "./js/hooks/useMessagingWebSocket";
import {
  MONITORING_MODULE_PREFIXES,
  ROUTING_CONFIG,
} from "./js/router/RoutingConfig";
import { TrainerProtectedRoute } from "./js/router/TrainerProtectedRoute";
import { ActivityLogWidgetEditorScreen } from "./js/screens/ActivityLogWidgetEditorScreen";
import { AcuteChronicWidgetEditorScreen } from "./js/screens/AcuteChronicWidgetEditorScreen";
import { AgendaScreen } from "./js/screens/AgendaScreen";
import { AthleteCollectionsScreen } from "./js/screens/AthleteCollectionsScreen";
import { AthleteProfileScreen } from "./js/screens/AthleteProfileScreen";
import { AthleteReportsScreen } from "./js/screens/AthleteReportsScreen";
import { AthleteScreen } from "./js/screens/AthleteScreen";
import { AthleteTableEditor } from "./js/screens/AthleteTableEditor";
import { AthleteTablePeriodizationEditorScreen } from "./js/screens/AthleteTablePeriodizationEditorScreen";
import { AthleteTeamScreen } from "./js/screens/AthleteTeamScreen";
import { AttendanceLogWidgetEditorScreen } from "./js/screens/AttendanceLogWidgetEditorScreen";
import { BodyChartEditorScreen } from "./js/screens/BodyChartEditorScreen";
import { CalendarScreen } from "./js/screens/CalendarScreen";
import { ChartEditorScreen } from "./js/screens/ChartEditorScreen";
import CollectionsScreen from "./js/screens/Collections";
import { CombinedTestEditorScreen } from "./js/screens/CombinedTestEditorScreen";
import { DownloadMobileAppScreen } from "./js/screens/DownloadMobileAppScreen";
import { ElectronicIDAuthPage } from "./js/screens/ElectronicIDAuthPage";
import { EmbedScreen } from "./js/screens/EmbedScreen";
import { ForgotPasswordPage } from "./js/screens/ForgotPasswordPage";
import { GroupReportScreen } from "./js/screens/GroupReportScreen";
import GroupTableEditor from "./js/screens/GroupTableEditor";
import { GroupTablePeriodizationEditorScreen } from "./js/screens/GroupTablePeriodizationEditorScreen";
import { InformationWidgetEditor } from "./js/screens/InformationWidgetEditor";
import { KeyValuesEditorScreen } from "./js/screens/KeyValuesEditorScreen";
import { ListReportsScreen } from "./js/screens/ListReportsScreen";
import LoginScreen from "./js/screens/LoginScreen";
import { MessagesScreen } from "./js/screens/MessagesScreen";
import { MyXPSScreen } from "./js/screens/MyXPSScreen";
import { NotFoundScreen } from "./js/screens/NotFoundScreen";
import { ReportScreen } from "./js/screens/ReportScreen";
import { ResetPasswordPage } from "./js/screens/ResetPasswordPage";
import SessionScreen from "./js/screens/SessionScreen";
import { TeamScreen } from "./js/screens/TeamScreen";
import { TrainingStatusScreen } from "./js/screens/TrainingStatusScreen";
import { WorkoutEditorScreen } from "./js/screens/WorkoutEditorScreen";
import { ExtendedStyledComponentThemeProvider } from "./js/theming/ExtendedStyledComponentThemeProvider";
import { isAcesNationEnv } from "./js/utils/isAcesNationEnv";
import { isTvEnv } from "./js/utils/isTvEnv";
import { setAcesMetadata } from "./js/utils/setAcesMetadata";
import Storage from "./js/utils/storage";
import { StorageKeys } from "./js/utils/storageKeys";

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(isoWeek);

const { search } = window.location;

const params = new URLSearchParams(search);
const isTest = params.get("isTest");
const isTv = params.get("isTv");
const acesNation = params.get("acesnation");

if (isTest === "true" || isTest === "1") {
  Storage.setItemSession(StorageKeys.isTestEnv, "true");
}

if (isTv === "true") {
  Storage.setItemSession(StorageKeys.isTv, "true");
}

if (acesNation === "true" || acesNation === "1") {
  Storage.setItemSession(StorageKeys.acesNation, "true");
}

const GlobalStyle = createGlobalStyle`
  @media print {
    @page {
      size: 210mm 297mm;
    }

    .react-grid-item-break {
      position: unset !important;
      transform: unset !important;
      margin: 10px;
      display: block;
    }

    .react-grid-layout {
      height: unset !important;
      display: table;
    }
  }
`;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MainWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: ${COLOR_GRAY};
`;

const NavWrapper = styled.div`
  display: flex;
  z-index: 2;
`;

const ROUTES_WITHOUT_NAVSIDE = [
  ROUTING_CONFIG.embed,
  ROUTING_CONFIG.login,
  ROUTING_CONFIG.reset,
  ROUTING_CONFIG.forgot,
  ROUTING_CONFIG.electronicIDAuth,
];

const NOT_SECURED_ROUTES = [
  ROUTING_CONFIG.embed,
  ROUTING_CONFIG.electronicIDAuth,
];

const ROUTES_WITH_CALENDAR_CONTEXT = [
  ROUTING_CONFIG.myXPS,
  ROUTING_CONFIG.calendar,
  ROUTING_CONFIG.session,
  ROUTING_CONFIG.workoutEditor,
  ROUTING_CONFIG.ResourceCalendar,
  ROUTING_CONFIG.Athletes,
  ROUTING_CONFIG.teamsAthlete,
];

const getShouldHaveMonitoringContext = (currentPathname: string) =>
  MONITORING_MODULE_PREFIXES.some((monitoringPrefix) =>
    currentPathname.startsWith(monitoringPrefix)
  );

const getShouldHaveCalendarContext = (currentPathname: string) =>
  ROUTES_WITH_CALENDAR_CONTEXT.some((route) =>
    matchPath(route, currentPathname)
  );

const AuthorizedApp = () => {
  useMessagingWebSocket();

  return null;
};

const CheckAuth = () => {
  const user = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const isRouteSecure = !NOT_SECURED_ROUTES.some((notSecured) =>
    matchPath(notSecured, location.pathname)
  );

  useCheckTermsAndInvitations({
    useAsEffectCheck: true,
    sessionId: user.sessionId!,
  });

  useAccessLevelsModuleOverrideReset();

  useEffect(() => {
    if (
      !user.loading &&
      !user.id &&
      location.pathname.includes("forgot") &&
      isRouteSecure
    ) {
      return navigate(ROUTING_CONFIG.forgot);
    }

    if (
      !user.loading &&
      !user.id &&
      !location.pathname.includes("login") &&
      !location.pathname.includes("forgot") &&
      !location.pathname.includes("reset") &&
      isRouteSecure
    ) {
      return navigate(ROUTING_CONFIG.login);
    }

    if (
      (location.pathname.includes("login") ||
        location.pathname.match(/^\/$/)) &&
      user.id &&
      isTvEnv()
    ) {
      return navigate(`${ROUTING_CONFIG.ResourceCalendar}?isTv=true`);
    }

    if (
      (location.pathname.includes("login") ||
        location.pathname.includes("electronicIDAuth") ||
        location.pathname.match(/^\/$/)) &&
      user.id
    ) {
      const homeRoute = isAcesNationEnv()
        ? ROUTING_CONFIG.calendar
        : ROUTING_CONFIG.myXPS;

      return navigate(homeRoute);
    }
  }, [
    isRouteSecure,
    location.pathname,
    location.search,
    navigate,
    user.id,
    user.loading,
  ]);

  if (
    (!user.sessionId || user.loading) &&
    !location.pathname.includes("login") &&
    !location.pathname.includes("reset") &&
    !location.pathname.includes("forgot") &&
    isRouteSecure
  ) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    );
  }

  const shouldHideNavSide = ROUTES_WITHOUT_NAVSIDE.some((route) =>
    matchPath(route, location.pathname)
  );

  const shouldRenderMonitoringReportsContext = getShouldHaveMonitoringContext(
    location.pathname
  );

  const shouldRenderCalendarContext = getShouldHaveCalendarContext(
    location.pathname
  );

  const MonitoringReportsContextProviderWrapper =
    shouldRenderMonitoringReportsContext
      ? MonitoringReportsContextProvider
      : React.Fragment;

  const AgendaCalendarContextProviderWrapper = shouldRenderCalendarContext
    ? AgendaCalendarContextProvider
    : React.Fragment;

  // Keep in mind that switching between pages with agenda context and monitoring context unmounts contexts
  // and causes call of all useEffect on re-mount
  return (
    <MainWrapper>
      <NavWrapper>{!shouldHideNavSide && <NavSide />}</NavWrapper>
      <MonitoringReportsContextProviderWrapper>
        <AgendaCalendarContextProviderWrapper>
          <DndProvider backend={HTML5Backend}>
            <MobileModalProvider>
              {user.sessionId ? <AuthorizedApp /> : null}
              <ErrorBoundary ErrorComponent={<ModalErrorComponent />}>
                <Modal />
              </ErrorBoundary>
              <ErrorBoundary ErrorComponent={<TopModalErrorComponent />}>
                <TopModal />
              </ErrorBoundary>
              <Outlet />
            </MobileModalProvider>
          </DndProvider>
        </AgendaCalendarContextProviderWrapper>
      </MonitoringReportsContextProviderWrapper>
    </MainWrapper>
  );
};

// We need to persist team context state between navigation to and from athlete screen in team module
const TeamsProvider = () => (
  <TeamContextProvider>
    <Outlet />
  </TeamContextProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<CheckAuth />}>
      <Route path={ROUTING_CONFIG.login} element={<LoginScreen />} />
      <Route path={ROUTING_CONFIG.forgot} element={<ForgotPasswordPage />} />
      <Route path={ROUTING_CONFIG.reset} element={<ResetPasswordPage />} />
      <Route path={ROUTING_CONFIG.embed} element={<EmbedScreen />} />

      {!isAcesNationEnv() && (
        <Route path={ROUTING_CONFIG.myXPS} element={<MyXPSScreen />} />
      )}
      <Route path={ROUTING_CONFIG.calendar} element={<AgendaScreen />} />
      <Route path={ROUTING_CONFIG.questionnaire} element={<NotFoundScreen />} />
      <Route path={ROUTING_CONFIG.teams} element={<TeamsProvider />}>
        <Route path="" element={<TeamScreen />} />
        <Route path="athlete" element={<AthleteScreen />} />
      </Route>
      <Route
        path={ROUTING_CONFIG.trainingStatus}
        element={
          <TrainerProtectedRoute redirectTo={"/" + ROUTING_CONFIG.calendar}>
            <TrainingStatusScreen />
          </TrainerProtectedRoute>
        }
      />
      <Route
        path={ROUTING_CONFIG.ResourceCalendar}
        element={<CalendarScreen />}
      />
      <Route
        path={ROUTING_CONFIG.collections}
        element={<CollectionsScreen />}
      />
      <Route path={ROUTING_CONFIG.messages} element={<MessagesScreen />} />
      <Route
        path={ROUTING_CONFIG.electronicIDAuth}
        element={<ElectronicIDAuthPage />}
      />
      <Route path={ROUTING_CONFIG.session} element={<SessionScreen />} />
      <Route path={ROUTING_CONFIG.Athletes} element={<AthleteScreen />} />
      <Route
        path={ROUTING_CONFIG.AthleteCollections}
        element={<AthleteCollectionsScreen />}
      />
      <Route
        path={ROUTING_CONFIG.AthleteReports}
        element={<AthleteReportsScreen />}
      />

      <Route
        path={ROUTING_CONFIG.AthleteTeam}
        element={<AthleteTeamScreen />}
      />

      <Route
        path={ROUTING_CONFIG.AthleteProfile}
        element={<AthleteProfileScreen />}
      />

      <Route
        path={ROUTING_CONFIG.ListReports}
        element={<ListReportsScreen />}
      />
      <Route path={ROUTING_CONFIG.Report} element={<ReportScreen />} />
      <Route
        path={ROUTING_CONFIG.GroupReport}
        element={<GroupReportScreen />}
      />
      <Route
        path={ROUTING_CONFIG.workoutEditor}
        element={<WorkoutEditorScreen />}
      />

      <Route path={ROUTING_CONFIG.ActivityLogEditor}>
        <Route index element={<ActivityLogWidgetEditorScreen />} />
        <Route path=":widgetId" element={<ActivityLogWidgetEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.ActivityLogWidgetEditor}>
        <Route index element={<ActivityLogWidgetEditorScreen />} />
        <Route path=":widgetId" element={<ActivityLogWidgetEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.AcuteChronicEditor}>
        <Route index element={<AcuteChronicWidgetEditorScreen />} />
        <Route path=":widgetId" element={<AcuteChronicWidgetEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.AcuteChronicWidgetEditor}>
        <Route index element={<AcuteChronicWidgetEditorScreen />} />
        <Route path=":widgetId" element={<AcuteChronicWidgetEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.AthleteTableEditor}>
        <Route index element={<AthleteTableEditor />} />
        <Route path=":widgetId" element={<AthleteTableEditor />} />
      </Route>
      <Route path={ROUTING_CONFIG.AthleteTableWidgetEditor}>
        <Route index element={<AthleteTableEditor />} />
        <Route path=":widgetId" element={<AthleteTableEditor />} />
      </Route>

      <Route path={ROUTING_CONFIG.AttendanceLogEditor}>
        <Route index element={<AttendanceLogWidgetEditorScreen />} />
        <Route path=":widgetId" element={<AttendanceLogWidgetEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.AttendanceLogWidgetEditor}>
        <Route index element={<AttendanceLogWidgetEditorScreen />} />
        <Route path=":widgetId" element={<AttendanceLogWidgetEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.ChartEditor}>
        <Route index element={<ChartEditorScreen />} />
        <Route path=":widgetId" element={<ChartEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.ChartWidgetEditor}>
        <Route index element={<ChartEditorScreen />} />
        <Route path=":widgetId" element={<ChartEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.CombinedTestEditor}>
        <Route index element={<CombinedTestEditorScreen />} />
        <Route path=":widgetId" element={<CombinedTestEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.CombinedTestWidgetEditor}>
        <Route index element={<CombinedTestEditorScreen />} />
        <Route path=":widgetId" element={<CombinedTestEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.GroupTableEditor}>
        <Route index element={<GroupTableEditor />} />
        <Route path=":widgetId" element={<GroupTableEditor />} />
      </Route>
      <Route path={ROUTING_CONFIG.GroupTableWidgetEditor}>
        <Route index element={<GroupTableEditor />} />
        <Route path=":widgetId" element={<GroupTableEditor />} />
      </Route>

      <Route path={ROUTING_CONFIG.InformationLogEditor}>
        <Route index element={<InformationWidgetEditor />} />
        <Route path=":widgetId" element={<InformationWidgetEditor />} />
      </Route>
      <Route path={ROUTING_CONFIG.InformationLogWidgetEditor}>
        <Route index element={<InformationWidgetEditor />} />
        <Route path=":widgetId" element={<InformationWidgetEditor />} />
      </Route>

      <Route path={ROUTING_CONFIG.KeyValuesEditor}>
        <Route index element={<KeyValuesEditorScreen />} />
        <Route path=":widgetId" element={<KeyValuesEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.KeyValuesWidgetEditor}>
        <Route index element={<KeyValuesEditorScreen />} />
        <Route path=":widgetId" element={<KeyValuesEditorScreen />} />
      </Route>

      <Route path={ROUTING_CONFIG.AthleteTablePeriodizationEditorScreen}>
        <Route index element={<AthleteTablePeriodizationEditorScreen />} />
        <Route
          path=":widgetId"
          element={<AthleteTablePeriodizationEditorScreen />}
        />
      </Route>
      <Route path={ROUTING_CONFIG.AthleteTablePeriodizationWidgetEditor}>
        <Route index element={<AthleteTablePeriodizationEditorScreen />} />
        <Route
          path=":widgetId"
          element={<AthleteTablePeriodizationEditorScreen />}
        />
      </Route>

      <Route path={ROUTING_CONFIG.GroupTablePeriodizationEditorScreen}>
        <Route index element={<GroupTablePeriodizationEditorScreen />} />
        <Route
          path=":widgetId"
          element={<GroupTablePeriodizationEditorScreen />}
        />
      </Route>
      <Route path={ROUTING_CONFIG.GroupTablePeriodizationWidgetEditor}>
        <Route index element={<GroupTablePeriodizationEditorScreen />} />
        <Route
          path=":widgetId"
          element={<GroupTablePeriodizationEditorScreen />}
        />
      </Route>

      <Route path={ROUTING_CONFIG.BodyChartEditor}>
        <Route index element={<BodyChartEditorScreen />} />
        <Route path=":widgetId" element={<BodyChartEditorScreen />} />
      </Route>
      <Route path={ROUTING_CONFIG.BodyChartWidgetEditor}>
        <Route index element={<BodyChartEditorScreen />} />
        <Route path=":widgetId" element={<BodyChartEditorScreen />} />
      </Route>
    </Route>
  )
);

const queryClient = new QueryClient();

export default function App() {
  console.log("SERVED EXPO WEB VERSION" + appJson?.expo?.extra?.version);

  if (
    isMobile &&
    !isTablet &&
    (isAndroid || isIOS) &&
    !window.location.pathname.includes("embed") &&
    !window.location.pathname.includes("reset") &&
    !window.location.pathname.includes("forgot")
  ) {
    return <DownloadMobileAppScreen />;
  }

  if (isAcesNationEnv()) {
    setAcesMetadata();
  }

  return (
    <AppWrapper id="uniqueRootView">
      <GlobalStyle />
      <ApolloProvider client={client}>
        <QueryClientProvider client={queryClient}>
          <ExtendedStyledComponentThemeProvider>
            <Context>
              <>
                <Loading />
                <RouterProvider router={router} />
              </>
            </Context>
          </ExtendedStyledComponentThemeProvider>
        </QueryClientProvider>
      </ApolloProvider>
    </AppWrapper>
  );
}

registerRootComponent(App);
