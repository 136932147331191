import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";

export const DraggableLineWrapper = styled.div<{ withBottomBorder?: boolean }>`
  display: flex;
  padding: 0 0 10px;
  background-color: #fff;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: ${({ withBottomBorder }) =>
    withBottomBorder ? "1px" : ""};
  border-bottom-color: ${({ withBottomBorder }) =>
    withBottomBorder ? COLOR_GREY_ACTIVE : ""};
  border-bottom-style: ${({ withBottomBorder }) =>
    withBottomBorder ? "solid" : ""};
  margin-bottom: ${({ withBottomBorder }) => (withBottomBorder ? 10 : 0)}px;
`;
