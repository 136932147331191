import { useMemo } from "react";

import { useReportsContext } from "../Reports";

import { useAccessLevelsContext } from ".";

export const useCanEditStatistics = () => {
  const [{ selectedGroup }] = useReportsContext();
  const { accessLevels, selectedAccountStatisticsAccess } =
    useAccessLevelsContext();

  const canEdit = useMemo(() => {
    const accessLevelForOwnerOfSelectedGroup = accessLevels?.find(
      (accessLevel) => accessLevel?.user?.id === selectedGroup?.owner?.id
    );

    if (accessLevelForOwnerOfSelectedGroup) {
      return accessLevelForOwnerOfSelectedGroup?.statistics?.edit;
    } else {
      return selectedAccountStatisticsAccess?.edit;
    }
  }, [
    accessLevels,
    selectedAccountStatisticsAccess?.edit,
    selectedGroup?.owner?.id,
  ]);

  return canEdit;
};
