import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Period, useReportsContext } from "../../contexts/Reports";
import { SelectOption } from "../SelectField";
import { StyledSelect } from "../StyledSelect";

const periods: Period[] = ["day", "week", "month", "custom"];

export function PeriodSelector() {
  const { t } = useTranslation();
  const [{ selectedPeriod }, actions] = useReportsContext();

  const options = useMemo<SelectOption[]>(
    () => [
      ...periods.map((value) => ({
        value,
        label: t(value),
      })),
      { value: "last7days", label: t("lastXDays", { x: 7 }) },
      { value: "last30days", label: t("lastXDays", { x: 30 }) },
    ],
    [t]
  );

  const setSelectedOption = (value) => actions.selectPeriod(value);

  return (
    <StyledSelect
      value={selectedPeriod ?? null}
      onChange={setSelectedOption}
      stylesOverrides={{
        control: {
          height: 35,
          minHeight: 35,
          cursor: "pointer",
        },
        singleValue: {
          fontSize: 12,
          lineHeight: "15px",
          fontWeight: 600,
          textTransform: "uppercase",
        },
        menu: {
          paddingBottom: 0,
          paddingTop: 0,
        },
        valueContainer: {
          height: 25,
          overflow: "hidden",
        },
      }}
      options={options}
      isSearchable={false}
      formatOptionLabel={(option, meta) =>
        meta.context === "value" &&
        (option.value === "last30days" || option.value === "last7days")
          ? t("custom")
          : option.label
      }
    />
  );
}
