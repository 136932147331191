import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChevronRightIcon = memo(
  ({
    width = 13,
    height = 13,
    isActive = false,
    tintColor = "#0073D9",
    strokeWidth = "1.5",
    direction,
    style,
    ...props
  }: SvgIconProps) => {
    const rotation = () => {
      switch (direction) {
        case "left":
          return "180";
        case "top":
          return "-90";
        case "bottom":
          return "90";
        case "right":
        default:
          return "0";
      }
    };

    return (
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ ...style, transform: `rotate(${rotation()}deg)` }}
        {...props}
      >
        <path
          d="M4.5 2.5L8.5 6.5L4.5 10.5"
          stroke={tintColor}
          strokeWidth={strokeWidth}
        />
      </svg>
    );
  }
);
