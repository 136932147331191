import React, { ChangeEventHandler, FocusEventHandler, useRef } from "react";

import styled, { CSSProperties } from "styled-components";

type StyledTextInputProps = {
  name?: string;
  value?: string;
  onChangeText?: (text: string) => void;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  style?: CSSProperties;
  placeholder?: string;
  type?: HTMLInputElement["type"];
  autoFocus?: boolean;
  disabled?: boolean;
  placeholderTextColor?: CSSProperties["color"];
};

const InputWithStyles = styled.input<{
  placeholderTextColor?: CSSProperties["color"];
}>`
  outline: 0;
  height: 45px;
  border: 0;
  background-color: #f5f6f8;
  font-weight: bold;
  border-radius: 6px;
  padding: 0 20px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ placeholderTextColor }) => placeholderTextColor ?? "#808080"};
    font-size: 14px;
    font-weight: 400;
  }
  :-ms-input-placeholder {
    color: ${({ placeholderTextColor }) => placeholderTextColor ?? "#808080"};
    font-size: 14px;
    font-weight: 400;
  }
  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`;

export const StyledTextInput: React.FunctionComponent<
  React.PropsWithChildren<StyledTextInputProps>
> = ({ onChange, onChangeText, placeholder, disabled, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onChangeText?.(event.target.value);
    onChange?.(event);
  };

  return (
    <InputWithStyles
      {...props}
      ref={inputRef}
      onClick={() => inputRef.current && inputRef.current.focus()}
      onChange={handleChange}
      placeholder={placeholder}
      disabled={disabled}
    />
  );
};
