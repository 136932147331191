import React, { useState } from "react";

import BigInt from "big-integer";

import {
  ItemButton,
  TrainingLoadRegistration,
} from "./TrainingLoadRegistration";

const getRandomId = () =>
  BigInt.randBetween("-9.223e18", "9.223e18").toString();

export interface TrainingLoadItemProps {
  item: any;
  questionnaireId: string;
  questionnaire: any;
  onRemovePress?: () => void;
}

export const TrainingLoadItem = ({ item, ...rest }: TrainingLoadItemProps) => {
  const [registrations, setRegistrations] = useState(item?.registrations || []);

  const onAddPress = () => {
    setRegistrations((old) => [
      ...old,
      { id: getRandomId(), minutes: 0, intensity: 0 },
    ]);
  };

  return registrations.length > 0 ? (
    <>
      {registrations
        .map((registration, index) => (
          <TrainingLoadRegistration
            item={item}
            onAddPress={onAddPress}
            registration={registration}
            key={registration.id}
            index={index}
            {...rest}
          />
        ))
        .reverse()}
    </>
  ) : (
    <ItemButton onClick={onAddPress}>{item?.tlTemplate?.name}</ItemButton>
  );
};
