import React from "react";

import { ApolloQueryResult } from "@apollo/client";
import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import {
  useDeleteWidgetMutation,
  useSaveReportTemplateMutation,
  WidgetTemplatesQuery,
} from "../../graphql";
import {
  usePrepareLayoutForSaving,
  IWidgetResizeProps,
  useGetSelectedAccountIdInMonitoring,
} from "../../hooks";
import { ErrorBoundary } from "../ErrorBoundary";
import { IconButton } from "../IconButton";
import { RemoveIcon } from "../Icons";
import WidgetOptions from "../Report/WidgetOptions";
import { Widget, WidgetType } from "../ReportCreateTable/models";

import { useDeleteWidgetWarning } from "./hooks/useDeleteWidgetWarning";
import { PlaceholderWidget } from "./PlaceholderWidget";
import { Widget as WidgetComponent } from "./Widget";

const WidgetOptionsWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  top: 10px;
  right: 10px;
  z-index: 5;
`;

interface IWidgetWithDataProps extends IWidgetResizeProps {
  widget:
    | (Widget & { columnXPosition: number })
    | { id: string; type: WidgetType.PLACEHOLDER; columnXPosition: number };
  generalWidgets: Widget[];
  report: any;
  readOnly: boolean;
  layoutIndex: number;
  mode: WidgetMode;
  activeTabId?: string;
  refetchGeneralWidgets: () => Promise<ApolloQueryResult<WidgetTemplatesQuery>>;
}

export enum WidgetMode {
  ATHLETE = "athlete",
  GROUP = "group",
}

export const WidgetWithData = ({
  widget,
  report,
  readOnly,
  layoutIndex,
  generalWidgets,
  mode,
  gridItemId,
  layoutRef,
  activeTabId,
  setItemToResize,
  setItemsToHideInViewMode,
  refetchGeneralWidgets,
}: IWidgetWithDataProps) => {
  const user = useUserContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { loading: isLoadingDeleteWidget }] = useDeleteWidgetMutation();
  const [saveReport] = useSaveReportTemplateMutation();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { normalizeLayout } = usePrepareLayoutForSaving();

  const preferences = report.preferences ? JSON.parse(report.preferences) : {};

  const layout = preferences?.layout.map((layoutItem, index) =>
    layoutItem.i === widget.id
      ? {
          ...layoutItem,
          i: `placeholder-${index}`,
          h: 1,
          w: 1,
          isDraggable: false,
          isResizable: false,
        }
      : layoutItem
  );

  const handleDeleteWidget = useDeleteWidgetWarning({
    widgetId: widget.id,
    withRefetchQueries: true,
    reportId: report.id,
    mode,
    ownCallBackAction: async () =>
      await saveReport({
        variables: {
          sessionId: user.sessionId,
          accountId: selectedAccountId,
          input: {
            preferences: JSON.stringify({
              ...JSON.parse(report.preferences),
              layout: normalizeLayout(layout),
            }),
            id: report.id,
          },
        },
      }),
  });

  return (
    <>
      {widget.type !== WidgetType.PLACEHOLDER && !readOnly && (
        <WidgetOptionsWrapper>
          <WidgetOptions
            report={report}
            widget={widget}
            mode={mode}
            layoutIndex={layoutIndex}
            onDeleteWidgetPress={handleDeleteWidget}
            isDeletingWidget={isLoadingDeleteWidget}
            refetchGeneralWidgets={refetchGeneralWidgets}
          />
          <IconButton icon={<RemoveIcon />} onClick={handleDeleteWidget} />
        </WidgetOptionsWrapper>
      )}

      {widget.type === WidgetType.PLACEHOLDER && !readOnly && (
        <PlaceholderWidget
          report={report}
          readOnly={readOnly}
          layoutIndex={layoutIndex}
          generalWidgets={generalWidgets}
          mode={mode}
          activeTabId={activeTabId}
          columnXPosition={widget.columnXPosition}
        />
      )}

      <ErrorBoundary>
        <WidgetComponent
          widget={widget}
          gridItemId={gridItemId}
          setItemToResize={setItemToResize}
          readOnly={readOnly}
          setItemsToHideInViewMode={setItemsToHideInViewMode}
          layoutRef={layoutRef}
          mode={mode}
        />
      </ErrorBoundary>
    </>
  );
};
