import * as api from "../api";
import * as endpoints from "../endpoints";

type MetadataType = "TEST" | "OBSERVATION" | "EXERCISE_SET";

export function fetchMetadata(
  type: MetadataType,
  focusedGroup: string,
  session: api.Session
) {
  const params = {
    _type: type,
    _focusedGroup: focusedGroup,
  };

  return api.post(
    endpoints.JsonCollectionQueryForRelativeTrainer,
    params,
    session
  );
}

export function fetchProgramAvailability(session: api.Session) {
  return api.post(endpoints.JsonProgramAvailabilityQuery, {}, session);
}

export function fetchAppInfo(session: api.Session) {
  return api.post(endpoints.JsonSimpleAppInfoQuery, {}, session);
}

export async function fetchClients(session: api.Session) {
  const params = {
    _expiredToo: true,
  };

  const response = await api.post(
    endpoints.JsonMyClientsQuery,
    params,
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
}

export function fetchGroups(session: api.Session) {
  const params = {
    _origin: "statistics",
  };

  return api.post(endpoints.JsonViewableGroupsQuery, params, session);
}
