import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonColor, GeneralButton } from "../../components/Button/";
import { Checkbox } from "../../components/Checkbox";
import { StyledTextInput } from "../../components/StyledTextInput";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { usePrintGroupReportContext } from "../../contexts/Print/PrintGroupReportProvider";
import { useModalContext } from "../../contexts/UI/Modal";
import {
  ButtonsContainer,
  CheckboxesContainer,
  ControlContainer,
  ControlsContainer,
  ControlSection,
  Header,
  Title,
} from "../styled";

interface GroupControlSectionProps {
  onPrint: () => void;
}

export const GroupControlSection = ({ onPrint }: GroupControlSectionProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const {
    mainTitle,
    logoFlag,
    periodFlag,
    athleteListFlag,
    teamNameFlag,
    experimentalGrid,
    landscapeOrientation,
    setAthleteListFlag,
    setLogoFlag,
    setMainTitle,
    setPeriodFlag,
    setTeamNameFlag,
    setExperimentalGridFlag,
    setLandscapeOrientationFlag,
  } = usePrintGroupReportContext();

  const { selectedAccount } = useAccessLevelsContext();

  return (
    <ControlSection>
      <ControlsContainer>
        <Header>{t("printTeamReport")}</Header>
        <ControlContainer>
          <Title>{t("title")}</Title>
          <StyledTextInput
            value={mainTitle}
            onChangeText={(text) => setMainTitle(text)}
          />
        </ControlContainer>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Title style={{ marginBottom: "17px" }}>{t("header")}</Title>
          <CheckboxesContainer>
            {selectedAccount?.img && (
              <Checkbox
                text={t("includeLogo")}
                checked={logoFlag}
                check={setLogoFlag}
                withMargin={false}
              />
            )}
            <Checkbox
              text={t("includePeriod")}
              checked={periodFlag}
              check={setPeriodFlag}
              withMargin={false}
            />
            <Checkbox
              text={t("includeTeamName")}
              checked={teamNameFlag}
              check={setTeamNameFlag}
              withMargin={false}
            />
            <Checkbox
              text={t("includeAthleteList")}
              checked={athleteListFlag}
              check={setAthleteListFlag}
              withMargin={false}
            />
          </CheckboxesContainer>

          <Title style={{ marginTop: "34px", marginBottom: "17px" }}>
            {t("experimentalFeatures")}
          </Title>
          <CheckboxesContainer>
            <Checkbox
              text={t("printGrid")}
              checked={experimentalGrid}
              check={setExperimentalGridFlag}
              withMargin={false}
            />
            <Checkbox
              text={t("landscapeOrientation")}
              checked={landscapeOrientation}
              check={setLandscapeOrientationFlag}
              withMargin={false}
            />
          </CheckboxesContainer>
        </div>
      </ControlsContainer>
      <ButtonsContainer>
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={modalActions.closeModal}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("confirm")}
          onClick={onPrint}
        />
      </ButtonsContainer>
    </ControlSection>
  );
};
