import React, { Dispatch, SetStateAction } from "react";
import { Props as ReactSelectProps } from "react-select";

import { FieldProps } from "formik";

import { StyledSelectField } from "./StyledSelectField";

export type SelectOption<T = string, CustomFields = Record<string, any>> = {
  label: string;
  value: T;
  optionalFlag?: boolean;
} & CustomFields;

export const SelectField: React.FC<
  React.PropsWithChildren<
    Omit<ReactSelectProps, "form" | "options"> & {
      options: SelectOption[];
      folderStructureProps?: {
        collectionId?: string;
        menuHidden?: boolean;
        setMenuHidden?: Dispatch<SetStateAction<boolean>>;
      };
    } & FieldProps
  >
> = ({
  options = [],
  field,
  form,
  meta,
  value = null,
  isMulti = false,
  onChange = null,
  isLoading = false,
  isClearable = false,
  ...props
}) => {
  const defaultOnSelectChange = (option: SelectOption | SelectOption[]) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as SelectOption[]).map((item: SelectOption) => item.value)
        : (option as SelectOption).value
    );
  };

  const hasError = Boolean(meta?.error);
  const showError = form.submitCount > 0;

  return (
    <StyledSelectField
      {...props}
      options={options}
      name={field.name}
      value={value ?? field.value}
      onChange={onChange ? onChange : defaultOnSelectChange}
      onBlur={field.onBlur}
      isMulti={isMulti}
      isLoading={isLoading}
      isClearable={isClearable}
      isInvalid={hasError && showError}
    />
  );
};
