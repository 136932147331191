import dayjs, { Dayjs } from "dayjs";
import _omit from "lodash/omit";

import {
  TimeLine,
  TimeLinePeriod,
} from "../components/Report/ChartWIdget/model";
import { PerformerSelectionMode } from "../components/Report/PerformerSection";
import {
  LinearColoring,
  SummaryStatsAlignment,
} from "../components/Report/PeriodizationTable/model";
import {
  GroupColumn,
  GroupTableSortBy,
  IRule,
  WidgetType,
  ColoringConfig,
} from "../components/ReportCreateTable/models";
import { AggregateFunction } from "../graphql";
import { Period, PeriodType } from "../models";
import { CompareLatestTo } from "../services";

import { parsePreferences } from "./preferences";

type TablePreferences = {
  type?: WidgetType.TABLE;
  sortingOrder?: number;
  alternatingValues?: boolean;
  alternatingColumns?: boolean;
  widgetBorders?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  showValuesAsText?: boolean;
  period?: Period;
  performerSelectionMode?: PerformerSelectionMode;
  athletes?: string[];
  rules?: IRule[];
};

export type AthleteTablePreferences = TablePreferences & {
  sortBy?: AggregateFunction;
};

export type AthleteTablePeriodizationPreferences = {
  timeLine: TimeLine;
  timeLinePeriod: TimeLinePeriod;
  coloringConfig: ColoringConfig;
  showSummaryStats: boolean;
  summaryStatsAlignment: SummaryStatsAlignment;
  sortOrder: number;
  period: Period;
  verticalHeader: boolean;
  showValuesAsText: boolean;
  ignoreColumnsWithNoData: boolean;
};

export type GroupTablePeriodizationPreferences = {
  timeLine: TimeLine;
  timeLinePeriod: TimeLinePeriod;
  coloringConfig: { id: string; coloring: LinearColoring }[];
  showSummaryStats: boolean;
  summaryStatsAlignment: SummaryStatsAlignment;
  sortOrder: number;
  period: Period;
  verticalHeader: boolean;
  showValuesAsText: boolean;
  ignoreColumnsWithNoData: boolean;
};

export type GroupTablePreferences = TablePreferences & {
  additionalColumnData?: (Pick<
    GroupColumn,
    "comparison" | "showTrend" | "showValue"
  > & {
    id: number;
  })[];
  sortBy?: GroupTableSortBy;
  rgbIndicator?: string;
  sumRow?: boolean;
  averageRow?: boolean;
  averageWithoutZeroes?: boolean;
  maxCount?: number;
  teams?: string[];
  showValuesAsText?: boolean;
  coloringConfig?: ColoringConfig;
  colorStartDate?: Dayjs;
};

export type ReportWidgetTemplateAttendanceLogPreferences = {
  type?: WidgetType.ATTENDANCE_LOG;
  showEvents?: boolean;
  showGames?: boolean;
  showPractices?: boolean;
  maxCount?: number;
  widgetBorders?: boolean;
  alternatingValues?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  allGroups?: boolean;
  sortingOrder?: number;
  period?: Period;
  performerSelectionMode?: PerformerSelectionMode;
  createdBy?: string;
  updatedAt?: Dayjs;
};

export type ReportWidgetTemplateCombinedTestPreferences = {
  type?: WidgetType.COMBINED_TEST;
  collectionId?: string;
  isGroupWidget?: boolean;
  chartType?: "radar" | "circle"; // radar only for athlete report
  showTeamAverage?: boolean; // only for athlete report
  compareTo?: "previous_day" | "latest" | "nothing";
  visibleSubTests?: string[];
  performerSelectionMode?: PerformerSelectionMode;
  athlete?: string;
  athletes?: string[];
  widgetBorders?: boolean;
  period?: Period;
};

export type ReportWidgetTemplateKeyValuesPreferences = {
  type?: WidgetType.KEY_VALUES;
  numberOfColumns?: number;
  performerSelectionMode?: PerformerSelectionMode;
  athlete?: string;
  athletes?: string[];
  teams?: string[];
  widgetBorders?: boolean;
  period?: Period;
  showValuesAsText?: boolean;
  itemsSettings?: {
    customTitle?: string;
    forAllGroups?: boolean;
    compareLatestTo?: CompareLatestTo;
  }[];
  isGroupWidget?: boolean;
};

export type ReportWidgetTemplateAcuteChronicPreferences = {
  type?: WidgetType.ACUTE_CHRONIC;

  collectionId?: string;
  templateId?: string;
  templateName?: string;
  undertrainingTreshold?: number;
  overtrainingTreshold?: number;
  overloadTreshold?: number;
  overloadTresholdColor?: string;
  showBarsForDailyLoad?: boolean;
  showAcuteAverageLine?: boolean;
  showChronicAverageLine?: boolean;

  widgetBorders?: boolean;
  period?: Period;
  createdBy?: string;
  updatedAt?: number;
};

export type ReportWidgetTemplateActivityLogPreferences = {
  type?: WidgetType.ACTIVITY_LOG;
  name?: string;
  workoutsCompleted?: boolean;
  testsRegistered?: boolean;
  informationRegistered?: boolean;
  somethingRead?: boolean;
  widgetBorders?: boolean;
  maxCount?: number;
  period?: Period;
  createdBy?: string;
  updatedAt?: number;
};

const defaultActivityLogPreferences: ReportWidgetTemplateActivityLogPreferences =
  {
    type: WidgetType.ACTIVITY_LOG,
    workoutsCompleted: false,
    testsRegistered: false,
    informationRegistered: false,
    somethingRead: false,
    widgetBorders: false,
    maxCount: 2,
    createdBy: "",
    updatedAt: undefined,
    period: {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
  };

const defaultTablePreferences: TablePreferences = {
  type: WidgetType.TABLE,
  sortingOrder: null,
  alternatingValues: false,
  alternatingColumns: false,
  widgetBorders: false,
  rowDividers: false,
  columnDividers: false,
  outsideBorderDividers: false,
  showValuesAsText: false,
  period: {
    type: PeriodType.SELECTION,
    from: null,
    to: null,
  },
  performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
  athletes: [],
  rules: [],
};

const defaultAthleteTablePreferences: AthleteTablePreferences = {
  ...defaultTablePreferences,
  sortBy: null,
};

const defaultAthleteTablePeriodizationPreferences: AthleteTablePeriodizationPreferences =
  {
    timeLine: TimeLine.PERIOD,
    timeLinePeriod: TimeLinePeriod.DAYS,
    coloringConfig: [],
    showSummaryStats: false,
    summaryStatsAlignment: SummaryStatsAlignment.TOP,
    sortOrder: -1,
    verticalHeader: true,
    showValuesAsText: false,
    period: {
      type: PeriodType.SELECTION,
      isTodaySetAsEnd: false,
      from: null,
      to: null,
    },
    ignoreColumnsWithNoData: false,
  };

const defaultGroupTablePreferences: GroupTablePreferences = {
  ...defaultTablePreferences,
  sortBy: {
    valueIndex: null,
    attributeIndex: null,
    statsIndex: null,
  },
  additionalColumnData: [],
  sumRow: false,
  averageRow: false,
  averageWithoutZeroes: false,
  showValuesAsText: false,
  coloringConfig: [],
  colorStartDate: null,
};

const defaultAttendanceLogPreferences: ReportWidgetTemplateAttendanceLogPreferences =
  {
    type: WidgetType.ATTENDANCE_LOG,
    showEvents: false,
    showGames: false,
    showPractices: false,
    allGroups: false,
    sortingOrder: null,
    alternatingValues: false,
    widgetBorders: false,
    rowDividers: false,
    columnDividers: false,
    performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
    period: {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
    createdBy: "",
    updatedAt: null,
  };

const defaultCombinedTestPreferences: ReportWidgetTemplateCombinedTestPreferences =
  {
    type: WidgetType.COMBINED_TEST,
    compareTo: "nothing",
    visibleSubTests: [],
    performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
    athletes: [],
    widgetBorders: false,
    period: {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
  };

const defaultKeyValuesPreferences: ReportWidgetTemplateKeyValuesPreferences = {
  type: WidgetType.KEY_VALUES,
  numberOfColumns: 3,
  itemsSettings: [],
  performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
  widgetBorders: false,
  period: {
    type: PeriodType.SELECTION,
    from: null,
    to: null,
  },
};

const defaultAcuteChronicPreferences: ReportWidgetTemplateAcuteChronicPreferences =
  {
    type: WidgetType.ACUTE_CHRONIC,
    widgetBorders: false,
    period: {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
  };

export function buildPreferencesString<
  WidgetPreferences =
    | GroupTablePreferences
    | AthleteTablePreferences
    | ReportWidgetTemplateCombinedTestPreferences
    | ReportWidgetTemplateKeyValuesPreferences
    | ReportWidgetTemplateAcuteChronicPreferences
    | ReportWidgetTemplateAttendanceLogPreferences
    | ReportWidgetTemplateActivityLogPreferences,
>(
  inputPreferences: WidgetPreferences,
  excludeProps: (keyof WidgetPreferences)[] = []
): string {
  // @ts-ignore
  const rest = _omit(inputPreferences, [
    "preferences",
    "__typename",
    ...excludeProps,
  ]);

  return JSON.stringify(rest);
}

export const parseAthleteTablePeriodizationPreferencesObject = (
  preferences: string = "{}"
): AthleteTablePeriodizationPreferences => {
  const preferencesObj = parsePreferences<AthleteTablePeriodizationPreferences>(
    preferences,
    defaultAthleteTablePeriodizationPreferences
  );

  return {
    ...defaultAthleteTablePeriodizationPreferences,
    ...preferencesObj,
    ignoreColumnsWithNoData: preferencesObj.ignoreColumnsWithNoData || false,
    period: {
      ...defaultAthleteTablePeriodizationPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseGroupTablePeriodizationPreferencesObject = (
  preferences: string = "{}"
): AthleteTablePeriodizationPreferences => {
  const preferencesObj = parsePreferences<AthleteTablePeriodizationPreferences>(
    preferences,
    defaultAthleteTablePeriodizationPreferences
  );

  return {
    ...defaultAthleteTablePeriodizationPreferences,
    ...preferencesObj,
    ignoreColumnsWithNoData: preferencesObj.ignoreColumnsWithNoData || false,
    period: {
      ...defaultAthleteTablePeriodizationPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseAthleteTablePreferencesObject = (
  preferences: string = "{}"
): AthleteTablePreferences => {
  const preferencesObj = parsePreferences<AthleteTablePreferences>(
    preferences,
    {}
  );

  return {
    ...defaultAthleteTablePreferences,
    ...preferencesObj,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseGroupTablePreferencesObject = (
  preferences: string = "{}"
): GroupTablePreferences => {
  const preferencesObj = parsePreferences<GroupTablePreferences>(
    preferences,
    {}
  );

  return {
    ...defaultGroupTablePreferences,
    ...preferencesObj,
    colorStartDate: preferencesObj.colorStartDate
      ? dayjs(preferencesObj.colorStartDate)
      : null,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseActivityLogPreferencesObject = (
  preferences: string = "{}"
): ReportWidgetTemplateActivityLogPreferences => {
  const preferencesObj =
    parsePreferences<ReportWidgetTemplateActivityLogPreferences>(
      preferences,
      {}
    );

  return {
    ...defaultActivityLogPreferences,
    ...preferencesObj,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseCombinedTestPreferencesObject = (
  preferences: string = "{}"
): ReportWidgetTemplateCombinedTestPreferences => {
  const preferencesObj =
    parsePreferences<ReportWidgetTemplateCombinedTestPreferences>(
      preferences,
      {}
    );

  return {
    ...defaultCombinedTestPreferences,
    ...preferencesObj,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseAttendanceLogPreferencesObject = (
  preferences: string = "{}"
): ReportWidgetTemplateAttendanceLogPreferences => {
  const preferencesObj = JSON.parse(
    preferences
  ) as ReportWidgetTemplateAttendanceLogPreferences;

  return {
    ...defaultAttendanceLogPreferences,
    ...preferencesObj,
    period: {
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseKeyValuesPreferencesObject = (
  preferences: string = "{}"
): ReportWidgetTemplateKeyValuesPreferences => {
  const preferencesObj =
    parsePreferences<ReportWidgetTemplateKeyValuesPreferences>(preferences, {});

  return {
    ...defaultKeyValuesPreferences,
    ...preferencesObj,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};

export const parseAcuteChronicPreferencesObject = (
  preferences: string = "{}"
): ReportWidgetTemplateAcuteChronicPreferences => {
  const preferencesObj =
    parsePreferences<ReportWidgetTemplateAcuteChronicPreferences>(
      preferences,
      {}
    );

  return {
    ...defaultAcuteChronicPreferences,
    ...preferencesObj,
    period: {
      ...defaultActivityLogPreferences.period,
      ...preferencesObj.period,
      from: dayjs(preferencesObj.period?.from),
      to: dayjs(preferencesObj.period?.to),
    },
  };
};
