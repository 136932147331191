import React from "react";

import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { isRouteParamTrue } from "../../utils/route";
import GroupSelector from "../GroupSelector";

const ReportGroupSelector = () => {
  const { selectedAccount } = useAccessLevelsContext();
  const [{ selectedGroup, handleGroupChange }] = useReportsContext();
  const params = new URLSearchParams(window.location.search);
  const isMobile = isRouteParamTrue(params.get("isMobile"));
  const user = useUserContext();

  return (
    <GroupSelector
      requireStatisticsAccess={isRealTrainer(user)}
      value={selectedGroup}
      onChange={(group) => handleGroupChange(group?.id)}
      accountId={
        isMobile || selectedAccount?.id === ALL_ACCOUNTS_ID
          ? undefined
          : selectedAccount?.id
      }
    />
  );
};

export default ReportGroupSelector;
