import React from "react";

import { Header } from "../components/Header";
import { ResourceCalendar } from "../components/ResourceCalendar";
import ScreenContainer from "../components/ScreenContainer";
import { isTvEnv } from "../utils/isTvEnv";

export function CalendarScreen() {
  const isTv = isTvEnv();

  return (
    <ScreenContainer style={{ overflowY: "hidden" }}>
      {!isTv && <Header />}
      <ResourceCalendar />
    </ScreenContainer>
  );
}
