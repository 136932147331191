import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import { useUserContext } from "../../contexts/User";
import { useGetGroupTreeQuery } from "../../graphql";
import { Loader } from "../Loader";

import { GroupFolder } from "./GroupFolder";
import { hasNameInChildren, normalizeString } from "./utils";

interface GroupsFilterProps {
  value: string;
  searchValue: string;
  isSelectAll?: boolean;
}

const LoaderWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const GroupsWrapper = styled.div`
  margin-top: 10px;
  padding: 0 15px 10px 15px;
`;

const NoTeamLabel = styled.div`
  font-size: 12px;
  color: ${COLOR_TEXT_DARK};
  margin-top: 33px;
`;

const EmptyWrapper = styled.div`
  padding: 30px 15px;
  text-align: center;
`;

const EmptyLabel = styled.span`
  font-size: 12px;
  text-align: center;
  color: ${COLOR_TEXT_DARK};
`;

export function GroupsFilter({ searchValue, value }: GroupsFilterProps) {
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const { selectedAccount } = useAccessLevelsContext();

  const { loading, data } = useGetGroupTreeQuery({
    variables: {
      sessionId,
      language,
      membershipFilter: 1,
      multiAccount: selectedAccount?.id === ALL_ACCOUNTS_ID,
      trainer:
        selectedAccount?.id === ALL_ACCOUNTS_ID
          ? undefined
          : selectedAccount?.id,
    },
    // keep this policy to check after removing user from team
    fetchPolicy: "cache-and-network",
    skip: !sessionId && !selectedAccount?.id,
  });

  const showEmptyContent = useMemo(() => {
    if (!searchValue) {
      return false;
    }

    return !data?.getGroupTree?.some(
      (folder) =>
        hasNameInChildren(folder, searchValue) ||
        normalizeString(folder?.name).includes(normalizeString(searchValue))
    );
  }, [data?.getGroupTree, searchValue]);

  if (loading && !data) {
    return (
      <LoaderWrapper>
        <Loader size="small" color={COLOR_BLUE} />
      </LoaderWrapper>
    );
  }

  if (showEmptyContent) {
    return (
      <EmptyWrapper>
        <EmptyLabel>{t("noTeamsAvailable")}</EmptyLabel>
      </EmptyWrapper>
    );
  }

  return (
    <GroupsWrapper>
      {data?.getGroupTree?.map((folder, index) => (
        <GroupFolder
          key={`${folder.id}-${index}`}
          data={folder}
          value={value}
          hasSearchValue={Boolean(searchValue)}
          searchValue={searchValue}
        />
      ))}

      {data?.getGroupTree.length === 0 ? (
        <NoTeamLabel>{t("teamNotFound")}</NoTeamLabel>
      ) : null}
    </GroupsWrapper>
  );
}
