import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_GREY_ACTIVE } from "../../colors";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useUpdateDocumentMutation } from "../../graphql";
import { ButtonColor, GeneralButton } from "../Button/";
import { Thumbnail } from "../Collections/Thumbnail";
import { StarIcon } from "../Icons";

const Wrapper = styled.div`
  display: flex;
  height: 80vh;
  flex-direction: column;
`;

const GradientWrapper = styled.div`
  height: 35vh;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  padding: 20px;
  z-index: 1;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  background-color: white;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
`;

const ButtonWrapper = styled.div`
  height: 40vh;
  background-color: ${COLOR_GRAY};
  z-index: 1;
  padding: 15px 20px;
`;

const StarWrapper = styled.div`
  background-color: ${COLOR_GRAY};
  border-radius: 50%;
  padding: 10px;
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: 20px;
  width: 21px;
  height: 21px;
`;

export const CollectionQuestionnaire = ({
  node,
  hidePopularButton,
  focusedAthleteId,
}) => {
  const { t } = useTranslation();
  const modal = useTopModalContext();
  const { id, sessionId } = useUserContext();
  const [updateDocumentMutation] = useUpdateDocumentMutation();
  const [doc, setDoc] = useState(node);

  const onFavoritePress = async () => {
    await updateDocumentMutation({
      variables: {
        sessionId,
        input: {
          id: node.id,
          isFavorite: !node.isFavorite,
        },
      },
      optimisticResponse: {
        updateDocument: {
          ...node,
          isFavorite: !node.isFavorite,
        },
      },
    });
    await setDoc({
      ...node,
      id: node.id,
      isFavorite: !node.isFavorite,
    });
  };

  const openQuestionnaire = () => {
    const formattedId = node.id.split(":")[2];
    const today = dayjs().format("DDMMYYYY");
    const athleteId = focusedAthleteId || id;

    modal.actions.openTopModal({
      modal: TopModalType.QUESTIONNAIRE,
      title: node.name,
      params: {
        isInTopModal: true,
        questionnaireId: `${formattedId}.${athleteId}.${today}.${today}`,
      },
    });
  };

  return (
    <Wrapper>
      {!hidePopularButton ? (
        <StarWrapper onClick={onFavoritePress}>
          <StarIcon isActive={doc.isFavorite} />
        </StarWrapper>
      ) : null}
      <GradientWrapper>
        <Thumbnail
          src={node.url}
          id={node.id}
          withoutDefaultDimensions={true}
          withoutBorderRadius={true}
        />
      </GradientWrapper>
      <TitleWrapper>{node.name}</TitleWrapper>
      <ButtonWrapper>
        <GeneralButton
          label={t("respondForm")}
          colorVariant={ButtonColor.Blue}
          onClick={openQuestionnaire}
          fullWidth={true}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
