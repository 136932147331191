import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import { COLOR_WHITE } from "../../colors";

interface Props {
  children: JSX.Element;
  offsetX?: number;
  offsetY?: number;
  component: JSX.Element;
  as?: JSX.Element;
  position?: "top" | "bottom";
}

export function Tooltip({
  children,
  offsetX = 10,
  offsetY = 10,
  component,
  position: originalPosition = "bottom",
}: Props) {
  const [{ x, y }, setMousePosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<Props["position"] | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  function handleMouseMovement(e: any): void {
    if (!isVisible) setIsVisible(true);
    setMousePosition({
      x: e.clientX,
      y: e.clientY,
    });
  }
  function handleMouseLeave(): void {
    setIsVisible(false);
    setMousePosition({ x: 0, y: 0 });
  }

  useEffect(() => {
    if (originalPosition && !position) {
      setPosition(originalPosition);
    }
  }, [originalPosition, position]);

  useEffect(() => {
    const tooltipComponentHeight = tooltipRef?.current?.offsetHeight;
    if (tooltipComponentHeight) {
      const toggleBreakpoint = window.innerHeight - tooltipComponentHeight - 10;
      const isTopPosition = y > toggleBreakpoint;
      setPosition(isTopPosition ? "top" : "bottom");
    }
  }, [y]);

  const tooltipYOffset =
    offsetY +
    y -
    (position === "top" && containerRef?.current && tooltipRef?.current
      ? containerRef.current.offsetHeight + tooltipRef.current.offsetHeight
      : 0);

  return (
    <>
      <div
        ref={containerRef}
        onMouseMove={handleMouseMovement}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      <StyledTooltip
        x={offsetX + x}
        y={tooltipYOffset}
        isVisible={isVisible}
        position={position}
      >
        <div ref={tooltipRef}>{component}</div>
      </StyledTooltip>
    </>
  );
}

const StyledTooltip = styled.div<{
  x: number;
  y: number;
  isVisible: boolean;
  position: Props["position"];
}>`
  position: fixed;
  z-index: 999;
  opacity: ${({ isVisible }) => (isVisible ? "1" : "0")};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  left: ${({ x }) => 10 + x}px;
  top: ${({ y, position }) => y + (position === "bottom" ? 10 : -10)}px;
  background: ${COLOR_WHITE};
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
`;
