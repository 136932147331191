import React from "react";
import { DateHeaderProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import styled from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_SECONDARY_GREY } from "../../colors";

dayjs.extend(isToday);

const MonthHeaderWrapper = styled.div<{ isOffRange?: boolean }>`
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 12px;
  color: ${({ isOffRange }) =>
    isOffRange ? COLOR_SECONDARY_GREY : COLOR_ALMOST_BLACK};

  margin: 4px 2px 4px 8px;
`;

const TodayIndicator = styled.span`
  color: ${COLOR_SECONDARY_GREY};
  font-weight: 400;
  font-size: 10px;
`;

const DateIndicator = styled.span`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const CustomCalendarMonthDateHeader = ({
  date,
  label,
  isOffRange,
}: DateHeaderProps) => {
  const { t } = useTranslation();

  const finalLabel = label.startsWith("0") ? label.substring(1) : label;

  const showMonth = isOffRange && date.getDate() === 1;
  const showToday = dayjs(date).isToday();
  const month = dayjs(date).format("MMMM");

  return (
    <MonthHeaderWrapper isOffRange={isOffRange}>
      {showMonth && <span>{month}</span>}
      {showToday && <TodayIndicator>{t("today").toUpperCase()}</TodayIndicator>}

      <DateIndicator>{finalLabel}</DateIndicator>
    </MonthHeaderWrapper>
  );
};
