import { max, min } from "mathjs";

import { getSoccerStatsWithCustomCols } from "../../../../../components/Report/utils/soccerStatsWithCustomCols";
import { GrouppedColumnHeader } from "../../../../../components/TablePeriodization/Widget";
import {
  AggregateFunction,
  ReportWidgetCellNumerical,
} from "../../../../../graphql";
import {
  AttendanceInPeriodOfTimeDataItem,
  SoccerGameStatistics,
  SoccerPeriodStatistics,
} from "../../../../../services";
import { getDataKey } from "../../../../../utils/statistics";

export const getStatsMinMaxForSoccerOrAttendanceData = (
  columnHeaders: GrouppedColumnHeader[] = [],
  keyAccessorFunc: (measurementTemplateId: string) => string,
  periodStats?:
    | AttendanceInPeriodOfTimeDataItem[]
    | SoccerGameStatistics[]
    | SoccerPeriodStatistics[],
  isWithAggregation: boolean = false
) => {
  return columnHeaders
    ?.flatMap((column) => {
      if (!column.attributes?.length) {
        return null;
      }

      const requestedData = column.attributes[0] as ReportWidgetCellNumerical;

      return requestedData.aggregateFunctions.map((aggregateFunction) => {
        const requestedDataId = getDataKey(
          requestedData.templateId,
          requestedData.attributeTemplate?.id,
          aggregateFunction
        );

        if (isWithAggregation) {
          const values = ((periodStats as SoccerPeriodStatistics[]) ?? [])
            .map((periodStat) =>
              Number(
                getSoccerStatsWithCustomCols(
                  periodStat,
                  requestedData.templateId,
                  aggregateFunction ?? AggregateFunction.Latest
                )
              )
            )
            .filter((value) => !isNaN(value));

          return {
            id: requestedDataId,
            min: values.length ? min(values) : Number.MAX_VALUE,
            max: values.length ? max(values) : Number.MIN_VALUE,
          };
        } else {
          const statsForRequestedData = keyAccessorFunc(
            requestedData.templateId
          );

          const values =
            periodStats
              ?.map((stat) => stat[statsForRequestedData])
              .filter((value) => typeof value === "number") ?? [];

          return {
            id: requestedDataId,
            min: values.length ? min(values) : Number.MAX_VALUE,
            max: values.length ? max(values) : Number.MIN_VALUE,
          };
        }
      });
    })
    .filter((val) => Boolean(val));
};
