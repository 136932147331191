import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { PerformerSelectionMode } from "../../components/Report/PerformerSection";
import { ReportsContextState, useReportsContext } from "../../contexts/Reports";
import {
  SelectedPerformersContextModel,
  useSelectedPerformersContext,
} from "../../contexts/selectedAthletes";

const getDefaultParticipants = ({
  routePathname,
  selectedPerformersState,
  reportContextState,
}: {
  routePathname: string;
  selectedPerformersState: SelectedPerformersContextModel;
  reportContextState: ReportsContextState;
}) => ({
  participantTeams:
    (routePathname === "/athlete/profile"
      ? []
      : selectedPerformersState.attendingTeams?.map((team) => team.id)) ?? [],
  participants:
    (routePathname === "/athlete/profile"
      ? reportContextState.selectedGroup?.requiredAthletes?.map(
          (athlete) => athlete.id
        )
      : selectedPerformersState.attendingUsers?.map((user) => user.id)) ?? [],
});

export const useGetGroupWidgetParticipants = () => {
  const [reportContextState] = useReportsContext();
  const selectedPerformersState = useSelectedPerformersContext();
  const location = useLocation();

  const getGroupWidgetParticipants = useCallback(
    (widget): { participants: string[]; participantTeams: string[] } => {
      const defaultResult = getDefaultParticipants({
        routePathname: location.pathname,
        reportContextState,
        selectedPerformersState,
      });

      switch (widget.performerSelectionMode) {
        case PerformerSelectionMode.ATHLETE_FIXED:
          return { participantTeams: [], participants: widget.athletes ?? [] };
        case PerformerSelectionMode.ATHLETE_SELECTION:
          return defaultResult;
        case PerformerSelectionMode.TEAM_FIXED:
          return { participants: [], participantTeams: widget.teams ?? [] };
        case PerformerSelectionMode.TEAM_SELECTION:
          return location.pathname === "/athlete/profile"
            ? {
                participants: [],
                participantTeams: [reportContextState.selectedGroup.id],
              }
            : {
                ...defaultResult,
                participants: [],
              };
        default:
          return defaultResult;
      }
    },
    [reportContextState, selectedPerformersState, location.pathname]
  );

  return { getGroupWidgetParticipants };
};

export function useGroupWidgetParticipants<
  TWidget extends {
    performerSelectionMode?: PerformerSelectionMode;
    athletes?: string[];
    teams?: string[];
  },
>({ performerSelectionMode, athletes, teams }: TWidget) {
  const [reportContextState] = useReportsContext();
  const selectedPerformersState = useSelectedPerformersContext();

  const location = useLocation();

  return useMemo<{ participants: string[]; participantTeams: string[] }>(() => {
    const defaultResult = getDefaultParticipants({
      routePathname: location.pathname,
      reportContextState,
      selectedPerformersState,
    });

    switch (performerSelectionMode) {
      case PerformerSelectionMode.ATHLETE_FIXED:
        return { participantTeams: [], participants: athletes ?? [] };
      case PerformerSelectionMode.ATHLETE_SELECTION:
        return defaultResult;
      case PerformerSelectionMode.TEAM_FIXED:
        return { participants: [], participantTeams: teams ?? [] };
      case PerformerSelectionMode.TEAM_SELECTION:
        return location.pathname === "/athlete/profile"
          ? {
              participants: [],
              participantTeams: [reportContextState.selectedGroup.id],
            }
          : {
              ...defaultResult,
              participants: [],
            };
      default:
        return defaultResult;
    }
  }, [
    reportContextState,
    selectedPerformersState,
    performerSelectionMode,
    athletes,
    teams,
    location.pathname,
  ]);
}
