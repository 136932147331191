import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components/";

import { COLOR_BLUE, COLOR_GREY_ACTIVE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import {
  useDeleteWidgetMutation,
  useWidgetTemplatesQuery,
} from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { sortList } from "../../utils/sortList";
import { Loader } from "../Loader";

import { ColumnNameText, NameText, NoDataForListingMessage } from "./styled";
import TemplatesListItem from "./TemplatesListItem";

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 11px 20px;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  shadow-opacity: 0.05;
  shadow-radius: 6px;
  shadow-offset: 0px 2px;
  shadow-color: #223e6a;
`;

const IconHeaderColumn = styled.div`
  width: 21px;
  height: 21px;
`;

const NoDataWrapper = styled.div`
  display: flex;
  padding: 17px;
`;

interface ITemplatesListProps {
  searchQuery: string;
}

export const TemplatesList = ({ searchQuery }: ITemplatesListProps) => {
  const [curItem, setCurItem] = useState<string>("");
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { loading: isLoadingDeleteWidget }] = useDeleteWidgetMutation();
  const { data, loading: loadingWidgets } = useWidgetTemplatesQuery({
    variables: {
      sessionId,
      accountId: selectedAccountId,
      language,
    },
    // We need to refetch results as new template may be added
    // We do not want to manually modify cache nor invalidate queries as we have many different widgets
    fetchPolicy: "network-only",
  });

  const widgets = useMemo(
    () =>
      sortList(
        data?.reportWidgetTemplates
          .filter((widgetTemplate) =>
            "name" in widgetTemplate
              ? widgetTemplate.name
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
              : true
          )
          .map((widgetTemplate) => ({
            ...widgetTemplate,
            ...JSON.parse(
              "preferences" in widgetTemplate &&
                typeof widgetTemplate.preferences === "string"
                ? widgetTemplate.preferences
                : "{}"
            ),
          })),
        language
      ),
    [data?.reportWidgetTemplates, language, searchQuery]
  );

  return (
    <TableWrapper>
      <HeaderRow>
        <NameText>{t("name").toUpperCase()}</NameText>
        <ColumnNameText>{t("typeOfWidget").toUpperCase()}</ColumnNameText>
        <ColumnNameText>{t("createdBy").toUpperCase()}</ColumnNameText>
        <ColumnNameText>{t("lastUpdated").toUpperCase()}</ColumnNameText>
        <IconHeaderColumn />
      </HeaderRow>

      {widgets.map((widget, index) => (
        <TemplatesListItem
          widget={widget}
          key={widget.id}
          deletingWidget={isLoadingDeleteWidget}
          isLast={index === widgets.length - 1}
          curItem={curItem}
          setCurItem={setCurItem}
        />
      ))}
      {!widgets.length && !loadingWidgets && (
        <NoDataWrapper>
          <NoDataForListingMessage>
            {t("noWidgetsFound").toUpperCase()}
          </NoDataForListingMessage>
        </NoDataWrapper>
      )}
      {loadingWidgets && (
        <NoDataWrapper>
          <Loader color={COLOR_BLUE} size="small" />
        </NoDataWrapper>
      )}
    </TableWrapper>
  );
};
