import { TFunction } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";

import { ChatterType } from "../../../graphql";

import { ChatTabs } from "./model";

export const getChatterType = (tab: { id: string }, t: TFunction) => {
  switch (tab.id) {
    case ChatTabs.COACHES:
      return ChatterType.Trainer;
    case ChatTabs.FAMILY:
      return ChatterType.Family;
    case ChatTabs.ATHLETES:
      return ChatterType.Athlete;
    case ChatTabs.TEAMS:
      return ChatterType.RealGroup;
    default:
      return null;
  }
};

export const getUpdatedDate = (date: Dayjs, hours: string) => {
  const [hour, minute] = hours?.split(":");

  return dayjs(date)
    .set("hours", Number(hour))
    .set("minute", Number(minute))
    .valueOf();
};
