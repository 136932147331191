import React, { useMemo, useRef, memo } from "react";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { std, mean, min, max } from "mathjs";

import { generatePeriodCategories } from "../../../hooks/data/periodizationTable/helpers/helpers";
import { getDummyValue } from "../../../utils/dummyData";
import { roundToTwo } from "../../../utils/number";
import { getDataKey } from "../../../utils/statistics";
import { TimeLinePeriod } from "../../Report/ChartWIdget/model";
import { TablePeriodizationForm } from "../../Report/PeriodizationTable/model";

import { TablePeriodizationWidgetTable } from "./Table";

export const TablePeriodizationTablePreview = memo(() => {
  const dummyValues = useRef<Map<string, number | string>>(
    new Map<string, number | string>()
  );

  const minAndMaxValues = useRef<Map<string, { min: number; max: number }>>(
    new Map<string, { min: number; max: number }>()
  );

  const { values } = useFormikContext<TablePeriodizationForm>();

  const periods = useMemo(
    () =>
      generatePeriodCategories(
        values.period.from?.isValid()
          ? values.period.from
          : dayjs().subtract(6, "month"),
        values.period.to?.isValid() ? values.period.to : dayjs(),
        values.timeLine === "continuous"
          ? TimeLinePeriod.DAYS
          : values.timeLinePeriod
      )
        .slice(0, 7)
        .sort(() => values.sortOrder),
    [values.timeLine, values.period, values.timeLinePeriod, values.sortOrder]
  );

  const getDumyDataPerPeriod = (columnHeader, period, id) => {
    if (!columnHeader) {
      return null;
    }

    const currentAggretate = columnHeader?.aggregateFunctions[0];

    const rowKey = `${id}-${columnHeader.itemId}-${currentAggretate}-${columnHeader.textOptions?.length}-${values.showValuesAsText}-${period.label}`;
    let alreadyGeneratedDummyCells = dummyValues.current.get(rowKey);

    if (!alreadyGeneratedDummyCells) {
      dummyValues.current.set(
        rowKey,
        getDummyValue(
          columnHeader.attributeTemplateUnitName,
          false,
          currentAggretate,
          columnHeader.textOptions,
          values.showValuesAsText,
          columnHeader.measurementTemplateIdAccessor
        ) as unknown as number
      );

      alreadyGeneratedDummyCells = dummyValues.current.get(rowKey);
    }

    const shouldShowTestValueAsString =
      values.showValuesAsText && columnHeader.textOptions?.length;

    return {
      id: getDataKey(
        columnHeader.itemId,
        columnHeader.attributeTemplateId,
        currentAggretate
      ),
      value: shouldShowTestValueAsString
        ? Math.floor(Math.random() * columnHeader.textOptions.length)
        : alreadyGeneratedDummyCells ?? -1,
      txt:
        shouldShowTestValueAsString &&
        typeof alreadyGeneratedDummyCells === "string"
          ? alreadyGeneratedDummyCells
          : "",
      unitName: columnHeader.attributeTemplateUnitName,
      time: 0,
      min: 0,
      max: 100,
    };
  };

  const exampleRows = useMemo(
    () =>
      ["John Doe", "Jane Doe"].map((athleteName, index) => ({
        athleteName,
        data: periods.flatMap((period) => ({
          label: period.label,
          dataPerPeriod: getDumyDataPerPeriod(
            values.columnHeaders[0],
            period,
            `${athleteName}-${index}`
          ),
        })),
      })),
    /* eslint-disable react-hooks/exhaustive-deps */
    [values.columnHeaders, periods]
  );

  const sortedStatsData = useMemo(() => {
    if (!periods?.length) {
      return [];
    }
    return periods.map((period, index) => {
      const filteredData = exampleRows.flatMap((row) =>
        row.data.filter(
          ({ label, dataPerPeriod }) => label === period.label && dataPerPeriod
        )
      );

      const statsData = filteredData.flatMap(
        (dataPeriod) => dataPeriod.dataPerPeriod.value as number
      );
      if (!filteredData?.length || !statsData?.length) {
        return {} as {
          id: string;
          average: number;
          stdDev: number;
          tifn: boolean;
          unitName: string;
        };
      }

      if (!minAndMaxValues.current.get(period.label)) {
        minAndMaxValues.current.set(period.label, {
          min: min(statsData),
          max: max(statsData),
        });
      }

      return {
        id: `${period.label}-${index}`,
        average: roundToTwo(mean(statsData)).toString(),
        stdDev: roundToTwo(std(statsData) as unknown as number).toString(),
        tifn: false,
        unitName: filteredData.length
          ? filteredData[0].dataPerPeriod.unitName
          : "",
      };
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [values.columnHeaders, periods]);

  if (
    values.columnHeaders.filter(
      (columnHeader) =>
        columnHeader.collectionId &&
        columnHeader.itemId &&
        columnHeader.attributeTemplateId
    ).length === 0
  ) {
    return null;
  }

  return (
    <TablePeriodizationWidgetTable
      title={values.name}
      coloringConfig={values.coloringConfig}
      columnPeriods={periods.map((period, index) => ({
        ...period,
        id: "",
        templateId: `${period.label}-${index}`,
        name: period.label,
      }))}
      data={exampleRows}
      statsData={sortedStatsData}
      timeLine={values.timeLine}
      verticalHeader={values.verticalHeader}
      showValuesAsText={values.showValuesAsText}
      showSummaryStats={values.showSummaryStats}
      summaryStatsAlignment={values.summaryStatsAlignment}
      ignoreColumnsWithNoData={values.ignoreColumnsWithNoData}
    />
  );
});
