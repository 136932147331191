import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useNotificationsContext } from "../../contexts/notifications";
import { ReportTemplate, ReportType } from "../../graphql";

import { BaseListItem } from "./BaseListItem";
import { useDeleteReportWarning } from "./hooks/useDeleteReportWarning";
import { useDuplicateReport } from "./hooks/useDuplicateReport";
import { WidgetMode } from "./WidgetWithData";

interface IReportListItemProps {
  report: ReportTemplate;
  onDeleteReportFinish: () => void;
  isLast: boolean;
  curItem: string;
  setCurItem: (id: string) => void;
  deletingReport: boolean;
}

export const ReportListItem = ({
  report,
  onDeleteReportFinish,
  isLast,
  curItem,
  setCurItem,
  deletingReport,
}: IReportListItemProps) => {
  const { t } = useTranslation();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const canEdit = useCanEditStatistics();

  const navigate = useNavigate();

  const widgetMode =
    report.reportType === ReportType.GroupReport
      ? WidgetMode.GROUP
      : WidgetMode.ATHLETE;

  const { duplicateReport } = useDuplicateReport(widgetMode);
  const handleDeleteReport = useDeleteReportWarning({
    reportTemplate: report,
    onCloseModal: onDeleteReportFinish,
  });

  const [isLoading, setIsLoading] = useState(false);

  const onOpenReportClick = () => {
    navigate({
      pathname:
        report.reportType === ReportType.GroupReport
          ? `/reports/groupReport/${report.id}`
          : `/reports/report/${report.id}`,
    });
  };

  const onDuplicateClick = async () => {
    setIsLoading(true);

    try {
      await duplicateReport(report);
      showSuccessNotification(t("duplicateReportSuccess"));
    } catch (error) {
      console.error(error);
      showErrorNotification(t("duplicateReportError"));
    }

    setIsLoading(false);
  };

  const items = canEdit
    ? [
        { onItemClick: onOpenReportClick, title: t("openReport") },
        {
          onItemClick: onDuplicateClick,
          title: t("duplicateReport"),
        },
        {
          onItemClick: handleDeleteReport,
          title: t("deleteReport"),
        },
      ]
    : [{ onItemClick: onOpenReportClick, title: t("openReport") }];

  return (
    <BaseListItem
      onNameClick={onOpenReportClick}
      updatedAt={report.lastSavedTime}
      owner={report.owner?.name}
      createdBy={report.createdBy?.name}
      objectName={report.name}
      setCurrentItem={setCurItem}
      currentItemId={curItem}
      objectId={report.id}
      isDeleting={deletingReport}
      isLoading={isLoading}
      isLast={isLast}
      items={items}
    />
  );
};

export default ReportListItem;
