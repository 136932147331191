import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const StarIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.58916 2.00992C9.94398 1.22694 11.056 1.22694 11.4108 2.00992L13.4305 6.46657L18.2931 7.01016C19.1474 7.10567 19.491 8.16327 18.856 8.74268L15.2416 12.0406L16.2272 16.8332C16.4004 17.6752 15.5007 18.3289 14.7535 17.904L10.5 15.4856L6.24654 17.904C5.49925 18.3289 4.5996 17.6752 4.77277 16.8332L5.75841 12.0406L2.14399 8.74268C1.50897 8.16327 1.85261 7.10567 2.70691 7.01016L7.56953 6.46657L9.58916 2.00992Z"
        fill={isActive ? "#FFC700" : "#E6E8EB"}
      />
    </svg>
  )
);
