import { ReportWidgetCell } from "../../../graphql";

export interface GrouppedColumnHeader {
  templateId: string;
  attributes: ReportWidgetCell[];
}

export const getGrouppedColumnHeader = (
  columnHeaders: ReportWidgetCell[]
): GrouppedColumnHeader[] => {
  const grouppedColumnHeaderMap = columnHeaders.reduce(
    (entryMap, header) =>
      entryMap.set(header.templateId, [
        ...(entryMap.get(header.templateId) || []),
        header,
      ]),
    new Map()
  );

  return Array.from(grouppedColumnHeaderMap.values()).map(
    (value: ReportWidgetCell[]) => ({
      templateId: value[0]?.templateId,
      attributes: value.map((attributeValue) => ({
        ...attributeValue,
      })),
    })
  );
};
