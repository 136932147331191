import { useCallback } from "react";

import { useUserContext } from "../../../../contexts/User";
import {
  useReportBuildingAttributeTemplatesLazyQuery,
  useReportBuildingMeasurementTemplatesLazyQuery,
} from "../../../../graphql";

export const useMeasurementAndAttributeDetails = () => {
  const { sessionId, language } = useUserContext();

  const [getMeasurementTemplates] =
    useReportBuildingMeasurementTemplatesLazyQuery();
  const [getAttributeTemplates] = useReportBuildingAttributeTemplatesLazyQuery({
    fetchPolicy: "no-cache",
  });

  const getDetails = useCallback(
    async (
      collectionId: string,
      measurementTemplateId: string,
      attributeId: string
    ) => {
      if (collectionId && measurementTemplateId && attributeId) {
        const sharedVariables = { sessionId, language, collectionId };

        const [measurementResponse, attributeResponse] = await Promise.all([
          getMeasurementTemplates({ variables: sharedVariables }),
          getAttributeTemplates({
            variables: { ...sharedVariables, measurementTemplateId },
          }),
        ]);

        const measurementTemplate =
          measurementResponse.data?.reportBuildingMeasurementTemplates?.find(
            (measurement) => measurement.id === measurementTemplateId
          );

        const attributeTemplate =
          attributeResponse.data?.reportBuildingAttributeTemplates?.find(
            (attribute) => attribute.id === attributeId
          );

        return {
          measurementTemplate,
          attributeTemplate,
        };
      }

      return {
        measurementTemplate: undefined,
        attributeTemplate: undefined,
      };
    },
    [sessionId, language, getMeasurementTemplates, getAttributeTemplates]
  );

  return { getDetails };
};
