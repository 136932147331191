import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY } from "../../../colors";
import { SelectedAthletesList } from "../../GroupReport/SelectedAthletesList";
import { CancelSearchIcon } from "../../Icons/CancelIcon";
import { SearchIcon } from "../../Icons/SearchIcon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 45px;
  border-radius: 6px;
  background-color: ${COLOR_GRAY};
  padding: 12px;
  margin: 15px;
  box-sizing: border-box;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
`;

const Input = styled.input`
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
`;

export const SelectAthletesFromGroupHeader = ({ search, setSearch }) => {
  const { t } = useTranslation();

  const searchInput = useRef(null);

  const searchComponent = useCallback((node) => {
    node?.focus();

    searchInput.current = node;
  }, []);

  return (
    <>
      <Container>
        <InputContainer>
          <SearchIcon style={{ marginRight: 10 }} />
          <Input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            placeholder={t("search")}
            autoFocus
            ref={searchComponent}
          />
          {!!search && (
            <CancelSearchIcon
              onClick={() => setSearch("")}
              style={{ cursor: "pointer" }}
            />
          )}
        </InputContainer>
      </Container>

      <SelectedAthletesList />
    </>
  );
};
