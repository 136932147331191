import React, { memo } from "react";

export const DiagramAndAnimationIcon = memo(() => (
  <svg
    width="54"
    height="42"
    viewBox="0 0 54 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 19C35.6 19 37 17.6 37 16C37 14.4 35.6 13 34 13C32.4 13 31 14.4 31 16C31 17.6 32.4 19 34 19ZM30 23.6L31.4 25L33 23.4V28.2C33 30.8 30.8 33 28.2 33H22.8C22.4 31.8 21.2 31 20 31C18.4 31 17 32.4 17 34C17 35.6 18.4 37 20 37C21.4 37 22.4 36.2 22.8 35H28.2C32 35 35 32 35 28.2V23.4L36.6 25L38 23.6L34 19.6L30 23.6ZM40 4H37C37 2.8 36.2 2 35 2H30.4C29.8 0.8 28.4 0 27 0C25.6 0 24.2 0.8 23.6 2H19C17.8 2 17 2.8 17 4H14C11.8 4 10 5.8 10 8V38C10 40.2 11.8 42 14 42H40C42.2 42 44 40.2 44 38V8C44 5.8 42.2 4 40 4ZM19 4H24.8L25.4 3C25.6 2.4 26.2 2 27 2C27.8 2 28.4 2.4 28.8 3L29.2 4H35V8H19V4ZM18 25.6L20 23.6L22 25.6L23.4 24.2L21.4 22.2L23.4 20.2L22 19L20 21L18 19L16.6 20.4L18.6 22.4L16.6 24.4L18 25.6Z"
      fill="#A0BEEC"
    />
  </svg>
));
