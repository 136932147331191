import React, { useEffect, useMemo, useRef, useState } from "react";

import { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import { COLOR_GRAY, COLOR_WHITE } from "../../../colors";
import { AggregateFunction } from "../../../graphql";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../../hooks";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetTitleContainer,
} from "../../Report/styled";
import { IRule } from "../../ReportCreateTable/models";
import { StyledTableComponent } from "../styled";
import { SortByConfig } from "../utils";

import { ITableRowModel } from "./model";
import { TableHeaderRow } from "./TableHeaderRow";
import { TableRow } from "./TableRow";
import {
  sortEmptyValues,
  sortNumericTableRows,
  sortTextualTableRows,
} from "./utils";

interface ITableProps extends IWidgetResizeProps {
  id: string;
  title: string;
  rows: ITableRowModel[];
  colNames: AggregateFunction[];
  alternatingRow?: boolean;
  alternatingColumns?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  widgetBorders?: boolean;
  rules: IRule[];
  sortBy?: AggregateFunction;
  sortingOrder?: number;
  readOnly?: boolean;
  showValuesAsText?: boolean;
  onChangeSort?: (sortBy: AggregateFunction, order: number) => void;
}

export const Table = (props: ITableProps) => {
  const { isMobile } = useTheme();
  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();

  const [sortByConfig, setSortByConfig] = useState<SortByConfig>({
    col: null,
    order: -1,
  });

  const scrollContainerRef = useRef<any>();

  useBottomWidgetResize({
    setItemToResize: props.setItemToResize,
    setItemsToHideInViewMode: props.setItemsToHideInViewMode,
    gridItemId: props.gridItemId,
    widgetHeight,
    id: props.id,
    shouldResize: props.readOnly,
    alwaysResize: true,
    layoutRef: props.layoutRef,
  });

  useEffect(() => {
    setSortByConfig({ col: props.sortBy, order: props.sortingOrder });
  }, [props.sortingOrder, props.sortBy]);

  const rowsData = useMemo(() => {
    if (sortByConfig.col === null || props.rows.length === 0) {
      return props.rows;
    }

    const colIndex = props.colNames.indexOf(sortByConfig.col);

    if (colIndex === -1) {
      return props.rows;
    }

    return [
      ...sortNumericTableRows(props.rows, colIndex, sortByConfig.order),
      ...sortTextualTableRows(props.rows, colIndex, sortByConfig.order),
      ...sortEmptyValues(props.rows, colIndex),
    ];
  }, [props.rows, props.colNames, sortByConfig]);

  const paddingValue = !isMobile ? 30 : 15;

  return (
    <WidgetContainer
      showBorders={props.widgetBorders}
      id={`athlete-table-${props.gridItemId}`}
    >
      <div ref={tableRef as any}>
        <WidgetTitleContainer style={{ paddingBottom: 10, marginBottom: 0 }}>
          <WidgetTitle>{props.title}</WidgetTitle>
        </WidgetTitleContainer>
        <div
          ref={scrollContainerRef}
          style={{ width: "100%", overflowX: "auto", paddingBottom: 10 }}
        >
          <StyledTableComponent
            id="athlete-table-container-table-el"
            paddingValue={paddingValue}
          >
            <thead>
              <TableHeaderRow
                sortByConfig={sortByConfig}
                setSortByConfig={setSortByConfig}
                colNames={props.colNames}
                alternatingColumns={props.alternatingColumns}
                onChangeSort={props.onChangeSort}
              />
            </thead>
            <tbody>
              {rowsData.map((row, index) => {
                const color =
                  props.alternatingRow && index % 2 === 0
                    ? COLOR_GRAY
                    : COLOR_WHITE;
                const isFirst = index === 0;
                const isLast = index === rowsData.length - 1;

                return (
                  <TableRow
                    colNames={props.colNames}
                    rules={props.rules}
                    color={color}
                    item={row}
                    isFirst={isFirst}
                    isLast={isLast}
                    alternatingColumns={props.alternatingColumns}
                    rowDividers={props.rowDividers}
                    columnDividers={props.columnDividers}
                    showValuesAsText={props.showValuesAsText}
                    outsideBorderDividers={props.outsideBorderDividers}
                    key={"tableRow" + index + row.key}
                  />
                );
              })}
            </tbody>
          </StyledTableComponent>
        </div>
      </div>
    </WidgetContainer>
  );
};
