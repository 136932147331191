import React, { useState } from "react";

import { Slider, styled as muistyled, alpha } from "@mui/material";
import styled from "styled-components";

import {
  COLOR_GREEN_ACTIVE,
  COLOR_YELLOW_ACTIVE,
  COLOR_RED_SECONDARY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../../colors";

export type Difficulty = "easy" | "average" | "hard" | "dynamic";

export const breakingPoints = {
  easy: 2.9,
  hard: 7.1,
};

const gradient = [
  "#35CC00",
  "#96cf07",
  "#b5d70f",
  "#d2d917",
  "#e1cd20",
  "#e4b726",
  "#e8a72f",
  "#ec973a",
  "#f28542",
  "#f5764b",
  "#fa6c56",
];

export function assignColor(
  difficulty: Difficulty,
  value?: number,
  min?: number,
  step?: number
): string {
  if (difficulty === "dynamic") {
    const normalizedValue = Math.floor((value - min) / step);
    const index = Math.min(Math.max(normalizedValue, 0), gradient.length - 1);
    return gradient[index];
  }
  if (difficulty === "easy") return COLOR_GREEN_ACTIVE;
  if (difficulty === "average") return COLOR_YELLOW_ACTIVE;
  if (difficulty === "hard") return COLOR_RED_SECONDARY;
  return COLOR_GREEN_ACTIVE;
}

export function HorizontalSlider(props: // SliderProps &
{
  difficulty?: Difficulty;
  width?: number;
  difficultyMarker?: boolean;
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}) {
  const {
    difficulty,
    width,
    difficultyMarker,
    defaultValue,
    value = 0,
    onChange,
    min = 0,
    max = 9,
    step = 1,
  } = props;
  const [observedValue, setObservedValue] = useState(0);

  function handleChange(_, value): void {
    const val = parseInt(value, 10);
    setObservedValue(val as number);
    onChange?.(val);
  }
  function getDifficultyString(): string | undefined {
    if (observedValue <= breakingPoints.easy) return "Easy";
    if (
      observedValue > breakingPoints.easy &&
      observedValue < breakingPoints.hard
    )
      return "Average";
    if (observedValue >= breakingPoints.hard) return "Hard";
    return undefined;
  }

  return (
    <StyledWrapper>
      {difficultyMarker && <StyledMarker>{getDifficultyString()}</StyledMarker>}
      <StyledSlider
        // {...props}
        difficulty={difficulty}
        width={width}
        observedValue={observedValue}
        orientation="horizontal"
        onChange={handleChange}
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        step={step}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 30px;
`;
const StyledMarker = styled.label`
  margin: 25px 0;
  font-family: "Inter", sans-serif;
`;

const StyledSlider = muistyled(Slider)<{
  difficulty: Difficulty;
  width: number;
  observedValue: number;
  min: number;
  step: number;
}>(({ difficulty, width, observedValue, min, step }) => ({
  minWidth: "40px",
  width: width || "initial",
  height: "15px",
  borderRadius: "10px",
  color: assignColor(difficulty, observedValue, min, step),
  "& .MuiSlider-rail": {
    opacity: 1,
    color: COLOR_GREY_ACTIVE,
  },
  "& .MuiSlider-track": {
    paddingRight: "15px",
  },
  "& .MuiSlider-thumb": {
    marginLeft: "15px",
    color: COLOR_WHITE,
    width: "31px",
    height: "31px",
    boxShadow:
      "0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 9px rgba(0, 0, 0, 0.15)",
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(
        assignColor(difficulty, observedValue, min, step),
        0.16
      )}`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 0px 14px ${alpha(
        assignColor(difficulty, observedValue, min, step),
        0.16
      )}`,
    },
  },
}));
