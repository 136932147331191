import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const SettingsHorizontalIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#0073D9",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="11.5"
        cy="5.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <circle
        cx="15.5"
        cy="10.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <circle
        cx="6.5"
        cy="15.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <rect
        x="4"
        y="15"
        width="3"
        height="1"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <rect
        x="9"
        y="15"
        width="8"
        height="1"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <rect
        x="4"
        y="10"
        width="12"
        height="1"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <rect
        x="4"
        y="5"
        width="8"
        height="1"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <rect
        x="14"
        y="5"
        width="3"
        height="1"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
    </svg>
  )
);
