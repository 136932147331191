import React, { useState, useEffect, CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import styled from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_WHITE } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { useMyAthletesQuery } from "../../../services/hooks";
import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import Avatar from "../../Avatar";
import { ChevronRightIcon, UserRoundedIcon } from "../../Icons";
import { Loader } from "../../Loader";
import { NavIcon } from "../NavIcon";

interface AthletesNavSideSectionProps {
  isCompact: boolean;
}

const AthletesWrapper = styled.div`
  margin-bottom: 40px;
  background-color: ${COLOR_ALMOST_BLACK};
  padding: 5px 10px;
  gap: 5px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AthletesNavSideSection = ({
  isCompact,
}: AthletesNavSideSectionProps) => {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isAthleteSectionOpened, setIsAthleteSectionOpened] = useState(false);

  const { data, isLoading, refetch } = useMyAthletesQuery({
    session: { sessionId },
  });

  useEffect(() => {
    refetch();
  }, [sessionId, refetch]);

  return (
    <>
      <NavIcon
        active={false}
        iconSvg={<UserRoundedIcon />}
        isCompact={isCompact}
        text={t("athletes")}
        onClick={() => setIsAthleteSectionOpened((old) => !old)}
      >
        {() => (
          <ChevronRightIcon
            tintColor={COLOR_WHITE}
            direction={isAthleteSectionOpened ? "bottom" : "right"}
          />
        )}
      </NavIcon>

      {isAthleteSectionOpened && (
        <AthletesWrapper>
          {isLoading && (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          )}
          {data?.data?._myAthletes?.map((athlete) => (
            <NavIcon
              active={
                Boolean(matchPath(ROUTING_CONFIG.Athletes, pathname)) &&
                searchParams.get("athleteId") === athlete._athleteGuid
              }
              leftIcon={
                <Avatar
                  userName={athlete._athleteName}
                  source={{ uri: athlete._profileImageThumbUrl }}
                  size="small"
                  style={{ marginRight: "10px" }}
                />
              }
              key={athlete._athleteGuid}
              onClick={() =>
                navigate({
                  pathname: `/${ROUTING_CONFIG.Athletes}`,
                  search: createSearchParamsWithoutUndefinedValues({
                    athleteId: athlete._athleteGuid,
                    teamGuids: athlete._groupsGuids.join(","),
                  }),
                })
              }
              text={athlete._athleteName}
              style={styles.athletesContainer}
              textStyle={styles.navButtonAthletesText}
              underlayColor={COLOR_ALMOST_BLACK}
            />
          ))}
        </AthletesWrapper>
      )}
    </>
  );
};

const styles: Record<string, CSSProperties> = {
  athletesContainer: {
    borderRadius: 6,
    paddingLeft: 20,
    paddingRight: 12,
    backgroundColor: COLOR_ALMOST_BLACK,
  },

  navButtonAthletesText: {
    fontSize: 12,
    color: COLOR_WHITE,
    fontWeight: "bold",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
