import React from "react";

import { useFormikContext } from "formik";

import { useDebounce } from "../hooks/useDebounce";

export function AutoSubmitFormikForm({
  isEnables,
  delay = 0,
}: {
  isEnables?: boolean;
  delay?: number;
}) {
  const { values, submitForm } = useFormikContext();
  const debauncedValue = useDebounce(values, delay);

  React.useEffect(() => {
    isEnables && submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debauncedValue]);

  return null;
}
