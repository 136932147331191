import React, { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
} from "../../colors";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  ChatterType,
  useGetTheGroupsImInListQuery,
  XpsUser,
} from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { useFetchUserProfile } from "../../services/hooks";
import { AthleteBaseInfo } from "../Athletes/AthleteBaseInfo";
import Avatar from "../Avatar";
import { IconButton } from "../IconButton";
import { CrossIcon } from "../Icons";
import StyledText from "../StyledText";

import { FamilyMember, ProfileList } from "./Components/ProfileList";
import { TeamsList } from "./Components/TeamsList";

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const HeaderContainer = styled.div<{ isLoggedUserTrainer: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  align-items: center;
  ${({ isLoggedUserTrainer }) =>
    isLoggedUserTrainer ? `width: 100%;` : `padding: 30px;`}
`;

const LabelContainer = styled.div`
  display: flex;
  margin-left: 30px;
  flex-direction: column;
`;

const Name = styled(StyledText)`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: ${COLOR_TEXT_DARK};
`;

const Type = styled(StyledText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_MEDIUM_GRAY};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_GRAY};
  padding: 20px 30px 0;
  overflow: auto;
  flex: 1;
`;

const CancelButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
`;

function getTranslatedAthleteTypes(
  type: string,
  t: TFunction<"common", undefined>
) {
  switch (type) {
    case ChatterType.Trainer:
      return t("trainer").toUpperCase();
    case ChatterType.Athlete:
      return t("athlete").toUpperCase();
    case ChatterType.Family:
      return t("familyMember").toUpperCase();
    default:
      return null;
  }
}

interface ProfileModalProps {
  currentAthlete?: XpsUser;
  currentGroupOwnerId: string;
  hideBody: boolean;
}

export const ProfileModal = ({
  currentAthlete,
  currentGroupOwnerId,
  hideBody,
}: ProfileModalProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const {
    sessionId,
    isFamilyMember,
    isTrainer: isLoggedUserTrainer,
  } = useUserContext();
  const navigate = useNavigate();

  const isTrainer = currentAthlete?.type === ChatterType.Trainer;

  const translatedType = getTranslatedAthleteTypes(currentAthlete?.type, t);

  const { data: userProfile, isLoading } = useFetchUserProfile(
    { session: { sessionId }, focusedAthleteGuid: currentAthlete.id },
    { enabled: !!currentAthlete.id }
  );

  const familyMembers: FamilyMember[] = useMemo(() => {
    return [
      ...((
        userProfile?.data?._family ||
        currentAthlete?.profile?.family?.membersOfFamily ||
        []
      ).map((fm) => ({ ...fm, id: fm?.userID })) as FamilyMember[]),
    ];
  }, [currentAthlete, userProfile]);

  const { data: userGroupsData } = useGetTheGroupsImInListQuery({
    variables: {
      sessionId,
      accountId: currentGroupOwnerId,
      includeGroupsWhereImAdmin: true,
      mustBeAccessibleBy: currentAthlete?.id,
    },
    skip: !currentGroupOwnerId || !currentAthlete?.id,
  });

  const onMessageClick = (id) => {
    navigate({
      pathname: `/${ROUTING_CONFIG.messages}`,
      search: `?id=${id}`,
    });
    modalActions.closeModal();
  };

  return (
    <ModalContentContainer>
      <HeaderContainer isLoggedUserTrainer={isLoggedUserTrainer}>
        {!isLoggedUserTrainer ? (
          <>
            <Avatar
              size={85}
              userName={currentAthlete?.name}
              uri={
                currentAthlete?.thumb ||
                currentAthlete?.profile?.profileImageUrl
              }
            />
            <LabelContainer>
              <Name>{currentAthlete?.name}</Name>
              <Type>{translatedType}</Type>
            </LabelContainer>
          </>
        ) : currentAthlete ? (
          <AthleteBaseInfo
            fullWidth
            id={currentAthlete.id}
            name={currentAthlete.name}
            profileImageUrl={
              currentAthlete?.thumb || currentAthlete?.profile?.profileImageUrl
            }
            email={currentAthlete?.profile?.emailAddress}
            isExpired={currentAthlete?.isExpired}
            city={currentAthlete?.profile?.city}
            birthDay={currentAthlete?.profile?.birthDay}
            firstName={currentAthlete?.firstName}
            lastName={currentAthlete?.lastName}
            phoneNumber={currentAthlete?.profile?.phoneNumber}
            isTrainer={isTrainer}
          />
        ) : null}
        <CancelButtonWrapper>
          <IconButton
            icon={
              <CrossIcon
                strokeWidth="1"
                tintColor={COLOR_TEXT_DARK}
                style={{ width: 32, height: 32 }}
              />
            }
            activeBgColor={COLOR_MEDIUM_GRAY}
            onClick={modalActions.closeModal}
          />
        </CancelButtonWrapper>
      </HeaderContainer>
      <BodyContainer>
        {!hideBody && (
          <>
            <ProfileList
              key="profile-list"
              isTrainer={isTrainer}
              isFamilyMember={isFamilyMember || isLoggedUserTrainer}
              currentAthlete={{
                ...currentAthlete,
                profile: {
                  ...currentAthlete.profile,
                  emailAddress: userProfile?.data?._emailAddress,
                },
              }}
              loading={isLoading}
              familyMembers={familyMembers}
              onMessageClick={onMessageClick}
            />
            {userGroupsData?.getTheGroupsImIn ? (
              <TeamsList
                key="teams-list"
                teams={userGroupsData.getTheGroupsImIn}
                loading={false}
                style={{ marginTop: 20, paddingBottom: 20 }}
              />
            ) : null}
          </>
        )}
      </BodyContainer>
    </ModalContentContainer>
  );
};
