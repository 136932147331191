import React from "react";

import styled from "styled-components/";

import {
  COLOR_BLUE,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import StyledText from "../StyledText";

import { Option } from "./AthleteSelect";

type AthleteRowProps = {
  data: Option;
  value: string;
  onChange: (value: string[] | string) => void;
};

const AthleteRowWrapper = styled(BasicButton)<{ isSelected?: boolean }>`
  width: 100%;
  padding: 10px;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
`;

const AthleteName = styled(StyledText)<{
  isSelected?: boolean;
  isExpired: boolean;
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({ isSelected, isExpired }) =>
    isSelected
      ? COLOR_WHITE
      : isExpired
        ? COLOR_SECONDARY_GREY
        : COLOR_TEXT_DARK};
`;

export function AthleteRow({ data, value, onChange }: AthleteRowProps) {
  const isSelected = value === data.id;

  const handleAthleteSelect = () => {
    if (isSelected) {
      return onChange("");
    }

    return onChange(data.id);
  };

  return (
    <AthleteRowWrapper onClick={handleAthleteSelect} isSelected={isSelected}>
      <Avatar
        size={25}
        style={{ marginRight: 10 }}
        source={{ uri: data.thumb as string }}
        userName={data.label}
      />
      <AthleteName isSelected={isSelected} isExpired={data.isExpired}>
        {data.name}
      </AthleteName>
    </AthleteRowWrapper>
  );
}
