import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_RED,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { BasicButton } from "../Button";
import StyledText from "../StyledText";

import { SecondLevelOptionsWrapperOrientation } from "./DropdownWrapper/SecondLevelOptionsWrapper";

const DROPDOWN_WIDTH = 170;

export const StyledOptionItem = styled(BasicButton)<{
  isHovered?: boolean;
  bgColor?: string;
  height?: number;
}>`
  display: flex;
  flex-direction: row;
  background-color: ${(props) =>
    props.isHovered ? props.bgColor || COLOR_GRAY : COLOR_WHITE};
  height: ${({ height }) => height || 35}px;
  align-items: center;
  padding-left: 10px;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.bgColor || COLOR_GRAY};
  }
`;

export const OptionItemText = styled(StyledText)<{ rightOriented?: boolean }>`
  font-weight: bold;
  font-size: 14px;
  margin-right: ${({ rightOriented }) => (rightOriented ? 10 : 0)}px;
  color: ${COLOR_TEXT_DEFAULT};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  line-height: 20px;
`;

export const DropdownOptionsWrapper = styled.div<{
  position?: "left" | "right";
}>`
  z-index: 100;
  position: absolute;
  ${({ position = "left" }) =>
    position === "left" ? "left: 50px" : "right: 0"};
  border-radius: 4px;
  top: 50px;
  width: ${DROPDOWN_WIDTH}px;
  background-color: ${COLOR_WHITE};
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
`;

export const NoDataForListingMessage = styled(StyledText)`
  font-weight: bold;
  font-size: 10px;
`;

export const ColumnNameText = styled(StyledText)`
  flex: 1;
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 10px;
`;

export const NameText = styled(ColumnNameText)`
  flex: 2;
`;

export const WidgetContainer = styled.div<{
  showBorders: boolean;
  withoutTopPadding?: boolean;
}>`
  background-color: ${COLOR_WHITE};
  border: ${(props) =>
    props.showBorders ? `0.5px solid ${COLOR_GREY_ACTIVE}` : "none"};
  border-radius: ${(props) => (props.showBorders ? "6px" : "")};
  padding: ${({ theme }) => (!theme.isMobile ? 15 : 10)}px 0 0;
  padding-top: ${({ theme, withoutTopPadding }) =>
    withoutTopPadding ? 0 : !theme.isMobile ? 15 : 10}px;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: auto;
`;

export const WidgetTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WidgetTitle = styled(StyledText)`
  font-weight: bold;
  font-size: 20px;
  padding-left: 30px;
  color: ${COLOR_TEXT_DEFAULT};
`;

export const FormWrapper = styled.div<{ shouldBeWider?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.shouldBeWider ? "750px" : "670px")};
  max-width: 70%;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 200px;
  overflow-y: scroll;
`;

export const FormContainer = styled.div<{
  hasLoadedAllTemplatesData?: boolean;
}>`
  display: ${({ hasLoadedAllTemplatesData = true }) =>
    hasLoadedAllTemplatesData ? "flex" : "none"};
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

export const FirstLevelOptionsContentWrapper = styled.div<{
  isHovered?: boolean;
}>`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  height: 35px;
  align-items: center;
  padding-left: 10px;
  background-color: ${({ isHovered }) =>
    isHovered ? COLOR_GRAY : COLOR_WHITE};
`;

export const SecondLevelOptionsContentWrapper = styled.div<{
  orientation: SecondLevelOptionsWrapperOrientation;
}>`
  position: absolute;
  ${({ orientation }) =>
    orientation === SecondLevelOptionsWrapperOrientation.LEFT
      ? `left: ${DROPDOWN_WIDTH - 2}px`
      : `right: ${DROPDOWN_WIDTH - 2}px`};
  border-radius: 4px;
  width: auto;
  max-width: 350px;
  background-color: ${COLOR_WHITE};
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
`;

export const FieldErrorMessage = styled(StyledText)`
  padding-top: 8px;
  font-weight: bold;
  color: ${COLOR_RED};
`;

export const MaxPerformersCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 130px;
`;

export const MobileViewGridLayout = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  margin-bottom: 40px;
`;
