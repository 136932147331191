import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import { COLOR_BLUE } from "../colors";
import { ButtonVariant, GeneralButton } from "../components/Button/";
import {
  LogoAcesNationNetworkIcon,
  LogoXpsNetworkIcon,
} from "../components/Icons";
import { Loader } from "../components/Loader";
import { ResetPasswordForm } from "../components/PasswordForm/ResetPasswordForm";
import {
  Description,
  FormWrapper,
  LogoWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "../components/PasswordForm/styles";
import { useNotificationsContext } from "../contexts/notifications";
import { changePasswordWithReset } from "../services/password";
import { isAcesNationEnv } from "../utils/isAcesNationEnv";

import {
  FormHeaderErrorText,
  LoadingMessage,
  LoadingWrapper,
  FormHeaderErrorWrapper,
} from "./LoginScreen/styles";

export function ResetPasswordPage() {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [isPassChanged, setPassChanged] = useState(false);
  const [isLoading] = useState(false);
  const { showSuccessNotification } = useNotificationsContext();
  const { guidOfReset } = useParams();

  const { mutateAsync: changePasswordWithResetMutation } = useMutation(
    ["changePassword", guidOfReset],
    (variables: { password: string; guidOfReset: string }) =>
      changePasswordWithReset(variables)
  );

  const { handleSubmit, reset, ...formMethods } = useForm<{
    newPassword: string;
    confirmPassword: string;
  }>({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    reValidateMode: "onSubmit",
  });

  const onSubmit: SubmitHandler<{
    newPassword: string;
    confirmPassword: string;
  }> = async (data) => {
    if (data.newPassword === data.confirmPassword) {
      try {
        await changePasswordWithResetMutation({
          password: data.newPassword,
          guidOfReset,
        });
        setPassChanged(true);
        showSuccessNotification(t("passwordChanged"));
      } catch (error) {
        setPassChanged(false);
        setError(error);
      }
    }

    reset();
  };

  const titleText = isPassChanged
    ? t("passwordChangeSuccessful")
    : t("resetPasswordTitle");

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loader size={31} color={COLOR_BLUE} />
        <LoadingMessage>{t("pleaseWait")}</LoadingMessage>
      </LoadingWrapper>
    );
  }

  return (
    <Wrapper>
      <FormWrapper>
        {error && (
          <FormHeaderErrorWrapper>
            <FormHeaderErrorText>{error}</FormHeaderErrorText>
          </FormHeaderErrorWrapper>
        )}
        <LogoWrapper>
          {isAcesNationEnv() ? (
            <LogoAcesNationNetworkIcon />
          ) : (
            <LogoXpsNetworkIcon />
          )}
        </LogoWrapper>
        <TitleWrapper>
          <Title>{titleText}</Title>
          {!isPassChanged && (
            <Description>{t("resetPasswordDescription")}</Description>
          )}
        </TitleWrapper>
        {!isPassChanged && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ResetPasswordForm formMethods={formMethods} />
            <GeneralButton
              fullWidth
              height={45}
              label={t("send")}
              onClick={handleSubmit(onSubmit)}
              type={ButtonVariant.Submit}
            />
          </form>
        )}
      </FormWrapper>
    </Wrapper>
  );
}
