import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_TABLE_DIVIDERS,
  COLOR_TEXT_DEFAULT,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../../colors";
import StyledText from "../StyledText";

interface IBorderedCellProps {
  index?: number;
  firstCell?: boolean;
  shouldDisplayLeftBorder?: boolean;
  shouldDisplayRightBorder?: boolean;
  shouldDisplayTopBorder?: boolean;
  shouldDisplayBottomBorder?: boolean;
}

export const BorderedCellTd = styled.td<
  IBorderedCellProps & { withBackground?: boolean; isTableBorderCell?: boolean }
>`
  height: 0;
  padding: 0;
  text-align: center;
  border-right-width: ${({ shouldDisplayRightBorder }) =>
    shouldDisplayRightBorder ? "1px" : ""};
  border-right-color: ${({ shouldDisplayRightBorder }) =>
    shouldDisplayRightBorder ? COLOR_TABLE_DIVIDERS : ""};
  border-right-style: ${({ shouldDisplayRightBorder }) =>
    shouldDisplayRightBorder ? "solid" : ""};
  border-bottom-width: ${({ shouldDisplayBottomBorder }) =>
    shouldDisplayBottomBorder ? "1px" : ""};
  border-bottom-color: ${({ shouldDisplayBottomBorder }) =>
    shouldDisplayBottomBorder ? COLOR_TABLE_DIVIDERS : ""};
  border-bottom-style: ${({ shouldDisplayBottomBorder }) =>
    shouldDisplayBottomBorder ? "solid" : ""};
  border-left-width: ${({ shouldDisplayLeftBorder }) =>
    shouldDisplayLeftBorder ? "1px" : ""};
  border-left-color: ${({ shouldDisplayLeftBorder }) =>
    shouldDisplayLeftBorder ? COLOR_TABLE_DIVIDERS : ""};
  border-left-style: ${({ shouldDisplayLeftBorder }) =>
    shouldDisplayLeftBorder ? "solid" : ""};
  border-top-width: ${({ shouldDisplayTopBorder }) =>
    shouldDisplayTopBorder ? "1px" : ""};
  border-top-color: ${({ shouldDisplayTopBorder }) =>
    shouldDisplayTopBorder ? COLOR_TABLE_DIVIDERS : ""};
  border-top-style: ${({ shouldDisplayTopBorder }) =>
    shouldDisplayTopBorder ? "solid" : ""};
  background-color: ${(props) =>
    props.withBackground ? COLOR_GRAY : "inherit"};
`;

export const HeaderCell = styled.div<{ withBackground?: boolean }>`
  position: relative;
  justify-content: center;
  text-align: center;
  color: ${COLOR_TEXT_TABLE_LABEL};
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 13px;
  background-color: ${(props) =>
    props.withBackground ? COLOR_GRAY : "transparent"};
  padding-top: 5px;
`;

export const ValueCellText = styled(StyledText)`
  color: ${COLOR_TEXT_DEFAULT};
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
`;

export const HeaderTitleTextWrapper = styled(StyledText)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_TEXT_TABLE_LABEL};
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  height: 16px;
  text-align: center;
  margin-bottom: 5px;
`;

export const StyledTableComponent = styled.table<{ paddingValue?: number }>`
  width: 100%;
  z-index: 10;
  max-width: 100%;
  border-spacing: 0;

  ${({ paddingValue, theme }) => {
    if (theme.isMobile) {
      return `
      & td:first-of-type > *,
      & .athlete-column-custom-padding-left > *:first-child {
        padding: 0 ${paddingValue}px;
      }
      & th:not(.athlete-column-custom-padding-left) > *:first-child {
        padding: 0 5px;
      }
      `;
    }
    return `
    & td > *,
    & th > *:first-child {
      padding: 0 ${paddingValue}px;
    }
    `;
  }}
`;

export const AthleteColumnTd = styled(BorderedCellTd)`
  left: 0;
  z-index: 10;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  line-height: 15px;
`;

export const AthleteColumnTh = styled.th<{ visible: boolean }>`
  left: 0;
  height: 100%;
  overflow: hidden;
  background-color: ${COLOR_WHITE};
  min-width: ${({ theme }) => (theme.isMobile ? 100 : 180)}px;
  z-index: 10;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export const ColorIndicator = styled.div<{ color: string }>`
  width: 13px;
  height: 13px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

export const SimpleHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const StyledTh = styled.th<{ withBackground?: boolean }>`
  background-color: ${(props) =>
    props.withBackground ? COLOR_GRAY : "transparent"};
  border: none;
`;

export const GroupTableHeaderCell = styled(HeaderCell)`
  margin-top: 6px;
`;
