// Buttons
export const COLOR_BUTTON_BLUE_HOVER = "#224DC4";
export const COLOR_BUTTON_BLUE = "#0073D9";
export const COLOR_BUTTON_BLUE_ACTIVE = "#1B3CAF";
export const COLOR_BUTTON_BLUE_TEXT = "#0073D9";
export const COLOR_BUTTON_BLUE_OUTLINE = "#A0BEEC";
export const COLOR_BUTTON_BLUE_OUTLINE_BG = "#ECF2FB";
export const COLOR_BUTTON_RED = "#FF6562";
export const COLOR_BUTTON_RED_ACTIVE = "#E01815";
export const COLOR_BUTTON_RED_HOVER = "#F04441";
export const COLOR_BUTTON_RED_TEXT = "#FF6562";
export const COLOR_BUTTON_RED_OUTLINE = "#FF9F9F";
export const COLOR_BUTTON_RED_OUTLINE_BG = "#FFECEC";
export const COLOR_BUTTON_GRAY = "#B1B7BC";
export const COLOR_BUTTON_GRAY_ACTIVE = "#64707A";
export const COLOR_BUTTON_GRAY_HOVER = "#8A939B";
export const COLOR_BUTTON_GRAY_TEXT = "#64707A";
export const COLOR_BUTTON_GRAY_OUTLINE = "#B1B7BC";
export const COLOR_BUTTON_GRAY_OUTLINE_BG = "#F5F6F8";
export const COLOR_BUTTON_GREEN = "#77CC00";
export const COLOR_BUTTON_GREEN_ACTIVE = "#378100";
export const COLOR_BUTTON_GREEN_HOVER = "#44A400";
export const COLOR_BUTTON_GREEN_TEXT = "#77CC00";
export const COLOR_BUTTON_GREEN_OUTLINE = "#BBE57F";
export const COLOR_BUTTON_GREEN_OUTLINE_BG = "#EBF7D9";
export const COLOR_BUTTON_YELLOW = "#F6A500";
export const COLOR_BUTTON_YELLOW_TEXT = "#F6A500";
export const COLOR_BUTTON_YELLOW_OUTLINE = "#FFCA0F";
export const COLOR_BUTTON_YELLOW_OUTLINE_BG = "#FEF2D9";
export const COLOR_BUTTON_LIGHT = "#F5F6F8";
export const COLOR_BUTTON_LIGHT_TEXT = "#64707A";
export const COLOR_BUTTON_LIGHT_ACTIVE = "#B1B7BC";
export const COLOR_BUTTON_LIGHT_HOVER = "#E6E8EB";
// Special Buttons
export const COLOR_BUTTON_SPECIAL_OUTLINE = "#E6E8EB";
export const COLOR_BUTTON_SPECIAL_OUTLINE_BG = "#FBFBFC";
// Add/Remove Buttons
export const COLOR_BUTTON_ADD_HOVER = "#EBF7D9";
export const COLOR_BUTTON_REMOVE_HOVER = "#FFECEC";
// Checkbox
export const COLOR_CHECKBOX_BLUE_HOVER = "#224DC4";
// Nav
export const COLOR_NAV_ITEM_HOVER = "#505963";
// General
export const COLOR_BLUE = "#0073D9";
export const COLOR_WHITE = "#FFFFFF";
export const COLOR_BLACK = "#000000";
// TODO add here all general ones

export const COLOR_PRIMARY_BLUE = "#2972D5";
export const COLOR_PURE_RED = "#FF0000";
export const COLOR_RED = "#ED6E67";
export const COLOR_RED_SECONDARY = "#FB4C48";
export const COLOR_TEXT_DARK = "#3C434C";
export const COLOR_LIGHT_BLUE = "#A0BEEC";
export const COLOR_DARK_BLUE = "#151F3B";
export const COLOR_BLUE_HOVER = "#2657CB";
export const COLOR_BLUE_SELECTED = "#1D44B8";
export const COLOR_LINK_BLUE = COLOR_BLUE;
export const COLOR_YELLOW_WARNING = "#FFC700";
export const COLOR_ORANGE = "#F6A500";
export const COLOR_ORANGE_GAME_DEFAULT = "#FB7F0C";
export const COLOR_YELLOW_LINK = "#D6DE23";

export const COLOR_SECONDARY_RED = "#FF8080";
export const COLOR_SECONDARY_GREY = "#B1B7BC";
export const COLOR_SECONDARY_GREY_HOVER = "#8A939B";
export const COLOR_SECONDARY_GREY_SELECTED = "#64707A";
export const COLOR_SECONDARY_MAGENTA = "#E745B9";

export const COLOR_GRAY = "#F5F6F8";
export const COLOR_GREEN = "#77CC00";
export const COLOR_GREEN_BORDER = "#BBE57F";
export const COLOR_GREEN_ICON = "#57B700";
export const COLOR_GREEN_HOVER = "#4DAE00";
export const COLOR_GREEN_ACTION_BUTTON_HOVER = "#EBF7D9";
export const COLOR_GREEN_SELECTED = "#3F8C00";
export const COLOR_GREEN_PRACTICE_DEFAULT = "#00C5AB";
export const COLOR_GREEN_EVENT_DEFAULT = "#8FCD9E";
export const COLOR_GREEN_BUTTON_HOVER = "#55aa00";

export const COLOR_CANCEL_BUTTON_GREY = COLOR_GRAY;
export const COLOR_DANGER_BUTTON_RED = COLOR_SECONDARY_RED;
export const COLOR_FINISH_BUTTON_BLUE = COLOR_BLUE;
export const COLOR_FINISH_BUTTON_BLUE_ACTIVE = "#255ACB";
export const COLOR_BUTTON_BORDER = "#e8e9ec";
export const COLOR_GRAY_BORDER = "#E7E7EB";
export const COLOR_GRAY_SEPARATOR = "#D9D9D9";

export const COLOR_GREEN_ACTIVE = "#35CC00";
export const COLOR_GREY_ACTIVE = "#E6E8EB";
export const COLOR_RED_ACTIVE = "#FF6562";
export const COLOR_YELLOW_ACTIVE = "#FCC400";

export const COLOR_SELECT_BORDER = "#E7E8EB";
export const COLOR_SELECT_BORDER_HOVER = "#D5D6DA";

export const COLOR_TEXT_DEFAULT = COLOR_TEXT_DARK;
export const COLOR_TEXT_TABLE_LABEL = "#8A939B";
export const COLOR_TABLE_DIVIDERS = "#B1B7BC";

export const COLOR_DAY_BACKGROUND = "#E3EDFC";

export const COLOR_MEDIUM_GRAY = "#64707A";

export const COLOR_LIGHT_GRAY = "#E5E5E5";
export const COLOR_SECONDARY_LIGHT_GRAY = "#eaeef6";
export const COLOR_LIGHT_GRAY_HOVER = "#E5E5E5";
export const COLOR_LIGHT_GRAY_SELECTED = "#E5E5E5";

export const COLOR_BLUE_ACTIVE = "#2B62D1";
export const COLOR_BLUE_PRESSED = "#1F4DC0";
export const COLOR_BLUE_SECONDARY = "#00B2FF";

export const COLOR_GREEN_PRESSED = "#489700";

export const COLOR_ALMOST_BLACK = "#2D3239";

export const COLOR_BLUE_ATTENDANCE_BG = "#ECF2FB";
export const COLOR_RED_ATTENDANCE_BG = "#FFECEC";
export const COLOR_DOLPHIN = "#67647A";

export const COLOR_BODY_PART = "#a9aca4";
export const COLOR_BODY_FILL = "#f0f2f1";
export const COLOR_BODY_OUTLINE = "#231F20";
export const COLOR_BODY_SECTION = "#979797";
export const COLOR_BODY_SECTION_FILL = "#D8D8D8";
