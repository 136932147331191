import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { TestStatistics } from "../../../../../services";
import { getDataKey } from "../../../../../utils/statistics";
import { Period } from "../../model";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const getStatisticsDataItemsForPeriods = (
  dataPeriod: Period,
  allStats: TestStatistics[]
) =>
  allStats.flatMap((stat) =>
    stat._attributes
      .flatMap((statAttribute) => {
        let min = statAttribute._min ?? Number.POSITIVE_INFINITY;
        let max = statAttribute._max ?? Number.NEGATIVE_INFINITY;

        // We need to calculate min and max for conditional coloring for measurements without min and max on server
        if (
          statAttribute._min === undefined ||
          statAttribute._max === undefined
        ) {
          statAttribute._values.forEach((value) => {
            if (value._val < min) {
              min = value._val;
            }

            if (value._val > max) {
              max = value._val;
            }
          });
        }

        return statAttribute._values.flatMap((attributeValue) => {
          const time =
            attributeValue._time ?? dayjs(dataPeriod.start).valueOf();
          return dayjs(time).isSameOrAfter(dataPeriod.start) &&
            dayjs(time).isSameOrBefore(dataPeriod.end)
            ? {
                id: getDataKey(stat._guid, statAttribute._guid, stat._agg),
                value: attributeValue._val,
                txt: attributeValue._txt,
                tifn: attributeValue.tifn,
                min,
                max,
                unitName: statAttribute._unitName,
                time,
              }
            : null;
        });
      })
      .filter((val) => Boolean(val))
      .sort((first, second) => (first.time > second.time ? -1 : 1))
  );
