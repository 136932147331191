import {
  COLOR_BUTTON_BLUE_ACTIVE,
  COLOR_BUTTON_BLUE_HOVER,
  COLOR_BUTTON_BLUE_OUTLINE_BG,
  COLOR_BUTTON_BLUE_OUTLINE,
  COLOR_BUTTON_BLUE_TEXT,
  COLOR_BUTTON_BLUE,
  COLOR_BUTTON_GRAY_ACTIVE,
  COLOR_BUTTON_GRAY_HOVER,
  COLOR_BUTTON_GRAY_OUTLINE_BG,
  COLOR_BUTTON_GRAY_OUTLINE,
  COLOR_BUTTON_GRAY_TEXT,
  COLOR_BUTTON_GRAY,
  COLOR_BUTTON_GREEN_ACTIVE,
  COLOR_BUTTON_GREEN_HOVER,
  COLOR_BUTTON_GREEN_OUTLINE_BG,
  COLOR_BUTTON_GREEN_OUTLINE,
  COLOR_BUTTON_GREEN_TEXT,
  COLOR_BUTTON_GREEN,
  COLOR_BUTTON_LIGHT_ACTIVE,
  COLOR_BUTTON_LIGHT_HOVER,
  COLOR_BUTTON_LIGHT_TEXT,
  COLOR_BUTTON_LIGHT,
  COLOR_BUTTON_RED_ACTIVE,
  COLOR_BUTTON_RED_HOVER,
  COLOR_BUTTON_RED_OUTLINE_BG,
  COLOR_BUTTON_RED_OUTLINE,
  COLOR_BUTTON_RED_TEXT,
  COLOR_BUTTON_RED,
  COLOR_BUTTON_YELLOW_OUTLINE_BG,
  COLOR_BUTTON_YELLOW_OUTLINE,
  COLOR_BUTTON_YELLOW_TEXT,
  COLOR_BUTTON_YELLOW,
} from "../../colors";

import { ButtonType, ButtonColor } from "./types";

export function getButtonDimension(type: ButtonType) {
  switch (type) {
    case ButtonType.Tiny:
      return {
        borderRadius: "3px",
        fontSize: "10px",
        height: "25px",
        padding: "0 15px",
      };
    case ButtonType.Secondary:
      return {
        borderRadius: "3px",
        fontSize: "12px",
        height: "29px",
        padding: "0 20px",
      };
    case ButtonType.Primary:
    default:
      return {
        borderRadius: "6px",
        fontSize: "12px",
        height: "35px",
        padding: "0 25px",
      };
  }
}

export function getButtonColor(variant: ButtonColor) {
  switch (variant) {
    case ButtonColor.Yellow:
      return {
        bgColorOutline: COLOR_BUTTON_YELLOW_OUTLINE_BG,
        color: COLOR_BUTTON_YELLOW,
        colorOutline: COLOR_BUTTON_YELLOW_OUTLINE,
        textColor: COLOR_BUTTON_YELLOW_TEXT,
      };
    case ButtonColor.Red:
      return {
        bgColorOutline: COLOR_BUTTON_RED_OUTLINE_BG,
        color: COLOR_BUTTON_RED,
        colorOutline: COLOR_BUTTON_RED_OUTLINE,
        colorHover: COLOR_BUTTON_RED_HOVER,
        colorActive: COLOR_BUTTON_RED_ACTIVE,
        textColor: COLOR_BUTTON_RED_TEXT,
      };
    case ButtonColor.Gray:
      return {
        bgColorOutline: COLOR_BUTTON_GRAY_OUTLINE_BG,
        color: COLOR_BUTTON_GRAY,
        colorOutline: COLOR_BUTTON_GRAY_OUTLINE,
        colorHover: COLOR_BUTTON_GRAY_HOVER,
        colorActive: COLOR_BUTTON_GRAY_ACTIVE,
        textColor: COLOR_BUTTON_GRAY_TEXT,
      };
    case ButtonColor.Light:
      return {
        color: COLOR_BUTTON_LIGHT,
        colorHover: COLOR_BUTTON_LIGHT_HOVER,
        colorActive: COLOR_BUTTON_LIGHT_ACTIVE,
        textColor: COLOR_BUTTON_LIGHT_TEXT,
      };
    case ButtonColor.Blue:
      return {
        bgColorOutline: COLOR_BUTTON_BLUE_OUTLINE_BG,
        color: COLOR_BUTTON_BLUE,
        colorOutline: COLOR_BUTTON_BLUE_OUTLINE,
        colorHover: COLOR_BUTTON_BLUE_HOVER,
        colorActive: COLOR_BUTTON_BLUE_ACTIVE,
        textColor: COLOR_BUTTON_BLUE_TEXT,
      };
    case ButtonColor.Green:
    default:
      return {
        bgColorOutline: COLOR_BUTTON_GREEN_OUTLINE_BG,
        color: COLOR_BUTTON_GREEN,
        colorOutline: COLOR_BUTTON_GREEN_OUTLINE,
        colorHover: COLOR_BUTTON_GREEN_HOVER,
        colorActive: COLOR_BUTTON_GREEN_ACTIVE,
        textColor: COLOR_BUTTON_GREEN_TEXT,
      };
  }
}
