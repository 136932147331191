import * as api from "../api";
import * as endpoints from "../endpoints";

export type InvitationsQueryArgs = {
  session: api.Session;
};

export type InvitationsQueryResponse = {
  _invitations: {
    _guid: string;
    _title: string;
    _message: string;
  }[];
};

export const fetchInvitations = async ({ session }: InvitationsQueryArgs) => {
  const response = await api.post<InvitationsQueryResponse>(
    endpoints.JsonInvitationQuery,
    {
      _onlyTrainerToAthlete: false,
    },
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
};

export type AnswerInvitationMutationArgs = {
  session: api.Session;
  guid: string;
  isAccepted: boolean;
};

export const answerInvitation = async ({
  session,
  isAccepted,
  guid,
}: AnswerInvitationMutationArgs) => {
  const response = await api.post(
    endpoints.JsonInvitationDecision,
    {
      _guid: guid,
      _accepted: isAccepted,
    },
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
};
