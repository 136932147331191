import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { ButtonColor, GeneralButton } from "../Button/";
import { Loader } from "../Loader";

interface PreviewButtonSectionProps {
  onSaveClick: () => void;
  onCancelClick: () => void;
  isSaving: boolean;
  children: ReactNode;
  withoutPadding?: boolean;
  withAutoWidth?: boolean;
}

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_GRAY};
  max-width: 50%;
`;

const PreviewWrapper = styled.div<{
  withoutPadding: boolean;
  withAutoWidth: boolean;
}>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: ${(props) => (props.withAutoWidth ? "auto" : "80%")};
  max-width: 80%;
  margin: 30px;
  background-color: ${COLOR_WHITE};
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  border-width: 0.5px;
  border-radius: 6px;
  padding: ${(props) => (props.withoutPadding ? 0 : 20)}px;
  min-height: 150px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  gap: 15px;
`;

const Spacer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const PreviewButtonSection = ({
  isSaving,
  children,
  withoutPadding = false,
  withAutoWidth = false,
  onSaveClick,
  onCancelClick,
}: PreviewButtonSectionProps) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <Spacer />

      <PreviewWrapper
        withoutPadding={withoutPadding}
        withAutoWidth={withAutoWidth}
      >
        {children}
      </PreviewWrapper>

      <Spacer />

      <ButtonsWrapper>
        {isSaving ? (
          <Loader color={COLOR_BLUE} size="small" />
        ) : (
          <GeneralButton label={t("saveAndClose")} onClick={onSaveClick} />
        )}
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={onCancelClick}
        />
      </ButtonsWrapper>
    </SectionWrapper>
  );
};
