import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_WHITE } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import {
  Cell,
  useExerciseSetsQuery,
  useUpdateAttributeMutation,
} from "../../../graphql";
import { endsWithPunctuation } from "../../SessionPlan/Workout/utils";
import { WorkoutTable } from "../../SessionPlan/Workout/WorkoutTable";

const WorkoutWrapper = styled.div`
  background-color: ${COLOR_WHITE};
  display: flex;
  padding: 7px 7px 7px 15px;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
`;

const WorkoutName = styled.p`
  color: ${COLOR_TEXT_DARK};
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  margin: 12px 0;
`;

export const WorkoutLine = ({ workoutLine }) => {
  const { sessionId, language, timezone } = useUserContext();
  const [updateAttributeMutation] = useUpdateAttributeMutation();

  const { data: exerciseData } = useExerciseSetsQuery({
    variables: {
      sessionId,
      language,
      timezone,
      id: workoutLine?.id,
    },
    skip: !workoutLine?.id,
  });

  const onCellUpdated = async (cell: Cell, value: string) => {
    if (endsWithPunctuation(value)) {
      return;
    }

    await updateAttributeMutation({
      variables: {
        sessionId,
        language,
        timezone,
        input: {
          id: cell.id,
          value,
        },
      },
      optimisticResponse: {
        updateAttribute: {
          ...cell,
          valuePerformed: value,
        },
      },
    });
  };

  const isInlineComment = workoutLine?.__typename === "InlineComment";

  return (
    <WorkoutWrapper>
      <WorkoutName>
        {isInlineComment ? workoutLine.txt : workoutLine.name}
      </WorkoutName>
      {!isInlineComment ? (
        <WorkoutTable
          exerciseData={workoutLine}
          sets={exerciseData?.exercise?.sets}
          onCellUpdated={onCellUpdated}
        />
      ) : null}
    </WorkoutWrapper>
  );
};
