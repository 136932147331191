import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { COLOR_GRAY } from "../../colors";
import { ControlledInput } from "../HookForm/ControlledInput";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export function ForgotPasswordForm({ formMethods }) {
  const { t } = useTranslation();

  return (
    <FormProvider {...formMethods}>
      <ControlledInput
        labelColor={COLOR_GRAY}
        name="email"
        placeholder={t("forgotPasswordPlaceholder")}
        label={t("email")}
        rules={{
          required: t("emailRequired"),
          pattern: {
            value: EMAIL_REGEX,
            message: t("invalidEmail"),
          },
        }}
      />
    </FormProvider>
  );
}
