import React from "react";

import styled from "styled-components";

import { VideoType } from "./model";
import { VideoItem } from "./VideoItem";

type VideoModuleProps = {
  data: VideoType[];
  type: string;
};

const Wrapper = styled.div`
  display: flex;
  margin-right: 20px;
  flex-direction: column;
`;

export function VideoModule({ data, type }: VideoModuleProps) {
  if (!data.length) {
    return null;
  }

  return (
    <Wrapper>
      {data.map((video, index) => (
        <VideoItem key={`${video.url}-${index}`} video={video} type={type} />
      ))}
    </Wrapper>
  );
}
