import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { COLOR_WHITE } from "../../colors";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import {
  CalendarDottedIcon,
  FolderFullIcon,
  HomeIcon,
  MonitoringIcon,
  PulseHeardIcon,
  ReportIcon,
  ResourcesIcon,
  UserGroupIcon,
} from "../Icons";

import { BaseNavSideWrapper } from "./BaseNavSideWrapper";
import { useTrainerSectionsAvailability } from "./hooks/useTrainerSectionsAvailability";
import { NavIcon } from "./NavIcon";
import { MessagesNavSideSection } from "./Sections/MessagesNavSideSection";

import { SectionTitleText } from "./index";

interface TrainerNavSideProps {
  isCompact: boolean;
}

export const TrainerNavSide = ({ isCompact }: TrainerNavSideProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const {
    canSeeMonitoringModule,
    canSeeResourceCalendarModule,
    canSeeTrainingStatusModule,
  } = useTrainerSectionsAvailability();

  const onMyXPSPress = () => navigate(`/${ROUTING_CONFIG.myXPS}`);
  const onAgendaPress = () => navigate(`/${ROUTING_CONFIG.calendar}`);
  const onTeamsPress = () => navigate(`/${ROUTING_CONFIG.teams}`);
  const onTrainingStatusPress = () =>
    navigate(`/${ROUTING_CONFIG.trainingStatus}`);
  const onResourceCalendarPress = () =>
    navigate(`/${ROUTING_CONFIG.ResourceCalendar}`);
  const onMonitoringPress = () => navigate(`/${ROUTING_CONFIG.AthleteProfile}`);
  const onReportsPress = () => navigate(`/${ROUTING_CONFIG.ListReports}`);
  const onCollectionsPress = () => navigate(`/${ROUTING_CONFIG.collections}`);

  return (
    <BaseNavSideWrapper isCompact={isCompact}>
      {!isAcesNationEnv() && (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
          iconSvg={
            <HomeIcon
              isActive={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
            />
          }
          isCompact={isCompact}
          text={t("myXPS")}
          onClick={onMyXPSPress}
        />
      )}

      <NavIcon
        active={Boolean(matchPath(ROUTING_CONFIG.teams, pathname))}
        iconSvg={
          <UserGroupIcon
            tintColor={COLOR_WHITE}
            isActive={Boolean(matchPath(ROUTING_CONFIG.teams, pathname))}
          />
        }
        isCompact={isCompact}
        text={t("teams")}
        onClick={onTeamsPress}
      />

      <MessagesNavSideSection isCompact={isCompact} />

      {!isCompact && (
        <SectionTitleText>{t("workspace").toUpperCase()}</SectionTitleText>
      )}

      <NavIcon
        active={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
        iconSvg={
          <CalendarDottedIcon
            isActive={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
          />
        }
        isCompact={isCompact}
        text={t("calendar")}
        onClick={onAgendaPress}
      />

      {canSeeResourceCalendarModule ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.ResourceCalendar, pathname))}
          iconSvg={
            <ResourcesIcon
              isActive={Boolean(
                matchPath(ROUTING_CONFIG.ResourceCalendar, pathname)
              )}
            />
          }
          isCompact={isCompact}
          text={t("resources")}
          onClick={onResourceCalendarPress}
        />
      ) : null}

      {canSeeMonitoringModule ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.AthleteProfile, pathname))}
          iconSvg={
            <MonitoringIcon
              isActive={Boolean(
                matchPath(ROUTING_CONFIG.AthleteProfile, pathname)
              )}
            />
          }
          isCompact={isCompact}
          text={t("monitoring")}
          onClick={onMonitoringPress}
        />
      ) : null}

      {canSeeTrainingStatusModule ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.trainingStatus, pathname))}
          iconSvg={
            <PulseHeardIcon
              isActive={Boolean(
                matchPath(ROUTING_CONFIG.trainingStatus, pathname)
              )}
            />
          }
          isCompact={isCompact}
          text={t("trainingStatus")}
          onClick={onTrainingStatusPress}
        />
      ) : null}

      {!isCompact && (
        <SectionTitleText>{t("library").toUpperCase()}</SectionTitleText>
      )}
      <NavIcon
        active={Boolean(matchPath(ROUTING_CONFIG.collections, pathname))}
        iconSvg={
          <FolderFullIcon
            isActive={Boolean(matchPath(ROUTING_CONFIG.collections, pathname))}
          />
        }
        isCompact={isCompact}
        text={t("collections")}
        onClick={onCollectionsPress}
      />
      {canSeeMonitoringModule ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.ListReports, pathname))}
          iconSvg={
            <ReportIcon
              tintColor={COLOR_WHITE}
              isActive={Boolean(
                matchPath(ROUTING_CONFIG.ListReports, pathname)
              )}
            />
          }
          isCompact={isCompact}
          text={t("reports")}
          onClick={onReportsPress}
        />
      ) : null}
    </BaseNavSideWrapper>
  );
};
