import React, { useMemo, useState } from "react";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { AggregateFunction } from "../../graphql";
import { getDummyValue } from "../../utils/dummyData";
import {
  isAttendanceCollection,
  isInformationCollection,
} from "../../utils/reports";
import { ITableRowModel } from "../ReportTableWidget/AthleteTable/model";
import { Table } from "../ReportTableWidget/AthleteTable/Table";

import { CreateAthleteTableFormValue } from "./models";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  max-height: 60vh;
`;

export const TablePreview = () => {
  const [dummyValues, setDummyValue] = useState<
    Map<string, { agg: string; value: string }[]>
  >(new Map<string, { agg: string; value: string }[]>());
  const { values } = useFormikContext<CreateAthleteTableFormValue>();

  const tableRows = useMemo(
    () =>
      values.rows.map<ITableRowModel>((row, index) => {
        const rowKey = `${row.collectionId}-${row.measurementTemplateId}-${row.textOptions?.length}-${values.showValuesAsText}`;
        const alreadyGeneratedDummyCells = dummyValues.get(rowKey) || [];

        const generateNewDummyValue = (agg: AggregateFunction) => {
          const value = getDummyValue(
            row.attributeTemplateName,
            isInformationCollection(row.collectionId),
            agg,
            row.textOptions,
            values.showValuesAsText,
            row.measurementTemplateId
          ).toString();

          setDummyValue(
            new Map(
              dummyValues.set(rowKey, [
                ...alreadyGeneratedDummyCells,
                { agg, value },
              ])
            )
          );

          return value;
        };

        return {
          measurementTemplateId: row.measurementTemplateId,
          attributeTemplateName: row.attributeTemplateName,
          attributeTemplateUnitName: row.attributeTemplateUnitName,
          attributeTemplateId: row.attributeTemplateId,
          label: row.measurementTemplateName,
          cells: values.columns.map((col) => {
            if (
              isAttendanceCollection(row.collectionId) &&
              col !== AggregateFunction.Latest
            ) {
              return { txt: "-" };
            }

            const rowValue =
              alreadyGeneratedDummyCells.find(
                (cellValue) => cellValue.agg === col
              )?.value || generateNewDummyValue(col);

            return {
              txt:
                row.textOptions?.length && values.showValuesAsText
                  ? rowValue
                  : null,
              value: rowValue,
            };
          }),
          key: `${row.measurementTemplateId}:${row.measurementTemplateName}-${index}`,
        };
      }),
    [values.rows, values.columns, dummyValues, values.showValuesAsText]
  );

  if (
    !values.title &&
    values.columns.length === 0 &&
    values.rows.length === 0
  ) {
    return null;
  }

  return (
    <TableWrapper>
      <Table
        id="table-preview"
        title={values.title}
        colNames={values.columns}
        rows={tableRows}
        rules={values.rules}
        alternatingRow={values.alternatingValues}
        alternatingColumns={values.alternatingColumns}
        rowDividers={values.rowDividers}
        columnDividers={values.columnDividers}
        outsideBorderDividers={values.outsideBorderDividers}
        widgetBorders={values.widgetBorders}
        sortBy={values.sortBy}
        sortingOrder={values.sortingOrder as any}
        showValuesAsText={values.showValuesAsText}
      />
    </TableWrapper>
  );
};
