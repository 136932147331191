import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { useUserContext } from "../../../contexts/User";
import { useGetTheGroupsImInListQuery } from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { getSearchParamsObject } from "../../../utils/getSearchParamsObject";
import { ButtonColor, GeneralButton } from "../../Button/";
import Dropdown from "../../Dropdown";
import { HoverableItem } from "../../HoverableItem";

const ButtonWrapper = styled.div`
  width: fit-content;
  margin-right: 12px;
`;

export const ApplyTabsFrom = () => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const [searchParams, setParams] = useSearchParams();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { data: { getTheGroupsImIn: myGroups } = {} } =
    useGetTheGroupsImInListQuery({
      variables: {
        sessionId,
        requireEditability: true,
        language,
        accountId: selectedAccountId,
      },
      fetchPolicy: "cache-first",
      skip: !sessionId,
    });

  return (
    <Dropdown
      dropdownContainerStyle={{
        width: 225,
        maxHeight: 300,
        marginRight: 12,
        marginTop: 35,
        padding: 0,
        overflow: "auto",
        left: 0,
        boxShadow: "0px 2px 6px rgba(34, 62, 106, 0.05)",
      }}
      dropdownContentStyle={{
        padding: "5px 0",
        top: 0,
      }}
      component={({ toggle }) => (
        <ButtonWrapper>
          <GeneralButton
            label={t("applyFromAnotherTeam")}
            colorVariant={ButtonColor.Gray}
            outlined
            onClick={toggle}
          />
        </ButtonWrapper>
      )}
    >
      {({ toggle }) => (
        <>
          {(myGroups || []).map((group) => (
            <HoverableItem
              key={group.id}
              label={group.name}
              onClick={() => {
                setParams({
                  ...getSearchParamsObject(searchParams),
                  groupId: group.id,
                });
                toggle((old) => !old);
              }}
              containerStyle={{
                display: "block",
                alignContent: "center",
              }}
            />
          ))}
        </>
      )}
    </Dropdown>
  );
};
