import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../../contexts/accessLevels";
import { useSelectedPerformersContext } from "../../../contexts/selectedAthletes";
import { Group } from "../../../graphql";
import StyledText from "../../StyledText";
import { Team } from "../Components/Team";

import { TabContainer } from "./TabContainer";

export const AthletesTab = ({
  search,
  groups,
  loading,
}: {
  search: string;
  groups: Pick<Group, "athletes" | "requiredAthletes" | "name" | "owner">[];
  loading: boolean;
}) => {
  const { t } = useTranslation();

  const { attendingUsers, setAttendingUsers } = useSelectedPerformersContext();
  const { selectedAccount } = useAccessLevelsContext();

  const groupedUsers = useMemo(() => {
    return groups?.reduce((acc, item) => {
      if (item.requiredAthletes.length > 0) {
        const groupName = `${
          selectedAccount?.id === ALL_ACCOUNTS_ID && item.owner
            ? `${item.owner.firstName} ${item.owner.lastName} - `
            : ""
        }${
          item.name === "all" ? t("outsideOfTeams") : item.name.toUpperCase()
        }`;
        acc[groupName] = {
          owner: item.owner,
          value: item.requiredAthletes,
          name: item.name,
        };
      }
      return acc;
    }, {});
  }, [groups, selectedAccount?.id, t]);

  const groupsCount = useMemo(
    () => Object.keys(groupedUsers || {}).length,
    [groupedUsers]
  );

  return (
    <TabContainer loading={loading}>
      <>
        {!loading &&
          Object.keys(groupedUsers)
            .sort()
            .map((gu, index) => (
              <Team
                key={`team-${index}`}
                isCreateGroupChat
                setAttendingUsers={setAttendingUsers}
                search={search}
                onClick={async (user, isAttending) => {
                  let currentAttendingUsers = [];
                  if (isAttending) {
                    currentAttendingUsers = attendingUsers.filter(
                      (au) => au.id !== user.id
                    );
                  } else {
                    currentAttendingUsers = attendingUsers.slice();
                    currentAttendingUsers.push(user);
                  }
                  setAttendingUsers(currentAttendingUsers);
                }}
                currentKey={gu}
                groupsCount={groupsCount}
                groups={groupedUsers}
                attendingUsers={attendingUsers}
                index={index}
                showAttendingImages
              />
            ))}
        {!loading && Object.keys(groupedUsers).length === 0 && (
          <StyledText
            style={{ textAlign: "center" }}
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
          >
            {t("noOne")}
          </StyledText>
        )}
      </>
    </TabContainer>
  );
};
