import React, { useMemo } from "react";

import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import {
  AC_ACUTE_ATTRIBUTE_ID,
  AC_CHRONIC_ATTRIBUTE_ID,
  AC_LOAD_ATTRIBUTE_ID,
  AC_RATIO_ATTRIBUTE_ID,
} from "../../../../services/statistics";
import { useAcuteChronicsStatisticsQuery } from "../../../../services/statistics/hooks";
import { AcuteChronicWidgetModel } from "../../../ReportCreateTable/models";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import { WidgetLoader } from "../../WidgetLoader";

import { AcuteChronicWidgetChart, AcuteChronicWidgetChartProps } from "./Chart";

interface AcuteChronicWidgetProps {
  widget: AcuteChronicWidgetModel;
}

export const AcuteChronicWidget = ({ widget }: AcuteChronicWidgetProps) => {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete }] = useReportsContext();

  const { fromDate, toDate } = useReportPeriod(widget.period);

  const {
    data: acuteChronicsStatisticsResponse,
    isLoading: acuteChronicsStatisticsIsLoading,
    fetchStatus: acuteChronicsStatisticsFetchStatus,
  } = useAcuteChronicsStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: selectedAthlete?.id,
      },
      fromDate,
      toDate,
      participants: [selectedAthlete],
      items: [
        {
          templateId: widget?.templateId,
          includeAC: true,
          includeAcute: widget?.showAcuteAverageLine,
          includeChronic: widget?.showChronicAverageLine,
          includeLoad: widget?.showBarsForDailyLoad,
        },
      ],
    },
    {
      enabled: Boolean(sessionId && selectedAthlete?.id),
      isDataEqual(oldData, newData) {
        return JSON.stringify(oldData?.data) === JSON.stringify(newData?.data);
      },
    }
  );

  const data = useMemo<AcuteChronicWidgetChartProps["data"]>(() => {
    const statsData =
      acuteChronicsStatisticsResponse?.data?._athletes?.[0]?._acuteChronics?.[0]
        ?._attributes ?? [];
    const attrToPropMap = {
      [AC_RATIO_ATTRIBUTE_ID]: "acRatio",
      [AC_ACUTE_ATTRIBUTE_ID]: "acuteLine",
      [AC_CHRONIC_ATTRIBUTE_ID]: "chronicLine",
      [AC_LOAD_ATTRIBUTE_ID]: "dailyBars",
    };

    return statsData.reduce<AcuteChronicWidgetChartProps["data"]>(
      (data, attr) => ({
        ...data,
        [attrToPropMap[attr._guid]]: (attr._values ?? [])
          .map((value) => ({ t: value._time, y: value._val }))
          .sort((p1, p2) => p1.t - p2.t),
      }),
      { acRatio: [] }
    );
  }, [acuteChronicsStatisticsResponse]);

  if (
    acuteChronicsStatisticsFetchStatus !== "idle" &&
    acuteChronicsStatisticsIsLoading
  ) {
    return <WidgetLoader />;
  }

  return <AcuteChronicWidgetChart data={data} widget={widget} />;
};
