import { useLayoutEffect } from "react";

import _round from "lodash/round";
import { useTheme } from "styled-components";

import { WidgetType } from "../../components/ReportCreateTable/models";
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_VERTICAL_ROW_MARGIN,
} from "../../constants/ReportGrid";

import { IWidgetResizeProps } from "./model";

interface IUseBottomWidgetResizeProps extends IWidgetResizeProps {
  id: string;
  shouldResize: boolean;
  widgetHeight: number;
  alwaysResize?: boolean;
}

export const useBottomWidgetResize = ({
  id,
  shouldResize,
  gridItemId,
  widgetHeight,
  layoutRef,
  alwaysResize,
  setItemsToHideInViewMode,
  setItemToResize,
}: IUseBottomWidgetResizeProps) => {
  const { isMobile } = useTheme();

  useLayoutEffect(() => {
    if (shouldResize && !isMobile && layoutRef.current) {
      const allLayoutItems = Array.from(layoutRef.current.childNodes);

      const gridItem = allLayoutItems.find(
        (item: any) => item.id === gridItemId
      ) as Element;
      const gridItemWidgets = allLayoutItems.filter(
        (widget: any) => widget.id !== gridItemId
      );

      const widgetsBelowTable = gridItemWidgets.filter(
        (widget: any) =>
          widget.getBoundingClientRect().top >
          gridItem.getBoundingClientRect().bottom
      );

      const isBottomWidget =
        widgetsBelowTable.length === 0 ||
        widgetsBelowTable.every((widget: any) =>
          widget.dataset?.id?.includes(WidgetType.PLACEHOLDER)
        );

      // We will probably extend this functionality in the future so for now it is possible to enforce
      // resize with alwaysResize or work only for bottom widgets
      const shouldUpdateHeight = alwaysResize || isBottomWidget;

      if (shouldUpdateHeight) {
        if (isBottomWidget && widgetsBelowTable.length > 0) {
          setItemsToHideInViewMode(
            widgetsBelowTable.map((widget: any) => widget.dataset?.id)
          );
        }

        if (gridItem.getBoundingClientRect().height < widgetHeight) {
          const rowDividerCount =
            _round(
              widgetHeight / (DEFAULT_ROW_HEIGHT + DEFAULT_VERTICAL_ROW_MARGIN)
            ) - 1;
          const widgetHeightWithoutRowMargins =
            widgetHeight - rowDividerCount * DEFAULT_VERTICAL_ROW_MARGIN;
          setItemToResize({
            itemId: id,
            scrollHeight: widgetHeightWithoutRowMargins,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    alwaysResize,
    shouldResize,
    gridItemId,
    widgetHeight,
    isMobile,
    layoutRef,
  ]);
};
