import React, { memo, useMemo, useState } from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { SummaryStatsAlignment } from "../../Report/PeriodizationTable/model";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetTitleContainer,
} from "../../Report/styled";
import {
  AthleteTablePeriodizationWidget,
  TablePeriodizationHeader,
  TablePeriodizationHeaderVertical,
  TableSummarySection,
  getGrouppedColumnHeader,
} from "../../TablePeriodization/Widget";

import { AthleteTablePeriodizationRow } from "./TableRow";
import {
  athleteFilterColumnsWithNoData,
  sortEmptyValues,
  sortNumericTableRows,
  sortTextualTableRows,
} from "./utils";

export type Data = {
  label: string;
  data: {
    id: string;
    value: string | number;
    txt?: string;
    min?: number;
    max?: number;
    unitName?: string;
    time?: number;
  }[][];
};

type AthleteTablePeriodizationWidgetTableProps = Pick<
  AthleteTablePeriodizationWidget,
  | "coloringConfig"
  | "columnHeaders"
  | "timeLine"
  | "showSummaryStats"
  | "summaryStatsAlignment"
  | "verticalHeader"
  | "showValuesAsText"
  | "ignoreColumnsWithNoData"
> & {
  title: string;
  data: Data[];
  tableRef?: any;
  statsData?: {
    id: string;
    average: string | number;
    stdDev: string | number;
  }[];
};

const StyledTableComponent = styled.table<{ paddingValue?: number }>`
  width: 100%;
  z-index: 10;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid ${COLOR_TEXT_DARK};
  }
`;

const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

export type SortByType = {
  colIndex?: number;
  order: number;
};

export const AthleteTablePeriodizationWidgetTable = memo(
  ({
    tableRef,
    title,
    columnHeaders,
    coloringConfig,
    data,
    timeLine,
    showSummaryStats,
    verticalHeader,
    showValuesAsText,
    statsData = [],
    summaryStatsAlignment,
    ignoreColumnsWithNoData,
  }: AthleteTablePeriodizationWidgetTableProps) => {
    const grouppedColumnHeaders = getGrouppedColumnHeader(columnHeaders);

    const [sortByConfig, setSortByConfig] = useState<SortByType>({
      colIndex: null,
      order: -1,
    });

    const onHeaderTitlePress = (colIndex?: number) => {
      const newOrder =
        sortByConfig.colIndex === colIndex ? -1 * sortByConfig.order : -1;
      setSortByConfig({ order: newOrder, colIndex });
    };

    const rowsData = useMemo(() => {
      if (ignoreColumnsWithNoData) {
        return athleteFilterColumnsWithNoData(data);
      }
      if (sortByConfig.colIndex === null || data.length === 0) {
        return data;
      }

      return [
        ...sortNumericTableRows(data, sortByConfig),
        ...sortTextualTableRows(data, sortByConfig),
        ...sortEmptyValues(data, sortByConfig),
      ];
    }, [data, sortByConfig, ignoreColumnsWithNoData]);

    return (
      <TableContainer>
        <WidgetContainer showBorders={false}>
          <div ref={tableRef}>
            <WidgetTitleContainer style={{ paddingBottom: 25, paddingTop: 5 }}>
              <WidgetTitle>{title}</WidgetTitle>
            </WidgetTitleContainer>

            <div
              style={{
                width: "100%",
                overflow: "auto",
              }}
            >
              <StyledTableComponent>
                {showSummaryStats &&
                  summaryStatsAlignment === SummaryStatsAlignment.TOP && (
                    <TableSummarySection statsData={statsData} isAboveTable />
                  )}

                <thead>
                  {verticalHeader ? (
                    <TablePeriodizationHeaderVertical
                      grouppedColumnHeaders={grouppedColumnHeaders}
                      timeLine={timeLine}
                      sortByConfig={sortByConfig}
                      onHeaderTitlePress={onHeaderTitlePress}
                    />
                  ) : (
                    <TablePeriodizationHeader
                      grouppedColumnHeaders={grouppedColumnHeaders}
                      timeLine={timeLine}
                      sortByConfig={sortByConfig}
                      onHeaderTitlePress={onHeaderTitlePress}
                    />
                  )}
                </thead>

                <tbody>
                  {rowsData.map((periodData, index) => (
                    <AthleteTablePeriodizationRow
                      key={`${periodData.label}-${index}`}
                      periodData={periodData}
                      showValuesAsText={showValuesAsText}
                      coloringConfig={coloringConfig}
                      columnHeaders={columnHeaders}
                    />
                  ))}
                </tbody>

                {showSummaryStats &&
                  summaryStatsAlignment === SummaryStatsAlignment.BOTTOM && (
                    <TableSummarySection statsData={statsData} />
                  )}
              </StyledTableComponent>
            </div>
          </div>
        </WidgetContainer>
      </TableContainer>
    );
  }
);
