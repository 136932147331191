import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const HideIcon = memo(
  ({ width = 18, height = 18, color = "#64707A", ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50702 15.8619L19.507 5.86193L19 5L15.0271 7.337C13.7216 6.7546 12.2754 6.43085 10.7536 6.43085C7.18545 6.43085 4.03302 8.21062 2.1356 10.9309C2.81632 11.9068 3.65858 12.7616 4.62361 13.4567L2 15L2.50702 15.8619ZM5.57518 12.897L6.48569 12.3614C6.33512 11.912 6.25355 11.4309 6.25355 10.9308C6.25355 9.85511 6.63102 8.86753 7.26072 8.09334C5.73761 8.69574 4.40719 9.68074 3.38765 10.9309C4.00996 11.6939 4.7481 12.3582 5.57518 12.897ZM7.37268 11.8396C7.29498 11.5498 7.25355 11.2452 7.25355 10.9308C7.25355 8.99785 8.82055 7.43085 10.7536 7.43085C11.7005 7.43085 12.5596 7.80691 13.1897 8.41783L7.37268 11.8396ZM15.2536 10.9308C15.2536 10.612 15.2204 10.3008 15.1573 10.0007L14.2325 10.5447C14.2464 10.6715 14.2536 10.8003 14.2536 10.9308C14.2536 12.8638 12.6865 14.4308 10.7536 14.4308C9.99775 14.4308 9.2979 14.1913 8.72579 13.7839L7.06105 14.7632C8.20972 15.1948 9.45407 15.4308 10.7536 15.4308C14.3217 15.4308 17.4741 13.6511 19.3715 10.9308C18.794 10.1029 18.1003 9.36211 17.3139 8.73209L16.3847 9.2787C17.03 9.75441 17.6131 10.3099 18.1195 10.9308C17.0999 12.181 15.7695 13.166 14.2464 13.7684C14.8761 12.9942 15.2536 12.0066 15.2536 10.9308Z"
        fill="currentColor"
      />
    </svg>
  )
);
