import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_RED_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  PaymentRequest,
  useGetPaymentLinkQuery,
  useUpdatePracticeAttendanceMutation,
} from "../../graphql";
import { AgendaSession } from "../Agenda/model";
import { StyledAttendanceButton } from "../AttendanceButton";
import StyledText from "../StyledText";

import { AttendanceStatus } from "./Attendance";
import AttendanceForm from "./Components/AttendanceForm";

type SessionPaymentProps = {
  session: AgendaSession;
  payment: PaymentRequest;
  refetch: () => Promise<any>;
};

const SessionPaymentWrapper = styled.div`
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
`;

const PaymentNotAllowedWrapper = styled.div`
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const PaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_GREY_ACTIVE};
  margin-bottom: 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const StyledButton = styled(StyledAttendanceButton)`
  width: 125px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

enum Stage {
  INITIAL_PAY = "Initial pay",
  NOT_ATTENDING = "Not attending",
  PAY_NOT_ALLOWED = "Pay not allowed",
}

export function SessionPayment({
  session,
  payment,
  refetch,
}: SessionPaymentProps) {
  const { t } = useTranslation();
  const { sessionId, timezone } = useUserContext();
  const {
    actions: { closeModal },
  } = useModalContext();

  const [updatePracticeAttendanceMutation] =
    useUpdatePracticeAttendanceMutation();

  const [currentStage, setCurrentStage] = useState<Stage>(Stage.INITIAL_PAY);
  const [note, setNote] = useState(payment.athleteComment || "");
  const [payNotAllowedReasonText, setPayNotAllowedReason] = useState("");

  const { data } = useGetPaymentLinkQuery({
    variables: {
      sessionId,
      athleteId: payment.participant.id,
      eventId: session?.id,
    },
  });

  const deadlinePassed = dayjs(session?.replyBefore).isAfter();
  const deadlineDate = dayjs(session?.replyBefore).format("YYYY.MM.DD h:mm A");

  const price = `${Math.round((payment?.pricex100 || 0) / 100)} ${
    payment?.currency
  }`;

  const onConfirm = async () => {
    await updatePracticeAttendanceMutation({
      variables: {
        sessionId,
        timezone,
        input: {
          practiceGuid: session?.id,
          athleteGuid: payment.participant.id,
          attendance: AttendanceStatus.OTHER,
          athleteComment: note.trim() ?? "",
        },
      },
    });

    await refetch();
    closeModal();
  };

  const handleNotAttending = async () => {
    if (deadlinePassed) {
      setCurrentStage(Stage.NOT_ATTENDING);
    } else {
      setCurrentStage(Stage.PAY_NOT_ALLOWED);
      setPayNotAllowedReason(t("deadlinePassed"));
    }
  };

  const handleOpenPaymentUrl = () => {
    if (data?.getPaymentLink?.error || !data?.getPaymentLink?.url) {
      setCurrentStage(Stage.PAY_NOT_ALLOWED);
      setPayNotAllowedReason(t("paymentNotSuccessful"));
    } else {
      closeModal();
      window.open(data?.getPaymentLink?.url, "_blank");
    }
  };

  const handlePayment = () => {
    const payNotAllowedReason = !deadlinePassed
      ? t("deadlinePassed")
      : !payment.loggedOnUserCanPay
        ? t("payableByFamilyMember")
        : null;

    if (payNotAllowedReason) {
      setCurrentStage(Stage.PAY_NOT_ALLOWED);
      setPayNotAllowedReason(payNotAllowedReason);
    } else {
      handleOpenPaymentUrl();
    }
  };

  return (
    <>
      {currentStage === Stage.INITIAL_PAY ? (
        <>
          <SessionPaymentWrapper>
            <PaymentItem>
              <StyledText bold>{t("description")}</StyledText>
              <StyledText style={{ textAlign: "right" }}>
                {payment.explanation}
              </StyledText>
            </PaymentItem>
            <PaymentItem>
              <StyledText bold>{t("dueDate")}</StyledText>
              <StyledText>{deadlineDate}</StyledText>
            </PaymentItem>
            <PaymentItem>
              <StyledText bold>{t("participant")}</StyledText>
              <StyledText>{payment.participant.name}</StyledText>
            </PaymentItem>
            <PaymentItem>
              <StyledText bold style={{ lineHeight: "30px" }}>
                {t("paymentDue")}
              </StyledText>
              <StyledText fontSize={30} bold>
                {price}
              </StyledText>
            </PaymentItem>
          </SessionPaymentWrapper>
          <Divider />
          <ButtonsWrapper>
            <StyledButton
              color={COLOR_RED_ACTIVE}
              hasIcon={false}
              hasBorder
              bgColor={COLOR_WHITE}
              onClick={handleNotAttending}
            >
              <span>{t("notAttending").toUpperCase()}</span>
            </StyledButton>
            <StyledButton
              color={COLOR_WHITE}
              hasIcon={false}
              bgColor={COLOR_GREEN}
              onClick={handlePayment}
            >
              <span>{t("payNow").toUpperCase()}</span>
            </StyledButton>
          </ButtonsWrapper>
        </>
      ) : null}
      {currentStage === Stage.NOT_ATTENDING ? (
        <FormWrapper>
          <AttendanceForm
            note={note}
            setNote={setNote}
            onCloseClick={closeModal}
            onConfirmClick={onConfirm}
          />
        </FormWrapper>
      ) : null}
      {currentStage === Stage.PAY_NOT_ALLOWED ? (
        <>
          <PaymentNotAllowedWrapper>
            <StyledText>{payNotAllowedReasonText}</StyledText>
          </PaymentNotAllowedWrapper>
          <Divider />
          <ButtonsWrapper>
            <StyledAttendanceButton
              color={COLOR_MEDIUM_GRAY}
              hasIcon={false}
              bgColor={COLOR_GRAY}
              onClick={closeModal}
            >
              <span>{t("close").toUpperCase()}</span>
            </StyledAttendanceButton>
          </ButtonsWrapper>
        </>
      ) : null}
    </>
  );
}
