import * as api from "../api";
import * as endpoints from "../endpoints";

export type AllPreferencesResponse = {
  _canUseGoogleCalendar: boolean;
  _firstNameFirst: boolean;
  _isInClubWithFamilyLicenses: boolean;
  _isSweFloorball: boolean;
  _isGolfer: boolean;
  _map: any;
  _speaksOfClients: boolean;
  _usesSoccer: boolean;
  _usesTeamSessions: boolean;
  blogInternal: string;
  blogFull: string;
};

export const fetchAllPreferences = async (session: api.Session) => {
  return api.post<AllPreferencesResponse>(
    endpoints.JsonAllPreferencesQuery,
    {},
    session
  );
};
