import React from "react";

export const Hoverable = ({ children, ...props }) => {
  return (
    <div className="hoverableDivContainer" {...props}>
      {children}
    </div>
  );
};

export const ShowOnHover = ({ children, ...props }) => {
  return (
    <div className="showOnHover" {...props}>
      {children}
    </div>
  );
};
