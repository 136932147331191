import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_MEDIUM_GRAY } from "../../colors";
import { BaseUserListCard } from "../../components/Athletes/BaseUserListCard";
import { GroupListCard } from "../../components/Athletes/GroupListCard";
import { IconListCard } from "../../components/Athletes/IconListCard";
import { SubGroupListCard } from "../../components/Athletes/SubGroupListCard";
import { LibrariesIcon, ReportIcon } from "../../components/Icons";
import StyledText from "../../components/StyledText";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { sortList } from "../../utils/sortList";

const ContentWrapper = styled.div`
  margin: 0 20px 100px;
`;

const SectionTitleWrapper = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubGroupListCardWrapper = styled.div`
  & > div:last-child {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const GroupListCardWrapper = styled.div`
  .groupListCardHasSubGroup > div {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const GroupSectionWrapper = styled.div<{ withBottomMargin?: boolean }>`
  margin-bottom: ${({ withBottomMargin }) => (withBottomMargin ? 20 : 0)}px;
`;

const SendGroupMessageWrapper = styled.div`
  cursor: pointer;
`;

export function InfoTab({
  isCurrentUserAthlete,
  someoneElseAsAthlete,
  isUserTypeTrainer,
  groups,
  currentUserAsAthlete,
  familyMembers,
  athleteId,
  teamGuids,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUserContext();
  const modal = useTopModalContext();

  const familyMembersToSendGroupMessage = familyMembers?.filter(
    (familyMember) => familyMember.id !== user.id
  );

  const shouldShowSendGroupMessage =
    familyMembersToSendGroupMessage?.length > 0 && isRealTrainer(user);

  const handleSendGroupMessage = () => {
    modal.actions.openTopModal({
      modal: TopModalType.CREATE_CHAT_GROUP,
      title: t("createChatGroup"),
      params: {
        familyMembers: familyMembersToSendGroupMessage.map((fm) => ({
          ...fm,
          id: fm.profile.id,
        })),
        athletes: isRealTrainer(user) ? [someoneElseAsAthlete] : [],
      },
    });
  };

  return (
    <ContentWrapper>
      {!isCurrentUserAthlete && someoneElseAsAthlete?.profile?.pin && (
        <>
          <SectionTitleWrapper>
            <StyledText
              fontSize={12}
              fontWeight="semibold"
              color={COLOR_MEDIUM_GRAY}
            >
              {t("pin").toUpperCase()}
            </StyledText>
          </SectionTitleWrapper>

          <StyledText fontSize={16} fontWeight="bold">
            {someoneElseAsAthlete.profile?.pin}
          </StyledText>
        </>
      )}

      {!isUserTypeTrainer && groups && groups.length > 0 ? (
        <>
          <SectionTitleWrapper>
            <StyledText
              fontSize={12}
              fontWeight="semibold"
              color={COLOR_MEDIUM_GRAY}
            >
              {t("teams").toUpperCase()}
            </StyledText>
          </SectionTitleWrapper>
          <GroupListCardWrapper>
            {groups.map((g) => (
              <GroupSectionWrapper
                key={g.id}
                withBottomMargin={
                  isRealTrainer(currentUserAsAthlete) && g.subGroups
                }
              >
                <GroupListCard
                  key={g.id}
                  group={g}
                  hasSubGroup={
                    isRealTrainer(currentUserAsAthlete) &&
                    g.subGroups.length > 0
                  }
                />
                {isRealTrainer(currentUserAsAthlete) && g.subGroups ? (
                  <SubGroupListCardWrapper>
                    {g.subGroups.map((subGroup, index, array) => (
                      <SubGroupListCard
                        key={subGroup.id}
                        group={subGroup}
                        isLast={index === array.length - 1}
                      />
                    ))}
                  </SubGroupListCardWrapper>
                ) : null}
              </GroupSectionWrapper>
            ))}
          </GroupListCardWrapper>
        </>
      ) : null}

      {familyMembers && familyMembers.length > 0 ? (
        <>
          <SectionTitleWrapper>
            <StyledText
              fontSize={12}
              fontWeight="semibold"
              color={COLOR_MEDIUM_GRAY}
            >
              {t("familyMembers").toUpperCase()}
            </StyledText>
            {shouldShowSendGroupMessage ? (
              <SendGroupMessageWrapper onClick={handleSendGroupMessage}>
                <StyledText color={COLOR_BLUE} fontSize={12} fontWeight={500}>
                  {t("sendGroupMessage").toUpperCase()}
                </StyledText>
              </SendGroupMessageWrapper>
            ) : null}
          </SectionTitleWrapper>
          {sortList(familyMembers, user.language).map(
            (familyMember, index, array) => (
              <BaseUserListCard
                key={familyMember.id}
                currentUser={familyMember}
                isTrainerCard={isRealTrainer(currentUserAsAthlete)}
                hideBody
                isLast={index === array.length - 1}
              />
            )
          )}
        </>
      ) : null}

      {!isCurrentUserAthlete && !isRealTrainer(currentUserAsAthlete) && (
        <>
          <SectionTitleWrapper>
            <StyledText
              fontSize={12}
              fontWeight="semibold"
              color={COLOR_MEDIUM_GRAY}
            >
              {t("shared").toUpperCase()}
            </StyledText>
          </SectionTitleWrapper>
          <IconListCard
            title={t("library")}
            svgIcon={<LibrariesIcon />}
            onClick={() => {
              navigate({
                pathname: `/${ROUTING_CONFIG.AthleteCollections}`,
                search: `?focusedAthleteId=${athleteId}`,
              });
            }}
          />
          <IconListCard
            title={t("reports")}
            svgIcon={<ReportIcon />}
            onClick={() =>
              navigate({
                pathname: `/${ROUTING_CONFIG.AthleteReports}`,
                search: `?focusedAthleteId=${athleteId}&allowedGroups=${teamGuids.join(
                  ","
                )}`,
              })
            }
          />
        </>
      )}
    </ContentWrapper>
  );
}
