import React, { memo } from "react";

export const DeleteIcon = memo(() => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#64707A" />
    <path d="M3.5 9.5L9.5 3.5" stroke="white" strokeWidth="1.5" />
    <path d="M3.5 3.5L9.5 9.5" stroke="white" strokeWidth="1.5" />
  </svg>
));
