import React, { CSSProperties, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";
import { useWorkoutEditorContext } from "../../contexts/workout/WorkoutEditorProvider";
import { KeyboardKeyCodes } from "../../utils/keyboardKeyCodes";
import { ControlledInput } from "../HookForm/ControlledInput";
import { ColumnOfRowWrapper } from "../HookForm/styled";

type PlanItemFormProps = {
  formMethods;
  id: string;
  splitAreaId?: string;
  style?: CSSProperties;
};

const FormWrapper = styled.div<{ isSplitSection?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
`;

const Row = styled.div<{ zIndexValue?: string }>`
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
`;

export function WorkoutPreviewTimeForm({
  id,
  formMethods,
  splitAreaId,
  style,
}: PlanItemFormProps) {
  const { t } = useTranslation();
  const { changeFormValue } = useWorkoutEditorContext();

  const onMinutesKeyDown = (e) => {
    if (
      [KeyboardKeyCodes.arrowUp, KeyboardKeyCodes.arrowDown].indexOf(
        e.keyCode
      ) > -1
    ) {
      e.preventDefault();
      const planItemEl = document.getElementById(`planItem-${id}`);
      if (planItemEl) {
        // up
        if (e.keyCode === KeyboardKeyCodes.arrowUp) {
          const minutesInput: HTMLElement | undefined =
            planItemEl?.previousElementSibling?.querySelector(
              ".planItemFormMinutes"
            );
          if (minutesInput) {
            minutesInput.focus();
          }
        }
        // down
        if (e.keyCode === KeyboardKeyCodes.arrowDown) {
          const minutesInput: HTMLElement | undefined =
            planItemEl?.nextElementSibling?.querySelector(
              ".planItemFormMinutes"
            );
          if (minutesInput) {
            minutesInput.focus();
          }
        }
      }
    }
  };

  const minutesValue = formMethods.watch("minutes");
  const timeValue = formMethods.watch("time");
  const intensityValue = formMethods.watch("intensity");

  useEffect(() => {
    changeFormValue({
      key: id,
      time: timeValue,
      intensity: intensityValue,
      minutes: minutesValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minutesValue, intensityValue, timeValue, minutesValue]);

  return (
    <FormWrapper isSplitSection={!!splitAreaId} style={style}>
      <ColumnOfRowWrapper width={100} rightMargin></ColumnOfRowWrapper>
      <Row>
        <ColumnOfRowWrapper width={20} rightMargin>
          <ControlledInput
            name="minutes"
            label={t("minutes")}
            type="number"
            className="planItemFormMinutes"
            onKeyDownCapture={onMinutesKeyDown}
          />
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper width={40} rightMargin>
          <ControlledInput name="time" label={t("time")} disabled />
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper width={20} rightMargin>
          <ControlledInput name="intensity" label={t("intensity")} />
        </ColumnOfRowWrapper>
      </Row>
    </FormWrapper>
  );
}
