import { useMemo } from "react";

import _compact from "lodash/compact";
import _uniq from "lodash/uniq";

import { useReportContext } from "../../../../contexts/report";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import {
  AggregateFunction,
  ReportWidgetCellNumerical,
  ReportWidgetCellTextual,
  XpsUser,
} from "../../../../graphql";
import {
  useGroupWidgetParticipants,
  useTeamsAthletes,
} from "../../../../hooks";
import {
  AthleteSoccerStatisticsResponse,
  FetchJsonStatisticsForMeasurementsArgs,
  FetchSoccerStatisticsResponse,
  getAggregationValueFromCooking,
  getCookingValue,
  InformationCooking,
  JsonAttendanceStatisticsQueryResponse,
  JsonInformation2022QueryResponse,
  JsonStatisticsForMeasurementsResponse,
} from "../../../../services";
import {
  useAthleteSoccerStatisticsQuery,
  useAthleteStatisticsQuery,
  useAttendanceStatisticsQuery,
  useInformations2022Query,
  useSoccerStatisticsQuery,
  useTeamAverageStatisticsQuery,
  useXpsMagicInfoStatisticsQuery,
} from "../../../../services/statistics/hooks";
import { JsonXpsMagicInfoStatisticsQueryResponse } from "../../../../services/statistics/xpsMagicInfo";
import { roundToTwo } from "../../../../utils/number";
import {
  getAttendanceStatisticsDataKey,
  groupByCollection,
  TEMPLATE_ID_TO_STAT_PROP,
} from "../../../../utils/reports";
import { getAllAthleteStats } from "../../../../utils/statistics";
import { KeyValuesWidgetModel } from "../../../ReportCreateTable/models";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import { PerformerSelectionMode } from "../../PerformerSection";
import { isHighPrecisionNumberInCanada } from "../../utils/isHighPrecisionNumberInCanada";
import {
  getAthleteSoccerStatsWithCustomCols,
  getSoccerStatsWithCustomCols,
} from "../../utils/soccerStatsWithCustomCols";
import { KeyValuesWidgetDataItem } from "../components/KeyValuesWidgetComponent";

export function useKeyValuesWidgetData(widget: KeyValuesWidgetModel) {
  const athleteWidgetData = useKeyValuesAthleteWidgetData(
    widget,
    widget.isGroupWidget
  );
  const groupWidgetData = useKeyValuesGroupWidgetData(
    widget,
    !widget.isGroupWidget
  );

  return !widget.isGroupWidget ? athleteWidgetData : groupWidgetData;
}

export function useKeyValuesAthleteWidgetData(
  widget: KeyValuesWidgetModel,
  skip: boolean
) {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete }] = useReportsContext();
  const { report } = useReportContext();
  const { fromDate, toDate } = useReportPeriod(widget.period);

  const participants: { id: string }[] = (
    widget?.performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED
      ? [{ id: widget?.athlete }]
      : [selectedAthlete]
  ).filter(Boolean);

  const widgetCells = useMemo(() => {
    const reportWidgetCells = _compact(widget?.grid?.flat() ?? []).map<
      (ReportWidgetCellNumerical | ReportWidgetCellTextual) & {
        settings?: KeyValuesWidgetModel["itemsSettings"][0];
      }
    >((cell, index) => ({ ...cell, settings: widget.itemsSettings?.[index] }));

    return groupByCollection(reportWidgetCells);
  }, [widget?.grid, widget.itemsSettings]);

  const { statsRequestItems, informationRequestItems } = useMemo(() => {
    return {
      statsRequestItems: getStatsRequestItems(
        widgetCells.statisticsItems as (ReportWidgetCellNumerical & {
          settings?: KeyValuesWidgetModel["itemsSettings"][0];
        })[]
      ),
      informationRequestItems: (widgetCells.informationItems || []).flatMap(
        (item) =>
          item.aggregateFunctions.flatMap((agg) => ({
            guid: item.templateId,
            cooking: getCookingValue(
              agg as
                | AggregateFunction.LastDayInRange
                | AggregateFunction.Latest
                | AggregateFunction.LatestForever
            ),
          }))
      ),
    };
  }, [widgetCells]);

  const aggregations: AggregateFunction[] =
    widgetCells.soccerStatisticsItems.flatMap(
      (soccerCell) => soccerCell.aggregateFunctions
    );

  const {
    data: statisticsData,
    isLoading: statisticsIsLoading,
    fetchStatus: statisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period: "continuous",
      session: { sessionId },
      participants,
      items: statsRequestItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        participants.length > 0 &&
        statsRequestItems.length > 0 &&
        !skip,
    }
  );

  const {
    data: informationsResponse,
    isLoading: informationsIsLoading,
    fetchStatus: informationsFetchStatus,
  } = useInformations2022Query(
    {
      athleteGuid: participants[0]?.id,
      templates: informationRequestItems,
      session: { sessionId },
      reportTemplateId: report?.id,
      range: {
        from: fromDate,
        to: toDate,
      },
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        participants.length > 0 &&
        informationRequestItems.length > 0 &&
        !skip,
    }
  );

  const {
    data: athleteSoccerStatisticsResponse,
    isLoading: athleteSoccerStatisticsIsLoading,
    fetchStatus: athleteSoccerStatisticsFetchStatus,
  } = useAthleteSoccerStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: participants[0]?.id,
      },
      from: fromDate,
      to: toDate,
      withAllTimeResults: aggregations.some((aggr) =>
        aggr?.includes("Forever")
      ),
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        Boolean(participants[0]?.id) &&
        widgetCells.soccerStatisticsItems?.length > 0 &&
        !skip,
    }
  );

  const {
    data: soccerStatisticsForAllGroupsResponse,
    isLoading: soccerStatisticsForAllGroupsIsLoading,
    fetchStatus: soccerStatisticsForAllGroupsFetchStatus,
  } = useAthleteSoccerStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: participants[0]?.id,
      },
      from: fromDate,
      to: toDate,
      allGroups: true,
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        Boolean(participants[0]?.id) &&
        widgetCells.soccerStatisticsItems?.length > 0 &&
        !skip,
    }
  );

  const {
    data: athletesAttendanceStatisticsResponse,
    isLoading: athletesAttendanceStatisticsIsLoading,
    fetchStatus: athletesAttendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: selectedAthlete?.id,
      },
      fromDate,
      toDate,
      participants,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        participants.length > 0 &&
        widgetCells.attendanceItems?.length > 0 &&
        !skip,
    }
  );

  const {
    data: attendanceStatisticsForAllGroupsResponse,
    isLoading: attendanceStatisticsForAllGroupsIsLoading,
    fetchStatus: attendanceStatisticsForAllGroupsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: selectedAthlete?.id,
      },
      fromDate,
      toDate,
      participants,
      reportTemplateId: report?.id,
      allGroups: true,
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        participants.length > 0 &&
        widgetCells.attendanceItems?.length > 0 &&
        !skip,
    }
  );

  const {
    data: athletesXpsMagicInfoStatisticsResponse,
    isLoading: athletesXpsMagicInfoStatisticsIsLoading,
    fetchStatus: athletesXpsMagicInfoStatisticsFetchStatus,
  } = useXpsMagicInfoStatisticsQuery(
    {
      athleteIds: [selectedAthlete?.id],
      reportTemplateId: report?.id,
      templates: widgetCells.xpsMagicInfoItems?.map((item) => ({
        id: item.templateId?.slice(item.templateId?.indexOf(".") + 1),
      })),
    },
    {
      enabled:
        sessionId &&
        !widget.isGroupWidget &&
        participants.length > 0 &&
        widgetCells.xpsMagicInfoItems?.length > 0 &&
        !skip,
    }
  );

  const participantsIdentity = participants.map(({ id }) => id).join(":");
  const data = useMemo<KeyValuesWidgetDataItem[]>(() => {
    const athleteStats = getAthleteStatsDataItems(
      statisticsData,
      participants[0]
    );
    const infoStats = getInformationsDataItems(informationsResponse?.data);
    const soccerStats = getSoccerStatsDataItems(
      widgetCells.soccerStatisticsItems,
      athleteSoccerStatisticsResponse?.data,
      soccerStatisticsForAllGroupsResponse?.data
    );
    const attendanceStats = getAttendanceStatsDataItems(
      widgetCells.attendanceItems,
      athletesAttendanceStatisticsResponse?.data,
      attendanceStatisticsForAllGroupsResponse?.data
    );
    const xpsMagicInfoStats = getXpsMagicInfoItems(
      widgetCells.xpsMagicInfoItems,
      athletesXpsMagicInfoStatisticsResponse?.data
    );

    return [
      ...athleteStats,
      ...infoStats,
      ...soccerStats,
      ...attendanceStats,
      ...xpsMagicInfoStats,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    participantsIdentity,
    statisticsData,
    informationsResponse,
    widgetCells.soccerStatisticsItems,
    athleteSoccerStatisticsResponse,
    soccerStatisticsForAllGroupsResponse,
    widgetCells.attendanceItems,
    athletesAttendanceStatisticsResponse,
    attendanceStatisticsForAllGroupsResponse,
    athletesXpsMagicInfoStatisticsResponse,
  ]);

  const isLoading =
    (statisticsFetchStatus !== "idle" && statisticsIsLoading) ||
    (informationsFetchStatus !== "idle" && informationsIsLoading) ||
    (athleteSoccerStatisticsFetchStatus !== "idle" &&
      athleteSoccerStatisticsIsLoading) ||
    (soccerStatisticsForAllGroupsFetchStatus !== "idle" &&
      soccerStatisticsForAllGroupsIsLoading) ||
    (athletesAttendanceStatisticsFetchStatus !== "idle" &&
      athletesAttendanceStatisticsIsLoading) ||
    (attendanceStatisticsForAllGroupsFetchStatus !== "idle" &&
      attendanceStatisticsForAllGroupsIsLoading) ||
    (athletesXpsMagicInfoStatisticsFetchStatus !== "idle" &&
      athletesXpsMagicInfoStatisticsIsLoading);

  return { data, isLoading };
}

function useKeyValuesGroupWidgetData(
  widget: KeyValuesWidgetModel,
  skip: boolean
) {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const { fromDate, toDate } = useReportPeriod(widget.period);

  const { participantTeams, participants: participantsIds } =
    useGroupWidgetParticipants(widget);
  const teams = useTeamsAthletes(participantTeams);

  const participantsIdentity =
    teams.map((team) => team.id).join(":") + participantsIds.join(":");
  const participants = useMemo(
    () =>
      Array.from(
        new Set([
          ...teams.flatMap((team) => team.participants),
          ...participantsIds,
        ])
      ).map((id) => ({
        id,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [participantsIdentity]
  );

  const { widgetCells, statsRequestItems } = useMemo(() => {
    const reportWidgetCells = _compact(widget?.grid?.flat() ?? []).map<
      (ReportWidgetCellNumerical | ReportWidgetCellTextual) & {
        settings?: KeyValuesWidgetModel["itemsSettings"][0];
      }
    >((cell, index) => ({ ...cell, settings: widget.itemsSettings?.[index] }));

    const widgetCells = groupByCollection(reportWidgetCells);

    return {
      widgetCells,
      statsRequestItems: getStatsRequestItems(
        widgetCells.statisticsItems as (ReportWidgetCellNumerical & {
          settings?: KeyValuesWidgetModel["itemsSettings"][0];
        })[]
      ),
    };
  }, [widget?.grid, widget.itemsSettings]);

  const {
    data: statisticsData,
    isLoading: statisticsIsLoading,
    fetchStatus: statisticsFetchStatus,
  } = useTeamAverageStatisticsQuery(
    {
      fromDate,
      toDate,
      period: "continuous",
      session: { sessionId },
      participants,
      items: statsRequestItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        widget.isGroupWidget &&
        participants.length > 0 &&
        statsRequestItems?.length > 0 &&
        !skip,
    }
  );

  const {
    data: attendanceStatisticsData = null,
    isLoading: attendanceStatisticsIsLoading,
    fetchStatus: attendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      fromDate,
      toDate,
      session: { sessionId },
      participants,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        widgetCells.attendanceItems.length > 0 &&
        participants?.length > 0 &&
        !skip,
    }
  );

  const {
    data: soccerStatisticsData = null,
    isLoading: soccerStatisticsIsLoading,
    fetchStatus: soccerStatisticsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      from: fromDate,
      to: toDate,
      session: { sessionId },
      athleteGuids: participants.map((athlete) => athlete.id),
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        widgetCells.soccerStatisticsItems.length > 0 &&
        participants?.length > 0 &&
        !skip,
    }
  );

  const data = useMemo(
    () => [
      ...getAthleteStatsDataItems(statisticsData, undefined),
      ...getAttendanceTeamAverageStatsDataItems(
        widgetCells.attendanceItems,
        attendanceStatisticsData?.data
      ),
      ...getSoccerTeamStatsDataItems(
        widgetCells.soccerStatisticsItems,
        soccerStatisticsData?.data
      ),
    ],
    [
      statisticsData,
      widgetCells.attendanceItems,
      widgetCells.soccerStatisticsItems,
      attendanceStatisticsData?.data,
      soccerStatisticsData?.data,
    ]
  );

  return {
    data,
    isLoading:
      (statisticsFetchStatus !== "idle" && statisticsIsLoading) ||
      (attendanceStatisticsFetchStatus !== "idle" &&
        attendanceStatisticsIsLoading) ||
      (soccerStatisticsFetchStatus !== "idle" && soccerStatisticsIsLoading),
  };
}

function getStatsRequestItems(
  cells: (ReportWidgetCellNumerical & {
    settings?: KeyValuesWidgetModel["itemsSettings"][0];
  })[] = []
) {
  const groupedReportWidgetCells = cells
    .reduce(
      (map, item) => {
        const key = `${item.collectionId}:${item.templateId}:${item.attributeTemplate?.id}`;

        if (map.has(key)) {
          const memCell = map.get(key);

          map.set(key, {
            ...memCell,
            aggregateFunctions: _uniq([
              ...memCell.aggregateFunctions,
              ...item.aggregateFunctions,
            ]),
          });
        } else {
          map.set(key, { ...item });
        }

        return map;
      },
      new Map<
        string,
        ReportWidgetCellNumerical & {
          settings?: KeyValuesWidgetModel["itemsSettings"][0];
        }
      >()
    )
    .values();

  return Array.from(groupedReportWidgetCells).map<
    FetchJsonStatisticsForMeasurementsArgs["items"][0]
  >(
    (
      item: ReportWidgetCellNumerical & {
        settings?: KeyValuesWidgetModel["itemsSettings"][0];
      }
    ) => {
      return {
        dataType: item.collectionId,
        templateGuid: item.templateId,
        attribute: item.attributeTemplate?.id,
        aggregation: item.aggregateFunctions,
        compareLatestTo: item.settings?.compareLatestTo,
      };
    }
  );
}

function getAthleteStatsDataItems(
  statisticsResponse: JsonStatisticsForMeasurementsResponse,
  athlete: Pick<XpsUser, "id">
): KeyValuesWidgetDataItem[] {
  return getAllAthleteStats(
    statisticsResponse?._athletes?.find(
      (athleteStats) => athleteStats._guid === athlete?.id
    )
  ).flatMap((measurement) =>
    measurement._attributes.map((item) => ({
      measurementId: measurement._guid,
      attributeId: item._guid,
      aggregation: measurement._agg,
      value:
        item._values[0]?._txt ||
        isHighPrecisionNumberInCanada(item._values[0]?._val)
          ? item._values[0]?._val
          : roundToTwo(item._values[0]?._val),
      diff: item._values[0]?._diff && roundToTwo(item._values[0]?._diff),
      isInfo: false,
      text: item._values[0]?._txt ?? "",
    }))
  );
}

function getInformationsDataItems(
  infomationsReposponse: JsonInformation2022QueryResponse
): KeyValuesWidgetDataItem[] {
  return (
    infomationsReposponse?.templates.flatMap((info) =>
      info.items.flatMap((item) => ({
        measurementId: info.templateGuid,
        aggregation: getAggregationValueFromCooking(
          info.cooking as InformationCooking
        ),
        value: item._value,
        isInfo: true,
      }))
    ) || []
  );
}

type WidgetCellsWithSettings = ((
  | ReportWidgetCellNumerical
  | ReportWidgetCellTextual
) & {
  settings?: KeyValuesWidgetModel["itemsSettings"][0];
})[];

function getAttendanceStatsDataItems(
  attendanceReportWidgetCells: WidgetCellsWithSettings = [],
  attendanceResponse?: JsonAttendanceStatisticsQueryResponse,
  attendanceForAllGroupsResponse?: JsonAttendanceStatisticsQueryResponse
): KeyValuesWidgetDataItem[] {
  return (
    attendanceReportWidgetCells?.map((cell) => {
      const forAllGroups = cell.settings?.forAllGroups ?? false;
      const athleteAttendanceStats = (
        forAllGroups ? attendanceForAllGroupsResponse : attendanceResponse
      )?.perAthletes?.[0]?.attendanceInPeriodOfTime?.[0];
      const currentTemplateId = getAttendanceStatisticsDataKey(cell.templateId);
      const isPercentageValue = currentTemplateId.includes("Perc");
      const value = athleteAttendanceStats?.[currentTemplateId];

      return {
        measurementId: cell.templateId,
        attributeId:
          cell.__typename === "ReportWidgetCellNumerical"
            ? cell.attributeTemplate?.id
            : "",
        aggregation: cell.aggregateFunctions?.[0],
        value: !isNaN(Number(value))
          ? roundToTwo(isPercentageValue ? value * 100 : value)
          : "-",
        isInfo: false,
        forAllGroups,
      };
    }) || []
  );
}

function getXpsMagicInfoItems(
  xpsMagicInfoWidgetCells: WidgetCellsWithSettings = [],
  xpsMagicInfoResponse: JsonXpsMagicInfoStatisticsQueryResponse
): KeyValuesWidgetDataItem[] {
  return xpsMagicInfoWidgetCells.map((cell) => {
    return {
      measurementId: cell.templateId,
      aggregation: AggregateFunction.Latest,
      value: xpsMagicInfoResponse?.byAthletes?.[0]?.templates?.find(
        (template) =>
          template.id ===
          cell.templateId?.slice(cell.templateId?.indexOf(".") + 1)
      )?.value,
      isInfo: true,
    };
  });
}

const customDividerLogicKey = (currentTemplateId: string) => {
  switch (currentTemplateId) {
    case TEMPLATE_ID_TO_STAT_PROP.pra_att_perc:
      return TEMPLATE_ID_TO_STAT_PROP.total_pra;
    case TEMPLATE_ID_TO_STAT_PROP.pra_min_perc:
      return TEMPLATE_ID_TO_STAT_PROP.pra_total_min;
    case TEMPLATE_ID_TO_STAT_PROP.workouts_perc:
      return TEMPLATE_ID_TO_STAT_PROP.total_workouts;
    default:
      return currentTemplateId;
  }
};

function getAttendanceTeamAverageStatsDataItems(
  attendanceReportWidgetCells: WidgetCellsWithSettings = [],
  attendanceResponse?: JsonAttendanceStatisticsQueryResponse
): KeyValuesWidgetDataItem[] {
  return (
    attendanceReportWidgetCells?.map((cell) => {
      const currentTemplateId = getAttendanceStatisticsDataKey(cell.templateId);
      const customDividerTemplateId = customDividerLogicKey(currentTemplateId);
      const isPercentageValue = currentTemplateId.includes("Perc");
      const sumValue = attendanceResponse?.perAthletes?.reduce(
        (value, current) =>
          value +
          (current.attendanceInPeriodOfTime?.[0]?.[currentTemplateId] || 0),
        0
      );

      // We need this to include only athletes with any training / match to average not the ones without any
      const divider =
        isPercentageValue && currentTemplateId !== customDividerTemplateId
          ? attendanceResponse?.perAthletes?.filter(
              (perAthlete) =>
                perAthlete.attendanceInPeriodOfTime?.[0]?.[
                  customDividerTemplateId
                ] > 0
            ).length
          : attendanceResponse?.perAthletes?.length;

      const value = sumValue / divider;

      return {
        measurementId: cell.templateId,
        attributeId:
          cell.__typename === "ReportWidgetCellNumerical"
            ? cell.attributeTemplate?.id
            : "",
        aggregation: cell.aggregateFunctions?.[0],
        value: !isNaN(Number(value))
          ? roundToTwo(isPercentageValue ? value * 100 : value)
          : "-",
        isInfo: false,
      };
    }) || []
  );
}

function getSoccerStatsDataItems(
  soccerStatsReportWidgetCells: WidgetCellsWithSettings = [],
  soccerStatsResponse?: AthleteSoccerStatisticsResponse,
  soccerStatsForAllGroupsResponse?: AthleteSoccerStatisticsResponse
): KeyValuesWidgetDataItem[] {
  return (
    soccerStatsReportWidgetCells?.map((cell) => {
      const forAllGroups = cell.settings?.forAllGroups ?? false;
      const value = getAthleteSoccerStatsWithCustomCols(
        forAllGroups ? soccerStatsForAllGroupsResponse : soccerStatsResponse,
        cell.templateId,
        cell.aggregateFunctions?.[0] ?? AggregateFunction.Latest
      );

      return {
        measurementId: cell.templateId,
        attributeId:
          "attributeTemplate" in cell ? cell.attributeTemplate?.id : "",
        aggregation: cell.aggregateFunctions?.[0],
        value: !isNaN(Number(value))
          ? roundToTwo(Number(value))
          : typeof value === "string"
            ? value
            : "-",
        isInfo: false,
        forAllGroups,
      };
    }) || []
  );
}

function getSoccerTeamStatsDataItems(
  soccerStatsReportWidgetCells: WidgetCellsWithSettings = [],
  soccerStatsReposponse?: FetchSoccerStatisticsResponse
): KeyValuesWidgetDataItem[] {
  return (
    soccerStatsReportWidgetCells?.map((cell) => {
      const sumValue = soccerStatsReposponse?.perAthletes?.reduce(
        (value, current) => {
          const periodValue = getSoccerStatsWithCustomCols(
            current.periods?.[0],
            cell.templateId,
            cell.aggregateFunctions?.[0] ?? AggregateFunction.Latest,
            0
          );

          return periodValue ? value + Number(periodValue) : value;
        },
        0
      );

      const isAggregateAverage =
        cell.aggregateFunctions[0] === AggregateFunction.Average;
      const value = isAggregateAverage
        ? sumValue / soccerStatsReposponse?.perAthletes?.length
        : sumValue;

      return {
        measurementId: cell.templateId,
        attributeId:
          cell.__typename === "ReportWidgetCellNumerical"
            ? cell.attributeTemplate?.id
            : "",
        aggregation: cell.aggregateFunctions?.[0],
        value: !isNaN(Number(value)) ? roundToTwo(value) : "-",
        isInfo: false,
      };
    }) || []
  );
}
