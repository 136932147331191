import { useTranslation } from "react-i18next";

import { getDateFormatString } from "../utils/date";

export const useDefaultDateFormat = () => {
  const { i18n } = useTranslation();
  const finalLang = i18n.language ?? "default";
  const dateFormat = getDateFormatString(finalLang);
  let shortWordDateFormat = `${dateFormat}`
    .replace("MM", "MMM")
    .replace("MMM.", "MMM")
    .replace("-MMM-", " MMM ")
    .replace(" YYYY", "")
    .replace(" YYYY.", "")
    .replace("YYYY.", "")
    .replace("YYYY-", "")
    .replace("-YYYY", "")
    .replace("YYYY", "")
    // bulgarian
    .replace(" г.", "");

  if (
    shortWordDateFormat &&
    ["/", "."].includes(
      shortWordDateFormat.charAt(shortWordDateFormat.length - 1)
    )
  ) {
    shortWordDateFormat = shortWordDateFormat.slice(0, -1);
  }
  if (
    shortWordDateFormat &&
    ["/", "."].includes(shortWordDateFormat.charAt(0))
  ) {
    shortWordDateFormat = shortWordDateFormat.substring(1);
  }
  let sameYearDateFormat = shortWordDateFormat.replace("YYYY", "YY");
  let wordMonthDateFormat = `${dateFormat}`
    .replace("MM", "MMM")
    .replace("MMM.", "MMM")
    .replace("-MMM-", " MMM ")
    .replace("YYYY.", "YYYY");

  if (dateFormat === "YYYY. MM. DD.") {
    sameYearDateFormat = "MMM DD.";
  }

  if (dateFormat === "DD.MM.YYYY г.") {
    wordMonthDateFormat = "DD. MMM YYYY г.";
  }
  if (sameYearDateFormat === "DD.MMM") {
    sameYearDateFormat = "DD. MMM";
  }

  return {
    dateFormat,
    shortWordDateFormat,
    sameYearDateFormat,
    wordMonthDateFormat,
  };
};
