import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../../contexts/accessLevels";
import { useUserContext } from "../../../contexts/User";
import {
  GetGroupsQuery,
  GetGroupTreeQuery,
  useGetGroupsQuery,
  useGetGroupTreeQuery,
  XpsUser,
} from "../../../graphql";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { sortList } from "../../../utils/sortList";

function filterDataType(data: GetGroupTreeQuery, type: string) {
  return data?.getGroupTree?.filter(
    (group) => group.__typename === type && group
  );
}

const getUniqueAthletesFromSelectedGroups = (
  groupsData: GetGroupsQuery,
  selectedVisibleGroupIds: string[],
  showOptional: boolean
) => {
  const uniqueAthletes = {};
  groupsData?.getGroups?.forEach((group) =>
    selectedVisibleGroupIds.includes(group.id)
      ? group?.[showOptional ? "athletes" : "requiredAthletes"]?.forEach(
          (athlete) => {
            uniqueAthletes[athlete.id] = athlete;
          }
        )
      : {}
  );

  return Object.values(uniqueAthletes) as XpsUser[];
};

const getSortedGroups = <T extends object>(groups: T[], language: string) =>
  sortList(groups ?? [], language);

export const useGetAgendaFiltersData = ({
  selectedVisibleGroupIds,
  showOptionalAthletes,
}: {
  selectedVisibleGroupIds: string[];
  showOptionalAthletes?: boolean;
}) => {
  const { sessionId, language, isTrainer, isFamilyMember } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();

  const isUserRealTrainer = isRealTrainer({ isTrainer, isFamilyMember });

  const variables = {
    sessionId,
    language,
    multiAccount: selectedAccount?.id === ALL_ACCOUNTS_ID,
    trainer:
      selectedAccount?.id === ALL_ACCOUNTS_ID ? undefined : selectedAccount?.id,
  };

  const { loading: loadingGroupTreeData, data: groupTreeData } =
    useGetGroupTreeQuery({
      variables,
      skip: !isUserRealTrainer || !sessionId || !selectedAccount?.id,
    });

  const { data: groupsData, loading: loadingGroupsData } = useGetGroupsQuery({
    variables,
    skip: !isUserRealTrainer,
  });

  const FOLDER = "Folder2023";
  const GROUP = "Group";

  const folders = filterDataType(groupTreeData, FOLDER);
  const groups = filterDataType(groupTreeData, GROUP);
  const athletes = getUniqueAthletesFromSelectedGroups(
    groupsData,
    selectedVisibleGroupIds,
    showOptionalAthletes
  );

  return {
    loading: loadingGroupTreeData || loadingGroupsData,
    folders,
    groups,
    athletes,
    allNestedGroups:
      getSortedGroups(groupsData?.getGroups, language)
        ?.flatMap((group) => [
          group,
          ...(getSortedGroups(group.subGroups, language).map((subgroup) => ({
            ...subgroup,
            isSubgroup: true,
          })) ?? []),
        ])
        .filter((group) => selectedVisibleGroupIds.includes(group.id)) ?? [],
  };
};
