import { useCallback, useEffect } from "react";

import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { fetchInvitations } from "../services/login/invitations";
import { fetchTerms } from "../services/login/terms";

export const useCheckTermsAndInvitations = ({
  sessionId = "",
  useAsEffectCheck = false,
}: {
  sessionId?: string;
  useAsEffectCheck?: boolean;
} = {}) => {
  const {
    actions: { openModal },
  } = useModalContext();

  const checkTermsAndInvitations = useCallback(
    async (sessionIdOverride = sessionId) => {
      if (sessionIdOverride) {
        const termsResponse = await fetchTerms({
          session: {
            sessionId: sessionIdOverride,
          },
        });

        if (termsResponse.categories?.length > 0) {
          openModal({
            modal: ModalType.TERMS,
            params: {
              terms: termsResponse.categories,
            },
          });
        } else {
          const invitationsResponse = await fetchInvitations({
            session: { sessionId: sessionIdOverride },
          });

          if (invitationsResponse._invitations?.length > 0) {
            openModal({
              modal: ModalType.INVITATIONS,
              params: {
                invitations: invitationsResponse._invitations,
              },
            });
          }
        }
      }
    },
    [openModal, sessionId]
  );

  useEffect(() => {
    if (useAsEffectCheck) {
      checkTermsAndInvitations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAsEffectCheck, sessionId]);

  return { checkTermsAndInvitations };
};
