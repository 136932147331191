import dayjs from "dayjs";
import duration, { DurationUnitType } from "dayjs/plugin/duration";

import { TimeUnit } from "../../../Report/models";

dayjs.extend(duration);

const getTimeUnit = (timeUnit: TimeUnit): DurationUnitType => {
  switch (timeUnit) {
    case "Millisecond":
      return "millisecond";
    case "Second":
      return "second";
    case "Minute":
      return "minute";
    case "Hour":
      return "hour";
    default:
      return null;
  }
};

const getTimeFormat = (timeUnit: TimeUnit) => {
  switch (timeUnit) {
    case "Millisecond":
      return "SSS";
    case "Second":
      return "m:ss";
    case "Minute":
      return "m:ss";
    case "Hour":
      return "HH:mm";
    default:
      return null;
  }
};

const enforceValidTimeUnitFromUnitName = (unitName: string): TimeUnit => {
  switch (unitName) {
    case "ms":
      return "Millisecond";
    case "sec":
      return "Second";
    case "min":
      return "Minute";
    case "h":
      return "Hour";
    default:
      return unitName as TimeUnit;
  }
};

export const formatDuration = (value: number, timeUnit: TimeUnit): string => {
  const duration = dayjs.duration(value, getTimeUnit(timeUnit));
  const durationDays = duration.get("hour");
  const durationMinutes = duration.get("minute");
  const durationSeconds = duration.get("second");
  const durationMiliseconds = duration.get("millisecond");

  const shouldRoundSecondsUp = durationMiliseconds >= 500;

  const dynamicFormats = [
    !!durationDays && "H[h]",
    !!durationMinutes && "m[m]",
    !!durationSeconds && "s[s]",
  ]
    .filter(Boolean)
    .join(" ");

  let finalDuration = duration;

  if (shouldRoundSecondsUp) {
    finalDuration = duration.add({ seconds: 1 });
  }

  return finalDuration.format(dynamicFormats);
};

export const getFormattedTimeOrValueWithAttributeFromDecimal = (
  value: number,
  timeUnitName: TimeUnit | string,
  unitName: string = ""
) => {
  const timeUnit = enforceValidTimeUnitFromUnitName(timeUnitName);
  const timeFormat = getTimeFormat(timeUnit);

  // We do not need to make duration formatting if we have less than 60 seconds
  if (!timeFormat || !value || (value < 60 && timeUnit === "Second")) {
    return `${value}\u00A0${unitName ?? ""}`;
  }

  const formattedTime = formatDuration(value, timeUnit);

  return formattedTime;
};
