import { useQuery, useMutation, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import {
  useDefaultDateFormat,
  useGetSelectedAccountIdInMonitoring,
  useIsMonitoring,
} from "../../hooks";
import * as api from "../api";
import {
  fetchGeoip,
  DokobitWebAuthorizeProps,
  DokobitWebAuthorizeQueryResponse,
  dokobitWebAuthorize,
  prepareDokobitRedirect,
  fetchDokobitLoginRequest,
} from "../dokobitLogin";

import {
  AcuteChronicsStatisticsResponse,
  fetchAcuteChronicsStatistics,
  FetchAcuteChronicsStatisticsArgs,
} from "./acuteChronics";
import {
  AttendanceLogQueryResult,
  fetchAttendanceLogQuery2022,
  fetchAttendanceStatistics,
  FetchAttendanceStatisticsArgs,
  fetchAttendanceTeamStatistics,
  FetchAttendanceTeamStatisticsArgs,
  JsonAttendanceStatisticsQueryResponse,
  JsonAttendanceTeamStatisticsQueryResponse,
  UseAttendanceLog2022QueryArgs,
} from "./attendance";
import {
  fetchJsonCombinedTestResults,
  FetchJsonCombinedTestResultsQueryArgs,
  FetchJsonCombinedTestResultsResponse,
} from "./combinedTests";
import {
  EventLogQueryItem,
  fetchEventLogQuery2022,
  UseEventLog2022QueryArgs,
} from "./eventLogs";
import {
  fetchInformation2022,
  fetchInformation2023,
  fetchInformations,
  InformationNewDataTeam,
  JsonInformation2022QueryResponse,
  JsonInformationQueryResponse,
  UseInformations2022QueryArgs,
  UseInformations2023QueryArgs,
} from "./informations";
import {
  AthleteSoccerStatisticsResponse,
  fetchAthleteSoccerStatistics,
  FetchAthleteSoccerStatisticsArgs,
  fetchSoccerStatistics,
  FetchSoccerStatisticsArgs,
  FetchSoccerStatisticsResponse,
  fetchSoccerTeamStatistics,
  FetchSoccerTeamStatisticsArgs,
  FetchSoccerTeamStatisticsResponse,
} from "./soccer";
import {
  fetchAthleteStatistics,
  FetchJsonStatisticsForMeasurementsArgs,
  FetchJsonTeamStatisticsForMeasurementsArgs,
  fetchTeamAverageStatistics,
  fetchTeamStatistics,
  JsonStatisticsForMeasurementsResponse,
} from "./statistics";
import {
  fetchXpsMagicInfoStatistics,
  FetchXpsMagicInfoStatisticsArgs,
  JsonXpsMagicInfoStatisticsQueryResponse,
} from "./xpsMagicInfo";

export type UseAthleteStatisticsQueryArgs =
  FetchJsonStatisticsForMeasurementsArgs;

export function useAthleteStatisticsQuery(
  args: UseAthleteStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<JsonStatisticsForMeasurementsResponse | null>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<JsonStatisticsForMeasurementsResponse | null>(
    queryKey ?? [
      "athleteStatisticsQuery",
      {
        ...args,
        fromDate: args?.fromDate?.valueOf(),
        toDate: args?.toDate?.valueOf(),
        startOfColoringRange: args?.startOfColoringRange?.valueOf(),
        isMonitoring,
      },
    ],
    async () =>
      fetchAthleteStatistics({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseTeamAverageStatisticsQueryArgs =
  FetchJsonTeamStatisticsForMeasurementsArgs;

export function useTeamAverageStatisticsQuery(
  args: UseTeamAverageStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<JsonStatisticsForMeasurementsResponse | null>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<JsonStatisticsForMeasurementsResponse | null>(
    queryKey ?? [
      "teamAverageStatisticsQuery",
      {
        ...args,
        fromDate: args?.fromDate?.valueOf(),
        toDate: args?.toDate?.valueOf(),
        isMonitoring,
      },
    ],
    async () =>
      fetchTeamAverageStatistics({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseTeamStatisticsQueryArgs = Parameters<
  typeof fetchTeamStatistics
>[0];

export function useTeamStatisticsQuery(
  args: UseTeamStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<JsonStatisticsForMeasurementsResponse | null>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<JsonStatisticsForMeasurementsResponse | null>(
    queryKey ?? [
      "teamStatisticsQuery",
      {
        ...args,
        fromDate: args?.fromDate?.valueOf(),
        toDate: args?.toDate?.valueOf(),
        isMonitoring,
      },
    ],
    async () =>
      fetchTeamStatistics({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseCombineTestStatisticsQueryArgs =
  FetchJsonCombinedTestResultsQueryArgs;

export function useCombineTestStatisticsQuery(
  args: UseCombineTestStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<FetchJsonCombinedTestResultsResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<FetchJsonCombinedTestResultsResponse>>(
    queryKey ?? [
      "combineTestStatisticsQuery",
      {
        ...args,
        fromDate: args?.fromDate?.valueOf(),
        toDate: args?.toDate?.valueOf(),
        isMonitoring,
      },
    ],
    async () =>
      fetchJsonCombinedTestResults({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseInformationsQueryArgs = {
  athleteGuids: string[];
  informationGuids: string[];
  reportTemplateId: string;
  session: api.Session;
};

export function useInformationsQuery(
  args: UseInformationsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonInformationQueryResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<JsonInformationQueryResponse>>(
    queryKey ?? ["informationsQuery", { ...args, isMonitoring }],
    async () =>
      fetchInformations({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export function useInformations2022Query(
  args: UseInformations2022QueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonInformation2022QueryResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<JsonInformation2022QueryResponse>>(
    queryKey ?? [
      "informations2022Query",
      {
        ...args,
        isMonitoring,
        range: {
          from: args.range?.from?.valueOf(),
          to: args.range?.to?.valueOf(),
        },
      },
    ],
    async () =>
      fetchInformation2022({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
      enabled: Boolean(args.athleteGuid) && !!args.templates.length,
    }
  );
}

export function useInformations2023Query(
  args: UseInformations2023QueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<InformationNewDataTeam>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<InformationNewDataTeam>>(
    queryKey ?? [
      "informations2023Query",
      {
        ...args,
        isMonitoring,
        range: {
          from: args.range?.from?.valueOf(),
          to: args.range?.to?.valueOf(),
        },
      },
    ],
    async () =>
      fetchInformation2023({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export function useEventLog2022Query(
  args: UseEventLog2022QueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<UseQueryOptions<AxiosResponse<EventLogQueryItem>>, "queryFn"> = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<EventLogQueryItem>>(
    queryKey ?? [
      "eventLog2022Query",
      { ...args, isMonitoring, from: args.from?.valueOf() },
    ],
    async () =>
      fetchEventLogQuery2022({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export function useAttendanceLog2022Query(
  args: UseAttendanceLog2022QueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<AttendanceLogQueryResult>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<AttendanceLogQueryResult>>(
    queryKey ?? [
      "attendanceLog2022Query",
      {
        ...args,
        isMonitoring,
        range: {
          from: args.range?.from?.valueOf(),
          to: args.range?.to?.valueOf(),
        },
      },
    ],
    async () =>
      fetchAttendanceLogQuery2022({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseAcuteChronicsStatisticsQueryArgs =
  FetchAcuteChronicsStatisticsArgs;

export function useAcuteChronicsStatisticsQuery(
  args: UseAcuteChronicsStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<AcuteChronicsStatisticsResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<AcuteChronicsStatisticsResponse>>(
    queryKey ?? [
      "acuteChronicsStatisticsQuery",
      {
        ...args,
        isMonitoring,
        fromDate: args.fromDate?.valueOf(),
        toDate: args.toDate?.valueOf(),
      },
    ],
    async () =>
      fetchAcuteChronicsStatistics({
        focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        focusedUserAccount: selectedAccountId,
        ...args,
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseAthleteSoccerStatisticsQueryArgs =
  FetchAthleteSoccerStatisticsArgs;

export function useAthleteSoccerStatisticsQuery(
  args: UseAthleteSoccerStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<AthleteSoccerStatisticsResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<AthleteSoccerStatisticsResponse>>(
    queryKey ?? [
      "athleteSoccerStatisticsQuery",
      {
        ...args,
        isMonitoring,
        from: args.from?.valueOf(),
        to: args.to?.valueOf(),
      },
    ],
    async () =>
      fetchAthleteSoccerStatistics({
        ...args,
        focusedUserAccount: selectedAccountId,
        session: {
          ...args.session,
          focusedGroup: selectedGroup?.id,
        },
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseSoccerStatisticsQueryArgs = FetchSoccerStatisticsArgs;

export function useSoccerStatisticsQuery(
  args: UseSoccerStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<FetchSoccerStatisticsResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<FetchSoccerStatisticsResponse>>(
    queryKey ?? [
      "soccerStatisticsQuery",
      {
        ...args,
        isMonitoring,
        from: args.from?.valueOf(),
        to: args.to?.valueOf(),
      },
    ],
    async () =>
      fetchSoccerStatistics({
        ...args,
        focusedUserAccount: selectedAccountId,
        session: {
          ...args.session,
          focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        },
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseSoccerTeamStatisticsQueryArgs = FetchSoccerTeamStatisticsArgs;

export function useSoccerTeamStatisticsQuery(
  args: UseSoccerTeamStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<FetchSoccerTeamStatisticsResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<FetchSoccerTeamStatisticsResponse>>(
    queryKey ?? [
      "soccerTeamStatisticsQuery",
      {
        ...args,
        isMonitoring,
        from: args.from?.valueOf(),
        to: args.to?.valueOf(),
      },
    ],
    async () =>
      fetchSoccerTeamStatistics({
        ...args,
        focusedUserAccount: selectedAccountId,
        session: {
          ...args.session,
          focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        },
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseAttendanceStatisticsQueryArgs = FetchAttendanceStatisticsArgs;

export function useAttendanceStatisticsQuery(
  args: UseAttendanceStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonAttendanceStatisticsQueryResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<JsonAttendanceStatisticsQueryResponse>>(
    queryKey ?? [
      "attendanceStatisticsQuery",
      {
        ...args,
        isMonitoring,
        fromDate: args.fromDate?.valueOf(),
        toDate: args.toDate?.valueOf(),
      },
    ],
    async () =>
      fetchAttendanceStatistics({
        ...args,
        focusedUserAccount: selectedAccountId,
        session: {
          ...args.session,
          focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        },
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseAttendanceTeamStatisticsQueryArgs =
  FetchAttendanceTeamStatisticsArgs;

export function useAttendanceTeamStatisticsQuery(
  args: UseAttendanceTeamStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonAttendanceTeamStatisticsQueryResponse>>,
    "queryFn"
  > = {}
) {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [{ selectedGroup }] = useReportsContext();
  const isMonitoring = useIsMonitoring();

  return useQuery<AxiosResponse<JsonAttendanceTeamStatisticsQueryResponse>>(
    queryKey ?? [
      "attendanceTeamStatisticsQuery",
      {
        ...args,
        isMonitoring,
        fromDate: args.fromDate?.valueOf(),
        toDate: args.toDate?.valueOf(),
      },
    ],
    async () =>
      fetchAttendanceTeamStatistics({
        ...args,
        focusedUserAccount: selectedAccountId,
        session: {
          ...args.session,
          focusedGroup: isMonitoring ? selectedGroup?.id : undefined,
        },
      }),
    {
      ...queryOptions,
    }
  );
}

export type UseXpsMagicInfoStatisticsQueryArgs = Omit<
  FetchXpsMagicInfoStatisticsArgs,
  "session"
>;

export function useXpsMagicInfoStatisticsQuery(
  args: UseXpsMagicInfoStatisticsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonXpsMagicInfoStatisticsQueryResponse>>,
    "queryFn"
  > = {}
) {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete, selectedGroup }] = useReportsContext();
  const { dateFormat } = useDefaultDateFormat();

  return useQuery<AxiosResponse<JsonXpsMagicInfoStatisticsQueryResponse>>(
    queryKey ?? ["xpsMagicInfoStatisticsQuery", args],
    async () =>
      fetchXpsMagicInfoStatistics({
        dateFormat,
        session: {
          sessionId,
          focusedAthlete: selectedAthlete?.id,
          focusedGroup: selectedGroup?.id,
        },
        ...args,
      }),
    queryOptions
  );
}

export function usePrepareDokobitRedirect() {
  return useMutation({ mutationFn: prepareDokobitRedirect });
}

export function useDokobitWebAuthorizeQuery(
  args: DokobitWebAuthorizeProps,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<DokobitWebAuthorizeQueryResponse>>,
    "queryFn"
  > = {}
) {
  return useQuery<AxiosResponse<DokobitWebAuthorizeQueryResponse>>(
    queryKey ?? ["dokobitWebAuthorize", args.userSession, args.sessionToken],
    async () => dokobitWebAuthorize(args),
    queryOptions
  );
}

export function useDokobitLoginRequest() {
  return useMutation({ mutationFn: fetchDokobitLoginRequest });
}

export function useFetchGeoIP() {
  return useQuery({ queryKey: ["fetchGeoIP"], queryFn: fetchGeoip });
}
