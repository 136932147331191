import React, { ReactElement } from "react";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_WHITE } from "../colors";

const RoundWrapper = styled.div<{
  size: number;
  marginRight: number;
  isHovered: boolean;
}>`
  background-color: ${({ isHovered }) =>
    isHovered ? COLOR_WHITE : COLOR_GRAY};
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight}px;
`;

interface RoundIconProps {
  svgIcon?: ReactElement;
  size?: number;
  marginRight?: number;
  isHovered?: boolean;
}

export const RoundIcon = ({
  svgIcon,
  size = 35,
  marginRight = 10,
  isHovered = false,
}: RoundIconProps) => {
  return (
    <RoundWrapper isHovered={isHovered} size={size} marginRight={marginRight}>
      {svgIcon}
    </RoundWrapper>
  );
};
