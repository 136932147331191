import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
} from "../../../colors";

import { TrainingDaySelect } from "./TrainingDaySelect";

const Wrapper = styled.div`
  background-color: ${COLOR_GRAY};
  padding: 25px 20px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  border-top-width: 1px;
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;
`;

const Label = styled.p`
  color: ${COLOR_TEXT_DARK};
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
`;

export const TrainingDaysSelector = ({ days, onDayPress, onTimeChange }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Label>{t("whichDays")}</Label>
      {days.map((day, dayIndex) => {
        return (
          <TrainingDaySelect
            key={dayIndex}
            day={day}
            days={days}
            dayIndex={dayIndex}
            onDayPress={onDayPress}
            onTimeChange={onTimeChange}
          />
        );
      })}
    </Wrapper>
  );
};
