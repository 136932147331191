import styled from "styled-components";

import { COLOR_GRAY, COLOR_MEDIUM_GRAY } from "../../../colors";
import { Hoverable } from "../../Hoverable/Hoverable";
import StyledText from "../../StyledText";

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: ${COLOR_GRAY};
  height: 100%;
  overflow: auto;
`;

export const SelectedSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLOR_GRAY};
  margin-bottom: 20px;
  padding-bottom: 16px;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  z-index: 10001;
`;

export const SectionTitle = styled(StyledText)`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 10px;
  margin-left: 10px;
  text-transform: uppercase;
`;

export const HoverableWrapper = styled(Hoverable)<{ index?: number }>`
  position: relative;
  z-index: ${({ index }) => 10000 - index};
`;

export const HoverableItem = styled.div`
  display: flex;
  flex-direction: row;
  background: white;
  margin-bottom: 5px;
  padding: 15px 10px 15px 15px;
  align-items: center;
  border-radius: 6px;
  border-color: #e7e7eb;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  height: 55px;
`;

export const Name = styled(StyledText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`;

export const NoDataTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
`;
