import React, { memo, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import {
  GroupTablePeriodizationDataPerAthleteItem,
  GroupTablePeriodizationStatsDataItem,
} from "../../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";
import { SummaryStatsAlignment } from "../../Report/PeriodizationTable/model";
import { WidgetContainer } from "../../Report/styled";
import { StickyColumn } from "../../TableStickyColumn/StickyColumn";

import { getGrouppedColumnHeader } from "./getGrouppedColumnHeader";
import { TablePeriodizationWidget } from "./model";
import { TablePeriodizationHeader } from "./TableHeader";
import { TablePeriodizationHeaderVertical } from "./TableHeaderVertical";
import { TablePeriodizationRow } from "./TableRow";
import { TableSummarySection } from "./TableSummarySection";
import {
  groupFilterColumnsWithNoData,
  sortEmptyGroupValues,
  sortNumericGroupTableRows,
  sortTextualGroupTableRows,
} from "./utils";

export type Data = {
  label: string;
  data: {
    id: string;
    value: string | number;
    txt?: string;
    min?: number;
    max?: number;
    unitName?: string;
    time?: number;
  }[][];
};

type TablePeriodizationWidgetTableProps = Pick<
  TablePeriodizationWidget,
  | "coloringConfig"
  | "timeLine"
  | "showSummaryStats"
  | "summaryStatsAlignment"
  | "verticalHeader"
  | "showValuesAsText"
  | "ignoreColumnsWithNoData"
> & {
  title: string;
  columnPeriods: any[];
  data: GroupTablePeriodizationDataPerAthleteItem[];
  tableRef?: any;
  statsData?: GroupTablePeriodizationStatsDataItem[];
};

const StyledTableComponent = styled.table<{ paddingValue?: number }>`
  width: 100%;
  z-index: 10;
  max-width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  td,
  th {
    border: 1px solid ${COLOR_TEXT_DARK};
  }
`;

const TableContainer = styled.div`
  height: 100%;
  overflow: auto;
`;

const EmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
`;

export enum PeriodizationTableType {
  VERTICAL = "vertical-periodization",
  HORIZONTAL = "horizontal-periodization",
}

export type SortByType = {
  colIndex?: number;
  order: number;
};

export const TablePeriodizationWidgetTable = memo(
  ({
    tableRef,
    title,
    columnPeriods,
    coloringConfig,
    data,
    timeLine,
    showSummaryStats,
    verticalHeader,
    showValuesAsText,
    statsData = [],
    summaryStatsAlignment,
    ignoreColumnsWithNoData,
  }: TablePeriodizationWidgetTableProps) => {
    const { t } = useTranslation();
    const grouppedColumnHeaders = getGrouppedColumnHeader(columnPeriods);
    const [sortByConfig, setSortByConfig] = useState<SortByType>({
      colIndex: null,
      order: -1,
    });

    const [hiddenHeaderHeight, setHiddenHeaderHeight] = useState<number>();

    const [shouldDisplayLongName, setShouldDisplayLongName] =
      useState<boolean>(true);

    const scrollContainerRef = useRef<any>();
    const hiddenItemContainerRef = useRef<any>();

    const onHeaderTitlePress = (colIndex?: number) => {
      const newOrder =
        sortByConfig.colIndex === colIndex ? -1 * sortByConfig.order : -1;
      setSortByConfig({ order: newOrder, colIndex });
    };

    const filteredStatsData = useMemo(() => {
      if (ignoreColumnsWithNoData) {
        return statsData.filter((data) => {
          if (data.average !== "-" || data.stdDev !== "-") {
            return data;
          }

          return null;
        });
      }

      return statsData;
    }, [ignoreColumnsWithNoData, statsData]);

    const rowsData = useMemo(() => {
      if (ignoreColumnsWithNoData) {
        return groupFilterColumnsWithNoData(data);
      }

      if (sortByConfig.colIndex === null || data.length === 0) {
        return data;
      }

      return [
        ...sortNumericGroupTableRows(data, sortByConfig),
        ...sortTextualGroupTableRows(data, sortByConfig),
        ...sortEmptyGroupValues(data, sortByConfig),
      ];
    }, [data, sortByConfig, ignoreColumnsWithNoData]);

    const filteredGrouppedColumnHeaders = useMemo(() => {
      if (!ignoreColumnsWithNoData) {
        return grouppedColumnHeaders;
      }
      const days: { date: string; isEmpty: boolean }[] = [];
      data?.forEach((athlete, athleteIndex) => {
        athlete.data.forEach((athleteDay, athleteDayIndex) => {
          if (athleteIndex === 0) {
            days.push({
              date: athleteDay.label,
              isEmpty:
                athleteDay.dataPerPeriod?.value === null ||
                athleteDay.dataPerPeriod?.txt === "-",
            });
          } else if (
            days.length > 0 &&
            days[athleteDayIndex] &&
            athleteDay.dataPerPeriod?.value !== null &&
            athleteDay.dataPerPeriod?.txt !== "-"
          ) {
            days[athleteDayIndex].isEmpty = false;
          }
        });
      });

      return [...grouppedColumnHeaders].filter((_, index) => {
        return days[index]?.isEmpty === false;
      });
    }, [grouppedColumnHeaders, ignoreColumnsWithNoData, data]);

    const allEmptyData = useMemo(
      () => rowsData.every((item) => item.data.length === 0),
      [rowsData]
    );

    return (
      <TableContainer>
        <WidgetContainer showBorders={false}>
          <div ref={tableRef}>
            <StickyColumn
              groupTablePeriodizationRowsData={rowsData}
              hiddenHeaderHeight={hiddenHeaderHeight}
              hiddenItemContainerRef={hiddenItemContainerRef}
              scrollContainerRef={scrollContainerRef}
              setShouldDisplayLongName={setShouldDisplayLongName}
              shouldDisplayLongName={shouldDisplayLongName}
              showSummaryStats={showSummaryStats}
              summaryStatsAlignment={summaryStatsAlignment}
              title={title}
              type={
                verticalHeader
                  ? PeriodizationTableType.VERTICAL
                  : PeriodizationTableType.HORIZONTAL
              }
            >
              {allEmptyData ? (
                <EmptyWrapper>{t("noData")}</EmptyWrapper>
              ) : (
                <StyledTableComponent>
                  {showSummaryStats &&
                    summaryStatsAlignment === SummaryStatsAlignment.TOP && (
                      <TableSummarySection
                        statsData={filteredStatsData}
                        isAboveTable
                      />
                    )}

                  <thead>
                    {verticalHeader ? (
                      <TablePeriodizationHeaderVertical
                        grouppedColumnHeaders={filteredGrouppedColumnHeaders}
                        sortByConfig={sortByConfig}
                        onHeaderTitlePress={onHeaderTitlePress}
                        setHiddenHeaderHeight={setHiddenHeaderHeight}
                        isGroup
                      />
                    ) : (
                      <TablePeriodizationHeader
                        grouppedColumnHeaders={filteredGrouppedColumnHeaders}
                        timeLine={timeLine}
                        sortByConfig={sortByConfig}
                        onHeaderTitlePress={onHeaderTitlePress}
                        setHiddenHeaderHeight={setHiddenHeaderHeight}
                      />
                    )}
                  </thead>

                  <tbody>
                    {rowsData?.map((periodData, index) => (
                      <TablePeriodizationRow
                        key={`${periodData.athleteName}-${index}`}
                        periodData={periodData}
                        showValuesAsText={showValuesAsText}
                        coloringConfig={coloringConfig}
                      />
                    ))}
                  </tbody>

                  {showSummaryStats &&
                    summaryStatsAlignment === SummaryStatsAlignment.BOTTOM && (
                      <TableSummarySection statsData={filteredStatsData} />
                    )}
                </StyledTableComponent>
              )}
            </StickyColumn>
          </div>
        </WidgetContainer>
      </TableContainer>
    );
  }
);
