import React from "react";

import dayjs from "dayjs";
import { Formik } from "formik";

import { PeriodType } from "../../../models";
import { ConnectedFocusError } from "../../FormikFocusError";
import { AppearanceSection } from "../../ReportCreateTable/AppearanceSection";
import { WidgetType } from "../../ReportCreateTable/models";
import { SectionWrapperForColorPicker } from "../../ReportCreateTable/styled";
import {
  PerformerSection,
  PerformerSelectionMode,
  PerformerSelectionWidgetType,
} from "../PerformerSection";
import { PeriodSection } from "../PeriodSection";
import { PreviewButtonSection } from "../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../styled";
import { WidgetEditorTitleSection } from "../WidgetEditorTitleSection";

import { ChartWidgetPreview } from "./ChartWidgetPreview";
import { ChartTypeSelectSection } from "./components/ChartTypeSelectSection";
import { ChartWhatToShowSection } from "./components/WhatToShowSection";
import { ChartType, ChartWidgetModel, TimeLine } from "./model";

export type ChartEditorProps = {
  widget: ChartWidgetModel;
  isGroupWidget: boolean;
  isSavingWidget: boolean;
  onSubmit: (values: ChartWidgetModel) => void;
  onCancel: () => void;
};

export function ChartEditor({
  widget,
  isSavingWidget,
  isGroupWidget,
  onSubmit,
  onCancel,
}: ChartEditorProps) {
  return (
    <Formik<ChartWidgetModel>
      initialValues={{ ...initialValues, ...widget, isGroupWidget }}
      onSubmit={onSubmit}
      validate={(values) => {
        if (values.items?.length < 1) {
          return { items: "required" };
        }
      }}
      validateOnMount
    >
      {(props) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <ChartTypeSelectSection />

            <WidgetEditorTitleSection
              name="name"
              value={props.values.name}
              handleChange={props.handleChange}
            />

            <SectionWrapperForColorPicker>
              <ChartWhatToShowSection isGroupWidget={isGroupWidget} />
            </SectionWrapperForColorPicker>

            <AppearanceSection
              widgetType={WidgetType.CHART}
              widgetBorders={props.values.widgetBorders}
              setFieldValue={props.setFieldValue}
            />

            <PerformerSection
              performerSelectionWidgetType={
                isGroupWidget
                  ? PerformerSelectionWidgetType.GROUP
                  : PerformerSelectionWidgetType.ATHLETE
              }
              setFieldValue={props.setFieldValue}
              performerSelectionMode={props.values.performerSelectionMode}
              withTeamSelection={isGroupWidget}
            />

            <PeriodSection
              setFieldValue={props.setFieldValue}
              period={props.values.period}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={props.submitForm}
          >
            <ChartWidgetPreview
              widget={{
                ...props.values,
                items: props.values.items.filter(
                  ({ dataType, templateGuid, attribute, aggregation }) =>
                    Boolean(dataType) &&
                    Boolean(templateGuid) &&
                    Boolean(attribute)
                ),
              }}
              isGroupReport={isGroupWidget}
            />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
}

const initialValues: ChartWidgetModel = {
  name: "",
  chartType: ChartType.COLUMN,
  showLegend: true,
  timeLine: TimeLine.CONTINUOUS,
  timeLinePeriod: undefined,
  includeZero: false,
  period: {
    type: PeriodType.SELECTION,
    from: dayjs(),
    to: dayjs(),
  },
  items: [
    {
      aggregation: undefined,
      dataType: "",
      templateGuid: "",
      attribute: "",
    },
  ],
  limits: [],
  performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
  athlete: "",
  athletes: [],
  teams: [],
  createdBy: "",
  participants: [],
  updatedAt: "",
  type: WidgetType.CHART,
  widgetBorders: false,
  series: undefined,
  isGroupWidget: false,
};
