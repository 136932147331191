import React, { useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../../colors";
import { useNewChatContext } from "../../../contexts/messaging/NewChatProvider";
import { useUIContext } from "../../../contexts/UI";
import { useUserContext } from "../../../contexts/User";
import {
  ChatterType,
  useFindChattableUsersQuery,
  useGetTheGroupsImInQuery,
} from "../../../graphql";
import { sortList } from "../../../utils/sortList";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";
import Tabs, { Tab } from "../../Tabs";
import { Team } from "../Components/Team";

import { ControlBar } from "./ControlBar";
import { SelectedUsersRow } from "./Group/SelectedUsersRow";
import { ChatTabs } from "./model";
import { getChatterType } from "./utils";

const Wrapper = styled.div`
  z-index: 1;
  background-color: ${COLOR_WHITE};
`;

const TabsWrapper = styled.div<{ screenWidth: number }>`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  padding: ${({ screenWidth }) => `0 ${screenWidth * 0.05}px`};
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: calc(90vh - 256px);
  width: 100%;
`;

const GroupedUserWrapper = styled.div<{ hasAttendance: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 21px;
  padding-bottom: 21px;
  height: ${({ hasAttendance }) =>
    `calc(90vh - ${hasAttendance ? 392 : 278}px)`};
`;

const getTabItems = (t: TFunction<"common">) => [
  { id: ChatTabs.ALL, title: t("all").toUpperCase() },
  {
    id: ChatTabs.COACHES,
    title: t("coaches").toUpperCase(),
  },
  {
    id: ChatTabs.ATHLETES,
    title: t("athletes").toUpperCase(),
  },
  {
    id: ChatTabs.FAMILY,
    title: t("family").toUpperCase(),
  },
];

export const GroupChatUserSelector = ({
  attendingUsers,
  setAttendingUsers,
}) => {
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const { stage } = useNewChatContext();

  const tabItems = getTabItems(t);
  const ui = useUIContext();
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState<Tab>(tabItems[0]);

  const isGroups = tab.type === "GROUPS";

  const chatterType = useMemo(() => getChatterType(tab, t), [tab, t]);

  const { data, loading } = useFindChattableUsersQuery({
    variables: { sessionId, chatterType },
  });

  const { data: groupsData, loading: groupsLoading } = useGetTheGroupsImInQuery(
    {
      variables: { sessionId },
    }
  );

  const groupsPeople = useMemo(() => {
    if (groupsData?.getTheGroupsImIn) {
      const filteredUsers = groupsData.getTheGroupsImIn.filter((u) =>
        u.name.toLowerCase().includes(search.toLowerCase())
      );
      return !search ? groupsData.getTheGroupsImIn : filteredUsers;
    }

    return [];
  }, [groupsData, search]);

  const users = useMemo(() => {
    if (data?.findChattableUsers) {
      if (stage === 2) return attendingUsers;
      return search.length < 4
        ? data.findChattableUsers
        : data.findChattableUsers.filter((u) =>
            u.name.toLowerCase().includes(search.toLowerCase())
          );
    } else return [];
  }, [data, search, attendingUsers, stage]);

  const groupedUsers = sortList([...users], language)
    .sort((a) => (a.chatterType2 === ChatterType.Trainer ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Athlete ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Family ? 1 : -1))
    .reduce((acc, value) => {
      if (stage === 1 && value.jointMainGroups.length) {
        value.jointMainGroups.forEach((g) => {
          if (!acc[g.name])
            acc[g.name] = {
              owner: g.owner,
              value: [],
            };
          acc[g.name]["value"].push(value);
        });
      } else {
        if (!acc["all"]) acc["all"] = { owner: null, value: [] };
        acc["all"]["value"].push(value);
      }
      return acc;
    }, []);

  const groupsCount = useMemo(() => {
    return Object.keys(groupedUsers).length;
  }, [groupedUsers]);

  return (
    <>
      <ControlBar search={search} setSearch={setSearch} />
      {Boolean(attendingUsers.length) && stage === 1 && (
        <SelectedUsersRow
          attendingUsers={attendingUsers}
          setAttendingUsers={setAttendingUsers}
        />
      )}
      <Wrapper>
        <TabsWrapper screenWidth={ui.width}>
          <Tabs
            tab={tab}
            setTab={setTab}
            tabs={tabItems}
            style={{ justifyContent: "space-between" }}
            tabStyle={{ marginLeft: 0, marginBottom: 0 }}
          />
        </TabsWrapper>
        {loading || groupsLoading ? (
          <LoaderWrapper>
            <Loader size="small" color={COLOR_BLUE} />
          </LoaderWrapper>
        ) : (
          <GroupedUserWrapper hasAttendance={!!attendingUsers.length}>
            <>
              {sortList(Object.keys(groupedUsers), language, (gu) => {
                const owner = groupedUsers[gu].owner;
                const ownerName = [owner?.firstName, owner?._lastName]
                  .filter((text) => !!text)
                  .join(" ");

                return ownerName + gu;
              })
                .sort((a, b) => {
                  if (a === "all" && b === "all") return 0;
                  if (a === "all") return 1;
                  if (b === "all") return -1;
                  return 0;
                })
                .map((gu, index) => (
                  <Team
                    key={index}
                    isCreateGroupChat
                    setAttendingUsers={setAttendingUsers}
                    search={search}
                    onClick={async (user, isAttending) => {
                      let currentAttendingUsers;
                      if (isAttending) {
                        currentAttendingUsers = attendingUsers.filter(
                          (au) => au.id !== user.id
                        );
                      } else {
                        currentAttendingUsers = attendingUsers.slice();
                        currentAttendingUsers.push(user);
                      }
                      await setAttendingUsers(currentAttendingUsers);
                      // if (currentAttendingUsers.length === 0) slide(0);
                      // else slide(1);
                    }}
                    currentKey={gu}
                    stage={stage}
                    groupsCount={groupsCount}
                    groups={groupedUsers}
                    attendingUsers={attendingUsers}
                    index={index}
                    showAttendingImages
                    isCreateChat
                  />
                ))}
            </>
            {(!isGroups || !groupsPeople.length) &&
              !users.length &&
              (tab?.type !== "ALL" || !groupsPeople.length) && (
                <StyledText
                  style={{ textAlign: "center" }}
                  fontWeight="bold"
                  color={COLOR_TEXT_DEFAULT}
                >
                  {` ${t("noOne")} `}
                </StyledText>
              )}
          </GroupedUserWrapper>
        )}
      </Wrapper>
    </>
  );
};
