import { CSSProperties, ReactNode } from "react";

import { ModalActionType, ModalType } from "./constants";

export interface ModelContextInitialStateValue {
  open: boolean;
  modal: ModalType;
  title: ReactNode;
  onBack?: () => void;
  titleAlignment: "flex-start" | "center" | "flex-end";
  params: any;
  style: CSSProperties;
}

export const initialModalContextState: ModelContextInitialStateValue = {
  open: false,
  modal: undefined,
  title: "",
  titleAlignment: "center",
  style: {},
  params: {},
};

export default (state: ModelContextInitialStateValue, action) => {
  switch (action.type) {
    case ModalActionType.OPEN:
      return { ...initialModalContextState, ...action.payload, open: true };
    case ModalActionType.CLOSE:
      return {
        ...initialModalContextState,
      };
    case ModalActionType.RENAME:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
