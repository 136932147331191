import React, { useState } from "react";
import Select from "react-select";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { Cell } from "../../../graphql";

type TableSelectProps = {
  cell: Cell;
  onCellUpdated: (cell: Cell, value: string) => void;
};

type Option = { label: string; value: string };

const customStyles = {
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    color: isSelected ? COLOR_WHITE : undefined,
    backgroundColor: isSelected
      ? COLOR_BLUE
      : isFocused
        ? COLOR_GRAY
        : COLOR_WHITE,
  }),
  menu: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
    width: 200,
  }),
  control: (provided) => ({
    ...provided,
    width: 75,
    minHeight: 25,
    marginRight: 10,
    border: "none",
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: COLOR_GRAY,
    borderRadius: 3,
    textAlign: "center",
    fontSize: 12,
    fontWeight: "700",
    color: COLOR_TEXT_DARK,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
    paddingRight: 5,
    zoom: 0.6,
    color: COLOR_BLUE,
  }),
  valueContainer: () => ({
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    width: 55,
    paddingLeft: 5,
    alignItems: "center",
  }),
};

export function TableSelect({ cell, onCellUpdated }: TableSelectProps) {
  const initialValue = cell.valuePerformed || "";
  const [selectedOption, setSelectedOption] = useState({
    label: initialValue,
    value: initialValue,
  });

  const options = cell?.options?.map((option) => ({
    label: option,
    value: option,
  }));

  const handleValueChange = (selectedOption: Option) => {
    setSelectedOption(selectedOption);
    onCellUpdated(cell, selectedOption.label);
  };

  return (
    <Select
      styles={customStyles}
      value={selectedOption}
      options={options}
      onChange={handleValueChange}
    />
  );
}
