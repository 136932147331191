import React, { useMemo } from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { XpsUser } from "../../../graphql";

import { SessionEditMemberItem } from "./SessionEditMemberItem";

type SessionEditMemberListProps = {
  data: XpsUser[];
  searchValue?: string;
  practice: any;
  refetch: any;
  emptyText: string;
};

const EmptyTextWrapper = styled.div`
  padding: 30px 15px;
  text-align: center;
`;

const EmptyText = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${COLOR_TEXT_DARK};
`;

export function SessionEditMemberList({
  data,
  searchValue,
  practice,
  refetch,
  emptyText,
}: SessionEditMemberListProps) {
  const userList = useMemo(() => {
    if (searchValue === undefined) {
      return data;
    }

    return (
      data?.filter((user) =>
        user?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      ) ?? []
    );
  }, [data, searchValue]);

  return (
    <>
      {userList && userList.length > 0 ? (
        userList.map((user) => (
          <SessionEditMemberItem
            key={user?.id}
            user={user}
            practice={practice}
            refetch={refetch}
          />
        ))
      ) : (
        <EmptyTextWrapper>
          <EmptyText>{emptyText}</EmptyText>
        </EmptyTextWrapper>
      )}
    </>
  );
}
