import {
  GroupTablePeriodizationDataItem,
  GroupTablePeriodizationDataPerAthleteItem,
} from "../../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";

import { SortByType } from "./Table";

export const sortNumericGroupTableRows = (
  data: GroupTablePeriodizationDataPerAthleteItem[],
  { colIndex, order }: SortByType
) =>
  data
    .filter((r) => {
      const colCel = r.data[colIndex];
      const currentValue = colCel.dataPerPeriod.value;

      return (
        currentValue &&
        currentValue !== 0 &&
        !isNaN(
          typeof currentValue == "string"
            ? parseFloat(currentValue as string)
            : currentValue
        )
      );
    })
    .sort(
      (r1, r2) =>
        (Number(r1.data[colIndex].dataPerPeriod.value) -
          Number(r2.data[colIndex].dataPerPeriod.value)) *
        order
    );

export const sortTextualGroupTableRows = (
  data: GroupTablePeriodizationDataPerAthleteItem[],
  { colIndex, order }: SortByType
) =>
  data
    .filter((r) => {
      const colCel = r.data[colIndex];
      const currentValue = colCel.dataPerPeriod.value;
      const currentTxt = colCel.dataPerPeriod.txt;

      return (
        currentTxt &&
        currentTxt !== "-" &&
        (!currentValue || currentValue === 0)
      );
    })
    .sort(
      (r1, r2) =>
        `${r1.data[colIndex].dataPerPeriod.txt}`.localeCompare(
          `${r2.data[colIndex].dataPerPeriod.txt}`
        ) * order
    );

export const sortEmptyGroupValues = (
  data: GroupTablePeriodizationDataPerAthleteItem[],
  { colIndex }: SortByType
) =>
  data.filter(
    (r) =>
      !r.data[colIndex].dataPerPeriod.value ||
      r.data[colIndex].dataPerPeriod.value === 0 ||
      r.data[colIndex].dataPerPeriod.txt === "-"
  );

const NON_BREAKING_HYPHEN = "\u{2011}";
export const MakeNonBreakingString = (input: string) =>
  input.replace("-", NON_BREAKING_HYPHEN);

export const groupFilterColumnsWithNoData = (
  data: GroupTablePeriodizationDataPerAthleteItem[]
) => {
  const completeData = [...data];

  const days: { date: string; isEmpty: boolean }[] = [];
  completeData?.forEach((athlete, athleteIndex) => {
    athlete.data.forEach((athleteDay, athleteDayIndex) => {
      if (athleteIndex === 0) {
        days.push({
          date: athleteDay.label,
          isEmpty:
            athleteDay.dataPerPeriod?.value === null ||
            athleteDay.dataPerPeriod?.txt === "-",
        });
      } else if (
        days.length > 0 &&
        days[athleteDayIndex] &&
        athleteDay.dataPerPeriod?.value !== null &&
        athleteDay.dataPerPeriod?.txt !== "-"
      ) {
        days[athleteDayIndex].isEmpty = false;
      }
    });
  });

  return completeData.map((athlete) => {
    const athleteData: GroupTablePeriodizationDataItem[] = [];
    days.forEach((day, dayIndex) => {
      if (!day.isEmpty) {
        athleteData.push(athlete.data[dayIndex]);
      }
    });

    return { ...athlete, data: athleteData };
  });
};
