import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";

import { COLOR_GRAY, COLOR_WHITE } from "../../colors";
import { ButtonVariant, GeneralButton } from "../../components/Button/";
import { ControlledCheckbox } from "../../components/HookForm/ControlledCheckbox";
import { ControlledInput } from "../../components/HookForm/ControlledInput";
import { useUserContext } from "../../contexts/User";
import {
  APP_VERSION,
  LoginResult,
  setClientParam,
  useAuthenticate2022Mutation,
  useUserProfileLazyQuery,
} from "../../graphql";
import { useCheckTermsAndInvitations } from "../../hooks/useCheckTermsAndInvitations";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { getDeviceType } from "../../utils/getDeviceType";
import { isTvEnv } from "../../utils/isTvEnv";
import { removeUnrememberedAccount } from "../../utils/removeUnrememberedAccount";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";

import { useLoginWithUserProfile } from "./hooks/useLoginWithUserProfile";
import { LoginScreenLayout } from "./LoginScreenLayout";
import { PinForm } from "./PinForm";
import { FormWrapper } from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);

interface LoginScreenProps {
  inModalView?: boolean;
}

interface LoginFormModel {
  username: string;
  password: string;
  rememberMe: boolean;
}

const APP_NAME = isTvEnv() ? "xps-expo-web-tv" : "xps-expo-web";

export default ({ inModalView }: LoginScreenProps) => {
  const userContext = useUserContext();

  const client = useApolloClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [shouldShowPinForm, setShouldShowPinForm] = useState(false);

  const { handleSubmit, getValues, watch, ...formMethods } =
    useForm<LoginFormModel>({
      defaultValues: {
        username: "",
        password: "",
        rememberMe: false,
      },
    });

  const rememberMe = watch("rememberMe");

  const [authenticate, authenticateResult] = useAuthenticate2022Mutation();
  const [getUserProfile, { data: userProfileData }] = useUserProfileLazyQuery();

  const { checkTermsAndInvitations } = useCheckTermsAndInvitations();

  useLoginWithUserProfile({
    userProfileData,
    sessionId: authenticateResult.data?.authenticate2022?.sessionId,
    inModalView,
    rememberMe,
  });

  useEffect(() => {
    // We need this due to electronic id
    Storage.setItem(StorageKeys.rememberMe, rememberMe);
  }, [rememberMe]);

  useEffect(() => {
    if (userContext?.sessionId && userContext?.id) {
      !inModalView && navigate(`/${ROUTING_CONFIG.calendar}`);
    }
  }, [userContext.sessionId, userContext.id, inModalView, navigate]);

  const signIn = async (data: LoginFormModel, pin: number = undefined) => {
    setError(null);
    setClientParam("sessionId", null);
    await client.clearStore();
    Storage.removeItem(StorageKeys.currentCalendarDate, window.sessionStorage);
    removeUnrememberedAccount();

    try {
      const authenticateResponse = await authenticate({
        variables: {
          username: data.username,
          password: data.password,
          app: APP_NAME,
          platform: window.navigator.userAgent,
          deviceType: getDeviceType(),
          appVersion: APP_VERSION,
          language: i18next.language,
          pin,
        },
      });

      if (authenticateResponse?.data?.authenticate2022?.userFriendlyRejection) {
        if (
          authenticateResponse.data.authenticate2022.result ===
          LoginResult.PinMissing
        ) {
          setShouldShowPinForm(true);
        } else {
          setError(
            authenticateResponse.data.authenticate2022.userFriendlyRejection
          );
        }

        return;
      }

      const sessionId = authenticateResponse?.data?.authenticate2022?.sessionId;

      if (sessionId) {
        await getUserProfile({
          variables: {
            sessionId,
            timezone: userContext?.timezone || dayjs.tz.guess(),
            language: userContext?.language || "en",
            accessLevelsForDisplayingInGlobalAccountSelector: true,
          },
        });

        await checkTermsAndInvitations(sessionId);
      } else {
        setError(t("invalidCredentials"));
      }
    } catch (error) {
      setError(t("unknownLoginError"));
      console.error(error);
    }
  };

  const onFormSubmit = (data: LoginFormModel) => signIn(data);

  return (
    <LoginScreenLayout
      error={error}
      isLoading={authenticateResult.loading}
      inModalView={inModalView}
      isPinFormVisible={shouldShowPinForm}
    >
      {shouldShowPinForm ? (
        <PinForm onSubmit={(data) => signIn(getValues(), data.pin)} />
      ) : (
        <FormWrapper onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider
            {...formMethods}
            handleSubmit={handleSubmit}
            watch={watch}
            getValues={getValues}
          >
            <ControlledInput
              labelColor={COLOR_GRAY}
              name="username"
              label={t("username")}
              spacing={{ marginBottom: 20 }}
              rules={{
                required: t("invalidName"),
              }}
            />
            <ControlledInput
              labelColor={COLOR_GRAY}
              name="password"
              label={t("password")}
              spacing={{ marginBottom: 40 }}
              type="password"
              rules={{
                required: t("invalidPassowrd"),
              }}
            />
            <GeneralButton
              fullWidth
              height={45}
              label={t("signIn")}
              type={ButtonVariant.Submit}
            />
            <ControlledCheckbox
              name="rememberMe"
              text={t("rememberMe")}
              textColor={COLOR_WHITE}
              centered
              marginTop={20}
              marginBottom={20}
            />
          </FormProvider>
        </FormWrapper>
      )}
    </LoginScreenLayout>
  );
};
