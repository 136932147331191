import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_DARK, COLOR_WHITE } from "../../colors";
import { GeneralButton } from "../Button/";
import { ChevronRightRoundedIcon } from "../Icons";

type LibraryHeaderProps = {
  title: string;
  icon: ReactNode;
  period?: string;
  description?: string;
  isProgram?: boolean;
  onAdd: () => void;
};

const HeaderWrapper = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  background-color: ${COLOR_WHITE};
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 30px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Period = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${COLOR_TEXT_DARK};
  margin-left: 10px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  height: 18px;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${COLOR_TEXT_DARK};
`;

const AddButtonWrapper = styled.div`
  padding: 10px 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  background-color: ${COLOR_WHITE};
  display: flex;
`;

export function LibraryHeader({
  title,
  icon,
  period,
  description,
  isProgram,
  onAdd,
}: LibraryHeaderProps) {
  const { t } = useTranslation();

  return (
    <>
      <HeaderWrapper>
        <Title>{title}</Title>
        {period ? (
          <PeriodWrapper>
            {icon}
            <Period>{period}</Period>
          </PeriodWrapper>
        ) : null}
        {description ? (
          <DescriptionWrapper>
            <IconWrapper>
              <ChevronRightRoundedIcon />
            </IconWrapper>
            <Description>{description}</Description>
          </DescriptionWrapper>
        ) : null}
      </HeaderWrapper>
      <AddButtonWrapper>
        <GeneralButton
          label={t(isProgram ? "addProgram" : "addToCalendar")}
          onClick={onAdd}
        />
      </AddButtonWrapper>
    </>
  );
}
