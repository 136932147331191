import React from "react";
import { useTranslation } from "react-i18next";

import { COLOR_BLUE } from "../../colors";
import { Chatter, ChatterType } from "../../graphql";
import StyledText from "../StyledText";

export const ChatMemberTypeText = ({
  user,
  withPadding = true,
}: {
  user: Pick<Chatter, "chatterType2">;
  withPadding?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {user.chatterType2 === ChatterType.Family && (
        <StyledText
          fontWeight="bold"
          fontSize={10}
          color={COLOR_BLUE}
          style={{ paddingRight: withPadding ? 10 : 0 }}
          cursor="default"
        >
          {t("family").toUpperCase()}
        </StyledText>
      )}
      {user.chatterType2 === ChatterType.Trainer && (
        <StyledText
          fontWeight="bold"
          fontSize={10}
          color={COLOR_BLUE}
          style={{ paddingRight: withPadding ? 10 : 0 }}
          cursor="default"
        >
          {t("coach").toUpperCase()}
        </StyledText>
      )}
    </>
  );
};
