import React from "react";

import styled from "styled-components";

import { Collections } from "../components/Collections";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { useUserContext } from "../contexts/User";

const HeaderWrapper = styled.div`
  z-index: 2;
`;

const CollectionsWrapper = styled.div`
  flex: 1;
  z-index: 1;
  display: flex;
`;

export default () => {
  const { isTrainer, isFamilyMember, id } = useUserContext();
  const focusedAthleteId = !isTrainer && !isFamilyMember && id;

  return (
    <ScreenContainer style={{ overflow: "hidden" }}>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <CollectionsWrapper>
        <Collections focusedAthleteId={focusedAthleteId} />
      </CollectionsWrapper>
    </ScreenContainer>
  );
};
