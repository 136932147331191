import React, { useState, useEffect } from "react";
import ReactJWPlayer from "react-jw-player";

import styled from "styled-components";

type MediaProps = {
  file: {
    id?: string;
    mime: string;
    url: any;
    __typename: string;
  };
};

const StyledImage = styled.img<{
  width: number;
}>`
  max-width: 100%;
  max-height: 100%;
  width: ${({ width }) => width}px;
  height: auto;
`;

const StyledModalWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 256px;
  height: 144px;
`;

export function Media({ file }: MediaProps) {
  const isPdf = file.mime === "application/pdf";
  const mimeSplit = file.mime ? file.mime.split("/") : [];
  const [imageSize, setImageSize] = useState<{
    width: number;
    height: number;
    ratioWidth: number;
  }>();

  const fileExtension = mimeSplit.length
    ? mimeSplit[mimeSplit.length - 1]
    : null;
  const isImage =
    fileExtension === "png" ||
    fileExtension === "jpg" ||
    fileExtension === "gif" ||
    fileExtension === "jpeg";
  const isVideo =
    fileExtension === "mp4" ||
    fileExtension === "wmv" ||
    fileExtension === "avi" ||
    fileExtension === "mpeg" ||
    fileExtension === "mov";

  useEffect(() => {
    if (isImage && !imageSize) {
      const img = new Image();
      img.src = file.url;
      img.onload = () => {
        const { innerWidth: maxWidth, innerHeight: maxHeight } = window;
        const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
        const ratioWidth = img.width * ratio;

        setImageSize({ width: img.width, height: img.height, ratioWidth });
      };
    }
  }, [file.url, isImage, imageSize]);

  return (
    <StyledModalWrapper>
      {isImage && (
        <StyledImage src={file.url} width={imageSize?.ratioWidth ?? 0} />
      )}
      {isPdf && (
        <iframe src={file.url} style={{ height: "90vh", width: "90vw" }} />
      )}
      {isVideo && (
        <VideoWrapper>
          <ReactJWPlayer
            playerId={file.id}
            playerScript="https://cdn.jwplayer.com/libraries/g9KQjRPV.js"
            file={file.url}
          />
        </VideoWrapper>
      )}
    </StyledModalWrapper>
  );
}
