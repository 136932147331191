import React, { useCallback, useEffect, useReducer, useRef } from "react";

import _debounce from "lodash/debounce";

import { useUserContext } from "../../contexts/User";
import {
  AgendaDocument,
  BodyChartItem as BodyChartItemType,
  CalendarDocument,
  useRegisterQuestionnaireBodyChartMutation,
} from "../../graphql";
import { BodyChart } from "../BodyParts/BodyChart";

import { ActionTypes, reducer } from "./bodyChartReducer";
import { ErrorText } from "./TrainingLoadRegistration";

type BodyPartClickAction = {
  type: "PART_PRESSED";
  id: number;
};

type InitPartsAction = {
  type: "INIT";
  item: any;
};

export type State = {
  isInitialized: boolean;
  categories: BodyChartCategory[];
  categoryCount: number;
  registrations: Registrations;
};

export type Registrations = {
  [id: number]: {
    categoryId: string;
    categoryNum: number;
    color: string;
  };
};

export type Action = BodyPartClickAction | InitPartsAction;

type BodyChartCategory = {
  categoryId: string;
  severity: number;
  description: string;
  red255: number;
  blue255: number;
  green255: number;
  deleted: boolean;
};

const initialState: State = {
  isInitialized: false,
  categoryCount: 0,
  registrations: {},
  categories: [],
};

type BodyChartItemProps = {
  item: BodyChartItemType;
  questionnaireId: string;
};

export const BodyChartItem = ({
  item,
  questionnaireId,
}: BodyChartItemProps) => {
  const { sessionId, language, timezone } = useUserContext();
  const categories = item.bcTemplate?.categories;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [registerBodyChart, { error }] =
    useRegisterQuestionnaireBodyChartMutation();
  const didMount = useRef(false);

  const onBodyPartPress = (id) => {
    dispatch({ type: ActionTypes.PART_PRESSED, id });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveRegistrations = useCallback(
    _debounce<any>((registrations) => {
      registerBodyChart({
        variables: {
          sessionId,
          language,
          timezone,
          input: {
            id: item.bcRegistration?.id || null,
            templateId: item.bcTemplate?.templateGuid,
            questionnaireId,
            bodyParts: Object.keys(registrations).map((bodypartId) => ({
              bodypartId,
              categoryId: registrations[bodypartId].categoryId,
              comment: "",
            })),
          },
        },
        refetchQueries: () => [CalendarDocument, AgendaDocument],
      });
    }, 1000),
    [item]
  );

  useEffect(() => {
    if (didMount.current) {
      saveRegistrations(state.registrations);
    } else if (state.isInitialized) {
      didMount.current = true;
    } else {
      dispatch({ type: ActionTypes.INIT, item });
    }
  }, [item, saveRegistrations, state.isInitialized, state.registrations]);

  const errorMessage = (error?.graphQLErrors?.[0] as any)?.exception
    ?.detailMessage;

  return (
    <div>
      {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}

      <BodyChart
        categories={categories}
        registrations={state.registrations}
        onBodyPartSelect={onBodyPartPress}
        name={item.bcTemplate?.name}
      />
    </div>
  );
};
