import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { StyledNumberInput } from "../../../StyledNumberInput";
import { DragDropContainer } from "../../../Wrappers/DragDropContainer";
import { FieldErrorMessage, MaxPerformersCountWrapper } from "../../styled";
import {
  KeyValuesWidgetFormDataItem,
  KeyValuesWidgetFormValue,
} from "../models";

import { KeyValuesDataItemSection } from "./KeyValuesDataItemSection";

const defaultNewEntity = {
  collectionId: "",
  measurementTemplateId: "",
  measurementTemplateName: "",
  attributeTemplateId: "",
  attributeTemplateName: "",
  attributeTemplateUnitName: "",
  customTitle: "",
  forAllGroups: false,
} as KeyValuesWidgetFormDataItem;

type KeyValuesDataSectionProps = {
  isGroupWidget?: boolean;
};

export function KeyValuesDataSection({
  isGroupWidget,
}: KeyValuesDataSectionProps) {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } =
    useFormikContext<KeyValuesWidgetFormValue>();

  return (
    <>
      <Section>
        <SectionTitle title={t("numberOfColumns")} />
        <MaxPerformersCountWrapper>
          <StyledNumberInput
            value={values.numberOfColumns}
            onChange={(value) => setFieldValue("numberOfColumns", value)}
            inputWidth={70}
            min={1}
            name="numberOfColumns"
            isInvalid={Boolean(errors.numberOfColumns)}
          />
        </MaxPerformersCountWrapper>
        {errors.numberOfColumns && (
          <FieldErrorMessage>{errors.numberOfColumns}</FieldErrorMessage>
        )}
      </Section>
      <Section>
        <SectionTitle title={t("data")} />
        <DragDropContainer
          fieldArrayName="items"
          buttonText={t("addItem")}
          defaultNewEntity={{
            ...defaultNewEntity,
            collectionId: values.items[values.items.length - 1].collectionId,
          }}
          items={values.items.map(
            (item, index) =>
              ({ remove, push, dragHandleProps }) => (
                <KeyValuesDataItemSection
                  key={`${item.measurementTemplateId}-${item.attributeTemplateId}-${index}`}
                  rowIndex={index}
                  remove={remove}
                  push={push}
                  dragHandleProps={dragHandleProps}
                  isGroupWidget={isGroupWidget}
                />
              )
          )}
        />
      </Section>
    </>
  );
}
