import { AggregateFunction } from "../../../../../graphql";
import { roundToTwo } from "../../../../../utils/number";
import { getValidUnitName } from "../../../../../utils/statistics";
import { StatisticsDataItem } from "../../../../Report/models";
import { ComparisonViewType } from "../../../models";

import { COMPARISON_AGGREGATION_LABEL } from "./constants";
import { GroupTableStatsValue } from "./models";

const NO_VALUE = "-";

export const prepareComparisonData = (
  dataForColumn: StatisticsDataItem[],
  comparisonView: ComparisonViewType,
  attributeId: string,
  attributeName: string,
  aggregateFunctions: AggregateFunction[]
): GroupTableStatsValue[] => {
  const aggregationForComparison = aggregateFunctions.includes(
    AggregateFunction.LastDayInRange
  )
    ? AggregateFunction.LastDayInRange
    : AggregateFunction.Latest;

  const stats = dataForColumn.find(
    (data) =>
      data.attribute.id === attributeId &&
      data.aggregateFunction === aggregationForComparison
  );

  if (!stats?.values || stats?.values?.length === 0) {
    return [
      {
        id: `${attributeId}-no-comparison`,
        aggregation: COMPARISON_AGGREGATION_LABEL,
        value: NaN,
        unitName:
          comparisonView === ComparisonViewType.PERCENTAGE
            ? "%"
            : getValidUnitName(stats?.attribute?.unitName),
        attributeName,
      } as GroupTableStatsValue,
    ];
  }

  const getValue = (diffPercent: number, diff: number) =>
    comparisonView === ComparisonViewType.PERCENTAGE
      ? diffPercent === undefined
        ? NO_VALUE
        : diffPercent === 0
          ? NaN
          : roundToTwo(diffPercent * 100)
      : diff === undefined
        ? NO_VALUE
        : roundToTwo(diff);

  return stats.values.flatMap(({ diffPercent, diff, txt }, index) => {
    const value = getValue(diffPercent, diff);

    return {
      id: `${stats.template.id}-comparison-${index}`,
      measurementTemplateId: stats.template.id,
      attributeTemplateId: stats.attribute.id,
      aggregation: COMPARISON_AGGREGATION_LABEL,
      value,
      count: value === NO_VALUE || isNaN(value) ? 0 : 1,
      unitName:
        value === NO_VALUE
          ? ""
          : comparisonView === ComparisonViewType.PERCENTAGE
            ? "%"
            : getValidUnitName(stats.attribute?.unitName),
      attributeName,
      txt,
    };
  });
};
