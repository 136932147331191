import { AggregateFunction } from "../../../../graphql";
import { ChartItemModel, ChartType } from "../model";

export interface ChartItemAttributes {
  aggregation: AggregateFunction | "";
  name: string;
  unit: string;
  collectionId: string;
  measurementId: string;
  attributeId: string;
  chartTypeOverride?: ChartType;
  showTeamAverage?: boolean;
  teamAverageChartType?: ChartType;
}

export type LoadDateForChartAxisType = (
  collectionId: string,
  measurementTemplateId: string,
  attributeId: string
) => Promise<{
  templateName: string;
  attributeUnitName: string;
}>;

export const prepareAttributes = (
  items: ChartItemModel[],
  templatesMap: Map<
    string,
    {
      templateName: string;
      attributeUnitName: string;
    }
  >
) =>
  items
    .map((item) => {
      const { templateName, attributeUnitName } =
        templatesMap.get(
          [item.dataType, item.templateGuid, item.attribute].join(":")
        ) ?? {};

      return {
        aggregation: item.aggregation,
        name: templateName ?? item.templateName,
        unit: attributeUnitName ?? "",
        collectionId: item.dataType,
        measurementId: item.templateGuid,
        attributeId: item.attribute,
        chartTypeOverride: item.chartTypeOverride,
        showTeamAverage: item.showTeamAverage,
        teamAverageChartType: item.teamAverageChartType,
      };
    })
    .filter((attr) => attr.measurementId && attr.attributeId);
