import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AttendanceCheckmarkIcon = memo(
  ({ width = 13, height = 13, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill="#E6E8EB" />
      <path d="M4 6L6 8L10 4" stroke="#3C434C" strokeWidth="1.5" />
    </svg>
  )
);
