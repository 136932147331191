import { AggregateFunction } from "../../../graphql";
import {
  AthleteSoccerStatisticsResponse,
  SoccerGameStatistics,
  SoccerPeriodStatistics,
} from "../../../services";
import {
  getAggregatedAthleteSoccerStatisticsDataKey,
  getAggregatedSoccerStatisticsDataKey,
  getAthleteSoccerStatisticsDataKey,
  getSoccerStatisticsDataKey,
} from "../../../utils/reports";

function getAggregateAttribute(aggregate: AggregateFunction) {
  switch (aggregate) {
    case AggregateFunction.Average:
      return "average";
    case AggregateFunction.Latest:
    case AggregateFunction.LatestForever:
      return "last";
    case AggregateFunction.Max:
    case AggregateFunction.MaxForever:
      return "max";
    case AggregateFunction.Min:
    case AggregateFunction.MinForever:
      return "min";
    case AggregateFunction.Sum:
      return "sum";
    default:
      return undefined;
  }
}

function getAthleteSoccerStatsDataByAggregateAttribute(
  soccerStats: AthleteSoccerStatisticsResponse,
  aggregateAttribute: string | undefined,
  isAllTime: boolean
) {
  if (!aggregateAttribute) {
    return soccerStats;
  }

  return soccerStats?.[isAllTime ? "aggregationTimeless" : "aggregation"]?.[
    aggregateAttribute
  ];
}

/**
 * Function to get athlete soccer statistics with custom columns.
 *
 * @template T - The type of the value to return if the measurementTemplateId or soccerStats is undefined.
 * @param {AthleteSoccerStatisticsResponse} soccerStats - The soccer statistics.
 * @param {string} measurementTemplateId - The ID of the measurement template.
 * @param {AggregateFunction} aggregate - attribute that selects the correct aggregated data
 * @param {T} [valueIfUndefined] - The value to return if the measurementTemplateId or soccerStats is undefined.
 *
 * @returns {number | string | T} - Returns the soccer statistics based on the measurement template ID.
 * If the measurement template ID or soccer stats is undefined, it returns the valueIfUndefined.
 */
export function getAthleteSoccerStatsWithCustomCols<T = undefined>(
  soccerStats: AthleteSoccerStatisticsResponse,
  measurementTemplateId: string,
  aggregate?: AggregateFunction,
  valueIfUndefined?: T
): string | number | T {
  if (!measurementTemplateId || !soccerStats) {
    return valueIfUndefined;
  }
  if (
    measurementTemplateId.includes("MinutesPerGame") &&
    aggregate !== AggregateFunction.Average
  ) {
    return valueIfUndefined;
  }

  const aggregateAttribute = getAggregateAttribute(aggregate);
  const selectedSoccerStats = getAthleteSoccerStatsDataByAggregateAttribute(
    soccerStats,
    aggregateAttribute,
    aggregate.includes("Forever")
  );

  const statsDataKeySplit: string[] = aggregateAttribute
    ? [getAggregatedAthleteSoccerStatisticsDataKey(measurementTemplateId)]
    : getAthleteSoccerStatisticsDataKey(measurementTemplateId).split(".");

  return (
    (statsDataKeySplit.length > 1
      ? selectedSoccerStats?.[statsDataKeySplit[0]]?.[
          statsDataKeySplit.slice(1).join(".")
        ]
      : selectedSoccerStats?.[statsDataKeySplit[0]]) ?? valueIfUndefined
  );
}

function getSoccerStatsDataByAggregateAttribute(
  soccerStats: SoccerGameStatistics,
  aggregateAttribute: string | undefined,
  isAllTime: boolean
) {
  if (!aggregateAttribute) {
    return soccerStats;
  }

  return soccerStats?.[isAllTime ? "aggregationTimeless" : "aggregation"]?.[
    aggregateAttribute
  ];
}

/**
 * Function to get soccer statistics with custom columns.
 *
 * @template T - The type of the value to return if the measurementTemplateId or soccerStats is undefined.
 * @param {Record<string, string | number | Record<string, string | number>>} soccerStats - The soccer statistics.
 * @param {string} measurementTemplateId - The ID of the measurement template.
 * @param {AggregateFunction} aggregate - attribute that selects the correct aggregated data
 * @param {T} [valueIfUndefined] - The value to return if the measurementTemplateId or soccerStats is undefined.
 *
 * @returns {number | string | T} - Returns the soccer statistics based on the measurement template ID.
 * If the measurement template ID or soccer stats is undefined, it returns the valueIfUndefined.
 */
export function getSoccerStatsWithCustomCols<T = undefined>(
  soccerStats: SoccerGameStatistics | SoccerPeriodStatistics,
  measurementTemplateId: string,
  aggregate?: AggregateFunction,
  valueIfUndefined?: T
): string | number | T {
  if (!measurementTemplateId || !soccerStats) {
    return valueIfUndefined;
  }

  const aggregateAttribute = getAggregateAttribute(aggregate);
  const selectedSoccerStats = getSoccerStatsDataByAggregateAttribute(
    soccerStats,
    aggregateAttribute,
    aggregate?.includes("Forever")
  );

  const statsDataKeySplit: string[] = aggregateAttribute
    ? [getAggregatedSoccerStatisticsDataKey(measurementTemplateId)]
    : getSoccerStatisticsDataKey(measurementTemplateId).split(".");

  return (
    (statsDataKeySplit.length > 1
      ? selectedSoccerStats?.[statsDataKeySplit[0]]?.[
          statsDataKeySplit.slice(1).join(".")
        ]
      : selectedSoccerStats?.[statsDataKeySplit[0]]) ?? valueIfUndefined
  );
}
