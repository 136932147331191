import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { COLOR_TEXT_DARK, COLOR_MEDIUM_GRAY } from "../../colors";
import { CustomTeamOption } from "../../components/CustomTeamOption";
import { Header } from "../../components/Header";
import ScreenContainer from "../../components/ScreenContainer";
import { StyledSelect } from "../../components/StyledSelect";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import { useUserContext } from "../../contexts/User";
import {
  TrainingProblem,
  useGetTrainingStatusGroupsQuery,
  useGetTrainingStatusQuery,
} from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isValidDateString } from "../../utils/date";

import PlayerSummaryTable from "./PlayerSummaryTable";
import { StatusOverview } from "./StatusOverview";

export interface SanitizedTrainingStatusCell {
  playerCol: string;
  statusCol: string;
  descCol: string;
  returnCol: string;
  thumb: string;
  severity: number;
  comment: string;
}

export function TrainingStatusScreen() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { sessionId, language, timezone } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();

  const {
    data: fetchedGroups,
    loading: isGroupsQueryLoading,
    refetch: refetchGroups,
  } = useGetTrainingStatusGroupsQuery({
    variables: {
      sessionId,
      language,
      timezone,
      selectedAccountId: selectedAccount?.id,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    refetchGroups({
      sessionId,
      selectedAccountId: selectedAccount?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, selectedAccount]);

  useEffect(() => {
    if (
      fetchedGroups?.trainingStatusGroups.length === 0 ||
      selectedAccount?.id === ALL_ACCOUNTS_ID
    ) {
      navigate(`/${ROUTING_CONFIG.calendar}`);
    }
  }, [fetchedGroups, navigate, selectedAccount?.id]);

  const options = useMemo(
    () =>
      fetchedGroups?.trainingStatusGroups.map((g) => ({
        label: g.name,
        value: g.id,
        isSubGroup: g.__typename === "SubGroup",
        isGroup: g.__typename === "Group",
      })) || [],
    [fetchedGroups]
  );
  const [selectedGroup, setSelectedGroup] = useState<string>("");

  const { data: fetchedTrainingStatuses } = useGetTrainingStatusQuery({
    variables: {
      sessionId,
      language,
      timezone,
      groupId: selectedGroup,
    },
    onCompleted: () => {
      if (!selectedGroup)
        setSelectedGroup(fetchedGroups?.trainingStatusGroups[0]?.id);
    },
  });

  const sanitizedCalculatedData = useMemo(() => {
    let available = 0,
      modified = 0,
      unavailable = 0;
    const data =
      (fetchedTrainingStatuses?.trainingStatus?.map((st) => {
        const mostSevereProblem: TrainingProblem = st.status.problems?.reduce(
          (acc, p) => {
            if (p.severity > acc?.severity || !acc) {
              acc = p;
            }
            return acc;
          },
          st[0]
        );

        if (!mostSevereProblem || mostSevereProblem?.severity === 0)
          available++;
        if (mostSevereProblem?.severity === 1) modified++;
        if (mostSevereProblem?.severity === 2) unavailable++;

        const msInADay = 8.64e7;
        const returnDateString =
          mostSevereProblem?.returnToFullTraining &&
          isValidDateString(
            mostSevereProblem.returnToFullTraining * msInADay
          ) &&
          new Date(
            mostSevereProblem.returnToFullTraining * msInADay
          ).toLocaleDateString();
        return {
          playerCol: st.user?.name || "",
          statusCol: mostSevereProblem?.status || t("available"),
          descCol: mostSevereProblem?.illness
            ? t("illness")
            : !mostSevereProblem?.status
              ? ""
              : t("injury"),
          returnCol: returnDateString,
          thumb: st.user?.thumb || "",
          severity: mostSevereProblem?.severity || 0,
          comment: mostSevereProblem?.coachComment || "",
          allProblems: st.status.problems,
        };
      }) as SanitizedTrainingStatusCell[]) || [];

    return {
      available,
      modified,
      unavailable,
      data,
    };
  }, [fetchedTrainingStatuses, t]);
  function handleSelectGroup(value: string | string[] | null): void {
    if (!Array.isArray(value) && value?.length) {
      setSelectedGroup(value);
    }
  }

  return (
    <ScreenContainer>
      <Header />
      <StyledContentWrapper>
        <SelectWrap isMobile={theme?.isMobile}>
          <span>{t("group")}</span>
          <StyledSelect
            options={options}
            value={selectedGroup}
            loading={isGroupsQueryLoading}
            onChange={handleSelectGroup}
            components={{ Option: CustomTeamOption }}
          />
        </SelectWrap>
        <StatusOverview
          available={sanitizedCalculatedData?.available}
          modified={sanitizedCalculatedData?.modified}
          unavailable={sanitizedCalculatedData?.unavailable}
        />
        {!!sanitizedCalculatedData?.data?.length && (
          <PlayerSummaryTable sanitizedData={sanitizedCalculatedData?.data} />
        )}
      </StyledContentWrapper>
    </ScreenContainer>
  );
}

export const StyledHeading = styled.h3`
  margin: 20px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

const StyledContentWrapper = styled.div`
  padding: 0 25px;
`;

const SelectWrap = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 20px 0 30px 0;
  span {
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${COLOR_MEDIUM_GRAY};
  }
  > * {
    width: ${({ isMobile }) => (isMobile ? "100%" : "384px")};
  }
`;
