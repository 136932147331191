import { useEffect } from "react";

import { UserPreferenceKeys } from "../../constants";
import {
  AccessToTrainer,
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
} from "../../graphql";
import { useIsMonitoring } from "../../hooks";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { useUserContext } from "../User";

import { generateAccessLevelLocalOverrideAccount } from "./utils/generateAccessLevelLocalOverrideAccount";

import { ALL_ACCOUNTS_ID } from ".";

const getPerModuleSelectedAccountOverrideKey = (module: string) =>
  `${UserPreferenceKeys.SELECTED_ACCOUNT_ID}-${module}`;

const MODULES_WITH_LOCAL_OVERRIDE = [
  ROUTING_CONFIG.collections,
  ROUTING_CONFIG.ListReports,
];

type AccessLevelModel = Pick<
  AccessToTrainer,
  "user" | "statistics" | "userManagement"
>;

type UseAccessLevelsModuleOverrideServerSynchProps = {
  selectedAccessLevel: AccessLevelModel;
  selectedAccessLevelLocalOverride: AccessLevelModel;
  overrideActiveForModule: string;
  isAllAccountOverrideActive: boolean;
  allAccountsAccount: AccessLevelModel;
  accessLevels: AccessToTrainer[];
  setSelectedAccessLevel: React.Dispatch<
    React.SetStateAction<AccessLevelModel>
  >;
  setSelectedAccessLevelLocalOverride: React.Dispatch<
    React.SetStateAction<AccessLevelModel>
  >;
  setIsAllAccountOverrideActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useAccessLevelsModuleOverrideServerSynch = ({
  overrideActiveForModule,
  accessLevels,
  setSelectedAccessLevelLocalOverride,
  selectedAccessLevel,
  selectedAccessLevelLocalOverride,
  isAllAccountOverrideActive,
  allAccountsAccount,
  setSelectedAccessLevel,
  setIsAllAccountOverrideActive,
}: UseAccessLevelsModuleOverrideServerSynchProps) => {
  const { sessionId } = useUserContext();

  const isFormMonitoring = useIsMonitoring();

  const [getPreference, { loading: loadingPreferences, data }] =
    useGetPreferenceLazyQuery({ fetchPolicy: "network-only" });
  const [setPreferences] = usePutPreferenceMutation();

  const loadedAccountId = data?.getPreference;
  const isSynchActive =
    MODULES_WITH_LOCAL_OVERRIDE.includes(overrideActiveForModule) &&
    selectedAccessLevel?.user?.id === ALL_ACCOUNTS_ID &&
    !isFormMonitoring;

  useEffect(() => {
    (async () => {
      if (sessionId && overrideActiveForModule && isSynchActive) {
        const preferencesResponse = await getPreference({
          variables: {
            sessionId,
            key: getPerModuleSelectedAccountOverrideKey(
              overrideActiveForModule
            ),
          },
        });

        const selectedUserAccessLevelId =
          preferencesResponse?.data?.getPreference ?? "ALL_ACCOUNTS";
        const selectedUserAccessLevel =
          accessLevels?.find(
            (accessLevel) => accessLevel.user?.id === selectedUserAccessLevelId
          ) ??
          // In fact, you can have local override for not globally available account see RB-1036
          generateAccessLevelLocalOverrideAccount(
            accessLevels,
            selectedUserAccessLevelId
          );

        setSelectedAccessLevelLocalOverride(selectedUserAccessLevel);
        setSelectedAccessLevel(allAccountsAccount);
        setIsAllAccountOverrideActive(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, overrideActiveForModule, accessLevels.length, isSynchActive]);

  useEffect(() => {
    const newSelectedAccountId = selectedAccessLevelLocalOverride?.user?.id;

    if (
      overrideActiveForModule &&
      selectedAccessLevelLocalOverride &&
      isAllAccountOverrideActive &&
      !loadingPreferences &&
      loadedAccountId !== newSelectedAccountId &&
      isSynchActive &&
      newSelectedAccountId &&
      sessionId
    ) {
      setPreferences({
        variables: {
          key: getPerModuleSelectedAccountOverrideKey(overrideActiveForModule),
          value: newSelectedAccountId,
          sessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionId,
    loadingPreferences,
    overrideActiveForModule,
    isAllAccountOverrideActive,
    selectedAccessLevelLocalOverride?.user?.id,
    isSynchActive,
  ]);
};
