import React, { useCallback, useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DEFAULT,
} from "../../colors";
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_VERTICAL_ROW_MARGIN,
  MAX_COLUMN_COUNT,
} from "../../constants/ReportGrid";
import { useReportContext } from "../../contexts/report";
import { useReportsContext } from "../../contexts/Reports";
import { useSelectedPerformersContext } from "../../contexts/selectedAthletes";
import { useUserContext } from "../../contexts/User";
import {
  useSaveReportTemplateMutation,
  useWidgetTemplatesQuery,
} from "../../graphql";
import {
  useScrollToWidget,
  usePrepareLayoutForSaving,
  useBottomWidgetResizeData,
  useBreakpoint,
  useAsyncMemo,
  useGetSelectedAccountIdInMonitoring,
} from "../../hooks";
import { useTableSortingPreference } from "../../hooks/table/useTableSortingPreference";
import { Loader } from "../Loader";
import {
  MIN_HEIGHT,
  MOBILE_PADDING_TOP,
} from "../Report/ChartWIdget/ChartWidgetComponent";
import { useEditReportWarning } from "../Report/hooks/useEditReportWarning";
import { MobileViewGridLayout } from "../Report/styled";
import { WidgetMode, WidgetWithData } from "../Report/WidgetWithData";
import { GroupWidget, WidgetType } from "../ReportCreateTable/models";
import StyledText from "../StyledText";

import { GridHeader } from "./GridHeader";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GridWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px ${({ theme }) => (theme.isMobile ? "0" : "10")}px;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR_GREY_ACTIVE};
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  flex: ${({ isLoading }) => (isLoading ? "1" : "none")};
  align-items: ${({ isLoading }) => (isLoading ? "center" : "unset")};
  min-height: ${({ isLoading }) => (isLoading ? "300px" : "auto")};
`;

const NoPerformerMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
`;

type GroupReportProps = {
  isEditMode?: boolean;
  toggleEditMode?(): void;
};

const DEFAULT_GRID_SM_BREAKPOINT_WIDTH = 768;
const MOBILE_CHART_RESIZE_RATIO_DUE_TO_ATHLETES_SECTION = 1.25;

export function GroupReport({
  isEditMode = false,
  toggleEditMode = () => {},
}: GroupReportProps) {
  const layoutRef = useRef();
  const layoutGridRef = useRef<any>();

  const { isMobile } = useTheme();
  const { t } = useTranslation();

  const { report, reportLoading, reportQueryCalled } = useReportContext();
  const { sessionId, language } = useUserContext();
  const { normalizeLayout } = usePrepareLayoutForSaving();
  const currentBreakpoint = useBreakpoint();
  const { attendingUsers, attendingTeams } = useSelectedPerformersContext();

  const [currentGridWidth, setCurrentGridWidth] = useState<number | null>(null);
  const [shouldResetLayout, setShouldResetLayout] = useState(false);

  const { getTableSortingPreference } = useTableSortingPreference();
  const {
    itemsToResize,
    onSetItemToResize,
    onSetItemsToHideInViewMode,
    includeInViewModeFilter,
    resetItemsToResize,
  } = useBottomWidgetResizeData({ isEditMode });

  const handleEditReport = useEditReportWarning({
    // @ts-ignore
    reportTemplate: report,
    onEdit: toggleEditMode,
  });

  const handleToggleEditMode = () => {
    if (isEditMode) {
      toggleEditMode();
    } else {
      handleEditReport();
    }
  };

  const [saveReport] = useSaveReportTemplateMutation();
  const [{ selectedGroup }] = useReportsContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const {
    data: generalWidgetsData,
    loading: widgetLoading,
    refetch: refetchGeneralWidgets,
  } = useWidgetTemplatesQuery({
    variables: {
      sessionId,
      accountId: selectedGroup?.owner?.id,
      parentReport: null,
      language,
    },
    fetchPolicy: "cache-and-network",
  });

  const { getWidgetElementId } = useScrollToWidget(
    reportLoading || widgetLoading
  );

  // We need this to prevent recalculation of grid on mobile devices because react-grid-layout do not use traditional width of screen
  useEffect(() => {
    setCurrentGridWidth(layoutGridRef?.current?.state?.width);
  }, [layoutGridRef?.current?.state?.width]);

  useEffect(() => {
    if (shouldResetLayout) {
      setShouldResetLayout(false);
      resetItemsToResize();
    }
  }, [shouldResetLayout, setShouldResetLayout, resetItemsToResize]);

  const widgets = useAsyncMemo(
    async () =>
      await Promise.all(
        (report?.widgets ?? []).map(async (widget) => {
          const widgetStoredPreference = await getTableSortingPreference(
            // @ts-ignore
            widget.id
          );

          if (widgetStoredPreference) {
            const newPreferences = {
              // @ts-ignore
              ...JSON.parse(widget.preferences),
              ...widgetStoredPreference,
            };
            return {
              ...widget,
              ...newPreferences,
            };
          } else {
            return {
              ...widget,
              ...("preferences" in widget
                ? JSON.parse(widget.preferences)
                : undefined),
            };
          }
        })
      ),
    [report, getTableSortingPreference]
  );

  const generalWidgets: GroupWidget[] = React.useMemo(
    () =>
      (generalWidgetsData?.reportWidgetTemplates ?? [])
        .map((widget) => ({
          ...widget,
          ...("preferences" in widget
            ? JSON.parse(widget.preferences)
            : undefined),
        }))
        .filter(
          (widget) =>
            widget.__typename === "ReportWidgetTemplateGroupTable" ||
            widget.isGroupWidget
        ),
    [generalWidgetsData]
  );

  const layout = normalizeLayout(report?.layout ?? [])
    ?.map((item: ReactGridLayout.Layout) => {
      const resizeItem = itemsToResize.find(
        (resizeItem) => resizeItem.itemId === item.i
      );

      return {
        ...item,
        h:
          resizeItem && !isEditMode
            ? Math.ceil(resizeItem.scrollHeight / DEFAULT_ROW_HEIGHT)
            : item.h,
        isDraggable: item.i.includes(WidgetType.PLACEHOLDER)
          ? false
          : isEditMode,
        isResizable: item.i.includes(WidgetType.PLACEHOLDER)
          ? false
          : isEditMode,
      };
    })
    .filter((item) => includeInViewModeFilter(item.i))
    .sort((first, second) => first.y - second.y);

  const filteredWidgets = layout
    ?.map((layoutItem: ReactGridLayout.Layout) => {
      const widgetExists = !layoutItem.i.includes(WidgetType.PLACEHOLDER)
        ? widgets?.find((w) => w.id === layoutItem.i)
        : undefined;

      const columnXPosition = currentBreakpoint === "xxl" ? layoutItem.x : 0;

      return widgetExists
        ? {
            ...widgetExists,
            columnXPosition,
            printWidth: isMobile
              ? "100%"
              : `${(layoutItem.w / MAX_COLUMN_COUNT) * 100}%`,
            printHeight: `${layoutItem.h * DEFAULT_ROW_HEIGHT}px`,
          }
        : {
            id: layoutItem.i,
            type: WidgetType.PLACEHOLDER,
            columnXPosition,
            printWidth: isMobile ? "100%" : `${(1 / MAX_COLUMN_COUNT) * 100}%`,
            printHeight: `${DEFAULT_ROW_HEIGHT}px`,
          };
    })
    .filter((item) => includeInViewModeFilter(item.id))
    .filter((item) => !isMobile || item.type !== WidgetType.PLACEHOLDER);

  const onLayoutChange = useCallback(
    async (currentLayout: ReactGridLayout.Layout[]) => {
      if (report) {
        const preferences = JSON.parse(report.preferences);

        await saveReport({
          variables: {
            sessionId,
            accountId: selectedAccountId,
            input: {
              preferences: JSON.stringify({
                ...preferences,
                layout:
                  currentGridWidth > DEFAULT_GRID_SM_BREAKPOINT_WIDTH
                    ? normalizeLayout(currentLayout)
                    : currentLayout,
              }),
              id: report.id,
            },
          },
        });
      }
    },
    [
      currentGridWidth,
      report,
      sessionId,
      saveReport,
      normalizeLayout,
      selectedAccountId,
    ]
  );

  if (!report && reportQueryCalled && !reportLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          paddingTop: 30,
          paddingBottom: 30,
        }}
      >
        <StyledText style={{ fontWeight: "bold" }}>
          {t("noContentAvailable")}
        </StyledText>
      </div>
    );
  }

  const performers = [...attendingUsers, ...attendingTeams];

  const shouldRenderWidgetsWithoutGrid = isMobile && !isEditMode;

  const GridLayoutComponent = shouldRenderWidgetsWithoutGrid
    ? MobileViewGridLayout
    : ResponsiveGridLayout;

  const isProcessingOrLoadingData =
    filteredWidgets?.length === 0 || shouldResetLayout || reportLoading;

  return (
    <GridWrapper isLoading={isProcessingOrLoadingData}>
      <GridHeader
        isEditMode={isEditMode}
        parentReport={report}
        toggleEditMode={handleToggleEditMode}
        filteredWidgets={filteredWidgets}
      />
      {!performers.length && !isEditMode ? (
        <NoPerformerMessageWrapper>
          <StyledText
            color={COLOR_TEXT_DEFAULT}
            fontSize={14}
            fontWeight="bold"
          >
            {t("noPerformerSelected")}
          </StyledText>
        </NoPerformerMessageWrapper>
      ) : isProcessingOrLoadingData ? (
        <Loader size="large" color={COLOR_BLUE} />
      ) : (
        layout && (
          <GridLayoutComponent
            ref={layoutGridRef}
            innerRef={layoutRef}
            key={report.id}
            onDragStop={onLayoutChange}
            onResizeStop={onLayoutChange}
            width={600}
            rowHeight={DEFAULT_ROW_HEIGHT}
            layouts={{ lg: layout }}
            onBreakpointChange={() => {
              setShouldResetLayout(true);
            }}
            cols={{
              xxs: 1,
              xs: 1,
              sm: MAX_COLUMN_COUNT,
              md: MAX_COLUMN_COUNT,
              lg: MAX_COLUMN_COUNT,
            }}
            containerPadding={{
              xxs: [0, 10],
              xs: [0, 10],
              sm: [0, 10],
              md: [10, 10],
              lg: [10, 10],
            }}
            margin={{
              xxs: [0, DEFAULT_VERTICAL_ROW_MARGIN],
              xs: [0, DEFAULT_VERTICAL_ROW_MARGIN],
              sm: [0, DEFAULT_VERTICAL_ROW_MARGIN],
              md: [10, DEFAULT_VERTICAL_ROW_MARGIN],
              lg: [10, DEFAULT_VERTICAL_ROW_MARGIN],
            }}
            useCSSTransforms={false}
            measureBeforeMount={true}
          >
            {filteredWidgets.map((widget, i) => (
              <div
                id={getWidgetElementId(i)}
                data-id={widget.id}
                key={widget.id}
                data-grid={layout[i]}
                className={`${isEditMode ? "widget-container" : ""}`}
                style={{
                  ...(shouldRenderWidgetsWithoutGrid
                    ? {
                        height:
                          widget.type === WidgetType.CHART
                            ? Math.max(
                                layout[i].h *
                                  DEFAULT_ROW_HEIGHT *
                                  MOBILE_CHART_RESIZE_RATIO_DUE_TO_ATHLETES_SECTION,
                                isMobile
                                  ? MIN_HEIGHT + MOBILE_PADDING_TOP
                                  : MIN_HEIGHT
                              )
                            : "auto",
                        marginBottom: "32px",
                        marginTop: "12px",
                      }
                    : {}),
                }}
              >
                <WidgetWithData
                  widget={widget}
                  report={report}
                  readOnly={!isEditMode}
                  layoutIndex={i}
                  generalWidgets={generalWidgets}
                  gridItemId={getWidgetElementId(i)}
                  mode={WidgetMode.GROUP}
                  setItemToResize={onSetItemToResize}
                  setItemsToHideInViewMode={onSetItemsToHideInViewMode}
                  layoutRef={layoutRef}
                  refetchGeneralWidgets={refetchGeneralWidgets}
                />
              </div>
            ))}
          </GridLayoutComponent>
        )
      )}
    </GridWrapper>
  );
}
