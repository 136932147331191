import React, { memo } from "react";

import styled, { css } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../colors";

import { SvgIconProps } from "./model";

interface Props extends SvgIconProps {
  isChecked?: boolean;
  isGrey?: boolean;
  variant?: "default" | "disabled";
  size?: "small" | "large";
}

const EmptyCheckbox = styled.div<{
  size: number;
  borderColor?: string;
  backgroundColor: string;
  opacity?: number;
}>`
  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${({ backgroundColor }) => backgroundColor};
  opacity: ${({ opacity }) => opacity ?? 1};
  border-radius: 2.5px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

export const CheckboxIcon = memo(
  ({
    isChecked,
    style,
    size = "small",
    variant = "default",
    isGrey = false,
    ...props
  }: Props) => {
    const width = size === "large" ? 21 : 15;
    const height = size === "large" ? 21 : 15;

    const colors = (): {
      backgroundColor: string;
      borderColor?: string;
      opacity?: number;
    } => {
      if (isChecked) {
        switch (variant) {
          case "disabled":
            return { backgroundColor: COLOR_BLUE, opacity: 0.35 };
          default:
            return { backgroundColor: COLOR_BLUE };
        }
      }

      switch (variant) {
        case "disabled":
          return {
            backgroundColor: COLOR_GREY_ACTIVE,
            borderColor: isGrey ? COLOR_GRAY : COLOR_WHITE,
            opacity: 0.35,
          };
        default:
          return {
            backgroundColor: isGrey ? COLOR_GRAY : COLOR_WHITE,
            borderColor: COLOR_GREY_ACTIVE,
          };
      }
    };

    if (isChecked) {
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ flexShrink: 0, ...style }}
          {...props}
        >
          <rect
            width="15"
            height="15"
            rx="3"
            opacity={colors()?.opacity ?? 1}
            fill={colors()?.backgroundColor}
          />
          <path d="M4 7L6.5 9.5L11.5 4.5" stroke="white" strokeWidth="1.5" />
        </svg>
      );
    } else {
      return (
        <EmptyCheckbox
          size={width}
          {...colors()}
          id="checkboxIcon"
          style={style}
        />
      );
    }
  }
);
