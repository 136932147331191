import React, { memo } from "react";

export const InstagramIcon = memo(() => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 7C1 3.68629 3.68629 1 7 1H18C21.3137 1 24 3.68629 24 7V18C24 21.3137 21.3137 24 18 24H7C3.68629 24 1 21.3137 1 18V7ZM21 5.5C21 6.32843 20.3284 7 19.5 7C18.6716 7 18 6.32843 18 5.5C18 4.67157 18.6716 4 19.5 4C20.3284 4 21 4.67157 21 5.5ZM16 12.5C16 14.433 14.433 16 12.5 16C10.567 16 9 14.433 9 12.5C9 10.567 10.567 9 12.5 9C14.433 9 16 10.567 16 12.5ZM18 12.5C18 15.5376 15.5376 18 12.5 18C9.46243 18 7 15.5376 7 12.5C7 9.46243 9.46243 7 12.5 7C15.5376 7 18 9.46243 18 12.5Z"
      fill="#3C434C"
    />
  </svg>
));
