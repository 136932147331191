import React from "react";
import { TFunction, useTranslation } from "react-i18next";

import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";

import { Checkbox } from "../../../Checkbox";
import { IconSelectField } from "../../../IconSelectField";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { StyledSwitch } from "../../../StyledSwitch";
import { ChartType, ChartWidgetModel } from "../model";

const getChartTypeOptions = (t: TFunction<"common">) => [
  { label: t("column"), value: ChartType.COLUMN },
  { label: t("bar"), value: ChartType.BAR },
  { label: t("line"), value: ChartType.LINE },
];

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
`;

const SwitchWrapper = styled.div`
  margin-left: 30px;
`;

export function ChartTypeSelectSection() {
  const { t } = useTranslation();
  const { values, setValues, setFieldValue } =
    useFormikContext<ChartWidgetModel>();

  return (
    <Section style={{ zIndex: 100 }}>
      <SectionTitle title={t("chartType")} />
      <FieldWrapper>
        <Field name="chartType">
          {({ field, form }: FieldProps) => (
            <IconSelectField
              field={field}
              form={form}
              options={getChartTypeOptions(t)}
            />
          )}
        </Field>

        <SwitchWrapper>
          <StyledSwitch
            value={values.showLegend}
            onValueChange={(value) =>
              setValues((prevValues) => ({
                ...prevValues,
                showLegend: value,
                includeZero: false,
              }))
            }
            label={t("showLegend")}
          />
        </SwitchWrapper>
      </FieldWrapper>

      {values.chartType === ChartType.LINE && (
        <Checkbox
          text={t("includeZero")}
          check={(value) => setFieldValue("includeZero", value)}
          checked={values.includeZero ?? false}
        />
      )}
    </Section>
  );
}
