import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";

import { useDefaultDateFormat } from "./useDateFormat";

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = ONE_MINUTE * 60;
const ONE_DAY = ONE_HOUR * 24;

export const useTimeAgoInfo = () => {
  const { t } = useTranslation();
  const { dateFormat } = useDefaultDateFormat();

  const getDateInfo = (itemDate: Dayjs) => {
    const now = dayjs();
    const timeDiff = now.diff(itemDate);

    if (timeDiff < ONE_MINUTE) {
      return t("fewSecondsAgo");
    }

    if (timeDiff < ONE_HOUR) {
      return t("xMinutesAgo", { x: now.diff(itemDate, "minutes") });
    }

    if (timeDiff < ONE_DAY) {
      return t("xHoursAgo", { x: now.diff(itemDate, "hours") });
    }

    return itemDate.format(dateFormat);
  };

  return { getDateInfo };
};
