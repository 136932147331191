import React, { memo } from "react";

export const YoutubeIcon = memo(() => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.8564 6.77452C23.5838 5.74804 22.7805 4.93962 21.7607 4.66527C19.9122 4.16675 12.4998 4.16675 12.4998 4.16675C12.4998 4.16675 5.08749 4.16675 3.23892 4.66527C2.21909 4.93966 1.41587 5.74804 1.14326 6.77452C0.647949 8.63506 0.647949 12.5169 0.647949 12.5169C0.647949 12.5169 0.647949 16.3988 1.14326 18.2593C1.41587 19.2858 2.21909 20.0605 3.23892 20.3349C5.08749 20.8334 12.4998 20.8334 12.4998 20.8334C12.4998 20.8334 19.9121 20.8334 21.7607 20.3349C22.7805 20.0605 23.5838 19.2858 23.8564 18.2593C24.3517 16.3988 24.3517 12.5169 24.3517 12.5169C24.3517 12.5169 24.3517 8.63506 23.8564 6.77452ZM10.0756 16.0414V8.99249L16.2708 12.517L10.0756 16.0414Z"
      fill="#3C434C"
    />
  </svg>
));
