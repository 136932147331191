import React, { ReactNode, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../../colors";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";
import { conversationWrapperId } from "../Chat";

const Wrapper = styled.div`
  flex: 1;
  padding: 15px 30px 0 330px;
  margin-left: -300px;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  height: 60px;
`;

const Button = styled.div`
  background-color: ${COLOR_BLUE};
  padding: 10px 15px;
  border-radius: 32px;
  cursor: pointer;
`;

type ConversationWrapperProps = {
  edges;
  fetchingMore: boolean;
  onFetchMore: () => void;
  hasChatNextPage: boolean;
  children: ReactNode;
};

export const ConversationWrapper = ({
  edges,
  fetchingMore,
  onFetchMore,
  hasChatNextPage,
  children,
}: ConversationWrapperProps) => {
  const { t } = useTranslation();

  const [currentContentSize, setCurrentContentSize] = useState(0);
  const [scrollPositionIsSet, setScrollPositionIsSet] = useState(true);

  const handleScroll = async () => {
    const conversationWrapper = document.getElementById(conversationWrapperId);
    setCurrentContentSize(conversationWrapper.scrollHeight);
  };

  useEffect(() => {
    if (edges.length >= 21 && !fetchingMore) {
      setScrollPositionIsSet(true);
      if (currentContentSize) {
        const conversationWrapper = document.getElementById(
          conversationWrapperId
        );
        if (conversationWrapper.scrollHeight - currentContentSize > 0) {
          conversationWrapper.scrollTop =
            conversationWrapper.scrollHeight - currentContentSize;
        }
      }
    }
  }, [edges, fetchingMore, currentContentSize]);

  useEffect(() => {
    const conversationWrapper = document.getElementById(conversationWrapperId);
    const conversationWrapperScrollHeight = conversationWrapper.scrollHeight;
    conversationWrapper.scrollTop = conversationWrapperScrollHeight;
    setCurrentContentSize(conversationWrapperScrollHeight);
  }, []);

  return (
    <Wrapper onScroll={handleScroll} id={conversationWrapperId}>
      {hasChatNextPage && (
        <ButtonWrapper>
          {fetchingMore || !scrollPositionIsSet ? (
            <Loader />
          ) : (
            <Button
              onClick={() => {
                setScrollPositionIsSet(false);
                onFetchMore();
              }}
            >
              <StyledText color={COLOR_WHITE} fontWeight={500}>
                {t("loadMoreMessages")}
              </StyledText>
            </Button>
          )}
        </ButtonWrapper>
      )}
      <div
        style={
          scrollPositionIsSet
            ? { opacity: 1, transition: "opacity 250ms" }
            : { opacity: 0, transition: "opacity 250ms" }
        }
      >
        {children}
      </div>
    </Wrapper>
  );
};
