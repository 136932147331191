import React, { useEffect, useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import styled, { css } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_RED_ACTIVE,
  COLOR_TEXT_DEFAULT,
} from "../../colors";
import { useNewChatContext } from "../../contexts/messaging/NewChatProvider";
import { useNotificationsContext } from "../../contexts/notifications";
import { useUserContext } from "../../contexts/User";
import {
  ChatterType,
  CreateGroupChatMutationVariables,
  useCreateGroupChatMutation,
  useGetCreateNewChatGroupsQuery,
  useMessageList2Query,
  useSaveChatMessageMutation,
  WhoCanPost,
} from "../../graphql";
import { MessageOverviewPaginated } from "../../graphql/queries/MessageOverviewPaginated";
import { useFindChattableUsers } from "../../hooks/data/useFindChattableUsers";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { sortList } from "../../utils/sortList";
import { CreateTeamChatStage2ModalBody } from "../Communication/CreateTeamChatStage2ModalBody";
import { Loader } from "../Loader";
import StyledText from "../StyledText";
import Tabs, { Tab } from "../Tabs";

import { BottomBar } from "./ChatModals/BottomBar";
import { ControlBar } from "./ChatModals/ControlBar";
import { GroupsTeam } from "./ChatModals/GroupsTeam";
import { createChatWithMsg, createGroupUpdateCb } from "./ChatModals/misc";
import { ChatTabs } from "./ChatModals/model";
import { getChatterType } from "./ChatModals/utils";
import { Team } from "./Components/Team";

const getTabItems = (
  t: TFunction<"common">,
  isTrainer: boolean,
  hasFamilyToChat: boolean
) => [
  { id: ChatTabs.ALL, title: t("all").toUpperCase() },
  ...(isTrainer
    ? [
        {
          id: ChatTabs.TEAMS,
          title: t("teams").toUpperCase(),
        },
      ]
    : []),
  {
    id: ChatTabs.COACHES,
    title: t("coaches").toUpperCase(),
  },
  ...(isTrainer
    ? [
        {
          id: ChatTabs.ATHLETES,
          title: t("athletes").toUpperCase(),
        },
      ]
    : []),

  ...(hasFamilyToChat
    ? [
        {
          id: ChatTabs.FAMILY,
          title: t("family").toUpperCase(),
        },
      ]
    : []),
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 90vh;
`;

const Stage2Container = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: 30px;
    padding-left: 30px;
  `
);

const TabsWrapper = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(90vh - 256px);
`;

const CreateNewChat = ({ closeModal, msg, onRowPress, createChatOverride }) => {
  const user = useUserContext();
  const { t } = useTranslation();
  const client = useApolloClient();

  const { sessionId, language } = useUserContext();
  const [search, setSearch] = useState("");
  const {
    selectedGroupId,
    autoAddTrainers,
    autoAddFamily,
    autoAddAthletes,
    forAllAutoAddedFriendsOfChatter,
    stage,
    whoCanPost,
    groupName,
    setGroupName,
    setHasFamilyToChat,
    hasFamilyToChat,
  } = useNewChatContext();
  const [tab, setTab] = useState<Tab>(
    getTabItems(t, isRealTrainer(user), false)[0]
  );
  const [sendMessage] = useSaveChatMessageMutation();
  const { showWarningNotification } = useNotificationsContext();
  const chatterType = useMemo(() => getChatterType(tab, t), [tab, t]);

  const isGroups = tab.id === ChatTabs.TEAMS;
  const { familyMembers, currentChatterTypeUsers, loading } =
    useFindChattableUsers({
      sessionId,
      chatterType,
    });

  const { refetch } = useMessageList2Query({
    skip: true,
  });

  const [createGroup, { error }] = useCreateGroupChatMutation();
  const { data: groupsData, loading: groupsLoading } =
    useGetCreateNewChatGroupsQuery({
      variables: {
        sessionId,
        requireGroupChatAllowed: true,
        archivedGroupsToo: false,
        requireEditability: false,
        includeGroupsWhereImAdmin: true,
        language,
      },
      fetchPolicy: "cache-first",
      skip: !sessionId || chatterType !== ChatterType.RealGroup,
    });

  const users = useMemo(() => {
    if (currentChatterTypeUsers) {
      return search.length < 4
        ? currentChatterTypeUsers
        : currentChatterTypeUsers.filter((u) =>
            u.name.toLowerCase().includes(search.toLowerCase())
          );
    }
    return [];
  }, [currentChatterTypeUsers, search]);

  const tabItems = getTabItems(
    t,
    isRealTrainer(user),
    familyMembers.length > 0
  );

  const onCreateChat = async (user) => {
    if (msg) {
      const { messageBody } = msg;
      await createChatWithMsg(
        msg,
        [user],
        async (_, au, files, attachments) => {
          return sendMessage({
            variables: {
              sessionId,
              language,
              input: {
                receiver: au.id,
                files,
                attachments,
                text: messageBody,
              },
            },
          });
        }
      );
    }

    const variables: CreateGroupChatMutationVariables = {
      sessionId,
      name: groupName || t("newGroupChat"),
      autoAddAthletes,
      autoAddFamily,
      autoAddTrainers,
      onlyTrainersCanPost: whoCanPost === WhoCanPost.Trainers,
      onlyAdminsCanPost: whoCanPost === WhoCanPost.Admins,
      linkedGroup: selectedGroupId || undefined,
      forAllAutoAddedFriendsOf:
        forAllAutoAddedFriendsOfChatter?.id || undefined,
    };

    if ((isGroups || selectedGroupId) && !createChatOverride) {
      await createGroup({
        variables,
        update: createGroupUpdateCb(
          sessionId,
          language,
          groupName ?? user?.name ?? t("groupChat"),
          async (newContact) => {
            const { data } = await refetch({
              sessionId,
              groupOrFriend: newContact.id as string,
            });

            const avatarList = data?.messageList2?.avatars2;

            if (avatarList && !avatarList.length) {
              return showWarningNotification(t("chatCreationWarning"));
            }
            await client.refetchQueries({
              include: [MessageOverviewPaginated],
            });
            await onRowPress(newContact);
            closeModal();
          }
        ),
      });
    } else {
      await onRowPress(createChatOverride ? variables : user);
      await closeModal();
    }
  };

  useEffect(() => {
    const familyTab = tabItems?.find(
      (newTabItem) => newTabItem.id === ChatTabs.FAMILY
    );
    const newHasFamilyToChat = !!familyTab;
    if (newHasFamilyToChat !== hasFamilyToChat) {
      setHasFamilyToChat(newHasFamilyToChat);
    }
  }, [hasFamilyToChat, setHasFamilyToChat, tabItems]);

  const groupedUsers = sortList([...users], language)
    .sort((a) => (a.chatterType2 === ChatterType.Trainer ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Athlete ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Family ? 1 : -1))
    // .filter(a => (!user.isTrainer || user.isFamilyMember) ? a. :)
    .reduce((acc, value) => {
      if (value.jointMainGroups.length) {
        value.jointMainGroups.forEach((g) => {
          if (!acc[g.name]) acc[g.name] = { owner: g.owner, value: [] };
          acc[g.name]["value"].push(value);
        });
      } else {
        if (!acc["all"]) acc["all"] = { owner: null, value: [] };
        acc["all"]["value"].push(value);
      }
      return acc;
    }, []);

  const groupsCount = useMemo(() => {
    return Object.keys(groupedUsers).length;
  }, [groupedUsers]);

  const shouldDisplayTabs = user.isFamilyMember || user.isTrainer;
  const showNoOneMsg = !users.length && !isGroups;

  return (
    <Container>
      <ControlBar
        search={search}
        setSearch={setSearch}
        shouldDisplayBottomBorder={!shouldDisplayTabs}
      />
      {stage === 1 && shouldDisplayTabs && (
        <TabsWrapper>
          <Tabs
            tab={tab}
            setTab={setTab}
            tabs={tabItems}
            tabStyle={{ marginLeft: 0, marginBottom: 0 }}
            spaceBetween={30}
          />
        </TabsWrapper>
      )}
      <div>
        {loading || groupsLoading ? (
          <LoaderWrapper>
            <Loader size="small" color={COLOR_BLUE} />
          </LoaderWrapper>
        ) : (
          <>
            <div
              style={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                height:
                  stage === 1
                    ? `calc(90vh - ${shouldDisplayTabs ? 229 : 213}px)`
                    : "auto",
              }}
            >
              {isGroups && isRealTrainer(user) && (
                <GroupsTeam search={search} groupsData={groupsData} />
              )}
              {stage === 1 &&
                sortList(Object.keys(groupedUsers), language, (gu) => {
                  const owner = groupedUsers[gu].owner;
                  const ownerName = [owner?.firstName, owner?._lastName]
                    .filter((text) => !!text)
                    .join(" ");

                  return ownerName + gu;
                })
                  .sort((a, b) => {
                    if (a === "all" && b === "all") return 0;
                    if (a === "all") return 1;
                    if (b === "all") return -1;
                    return 0;
                  })
                  .map((gu, index) => {
                    return (
                      <Team
                        key={index}
                        withOwnerName={true}
                        search={search}
                        groupsCount={groupsCount}
                        currentKey={gu}
                        // @ts-ignore
                        groups={groupedUsers}
                        stage={stage}
                        index={index}
                        onClick={async (user) => {
                          await onCreateChat(user);
                        }}
                        withGroupIcon={false}
                        isCreateChat
                      />
                    );
                  })}
              {showNoOneMsg && (
                <StyledText
                  style={{ textAlign: "center", marginTop: 20 }}
                  fontWeight="bold"
                  color={COLOR_TEXT_DEFAULT}
                >
                  {` ${t("noOne")} `}
                </StyledText>
              )}
            </div>
          </>
        )}
      </div>
      {stage === 2 && (
        <Stage2Container>
          <CreateTeamChatStage2ModalBody
            users={users}
            groupName={groupName}
            setGroupName={setGroupName}
            groupsData={groupsData?.getTheGroupsImIn}
          />

          {error && (
            <StyledText
              centerText
              color={COLOR_RED_ACTIVE}
              style={{ padding: "10px 0" }}
            >
              {t("groupCreationError")} {!groupName && t("missingGroupName")}
            </StyledText>
          )}
        </Stage2Container>
      )}
      <BottomBar
        variant="dm"
        onRowPress={onRowPress}
        closeModal={closeModal}
        isTrainer={user?.isTrainer}
        isFamilyMember={user?.isFamilyMember}
        isMultistage={(isGroups || selectedGroupId) && stage === 2}
        done={() => onCreateChat(msg)}
        hideBackButton={createChatOverride}
      />
    </Container>
  );
};

export default CreateNewChat;
