import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChartLineIcon = memo(
  ({
    width = 25,
    height = 25,
    isActive = false,
    tintColor = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12154_31116)">
        {isActive ? (
          <>
            <rect width="1" height="25" fill="white" />
            <rect y="24" width="25" height="1" fill="white" />
            <path
              d="M0.5 18.5L4.5 14.5L7 16.5L12.5 7.5L17 12.5L21.5 9.5L25.5 11.5"
              stroke="white"
              stroke-width="2"
            />
            <path
              opacity="0.5"
              d="M0.5 16.5L4.5 10.5L7 9L12.5 12.5L17 3L21.5 6H26"
              stroke="white"
              stroke-width="2"
            />
          </>
        ) : (
          <>
            <rect width="1" height="25" fill={tintColor} />
            <rect y="24" width="25" height="1" fill={tintColor} />
            <path
              d="M0.5 18.5L4.5 14.5L7 16.5L12.5 7.5L17 12.5L21.5 9.5L25.5 11.5"
              stroke={tintColor}
              stroke-width="2"
            />
            <path
              opacity="0.5"
              d="M0.5 16.5L4.5 10.5L7 9L12.5 12.5L17 3L21.5 6H26"
              stroke={tintColor}
              stroke-width="2"
            />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0_12154_31116">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
