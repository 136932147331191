import React from "react";

import styled from "styled-components";

import { COLOR_BLUE_ATTENDANCE_BG, COLOR_TEXT_DARK } from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { XpsUser, useUpdateParticipationMutation } from "../../../graphql";
import Avatar from "../../Avatar";
import Hoverable from "../../Hoverable";
import { PlusIcon } from "../../Icons/PlusIcon";

type SessionEditMemberItemProps = {
  user: XpsUser;
  practice: any;
  refetch: any;
};

const Row = styled.div<{ isHovered: boolean }>`
  display: flex;
  padding: 14px 11px 14px 16px;
  align-items: center;
  justify-content: space-between;
  ${({ isHovered }) =>
    isHovered && `background-color: ${COLOR_BLUE_ATTENDANCE_BG}`};
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
`;

export function SessionEditMemberItem({
  user,
  practice,
  refetch,
}: SessionEditMemberItemProps) {
  const { sessionId } = useUserContext();
  const { actions: modalActions } = useModalContext();

  const [updateParticipation] = useUpdateParticipationMutation();

  const onUpdateMembers = async () => {
    await updateParticipation({
      variables: {
        sessionId,
        input: {
          sessionGuid: practice.id,
          participates: true,
          userGuid: user.id,
        },
      },
    });

    await refetch();
  };

  const onRowClick = () => {
    modalActions.openModal({
      modal: ModalType.USER_PROFILE,
      title: "",
      params: { currentAthlete: user, groupsIds: user.jointGroupIds },
    });
  };

  return (
    <Hoverable>
      {(isHovered) => (
        <Row isHovered={isHovered}>
          <UserWrapper onClick={onRowClick}>
            <Avatar
              size={25}
              style={{ marginRight: 12 }}
              source={{ uri: user.thumb }}
              userName={user.name}
            />
            <Name>{user.name}</Name>
          </UserWrapper>

          {isHovered && <PlusIcon cursor="pointer" onClick={onUpdateMembers} />}
        </Row>
      )}
    </Hoverable>
  );
}
