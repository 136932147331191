import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { Agenda } from "../../components/Agenda/Agenda";
import { AthleteBaseInfo } from "../../components/Athletes/AthleteBaseInfo";
import { CalendarContainer } from "../../components/CalenderContainer";
import { Header } from "../../components/Header";
import ScreenContainer from "../../components/ScreenContainer";
import Tabs, { Tab } from "../../components/Tabs";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { useUserContext } from "../../contexts/User";
import {
  SubGroup,
  useAgendaQuery,
  useCalendarQuery,
  useFamilyMembersQuery,
  useGetGroupsForProfileQuery,
  useGetTheGroupsImInWithFamilyAccessQuery,
  useGetUserLazyQuery,
} from "../../graphql";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { isRealTrainer } from "../../utils/isRealTrainer";

import { InfoTab } from "./InfoTab";

export enum TabLabel {
  INFO = "info",
  AGENDA = "agenda",
  CALENDAR = "calendar",
}

function getTabs(t: TFunction) {
  return [
    { id: TabLabel.INFO, title: t("info").toUpperCase() },
    { id: TabLabel.AGENDA, title: t("agenda").toUpperCase() },
    { id: TabLabel.CALENDAR, title: t("calendar").toUpperCase() },
  ];
}

const tabStyles = {
  backgroundColor: COLOR_WHITE,
  paddingTop: 10,
  paddingLeft: 30,
  paddingBottom: 5,
  borderBottomColor: COLOR_GREY_ACTIVE,
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  zIndex: 999,
  flexWrap: "no-wrap",
  overflow: "visible",
};

export const AthleteScreen = () => {
  const navigate = useNavigate();
  const currentUserAsAthlete = useUserContext();
  const { setCurrentWeek } = useAgendaContext();
  const { selectedAccount } = useAccessLevelsContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const [
    getUser,
    { data: userData, loading: userLoading, refetch: userRefetch },
  ] = useGetUserLazyQuery();

  const { id, sessionId, timezone, language, isFamilyMember, isTrainer } =
    currentUserAsAthlete;
  const isAthlete = !isFamilyMember && !isTrainer;

  const { t } = useTranslation();

  const tabs = getTabs(t);

  const [tab, setTab] = useState<Tab>(tabs[0]);
  const [someoneElseAsAthlete, setSomeoneElseAsAthlete] = useState(null);

  const athleteId = searchParams.get("athleteId");
  const teamGuids = searchParams.get("teamGuids")?.split(",");
  const isCurrentUserAthlete =
    isAthlete && searchParams.get("viewAs") === "athlete";
  const isUserTypeAthlete = searchParams.get("userType") === "Athlete";
  const isUserTypeTrainer = searchParams.get("userType") === "Trainer";

  const navigateFrom = searchParams.get("navigateFrom");

  const { data, refetch } = useFamilyMembersQuery({
    variables: { sessionId, timezone, language },
  });

  const isAthleteEditable = useMemo(
    () =>
      (isCurrentUserAthlete && athleteId === id) ||
      (!isCurrentUserAthlete &&
        isFamilyMember &&
        data?.UserProfile?.family?.membersOfFamily?.find(
          (fm) => fm.id === athleteId
        )?.profile?.editableByLoggedOnUser),
    [
      isCurrentUserAthlete,
      data?.UserProfile?.family?.membersOfFamily,
      athleteId,
      id,
      isFamilyMember,
    ]
  );

  const shouldUseGroupsImInWithFamilyAccess = useMemo(
    () => isCurrentUserAthlete || isUserTypeAthlete || isUserTypeTrainer,
    [isCurrentUserAthlete, isUserTypeAthlete, isUserTypeTrainer]
  );

  const { data: groupData } = useGetGroupsForProfileQuery({
    variables: { sessionId, ids: teamGuids },
    skip: !teamGuids?.length && shouldUseGroupsImInWithFamilyAccess,
  });

  const { data: groupsImInData } = useGetTheGroupsImInWithFamilyAccessQuery({
    variables: {
      sessionId,
      accountId: selectedAccount?.id,
      mustBeAccessibleBy: athleteId,
      includeGroupsWhereImAdmin: true,
    },
    skip: !athleteId,
  });

  const groups = useMemo(
    () =>
      shouldUseGroupsImInWithFamilyAccess
        ? groupsImInData?.getTheGroupsImIn
        : groupData?.getGroups,
    [
      groupData?.getGroups,
      groupsImInData?.getTheGroupsImIn,
      shouldUseGroupsImInWithFamilyAccess,
    ]
  );

  const groupWithSubGroupsToShow = useMemo(() => {
    if (shouldUseGroupsImInWithFamilyAccess) {
      return groups?.map((group) => {
        const newGroup = { ...group, subGroups: [] };
        group?.subGroups?.forEach((subGroup: SubGroup) => {
          if (
            subGroup?.athleteAccess?.some(
              (athlete) =>
                athlete.user.id === someoneElseAsAthlete?.id && athlete.active
            )
          ) {
            newGroup.subGroups.push(subGroup);
          }
        });
        return newGroup;
      });
    } else {
      return groups;
    }
  }, [groups, shouldUseGroupsImInWithFamilyAccess, someoneElseAsAthlete?.id]);

  useEffect(() => {
    (async () => {
      if (athleteId) {
        await getUser({
          variables: {
            id: athleteId,
            sessionId,
            language,
            timezone,
          },
        });
      }
    })();
  }, [getUser, language, sessionId, athleteId, timezone]);

  useEffect(() => {
    if (userData?.getUser) {
      setSomeoneElseAsAthlete(userData?.getUser);
    }
  }, [userData?.getUser]);

  const familyMembers = useMemo(
    () =>
      isCurrentUserAthlete
        ? data?.UserProfile?.family?.membersOfFamily
        : someoneElseAsAthlete?.profile?.family?.membersOfFamily,
    [
      data?.UserProfile?.family?.membersOfFamily,
      isCurrentUserAthlete,
      someoneElseAsAthlete?.profile?.family?.membersOfFamily,
    ]
  );

  const {
    data: athleteData,
    loading: athleteLoading,
    refetch: refetchAthlete,
  } = useAgendaQuery({
    variables: {
      focusedAthlete: isCurrentUserAthlete
        ? currentUserAsAthlete?.id
        : someoneElseAsAthlete?.__typename === "GroupAccess"
          ? someoneElseAsAthlete?.user.id
          : someoneElseAsAthlete?.id,
      from: dayjs().startOf("day").valueOf(),
      sessionId,
      timezone: timezone || dayjs.tz.guess(),
      focusedUserAccount:
        isRealTrainer(currentUserAsAthlete) &&
        selectedAccount &&
        selectedAccount.id !== ALL_ACCOUNTS_ID
          ? selectedAccount.id
          : null,
    },
    skip: !sessionId || tab?.id !== TabLabel.AGENDA,
    fetchPolicy: "cache-and-network",
  });

  const {
    data: calendarData,
    loading: calendarLoading,
    refetch: refetchCalendar,
  } = useCalendarQuery({
    variables: {
      sessionId,
      timezone: timezone || dayjs.tz.guess(),
      focusedAthlete: isCurrentUserAthlete
        ? currentUserAsAthlete?.id
        : someoneElseAsAthlete?.__typename === "GroupAccess"
          ? someoneElseAsAthlete?.user.id
          : someoneElseAsAthlete?.id,
      from: dayjs().startOf("month").subtract(6, "day").valueOf(),
      to: dayjs().endOf("month").add(6, "day").valueOf(),
    },
    skip: tab?.id !== TabLabel.CALENDAR,
    fetchPolicy: "network-only",
  });

  const onNavigate = useCallback(
    (newDate: Date) => {
      refetchCalendar({
        from: dayjs(newDate).startOf("month").subtract(6, "day").valueOf(),
        to: dayjs(newDate).endOf("month").add(6, "day").valueOf(),
      });

      if (tab.id === "week") {
        setCurrentWeek(dayjs(newDate).week());
      }
    },
    [tab, setCurrentWeek, refetchCalendar]
  );

  useEffect(() => {
    const lastFocusedAthleteDetailTabId =
      getSearchParamsObject(searchParams)?.activeTabId;

    if (lastFocusedAthleteDetailTabId) {
      setTab(
        tabs.find((tab) => tab.id === lastFocusedAthleteDetailTabId) ?? tabs[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleSetTab = (newTab) => {
    setTab(newTab);
    setSearchParams({
      ...getSearchParamsObject(searchParams),
      activeTabId: newTab.id,
    });
  };

  const isLoading =
    !data ||
    (!shouldUseGroupsImInWithFamilyAccess && !groupData) ||
    (shouldUseGroupsImInWithFamilyAccess && !groupsImInData) ||
    userLoading ||
    (athleteId && !someoneElseAsAthlete);

  return (
    <ScreenContainer>
      <Header onBack={() => navigate(navigateFrom)} />

      <AthleteBaseInfo
        isLoading={isLoading}
        isAthleteEditable={isAthleteEditable}
        refetchFamilyMembers={refetch}
        userRefetch={userRefetch}
        id={
          !someoneElseAsAthlete
            ? currentUserAsAthlete.id
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user.id
              : someoneElseAsAthlete?.id
        }
        name={
          !someoneElseAsAthlete
            ? `${currentUserAsAthlete?.firstName} ${currentUserAsAthlete?.lastName}`
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user.name
              : someoneElseAsAthlete?.name
        }
        isExpired={
          !someoneElseAsAthlete
            ? false
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user.isExpired
              : someoneElseAsAthlete?.isExpired
        }
        profileImageUrl={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.profileImageUrl
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.profile?.user.profileImageUrl
              : someoneElseAsAthlete?.profile?.profileImageUrl
        }
        focusedAthlete={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.id
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user.id
              : someoneElseAsAthlete?.id
        }
        phoneNumber={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.phoneNumber
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user?.profile?.phoneNumber
              : someoneElseAsAthlete?.profile?.phoneNumber
        }
        email={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.emailAddress
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user?.profile?.emailAddress
              : someoneElseAsAthlete?.profile?.emailAddress
        }
        city={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.city
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user?.profile?.city
              : someoneElseAsAthlete?.profile?.city
        }
        birthDay={
          !someoneElseAsAthlete
            ? currentUserAsAthlete?.birthDay
            : someoneElseAsAthlete.__typename === "GroupAccess"
              ? someoneElseAsAthlete?.user?.profile?.birthDay
              : someoneElseAsAthlete?.profile?.birthDay
        }
        firstName={
          !someoneElseAsAthlete ? undefined : someoneElseAsAthlete?.firstName
        }
        lastName={
          !someoneElseAsAthlete ? undefined : someoneElseAsAthlete?.lastName
        }
      />
      {isTrainer && !isUserTypeTrainer && (
        <Tabs
          tab={tab}
          tabs={tabs}
          setTab={(newTab) => handleSetTab(newTab)}
          style={tabStyles as CSSProperties}
        />
      )}

      {tab.id === TabLabel.INFO && (
        <InfoTab
          isCurrentUserAthlete={isCurrentUserAthlete}
          someoneElseAsAthlete={someoneElseAsAthlete}
          isUserTypeTrainer={isUserTypeTrainer}
          groups={groupWithSubGroupsToShow}
          currentUserAsAthlete={currentUserAsAthlete}
          familyMembers={familyMembers}
          athleteId={athleteId}
          teamGuids={teamGuids}
        />
      )}
      {tab.id === TabLabel.AGENDA && (
        <Agenda
          data={athleteData}
          loading={athleteLoading}
          refetch={refetchAthlete}
        />
      )}
      {tab.id === TabLabel.CALENDAR && (
        <CalendarContainer
          onNavigate={onNavigate}
          loading={calendarLoading}
          data={calendarData}
          isAthleteScreen
        />
      )}
    </ScreenContainer>
  );
};
