import React from "react";

import dayjs from "dayjs";
import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DEFAULT,
} from "../../colors";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { HoverableItem } from "../HoverableItem";
import { IconButton } from "../IconButton";
import { ThreeDotsIcon } from "../Icons";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

interface IBaselistItemProps {
  onNameClick: () => void;
  items: {
    title: string;
    onItemClick: (itemId: string) => void;
  }[];
  setCurrentItem: (item: string) => void;
  currentItemId: string;
  objectId: string;
  isLast: boolean;
  objectName: string;
  createdBy?: string;
  owner?: string;
  updatedAt: string;
  isDeleting?: boolean;
  isLoading?: boolean;
  objectType?: string;
}

const Wrapper = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: ${({ isFocused }) => (isFocused ? 1 : 0)};
`;

const LoaderWrapper = styled.div`
  display: flex;
  padding: 10px;
`;

const BaseButton = styled(BasicButton)`
  flex: 2;
  overflow: auto;
`;

const BaseText = styled(StyledText)`
  font-size: 14px;
  font-weight: bold;
  color: ${COLOR_TEXT_DEFAULT};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
`;

const FlexText = styled(BaseText)`
  line-height: 17px;
  flex: 1;
`;

const ItemRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 0 5px 0 20px;
  height: 45px;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  align-items: center;
`;

export function BaseListItem({
  onNameClick,
  setCurrentItem,
  items,
  isDeleting,
  isLoading,
  currentItemId,
  objectId,
  objectName,
  updatedAt,
  createdBy,
  owner,
  objectType,
}: IBaselistItemProps) {
  const { isMobile } = useTheme();

  return (
    <Wrapper isFocused={currentItemId === objectId}>
      <div className="hover-container">
        <ItemRow>
          <BaseButton key={objectId} onClick={onNameClick}>
            <BaseText>{objectName}</BaseText>
          </BaseButton>
          {objectType && <FlexText>{objectType}</FlexText>}
          <FlexText>{createdBy ?? owner}</FlexText>
          {!isMobile && <FlexText>{dayjs(updatedAt).format("LLL")}</FlexText>}
          {!isMobile && (
            <Dropdown
              dropdownContainerStyle={{
                width: 135,
                height: 80,
                marginRight: 0,
                padding: 0,
              }}
              dropdownContentStyle={{
                padding: "5px 0",
                top: 10,
                right: 10,
              }}
              component={({ toggle, show }) => {
                return (
                  <IconButton
                    icon={<ThreeDotsIcon isActive={show} />}
                    enforceActiveState={show}
                    onClick={() => {
                      setCurrentItem(objectId);
                      toggle();
                    }}
                  />
                );
              }}
            >
              <>
                {items.map((item) =>
                  isDeleting || isLoading ? (
                    <LoaderWrapper key={item.title}>
                      <Loader color={COLOR_BLUE} size="small" />
                    </LoaderWrapper>
                  ) : (
                    <HoverableItem
                      key={item.title}
                      onClick={() => item.onItemClick(objectId)}
                      label={item.title}
                    />
                  )
                )}
              </>
            </Dropdown>
          )}
        </ItemRow>
      </div>
    </Wrapper>
  );
}
