import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { useCanSendPersonalMessageQuery } from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import Card from "../Card";
import { MessageIcon, AttendingIcon, AwayIcon } from "../Icons";
import { XpsUserWithParticipates } from "../Members/MembersList";
import StyledText from "../StyledText";

const AthleteMainCard = styled(Card)<{ noBottomMargin?: boolean }>`
  flex-direction: row;
  align-items: center;
  height: 65px;
  padding: 15px 10px;
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? 0 : 5)}px;
`;

const AthleteBaseInfoWrapper = styled.div`
  margin-left: 20px;
  justify-content: center;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const AdditionalTrainerInfoWrapper = styled.div`
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
  display: flex;
`;

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
`;

const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const MessageIconClickable = styled(BasicButton)`
  align-self: center;
  margin-right: 10px;
`;

const DetailInfoStyledText = styled(StyledText)`
  margin-top: 5px;
`;

const AthleteRow = styled(BasicButton)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  flex-grow: 3;
`;

const ParticipateImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 15px;
`;

interface BaseUserListCardProps {
  currentUser: XpsUserWithParticipates;
  isTrainerCard?: boolean;
  hideBody?: boolean;
  hideAdditionalInfo?: boolean;
  isLast?: boolean;
  showMembersAttendance?: boolean;
  currentGroupOwnerId?: string;
}

export const BaseUserListCard = ({
  currentUser,
  isTrainerCard = false,
  hideBody = false,
  hideAdditionalInfo = false,
  isLast,
  showMembersAttendance = false,
  currentGroupOwnerId,
}: BaseUserListCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { actions: modalActions } = useModalContext();
  const user = useUserContext();

  const { data } = useCanSendPersonalMessageQuery({
    variables: {
      sessionId: user?.sessionId,
      userID: currentUser?.id,
    },
  });

  const onMessageClick = () => {
    navigate({
      pathname: `/${ROUTING_CONFIG.messages}`,
      search: `?id=${currentUser?.id}`,
    });
    modalActions.closeModal();
  };

  const onAthleteRowClick = () => {
    modalActions.openModal({
      modal: ModalType.USER_PROFILE,
      title: "",
      params: {
        currentAthlete: currentUser,
        hideBody,
        currentGroupOwnerId,
      },
    });
  };

  return (
    <AthleteMainCard noBottomMargin={isLast}>
      <AthleteRow onClick={onAthleteRowClick}>
        <Avatar
          userName={currentUser.name}
          source={{ uri: currentUser.thumb }}
          size={45}
        />

        <AthleteBaseInfoWrapper>
          <StyledText fontSize={16} fontWeight="bold">
            {currentUser.name}
          </StyledText>
        </AthleteBaseInfoWrapper>
      </AthleteRow>
      {isTrainerCard ? (
        <AdditionalTrainerInfoWrapper>
          <>
            <PhoneWrapper>
              {hideAdditionalInfo ||
              !currentUser?.profile?.phoneNumber ? null : (
                <>
                  <StyledText fontSize={10}>
                    {t("phoneNumber").toUpperCase()}
                  </StyledText>
                  <DetailInfoStyledText fontSize={14} fontWeight="bold">
                    {currentUser?.profile?.phoneNumber}
                  </DetailInfoStyledText>
                </>
              )}
            </PhoneWrapper>

            {!user.isFamilyMember &&
              !hideAdditionalInfo &&
              currentUser?.profile?.emailAddress && (
                <EmailWrapper>
                  <StyledText fontSize={10}>
                    {t("email").toUpperCase()}
                  </StyledText>
                  <DetailInfoStyledText fontSize={14} fontWeight="bold">
                    {currentUser?.profile?.emailAddress}
                  </DetailInfoStyledText>
                </EmailWrapper>
              )}
          </>

          {user?.id !== currentUser?.id && data?.canISendAPersonalMessageTo ? (
            <MessageIconClickable onClick={onMessageClick}>
              <MessageIcon />
            </MessageIconClickable>
          ) : null}
        </AdditionalTrainerInfoWrapper>
      ) : null}
      {showMembersAttendance ? (
        <ParticipateImageWrapper>
          {currentUser?.participates !== null ? (
            currentUser?.participates ? (
              <AttendingIcon isActive />
            ) : (
              <AwayIcon isActive />
            )
          ) : null}
        </ParticipateImageWrapper>
      ) : null}
    </AthleteMainCard>
  );
};
