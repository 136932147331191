import React from "react";
import Select, { components, StylesConfig } from "react-select";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_LIGHT_GRAY,
  COLOR_WHITE,
} from "../colors";

import Avatar from "./Avatar";
import { SelectOption } from "./SelectField";

const { Option, SingleValue } = components;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ValueLabel = styled.p`
  margin: 0;
  margin-left: 16px;
  font-weight: bold;
  color: inherit;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 120px;
  font-family: "Inter", sans-serif;
`;

const AvatarOption = (props) => (
  <Option {...props}>
    <ValueContainer>
      <Avatar
        userGuid={props.data.value}
        uri={props.data.img}
        userName={props.data.name || props.data.label}
        size="small"
        isThumb
      />
      <ValueLabel>{props.data.label}</ValueLabel>
    </ValueContainer>
  </Option>
);

const AvatarSingleValue = (props) => (
  <SingleValue {...props}>
    <ValueContainer>
      <Avatar
        userGuid={props.data.value}
        uri={props.data.img}
        userName={props.data.name || props.data.label}
        size="small"
        isThumb
      />
      <ValueLabel>{props.data.label}</ValueLabel>
    </ValueContainer>
  </SingleValue>
);

type OptionType = SelectOption & { img?: string; name?: string };

export const AvatarSelect = ({
  options,
  value,
  onChange,
}: {
  options: OptionType[];
  value: string | null;
  onChange(value: string | null): void;
}) => {
  const { isMobile } = useTheme();
  const defaultOnSelectChange = (option: SelectOption) => {
    onChange(option.value);
  };

  return (
    <Select
      defaultValue={options[0]}
      options={options}
      components={{ Option: AvatarOption, SingleValue: AvatarSingleValue }}
      styles={getSelectStyles({ isMobile })}
      value={options.find((option) => (option as SelectOption).value === value)}
      onChange={defaultOnSelectChange}
    />
  );
};

const getSelectStyles: (props: { isMobile?: boolean }) => StylesConfig = ({
  isMobile,
}) => ({
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    color: isSelected ? COLOR_WHITE : undefined,
    backgroundColor: isSelected
      ? COLOR_BLUE
      : isFocused
        ? COLOR_GRAY
        : COLOR_WHITE,
    cursor: "pointer",
  }),
  menu: (provided) => ({
    ...provided,
    paddingTop: 10,
    paddingBottom: 10,
    zIndex: 999,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: COLOR_WHITE,
    border: `1px solid ${COLOR_LIGHT_GRAY}`,
    borderRadius: 6,
    height: 45,
    width: !isMobile ? 260 : "auto",
    cursor: "pointer",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    zoom: 0.8,
    color: COLOR_BLUE,
  }),
  valueContainer: () => ({
    fontWeight: "bold",
    marginLeft: 15,
    display: "flex",
    alignItems: "center",
  }),
  container: (provided) => ({
    ...provided,
    flex: isMobile ? 1 : undefined,
  }),
});
