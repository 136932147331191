import React from "react";

import styled from "styled-components";

import { COLOR_GREEN, COLOR_ORANGE, COLOR_RED_ACTIVE } from "../../colors";

interface Props {
  status: string;
  severity: 0 | 1 | 2;
  cursor?: string;
}

export function StatusCell({ status, severity, cursor }: Props) {
  return (
    <StatusCellWrap severity={severity} cursor={cursor}>
      {status}
    </StatusCellWrap>
  );
}

const StatusCellWrap = styled.div<{ severity?: 0 | 1 | 2; cursor?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 35px;
  text-transform: uppercase;
  border: 1px solid
    ${({ severity }) => {
      if (severity === 1) return COLOR_ORANGE;
      if (severity === 2) return COLOR_RED_ACTIVE;
      return COLOR_GREEN;
    }};
  border-radius: 3px;
  font-size: 14px;
  font-weight: 700;
  cursor: ${({ cursor }) => cursor ?? "pointer"};
  color: ${({ severity }) => {
    if (severity === 1) return COLOR_ORANGE;
    if (severity === 2) return COLOR_RED_ACTIVE;
    return COLOR_GREEN;
  }};
`;
