import { useMemo } from "react";

import dayjs from "dayjs";

import { useReportContext } from "../../../contexts/report";
import { useReportsContext } from "../../../contexts/Reports";
import { Period, PeriodType } from "../../../models/reports";
import { getReportsSelectionDateRange } from "../../../utils/reports";

export function useReportPeriod(period?: Period) {
  const { report } = useReportContext();
  const { type, from, to } = period ?? {};

  const [
    { selectedPeriod, selectedStartDate, selectedEndDate, selectedSingleDay },
  ] = useReportsContext();

  return useMemo(() => {
    switch (type) {
      case PeriodType.FIXED:
        return {
          fromDate: dayjs(from).startOf("day"),
          toDate: dayjs(to).endOf("day"),
        };
      case PeriodType.DYNAMIC:
        return {
          fromDate: dayjs(from).startOf("day"),
          toDate: dayjs().endOf("day"),
        };
      case PeriodType.SELECTION:
      default:
        return getReportsSelectionDateRange({
          selectedPeriod,
          selectedStartDate,
          selectedEndDate,
          isSingleDay: report?.isSingleDay,
          selectedSingleDay,
        });
    }
  }, [
    type,
    from,
    to,
    selectedPeriod,
    selectedStartDate,
    selectedEndDate,
    report?.isSingleDay,
    selectedSingleDay,
  ]);
}
