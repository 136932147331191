import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../colors";
import { useNewChatContext } from "../../../contexts/messaging/NewChatProvider";
import { CrossFilledIcon, SearchIcon } from "../../Icons";
import { StyledTextInput } from "../../StyledTextInput";

interface ControlBarProps {
  search: string;
  shouldDisplayBottomBorder?: boolean;
  setSearch: (_: string) => void;
}

const ControlBarWrapper = styled.div<{ shouldDisplayBottomBorder: boolean }>`
  border-bottom-style: solid;
  border-bottom-width: ${({ shouldDisplayBottomBorder }) =>
    shouldDisplayBottomBorder ? 1 : 0}px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
`;

const TextInputGroupWrapper = styled.div<{ isFocused: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  padding: 12px;
  margin: 15px;

  border-color: ${({ isFocused }) =>
    isFocused ? "rgba(0, 115, 217, 0.25)" : COLOR_GRAY};
  border-style: solid;
  border-width: 4px;
`;

const CancelButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const ControlBar = ({
  search,
  setSearch,
  shouldDisplayBottomBorder,
}: ControlBarProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const { stage } = useNewChatContext();

  return (
    <ControlBarWrapper shouldDisplayBottomBorder={shouldDisplayBottomBorder}>
      {stage === 1 && (
        <TextInputGroupWrapper isFocused={isFocused}>
          <SearchIcon style={{ marginRight: 10 }} />
          <StyledTextInput
            onChangeText={setSearch}
            value={search}
            placeholder={t("search")}
            style={{ width: "100%", height: 21, padding: 0 }}
            placeholderTextColor={COLOR_TEXT_TABLE_LABEL}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            autoFocus
          />
          {Boolean(search) && (
            <CancelButton onClick={() => setSearch("")}>
              <CrossFilledIcon />
            </CancelButton>
          )}
        </TextInputGroupWrapper>
      )}
    </ControlBarWrapper>
  );
};
