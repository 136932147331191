import React from "react";
import { useParams } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { Header } from "../components/Header";
import { AthleteReportContainer } from "../components/Report/AthleteReportContainer";
import { ReportScreenHeader } from "../components/Report/ReportScreenHeader";
import ScreenContainer from "../components/ScreenContainer";
import { ReportContextProvider } from "../contexts/report";

const AthleteReportWrapper = styled.div<{ isMobile: boolean }>`
  flex: 1;
  padding: ${({ isMobile }) => (!isMobile ? "30px" : "15px")};
`;

export function ReportScreen() {
  const { reportId } = useParams();
  const { isMobile } = useTheme();

  return (
    <ReportContextProvider reportId={reportId}>
      <ScreenContainer>
        <div style={{ zIndex: 2 }}>
          <Header />
        </div>

        <div style={{ zIndex: 1 }}>
          <ReportScreenHeader />
        </div>

        <AthleteReportWrapper isMobile={isMobile}>
          <AthleteReportContainer />
        </AthleteReportWrapper>
      </ScreenContainer>
    </ReportContextProvider>
  );
}
