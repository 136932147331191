import { AggregateFunction } from "../../../graphql";
import { Period } from "../../../models";
import { ColoringConfig } from "../../ReportCreateTable/models";
import { TimeLine, TimeLinePeriod } from "../ChartWIdget/model";

export type AthleteTablePeriodizationColumnHeader = {
  collectionId: string;
  itemId: string;
  attributeTemplateId: string;
  aggregateFunctions: AggregateFunction[];
  measurementTemplateName?: string;
  attributeTemplateUnitName?: string;
  attributeTemplateName?: string;
  textOptions?: string[];
};

export type TablePeriodizationColumnHeader = {
  collectionId: string;
  itemId: string;
  attributeTemplateId: string;
  aggregateFunctions: AggregateFunction[];
  measurementTemplateName?: string;
  attributeTemplateUnitName?: string;
  attributeTemplateName?: string;
  textOptions?: string[];
};

export type AthleteTablePeriodizationForm = {
  name: string;
  columnHeaders: AthleteTablePeriodizationColumnHeader[];
  timeLine: TimeLine;
  timeLinePeriod: TimeLinePeriod;
  coloringConfig: ColoringConfig;
  showSummaryStats: boolean;
  summaryStatsAlignment: SummaryStatsAlignment;
  sortOrder: number;
  period: Period;
  verticalHeader: boolean;
  showValuesAsText: boolean;
  ignoreColumnsWithNoData: boolean;
};

export type GroupTablePeriodizationForm = {
  coloringConfig: { id: string; coloring: LinearColoring }[];
  name: string;
  period: Period;
  showSummaryStats: boolean;
  showValuesAsText: boolean;
  sortOrder: number;
  summaryStatsAlignment: SummaryStatsAlignment;
  timeLine: TimeLine;
  timeLinePeriod: TimeLinePeriod;
  verticalHeader: boolean;
  columnHeaders: AthleteTablePeriodizationColumnHeader[];
  ignoreColumnsWithNoData: boolean;
};

export type TablePeriodizationForm =
  | GroupTablePeriodizationForm
  | AthleteTablePeriodizationForm;

export enum Periodization {
  CONTINUOUS = "continuous",
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
  NONE = "none",
}

export enum LinearColoring {
  GREEN_WHITE = "green_white",
  WHITE_GREEN = "white_green",
  RED_YELLOW_GREEN = "red_yellow_green",
  GREEN_YELLOW_RED = "green_yellow_red",
}

export enum SummaryStatsAlignment {
  TOP = "top",
  BOTTOM = "bottom",
}
