import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useMutation } from "@tanstack/react-query";

import { COLOR_BLUE } from "../colors";
import { ButtonVariant, GeneralButton } from "../components/Button/";
import {
  LogoAcesNationNetworkIcon,
  LogoXpsNetworkIcon,
} from "../components/Icons";
import { Loader } from "../components/Loader";
import { ForgotPasswordForm } from "../components/PasswordForm/ForgotPasswordForm";
import {
  Description,
  FormWrapper,
  LogoWrapper,
  Title,
  TitleWrapper,
  Wrapper,
} from "../components/PasswordForm/styles";
import { useNotificationsContext } from "../contexts/notifications";
import { useModalContext } from "../contexts/UI/Modal";
import { resetPassword } from "../services/password";
import { isAcesNationEnv } from "../utils/isAcesNationEnv";

import {
  FormHeaderErrorText,
  LoadingMessage,
  LoadingWrapper,
  FormHeaderErrorWrapper,
} from "./LoginScreen/styles";

interface ForgotPasswordPageProps {
  inModalView?: boolean;
}

export function ForgotPasswordPage({ inModalView }: ForgotPasswordPageProps) {
  const { t } = useTranslation();
  const [error] = useState(null);
  const {
    actions: { closeModal },
  } = useModalContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const { mutateAsync: resetPasswordMutation, isLoading } = useMutation(
    ["resetPassword"],
    (variables: { email: string }) => resetPassword(variables.email)
  );

  const { handleSubmit, reset, ...formMethods } = useForm<{
    email: string;
  }>({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit: SubmitHandler<{ email: string }> = async (data) => {
    try {
      await resetPasswordMutation({ email: data.email });

      if (inModalView) {
        closeModal();
      }

      showSuccessNotification(t("emailNotificationPasswordResetSent"));
    } catch (error) {
      showErrorNotification(error || t("error"));
    }

    reset();
  };

  return (
    <Wrapper style={inModalView ? { flex: 1 } : undefined}>
      {isLoading ? (
        <LoadingWrapper>
          <Loader size={31} color={COLOR_BLUE} />
          <LoadingMessage>{t("pleaseWait")}</LoadingMessage>
        </LoadingWrapper>
      ) : (
        <FormWrapper>
          {error && (
            <FormHeaderErrorWrapper>
              <FormHeaderErrorText>{error}</FormHeaderErrorText>
            </FormHeaderErrorWrapper>
          )}
          <LogoWrapper>
            {isAcesNationEnv() ? (
              <LogoAcesNationNetworkIcon />
            ) : (
              <LogoXpsNetworkIcon />
            )}
          </LogoWrapper>
          <TitleWrapper>
            <Title>{t("forgotPasswordTitle")}</Title>
            <Description>{t("forgotPasswordDescription")}</Description>
          </TitleWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ForgotPasswordForm formMethods={formMethods} />
            <GeneralButton
              fullWidth
              height={45}
              label={t("send")}
              type={ButtonVariant.Submit}
            />
          </form>
        </FormWrapper>
      )}
    </Wrapper>
  );
}
