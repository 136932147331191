import React from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import { AthleteTablePeriodizationTablePreview } from "../components/AthleteTablePeriodization/Widget/TablePreview";
import { Header } from "../components/Header";
import {
  TimeLine,
  TimeLinePeriod,
} from "../components/Report/ChartWIdget/model";
import {
  AthleteTablePeriodizationForm,
  SummaryStatsAlignment,
} from "../components/Report/PeriodizationTable/model";
import { PeriodSection } from "../components/Report/PeriodSection";
import { PreviewButtonSection } from "../components/Report/PreviewButtonSection";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { FormContainer, FormWrapper } from "../components/Report/styled";
import { WidgetEditorTitleSection } from "../components/Report/WidgetEditorTitleSection";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { BackgroundColoringSection } from "../components/SharedWidgetEditorSections/BackgroundColoringSection";
import {
  TablePeriodizationDataSelectionSection,
  TablePeriodizationAppearanceSection,
  TablePeriodizationSortingSection,
  TablePeriodizationSummarySection,
  TablePeriodizationWhatToShowSection,
} from "../components/TablePeriodization/Editor";
import { useUserContext } from "../contexts/User";
import {
  useSaveWidgetTemplateSchalkeAthleteTableMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport, useNavigateToPreviousPage } from "../hooks";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { PeriodType } from "../models";
import { getValidUnitNameFromReportWidgetCell } from "../utils/statistics";
import {
  buildPreferencesString,
  parseAthleteTablePeriodizationPreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export const AthleteTablePeriodizationEditorScreen = () => {
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const navigateToPreviousPage = useNavigateToPreviousPage();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const [saveWidget] = useSaveWidgetTemplateSchalkeAthleteTableMutation();

  const {
    reportId,
    widgetId,
    parentReportId,
    editReportWidget,
    isWidgetTemplate,
    layoutIndex,
  } = useWidgetEditorUrlParams();

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const { data: templateData, loading: loadingAthleteTableData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const savingWidget = false;

  const onSubmit = async (values: AthleteTablePeriodizationForm) => {
    try {
      const response = await saveWidget({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              values.name ||
              (isWidgetTemplate ? t("defaultAthleteTableWidgetName") : ""),
            reportTemplateId: reportId || parentReportId,
            columnHeaders: values.columnHeaders.map((columnHeader) => ({
              attributeTemplateId: columnHeader.attributeTemplateId,
              itemId: columnHeader.itemId,
              collectionId: columnHeader.collectionId,
              aggregateFunctions: columnHeader.aggregateFunctions,
            })),
            preferences: buildPreferencesString({
              type: WidgetType.ATHLETE_TABLE_PERIODIZATION,
              period: values.period,
              timeLine: values.timeLine,
              timeLinePeriod: values.timeLinePeriod,
              coloringConfig: values.coloringConfig,
              showSummaryStats: values.showSummaryStats,
              summaryStatsAlignment: values.summaryStatsAlignment,
              sortOrder: values.sortOrder,
              verticalHeader: values.verticalHeader,
              showValuesAsText: values.showValuesAsText,
              ignoreColumnsWithNoData: values.ignoreColumnsWithNoData,
            }),
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateSchalkeAthleteTable?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateSchalkeAthleteTable.id
        );
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  const initialFormValues: AthleteTablePeriodizationForm =
    widgetId && templateData
      ? {
          // @ts-ignore
          name: templateData.reportWidgetTemplateById?.name,
          columnHeaders:
            (templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateSchalkeAthleteTable" &&
              templateData.reportWidgetTemplateById?.columnHeaders.map(
                (columnHeader) => ({
                  attributeTemplateId:
                    "attributeTemplate" in columnHeader
                      ? columnHeader.attributeTemplate?.id
                      : undefined,
                  attributeTemplateName:
                    "attributeTemplate" in columnHeader
                      ? columnHeader.attributeTemplate?.name
                      : undefined,
                  attributeTemplateUnitName:
                    getValidUnitNameFromReportWidgetCell(columnHeader),
                  collectionId: columnHeader.collectionId,
                  itemId: columnHeader.templateId,
                  measurementTemplateName: "",
                  aggregateFunctions: columnHeader.aggregateFunctions,
                })
              )) ||
            [],
          ...parseAthleteTablePeriodizationPreferencesObject(
            templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateSchalkeAthleteTable"
              ? templateData.reportWidgetTemplateById?.preferences
              : "{}"
          ),
        }
      : {
          name: "",
          columnHeaders: [],
          timeLine: TimeLine.CONTINUOUS,
          timeLinePeriod: TimeLinePeriod.NONE,
          coloringConfig: [],
          showSummaryStats: false,
          summaryStatsAlignment: SummaryStatsAlignment.TOP,
          sortOrder: -1,
          verticalHeader: true,
          showValuesAsText: false,
          period: {
            type: PeriodType.SELECTION,
            from: null,
            to: null,
            isTodaySetAsEnd: false,
          },
          ignoreColumnsWithNoData: false,
        };

  if (loadingAthleteTableData) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <Formik<AthleteTablePeriodizationForm>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue, submitForm }) => {
          return (
            <FormContainer>
              <FormWrapper>
                <WidgetEditorTitleSection
                  value={values.name}
                  name="name"
                  handleChange={handleChange}
                />

                <TablePeriodizationWhatToShowSection />

                <TablePeriodizationDataSelectionSection />

                <PeriodSection
                  setFieldValue={setFieldValue}
                  period={values.period}
                  withDynamicSelection
                  showCalendarAboveInput={false}
                  sectionZIndex={100}
                />

                <BackgroundColoringSection />

                <TablePeriodizationSummarySection />

                <TablePeriodizationSortingSection />

                <TablePeriodizationAppearanceSection />
              </FormWrapper>
              <PreviewButtonSection
                isSaving={savingWidget}
                withAutoWidth
                onCancelClick={navigateToPreviousPage}
                onSaveClick={submitForm}
              >
                <AthleteTablePeriodizationTablePreview />
              </PreviewButtonSection>
            </FormContainer>
          );
        }}
      </Formik>
    </ScreenContainer>
  );
};
