import { useState, useEffect } from "react";

import throttle from "lodash/throttle";

const getDeviceConfig = (width) => {
  if (width > 1200) {
    return "xxl";
  } else if (width > 1000 && width < 1200) {
    return "xl";
  } else if (width > 800 && width < 1000) {
    return "md";
  } else if (width > 600 && width < 800) {
    return "sm";
  } else {
    return "xs";
  }
};

export const useBreakpoint = () => {
  const [breakPoint, setBreakPoint] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBreakPoint(getDeviceConfig(window.innerWidth));
    }, 200);

    window.addEventListener("resize", calcInnerWidth);

    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return breakPoint;
};
