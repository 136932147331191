import React, { memo } from "react";

export const LibrariesIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M1.5 16V4C1.5 3.17157 2.17157 2.5 3 2.5H8.17157C8.5694 2.5 8.95093 2.65804 9.23223 2.93934L11.5 5.20711C11.6875 5.39464 11.9419 5.5 12.2071 5.5H18C18.8284 5.5 19.5 6.17157 19.5 7V16C19.5 16.8284 18.8284 17.5 18 17.5H3C2.17157 17.5 1.5 16.8284 1.5 16Z"
      stroke="#3C434C"
    />
    <path d="M2 5.5H12" stroke="#3C434C" />
  </svg>
));
