import React from "react";

import { FacebookIcon } from "../../components/Icons/FacebookIcon";
import { InstagramIcon } from "../../components/Icons/InstagramIcon";
import { LinkedinIcon } from "../../components/Icons/LinkedinIcon";
import { TwitterIcon } from "../../components/Icons/TwitterIcon";
import { YoutubeIcon } from "../../components/Icons/YoutubeIcon";

import { BlogContainer } from "./blog/BlogContainer";
import {
  LeftSectionBoxTitle,
  LeftSectionBoxTitleWrapper,
  SectionBox,
  SocialMediaIconsWrapper,
  SocialMediaIconWrapper,
} from "./styles";

const socialMedias = [
  {
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/xpsnetwork/",
  },
  {
    icon: <YoutubeIcon />,
    url: "https://www.youtube.com/channel/UCA4RUrb0VhamM_FkYgxa45Q",
  },
  {
    icon: <TwitterIcon />,
    url: "https://twitter.com/xpsnetwork",
  },
  {
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/sidelinesports",
  },
  {
    icon: <LinkedinIcon />,
    url: "https://www.linkedin.com/company/sideline-sports/",
  },
];

export const LeftSectionBox = ({
  title = "",
  withBlog = false,
  withSocialMediaIcons = false,
}) => {
  return (
    <SectionBox shouldGrow={withBlog}>
      <LeftSectionBoxTitleWrapper direction={withBlog ? "column" : "row"}>
        {title ? <LeftSectionBoxTitle>{title}</LeftSectionBoxTitle> : null}
        {withBlog ? <BlogContainer /> : null}
        {withSocialMediaIcons ? (
          <SocialMediaIconsWrapper>
            {socialMedias.map((socialMedia) => (
              <SocialMediaIconWrapper target="_blank" href={socialMedia.url}>
                {socialMedia.icon}
              </SocialMediaIconWrapper>
            ))}
          </SocialMediaIconsWrapper>
        ) : null}
      </LeftSectionBoxTitleWrapper>
    </SectionBox>
  );
};
