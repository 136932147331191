import React, {
  createContext,
  ReactNode,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";

import { ApolloQueryResult } from "@apollo/client";
import { Dayjs } from "dayjs";

import { Resource } from "../../components/Calendar/model";
import { getSelectedResourceskey, UserPreferenceKeys } from "../../constants";
import {
  Exact,
  GetPreferenceQuery,
  Practice,
  useGetPreferenceQuery,
  useResourceGroupsQuery,
} from "../../graphql";
import { useAccessLevelsContext } from "../accessLevels";
import { useUserContext } from "../User";

type SelectableResourceList = {
  isOccupied?: boolean;
  isSelected?: boolean;
} & Resource;

export type ResourceCalendarContextModel = {
  fromDate: number;
  onSetDay: (day: Dayjs) => void;
  preferenceData: GetPreferenceQuery;
  refetchPreference: (
    variables?: Partial<
      Exact<{ sessionId?: string; accountId?: string; key: string }>
    >
  ) => Promise<ApolloQueryResult<GetPreferenceQuery>>;
  resourceList: Resource[];
  selectedDay: Dayjs;
  setResourceList: Dispatch<SetStateAction<Resource[]>>;
  toDate: number;
  onSelectResource: (id: string) => void;
  onClearSelectableResourceList: () => void;
  loadingResourceGroupsData: boolean;
  selectableResourceList: SelectableResourceList[];
  setSelectableResourceList: Dispatch<SetStateAction<SelectableResourceList[]>>;
  setSelectedSessionOwnerId: Dispatch<SetStateAction<string>>;
  originalResourceGroupList: Resource[];
  resourceGroupList;
  initializeSelectedResources: (list) => void;
  loadingSelectedResources: boolean;
};

const ResourceCalendarContext =
  createContext<ResourceCalendarContextModel>(null);

export function ResourceCalendarContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { sessionId, id: userId } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();

  const [resourceList, setResourceList] = useState<Resource[]>([]);
  const [selectedDay, setSelectedDay] = useState<Dayjs>(null);
  const [selectedSessionOwnerId, setSelectedSessionOwnerId] = useState(null);
  const [selectableResourceList, setSelectableResourceList] = useState<
    SelectableResourceList[]
  >([]);

  const { data: resourceGroupsData, loading: loadingResourceGroupsData } =
    useResourceGroupsQuery({
      variables: {
        sessionId,
        club: selectedSessionOwnerId || selectedAccount?.id,
      },
      skip: !sessionId || (!selectedAccount?.id && !selectedSessionOwnerId),
      fetchPolicy: "network-only",
    });

  const resourceGroupList =
    useMemo(
      () =>
        resourceGroupsData?.resourceGroups
          ? resourceGroupsData?.resourceGroups
              ?.flatMap((resourceGroup) => {
                if (resourceGroup?.resources?.length) {
                  return resourceGroup?.resources;
                }
              })
              .filter((resource) => resource)
          : [],
      [resourceGroupsData?.resourceGroups]
    ) ?? [];

  const originalResourceGroupList = resourceGroupList?.map((resource) => ({
    resourceId: resource?.guid,
    resourceTitle: resource?.name,
  }));

  const onSelectResource = useCallback(
    (id: string) => {
      const updatedResourceList = selectableResourceList?.map((resource) => {
        if (resource.resourceId === id) {
          return {
            ...resource,
            isSelected: !resource.isSelected,
          };
        }

        return resource;
      });

      setSelectableResourceList(updatedResourceList);
    },
    [selectableResourceList]
  );

  const initializeSelectedResources = useCallback(
    (sessionList: Practice) => {
      const selectedResourceList = sessionList?.resourcesUsage?.items?.map(
        ({ resource }) => resource?.guid
      );

      const updatedResourceList =
        selectableResourceList?.map((resource) => {
          const isResourceSelected = selectedResourceList?.find(
            (selectedResource) => selectedResource === resource.resourceId
          );

          if (isResourceSelected) {
            return {
              ...resource,
              isSelected: true,
            };
          }

          return resource;
        }) ?? [];

      setSelectableResourceList(updatedResourceList);
    },
    [selectableResourceList]
  );

  const onClearSelectableResourceList = useCallback(() => {
    const resourceListReset = selectableResourceList?.map((resource) => ({
      ...resource,
      isSelected: false,
    }));

    setSelectableResourceList(resourceListReset);
  }, [selectableResourceList]);

  useEffect(
    function setResourceGroupsToLocalState() {
      if (!selectableResourceList.length && originalResourceGroupList?.length) {
        setSelectableResourceList(originalResourceGroupList);
      }
      if (
        selectableResourceList.length &&
        !originalResourceGroupList.length &&
        !loadingResourceGroupsData
      ) {
        setSelectableResourceList([]);
      }
    },
    [
      originalResourceGroupList,
      selectableResourceList,
      loadingResourceGroupsData,
    ]
  );

  const fromDate = useMemo(
    () => selectedDay?.hour(0).minute(0).second(0).valueOf(),
    [selectedDay]
  );

  const toDate = useMemo(
    () => selectedDay?.hour(23).minute(59).second(59).valueOf(),
    [selectedDay]
  );

  const {
    data: preferenceData,
    refetch: refetchPreference,
    loading: loadingPreference,
  } = useGetPreferenceQuery({
    variables: {
      sessionId,
      accountId: selectedAccount?.id,
      key: getSelectedResourceskey({ userId }),
    },
    skip: !selectedAccount,
  });

  // TODO: remove in next release -> only for backward compatibility (added on 2.11.2023)
  const {
    data: preferenceDataPerAccount,
    loading: loadingPreferencePerAccount,
  } = useGetPreferenceQuery({
    variables: {
      sessionId,
      accountId: selectedAccount?.id,
      key: UserPreferenceKeys.SELECTED_RESOURCES,
    },
    skip: !selectedAccount,
  });

  useEffect(
    function setResourceListFromPreferences() {
      const storedPreferenceData = preferenceData?.getPreference
        ? JSON.parse(preferenceData.getPreference)
        : [];

      setResourceList(storedPreferenceData);
    },
    [preferenceData, preferenceDataPerAccount]
  );

  const onSetDay = useCallback((day: Dayjs) => setSelectedDay(day), []);

  return (
    <ResourceCalendarContext.Provider
      value={{
        fromDate,
        onSetDay,
        preferenceData,
        refetchPreference,
        resourceList,
        selectedDay,
        setResourceList,
        toDate,
        onSelectResource,
        loadingResourceGroupsData,
        onClearSelectableResourceList,
        selectableResourceList,
        setSelectableResourceList,
        setSelectedSessionOwnerId,
        originalResourceGroupList,
        resourceGroupList,
        initializeSelectedResources,
        loadingSelectedResources:
          loadingPreference || loadingPreferencePerAccount,
      }}
    >
      {children}
    </ResourceCalendarContext.Provider>
  );
}

export const useResourceCalendarContext = () =>
  useContext(ResourceCalendarContext);
