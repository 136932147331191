import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";

import { TrainingDays } from "./TrainingDays";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const DayLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  gap: 5px;
`;

const DayLabel = styled.p`
  color: ${COLOR_SECONDARY_GREY_HOVER};
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
`;

const SessionLabel = styled.p`
  color: ${COLOR_SECONDARY_GREY_HOVER};
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
`;

const TrainingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  border: 0.5px solid rgba(60, 67, 76, 0.05);
  box-shadow: 0 2px 6px 0 rgba(21, 31, 59, 0.08);
  align-items: center;
  padding: 7px 15px;
`;

const TrainingInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TrainingName = styled.p`
  color: ${COLOR_TEXT_DARK};
  font-family: "Inter", sans-serif;
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: 700;
`;

const DurationLabel = styled.p`
  color: ${COLOR_MEDIUM_GRAY};
  font-family: "Inter", sans-serif;
  margin: 0;
  font-size: 10px;
  font-weight: 500;
`;

const TrainingDayTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TimeInput = styled.input`
  background-color: ${COLOR_GRAY};
  color: ${COLOR_TEXT_DARK};
  border-radius: 6px;
  height: 41px;
  padding: 0 15px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
  ,
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TrainingDaySelect = ({
  days,
  day,
  dayIndex,
  onDayPress,
  onTimeChange,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <DayLabelWrapper>
        <DayLabel>
          {t("day")} {day.dayOfWeekSequential}
        </DayLabel>
        {day.hasMultipleSessions ? (
          <SessionLabel>
            {t("session")} {day.sessionOfDay + 1}
          </SessionLabel>
        ) : null}
      </DayLabelWrapper>
      <TrainingWrapper>
        <TrainingInfoWrapper>
          <TrainingName>{day.label}</TrainingName>
          {day.duration ? (
            <DurationLabel>
              {day.duration} {t("min").toUpperCase()}
            </DurationLabel>
          ) : null}
        </TrainingInfoWrapper>
        <TrainingDayTimeWrapper>
          <TrainingDays
            days={days}
            day={day}
            dayIndex={dayIndex}
            onDayPress={onDayPress}
          />
          <TimeInput
            placeholder="time"
            value={day.time}
            onChange={(e) => onTimeChange(dayIndex, e.target.value)}
            name="time"
            type="time"
          />
        </TrainingDayTimeWrapper>
      </TrainingWrapper>
    </Wrapper>
  );
};
