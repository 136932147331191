import { useUserContext } from "../../contexts/User";
import { SessionInput, useCreateWorkoutMutation } from "../../graphql";

export const useCreateWorkout = () => {
  const { sessionId, language, timezone } = useUserContext();

  const [createWorkoutMutation] = useCreateWorkoutMutation();

  const createWorkout = async (
    { focusedAthlete, ...input }: SessionInput & { focusedAthlete: string },
    refetchQueries: () => string[]
  ) => {
    return await createWorkoutMutation({
      variables: {
        timezone,
        language,
        sessionId,
        focusedAthlete,
        input,
      },

      refetchQueries,
    });
  };

  return { createWorkout };
};
