export default {
  UNKNOWN: 0,
  THERAPY: 1,
  EXERCISE_SET: 2,
  DRILL: 3,
  INJURY: 4,
  SICKNESS: 5,
  DOCUMENT: 6,
  MASTERPLAN: 7,
  MASTERPLAN_TEMPLATE: 8,
  OBSERVATION: 9,
  SEASON: 10,
  WORKOUT_TEMPLATE: 11,
  PERSONAL_DOCUMENT: 12,
  PERSONAL_MASTERPLAN: 13,
  STAT_QUERY: 14,
  PERSONAL_MPLAN_FOR_STATS_OLD: 15,
  PRACTICE_TEMPLATE_OLD: 16,
  EDIBLE_THING: 17,
  MEAL_TEMPLATE: 18,
  PERSONAL_MPLAN_FOR_TESTS_OLD: 19,
  PERSONAL_MPLAN_FOR_INFORMATION_OLD: 20,
  PERSONAL_GOAL: 21,
  PERSONAL_FAVORITE_EXER: 22,
  PERSONAL_FAVORITE_DRILLS: 23,
  PERSONAL_FAVORITE_WT: 24,
  PERSONAL_FAVORITE_TESTS: 25,
  GOLF_COURSE: 26,
  PLAYBOOK: 27,
  PERSONAL_MPLAN_FOR_TESTS_OLD2: 28,
  PERSONAL_MPLAN_FOR_INFORMATION_OLD2: 29,
  SVA_FORMULA: 30,
  SVA_GAME: 31,
  SVA_TEAM: 32,
  SVA_QUERY: 33,
  SVA_EVENT_SET: 34,
  SVA_STATS: 35,
  HOPPER: 36,
  NUTRITION_REPORT: 37,
  LIFE_COACHING: 38,
  TAG_EXERCISE: 39,
  TAG_DRILL: 40,
  TAG_DOCUMENT: 31,
  TAG_PROGRAM: 42,
  QUESTIONNAIRE_COLLECTION: 43,
  QUESTIONNAIRE_ITEM: 44,
  TRAINING_LOAD: 45,
  SVA_REPORT_ITEM: 46,
  SVA_REPORT_WIDGET_TEMPLATE: 47,
  SVA_PRESENTATION: 48,
  PRACTICE_TEMPLATE_2020: 49,
  TAG_PROGRAM_PRACTICES: 50,
};
