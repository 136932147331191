import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const TrashIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#3C434C",
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1H9C8.44772 1 8 1.44772 8 2V3H13V2C13 1.44772 12.5523 1 12 1ZM7 2V3H5H4H2V4H4V18C4 19.6569 5.34315 21 7 21H14C15.6569 21 17 19.6569 17 18V4H19V3H17H16H14V2C14 0.895431 13.1046 0 12 0H9C7.89543 0 7 0.895431 7 2ZM13 4H14H16V18C16 19.1046 15.1046 20 14 20H7C5.89543 20 5 19.1046 5 18V4H7H8H13ZM8 17V7H7V17H8ZM11 17V7H10V17H11ZM14 17V7H13V17H14Z"
        fill={tintColor}
      />
    </svg>
  )
);
