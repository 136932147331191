import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";

import { AggregateFunction } from "../../graphql";
import { getDataKey } from "../../utils/statistics";
import { GeneralButton } from "../Button/";
import { IconButton } from "../IconButton";
import { BinIcon, PlusIcon } from "../Icons";
import { Label } from "../Label";
import {
  AthleteTablePeriodizationColumnHeader,
  LinearColoring,
  TablePeriodizationColumnHeader,
  TablePeriodizationForm,
} from "../Report/PeriodizationTable/model";
import { IconButtonWrapper } from "../ReportCreateTable/styled";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { SelectField } from "../SelectField";

import { StartDateComparison } from "./StartDateComparison";

const ColoringSelectorLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const ColoringSelectorWrapper = styled.div`
  width: 50%;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 90;
`;

const StyledGeneralButton = styled(GeneralButton)`
  align-self: flex-start;
`;

interface BackgroundColoringSectionProps {
  dataAccessor?: string;
  measurementTemplateIdAccessor?: string;
  isGroup?: boolean;
  hasPeriod?: boolean;
  filterOptions?: (item: {
    collectionId: string;
    measurementId: string;
    aggregation: AggregateFunction;
  }) => boolean;
}

export function BackgroundColoringSection({
  dataAccessor = "columnHeaders",
  measurementTemplateIdAccessor = "itemId",
  filterOptions = null,
  isGroup = false,
  hasPeriod = false,
}: BackgroundColoringSectionProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<TablePeriodizationForm>();

  const generateLabelForColumnHeader = useCallback(
    (
      columnHeader:
        | AthleteTablePeriodizationColumnHeader
        | TablePeriodizationColumnHeader,
      currentAggregation: AggregateFunction
    ) => {
      let label = columnHeader.measurementTemplateName;

      if (
        values[dataAccessor].filter(
          (item) =>
            item[measurementTemplateIdAccessor] ===
            columnHeader[measurementTemplateIdAccessor]
        ).length > 1 &&
        columnHeader.measurementTemplateName
      ) {
        label += ` - ${columnHeader.attributeTemplateName}`;
      }

      if (columnHeader.aggregateFunctions.length > 1) {
        label += ` - ${t(`aggregation${currentAggregation}`, {
          defaultValue: currentAggregation,
        })}`;
      }

      return label;
    },
    [t, dataAccessor, measurementTemplateIdAccessor, values]
  );

  const coloringSourceOptions = useMemo(
    () =>
      values[dataAccessor].flatMap((columnHeader) =>
        columnHeader.aggregateFunctions
          .flatMap((currentAggregation) =>
            typeof filterOptions !== "function" ||
            filterOptions({
              collectionId: columnHeader.collectionId,
              measurementId: columnHeader[measurementTemplateIdAccessor],
              aggregation: currentAggregation,
            })
              ? {
                  value: getDataKey(
                    columnHeader[measurementTemplateIdAccessor],
                    columnHeader.attributeTemplateId,
                    currentAggregation
                  ),
                  label: generateLabelForColumnHeader(
                    columnHeader,
                    currentAggregation
                  ),
                }
              : null
          )
          .filter((option) => Boolean(option))
      ),
    [
      generateLabelForColumnHeader,
      filterOptions,
      values,
      dataAccessor,
      measurementTemplateIdAccessor,
    ]
  );

  const unusedColoringSourceOptions = useMemo(
    () =>
      coloringSourceOptions.filter(
        (option) =>
          !values.coloringConfig.some(
            (existingConfig) => existingConfig.id === option.value
          )
      ),
    [coloringSourceOptions, values.coloringConfig]
  );

  const coloringSchemeOptions = useMemo(
    () => [
      {
        label: t(LinearColoring.GREEN_WHITE),
        value: LinearColoring.GREEN_WHITE,
      },
      {
        label: t(LinearColoring.WHITE_GREEN),
        value: LinearColoring.WHITE_GREEN,
      },
      {
        label: t(LinearColoring.GREEN_YELLOW_RED),
        value: LinearColoring.GREEN_YELLOW_RED,
      },
      {
        label: t(LinearColoring.RED_YELLOW_GREEN),
        value: LinearColoring.RED_YELLOW_GREEN,
      },
    ],
    [t]
  );

  const onAddClick = useCallback(() => {
    setFieldValue("coloringConfig", [
      ...values.coloringConfig,
      {
        id: "",
        coloring: LinearColoring.GREEN_WHITE,
      },
    ]);
  }, [values.coloringConfig, setFieldValue]);

  const onDeleteClick = useCallback(
    (index: number) => {
      values.coloringConfig[index] = null;

      setFieldValue(
        "coloringConfig",
        values.coloringConfig.filter((value) => Boolean(value))
      );
    },
    [values.coloringConfig, setFieldValue]
  );

  return (
    // We need zIndex here due to rules button overlap
    <Section style={{ zIndex: 99 }}>
      <SectionTitle title={t("linearColoring")} />
      {hasPeriod && !!values?.coloringConfig?.length && <StartDateComparison />}
      <Content>
        {values.coloringConfig.map((config, index) => {
          const currentConfig = coloringSourceOptions.find(
            (option) => option.value === config.id
          );

          return (
            <ColoringSelectorLineWrapper key={index}>
              <ColoringSelectorLineWrapper>
                <ColoringSelectorWrapper>
                  <Label>{t("source").toUpperCase()}</Label>
                  <Field name={`coloringConfig[${index}].id`}>
                    {({
                      field,
                      form,
                      meta,
                    }: FieldProps<TablePeriodizationForm>) => (
                      <SelectField
                        field={field}
                        form={form}
                        meta={meta}
                        options={[
                          ...unusedColoringSourceOptions,
                          ...(currentConfig ? [currentConfig] : []),
                        ]}
                      />
                    )}
                  </Field>
                </ColoringSelectorWrapper>
                <ColoringSelectorWrapper>
                  <Label>{t("type").toUpperCase()}</Label>
                  <Field name={`coloringConfig[${index}].coloring`}>
                    {({
                      field,
                      form,
                      meta,
                    }: FieldProps<TablePeriodizationForm>) => (
                      <SelectField
                        field={field}
                        form={form}
                        meta={meta}
                        options={coloringSchemeOptions}
                      />
                    )}
                  </Field>
                </ColoringSelectorWrapper>
              </ColoringSelectorLineWrapper>
              <IconButtonWrapper mt={6} ml={0}>
                <IconButton
                  icon={<BinIcon />}
                  onClick={() => onDeleteClick(index)}
                />
              </IconButtonWrapper>
            </ColoringSelectorLineWrapper>
          );
        })}
        {isGroup && values?.coloringConfig?.length >= 1 ? null : (
          <StyledGeneralButton
            icon={<PlusIcon isActive />}
            label={t("addColoring")}
            onClick={onAddClick}
          />
        )}
      </Content>
    </Section>
  );
}
