import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../../colors";

import { InformationTemplateValues } from "./model";

type ValueItemProps = {
  id: string;
  informationTemplateValues: InformationTemplateValues;
};

const ValueWrapper = styled.div`
  margin: 10px 0 20px;
  background-color: ${COLOR_GRAY};
  height: 45px;
  align-items: center;
  display: flex;
`;

const Value = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: ${COLOR_TEXT_DARK};
  padding-left: 30px;
`;

export function ValueItem({ id, informationTemplateValues }: ValueItemProps) {
  const { t } = useTranslation();

  if (
    !informationTemplateValues ||
    !Object.keys(informationTemplateValues)?.length
  ) {
    return (
      <ValueWrapper>
        <Value>{t("emptyInformationValue")}</Value>
      </ValueWrapper>
    );
  }

  const valueToDisplay = informationTemplateValues[id]?.value?.join(", ");

  return (
    <ValueWrapper>
      <Value>{valueToDisplay}</Value>
    </ValueWrapper>
  );
}
