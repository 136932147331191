import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import {
  GroupAvailableForSession,
  useAgendaContext,
} from "../../contexts/agenda/AgendaProvider";
import { useNotificationsContext } from "../../contexts/notifications";
import { BottomBar } from "../BottomBar";

import { SelectSessionGroupRow } from "./SelectSessionGroup/Row";

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  margin-top: auto;
  background-color: ${COLOR_WHITE};
  border-top-width: 1px;
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;

  & > div {
    padding: 20px;
  }
`;

interface Props {
  onConfirm: (group: GroupAvailableForSession, groupOwnerId?: string) => void;
  closeModal: () => void;
}

export const SelectSessionGroupModal = ({ onConfirm, closeModal }: Props) => {
  const { getTeamSessionsRight } = useAccessLevelsContext();
  const { showWarningNotification } = useNotificationsContext();

  const [activeGroupId, setActiveGroupId] = useState<string>("");
  const [activeGroupOwnerId, setActiveGroupOwnerId] = useState<string>("");
  const { t } = useTranslation();
  const { groupsAvailableForSession } = useAgendaContext();

  const onSubmit = (groupId?: string, groupOwnerId?: string) => {
    if (groupId) {
      const groupToSelect = groupsAvailableForSession.find(
        (group) => group.id === groupId
      );

      if (groupToSelect) {
        onConfirm(groupToSelect, groupOwnerId);
      }
    }
  };

  const handleSetActiveGroup = (groupId: string, groupOwnerId: string) => {
    if (groupId === activeGroupId) {
      setActiveGroupId("");
      setActiveGroupOwnerId("");
    } else {
      setActiveGroupId(groupId);
      setActiveGroupOwnerId(groupOwnerId);
    }
  };

  const onDonePress = () => {
    const { edit } = getTeamSessionsRight(activeGroupOwnerId);

    if (!edit) {
      return showWarningNotification(t("cannotCreateTeamSession"));
    }

    onSubmit(activeGroupId, activeGroupOwnerId);
  };

  return (
    <>
      {groupsAvailableForSession.map((group: any) => (
        <SelectSessionGroupRow
          key={group.id}
          data={group}
          isSelected={activeGroupId === group.id}
          onClick={() => handleSetActiveGroup(group.id, group.owner?.id)}
          onDoubleClick={() => onSubmit(group.id, group?.owner?.id)}
        />
      ))}
      <BottomSection style={{ marginTop: "auto" }}>
        <BottomBar
          style={{ width: "100%" }}
          secondary={{
            text: t("cancel"),
            onClick: closeModal,
          }}
          primary={{
            text: t("done"),
            onClick: onDonePress,
          }}
        />
      </BottomSection>
    </>
  );
};
