import React from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import {
  CalendarDottedIcon,
  FolderFullIcon,
  HomeIcon,
  MonitoringIcon,
} from "../Icons";

import { BaseNavSideWrapper } from "./BaseNavSideWrapper";
import { NavIcon } from "./NavIcon";
import { MessagesNavSideSection } from "./Sections/MessagesNavSideSection";

import { SectionTitleText } from "./index";

interface NavSideProps {
  isCompact: boolean;
}

export const AthleteNavSide = ({ isCompact }: NavSideProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <BaseNavSideWrapper isCompact={isCompact}>
      {isAcesNationEnv() ? (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
          iconSvg={
            <CalendarDottedIcon
              isActive={Boolean(matchPath(ROUTING_CONFIG.calendar, pathname))}
            />
          }
          isCompact={isCompact}
          text={t("calendar")}
          onClick={() => navigate(`/${ROUTING_CONFIG.calendar}`)}
        />
      ) : (
        <NavIcon
          active={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
          iconSvg={
            <HomeIcon
              isActive={Boolean(matchPath(ROUTING_CONFIG.myXPS, pathname))}
            />
          }
          isCompact={isCompact}
          text={t("myXPS")}
          onClick={() => navigate(`/${ROUTING_CONFIG.myXPS}`)}
        />
      )}

      <MessagesNavSideSection isCompact={isCompact} />

      {!isCompact && (
        <SectionTitleText>{t("workspace").toUpperCase()}</SectionTitleText>
      )}

      <NavIcon
        active={Boolean(matchPath(ROUTING_CONFIG.AthleteProfile, pathname))}
        iconSvg={
          <MonitoringIcon
            isActive={Boolean(
              matchPath(ROUTING_CONFIG.AthleteProfile, pathname)
            )}
          />
        }
        isCompact={isCompact}
        text={t("monitoring")}
        onClick={() => navigate(`/${ROUTING_CONFIG.AthleteProfile}`)}
      />

      {!isCompact && (
        <SectionTitleText>{t("library").toUpperCase()}</SectionTitleText>
      )}

      <NavIcon
        active={Boolean(matchPath(ROUTING_CONFIG.collections, pathname))}
        iconSvg={
          <FolderFullIcon
            isActive={Boolean(matchPath(ROUTING_CONFIG.collections, pathname))}
          />
        }
        isCompact={isCompact}
        text={t("collections")}
        onClick={() => navigate(`/${ROUTING_CONFIG.collections}`)}
      />
    </BaseNavSideWrapper>
  );
};
