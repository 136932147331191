import React from "react";

import { ChartWidgetComponent } from "./ChartWidgetComponent";
import { useChartOptions } from "./hooks/useChartOptions";
import { ChartWidgetModel } from "./model";

interface ChartWidgetProps {
  widget: ChartWidgetModel;
  isGroupReport: boolean;
}

export function ChartWidget({ widget, isGroupReport }: ChartWidgetProps) {
  const { options, isLoading } = useChartOptions({
    widget,
    isGroupReport,
    isPreviewMode: false,
  });

  return (
    <ChartWidgetComponent
      widget={widget}
      isLoading={isLoading}
      chartOptions={options}
    />
  );
}
