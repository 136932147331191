import React, { useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import styled, { css } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { useNewChatContext } from "../../contexts/messaging/NewChatProvider";
import { useNotificationsContext } from "../../contexts/notifications";
import { useUIContext } from "../../contexts/UI";
import { useUserContext } from "../../contexts/User";
import {
  ChatterType,
  CreateGroupChatMutationVariables,
  useCreateGroupChatMutation,
  useFindChattableUsersQuery,
  useGetTheGroupsImInQuery,
  useSaveChatMessageMutation,
} from "../../graphql";
import { MessageList2 } from "../../graphql/queries/MessageList2";
import { MessageOverviewPaginated } from "../../graphql/queries/MessageOverviewPaginated";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { sortList } from "../../utils/sortList";
import { UpsertGroupChatStage2ModalBody } from "../Communication/UpsertGroupChatStage2ModalBody";
import { Loader } from "../Loader";
import StyledText from "../StyledText";
import Tabs, { Tab } from "../Tabs";

import { BottomBar } from "./ChatModals/BottomBar";
import { ControlBar } from "./ChatModals/ControlBar";
import { SelectedUsersRow } from "./ChatModals/Group/SelectedUsersRow";
import {
  createChatWithMsg,
  createGroupUpdateCb,
  sendMessageUpdateCb,
} from "./ChatModals/misc";
import { ChatTabs } from "./ChatModals/model";
import { getChatterType } from "./ChatModals/utils";
import { Team } from "./Components/Team";

const getTabItems = (
  t: TFunction<"common">,
  isTrainer: boolean,
  isFamilyMember: boolean
) => [
  { id: ChatTabs.ALL, title: t("all").toUpperCase() },
  ...(isFamilyMember || isTrainer
    ? [
        {
          id: ChatTabs.COACHES,
          title: t("coaches").toUpperCase(),
        },
      ]
    : []),
  ...(isTrainer
    ? [
        {
          id: ChatTabs.ATHLETES,
          title: t("athletes").toUpperCase(),
        },
      ]
    : []),

  ...(isFamilyMember || isTrainer
    ? [
        {
          id: ChatTabs.FAMILY,
          title: t("family").toUpperCase(),
        },
      ]
    : []),
];

const Wrapper = styled.div`
  z-index: 1;
  background-color: ${COLOR_WHITE};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 90vh;
`;

const TabsContainer = styled.div<{ uiWidth: number }>(
  ({ uiWidth }) => css`
    border-bottom-width: 1px;
    border-bottom-color: ${COLOR_GREY_ACTIVE};
    border-bottom-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: ${uiWidth & 0.05}px;
    padding-right: ${uiWidth & 0.05}px;
  `
);

const LoaderWrapper = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: calc(90vh - 256px);
  width: 100%;
`;

const TeamsContainer = styled.div<{ hasAttendance: boolean }>`
  padding-top: 21px;
  flex-direction: column;
  padding-bottom: 21px;
  overflow-y: auto;
  display: flex;
  height: ${({ hasAttendance }) =>
    `calc(90vh - ${hasAttendance ? 342 : 271}px)`};
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CreateNewGroupChat = ({
  closeModal,
  onRowPress,
  msg,
  onShow,
  onAddParticipants,
  isForwardMessageVariant,
}) => {
  const user = useUserContext();
  const { sessionId, language, isFamilyMember } = user;
  const client = useApolloClient();
  const { t } = useTranslation();

  const tabItems = isForwardMessageVariant
    ? getTabItems(t, isRealTrainer(user), isFamilyMember)
    : getTabItems(t, true, true);
  const ui = useUIContext();
  const [onlyTrainersCanPost, setOnlyTrainersCanPost] = useState(false);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupName, setGroupName] = useState("");
  const [attendingUsers, setAttendingUsers] = useState([]);
  const [tab, setTab] = useState<Tab>(tabItems[0]);
  const {
    announce,
    autoAddFamily,
    autoAddAthletes,
    stage,
    forAllAutoAddedFriendsOfChatter,
  } = useNewChatContext();
  const isGroups = tab.type === "GROUPS";
  const [sendMessage, { loading: sendingMessage }] =
    useSaveChatMessageMutation();
  const { showErrorNotification } = useNotificationsContext();

  const chatterType = useMemo(() => getChatterType(tab, t), [tab, t]);

  const [createGroup] = useCreateGroupChatMutation();
  const { data, loading } = useFindChattableUsersQuery({
    variables: { sessionId, chatterType },
  });

  const { data: groupsData, loading: groupsLoading } = useGetTheGroupsImInQuery(
    {
      variables: { sessionId },
    }
  );

  const groupsPeople = useMemo(() => {
    if (groupsData?.getTheGroupsImIn) {
      const filteredUsers = groupsData.getTheGroupsImIn.filter((u) =>
        u.name.toLowerCase().includes(search.toLowerCase())
      );
      return !search ? groupsData.getTheGroupsImIn : filteredUsers;
    }

    return [];
  }, [groupsData, search]);

  const users = useMemo(() => {
    if (data?.findChattableUsers) {
      if (stage === 2) return attendingUsers;
      return search.length < 4
        ? data.findChattableUsers
        : data.findChattableUsers.filter((u) =>
            u.name.toLowerCase().includes(search.toLowerCase())
          );
    } else return [];
  }, [data, search, stage, attendingUsers]);

  const groupedUsers = sortList([...users], language)
    .sort((a) => (a.chatterType2 === ChatterType.Trainer ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Athlete ? -1 : 1))
    .sort((a) => (a.chatterType2 === ChatterType.Family ? 1 : -1))
    .reduce((acc, value) => {
      if (stage === 1 && value.jointMainGroups.length) {
        value.jointMainGroups.forEach((g) => {
          if (!acc[g.name])
            acc[g.name] = {
              owner: g.owner,
              value: [],
            };
          acc[g.name]["value"].push(value);
        });
      } else {
        if (!acc["all"]) acc["all"] = { owner: null, value: [] };
        acc["all"]["value"].push(value);
      }
      return acc;
    }, []);

  // TODO introduce new stage when forwarding messages
  const onCreateChat = async () => {
    if (msg) {
      const { messageBody } = msg;
      await createChatWithMsg(
        msg,
        attendingUsers,
        async (newContact, au, files, attachments) => {
          return sendMessage({
            variables: {
              sessionId,
              language,
              input: {
                text: messageBody,
                files,
                attachments,
                receiver: au.id,
                forwarded: true,
              },
            },
            update: sendMessageUpdateCb(sessionId, language, newContact, au),
          });
        }
      ).then(() => {
        closeModal();
        onShow();
      });
    } else if (onAddParticipants) {
      await onAddParticipants(attendingUsers);
      await closeModal();
    } else {
      const variables: CreateGroupChatMutationVariables = {
        sessionId,
        name: groupName,
        onlyTrainersCanPost,
        participants: [...attendingUsers.map((au) => au.id)],
      };

      if (isGroups) {
        delete variables.participants;
        variables.autoAddTrainers = true;
        variables.autoAddAthletes = autoAddAthletes;
        variables.autoAddFamily = autoAddFamily;
        if (announce) variables.linkedGroup = announce.id;
        if (forAllAutoAddedFriendsOfChatter)
          variables.forAllAutoAddedFriendsOf =
            forAllAutoAddedFriendsOfChatter.id;
      }

      try {
        await createGroup({
          variables,
          update: createGroupUpdateCb(
            sessionId,
            language,
            groupName,
            async (newContact) => {
              await client.refetchQueries({
                include: [MessageOverviewPaginated, MessageList2],
              });
              await onRowPress(newContact);
              closeModal();
            }
          ),
        });
      } catch (error) {
        const errorMessage =
          (error?.graphQLErrors?.[0] as any)?.exception?.detailMessage ||
          t("createChatGroupErrorMessage");

        showErrorNotification(errorMessage);
      }
    }
  };

  const groupsCount = useMemo(() => {
    return Object.keys(groupedUsers).length;
  }, [groupedUsers]);

  return (
    <>
      <Container>
        {stage === 1 && (
          <>
            <ControlBar search={search} setSearch={setSearch} />
            {Boolean(attendingUsers.length) && stage === 1 && (
              <SelectedUsersRow
                attendingUsers={attendingUsers}
                setAttendingUsers={setAttendingUsers}
              />
            )}
            <Wrapper>
              <TabsContainer uiWidth={ui.width}>
                {tabItems?.length > 1 && (
                  <Tabs
                    tab={tab}
                    setTab={setTab}
                    tabs={tabItems}
                    tabStyle={{ marginLeft: 0, marginBottom: 0 }}
                    spaceBetween={30}
                  />
                )}
              </TabsContainer>
              {loading || groupsLoading ? (
                <LoaderWrapper>
                  <Loader size="small" color={COLOR_BLUE} />
                </LoaderWrapper>
              ) : (
                <TeamsContainer hasAttendance={!!attendingUsers.length}>
                  <>
                    {sortList(Object.keys(groupedUsers), language, (gu) => {
                      const owner = groupedUsers[gu].owner;
                      const ownerName = [owner?.firstName, owner?._lastName]
                        .filter((text) => !!text)
                        .join(" ");

                      return ownerName + gu;
                    })
                      .sort((a, b) => {
                        if (a === "all" && b === "all") return 0;
                        if (a === "all") return 1;
                        if (b === "all") return -1;
                        return 0;
                      })
                      .map((gu, index) => (
                        <Team
                          key={index}
                          isCreateGroupChat
                          setAttendingUsers={setAttendingUsers}
                          search={search}
                          onClick={async (user, isAttending) => {
                            let currentAttendingUsers;
                            if (isAttending) {
                              currentAttendingUsers = attendingUsers.filter(
                                (au) => au.id !== user.id
                              );
                            } else {
                              currentAttendingUsers = attendingUsers.slice();
                              currentAttendingUsers.push(user);
                            }
                            await setAttendingUsers(currentAttendingUsers);
                          }}
                          currentKey={gu}
                          stage={stage}
                          groupsCount={groupsCount}
                          groups={groupedUsers}
                          attendingUsers={attendingUsers}
                          index={index}
                          showAttendingImages
                          isCreateChat
                        />
                      ))}
                  </>
                  {(!isGroups || !groupsPeople.length) &&
                    !users.length &&
                    (tab?.type !== "ALL" || !groupsPeople.length) && (
                      <StyledText
                        style={{ textAlign: "center" }}
                        fontWeight="bold"
                        color={COLOR_TEXT_DEFAULT}
                      >
                        {` ${t("noOne")} `}
                      </StyledText>
                    )}
                </TeamsContainer>
              )}
            </Wrapper>
          </>
        )}
        {stage === 2 && (
          <ModalContainer>
            <UpsertGroupChatStage2ModalBody
              groupName={groupName}
              setGroupName={setGroupName}
              onlyTrainersCanPost={onlyTrainersCanPost}
              setOnlyTrainersCanPost={setOnlyTrainersCanPost}
              usersInChat={attendingUsers}
            />
          </ModalContainer>
        )}
      </Container>
      <BottomBar
        variant="group"
        closeModal={() => {
          closeModal();
          onShow && onShow();
        }}
        done={onCreateChat}
        sendingMessage={sendingMessage}
        disableNext={attendingUsers.length < 2}
        disableDone={
          isForwardMessageVariant ? !attendingUsers.length : !groupName?.trim()
        }
        isMultistage
        count={attendingUsers?.length}
        editMode={isForwardMessageVariant}
      />
    </>
  );
};

export default CreateNewGroupChat;
