import React, { memo } from "react";

export const StrengthIcon = memo(() => (
  <svg
    width="54"
    height="44"
    viewBox="0 0 54 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.3373 3.93726C38.0876 2.68758 36.0615 2.68758 34.8118 3.93726L33.8746 4.87452L33.7373 4.73726C32.4876 3.48758 30.4615 3.48758 29.2118 4.73726L25.7373 8.21178C24.4876 9.46145 24.4876 11.4876 25.7373 12.7373L29.9746 16.9746L21.9746 24.9746L17.7373 20.7373C16.4876 19.4876 14.4615 19.4876 13.2118 20.7373L9.73726 24.2118C8.48758 25.4615 8.48758 27.4876 9.73726 28.7373L9.87452 28.8746L8.93726 29.8118C7.68758 31.0615 7.68758 33.0876 8.93726 34.3373L15.6118 41.0118C16.8615 42.2615 18.8876 42.2615 20.1373 41.0118L20.2745 40.8746L9.07458 29.6746L9.87458 28.8746L21.0745 40.0746L21.0746 40.0746L21.2118 40.2118C22.4615 41.4615 24.4876 41.4615 25.7373 40.2118L29.2118 36.7373C30.4615 35.4876 30.4615 33.4615 29.2118 32.2118L24.9746 27.9746L32.9746 19.9746L37.2118 24.2118C38.4615 25.4615 40.4876 25.4615 41.7373 24.2118L45.2118 20.7373C46.4615 19.4876 46.4615 17.4615 45.2118 16.2118L45.0746 16.0746L45.0746 16.0745L33.8746 4.87458L34.6746 4.07458L45.8746 15.2745L46.0118 15.1373C47.2615 13.8876 47.2615 11.8615 46.0118 10.6118L39.3373 3.93726Z"
      fill="#A0BEEC"
    />
  </svg>
));
