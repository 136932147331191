import React, { memo } from "react";

export const FacebookIcon = memo(() => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0938 1.5625H3.90625C3.28465 1.5625 2.68851 1.80943 2.24897 2.24897C1.80943 2.68851 1.5625 3.28465 1.5625 3.90625L1.5625 21.0938C1.5625 21.7154 1.80943 22.3115 2.24897 22.751C2.68851 23.1906 3.28465 23.4375 3.90625 23.4375H10.6079V16.0005H7.53174V12.5H10.6079V9.83203C10.6079 6.79736 12.4146 5.12109 15.1816 5.12109C16.5068 5.12109 17.8926 5.35742 17.8926 5.35742V8.33594H16.3657C14.8613 8.33594 14.3921 9.26953 14.3921 10.2271V12.5H17.7505L17.2134 16.0005H14.3921V23.4375H21.0938C21.7154 23.4375 22.3115 23.1906 22.751 22.751C23.1906 22.3115 23.4375 21.7154 23.4375 21.0938V3.90625C23.4375 3.28465 23.1906 2.68851 22.751 2.24897C22.3115 1.80943 21.7154 1.5625 21.0938 1.5625Z"
      fill="#3C434C"
    />
  </svg>
));
