import { useUserContext } from "../../../contexts/User";
import {
  ReportBuildingMeasurementTemplatesQuery,
  useReportBuildingMeasurementTemplatesQuery,
} from "../../../graphql";

export const useTransformChartWidgetData = (
  widget: any,
  options?: { skip?: boolean }
) => {
  const { sessionId, language } = useUserContext();

  const widgetSoccerItem = widget?.items?.find(
    (item) =>
      item.templateId === "SoccerStat.MinutesInPosition" ||
      item.templateGuid === "SoccerStat.MinutesInPosition"
  );

  const skipQuery = !widget || !widgetSoccerItem || !widgetSoccerItem?.dataType;

  const { data } = useReportBuildingMeasurementTemplatesQuery({
    variables: {
      sessionId,
      language,
      collectionId: widgetSoccerItem?.dataType,
    },
    skip: options?.skip || skipQuery,
  });

  if (
    skipQuery ||
    options?.skip ||
    !widget ||
    !data ||
    !data.reportBuildingMeasurementTemplates.some((template) =>
      template.id.includes("SoccerStat.MinutesInPosition")
    )
  ) {
    return {
      chartWidget: !widget?.items?.some(
        (item) =>
          item.templateId === "SoccerStat.MinutesInPosition" ||
          item.templateGuid === "SoccerStat.MinutesInPosition"
      )
        ? widget
        : null,
    };
  }
  const chartWidget = { ...widget };
  const chartWidgetItems: ReportBuildingMeasurementTemplatesQuery["reportBuildingMeasurementTemplates"] =
    [];

  widget.items?.forEach((item) => {
    if (
      item.templateGuid === "SoccerStat.MinutesInPosition" ||
      item.templateId === "SoccerStat.MinutesInPosition"
    ) {
      data.reportBuildingMeasurementTemplates.forEach((template) => {
        if (template.id.includes("SoccerStat.MinutesInPosition.")) {
          chartWidgetItems.push({
            ...item,
            templateGuid: template.id,
            templateId: template.id,
            name: `${template.name}`,
            templateName: `${template.name}`,
          });
        }
      });
    } else {
      chartWidgetItems.push(item);
    }
  });
  chartWidget.items = chartWidgetItems;

  return { chartWidget };
};
