import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePrintAthleteReportContext } from "../../contexts/Print/PrintAthleteReportProvider";

import { PrintSelector } from "./PrintSelector";

interface HeaderDropdownProps {
  headerCells: { id: string; title: string }[];
}

export const HeaderDropdown = ({ headerCells }: HeaderDropdownProps) => {
  const { t } = useTranslation();
  const { headerIds, setHeaderIDs } = usePrintAthleteReportContext();

  const cells = useMemo(
    () =>
      headerCells?.map((cell) => ({
        value: cell.id,
        label: cell.title,
      })) ?? [],
    [headerCells]
  );

  return (
    <PrintSelector
      setSectionIDs={setHeaderIDs}
      selectedIds={headerIds}
      options={cells}
      placeholder={t("chooseHeadersPlaceholder")}
    />
  );
};
