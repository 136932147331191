import React, { useEffect, useMemo, useState } from "react";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Dayjs } from "dayjs";
import styled from "styled-components";

import { COLOR_WHITE } from "../../colors";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { useNotificationsContext } from "../../contexts/notifications";
import { usePracticeContext } from "../../contexts/practice/PracticeProvider";
import { useResourceCalendarContext } from "../../contexts/resourceCalendar/ResourceCalendarProvider";
import { useModalContext } from "../../contexts/UI/Modal";
import { useCreateOrUpdateSession } from "../../hooks/sessions/useCreateOrUpdateSession";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { BottomBar } from "../BottomBar";
import { ButtonColor } from "../Button/";
import { EventType } from "../Filters/model";

import { EventForm } from "./CreateEvent/Form";
import { createEventOrGameModalDefaultValues } from "./utils";

export type CreateOrUpdateFormState = {
  label?: string;
  title?: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  startTime: string;
  endTime?: string;
  attendance?: number;
  color: string;
  allDay: boolean;
  location: string;
  locationDescription: string;
  notes: string;
  specialNotes?: string;
  focusedAthlete: string;
  durationMinutes?: number;
  selectedGoogleLocationId?: string;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px 0;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  width: inherit;
  margin-top: auto;
  background-color: ${COLOR_WHITE};
  z-index: 10;
  & > div {
    padding: 20px 0;
  }
`;

interface CreateEventOrGameModalProps {
  eventType: EventType;
  session?: any;
  openedForSpecificGroupId: string;
  selectedStartDate?: Date;
}

export const CreateEventOrGameModal = ({
  eventType,
  session,
  openedForSpecificGroupId,
  selectedStartDate,
}: CreateEventOrGameModalProps) => {
  const { t } = useTranslation();
  // const [files, setFiles] = useState([]);
  // const [filesBlobs, setFilesBlobs] = useState([]);
  // const [uploading, setUploading] = useState(false);
  const [selectedGoogleLocationId, setSelectedGoogleLocationId] = useState(
    session?.googleLocationId || ""
  );
  // const { sessionId } = useUserContext();
  const { actions: modalActions } = useModalContext();
  // const enforceToggleCallback = useRef<() => void>();
  const { createOrUpdateSession } = useCreateOrUpdateSession();
  const { onClear } = usePracticeContext();
  const eventTypename = session ? session.__typename : eventType;

  const { showErrorNotification } = useNotificationsContext();
  const { selectedGroup } = useAgendaContext();
  const {
    selectableResourceList,
    initializeSelectedResources,
    onClearSelectableResourceList,
  } = useResourceCalendarContext();

  const { handleSubmit, ...formMethods } = useForm<CreateOrUpdateFormState>({
    mode: "all",
    defaultValues: createEventOrGameModalDefaultValues(
      session,
      eventType,
      selectedStartDate
    ),
  });

  useEffect(
    function initializeSelectedResourcesForEvent() {
      if (session?.id) {
        initializeSelectedResources(session);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [session]
  );

  const selectedResources = useMemo(
    () =>
      selectableResourceList
        ?.map((resource) => {
          if (resource?.isSelected) {
            return { guid: resource.resourceId };
          }
        })
        .filter((selectedResource) => selectedResource && selectedResource) ||
      [],
    [selectableResourceList]
  );

  // const onFileUpload = useCallback(async () => {
  //   if (files.length) {
  //     if (files?.length) {
  //       setUploading(true);
  //       const filesBlobsPromise = await Promise.all(
  //         files.map(async (f) => {
  //           const data = await fetch(f.url);
  //           const blob = await data.blob();
  //           const upload = await fetch(
  //             `${getServerBasePath()}/xpsweb/savechatattachment/?sessionId=${sessionId}&mime=${
  //               f.mime
  //             }&filename=${f.localName}`,
  //             {
  //               method: "POST",
  //               body: blob,
  //             }
  //           );
  //           const response = await upload.json();
  //           if (upload.ok && upload.status === 200 && response?.url) {
  //             return Promise.resolve({ ...f, url: response.url });
  //           }

  //           setUploading(false);
  //           showErrorNotification(t("uploadErrorMessage"));
  //           return Promise.resolve();
  //         })
  //       );
  //       setUploading(false);
  //       setFilesBlobs(filesBlobsPromise);
  //     }
  //   }
  // }, [files, sessionId, t, showErrorNotification]);

  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     acceptedFiles.forEach((file) => {
  //       const reader = new FileReader();

  //       reader.onload = () => {
  //         const binaryStr = reader.result;
  //         //@ts-ignore
  //         const base64 = btoa(
  //           new Uint8Array(binaryStr as ArrayBuffer).reduce(
  //             (data, byte) => data + String.fromCharCode(byte),
  //             ""
  //           )
  //         );

  //         if (base64) {
  //           const url = `data:${file.type};base64,${base64}`;
  //           setFiles([
  //             ...files,
  //             {
  //               localName: file.name.replace(/\s/g, "_"),
  //               mime: file.type,
  //               content: base64,
  //               url,
  //             },
  //           ]);
  //         }
  //       };
  //       onFileUpload();

  //       reader.readAsArrayBuffer(file);
  //     });

  //     if (
  //       enforceToggleCallback?.current &&
  //       typeof enforceToggleCallback.current === "function"
  //     ) {
  //       enforceToggleCallback.current();
  //     }
  //   },
  //   [files, onFileUpload]
  // );

  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  // });

  const handleCleanUp = () => {
    onClear();
    onClearSelectableResourceList();
    Storage.removeItem(StorageKeys.practiceForm);
    Storage.removeItem(StorageKeys.gameOrEvent);
    modalActions.closeModal();
  };

  const onSubmit: SubmitHandler<CreateOrUpdateFormState> = async (data) => {
    if (!session?.id && !selectedGroup?.id && !openedForSpecificGroupId) {
      return showErrorNotification(t("noGroupSelected"));
    }

    createOrUpdateSession({
      data,
      selectedGoogleLocationId,
      session,
      eventTypename,
      resourcesUsage: { items: selectedResources },
      groupId: session?.id || openedForSpecificGroupId || selectedGroup?.id,
    });

    handleCleanUp();
  };

  const handleCancel = () => {
    handleCleanUp();
  };

  const onError: SubmitErrorHandler<CreateOrUpdateFormState> = () =>
    showErrorNotification(t("formError"));

  return (
    <ModalContainer>
      <EventForm
        eventType={eventType}
        formMethods={formMethods}
        setSelectedGoogleLocationId={setSelectedGoogleLocationId}
        selectedGoogleLocationId={selectedGoogleLocationId}
        googleMapsUrl={session?.googleMapsUrl}
        openedForSpecificGroupId={openedForSpecificGroupId}
      />
      <ButtonWrapper>
        <BottomBar
          style={{ width: "100%" }}
          primary={{
            text: session ? t("update") : t("create"),
            variant: ButtonColor.Blue,
            onClick: handleSubmit(onSubmit, onError),
          }}
          secondary={{
            text: t("cancel"),
            onClick: handleCancel,
          }}
          // additional={{
          //   text: t("addAttachment"),
          //   getRootProps,
          //   getInputProps,
          // }}
        />
      </ButtonWrapper>
    </ModalContainer>
  );
};
