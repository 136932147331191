import React, { useCallback, useMemo } from "react";
import {
  Calendar,
  CalendarProps as RNCalendarProps,
} from "react-native-calendars";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";
import { isValidDate } from "../utils/date";

import { BasicButton } from "./Button";
import { NextIcon } from "./Icons";
import StyledText from "./StyledText";

export type CalendarProps = {
  value?: Dayjs;
  from?: Dayjs;
  to?: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  set(date: Dayjs): void;
};

const DayButton = styled(BasicButton)<{
  selected?: boolean;
  startingDay?: boolean;
  endingDay?: boolean;
}>`
  height: 35px;
  width: 35px;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected }) =>
    selected ? COLOR_BLUE : "transparent"};
  border-top-left-radius: ${({ selected, startingDay }) =>
    !selected || startingDay ? "50%" : undefined};
  border-bottom-left-radius: ${({ selected, startingDay }) =>
    !selected || startingDay ? "50%" : undefined};
  border-top-right-radius: ${({ selected, endingDay }) =>
    !selected || endingDay ? "50%" : undefined};
  border-bottom-right-radius: ${({ selected, endingDay }) =>
    !selected || endingDay ? "50%" : undefined};

  &:hover {
    background-color: ${({ selected }) =>
      selected ? undefined : COLOR_LIGHT_GRAY};
  }

  &:active {
    opacity: 1;
    background-color: ${({ selected }) => (selected ? undefined : COLOR_BLUE)};
  }
`;

const CalendarComponent = ({
  value,
  from,
  set,
  to,
  minDate,
  maxDate,
}: CalendarProps) => {
  const markedDates = useMemo<RNCalendarProps["markedDates"]>(() => {
    if (!isValidDate(from) || !isValidDate(to)) {
      return {};
    }

    const daysDiff = to.diff(from, "days");

    return Array.from({ length: daysDiff + 1 }, (_, i) =>
      from.add(i, "days")
    ).reduce<RNCalendarProps["markedDates"]>((map, date, i) => {
      map[date.format("YYYY-MM-DD")] = {
        startingDay: i === 0,
        endingDay: i === daysDiff,
        selected: true,
        color: COLOR_BLUE,
        textColor: COLOR_WHITE,
      };

      return map;
    }, {});
  }, [from, to]);

  const handleDayPress = useCallback<RNCalendarProps["onDayPress"]>(
    (date) => {
      set(dayjs(date.dateString));
    },
    [set]
  );

  return (
    <Calendar
      markedDates={markedDates}
      markingType="period"
      firstDay={1}
      dayComponent={DayComponent}
      key={value?.format("YYYY-MM-DD")}
      current={isValidDate(value) ? dayjs(value).format("YYYY-MM-DD") : ""}
      minDate={isValidDate(minDate) ? dayjs(minDate).format("YYYY-MM-DD") : ""}
      maxDate={isValidDate(maxDate) ? dayjs(maxDate).format("YYYY-MM-DD") : ""}
      onDayPress={handleDayPress}
      theme={{
        // @ts-ignore
        "stylesheet.calendar.header": {
          dayHeader: {
            fontSize: 8,
            fontWeight: 600,
            textTransform: "uppercase",
            color: "#8A939B",
            paddingBottom: 15,
          },
          monthText: {
            margin: 0,
          },
        },
        weekVerticalMargin: 2.5,
        borderRadius: 30,
      }}
      style={{
        paddingBottom: 5,
        width: 245,
      }}
      renderArrow={(direction) => (
        <NextIcon
          direction={direction === "left" ? "left" : "right"}
          style={{ width: 19, height: 19 }}
        />
      )}
    />
  );
};

const DayComponent: RNCalendarProps["dayComponent"] = ({
  date,
  state,
  marking,
  onPress,
}) => {
  const { selected, startingDay, endingDay } = marking || {};

  return (
    <DayButton
      selected={selected}
      startingDay={startingDay}
      endingDay={endingDay}
      onClick={() => onPress(date)}
    >
      <StyledText
        centerText
        fontSize={12}
        fontWeight={selected ? "bold" : "normal"}
        color={
          selected
            ? COLOR_WHITE
            : state === "disabled"
              ? COLOR_SECONDARY_GREY
              : COLOR_TEXT_DEFAULT
        }
      >
        {date.day}
      </StyledText>
    </DayButton>
  );
};

export default CalendarComponent;
