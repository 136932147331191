import React, {
  ReactElement,
  ReactNode,
  cloneElement,
  CSSProperties,
} from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_NAV_ITEM_HOVER,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";
import { BasicButton } from "../Button";
import Hoverable from "../Hoverable";
import StyledText from "../StyledText";

interface NavIconProps {
  text: string;
  leftIcon?: ReactNode;
  onClick(): void;
  iconSvg?: ReactElement;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  childStyle?: object;
  active: boolean;
  isCompact?: boolean;
  children?: ReactNode | ((props) => ReactNode);
  underlayColor?: string;
}

const NavButton = styled(BasicButton)`
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

const SvgIconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

export const NavIcon = ({
  text,
  leftIcon,
  active,
  onClick,
  style,
  textStyle,
  children,
  isCompact,
  iconSvg,
  underlayColor = COLOR_TEXT_DARK,
}: NavIconProps) => {
  const backgroundColor = (hover) => {
    if (hover && !active) return COLOR_NAV_ITEM_HOVER;
    else if (active) return COLOR_BLUE;
    else return underlayColor;
  };

  return (
    <Hoverable>
      {(hover) => (
        <NavButton onClick={onClick}>
          <>
            <div
              style={{
                ...styles.navButton,
                ...style,
                ...{ backgroundColor: backgroundColor(hover) },
              }}
            >
              {iconSvg && (
                <SvgIconWrapper>
                  {cloneElement(iconSvg, { isActive: active })}
                </SvgIconWrapper>
              )}

              {!isCompact && (
                <>
                  {leftIcon}
                  <StyledText
                    color={COLOR_WHITE}
                    fontSize={14}
                    fontWeight={500}
                    style={{ marginLeft: 3, ...textStyle }}
                  >
                    {text}
                  </StyledText>
                  <div style={styles.childIcon}>
                    {typeof children === "function" &&
                      children({ active, hover })}
                  </div>
                </>
              )}
            </div>
          </>
        </NavButton>
      )}
    </Hoverable>
  );
};

const styles: Record<string, CSSProperties> = {
  childIcon: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "flex-end",
    display: "flex",
  },

  navButton: {
    flex: 1,
    alignItems: "center",
    paddingLeft: 25,
    paddingRight: 20,
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 1,
    flexDirection: "row",
    display: "flex",
  },
};
