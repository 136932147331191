import React, { useMemo, useRef, memo } from "react";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { std, mean, min, max } from "mathjs";

import { generatePeriodCategories } from "../../../hooks/data/periodizationTable/helpers/helpers";
import { getDummyValue } from "../../../utils/dummyData";
import { roundToTwo } from "../../../utils/number";
import { getDataKey } from "../../../utils/statistics";
import { TimeLinePeriod } from "../../Report/ChartWIdget/model";
import { AthleteTablePeriodizationForm } from "../../Report/PeriodizationTable/model";

import { AthleteTablePeriodizationWidgetTable } from "./Table";

export const AthleteTablePeriodizationTablePreview = memo(() => {
  const dummyValues = useRef<Map<string, number | string>>(
    new Map<string, number | string>()
  );
  const minAndMaxValues = useRef<Map<string, { min: number; max: number }>>(
    new Map<string, { min: number; max: number }>()
  );

  const { values } = useFormikContext<AthleteTablePeriodizationForm>();

  // To optimize data generation - rerenders are caused by formik not data regeneration
  const columnHeadersIdentity = values.columnHeaders
    .flatMap((columnHeader) =>
      columnHeader.aggregateFunctions.flatMap((currentAgg) =>
        getDataKey(
          columnHeader.itemId,
          columnHeader.attributeTemplateId,
          currentAgg
        )
      )
    )
    .join(",");

  const periods = useMemo(
    () =>
      generatePeriodCategories(
        values.period.from?.isValid()
          ? values.period.from
          : dayjs().subtract(6, "month"),
        values.period.to?.isValid() ? values.period.to : dayjs(),
        values.timeLine === "continuous"
          ? TimeLinePeriod.DAYS
          : values.timeLinePeriod
      )
        .slice(0, 7)
        .sort(() => values.sortOrder),
    [values.timeLine, values.period, values.timeLinePeriod, values.sortOrder]
  );

  const data = useMemo(
    () =>
      periods.flatMap((period) => ({
        label: period.label,
        data: values.columnHeaders.flatMap((columnHeader) =>
          columnHeader.aggregateFunctions.map((currentAggretate) => {
            const rowKey = `${columnHeader.itemId}-${currentAggretate}-${columnHeader.textOptions?.length}-${values.showValuesAsText}-${period.label}`;
            let alreadyGeneratedDummyCells = dummyValues.current.get(rowKey);

            if (!alreadyGeneratedDummyCells) {
              dummyValues.current.set(
                rowKey,
                getDummyValue(
                  columnHeader.attributeTemplateUnitName,
                  false,
                  currentAggretate,
                  columnHeader.textOptions,
                  values.showValuesAsText,
                  columnHeader.itemId
                ) as unknown as number
              );

              alreadyGeneratedDummyCells = dummyValues.current.get(rowKey);
            }

            const shouldShowTestValueAsString =
              values.showValuesAsText && columnHeader.textOptions?.length;

            return [
              {
                id: getDataKey(
                  columnHeader.itemId,
                  columnHeader.attributeTemplateId,
                  currentAggretate
                ),
                value: shouldShowTestValueAsString
                  ? Math.floor(Math.random() * columnHeader.textOptions.length)
                  : alreadyGeneratedDummyCells ?? -1,
                txt:
                  shouldShowTestValueAsString &&
                  typeof alreadyGeneratedDummyCells === "string"
                    ? alreadyGeneratedDummyCells
                    : "",
                unitName: columnHeader.attributeTemplateUnitName,
                time: 0,
              },
            ];
          })
        ),
      })),
    [periods, values.columnHeaders, values.showValuesAsText]
  );

  const sortedStatsData = useMemo(
    () =>
      values.columnHeaders.flatMap((columnHeader) =>
        columnHeader.aggregateFunctions.flatMap((currentAggregation) => {
          const columnHeaderId = getDataKey(
            columnHeader.itemId,
            columnHeader.attributeTemplateId,
            currentAggregation
          );

          const filteredData = data.flatMap((dataPeriod) =>
            dataPeriod.data.flatMap((dataItem) =>
              dataItem.filter((item) => item.id === columnHeaderId)
            )
          );

          const statsData = filteredData
            .map((dataPeriod) => dataPeriod.value as number)
            .filter((numberData) => typeof numberData === "number");

          if (statsData.length === 0) {
            return undefined;
          }

          if (!minAndMaxValues.current.get(columnHeaderId)) {
            minAndMaxValues.current.set(columnHeaderId, {
              min: min(statsData),
              max: max(statsData),
            });
          }

          return {
            id: columnHeaderId,
            average: roundToTwo(mean(statsData)).toString(),
            stdDev: roundToTwo(std(statsData) as unknown as number).toString(),
          };
        })
      ),
    /* eslint-disable react-hooks/exhaustive-deps */
    [columnHeadersIdentity]
  );

  const dataWithDynamicMinAndMax = useMemo(
    () =>
      data.map((periodItem) => ({
        label: periodItem.label,
        data: periodItem.data.map((dataItem) =>
          dataItem.map((dataItemValue) => ({
            ...dataItemValue,
            ...minAndMaxValues.current.get(dataItemValue.id),
          }))
        ),
      })),
    [data, minAndMaxValues]
  );

  if (
    values.columnHeaders.filter(
      (columnHeader) =>
        columnHeader.collectionId &&
        columnHeader.itemId &&
        columnHeader.attributeTemplateId
    ).length === 0
  ) {
    return null;
  }

  return (
    <AthleteTablePeriodizationWidgetTable
      title={values.name}
      coloringConfig={values.coloringConfig}
      columnHeaders={values.columnHeaders.map((columnHeader) => ({
        ...columnHeader,
        id: "",
        templateId: columnHeader.itemId,
        name: columnHeader.measurementTemplateName,
      }))}
      data={dataWithDynamicMinAndMax}
      statsData={sortedStatsData}
      timeLine={values.timeLine}
      verticalHeader={values.verticalHeader}
      showValuesAsText={values.showValuesAsText}
      showSummaryStats={values.showSummaryStats}
      summaryStatsAlignment={values.summaryStatsAlignment}
      ignoreColumnsWithNoData={values.ignoreColumnsWithNoData}
    />
  );
});
