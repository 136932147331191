import React, { useState } from "react";

import { Formik } from "formik";

import { PeriodType } from "../../../models";
import { ConnectedFocusError } from "../../FormikFocusError";
import { AppearanceSection } from "../../ReportCreateTable/AppearanceSection";
import {
  CombinedTestWidgetModel,
  WidgetType,
} from "../../ReportCreateTable/models";
import {
  PerformerSection,
  PerformerSelectionMode,
  PerformerSelectionWidgetType,
} from "../PerformerSection";
import { PeriodSection } from "../PeriodSection";
import { PreviewButtonSection } from "../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../styled";
import { WidgetEditorTitleSection } from "../WidgetEditorTitleSection";

import { CombinedTestWidget } from "./CombinedTestWidget";
import { CombinedTestEditorCompareToSection } from "./components/CombinedTestEditorCompareToSection";
import { CombinedTestEditorDataSection } from "./components/CombinedTestEditorDataSection";
import { CombinedTestEditorWhatToShowSection } from "./components/CombinedTestEditorWhatToShowSection";
import { ChartType, CombinedFormValue, CompareTo } from "./models";

type CombinedTestEditorProps = {
  widget?: CombinedTestWidgetModel;
  isGroupWidget: boolean;
  isSavingWidget: boolean;
  onSubmit: (values: CombinedFormValue) => void;
  onCancel: () => void;
};

export function CombinedTestEditor({
  widget,
  isGroupWidget,
  isSavingWidget,
  onSubmit,
  onCancel,
}: CombinedTestEditorProps) {
  const [subTests, setSubTests] = useState<{ id: string; name?: string }[]>([]);

  const initialFormValues: CombinedFormValue = {
    title: widget?.name ?? "",
    collectionId: widget?.collectionId ?? "",
    templateId: widget?.testTemplateId ?? "",
    templateName: widget?.combinedTestTemplate?.mainTest?.name ?? "",
    chartType:
      ChartType[widget?.chartType?.toUpperCase()] ??
      (isGroupWidget ? ChartType.CIRCLE : ChartType.RADAR),
    showTeamAverage: widget?.showTeamAverage ?? false,
    compareTo: CompareTo[widget?.compareTo?.toUpperCase()] ?? CompareTo.NOTHING,
    visibleSubTests: widget?.visibleSubTests ?? [],
    widgetBorders: widget?.widgetBorders ?? false,
    period: {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
      ...widget?.period,
    },
    performerSelectionMode:
      widget?.performerSelectionMode ??
      PerformerSelectionMode.ATHLETE_SELECTION,
    athlete: widget?.athlete ?? "",
    athletes: widget?.athletes ?? [],
  };

  return (
    <Formik<CombinedFormValue>
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validate={(values) => {
        if (!values.templateId) {
          return { templateId: "required" };
        }
      }}
      validateOnMount
    >
      {({ values, handleChange, submitForm, setFieldValue }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <WidgetEditorTitleSection
              value={values.title}
              handleChange={handleChange}
            />

            <CombinedTestEditorWhatToShowSection
              isGroupWidget={isGroupWidget}
            />

            <CombinedTestEditorDataSection onSubTestChange={setSubTests} />

            {values.chartType !== ChartType.RADAR && (
              <CombinedTestEditorCompareToSection
                isGroupWidget={isGroupWidget}
              />
            )}

            <AppearanceSection
              widgetType={WidgetType.COMBINED_TEST}
              widgetBorders={values.widgetBorders}
              setFieldValue={setFieldValue}
            />

            <PerformerSection
              performerSelectionWidgetType={
                isGroupWidget
                  ? PerformerSelectionWidgetType.GROUP
                  : PerformerSelectionWidgetType.ATHLETE
              }
              setFieldValue={setFieldValue}
              performerSelectionMode={values.performerSelectionMode}
            />

            <PeriodSection
              setFieldValue={setFieldValue}
              period={values.period}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <CombinedTestWidget
              widget={{
                name: values.title,
                testTemplateId: values.templateId,
                chartType: values.chartType,
                period: values.period,
                combinedTestTemplate: {
                  mainTest: {
                    id: values.templateId,
                    name: values.templateName,
                  },
                  subTests,
                },
                showTeamAverage: values.showTeamAverage,
                visibleSubTests: values.visibleSubTests,
                compareTo: values.compareTo,
                widgetBorders: values.widgetBorders,
              }}
              isGroupReport={isGroupWidget}
              useDummyData
            />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
}
