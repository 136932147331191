import React, { useMemo } from "react";

import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { useUserContext } from "../../contexts/User";
import { sortList } from "../../utils/sortList";

import { AthleteSelect } from "./AthleteSelect";
import { useGetAgendaFiltersData } from "./hooks/useGetAgendaFiltersData";

interface AthletesFilterProps {
  value: string;
  searchValue: string;
  selectedGroupId?: string;
  isSelectAll?: boolean;
  showOptionalAthletes: boolean;
}

export function AthletesFilter({
  searchValue,
  value,
  showOptionalAthletes,
}: AthletesFilterProps) {
  const { selectedVisibleGroupIds } = useAgendaContext();
  const { language } = useUserContext();

  const { athletes: allSelectedGroupsUniqueAthletes, loading } =
    useGetAgendaFiltersData({ selectedVisibleGroupIds, showOptionalAthletes });

  const athletes = useMemo(
    () =>
      sortList(
        allSelectedGroupsUniqueAthletes
          ?.map(({ id, firstName, lastName, thumb, name, isExpired }) => ({
            id,
            firstName,
            lastName,
            label: `${firstName} ${lastName}`,
            thumb,
            name,
            isExpired,
          }))
          .filter((report) =>
            report.label.toLowerCase().includes(searchValue.toLowerCase())
          ),
        language
      ),
    [allSelectedGroupsUniqueAthletes, language, searchValue]
  );

  return <AthleteSelect options={athletes} value={value} loading={loading} />;
}
