import React, { useMemo, useState } from "react";
import {
  components,
  DropdownIndicatorProps,
  Props as ReactSelectProps,
} from "react-select";

import fuzzysort from "fuzzysort";
import AsyncSelect from "react-select/async";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_RED,
  COLOR_SELECT_BORDER_HOVER,
  COLOR_WHITE,
} from "../colors";
import { getFieldSelectStyles } from "../utils/selectStyle";

import { CustomDropdownIndicator } from "./CustomDropdownIndicator";
import Hoverable from "./Hoverable";

export interface SelectOption<T = string> {
  label: string;
  value: T;
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// See Custom Component with selectProps Example section here https://react-select.com/components#defining-components
const DropdownIndicator = (
  props: DropdownIndicatorProps & {
    backgroundColor?: string;
    hoverBackgroundColor?: string;
    selectProps: {
      isHovered?: boolean;
      isSelected?: boolean;
    };
  }
) => {
  const { isHovered, isSelected } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <CustomDropdownIndicator
        {...props}
        isHovered={isHovered}
        isSelected={isSelected}
        hoverBackgroundColor={COLOR_WHITE}
      />
    </components.DropdownIndicator>
  );
};

export function StyledSelectField({
  isInvalid = false,
  value,
  ...props
}: Omit<ReactSelectProps, "form" | "options" | "value"> & {
  options: SelectOption[];
  value: SelectOption["value"] | SelectOption["value"][];
  isInvalid?: boolean;
  collectionId?: string;
}) {
  const [isSelected, setSelected] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const selectValue = useMemo(
    () =>
      props.isMulti
        ? props.options?.filter((option) =>
            (value as SelectOption["value"][])?.includes?.(
              (option as SelectOption).value
            )
          ) ?? []
        : props.options?.find(
            (option) => (option as SelectOption).value === value
          ) ?? null,
    [props.options, props.isMulti, value]
  );

  const loadOptions = (
    inputValue: string,
    callback: (options: SelectOption[]) => void
  ) => {
    const filterItems = (inputValue: string) => {
      return fuzzysort
        .go(inputValue, props.options, { key: "label" })
        .map(({ obj }) => obj);
    };

    callback(filterItems(inputValue));
  };

  const selectStyles = useMemo(
    () =>
      getFieldSelectStyles({
        container: {
          ...(isInvalid
            ? {
                borderColor: COLOR_RED,
              }
            : isSelected
              ? {
                  borderColor: COLOR_BLUE,
                }
              : isHovered
                ? {
                    borderColor: COLOR_SELECT_BORDER_HOVER,
                  }
                : {}),
        },
      }),
    [isHovered, isSelected, isInvalid]
  );

  return (
    <Hoverable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <SelectWrapper>
        <AsyncSelect
          {...props}
          defaultOptions={props.options}
          loadOptions={loadOptions}
          styles={selectStyles}
          onMenuOpen={() => setSelected(true)}
          onMenuClose={() => setSelected(false)}
          menuPortalTarget={document.body}
          value={selectValue}
          // @ts-ignore - pass to DropdownIndicator
          isHovered={isHovered}
          // @ts-ignore - pass to DropdownIndicator
          isSelected={isSelected}
          components={{
            DropdownIndicator,
            ...props.components,
          }}
        />
      </SelectWrapper>
    </Hoverable>
  );
}
