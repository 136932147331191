import { useMemo } from "react";

import { Dayjs } from "dayjs";
import { std, mean } from "mathjs";

import { TimeLinePeriod } from "../../../components/Report/ChartWIdget/model";
import { TablePeriodizationWidget } from "../../../components/TablePeriodization/Widget";
import { useReportContext } from "../../../contexts/report";
import { useUserContext } from "../../../contexts/User";
import { AggregateFunction } from "../../../graphql";
import { JsonStatisticsPeriod } from "../../../services";
import {
  useAthleteStatisticsQuery,
  useSoccerStatisticsQuery,
  useAttendanceStatisticsQuery,
} from "../../../services/statistics/hooks";
import { roundToTwo } from "../../../utils/number";
import {
  getAttendanceStatisticsDataKey,
  getSoccerStatisticsDataKey,
  isAttendanceCollection,
  isSoccerStatsCollection,
} from "../../../utils/reports";
import { getAllAthleteStats } from "../../../utils/statistics";
import { useGetGroupWidgetParticipants } from "../useGetGroupWidgetParticipants";

import {
  getStatsMinMaxForSoccerOrAttendanceData,
  getSoccerStatsDataItemsForPeriods,
  generatePeriodCategories,
} from "./helpers/helpers";
import { getAttendanceStatsDataItems } from "./helpers/helpers/getAttendanceStatsDataItems";
import { getPeriodizationForSoccerStats } from "./helpers/helpers/getPeriodizationForSoccerAndAttendanceStats";
import { getStatisticsDataItemsForPeriods } from "./helpers/helpers/getStatisticsDataItemsForPeriods";

type UseAthleteDataForPeriodizationTableParams = {
  widget: TablePeriodizationWidget;
  fromDate: Dayjs;
  toDate: Dayjs;
  period: JsonStatisticsPeriod;
  requestedMeasurements: {
    measurementTemplateId: string;
    collectionId: string;
    attributeId: string;
    aggregateFunctions: AggregateFunction[];
  };
  sortOrder: number;
};

enum GroupPeriodizationQueryKeys {
  STATS = "Stats",
  SOCCER_STATS = "Soccer-stats",
  ATTENDANCE_STATS = "Attendance-stats",
}

type ReturnValue = {
  loading: boolean;
  data: GroupTablePeriodizationDataPerAthleteItem[];
  statsData?: GroupTablePeriodizationStatsDataItem[];
};

export type GroupTablePeriodizationStatsDataItem = {
  average: string | number;
  stdDev: string | number;
  tifn: boolean;
  unitName: string;
};

export type GroupTablePeriodizationDataPerAthleteItem = {
  athleteName: string;
  data: GroupTablePeriodizationDataItem[];
};

export type DataPerPeriod = {
  id: string;
  value: string | number;
  txt?: string;
  unitName?: any;
  time?: number;
  min: number;
  max: number;
  tifn?: boolean;
};

export type GroupTablePeriodizationDataItem = {
  label: string;
  dataPerPeriod: DataPerPeriod;
};

export const useGroupDataForPeriodizationTable = ({
  widget,
  fromDate,
  toDate,
  period,
  requestedMeasurements,
  sortOrder,
}: UseAthleteDataForPeriodizationTableParams): ReturnValue => {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();

  const { getGroupWidgetParticipants } = useGetGroupWidgetParticipants();
  const groupWidgetParticipants = getGroupWidgetParticipants(widget);

  const getStatsQueryKey = (statType: GroupPeriodizationQueryKeys) => [
    statType,
    widget.id,
    groupWidgetParticipants.participants
      .map((participant) => participant)
      .join(","),
    fromDate.toString(),
    toDate.toString(),
  ];

  const {
    data: athleteStatisticsData = null,
    isLoading: athleteStatisticsIsLoading,
    fetchStatus: athleteStatisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      session: { sessionId },
      items: [
        {
          dataType: requestedMeasurements.collectionId,
          attribute: requestedMeasurements.attributeId,
          templateGuid: requestedMeasurements.measurementTemplateId,
          aggregation:
            period.toLowerCase() === "continuous"
              ? [AggregateFunction.Each]
              : requestedMeasurements.aggregateFunctions,
        },
      ],
      participants: groupWidgetParticipants.participants.map((id) => ({
        id,
      })),
      reportTemplateId: report?.id,
    },
    {
      queryKey: getStatsQueryKey(GroupPeriodizationQueryKeys.STATS),
      enabled:
        sessionId &&
        groupWidgetParticipants.participants?.length > 0 &&
        !isSoccerStatsCollection(requestedMeasurements.collectionId) &&
        !isAttendanceCollection(requestedMeasurements.collectionId) &&
        Boolean(requestedMeasurements.measurementTemplateId),
    }
  );

  const {
    data: soccerStatisticsData = null,
    isLoading: soccerStatisticsIsLoading,
    fetchStatus: soccerStatisticsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      from: fromDate,
      to: toDate,
      session: { sessionId },
      athleteGuids: groupWidgetParticipants.participants,
      reportTemplateId: report?.id,
      periodization:
        period !== "continuous" ? getPeriodizationForSoccerStats(period) : "No",
    },
    {
      queryKey: getStatsQueryKey(GroupPeriodizationQueryKeys.SOCCER_STATS),
      enabled:
        sessionId &&
        isSoccerStatsCollection(requestedMeasurements.collectionId) &&
        groupWidgetParticipants.participants?.length > 0,
    }
  );

  const {
    data: attendanceStatisticsData = null,
    isLoading: attendanceStatisticsIsLoading,
    fetchStatus: attendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: { sessionId },
      fromDate,
      toDate,
      participants: groupWidgetParticipants.participants.map((id) => ({
        id,
      })),
      periodization:
        period !== "continuous"
          ? getPeriodizationForSoccerStats(period)
          : undefined,
      reportTemplateId: report?.id,
    },
    {
      queryKey: getStatsQueryKey(GroupPeriodizationQueryKeys.ATTENDANCE_STATS),
      enabled:
        sessionId &&
        isAttendanceCollection(requestedMeasurements.collectionId) &&
        groupWidgetParticipants.participants?.length > 0,
    }
  );

  return useMemo(() => {
    if (
      athleteStatisticsFetchStatus !== "idle" &&
      athleteStatisticsIsLoading &&
      soccerStatisticsFetchStatus !== "idle" &&
      soccerStatisticsIsLoading &&
      attendanceStatisticsFetchStatus !== "idle" &&
      attendanceStatisticsIsLoading
    ) {
      return { loading: true, data: [] };
    }

    const periods = generatePeriodCategories(
      fromDate,
      toDate,
      period === "continuous" ? TimeLinePeriod.DAYS : (period as TimeLinePeriod)
    ).sort(() => sortOrder);

    const columnHeader = [
      {
        templateId: requestedMeasurements.measurementTemplateId,
        attributes: [
          {
            id: requestedMeasurements.attributeId,
            templateId: requestedMeasurements.measurementTemplateId,
            aggregateFunctions: requestedMeasurements.aggregateFunctions,
            attributeTemplate: {
              id: requestedMeasurements.attributeId,
            },
          },
        ],
      },
    ];

    let data: ReturnValue["data"] = [];
    let isLoadingData = false;

    if (isSoccerStatsCollection(requestedMeasurements.collectionId)) {
      const soccerMinMax = soccerStatisticsData?.data?.perAthletes?.map(
        (athleteStats) =>
          getStatsMinMaxForSoccerOrAttendanceData(
            columnHeader,
            getSoccerStatisticsDataKey,
            period === "continuous"
              ? athleteStats?.games
              : athleteStats?.periods
          )
      );

      isLoadingData = soccerStatisticsIsLoading;
      data = soccerStatisticsData?.data?.perAthletes?.map(
        (athleteSoccerStats, athleteIndex) => ({
          athleteName: athleteSoccerStats.athleteName,
          data: periods.map((dataPeriod, periodIndex) => ({
            label: dataPeriod.label,
            dataPerPeriod: getSoccerStatsDataItemsForPeriods(
              dataPeriod,
              period === "continuous" ? "days" : period,
              columnHeader,
              period === "continuous"
                ? athleteSoccerStats?.games
                : athleteSoccerStats?.periods,
              soccerMinMax[athleteIndex],
              period === "continuous"
            )[0] ?? {
              id: `empty-value-${dataPeriod.start}-${athleteSoccerStats.athleteId}`,
              value: null,
              ...soccerMinMax[athleteIndex][periodIndex],
            },
          })),
        })
      );
    }

    if (isAttendanceCollection(requestedMeasurements.collectionId)) {
      const attendanceMinMax = attendanceStatisticsData?.data?.perAthletes?.map(
        (athleteStats) =>
          getStatsMinMaxForSoccerOrAttendanceData(
            columnHeader,
            getAttendanceStatisticsDataKey,
            athleteStats?.attendanceInPeriodOfTime
          )
      );

      isLoadingData = attendanceStatisticsIsLoading;
      data = attendanceStatisticsData?.data?.perAthletes?.map(
        (athleteAttendanceStats, athleteIndex) => ({
          athleteName: athleteAttendanceStats.athleteName,
          data: periods.map((dataPeriod, periodIndex) => ({
            label: dataPeriod.label,
            dataPerPeriod: getAttendanceStatsDataItems(
              dataPeriod,
              columnHeader,
              athleteAttendanceStats?.attendanceInPeriodOfTime,
              attendanceMinMax[athleteIndex]
            )[0] ?? {
              id: `empty-value-${dataPeriod.start}-${athleteAttendanceStats.athleteId}`,
              value: null,
              ...attendanceMinMax[athleteIndex][periodIndex],
            },
          })),
        })
      );
    }

    if (
      !isAttendanceCollection(requestedMeasurements.collectionId) &&
      !isSoccerStatsCollection(requestedMeasurements.collectionId)
    ) {
      isLoadingData = athleteStatisticsIsLoading;
      data = athleteStatisticsData?._athletes.map((athlete) => {
        const allStatsForAthlete = getAllAthleteStats(athlete, true);

        return {
          athleteName: athlete._name,
          data: periods.map((dataPeriod, index) => ({
            label: dataPeriod.label,
            dataPerPeriod: getStatisticsDataItemsForPeriods(
              dataPeriod,
              allStatsForAthlete
            )[0] ?? {
              id: `dummy-${index}`,
              value: null,
              txt: "-",
              unitName: "",
              time: null,
              min: 0,
              max: 0,
            },
          })),
        };
      });
    }

    const statsData = periods
      .map((_, periodIndex) =>
        data?.flatMap((athleteData) => ({
          value: athleteData.data[periodIndex].dataPerPeriod.value,
          unitName: athleteData.data[periodIndex].dataPerPeriod.unitName,
          tifn: athleteData.data[periodIndex].dataPerPeriod.tifn,
        }))
      )
      .map((items) => {
        const values = items
          ?.filter((item) => typeof item.value === "number")
          .map((item) => Number(item.value));

        const unitName = items?.find((item) => item.unitName)?.unitName ?? "";
        const tifn = items?.some((item) => item.tifn === true);

        return {
          average: values?.length
            ? `${roundToTwo(mean(values))} ${unitName}`
            : "-",
          stdDev: values?.length
            ? `${roundToTwo(std(values) as unknown as number)} ${unitName}`
            : "-",
          tifn,
          unitName,
        };
      });

    return {
      loading: isLoadingData,
      data,
      statsData,
    };
  }, [
    athleteStatisticsData?._athletes,
    athleteStatisticsFetchStatus,
    athleteStatisticsIsLoading,
    attendanceStatisticsData?.data?.perAthletes,
    attendanceStatisticsFetchStatus,
    attendanceStatisticsIsLoading,
    fromDate,
    period,
    requestedMeasurements.aggregateFunctions,
    requestedMeasurements.attributeId,
    requestedMeasurements.collectionId,
    requestedMeasurements.measurementTemplateId,
    soccerStatisticsData?.data?.perAthletes,
    soccerStatisticsFetchStatus,
    soccerStatisticsIsLoading,
    sortOrder,
    toDate,
  ]);
};
