import React, { CSSProperties, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useResourceCalendarContext } from "../../contexts/resourceCalendar/ResourceCalendarProvider";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useResourcesQuery } from "../../graphql";
import { SessionType } from "../../services/sessions";
import { PlusRoundedIcon } from "../Icons";
import { SelectedResourceTagList } from "../SelectedResourceTagList";

import { ControlledLocationSelect } from "./ControlledLocationSelect";
import { LabelText } from "./StyledInputComponents";

type ResourceSelectorProps = {
  from: number;
  to: number;
  setSelectedGoogleLocationId: Dispatch<SetStateAction<string>>;
  selectedGoogleLocationId: string;
  googleMapsUrl?: string;
  practiceId?: string;
  onMenuToggle?: (isOpen: boolean) => void;
  maxMenuHeight?: number;
};

const ResourcesWrapper = styled.div<{ autoWidth: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: ${({ autoWidth }) => (autoWidth ? "auto" : "100%")};
`;

const ResourceList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const AddResourceButton = styled.button`
  display: flex;
  cursor: pointer;
  border: 0;
  background: transparent;
  padding: 10px 15px 10px 0;
`;

const ResourceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

const ResourceLocationWrapper = styled.div`
  width: 100%;
`;

const additionalResourceListTagStyle: CSSProperties = {
  flexWrap: "nowrap",
  alignItems: "center",
  overflow: "auto",
};

export function ResourceLocationSelector({
  from,
  to,
  setSelectedGoogleLocationId,
  selectedGoogleLocationId,
  googleMapsUrl,
  practiceId,
  onMenuToggle,
  maxMenuHeight,
}: ResourceSelectorProps) {
  const { t } = useTranslation();
  const modal = useTopModalContext();
  const { sessionId, timezone } = useUserContext();
  const { selectableResourceList } = useResourceCalendarContext();

  const { data: resourcesData } = useResourcesQuery({
    variables: {
      sessionId,
      timezone,
      ids: selectableResourceList?.map((item) => item?.resourceId),
      from,
      to,
      practiceId,
    },
    skip: !selectableResourceList?.length || !to || !from,
  });

  const onAddResource = () => {
    modal.actions.openTopModal({
      modal: TopModalType.ADD_RESOURCE,
      title: t("addResource"),
      params: {
        resourcesData,
        disableCancel: true,
      },
    });
  };

  const hasAnySelectedResource = selectableResourceList?.some(
    (resource) => resource.isSelected
  );

  return (
    <ResourceLocationWrapper>
      <LabelText topOffset={-5}>
        {t("resourcesLocation").toUpperCase()}
      </LabelText>
      <ResourceRow>
        <ResourcesWrapper autoWidth={!hasAnySelectedResource}>
          <ResourceList>
            <AddResourceButton onClick={onAddResource}>
              <PlusRoundedIcon isActive />
            </AddResourceButton>
            <SelectedResourceTagList
              resourcesData={resourcesData}
              additionalStyle={additionalResourceListTagStyle}
            />
          </ResourceList>
        </ResourcesWrapper>
        {!hasAnySelectedResource ? (
          <ControlledLocationSelect
            name="location"
            sessionType={SessionType.PRACTICE}
            setSelectedGoogleLocationId={setSelectedGoogleLocationId}
            selectedGoogleLocationId={selectedGoogleLocationId}
            googleMapsUrl={googleMapsUrl}
            maxMenuHeight={maxMenuHeight || 150}
            additionalDropdownOption={{
              value: "",
              label: t("selectFacility"),
              onClick: onAddResource,
            }}
            onMenuToggle={onMenuToggle}
          />
        ) : null}
      </ResourceRow>
    </ResourceLocationWrapper>
  );
}
