import React, { CSSProperties } from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_CHECKBOX_BLUE_HOVER,
  COLOR_GRAY,
  COLOR_SECONDARY_GREY,
} from "../colors";

import { BasicButton } from "./Button";
import { CheckboxLabel } from "./CheckboxLabel";
import { CheckboxIcon, RadioIcon } from "./Icons";
import { Loader } from "./Loader";

type CheckboxProps = {
  text: string;
  thumb?: string;
  showGroupIcon?: boolean;
  checked: boolean;
  disabled?: boolean;
  typeIcon?: string;
  check?(value: boolean): void;
  withMargin?: boolean;
  rightOrientation?: boolean;
  loading?: boolean;
  textStyle?: CSSProperties;
  buttonStyle?: CSSProperties;
  style?: CSSProperties;
  checkboxSize?: "small" | "large";
  isGrey?: boolean;
  onCheckboxClick?: () => void;
  onTextClick?: () => void;
  isFocused?: boolean;
};

const CheckboxWrapper = styled.div<{
  withMargin: boolean;
  hasClickEvent: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  margin-right: 5px;
  margin-top: ${({ withMargin, hasClickEvent }) =>
    withMargin ? `${hasClickEvent ? 5 : 15}px` : 0};
  min-width: auto;
`;

const CheckboxButton = styled(BasicButton)<{
  rightOrientation: boolean;
  isChecked: boolean;
}>`
  flex-direction: row;
  align-items: center;
  flex: ${({ rightOrientation }) => (rightOrientation ? 1 : 0)};

  &:hover {
    #radioIcon,
    #checkboxIcon {
      border-color: ${COLOR_SECONDARY_GREY};
    }

    & > button > svg > rect {
      fill: ${({ isChecked }) =>
        isChecked ? COLOR_CHECKBOX_BLUE_HOVER : COLOR_GRAY};
    }
  }
`;

const StyledRadioIcon = styled(RadioIcon)<{
  rightOrientation: boolean;
  thumb?: string;
  showGroupIcon?: boolean;
}>`
  margin-right: ${({ rightOrientation, thumb, showGroupIcon }) =>
    !rightOrientation ? `${thumb || showGroupIcon ? 5 : 12}px` : 0};
  margin-left: ${({ rightOrientation, thumb, showGroupIcon }) =>
    rightOrientation ? `${thumb || showGroupIcon ? 5 : 12}px` : 0};
`;

export function Checkbox({
  text,
  thumb,
  showGroupIcon,
  check,
  checked,
  disabled,
  typeIcon,
  rightOrientation = false,
  withMargin = true,
  loading = false,
  textStyle,
  buttonStyle = {},
  style,
  checkboxSize = "large",
  isGrey = true,
  onCheckboxClick,
  onTextClick,
  isFocused,
}: CheckboxProps) {
  return (
    <CheckboxWrapper
      withMargin={withMargin}
      hasClickEvent={!!onTextClick}
      style={style}
    >
      <CheckboxButton
        style={buttonStyle}
        disabled={disabled}
        isChecked={checked}
        rightOrientation={rightOrientation}
        onClick={(event) => {
          event.preventDefault();
          !onCheckboxClick && check(!checked);
        }}
      >
        {rightOrientation && (
          <CheckboxLabel
            disabled={disabled}
            isFocused={isFocused}
            onTextClick={onTextClick}
            rightOrientation={rightOrientation}
            showGroupIcon={showGroupIcon}
            text={text}
            textStyle={textStyle}
            thumb={thumb}
          />
        )}
        <BasicButton
          disabled={disabled}
          onClick={(event) => {
            event.preventDefault();
            onCheckboxClick?.();
            check(!checked);
          }}
        >
          {loading ? (
            <Loader
              size={21}
              style={{
                marginRight: rightOrientation ? 0 : 15,
                marginLeft: rightOrientation ? 15 : 0,
              }}
              color={COLOR_BLUE}
            />
          ) : typeIcon === "circle" ? (
            <StyledRadioIcon
              variant={disabled ? "disabled" : "default"}
              isChecked={checked}
              rightOrientation={rightOrientation}
              showGroupIcon={showGroupIcon}
              thumb={thumb}
            />
          ) : (
            <CheckboxIcon
              size={checkboxSize}
              variant={disabled ? "disabled" : "default"}
              isGrey={isGrey}
              isChecked={checked}
              style={{
                marginRight: rightOrientation ? 0 : 5,
                marginLeft: !rightOrientation
                  ? 0
                  : thumb || showGroupIcon
                    ? 5
                    : 12,
              }}
            />
          )}
        </BasicButton>
        {!rightOrientation && (
          <CheckboxLabel
            disabled={disabled}
            isFocused={isFocused}
            onTextClick={onTextClick}
            rightOrientation={rightOrientation}
            showGroupIcon={showGroupIcon}
            text={text}
            textStyle={textStyle}
            thumb={thumb}
          />
        )}
      </CheckboxButton>
    </CheckboxWrapper>
  );
}
