import React, {
  CSSProperties,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";

import { CheckIcon } from "./Icons";
import StyledText from "./StyledText";

interface WorkoutDoneButtonProps {
  onClick: MouseEventHandler<HTMLDivElement>;
  isGreenInCalendar: boolean;
  style?: CSSProperties;
  containerStyle?: CSSProperties;
}

const Container = styled.div`
  padding-top: 10px;
  padding-block: 10px;
  display: flex;
  justify-content: flex-end;
  background: ${COLOR_WHITE};
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline: 15px;
  cursor: pointer;

  > svg,
  > svg * {
    transition: all 0.3s ease;
  }
`;

export const WorkoutDoneButton = ({
  onClick,
  isGreenInCalendar,
  style,
  containerStyle,
}: WorkoutDoneButtonProps) => {
  const { t } = useTranslation();
  const [isHovering, setHovering] = useState(false);

  useEffect(() => {
    if (!isGreenInCalendar) {
      // remove active color when switched to not completed
      setHovering(false);
    }
  }, [isGreenInCalendar]);

  return (
    <Container style={containerStyle}>
      <Button
        style={style}
        onClick={onClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <StyledText
          color={COLOR_TEXT_DARK}
          fontSize={12}
          style={{ fontWeight: "bold" }}
        >
          {isGreenInCalendar ? t("completed") : t("notCompleted")}
        </StyledText>
        <CheckIcon
          style={{ flexShrink: 0, marginLeft: 15 }}
          isActive={isGreenInCalendar || isHovering}
        />
      </Button>
    </Container>
  );
};
