import { ApolloCache } from "@apollo/client";

import {
  UnreadMessageCountQueryVariables,
  UnreadMessageCountQuery as UnreadMessageCountQueryType,
} from "../graphql/generated";
import { UnreadMessageCountQuery } from "../graphql/queries/UnreadMessageCountQuery";

// Update unread count in the navside
export const updateUnreadAll = (
  proxy: ApolloCache<any>,
  variables: UnreadMessageCountQueryVariables
) => {
  const previousUnreadMessagesData = proxy.readQuery<
    UnreadMessageCountQueryType,
    UnreadMessageCountQueryVariables
  >({
    query: UnreadMessageCountQuery,
    variables,
  });
  if (previousUnreadMessagesData) {
    proxy.writeQuery({
      query: UnreadMessageCountQuery,
      variables,
      data: {
        messageOverview: {
          ...previousUnreadMessagesData.messageOverview,
          unreadAll: !isNaN(
            Number(previousUnreadMessagesData.messageOverview?.unreadAll)
          )
            ? Number(previousUnreadMessagesData.messageOverview?.unreadAll) - 1
            : 0,
        },
      },
    });
  }
};
