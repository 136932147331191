import { isCustomTextSoccerAttribute } from "../../../utils/reports";

import { Data, SortByType } from "./Table";

export const sortNumericTableRows = (
  data: Data[],
  { colIndex, order }: SortByType
) =>
  data
    .filter((r) => {
      const colCel = r.data[colIndex];

      return (
        colCel[0].value !== 0 &&
        !isNaN(
          typeof colCel[0].value == "string"
            ? parseFloat(colCel[0].value as string)
            : colCel[0].value
        )
      );
    })
    .sort(
      (r1, r2) =>
        (Number(r1.data[colIndex][0].value) -
          Number(r2.data[colIndex][0].value)) *
        order
    );

export const sortTextualTableRows = (
  data: Data[],
  { colIndex, order }: SortByType
) =>
  data
    .filter((r) => {
      const colCel = r.data[colIndex];

      return (
        colCel[0].txt &&
        colCel[0].txt !== "-" &&
        (isCustomTextSoccerAttribute(colCel[0].id) ||
          !colCel[0].value ||
          colCel[0].value === 0)
      );
    })
    .sort(
      (r1, r2) =>
        `${r1.data[colIndex][0].txt}`.localeCompare(
          `${r2.data[colIndex][0].txt}`
        ) * order
    );

export const sortEmptyValues = (data: Data[], { colIndex }: SortByType) =>
  data.filter(
    (r) => r.data[colIndex][0].value === 0 || r.data[colIndex][0].txt === "-"
  );

const NON_BREAKING_HYPHEN = "\u{2011}";
export const MakeNonBreakingString = (input: string) =>
  input.replace("-", NON_BREAKING_HYPHEN);

export const athleteFilterColumnsWithNoData = (data: Data[]) => {
  const completeData = [...data];

  return completeData.filter((row) =>
    row.data.some((rowCell, index, array) => {
      let isEmpty = true;
      for (let i = 0; i < array.length; i++) {
        if (rowCell[i] && rowCell[i]?.txt !== "-") {
          isEmpty = false;
          break;
        }
      }
      return !isEmpty;
    })
  );
};
