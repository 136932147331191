import React, { useCallback } from "react";

import { useFormikContext } from "formik";

import { ReportItemType } from "../../../graphql";
import { IconButton } from "../../IconButton";
import { BinIcon, ReorderIcon } from "../../Icons";
import { DataSourceSelectorRow } from "../../Report/DataSourceSelector";
import {
  IconButtonWrapper,
  ReorderImageWrapper,
} from "../../ReportCreateTable/styled";
import { DraggableLineWrapper } from "../../styled";
import { DragHandlePropsType } from "../../Wrappers/DragDropContainer";

import { CreateBodyChartFormValue } from "./model";

interface IRowSectionProps {
  rowIndex: number;
  remove(rowIndex: number): void;
  dragHandleProps: DragHandlePropsType;
}

export function BodyRowSection({
  rowIndex,
  dragHandleProps,
  remove,
}: IRowSectionProps) {
  const { values, setFieldValue } =
    useFormikContext<Pick<CreateBodyChartFormValue, "rows">>();

  const currentRow = values.rows[rowIndex];

  const onChangeRowValues = useCallback(
    (newValues: typeof currentRow) => {
      setFieldValue(`rows[${rowIndex}]`, {
        ...currentRow,
        ...newValues,
      });
    },
    [currentRow, rowIndex, setFieldValue]
  );

  return (
    <DraggableLineWrapper>
      <ReorderImageWrapper {...dragHandleProps}>
        <ReorderIcon />
      </ReorderImageWrapper>
      <DataSourceSelectorRow
        dataSourceArrayItemAccessor={`rows[${rowIndex}]`}
        onChangeDataSourceValues={onChangeRowValues}
        currentDataSource={currentRow}
        allowedReportItemTypes={[ReportItemType.Information]}
      />
      {values?.rows?.length > 0 && (
        <IconButtonWrapper>
          <IconButton icon={<BinIcon />} onClick={() => remove(rowIndex)} />
        </IconButtonWrapper>
      )}
    </DraggableLineWrapper>
  );
}
