import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import {
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";

import { useGetAgendaFiltersData } from "../../components/Filters/hooks/useGetAgendaFiltersData";
import { EventType } from "../../components/Filters/model";
import { GroupValue } from "../../components/GroupSelector";
import {
  GetTheGroupsImInQuery,
  useGetAthleteAdditionalDataLazyQuery,
  useGetAthleteDataForAuthorityToCreateWorkoutLazyQuery,
  useGetTheGroupsImInQuery,
} from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { useTrainerViewableGroupsQuery } from "../../services/hooks";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { useAccessLevelsContext } from "../accessLevels";
import { useNotificationsContext } from "../notifications";
import { ModalType, useModalContext } from "../UI/Modal";
import { useUserContext } from "../User";

dayjs.extend(weekOfYear);

export type GroupAvailableForSession = {
  id: string;
  name: string;
  isSubgroup?: boolean;
  owner?: { __typename?: "XpsUser"; thumb?: string | null; id: string };
};

type SelectedAthleteAdditionalData = {
  isExpired: boolean;
  name: string;
};

export type AgendaContextModel = {
  currentWeek: number;
  selectedEventTypes: EventType[];
  focusedAgendaGroupId: string;
  selectedAthleteId: string;
  selectedAthleteAdditionalData: SelectedAthleteAdditionalData | null;
  selectedVisibleGroupIds: string[];
  selectedGroup: GroupValue;
  setCurrentWeek: Dispatch<SetStateAction<number>>;
  setSelectedEventTypes: Dispatch<SetStateAction<string[]>>;
  setFocusedAgendaGroupId: Dispatch<SetStateAction<string>>;
  setSelectedVisibleGroupIds: Dispatch<SetStateAction<string[]>>;
  setSelectedAthleteId: Dispatch<SetStateAction<string>>;
  setSelectedGroup: Dispatch<SetStateAction<GroupValue>>;
  handleCreatePracticePress: (
    group?: GroupAvailableForSession,
    selectedStartDate?: Date
  ) => void;
  handleCreateWorkoutPress: (
    focusedAthleteId?: string,
    selectedStartDate?: Date
  ) => void;
  handleCreateEventPress: (
    group?: Pick<GroupAvailableForSession, "id">,
    selectedStartDate?: Date
  ) => void;
  handleCreateGamePress: (
    group?: Pick<GroupAvailableForSession, "id">,
    selectedStartDate?: Date
  ) => void;
  groupsAvailableForSession: GroupAvailableForSession[];
  groupList: GetTheGroupsImInQuery["getTheGroupsImIn"];
};

const AgendaContext = createContext<AgendaContextModel>(null);

export function AgendaContextProvider({ children }: { children: ReactNode }) {
  const { actions: modalActions } = useModalContext();
  const { isTrainer, isFamilyMember, sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getTeamSessionsRight, selectedAccount } = useAccessLevelsContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const [currentWeek, setCurrentWeek] = useState(dayjs().week());
  const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]);
  const [focusedAgendaGroupId, setFocusedAgendaGroupId] = useState<string>("");
  const [selectedVisibleGroupIds, setSelectedVisibleGroupIds] = useState<
    string[]
  >([]);
  const [selectedAthleteId, setSelectedAthleteId] = useState<string>("");
  const [selectedAthleteAdditionalData, setSelectedAthleteAdditionalData] =
    useState<SelectedAthleteAdditionalData | null>(null);

  const [selectedGroup, setSelectedGroup] = useState<GroupValue>(null);
  const { showWarningNotification } = useNotificationsContext();

  const { allNestedGroups: groupsAvailableForSession } =
    useGetAgendaFiltersData({
      selectedVisibleGroupIds,
    });

  const { data: viewableGroupsData } = useTrainerViewableGroupsQuery(
    {
      session: { sessionId },
    },
    { enabled: Boolean(sessionId) }
  );

  const [getAthleteAdditionalData] = useGetAthleteAdditionalDataLazyQuery({
    variables: { id: selectedAthleteId, sessionId },
  });

  const [getAthleteDataForAuthorityToCreateWorkout] =
    useGetAthleteDataForAuthorityToCreateWorkoutLazyQuery();

  const viewableGroupsIds =
    viewableGroupsData?.map((group) => group._groupGuid) ?? [];
  const viewableGroupsIdsIdentity = viewableGroupsIds.join(",");

  const selectFocusedGroupHandler = useCallback((groupId: string) => {
    setFocusedAgendaGroupId(groupId);
    setSelectedAthleteId("");
    setSelectedAthleteAdditionalData(null);
  }, []);

  const selectFocusedAthleteHandler = useCallback(
    async (athleteId: string) => {
      setSelectedAthleteId(athleteId);
      setFocusedAgendaGroupId("");

      const { data } = await getAthleteAdditionalData();
      if (data?.getUser) {
        setSelectedAthleteAdditionalData(
          data?.getUser as SelectedAthleteAdditionalData
        );
      }
    },
    [getAthleteAdditionalData]
  );

  // Significant performance improvement as query is heavy and needed only on calendar screen
  const isOnCalendarScreen = matchPath(
    ROUTING_CONFIG.calendar,
    location.pathname
  );

  const { data: { getTheGroupsImIn } = {} } = useGetTheGroupsImInQuery({
    variables: {
      sessionId,
      language,
      requireEditability: false,
    },
    fetchPolicy: "cache-first",
    skip: !sessionId || !isOnCalendarScreen,
  });

  const groupList = useMemo(
    () =>
      getTheGroupsImIn?.flatMap((group) => {
        const groupList = [{ ...group }];

        if (group.subGroups?.length) {
          groupList.push(
            ...(group.subGroups.map((subGroup) => ({
              ...subGroup,
            })) as any)
          );
        }

        return groupList;
      }),
    [getTheGroupsImIn]
  );

  useEffect(() => {
    if (focusedAgendaGroupId) {
      const group = groupList?.find(
        (group) => group?.id === focusedAgendaGroupId
      );

      return setSelectedGroup(group);
    }

    return setSelectedGroup(null);
  }, [focusedAgendaGroupId, groupList]);

  useEffect(() => {
    if (viewableGroupsData?.length) {
      setSelectedVisibleGroupIds(
        viewableGroupsData.map((group) => group._groupGuid)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewableGroupsIdsIdentity, viewableGroupsData?.length]);

  useEffect(() => {
    setFocusedAgendaGroupId("");
    setSelectedAthleteId("");
    setSelectedAthleteAdditionalData(null);

    const newSearchParams: Record<string, string> = {
      ...getSearchParamsObject(searchParams),
    };

    if (newSearchParams.focusedGroupId || newSearchParams.focusedAthleteId) {
      delete newSearchParams.focusedGroupId;
      delete newSearchParams.focusedAthleteId;
      setSearchParams(newSearchParams);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount?.id]);

  const handleCreateWorkoutPress = async (
    focusedAthleteId,
    selectedStartDate
  ) => {
    const athleteId = selectedAthleteId || focusedAthleteId;

    if (!athleteId) {
      return showWarningNotification(t("noAthleteChecked"));
    }

    const { data: athleteDataForAuthorityToCreateWorkout } =
      await getAthleteDataForAuthorityToCreateWorkout({
        variables: {
          sessionId,
          id: athleteId,
        },
      });

    const {
      getUser: {
        name,
        license,
        isExpired,
        aeblou: { create: canCreate },
      },
    } = athleteDataForAuthorityToCreateWorkout;

    if (license === "ClientBasic") {
      showWarningNotification(
        t("cannotCreateSessionForBasicClient", { x: name })
      );
      return false;
    }

    if (!canCreate) {
      showWarningNotification(
        t("cannotCreateSessionForViewAccess", { x: name })
      );
      return false;
    }

    if (isExpired) {
      showWarningNotification(t("hasExpiredAccount", { x: name }));
      return false;
    }

    navigate({
      pathname: `/${ROUTING_CONFIG.workoutEditor}`,
      search: `?athleteId=${athleteId}&selectedStartDate=${dayjs(
        selectedStartDate
      )}`,
    });
  };

  const openSelectSessionGroupModal = ({
    openSessionEditorModal,
  }: {
    openSessionEditorModal: (selectedGroup, groupOwnerId) => void;
  }) => {
    modalActions.openModal({
      modal: ModalType.SELECT_SESSION_GROUP,
      title: t("selectTeamOrGroup"),
      params: {
        onConfirm: openSessionEditorModal,
      },
    });
  };

  const handleCreatePracticePress = (
    group?: GroupAvailableForSession,
    selectedStartDate?: Date
  ) => {
    if (!group && !groupsAvailableForSession?.length) {
      return showWarningNotification(t("noGroupSelected"));
    }

    if (group?.id) {
      const { edit } = getTeamSessionsRight(group?.owner?.id);

      if (!edit) {
        return showWarningNotification(t("cannotCreateTeamSession"));
      }
    }

    if (selectedGroup?.id || groupsAvailableForSession.length < 4) {
      return modalActions.openModal({
        modal: ModalType.CREATE_PRACTICE_SESSION,
        title: "",
        params: {
          openedForSpecificGroupId: group.id,
          groupOwnerId: group?.owner?.id,
          selectedStartDate,
        },
      });
    }

    openSelectSessionGroupModal({
      openSessionEditorModal: (selectedGroup, groupOwnerId) =>
        modalActions.openModal({
          modal: ModalType.CREATE_PRACTICE_SESSION,
          title: "",
          params: {
            openedForSpecificGroupId: selectedGroup.id,
            groupOwnerId,
            selectedStartDate,
          },
        }),
    });
  };

  const handleCreateEventPress = (
    group?: Pick<GroupAvailableForSession, "id" | "owner">,
    selectedStartDate?: Date
  ) => {
    if (isRealTrainer({ isTrainer, isFamilyMember })) {
      if (!group && !groupsAvailableForSession?.length) {
        return showWarningNotification(t("noGroupSelected"));
      }

      if (group?.id) {
        const { edit } = getTeamSessionsRight(group?.owner?.id);

        if (!edit) {
          return showWarningNotification(t("cannotCreateTeamSession"));
        }
      }

      if (
        selectedGroup?.id ||
        groupsAvailableForSession.length < 4 ||
        group?.id
      ) {
        return modalActions.openModal({
          modal: ModalType.CREATE_EVENT_OR_GAME,
          title: t("newTeamEvent"),
          params: {
            disableCancel: true,
            eventType: EventType.TEAM_EVENT,
            openedForSpecificGroupId: group.id,
            selectedStartDate,
          },
        });
      }

      openSelectSessionGroupModal({
        openSessionEditorModal: (selectedGroup) =>
          modalActions.openModal({
            modal: ModalType.CREATE_EVENT_OR_GAME,
            title: t("newTeamEvent"),
            params: {
              disableCancel: true,
              eventType: EventType.TEAM_EVENT,
              openedForSpecificGroupId: selectedGroup.id,
              selectedStartDate,
            },
          }),
      });
    } else {
      modalActions.openModal({
        modal: ModalType.CREATE_EVENT_OR_GAME,
        title: t("newEvent"),
        params: {
          disableCancel: true,
          eventType: EventType.EVENT,
          selectedStartDate,
        },
      });
    }
  };

  const handleCreateGamePress = (
    group?: Pick<GroupAvailableForSession, "id" | "owner">,
    selectedStartDate?: Date
  ) => {
    if (!group && !groupsAvailableForSession?.length) {
      return showWarningNotification(t("noGroupSelected"));
    }

    if (group?.id) {
      const { edit } = getTeamSessionsRight(group?.owner?.id);

      if (!edit) {
        return showWarningNotification(t("cannotCreateTeamSession"));
      }
    }

    if (
      selectedGroup?.id ||
      groupsAvailableForSession.length < 4 ||
      group?.id
    ) {
      return modalActions.openModal({
        modal: ModalType.CREATE_EVENT_OR_GAME,
        title: t("newGame"),
        params: {
          disableCancel: true,
          eventType: EventType.TEAM_GAME,
          openedForSpecificGroupId: group.id,
          selectedStartDate,
        },
      });
    }

    openSelectSessionGroupModal({
      openSessionEditorModal: (selectedGroup) =>
        modalActions.openModal({
          modal: ModalType.CREATE_EVENT_OR_GAME,
          title: t("newGame"),
          params: {
            disableCancel: true,
            eventType: EventType.TEAM_GAME,
            openedForSpecificGroupId: selectedGroup.id,
            selectedStartDate,
          },
        }),
    });
  };

  const value = useMemo(
    () => ({
      currentWeek,
      selectedEventTypes,
      focusedAgendaGroupId,
      selectedVisibleGroupIds,
      selectedAthleteId,
      selectedAthleteAdditionalData,
      selectedGroup,
      setCurrentWeek,
      setSelectedEventTypes,
      setFocusedAgendaGroupId: selectFocusedGroupHandler,
      setSelectedVisibleGroupIds,
      setSelectedAthleteId: selectFocusedAthleteHandler,
      setSelectedGroup,
      handleCreatePracticePress,
      handleCreateWorkoutPress,
      handleCreateEventPress,
      handleCreateGamePress,
      groupsAvailableForSession,
      groupList,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentWeek,
      selectedEventTypes,
      focusedAgendaGroupId,
      selectedVisibleGroupIds,
      selectedAthleteId,
      selectedAthleteAdditionalData,
      selectedGroup,
      groupsAvailableForSession,
      groupList,
    ]
  );

  return (
    <AgendaContext.Provider value={value}>{children}</AgendaContext.Provider>
  );
}

export const useAgendaContext = () => useContext(AgendaContext);
