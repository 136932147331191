import React, { useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import { FieldArray } from "formik";
import styled from "styled-components";

import { GeneralButton } from "../Button/";
import { PlusIcon } from "../Icons";

export type RemoveFunction = <T>(index: number) => T;
type PushFunction = (obj: any) => void;

interface DragDropContainerProps {
  fieldArrayName: string;
  buttonText: string;
  items: (({
    push,
    remove,
    dragHandleProps,
  }: {
    push: PushFunction;
    remove: RemoveFunction;
    dragHandleProps: DraggableProvidedDragHandleProps;
  }) => React.ReactNode)[];
  defaultNewEntity?: any;
  hideButton?: boolean;
  onAddPress?: (newItem: any) => Promise<void>;
  additionalReorderAction?: () => void;
}

export type DragHandlePropsType = DraggableProvidedDragHandleProps;

const AddButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: fit-content;
  padding-top: 10px;
`;

export const DragDropContainer = ({
  fieldArrayName,
  items,
  buttonText = "ADD",
  defaultNewEntity = {},
  hideButton = false,
  onAddPress,
  additionalReorderAction,
}: DragDropContainerProps) => {
  const [isAdding, setIsAdding] = useState(false);

  const onOrderChange = (
    res: DropResult,
    move: (from: number, to: number) => void
  ) => {
    if (res.source && res.destination) {
      move(res.source.index, res.destination.index);
      additionalReorderAction?.();
    }
  };

  const handleAddClick = async ({ push }: { push: (obj: any) => void }) => {
    if (typeof onAddPress === "function") {
      setIsAdding(true);
      await onAddPress(defaultNewEntity);
      setIsAdding(false);
    } else {
      push(defaultNewEntity);
    }
  };

  return (
    <FieldArray name={fieldArrayName}>
      {({ push, remove, move }) => (
        <DragDropContext onDragEnd={(result) => onOrderChange(result, move)}>
          <Droppable droppableId={fieldArrayName}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, index) => (
                  <Draggable
                    index={index}
                    draggableId={`${fieldArrayName}-${index}`}
                    key={index}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        {item({
                          push,
                          remove,
                          dragHandleProps: provided.dragHandleProps,
                        })}
                      </div>
                    )}
                  </Draggable>
                ))}

                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                </div>

                {!hideButton && (
                  <AddButtonWrapper>
                    <GeneralButton
                      label={buttonText}
                      icon={<PlusIcon isActive />}
                      isLoading={isAdding}
                      onClick={() => handleAddClick({ push })}
                    />
                  </AddButtonWrapper>
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </FieldArray>
  );
};
