import React, { CSSProperties, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { debounce } from "lodash";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import { KeyboardKeyCodes } from "../../../utils/keyboardKeyCodes";
import { ControlledInput } from "../../HookForm/ControlledInput";
import { ColumnOfRowWrapper } from "../../HookForm/styled";

type PlanItemFormProps = {
  formMethods;
  id: string;
  splitAreaId?: string;
  style?: CSSProperties;
};

const FormWrapper = styled.div<{ isSplitSection?: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 100%;
`;

const Row = styled.div<{ zIndexValue?: string }>`
  display: flex;
  padding-top: 10px;
  flex-direction: row;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  justify-content: flex-end;
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
`;

export function PlanItemForm({
  formMethods,
  id,
  splitAreaId,
  style,
}: PlanItemFormProps) {
  const { t } = useTranslation();
  const { handleDurationChange } = usePracticeContext();
  const { watch } = formMethods;

  const debouncedHandleChange = useMemo(
    () =>
      debounce((minutes: number) => {
        if (minutes && id) {
          handleDurationChange(id, minutes, splitAreaId);
        }
      }, 250),
    [id, handleDurationChange, splitAreaId]
  );

  const onMinutesKeyDown = (e) => {
    if (
      [KeyboardKeyCodes.arrowUp, KeyboardKeyCodes.arrowDown].indexOf(
        e.keyCode
      ) > -1
    ) {
      e.preventDefault();
      const planItemEl = document.getElementById(`planItem-${id}`);
      if (planItemEl) {
        // up
        if (e.keyCode === KeyboardKeyCodes.arrowUp) {
          const minutesInput: HTMLElement | undefined =
            planItemEl?.previousElementSibling?.querySelector(
              ".planItemFormMinutes"
            );
          if (minutesInput) {
            minutesInput.focus();
          }
        }
        // down
        if (e.keyCode === KeyboardKeyCodes.arrowDown) {
          const minutesInput: HTMLElement | undefined =
            planItemEl?.nextElementSibling?.querySelector(
              ".planItemFormMinutes"
            );
          if (minutesInput) {
            minutesInput.focus();
          }
        }
      }
    }
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name === "minutes") {
        debouncedHandleChange(value?.minutes);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, id, debouncedHandleChange]);

  return (
    <FormWrapper isSplitSection={!!splitAreaId} style={style}>
      <Row>
        <ColumnOfRowWrapper maxWidth={65} rightMargin>
          <ControlledInput
            name="minutes"
            label={t("minutes")}
            type="number"
            className="planItemFormMinutes"
            onKeyDownCapture={onMinutesKeyDown}
            autoFocus={true}
            onWheel={(e) => e?.target?.blur()}
          />
        </ColumnOfRowWrapper>
        <ColumnOfRowWrapper maxWidth={140}>
          <ControlledInput name="time" label={t("time")} disabled />
        </ColumnOfRowWrapper>
        {/*<ColumnOfRowWrapper width={20} rightMargin>*/}
        {/*  <ControlledInput name="intensity" label={t("intensity")} />*/}
        {/*</ColumnOfRowWrapper>*/}
      </Row>
    </FormWrapper>
  );
}
