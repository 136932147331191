import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ReorderIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#8A939B",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="4" y="6" width="13" height="1" fill={tintColor} />
      <rect x="4" y="10" width="13" height="1" fill={tintColor} />
      <rect x="4" y="14" width="13" height="1" fill={tintColor} />
    </svg>
  )
);
