import React from "react";

import dayjs from "dayjs";
import { Formik } from "formik";

import { useUserContext } from "../../../../contexts/User";
import { PeriodType } from "../../../../models";
import { ConnectedFocusError } from "../../../FormikFocusError";
import { AppearanceSection } from "../../../ReportCreateTable/AppearanceSection";
import {
  AcuteChronicWidgetModel,
  WidgetType,
} from "../../../ReportCreateTable/models";
import { PeriodSection } from "../../PeriodSection";
import { PreviewButtonSection } from "../../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../../styled";
import { WidgetEditorTitleSection } from "../../WidgetEditorTitleSection";
import { AcuteChronicWidgetPreview } from "../Widget/Preview";

import { DataSection } from "./DataSection";
import { AcuteChronicWidgetFormValue } from "./model";

interface AcuteChronicWidgetEditorProps {
  widget: AcuteChronicWidgetModel;
  isSavingWidget: boolean;
  onSubmit: (values: AcuteChronicWidgetModel) => Promise<void>;
  onCancel: () => void;
}

export const AcuteChronicWidgetEditor = ({
  widget,
  isSavingWidget,
  onSubmit,
  onCancel,
}: AcuteChronicWidgetEditorProps) => {
  const { firstName, lastName } = useUserContext();

  const initialFormValues: AcuteChronicWidgetFormValue = {
    name: widget?.name ?? "",
    undertrainingTreshold: widget?.undertrainingTreshold ?? 0.8,
    overtrainingTreshold: widget?.overtrainingTreshold ?? 1.5,
    overloadTreshold: widget?.overloadTreshold ?? NaN,
    showBarsForDailyLoad: widget?.showBarsForDailyLoad ?? true,
    showAcuteAverageLine: widget?.showAcuteAverageLine ?? true,
    showChronicAverageLine: widget?.showChronicAverageLine ?? true,
    collectionId: widget?.collectionId ?? "",
    templateId: widget?.templateId ?? "",
    templateName: widget?.templateName ?? "",
    overloadTresholdColor: widget?.overloadTresholdColor ?? "",

    widgetBorders: widget?.widgetBorders ?? false,
    type: WidgetType.ACUTE_CHRONIC,
    period: widget?.period ?? {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
  };

  const handleSubmit = (values: AcuteChronicWidgetFormValue) => {
    onSubmit({
      ...values,
      type: WidgetType.ACUTE_CHRONIC,
      createdBy: widget?.createdBy ?? `${firstName} ${lastName}`,
      updatedAt: widget?.updatedAt ?? dayjs().valueOf(),
    });
  };

  return (
    <Formik<AcuteChronicWidgetFormValue>
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        if (!values.templateId) {
          return {
            templateId: "required",
          };
        }
      }}
      validateOnMount
    >
      {({ values, handleChange, submitForm, setFieldValue }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <WidgetEditorTitleSection
              value={values.name}
              name="name"
              handleChange={handleChange}
            />

            <div style={{ zIndex: 2 }}>
              <DataSection />
            </div>

            <AppearanceSection
              widgetType={WidgetType.ACUTE_CHRONIC}
              widgetBorders={values.widgetBorders}
              setFieldValue={setFieldValue}
            />

            <PeriodSection
              setFieldValue={setFieldValue}
              period={values.period}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <AcuteChronicWidgetPreview />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
};
