import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CardIcon = memo(
  ({
    width = 25,
    height = 25,
    isActive = false,
    tintColor = "#8A939B",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <rect x="1" y="8" width="23" height="3" fill={tintColor} />
      <rect x="3" y="17" width="6" height="1" fill={tintColor} />
      <rect x="11" y="17" width="4" height="1" fill={tintColor} />
      <rect
        x="0.5"
        y="3.5"
        width="24"
        height="18"
        rx="2.5"
        stroke={tintColor}
      />
    </svg>
  )
);
