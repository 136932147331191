import { ITableRowModel } from "./model";

export const sortNumericTableRows = (
  rows: ITableRowModel[],
  colIndex: number,
  sortOrder: number
) =>
  rows
    .filter((r) => {
      const colCel = r.cells[colIndex];

      return (
        colCel.value !== "-" &&
        !isNaN(
          typeof colCel.value == "string"
            ? parseFloat(colCel.value as string)
            : colCel.value
        )
      );
    })
    .sort(
      (r1, r2) =>
        (Number(r1.cells[colIndex].value) - Number(r2.cells[colIndex].value)) *
        sortOrder
    );

export const sortTextualTableRows = (
  rows: ITableRowModel[],
  colIndex: number,
  sortOrder: number
) =>
  rows
    .filter((r) => {
      const colCel = r.cells[colIndex];

      return (
        colCel.txt &&
        colCel.txt !== "-" &&
        (!colCel.value || colCel.value === "-")
      );
    })
    .sort(
      (r1, r2) =>
        `${r1.cells[colIndex].txt}`.localeCompare(`${r2.cells[colIndex].txt}`) *
        sortOrder
    );

export const sortEmptyValues = (rows: ITableRowModel[], colIndex: number) =>
  rows.filter(
    (r) => r.cells[colIndex].value === "-" || r.cells[colIndex].txt === "-"
  );

const NON_BREAKING_HYPHEN = "\u{2011}";
export const MakeNonBreakingString = (input: string) =>
  input.replace("-", NON_BREAKING_HYPHEN);
