import { useUserContext } from "../../contexts/User";
import {
  ResourcesUsageInput,
  useCreateIndividualEventMutation,
} from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useCreateEvent = () => {
  const { sessionId, language, timezone, id } = useUserContext();

  const [createIndividualEvent] = useCreateIndividualEventMutation();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const createNewIndividualEvent = async (
    label: string,
    start: number,
    durationMinutes: number,
    attendedMinutesBefore: number,
    googleLocationId: string,
    location: string,
    xtraLocation: string,
    notes: string,
    colorHexCode: string,
    resourcesUsage: ResourcesUsageInput,
    refetchQueries: () => string[]
  ) => {
    const createIndividualEventResult = await createIndividualEvent({
      variables: {
        focusedAthlete: id,
        timezone,
        language,
        sessionId,
        input: {
          label,
          start,
          durationMinutes,
          attendedMinutesBefore,
          googleLocationId,
          location,
          xtraLocation,
          notes,
          colorHexCode,
          resourcesUsage,
        },
      },

      refetchQueries,
    });

    handleSendManualNotificationAfterAction(
      { ...createIndividualEventResult?.data?.createIndividualEvent },
      createIndividualEventResult?.data?.createIndividualEvent
        ?.notificationToSuggest
    );
  };

  return { createNewIndividualEvent };
};
