import React, { ReactElement } from "react";

import styled from "styled-components";

import { BasicButton } from "../Button";
import Card from "../Card";
import StyledText from "../StyledText";

const Button = styled(BasicButton)`
  width: 100%;
  align-items: center;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
`;

const Title = styled(StyledText)`
  display: flex;
  align-items: center;
`;

const IconMainCard = styled(Card)`
  height: 65px;
  padding: 20px;
  margin-bottom: 5px;
`;

export const IconListCard = ({
  title,
  svgIcon,
  onClick,
}: {
  title: string;
  svgIcon?: ReactElement;

  onClick?: () => void;
}) => {
  return (
    <IconMainCard>
      <Button onClick={onClick}>
        <IconWrapper>{svgIcon}</IconWrapper>
        <Title fontSize={14} fontWeight="bold">
          {title}
        </Title>
      </Button>
    </IconMainCard>
  );
};
