import React from "react";

import { multiSelectStyles } from "../../components/Modals/AddToCalendar/styles";
import { MultiValueRemove } from "../../components/MultiValueRemove";
import { StyledSelect } from "../../components/StyledSelect";

export type Option = {
  value: string;
  label: string;
};

type PrintTabSelectorProps = {
  options: Option[];
  placeholder: string;
  selectedIds: string[];
  setSectionIDs: (ids: string[]) => void;
};

export function PrintSelector({
  options,
  placeholder,
  selectedIds,
  setSectionIDs,
}: PrintTabSelectorProps) {
  const onSelectOption = (newItem: string[]) => {
    setSectionIDs(newItem);
  };

  return (
    <StyledSelect
      isMulti
      isClearable={false}
      hideSelectedOptions={false}
      placeholder={placeholder}
      value={selectedIds}
      onChange={onSelectOption}
      options={options}
      styles={{
        ...multiSelectStyles,
        option: (base) => ({
          ...base,
          fontWeight: "700",
          fontSize: 12,
          lineHeight: "15px",
        }),
      }}
      components={{
        MultiValueRemove,
        IndicatorSeparator: () => null,
      }}
    />
  );
}
