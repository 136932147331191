import React from "react";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { PracticeInlineComment } from "../../graphql";
import StyledText from "../StyledText";

type CommentItemProps = {
  comment: PracticeInlineComment;
};

const Wrapper = styled.div`
  display: block;
  padding: 5px;
  margin: 15px;
`;

export function CommentItem({ comment }: CommentItemProps) {
  return (
    <Wrapper>
      <StyledText regular fontSize={12} color={COLOR_MEDIUM_GRAY}>
        {comment.text}
      </StyledText>
    </Wrapper>
  );
}
