import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Formik } from "formik";

import { ConnectedFocusError } from "../components/FormikFocusError";
import { Header } from "../components/Header";
import {
  PerformerSection,
  PerformerSelectionMode,
  PerformerSelectionWidgetType,
} from "../components/Report/PerformerSection";
import { PeriodSection } from "../components/Report/PeriodSection";
import { PreviewButtonSection } from "../components/Report/PreviewButtonSection";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { FormContainer, FormWrapper } from "../components/Report/styled";
import { WidgetEditorTitleSection } from "../components/Report/WidgetEditorTitleSection";
import { AppearanceSection } from "../components/ReportCreateTable/AppearanceSection";
import { ColumnsSection } from "../components/ReportCreateTable/ColumnsSection";
import { DefaultSortingSectionAthleteTable } from "../components/ReportCreateTable/DefaultSortingSection/AthleteTable";
import {
  CreateAthleteTableFormValue,
  WidgetType,
} from "../components/ReportCreateTable/models";
import { RowsSection } from "../components/ReportCreateTable/RowsSection";
import { AthleteTableRulesSection } from "../components/ReportCreateTable/Rules/AthleteTableRulesSection";
import { SectionWrapperForColorPicker } from "../components/ReportCreateTable/styled";
import { TablePreview } from "../components/ReportCreateTable/TablePreview";
import ScreenContainer from "../components/ScreenContainer";
import { useReportsContext } from "../contexts/Reports";
import { useUserContext } from "../contexts/User";
import {
  AggregateFunction,
  useSaveWidgetTemplateAthleteTableMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import {
  useAddWidgetToReport,
  useGetSelectedAccountIdInMonitoring,
  useNavigateToPreviousPage,
  useWidgetEditorUrlParams,
} from "../hooks";
import { useTableAppearancePreference } from "../hooks/table/useTableAppearancePreference";
import { PeriodType } from "../models";
import { getValidUnitNameFromReportWidgetCell } from "../utils/statistics";
import {
  buildPreferencesString,
  parseAthleteTablePreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export const AthleteTableEditor = () => {
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
  } = useWidgetEditorUrlParams();

  const { appearancePreferences, saveTableAppearancePreference } =
    useTableAppearancePreference();

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { sessionId, language, firstName, lastName } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const [saveWidgetTemplateAthleteTable, { loading: savingWidget }] =
    useSaveWidgetTemplateAthleteTableMutation();
  const { t } = useTranslation();

  const navigateToPreviousPage = useNavigateToPreviousPage();
  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const { data: templateData, loading: loadingAthleteTableData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const [reportsContextState] = useReportsContext();

  if (loadingAthleteTableData) {
    return <ReportEditorLoading />;
  }

  const initialFormValues =
    widgetId && templateData
      ? {
          title:
            (templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateAthleteTable" &&
              templateData.reportWidgetTemplateById?.name) ||
            "",
          columns:
            (templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateAthleteTable" &&
              templateData.reportWidgetTemplateById?.columns) ||
            [],
          rows: (
            (templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateAthleteTable" &&
              templateData?.reportWidgetTemplateById?.rowHeaders) ||
            []
          ).map((row) => ({
            attributeTemplateId:
              "attributeTemplate" in row
                ? row.attributeTemplate?.id
                : undefined,
            attributeTemplateName:
              "attributeTemplate" in row
                ? row.attributeTemplate?.name
                : undefined,
            attributeTemplateUnitName:
              getValidUnitNameFromReportWidgetCell(row),
            collectionId: row.collectionId,
            measurementTemplateId: row.templateId,
            measurementTemplateName: "",
          })),
          ...parseAthleteTablePreferencesObject(
            templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateAthleteTable"
              ? templateData.reportWidgetTemplateById?.preferences
              : "{}"
          ),
        }
      : ({
          title: "",
          columns: [AggregateFunction.Latest],
          rows: [{}],
          sortBy: null,
          sortingOrder: null,
          ...appearancePreferences,
          period: {
            type: PeriodType.SELECTION,
            from: null,
            to: null,
          },
          performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
          rules: [],
        } as CreateAthleteTableFormValue);

  const onSubmit = async (values: CreateAthleteTableFormValue) => {
    const { title, columns, rows, ...rest } = values;

    const appearancePreferences = {
      alternatingValues: rest.alternatingValues,
      alternatingColumns: rest.alternatingColumns,
      widgetBorders: rest.widgetBorders,
      rowDividers: rest.rowDividers,
      columnDividers: rest.columnDividers,
      outsideBorderDividers: rest.outsideBorderDividers,
      showValuesAsText: rest.showValuesAsText,
    };

    try {
      const response = await saveWidgetTemplateAthleteTable({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              title ||
              (isWidgetTemplate ? t("defaultAthleteTableWidgetName") : ""),
            columns,
            rowHeaders: rows.map(
              ({
                attributeTemplateId,
                collectionId,
                measurementTemplateId,
              }) => ({
                attributeTemplateId,
                collectionId,
                itemId: measurementTemplateId,
              })
            ),
            preferences: buildPreferencesString({
              type: WidgetType.TABLE,
              updatedAt: dayjs(),
              createdBy: `${firstName} ${lastName}`,
              ...rest,
            }),
            reportTemplateId: reportId || parentReportId,
          },
        },
      });
      await saveTableAppearancePreference(appearancePreferences);

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateAthleteTable?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateAthleteTable.id
        );
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <Formik<CreateAthleteTableFormValue>
        enableReinitialize={false} // Fixme
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validate={async (values) => {
          if (values.columns?.length < 1) {
            return { columns: "required" };
          }

          if (values.rows?.length < 1) {
            return { rows: "required" };
          }
        }}
        validateOnMount
      >
        {({ values, handleChange, submitForm, setFieldValue }) => (
          <FormContainer>
            <ConnectedFocusError />
            <FormWrapper>
              <WidgetEditorTitleSection
                value={values.title}
                handleChange={handleChange}
              />

              <ColumnsSection
                columns={values.columns}
                aggregations={reportsContextState.aggregations.filter(
                  (agg) => agg !== AggregateFunction.Each
                )}
              />

              <RowsSection />

              <SectionWrapperForColorPicker>
                <AthleteTableRulesSection />
              </SectionWrapperForColorPicker>

              <DefaultSortingSectionAthleteTable />

              <AppearanceSection
                widgetType={WidgetType.TABLE}
                alternatingValues={values.alternatingValues}
                alternatingColumns={values.alternatingColumns}
                rowDividers={values.rowDividers}
                columnDividers={values.columnDividers}
                outsideBorderDividers={values.outsideBorderDividers}
                widgetBorders={values.widgetBorders}
                showValuesAsText={values.showValuesAsText}
                setFieldValue={setFieldValue}
              />

              <PerformerSection
                performerSelectionWidgetType={
                  PerformerSelectionWidgetType.ATHLETE
                }
                setFieldValue={setFieldValue}
                performerSelectionMode={values.performerSelectionMode}
              />

              <PeriodSection
                setFieldValue={setFieldValue}
                period={values.period}
              />
            </FormWrapper>

            <PreviewButtonSection
              isSaving={savingWidget}
              withAutoWidth
              onCancelClick={navigateToPreviousPage}
              onSaveClick={submitForm}
            >
              <TablePreview />
            </PreviewButtonSection>
          </FormContainer>
        )}
      </Formik>
    </ScreenContainer>
  );
};

export default AthleteTableEditor;
