import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../colors";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px 30px 30px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${COLOR_GREY_ACTIVE};
`;
