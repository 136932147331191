import { std, mean } from "mathjs";

import { GrouppedColumnHeader } from "../../../../../components/TablePeriodization/Widget";
import { ReportWidgetCellNumerical } from "../../../../../graphql";
import { roundToTwo } from "../../../../../utils/number";
import { getDataKey } from "../../../../../utils/statistics";

export const getSummaryStatitisticForSoccerOrAttendanceForPeriods = (
  grouppedColumnHeaders: GrouppedColumnHeader[],
  statsData: {
    id: string;
    value: any;
  }[][]
) =>
  grouppedColumnHeaders.flatMap((columnHeader) =>
    columnHeader.attributes.flatMap(
      (columnAttribute: ReportWidgetCellNumerical) =>
        columnAttribute.aggregateFunctions.flatMap((currentAggregation) => {
          const columnHeaderId = getDataKey(
            columnHeader.templateId,
            columnAttribute.attributeTemplate?.id,
            currentAggregation
          );

          const currentColumnValues = statsData
            .flatMap(
              (statsDataItem) =>
                statsDataItem.find((item) => item.id === columnHeaderId)
                  ?.value ?? "-"
            )
            .filter((value) => typeof value === "number");

          return {
            id: columnHeaderId,
            average: currentColumnValues.length
              ? roundToTwo(mean(currentColumnValues))
              : "-",
            stdDev: currentColumnValues.length
              ? roundToTwo(std(currentColumnValues) as unknown as number)
              : "-",
          };
        })
    )
  );
