import dayjs from "dayjs";
import produce from "immer";

import { AggregateFunction, XpsUser } from "../../graphql";
import { getPeriodStartEndUnitFromString } from "../../utils/getPeriodStartEndUnit";

import { Actions } from "./actions";
import { ReportsContextState } from "./types";

const MONTH_DAYS_COUNT = 30;
const WEEK_DAYS_COUNT = 7;

export const initialState: ReportsContextState = {
  isDefaultState: true,
  selectedPeriod: "month",
  selectedStartDate: dayjs().startOf("month"),
  selectedEndDate: dayjs().endOf("month"),
  selectedSingleDay: dayjs(),
  aggregations: [
    AggregateFunction.Average,
    AggregateFunction.Max,
    AggregateFunction.Min,
    AggregateFunction.Latest,
    AggregateFunction.Sum,
    AggregateFunction.Each,
    AggregateFunction.MaxForever,
    AggregateFunction.MinForever,
    AggregateFunction.LatestForever,
  ],
  lastSelectedAccountForAllAccounts: {} as XpsUser,
};

const getLastXDaysRange = (countOfDays: number) => ({
  selectedStartDate: dayjs()
    .add(-1 * (countOfDays - 1), "day")
    .startOf("day"),
  selectedEndDate: dayjs().endOf("day"),
});

export default (
  state: ReportsContextState,
  action: Actions
): ReportsContextState =>
  produce(state, (draft) => {
    switch (action.type) {
      case "LOAD_REPORTS": {
        draft.selectedAthlete = action.state.selectedAthlete;

        draft.lastSelectedAccountForAllAccounts =
          action.state.lastSelectedAccountForAllAccounts;
        break;
      }

      case "LOAD_DATE_DATA": {
        draft.selectedPeriod = action.state.selectedPeriod
          ? action.state.selectedPeriod
          : draft.selectedPeriod;
        draft.selectedSingleDay = action.state.selectedSingleDay
          ? dayjs(action.state.selectedSingleDay)
          : draft.selectedSingleDay;

        if (
          draft.selectedPeriod === "last7days" ||
          draft.selectedPeriod === "last30days"
        ) {
          const range = getLastXDaysRange(
            draft.selectedPeriod === "last7days"
              ? WEEK_DAYS_COUNT
              : MONTH_DAYS_COUNT
          );
          draft.selectedStartDate = range.selectedStartDate;
          draft.selectedEndDate = range.selectedEndDate;
        } else {
          draft.selectedStartDate = action.state.selectedStartDate
            ? dayjs(action.state.selectedStartDate)
            : draft.selectedStartDate;
          draft.selectedEndDate = action.state.selectedEndDate
            ? dayjs(action.state.selectedEndDate)
            : draft.selectedEndDate;
        }

        break;
      }

      case "SELECT_GROUP": {
        draft.selectedGroup = action.group;
        draft.selectedAthlete = null;
        break;
      }

      case "SELECT_ATHLETE": {
        draft.selectedAthlete = action.athlete;
        break;
      }

      case "SELECT_PERIOD": {
        draft.isDefaultState = false;
        draft.selectedPeriod = action.period;
        switch (action.period) {
          case "day":
            draft.selectedEndDate = draft.selectedStartDate?.clone?.();
          // eslint-disable-next-line no-fallthrough
          case "week":
          case "month":
            draft.selectedStartDate = draft.selectedStartDate.startOf(
              getPeriodStartEndUnitFromString(action.period)
            );
            draft.selectedEndDate = draft.selectedStartDate.endOf(
              getPeriodStartEndUnitFromString(action.period)
            );
            break;
          case "last7days": {
            const range = getLastXDaysRange(WEEK_DAYS_COUNT);
            draft.selectedStartDate = range.selectedStartDate;
            draft.selectedEndDate = range.selectedEndDate;
            break;
          }
          case "last30days": {
            const range = getLastXDaysRange(MONTH_DAYS_COUNT);
            draft.selectedStartDate = range.selectedStartDate;
            draft.selectedEndDate = range.selectedEndDate;
            break;
          }
        }
        break;
      }

      case "SELECT_START_DATE": {
        draft.isDefaultState = false;
        draft.selectedStartDate = action.date;
        break;
      }

      case "SELECT_END_DATE": {
        draft.isDefaultState = false;
        draft.selectedEndDate = action.date;
        break;
      }

      case "SELECT_NEXT_PERIOD": {
        changePeriod("next", action.isSingleDay, draft);
        break;
      }

      case "SELECT_PREV_PERIOD": {
        changePeriod("prev", action.isSingleDay, draft);
        break;
      }

      case "SELECT_SINGLE_DAY": {
        draft.isDefaultState = false;
        draft.selectedSingleDay = action.date;
        break;
      }

      case "SELECT_LAST_SELECTED_ACCOUNT_FOR_ALL_ACCOUNTS": {
        draft.lastSelectedAccountForAllAccounts = action.account;
        break;
      }

      case "RESET_REPORTS": {
        draft = initialState;
        break;
      }
    }
  });

function changePeriod(
  direction: "next" | "prev",
  isSingleDay: boolean,
  draft: ReportsContextState
) {
  const addCount = direction === "next" ? 1 : -1;

  draft.isDefaultState = false;

  if (isSingleDay) {
    draft.selectedSingleDay = draft.selectedSingleDay
      .add(addCount, "day")
      .startOf("day");

    return;
  }

  if (
    draft.selectedPeriod === "custom" ||
    draft.selectedPeriod === "last7days" ||
    draft.selectedPeriod === "last30days"
  ) {
    const daysBetween = draft.selectedEndDate.diff(
      draft.selectedStartDate,
      "day"
    );
    draft.selectedStartDate = draft.selectedStartDate.add(
      addCount * daysBetween,
      "day"
    );
    draft.selectedEndDate = draft.selectedEndDate.add(
      addCount * daysBetween,
      "day"
    );
    draft.selectedPeriod = "custom";
  } else {
    draft.selectedStartDate = draft.selectedStartDate
      .add(addCount, draft.selectedPeriod)
      .startOf(getPeriodStartEndUnitFromString(draft.selectedPeriod));
    draft.selectedEndDate = draft.selectedEndDate
      .add(addCount, draft.selectedPeriod)
      .endOf(getPeriodStartEndUnitFromString(draft.selectedPeriod));
  }
}
