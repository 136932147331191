import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const UserRoundedIcon = memo(
  ({ width = 21, height = 21, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="10" stroke="white" />
      <mask id="path-2-inside-1_10909_3988" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.35974 18.1985C4.33119 15.1823 7.16075 13 10.5 13C13.8393 13 16.6688 15.1823 17.6403 18.1985C15.7667 19.9371 13.2575 21 10.5 21C7.74253 21 5.23331 19.937 3.35974 18.1985Z"
        />
      </mask>
      <path
        d="M3.35974 18.1985L2.40789 17.892L2.21261 18.4983L2.67955 18.9316L3.35974 18.1985ZM17.6403 18.1985L18.3205 18.9316L18.7874 18.4983L18.5921 17.892L17.6403 18.1985ZM4.31159 18.5051C5.15384 15.89 7.60751 14 10.5 14V12C6.71399 12 3.50855 14.4746 2.40789 17.892L4.31159 18.5051ZM10.5 14C13.3925 14 15.8462 15.89 16.6884 18.5051L18.5921 17.892C17.4915 14.4746 14.286 12 10.5 12V14ZM16.9601 17.4655C15.2642 19.0392 12.9954 20 10.5 20V22C13.5196 22 16.2693 20.8349 18.3205 18.9316L16.9601 17.4655ZM10.5 20C8.00463 20 5.73588 19.0392 4.03994 17.4655L2.67955 18.9316C4.73073 20.8349 7.48044 22 10.5 22V20Z"
        fill="white"
        mask="url(#path-2-inside-1_10909_3988)"
      />
      <circle cx="10.5" cy="8.5" r="3" stroke="white" />
    </svg>
  )
);
