import Storage from "./storage";
import { StorageKeys } from "./storageKeys";

export interface Account {
  id: string;
  profileImageUrl?: string;
  profileImageUrlThumb?: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  isFamilyMember: boolean;
  isTrainer: boolean;
  isLoggedIn: boolean;
  sessionId?: string;
  isTemporary?: boolean;
}

interface EditableAccountData {
  profileImageUrl?: string;
  profileImageUrlThumb?: string;
  fullName?: string;
}

export const getAccounts = () => {
  try {
    const savedAccount = Storage.getItem<Account[]>(
      StorageKeys.accountsToSwitch
    );
    return savedAccount ? savedAccount : null;
  } catch {
    return null;
  }
};

// second arg is for login without "remember me" option
export const saveAccount = (newAccount: Account, isTemporary?: boolean) => {
  const savedAccounts = getAccounts();
  const newAccountsToSave = savedAccounts
    ? savedAccounts
        .filter((savedAccount) => savedAccount.id !== newAccount.id)
        .map((account) => {
          return {
            ...account,
            isLoggedIn: false,
          };
        })
    : [];
  newAccountsToSave.push({
    ...newAccount,
    isTemporary: !!isTemporary,
  });
  return Storage.setItem(StorageKeys.accountsToSwitch, newAccountsToSave);
};

export const logoutFromAccounts = (loggedInUserId?: string) => {
  const savedAccounts = getAccounts();
  const newSaveAccounts = [];
  if (savedAccounts) {
    savedAccounts.map((savedAccount) => {
      newSaveAccounts.push({
        ...savedAccount,
        isLoggedIn: savedAccount.id === loggedInUserId,
      });
    });
    return Storage.setItem(StorageKeys.accountsToSwitch, newSaveAccounts);
  }
};

export const removeAccount = (accountGuidToRemove) => {
  const savedAccounts = getAccounts();
  const newAccountsToSave = savedAccounts
    ? savedAccounts.filter(
        (savedAccount) => savedAccount.id !== accountGuidToRemove
      )
    : [];
  return Storage.setItem(StorageKeys.accountsToSwitch, newAccountsToSave);
};

export const editActiveAccount = (editableAccountData: EditableAccountData) => {
  const savedAccounts = getAccounts();

  let activeAccount = savedAccounts?.find(
    (savedAccount) => savedAccount.isLoggedIn
  );

  activeAccount = {
    ...activeAccount,
    ...editableAccountData,
  };

  saveAccount(activeAccount);
};
