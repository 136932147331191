import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_TEXT_DARK } from "../../../colors";
import { useAccessLevelsContext } from "../../../contexts/accessLevels";
import { useUserContext } from "../../../contexts/User";
import { useGetTheGroupsImInQuery } from "../../../graphql";
import {
  useTrainerClientsQuery,
  useTrainerViewableGroupsQuery,
} from "../../../services/hooks";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { CustomAthleteOption } from "../../CustomAthleteOption";
import { CustomTeamOption } from "../../CustomTeamOption";
import { MultiValue } from "../../MultiValue";
import { MultiValueContainer } from "../../MultiValueContainer";
import { MultiValueRemove } from "../../MultiValueRemove";
import { StyledSelect } from "../../StyledSelect";

import { multiSelectStyles } from "./styles";

const SelectWrapper = styled.div`
  padding: 25px 30px;
`;

const SelectTitle = styled.p`
  color: ${COLOR_TEXT_DARK};
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 0 20px 0;
`;

const Label = styled.p`
  margin-right: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export enum SelectDeselectEnum {
  SELECT_ALL = "selectAll",
  DESELECT_ALL = "deselectAll",
}

export const TeamsOrAthletesSelect = ({
  isTeamPractice,
  selectedItemsToShow,
  setSelectedItemsToShow,
}) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const { sessionId, language } = user;
  const { selectedAccount } = useAccessLevelsContext();

  const { data, loading } = useGetTheGroupsImInQuery({
    variables: { sessionId, accountId: selectedAccount?.id, language },
    skip: !isRealTrainer(user),
  });

  const { data: trainerViewableGroups } = useTrainerViewableGroupsQuery(
    {
      session: {
        sessionId: user?.sessionId,
      },
    },
    { enabled: !isTeamPractice }
  );

  const { data: trainerClients } = useTrainerClientsQuery(
    {
      session: {
        sessionId: user?.sessionId,
      },
    },
    { enabled: !isTeamPractice }
  );

  const athletesByGroupGuid = useMemo(() => {
    return trainerClients?._myAthletes.reduce((acc, athlete) => {
      athlete._groupsGuids.forEach((groupGuid) => {
        if (!acc[groupGuid]) {
          acc[groupGuid] = [];
        }
        acc[groupGuid].push(athlete);
      });
      return acc;
    }, {});
  }, [trainerClients?._myAthletes]);

  const options = useMemo(() => {
    if (!data?.getTheGroupsImIn) return [];

    const formatGroupOptions = (groups) =>
      groups.flatMap((group) => [
        { value: group.teamAthleteId, label: group.name, isGroup: true },
        ...(group.subGroups?.map((subGroup) => ({
          value: subGroup.teamAthleteId,
          label: subGroup.name,
        })) || []),
      ]);

    const formatAthleteOptions = (viewableGroups, athletesByGroupGuid) => {
      const groupsWithAthletes = [];

      viewableGroups.forEach((group) => {
        const groupGuid = group?._groupGuid;
        const athletes = athletesByGroupGuid[groupGuid];

        if (athletes?.length) {
          const selectedItemsToShowIds = new Set(
            selectedItemsToShow.map((item) => item)
          );

          const allAthletesFromGroupAreIncluded = athletes?.every((item) =>
            selectedItemsToShowIds.has(item._athleteGuid)
          );

          groupsWithAthletes.push({
            value: groupGuid,
            label: group?._name,
            isGroup: true,
            hasAthletes: true,
            allAthletesFromGroupAreIncluded,
          });

          athletes.forEach((athlete) => {
            groupsWithAthletes.push({
              value: athlete?._athleteGuid,
              label: athlete?._athleteName,
              isAvatar: true,
            });
          });
        }
      });

      return groupsWithAthletes;
    };

    if (isTeamPractice) {
      return formatGroupOptions(data.getTheGroupsImIn);
    }

    if (trainerClients?._myAthletes) {
      return formatAthleteOptions(trainerViewableGroups, athletesByGroupGuid);
    }

    return [];
  }, [
    athletesByGroupGuid,
    data?.getTheGroupsImIn,
    isTeamPractice,
    selectedItemsToShow,
    trainerClients?._myAthletes,
    trainerViewableGroups,
  ]);

  const onSelect = (newItem, actionMeta?: { action: string }) => {
    const actionName = actionMeta?.action;
    if (
      actionName === SelectDeselectEnum.SELECT_ALL ||
      actionName === SelectDeselectEnum.DESELECT_ALL
    ) {
      handleSelectDeselectAllAction(actionName, athletesByGroupGuid[newItem]);
    } else {
      const alreadyAdded = selectedItemsToShow.find(
        (selectedItem) => selectedItem === newItem.toString()
      );
      if (alreadyAdded) {
        onDelete(alreadyAdded);
      } else {
        setSelectedItemsToShow([...selectedItemsToShow, newItem]);
      }
    }
  };

  const handleSelectDeselectAllAction = (actionName, athletes) => {
    const athletesIds = athletes.map((athlete) => athlete?._athleteGuid);

    if (actionName === SelectDeselectEnum.DESELECT_ALL) {
      setSelectedItemsToShow(
        selectedItemsToShow.filter((item) => !athletesIds.includes(item))
      );
    } else {
      const newSelections = athletesIds.filter(
        (item) => !selectedItemsToShow.includes(item)
      );
      setSelectedItemsToShow([...selectedItemsToShow, ...newSelections]);
    }
  };

  const onDelete = (removedItem) => {
    setSelectedItemsToShow([
      ...selectedItemsToShow.filter(
        (selectedItem) => selectedItem !== removedItem.toString()
      ),
    ]);
  };

  return (
    <SelectWrapper>
      <SelectTitle>
        {t(isTeamPractice ? "selectTeamsAndDate" : "selectAthletesAndDate")}
      </SelectTitle>
      <div>
        <Label>{t(isTeamPractice ? "team" : "athlete").toUpperCase()}</Label>
        <StyledSelect
          options={options}
          value={selectedItemsToShow}
          loading={loading}
          onChange={(newItem, actionMeta) => onSelect(newItem, actionMeta)}
          onDelete={(removedItem) => onDelete(removedItem)}
          components={{
            Option: isTeamPractice ? CustomTeamOption : CustomAthleteOption,
            MultiValueRemove,
            MultiValueContainer,
            IndicatorSeparator: () => null,
            MultiValue,
          }}
          styles={multiSelectStyles}
          isMulti={true}
          isClearable={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          blurInputOnSelect={true}
        />
      </div>
    </SelectWrapper>
  );
};
