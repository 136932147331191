import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";

import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplateInput,
  ReportType,
  useDeleteReportTemplateMutation,
  useSaveReportTemplateMutation,
} from "../../../graphql";
import { generateDefaultLayout } from "../../../utils/generateDefaultLayout";
import { AutoSubmitFormikForm } from "../../AutoSubmitFormikForm";
import { DragDropContainer } from "../../Wrappers/DragDropContainer";

import { TabRow } from "./TabRow";

type TabsSettingsFormValues = {
  tabs: ReportTemplateInput[];
};

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 10px 25px 30px;
`;

const tabDefaultName = "Tab";

function getTabNewEntity(
  parentId?: string,
  createdBy?: string
): ReportTemplateInput {
  return {
    name: tabDefaultName,
    parentId,
    preferences: JSON.stringify({
      layout: generateDefaultLayout(),
      createdBy,
      updatedAt: dayjs(),
    }),
    reportType: ReportType.Tab,
  };
}

export function TabsSettingsSection({
  parentReportId,
  accountId,
  tabs,
}: {
  parentReportId: string;
  accountId?: string;
  tabs: ReportTemplateInput[];
}) {
  const {
    sessionId,
    firstName: userFirstName,
    lastName: userLastName,
  } = useUserContext();
  const { t } = useTranslation();

  const [saveReport] = useSaveReportTemplateMutation();
  const [deleteReport] = useDeleteReportTemplateMutation();
  const [addedTabId, setAddedTabId] = useState(null);

  async function handleSaveTab(
    { id, name, parentId, preferences, reportType }: ReportTemplateInput,
    position: number
  ) {
    return saveReport({
      variables: {
        accountId,
        sessionId,
        input: {
          id,
          name,
          parentId: parentId ?? parentReportId,
          preferences,
          reportType: reportType ?? ReportType.Tab,
          position,
        },
      },
    });
  }

  async function handleDeleteTab(tab: ReportTemplateInput) {
    setAddedTabId(null);

    return deleteReport({
      variables: {
        reportId: tab.id,
        sessionId,
      },
    });
  }

  const formik = useFormik<TabsSettingsFormValues>({
    initialValues: {
      tabs:
        tabs?.map(({ id, name, position, reportType, preferences }) => ({
          id,
          name,
          position,
          reportType,
          preferences,
          parentId: parentReportId,
        })) ?? [],
    },
    async onSubmit(values) {
      // tab was deleted, do nothing, it was handled
      if (values.tabs.length + 1 === tabs.length) {
        return;
      }

      values.tabs.forEach((tab, index) => {
        if (tab.name !== tabs?.[index]?.name) {
          handleSaveTab(tab, index);
        }
      });
    },
  });

  const { values, resetForm, setFieldValue } = formik;

  const onAddNewTabClick = async (newItem: ReportTemplateInput) => {
    const newLastIndex = values.tabs.length;
    const savedTab = await handleSaveTab(newItem, newLastIndex);
    const newTabId = savedTab?.data?.saveReportTemplate?.id;

    await setFieldValue(`tabs.[${newLastIndex}]`, {
      ...newItem,
      id: newTabId,
    });

    setAddedTabId(newTabId);
  };

  useEffect(() => {
    const tabInputs = tabs?.map(({ id, name, reportType, preferences }) => ({
      id,
      name,
      reportType,
      preferences,
      parentId: parentReportId,
    }));
    resetForm({
      values: {
        tabs:
          tabInputs?.length > 0
            ? tabInputs
            : [
                getTabNewEntity(
                  parentReportId,
                  `${userFirstName} ${userLastName}`
                ),
              ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider value={formik}>
      <Section>
        <DragDropContainer
          fieldArrayName="tabs"
          buttonText={t("addTab").toUpperCase()}
          defaultNewEntity={getTabNewEntity(
            parentReportId,
            `${userFirstName} ${userLastName}`
          )}
          onAddPress={onAddNewTabClick}
          additionalReorderAction={() => setAddedTabId(null)}
          items={values.tabs.map(
            (tab, index) =>
              ({ remove, dragHandleProps }) => (
                <TabRow
                  key={tab.id}
                  dragHandleProps={dragHandleProps}
                  tab={tab}
                  index={index}
                  shouldBeTextSelected={tab.id === addedTabId}
                  onDeletePress={async () => {
                    await handleDeleteTab(tab);
                    remove<ReportTemplateInput>(index);
                  }}
                />
              )
          )}
        />
      </Section>

      <AutoSubmitFormikForm isEnables={true} delay={300} />
    </FormikProvider>
  );
}
