import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../colors";

import { DayBlock } from "./DayBlock";

const SectionLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOR_MEDIUM_GRAY};
  margin: 15px 8px;
`;

export function AgendaActionBlock({
  withAnswerLabel = false,
  monthsWithDays,
  agendaWithRegistrationRefetch,
}) {
  const { t } = useTranslation();

  return (
    <div>
      {withAnswerLabel ? (
        <SectionLabel>{t("answered").toUpperCase()}</SectionLabel>
      ) : null}
      {monthsWithDays?.map(({ month, days }) => (
        <div key={month}>
          {Object.keys(days).map((day) => (
            <DayBlock
              key={day}
              refetch={agendaWithRegistrationRefetch}
              day={day}
              days={days}
            />
          ))}
        </div>
      ))}
    </div>
  );
}
