import React from "react";

interface Props {
  color?: string;
}

export const CalendarChevronLeftIcon = ({ color }: Props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 5L8 10.5L13.5 16"
        stroke={color ?? "#64707A"}
        strokeWidth="1.5"
      />
    </svg>
  );
};
