import React from "react";
import { ResourceHeaderProps } from "react-big-calendar";

import styled from "styled-components";

import { COLOR_SECONDARY_GREY_HOVER } from "../../colors";

const ResourceHeaderWrapper = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${COLOR_SECONDARY_GREY_HOVER};
`;

export function CustomCalendarResourceHeader({ label }: ResourceHeaderProps) {
  const columLabel = label as string;

  return <ResourceHeaderWrapper>{columLabel}</ResourceHeaderWrapper>;
}
