import React, { useMemo } from "react";

import dayjs from "dayjs";
import useResizeObserver from "use-resize-observer";

import { useReportContext } from "../../../../contexts/report";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../../../hooks";
import { useAttendanceLog2022Query } from "../../../../services/statistics/hooks";
import { AttendanceLogWidgetModel } from "../../../ReportCreateTable/models";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import { WidgetContainer } from "../../styled";
import { WidgetLoader } from "../../WidgetLoader";

import { AttendanceLogTable } from "./AttendanceLogTable";
import { AttendanceLogDataItem } from "./model";

interface AttendanceLogWidgetProps extends IWidgetResizeProps {
  widget: AttendanceLogWidgetModel;
  readOnly?: boolean;
}

const AttendanceLogWidget = ({
  widget,
  gridItemId,
  layoutRef,
  readOnly,
  setItemToResize,
  setItemsToHideInViewMode,
}: AttendanceLogWidgetProps) => {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete, selectedGroup }] = useReportsContext();
  const { report } = useReportContext();
  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();

  const { fromDate, toDate } = useReportPeriod(widget.period);

  useBottomWidgetResize({
    setItemToResize,
    setItemsToHideInViewMode,
    gridItemId,
    widgetHeight,
    id: widget.id,
    shouldResize: readOnly,
    alwaysResize: true,
    layoutRef,
  });

  const {
    data: attendanceLogResponse,
    isLoading: attendanceLogIsLoading,
    fetchStatus: attendanceLogFetchStatus,
  } = useAttendanceLog2022Query(
    {
      athleteGuid: selectedAthlete?.id,
      groupGuid: selectedGroup?.id,
      data: {
        games: widget.showGames,
        practices: widget.showPractices,
        events: widget.showEvents,
        allGroups: widget.allGroups,
      },
      range: { from: fromDate, to: toDate },
      session: { sessionId },
      reportTemplateId: report?.id,
    },
    { enabled: Boolean(sessionId && selectedAthlete?.id) }
  );

  const data = useMemo<AttendanceLogDataItem[]>(
    () =>
      (attendanceLogResponse?.data?.items ?? []).map(({ time, ...rest }) => ({
        time: dayjs(time),
        ...rest,
      })) ?? [],
    [attendanceLogResponse?.data?.items]
  );

  if (attendanceLogFetchStatus !== "idle" && attendanceLogIsLoading) {
    return <WidgetLoader />;
  }

  return (
    <WidgetContainer showBorders={widget.widgetBorders}>
      <AttendanceLogTable
        data={data}
        name={widget.name}
        columnDividers={widget.columnDividers}
        rowDividers={widget.rowDividers}
        alternatingRow={widget.alternatingValues}
        sortingOrder={widget.sortingOrder}
        resizerRef={tableRef}
        maxCount={widget.maxCount}
      />
    </WidgetContainer>
  );
};

export default AttendanceLogWidget;
