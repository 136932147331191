import React, { ReactNode } from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { BasicButton } from "../../Button";
import StyledText from "../../StyledText";

type Props = {
  svgIcon: ReactNode;
  title: string;
  onClick: () => void;
};

const ItemContainer = styled(BasicButton)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 21px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
`;

const ItemLabel = styled(StyledText)`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${COLOR_TEXT_DARK};
`;

function AttendanceStatusItem({ svgIcon, title, onClick }: Props) {
  return (
    <ItemContainer onClick={onClick}>
      <IconWrapper>{svgIcon}</IconWrapper>
      <div>
        <ItemLabel>{title}</ItemLabel>
      </div>
    </ItemContainer>
  );
}

export default AttendanceStatusItem;
