import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../colors";
import { useUIContext } from "../../contexts/UI";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { isTvEnv } from "../../utils/isTvEnv";
import StyledText from "../StyledText";

import { AthleteNavSide } from "./AthleteNavSide";
import { FamilyMemberNavSide } from "./FamilyMemberNavSide";
import { ROUTES_WITH_COMPACT_NAVSIDE } from "./routeWithCompactNavside";
import { TrainerNavSide } from "./TrainerNavSide";

export const SectionTitleText = styled(StyledText)`
  padding: 30px 0 10px 20px;
  font-size: 12px;
  font-weight: 600;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

export const NavSide = () => {
  const { pathname } = useLocation();

  const user = useUserContext();
  const UI = useUIContext();
  const isTv = isTvEnv();

  const isCompact =
    UI.width < 1000 ||
    ROUTES_WITH_COMPACT_NAVSIDE.some((compactPath) =>
      matchPath(compactPath, pathname)
    );

  if (UI.embed || isTv) return null;

  if (user.isFamilyMember) {
    return <FamilyMemberNavSide isCompact={isCompact} />;
  }

  if (isRealTrainer(user)) {
    return <TrainerNavSide isCompact={isCompact} />;
  }

  return <AthleteNavSide isCompact={isCompact} />;
};
