import { Dayjs } from "dayjs";
import { Options, XAxisLabelsOptions } from "highcharts";

import { COLOR_TEXT_TABLE_LABEL } from "../../../../colors";
import { Period } from "../../../../contexts/Reports";
import { AggregateFunction } from "../../../../graphql";
import { ChartWidgetModel, TimeLine } from "../model";

import { generatePeriodCategories } from "./generateCategories";

const BREAKPOINT_FOR_ROTATION_OF_X_AXIS = 6;
const BREAKPOINT_FOR_MAX_ROTATION_OF_X_AXIS = 10;

const colors16 = [
  "#4698BC",
  "#8BC8D3",
  "#6BBB5D",
  "#BCDE85",
  "#DE8530",
  "#F5AC91",
  "#DF5D99",
  "#DDA0D0",
  "#48AA9F",
  "#96D1B4",
  "#A66BC3",
  "#B0A8DE",
  "#AB9B06",
  "#D4B64A",
  "#676E74",
  "#9FA9B0",
];
const colors8 = [
  "#4698BC",
  "#8BC8D3",
  "#6BBB5D",
  "#BCDE85",
  "#DE8530",
  "#F5AC91",
  "#DF5D99",
  "#DDA0D0",
];
const colors5 = ["#4698BC", "#6BBB5D", "#DE8530", "#DF5D99", "#9FA9B0"];
const colors3 = ["#4698BC", "#DE8530", "#DF5D99"];
const colors2 = ["#4698BC", "#DE8530"];

function getDefaultOptions(
  widget: ChartWidgetModel,
  chartMarginTop?: number
): Options {
  return {
    chart: {
      type: widget.chartType,
      animation: false,
      marginTop: chartMarginTop,
    },
    plotOptions: {
      series: {
        animation: false,
        marker: { enabled: true },
      },
    },
    legend: {
      enabled: !!widget.showLegend,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size: 10px">{point.key}</span><br />',
      xDateFormat: "%A, %e %b",
      shared: true,
      useHTML: true,
    },
  };
}

export function generateContinuousChartOptions({
  widget,
  fromDate,
  toDate,
  selectedPeriod,
  chartMarginTop,
  timezone,
}: {
  widget: ChartWidgetModel;
  fromDate: Dayjs;
  toDate: Dayjs;
  selectedPeriod: Period;
  chartMarginTop?: number;
  timezone: string;
}) {
  const options: Options = {
    ...getDefaultOptions(widget, chartMarginTop),
    time: {
      useUTC: false,
      timezone,
    },
    xAxis: {
      id: `${TimeLine.CONTINUOUS}:${AggregateFunction.Each}`,
      type: "datetime",
      dateTimeLabelFormats: {
        day: selectedPeriod === "week" ? "%a" : "%e. %b",
      },
      min: fromDate.valueOf(),
      max: toDate.valueOf(),
      crosshair: true,
    },
    yAxis: [],
  };

  return options;
}

export function generatePeriodChartOptions({
  widget,
  fromDate,
  toDate,
  chartMarginTop,
}: {
  widget: ChartWidgetModel;
  fromDate: Dayjs;
  toDate: Dayjs;
  chartMarginTop?: number;
}) {
  const options: Options = {
    ...getDefaultOptions(widget, chartMarginTop),
    xAxis: {
      id: TimeLine.PERIOD,
      categories: generatePeriodCategories(
        fromDate,
        toDate,
        widget.timeLinePeriod
      ),
      crosshair: true,
      labels: {
        style: {
          fontWeight: "600",
          fontSize: "9px",
          color: COLOR_TEXT_TABLE_LABEL,
        },
      },
    },
    yAxis: [],
  };

  return options;
}

export type GroupedCategoriesOptions = Omit<Options, "xAxis"> & {
  xAxis: {
    id: string;
    crosshair: boolean;
    labels: XAxisLabelsOptions;
    categories: { name: string; categories: string[] }[];
  };
};

export function generatePerformersPeriodChartOptions({
  widget,
  categories,
  chartMarginTop,
}: {
  widget: ChartWidgetModel;
  categories: GroupedCategoriesOptions["xAxis"]["categories"];
  chartMarginTop?: number;
}) {
  const countOfCategories = categories.length
    ? categories[0].categories?.length * categories.length
    : 0;

  const options: GroupedCategoriesOptions = {
    ...getDefaultOptions(widget, chartMarginTop),
    xAxis: {
      id: TimeLine.PERFORMERS,
      categories,
      crosshair: true,
      tickWidth: 0,
      labels: {
        // We use styling for grouped categories here which has not types
        // @ts-ignore
        groupedOptions: [
          {
            style: {
              fontWeight: "600",
              fontSize: "9px",
              color: COLOR_TEXT_TABLE_LABEL,
            },
            rotation: 0,
          },
        ],
        rotation:
          countOfCategories > BREAKPOINT_FOR_ROTATION_OF_X_AXIS
            ? countOfCategories > BREAKPOINT_FOR_MAX_ROTATION_OF_X_AXIS
              ? -80
              : -45
            : 0,
        style: {
          fontWeight: "600",
          fontSize: "9px",
          color: COLOR_TEXT_TABLE_LABEL,
        },
      },
    },
    yAxis: [],
  };

  return options;
}

export function generatePerformersChartOptions({
  widget,
  categories,
  chartMarginTop,
}: {
  widget: ChartWidgetModel;
  categories: string[];
  chartMarginTop?: number;
}) {
  const options: Options = {
    ...getDefaultOptions(widget, chartMarginTop),
    xAxis: {
      id: TimeLine.PERFORMERS,
      categories,
      crosshair: true,
      labels: {
        style: {
          fontWeight: "600",
          fontSize: "9px",
          color: COLOR_TEXT_TABLE_LABEL,
        },
      },
    },
    yAxis: [],
  };

  return options;
}

export function setColorsOption(options: Options | GroupedCategoriesOptions) {
  const seriesCount = options.series?.length ?? 0;

  const colors =
    seriesCount <= 2
      ? colors2
      : seriesCount <= 3
        ? colors3
        : seriesCount <= 5
          ? colors5
          : seriesCount <= 8
            ? colors8
            : colors16;

  return { ...options, colors };
}
