import React, { ReactNode } from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../colors";
import { Loader } from "../Loader";

import { ButtonType, ButtonVariant, ButtonColor } from "./types";
import { getButtonColor, getButtonDimension } from "./utils";

type ButtonProps = {
  bgColor?: string;
  borderColor?: string;
  colorVariant?: ButtonColor;
  fullWidth?: boolean;
  height?: number;
  icon?: ReactNode;
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
  outlined?: boolean;
  sizeType?: ButtonType;
  type?: ButtonVariant;
};

const StyledButton = styled.button<{
  colorVariant?: ButtonColor;
  disabled?: boolean;
  fullWidth?: boolean;
  hasIcon?: boolean;
  hasLoader?: boolean;
  height?: number;
  outlined?: boolean;
  sizeType?: ButtonType;
}>`
  position: relative;
  align-items: center;
  background-color: ${({ colorVariant, outlined }) =>
    outlined ? COLOR_WHITE : getButtonColor(colorVariant).color};
  border-radius: ${({ sizeType }) => getButtonDimension(sizeType).borderRadius};
  border-style: solid;
  box-sizing: border-box;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  border-color: ${({ colorVariant, outlined }) =>
    outlined ? getButtonColor(colorVariant).colorOutline : "transparent"};
  border-width: ${({ outlined }) => (outlined ? "1px" : "none")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: flex;
  justify-content: center;
  user-select: none;
  padding: ${({ hasIcon, hasLoader, sizeType }) =>
    hasIcon || hasLoader ? "0 7px" : getButtonDimension(sizeType).padding};
  height: ${({ sizeType, height }) =>
    height ? `${height}px` : getButtonDimension(sizeType).height};
  opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  outline: ${({ outlined }) => (outlined ? `1px solid transparent` : "none")};
  transition:
    border-width 0.3s ease,
    border-color 0.3s ease;
  &:hover {
    border-color: ${({ colorVariant, outlined }) =>
      outlined ? getButtonColor(colorVariant).color : "transparent"};
    outline: ${({ colorVariant, outlined, disabled }) =>
      outlined && !disabled
        ? `1px solid ${getButtonColor(colorVariant).color}`
        : "none"};

    background-color: ${({ disabled, colorVariant, outlined }) => {
      if (outlined) {
        return "transparent";
      }

      return disabled
        ? getButtonColor(colorVariant).color
        : getButtonColor(colorVariant).colorHover;
    }};
  }
  &:active > span {
    color: ${({ colorVariant }) =>
      colorVariant === ButtonColor.Light && COLOR_WHITE};
  }
  &:active {
    outline: ${({ colorVariant, disabled, outlined }) =>
      outlined && !disabled
        ? `1px solid ${getButtonColor(colorVariant).color}`
        : "none"};

    background-color: ${({ colorVariant, disabled, outlined }) => {
      if (outlined) {
        return disabled
          ? "transparent"
          : getButtonColor(colorVariant).bgColorOutline;
      }

      return disabled
        ? getButtonColor(colorVariant).color
        : getButtonColor(colorVariant).colorActive;
    }};
  }
  &:focus {
    outline-width: 4px;
    outline-style: solid;
    outline-color: ${COLOR_BLUE}40;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;

const Label = styled.span<{
  colorVariant?: ButtonColor;
  hasIcon?: boolean;
  hasLoader?: boolean;
  outlined?: boolean;
  sizeType?: ButtonType;
}>`
  color: ${({ colorVariant, outlined }) =>
    colorVariant === ButtonColor.Light || outlined
      ? getButtonColor(colorVariant).textColor
      : COLOR_WHITE};
  font-size: ${({ sizeType }) => getButtonDimension(sizeType).fontSize};
  font-weight: 700;
  text-transform: uppercase;
  text-wrap: nowrap;
  padding: ${({ hasIcon, hasLoader }) =>
    hasIcon || hasLoader ? "0 10px" : "0"};
`;

export const GeneralButton = ({
  colorVariant,
  fullWidth,
  height,
  icon,
  isDisabled,
  isLoading,
  label,
  onClick,
  outlined = false,
  sizeType,
  type = ButtonVariant.Button,
  ...rest
}: ButtonProps) => {
  return (
    <StyledButton
      colorVariant={colorVariant}
      disabled={isDisabled || isLoading}
      fullWidth={fullWidth}
      height={height}
      hasIcon={!!icon}
      hasLoader={isLoading}
      onClick={onClick}
      outlined={outlined}
      sizeType={sizeType}
      type={type}
      {...rest}
    >
      {isLoading ? <Loader color={COLOR_WHITE} /> : null}
      {icon && !isLoading ? <IconWrapper>{icon}</IconWrapper> : null}
      <Label
        colorVariant={colorVariant}
        hasIcon={!!icon}
        hasLoader={isLoading}
        outlined={outlined}
        sizeType={sizeType}
      >
        {label}
      </Label>
    </StyledButton>
  );
};
