import React, { useMemo, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import styled from "styled-components";

import { COLOR_LIGHT_GRAY } from "../../colors";
import { getReportPeriodLabel } from "../../components/Report/ReportPeriodText";
import StyledText from "../../components/StyledText";
import {
  DEFAULT_ROW_HEIGHT,
  MAX_COLUMN_COUNT,
} from "../../constants/ReportGrid";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { AthleteProfileContextProvider } from "../../contexts/athleteProfile";
import { usePrintAthleteReportContext } from "../../contexts/Print/PrintAthleteReportProvider";
import { useReportsContext } from "../../contexts/Reports";
import { ReportWidgetTemplateHeader } from "../../graphql";
import { useDefaultDateFormat } from "../../hooks";
import { LandscapeOrientationStyleEnforcement } from "../LandscapeOrientationStyleEnforcement";
import { PrintReport } from "../PrintGroupReport";
import {
  ModalWrapper,
  Preview,
  PreviewSection,
  PreviewWrapper,
} from "../styled";

import { AthleteControlSection } from "./AthleteControlSection";
import { AthleteReportPrintHeader } from "./AthleteReportPrintHeader";

interface PrintAthleteReportModalProps {
  reportName: string;
  tabTemplates: any[];
  headerWidget: ReportWidgetTemplateHeader;
}

const ReportTitle = styled(StyledText)`
  display: flex;
  padding-left: 30px;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 26px;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_LIGHT_GRAY};
  border-bottom-style: solid;
`;

export const PrintAthleteReportModal = ({
  reportName,
  tabTemplates,
  headerWidget,
}: PrintAthleteReportModalProps) => {
  const printRef = useRef<any>();

  const { selectedAccount } = useAccessLevelsContext();
  const [{ selectedPeriod, selectedStartDate, selectedEndDate }] =
    useReportsContext();
  const { tabIDs, experimentalGridFlag, landscapeOrientation } =
    usePrintAthleteReportContext();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const dateFormats = useDefaultDateFormat();

  const reports = useMemo(() => {
    const pickedTabs = tabIDs.map((tabID) =>
      tabTemplates.find((template) => template.id === tabID)
    );

    return pickedTabs
      .filter((tabReport) => Boolean(tabReport))
      .map((tabReport) => {
        const layout = JSON.parse(tabReport.preferences).layout.filter(
          (layoutItem) => !layoutItem.i.includes("placeholder")
        );

        const widgets = (tabReport?.widgets || [])
          .map((widget) => {
            const widgetPrefs = JSON.parse(widget.preferences);
            const foundWidgetLayout = layout.find((wl) => wl.i === widget.id);

            const widgetLayout = {
              printWidth: foundWidgetLayout?.w
                ? `${(foundWidgetLayout.w / MAX_COLUMN_COUNT) * 100}%`
                : `${(1 / MAX_COLUMN_COUNT) * 100}%`,
              printHeight: foundWidgetLayout?.h
                ? `${foundWidgetLayout.h * DEFAULT_ROW_HEIGHT}px`
                : `${DEFAULT_ROW_HEIGHT}px`,
              y: foundWidgetLayout?.y,
              x: foundWidgetLayout?.x,
            };

            return {
              ...widget,
              ...widgetPrefs,
              ...widgetLayout,
            };
          })
          .sort((first, second) => first.y - second.y);

        return {
          widgets,
          title: tabReport?.name,
        };
      });
  }, [tabIDs, tabTemplates]);

  return (
    <ModalWrapper>
      <AthleteProfileContextProvider>
        <PreviewSection>
          <PreviewWrapper landscapeOrientation={landscapeOrientation}>
            <Preview ref={printRef}>
              <LandscapeOrientationStyleEnforcement
                landscapeOrientation={landscapeOrientation}
              />
              <AthleteReportPrintHeader
                title={reportName}
                period={getReportPeriodLabel({
                  selectedPeriod,
                  selectedEndDate,
                  selectedStartDate,
                  ...dateFormats,
                })}
                accountLogo={selectedAccount?.img}
                headerWidget={headerWidget}
              />
              {reports.map((report) => (
                <>
                  <ReportTitle>{report.title}</ReportTitle>
                  <PrintReport
                    filteredWidgets={report.widgets}
                    experimentalGrid={experimentalGridFlag}
                    landscapeOrientation={landscapeOrientation}
                    isAthlete
                  />
                </>
              ))}
            </Preview>
          </PreviewWrapper>
        </PreviewSection>
        <AthleteControlSection
          onPrint={handlePrint}
          headerWidget={headerWidget}
        />
      </AthleteProfileContextProvider>
    </ModalWrapper>
  );
};
