import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import { AggregateFunction } from "../../../graphql";
import { useReportBuildingCollections } from "../../../hooks";
import { PeriodType } from "../../../models";
import { isInformationCollection } from "../../../utils/reports";
import { ConnectedFocusError } from "../../FormikFocusError";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { DragDropContainer } from "../../Wrappers/DragDropContainer";
import { PeriodSection } from "../PeriodSection";
import { PreviewButtonSection } from "../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../styled";

import { BodyChartAppearanceSection } from "./BodyChartAppearanceSection";
import { BodyChartEditorTitleSection } from "./BodyChartEditorTitleSection";
import { BodyChartSection } from "./BodyChartSection";
import { BodyChartWidgetPreview } from "./BodyChartWidgetPreview";
import { BodyRowSection } from "./BodyRowSection";
import { BodyChartWidgetModel, CreateBodyChartFormValue } from "./model";

export type BodyChartEditorProps = {
  widget: BodyChartWidgetModel;
  isSavingWidget: boolean;
  onSubmit: (values: CreateBodyChartFormValue) => void;
  onCancel: () => void;
};

export function BodyChartEditor({
  widget,
  isSavingWidget,
  onSubmit,
  onCancel,
}: BodyChartEditorProps) {
  const { t } = useTranslation();

  const { data } = useReportBuildingCollections();

  const idOfInformationsCollection = useMemo(
    () =>
      data?.reportBuildingCollections?.find((collection) =>
        isInformationCollection(collection.id)
      )?.id,
    [data]
  );

  const initialValues: CreateBodyChartFormValue = useMemo(() => {
    const rows = widget?.informationTemplates?.map(({ id, name }) => {
      return {
        collectionId: idOfInformationsCollection,
        measurementTemplateId: id,
        measurementTemplateName: name,
      };
    });

    return {
      id: widget?.id || "",
      name: widget?.name ?? "",
      aggregation: widget?.aggregation || AggregateFunction.Latest,
      widgetBorders: widget?.widgetBorders || false,
      period: {
        type: widget?.period?.type || PeriodType.SELECTION,
        from: widget?.period?.from || null,
        to: widget?.period?.to || null,
        isTodaySetAsEnd: widget?.period?.isTodaySetAsEnd || false,
      },
      bodyChartTemplateID: widget?.bodyChartTemplate?.templateGuid,
      rows: rows || [],
    };
  }, [widget, idOfInformationsCollection]);

  return (
    <Formik<CreateBodyChartFormValue>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, submitForm, setFieldValue }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />
            <BodyChartEditorTitleSection
              name="name"
              values={values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <BodyChartSection />
            <Section>
              <SectionTitle title={t("information")} />
              <DragDropContainer
                fieldArrayName="rows"
                buttonText={t("addRow")}
                items={values?.rows?.map(
                  (row, index) =>
                    ({ remove, dragHandleProps }) => (
                      <BodyRowSection
                        key={`${row.collectionId}-${row.measurementTemplateId}-${index}`}
                        rowIndex={index}
                        remove={remove}
                        dragHandleProps={dragHandleProps}
                      />
                    )
                )}
              />
            </Section>
            <BodyChartAppearanceSection
              value={values.widgetBorders}
              setFieldValue={setFieldValue}
            />
            <PeriodSection
              setFieldValue={setFieldValue}
              period={values.period}
              withDynamicSelection
              showCalendarAboveInput={false}
              sectionZIndex={100}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <BodyChartWidgetPreview widget={values} />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
}
