import styled from "styled-components";

export const ColumnOfRowWrapper = styled.div<{
  width?: number;
  leftMargin?: boolean;
  rightMargin?: boolean;
  flexColumn?: boolean;
  justifyContent?: string;
  maxWidth?: number;
  withoutFlex?: boolean;
}>`
  display: ${({ withoutFlex }) => (withoutFlex ? "block" : "flex")};
  width: ${({ width }) => (width ? `${width}%` : "auto")};
  margin-left: ${({ leftMargin = false }) => (leftMargin ? "10px" : 0)};
  margin-right: ${({ rightMargin = false }) => (rightMargin ? "10px" : 0)};
  flex-direction: ${({ flexColumn }) => (flexColumn ? "column" : "row")};
  justify-content: ${({ justifyContent = "unset" }) => justifyContent};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "")};
`;
