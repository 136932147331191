import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";

import { useApolloClient } from "@apollo/client";

import { useUIContext } from "../contexts/UI";
import { useUserContext } from "../contexts/User";
import {
  MessageList2Document,
  MessageOverviewPaginatedDocument,
  UnreadMessageCountDocument,
} from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { getServerBasePath } from "../utils/getServerBasePath";

export const useMessagingWebSocket = () => {
  const { sessionId } = useUserContext();
  const { pathname, search } = useLocation();
  const { embed } = useUIContext();
  const client = useApolloClient();

  const params = new URLSearchParams(search);
  const idFromUrlSearch = params.get("id");
  const socketUrl = `${getServerBasePath().replace(
    "https",
    "wss"
  )}/websockets/events/?sessionId=${sessionId}`;

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket<{
    msgType: string;
    chatter: string;
  }>(socketUrl, {
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (readyState === ReadyState.OPEN && sessionId) {
      sendMessage("AddChatListener{}");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  if (lastJsonMessage?.msgType === "RefreshChat") {
    client.refetchQueries({
      include: [MessageOverviewPaginatedDocument],
    });

    // Refetch MessageList only if message arrived to current chat
    if (
      matchPath(ROUTING_CONFIG.messages, pathname) &&
      lastJsonMessage.chatter === idFromUrlSearch
    ) {
      client.refetchQueries({
        include: [MessageList2Document],
      });
    }

    // Refetch info for unread messsage count only if we are not in embed version of app
    if (!embed) {
      client.refetchQueries({
        include: [UnreadMessageCountDocument],
      });
    }
  }

  return null;
};
