import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CheckmarkAwayIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill="#E6E8EB" />
      <path d="M7 14L14 7" stroke="#3C434C" strokeWidth="1.5" />
      <path d="M7 7L14 14" stroke="#3C434C" strokeWidth="1.5" />
    </svg>
  )
);
