import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { getSelectedResourceskey } from "../../constants";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { useUserContext } from "../../contexts/User";
import { usePutPreferenceMutation } from "../../graphql";
import { Resource } from "../Calendar/model";

import { AvailableResourceList } from "./DraggableModal/AvailableResourceList";
import { DraggableItem } from "./DraggableModal/DraggableItem";
import { EmptyList } from "./DraggableModal/EmptyList";
import { SearchBar } from "./DraggableModal/SearchBar";
import {
  ContentContainer,
  SectionTitle,
  SelectedSectionWrapper,
} from "./DraggableModal/styled";

type ResourceModalProps = {
  resourceList: Resource[];
  setResourceList: Dispatch<SetStateAction<Resource[]>>;
  onSave: () => void;
};

export function ResourceModal({
  resourceList,
  setResourceList,
  onSave,
}: ResourceModalProps) {
  const { t } = useTranslation();
  const { sessionId, id: userId } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();

  const [setPreference] = usePutPreferenceMutation();

  const [searchValue, setSearchValue] = useState("");
  const [localResourceList, setLocalResourceList] =
    useState<Resource[]>(resourceList);

  useEffect(() => {
    setPreference({
      variables: {
        sessionId,
        accountId: selectedAccount?.id,
        key: getSelectedResourceskey({ userId }),
        value: JSON.stringify(localResourceList),
      },
      onCompleted() {
        onSave?.();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localResourceList]);

  const onOrderChange = useCallback(
    (result: DropResult) => {
      const localResourceListCopy = Array.from(localResourceList);
      const [reorderedItem] = localResourceListCopy.splice(
        result.source.index,
        1
      );
      localResourceListCopy.splice(result.destination.index, 0, reorderedItem);

      setResourceList(localResourceListCopy);
      setLocalResourceList(localResourceListCopy);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localResourceList]
  );

  const onRemoveResource = useCallback(
    ({ resourceId }: Resource) => {
      const localResourceListCopy = Array.from(localResourceList);
      const filteredResourceList = localResourceListCopy?.filter(
        (item) => item.resourceId !== resourceId
      );

      setResourceList(filteredResourceList);
      setLocalResourceList(filteredResourceList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localResourceList]
  );

  const onAddResource = useCallback(
    (resource: Resource) => {
      const updatedResourceList = [...localResourceList, resource];

      setResourceList(updatedResourceList);
      setLocalResourceList(updatedResourceList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localResourceList]
  );

  return (
    <DragDropContext onDragEnd={onOrderChange}>
      <ContentContainer>
        <SelectedSectionWrapper>
          <SectionTitle>{t("visible")}</SectionTitle>
          {!localResourceList?.length && (
            <EmptyList label={t("clickResourcesBelowToAdd")} />
          )}
          <Droppable droppableId="resourceList">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {localResourceList?.map((resource, index) => (
                  <DraggableItem
                    key={resource.resourceId}
                    onRemove={onRemoveResource}
                    resource={resource}
                    index={index}
                  />
                ))}
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </SelectedSectionWrapper>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        <SectionTitle>{t("hidden")}</SectionTitle>
        <AvailableResourceList
          localResourceList={localResourceList}
          searchValue={searchValue}
          onAddResource={onAddResource}
        />
      </ContentContainer>
    </DragDropContext>
  );
}
