import React, { PropsWithChildren } from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../../colors";
import { useSelectedPerformersContext } from "../../../contexts/selectedAthletes";
import { Loader } from "../../Loader";

interface TabContainerProps {
  loading: boolean;
}

const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: calc(90vh - 256px);
  width: 100%;
`;

export const TabContainer = ({
  children,
  loading,
}: PropsWithChildren<TabContainerProps>) => {
  const { attendingTeams, attendingUsers } = useSelectedPerformersContext();

  const hasItems = attendingTeams.length || attendingUsers.length;

  return (
    <div
      style={{
        zIndex: 1,
        backgroundColor: COLOR_WHITE,
      }}
    >
      {loading ? (
        <LoaderWrapper>
          <Loader size="small" color={COLOR_BLUE} />
        </LoaderWrapper>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: 20,
            paddingBottom: 20,
            width: "100%",
            overflow: "auto",
            height: `calc(90vh - ${hasItems ? 419 : 305}px)`,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
