import React from "react";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_BLUE_HOVER, COLOR_WHITE } from "../../../../colors";
import {
  ChartBarIcon,
  ChartColumnSmallIcon,
  ChartLineSmallIcon,
} from "../../../Icons";
import { ChartType, ChartWidgetModel } from "../model";

const CharButton = styled.button<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
  height: 25px;
  width: 25px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  padding: 0;
  display: flex;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: ${({ isSelected }) =>
      isSelected ? COLOR_BLUE_HOVER : COLOR_WHITE};
  }
`;

interface ChartIconButtonProps {
  index: number;
  currentChartTypeOverride: ChartType;
  chartTypeOverride: ChartType;
}

function getChartIcon(type: ChartType, isActive: boolean) {
  switch (type) {
    case ChartType.COLUMN:
      return <ChartColumnSmallIcon isActive={isActive} />;
    case ChartType.LINE:
      return <ChartLineSmallIcon isActive={isActive} />;
    case ChartType.BAR:
      return <ChartBarIcon width={13} height={13} isActive={isActive} />;
  }
}

export const ChartIconButton = ({
  index,
  currentChartTypeOverride,
  chartTypeOverride,
}: ChartIconButtonProps) => {
  const { setFieldValue } = useFormikContext<ChartWidgetModel>();

  const isSelected = currentChartTypeOverride === chartTypeOverride;

  const onClick = () =>
    setFieldValue(
      `items.${index}.chartTypeOverride`,
      isSelected ? undefined : chartTypeOverride
    );

  return (
    <CharButton isSelected={isSelected} onClick={onClick}>
      {getChartIcon(chartTypeOverride, isSelected)}
    </CharButton>
  );
};
