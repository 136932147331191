import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useNewChatContext } from "../../contexts/messaging/NewChatProvider";
import { GetCreateNewChatGroupsQuery } from "../../graphql";
import { Checkbox } from "../Checkbox";
import { ControlBarTeamChat } from "../Modals/ChatModals/ControlBarTeamChat";

import { PostRestriction } from "./components/PostRestriction";

interface CreateTeamChatStage2ModalBodyProps {
  users: any[];
  groupName: string;
  groupsData?: GetCreateNewChatGroupsQuery["getTheGroupsImIn"];
  setGroupName: React.Dispatch<React.SetStateAction<string>>;
}

const WhoCanPostWrapper = styled.div`
  margin-top: 30px;
`;

export const CreateTeamChatStage2ModalBody = ({
  groupsData,
  groupName,
  setGroupName,
}: CreateTeamChatStage2ModalBodyProps) => {
  const { t } = useTranslation();
  const {
    autoAddAthletes,
    autoAddTrainers,
    autoAddFamily,
    whoCanPost,
    setWhoCanPost,
    setAutoAddFamily,
    setAutoAddAthletes,
    setAutoAddTrainers,
    hasFamilyToChat,
  } = useNewChatContext();

  return (
    <>
      <ControlBarTeamChat
        groupsData={groupsData}
        groupName={groupName}
        setGroupName={setGroupName}
      />

      <div>
        <Checkbox
          text={t("coaches")}
          check={setAutoAddTrainers}
          checked={autoAddTrainers}
        />
        <Checkbox
          text={t("athletes")}
          check={setAutoAddAthletes}
          checked={autoAddAthletes}
        />
        {hasFamilyToChat ? (
          <Checkbox
            text={t("familyMembers")}
            check={setAutoAddFamily}
            checked={autoAddFamily}
          />
        ) : null}
      </div>

      <WhoCanPostWrapper>
        <PostRestriction
          whoCanPost={whoCanPost}
          onWhoCanPostUpdate={setWhoCanPost}
        />
      </WhoCanPostWrapper>
    </>
  );
};
