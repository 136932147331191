import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../colors";

export enum Direction {
  Up = "up",
  Down = "down",
  Left = "left",
  Right = "right",
}

export const SortIconWrapper = styled.div<{
  height?: number;
  isIconVisible: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: ${({ height }) => `${height}px` || "16px"};
  visibility: ${({ isIconVisible }) => (isIconVisible ? "visible" : "hidden")};
`;

export const Arrow = styled.div<{ direction?: Direction }>`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: ${({ direction }) =>
    direction === Direction.Down ? 0 : `4px solid ${COLOR_TEXT_TABLE_LABEL}`};
  border-top: ${({ direction }) =>
    direction === Direction.Down ? `4px solid ${COLOR_TEXT_TABLE_LABEL}` : 0};
`;
