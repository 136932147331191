import React from "react";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import StyledText from "../StyledText";

type CommentItemProps = {
  comment: string;
};

const CommentWrapper = styled.div`
  padding: 5px;
  margin: 15px;
`;

export function CommentItem({ comment }: CommentItemProps) {
  return (
    <CommentWrapper>
      <StyledText regular fontSize={12} color={COLOR_MEDIUM_GRAY}>
        {comment}
      </StyledText>
    </CommentWrapper>
  );
}
