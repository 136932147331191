import { useLayoutEffect } from "react";

// We need to enforce reverse z-index stacking due to dropdown for event edit
export const useSetZIndexesForCalendarRowsEffect = () => {
  useLayoutEffect(() => {
    const elements = document.getElementsByClassName("rbc-month-row");

    for (let index = 0; index < elements.length; index++) {
      const element = elements.item(index);

      (element as HTMLDivElement).style.zIndex = String(100 - index);
    }
  }, []);
};
