import React from "react";

import { Dayjs } from "dayjs";

import { ReportPeriodSingleCalendar } from "./ReportPeriodSingleCalendar";

export function ReportSingleDayCalendar({
  selectedSingleDay,
  setSingleDay,
}: {
  selectedSingleDay: Dayjs;
  setSingleDay(date): void;
}) {
  return (
    <ReportPeriodSingleCalendar
      selectedStartDate={selectedSingleDay}
      selectedEndDate={selectedSingleDay}
      selectedPeriod="day"
      setStartDate={setSingleDay}
    />
  );
}
