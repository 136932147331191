import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";
import {
  MobileModalTypes,
  useMobileModalContext,
} from "../contexts/mobileModal/MobileModalProvider";
import { useReportsContext } from "../contexts/Reports";

import { ChevronRightIcon } from "./Icons";

const Wrapper = styled.button`
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  padding: 0;
  width: 100%;
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLOR_TEXT_DARK};
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const InnerContainer = styled(FlexContainer)`
  padding: 10px 15px;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(60, 67, 76, 0.04);
  align-items: center;
`;

const OuterContainer = styled.div`
  margin-right: 20px;
  width: 100%;
`;

export const MobileGroupSelector = () => {
  const { t } = useTranslation();
  const { showModal } = useMobileModalContext();
  const [{ selectedGroup }] = useReportsContext();

  return (
    <OuterContainer>
      <Wrapper onClick={() => showModal(MobileModalTypes.GROUP_PICKER)}>
        <InnerContainer>
          <FlexContainer>
            <Title>{selectedGroup?.name ?? t("selectTeam")}</Title>
          </FlexContainer>
          <ChevronRightIcon tintColor={COLOR_BLUE} direction="bottom" />
        </InnerContainer>
      </Wrapper>
    </OuterContainer>
  );
};
