import { Event, Game, InaccessibleSession, Practice } from "../../graphql";

import { CustomResourceEvent } from "./model";

export function getModifiedEvent(
  data: Event[] | Practice[] | Game[] | InaccessibleSession[]
): CustomResourceEvent[] {
  return (
    data?.flatMap((item) => {
      const {
        start,
        id,
        label,
        resourcesUsage,
        durationMinutes,
        rgb,
        location,
        xtraLocation,
        notes,
        resourceCalendarDescription,
        owner,
        ownerShortName,
        cancelled,
        attendance,
        __typename,
      } = item || {};
      const end = start + durationMinutes * 60000;

      const combinedLocation =
        location && xtraLocation && `${location} ${xtraLocation}`;

      const resourceUsageList = resourcesUsage.items?.length
        ? resourcesUsage.items?.map(({ resource }) => resource?.guid)
        : [];

      return resourceUsageList?.map((resourceId: string) => ({
        id: `${id}-${resourceId}`,
        eventId: id,
        title: label,
        start: new Date(start),
        end: new Date(end),
        resourceId,
        cancelled,
        attendance,
        resourceList: resourcesUsage?.items,
        color: rgb,
        location: combinedLocation,
        description: notes,
        resourceCalendarDescription,
        ownerShortName,
        isGame: __typename === "Game",
        type: __typename,
        selectedGroupId: owner,
      }));
    }) || []
  );
}

export function isTomorrow(date: Date): boolean {
  const today = new Date();
  const tomorrow = new Date(date);

  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);

  return today.valueOf() >= tomorrow.valueOf();
}
