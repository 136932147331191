import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { Group } from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import Avatar from "../Avatar";
import Card from "../Card";
import StyledText from "../StyledText";

const AthleteBaseInfoWrapper = styled.div`
  margin-left: 20px;
  justify-content: center;
`;

export const GroupMainCard = styled(Card)<{ noBottomMargin?: boolean }>`
  flex-direction: row;
  align-items: center;
  height: 65px;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? 0 : 5)}px;
  cursor: pointer;
`;

export const GroupListCard = ({
  group,
  hasSubGroup,
  isLast,
}: {
  group: Group;
  hasSubGroup: boolean;
  isLast?: boolean;
}) => {
  const navigate = useNavigate();
  const displayedGroupName = `${group.owner?.name || ""} - ${group.name}`;

  return (
    <div
      className={hasSubGroup ? "groupListCardHasSubGroup" : ""}
      onClick={() =>
        navigate(`/${ROUTING_CONFIG.AthleteTeam}`, { state: group })
      }
    >
      <GroupMainCard noBottomMargin={isLast}>
        <Avatar
          userGuid={group?.owner?.id}
          isThumb={true}
          size={45}
          // no need for first/lastName - group
          userName={displayedGroupName}
        />
        <AthleteBaseInfoWrapper>
          <StyledText fontSize={16} fontWeight="bold">
            {displayedGroupName}
          </StyledText>
        </AthleteBaseInfoWrapper>
      </GroupMainCard>
    </div>
  );
};
