export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }

  if (/windows phone/i.test(userAgent)) {
    return "windows-phone";
  }

  if (
    /Macintosh|MacIntel|MacPPC|Mac68K|Win32|Win64|Windows|WinCE|Linux|X11/.test(
      userAgent
    )
  ) {
    return "web";
  }

  return "web";
};
