import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAccessLevelsContext } from ".";

// Unfortunately, this is quite hacky as it reset override of account due to all accounts option
// We cannot really create new context field as it would require complex rework of api communication due to selectedAccount field
// Logic is - if you override selected account within module, it is resetted on module change
export const useAccessLevelsModuleOverrideReset = () => {
  const { resetLocalOverride } = useAccessLevelsContext();

  const location = useLocation();

  useEffect(() => {
    resetLocalOverride(location.pathname);
  }, [location.pathname, resetLocalOverride]);
};
