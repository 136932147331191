import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { hasAllVariables } from "@apollo-elements/lib/has-all-variables";

import appJson from "../../app.json";
import { getServerBasePath } from "../utils/getServerBasePath";

import generated, { ContactsConnection } from "./generated";

const appVersionSuffix =
  window?.location.hostname.includes("develop") ||
  window?.location.hostname.includes("localhost")
    ? "dev"
    : "";

export const APP_VERSION = `${appJson?.expo?.extra?.version}-${appVersionSuffix}`;

const loggerLink = new ApolloLink((operation, forward) => {
  __DEV__ && console.log(`starting request for ${operation.operationName}`);
  __DEV__ && console.log(operation.variables);

  if (forward) {
    return forward(operation).map((data) => {
      __DEV__ && console.log(`ending request for ${operation.operationName}`);
      return data;
    });
  } else {
    return null;
  }
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (__DEV__ && graphQLErrors) {
    console.log("[GraphQL error] ----");
    console.log(operation);
    graphQLErrors.map(({ message }) => {
      console.log(message);
    });
  }

  if (__DEV__ && networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const validateVariablesLink = new ApolloLink((operation, forward) => {
  if (hasAllVariables(operation)) {
    return forward(operation);
  } else {
    console.error(`Request ${operation.operationName} has missing variables`, {
      variables: operation.variables,
    });
    throw new ApolloError({
      errorMessage: `Request ${operation.operationName} has missing variables`,
    });
  }
});

type ClientParams = {
  sessionId: string | null;
  focusedAthlete: string | null;
};

const clientParams: ClientParams = {
  sessionId: null,
  focusedAthlete: null,
};

export const setClientParam = <K extends keyof ClientParams>(
  param: K,
  value: ClientParams[K]
) => {
  clientParams[param] = value;
};

export const getClientParam = <K extends keyof ClientParams>(
  param: K
): string | null => {
  return clientParams[param];
};

const httpLink = createHttpLink({
  async fetch(_uri, options) {
    const { sessionId, focusedAthlete } = clientParams;
    const { operationName } = JSON.parse(options.body as string);

    const url = getServerBasePath();

    const queryParams = new URLSearchParams({
      opname: operationName,
      operation: "graphql",
      version: APP_VERSION,
    });

    if (sessionId) {
      queryParams.set("sessionId", sessionId);
    }

    if (focusedAthlete) {
      queryParams.set("focusedAthlete", focusedAthlete);
    }

    try {
      return await fetch(`${url}/xpsweb?${queryParams.toString()}`, options);
    } catch (e) {
      if (e.message === "Network request failed") {
        return Promise.reject(new Error("No internet connection"));
      } else {
        return Promise.reject(e);
      }
    }
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    validateVariablesLink,
    loggerLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    possibleTypes: generated.possibleTypes,
    typePolicies: {
      ChattersOverview: {
        fields: {
          contactsPaginated: {
            keyArgs: (keys) => {
              return [];
            },
            merge(
              existing: ContactsConnection = {} as ContactsConnection,
              incoming: ContactsConnection
            ) {
              const realNewChatters =
                incoming.edges?.filter(
                  (edge) =>
                    !existing.edges?.some(
                      (existingEdge) => existingEdge.cursor === edge.cursor
                    )
                ) || [];

              const areAllItemsTheSame =
                incoming.edges?.every((edge) =>
                  existing.edges?.some(
                    (existingEdge) => existingEdge?.cursor === edge?.cursor
                  )
                ) && incoming.edges?.length > 0;

              // console.log("XXXXXX", {
              //   existing,
              //   incoming,
              //   realNewChatters,
              //   areAllItemsTheSame,
              //   result: {
              //     pageInfo: incoming?.pageInfo
              //       ? incoming.pageInfo
              //       : existing?.pageInfo,
              //     edges: areAllItemsTheSame
              //       ? [...(incoming?.edges || [])]
              //       : [...(existing?.edges || []), ...realNewChatters],
              //   },
              // });

              return {
                pageInfo: incoming?.pageInfo
                  ? incoming.pageInfo
                  : existing?.pageInfo,
                edges: areAllItemsTheSame
                  ? [...(incoming?.edges || [])]
                  : [...(existing?.edges || []), ...realNewChatters],
              };
            },
          },
        },
      },
    },
  }),
  connectToDevTools: __DEV__,
});

export * from "./generated";
