import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { ReportWidgetCellNumerical } from "../../../graphql";
import { isAttendanceCollection } from "../../../utils/reports";
import { TimeLine } from "../../Report/ChartWIdget/model";
import { ComplexHeaderValueWrapper } from "../../ReportTableWidget/GroupTableHeader";
import { StyledTh } from "../../ReportTableWidget/styled";
import { Arrow, Direction, SortIconWrapper } from "../../styled";

import { GrouppedColumnHeader } from "./getGrouppedColumnHeader";
import { HeaderTitleButton } from "./styled";
import { SortByType } from "./Table";

interface TablePeriodizationHeaderProps {
  grouppedColumnHeaders: GrouppedColumnHeader[];
  timeLine?: TimeLine;
  sortByConfig: SortByType;
  isGroup?: boolean;
  onHeaderTitlePress: (colIndex: number) => void;
  setHiddenHeaderHeight?: (height: number) => void;
}

const HeaderWrapper = styled(ComplexHeaderValueWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  width: 100%;
  min-width: 40px;
`;

const CustomStyledTh = styled(StyledTh)`
  font-size: 10px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

export function TablePeriodizationHeaderVertical({
  grouppedColumnHeaders,
  timeLine,
  sortByConfig,
  isGroup = false,
  onHeaderTitlePress,
  setHiddenHeaderHeight,
}: TablePeriodizationHeaderProps) {
  const { t } = useTranslation();
  const firstRowRef = useRef();

  const getShouldShowAggregationSelectorForCollection = (
    columnHeader: GrouppedColumnHeader
  ) =>
    (!isAttendanceCollection(columnHeader.attributes[0].collectionId) &&
      columnHeader.attributes.length > 1) ||
    columnHeader.attributes.some(
      (attribute) => attribute.aggregateFunctions.length !== 1
    );

  useEffect(() => {
    if (setHiddenHeaderHeight) {
      // @ts-ignore
      const firstHiddenHeight = firstRowRef?.current?.clientHeight ?? 0;

      setHiddenHeaderHeight(firstHiddenHeight);
    }
  }, [setHiddenHeaderHeight]);

  const directionIndicator =
    sortByConfig.order === 1 ? Direction.Down : Direction.Up;

  const itemsToDisplay = grouppedColumnHeaders.flatMap((columnHeader) => {
    if (isGroup) {
      return {
        templateNameTitle: columnHeader.attributes[0].name,
        currentAggregation: "",
        attributeNameTitle: "",
      };
    }

    const shouldShowAdditionalHeaderData =
      getShouldShowAggregationSelectorForCollection(columnHeader);

    if (columnHeader.attributes.length > 1) {
      return columnHeader.attributes.flatMap(
        (attribute: ReportWidgetCellNumerical) =>
          attribute.aggregateFunctions.flatMap((currentAggregation) => ({
            templateNameTitle: attribute.name,
            attributeNameTitle: shouldShowAdditionalHeaderData
              ? attribute.attributeTemplate?.name ??
                (
                  attribute as ReportWidgetCellNumerical & {
                    attributeTemplateName: string;
                  }
                ).attributeTemplateName
              : "",
            currentAggregation: shouldShowAdditionalHeaderData
              ? currentAggregation
              : "",
          }))
      );
    }

    return columnHeader.attributes[0].aggregateFunctions.flatMap(
      (currentAggregation) => ({
        templateNameTitle: columnHeader.attributes[0].name,
        attributeNameTitle: shouldShowAdditionalHeaderData
          ? (
              columnHeader.attributes[0] as ReportWidgetCellNumerical & {
                attributeTemplateName: string;
              }
            ).attributeTemplate?.name ??
            (
              columnHeader.attributes[0] as ReportWidgetCellNumerical & {
                attributeTemplateName: string;
              }
            ).attributeTemplateName
          : "",
        currentAggregation: shouldShowAdditionalHeaderData
          ? currentAggregation
          : "",
      })
    );
  });

  return (
    <>
      <tr ref={firstRowRef} style={{ zIndex: 10 }}>
        <CustomStyledTh key="th-placeholder"> {t("athlete")}</CustomStyledTh>

        {itemsToDisplay.map((item, index) => {
          const isSortIconVisible = sortByConfig.colIndex === index;
          const key = isGroup
            ? `${item.attributeNameTitle}-${item.templateNameTitle}-${index}`
            : `${item.attributeNameTitle}-${item.templateNameTitle}-${item.currentAggregation}-${index}`;

          return (
            <StyledTh key={key}>
              <HeaderTitleButton onClick={() => onHeaderTitlePress(index)}>
                <HeaderWrapper>
                  <span>{item.templateNameTitle}</span>
                  <span>{item.attributeNameTitle ?? ""}</span>
                  {timeLine !== TimeLine.CONTINUOUS && (
                    <span>{item.currentAggregation ?? ""}</span>
                  )}
                  {isSortIconVisible && (
                    <SortIconWrapper isIconVisible={isSortIconVisible}>
                      <Arrow direction={directionIndicator} />
                    </SortIconWrapper>
                  )}
                </HeaderWrapper>
              </HeaderTitleButton>
            </StyledTh>
          );
        })}
      </tr>
    </>
  );
}
