import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Loader } from "../components/Loader";
import { useUIContext } from "../contexts/UI";
import { useUserContext } from "../contexts/User";
import { useUserProfileQuery } from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../utils/createSearchParamsWithoutUndefinedValues";
import { getSearchParamsObject } from "../utils/getSearchParamsObject";

const ALLOWED_EMBEDED_SCREENS = [
  ROUTING_CONFIG.messages,
  ROUTING_CONFIG.ListReports,
  ROUTING_CONFIG.AthleteProfile,
  ROUTING_CONFIG.ResourceCalendar,
];

const getParams = (route: string, params: Record<string, string>) => {
  if (route === ROUTING_CONFIG.messages && !!params.id) {
    return { id: params.id };
  }

  if (
    route === ROUTING_CONFIG.AthleteProfile ||
    route === ROUTING_CONFIG.ListReports
  ) {
    const { sessionId, ...rest } = params;

    return rest;
  }

  return params;
};

export const EmbedScreen = () => {
  const user = useUserContext();
  const ui = useUIContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("sessionId");
  const route = searchParams.get("route");

  const { data, loading } = useUserProfileQuery({
    variables: {
      sessionId,
    },
    skip: !sessionId,
  });

  useEffect(() => {
    if (!ALLOWED_EMBEDED_SCREENS.includes(route) || !sessionId) {
      navigate(`/${ROUTING_CONFIG.login}`);
    }
  }, [navigate, route, sessionId]);

  useEffect(() => {
    if (data?.userProfile) {
      const defaultUser = data.userAccessLevels?.[0]?.user;

      user.actions.setUser({
        sessionId,
        ...data.userProfile,
        defaultUser,
      });

      ui.actions.setEmbed(true);
    }
  }, [
    data?.userAccessLevels,
    data?.userProfile,
    sessionId,
    ui.actions,
    user.actions,
  ]);

  useEffect(() => {
    if (ui.embed) {
      if (ALLOWED_EMBEDED_SCREENS.includes(route)) {
        const params = getSearchParamsObject(searchParams, [
          "route",
          "sessionId",
        ]);

        navigate({
          pathname: `/${route}`,
          search: createSearchParamsWithoutUndefinedValues({
            ...getParams(route, params),
          }),
        });
      } else {
        navigate(`/${ROUTING_CONFIG.login}`);
      }
    }
  }, [navigate, route, searchParams, ui.embed]);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading && <Loader />}
    </div>
  );
};
