import React, { useLayoutEffect, useRef, useState } from "react";
import { Event } from "react-big-calendar";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import styled from "styled-components";

import {
  COLOR_TEXT_DARK,
  COLOR_WHITE,
  COLOR_DOLPHIN,
  COLOR_BLUE,
  COLOR_BLUE_ATTENDANCE_BG,
  COLOR_GRAY,
  COLOR_SECONDARY_GREY,
} from "../../colors";
import { useDefaultDateFormat } from "../../hooks";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { CoachAttendanceContainer } from "../Agenda/components/attendance/CoachAttendanceContainer";

import { EventCoordinates } from "./CustomResourceCalendarEvent";

dayjs.extend(customParseFormat);

const SIDE_BAR_WIDTH = 310;
const TRIANGLE_WIDTH = 20;
const HEADER_HEIGHT = 180;

type Props = Event & {
  onUnselectedItem: () => void;
  eventHeight?: number;
  left?: number;
  eventCoordinates: EventCoordinates;
};

const ResourceText = styled.p`
  font-family: "Inter", sans-serif;
  margin: 0;
`;

const EventDetail = styled.div<{
  detailHeight?: number;
  detailWidth?: number;
  isEventPlacedLeft: boolean;
  isEventPlacedTop: boolean;
  hasOneColumn?: boolean;
  eventCoordinates?: EventCoordinates;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.25);
  border-radius: 12px;
  padding: 16px 20px;
  position: absolute;
  width: 320px;
  max-height: 320px;
  z-index: 9999;

  left: ${({
    isEventPlacedLeft,
    detailWidth,
    hasOneColumn,
    eventCoordinates,
  }) => {
    if (hasOneColumn) {
      return `${(eventCoordinates.width - detailWidth) / 2}px`;
    }
    return isEventPlacedLeft ? "unset" : `${-detailWidth}px`;
  }};

  right: ${({ isEventPlacedLeft, detailWidth, hasOneColumn }) =>
    isEventPlacedLeft && !hasOneColumn ? `${-detailWidth}px` : "unset"};

  top: ${({
    detailHeight,
    eventCoordinates,
    hasOneColumn,
    isEventPlacedTop,
  }) => {
    if (hasOneColumn) {
      return isEventPlacedTop
        ? `${eventCoordinates.height}px`
        : `-${detailHeight - TRIANGLE_WIDTH / 2}px`;
    }
    return isEventPlacedTop
      ? "0px"
      : `${(eventCoordinates.height - detailHeight) / 2}px`;
  }};
`;

const Triangle = styled.div<{
  detailHeight?: number;
  detailWidth?: number;
  isEventPlacedLeft?: boolean;
  isEventPlacedTop: boolean;
  hasOneColumn?: boolean;
  eventCoordinates?: EventCoordinates;
}>`
  width: ${TRIANGLE_WIDTH}px;
  height: ${TRIANGLE_WIDTH}px;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  transform: rotate(45deg);
  margin-top: -10px;
  position: absolute;

  right: ${({ isEventPlacedLeft, hasOneColumn, detailWidth }) => {
    if (hasOneColumn) {
      return `${(detailWidth - TRIANGLE_WIDTH) / 2}px`;
    }
    return isEventPlacedLeft ? "unset" : "-10px";
  }};

  left: ${({ isEventPlacedLeft, hasOneColumn }) =>
    isEventPlacedLeft && !hasOneColumn ? "-10px" : "unset"};

  top: ${({
    detailHeight,
    hasOneColumn,
    isEventPlacedTop,
    eventCoordinates,
  }) => {
    if (hasOneColumn) {
      return isEventPlacedTop ? "0px" : `${detailHeight}px`;
    }
    return detailHeight > eventCoordinates.height
      ? `${detailHeight / 2}px`
      : `${(detailHeight - TRIANGLE_WIDTH) / 2}px`;
  }};
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  width: 25px;
  height: 25px;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ColorIndicator = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ color }) => color};
  border: 1px solid rgba(60, 67, 76, 0.1);
  border-radius: 3px;
  width: 15px;
  height: 15px;
`;

const Title = styled(ResourceText)`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 15px;
  padding-right: 16px;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Separator = styled.div`
  display: flex;
  background-color: ${COLOR_SECONDARY_GREY};
  height: 15px;
  width: 2px;
  margin: 0 5px;
`;

const Date = styled(ResourceText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_DOLPHIN};
`;

const ResourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-bottom: 20px;
`;

const Resource = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_BLUE_ATTENDANCE_BG};
  padding: 3px 10px;
  margin-right: 10px;
  border-radius: 12px;
`;

const ResourceLabel = styled(ResourceText)`
  font-weight: 600;
  font-size: 9px;
  line-height: 12px;
  color: ${COLOR_BLUE};
`;

const AddressWrapper = styled(ResourceText)`
  margin-bottom: 20px;
`;

const Address = styled(ResourceText)`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_DOLPHIN};
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const Description = styled(ResourceText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_DOLPHIN};
`;

const AttendanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1000;
  margin-bottom: 15px;
`;

export function ResourceCalendarEventDetail({
  selectedEvent,
  eventCoordinates,
  hasOneColumn,
}: Props) {
  const eventDetail = useRef(null);
  const [detailHeight, setDetailHeight] = useState(0);
  const [detailWidth, setDetailWidth] = useState(0);
  const { dateFormat } = useDefaultDateFormat();

  const date = dayjs(selectedEvent.start).format(dateFormat);
  const startTime = dayjs(selectedEvent.start).format("HH:mm");
  const endTime = dayjs(selectedEvent.end).format("HH:mm");

  useLayoutEffect(() => {
    setDetailHeight(eventDetail?.current?.clientHeight);
    setDetailWidth(eventDetail?.current?.clientWidth);
  }, []);

  const isEventPlacedLeft =
    eventCoordinates &&
    detailWidth &&
    eventCoordinates?.boundingRect?.left - SIDE_BAR_WIDTH < detailWidth;

  const isEventPlacedTop =
    eventCoordinates &&
    detailHeight &&
    eventCoordinates?.boundingRect?.top - HEADER_HEIGHT < detailHeight;

  const color = selectedEvent?.color
    ? `rgb(${selectedEvent.color})`
    : COLOR_BLUE;

  return (
    <EventDetail
      ref={eventDetail}
      detailHeight={detailHeight}
      detailWidth={detailWidth}
      eventCoordinates={eventCoordinates}
      isEventPlacedLeft={isEventPlacedLeft}
      isEventPlacedTop={isEventPlacedTop}
      hasOneColumn={hasOneColumn}
    >
      <Triangle
        detailHeight={detailHeight}
        detailWidth={detailWidth}
        isEventPlacedLeft={isEventPlacedLeft}
        isEventPlacedTop={isEventPlacedTop}
        hasOneColumn={hasOneColumn}
        eventCoordinates={eventCoordinates}
      />
      <ColorWrapper>
        <ColorIndicator color={color} />
      </ColorWrapper>
      <Title>{selectedEvent.title}</Title>
      <DateWrapper>
        <Date>{date}</Date>
        <Separator />
        <Date>{`${startTime} - ${endTime}`}</Date>
      </DateWrapper>
      {selectedEvent.ownerShortName ? (
        <DescriptionWrapper>
          <Description>{selectedEvent.ownerShortName}</Description>
        </DescriptionWrapper>
      ) : null}
      <ResourceWrapper>
        {selectedEvent.resourceList?.map(({ resource }) => (
          <Resource key={resource.guid}>
            <ResourceLabel>{resource.name}</ResourceLabel>
          </Resource>
        ))}
      </ResourceWrapper>
      {selectedEvent.location && (
        <AddressWrapper>
          <Address>{selectedEvent.location}</Address>
        </AddressWrapper>
      )}
      {selectedEvent.description && (
        <DescriptionWrapper>
          <Description>{selectedEvent.description}</Description>
        </DescriptionWrapper>
      )}
      {isRealTrainer && selectedEvent?.type !== "InaccessibleSession" && (
        <AttendanceWrapper>
          <CoachAttendanceContainer attendance={selectedEvent?.attendance} />
        </AttendanceWrapper>
      )}
    </EventDetail>
  );
}
