import React, { ReactNode } from "react";

import styled from "styled-components";

import {
  COLOR_BUTTON_BLUE,
  COLOR_BUTTON_SPECIAL_OUTLINE,
  COLOR_BUTTON_SPECIAL_OUTLINE_BG,
  COLOR_WHITE,
} from "../../colors";

import { GeneralButton } from "./GeneralButton";
import { ButtonColor } from "./types";

const StyledButton = styled(GeneralButton)<{ isDisabled?: boolean }>`
  border-color: ${COLOR_BUTTON_SPECIAL_OUTLINE};

  &:hover {
    background-color: ${COLOR_BUTTON_SPECIAL_OUTLINE_BG};
    border-color: ${COLOR_BUTTON_SPECIAL_OUTLINE};
    outline: 1px solid ${COLOR_BUTTON_SPECIAL_OUTLINE};
  }

  &:active {
    background-color: ${({ isDisabled }) => !isDisabled && COLOR_BUTTON_BLUE};
    border: ${({ isDisabled }) => !isDisabled && "none"};
    outline: ${({ isDisabled }) => !isDisabled && "none"};

    & > span {
      color: ${({ isDisabled }) => !isDisabled && COLOR_WHITE};
    }

    & .highlight > path {
      stroke: ${({ isDisabled }) => !isDisabled && COLOR_WHITE};
    }
  }

  & > span {
    font-weight: 600;
  }
`;

type MessageButtonProps = {
  label: string;
  icon?: ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
};

export function MessageButton({
  icon,
  isDisabled,
  label,
  onClick,
}: MessageButtonProps) {
  return (
    <StyledButton
      colorVariant={ButtonColor.Gray}
      height={45}
      icon={icon}
      isDisabled={isDisabled}
      label={label}
      onClick={onClick}
      outlined
    />
  );
}
