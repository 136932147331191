import React from "react";
import { Navigate } from "react-router-dom";

import { useUserContext } from "../contexts/User";
import { isRealTrainer } from "../utils/isRealTrainer";

export function TrainerProtectedRoute({ children, redirectTo }) {
  const user = useUserContext();
  const isUserRealTrainer = isRealTrainer(user);
  return isUserRealTrainer ? children : <Navigate to={redirectTo} />;
}
