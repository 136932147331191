import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNotificationsContext } from "../../../contexts/notifications";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  ReportDocument,
  useDeleteWidgetMutation,
  useWidgetTemplatesQuery,
  WidgetTemplatesDocument,
} from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { ConfirmProps } from "../../Modals/ConfirmationModal";
import { WidgetMode } from "../WidgetWithData";

export const useDeleteWidgetWarning = ({
  widgetId,
  withRefetchQueries,
  reportId,
  mode,
  ownCallBackAction,
}: {
  widgetId: string;
  withRefetchQueries?: boolean;
  reportId?: string;
  mode?: WidgetMode;
  ownCallBackAction?: () => void;
}) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const modal = useModalContext();

  const [deleteWidget] = useDeleteWidgetMutation();

  const { refetch: refetchWidgets } = useWidgetTemplatesQuery({
    variables: {
      sessionId,
      accountId: selectedAccountId,
      language,
    },
  });

  const handleDeleteWidget = useCallback(async () => {
    try {
      await deleteWidget({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          widgetId,
        },
        refetchQueries: withRefetchQueries
          ? [
              mode === WidgetMode.ATHLETE
                ? {
                    query: WidgetTemplatesDocument,
                    variables: {
                      sessionId,
                      accountId: selectedAccountId,
                      language,
                      parentReport: reportId,
                    },
                  }
                : {
                    query: ReportDocument,
                    variables: {
                      sessionId,
                      reportId,
                    },
                  },
            ]
          : undefined,
      });
      if (ownCallBackAction) {
        ownCallBackAction();
      } else {
        refetchWidgets();
      }
      showSuccessNotification(t("deleteWidgetSuccess"));
    } catch {
      showErrorNotification(t("deleteWidgetError"));
    }
  }, [
    deleteWidget,
    language,
    mode,
    ownCallBackAction,
    refetchWidgets,
    reportId,
    selectedAccountId,
    sessionId,
    showErrorNotification,
    showSuccessNotification,
    t,
    widgetId,
    withRefetchQueries,
  ]);

  return useCallback(() => {
    const params: Omit<ConfirmProps, "closeModal"> = {
      content: t("widgetDeleteConfirm"),
      onOK: () => handleDeleteWidget(),
      okButtonText: t("delete"),
      cancelButtonText: t("cancel"),
    };

    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title: t("deleteWidget"),
      params,
    });
  }, [modal.actions, t, handleDeleteWidget]);
};
