import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_GRAY, COLOR_MEDIUM_GRAY } from "../../colors";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { GetGroupQuery, useFamilyMembersQuery, XpsUser } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { sortList } from "../../utils/sortList";
import { CoachAttendanceItem } from "../Agenda/components/attendance/CoachAttendanceItem";
import { BaseUserListCard } from "../Athletes/BaseUserListCard";
import {
  AttendanceCheckmarkIcon,
  AttendanceCrossIcon,
  AttendanceQuestionMarkIcon,
} from "../Icons";
import StyledText from "../StyledText";

export type XpsUserWithParticipates = XpsUser & {
  participates?: boolean | null;
};

type MembersListProps = {
  trainers: XpsUser[];
  athletes: XpsUserWithParticipates[];
  hideAdditionalInfo?: boolean;
  showMembersAttendance?: boolean;
  group?: GetGroupQuery["getGroup"];
  forTeamDetail?: boolean;
};

const BodyContainer = styled.div`
  padding: 0 20px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${COLOR_GRAY};
`;

const SectionTitleWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AthleteListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AttendanceWrapper = styled.div`
  display: flex;
`;

const SendGroupMessageWrapper = styled.div`
  cursor: pointer;
`;

const Title = styled(StyledText)`
  margin: 20px 0 10px 0;
`;

export const MembersList = ({
  trainers,
  athletes,
  hideAdditionalInfo = false,
  showMembersAttendance = false,
  group,
  forTeamDetail = false,
}: MembersListProps) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const { sessionId, timezone, language } = user;
  const modal = useTopModalContext();

  const { data } = useFamilyMembersQuery({
    variables: { sessionId, timezone, language },
    skip: !user?.isFamilyMember,
  });

  const sortedTrainers = sortList(trainers, language);
  const sortedAthletes = sortList(athletes, language);

  const trainersToSendGroupMessage = sortedTrainers?.filter(
    (trainer) => trainer?.id !== user.id
  );

  const shouldShowSendGroupMessage =
    trainersToSendGroupMessage?.length > 0 &&
    ((isRealTrainer(user) && forTeamDetail) || user?.isFamilyMember);

  const handleSendGroupMessage = () => {
    const myAttendingFamilyIds = data?.UserProfile?.family?.membersOfFamily
      ?.filter((member) =>
        athletes?.some((athlete) => athlete?.id === member?.id)
      )
      ?.map((fm) => fm?.id);

    const otherAthletes = sortedAthletes?.filter(
      (athlete) => !myAttendingFamilyIds?.includes(athlete?.id)
    );

    const otherAthletesFamilyMembers = Object.values(
      otherAthletes.reduce(
        (acc, athlete) => {
          // go through each family member of each athlete and add into the record (if they aren't already there)
          athlete?.profile?.family?.membersOfFamily?.forEach(
            (member: XpsUser) => {
              if (member.id && !acc[member.id]) {
                acc[member.id] = member;
              }
            }
          );

          return acc;
        },
        {} as Record<string, XpsUser>
      )
    );

    modal.actions.openTopModal({
      modal: TopModalType.CREATE_CHAT_GROUP,
      title: t("createChatGroup"),
      params: {
        coaches: trainersToSendGroupMessage,
        groupName: group?.name ?? "",
        familyMembers: user?.isFamilyMember ? otherAthletesFamilyMembers : [],
        shouldShowSwitcher: user?.isFamilyMember,
      },
    });
  };

  return (
    <BodyContainer>
      <SectionTitleWrapper>
        {sortedTrainers?.length ? (
          <Title fontWeight="semibold" fontSize={12} color={COLOR_MEDIUM_GRAY}>
            {t("coaches").toUpperCase()}
          </Title>
        ) : null}
        {shouldShowSendGroupMessage ? (
          <SendGroupMessageWrapper onClick={handleSendGroupMessage}>
            <StyledText color={COLOR_BLUE} fontSize={12} fontWeight={500}>
              {t("sendGroupMessage").toUpperCase()}
            </StyledText>
          </SendGroupMessageWrapper>
        ) : null}
      </SectionTitleWrapper>
      {sortedTrainers.map((trainer) => (
        <BaseUserListCard
          key={trainer?.id}
          currentUser={trainer}
          isTrainerCard
          hideAdditionalInfo={hideAdditionalInfo}
        />
      ))}
      <AthleteListWrapper>
        {sortedAthletes?.length ? (
          <Title fontWeight="semibold" fontSize={12} color={COLOR_MEDIUM_GRAY}>
            {t("athletes").toUpperCase()}
          </Title>
        ) : null}
        {showMembersAttendance ? (
          <AttendanceWrapper>
            <CoachAttendanceItem
              attendance={athletes.filter(
                (athlete) => athlete.participates === true
              )}
              icon={<AttendanceCheckmarkIcon />}
              isHoverable={false}
            />
            <CoachAttendanceItem
              attendance={athletes.filter(
                (athlete) => athlete.participates === false
              )}
              icon={<AttendanceCrossIcon />}
              isHoverable={false}
            />
            <CoachAttendanceItem
              attendance={athletes.filter(
                (athlete) => athlete.participates === null
              )}
              icon={<AttendanceQuestionMarkIcon />}
              isHoverable={false}
            />
          </AttendanceWrapper>
        ) : null}
      </AthleteListWrapper>

      {sortedAthletes.map((athlete) => (
        <BaseUserListCard
          key={athlete?.id}
          currentUser={athlete}
          hideAdditionalInfo={hideAdditionalInfo}
          showMembersAttendance={showMembersAttendance}
          currentGroupOwnerId={group?.owner?.id}
        />
      ))}
    </BodyContainer>
  );
};
