import { useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import { getSearchParamsObject } from "../utils/getSearchParamsObject";

export const useScrollToWidget = (hasLoadedAllData: boolean) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const focusedLayoutIndex = searchParams.get("layoutIndex") ?? undefined;

  useEffect(() => {
    if (!hasLoadedAllData && focusedLayoutIndex) {
      setTimeout(() => {
        const focusedWidget = document.getElementById(
          getWidgetElementId(focusedLayoutIndex)
        );

        if (focusedWidget) {
          focusedWidget.scrollIntoView({ behavior: "smooth" });
          setSearchParams({
            ...getSearchParamsObject(searchParams, ["layoutIndex"]),
          });
        }
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, hasLoadedAllData, focusedLayoutIndex, location.pathname]);

  const getWidgetElementId = (index: string | number) =>
    `widget-${index.toString()}`;

  return {
    getWidgetElementId,
  };
};
