import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const UserIcon = memo(
  ({
    width = 9,
    height = 11,
    isActive = false,
    tintColor = "#8A939B",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="4.5" cy="2.5" r="2.5" fill={tintColor} />
      <path
        d="M0 10C0 7.79086 1.79086 6 4 6H5C7.20914 6 9 7.79086 9 10C9 10.5523 8.55229 11 8 11H1C0.447715 11 0 10.5523 0 10Z"
        fill={tintColor}
      />
    </svg>
  )
);
