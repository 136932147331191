import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
} from "../../../colors";
import { GetTheGroupsImInQuery, XpsUser } from "../../../graphql";
import { Loader } from "../../Loader";
import { TheWho } from "../../Teams/components/TeamGroups";

import { AddGroupOrAthleteToGroupRow } from "./Row";

const Container = styled.div`
  overflow: auto;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  padding-left: 15px;
  height: 20px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
`;

const Text = styled.div<{ isEmptyText?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  color: ${COLOR_SECONDARY_GREY_HOVER};
  ${({ isEmptyText }) =>
    isEmptyText
      ? css`
          color: ${COLOR_TEXT_DARK};
          font-size: 12px;
          text-transform: none;
          font-weight: 700;
        `
      : ""}
`;

const EmptyContainer = styled.div`
  padding: 15px;
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

interface AddCoachOrAthleteToGroupContentProps {
  data: GetTheGroupsImInQuery["getTheGroupsImIn"];
  membersToShow: XpsUser[];
  who: TheWho;
  onUserSelect: (id: string) => void;
  selectedUsers: string[];
  loading: boolean;
  ownerId?: string;
}

export const AddCoachOrAthleteToGroupContent = ({
  data,
  membersToShow,
  who,
  onUserSelect,
  selectedUsers,
  loading,
  ownerId,
}: AddCoachOrAthleteToGroupContentProps) => {
  const { t } = useTranslation();
  const dataWithGroups = useMemo(() => {
    if (!who || !data || !membersToShow) {
      return [];
    }
    return (
      membersToShow
        ?.map((member) => ({
          ...member,
          groups: data
            .map((group) =>
              (who === "coach" ? group.trainers : group.athletes).some(
                (athlete) => athlete.id === member.id
              )
                ? group
                : undefined
            )
            .filter((group) => group?.owner?.id === ownerId),
        }))
        // eliminate duplicates
        ?.filter(
          (member, index) =>
            membersToShow.findIndex(
              (memberToShow) => memberToShow.id === member.id
            ) === index
        ) ?? []
    );
  }, [who, data, membersToShow, ownerId]);

  if (!dataWithGroups || dataWithGroups.length === 0 || loading) {
    return (
      <EmptyContainer>
        {loading ? (
          <Loader color={COLOR_BLUE} size="large" />
        ) : (
          <Text isEmptyText>
            {who === "coach" ? t("noCoachesFound") : t("noAthletesFound")}
          </Text>
        )}
      </EmptyContainer>
    );
  }
  return (
    <>
      <Header>
        <Text style={{ width: 250 }}>{t("name")}</Text>
        {who === "athlete" ? (
          <Text style={{ width: 75 }}>{t("birthYear")}</Text>
        ) : null}
        <Text>{t("teams")}</Text>
      </Header>
      <Container>
        {dataWithGroups.map((user, index) => (
          <AddGroupOrAthleteToGroupRow
            isLast={index === dataWithGroups.length - 1}
            data={user}
            key={user.id}
            who={who}
            isSelected={selectedUsers.indexOf(user.id) > -1}
            onClick={() => onUserSelect(user.id)}
          />
        ))}
      </Container>
    </>
  );
};
