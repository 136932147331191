import React from "react";
import { TFunction, useTranslation } from "react-i18next";

import styled from "styled-components";

import { AggregateFunction } from "../../../graphql";
import { Checkbox } from "../../Checkbox";
import { Section } from "../../Section";
import { WidgetEditorTitleSection } from "../WidgetEditorTitleSection";

import { CreateBodyChartFormValue } from "./model";

interface WidgetEditorTitleSectionProps {
  handleChange(e: React.ChangeEvent): void;
  values: CreateBodyChartFormValue;
  name?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LastDayInRange,
] as const;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

function getLabel(aggregation: string, t: TFunction) {
  switch (aggregation) {
    case "Latest":
      return t("aggregationLatest");
    case "LastDayInRange":
      return t("aggregationLastDayInRange");
  }
}

export const BodyChartEditorTitleSection = ({
  values,
  handleChange,
  name = "title",
  setFieldValue,
}: WidgetEditorTitleSectionProps) => {
  const { t } = useTranslation();

  return (
    <Section>
      <WidgetEditorTitleSection
        handleChange={handleChange}
        name={name}
        value={values.name}
        asSection={false}
      />

      <CheckboxWrapper>
        {AGGREGATIONS.map((aggregation) => {
          return (
            <Checkbox
              key={aggregation}
              text={getLabel(aggregation, t)}
              typeIcon="circle"
              check={() => setFieldValue("aggregation", aggregation)}
              checked={values.aggregation === aggregation}
              withMargin={false}
            />
          );
        })}
      </CheckboxWrapper>
    </Section>
  );
};
