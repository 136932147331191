import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNotificationsContext } from "../../../contexts/notifications";
import { useReportsContext } from "../../../contexts/Reports";
import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplate,
  useSaveReportTemplateMutation,
  useSetReportSharedWithAthletesInGroupMutation,
} from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks";
import { useGetIdsOfSharedReports } from "../../../hooks/report/useGetIdsOfSharedReports";

import { HoverableItemWithCheckbox } from "./HoverableItemWithCheckbox";

export const SharableWithAthleteModal = ({
  reportTabs,
  parentId,
  onShareTabReport,
}: {
  reportTabs: ReportTemplate[];
  parentId: string;
  onShareTabReport: (hasSharedTabs: boolean) => Promise<void>;
}) => {
  const { t } = useTranslation();

  const { sessionId } = useUserContext();
  const [{ selectedGroup }] = useReportsContext();
  const { showErrorNotification } = useNotificationsContext();
  const { getIdsOfSharedTabReports, loading } = useGetIdsOfSharedReports({
    reportIds: reportTabs.map((tab) => tab.id),
  });
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const [sharedTabReports, setSharedTabReports] = useState<string[]>([]);

  const [setReportSharedWithAthletesInGroupMutations] =
    useSetReportSharedWithAthletesInGroupMutation();
  const [saveReportTemplate] = useSaveReportTemplateMutation();

  useEffect(() => {
    (async () => {
      const sharedReportIds = await getIdsOfSharedTabReports();
      setSharedTabReports(sharedReportIds);
    })();
  }, [getIdsOfSharedTabReports]);

  const onShareClick = async ({
    report,
    checked,
  }: {
    report: ReportTemplate;
    checked: boolean;
  }) => {
    try {
      // TODO: If it is AthleteReport or TabReport modify it to be shareable as it has no checkbox like group report
      // This is UGLY HACK while API will be fixed - save report template should be removed then
      if (!report.shareableWithAthletes) {
        await saveReportTemplate({
          variables: {
            sessionId,
            accountId: selectedAccountId,
            input: {
              id: report.id,
              name: report.name,
              parentId,
              position: report.position,
              preferences: report.preferences,
              reportType: report.reportType,
              shareableWithAthletes: true,
            },
          },
        });
      }

      await setReportSharedWithAthletesInGroupMutations({
        variables: {
          sessionId,
          reportId: report?.id,
          groupId: selectedGroup?.id,
          shared: checked,
        },
      });

      const newSharedTabReports = checked
        ? [...sharedTabReports, report.id]
        : sharedTabReports.filter((tabId) => tabId !== report.id);

      await onShareTabReport(newSharedTabReports.length > 0);

      setSharedTabReports(newSharedTabReports);
    } catch {
      showErrorNotification(t("reportNotShareableError"));
    }
  };

  return (
    <div
      style={{
        position: "relative",
        top: 0,
        right: 0,
        backgroundColor: "none",
      }}
    >
      {reportTabs.map((tab, index) => (
        <HoverableItemWithCheckbox
          level={2}
          text={tab.name}
          key={`${tab.name}:${index}`}
          onClick={(check) => onShareClick({ checked: check, report: tab })}
          isChecked={sharedTabReports.includes(tab.id)}
          initialLoading={loading}
        />
      ))}
    </div>
  );
};
