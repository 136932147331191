import { Dispatch, useMemo } from "react";

import { Dayjs } from "dayjs";

import { XpsUser } from "../../graphql";

import * as fromActions from "./actions";
import {
  DateSubState,
  Group,
  ExtendedPeriod,
  ReportsContextState,
} from "./types";

export function useActions(dispatch: Dispatch<fromActions.Actions>) {
  return useMemo(
    () => ({
      loadReports(state: ReportsContextState) {
        dispatch(fromActions.loadReportsAction(state));
      },
      loadDateData(state: DateSubState) {
        dispatch(fromActions.loadDateData(state));
      },
      selectGroup(group: Group) {
        dispatch(fromActions.selectGroupAction(group));
      },
      selectAthlete(athlete: XpsUser) {
        dispatch(fromActions.selectAthleteAction(athlete));
      },
      selectPeriod(period: ExtendedPeriod) {
        dispatch(fromActions.selectPeriodAction(period));
      },
      selectStartDate(date: Dayjs) {
        dispatch(fromActions.selectStartDateAction(date));
      },
      selectEndDate(date: Dayjs) {
        dispatch(fromActions.selectEndDateAction(date));
      },
      selectNextPeriod(isSingleDay = false) {
        dispatch(fromActions.selectNextPeriodAction(isSingleDay));
      },
      selectPrevPeriod(isSingleDay = false) {
        dispatch(fromActions.selectPrevPeriodAction(isSingleDay));
      },
      selectSingleDay(date: Dayjs) {
        dispatch(fromActions.selecSingleDayAction(date));
      },
      resetReports() {
        dispatch(fromActions.resetReports());
      },
    }),
    [dispatch]
  );
}
