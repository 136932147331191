import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

interface CustomCalendarTimeGutterHeaderProps {
  week: number;
}

const StyledWeekSpan = styled.span`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  line-height: 20px;
  margin-left: 8px;
  font-weight: 500;
`;

export const CustomCalendarTimeGutterHeader = ({
  week,
}: CustomCalendarTimeGutterHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledWeekSpan>
      {`${t("week").toUpperCase()}`}&nbsp;{week}
    </StyledWeekSpan>
  );
};
