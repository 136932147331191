import React from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { getSortingOptions } from "../../../utils/sortingOrderOptions";
import { TablePeriodizationForm } from "../../Report/PeriodizationTable/model";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField } from "../../SelectField";

const DefaultSortingSelectWrapper = styled.div`
  display: block;
  width: 50%;
`;

export function TablePeriodizationSortingSection() {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle title={t("defaultSorting")} />
      <DefaultSortingSelectWrapper>
        <Field name="sortOrder">
          {({ field, form, meta }: FieldProps<TablePeriodizationForm>) => (
            <SelectField
              field={field}
              form={form}
              meta={meta}
              // @ts-ignore
              options={getSortingOptions(t, false)}
            />
          )}
        </Field>
      </DefaultSortingSelectWrapper>
    </Section>
  );
}
