import React from "react";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../colors";
import { useNewChatContext } from "../../../contexts/messaging/NewChatProvider";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { ButtonColor, GeneralButton } from "../../Button/";

interface BottomBarProps {
  variant: "dm" | "group";
  onRowPress?: (_: any) => Promise<void>;
  closeModal: () => void;
  done: () => void;
  count?: number;
  isMultistage?: boolean;
  disableNext?: boolean;
  disableDone?: boolean;
  isTrainer?: boolean;
  isFamilyMember?: boolean;
  editMode?: boolean;
  sendingMessage?: boolean;
  hideBackButton?: boolean;
}

export const BottomBar = ({
  variant,
  isTrainer,
  onRowPress,
  closeModal,
  count,
  done,
  disableNext,
  disableDone,
  isMultistage,
  isFamilyMember,
  sendingMessage = false,
  editMode = false,
  hideBackButton = false,
}: BottomBarProps) => {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { stage, setStage } = useNewChatContext();

  const onCreateNewGroupChatClick = () =>
    modal.actions.openModal({
      modal: ModalType.CREATE_NEW_GROUP_CHAT,
      title: t("createCustomChatGroup"),
      params: {
        onRowPress: onRowPress ?? (() => {}),
        disableCancel: true,
      },
    });

  return (
    <Container>
      {stage === 2 && !hideBackButton && (
        <GeneralButton
          label={t("back")}
          colorVariant={ButtonColor.Light}
          onClick={() => setStage(1)}
        />
      )}
      {variant === "group" && (
        <CounterRow>
          {!editMode && `${t("messaging")}`}
          <Counter>&nbsp;{count ?? 0}&nbsp;</Counter>
          {editMode
            ? t("membersInGroup")
            : t(count !== 1 ? "members" : "member").toLowerCase()}
        </CounterRow>
      )}
      {isTrainer && !isFamilyMember && variant === "dm" && stage === 1 && (
        <GeneralButton
          label={t("createCustomChatGroup")}
          colorVariant={ButtonColor.Blue}
          outlined
          onClick={onCreateNewGroupChatClick}
        />
      )}
      <FillerElement />
      <GeneralButton
        label={t("cancel")}
        colorVariant={ButtonColor.Light}
        onClick={closeModal}
      />
      {isMultistage &&
        (stage === 2 || editMode ? (
          <GeneralButton
            label={t("done")}
            isLoading={sendingMessage}
            isDisabled={disableDone}
            onClick={done}
          />
        ) : (
          <GeneralButton
            label={t("next")}
            colorVariant={ButtonColor.Blue}
            isDisabled={disableNext}
            onClick={() => setStage(2)}
          />
        ))}
    </Container>
  );
};

export const Container = styled.div(
  () => css`
    background-color: ${COLOR_WHITE};
    padding: 0 20px;
    display: flex;
    min-height: 65px;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: ${COLOR_GREY_ACTIVE};
    position: sticky;
    bottom: 0;
  `
);

const FillerElement = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  `
);

export const CounterRow = styled.div(
  () => css`
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    cursor: default;
  `
);

export const Counter = styled.div(
  () => css`
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
  `
);
