import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_BLUE } from "../colors";
import { EmptyTeams } from "../components/EmptyTeams";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import ScreenContainer from "../components/ScreenContainer";
import { SubHeader } from "../components/SubHeader";
import { Tab } from "../components/Tabs";
import { TeamGroups } from "../components/Teams/components/TeamGroups";
import { TeamMembers } from "../components/Teams/components/TeamMembers";
import { useTeamsContext } from "../contexts/teams";
import Storage from "../utils/storage";
import { StorageKeys } from "../utils/storageKeys";

export enum TabLabel {
  GROUPS = "groups",
  MEMBERS = "members",
}

function getTabs(t: TFunction) {
  return [
    { id: TabLabel.GROUPS, title: t("groups").toUpperCase() },
    { id: TabLabel.MEMBERS, title: t("members").toUpperCase() },
  ];
}

const EmptyWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function TeamScreen() {
  const { t } = useTranslation();
  const { group, onSetGroup, myGroups, loadingGroups } = useTeamsContext();
  const tabs = getTabs(t);
  const [tab, setTab] = useState<Tab>(tabs[0]);
  const [membersAreLoaded, setMembersAreLoaded] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetTab = (tab: Tab) => {
    setSearchParams((params) => {
      params.set("tab", tab.id);
      return params;
    });
    setTab(tab);
  };

  useEffect(() => {
    const tabParam = searchParams.get("tab");
    if (tabParam) {
      const tabIndex = tabs.findIndex((t) => t.id === tabParam);
      setTab(tabs[tabIndex]);
    } else {
      setTab(tabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.toString()]);

  const withoutTeams = !loadingGroups && myGroups?.length === 0;

  return (
    <ScreenContainer
      scrollPersist={tab.id === TabLabel.MEMBERS}
      onScrollPersist={(newScrollPosition) => {
        Storage.setItem(
          StorageKeys.teamMembersLastScrollPosition,
          newScrollPosition
        );
      }}
      scrollToValue={
        membersAreLoaded &&
        Storage.getItem(StorageKeys.teamMembersLastScrollPosition)
      }
    >
      <Header />
      {loadingGroups ? (
        <EmptyWrapper>
          <Loader size="large" color={COLOR_BLUE} />
        </EmptyWrapper>
      ) : (
        <>
          <SubHeader
            tab={tab}
            tabs={tabs}
            setTab={handleSetTab}
            group={group}
            onSetGroup={onSetGroup}
            isGroupSelectorVisible
            isButtonVisible={true}
            isCreateButtonForSession={false}
            shouldDisplaySubgroups={false}
            isTeamsModule
            groupSelectorLoading={loadingGroups}
            withoutTeams={withoutTeams}
          />
          {withoutTeams ? (
            <EmptyWrapper>
              <EmptyTeams />
            </EmptyWrapper>
          ) : (
            <>
              {tab.id === TabLabel.GROUPS && <TeamGroups />}
              {tab.id === TabLabel.MEMBERS && (
                <TeamMembers
                  onMembersLoaded={() => setMembersAreLoaded(true)}
                />
              )}
            </>
          )}
        </>
      )}
    </ScreenContainer>
  );
}
