export const WIDTH = 370;
export const HEIGHT = 800;
const LEG_OFFSET = 200;

export const SECTION_CONFIG = {
  "right-leg": {
    ids: {
      front: [61, 60, 58, 57, 56, 55, 54, 53, 52, 46, 45, 43],
      back: [85, 86, 87, 94, 95, 97, 98, 100, 102, 103, 104, 105],
    },
    origin: [230, HEIGHT + LEG_OFFSET],
    zoomProps: {
      front: { transform: "translate(236.000000, 514.000000)" },
      back: { transform: "translate(236.000000, 514.000000)" },
    },
    props: {
      front: { transform: "translate(173.000000, 354.000000)" },
      back: { transform: "translate(173.000000, 358.000000)" },
    },
  },
  "left-leg": {
    ids: {
      front: [29, 28, 26, 25, 24, 23, 22, 21, 20, 14, 13, 11],
      back: [124, 125, 126, 133, 134, 136, 137, 139, 141, 142, 143, 144],
    },
    origin: [120, HEIGHT + LEG_OFFSET],
    zoomProps: {
      front: { transform: "translate(42.000000, 514.000000)" },
      back: { transform: "translate(42.000000, 514.000000)" },
    },
    props: {
      front: { transform: "translate(96.000000, 354.000000)" },
      back: { transform: "translate(96.000000, 358.000000)" },
    },
  },
  "right-arm": {
    ids: {
      front: [68, 67, 65, 51, 50, 49, 48, 47, 44, 42, 41],
      back: [73, 76, 78, 79, 88, 89, 90, 92, 93, 106, 107],
    },
    origin: [WIDTH, 250],
    zoomProps: {
      front: { transform: "translate(298.000000, 219.000000)" },
      back: { transform: "translate(298.000000, 219.000000)" },
    },
    props: {
      front: { transform: "translate(229.000000, 140.000000)" },
      back: { transform: "translate(224.000000, 140.000000)" },
    },
  },
  "left-arm": {
    ids: {
      front: [36, 35, 33, 19, 18, 17, 16, 15, 12, 10, 9],
      back: [112, 115, 117, 118, 127, 128, 129, 131, 132, 145, 146],
    },
    origin: [0, 250],
    zoomProps: {
      front: { transform: "translate(-16.000000, 219.000000)" },
      back: { transform: "translate(-16.000000, 219.000000)" },
    },
    props: {
      front: { transform: "translate(3.000000, 140.000000)" },
      back: { transform: "translate(3.000000, 140.000000)" },
    },
  },
  "torso-head": {
    ids: {
      front: [
        66, 64, 63, 62, 59, 40, 39, 38, 37, 34, 32, 31, 30, 27, 8, 7, 6, 5, 4,
        3, 2, 1,
      ],
      back: [
        74, 75, 77, 80, 81, 82, 83, 84, 91, 96, 99, 101, 113, 114, 116, 119,
        120, 121, 122, 123, 130, 135, 138, 140,
      ],
    },
    origin: [Math.round(WIDTH / 2), -20],
    zoomProps: {
      front: { transform: "translate(141.000000, -56.000000)" },
      back: { transform: "translate(141.000000, -56.000000)" },
    },
    props: {
      front: { transform: "translate(95.000000, 2.000000)" },
      back: { transform: "translate(89.000000, 2.000000)" },
    },
  },
};
