import _groupBy from "lodash/groupBy";

import { ReportsContextState } from "../contexts/Reports";

import { getPeriodStartEndUnitFromString } from "./getPeriodStartEndUnit";

const DOCUMENT_COLLECTION_ID_PART = "DOCUMENT";
const EXERCISE_SET_COLLECTION_ID_PART = "EXERCISE_SET";
const TEST_COLLECTION_ID_PART = "TEST";
const TRAINING_LOAD_COLLECTION_ID_PART = "TRAINING_LOAD";
const DRILL_COLLECTION_ID_PART = "DRILL";
const INFORMATION_COLLECTION_ID_PART = "OBSERVATION";
const SOCCER_STATS_COLLECTION_ID_PART = "SoccerStat";
const ATTENDANCE_COLLECTION_ID_PART = "Attendance";
const ACUTE_CHRONIC_COLLECTION_ID_PART = "AcuteChronic";
const POLAR_COLLECTION_ID_PART = "Polar";
const GARMIN_COLLECTION_ID_PART = "Garmin";
const KINEXON_COLLECTION_ID_PART = "Kinexon";
const FIRSTBEAT_COLLECTION_ID_PART = "Firstbeat";
const PLAYERTEC_CATAPULT_COLLECTION_ID_PART = "PlayertecCatapult";
const CATAPULT_COLLECTION_ID_PART = "Catapult";
const STATSPORTS_COLLECTION_ID_PART = "Statsports";
const PUBG_COLLECTION_ID_PART = "Pubg";
const XPS_MAGIC_ID_PART = "XpsMagic";

const WEIGHT_X_REPS_SUB_ATTRIBUTE_ID = "777";
const COUNT_ATTRIBUTE_ID = "901";

export const CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX = "CustomTextColumn";
export const CUSTOM_NUMBER_SOCCER_MEASUREMENTS_PREFIX = "CustomColumn";

export const XPS_AGE_ATTRIBUTE = "Age";
export const XPS_BIRTH_DAY_ATTRIBUTE = "BirthDay";

export const isDocumentCollection = (collectionId = "") =>
  collectionId.includes(DOCUMENT_COLLECTION_ID_PART);
export const isExerciseSetCollection = (collectionId = "") =>
  collectionId.includes(EXERCISE_SET_COLLECTION_ID_PART);
export const isTestCollection = (collectionId = "") =>
  collectionId.includes(TEST_COLLECTION_ID_PART);
export const isTrainingLoadCollection = (collectionId = "") =>
  collectionId.includes(TRAINING_LOAD_COLLECTION_ID_PART);
export const isDrillCollection = (collectionId = "") =>
  collectionId.includes(DRILL_COLLECTION_ID_PART);
export const isInformationCollection = (collectionId = "") =>
  collectionId.includes(INFORMATION_COLLECTION_ID_PART);
export const isSoccerStatsCollection = (collectionId = "") =>
  collectionId.includes(SOCCER_STATS_COLLECTION_ID_PART);
export const isAttendanceCollection = (collectionId = "") =>
  collectionId.includes(ATTENDANCE_COLLECTION_ID_PART);
export const isAcuteChronicCollection = (collectionId = "") =>
  collectionId.includes(ACUTE_CHRONIC_COLLECTION_ID_PART);
export const isPolarCollection = (collectionId = "") =>
  collectionId.includes(POLAR_COLLECTION_ID_PART);
export const isKinexonCollection = (collectionId = "") =>
  collectionId.includes(KINEXON_COLLECTION_ID_PART);
export const isFirstbeatCollection = (collectionId = "") =>
  collectionId.includes(FIRSTBEAT_COLLECTION_ID_PART);
export const isGarminCollection = (collectionId = "") =>
  collectionId.includes(GARMIN_COLLECTION_ID_PART);
export const isPlayertecCataplutCollection = (collectionId = "") =>
  collectionId.includes(PLAYERTEC_CATAPULT_COLLECTION_ID_PART);
export const isCatapultCollection = (collectionId = "") =>
  collectionId.includes(CATAPULT_COLLECTION_ID_PART);
export const isStatsportsCollection = (collectionId = "") =>
  collectionId.includes(STATSPORTS_COLLECTION_ID_PART);
export const isPubgCollection = (collectionId = "") =>
  collectionId.includes(PUBG_COLLECTION_ID_PART);
export const isXpsMagicCollection = (collectionId = "") =>
  collectionId.includes(XPS_MAGIC_ID_PART);

export const isXpsMagicAgeAttribute = (measurementId = "") =>
  measurementId.includes(XPS_MAGIC_ID_PART + "." + XPS_AGE_ATTRIBUTE);
export const isXpsMagicBirthDayAttribute = (measurementId = "") =>
  measurementId.includes(XPS_MAGIC_ID_PART + "." + XPS_BIRTH_DAY_ATTRIBUTE);

export const isSumAttribute = (attributeId = "") =>
  attributeId === WEIGHT_X_REPS_SUB_ATTRIBUTE_ID;
export const isCountAttribute = (attributeId = "") =>
  attributeId === COUNT_ATTRIBUTE_ID;

export const isCustomTextSoccerAttribute = (measurementId = "") =>
  measurementId.includes(CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX);

export const isCustomNumberSoccerAttribute = (measurementId = "") =>
  measurementId.includes(CUSTOM_NUMBER_SOCCER_MEASUREMENTS_PREFIX);

export const isNoteSoccerAttribute = (measurementId = "") =>
  measurementId.includes(CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX + ".txt");
export const isOpponentSoccerAttribute = (measurementId = "") =>
  measurementId.includes(CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX + ".opponent");

export const isNoteOrOpponentSoccerAttribute = (measurementId = "") =>
  isNoteSoccerAttribute(measurementId) ||
  isOpponentSoccerAttribute(measurementId);

export function getReportsSelectionDateRange({
  selectedStartDate,
  selectedEndDate,
  selectedPeriod,
  isSingleDay = false,
  selectedSingleDay,
}: Pick<
  ReportsContextState,
  | "selectedStartDate"
  | "selectedEndDate"
  | "selectedPeriod"
  | "selectedSingleDay"
> & {
  isSingleDay?: boolean;
}) {
  if (isSingleDay) {
    return {
      fromDate: selectedSingleDay.startOf("day"),
      toDate: selectedSingleDay.endOf("day"),
    };
  }

  switch (selectedPeriod) {
    case "day":
      return {
        fromDate: selectedStartDate.startOf(
          getPeriodStartEndUnitFromString(selectedPeriod)
        ),
        toDate: selectedStartDate.endOf(
          getPeriodStartEndUnitFromString(selectedPeriod)
        ),
      };
    case "week":
    case "month":
      return {
        fromDate: selectedStartDate.startOf(
          getPeriodStartEndUnitFromString(selectedPeriod)
        ),
        toDate: selectedEndDate.endOf(
          getPeriodStartEndUnitFromString(selectedPeriod)
        ),
      };
    default:
      // "custom"
      return {
        fromDate: selectedStartDate.startOf("day"),
        toDate: selectedEndDate.endOf("day"),
      };
  }
}

type SoccerTemplateToStatType = {
  A2A: string;
  Assists: string;
  CleanSheets: string;
  GamesPlayed: string;
  Goals: string;
  Grade: string;
  Minutes: string;
  MinutesInPosition: string;
  MinutesPerGame: string;
  Points: string;
  RedCards: string;
  YellowCards: string;
};

const ATHLETE_SOCCER_STATS_TEMPLATE_ID_TO_STAT: SoccerTemplateToStatType = {
  A2A: "_a2a",
  Assists: "_assists",
  CleanSheets: "_cleanSheet",
  GamesPlayed: "_games",
  Goals: "_goals",
  Grade: "_grade",
  Minutes: "_minutes",
  MinutesInPosition: "minutesPerPos",
  MinutesPerGame: "_minutesPerGame",
  Points: "_points",
  RedCards: "_red",
  YellowCards: "_yellow",
};

const SOCCER_STATS_TEMPLATE_ID_TO_STAT: SoccerTemplateToStatType = {
  A2A: "a2a",
  Assists: "assists",
  CleanSheets: "cleanSheet",
  GamesPlayed: "numberOfGames",
  Goals: "goals",
  Grade: "grade",
  Minutes: "minutes",
  MinutesInPosition: "minutesPerPos",
  MinutesPerGame: "mpg",
  Points: "points",
  RedCards: "red",
  YellowCards: "yellow",
};

export const getSoccerStatisticsDataKeyFactory =
  (templateIdToStat: SoccerTemplateToStatType) =>
  (measurementTemplateId: string): string => {
    const statId = /\.([^.]+)$/.exec(measurementTemplateId);

    if (measurementTemplateId.includes("MinutesInPosition.")) {
      const minutesInPositionId =
        measurementTemplateId.split(".")[
          measurementTemplateId.split(".").length - 1
        ];

      return `minutesPerPos.${minutesInPositionId}`;
    }

    if (isCustomNumberSoccerAttribute(measurementTemplateId)) {
      const customColumnId = measurementTemplateId.split(
        `${CUSTOM_NUMBER_SOCCER_MEASUREMENTS_PREFIX}.`
      )[
        measurementTemplateId.split(
          `${CUSTOM_NUMBER_SOCCER_MEASUREMENTS_PREFIX}.`
        ).length - 1
      ];

      return `extraCols.SoccerStat.${CUSTOM_NUMBER_SOCCER_MEASUREMENTS_PREFIX}.${customColumnId}`;
    }

    if (isCustomTextSoccerAttribute(measurementTemplateId)) {
      const customColumnId = measurementTemplateId.split(
        `${CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX}.`
      )[
        measurementTemplateId.split(
          `${CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX}.`
        ).length - 1
      ];

      return `extraTextCols.SoccerStat.${CUSTOM_TEXT_SOCCER_MEASUREMENTS_PREFIX}.${customColumnId}`;
    }

    return templateIdToStat[statId?.[1]] || "";
  };
export const getAggregatedSoccerStatisticsDataKeyFactory =
  (templateIdToStat: SoccerTemplateToStatType) =>
  (measurementTemplateId: string): string => {
    const statId = /\.([^.]+)$/.exec(measurementTemplateId);

    if (measurementTemplateId.includes("MinutesPerGame")) {
      return templateIdToStat.Minutes;
    }

    if (measurementTemplateId.includes("MinutesInPosition.")) {
      return measurementTemplateId.replace("SoccerStat.", "");
    }
    if (
      isCustomNumberSoccerAttribute(measurementTemplateId) ||
      isCustomTextSoccerAttribute(measurementTemplateId)
    ) {
      return measurementTemplateId;
    }
    return templateIdToStat[statId?.[1]] || "";
  };

export const getAggregatedAthleteSoccerStatisticsDataKey =
  getAggregatedSoccerStatisticsDataKeyFactory(
    ATHLETE_SOCCER_STATS_TEMPLATE_ID_TO_STAT
  );

export const getAthleteSoccerStatisticsDataKey =
  getSoccerStatisticsDataKeyFactory(ATHLETE_SOCCER_STATS_TEMPLATE_ID_TO_STAT);

export const getAggregatedSoccerStatisticsDataKey =
  getAggregatedSoccerStatisticsDataKeyFactory(SOCCER_STATS_TEMPLATE_ID_TO_STAT);

export const getSoccerStatisticsDataKey = getSoccerStatisticsDataKeyFactory(
  SOCCER_STATS_TEMPLATE_ID_TO_STAT
);

export const TEMPLATE_ID_TO_STAT_PROP = {
  games_total: "gamesTotal",
  games_played: "gamesPlayed",
  games_p_perc: "gamesPlayedPerc",
  games_p_min: "gamesPlayedMinutes",
  total_pra: "practicesTotal",
  pra_attended: "practicesAttended",
  pra_att_perc: "practicesAttendedPerc",
  pra_total_min: "practicesTotalMinutes",
  pra_att_min: "practicesAttendedMinutes",
  pra_min_perc: "practicesMinutesPerc",
  total_workouts: "workoutsTotal",
  workouts_compl: "workoutsCompleted",
  workouts_perc: "workoutsPerc",
  workout_compl_min: "workoutsCompletedMinutes",
  total_sess_att: "totalSessionsAttended",
  total_sess_min: "totalSessionsAttendedMinutes",
  total_rest_days: "totalRestDays",
  missed_games: "gamesMissed",
  missed_pract: "practicesMissed",
  missed_games_sick: "gamesMissedSick",
  missed_games_inj: "gamesMissedInjured",
  missed_games_other: "gamesMissedOther",
  missed_pra_sic: "practicesMissedSick",
  missed_pra_inj: "practicesMissedInjured",
  missed_pra_other: "practicesMissedOther",
  total_team_ev: "teamEventsTotal",
  team_ev_attended: "teamEventsAttended",
  team_ev_att_min: "teamEventsAttendedMinutes",
  team_ev_att_perc: "teamEventsAttendedPerc",
  team_ev_total_min: "teamEventsTotalMinutes",
  team_ev_min_perc: "teamEventsMinutesPerc",
  missed_team_ev: "teamEventsMissed",
  missed_team_ev_sic: "teamEventsMissedSick",
  missed_team_ev_other: "teamEventsMissedOther",
  missed_team_ev_inj: "teamEventsMissedInjured",
};

export const getAttendanceStatisticsDataKey = (
  measurementTemplateId: string
) => {
  const statId = /\.([^.]+)$/.exec(measurementTemplateId);
  return TEMPLATE_ID_TO_STAT_PROP[statId?.[1]];
};

export function groupByCollection<T extends { collectionId?: string }>(
  collection: T[]
): {
  informationItems: T[];
  soccerStatisticsItems: T[];
  attendanceItems: T[];
  xpsMagicInfoItems: T[];
  statisticsItems: T[];
} {
  const {
    informationItems = [],
    soccerStatisticsItems = [],
    attendanceItems = [],
    xpsMagicInfoItems = [],
    statisticsItems = [],
  } = _groupBy(collection, (item) => {
    if (isInformationCollection(item.collectionId)) {
      return "informationItems";
    }

    if (isSoccerStatsCollection(item.collectionId)) {
      return "soccerStatisticsItems";
    }

    if (isAttendanceCollection(item.collectionId)) {
      return "attendanceItems";
    }

    if (isXpsMagicCollection(item.collectionId)) {
      return "xpsMagicInfoItems";
    }

    return "statisticsItems";
  });

  return {
    informationItems: informationItems ?? [],
    soccerStatisticsItems: soccerStatisticsItems ?? [],
    attendanceItems: attendanceItems ?? [],
    xpsMagicInfoItems: xpsMagicInfoItems ?? [],
    statisticsItems: statisticsItems ?? [],
  };
}
