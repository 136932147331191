import styled from "styled-components";

import { Row } from "../../styled";

export const SubSectionRow = styled(Row)`
  justify-content: flex-start;
  margin-left: 30px;
  flex-basis: 100%;
`;

export const CheckboxWrapper = styled.div<{ isFirst: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.isFirst ? 0 : 12)}px;
`;
