import dayjs, { Dayjs } from "dayjs";

import { AggregateFunction } from "../../graphql";
import * as api from "../api";
import * as endpoints from "../endpoints";

import { Attribute } from "./statistics";

interface AcuteChronicsStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Attribute[];
}

interface Athlete {
  _guid: string;
  _name: string;
  _acuteChronics: AcuteChronicsStatistics[];
}

export interface AcuteChronicsStatisticsResponse {
  _athletes: Athlete[];
}

export type FetchAcuteChronicsStatisticsArgs = {
  session: api.Session;
  participants: {
    id: string;
  }[];
  items: {
    templateId: string;
    includeAC?: boolean;
    includeAcute?: boolean;
    includeChronic?: boolean;
    includeLoad?: boolean;
  }[];
  fromDate: Dayjs;
  toDate: Dayjs;
  focusedGroup?: string;
  focusedUserAccount?: string;
};

export const AC_RATIO_ATTRIBUTE_ID = "7001";
export const AC_ACUTE_ATTRIBUTE_ID = "7002";
export const AC_CHRONIC_ATTRIBUTE_ID = "7003";
export const AC_LOAD_ATTRIBUTE_ID = "7004";

export const getIncludeAC = (attributeId: string) =>
  attributeId === AC_RATIO_ATTRIBUTE_ID;
export const getIncludeAcute = (attributeId: string) =>
  attributeId === AC_ACUTE_ATTRIBUTE_ID;
export const getIncludeChronic = (attributeId: string) =>
  attributeId === AC_CHRONIC_ATTRIBUTE_ID;
export const getIncludeLoad = (attributeId: string) =>
  attributeId === AC_LOAD_ATTRIBUTE_ID;

export async function fetchAcuteChronicsStatistics({
  session,
  participants = [],
  items = [],
  fromDate,
  toDate,
  focusedGroup,
  focusedUserAccount,
}: FetchAcuteChronicsStatisticsArgs) {
  const params = {
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
    _range: {
      _from: dayjs(fromDate).valueOf(),
      _to: dayjs(toDate).valueOf(),
    },
    _templates: {
      _acuteChronics: items.map(
        ({
          templateId,
          includeAC,
          includeAcute,
          includeChronic,
          includeLoad,
        }) => ({
          _template: templateId,
          _includeAC: includeAC ?? false,
          _includeAcute: includeAcute ?? false,
          _includeChronic: includeChronic ?? false,
          _includeLoad: includeLoad ?? false,
          _aggregation: AggregateFunction.Each,
        })
      ),
    },
    _participants: {
      _athleteGuids: participants.map((p: { id: string }) => p?.id),
    },
  };

  return api.post<AcuteChronicsStatisticsResponse>(
    endpoints.JsonStatisticsForMeasurementsQuery,
    params,
    session
  );
}
