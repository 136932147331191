import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";
import _get from "lodash/get";
import _merge from "lodash/merge";

import { getTextFromLangText } from "../utils/i18n";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

function catchError<T extends { _failureTextForUser?: string }>(
  response: AxiosResponse<T>
) {
  const error = response.data._failureTextForUser;
  if (error) {
    return Promise.reject(error);
  }

  return response;
}

export type Session = {
  sessionId: string;
  focusedGroup?: string;
  focusedAthlete?: string;
};

export async function post<T = any>(
  endpoint: string,
  params: any,
  session: Session = { sessionId: undefined },
  userConfig = {},
  shouldCatchError = true
) {
  const defaultConfig = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const config = _merge({}, defaultConfig, userConfig);

  const requestParams = {
    ...params,
    _sessionId: session.sessionId,
    _focusedAthlete:
      params._focusedAthlete || session.focusedAthlete || undefined,
    _focusedGroup: params._focusedGroup || session.focusedGroup || undefined,
    _timeZoneOffset: -new Date().getTimezoneOffset() * 60 * 1000,
    _timeZoneName: dayjs.tz.guess(),
    _focusedUserAccount: params._focusedUserAccount || undefined,
  };

  const response = await axios.post<T>(
    buildEndpointUrl(endpoint),
    requestParams,
    config
  );

  return shouldCatchError ? catchError(response) : response;
}

export async function get<T extends any>(endpoint: string, params?: any) {
  if (params) {
    endpoint += "?";
  }

  const response = await fetch(buildEndpointUrl(endpoint));
  const data = (await response.json()) as T;

  return { data, status: response.status };
}

function buildEndpointUrl(endpoint: string, userParams?: any) {
  const language = i18next.language.slice(0, 2);

  const defaultParams = {
    charset: "UTF-8",
    gzip: "true",
    _languageCode: language,
  };

  const params = _merge({}, defaultParams, userParams);

  return (endpoint += "&" + buildQueryString(params));
}

function buildQueryString(params: any) {
  return Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
}

export async function fetchModularContent(
  url: string
): Promise<ModularContent> {
  const response = await fetch(url);
  const content = await response.json();
  const elements = _get(content, "_elements", []);

  const paragraphs: ModularContentParagraph[] = elements
    .filter((e) => e._type === "Paragraph")
    .map((paragraph) => ({
      title: paragraph._title ? getTextFromLangText(paragraph._title) : null,
      text: paragraph._text ? getTextFromLangText(paragraph._text) : null,
    }));

  const videos: ModularContentVideo[] = elements
    .filter(
      (e) => e._type === "Video" && !e._youtubeId && !e._vimeoId && e._videoUrl
    )
    .map((video) => {
      const urlParts = video._videoUrl.split("/");
      const lastIndex = urlParts.length - 1;
      const nameParts = urlParts[lastIndex].split(".");
      const extension = nameParts[nameParts.length - 1];
      urlParts[lastIndex] =
        "thumb.framegrab." + urlParts[lastIndex].replace(extension, "jpg");

      return {
        url: video._videoUrl,
        thumbnailUrl: urlParts.join("/"),
        caption: getTextFromLangText(video._title),
      };
    });

  const youtubeVideos: ModularContentYoutubeVideo[] = elements
    .filter((e) => e._type === "Video" && e._youtubeId)
    .map((video) => {
      return {
        embedUrl: `https://www.youtube.com/embed/${
          video._youtubeId
        }?rel=0&amp;modestbranding=1&amp;showinfo=0&amp;controls=1&amp;start=${
          video.startAt || 0
        }`,
        youtubeId: video._youtubeId,
        caption: getTextFromLangText(video._title),
      };
    });

  const vimeoVideos: ModularContentVimeoVideo[] = elements
    .filter((e) => e._type === "Video" && e._vimeoId)
    .map((video) => {
      return {
        embedUrl: `https://player.vimeo.com/video/${video._vimeoId}?autoplay=0`,
        vimeoId: video._vimeoId,
        caption: getTextFromLangText(video._title),
      };
    });

  const images: ModularContentImage[] = elements
    .filter((e) => e._type === "Image")
    .map((image) => ({
      url: image._imageUrl,
      caption: getTextFromLangText(image._title),
    }));

  return {
    title: getTextFromLangText(content._title),
    paragraphs,
    videos,
    images,
    youtubeVideos,
    vimeoVideos,
  };
}

export async function fetchJsonContent(url: string): Promise<ModularContent> {
  const response = await fetch(url);
  const content = await response.json();

  const paragraphs: ModularContentParagraph[] = _get(
    content,
    "paragraphs",
    []
  ).map((paragraph) => ({
    title: paragraph.title,
    text: paragraph.text,
  }));

  const videos: ModularContentVideo[] = _get(content, "videos", []).map(
    (video) => {
      return {
        url: video.url,
        thumbnailUrl: video.urlThumbSmaller,
        caption: video.caption || video.title,
      };
    }
  );

  const images: ModularContentImage[] = _get(content, "images", []).map(
    (image) => {
      return {
        url: image.url,
        thumb: image.thumb,
      };
    }
  );

  const youtubeVideos: ModularContentYoutubeVideo[] = _get(
    content,
    "youtubes",
    []
  ).map((video) => {
    return {
      embedUrl: `https://www.youtube.com/embed/${
        video.vid
      }?rel=0&amp;modestbranding=1&amp;showinfo=0&amp;controls=1&amp;start=${
        video.startAt || 0
      }`,
      youtubeId: video.vid,
      startAt: video.startAt,
    };
  });

  const vimeoVideos: ModularContentVimeoVideo[] = _get(
    content,
    "vimeos",
    []
  ).map((video) => {
    return {
      embedUrl: `https://player.vimeo.com/video/${video.vid}?autoplay=0`,
      thumbnailUrl: video.thumb,
      vimeoId: video.vid,
    };
  });

  return {
    title: content.mainTitle,
    paragraphs,
    videos,
    images,
    youtubeVideos,
    vimeoVideos,
  };
}
export type ModularContentParagraph = {
  title: string;
  text: string;
};

export type ModularContentVideo = {
  url: string;
  thumbnailUrl: string;
  caption: string;
};

export type ModularContentYoutubeVideo = {
  embedUrl: string;
  youtubeId: string;
  caption: string;
  startAt: number;
};

export type ModularContentVimeoVideo = {
  thumbnailUrl?: string;
  embedUrl: string;
  vimeoId: string;
  caption?: string;
};

export type ModularContentImage = {
  url: string;
  caption: string;
};

export type ModularContent = {
  title: string;
  paragraphs: ModularContentParagraph[];
  videos: ModularContentVideo[];
  images: ModularContentImage[];
  youtubeVideos: ModularContentYoutubeVideo[];
  vimeoVideos: ModularContentVimeoVideo[];
};
