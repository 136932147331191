import * as api from "../api";
import * as endpoints from "../endpoints";

export type TermsQueryArgs = {
  session: api.Session;
};

export type TermCategory = {
  logOutIfNotAccepted: boolean;
  smallPrint: string;
  termGroup: string;
  termId: string;
  title: string;
  url: string;
};
export type TermsQueryResponse = {
  footNote: string;
  categories: TermCategory[];
};

export const fetchTerms = async ({ session }: TermsQueryArgs) => {
  const response = await api.post<TermsQueryResponse>(
    endpoints.JsonTermsAcceptanceQuery,
    {},
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
};

export type UpdateTermsMutationArgs = {
  session: api.Session;
  termId: string;
  accepted: boolean;
};

export const updateTerms = ({
  session,
  termId,
  accepted,
}: UpdateTermsMutationArgs) => {
  return api.post(
    endpoints.JsonTermsAcceptanceUpdate,
    {
      acceptedTermIds: [
        {
          termId,
          accepted,
        },
      ],
    },
    session
  );
};
