import React, { memo } from "react";

export const LinkedinIcon = memo(() => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.875 1.5625H3.12012C2.26074 1.5625 1.5625 2.27051 1.5625 3.13965V21.8604C1.5625 22.7295 2.26074 23.4375 3.12012 23.4375H21.875C22.7344 23.4375 23.4375 22.7295 23.4375 21.8604V3.13965C23.4375 2.27051 22.7344 1.5625 21.875 1.5625ZM8.17383 20.3125H4.93164V9.87305H8.17871V20.3125H8.17383ZM6.55273 8.44727C5.5127 8.44727 4.67285 7.60254 4.67285 6.56738C4.67285 5.53223 5.5127 4.6875 6.55273 4.6875C7.58789 4.6875 8.43262 5.53223 8.43262 6.56738C8.43262 7.60742 7.59277 8.44727 6.55273 8.44727ZM20.3271 20.3125H17.085V15.2344C17.085 14.0234 17.0605 12.4658 15.4004 12.4658C13.7109 12.4658 13.4521 13.7842 13.4521 15.1465V20.3125H10.21V9.87305H13.3203V11.2988H13.3643C13.7988 10.4785 14.8584 9.61426 16.4355 9.61426C19.7168 9.61426 20.3271 11.7773 20.3271 14.5898V20.3125Z"
      fill="#3C434C"
    />
  </svg>
));
