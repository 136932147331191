import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const BellIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {isActive ? (
        <>
          <path
            d="M10.5 4C13.8135 4 16.5 6.68628 16.5 10V12.3243C16.5 13.7174 17.0534 15.0534 18.0385 16.0385L18.2929 16.2929C18.4255 16.4255 18.5 16.6054 18.5 16.7929C18.5 17.1834 18.1834 17.5 17.7929 17.5H3.20711C2.81658 17.5 2.5 17.1834 2.5 16.7929C2.5 16.6054 2.5745 16.4255 2.70711 16.2929L2.96154 16.0385C3.9466 15.0534 4.5 13.7174 4.5 12.3243V10C4.5 6.68628 7.18652 4 10.5 4Z"
            fill="white"
            stroke="white"
          />
          <path
            d="M10.5 19.5C11.8807 19.5 13 18.3807 13 17H8C8 18.3807 9.11929 19.5 10.5 19.5Z"
            fill="white"
            stroke="white"
          />
          <path
            d="M11.6181 4C11.8556 3.73462 12 3.38418 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.38418 9.14443 3.73462 9.38195 4"
            stroke="white"
          />
        </>
      ) : (
        <>
          <path
            d="M10.5 4C13.8135 4 16.5 6.68628 16.5 10V12.3243C16.5 13.7174 17.0534 15.0534 18.0385 16.0385L18.2929 16.2929C18.4255 16.4255 18.5 16.6054 18.5 16.7929V16.7929C18.5 17.1834 18.1834 17.5 17.7929 17.5H3.20711C2.81658 17.5 2.5 17.1834 2.5 16.7929V16.7929C2.5 16.6054 2.5745 16.4255 2.70711 16.2929L2.96154 16.0385C3.9466 15.0534 4.5 13.7174 4.5 12.3243V10C4.5 6.68628 7.18652 4 10.5 4Z"
            stroke="#3C434C"
          />
          <path
            d="M13 17C13 18.3807 11.8807 19.5 10.5 19.5C9.11929 19.5 8 18.3807 8 17"
            stroke="#3C434C"
          />
          <path
            d="M11.6181 4C11.8556 3.73462 12 3.38418 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.38418 9.14443 3.73462 9.38195 4"
            stroke="#3C434C"
          />
        </>
      )}
    </svg>
  )
);
