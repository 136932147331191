import React from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../../colors";
import {
  MobileModalTypes,
  useMobileModalContext,
} from "../../../contexts/mobileModal/MobileModalProvider";
import { useReportContext } from "../../../contexts/report";
import { BasicButton } from "../../Button";
import { ChevronRightIcon } from "../../Icons";
import { ReportPeriodText } from "../ReportPeriodText";

const PeriodButton = styled(BasicButton)`
  padding: 7.5px 0 7.5px 7.5px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  background-color: ${COLOR_WHITE};
  align-self: center;
  border-radius: 12px;
  padding: 4px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export function ReportPeriodOnMobile() {
  const { showModal } = useMobileModalContext();
  const { report } = useReportContext();

  return (
    <PeriodButton
      onClick={() =>
        showModal(MobileModalTypes.DATE_PICKER, {
          isSingleDayReport: report?.isSingleDay,
        })
      }
    >
      <PeriodWrapper>
        <ReportPeriodText isSingleDay={report?.isSingleDay} />
        <IconWrapper>
          <ChevronRightIcon tintColor={COLOR_BLUE} direction="bottom" />
        </IconWrapper>
      </PeriodWrapper>
    </PeriodButton>
  );
}
