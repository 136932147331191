import React, {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
  useLayoutEffect,
  MouseEvent,
} from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_BLUE,
  COLOR_WHITE,
  COLOR_TEXT_DEFAULT,
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
} from "../../../colors";
import { Chatter, ChatterType, XpsUser } from "../../../graphql";
import Avatar from "../../Avatar";
import { BasicButton, ButtonColor, GeneralButton } from "../../Button";
import {
  CheckmarkDoneIcon,
  AnnouncementIcon,
  RoundedGroupProfileIcon,
} from "../../Icons";
import StyledText from "../../StyledText";

import { TeamRow } from "./TeamRow";

interface ITeamProps {
  isCreateGroupChat?: boolean;
  isTeamSelection?: boolean;
  onClick: (
    user: Chatter | XpsUser,
    isAttending?: boolean,
    isAnnouncement?: boolean
  ) => Promise<void>;
  groupsCount?: number;
  index: number;
  stage?: number;
  attendingUsers?: (Chatter | XpsUser)[];
  currentKey: string;
  search: string;
  groups: {
    [index: string]: {
      owner?: XpsUser;
      value: (Chatter | XpsUser)[];
      name?: string;
    };
  };
  setAttendingUsers?: Dispatch<SetStateAction<(Chatter | XpsUser)[]>>;
  showAttendingImages?: boolean;
  withOwnerName?: boolean;
  withArrow?: boolean;
  withGroupIcon?: boolean;
  isCreateChat?: boolean;
  withPagination?: boolean;
}

const PAGE_SIZE = 50;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;

const TeamRowButton = styled(BasicButton)`
  display: block;
`;

const ButtonWrapper = styled.div<{ isGroup: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 10px 25px 10px 0;
  padding-left: ${({ isGroup }) => (isGroup ? 25 : 50)}px;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLOR_WHITE};

  &:hover {
    background-color: ${COLOR_GRAY};
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 15px;
`;

const NoOneWrapper = styled.div`
  padding: 10px 0 10px 47px;
`;

const EmptyCircle = styled.div`
  width: 19px;
  height: 19px;
  background: ${COLOR_GRAY};
  border-radius: 50%;
  border: 1px solid ${COLOR_GREY_ACTIVE};
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const Team = ({
  search,
  currentKey,
  groups,
  isCreateGroupChat,
  attendingUsers = [],
  groupsCount = 0,
  stage = 1,
  index = 0,
  showAttendingImages = false,
  isTeamSelection = false,
  onClick,
  setAttendingUsers,
  withOwnerName,
  withArrow = true,
  withGroupIcon = true,
  isCreateChat,
  withPagination = true,
}: ITeamProps) => {
  const [page, setPage] = useState(15);
  const [selectedAll, setSelectedAll] = useState(false);
  const [collapsed, collapse] = useState(groupsCount > 5 ? true : index !== 0);
  const { t } = useTranslation();

  const owner = groups[currentKey].owner;
  const name = groups[currentKey].name;

  const newMembers = useMemo(() => {
    const newArray = groups[currentKey].value;

    // To remove duplicities in athletes - can arise when two groups have the same name
    const finalArray = Array.from(new Set(newArray));

    if (search.length > 2) {
      return newArray.filter((user) => {
        const name =
          user.name === currentKey
            ? `${user.name} - ${t("everyone")}`
            : user.name;

        return name?.toLowerCase().includes(search.toLowerCase());
      });
    }
    return withPagination ? finalArray.slice(0, page) : finalArray;
  }, [withPagination, page, groups, search, currentKey, t]);

  const count = useMemo(
    () =>
      search.length > 2
        ? newMembers.length
        : groups[currentKey]["value"].length,
    [groups, search, currentKey, newMembers.length]
  );

  useLayoutEffect(() => {
    const allAttendingAthletesIds = attendingUsers.map((member) => member.id);
    const usersBase = search.length > 2 ? newMembers : groups[currentKey].value;
    const allGroupMembersIncluded = usersBase.every((user) =>
      allAttendingAthletesIds.includes(user.id)
    );

    setSelectedAll(allGroupMembersIncluded);
  }, [
    setSelectedAll,
    attendingUsers,
    groups,
    currentKey,
    search.length,
    newMembers,
  ]);

  useEffect(() => {
    if (search.length > 2 && !!count) collapse(false);
  }, [search, count]);

  const onSelectAllMembersOfGroupClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    const groupMembers = groups[currentKey].value;
    let newAttending = [...attendingUsers];

    if (!selectedAll) {
      const idsOfAttenders = newAttending.map((attender) => attender.id);
      const usersBase =
        search.length > 2 ? newMembers : groups[currentKey].value;
      const usersToAdd = usersBase.filter(
        (user) => !idsOfAttenders.includes(user.id)
      );
      newAttending = Array.from(new Set([...newAttending, ...usersToAdd]));
    } else {
      const idsOfGroupMembers = groupMembers.map((member) => member.id);
      newAttending = newAttending.filter(
        (attender) => !idsOfGroupMembers.includes(attender.id)
      );
    }
    setSelectedAll((prev) => !prev);
    await setAttendingUsers(newAttending);
  };

  const teamIsEmpty = !isTeamSelection && count === 0;
  return (
    <Wrapper key={`${currentKey}-${index}`}>
      {stage === 1 && !isTeamSelection && (
        <TeamRow
          isCreateGroupChat={isCreateGroupChat}
          currentKey={currentKey}
          selectedAll={count !== 0 && selectedAll}
          owner={owner}
          name={name}
          isGroup={isTeamSelection}
          groupsCount={groupsCount}
          showAttendingImages={showAttendingImages}
          collapsed={collapsed}
          withArrow={withArrow}
          setCollapsed={collapse}
          withOwnerName={withOwnerName}
          onSelectAllMembersOfGroupClick={onSelectAllMembersOfGroupClick}
          withGroupIcon={withGroupIcon}
          isCreateChat={isCreateChat}
          teamIsEmpty={teamIsEmpty}
        />
      )}
      {(isTeamSelection || !collapsed) &&
        newMembers.map((user: Chatter) => {
          const isAnnouncement = user.name === currentKey;
          const isAttending = isTeamSelection
            ? false
            : !!attendingUsers.find((au) => au.id === user.id);

          return (
            <TeamRowButton
              key={`create-group-chat-${user.id}`}
              onClick={() => onClick(user, isAttending, isAnnouncement)}
            >
              <ButtonWrapper isGroup={isTeamSelection}>
                <SectionWrapper>
                  {isTeamSelection ? (
                    <IconWrapper>
                      {isAnnouncement ? (
                        <AnnouncementIcon width={25} height={25} />
                      ) : (
                        <RoundedGroupProfileIcon width={25} height={25} />
                      )}
                    </IconWrapper>
                  ) : (
                    <Avatar
                      size={25}
                      source={{
                        uri:
                          user?.avatarThumbURL ||
                          (user as unknown as XpsUser)?.thumb,
                      }}
                      userName={user.name}
                      style={{ marginRight: 15 }}
                    />
                  )}
                  <StyledText
                    fontWeight="bold"
                    fontSize={14}
                    color={COLOR_TEXT_DEFAULT}
                  >
                    {isAnnouncement
                      ? `${user.name} - ${t("everyone")}`
                      : user.name}
                  </StyledText>
                </SectionWrapper>
                <SectionWrapper>
                  {!isTeamSelection &&
                    user.chatterType2 === ChatterType.Family && (
                      <StyledText
                        fontWeight="bold"
                        fontSize={10}
                        color={COLOR_BLUE}
                        style={{ paddingRight: 10 }}
                      >
                        {t("family").toUpperCase()}
                      </StyledText>
                    )}
                  {!isTeamSelection &&
                    user.chatterType2 === ChatterType.Trainer && (
                      <StyledText
                        fontWeight="bold"
                        fontSize={10}
                        color={COLOR_BLUE}
                        style={{ paddingRight: 10 }}
                      >
                        {t("coach").toUpperCase()}
                      </StyledText>
                    )}

                  {!isTeamSelection &&
                    showAttendingImages &&
                    (isAttending ? (
                      <CheckmarkDoneIcon
                        tintColor={COLOR_GREEN}
                        strokeColor={COLOR_WHITE}
                      />
                    ) : (
                      <EmptyCircle />
                    ))}
                </SectionWrapper>
              </ButtonWrapper>
            </TeamRowButton>
          );
        })}
      {withPagination && !collapsed && count > 15 && count !== page && (
        <LoadMoreWrapper>
          <GeneralButton
            label={t("loadMore")}
            colorVariant={ButtonColor.Blue}
            outlined
            onClick={() =>
              setPage(page + PAGE_SIZE > count ? count : page + PAGE_SIZE)
            }
          />
        </LoadMoreWrapper>
      )}
      {teamIsEmpty && !collapsed && (
        <NoOneWrapper>
          <StyledText
            fontSize={12}
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
          >
            {` ${t("noOne")} `}
          </StyledText>
        </NoOneWrapper>
      )}
    </Wrapper>
  );
};
