import { useUserContext } from "../../contexts/User";
import {
  UpdateWorkoutMutationVariables,
  useUpdateWorkoutMutation,
} from "../../graphql";

export const useUpdateWorkout = () => {
  const { sessionId, language, timezone } = useUserContext();

  const [updateWorkout] = useUpdateWorkoutMutation();

  const updateWorkoutMutation = async ({
    id,
    refetchQueries,
    input,
  }: Omit<
    UpdateWorkoutMutationVariables,
    "sessionId" | "timezone" | "language"
  > & {
    id: string;
    refetchQueries?: () => string[];
  }) => {
    await updateWorkout({
      variables: {
        sessionId,
        timezone,
        language,
        input: {
          id,
          ...input,
        },
      },
      refetchQueries,
    });
  };

  return { updateWorkoutMutation };
};
