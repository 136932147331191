import { useCallback, useState, useEffect } from "react";

import { UserPreferenceKeys } from "../../constants";
import { useUserContext } from "../../contexts/User";
import {
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
} from "../../graphql";

export const getTableAppearancePreferenceKey = (userId: string) =>
  `${UserPreferenceKeys.TABLE_APPEARANCE_PREFERENCE}-${userId}`;

export type AppearancePreferences = {
  alternatingColumns?: boolean;
  alternatingValues?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  rowDividers?: boolean;
  showValuesAsText?: boolean;
  widgetBorders: boolean;
};

export const DEFAULT_APPEARANCE_VALUES: AppearancePreferences = {
  alternatingColumns: false,
  alternatingValues: true,
  columnDividers: true,
  outsideBorderDividers: true,
  rowDividers: true,
  showValuesAsText: false,
  widgetBorders: false,
};

export const useTableAppearancePreference = () => {
  const { id: userId, sessionId } = useUserContext();
  const [appearancePreferences, setAppearancePreferences] =
    useState<AppearancePreferences>(DEFAULT_APPEARANCE_VALUES);
  const [getPreference, { refetch }] = useGetPreferenceLazyQuery();
  const [setPreferences] = usePutPreferenceMutation();

  const saveTableAppearancePreference = useCallback(
    async (appearanceData: AppearancePreferences) => {
      if (appearanceData && userId) {
        const response = await setPreferences({
          variables: {
            key: getTableAppearancePreferenceKey(userId),
            sessionId,
            value: JSON.stringify(appearanceData || {}),
          },
        });

        if (response?.data) {
          setAppearancePreferences(appearanceData);
          refetch();
        }
      }
    },
    [sessionId, setPreferences, userId, refetch]
  );

  const getTableAppearancePreference = useCallback(async () => {
    if (userId) {
      const appearancePreferenceResponse = await getPreference({
        variables: {
          key: getTableAppearancePreferenceKey(userId),
          sessionId,
        },
      });

      const preferences = appearancePreferenceResponse?.data?.getPreference
        ? JSON.parse(appearancePreferenceResponse.data.getPreference)
        : DEFAULT_APPEARANCE_VALUES;

      setAppearancePreferences(preferences);
    }
  }, [userId, sessionId, getPreference]);

  useEffect(() => {
    getTableAppearancePreference();
  }, [getTableAppearancePreference]);

  return {
    appearancePreferences,
    saveTableAppearancePreference,
  };
};
