import dayjs from "dayjs";

import { getTime } from "../../utils";

export const processWeekSessions = (
  weekSessions,
  startDate,
  defaultWeekDays
) => {
  const newDays = [];
  const firstWeekDay = dayjs(startDate).day();
  let dayOfWeekSequential = 0;
  let sessionOfDay = 0;
  let hasMultipleSessions = false;

  weekSessions.forEach((session, index) => {
    const firstSession = weekSessions[0];
    const dayOfProgramFromStart =
      session._dayOfProgram - firstSession._dayOfProgram;
    const dayOfWeek = (firstWeekDay + dayOfProgramFromStart) % 7;
    const prevSession = weekSessions[index - 1];

    if (!prevSession || prevSession._dayOfProgram < session._dayOfProgram) {
      dayOfWeekSequential++;
      sessionOfDay = 0;
      hasMultipleSessions = false;
    } else {
      sessionOfDay++;
      hasMultipleSessions = true;
      newDays[index - 1].hasMultipleSessions = true;
    }

    const extraMinutes = session._minuteOfDay ? 0 : 90 * sessionOfDay;
    const totalMinutes = session._minuteOfDay || 720 + extraMinutes;
    const hour = Math.floor(totalMinutes / 60);
    const minute = totalMinutes % 60;

    const date = dayjs(startDate)
      .add(dayOfProgramFromStart, "day")
      .hour(hour)
      .minute(minute);
    const time = getTime(date);

    newDays.push({
      date,
      time,
      label: session._label,
      duration: session._durationMinutes,
      dayOfWeek,
      defaultDayOfWeek: defaultWeekDays[index],
      dayOfWeekSequential,
      sessionOfDay,
      hasMultipleSessions,
    });
  });

  return newDays;
};
