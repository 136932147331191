import gql from "graphql-tag";

export const MessageOverviewPaginated = gql`
  query messageOverviewPaginated(
    $sessionId: String!
    $language: String
    $timezone: String
    $showAll: Boolean
    $includeHidden: Boolean
    $ignoreContactsWithoutChats: Boolean
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    messageOverview(
      sessionId: $sessionId
      timezone: $timezone
      language: $language
      showAll: $showAll
      includeHidden: $includeHidden
      ignoreContactsWithoutChats: $ignoreContactsWithoutChats
    ) {
      contactsPaginated(
        before: $before
        after: $after
        first: $first
        last: $last
      ) {
        edges {
          node {
            licence
            muted
            name
            firstName
            lastName
            avatarURL
            avatarThumbURL
            lastMessage
            unreadByMeInGroup
            unread
            canLoggedOnUserAddParticipants
            canLoggedOnUserRemoveParticipants
            canLoggedOnUserEditName
            canLoggedOnUserDelete
            chatterType
            hidden
            onlyTrainersCanPost
            chatterType2
            id
            lastChatTime
            linkedGroup
            allFriendsOf {
              id
              name
            }
            groupOwner {
              id
              name
              thumb
            }
            whoCanPost
            canPost
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
