import React, { ReactNode } from "react";

import styled from "styled-components";

import { COLOR_WHITE, COLOR_MEDIUM_GRAY } from "../../../../colors";
import StyledText from "../../../StyledText";
import Tooltip from "../../../Tooltip";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(StyledText)`
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
  color: ${COLOR_WHITE};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 5px;
`;

const TitleValue = styled(StyledText)`
  font-weight: 700;
  font-size: 14px;
  color: ${COLOR_WHITE};
`;

const ComponentContainer = styled.div<{ hovered?: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
  padding: 3px 5px 3px 4px;
  border-radius: 15px;
  background-color: ${({ hovered }) => hovered && COLOR_MEDIUM_GRAY};
`;

const ComponentValue = styled(StyledText)<{ hovered?: boolean }>`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 12px;
  font-weight: 500;
  color: ${({ hovered }) => hovered && COLOR_WHITE};
`;

const AthleteName = styled(StyledText)`
  color: ${COLOR_WHITE};
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 6px;
`;

type CoachAttendanceItemProps = {
  attendance;
  icon: ReactNode;
  tooltipTitle?: string;
  right?: boolean;
  isHoverable?: boolean;
  index?: number;
};

export function CoachAttendanceItem({
  attendance,
  icon,
  tooltipTitle = null,
  right = false,
  isHoverable = true,
  index = 0,
}: CoachAttendanceItemProps) {
  return (
    <Tooltip
      zIndex={100000 - index}
      title={() => (
        <TitleContainer>
          {tooltipTitle ? (
            <Title numberOfLines={1}>{tooltipTitle}</Title>
          ) : null}
          <TitleValue>{attendance.length}</TitleValue>
        </TitleContainer>
      )}
      component={(hovered) => {
        const shouldBeHoverable = hovered && isHoverable;
        return (
          <ComponentContainer hovered={shouldBeHoverable}>
            <IconWrapper>{icon}</IconWrapper>
            <ComponentValue hovered={shouldBeHoverable}>
              {attendance.length}
            </ComponentValue>
          </ComponentContainer>
        );
      }}
      isHoverable={isHoverable}
      right={right}
    >
      {isHoverable
        ? attendance.length
          ? attendance.map((attend) => (
              <AthleteName
                key={`tooltip-agenda-attendance-${attend._id}`}
                numberOfLines={1}
              >
                {attend.athleteName}
              </AthleteName>
            ))
          : null
        : null}
    </Tooltip>
  );
}
