import React from "react";

interface Props {
  direction?: "top" | "bottom" | "left" | "right";
}

export const BackIcon = ({ direction }: Props) => {
  const rotation = () => {
    switch (direction) {
      case "right":
        return "180";
      case "bottom":
        return "270";
      case "top":
        return "90";
      default:
        return "0";
    }
  };

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation()}deg)` }}
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
      <path d="M16 12L10.5 17.5L16 23" stroke="#3C434C" />
      <path d="M11 17.5H25" stroke="#3C434C" />
    </svg>
  );
};
