import React from "react";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";

import { COLOR_SECONDARY_GREY_HOVER, COLOR_TEXT_DARK } from "../../../colors";
import { DuplicateWorkoutGroup } from "../DuplicateWorkoutSelectAthleteModal";

import { DuplicateWorkoutSelectAthleteGroup } from "./Group";

const Container = styled.div`
  overflow: auto;
  flex: 1;
`;

const Text = styled.div<{ isEmptyText?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  text-transform: uppercase;
  color: ${COLOR_SECONDARY_GREY_HOVER};
  ${({ isEmptyText }) =>
    isEmptyText
      ? css`
          color: ${COLOR_TEXT_DARK};
          font-size: 12px;
          text-transform: none;
          font-weight: 700;
        `
      : ""}
`;

const EmptyContainer = styled.div`
  padding: 15px;
  text-align: center;
  flex: 1;
`;

interface DuplicateWorkoutSelectAthleteContentProps {
  data: DuplicateWorkoutGroup[];
  onUserSelect: (id: string) => void;
  selectedUsers: string[];
  onWholeGroupSelect: (athletes: string[], select: boolean) => void;
}

export const DuplicateWorkoutSelectAthleteContent = ({
  data,
  onUserSelect,
  selectedUsers,
  onWholeGroupSelect,
}: DuplicateWorkoutSelectAthleteContentProps) => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return (
      <EmptyContainer>
        <Text isEmptyText>{t("noAthletesFound")}</Text>
      </EmptyContainer>
    );
  }
  return (
    <Container>
      {data.map((group, index) => (
        <DuplicateWorkoutSelectAthleteGroup
          key={index}
          data={group}
          selectedUsers={selectedUsers}
          onUserSelect={onUserSelect}
          onAllUsersSelect={(select) =>
            onWholeGroupSelect(
              group.athletes.map((athlete) => athlete.id),
              select
            )
          }
        />
      ))}
    </Container>
  );
};
