import { useEffect, useState } from "react";

import { useUserContext } from "../../contexts/User";
import { AttendanceStatus } from "../../models/attendance";
import { sortList } from "../../utils/sortList";

type Athlete = {
  name: string;
  attendance: AttendanceStatus;
};

export const useAttendanceData = (data) => {
  const { sessionId, timezone, language } = useUserContext();
  const [attendanceData, setAttendanceData] = useState({
    athletes: [],
    attending: 0,
    away: 0,
    notResponded: 0,
    attendingAthletes: [],
    awayAthletes: [],
    notRespondedAthletes: [],
  });

  useEffect(() => {
    const athletes = sortList(
      [...(data?.practice?.attendance || [])],
      language,
      (attendance) => attendance.athleteName
    );

    let attending = 0;
    let away = 0;
    let notResponded = 0;
    const attendingAthletes: Athlete[] = [];
    const awayAthletes: Athlete[] = [];
    const notRespondedAthletes: Athlete[] = [];

    athletes.forEach((athlete: Athlete) => {
      if (athlete.attendance === AttendanceStatus.ATTENDED) {
        attending = attending + 1;
        attendingAthletes.push(athlete);
      }
      if (
        athlete.attendance === AttendanceStatus.SICK ||
        athlete.attendance === AttendanceStatus.INJURED ||
        athlete.attendance === AttendanceStatus.OTHER
      ) {
        away = away + 1;
        awayAthletes.push(athlete);
      }
      if (athlete.attendance === AttendanceStatus.UNKNOWN) {
        notResponded = notResponded + 1;
        notRespondedAthletes.push(athlete);
      }
    });

    setAttendanceData({
      athletes,
      attending,
      away,
      notResponded,
      attendingAthletes,
      awayAthletes,
      notRespondedAthletes,
    });
  }, [data?.practice, language, sessionId, timezone]);

  return attendanceData;
};
