import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { AggregateFunction } from "../../../../graphql";
import {
  isAcuteChronicCollection,
  isSumAttribute,
} from "../../../../utils/reports";
import { Checkbox } from "../../../Checkbox";
import { StyledSwitch } from "../../../StyledSwitch";
import { GroupColumn as GroupColumnModel } from "../../models";
import { SwitchWrapper } from "../../styled";

import { CheckboxWrapper, SubSectionRow } from "./styled";
import { getAggregations, getPropertyNameInForm } from "./utils";

interface IValueSubSectionProps {
  columnIndex: number;
}

export const ValueSubSection = ({ columnIndex }: IValueSubSectionProps) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<{
    columns: GroupColumnModel[];
  }>();

  const currentColumn = values.columns[columnIndex];

  const onCheckboxCheck =
    (aggregationName: AggregateFunction) => (isChecked: boolean) => {
      const newValues = isAcuteChronicCollection(currentColumn.collectionId)
        ? [aggregationName]
        : [...currentColumn.aggregateFunctions, aggregationName];

      setFieldValue(
        getPropertyNameInForm("aggregateFunctions", columnIndex),
        isChecked
          ? newValues
          : currentColumn.aggregateFunctions.filter(
              (agg) => agg !== aggregationName
            )
      );
    };

  const onShowValueChange = (isChecked: boolean) => {
    setFieldValue(getPropertyNameInForm("showValue", columnIndex), isChecked);
    setFieldValue(
      getPropertyNameInForm("aggregateFunctions", columnIndex),
      isChecked ? [AggregateFunction.Latest] : []
    );
  };

  return (
    !isSumAttribute(currentColumn.attributeTemplateId) && (
      <>
        <SwitchWrapper>
          <StyledSwitch
            value={currentColumn.showValue}
            onValueChange={onShowValueChange}
            primary={true}
            label="Show Value"
          />
        </SwitchWrapper>

        {currentColumn.showValue && (
          <SubSectionRow>
            {getAggregations(currentColumn.measurementTemplateId).map(
              (aggregation, index) => (
                <CheckboxWrapper key={aggregation} isFirst={index === 0}>
                  <Checkbox
                    text={t(`aggregation${aggregation}`, {
                      defaultValue: aggregation,
                    })}
                    check={onCheckboxCheck(aggregation)}
                    checked={currentColumn.aggregateFunctions.includes(
                      aggregation
                    )}
                    {...(isAcuteChronicCollection(currentColumn.collectionId)
                      ? { typeIcon: "circle" }
                      : {})}
                  />
                </CheckboxWrapper>
              )
            )}
          </SubSectionRow>
        )}
      </>
    )
  );
};
