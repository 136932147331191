import { useUserContext } from "../../contexts/User";
import { ResourcesUsageInput, useCreateTeamGameMutation } from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useCreateTeamGame = () => {
  const { sessionId, language, timezone } = useUserContext();

  const [createTeamGame] = useCreateTeamGameMutation();
  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const createNewTeamGame = async (
    label: string,
    start: number,
    durationMinutes: number,
    attendedMinutesBefore: number,
    googleLocationId: string,
    location: string,
    xtraLocation: string,
    notes: string,
    colorHexCode: string,
    groupID: string,
    resourcesUsage: ResourcesUsageInput,
    refetchQueries: () => string[]
  ) => {
    const createTeamGameResult = await createTeamGame({
      variables: {
        timezone,
        language,
        sessionId,
        input: {
          label,
          start,
          durationMinutes,
          attendedMinutesBefore,
          googleLocationId,
          location,
          xtraLocation,
          notes,
          colorHexCode,
          groupID,
          resourcesUsage,
        },
      },

      refetchQueries,
    });

    handleSendManualNotificationAfterAction(
      { ...createTeamGameResult?.data?.createTeamGame },
      createTeamGameResult?.data?.createTeamGame?.notificationToSuggest
    );
  };

  return { createNewTeamGame };
};
