import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CheckmarkNotAnsweredIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill="#E6E8EB" />
      <path
        d="M9.07121 12.0537V11.4264C9.07121 10.9498 9.16483 10.5486 9.35206 10.2228C9.53928 9.89213 9.87161 9.57118 10.349 9.25995C10.7282 9.01194 10.9997 8.78824 11.1635 8.58886C11.332 8.38948 11.4162 8.16092 11.4162 7.90318C11.4162 7.69894 11.3273 7.53846 11.1494 7.42175C10.9716 7.30018 10.7399 7.23939 10.4544 7.23939C9.74758 7.23939 8.92143 7.49956 7.97593 8.01989L7 6.03581C8.1655 5.34527 9.38248 5 10.651 5C11.6948 5 12.5139 5.23828 13.1083 5.71485C13.7028 6.19142 14 6.84063 14 7.66247C14 8.25088 13.8666 8.76149 13.5998 9.1943C13.3377 9.62224 12.9164 10.0283 12.336 10.4125C11.8445 10.7431 11.5356 10.9863 11.4092 11.1419C11.2875 11.2927 11.2267 11.4726 11.2267 11.6817V12.0537H9.07121ZM8.76931 14.6068C8.76931 14.1594 8.89335 13.8141 9.14142 13.571C9.39418 13.3278 9.76396 13.2062 10.2508 13.2062C10.7188 13.2062 11.0769 13.3302 11.325 13.5782C11.5777 13.8214 11.7041 14.1642 11.7041 14.6068C11.7041 15.0493 11.5731 15.3921 11.3109 15.6353C11.0535 15.8784 10.7001 16 10.2508 16C9.78736 16 9.42461 15.8809 9.16249 15.6426C8.90037 15.3994 8.76931 15.0542 8.76931 14.6068Z"
        fill="#3C434C"
      />
    </svg>
  )
);
