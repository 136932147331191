import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

import { useNotificationsContext } from "../../../contexts/notifications";
import { ReportTemplate, ReportType } from "../../../graphql";
import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import { WidgetMode } from "../WidgetWithData";

import { useDuplicateReport } from "./useDuplicateReport";
import { useReportWarningModal } from "./useReportWarningModal";

export const useEditReportWarning = ({
  reportTemplate,
  onEdit,
}: {
  reportTemplate: ReportTemplate;
  onEdit(): void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const activeTabId = searchParams.get("activeTabId") ?? undefined;
  const activeProfileTabId =
    searchParams.get("activeProfileTabId") ?? undefined;

  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const { duplicateReport } = useDuplicateReport(
    reportTemplate?.reportType === ReportType.GroupReport
      ? WidgetMode.GROUP
      : WidgetMode.ATHLETE
  );

  return useReportWarningModal({
    title: t("duplicateReport"),
    cancelButtonText: t("edit"),
    okButtonText: t("duplicate"),
    confirmationQuestion: t("editReportDuplicationConfirm"),
    reportTemplate,
    onCancel: onEdit,
    async onOK() {
      try {
        const duplicatedReportId = await duplicateReport(reportTemplate);
        navigate({
          pathname:
            reportTemplate?.reportType === ReportType.GroupReport
              ? `/reports/groupReport/${duplicatedReportId}`
              : `/reports/report/${duplicatedReportId}`,
          search: createSearchParamsWithoutUndefinedValues({
            isEditMode: "true",
            activeProfileTabId,
            activeTabId,
          }),
        });

        showSuccessNotification(t("duplicateReportSuccess"));
      } catch {
        showErrorNotification(t("duplicateReportError"));
      }
    },
  });
};
