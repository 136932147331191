import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE_SECONDARY, COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";
import { useAgendaContext } from "../contexts/agenda/AgendaProvider";
import { useUserContext } from "../contexts/User";
import { useGetGroupQuery } from "../graphql";

import Avatar from "./Avatar";

const FocusedTeamWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 1;
`;

const Badge = styled.div`
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 9px;
  background-color: ${COLOR_BLUE_SECONDARY};
  font-weight: 600;
  color: ${COLOR_WHITE};
  text-transform: uppercase;
  margin-bottom: 5px;
  width: fit-content;
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

export function FocusedTeam() {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();

  const { focusedAgendaGroupId } = useAgendaContext();

  const { data } = useGetGroupQuery({
    variables: {
      sessionId,
      id: focusedAgendaGroupId,
    },
    fetchPolicy: "cache-first",
    skip: !focusedAgendaGroupId,
  });

  if (!focusedAgendaGroupId || !data) {
    return null;
  }

  return (
    <FocusedTeamWrapper>
      <Avatar
        size={35}
        style={{ marginRight: 10 }}
        source={{ uri: data.getGroup?.owner?.thumb }}
        userName={data.getGroup?.name}
      />
      <div>
        <Badge>{t("viewingTeam")}</Badge>
        <Name>{data?.getGroup?.name}</Name>
      </div>
    </FocusedTeamWrapper>
  );
}
