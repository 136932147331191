import React from "react";
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import styled, { useTheme } from "styled-components";

import { Period } from "../../contexts/Reports";
import { getTodayDate } from "../../utils/date";
import { getPeriodStartEndUnitFromString } from "../../utils/getPeriodStartEndUnit";
import { ButtonColor, ButtonType, GeneralButton } from "../Button/";
import { CalendarWithDateInput } from "../CalendarWithDateInput";

import { DateButtonsRow } from "./ReportPeriodDateButtons";

type ReportPeriodSingleCalendarProps = {
  selectedPeriod?: Exclude<Period, "custom">;
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  setStartDate(date): void;
  setEndDate?(date): void;
};

const ReportPeriodSingleCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateButtonRowWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  margin-top: 10px;
  padding: ${({ isMobile }) => (isMobile ? "5px 0px" : "0px")};
  flex-direction: ${({ isMobile }) => (isMobile ? "row" : "column")};
  justify-content: ${({ isMobile }) => (isMobile ? "center" : "flex-start")};
`;

export function ReportPeriodSingleCalendar({
  selectedPeriod,
  selectedStartDate,
  selectedEndDate,
  setStartDate,
  setEndDate = () => {},
}: ReportPeriodSingleCalendarProps) {
  const { t } = useTranslation();
  const { isMobile } = useTheme();

  const handleDaySelect = (date: dayjs.Dayjs) => {
    const startDate = date.startOf(
      getPeriodStartEndUnitFromString(selectedPeriod)
    );

    setStartDate(startDate);
    setEndDate(
      selectedPeriod !== "day"
        ? date.endOf(getPeriodStartEndUnitFromString(selectedPeriod))
        : startDate
    );
  };

  const onSelectedPeriodClick = () => {
    const today = getTodayDate();

    setStartDate(
      today.startOf(getPeriodStartEndUnitFromString(selectedPeriod))
    );
    setEndDate(today.endOf(getPeriodStartEndUnitFromString(selectedPeriod)));
  };

  return (
    <ReportPeriodSingleCalendarWrapper>
      <CalendarWithDateInput
        value={selectedStartDate}
        from={selectedStartDate}
        to={selectedEndDate}
        set={handleDaySelect}
        inputVisibility="hiddenOnMobile"
        calendarVisibility="visible"
      />
      <DateButtonRowWrapper isMobile={isMobile}>
        <DateButtonsRow>
          <GeneralButton
            label={t(
              selectedPeriod === "month"
                ? "thisMonth"
                : selectedPeriod === "week"
                  ? "thisWeek"
                  : "today"
            )}
            colorVariant={ButtonColor.Blue}
            sizeType={ButtonType.Tiny}
            outlined
            onClick={onSelectedPeriodClick}
          />
        </DateButtonsRow>
      </DateButtonRowWrapper>
    </ReportPeriodSingleCalendarWrapper>
  );
}
