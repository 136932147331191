import dayjs, { Dayjs } from "dayjs";
import _uniqBy from "lodash/uniqBy";

import { TimeUnit } from "../../components/Report/models";
import { AggregateFunction } from "../../graphql";
import * as api from "../api";
import * as endpoints from "../endpoints";

import {
  getIncludeAC,
  getIncludeAcute,
  getIncludeChronic,
  getIncludeLoad,
} from "./acuteChronics";
import { CombineTestResult, CompareLatestTo, Periodization } from "./common";

export interface Value {
  ctr?: CombineTestResult;
  _val: number;
  _time?: number;
  _meas?: string;
  _row?: number;
  _set?: number;
  _cnt?: number;
  _diffPercent?: number;
  _diff?: number;
  _rgb?: string;
  _txt?: string;
  /* Percentage of scale for coloring */
  pos?: number;
  tifn?: boolean;
}

export interface Attribute {
  _guid: string;
  _name: string;
  _unitName: string;
  _isDuration?: boolean;
  _values: Value[];
  _min?: number;
  _max?: number;
  _ut?: TimeUnit;
}

export interface ExerciseStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Attribute[];
}

export interface TestStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Attribute[];
}

export interface TrainingLoadStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Attribute[];
}

export interface DrillStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Attribute[];
}

export interface AcuteChronicsStatistics {
  _agg: AggregateFunction;
  _guid: string;
  _name: string;
  _attributes: Omit<Attribute, "unitName">[];
}

export interface Athlete {
  _guid?: string;
  _name?: string;
  _exercises?: ExerciseStatistics[];
  _trainingLoad?: TrainingLoadStatistics[];
  _tests?: TestStatistics[];
  _drills?: DrillStatistics[];
  _acuteChronics?: AcuteChronicsStatistics[];
}

export interface JsonStatisticsForMeasurementsResponse {
  _athletes: Athlete[];
}

export type JsonStatisticsPeriod =
  | "continuous"
  | "days"
  | "weeks"
  | "months"
  | "years";

type TemplateItem = {
  /**
   * collectionId
   */
  dataType: string;
  /**
   * measurementId
   */
  templateGuid: string;
  /**
   * attributetId
   */
  attribute: string;
  /**
   * aggregation(s)
   */
  aggregation: AggregateFunction | AggregateFunction[];
  compareLatestTo?: CompareLatestTo;
  withPOS?: boolean;
};

export type FetchJsonStatisticsForMeasurementsArgs = {
  session: {
    sessionId: string;
  };
  focusedGroup?: string;
  focusedAthlete?: string;
  focusedUserAccount?: string;
  participants: {
    id: string;
  }[];
  items: TemplateItem[];
  fromDate: Dayjs;
  toDate: Dayjs;
  period: JsonStatisticsPeriod;
  compareLatestTo?: CompareLatestTo;
  reportTemplateId: string;
  startOfColoringRange?: Dayjs;
};

export type FetchJsonTeamStatisticsForMeasurementsArgs = {
  session: {
    sessionId: string;
  };
  focusedGroup?: string;
  focusedAthlete?: string;
  focusedUserAccount?: string;
  participants: {
    id: string;
  }[];
  items: Omit<TemplateItem, "compareLatestTo">[];
  fromDate: Dayjs;
  toDate: Dayjs;
  period: JsonStatisticsPeriod;
  reportTemplateId: string;
};

type RequestTemplateItem = {
  _guid: string;
  withPOS: boolean;
  _aggPerAtt: {
    _att: string;
    _agg: AggregateFunction[];
  }[];
  _compareLatestTo: CompareLatestTo;
  _compareLatestAtMostDaysBefore: number;
};

type RequestAcuteChronicTemplateItem = {
  _aggregation: string;
  _template: string;
  _includeAC: boolean;
  _includeAcute: boolean;
  _includeChronic: boolean;
  _includeLoad: boolean;
};

const prepareDataRetriever = ({
  accuteChronicArray,
  items,
  templateGuid,
  aggregation,
}: {
  accuteChronicArray: RequestAcuteChronicTemplateItem[];
  aggregation: AggregateFunction;
  templateGuid: string;
} & Pick<FetchJsonStatisticsForMeasurementsArgs, "items">) => {
  if (
    !accuteChronicArray.some(
      (item) =>
        item._template === templateGuid && item._aggregation === aggregation
    )
  ) {
    const itemsOfSameMeasurement = items.filter(
      (item) =>
        item.templateGuid === templateGuid &&
        (item.aggregation === aggregation ||
          (Array.isArray(item.aggregation) &&
            item.aggregation.includes(aggregation)))
    );

    const sharedConfig = {
      _template: templateGuid,
      _includeAC: itemsOfSameMeasurement.some((item) =>
        getIncludeAC(item.attribute)
      ),
      _includeAcute: itemsOfSameMeasurement.some((item) =>
        getIncludeAcute(item.attribute)
      ),
      _includeChronic: itemsOfSameMeasurement.some((item) =>
        getIncludeChronic(item.attribute)
      ),
      _includeLoad: itemsOfSameMeasurement.some((item) =>
        getIncludeLoad(item.attribute)
      ),
    };

    return { ...sharedConfig, _aggregation: aggregation };
  }

  return undefined;
};

export const fetchAthleteStatistics = async ({
  session,
  focusedGroup,
  focusedAthlete,
  focusedUserAccount,
  participants = [],
  items = [],
  fromDate,
  toDate,
  period,
  reportTemplateId,
  startOfColoringRange,
}: FetchJsonStatisticsForMeasurementsArgs) => {
  if (participants.length === 0) {
    return null;
  }

  const templates = prepareTemplates(items);

  const params = {
    _range: {
      _from: dayjs(fromDate).valueOf(),
      _to: dayjs(toDate).valueOf(),
      _onlyReturnLatest: false,
      startOfColoringRange: dayjs(startOfColoringRange).valueOf(),
      periodization: parsePeriod(period),
    },
    _focusedGroup: focusedGroup,
    _focusedAthlete: focusedAthlete,
    _focusedUserAccount: focusedUserAccount,
    _templates: templates,
    _participants: {
      _athleteGuids: participants.map((p: { id: string }) => p?.id),
    },
    reportTemplateId,
  };

  const response = await api.post<JsonStatisticsForMeasurementsResponse>(
    endpoints.JsonStatisticsForMeasurementsQuery,
    params,
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response.data;
};

export const fetchTeamAverageStatistics = async ({
  session,
  focusedGroup,
  focusedAthlete,
  focusedUserAccount,
  participants = [],
  items = [],
  fromDate,
  toDate,
  period,
  reportTemplateId,
}: FetchJsonTeamStatisticsForMeasurementsArgs) => {
  if (participants.length === 0) {
    return null;
  }

  const groupedItems = items.reduce<Record<string, typeof items>>(
    (prev, curr) => {
      (Array.isArray(curr.aggregation)
        ? curr.aggregation
        : [curr.aggregation]
      ).forEach((agg) => {
        if (!prev[agg]) {
          prev[agg] = [];
        }

        prev[agg].push(curr);
      });

      return prev;
    },
    {}
  );

  const data = await Promise.all(
    Object.entries(groupedItems).map(async ([agg, items]) => {
      const templates = prepareTemplates(
        _uniqBy(
          items,
          ({ dataType, templateGuid, attribute }) =>
            `${dataType}:${templateGuid}:${attribute}`
        ).map((item) => ({ ...item, aggregation: agg as AggregateFunction }))
      );

      const params = {
        _range: {
          _from: dayjs(fromDate).valueOf(),
          _to: dayjs(toDate).valueOf(),
          periodization: parsePeriod(period),
        },
        _focusedGroup: focusedGroup,
        _focusedAthlete: focusedAthlete,
        _focusedUserAccount: focusedUserAccount,
        _aggregation: {
          _aggregateFunction: AggregateFunction.Average,
        },
        _templates: templates,
        _ignoreNulls: true,
        _participants: {
          _athleteGuids: participants.map((p: { id: string }) => p?.id),
        },
        reportTemplateId,
      };

      try {
        const response = await api.post<JsonStatisticsForMeasurementsResponse>(
          endpoints.JsonStatisticsForMeasurementsQuery,
          params,
          session
        );

        if (response.status !== 200 || !response.data) {
          return null;
        }

        return {
          ...response.data,
          _athletes: response.data._athletes.map((athlete) => ({
            ...athlete,
            _exercises:
              athlete._exercises?.map((stats) => ({
                ...stats,
                _agg: agg as AggregateFunction,
              })) ?? [],
            _trainingLoad:
              athlete._trainingLoad?.map((stats) => ({
                ...stats,
                _agg: agg as AggregateFunction,
              })) ?? [],
            _tests:
              athlete._tests?.map((stats) => ({
                ...stats,
                _agg: agg as AggregateFunction,
              })) ?? [],
            _drills:
              athlete._drills?.map((stats) => ({
                ...stats,
                _agg: agg as AggregateFunction,
              })) ?? [],
            _acuteChronics:
              athlete._acuteChronics?.map((stats) => ({
                ...stats,
                _agg: agg as AggregateFunction,
              })) ?? [],
          })),
        };
      } catch (error) {
        console.error(error);

        return null;
      }
    })
  );

  const ret = data
    .filter(Boolean)
    .reduce<JsonStatisticsForMeasurementsResponse>(
      (prev, curr) => {
        return {
          ...prev,
          _athletes: [
            {
              ...prev._athletes[0],
              _exercises: [
                ...(prev._athletes[0]?._exercises ?? []),
                ...(curr._athletes[0]?._exercises ?? []),
              ],
              _trainingLoad: [
                ...(prev._athletes[0]?._trainingLoad ?? []),
                ...(curr._athletes[0]?._trainingLoad ?? []),
              ],
              _tests: [
                ...(prev._athletes[0]?._tests ?? []),
                ...(curr._athletes[0]?._tests ?? []),
              ],
              _drills: [
                ...(prev._athletes[0]?._drills ?? []),
                ...(curr._athletes[0]?._drills ?? []),
              ],
              _acuteChronics: [
                ...(prev._athletes[0]?._acuteChronics ?? []),
                ...(curr._athletes[0]?._acuteChronics ?? []),
              ],
            },
          ],
        };
      },
      { _athletes: [] }
    );

  return ret;
};

export const fetchTeamStatistics = async ({
  session,
  focusedGroup,
  focusedAthlete,
  focusedUserAccount,
  teams = [],
  items = [],
  fromDate,
  toDate,
  period,
  reportTemplateId,
}: Omit<FetchJsonTeamStatisticsForMeasurementsArgs, "participants"> & {
  teams: {
    id: string;
    participants: string[];
    name: string;
  }[];
}) => {
  if (teams.length === 0) {
    return null;
  }

  const data = await Promise.all(
    teams.map(async ({ id, name, participants }) => {
      try {
        const data = await fetchTeamAverageStatistics({
          fromDate,
          toDate,
          period,
          session,
          focusedGroup,
          focusedAthlete,
          focusedUserAccount,
          reportTemplateId,
          participants: participants.map((id) => ({ id })),
          items: _uniqBy(
            items,
            ({ dataType, templateGuid, attribute, aggregation }) =>
              `${dataType}:${templateGuid}:${attribute}:${aggregation}`
          ),
        });

        return {
          ...data,
          _athletes: [
            {
              _acuteChronics: [],
              _drills: [],
              _exercises: [],
              _tests: [],
              _trainingLoad: [],
              ...data?._athletes[0],
              _guid: id,
              _name: name,
            },
          ],
        };
      } catch (error) {
        console.error(error);

        return null;
      }
    })
  );

  return data
    ? data.reduce(
        (mem, curr) => ({
          ...mem,
          _athletes: [...mem._athletes, ...(curr?._athletes ?? [])],
        }),
        {
          _athletes: [],
        }
      )
    : null;
};

export function parsePeriod(
  period: FetchJsonStatisticsForMeasurementsArgs["period"]
): Periodization {
  switch (period) {
    case "days":
      return "Day";
    case "weeks":
      return "Week";
    case "months":
      return "Month";
    case "years":
      return "Year";
    case "continuous":
      return "Continuous";
    default:
      return undefined;
  }
}

function prepareTemplates(items: TemplateItem[] = []) {
  return items.reduce<{
    _acuteChronics: RequestAcuteChronicTemplateItem[];
    _exercises: RequestTemplateItem[];
    _tests: RequestTemplateItem[];
    _trainingLoads: RequestTemplateItem[];
    _drills: RequestTemplateItem[];
  }>(
    (templates, item) => {
      const { dataType } = item;
      const [typeOrId, type] = dataType.split(".");

      const getTemplateItem = ({
        aggregation,
        attribute,
        templateGuid,
        compareLatestTo,
        withPOS,
      }: TemplateItem): RequestTemplateItem => ({
        _guid: templateGuid,
        withPOS,
        _aggPerAtt: [
          {
            _att: attribute,
            _agg: Array.isArray(aggregation) ? aggregation : [aggregation],
          },
        ],
        _compareLatestTo: compareLatestTo || CompareLatestTo.NEXT_BEFORE,
        _compareLatestAtMostDaysBefore: 2147483647,
      });

      if (typeOrId.toLowerCase() === "acutechronic") {
        const { aggregation, templateGuid } = item;

        if (typeof aggregation === "string") {
          const accuteChronicItem = prepareDataRetriever({
            aggregation,
            items,
            templateGuid,
            accuteChronicArray: templates._acuteChronics,
          });

          if (accuteChronicItem) {
            templates._acuteChronics.push(accuteChronicItem);
          }
        } else {
          aggregation.map((agg) => {
            const accuteChronicItem = prepareDataRetriever({
              aggregation: agg,
              items,
              templateGuid,
              accuteChronicArray: templates._acuteChronics,
            });

            if (accuteChronicItem) {
              templates._acuteChronics.push(accuteChronicItem);
            }
          });
        }
      } else {
        const templateItem = getTemplateItem(item);

        switch (type?.toUpperCase()) {
          // Exercise
          case "EXERCISE":
          case "EXERCISE_SET": {
            addRequestTemplateItem(templateItem, templates._exercises);
            break;
          }

          // Test
          case "TEST": {
            addRequestTemplateItem(templateItem, templates._tests);
            break;
          }

          // Traning loads
          case "TRAININGLOAD":
          case "TRAINING_LOAD": {
            addRequestTemplateItem(templateItem, templates._trainingLoads);
            break;
          }

          // Drills
          case "DRILL": {
            addRequestTemplateItem(templateItem, templates._drills);
            break;
          }

          default:
            console.log("missing type", { type });
        }

        if (
          [
            "polar",
            "playertekcatapult",
            "catapult",
            "garmin",
            "statsports",
            "pubg",
            "kinexon",
            "firstbeat",
          ].includes(typeOrId.toLowerCase())
        ) {
          addRequestTemplateItem(templateItem, templates._tests);
        }
      }

      return templates;
    },
    {
      _acuteChronics: [],
      _exercises: [],
      _tests: [],
      _trainingLoads: [],
      _drills: [],
    }
  );
}

function addRequestTemplateItem(
  item: RequestTemplateItem,
  templates: RequestTemplateItem[]
) {
  const template = templates.find(
    ({ _guid, _compareLatestTo, _compareLatestAtMostDaysBefore }) =>
      _guid === item._guid &&
      _compareLatestTo === item._compareLatestTo &&
      _compareLatestAtMostDaysBefore === item._compareLatestAtMostDaysBefore
  );
  const attribute = template?._aggPerAtt.find(
    ({ _att }) => _att === item._aggPerAtt[0]._att
  );

  if (template && !attribute) {
    template._aggPerAtt.push(item._aggPerAtt[0]);

    return;
  }

  if (attribute) {
    !attribute._agg.includes(item._aggPerAtt[0]._agg[0]) &&
      attribute._agg.push(item._aggPerAtt[0]._agg[0]);

    return;
  }

  templates.push(item);
}
