import React, { useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";

import Highcharts from "highcharts";
import HighchartsGroupedCategories from "highcharts-grouped-categories";
import ReactHighcharts from "highcharts-react-official";
import { useTheme } from "styled-components";

import { WidgetContainer, WidgetTitle, WidgetTitleContainer } from "../styled";
import { WidgetLoader } from "../WidgetLoader";
import { WidgetNoData } from "../WidgetNoData";

import { ChartWidgetModel } from "./model";
import { GroupedCategoriesOptions } from "./utils/generateChartOptions";

HighchartsGroupedCategories(Highcharts);

interface ChartWidgetComponentProps {
  widget: ChartWidgetModel;
  isLoading: boolean;
  chartOptions: Highcharts.Options | GroupedCategoriesOptions;
}

export const MIN_HEIGHT = 248;
export const MOBILE_PADDING_TOP = 15;

export function ChartWidgetComponent({
  widget,
  isLoading,
  chartOptions,
}: ChartWidgetComponentProps) {
  // Fix chart dimension on widget resize
  const { height, ref } = useResizeDetector();
  const { isMobile } = useTheme();

  const options = useMemo(() => {
    const chartHeight = Math.max(height ?? 0, MIN_HEIGHT);

    return {
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        height: chartHeight,
      },
    };
  }, [height, chartOptions]);

  if (isLoading) {
    return <WidgetLoader />;
  }

  const noData =
    options?.series?.length === 0 ||
    options?.series?.every(
      (options) => "data" in options && options.data?.length === 0
    );

  const containerProps = {
    style: {
      paddingTop: MOBILE_PADDING_TOP,
    },
  };

  return (
    <WidgetContainer ref={ref} showBorders={widget.widgetBorders}>
      <div style={{ height: "100%", overflow: isMobile ? "hidden" : "auto" }}>
        <WidgetTitleContainer style={{ position: "absolute", zIndex: 2 }}>
          <WidgetTitle>{widget?.name}</WidgetTitle>
        </WidgetTitleContainer>

        {noData ? (
          <WidgetNoData />
        ) : (
          <ReactHighcharts
            highcharts={Highcharts}
            options={options}
            immutable
            containerProps={isMobile && containerProps}
          />
        )}
      </div>
    </WidgetContainer>
  );
}
