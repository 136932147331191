import { UserContextState } from "../contexts/User";

import * as api from "./api";
import * as endpoints from "./endpoints";

export interface GroupSelection {
  _groupGuid: string;
  _checked: boolean;
}

export function toggleGroupSelection(
  user: UserContextState,
  _items: GroupSelection[]
) {
  return api.post(
    endpoints.JsonGroupCheckUpdateRequest,
    {
      _items,
    },
    user
  );
}
