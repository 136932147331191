import React, { Fragment, useEffect } from "react";

import { FormikContextType, FormikValues, FormikErrors } from "formik";

const OBJECT_DELIMITERS = ".";
const ARRAY_START_DELIMITER = "[";
const ARRAY_END_DELIMITER = "]";

export interface FocusErrorProps {
  /**
   * Values from Formik provider.
   */
  formik: FormikContextType<FormikValues>;
  /**
   * Time in ms to execute the focus in the component with the error, by default 100ms.
   */
  focusDelay?: number;
}

/**
 * This component focus the first error in the Formik form after the validation.
 * Note: The first is not necessary the first on the screen, it's just the first
 * key in the error object, order is not guaranteed.
 */
export function FocusError({
  formik: { isSubmitting, isValidating, errors },
  focusDelay = 100,
}: FocusErrorProps) {
  useEffect(() => {
    if (isSubmitting && !isValidating) {
      const errorSelector =
        getFirstErrorInputSelectors(errors)
          .map((errorKey) => `[name="${errorKey}"]`)
          .join(",") ?? "";

      if (errorSelector.length && typeof document !== "undefined") {
        const errorElement: HTMLElement | null =
          document.querySelector(errorSelector);

        // This is to avoid the other components autofocus when submitting
        setTimeout(() => {
          if (
            errorElement instanceof HTMLInputElement &&
            errorElement.type === "hidden"
          ) {
            errorElement.parentElement?.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
          errorElement?.focus?.();
        }, focusDelay);
      }
    }
  }, [isSubmitting, isValidating, errors, focusDelay]);

  return <Fragment />;
}

function getFirstErrorInputSelectors(
  errors: FormikErrors<any>,
  errorKeys?: string[]
): string[] {
  const type = Object.prototype.toString.call(errors);
  const isObject = type === "[object Object]" || type === "[object Array]";
  const isArray = Array.isArray(errors);

  const [[key, value] = []] = isObject ? Object.entries(errors) : [];

  if (!value) {
    return errorKeys ?? [];
  }

  if (!errorKeys) {
    return getFirstErrorInputSelectors(value as any, [key]);
  }

  return isArray
    ? getFirstErrorInputSelectors(
        value as any,
        errorKeys.flatMap((errorKey) => [
          `${errorKey}${OBJECT_DELIMITERS}${key}`,
          `${errorKey}${ARRAY_START_DELIMITER}${key}${ARRAY_END_DELIMITER}`,
        ])
      )
    : isObject
      ? getFirstErrorInputSelectors(
          value as any,
          errorKeys.map((errorKey) => `${errorKey}${OBJECT_DELIMITERS}${key}`)
        )
      : errorKeys.map((errorKey) => `${errorKey}${OBJECT_DELIMITERS}${key}`);
}
