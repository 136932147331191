import gql from "graphql-tag";

export const MessageList2 = gql`
  query messageList2(
    $first: Int
    $after: String
    $sessionId: String!
    $timezone: String
    $language: String
    $marker: String
    $groupOrFriend: String!
    $maxCount: Int
  ) {
    messageList2(
      sessionId: $sessionId
      timezone: $timezone
      language: $language
      marker: $marker
      groupOrFriend: $groupOrFriend
      maxCount: $maxCount
    ) {
      ownImgUrl
      translationOfViewVideoMessage
      translationOfViewPicture
      marker
      containsBeginning
      messages2(first: $first, after: $after) {
        edges {
          msg {
            guid
            guidS
            isPrivateNote
            sent
            fromGuid
            fromGuidS
            fromName
            fromImgUrl
            toGuid
            toGuidS
            toName
            toImgUrl
            inReplyTo
            inReplyToS
            anonym
            isRead
            messageBody
            videoUrl
            videoThumbUrl
            imageUrl
            imageThumbUrl
            cursor
            id
            forwarded
            attachedFiles {
              mime
              url
              thumb
            }
            oldLinks {
              type
              guidOfUnderlying
              guidOfUnderlying2
              thorsGuid
              itemName
              url
              mcUrl
            }
          }
          cursor
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      avatars2 {
        img
        thumb
        name
        firstName
        lastName
        userID
      }
    }
  }
`;
