import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import Avatar from "./Avatar";
import StyledText from "./StyledText";

const initialYPosition = -100;
const finalYPosition = 65;
const animationDuration = 500;
const animationDelay = 500;
const animationPersistence = 2000;

const ToastContainer = styled.div<{ translateY: number }>`
  position: absolute;
  height: 60px;
  right: -35px;
  margin-left: 10px;
  z-index: 999;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  transform: translateY(${({ translateY }) => translateY}px);
  transition: transform ${animationDuration}ms;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 15px;
`;

const ToastTextWrapper = styled.div`
  margin-left: 15px;
  width: 250px;
`;

export const SwitchAccountToast = ({ user }) => {
  const { t } = useTranslation();
  const [translateY, setTranslateY] = useState(initialYPosition);

  useEffect(() => {
    if (user.userWasSwitched) {
      setTimeout(() => {
        setTranslateY(finalYPosition);
      }, animationDelay);

      setTimeout(
        () => {
          setTranslateY(initialYPosition);
        },
        animationDelay + animationDuration + animationPersistence
      );

      user.actions.setUser({ userWasSwitched: false });
    }
  }, [user.userWasSwitched, user.actions]);

  return (
    <ToastContainer translateY={translateY}>
      <ContentWrapper>
        <Avatar
          size={35}
          uri={user.profileImageUrlThumb}
          userName={user.userName}
        />
        <ToastTextWrapper>
          <StyledText fontWeight="bold">
            {t("switchedToAccount", { x: user.userName })}
          </StyledText>
        </ToastTextWrapper>
      </ContentWrapper>
    </ToastContainer>
  );
};
