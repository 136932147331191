export const getSearchParamsObject = (
  searchParams: URLSearchParams,
  paramsToExclude: string[] = []
): Record<string, string> => {
  const paramsObject = {};

  searchParams.forEach((value, key) => {
    if (!paramsToExclude.includes(key)) {
      paramsObject[key] = value;
    }
  });

  return paramsObject;
};
