import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
  COLOR_ORANGE,
  COLOR_RED_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { TrainingProblem } from "../../graphql";

export function InjuryOverviewOverlay({
  problems,
}: {
  problems: TrainingProblem[];
}) {
  const { t } = useTranslation();
  return (
    <StyledOverlay>
      {problems?.length ? (
        problems.map(({ severity, status, illness, coachComment, id }) => (
          <Item key={id}>
            <Status severity={severity}>{status}</Status>
            <Injury>{illness ? t("illness") : t("injury")}</Injury>
            <Comment>{coachComment}</Comment>
          </Item>
        ))
      ) : (
        <Item>
          <Status severity={0}>{t("available")}</Status>
        </Item>
      )}
    </StyledOverlay>
  );
}

const StyledOverlay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 0;
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0px 4px 16px rgba(60, 67, 76, 0.12);
  border-radius: 6px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  :not(:last-child) {
    border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  }
`;

const Status = styled.div<{ severity?: number }>`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: ${({ severity }) => {
    if (severity === 1) return COLOR_ORANGE;
    if (severity === 2) return COLOR_RED_ACTIVE;
    return COLOR_GREEN;
  }};
`;

const Injury = styled.div`
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`;

const Comment = styled.div`
  font-weight: 700;
  font-size: 12px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;
