import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../../../colors";
import { ReportItemType } from "../../../../graphql";
import {
  useReportBuildingCollections,
  useReportBuildingMeasurements,
} from "../../../../hooks";
import { requiredValidation } from "../../../../utils/form";
import { Checkbox } from "../../../Checkbox";
import { CheckboxList } from "../../../CheckboxList";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { SelectField, SelectOption } from "../../../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../../../styled";
import StyledText from "../../../StyledText";
import { ChartType, CombinedFormValue } from "../models";

const SectionSubTitle = styled(StyledText)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${COLOR_TEXT_DEFAULT};
  font-size: 14px;
  line-height: 17px;
`;

export function CombinedTestEditorDataSection({
  onSubTestChange,
}: {
  onSubTestChange(subTests: { id: string; name?: string }[]): void;
}) {
  const { t } = useTranslation();
  const { values, setFieldValue, setValues } =
    useFormikContext<CombinedFormValue>();

  const [subTests, setSubTests] = useState<{ id: string; name?: string }[]>([]);

  const {
    data: reportBuildingCollectionsResponse,
    loading: loadingCollections,
  } = useReportBuildingCollections({
    onCompleted(data) {
      const allowedCollections = data?.reportBuildingCollections?.filter(
        ({ reportItemType }) => reportItemType === ReportItemType.Test
      );
      if (allowedCollections?.length === 1) {
        setFieldValue("collectionId", allowedCollections[0]?.id ?? "");
      }
    },
  });

  const {
    data: reportBuildingMeasurementTemplatesResponse,
    loading: loadingMeasurements,
  } = useReportBuildingMeasurements({
    collectionId: values.collectionId,
    isCombinedTest: true,
  });

  const collectionOptions =
    reportBuildingCollectionsResponse?.reportBuildingCollections
      .filter(({ reportItemType }) => reportItemType === ReportItemType.Test)
      .map(({ name, id }) => ({
        label: name,
        value: id,
      })) ?? [];

  const measurementOptions =
    reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates.map(
      ({ id, name, subTests }) => ({
        label: name,
        value: id,
        subTests,
      })
    ) ?? [];

  useEffect(() => {
    setSubTests([]);

    const measurementTemplate =
      reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates?.find(
        (measurement) => measurement.id === values.templateId
      );
    if (measurementTemplate) {
      setSubTests(measurementTemplate.subTests ?? []);
    }
  }, [
    reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates,
    values.templateId,
  ]);

  useEffect(() => {
    onSubTestChange(subTests);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTests]);

  return (
    <Section>
      <SectionTitle title={t("data")} />

      <DraggableLineWrapper>
        <FieldWrapper
          style={collectionOptions?.length === 1 ? { display: "none" } : {}}
        >
          <Field name={"collectionId"} validate={requiredValidation}>
            {({ field, form, meta }) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={collectionOptions}
              />
            )}
          </Field>
        </FieldWrapper>
        <FieldWrapper>
          <Field name={"templateId"} validate={requiredValidation}>
            {({ field, form, meta }) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={measurementOptions}
                onChange={(
                  option: SelectOption & {
                    subTests: {
                      name?: string;
                      id: string;
                    }[];
                  }
                ) => {
                  setValues((values) => ({
                    ...values,
                    templateId: option.value,
                    templateName: option.label,
                    visibleSubTests: option.subTests?.map(({ id }) => id) ?? [],
                  }));
                }}
                isLoading={loadingCollections || loadingMeasurements}
              />
            )}
          </Field>
        </FieldWrapper>
      </DraggableLineWrapper>

      {values.chartType === ChartType.RADAR && Boolean(values.templateId) && (
        <Checkbox
          text={t("showTeamAverage")}
          check={(checked) => {
            setFieldValue("showTeamAverage", checked);
          }}
          checked={values.showTeamAverage}
        />
      )}
      {subTests.length > 0 && values.chartType === ChartType.CIRCLE && (
        <>
          <SectionSubTitle style={{ marginTop: 20 }}>
            Visible sub tests
          </SectionSubTitle>
          <CheckboxList
            items={subTests.map(({ id }) => id)}
            getDisplayValue={(id) => subTests.find((t) => t.id === id)?.name}
            value={values.visibleSubTests}
            onChange={(checked) => {
              setFieldValue("visibleSubTests", checked);
            }}
          />
        </>
      )}
    </Section>
  );
}
