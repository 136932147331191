import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { FormikProvider, useFormik } from "formik";
import styled, { useTheme } from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { useReportContext } from "../../contexts/report";
import { useUserContext } from "../../contexts/User";
import {
  useSaveReportTemplateMutation,
  ReportTemplateInput,
  ReportDocument,
} from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { isRouteParamTrue } from "../../utils/route";
import { AutoSubmitFormikForm } from "../AutoSubmitFormikForm";
import StyledText from "../StyledText";
import { StyledTextInput } from "../StyledTextInput";

import { AthleteSelectorOnMobile } from "./mobile/AthleteSelectorOnMobile";
import { ReportPeriodOnMobile } from "./mobile/ReportPeriodOnMobile";
import ReportGroupSelector from "./ReportGroupSelector";
import { ReportPeriodSelection } from "./ReportPeriodSelection";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7.5px;
`;

const ReportPeriodOnMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const GroupNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

const NameInput = styled(StyledTextInput)`
  width: 500px;
  background-color: ${COLOR_WHITE};
  height: 45px;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-radius: 6px;
  border-style: solid;
`;

const ReportPeriodSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 2;
`;

const ReportNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => (!theme.isMobile ? "30px" : "15px 15px 0")};
  z-index: 2;
  border-bottom-width: ${({ theme }) => (!theme.isMobile ? 1 : 0)}px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
`;

export function ReportScreenHeader() {
  const { isMobile } = useTheme();
  const { t } = useTranslation();

  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const [searchParams] = useSearchParams();
  const isEditMode = isRouteParamTrue(searchParams.get("isEditMode"));
  const [isTitleInitialized, setIsTitleInitialized] = useState(false);

  const [saveReport] = useSaveReportTemplateMutation();

  async function handleSaveReport({ id, name }: ReportTemplateInput) {
    if (report?.name !== name && Boolean(name)) {
      return saveReport({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id,
            name,
          },
        },
        refetchQueries: [ReportDocument],
      });
    }
  }

  const formik = useFormik<{ name: string }>({
    initialValues: {
      name: "",
    },
    async onSubmit(values) {
      return handleSaveReport({ id: report?.id, name: values.name });
    },
  });

  const { values, handleChange, handleBlur, setValues } = formik;

  useEffect(() => {
    if (report?.name && !isTitleInitialized) {
      setValues({
        name: report?.name,
      });
      setIsTitleInitialized(true);
    }
  }, [report, isTitleInitialized, setIsTitleInitialized, setValues]);

  return (
    <HeaderWrapper>
      {!isMobile && (
        <Wrapper>
          <ReportPeriodSelectionWrapper>
            <ReportPeriodSelection />
          </ReportPeriodSelectionWrapper>
          <GroupNameWrapper style={{ marginTop: 15 }}>
            <ReportGroupSelector />
            {isEditMode && (
              <FormikProvider value={formik}>
                <ReportNameWrapper>
                  <StyledText
                    color={COLOR_MEDIUM_GRAY}
                    fontSize={10}
                    style={{ marginBottom: 5, textTransform: "uppercase" }}
                  >
                    {t("reportName")}
                  </StyledText>
                  <NameInput
                    value={values.name || ""}
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </ReportNameWrapper>

                <AutoSubmitFormikForm isEnables={isEditMode} delay={500} />
              </FormikProvider>
            )}
          </GroupNameWrapper>
        </Wrapper>
      )}
      {isMobile && (
        <MobileWrapper>
          <ReportPeriodOnMobileWrapper>
            <ReportPeriodOnMobile />
          </ReportPeriodOnMobileWrapper>
          <AthleteSelectorOnMobile />
        </MobileWrapper>
      )}
    </HeaderWrapper>
  );
}
