import React, { useMemo, useRef } from "react";

import { COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { AggregateFunction } from "../../../graphql";
import { isURL } from "../../../utils/isURL";
import { isCountAttribute } from "../../../utils/reports";
import { LinkUrl } from "../../LinkUrl";
import { IRule } from "../../ReportCreateTable/models";
import { AthleteColumnTd, BorderedCellTd, ValueCellText } from "../styled";
import { getRulesStyle, getValueToDisplay } from "../utils";

import { ITableRowModel } from "./model";

interface AlternateTableRowProps {
  colNames: AggregateFunction[];
  alternatingColumns?: boolean;
  rowDividers?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  rules: IRule[];
  color: string;
  isFirst?: boolean;
  isLast?: boolean;
  showValuesAsText?: boolean;
  item: ITableRowModel;
}

// const isTextValue = (value: string) =>
//   typeof value === "string" && isNaN(parseFloat(value)); // Input "14,5" would pass this check but ```Number("14,5")``` produces NaN

export const TableRow = ({
  colNames,
  alternatingColumns,
  rowDividers,
  columnDividers,
  outsideBorderDividers,
  rules,
  color,
  isFirst,
  isLast,
  showValuesAsText,
  item,
}: AlternateTableRowProps) => {
  const cellOrderRef = useRef(0);

  const attributeUnit = useMemo(() => {
    if (item.attributeTemplateName === "Result") {
      return item.attributeTemplateUnitName
        ? item.attributeTemplateUnitName
        : "";
    }
    return (
      item.attributeTemplateUnitName ||
      (item.attributeTemplateName?.includes("(")
        ? item.attributeTemplateName.match(/\((.*)\)/).pop()
        : item.attributeTemplateName)
    );
  }, [item.attributeTemplateName, item.attributeTemplateUnitName]);

  if (!item.cells.length) {
    return null;
  }

  cellOrderRef.current = 0;

  return (
    <tr
      style={{
        backgroundColor: color,
        height: 45,
      }}
    >
      <AthleteColumnTd
        key="athlete-column"
        style={{
          backgroundColor: color,
          color: COLOR_TEXT_TABLE_LABEL,
        }}
        shouldDisplayLeftBorder={outsideBorderDividers}
        shouldDisplayRightBorder={columnDividers}
        shouldDisplayTopBorder={outsideBorderDividers && isFirst}
        shouldDisplayBottomBorder={
          (rowDividers && !isLast) || (outsideBorderDividers && isLast)
        }
        className="athlete-column-custom-padding-left"
      >
        <div>{item.label}</div>
      </AthleteColumnTd>
      {item.cells.map((value, valueIndex) => {
        const rulesToApply = getRulesStyle(
          {
            measurementTemplateId: item.measurementTemplateId,
            attributeTemplateId: item.attributeTemplateId,
            aggregation: colNames[valueIndex],
            value: getValueToDisplay(false, value).toString(),
            rgb: `#${value.rgb}`,
            isInfo: value.isInfo,
          },
          rules
        );

        const isLastColumnInRow = valueIndex === item.cells.length - 1;

        return (
          <BorderedCellTd
            key={`${item.label}:${valueIndex}:${value.value}:${value.txt}`}
            withBackground={
              alternatingColumns && cellOrderRef.current++ % 2 === 0
            }
            shouldDisplayLeftBorder={false}
            shouldDisplayTopBorder={outsideBorderDividers && isFirst}
            shouldDisplayRightBorder={
              (columnDividers && !isLastColumnInRow) ||
              (outsideBorderDividers && isLastColumnInRow)
            }
            shouldDisplayBottomBorder={
              (rowDividers && !isLast) || (outsideBorderDividers && isLast)
            }
          >
            {isURL(value.txt || "") ? (
              <LinkUrl
                size={12}
                url={value.txt}
                wrapperStyle={{
                  display: "flex",
                  alignItems: "center",
                }}
              />
            ) : (
              <ValueCellText style={rulesToApply}>
                {item.label
                  ? `${getValueToDisplay(showValuesAsText, value)}\u00A0${
                      (typeof value.value === "number" &&
                        !value.txt &&
                        !isCountAttribute(item.attributeTemplateId) &&
                        attributeUnit) ||
                      ""
                    }`
                  : ""}
              </ValueCellText>
            )}
          </BorderedCellTd>
        );
      })}
    </tr>
  );
};
