import React, { useState } from "react";

import { BodyOutline } from "./BodyOutline";
import { BodyParts } from "./BodyParts";
import { BodySection } from "./BodySection";
import { BodyPartsType, BodyRegistration } from "./model";
import { HEIGHT, WIDTH } from "./SectionConfig";

type BodyChartSvgProps = {
  registrations: BodyRegistration;
  side: BodyChartSide;
  sectionsVisible?: boolean;
  customHeight?: number;
  customWidth?: number;
  customScale?: number;
  bodyPartSelected?: (id: number) => void;
};

const ZOOM_SCALE = 2;

const getZoomViewBox = (focusedSection: BodyPartsType | null) => {
  switch (focusedSection) {
    case "torso-head":
      return "70 -40";

    case "left-arm":
      return "-17 57";

    case "left-leg":
      return "-17 350";

    case "right-arm":
      return "170 57";

    case "right-leg":
      return "170 350";

    default:
      return "-17 -57";
  }
};

const sections = [
  "right-leg",
  "left-leg",
  "right-arm",
  "left-arm",
  "torso-head",
] as BodyPartsType[];

export type BodyChartSide = "front" | "back";

export const BodyChartSvg = ({
  registrations,
  side,
  sectionsVisible = true,
  customHeight = HEIGHT,
  customWidth = WIDTH,
  customScale,
  bodyPartSelected,
}: BodyChartSvgProps) => {
  const [focusedSection, setFocusedSection] = useState<null | BodyPartsType>(
    null
  );

  const scale = focusedSection !== null ? ZOOM_SCALE : customScale || 1;
  let height = customHeight + (focusedSection === "torso-head" ? 100 : 0);

  if (side === "back") {
    height += 60;
  }

  const onBodyPartClick = (id: number, sectionId: BodyPartsType) => {
    if (focusedSection && focusedSection !== sectionId) {
      setFocusedSection(null);
    } else {
      bodyPartSelected?.(id);
    }
  };

  const onBodySectionClick = (sectionId: BodyPartsType) =>
    setFocusedSection(focusedSection === sectionId ? null : sectionId);

  return (
    <svg
      width={customWidth}
      height={height}
      viewBox={`${getZoomViewBox(focusedSection)} ${customWidth / scale} ${
        height / scale
      }`}
    >
      <rect
        fill="none"
        x="-17"
        y="-57"
        width="100%"
        height="100%"
        onClick={() => {
          setFocusedSection(null);
        }}
      />
      <g>
        {sections.map((sectionId) => (
          <BodyParts
            sectionId={sectionId}
            key={sectionId}
            side={side}
            scale={scale}
            focusedSection={focusedSection}
            registrations={registrations}
            onBodyPartClick={onBodyPartClick}
          />
        ))}
        <BodyOutline
          side={side}
          scale={scale}
          focusedSection={focusedSection}
        />
        {sectionsVisible &&
          sections.map((sectionId) => (
            <BodySection
              key={sectionId}
              sectionId={sectionId}
              side={side}
              scale={scale}
              focusedSection={focusedSection}
              onBodySectionClick={onBodySectionClick}
            />
          ))}
      </g>
    </svg>
  );
};
