import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "axios";
import fileDownload from "js-file-download";
import styled from "styled-components";

import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import Dropdown from "../../Dropdown";
import Hoverable from "../../Hoverable";
import { IconButton } from "../../IconButton";
import { ThreeDotsIcon } from "../../Icons";
import { OptionItemText, StyledOptionItem } from "../../Report/styled";

const IconButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 10px;
  transform: rotate(90deg);
`;

interface ChatMessageDropdownProps {
  setSelectedMessageId: (input: any) => void;
  isReceived: boolean;
  msg: any;
  canDownload: (item: any) => boolean;
  onRowPress: (contact) => void;
  onDeleteMessage: (messageId) => void;
  selectedMessageId: any;
  messageError: any;
}

export const ChatMessageDropdown = ({
  setSelectedMessageId,
  isReceived,
  messageError,
  msg,
  selectedMessageId,
  onDeleteMessage,
  onRowPress,
  canDownload,
}: ChatMessageDropdownProps) => {
  const user = useUserContext();
  const divRef = useRef(null);
  const [belowThreshold, setBelowThreshold] = useState<boolean>();

  return (
    <div style={{ position: "relative" }} ref={divRef}>
      <Dropdown
        dropdownContainerStyle={{
          minWidth: 170,
          bottom: belowThreshold ? 10 : "auto",
          right: 0,
          top: belowThreshold ? "auto" : 10,
        }}
        dropdownContentStyle={{
          bottom: 10,
          right: 10,
          padding: "5px 0",
        }}
        component={({ toggle, show }) => (
          <IconButtonWrapper>
            <IconButton
              icon={<ThreeDotsIcon isActive={show} />}
              enforceActiveState={show}
              onClick={() => {
                setBelowThreshold(
                  divRef?.current?.getBoundingClientRect &&
                    divRef?.current?.getBoundingClientRect()?.top >
                      window.innerHeight * 0.7
                );
                setSelectedMessageId(msg.id);
                toggle();
              }}
            />
          </IconButtonWrapper>
        )}
      >
        <MessageDropdown
          isReceived={isReceived}
          msg={msg}
          isTrainer={isRealTrainer(user)}
          canDownload={
            msg.attachedFiles.length > 0 && canDownload(msg.attachedFiles)
          }
          onRowPress={onRowPress}
          onDeleteMessage={onDeleteMessage}
          selectedMessageId={selectedMessageId}
          setSelectedMessageId={setSelectedMessageId}
          messageError={!!messageError}
        />
      </Dropdown>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MessageDropdown = ({
  toggle,
  show,
  msg,
  onRowPress,
  onDeleteMessage,
  isTrainer,
  canDownload,
  setSelectedMessageId,
  selectedMessageId,
  isReceived,
}: any) => {
  const modal = useModalContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (!!selectedMessageId && show && selectedMessageId !== msg.id)
      toggle(false);
  }, [msg.id, selectedMessageId, show, toggle]);

  const handleDelete = async () => {
    await onDeleteMessage(msg.id);
    await setSelectedMessageId(null);
    await toggle();
  };

  const handleForward = () => {
    modal.actions.openModal({
      modal: ModalType.CREATE_NEW_GROUP_CHAT,
      title: t("forwardMessageTo"),
      params: {
        msg,
        onRowPress,
        onShow: toggle,
        disableCancel: true,
        isForwardMessageVariant: true,
      },
    });
  };

  const handleDownload = async () => {
    msg.attachedFiles.map((file) => {
      const parsedUrl = new URL(file.url);
      const fileName = parsedUrl.pathname.split("/").pop();
      const fileNameParts = fileName.split(".");
      const finalFileName = fileNameParts.slice(1).join(".");

      axios
        .get(file.url, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res.data, finalFileName);
        });
    });
    await toggle();
  };

  const handleCopyText = async () => {
    const textToCopy = msg.messageBody;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(textToCopy);
    } else {
      document.execCommand("copy", true, textToCopy);
    }
    await toggle();
  };

  return (
    <>
      {!isReceived && (
        <Hoverable>
          {(isHovered) => (
            <StyledOptionItem isHovered={isHovered} onClick={handleDelete}>
              <OptionItemText>{t("delete")}</OptionItemText>
            </StyledOptionItem>
          )}
        </Hoverable>
      )}
      <Hoverable>
        {(isHovered) => (
          <StyledOptionItem isHovered={isHovered} onClick={handleForward}>
            <OptionItemText>{t("forward")}</OptionItemText>
          </StyledOptionItem>
        )}
      </Hoverable>
      {isTrainer && canDownload && (
        <Hoverable>
          {(isHovered) => (
            <StyledOptionItem isHovered={isHovered} onClick={handleDownload}>
              <OptionItemText>{t("download")}</OptionItemText>
            </StyledOptionItem>
          )}
        </Hoverable>
      )}
      {msg.messageBody ? (
        <Hoverable>
          {(isHovered) => (
            <StyledOptionItem isHovered={isHovered} onClick={handleCopyText}>
              <OptionItemText>{t("copyText")}</OptionItemText>
            </StyledOptionItem>
          )}
        </Hoverable>
      ) : null}
    </>
  );
};
