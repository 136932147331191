import React from "react";
import { ToolbarProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../colors";
import { useDefaultDateFormat } from "../../hooks/useDateFormat";
import { isTvEnv } from "../../utils/isTvEnv";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { ChevronDownIcon, FilterIcon, NextIcon, PreviousIcon } from "../Icons";
import { ReportSingleDayCalendar } from "../Report/ReportSingleDayCalendar";

type CustomResourceCalendarToolbarProps = ToolbarProps<CustomEvent> & {
  date: Date;
  onOpenFilter: () => void;
  onNavigate: (date: Date) => void;
  onSetDay?: (date: Dayjs) => void;
};

enum NavigateAction {
  PREV = "PREV",
  NEXT = "NEXT",
}

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 20px 27px;
  align-items: center;
  justify-content: space-between;
`;

const PeriodSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

enum ChevronButtonOrientation {
  LEFT = "left",
  RIGHT = "right",
}

const ChevronButton = styled.button<{ orientation: ChevronButtonOrientation }>`
  height: 35px;
  width: 35px;
  background-color: ${COLOR_GRAY};
  border: 1px solid rgba(60, 67, 76, 0.04);
  justify-content: center;
  align-items: center;
  display: flex;
  border-top-right-radius: ${({ orientation }) =>
    orientation === ChevronButtonOrientation.RIGHT ? 6 : 0}px;
  border-bottom-right-radius: ${({ orientation }) =>
    orientation === ChevronButtonOrientation.RIGHT ? 6 : 0}px;
  border-top-left-radius: ${({ orientation }) =>
    orientation === ChevronButtonOrientation.LEFT ? 6 : 0}px;
  border-bottom-left-radius: ${({ orientation }) =>
    orientation === ChevronButtonOrientation.LEFT ? 6 : 0}px;
  cursor: pointer;
`;

const DateLabelWrapper = styled(BasicButton)`
  flex: 1;
  margin-left: 25px;
  flex-direction: row;
  align-items: center;
`;

const DateLabel = styled.span<{
  bold?: boolean;
  withLeftMargin?: boolean;
}>`
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
  font-size: 20px;
  line-height: 24px;
  color: ${COLOR_TEXT_DARK};
  margin-left: ${({ withLeftMargin }) => `${withLeftMargin ? 5 : 0}px`};
`;

const ChevronIconWrapper = styled.div`
  margin-left: 10px;
  border: 1px solid rgba(60, 67, 76, 0.04);
  border-radius: 50%;
  width: 21px;
  height: 21px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export function CustomResourceCalendarToolbar({
  onOpenFilter,
  onSetDay,
  onNavigate,
  date,
}: CustomResourceCalendarToolbarProps) {
  const { t } = useTranslation();
  const dateInFormat = dayjs(date);
  const { wordMonthDateFormat } = useDefaultDateFormat();
  const formatedLabel = dateInFormat.format(wordMonthDateFormat);
  const isTv = isTvEnv();

  return (
    <ToolbarWrapper>
      <PeriodSwitcherWrapper>
        {!isTv && (
          <>
            <ChevronButton
              orientation={ChevronButtonOrientation.LEFT}
              onClick={() => onNavigate(NavigateAction.PREV)}
            >
              <PreviousIcon />
            </ChevronButton>
            <ChevronButton
              orientation={ChevronButtonOrientation.RIGHT}
              onClick={() => onNavigate(NavigateAction.NEXT)}
            >
              <NextIcon />
            </ChevronButton>
          </>
        )}
        <Dropdown
          component={({ toggle }) => (
            <DateLabelWrapper disabled={isTv} onClick={toggle}>
              <DateLabel bold>{t("day")}:</DateLabel>
              <DateLabel withLeftMargin>{formatedLabel}</DateLabel>
              {!isTv && (
                <ChevronIconWrapper>
                  <ChevronDownIcon />
                </ChevronIconWrapper>
              )}
            </DateLabelWrapper>
          )}
          dropdownContentStyle={{
            padding: 20,
            marginTop: 35,
            borderRadius: 12,
            left: 40,
          }}
          dropdownMainContainerStyle={{
            position: "relative",
            display: "flex",
          }}
        >
          {!isTv && (
            <ReportSingleDayCalendar
              selectedSingleDay={dateInFormat}
              setSingleDay={onSetDay}
            />
          )}
        </Dropdown>
      </PeriodSwitcherWrapper>
      <BasicButton onClick={onOpenFilter}>
        <FilterIcon />
      </BasicButton>
    </ToolbarWrapper>
  );
}
