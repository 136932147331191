export type Periodization = "Continuous" | "Day" | "Week" | "Month" | "Year";

export enum CompareLatestTo {
  DAY_BEFORE = "DayBefore",
  FIRST_IN_PERIOD = "FirstInPeriod",
  NEXT_BEFORE = "NextBefore",
  LATEST = "Latest",
}

export type CombineTestSubResult = {
  _rgb?: string;
  _score: number;
  _diff: number;
  _name: string;
  _template: string;
  _max: number;
  _min: number;
  _txt: string;
  _val: string;
};

export type CombineTestResult = {
  _rgb?: string;
  _scoreAbsolute: number;
  _diffAbsolute: number;
  _maxAbsolute: number;
  _minAbsolute: number;
  _date?: number;
  _score: number;
  _diff?: number;
  _max?: number;
  _min?: number;
  _diffPercent?: number;
  _subResults: CombineTestSubResult[];
};
