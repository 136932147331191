import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../colors";
import { useResourceCalendarContext } from "../contexts/resourceCalendar/ResourceCalendarProvider";
import { ModalType, useModalContext } from "../contexts/UI/Modal";

import {
  CustomResourceCalendarEvent,
  CustomCalendarResourceHeader,
  CustomResourceCalendarToolbar,
} from "./Calendar/";
import StyledText from "./StyledText";

const ResourceModalHeaderTitle = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 20px;
  margin-right: 24px;
`;

const createEventComponent = () => (props) => {
  const { resourceList } = useResourceCalendarContext();

  return (
    <CustomResourceCalendarEvent
      {...props}
      hasOneColumn={resourceList?.length === 1}
    />
  );
};

const createToolbarComponent = () => (props) => {
  const modal = useModalContext();
  const { resourceList, setResourceList, refetchPreference, onSetDay } =
    useResourceCalendarContext();
  const { t } = useTranslation();

  const onOpenFilter = () => {
    modal.actions.openModal({
      modal: ModalType.RESOURCE,
      title: (
        <ResourceModalHeaderTitle>{t("resources")}</ResourceModalHeaderTitle>
      ),
      titleAlignment: "flex-start",
      params: {
        resourceList,
        setResourceList,
        onSave: refetchPreference,
      },
    });
  };

  return (
    <div style={{ zIndex: 10 }}>
      <CustomResourceCalendarToolbar
        {...props}
        onOpenFilter={onOpenFilter}
        onSetDay={onSetDay}
      />
    </div>
  );
};

export const CALENDAR_COMPONENTS = {
  event: createEventComponent(),
  toolbar: createToolbarComponent(),
  resourceHeader: CustomCalendarResourceHeader,
};
