import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DEFAULT } from "../colors";
import { useAccessLevelsContext } from "../contexts/accessLevels";
import { useNotificationsContext } from "../contexts/notifications";
import { usePracticeContext } from "../contexts/practice/PracticeProvider";
import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import {
  AgendaDocument,
  CalendarDocument,
  useCreatePracticeMutation,
  useGetTheGroupsImInLazyQuery,
} from "../graphql";
import { useCreateSingleSessionPracticeTemplate } from "../hooks/data/useCreateSingleSessionPracticeTemplate";
import { useDeleteSession } from "../hooks/data/useDeleteSession";
import { useUpdateSession } from "../hooks/data/useUpdateSession";
import { useNavigateToWorkoutEditor } from "../hooks/sessions/useNavigateToWorkoutEditor";
import { useCreateGroupChat } from "../hooks/useCreateGroupChat";
import { useNavigateBack } from "../hooks/useNavigateBack";
import useSendManualNotificationAfterAction from "../hooks/useSendManualNotificationAfterAction";
import { isRealTrainer } from "../utils/isRealTrainer";

import { RefetchAgendaFunc } from "./Agenda/model";
import { ButtonColor } from "./Button/";
import Dropdown from "./Dropdown";
import { IconButton } from "./IconButton";
import {
  BellIcon,
  CancelRoundedIcon,
  ChatIcon,
  DuplicateIcon,
  EditPencilIcon,
  NewDocumentIcon,
  ThreeDotsIcon,
  TrashIcon,
} from "./Icons";
import { rgbToHex } from "./Modals/utils";

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;

  &:hover {
    cursor: pointer;
    background-color: ${COLOR_GRAY};
  }
`;

const Text = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${COLOR_TEXT_DEFAULT};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  align-items: center;
  background-color: ${COLOR_GRAY};
  border-radius: 50px;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-right: 5px;
  width: 35px;
`;

interface SessionDropdownProps {
  session: any;
  onClick?(show: boolean): void;
  cb?: RefetchAgendaFunc;
  isSessionRoute?: boolean;
  isProgramPreview: boolean;
  shouldNavigateBack?: boolean;
  refetch?: () => void;
}

export function SessionDropdown({
  session,
  onClick,
  cb,
  isSessionRoute,
  isProgramPreview,
  shouldNavigateBack,
  refetch,
}: SessionDropdownProps) {
  const modal = useModalContext();
  const { t } = useTranslation();
  const { deleteSessionMutation } = useDeleteSession();
  const { updateSessionMutation } = useUpdateSession();
  const [createPracticeMutation] = useCreatePracticeMutation();
  const { saveSessionAsTemplate } = useCreateSingleSessionPracticeTemplate();
  const user = useUserContext();
  const sessionType = session.__typename.toLowerCase() as
    | "workout"
    | "practice"
    | "game"
    | "event";
  const { selectedAccount } = useAccessLevelsContext();
  const { setPracticeSession } = usePracticeContext();
  const { createGroupChatForSession } = useCreateGroupChat();
  const { navigateToWorkoutEditor } = useNavigateToWorkoutEditor();
  const navigateBack = useNavigateBack();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const isPractice = session?.__typename === "Practice";
  const [fetchGetTheGroupsQuery] = useGetTheGroupsImInLazyQuery();

  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const client = useApolloClient();

  const openEditModal = async (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    setPracticeSession(session as any);

    modal.actions.openModal({
      modal: isPractice
        ? ModalType.CREATE_PRACTICE_SESSION
        : ModalType.CREATE_EVENT_OR_GAME,
      title: isPractice ? "" : `${t("update")} ${t(sessionType)}`,
      params: {
        session,
        eventType: session?.__typename,
        disableCancel: true,
        openedForSpecificGroupId: session?.groupId,
      },
    });
  };

  const editHandler = async (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (session?.__typename === "Workout") {
      return navigateToWorkoutEditor({ sessionGuid: session.id });
    }

    openEditModal(event);
  };

  const handleCancellationStatusChange = async (cancelled: boolean) => {
    try {
      await updateSessionMutation(session, () => ["agenda"], {
        cancelled,
      });
      await refetch?.();
      showSuccessNotification(
        t(cancelled ? "eventCancelled" : "eventCancellationRevoked")
      );
    } catch {
      showErrorNotification(
        t(cancelled ? "eventCancelError" : "eventCancellationRevokedError")
      );
    }
    modal.actions.closeModal();
  };

  const onEventCancellationOrRevokeClick = (
    event: MouseEvent<HTMLDivElement>
  ) => {
    if (!session.cancelled) {
      event.stopPropagation();

      modal.actions.openModal({
        modal: ModalType.CONFIRM,
        title: t("confirm"),
        params: {
          content: t("cancelConfirmQuestion", {
            x: t(sessionType).toLowerCase(),
          }),
          okButtonText: t("yes"),
          okButtonVariant: ButtonColor.Red,
          cancelButtonText: t("no"),
          onOK: () => handleCancellationStatusChange(!session.cancelled),
        },
      });
    } else {
      handleCancellationStatusChange(!session.cancelled);
    }
  };

  const openSaveAsTemplateModal = () => {
    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title: t("confirm"),
      params: {
        content: t("saveAsTemplateConfirmQuestion", {
          x: t(sessionType).toLowerCase(),
        }),
        okButtonText: t("save"),
        onOK: async () => {
          try {
            await saveSessionAsTemplate(session.id, session.label, () => [
              "agenda",
            ]);
            showSuccessNotification(t("templateSaveSuccess"));
          } catch {
            showErrorNotification(t("templateSaveError"));
          }
          modal.actions.closeModal();
        },
      },
    });
  };

  const openDeleteModal = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title: t("confirm"),
      params: {
        content: t("deleteConfirmQuestion", {
          x: t(sessionType).toLowerCase(),
        }),
        okButtonText: t("delete"),
        onOK: async () => {
          try {
            await deleteSessionMutation(session, () => []);
            if (shouldNavigateBack) {
              client.refetchQueries({
                include: [AgendaDocument, CalendarDocument],
              });
              navigateBack();
            } else {
              client.refetchQueries({
                include: [AgendaDocument, CalendarDocument],
              });
              await cb?.({
                deleteAgendaItem: {
                  id: session.id,
                },
              });
            }

            showSuccessNotification(t("sessionDeleteSuccess"));
          } catch {
            showErrorNotification(t("sessionDeleteError"));
          }
        },
      },
    });
  };

  const onDuplicateClick = () => {
    if (session?.__typename === "Workout") {
      return modal.actions.openModal({
        modal: ModalType.DUPLICATE_WORKOUT_SELECT_ATHLETE,
        title: t("selectAthletes"),
        params: {
          workoutId: session.id,
          start: session.start,
        },
      });
    }
    modal.actions.openModal({
      modal: ModalType.CONFIRM,
      title: t("confirm"),
      params: {
        content: t("duplicateConfirmQuestion", {
          x: t(sessionType).toLowerCase(),
        }),
        okButtonText: t("duplicate"),
        onOK: async () => {
          try {
            const { data: groupsData } = await fetchGetTheGroupsQuery({
              variables: {
                sessionId: user.sessionId,
                accountId: selectedAccount?.id,
                language: user.language,
              },
            });

            const groupID =
              groupsData?.getTheGroupsImIn.find(
                (group) =>
                  group?.name?.toLowerCase() ===
                  session?.teamName?.toLowerCase()
              )?.id ||
              groupsData?.getTheGroupsImIn.find((group) =>
                group.subGroups.find(
                  (group) =>
                    group?.name?.toLowerCase() ===
                    session?.teamName?.toLowerCase()
                )
              )?.id ||
              "";

            const createPracticeResult = await createPracticeMutation({
              variables: {
                sessionId: user.sessionId,
                timezone: user.timezone,
                language: user.language,
                input: {
                  groupID,
                  label: session.label,
                  location: session.location,
                  notes: session.notes,
                  start: session.start,
                  colorHexCode: rgbToHex(session.rgb),
                  durationMinutes: session.durationMinutes,
                  attendedMinutesBefore: session.attendedMinutesBefore,
                },
              },
              refetchQueries: ["agenda", "resourceCalendar"],
            });
            showSuccessNotification(t("duplicateSuccess"));

            handleSendManualNotificationAfterAction(
              { ...createPracticeResult?.data?.createPractice },
              createPracticeResult?.data?.createPractice?.notificationToSuggest
            );
          } catch {
            showErrorNotification(t("duplicateError"));
          }
          modal.actions.closeModal();
        },
      },
    });
  };

  const onMessageClick = async () => {
    await createGroupChatForSession(session);
  };

  const onManualNotificationClick = () => {
    const formattedSessionStart = dayjs(session.start).format("MMM D, YYYY");
    const forIndividualAthlete =
      session?.__typename === "Workout" ||
      (session?.__typename === "Event" && !session.teamName);
    const focusedAthleteName = session?.ownerUser?.name;

    modal.actions.openModal({
      modal: ModalType.SEND_MANUAL_NOTIFICATION,
      title: t("sendManualNotification"),
      params: {
        defaultTitle: `${t("message")} - ${
          forIndividualAthlete ? focusedAthleteName : session?.teamName
        }`,
        defaultText: `${session.label} ${formattedSessionStart}`,
        sessionGuid: session.id,
        forIndividualAthlete,
        focusedAthleteName,
      },
    });
  };

  return (
    <Dropdown
      component={({ show, toggle }) => (
        <IconButton
          icon={<ThreeDotsIcon isActive={show} />}
          enforceActiveState={show}
          onClick={(event) => {
            event.stopPropagation();
            onClick?.(show);
            toggle();
          }}
        />
      )}
      dropdownContainerStyle={{
        top: 27,
        right: 0,
        minWidth: 200,
        width: "max-content",
      }}
      dropdownContentStyle={{ padding: "5px 0" }}
    >
      {({ toggle }) => (
        <ContentWrapper>
          {isRealTrainer(user) || session.ownerUser.id === user.id ? (
            <ItemWrapper onClick={editHandler}>
              {isSessionRoute ? (
                <IconWrapper>
                  <EditPencilIcon />
                </IconWrapper>
              ) : null}
              <Text>{t("edit")}</Text>
            </ItemWrapper>
          ) : null}
          {isSessionRoute && !!(session?.offerChat && isRealTrainer(user)) ? (
            <ItemWrapper onClick={onMessageClick}>
              {isSessionRoute ? (
                <IconWrapper>
                  <ChatIcon />
                </IconWrapper>
              ) : null}
              <Text>{t("sendMessage")}</Text>
            </ItemWrapper>
          ) : null}
          {isSessionRoute &&
          isRealTrainer(user) &&
          session?.editable &&
          !isProgramPreview ? (
            <ItemWrapper onClick={onManualNotificationClick}>
              {isSessionRoute ? (
                <IconWrapper>
                  <BellIcon />
                </IconWrapper>
              ) : null}
              <Text>{t("sendManualNotification")}</Text>
            </ItemWrapper>
          ) : null}
          {isSessionRoute &&
          !isProgramPreview &&
          (isRealTrainer(user) || session.ownerUser.id === user.id) ? (
            <ItemWrapper onClick={onDuplicateClick}>
              {isSessionRoute ? (
                <IconWrapper>
                  <DuplicateIcon />
                </IconWrapper>
              ) : null}
              <Text>{t("duplicate")}</Text>
            </ItemWrapper>
          ) : null}
          {isSessionRoute && !isProgramPreview && isRealTrainer(user) ? (
            <ItemWrapper onClick={openSaveAsTemplateModal}>
              {isSessionRoute ? (
                <IconWrapper>
                  <NewDocumentIcon />
                </IconWrapper>
              ) : null}
              <Text>{t("saveAsTemplate")}</Text>
            </ItemWrapper>
          ) : null}
          {isSessionRoute &&
          (isRealTrainer(user) || session.ownerUser.id === user.id) &&
          session.__typename !== "Workout" ? (
            <ItemWrapper
              onClick={(event) => {
                onEventCancellationOrRevokeClick(event);
                toggle();
              }}
            >
              {isSessionRoute ? (
                <IconWrapper>
                  <CancelRoundedIcon />
                </IconWrapper>
              ) : null}
              <Text>
                {session.cancelled
                  ? t("revokeCancellation")
                  : t("cancelSession")}
              </Text>
            </ItemWrapper>
          ) : null}
          {!isProgramPreview &&
          (isRealTrainer(user) || session.ownerUser.id === user.id) ? (
            <ItemWrapper onClick={openDeleteModal}>
              {isSessionRoute ? (
                <IconWrapper>
                  <TrashIcon />
                </IconWrapper>
              ) : null}

              <Text>{t("delete")}</Text>
            </ItemWrapper>
          ) : null}
        </ContentWrapper>
      )}
    </Dropdown>
  );
}
