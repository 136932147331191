import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import StyledText from "../StyledText";
import { Tab } from "../Tabs";

type EmptyResultProps = {
  tab?: Tab;
  search?: string;
};

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Label = styled(StyledText)`
  font-size: 16px;
  text-align: center;
`;

export function EmptyResult({ tab, search }: EmptyResultProps) {
  const { t } = useTranslation();
  const drillName = search ? search : tab.title.toLowerCase();

  return (
    <Container>
      <Label>
        {tab
          ? t(search ? "noResultsFor" : "noResults", { x: drillName })
          : t("noResultsEmpty")}
      </Label>
    </Container>
  );
}
