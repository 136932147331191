import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CheckmarkRoundedDoneIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill="#77CC00" />
      <path d="M6.5 10.5L9.5 13.5L15.5 7.5" stroke="white" strokeWidth="1.5" />
    </svg>
  )
);
