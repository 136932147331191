import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_GREY_ACTIVE } from "../../../colors";
import { useManualNotificationsQuery } from "../../../graphql";
import { getAvatarUrl } from "../../../utils/getAvatarUrl";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";

import { NotificationListItem } from "./NotificationListItem";

const Divider = styled.div`
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  margin-top: 10px;
`;

const CenteredWrapper = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
`;

const NotificationListWrapper = styled.div`
  overflow-y: auto;
`;

export const NotificationsList = ({ sessionId }) => {
  const { t } = useTranslation();

  const { data, loading } = useManualNotificationsQuery({
    variables: { sessionId },
  });

  return (
    <>
      <StyledText fontSize={20} fontWeight="bold" style={{ padding: "0 15px" }}>
        {t("notifications")}
      </StyledText>
      <Divider />
      {loading ? (
        <CenteredWrapper>
          <Loader size="small" color={COLOR_BLUE} />
        </CenteredWrapper>
      ) : (
        <NotificationListWrapper>
          {data?.manualNotifications.length ? (
            data.manualNotifications.map((item, index) => {
              return item?.session?.__typename !== "Questionnaire" ? (
                <NotificationListItem
                  key={index}
                  sessionId={item?.session?.id}
                  sessionType={item?.session?.__typename}
                  cancelled={item?.session?.cancelled}
                  isLastItem={index === data.manualNotifications.length - 1}
                  time={item.time}
                  userName={`${item?.session?.ownerUser?.firstName} ${item?.session?.ownerUser?.lastName}`}
                  profileImg={getAvatarUrl(item?.session?.ownerUser)}
                  teamName={item?.club?.name}
                  teamImg={item?.club?.thumb}
                  groupName={item.groupName}
                  texts={item.texts}
                  title={item.title}
                  receivers={item?.receivers}
                />
              ) : null;
            })
          ) : (
            <CenteredWrapper>
              <StyledText fontSize={12} fontWeight="bold">
                {t("emptyContent")}
              </StyledText>
            </CenteredWrapper>
          )}
        </NotificationListWrapper>
      )}
    </>
  );
};
