import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ReportIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#3C434C",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3C1.89543 3 1 3.89543 1 5V16C1 17.1046 1.89543 18 3 18H18C19.1046 18 20 17.1046 20 16V5C20 3.89543 19.1046 3 18 3H3ZM5 13H4V15H5V13ZM7 10H8V15H7V10ZM11 12H10V15H11V12ZM13 6H14V15H13V6ZM17 9H16V15H17V9Z"
          fill="white"
        />
      ) : (
        <>
          <path
            d="M1.5 5C1.5 4.17157 2.17157 3.5 3 3.5H18C18.8284 3.5 19.5 4.17157 19.5 5V16C19.5 16.8284 18.8284 17.5 18 17.5H3C2.17157 17.5 1.5 16.8284 1.5 16V5Z"
            stroke={tintColor}
          />
          <rect x="4" y="13" width="1" height="2" fill={tintColor} />
          <rect x="7" y="10" width="1" height="5" fill={tintColor} />
          <rect x="10" y="12" width="1" height="3" fill={tintColor} />
          <rect x="13" y="6" width="1" height="9" fill={tintColor} />
          <rect x="16" y="9" width="1" height="6" fill={tintColor} />
        </>
      )}
    </svg>
  )
);
