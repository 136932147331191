import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const EditPencilSquaredIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#0073D9",
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      className="highlight"
      {...props}
    >
      {isActive ? (
        <>
          <path
            d="M18.5 7V15.5C18.5 17.1569 17.1569 18.5 15.5 18.5H5.5C3.84315 18.5 2.5 17.1569 2.5 15.5V5.5C2.5 3.84315 3.84315 2.5 5.5 2.5H14"
            stroke="white"
          />
          <path
            d="M8 11L7 14L10 13L19.1515 3.84853C19.6201 3.3799 19.6201 2.6201 19.1515 2.15147L18.8485 1.84853C18.3799 1.3799 17.6201 1.3799 17.1515 1.84853L8 11Z"
            stroke="white"
          />
          <path d="M16 3L18 5" stroke="white" />
        </>
      ) : (
        <>
          <path
            d="M18.5 7V15.5C18.5 17.1569 17.1569 18.5 15.5 18.5H5.5C3.84315 18.5 2.5 17.1569 2.5 15.5V5.5C2.5 3.84315 3.84315 2.5 5.5 2.5H14"
            stroke={tintColor}
          />
          <path
            d="M8 11L7 14L10 13L19.1515 3.84853C19.6201 3.3799 19.6201 2.6201 19.1515 2.15147L18.8485 1.84853C18.3799 1.3799 17.6201 1.3799 17.1515 1.84853L8 11Z"
            stroke={tintColor}
          />
          <path d="M16 3L18 5" stroke={tintColor} />
        </>
      )}
    </svg>
  )
);
