import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation } from "react-router-dom";

import { useUserContext } from "../../../contexts/User";
import { useCreateShoppingUrlQuery, useShopInfoQuery } from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { BagIcon } from "../../Icons/BagIcon";
import { NavIcon } from "../NavIcon";

interface StoreNavSideSectionProps {
  isCompact: boolean;
}

export function StoreNavSideSection({ isCompact }: StoreNavSideSectionProps) {
  const { sessionId, timezone, language } = useUserContext();

  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { data: createShoppingUrlData } = useCreateShoppingUrlQuery({
    variables: { sessionId, timezone, language },
  });

  const { data: shopInfoData } = useShopInfoQuery({
    variables: { sessionId },
    skip: !sessionId,
  });

  const navigateToStore = useCallback(() => {
    if (createShoppingUrlData?.createShoppingUrl?.url) {
      window.open(createShoppingUrlData?.createShoppingUrl?.url, "_blank");
    }
  }, [createShoppingUrlData?.createShoppingUrl?.url]);

  if (!shopInfoData?.shopInfo?.available) {
    return null;
  }

  return (
    <NavIcon
      active={Boolean(matchPath(ROUTING_CONFIG.store, pathname))}
      iconSvg={<BagIcon />}
      isCompact={isCompact}
      text={t("store")}
      onClick={navigateToStore}
    />
  );
}
