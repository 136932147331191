import React from "react";

import styled from "styled-components";

import { COLOR_WHITE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Avatar } from "../../graphql";
import { sortList } from "../../utils/sortList";
import StyledText from "../StyledText";

type AvatarListProps = {
  isLoggedInUserOwner: boolean;
  numberMembersCols: () => number;
  avatars: Avatar[];
};

const Wrapper = styled.div<{ numberMembersCols: () => number }>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
  min-width: ${({ numberMembersCols }) => 200 * numberMembersCols()};
`;

const ColumnWrapper = styled.div<{ numberMembersCols: () => number }>`
  display: flex;
  flex-direction: column;
  width: ${({ numberMembersCols }) =>
    (200 * numberMembersCols()) / numberMembersCols()};
`;

export function AvatarList({
  isLoggedInUserOwner,
  numberMembersCols,
  avatars,
}: AvatarListProps) {
  const { firstName, lastName, id, language } = useUserContext();

  const organizeAvatarsList = (avatars: Avatar[]) => {
    const n = numberMembersCols();
    return sortList(
      [
        ...avatars,
        isLoggedInUserOwner && { id, name: `${firstName} ${lastName}` },
      ],
      language
    ).reduce(
      (a, i) => {
        if (a[a.length - 1].length >= avatars.length / n) {
          a.push([]);
        }
        a[a.length - 1].push(i);
        return a;
      },
      [[]]
    );
  };

  return (
    <Wrapper numberMembersCols={numberMembersCols}>
      {organizeAvatarsList(avatars).map((columns, index) => (
        <ColumnWrapper numberMembersCols={numberMembersCols} key={index}>
          {columns.map((avatar) => (
            <StyledText
              key={avatar.userID}
              color={COLOR_WHITE}
              fontWeight="bold"
              fontSize={12}
            >
              {avatar.name}
            </StyledText>
          ))}
        </ColumnWrapper>
      ))}
    </Wrapper>
  );
}
