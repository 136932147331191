import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const NotAttendedIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <>
      <svg
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        width={width}
        height={height}
      >
        {isActive ? (
          <>
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
            <circle cx="17.5" cy="17.5" r="14" fill="white" stroke="#E6E8EB" />
            <circle cx="17.5" cy="17.5" r="6.5" fill="#FF6562" />
          </>
        ) : (
          <>
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
            <circle cx="17.5" cy="17.5" r="12" stroke="#B1B7BC" />
            <path d="M13.5 21.5L21.5 13.5" stroke="#64707A" strokeWidth="1.5" />
            <path d="M13.5 13.5L21.5 21.5" stroke="#64707A" strokeWidth="1.5" />
          </>
        )}
      </svg>
    </>
  )
);
