import React, { useState } from "react";
import { useDrag } from "react-dnd";

import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_PURE_RED,
  COLOR_SECONDARY_GREY,
  COLOR_WHITE,
} from "../colors";
import { DocumentPreview } from "../contexts/workout/WorkoutEditorProvider";

import { Thumbnail } from "./Collections/Thumbnail";
import { PlusIcon, StarIcon, TickIcon } from "./Icons";
import { ItemTypes } from "./WorkoutEditor/model";

type DocumentTileProps = {
  id: string;
  name: string;
  thumbnail?: string;
  isFavorite?: boolean;
  needsAttention?: number;
  onClick: () => void;
  shouldShowFavoriteIcon?: boolean;
  shouldShowUnreadIcon?: boolean;
  shouldShowAddIcon?: boolean;
  onPlusClick?: () => void;
  canDrag?: boolean;
  dragData?: DocumentPreview;
};

const DocumentTileWrapper = styled.div`
  width: 160px;
  background-color: ${COLOR_WHITE};
  box-shadow: 0 2px 12px rgba(21, 31, 59, 0.08);
  border-radius: 12px;
  cursor: pointer;
`;

const ThumbnailContainer = styled.div`
  height: 140px;
  overflow: hidden;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  position: relative;
`;

const ActionIconWrapper = styled.div<{
  shouldShowAddIcon?: boolean;
  isFreshlyAdded?: boolean;
}>`
  position: absolute;
  top: 10px;
  left: ${({ shouldShowAddIcon }) => (shouldShowAddIcon ? "auto" : "10px")};
  right: ${({ shouldShowAddIcon }) => (shouldShowAddIcon ? "10px" : "auto")};
  width: 24px;
  height: 24px;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  backdrop-filter: blur(4px);
  cursor: pointer;
  background: ${({ isFreshlyAdded }) =>
    isFreshlyAdded ? COLOR_GREEN : COLOR_SECONDARY_GREY};
`;

const BodyContainer = styled.div`
  display: flex;
  height: 60px;
  padding: 8px 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnreadWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  justify-content: center;
  align-items: center;
  z-index: 100;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: white;
`;

const RedDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${COLOR_PURE_RED};
`;

export function DocumentTile({
  id,
  name,
  thumbnail,
  isFavorite,
  needsAttention,
  onClick,
  shouldShowFavoriteIcon,
  shouldShowUnreadIcon,
  shouldShowAddIcon,
  onPlusClick,
  canDrag,
  dragData,
}: DocumentTileProps) {
  const [isFreshlyAdded, setIsFreshlyAdded] = useState(false);

  const clickOnPlus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onPlusClick();
    setIsFreshlyAdded(true);

    const timer = setTimeout(() => {
      setIsFreshlyAdded(false);
      return () => clearTimeout(timer);
    }, 1500);
  };

  const [, drag] = useDrag(() => ({
    type: ItemTypes.DOCUMENT,
    options: {
      dropEffect: "move",
    },
    item: dragData,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
    canDrag: !!(canDrag && dragData),
  }));

  return (
    <DocumentTileWrapper onClick={onClick} ref={drag} className="documentTile">
      <ThumbnailContainer>
        {isFavorite && shouldShowFavoriteIcon && (
          <ActionIconWrapper>
            <StarIcon isActive width={18} />
          </ActionIconWrapper>
        )}
        {shouldShowAddIcon && (
          <ActionIconWrapper
            shouldShowAddIcon={shouldShowAddIcon}
            isFreshlyAdded={isFreshlyAdded}
            onClick={clickOnPlus}
          >
            {isFreshlyAdded ? (
              <TickIcon tintColor={COLOR_WHITE} />
            ) : (
              <PlusIcon tintColor={COLOR_WHITE} />
            )}
          </ActionIconWrapper>
        )}
        {needsAttention && shouldShowUnreadIcon ? (
          <UnreadWrapper>
            <RedDot />
          </UnreadWrapper>
        ) : null}
        <Thumbnail id={id} src={thumbnail} />
      </ThumbnailContainer>
      <BodyContainer>
        <Label>{name}</Label>
      </BodyContainer>
    </DocumentTileWrapper>
  );
}
