import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useUserContext } from "../contexts/User";
import { useGetAccessLevelsQuery } from "../graphql";
import { formatName } from "../utils/user";

import { AvatarSelect } from "./AvatarSelect";

interface AvatarSelectWrapperProps {
  isFromMessaging?: boolean;
  accessLevelArea: "documents" | "statistics";
}

export function AvatarSelectWrapper({
  isFromMessaging,
  accessLevelArea,
}: AvatarSelectWrapperProps) {
  const { isTrainer, sessionId } = useUserContext();
  const location = useLocation();
  const { selectedAccount, setSelectedAccount, isAllAccountOverrideActive } =
    useAccessLevelsContext();

  const { data } = useGetAccessLevelsQuery({
    variables: { ignoreFriendsWhoShareNothing: true, sessionId },
  });

  const userAccountsOptions = useMemo(
    () =>
      data?.getAccessLevels
        ?.filter((accessLevel) =>
          accessLevelArea ? accessLevel[accessLevelArea]?.viewAll : true
        )
        .map(({ user }) => ({
          label: formatName(user?.name),
          value: user?.id,
          img: user?.img,
          name: user?.name,
        })) ?? [],
    [data?.getAccessLevels, accessLevelArea]
  );

  const isTrainerWithAllAccount =
    (!selectedAccount?.id ||
      selectedAccount?.id === ALL_ACCOUNTS_ID ||
      isAllAccountOverrideActive) &&
    isTrainer;

  const handleOnChange = useCallback(
    (accountId) => {
      setSelectedAccount(accountId, true, location.pathname);
    },
    [location.pathname, setSelectedAccount]
  );

  if (!isTrainerWithAllAccount && !isFromMessaging) {
    return null;
  }

  return (
    <AvatarSelect
      options={userAccountsOptions}
      value={
        selectedAccount?.id === ALL_ACCOUNTS_ID
          ? userAccountsOptions?.at(0)?.value
          : selectedAccount?.id
      }
      onChange={handleOnChange}
    />
  );
}
