import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { useUserContext } from "../../../../contexts/User";
import AttendeeItem from "../../../AttendeeItem";
import { AgendaSession } from "../../model";
import { PaymentSection } from "../PaymentSection";

import { CoachAttendanceContainer } from "./CoachAttendanceContainer";

const AttendanceContainer = styled.div`
  flex: 1;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`;

interface AttendanceSectionProps {
  session: AgendaSession;
  attendanceSelfRegistration: boolean;
  refetch: () => Promise<any>;
}

export function AttendanceSection({
  session,
  attendanceSelfRegistration,
  refetch,
}: AttendanceSectionProps) {
  const { isFamilyMember, isTrainer } = useUserContext();
  const isExpired = dayjs(session?.replyBefore).isBefore();

  return (
    <AttendanceContainer>
      <table>
        <tbody>
          {isFamilyMember || !isTrainer ? (
            attendanceSelfRegistration ? (
              session?.attendance?.map((attendanceInfo) => (
                <AttendeeItem
                  key={attendanceInfo.athleteGuid}
                  avatarsOfChildren={session?.avatarsOfChildren}
                  attendance={attendanceInfo}
                  sessionGuid={session?.id}
                  withRegistration={!!session?.replyBefore}
                  refetch={refetch}
                  isSessionDetail={false}
                  isExpired={isExpired}
                />
              ))
            ) : null
          ) : (
            <CoachAttendanceContainer
              attendance={session?.attendance}
              index={session?.index}
            />
          )}
          {session?.payments?.length ? (
            <PaymentSection session={session} refetch={refetch} />
          ) : null}
        </tbody>
      </table>
    </AttendanceContainer>
  );
}
