import React, { memo } from "react";

export const ProfileIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <circle cx="10.5" cy="6.5" r="3.5" fill="#64707A" />
    <path
      d="M5 15C5 12.7909 6.79086 11 9 11H12C14.2091 11 16 12.7909 16 15V17C16 17.5523 15.5523 18 15 18H6C5.44771 18 5 17.5523 5 17V15Z"
      fill="#64707A"
    />
  </svg>
));
