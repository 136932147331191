import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { Chatter, Group, SubGroup, WhoCanPost } from "../../graphql";

export interface GroupToInitGroupName {
  name?: string;
  groupName?: string;
  isAnnouncement: boolean;
  owner?: {
    nickName: string;
    country: string;
  };
  subGroups?: {
    id: string;
    name: string;
  }[];
}

type NewChatState = {
  forAllAutoAddedFriendsOfChatter: Chatter | null;
  autoAddFamily: boolean;
  autoAddAthletes: boolean;
  autoAddTrainers: boolean;
  announce: Chatter;
  stage: number;
  selectedGroupId?: string;
  whoCanPost: WhoCanPost;
};

type FlagSetter = (flag: boolean) => void;

interface NewChatActions {
  setForAllAutoAddedFriendsOfChatter: (_: Chatter) => void;
  setAutoAddFamily: FlagSetter;
  setAutoAddAthletes: FlagSetter;
  setAutoAddTrainers: FlagSetter;
  setAnnounce: (_: Chatter | Group | SubGroup) => void;
  setStage: (_: number) => void;
  setSelectedGroupId: (selectedGroupId: string) => void;
  setWhoCanPost: (whoCanPost: WhoCanPost) => void;
}

type NewChatContextType = NewChatState &
  NewChatActions & {
    groupForInitGroupName: GroupToInitGroupName | null;
    groupName: string;
    hasFamilyToChat: boolean;
    setHasFamilyToChat: Dispatch<SetStateAction<boolean>>;
    setGroupName: Dispatch<SetStateAction<string>>;
    setGroupForInitGroupName: Dispatch<SetStateAction<GroupToInitGroupName>>;
  };

export const NewChatContext = createContext<NewChatContextType>(null);

interface NewChatProviderProps {
  defaultGroupForInitGroupName?: GroupToInitGroupName | null;
  defaultChatState?: NewChatState;
  children: ReactNode;
}

// TODO: Revise this context as it currently adds lots of complexity
// and doesnt update when being consumed.

export const NewChatProvider = ({
  children,
  defaultGroupForInitGroupName = null,
  defaultChatState = {
    forAllAutoAddedFriendsOfChatter: null,
    autoAddFamily: false,
    autoAddAthletes: false,
    autoAddTrainers: true,
    announce: null,
    stage: 1,
    selectedGroupId: "",
    whoCanPost: WhoCanPost.All,
  },
}: NewChatProviderProps) => {
  const { t } = useTranslation();
  const [groupForInitGroupName, setGroupForInitGroupName] =
    useState<GroupToInitGroupName | null>(defaultGroupForInitGroupName);
  const [groupName, setGroupName] = useState("");
  const [hasFamilyToChat, setHasFamilyToChat] = useState(false);

  const [chatState, setChatState] = useState<NewChatState>(defaultChatState);

  const actions = useMemo(
    () =>
      ({
        setForAllAutoAddedFriendsOfChatter: (
          forAllAutoAddedFriendsOfChatter: Chatter
        ) =>
          setChatState((old) => ({
            ...old,
            forAllAutoAddedFriendsOfChatter,
          })),
        setAnnounce: (announce: Chatter) =>
          setChatState((old) => ({ ...old, announce })),
        setAutoAddAthletes: (autoAddAthletes) =>
          setChatState((old) => ({ ...old, autoAddAthletes })),
        setAutoAddTrainers: (autoAddTrainers) =>
          setChatState((old) => ({ ...old, autoAddTrainers })),
        setAutoAddFamily: (autoAddFamily) =>
          setChatState((old) => ({ ...old, autoAddFamily })),
        setStage: (stage: number) => setChatState((old) => ({ ...old, stage })),
        setSelectedGroupId: (selectedGroupId: string) =>
          setChatState((old) => ({ ...old, selectedGroupId })),
        setWhoCanPost: (whoCanPost: WhoCanPost) =>
          setChatState((old) => ({ ...old, whoCanPost })),
      }) as NewChatActions,
    []
  );

  useEffect(() => {
    if (groupForInitGroupName) {
      const isAnnouncement = groupForInitGroupName.isAnnouncement;
      const accountName = groupForInitGroupName.name;

      const ownerDescription =
        groupForInitGroupName?.owner?.country === "Iceland" &&
        groupForInitGroupName?.owner?.nickName
          ? `${groupForInitGroupName.owner.nickName} `
          : "";

      const subGroupDescription = groupForInitGroupName?.subGroups?.find(
        (subGroup) => subGroup.id === chatState.selectedGroupId
      )?.name;

      const groupDescription = subGroupDescription
        ? `${groupForInitGroupName.groupName ?? groupForInitGroupName.name}-${subGroupDescription}`
        : groupForInitGroupName.groupName ?? groupForInitGroupName.name;

      let suffix = "";

      if (chatState.autoAddFamily && hasFamilyToChat) {
        if (chatState.autoAddTrainers && chatState.autoAddAthletes) {
          suffix = t("all");
        } else {
          suffix = t("family");
        }
      } else {
        if (
          (hasFamilyToChat && chatState.autoAddAthletes) ||
          (!hasFamilyToChat &&
            chatState.autoAddAthletes &&
            !chatState.autoAddTrainers)
        ) {
          suffix = t("athletes");
        } else if (
          (hasFamilyToChat && chatState.autoAddTrainers) ||
          (!hasFamilyToChat &&
            chatState.autoAddTrainers &&
            !chatState.autoAddAthletes)
        ) {
          suffix = t("trainers");
        }
      }

      const initGroupNameWithoutSuffix = `${ownerDescription}${groupDescription}`;
      if (suffix) {
        const initGroupNameWithSuffix = `${
          isAnnouncement ? accountName : initGroupNameWithoutSuffix
        } (${suffix})`;
        setGroupName(initGroupNameWithSuffix);
      } else {
        setGroupName(isAnnouncement ? accountName : initGroupNameWithoutSuffix);
      }
    }
  }, [
    chatState.autoAddAthletes,
    chatState.autoAddFamily,
    chatState.autoAddTrainers,
    groupForInitGroupName,
    chatState.selectedGroupId,
    hasFamilyToChat,
    actions,
    t,
  ]);

  useEffect(() => {
    if (groupForInitGroupName) {
      const isAnnouncement = groupForInitGroupName.isAnnouncement;

      if (isAnnouncement) {
        actions.setAutoAddAthletes(true);
        actions.setAutoAddFamily(true);
      }
    }
  }, [actions, groupForInitGroupName]);

  return (
    <NewChatContext.Provider
      value={{
        ...chatState,
        ...actions,
        setGroupForInitGroupName,
        groupForInitGroupName,
        setGroupName,
        groupName,
        setHasFamilyToChat,
        hasFamilyToChat,
      }}
    >
      {children}
    </NewChatContext.Provider>
  );
};

export const useNewChatContext = () => useContext(NewChatContext);
