import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { useNotificationsContext } from "../../../contexts/notifications";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryVariables,
  useDeleteMessageGroupMutation,
} from "../../../graphql";
import { MessageOverviewPaginated } from "../../../graphql/queries/MessageOverviewPaginated";
import { ButtonColor } from "../../Button/";
import Hoverable from "../../Hoverable";
import { TrashIcon } from "../../Icons";
import { StyledOptionItem } from "../../Report/styled";
import { RoundIcon } from "../../RoundIcon";
import StyledText from "../../StyledText";

export const DeleteChatOption = ({
  chatGroupId,
}: {
  chatGroupId: string | undefined;
}) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setParams] = useSearchParams();
  const modal = useModalContext();

  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const [deleteMessageGroup] = useDeleteMessageGroupMutation();

  const onDeleteMessage = useCallback(
    async (groupId) => {
      if (!groupId) {
        return;
      }
      modal.actions.openModal({
        modal: ModalType.CONFIRM,
        title: t("confirm"),
        params: {
          content: t("deleteChatQuestion"),
          okButtonText: t("delete"),
          okButtonVariant: ButtonColor.Red,
          cancelButtonText: t("no"),
          onOK: async () => {
            try {
              const response = await deleteMessageGroup({
                variables: {
                  sessionId,
                  chatID: groupId,
                },
                update: (proxy) => {
                  const variables = {
                    sessionId,
                    language,
                    showAll: true,
                    includeHidden: true,
                    ignoreContactsWithoutChats: true,
                  };

                  const data = proxy.readQuery<
                    MessageOverviewPaginatedQuery,
                    MessageOverviewPaginatedQueryVariables
                  >({
                    query: MessageOverviewPaginated,
                    variables,
                  });
                  const contacts =
                    data?.messageOverview.contactsPaginated.edges.slice();

                  const contactIndexToDelete = contacts?.findIndex(
                    (contact) => contact.node.id === groupId
                  );

                  contacts.splice(contactIndexToDelete, 1);

                  proxy.writeQuery<
                    MessageOverviewPaginatedQuery,
                    MessageOverviewPaginatedQueryVariables
                  >({
                    query: MessageOverviewPaginated,
                    variables,
                    data: {
                      ...data,
                      messageOverview: {
                        ...data.messageOverview,
                        contactsPaginated: {
                          ...data.messageOverview.contactsPaginated,
                          edges: contacts,
                        },
                      },
                    },
                  });
                },
              });

              if (response.data?.deleteChatGroup?.success) {
                showSuccessNotification(t("chatDeletedSuccess"));
                setParams(undefined);
              } else {
                showErrorNotification(response.data?.deleteChatGroup?.problem);
              }
            } catch (e) {
              showErrorNotification(e);
              console.error(e);
            }
          },
        },
      });
    },
    [
      deleteMessageGroup,
      sessionId,
      showSuccessNotification,
      t,
      setParams,
      showErrorNotification,
      language,
      modal.actions,
    ]
  );

  return (
    <Hoverable>
      {(isHovered) => (
        <StyledOptionItem
          isHovered={isHovered}
          height={45}
          onClick={() => onDeleteMessage(chatGroupId)}
        >
          <RoundIcon svgIcon={<TrashIcon />} isHovered={isHovered} />
          <StyledText
            fontWeight="bold"
            fontSize={14}
            numberOfLines={1}
            style={{ maxWidth: 200 }}
          >
            {t("delete")}
          </StyledText>
        </StyledOptionItem>
      )}
    </Hoverable>
  );
};
