import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DEFAULT } from "../../../../colors";
import StyledText from "../../../StyledText";

export const WidgetPreviewTitle = styled(StyledText)`
  font-weight: bold;
  font-size: 20px;
  color: ${COLOR_TEXT_DEFAULT};
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px;
`;

export const InformationTitle = styled(StyledText)`
  font-weight: 600;
  margin: 8px;
`;

export const DataItemRow = styled.div<{ withBackground: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ withBackground }) =>
    withBackground ? COLOR_GRAY : "transparent"};
  padding: 15px 30px;
  box-sizing: border-box;
`;

export const InformationTableWrapper = styled.div<{ order: number }>`
  display: flex;
  flex-direction: column;
  margin: ${({ order }) => (!order ? 0 : 15)}px 0;
  z-index: ${({ order }) => 10000 - order};
`;

export const HoverImageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
