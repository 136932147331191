import dayjs from "dayjs";

import { getSoccerStatsWithCustomCols } from "../../../../../components/Report/utils/soccerStatsWithCustomCols";
import { GrouppedColumnHeader } from "../../../../../components/TablePeriodization/Widget";
import { ReportWidgetCellNumerical } from "../../../../../graphql";
import {
  JsonStatisticsPeriod,
  SoccerGameStatistics,
  SoccerPeriodStatistics,
} from "../../../../../services";
import {
  getSoccerStatisticsDataKey,
  isCustomTextSoccerAttribute,
} from "../../../../../utils/reports";
import { getDataKey } from "../../../../../utils/statistics";
import { Period } from "../../model";

const getPeriodizationForSoccerStatsComparison = (
  period: JsonStatisticsPeriod
) => {
  switch (period) {
    case "days":
      return "day";
    case "weeks":
      return "isoWeek";
    case "months":
      return "month";
    case "years":
      return "year";
    default:
      return "day";
  }
};

export type CalculatedMinAndMaxValues = {
  id: string;
  min: number;
  max: number;
}[];

export const getSoccerStatsDataItemsForPeriods = (
  period: Period,
  periodization: JsonStatisticsPeriod,
  columnHeaders: GrouppedColumnHeader[] = [],
  soccerPeriodStats?: SoccerPeriodStatistics[] | SoccerGameStatistics[],
  minAndMaxValues?: CalculatedMinAndMaxValues,
  isContinuous = false
) => {
  return columnHeaders
    ?.flatMap((column) => {
      if (!column.attributes?.length) {
        return null;
      }

      const requestedData = column.attributes[0] as ReportWidgetCellNumerical;

      return requestedData.aggregateFunctions.map((aggregateFunction) => {
        const requestedDataId = getDataKey(
          requestedData.templateId,
          requestedData.attributeTemplate?.id,
          aggregateFunction
        );

        const soccerStats =
          // @ts-ignore
          soccerPeriodStats?.find((item) => {
            const itemDate =
              "date" in item ? item.date : "start" in item ? item.start : null;
            return (
              dayjs(itemDate).isSame(
                period.start,
                getPeriodizationForSoccerStatsComparison(periodization)
              ) && dayjs(itemDate).isSame(period.start, "year")
            );
          }) || {};

        // We need this due to composed keys for extraTextCols / extraCols
        const dataKey = getSoccerStatisticsDataKey(requestedData.templateId);
        const isComposedKey = dataKey.split(".").length > 1;
        const mainObjectKey = isComposedKey ? dataKey.split(".")[0] : dataKey;
        const fieldKey = isComposedKey
          ? dataKey.replace(`${mainObjectKey}.`, "")
          : "";

        // Extra case for numberOfGames as it is not part of games data returned by day for continuous data
        const value =
          isContinuous &&
          getSoccerStatisticsDataKey(requestedData.templateId) ===
            "numberOfGames" &&
          Object.keys(soccerStats).length !== 0
            ? 1
            : isContinuous
              ? isComposedKey
                ? soccerStats[mainObjectKey]?.[fieldKey]
                : soccerStats[mainObjectKey]
              : getSoccerStatsWithCustomCols(
                  soccerStats,
                  requestedData.templateId,
                  aggregateFunction
                );

        return value
          ? {
              id: requestedDataId,
              value,
              ...(isCustomTextSoccerAttribute(requestedDataId)
                ? { txt: value }
                : {}),
              ...(minAndMaxValues?.find(
                (minMax) => minMax.id === requestedDataId
              ) ?? { min: 0, max: 0 }),
            }
          : null;
      });
    })
    .filter((val) => Boolean(val));
};
