import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";

import Avatar from "../components/Avatar";
import Card from "../components/Card";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import { TeamMembersList } from "../components/Members/TeamMembersList";
import ScreenContainer from "../components/ScreenContainer";
import StyledText from "../components/StyledText";
import { useUserContext } from "../contexts/User";
import { Group, useGetGroupQuery } from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";

const Container = styled.div`
  flex: 1;
`;

const GroupInfoTextContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
`;

const LoaderContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const CardWrapper = styled(Card)`
  align-items: center;
  padding: 30px;
  flex-direction: row;
`;

export function AthleteTeamScreen() {
  const { state: groupWithId }: { state: Group } = useLocation();
  const navigate = useNavigate();
  const { sessionId } = useUserContext();
  const { id, __typename } = groupWithId;

  const { data: groupToShow, loading } = useGetGroupQuery({
    variables: {
      sessionId,
      id,
    },
    skip: !id,
  });

  useEffect(() => {
    if (!groupWithId) {
      navigate(`/${ROUTING_CONFIG.calendar}`);
    }
  }, [navigate, groupWithId]);

  const showLoader = !groupToShow?.getGroup && loading;
  const group = groupToShow?.getGroup;
  const isSubGroup = __typename === ("SubGroup" as string);

  return (
    <ScreenContainer>
      <Header />
      {showLoader ? (
        <LoaderContainer>
          <Loader size="large" />
        </LoaderContainer>
      ) : (
        <Container>
          <CardWrapper>
            <Avatar
              size={85}
              userName={group.owner?.name}
              source={{ uri: group.owner?.profile?.profileImageUrl }}
            />

            <GroupInfoTextContainer>
              <StyledText fontSize={30} fontWeight="bold">
                {group.owner.name}
              </StyledText>
              <StyledText fontSize={20}>{group.name}</StyledText>
            </GroupInfoTextContainer>
          </CardWrapper>
          <TeamMembersList group={group} isSubGroup={isSubGroup} />
        </Container>
      )}
    </ScreenContainer>
  );
}
