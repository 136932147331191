import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useNotificationsContext } from "../contexts/notifications";
import { useTeamsContext } from "../contexts/teams";
import { ModalType, useModalContext } from "../contexts/UI/Modal";

import { GeneralButton } from "./Button/";
import { EmptyMagnifyingGlassIcon, PlusIcon } from "./Icons";
import StyledText from "./StyledText";

const NoTeamWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SubLabel = styled(StyledText)`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 28px;
`;

const Label = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
`;

export function EmptyTeams() {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { accessLevels, selectedAccount } = useAccessLevelsContext();
  const { showErrorNotification } = useNotificationsContext();
  const { onSetGroup, refetch } = useTeamsContext();

  const { fullAccess } = useMemo(() => {
    return (
      accessLevels.find(
        (accessLevel) => accessLevel?.user?.id === selectedAccount?.id
      )?.userManagement || {}
    );
  }, [accessLevels, selectedAccount?.id]);

  const handleCreateTeamPress = () => {
    if (selectedAccount?.id !== ALL_ACCOUNTS_ID) {
      if (!fullAccess) {
        showErrorNotification(t("cannotCreateTeam"));
        return false;
      }
    }

    modal.actions.openModal({
      modal: ModalType.SAVE_GROUP,
      title: t("addTeam"),
      params: {
        disableCancel: true,
        parentGroup: null,
        isTeam: true,
        onSetGroup,
        refetch,
      },
    });
  };

  return (
    <NoTeamWrapper>
      <EmptyMagnifyingGlassIcon style={{ marginBottom: 28 }} />
      <>
        <Label>{t("withoutTeams")}.</Label>
        <SubLabel>{t("tryCreateNewTeam")}.</SubLabel>
        <GeneralButton
          icon={<PlusIcon isActive />}
          label={t("create")}
          onClick={handleCreateTeamPress}
        />
      </>
    </NoTeamWrapper>
  );
}
