import React from "react";

import styled from "styled-components";

import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { WorkoutEditor } from "../components/WorkoutEditor";

const HeaderWrapper = styled.div`
  z-index: 2;
`;

const WorkoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 1;
`;

export const WorkoutEditorScreen = () => {
  return (
    // @ts-ignore
    <ScreenContainer style={{ overflow: "unset !important" }}>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <WorkoutWrapper>
        <WorkoutEditor />
      </WorkoutWrapper>
    </ScreenContainer>
  );
};
