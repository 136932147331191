import React from "react";
import { useTranslation } from "react-i18next";

import { Dayjs } from "dayjs";
import styled, { useTheme } from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../colors";
import { ExtendedPeriod, useReportsContext } from "../../contexts/Reports";
import { useDefaultDateFormat } from "../../hooks";
import StyledText from "../StyledText";

const DateRangeText = styled(StyledText)<{ isMobile?: boolean }>`
  color: ${COLOR_TEXT_DEFAULT};
  font-size: ${(props) => (props.isMobile ? 16 : 20)}px;
  line-height: ${(props) => (props.isMobile ? 20 : 24)}px;
  letter-spacing: ${(props) => (props.isMobile ? "-0.02em" : "inherit")};
`;

interface ReportPeriodTextProps {
  isSingleDay?: boolean;
}

const BoldDateRangeText = styled(DateRangeText)`
  font-weight: 700;
`;

export const ReportPeriodText = ({ isSingleDay }: ReportPeriodTextProps) => {
  const { t } = useTranslation();
  const { isMobile } = useTheme();
  const [state] = useReportsContext();
  const dateFormats = useDefaultDateFormat();

  const dateLabel =
    state.selectedPeriod === "last30days" ||
    state.selectedPeriod === "last7days"
      ? t("custom")
      : t(isSingleDay ? "day" : state.selectedPeriod);

  const {
    selectedPeriod,
    selectedStartDate,
    selectedEndDate,
    selectedSingleDay,
  } = state;

  return (
    <>
      <BoldDateRangeText isMobile={isMobile}>{dateLabel}: </BoldDateRangeText>
      <DateRangeText isMobile={isMobile}>
        {isSingleDay
          ? getReportPeriodLabel({
              selectedPeriod: "day",
              selectedStartDate: selectedSingleDay,
              selectedEndDate: null,
              ...dateFormats,
            })
          : getReportPeriodLabel({
              selectedPeriod,
              selectedStartDate,
              selectedEndDate,
              ...dateFormats,
            })}
      </DateRangeText>
    </>
  );
};

export function getReportPeriodLabel({
  selectedPeriod,
  selectedStartDate,
  selectedEndDate,
  dateFormat,
  sameYearDateFormat,
  wordMonthDateFormat,
}: {
  selectedPeriod: ExtendedPeriod;
  selectedStartDate: Dayjs;
  selectedEndDate: Dayjs;
  dateFormat: string;
  sameYearDateFormat: string;
  wordMonthDateFormat: string;
}) {
  switch (selectedPeriod) {
    case "month":
      return selectedStartDate.format("MMMM YYYY");
    case "day":
      return selectedStartDate.format(wordMonthDateFormat);
    case "week":
    case "custom":
    case "last30days":
    case "last7days":
      return `${selectedStartDate.format(
        selectedStartDate.isSame(selectedEndDate, "year")
          ? sameYearDateFormat
          : wordMonthDateFormat
      )} - ${selectedEndDate.format(wordMonthDateFormat)}`;
    default:
      return "";
  }
}
