import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

import { getPeriodStartEndUnit } from "../../../../utils/getPeriodStartEndUnit";
import { ChartPerformers, TimeLinePeriod } from "../model";

dayjs.extend(isoWeek);

export function generatePeriodCategories(
  startDate: Dayjs,
  endDate: Dayjs,
  timeLinePeriod: TimeLinePeriod
) {
  const dateCategories: Dayjs[] = [];

  if (timeLinePeriod === TimeLinePeriod.NONE) {
    return [`${startDate.format("MMM D")} - ${endDate.format("MMM D")}`];
  }

  let date = dayjs(startDate).startOf(getPeriodStartEndUnit(timeLinePeriod));
  const stopDate = dayjs(endDate).endOf(getPeriodStartEndUnit(timeLinePeriod));

  while (date.isBefore(stopDate)) {
    dateCategories.push(
      date.isBefore(startDate) ? startDate.clone() : date.clone()
    );
    date = date.add(1, timeLinePeriod);
  }

  return dateCategories.map((startDate, i) => {
    switch (timeLinePeriod) {
      case TimeLinePeriod.DAYS:
      case TimeLinePeriod.WEEKS:
        return startDate.format("MMM D");
      case TimeLinePeriod.MONTHS:
        return startDate.format("MMM").toUpperCase();
      case TimeLinePeriod.YEARS:
        return startDate.format("YYYY");
      default:
        return i.toString();
    }
  });
}

export function generatePerformerCategories(
  performers: ChartPerformers,
  hasTeamAverageItem: boolean
) {
  const hasTeamAverage =
    hasTeamAverageItem && performers.teamGuids.length === 0;

  return {
    hasTeamAverage,
    categories: [
      ...performers.teamGuids,
      ...performers.athleteGuids,
      ...(hasTeamAverage ? ["teamAverage"] : []),
    ],
  };
}
