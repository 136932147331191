import React, { MouseEvent, ReactNode } from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_BLUE_HOVER,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../colors";

import { Loader } from "./Loader";

type IconButtonProps = {
  activeBgColor?: string;
  bgColor?: string;
  enforceActiveState?: boolean;
  hoverBgColor?: string;
  disabled?: boolean;
  isSpinnerVisible?: boolean;
  icon: ReactNode;
  ml?: number;
  mr?: number;
  onClick: (event: MouseEvent) => void;
  size?: number;
};

const ButtonWrapper = styled.button<{
  activeBgColor?: string;
  bgColor?: string;
  disabled?: boolean;
  enforceActiveState?: boolean;
  hoverBgColor?: string;
  ml?: number;
  mr?: number;
  size?: number;
}>`
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size ?? 35}px;
  height: ${({ size }) => size ?? 35}px;
  margin-right: ${({ mr }) => mr ?? 0}px;
  margin-left: ${({ ml }) => ml ?? 0}px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background: ${({ activeBgColor, bgColor, enforceActiveState }) => {
    if (enforceActiveState) {
      return activeBgColor ?? COLOR_BLUE;
    }

    return bgColor ?? COLOR_WHITE;
  }};
  &:hover {
    background: ${({ hoverBgColor, enforceActiveState }) => {
      if (enforceActiveState) {
        return COLOR_BLUE_HOVER;
      }

      return hoverBgColor ?? COLOR_GRAY;
    }};
  }
  &:active {
    background: ${({ activeBgColor }) => activeBgColor ?? COLOR_GREY_ACTIVE};
  }
`;

const IconWrapper = styled.div`
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function IconButton({
  activeBgColor,
  bgColor,
  enforceActiveState,
  hoverBgColor,
  icon,
  ml,
  mr,
  onClick,
  disabled,
  isSpinnerVisible = false,
  size,
}: IconButtonProps) {
  return (
    <ButtonWrapper
      activeBgColor={activeBgColor}
      bgColor={bgColor}
      enforceActiveState={enforceActiveState}
      hoverBgColor={hoverBgColor}
      ml={ml}
      mr={mr}
      onClick={onClick}
      disabled={disabled}
      size={size}
    >
      {isSpinnerVisible ? (
        <Loader color={COLOR_WHITE} />
      ) : (
        <IconWrapper>{icon}</IconWrapper>
      )}
    </ButtonWrapper>
  );
}
