import React from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_BUTTON_ADD_HOVER,
  COLOR_BUTTON_GREEN,
  COLOR_BUTTON_RED,
  COLOR_BUTTON_REMOVE_HOVER,
  COLOR_WHITE,
} from "../../colors";
import { PlusRoundedIcon, RemoveIcon } from "../Icons";

import { ButtonType } from "./types";

type AddRemoveButtonProps = {
  isDisabled?: boolean;
  label: string;
  onClick: () => void;
  sizeType?: ButtonType;
};

const StyledButton = styled.button<{ sizeType: ButtonType; disabled: boolean }>`
  padding: 0 10px;
  height: 25px;
  border: none;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: background-color 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.35 : 1)};
  &:hover {
    background-color: ${({ sizeType, disabled }) => {
      if (disabled) {
        return "none";
      }

      return sizeType === ButtonType.Add
        ? COLOR_BUTTON_ADD_HOVER
        : COLOR_BUTTON_REMOVE_HOVER;
    }};
  }
  &:active {
    background-color: ${({ sizeType, disabled }) => {
      if (disabled) {
        return "none";
      }

      return sizeType === ButtonType.Add
        ? COLOR_BUTTON_GREEN
        : COLOR_BUTTON_RED;
    }};
    & > span {
      color: ${({ disabled }) => !disabled && COLOR_WHITE};
    }
    & .highlight > rect {
      fill: ${({ disabled }) => !disabled && COLOR_WHITE};
    }
    & .highlight > .symbol {
      fill: ${({ disabled, sizeType }) => {
        if (disabled) {
          return COLOR_WHITE;
        }

        return sizeType === ButtonType.Add
          ? COLOR_BUTTON_GREEN
          : COLOR_BUTTON_RED;
      }}
  }
  &:focus {
    outline-width: 4px;
    outline-style: solid;
    outline-color: ${COLOR_BLUE}40;
  }
`;

const Label = styled.span<{ sizeType: ButtonType }>`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  text-wrap: nowrap;
  color: ${({ sizeType }) =>
    sizeType === ButtonType.Add ? COLOR_BUTTON_GREEN : COLOR_BUTTON_RED};
`;

export function AddRemoveButton({
  isDisabled = false,
  label,
  onClick,
  sizeType = ButtonType.Add,
}: AddRemoveButtonProps) {
  return (
    <StyledButton sizeType={sizeType} disabled={isDisabled} onClick={onClick}>
      {sizeType === ButtonType.Add ? (
        <PlusRoundedIcon isActive />
      ) : (
        <RemoveIcon />
      )}
      <Label sizeType={sizeType}>{label}</Label>
    </StyledButton>
  );
}
