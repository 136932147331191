import React, { ReactNode } from "react";

import { AgendaDataContextProvider } from "./agenda/AgendaDataProvider";
import { AgendaContextProvider } from "./agenda/AgendaProvider";
import { PracticeContextProvider } from "./practice/PracticeProvider";
import { ResourceCalendarContextProvider } from "./resourceCalendar/ResourceCalendarProvider";

export const AgendaCalendarContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => (
  <ResourceCalendarContextProvider>
    <PracticeContextProvider>
      <AgendaContextProvider>
        <AgendaDataContextProvider>{children}</AgendaDataContextProvider>
      </AgendaContextProvider>
    </PracticeContextProvider>
  </ResourceCalendarContextProvider>
);
