import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../../colors";

const TableHeaderRow = styled.tr`
  height: 35px;
  background-color: ${COLOR_WHITE};
`;

const TableHeader = styled.th<{
  width?: number;
  textAlign?: string;
  paddingRight?: number;
}>`
  text-align: ${({ textAlign = "left" }) => textAlign};
  width: ${({ width }) => (width ? `${width} px` : "auto")};
  padding-right: ${({ paddingRight = 0 }) => paddingRight}px;

  &:first-child {
    border-top-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
  }
`;

const TableHeaderContent = styled.div``;

const Label = styled.span<{ marginLeft?: number }>`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-left: ${({ marginLeft = 10 }) => marginLeft}px;
  color: ${COLOR_MEDIUM_GRAY};
`;

export function AttendanceTableHeader() {
  const { t } = useTranslation();

  return (
    <TableHeaderRow>
      <TableHeader>
        <TableHeaderContent>
          <Label marginLeft={20}>{t("athleteName").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader width={80} textAlign="center">
        <TableHeaderContent>
          <Label marginLeft={0}>{t("attending").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader width={80} paddingRight={20} textAlign="center">
        <TableHeaderContent>
          <Label marginLeft={0}>{t("away").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader>
        <TableHeaderContent>
          <Label marginLeft={0}>{t("note").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader>
        <TableHeaderContent>
          <Label>{t("athleteComment").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
    </TableHeaderRow>
  );
}
