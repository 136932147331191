import { ComponentType } from "react";

import { connect } from "formik";

export function formikConnect<
  C extends ComponentType<React.PropsWithChildren<{ formik: any }>>,
>(component: C) {
  return connect(component as any) as ComponentType<
    React.PropsWithChildren<Omit<GetProps<C>, "formik">>
  >;
}

type GetProps<T> =
  T extends ComponentType<React.PropsWithChildren<infer P>> ? P : never;
