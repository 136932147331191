import React, { useLayoutEffect, useRef, useState } from "react";
import { EventProps } from "react-big-calendar";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE, COLOR_SECONDARY_GREY } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { isTvEnv } from "../../utils/isTvEnv";

import { CustomResourceEvent } from "./model";
import { ResourceCalendarEventDetail } from "./ResourceCalendarEventDetail";

export type EventCoordinates = {
  width?: number;
  height?: number;
  boundingRect?: DOMRect;
  hasOneColumn?: boolean;
};

const EventWrapper = styled.div`
  height: 100%;
`;

const StyledCalendarEventContainer = styled.div<{
  backgroundColor?: string;
  cancelled?: boolean;
  isLocked?: boolean;
  height?: number;
  isGame?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ height }) => (height <= 20 ? "2px 8px" : "4px 8px")};
  height: calc(100% - 1px);
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.25);
  ${({ backgroundColor, cancelled, isGame }) => {
    const defaultColor = backgroundColor?.length
      ? `rgb(${backgroundColor})`
      : COLOR_BLUE;

    if (cancelled) {
      return `
        background-color: ${COLOR_WHITE};
        border: 1px dashed ${COLOR_SECONDARY_GREY};
        color: ${COLOR_SECONDARY_GREY};
    `;
    }

    return `
      background-color: ${defaultColor};
      color: ${COLOR_WHITE};
      border:  ${
        isGame ? `4px solid rgba(0, 0, 0, 0.20)` : `1px solid ${defaultColor}`
      };
    `;
  }}
`;

const CalendarInfoWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: ${({ height }) => (height <= 20 ? "row" : "column")};
`;

const AdditionalInfoWrapper = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: ${({ height }) => (height <= 40 ? "row" : "column")};
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
`;

export const CustomResourceCalendarEvent = (
  props: EventProps<CustomResourceEvent>
) => {
  const user = useUserContext();

  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [eventCoordinates, setEventCoordinates] = useState<EventCoordinates>({
    width: 0,
    height: 0,
    boundingRect: null,
  });

  const eventRef = useRef(null);
  const { event, hasOneColumn } = props;

  const isUserRealTrainer = isRealTrainer(user);

  useLayoutEffect(() => {
    const height = eventRef?.current?.clientHeight;
    const width = eventRef?.current?.clientWidth;
    const boundingRect = eventRef?.current?.getBoundingClientRect();

    setEventCoordinates({ height, width, boundingRect });
  }, []);

  const [firstDescriptionItem, secondDescriptionItem, thirdDescriptionItem] =
    event.resourceCalendarDescription || [];

  const handleMouseOverEvent = (event: EventProps) => {
    if (!isTvEnv()) {
      setHoveredEvent(event?.resourceId);
    }
  };

  const handleMouseLeaveEvent = () => {
    setHoveredEvent(null);
  };

  return (
    <EventWrapper
      id="calendar-event-container"
      onMouseOver={() => handleMouseOverEvent(event)}
      onMouseLeave={handleMouseLeaveEvent}
    >
      <StyledCalendarEventContainer
        backgroundColor={event.color}
        cancelled={event?.cancelled}
        height={eventCoordinates.height}
        ref={eventRef}
        isGame={event.isGame}
      >
        <CalendarInfoWrapper height={eventCoordinates.height}>
          <Title>{firstDescriptionItem}&nbsp;</Title>
          <AdditionalInfoWrapper height={eventCoordinates.height}>
            {secondDescriptionItem ? (
              <Title>{secondDescriptionItem}&nbsp;</Title>
            ) : null}
            {thirdDescriptionItem ? (
              <Title>{thirdDescriptionItem}</Title>
            ) : null}
          </AdditionalInfoWrapper>
        </CalendarInfoWrapper>
      </StyledCalendarEventContainer>
      {isUserRealTrainer && hoveredEvent && (
        <ResourceCalendarEventDetail
          eventCoordinates={eventCoordinates}
          selectedEvent={event}
          hasOneColumn={hasOneColumn}
          onUnselectedItem={handleMouseLeaveEvent}
        />
      )}
    </EventWrapper>
  );
};
