import React from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";

import { Label } from "../../Label";
import {
  TablePeriodizationForm,
  SummaryStatsAlignment,
} from "../../Report/PeriodizationTable/model";
import { SwitchWrapper } from "../../ReportCreateTable/styled";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField } from "../../SelectField";
import { StyledSwitch } from "../../StyledSwitch";

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AlignmentWrapper = styled.div`
  display: block;
  width: 50%;
`;

export function TablePeriodizationSummarySection() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<TablePeriodizationForm>();

  return (
    <Section>
      <SectionTitle title={t("summaryStats")} />

      <ControlsWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.showSummaryStats}
            onValueChange={(value) => setFieldValue("showSummaryStats", value)}
            label={t("summaryStats")}
          />
        </SwitchWrapper>

        {values.showSummaryStats && (
          <AlignmentWrapper>
            <Label>{t("alignment").toUpperCase()}</Label>
            <Field name="summaryStatsAlignment">
              {({ field, form, meta }: FieldProps<TablePeriodizationForm>) => (
                <SelectField
                  field={field}
                  form={form}
                  meta={meta}
                  options={[
                    {
                      label: t("aboveTable"),
                      value: SummaryStatsAlignment.TOP,
                    },
                    {
                      label: t("belowTable"),
                      value: SummaryStatsAlignment.BOTTOM,
                    },
                  ]}
                />
              )}
            </Field>
          </AlignmentWrapper>
        )}
      </ControlsWrapper>
    </Section>
  );
}
