import { useNavigate } from "react-router-dom";

import { useUserContext } from "../../contexts/User";
import { useEventLazyQuery } from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";

export const useNavigateToWorkoutEditor = () => {
  const user = useUserContext();

  const navigate = useNavigate();

  const [fetchEvent] = useEventLazyQuery();

  const navigateToWorkoutEditor = async ({
    sessionGuid,
  }: {
    sessionGuid: string;
  }) => {
    const res = await fetchEvent({
      variables: {
        sessionId: user.sessionId,
        language: user.language,
        timezone: user.timezone,
        sessionGuid,
      },
    });

    if (res?.data?.event2?.ownerUser?.id) {
      return navigate(
        `/${ROUTING_CONFIG.workoutEditor}?athleteId=${res.data?.event2?.ownerUser?.id}&sessionGuid=${sessionGuid}`,
        { replace: true }
      );
    }
  };

  return { navigateToWorkoutEditor };
};
