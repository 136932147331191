import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TFunction, useTranslation } from "react-i18next";

import { UserPreferenceKeys } from "../../constants";
import {
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
  XpsUser,
  useUserAccessLevelsQuery,
  AccessToTrainer,
  AccessLevel,
  UserAccessLevelsQuery,
} from "../../graphql";
import { sortList } from "../../utils/sortList";
import { useUIContext } from "../UI";
import { useUserContext } from "../User";

import { useAccessLevelsModuleOverrideServerSynch } from "./useAccessLevelsModuleOverrideServerSynch";
import { generateAccessLevelLocalOverrideAccount } from "./utils/generateAccessLevelLocalOverrideAccount";

type AccessLevelModel = Pick<
  AccessToTrainer,
  "user" | "statistics" | "userManagement" | "teamSessions" | "individualData"
>;

type AccessLevelsContextValue = {
  selectedAccount: XpsUser;
  selectedAccountStatisticsAccess: AccessLevel;
  isAllAccountOverrideActive: boolean;
  accessLevels: AccessLevelModel[];
  setSelectedAccount: (
    newSelectedAccountId: string,
    isLocalOverride?: boolean,
    currentModulePathname?: string
  ) => void;
  resetLocalOverride: (pathname: string) => void;
  getTeamSessionsRight: (groupOwnerId: string) => { edit?: boolean };
  refetch: any;
};

const AccessLevelsContext = createContext<AccessLevelsContextValue>({
  selectedAccount: {} as XpsUser,
  selectedAccountStatisticsAccess: {} as AccessLevel,
  isAllAccountOverrideActive: false,
  accessLevels: [] as AccessLevelModel[],
  setSelectedAccount: () => ({}),
  resetLocalOverride: () => ({}),
  getTeamSessionsRight: () => ({ edit: false }),
  refetch: () => ({}),
});

export const ALL_ACCOUNTS_ID = "ALL_ACCOUNTS";

const getModuleOverride = (currentPathname: string) => {
  const splittedPathname = currentPathname.split("/");

  const module =
    splittedPathname.length > 1 ? splittedPathname[1] : splittedPathname[0];

  return module;
};

const getAllAccountsAccount = (
  userAccessLevelsData: UserAccessLevelsQuery,
  t: TFunction
) => ({
  user: {
    id: ALL_ACCOUNTS_ID,
    img: null,
    name: t("allAccounts"),
    type: null,
  },
  statistics: {
    viewAll: userAccessLevelsData?.userAccessLevels?.some(
      ({ statistics }) => statistics.viewAll
    ),
    edit: userAccessLevelsData?.userAccessLevels?.some(
      ({ statistics }) => statistics.edit
    ),
  },
  teamSessions: {
    edit: userAccessLevelsData?.userAccessLevels?.some(
      ({ statistics }) => statistics.edit
    ),
  },
});

const getOrderedAccounts = (
  userAccessLevelsData: UserAccessLevelsQuery,
  language: string
) => {
  if (!userAccessLevelsData?.userAccessLevels?.length) {
    return [];
  }

  const [loggedInUserAccount, ...rest] = userAccessLevelsData?.userAccessLevels;

  const sortedAccounts = sortList(
    rest ?? [],
    language,
    (account) => account.user.name
  );

  return [loggedInUserAccount, ...sortedAccounts];
};

export function AccessLevelsContextProvider({
  children,
}: PropsWithChildren<object>) {
  const { sessionId, language, timezone } = useUserContext();
  const { t } = useTranslation();
  const { embed } = useUIContext();

  const [embedGroupSaved, setEmbedGroupSaved] = useState(false);
  const [overrideActiveForModule, setOverrideActiveForModule] = useState("");
  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<AccessLevelModel>({} as AccessLevelModel);
  const [
    selectedAccessLevelLocalOverride,
    setSelectedAccessLevelLocalOverride,
  ] = useState<AccessLevelModel>({} as AccessLevelModel);
  const [isAllAccountOverrideActive, setIsAllAccountOverrideActive] =
    useState(false);

  const [getPreference] = useGetPreferenceLazyQuery();
  const [setPreferences] = usePutPreferenceMutation();

  const { data: userAccessLevelsData, refetch } = useUserAccessLevelsQuery({
    variables: {
      sessionId,
      language,
      timezone,
      accessLevelsForDisplayingInGlobalAccountSelector: true,
    },
    skip: !sessionId,
  });

  const params = new URLSearchParams(window.location.search);
  const selectedAccountIdParam = params.get("selectedAccount");

  const accessLevels = useMemo(
    () => [
      getAllAccountsAccount(userAccessLevelsData, t),
      ...getOrderedAccounts(userAccessLevelsData, language),
    ],
    [userAccessLevelsData, t, language]
  );

  useEffect(() => {
    if (!sessionId) {
      setSelectedAccessLevel({} as AccessLevelModel);
      setIsAllAccountOverrideActive(false);
    }
  }, [sessionId]);

  useEffect(() => {
    (async () => {
      // We need this because otherwise selected account is rewritten by data from server
      if (
        sessionId &&
        selectedAccountIdParam &&
        selectedAccountIdParam !== "undefined"
      ) {
        await setPreferences({
          variables: {
            key: UserPreferenceKeys.SELECTED_ACCOUNT_ID,
            value: selectedAccountIdParam,
            sessionId,
          },
        });
        setEmbedGroupSaved(true);
      }
    })();
  }, [sessionId, selectedAccountIdParam, setPreferences]);

  useEffect(() => {
    (async () => {
      if (!accessLevels) {
        return;
      }

      if (
        Boolean(sessionId) &&
        (embedGroupSaved || !embed) &&
        !isAllAccountOverrideActive
      ) {
        const preferencesResponse = await getPreference({
          variables: {
            key: UserPreferenceKeys.SELECTED_ACCOUNT_ID,
            sessionId,
          },
        });

        const selectedUserAccessLevelId =
          preferencesResponse?.data?.getPreference;
        const selectedUserAccessLevel =
          accessLevels?.find(
            (accessLevel) => accessLevel.user?.id === selectedUserAccessLevelId
          ) ?? accessLevels?.at(0);

        setSelectedAccessLevel(selectedUserAccessLevel);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, embed, embedGroupSaved, accessLevels.length]);

  const getTeamSessionsRight = useCallback(
    (groupOwnerId: string) => {
      if (!groupOwnerId) {
        return { edit: false };
      }

      return (
        accessLevels.find(
          (accessLevel) => accessLevel?.user?.id === groupOwnerId
        )?.teamSessions || {}
      );
    },
    [accessLevels]
  );

  useAccessLevelsModuleOverrideServerSynch({
    isAllAccountOverrideActive,
    selectedAccessLevelLocalOverride,
    selectedAccessLevel,
    setIsAllAccountOverrideActive,
    setSelectedAccessLevel,
    setSelectedAccessLevelLocalOverride,
    overrideActiveForModule,
    accessLevels,
    allAccountsAccount: getAllAccountsAccount(userAccessLevelsData, t),
  });

  useEffect(
    () => {
      if (embed && !selectedAccountIdParam && !selectedAccessLevel?.user?.id) {
        setSelectedAccessLevel(getAllAccountsAccount(userAccessLevelsData, t));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAccessLevel?.user?.id, embed, userAccessLevelsData, t]
  );

  useEffect(() => {
    if (sessionId && selectedAccessLevel?.user?.id && !selectedAccountIdParam) {
      setPreferences({
        variables: {
          key: UserPreferenceKeys.SELECTED_ACCOUNT_ID,
          value: selectedAccessLevel.user.id,
          sessionId,
        },
      });
    }
  }, [
    sessionId,
    selectedAccountIdParam,
    selectedAccessLevel?.user?.id,
    setPreferences,
  ]);

  const setSelectedAccount = (
    newSelectedAccountId: string,
    localOverride = false,
    currentModulePathname = ""
  ) => {
    setIsAllAccountOverrideActive(localOverride);

    if (newSelectedAccountId === ALL_ACCOUNTS_ID) {
      setSelectedAccessLevel(getAllAccountsAccount(userAccessLevelsData, t));
      setSelectedAccessLevelLocalOverride(null);
      return;
    }

    const newSelectedAccessLevel = userAccessLevelsData?.userAccessLevels?.find(
      (accessLevel) => accessLevel.user?.id === newSelectedAccountId
    );

    if (newSelectedAccessLevel?.user?.id) {
      if (localOverride) {
        setSelectedAccessLevelLocalOverride(newSelectedAccessLevel);
        setOverrideActiveForModule(getModuleOverride(currentModulePathname));
      } else {
        setSelectedAccessLevel(newSelectedAccessLevel);
        setSelectedAccessLevelLocalOverride(null);
      }
    } else {
      // This is special case when we display some account only in collections -
      // they are not available as global accounts in the system but we need to provide
      // selectedAccountId for collection module like it was
      // SEE https://xpsnetwork.atlassian.net/browse/RB-1036
      setSelectedAccessLevelLocalOverride(
        generateAccessLevelLocalOverrideAccount(
          accessLevels,
          newSelectedAccountId
        )
      );
      setOverrideActiveForModule(getModuleOverride(currentModulePathname));
    }
  };

  const resetLocalOverride = useCallback(
    (newPathname: string) => {
      const newModule = getModuleOverride(newPathname);

      if (newModule !== overrideActiveForModule) {
        setOverrideActiveForModule(newModule);
        setIsAllAccountOverrideActive(false);
      }
    },
    [overrideActiveForModule]
  );

  return (
    <AccessLevelsContext.Provider
      value={{
        selectedAccount: isAllAccountOverrideActive
          ? selectedAccessLevelLocalOverride?.user
          : selectedAccessLevel?.user,
        selectedAccountStatisticsAccess: isAllAccountOverrideActive
          ? selectedAccessLevelLocalOverride?.statistics
          : selectedAccessLevel?.statistics,
        isAllAccountOverrideActive,
        accessLevels,
        setSelectedAccount,
        refetch,
        resetLocalOverride,
        getTeamSessionsRight,
      }}
    >
      {children}
    </AccessLevelsContext.Provider>
  );
}

export const useAccessLevelsContext = () => useContext(AccessLevelsContext);
