import React, { useMemo } from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { useSettingsContext } from "../../../contexts/settingsContext";
import { useUserContext } from "../../../contexts/User";
import Avatar from "../../Avatar";
import StyledText from "../../StyledText";
import { AgendaSession } from "../model";

const LeftContainer = styled.div`
  width: 110px;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
  display: flex;
`;

const TimeContainer = styled.div`
  margin-bottom: 10px;
`;

const StartTimeContainer = styled.div`
  flex-direction: column;
  display: flex;
`;

const StartTime = styled(StyledText)`
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
`;

const EndTime = styled(StyledText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.5px;
  color: ${COLOR_TEXT_DARK};
`;

type LeftSectionProps = Pick<
  AgendaSession,
  | "start"
  | "durationMinutes"
  | "ownerUser"
  | "avatarsOfChildren"
  | "__typename"
  | "name"
  | "teamAthleteGuid"
>;

export function LeftSection({
  start,
  durationMinutes,
  ownerUser,
  avatarsOfChildren,
  teamAthleteGuid,
  name,
  __typename,
}: LeftSectionProps) {
  const { timeFormatTemplate } = useSettingsContext();
  const { isFamilyMember } = useUserContext();

  const sessionStart = dayjs(start);
  const sessionEnd = sessionStart.add(durationMinutes || 0, "minute");
  const isMultipleDaySession = sessionEnd.isAfter(sessionStart.endOf("day"));

  const formatStartTime = sessionStart.format(timeFormatTemplate);
  const formatEndTime = isMultipleDaySession
    ? sessionEnd.format(`DD/MM ${timeFormatTemplate}`)
    : sessionEnd.format(timeFormatTemplate);

  const avatarUri = useMemo(() => {
    if (isFamilyMember && ownerUser?.thumb) {
      return ownerUser.thumb;
    }

    return teamAthleteGuid
      ? ownerUser?.thumb
      : avatarsOfChildren?.length
        ? avatarsOfChildren[0].img
        : null;
  }, [avatarsOfChildren, ownerUser?.thumb, teamAthleteGuid, isFamilyMember]);

  const userName = useMemo(() => {
    if (isFamilyMember) {
      return name;
    }

    return teamAthleteGuid
      ? ownerUser?.profile?.userName
      : avatarsOfChildren?.length
        ? avatarsOfChildren[0].name
        : undefined;
  }, [
    avatarsOfChildren,
    ownerUser?.profile?.userName,
    teamAthleteGuid,
    isFamilyMember,
    name,
  ]);

  return (
    <LeftContainer>
      {start ? (
        <TimeContainer>
          {__typename === "Questionnaire" &&
          formatStartTime === "00:00" ? null : (
            <StartTimeContainer>
              <StartTime>{formatStartTime}</StartTime>
              {!!durationMinutes && <EndTime>{formatEndTime}</EndTime>}
            </StartTimeContainer>
          )}
        </TimeContainer>
      ) : null}
      {avatarUri ? (
        <Avatar uri={avatarUri} userName={userName} size={35} />
      ) : null}
    </LeftContainer>
  );
}
