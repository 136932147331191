import React, { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import {
  COLOR_GREEN_PRACTICE_DEFAULT,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { useNotificationsContext } from "../../contexts/notifications";
import { usePracticeContext } from "../../contexts/practice/PracticeProvider";
import { useResourceCalendarContext } from "../../contexts/resourceCalendar/ResourceCalendarProvider";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  AgendaDocument,
  CalendarDocument,
  GetPracticeDocument,
  SessionPlanDocument,
  useAddDrillToPracticeMutation,
  useGetGroupPracticeColorQuery,
  useSessionPlanQuery,
} from "../../graphql";
import { useCreatePracticeWithDrills } from "../../hooks/sessions/useCreatePracticeWithDrills";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { BottomBar } from "../BottomBar";
import { ButtonColor } from "../Button/";
import {
  FOLDER_STRUCTURE_SECTION_WIDTH,
  FolderStructure,
} from "../FolderStructure";

import { PlanList } from "./CreateDrill/PlanList";
import { PracticeForm } from "./CreateDrill/PracticeForm";
import { getTime, rgbToHex } from "./utils";

export type CreatePracticeFormState = {
  title: string;
  date: Dayjs;
  time: string;
  duration: number;
  color: string;
  location: string;
  locationDescription: string;
  attendance: number;
  notes: string;
  coachNotes: string;
};

export type CreatePracticeValues = Omit<
  CreatePracticeFormState,
  "attendance"
> & {
  attendance: number;
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 900px;
  height: 100%;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

const FormWrapper = styled.div`
  width: calc(100% - ${FOLDER_STRUCTURE_SECTION_WIDTH}px);
  min-width: 650px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  width: inherit;
  background-color: ${COLOR_WHITE};
  & > div {
    padding: 10px 20px;
  }
`;

interface CreatePracticeModalProps {
  openedForSpecificGroupId: string;
  session?: any;
  groupOwnerId?: string;
  selectedStartDate?: Date;
}

export function CreatePracticeModal({
  openedForSpecificGroupId,
  session,
  groupOwnerId,
  selectedStartDate,
}: CreatePracticeModalProps) {
  const { actions } = useModalContext();
  const { t } = useTranslation();
  const {
    practiceSession,
    onClear,
    practiceLines,
    originalPracticeLines,
    practiceStartDate,
    setPracticeLines,
    currentGroupId,
    setCurrentGroupId,
    setPracticeStartDate,
  } = usePracticeContext();
  const { selectableResourceList } = useResourceCalendarContext();
  const { selectedGroup, setSelectedGroup } = useAgendaContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const { sessionId, language, timezone } = useUserContext();
  const { setSelectedSessionOwnerId } = useResourceCalendarContext();

  const {
    id,
    start,
    durationMinutes,
    rgb,
    notes,
    specialNotes,
    location,
    ownerUser,
    googleLocationId,
    attendedMinutesBefore,
  } = practiceSession || {};

  const { data: groupColorData } = useGetGroupPracticeColorQuery({
    variables: {
      sessionId,
      id: currentGroupId,
      timezone,
      language,
    },
    skip: !currentGroupId,
  });

  const [selectedGoogleLocationId, setSelectedGoogleLocationId] = useState(
    googleLocationId || ""
  );

  const client = useApolloClient();

  const isEditMode = !!id;
  const ownerUserId = isEditMode ? ownerUser?.id : groupOwnerId;

  setSelectedSessionOwnerId(ownerUserId);

  const { createPracticeWithDrills, createPracticeLoading } =
    useCreatePracticeWithDrills();
  const [isSectionHidden, setSectionHidden] = useState(false);
  const [canChangeOnScroll, setCanChangeOnScroll] = useState(false);

  useEffect(() => {
    // initially keep open before practice lines load
    const timeout = setTimeout(() => {
      setCanChangeOnScroll(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (!practiceStartDate && selectedStartDate) {
      setPracticeStartDate(dayjs(selectedStartDate).valueOf());
    }
  }, [practiceStartDate, selectedStartDate, setPracticeStartDate]);

  const primaryButtonText = isEditMode ? t("update") : t("create");
  const date = useMemo(
    () =>
      start ||
      (selectedStartDate
        ? dayjs(selectedStartDate)
        : practiceStartDate
          ? dayjs(practiceStartDate)
          : dayjs().set("hour", 9).set("minute", 30)),
    [practiceStartDate, selectedStartDate, start]
  );
  const time = getTime(date);
  const duration = durationMinutes ?? 0;
  const colorToDisplay = rgb ? rgbToHex(rgb) : COLOR_GREEN_PRACTICE_DEFAULT;
  const [addDrillToPracticeMutation] = useAddDrillToPracticeMutation();

  useEffect(() => {
    const newId =
      practiceSession?.groupId || openedForSpecificGroupId || selectedGroup?.id;
    if (newId && currentGroupId !== newId) {
      setCurrentGroupId(newId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentGroupId,
    openedForSpecificGroupId,
    selectedGroup?.id,
    practiceSession,
  ]);

  const { data: sessionPlanData } = useSessionPlanQuery({
    variables: {
      sessionId,
      timezone,
      language,
      sessionGuid: practiceSession?.id,
    },
    skip: !practiceSession?.id,
    onCompleted: (data) => {
      if (data?.practice?.practiceLines?.length && !practiceLines?.length) {
        setPracticeLines(sessionPlanData.practice.practiceLines);
      }
    },
  });

  const { handleSubmit, getValues, reset, ...formMethods } =
    useForm<CreatePracticeValues>({
      mode: "all",
      defaultValues: {
        title: "",
        date,
        time,
        duration,
        color: colorToDisplay,
        location: "",
        locationDescription: "",
        attendance: 0,
        notes: "",
        coachNotes: "",
      },
    });

  useEffect(() => {
    if (practiceSession?.id) {
      reset({
        title: practiceSession?.label,
        date,
        time,
        duration,
        color: colorToDisplay,
        location,
        locationDescription: practiceSession.xtraLocation,
        attendance: attendedMinutesBefore,
        notes,
        coachNotes: specialNotes,
      });
    }
  }, [
    colorToDisplay,
    date,
    duration,
    location,
    notes,
    specialNotes,
    practiceSession,
    reset,
    time,
    attendedMinutesBefore,
  ]);

  useEffect(() => {
    if (groupColorData?.getGroup?.practiceColor && !rgb) {
      formMethods.setValue(
        "color",
        `#${groupColorData?.getGroup?.practiceColor}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupColorData?.getGroup?.practiceColor, rgb]);

  const selectedResources = useMemo(
    () =>
      selectableResourceList
        ?.map((resource) => {
          if (resource?.isSelected) {
            return { guid: resource.resourceId };
          }
        })
        .filter((selectedResource) => selectedResource && selectedResource) ||
      [],
    [selectableResourceList]
  );

  const handleCleanUp = () => {
    onClear();
    setSelectedGroup(null);
    actions.closeModal();
    Storage.removeItem(StorageKeys.practiceForm);
    Storage.removeItem(StorageKeys.gameOrEvent);
  };

  const onSubmit: SubmitHandler<CreatePracticeValues> = async ({
    attendance,
    ...otherData
  }) => {
    if (!id && !selectedGroup?.id && !openedForSpecificGroupId) {
      return showErrorNotification(t("noGroupSelected"));
    }

    let practiceLinesToRemove = [];
    const previousPracticeLines = sessionPlanData?.practice?.practiceLines;

    if (practiceSession?.id && previousPracticeLines.length) {
      practiceLinesToRemove = previousPracticeLines.filter(
        (obj) => !practiceLines.some((item) => item.id === obj.id)
      );
    }

    await createPracticeWithDrills({
      data: {
        attendance:
          attendance && !Number.isNaN(attendance)
            ? Number(attendance)
            : undefined,
        ...otherData,
      },
      selectedGoogleLocationId,
      practiceId: id || null,
      practiceLines,
      resourcesUsage: { items: selectedResources },
      groupId: currentGroupId,
      practiceLinesToRemove,
      originalPracticeLines,
    });

    client.refetchQueries({
      include: [
        SessionPlanDocument,
        CalendarDocument,
        AgendaDocument,
        GetPracticeDocument,
      ],
    });

    handleCleanUp();
    setSelectedSessionOwnerId(null);
  };

  const handleCancel = () => {
    handleCleanUp();
    setSelectedSessionOwnerId(null);
  };

  const toggleSectionVisibility = (hide?: boolean) =>
    setSectionHidden((current) =>
      typeof hide !== "undefined" ? hide : !current
    );

  const toggleSectionVisibilityOnScroll = (hide?: boolean) => {
    if (canChangeOnScroll) {
      toggleSectionVisibility(hide);
    }
  };

  const createNewDrill = async (templateName) => {
    if (id) {
      const { data } = await addDrillToPracticeMutation({
        variables: {
          sessionId,
          language,
          timezone,
          input: {
            practiceID: id,
            idx: practiceLines.length + 1,
            minutes: null,
            templateName,
          },
        },
      });

      setPracticeLines([...practiceLines, data.createDrill]);
    } else {
      setPracticeLines([
        ...practiceLines,
        {
          id: `${practiceLines.length}-${Math.round(Math.random() * 1000)}`,
          __typename: "Drill",
          name: templateName,
        },
      ]);
    }

    showSuccessNotification(t("drillCreateSuccess"));
  };

  return (
    <ModalContainer>
      <Layout>
        <FormWrapper>
          <PracticeForm
            formMethods={formMethods}
            setSelectedGoogleLocationId={setSelectedGoogleLocationId}
            selectedGoogleLocationId={selectedGoogleLocationId}
            googleMapsUrl={practiceSession?.googleMapsUrl}
            isSectionHidden={isSectionHidden}
            toggleSectionVisibility={toggleSectionVisibility}
            setManuallyToggle={() => setCanChangeOnScroll(false)}
            practiceId={session?.id}
          />
          <PlanList
            isSectionHidden={isSectionHidden}
            toggleSectionVisibility={toggleSectionVisibilityOnScroll}
            canChangeOnScroll={canChangeOnScroll}
          />
        </FormWrapper>
        <FolderStructure
          onCreateNewDrill={createNewDrill}
          folderListStyle={{ maxHeight: "unset", height: "calc(100% - 93px)" }}
          isPractice
          accountIdForCollections={ownerUserId}
        />
      </Layout>
      <ButtonWrapper>
        <BottomBar
          primary={{
            text: primaryButtonText,
            variant: ButtonColor.Blue,
            isLoading: createPracticeLoading,
            onClick: handleSubmit(onSubmit),
          }}
          secondary={{
            text: t("cancel"),
            onClick: handleCancel,
          }}
        />
      </ButtonWrapper>
    </ModalContainer>
  );
}
