import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../colors";
import StyledText from "../StyledText";

export const ConfirmModalText = styled(StyledText)<{
  color?: string;
  marginBottom?: number;
  marginTop?: number;
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: ${({ color }) => color || COLOR_TEXT_DARK};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-top: ${({ marginTop }) => marginTop || 0};
`;
