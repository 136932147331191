import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const SettingsGearIcon = memo(
  ({ width = 21, height = 21, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6916 2.42813C13.4112 2.625 14.0654 2.8875 14.7196 3.28125L16.486 1.90313C17.5981 2.69063 18.5794 3.675 19.2991 4.85625L17.7944 6.36562C18.1869 7.02187 18.4486 7.67813 18.6449 8.4L20.8692 8.925C20.9346 9.45 21 9.975 21 10.5C21 11.0906 20.9346 11.6156 20.8692 12.1406L18.5794 12.7312C18.3832 13.4531 18.1215 14.1094 17.729 14.7656L19.1028 16.5375C18.3832 17.5875 17.4673 18.4406 16.4206 19.1625L14.6542 17.7844C14 18.1781 13.3458 18.4406 12.6262 18.6375L12.2336 20.8687C11.5794 20.9344 11.0561 21 10.4673 21C9.8785 21 9.28972 20.9344 8.70093 20.8687L8.24299 18.6375C7.52337 18.4406 6.86916 18.1781 6.21495 17.7844L4.4486 19.1625C3.40187 18.4406 2.5514 17.5219 1.83178 16.4719L3.20561 14.7C2.81308 14.0437 2.5514 13.3875 2.35514 12.6656L0.130841 12.2719C0.0654206 11.6156 0 11.0906 0 10.5C0 9.90938 0.0654206 9.31875 0.130841 8.72813L2.35514 8.26875C2.61682 7.54688 2.8785 6.89062 3.27103 6.23438L1.8972 4.52813C2.61682 3.47813 3.53271 2.625 4.57944 1.90313L6.34579 3.28125C7 2.8875 7.65421 2.625 8.37383 2.42813L8.76636 0.196875C9.35514 0.065625 9.8785 0 10.4673 0C11.0561 0 11.6449 0.065625 12.2336 0.13125L12.6916 2.42813ZM5.23364 10.5C5.23364 13.3875 7.58879 15.75 10.4673 15.75C13.3458 15.75 15.7009 13.3875 15.7009 10.5C15.7009 7.6125 13.3458 5.25 10.4673 5.25C7.58879 5.25 5.23364 7.6125 5.23364 10.5Z"
        fill="#64707A"
      />
    </svg>
  )
);
