import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_RED_SECONDARY,
  COLOR_TEXT_DARK,
} from "../../colors";
import { useResourceCalendarContext } from "../../contexts/resourceCalendar/ResourceCalendarProvider";
import { useTopModalContext } from "../../contexts/UI/TopModal";
import { ResourcesQuery } from "../../graphql";
import { BottomBar } from "../BottomBar";
import { Resource } from "../Calendar/model";
import { SearchBar } from "../SearchBar/SearchBar";
import { SelectedResourceTagList } from "../SelectedResourceTagList";
import { SelectResource } from "../SelectResource";

export type SelectableResource = {
  isOccupied?: boolean;
  isSelected?: boolean;
} & Resource;

type AddResourceModalProps = {
  resourcesData: ResourcesQuery;
  returnModal: () => void;
};

const AddResourceModalWrapper = styled.div`
  min-height: 100%;
  overflow-y: auto;
`;

const TopContentWrapper = styled.div`
  min-height: 100px;
`;

const BottomContentWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
`;

const SearchWrapper = styled.div`
  padding: 15px 15px 0;
`;

const SelectedResourceWrapper = styled.div`
  margin: 0 15px 15px;
`;

const ResourceListWrapper = styled.div<{ hasSelectedResources?: boolean }>`
  padding: 15px 15px 35px 15px;
  max-height: ${({ hasSelectedResources }) =>
    hasSelectedResources ? "calc(100% - 228px)" : "calc(100% - 216px)"};
  overflow-y: auto;
  align-items: center;
`;

const EmptyLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 15px;
`;

const EmptyLabel = styled.span`
  font-size: 14px;
  text-align: center;
  color: ${COLOR_TEXT_DARK};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_GREY_ACTIVE};
`;

const ButtonWrapper = styled.div`
  padding: 15px 20px;
  flex-direction: row;
  align-items: center;
  align-self: flex-end;
  display: flex;
`;

const WarningLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_RED_SECONDARY};
  margin-right: 18px;
`;

export function AddResourceModal({ resourcesData }: AddResourceModalProps) {
  const { t } = useTranslation();
  const { selectableResourceList, setSelectableResourceList } =
    useResourceCalendarContext();
  const { actions } = useTopModalContext();

  const [searchText, setSearchText] = useState("");
  const [localResourceList, setLocalResourceList] = useState(
    selectableResourceList
  );

  const getIsOccupied = (guid: string) => {
    const resourceToCheck = resourcesData?.resources?.find(
      (resource) => resource?.guid === guid
    );

    return !!(
      resourceToCheck &&
      resourceToCheck?.conflicts &&
      resourceToCheck?.conflicts?.items?.length
    );
  };

  const updateLocalResourceList = (id: string) => {
    const updatedResourceList = localResourceList?.map((resource) => {
      if (resource.resourceId === id) {
        return {
          ...resource,
          isSelected: !resource.isSelected,
        };
      }

      return resource;
    });

    setLocalResourceList(updatedResourceList);
  };

  const searchedResourceList = useMemo(
    () =>
      localResourceList.filter((resource) =>
        resource.resourceTitle.toLocaleLowerCase().includes(searchText)
      ),
    [searchText, localResourceList]
  );

  const occupiedResourceList = useMemo(
    () =>
      localResourceList.filter(
        (resource) => resource.isSelected && resource.isOccupied
      ),
    [localResourceList]
  );

  const onDoneClick = () => {
    setSelectableResourceList(localResourceList);
    navigateBack();
  };

  const navigateBack = () => {
    actions.closeTopModal();
  };

  const resourceList = localResourceList?.length
    ? localResourceList
    : selectableResourceList;

  const selectedResources = resourceList?.filter((resource) => {
    if (resource?.isSelected) {
      return resource;
    }
  });

  return (
    <AddResourceModalWrapper>
      <TopContentWrapper>
        <SearchWrapper>
          <SearchBar
            searchValue={searchText}
            setSearchValue={setSearchText}
            placeholder={t("searchResources")}
            backgroundColor={COLOR_GRAY}
          />
        </SearchWrapper>
        <SelectedResourceWrapper>
          <SelectedResourceTagList
            resourcesData={resourcesData}
            localResourceList={localResourceList}
            updateLocalResourceList={updateLocalResourceList}
          />
        </SelectedResourceWrapper>
        <Divider />
      </TopContentWrapper>

      {searchedResourceList?.length ? (
        <ResourceListWrapper hasSelectedResources={!!selectedResources?.length}>
          {searchedResourceList?.map((resource) => {
            const isOccupied = getIsOccupied(resource.resourceId);

            return (
              <SelectResource
                key={resource.resourceId}
                resource={{ ...resource, isOccupied }}
                onSelectResource={updateLocalResourceList}
              />
            );
          })}
        </ResourceListWrapper>
      ) : (
        <EmptyLabelWrapper>
          <EmptyLabel>{t("noAvailableResourcesToAdd")}</EmptyLabel>
        </EmptyLabelWrapper>
      )}

      <BottomContentWrapper>
        <Divider />
        <ButtonWrapper>
          <WarningLabel>
            {occupiedResourceList?.length > 0 &&
              t("resourceOccupied", { x: occupiedResourceList?.length })}
          </WarningLabel>

          <BottomBar
            secondary={{
              text: t("cancel"),
              onClick: navigateBack,
            }}
            primary={{
              text: t("done"),
              onClick: onDoneClick,
            }}
          />
        </ButtonWrapper>
      </BottomContentWrapper>
    </AddResourceModalWrapper>
  );
}
