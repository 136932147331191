export const isImage = (url: string) => {
  const nameArr = url.split("/");
  const nameWithExt = nameArr.length
    ? nameArr[nameArr.length ? nameArr.length - 1 : ""]
    : "";
  const nameSplitted = nameWithExt ? nameWithExt.split(".") : [];
  const ext = nameSplitted[nameSplitted.length ? nameSplitted.length - 1 : ""];
  const valid =
    ext &&
    (ext === "png" ||
      ext === "jpg" ||
      ext === "JPG" ||
      ext === "jpeg" ||
      ext === "gif");

  return { valid, name: nameWithExt, mime: `image/${ext}` };
};

export const isVideo = (url: string) => {
  const nameArr = url.split("/");
  const nameWithExt = nameArr.length
    ? nameArr[nameArr.length ? nameArr.length - 1 : ""]
    : "";
  const nameSplitted = nameWithExt ? nameWithExt.split(".") : [];
  const ext = nameSplitted[nameSplitted.length ? nameSplitted.length - 1 : ""];
  const valid =
    ext &&
    (ext === "mp4" ||
      ext === "wmv" ||
      ext === "avi" ||
      ext === "mpeg" ||
      ext === "mov");

  return { valid, name: nameWithExt, mime: `video/${ext}` };
};
