import React from "react";
import { useTranslation } from "react-i18next";
import { components, OptionProps } from "react-select";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";

import Avatar from "./Avatar";
import { BasicButton } from "./Button";
import { RoundedGroupProfileIcon } from "./Icons";
import { SelectDeselectEnum } from "./Modals/AddToCalendar/TeamOrAthletesSelect";
import { SelectOption } from "./StyledSelectField";

const Container = styled(BasicButton)<{ pointer: boolean }>`
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: 25px;
  justify-content: space-between;
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const WrapperGroupIcon = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

const AvatarWrapper = styled.div`
  margin-right: 10px;
  margin-left: 35px;
`;

const Label = styled.p<{ color: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${({ color }) => color};
`;

const SelectButton = styled(BasicButton)`
  color: ${COLOR_BLUE};
  font-weight: 700;
  font-size: 12px;
`;

export function CustomAthleteOption(
  props: OptionProps<
    SelectOption & {
      isGroup?: boolean;
      isAvatar?: boolean;
      hasAthletes?: boolean;
      allAthletesFromGroupAreIncluded?: boolean;
    }
  >
) {
  const { t } = useTranslation();

  const { data, selectProps } = props;

  const onOptionClick = (event, actionMeta?) => {
    event.stopPropagation();

    const { onChange, onMenuClose } = selectProps;

    onChange(props.data, actionMeta);
    onMenuClose();
  };

  const { isGroup, isAvatar, hasAthletes, allAthletesFromGroupAreIncluded } =
    data;

  return (
    <components.Option {...props}>
      <Container
        pointer={!hasAthletes}
        onClick={(event) => !hasAthletes && onOptionClick(event)}
      >
        <LeftContainer>
          {isGroup && (
            <WrapperGroupIcon>
              <RoundedGroupProfileIcon width={25} height={25} />
            </WrapperGroupIcon>
          )}
          {isAvatar && (
            <AvatarWrapper>
              <Avatar
                size={25}
                userGuid={data.value}
                userName={data.label}
                isThumb={true}
              />
            </AvatarWrapper>
          )}
          <Label color={props.isSelected ? COLOR_WHITE : COLOR_TEXT_DARK}>
            {data.label}
          </Label>
        </LeftContainer>

        {hasAthletes && (
          <SelectButton
            onClick={(event) =>
              onOptionClick(event, {
                action: allAthletesFromGroupAreIncluded
                  ? SelectDeselectEnum.DESELECT_ALL
                  : SelectDeselectEnum.SELECT_ALL,
              })
            }
          >
            {t(
              allAthletesFromGroupAreIncluded ? "deselectAll" : "selectAll"
            ).toUpperCase()}
          </SelectButton>
        )}
      </Container>
    </components.Option>
  );
}
