import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_RED_ACTIVE } from "../../colors";
import { useSettingsContext } from "../../contexts/settingsContext";
import StyledText from "../StyledText";

const DayText = styled(StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: ${COLOR_MEDIUM_GRAY};
`;

const AttendanceAt = styled(StyledText)`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_RED_ACTIVE};
  margin-left: 10px;
`;

const SessionTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SessionTime = ({
  sessionStart,
  attendedMinutesBefore,
  duration,
}: {
  sessionStart: Date;
  attendedMinutesBefore: number;
  duration: number;
}) => {
  const { t } = useTranslation();
  const { timeFormatTemplate } = useSettingsContext();

  const attendanceAt = dayjs(sessionStart)
    .subtract(attendedMinutesBefore ?? 0, "minute")
    .format(timeFormatTemplate);

  const formattedDate = useMemo(() => {
    let startDate;
    let endDate;

    const mDate = dayjs(sessionStart);
    const sessionEnd = mDate.add(duration || 0, "minute");
    const isMultipleDaySession = sessionEnd.isAfter(mDate.endOf("day"));

    if (isMultipleDaySession) {
      endDate = sessionEnd.format("dddd");
      endDate += ", " + sessionEnd.format("LL").replace(",", "");
      endDate += "- " + sessionEnd.format(timeFormatTemplate);
    } else {
      endDate = sessionEnd.format(timeFormatTemplate);
    }

    startDate = mDate.format("dddd");
    startDate += ", " + mDate.format("LL");
    startDate += " - " + mDate.format(timeFormatTemplate);

    if (duration) {
      startDate += " - " + endDate;
    }

    const year = dayjs().format("YYYY");
    const regExp = new RegExp(`[,/]*[ ]+${year}`);

    return startDate.replace(regExp, "").replace(year, "");
  }, [sessionStart, duration, timeFormatTemplate]);

  return (
    <SessionTimeWrapper>
      <DayText>{formattedDate}</DayText>
      {attendedMinutesBefore ? (
        <AttendanceAt>
          {t("attendanceAt", { x: attendanceAt }).toUpperCase()}
        </AttendanceAt>
      ) : null}
    </SessionTimeWrapper>
  );
};
