import { useUserContext } from "../../contexts/User";
import { useUpdateSessionMutation } from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useUpdateSession = () => {
  const { sessionId, language, timezone } = useUserContext();

  const [updateSession] = useUpdateSessionMutation();
  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const updateSessionMutation = async (
    sessionToUpdate,
    refetchQueries: () => string[],
    ...props
  ) => {
    const updateSessionResult: any = await updateSession({
      variables: {
        sessionId,
        timezone,
        language,
        input: {
          id: sessionToUpdate.id,
          ...props[0],
        },
      },
      refetchQueries,
    });

    handleSendManualNotificationAfterAction(
      sessionToUpdate,
      updateSessionResult?.data?.updateSession?.notificationToSuggest
    );
  };

  return { updateSessionMutation };
};
