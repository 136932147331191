export const mockedInjuriesData = {
  __typename: "BodyChartItem",
  id: "-6448069631558402033",
  name: "Injuries",
  bcTemplate: {
    __typename: "BodyChartTemplate",
    templateGuid: "-6448069631558402033",
    name: "Injuries",
    categories: [
      {
        __typename: "BodyChartCategory",
        categoryId: "-6871549890804446819",
        severity: 1,
        description: "Mildly hurt",
        red255: 255,
        green255: 255,
        blue255: 0,
        deleted: false,
      },
      {
        __typename: "BodyChartCategory",
        categoryId: "-3814340078977924221",
        severity: 2,
        description: "Medium pain",
        red255: 255,
        green255: 204,
        blue255: 153,
        deleted: false,
      },
      {
        __typename: "BodyChartCategory",
        categoryId: "-5387016198409018467",
        severity: 3,
        description: "Severe",
        red255: 255,
        green255: 0,
        blue255: 0,
        deleted: false,
      },
    ],
  },
  bcRegistration: {
    __typename: "BodyChartRegistration",
    id: "30082023.270286909185598272",
    bodyChartTemplateGuid: "-6448069631558402033",
    ddmmyyyy: "30082023",
    parts: [
      {
        __typename: "BodyPartEntry",
        bodypartId: "34",
        categoryId: "-3814340078977924221",
        comment: "",
      },
      {
        __typename: "BodyPartEntry",
        bodypartId: "35",
        categoryId: "-3814340078977924221",
        comment: "",
      },
      {
        __typename: "BodyPartEntry",
        bodypartId: "42",
        categoryId: "-6871549890804446819",
        comment: "",
      },
      {
        __typename: "BodyPartEntry",
        bodypartId: "66",
        categoryId: "-5387016198409018467",
        comment: "",
      },
    ],
  },
};
