import styled from "styled-components";

import StyledText from "../../../StyledText";

export const CombinedTestCircleChartWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  margin-top: 30px;
  width: ${({ width }) => width}px;
  max-width: 100%;
  height: ${({ theme }) => (theme.isMobile ? "auto" : "210px")};
  align-items: ${({ theme }) => (theme.isMobile ? "center" : "")};
`;

export const CombinedTestCircleChartResult = styled.div`
  text-align: center;
  width: 160px;
  padding-bottom: ${({ theme }) => (theme.isMobile ? "40px" : "10px")};
  padding-top: 10px;
`;

export const CombinedTestCircleChartResultTitle = styled(StyledText)`
  margin-top: 11px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
`;

export const CombinedTestCircleChartSubresults = styled.div<{
  width: number;
}>`
  display: ${({ theme }) => (theme.isMobile ? "block" : "flex")};
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  gap: ${({ theme }) => (theme.isMobile ? "" : "20px 20px;")};
  margin-left: ${({ theme }) => (theme.isMobile ? "0px" : "30px")};
  width: ${({ theme, width }) =>
    theme.isMobile ? "220px" : `${width - 160}px`};
`;

export const CombinedTestCircleChartSubresult = styled.div<{ width: number }>`
  display: inline-block;
  width: ${({ theme }) => (theme.isMobile ? "50%" : "95px")};
  height: ${({ theme }) => (theme.isMobile ? "110px" : "95px")};
  text-align: center;
`;

export const CombinedTestCircleTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => (theme.isMobile ? "auto" : "100px")};
  margin-top: 5px;
  margin-bottom: ${({ theme }) => (theme.isMobile ? "20px" : "0px")};
  text-align: center;
`;

export const CombinedTestChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;
