import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { Header } from "../components/Header";
import { BodyChartEditor } from "../components/Report/BodyChartWidget/BodyChartEditor";
import {
  BodyChartWidgetModel,
  BodyChartWidgetPreferences,
  CreateBodyChartFormValue,
} from "../components/Report/BodyChartWidget/model";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { useNotificationsContext } from "../contexts/notifications";
import { useUserContext } from "../contexts/User";
import {
  ReportWidgetTemplateBodyChart,
  useSaveWidgetTemplateBodyChartMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport, useNavigateToPreviousPage } from "../hooks";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { parsePreferences } from "../utils/preferences";
import { buildPreferencesString } from "../utils/widgetPreferencesBuilder";

export const BodyChartEditorScreen = () => {
  const { sessionId, language, firstName, lastName } = useUserContext();
  const { t } = useTranslation();

  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
  } = useWidgetEditorUrlParams();
  const { showErrorNotification } = useNotificationsContext();

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { data: templateData } = useWidgetTemplateQuery({
    variables: {
      sessionId,
      id: widgetId,
      language,
    },
    skip: !widgetId,
    fetchPolicy: "network-only",
  });

  const widgetEditData = React.useMemo<BodyChartWidgetModel | null>(() => {
    if (templateData?.reportWidgetTemplateById) {
      const widget =
        templateData.reportWidgetTemplateById as ReportWidgetTemplateBodyChart;

      const preferences = parsePreferences<BodyChartWidgetPreferences>(
        "preferences" in templateData.reportWidgetTemplateById
          ? templateData.reportWidgetTemplateById.preferences
          : "",
        {} as BodyChartWidgetPreferences
      );

      return { ...widget, ...preferences };
    }
    return null;
  }, [templateData]);

  const [saveBodyChart, { loading: savingWidget }] =
    useSaveWidgetTemplateBodyChartMutation();

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const onSubmit = async (values: CreateBodyChartFormValue) => {
    const {
      name,
      bodyChartTemplateID,
      rows,
      aggregation,
      widgetBorders,
      period,
    } = values;

    if (!bodyChartTemplateID) {
      return showErrorNotification(t("selectBodyChart"));
    }

    const informationTemplateIds = rows?.map(
      (row) => row?.measurementTemplateId
    );

    const response = await saveBodyChart({
      variables: {
        sessionId,
        accountId: selectedAccountId,
        input: {
          id: isEditingWidgetTemplate ? widgetId || null : null,
          bodyChartTemplateID,
          name,
          reportTemplateId: reportId || parentReportId,
          informationTemplateIds,
          preferences: buildPreferencesString({
            type: WidgetType.BODY_CHART,
            updatedAt: widgetEditData?.updatedAt ?? dayjs(),
            createdBy: widgetEditData?.createdBy ?? `${firstName} ${lastName}`,
            aggregation,
            widgetBorders,
            period,
          }),
        },
      },
    });

    if (
      !isEditingWidgetTemplate &&
      response.data?.saveWidgetTemplateBodyChart
    ) {
      await addWidgetToReport(response.data.saveWidgetTemplateBodyChart.id);
    }

    navigateToPreviousPage();
  };

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <BodyChartEditor
        widget={widgetEditData}
        isSavingWidget={savingWidget}
        onSubmit={onSubmit}
        onCancel={navigateToPreviousPage}
      />
    </ScreenContainer>
  );
};
