import React from "react";

import styled from "styled-components";

import { ImageType } from "./model";

type ImageModuleProps = {
  data: ImageType[];
};

const Wrapper = styled.div`
  display: flex;
  margin-right: 20px;
  flex-direction: column;
`;

const ImageWrapper = styled.div`
  margin-bottom: 5px;
`;

const StyledImage = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
`;

export function ImageModule({ data }: ImageModuleProps) {
  if (!data.length) {
    return null;
  }

  return (
    <Wrapper>
      {data.map(({ thumb }) => (
        <ImageWrapper key={thumb}>
          <StyledImage src={thumb} />
        </ImageWrapper>
      ))}
    </Wrapper>
  );
}
