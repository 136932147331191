import React, { Fragment } from "react";

import { useTheme } from "styled-components";
import useResizeObserver from "use-resize-observer";

import {
  AggregateFunction,
  ReportWidgetCellNumerical,
} from "../../../../graphql";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../../../hooks";
import { isURL } from "../../../../utils/isURL";
import { roundToOne } from "../../../../utils/number";
import {
  isDrillCollection,
  isExerciseSetCollection,
  isGarminCollection,
  isKinexonCollection,
  isFirstbeatCollection,
  isPlayertecCataplutCollection,
  isCatapultCollection,
  isPolarCollection,
  isPubgCollection,
  isSoccerStatsCollection,
  isTestCollection,
  isTrainingLoadCollection,
  isCustomTextSoccerAttribute,
} from "../../../../utils/reports";
import { getDataKey } from "../../../../utils/statistics";
import { LinkUrl } from "../../../LinkUrl";
import { KeyValuesWidgetModel } from "../../../ReportCreateTable/models";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetTitleContainer,
} from "../../styled";

import {
  KeyValuesWidgetValueDescriptionText,
  KeyValuesWidgetValuesCol,
  KeyValuesWidgetValuesContainer,
  KeyValuesWidgetValuesRow,
  KeyValuesWidgetValueText,
  KeyValuesWidgetValueTextWrapper,
  KeyValuesWidgetValueUnitText,
} from "./styled";

export interface KeyValuesWidgetDataItem {
  measurementId: string;
  attributeId?: string;
  aggregation?: AggregateFunction;
  value: number | string;
  diff?: number;
  isInfo?: boolean;
  text?: string;
  forAllGroups?: boolean;
}

type KeyValuesWidgetComponentProps = {
  widget: KeyValuesWidgetModel;
  data: KeyValuesWidgetDataItem[];
  isReportViewMode?: boolean;
} & IWidgetResizeProps;

const TITLE_WRAPPING_HEIGHT = 30;

const getExtendedDataKey = (
  item: Pick<
    KeyValuesWidgetDataItem,
    "measurementId" | "attributeId" | "aggregation"
  >,
  forAllGroups: boolean = undefined
) => {
  const dataKey = getDataKey(
    item.measurementId,
    item.attributeId,
    item.aggregation
  );

  return forAllGroups ? `${dataKey}:forAllGroups` : dataKey;
};

export function KeyValuesWidgetComponent({
  widget,
  data,
  layoutRef,
  gridItemId,
  isReportViewMode = false,
  setItemToResize,
  setItemsToHideInViewMode,
}: KeyValuesWidgetComponentProps) {
  const { ref: contentRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();
  const { isMobile } = useTheme();

  useBottomWidgetResize({
    id: widget.id,
    shouldResize: isReportViewMode,
    gridItemId,
    widgetHeight: widgetHeight + TITLE_WRAPPING_HEIGHT,
    layoutRef,
    setItemToResize,
    setItemsToHideInViewMode,
    alwaysResize: isMobile,
  });

  const valueMap = new Map<
    string,
    { value: string; diff?: string; numerical?: boolean; text?: string }
  >(
    data.map((item) => {
      let indexOfConfig = -1;

      widget?.grid?.forEach((row, rowIndex) => {
        const index = row
          ?.filter((item) => !!item)
          .findIndex(
            (gridItem) =>
              gridItem.templateId === item.measurementId &&
              gridItem.aggregateFunctions?.[0] === item.aggregation
          );

        if (index !== -1) {
          indexOfConfig = row.length * rowIndex + index;
        }
      });

      const forAllGroups =
        indexOfConfig !== -1
          ? widget?.itemsSettings?.[indexOfConfig].forAllGroups
          : false;

      return [
        !item.isInfo
          ? getExtendedDataKey(item, forAllGroups)
          : getDataKey(item.measurementId, "TEXT", item.aggregation),
        {
          value: item.value?.toString(),
          diff: item.diff?.toString(),
          text: item.text,
          numerical: typeof item.value === "number",
        },
      ];
    })
  );

  const RowWrapper = isMobile ? Fragment : KeyValuesWidgetValuesRow;

  return (
    <WidgetContainer showBorders={widget.widgetBorders}>
      <div ref={contentRef as any}>
        <WidgetTitleContainer style={{ paddingBottom: 0 }}>
          <WidgetTitle>{widget?.name}</WidgetTitle>
        </WidgetTitleContainer>

        <KeyValuesWidgetValuesContainer>
          {widget.grid.map((row, rowIndex) => (
            <RowWrapper
              key={row.map((item) => `${item?.id}_${rowIndex}`).join(":")}
            >
              {row
                .filter((item) => !!item)
                .map((item, itemIndex) => {
                  const forAllGroups =
                    widget?.itemsSettings?.[row.length * rowIndex + itemIndex]
                      ?.forAllGroups;

                  const valueKey =
                    item.__typename === "ReportWidgetCellNumerical" ||
                    isCustomTextSoccerAttribute(item.templateId)
                      ? getExtendedDataKey(
                          {
                            measurementId: item.templateId,
                            attributeId: (item as ReportWidgetCellNumerical)
                              .attributeTemplate?.id,
                            aggregation: item.aggregateFunctions?.[0],
                          },
                          forAllGroups
                        )
                      : getDataKey(
                          item.templateId,
                          "TEXT",
                          item.aggregateFunctions?.[0]
                        );

                  const keyValueAndDiff = valueMap.get(valueKey);
                  const valueItem =
                    !keyValueAndDiff || keyValueAndDiff.value === "NaN"
                      ? { value: "-" }
                      : keyValueAndDiff;
                  const customTitle =
                    widget?.itemsSettings?.[row.length * rowIndex + itemIndex]
                      ?.customTitle;

                  const showDiffValue =
                    item.aggregateFunctions?.[0] === AggregateFunction.Latest &&
                    Boolean(
                      widget?.itemsSettings?.[row.length * rowIndex + itemIndex]
                        ?.compareLatestTo
                    ) &&
                    Boolean(valueItem.diff) &&
                    (isExerciseSetCollection(item.collectionId) ||
                      isTestCollection(item.collectionId) ||
                      isPolarCollection(item.collectionId) ||
                      isGarminCollection(item.collectionId) ||
                      isKinexonCollection(item.collectionId) ||
                      isFirstbeatCollection(item.collectionId) ||
                      isSoccerStatsCollection(item.collectionId) ||
                      isPlayertecCataplutCollection(item.collectionId) ||
                      isCatapultCollection(item.collectionId) ||
                      isPubgCollection(item.collectionId) ||
                      isTrainingLoadCollection(item.collectionId) ||
                      isDrillCollection(item.collectionId));

                  const shouldShowTextValue =
                    widget?.showValuesAsText && valueItem.text;
                  const displayValue =
                    (widget?.showValuesAsText && valueItem.text
                      ? valueItem.text
                      : valueItem.value) ?? "-";

                  return (
                    <KeyValuesWidgetValuesCol
                      key={`${item.id}_${rowIndex}_${itemIndex}`}
                    >
                      <KeyValuesWidgetValueTextWrapper>
                        <KeyValuesWidgetValueText>
                          {isURL(valueItem.value) ? (
                            <LinkUrl
                              url={valueItem.value}
                              size={16}
                              wrapperStyle={{
                                // @ts-ignore
                                lineHeight: 24,
                              }}
                            />
                          ) : (
                            displayValue
                          )}
                          {showDiffValue &&
                            ` (${
                              Number(valueItem.diff) > 0 ? "+" : ""
                            }${roundToOne(Number(valueItem.diff)).toFixed(1)})`}
                          {valueItem.value !== "-" &&
                            !isSoccerStatsCollection(item.collectionId) && // this because we do not want to show minutes unit
                            item.__typename === "ReportWidgetCellNumerical" &&
                            keyValueAndDiff?.numerical &&
                            !shouldShowTextValue && (
                              <KeyValuesWidgetValueUnitText>
                                {item.attributeTemplate?.unitName}
                              </KeyValuesWidgetValueUnitText>
                            )}
                        </KeyValuesWidgetValueText>
                      </KeyValuesWidgetValueTextWrapper>
                      <KeyValuesWidgetValueDescriptionText>
                        {customTitle || item.name}
                      </KeyValuesWidgetValueDescriptionText>
                    </KeyValuesWidgetValuesCol>
                  );
                })}
            </RowWrapper>
          ))}
        </KeyValuesWidgetValuesContainer>
      </div>
    </WidgetContainer>
  );
}
