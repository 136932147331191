import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { useMobileModalContext } from "../../../../contexts/mobileModal/MobileModalProvider";
import { useUserContext } from "../../../../contexts/User";
import { Group, useGetTheGroupsImInListQuery } from "../../../../graphql";
import { BasicButton } from "../../../Button";
import Loading from "../../../Loading";
import { SearchBar } from "../../../SearchBar";
import {
  CloseTextButton,
  HeaderTitle,
  ModalHeaderContainer,
  ModalHeaderContent,
  ModalWrapper,
  SpacingContainer,
} from "../styled";

import { GroupPickerRow } from "./GroupPickerRow";

const GroupPickerWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const GroupPickerModal = () => {
  const { t } = useTranslation();
  const { hideModal } = useMobileModalContext();
  const { sessionId, language } = useUserContext();

  const [search, setSearch] = useState<string>("");
  const [searchParams] = useSearchParams();
  const allowedGroups = searchParams.get("allowedGroups")?.split(",");
  const selectedAthleteIdFromMobileApp = searchParams.get(
    "selectedAthleteIdFromMobileApp"
  );
  const selectedGroupsFromMobileApp = searchParams.get("selectedGroups");

  const { data: { getTheGroupsImIn: myGroups } = {}, loading: loadingGroups } =
    useGetTheGroupsImInListQuery({
      variables: {
        sessionId,
        requireEditability: false,
        language,
        accountId: undefined,
        mustBeAccessibleBy: selectedAthleteIdFromMobileApp || undefined,
      },
      fetchPolicy: "cache-first",
      skip: !sessionId,
    });

  const myFilteredGroups = useMemo(() => {
    if (selectedGroupsFromMobileApp) {
      const groupsIdFromParams = selectedGroupsFromMobileApp.split(",");
      const filteredGroups = [];
      myGroups?.map((group) => {
        if (groupsIdFromParams.find((groupId) => groupId === group.id)) {
          const newGroup = {
            ...group,
            subGroups: [],
          };
          group?.subGroups?.map((subGroup) => {
            if (groupsIdFromParams.find((groupId) => groupId === subGroup.id)) {
              newGroup.subGroups.push(subGroup);
            }
          });
          filteredGroups.push(newGroup);
        }
      });

      return filteredGroups;
    }

    return myGroups;
  }, [myGroups, selectedGroupsFromMobileApp]);

  const options = useMemo(
    () =>
      (allowedGroups?.length
        ? myFilteredGroups?.filter(({ id }) => allowedGroups?.includes(id))
        : myFilteredGroups
      )?.flatMap((group) => [
        group,
        ...group.subGroups.map((subGroup) => ({
          ...subGroup,
          isSubGroup: true,
        })),
      ]) ?? [],
    [myFilteredGroups, allowedGroups]
  );

  const filteredGroups = options.filter((group) =>
    group?.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <ModalWrapper>
      <ModalHeaderContainer>
        <ModalHeaderContent>
          <HeaderTitle>{t("selectTeam")}</HeaderTitle>
        </ModalHeaderContent>
        <BasicButton onClick={hideModal}>
          <CloseTextButton>{t("close").toUpperCase()}</CloseTextButton>
        </BasicButton>
      </ModalHeaderContainer>
      <SpacingContainer>
        <SearchBar
          value={search}
          onClear={() => setSearch("")}
          onChangeText={(value) => setSearch(value)}
          iconColor="#64707A"
        />
      </SpacingContainer>
      {loadingGroups ? (
        <Loading />
      ) : (
        <GroupPickerWrapper>
          {filteredGroups?.map((group, index) => (
            <GroupPickerRow
              key={`flatListItem${group?.name}${index}`}
              group={group as Group}
            />
          ))}
        </GroupPickerWrapper>
      )}
    </ModalWrapper>
  );
};
