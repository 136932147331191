import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../../colors";
import { useFolderStructureContext } from "../../../contexts/folderStructure";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { useUserContext } from "../../../contexts/User";
import {
  getClientParam,
  setClientParam,
  TreeType,
  useFolderTreeDocumentsQuery,
} from "../../../graphql";
import { ButtonColor, GeneralButton } from "../../Button";
import { Search } from "../../Collections/Search";
import { normalizeString } from "../../Filters/utils";
import { FileType } from "../../Modals/CreateDrill/FileItem";
import { FileList } from "../../Modals/CreateDrill/FileList";
import { FolderItem } from "../../Modals/CreateDrill/FolderItem";
import StyledText from "../../StyledText";
import { WidgetLoader } from "../WidgetLoader";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
`;

const BodyWrapper = styled.div`
  padding: 0 12px;
  border: 1px solid ${COLOR_GREY_ACTIVE};
  flex: 1;
  overflow: auto;
  #folders-empty {
    display: none;

    &:last-child {
      display: flex;
    }
  }
`;
const EmptyFolderList = styled(StyledText)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
`;

const PrimaryButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

type FolderStructureModalProps = {
  collectionId: string;
  selectOption: (newValue: unknown) => void;
  closeModal: () => void;
};

export const FolderStructureModal = ({
  collectionId,
  selectOption,
  closeModal,
}: FolderStructureModalProps) => {
  const { t } = useTranslation();

  const topModal = useTopModalContext();
  const { sessionId } = useUserContext();
  const { folderStructureObject, setFolderStructureObject } =
    useFolderStructureContext();

  const clientSessionId = getClientParam("sessionId");
  const [searchValue, setSearchValue] = useState("");
  const [selectedFile, setSelectedFile] = useState<FileType>();

  const onFileSingleClick = (file: FileType) => setSelectedFile(file);
  const onFileDoubleClick = (file: FileType) => {
    const fileIdRaw = file.id.split(":");
    const value = fileIdRaw[fileIdRaw.length - 1];

    selectOption({ value });
    closeModal();
  };
  const onShowClick = (file: FileType) => {
    //@ts-ignore
    if (file.url || file.jsonUrl || file.mcUrl) {
      topModal.actions.openTopModal({
        title: file.name,
        modal: TopModalType.DOCUMENT,
        params: {
          document: {
            ...file,
            mcUrl: file.modularContentUrl,
            itemName: file.name,
          },
        },
      });
    }
  };

  const controlledSetToggledFolder =
    (folderId: string) => (newToggle: boolean) =>
      setFolderStructureObject((prevObject) => ({
        ...prevObject,
        [folderId]: newToggle,
      }));

  const controlledSetSelectedFolder = (folderId: string) =>
    setFolderStructureObject((prevObject) => ({
      ...prevObject,
      [folderId]: !prevObject[folderId],
    }));

  const { data: folderDocumentsData, loading } = useFolderTreeDocumentsQuery({
    variables: {
      collectionId,
      collectionType: TreeType.Folders,
      desktopStyleSearchText: "",
    },
    skip: !clientSessionId,
  });

  const { folders, documents } = useMemo(() => {
    if (folderDocumentsData?.user?.collection?.tree?.children) {
      return {
        folders: folderDocumentsData.user.collection.tree.children,
        documents: (
          folderDocumentsData.user.collection.tree.documents?.edges ?? []
        )
          .filter((d) => !d.node.folderGuid)
          .filter((d) => {
            if (!searchValue) {
              return true;
            }
            return normalizeString(d.node?.name ?? "")?.includes(
              normalizeString(searchValue)
            );
          }),
      };
    }

    return { folders: [], documents: [] };
  }, [folderDocumentsData, searchValue]);

  useEffect(() => {
    if (sessionId !== clientSessionId) {
      setClientParam("sessionId", sessionId);
    }
  }, [clientSessionId, sessionId]);

  return (
    <Wrapper>
      <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      {folderDocumentsData ? (
        <BodyWrapper>
          <EmptyFolderList id="folders-empty">{t("noResults")}</EmptyFolderList>
          {folders?.map((folder) => (
            <FolderItem
              isTag={false}
              key={folder.id}
              folder={folder}
              collectionId={collectionId}
              isWorkout={false}
              allOpen={!!searchValue}
              isLoading={loading}
              searchValue={searchValue}
              documents={
                folderDocumentsData.user.collection.tree.documents?.edges ?? []
              }
              rootFolder
              selectedFile={selectedFile}
              onFileSingleClick={onFileSingleClick}
              onFileDoubleClick={onFileDoubleClick}
              controlledToggledFolderObject={folderStructureObject}
              controlledSetToggledFolder={controlledSetToggledFolder}
              controlledSelectedFolder={""}
              controlledSetSelectedFolder={controlledSetSelectedFolder}
            />
          ))}
          {documents.length ? (
            <FileList
              loading={false}
              data={documents}
              collectionId={collectionId}
              searchValue={searchValue}
              selectedFile={selectedFile}
              onFileSingleClick={onFileSingleClick}
              onFileDoubleClick={onFileDoubleClick}
            />
          ) : null}
        </BodyWrapper>
      ) : (
        <WidgetLoader />
      )}
      <ActionsWrapper>
        <GeneralButton
          isDisabled={!selectedFile}
          label={"Show"}
          colorVariant={ButtonColor.Light}
          onClick={() => onShowClick(selectedFile)}
        />
        <PrimaryButtons>
          <GeneralButton
            isDisabled={!selectedFile}
            label={"Select"}
            colorVariant={ButtonColor.Blue}
            onClick={() => onFileDoubleClick(selectedFile)}
          />
          <GeneralButton
            label={"Cancel"}
            colorVariant={ButtonColor.Light}
            onClick={closeModal}
          />
        </PrimaryButtons>
      </ActionsWrapper>
    </Wrapper>
  );
};
