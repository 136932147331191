import React from "react";

import styled from "styled-components";

import { ChevronRightIcon } from "../Icons";

type DropdownIndicatorProps = {
  onClick: () => void;
};

const IndicatorWrapper = styled.div`
  display: flex;
  padding: 15px 5px;
`;

export function DropdownIndicator({ onClick }: DropdownIndicatorProps) {
  return (
    <IndicatorWrapper onClick={onClick}>
      <ChevronRightIcon direction="bottom" />
    </IndicatorWrapper>
  );
}
