import React, { Fragment, useEffect, useState } from "react";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_WHITE,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Avatar, LicenseType } from "../../graphql";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import { ChatAnnouncementOnlySection } from "./ChatAnnouncementOnlySection";
import { ChatMessageInput } from "./ChatMessageInput";
import { Conversation } from "./components/Conversation";
import { ConversationWrapper } from "./components/ConversationWrapper";
import { SeenByForGroup } from "./components/SeenByForGroup";
import { SeenByForUser } from "./components/SeenByForUser";
import { areEdgesClose, isContentVisible } from "./utils";

dayjs.extend(relativeTime);

const MessageMetaInfoWrapper = styled.div<{ isSelectedMessage: boolean }>`
  z-index: ${({ isSelectedMessage }) => (isSelectedMessage ? 3 : 1)};
  display: flex;
  flex-direction: column;
`;

const ChatWrapper = styled.div`
  background-color: ${COLOR_WHITE};
  z-index: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100% - 66px);
  flex: 1;
  border-top-width: 1px;
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;
`;

const SendTimeTextWrapper = styled.div`
  align-self: center;
  margin-top: 10px;
`;

export type ChatProps = {
  contact: any; //Chatter;
  allAvatarsInChat: Avatar[];
  loading: boolean;
  fetchingMore: boolean;
  edges: any[]; //MessageEdge[];
  isGroupChat: boolean;
  onFetchMore: () => void;
  onRowPress: (contact) => void;
  onSendMessage: (message) => void;
  sendingMessage: boolean;
  uploading: boolean;
  onDeleteMessage: (messageId) => void;
  deletingMessage: boolean;
  onVideoPress: (video) => void;
  onUrlPress: (url, isModular) => void;
  setIsAvatarHovered: (isHovered: boolean) => void;
  messageError: any;
  hasChatNextPage: boolean;
};

export const conversationWrapperId = "conversationWrapper";

export const Chat = ({
  contact,
  loading,
  edges,
  allAvatarsInChat,
  onSendMessage,
  sendingMessage,
  uploading,
  onDeleteMessage,
  deletingMessage,
  messageError,
  onVideoPress,
  onRowPress,
  onFetchMore,
  fetchingMore,
  isGroupChat,
  setIsAvatarHovered,
  hasChatNextPage,
}: ChatProps) => {
  const { id } = useUserContext();
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const user = useUserContext();

  const newestMessageId = [...edges].pop()?.msg.id;

  useEffect(() => {
    handleScrollToBottom();
  }, [newestMessageId]);

  const handleScrollToBottom = () => {
    const conversationWrapper = document.getElementById(conversationWrapperId);
    if (conversationWrapper) {
      conversationWrapper.scrollTop = conversationWrapper.scrollHeight;
    }
  };

  const handleSendMessage = async (message) => {
    await onSendMessage(message);
    handleScrollToBottom();
  };

  return (
    <ChatWrapper key={contact.id}>
      <>
        {loading && (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loader />
          </div>
        )}
        {!loading && (
          <ConversationWrapper
            edges={edges}
            fetchingMore={fetchingMore}
            onFetchMore={onFetchMore}
            hasChatNextPage={hasChatNextPage}
          >
            {edges.map((item, index) => {
              if (!item.msg) {
                return null;
              }
              const isReceived = item.msg.fromGuidS !== id;
              const msg = item.msg;
              const isLastMsg = index + 1 === edges.length;
              const isSelectedMessage = selectedMessageId === msg.id;

              const showAgoInfo = !areEdgesClose(edges[index - 1], item);
              return (
                <Fragment key={item.msg.guidS as string}>
                  <MessageMetaInfoWrapper isSelectedMessage={isSelectedMessage}>
                    {showAgoInfo && (
                      <SendTimeTextWrapper>
                        <StyledText color={COLOR_SECONDARY_GREY} fontSize={10}>
                          {dayjs(item.msg.sent).fromNow().toUpperCase()}
                        </StyledText>
                      </SendTimeTextWrapper>
                    )}
                    {user.id !== item.msg.fromGuidS &&
                      isContentVisible(edges[index - 1], item) && (
                        <StyledText
                          fontWeight="semibold"
                          fontSize={10}
                          color={COLOR_MEDIUM_GRAY}
                          style={{ marginTop: 8 }}
                        >
                          {item.msg.fromName}
                        </StyledText>
                      )}
                    <Conversation
                      selectedMessageId={selectedMessageId}
                      setSelectedMessageId={setSelectedMessageId}
                      isSelectedMessage={isSelectedMessage}
                      item={item}
                      isReceived={isReceived}
                      isGroupChat={isGroupChat}
                      edges={edges}
                      msg={msg}
                      index={index}
                      setIsAvatarHovered={setIsAvatarHovered}
                      deletingMessage={deletingMessage}
                      onVideoPress={onVideoPress}
                      onRowPress={onRowPress}
                      onDeleteMessage={onDeleteMessage}
                      messageError={messageError}
                    />
                  </MessageMetaInfoWrapper>
                  {!isGroupChat && !isReceived && isLastMsg && msg.isRead && (
                    <SeenByForUser />
                  )}
                  {isGroupChat &&
                  !isReceived &&
                  isLastMsg &&
                  contact.seen?.who?.length ? (
                    <SeenByForGroup
                      contact={contact}
                      allAvatarsInChat={allAvatarsInChat}
                    />
                  ) : null}
                </Fragment>
              );
            })}
          </ConversationWrapper>
        )}
      </>
      {contact.canPost ? (
        <ChatMessageInput
          contact={contact}
          sendingMessage={sendingMessage}
          uploading={uploading}
          onSendMessage={handleSendMessage}
        />
      ) : (
        <ChatAnnouncementOnlySection
          showAnnouncementText={
            !(
              contact.licence === LicenseType.ClientBasic ||
              !contact?.allFriendsOf
            )
          }
        />
      )}
    </ChatWrapper>
  );
};
