import React, { memo } from "react";

import styled from "styled-components";

import { useFolderNodeQuery } from "../../graphql";
import { DocumentTile } from "../DocumentTile";
import StyledText from "../StyledText";

const TitleWrapper = styled.div<{ isFirst?: boolean }>`
  width: 100%;
  margin-top: ${({ isFirst }) => (isFirst ? 10 : 20)}px;
  display: none;
  &:has(+ .documentTile) {
    display: block;
  }
`;

interface DocumentGroupProps {
  documentList: any[];
  index: number;
  fullId?: string;
  handleOpenModal: (document: any) => void;
  onPlusClick: (document: any) => void;
  groupName: string;
  isTag?: boolean;
}

export const DocumentGroup = memo(
  ({
    documentList,
    index,
    fullId,
    handleOpenModal,
    onPlusClick,
    groupName,
    isTag,
  }: DocumentGroupProps) => {
    const { data: folderNodeData } = useFolderNodeQuery({
      variables: {
        id: fullId,
      },
      skip: !!groupName || !isTag || !fullId,
    });
    if (!documentList?.length) {
      return null;
    }

    return (
      <>
        <TitleWrapper isFirst={index === 0}>
          <StyledText fontSize={12} semibold>
            {groupName?.toUpperCase() ??
              /*@ts-ignore*/
              folderNodeData?.node?.name?.toUpperCase()}
          </StyledText>
        </TitleWrapper>
        {documentList?.map((document, index) => (
          <DocumentTile
            key={`${document.id}-${document.folderGuid}-${document.name}-${index}`}
            {...document}
            onClick={() => handleOpenModal(document)}
            onPlusClick={() => onPlusClick(document)}
            shouldShowFavoriteIcon
            shouldShowAddIcon
            canDrag
            dragData={document}
          />
        ))}
      </>
    );
  }
);
