import React from "react";
import { components, OptionProps } from "react-select";

import { DeleteIcon } from "./Icons";
import { SelectOption } from "./StyledSelectField";

export function MultiValueRemove(
  props: OptionProps<SelectOption & { isSubGroup?: boolean }>
) {
  return (
    <components.MultiValueRemove {...props}>
      <DeleteIcon />
    </components.MultiValueRemove>
  );
}
