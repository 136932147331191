import React, { CSSProperties } from "react";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
} from "../../../colors";
import { Attachment } from "../../../graphql";

type TeamListProps = {
  data: { name?: Attachment["name"] };
  onClick?: () => void;
  style?: CSSProperties;
};

const Item = styled.div`
  background: ${COLOR_GRAY};
  cursor: pointer;
  border-radius: 6px;
  width: fit-content;
  padding: 5px 10px;
  border: 1px solid ${COLOR_GREY_ACTIVE};
`;

const Text = styled.span`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export function AttachmentItem({ data, onClick, style }: TeamListProps) {
  return (
    <Item onClick={onClick} style={style}>
      <Text>{data?.name}</Text>
    </Item>
  );
}
