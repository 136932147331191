import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { UserProfileQuery } from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { isAcesNationEnv } from "../../../utils/isAcesNationEnv";
import Storage from "../../../utils/storage";
import { StorageKeys } from "../../../utils/storageKeys";
import { saveAccount } from "../../../utils/switchAccounts";

export const useLoginWithUserProfile = ({
  userProfileData,
  sessionId,
  rememberMe,
  inModalView = false,
}: {
  userProfileData: UserProfileQuery;
  sessionId: string;
  rememberMe: boolean;
  inModalView: boolean;
}) => {
  const userContext = useUserContext();
  const { i18n } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    const sideEffect = async () => {
      if (userProfileData?.userProfile && sessionId) {
        const defaultUser = userProfileData?.userAccessLevels?.[0]?.user ?? {};

        userContext.actions.setUser({
          sessionId,
          ...userProfileData.userProfile,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          language: i18n.language,
          loading: false,
          defaultUser,
          isTemporary: !rememberMe,
        });

        const accountToSave = {
          id: userProfileData?.userProfile.id,
          profileImageUrlThumb:
            userProfileData?.userProfile.profileImageUrlThumb,
          firstName: userProfileData?.userProfile.firstName,
          lastName: userProfileData?.userProfile.lastName,
          fullName: `${userProfileData?.userProfile.firstName} ${userProfileData?.userProfile.lastName}`,
          isFamilyMember: userProfileData?.userProfile.isFamilyMember,
          isTrainer: userProfileData?.userProfile.isTrainer,
          isLoggedIn: true,
          sessionId,
        };

        await saveAccount(accountToSave, !rememberMe);
        Storage.setItem(
          StorageKeys.sessionId,
          {
            sessionId,
          },
          rememberMe ? undefined : window.sessionStorage
        );

        if (inModalView) {
          const homeRoute = isAcesNationEnv()
            ? ROUTING_CONFIG.calendar
            : ROUTING_CONFIG.myXPS;

          modalActions.closeModal();
          navigate(`/${homeRoute}`);
          userContext.actions.setUser({ userWasSwitched: true });
        }
      }
    };

    sideEffect();
  }, [
    userProfileData,
    sessionId,
    inModalView,
    modalActions,
    rememberMe,
    userContext.actions,
    i18n,
    navigate,
  ]);
};
