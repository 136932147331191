import { useRef } from "react";

import { FetchJsonCombinedTestResultsResponse } from "../../../../services/statistics";
import { getRandomInt } from "../../../../utils/dummyData";
import { CombinedTestWidgetModel } from "../../../ReportCreateTable/models";

export function useGenerateCombinedTestDummyData() {
  const { current: testDummyDataMemoMap } = useRef<
    Map<
      string,
      {
        _diffAbsolute: number;
        _maxAbsolute: number;
        _minAbsolute: number;
        _score: number;
        _scoreAbsolute: number;
      }
    >
  >(new Map());
  const { current: subTestDummyDataMemoMap } = useRef<
    Map<
      string,
      {
        _score: number;
        _diff: number;
        _max: number;
        _min: number;
      }
    >
  >(new Map());

  function generateSubTestDummyData(
    subtest: CombinedTestWidgetModel["combinedTestTemplate"]["subTests"][0],
    teamStats = false
  ) {
    if (!subTestDummyDataMemoMap.has(`${subtest?.id}:${teamStats}`)) {
      const min = 0;
      const max = 10;
      const score = getRandomInt({ min: 5, max: 10 });
      const diff = getRandomInt({ min: score - max, max: score - 5 });

      subTestDummyDataMemoMap.set(`${subtest?.id}:${teamStats}`, {
        _score: score,
        _diff: diff,
        _max: max,
        _min: min,
      });
    }

    return {
      _name: subtest?.name,
      _template: subtest?.id,
      _txt: "",
      _val: "",
      ...subTestDummyDataMemoMap.get(`${subtest?.id}:${teamStats}`),
    };
  }

  return (widget: CombinedTestWidgetModel, teamStats: boolean) => {
    if (
      !testDummyDataMemoMap.has(
        `${widget?.combinedTestTemplate?.mainTest?.id}:${teamStats}`
      )
    ) {
      const testMinAbs = getRandomInt({ min: 0, max: 40 });
      const testMaxAbs = getRandomInt({ min: 60, max: 100 });
      const testDiffAbs = getRandomInt({ min: -10, max: 10 });
      const testScoreAbs = getRandomInt({ min: testMinAbs, max: testMaxAbs });
      const testScore = getRandomInt({ min: 0, max: 100 });

      testDummyDataMemoMap.set(
        `${widget?.combinedTestTemplate?.mainTest?.id}:${teamStats}`,
        {
          _diffAbsolute: testDiffAbs,
          _maxAbsolute: testMaxAbs,
          _minAbsolute: testMinAbs,
          _score: testScore,
          _scoreAbsolute: testScoreAbs,
        }
      );
    }

    const data: FetchJsonCombinedTestResultsResponse = {
      _testName: widget?.combinedTestTemplate?.mainTest?.name,
      _resultsByAthlete: [
        {
          _athleteName: "",
          _results: [
            {
              ...testDummyDataMemoMap.get(
                `${widget?.combinedTestTemplate?.mainTest?.id}:${teamStats}`
              ),
              _subResults:
                widget?.combinedTestTemplate?.subTests?.map((subTest) =>
                  generateSubTestDummyData(subTest, teamStats)
                ) ?? [],
            },
          ],
        },
      ],
      _totalScoreIsSum: false,
    };

    return data;
  };
}
