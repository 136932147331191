import { AggregateFunction } from "../../../../graphql";
import {
  isAcuteChronicCollection,
  isCustomTextSoccerAttribute,
  isInformationCollection,
  isSoccerStatsCollection,
} from "../../../../utils/reports";

export const getPropertyNameInForm = (name: string, columnIndex: number) =>
  `columns[${columnIndex}].${name}`;

const AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LastDayInRange,
  AggregateFunction.Sum,
  AggregateFunction.Average,
  AggregateFunction.Min,
  AggregateFunction.Max,
];

const INFO_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LastDayInRange,
  AggregateFunction.LatestForever,
];

const AC_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.Min,
  AggregateFunction.Max,
];

const SOCCER_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.Sum,
  AggregateFunction.Average,
  AggregateFunction.Min,
  AggregateFunction.Max,
];

export const getAggregations = (measurementTemplateId: string) => {
  if (isCustomTextSoccerAttribute(measurementTemplateId)) {
    return [AggregateFunction.Latest];
  }

  if (isSoccerStatsCollection(measurementTemplateId)) {
    switch (measurementTemplateId) {
      case "SoccerStat.GamesPlayed":
        return [AggregateFunction.Sum];
      case "SoccerStat.MinutesPerGame":
        return [AggregateFunction.Average];
      case "SoccerStat.CleanSheets":
        return [AggregateFunction.Sum, AggregateFunction.Average];
      case "SoccerStat.RedCards":
      case "SoccerStat.YellowCards":
        return [
          AggregateFunction.Latest,
          AggregateFunction.Sum,
          AggregateFunction.Average,
        ];
      default:
        return SOCCER_AGGREGATIONS;
    }
  }

  if (isInformationCollection(measurementTemplateId)) {
    return INFO_AGGREGATIONS;
  }

  if (isAcuteChronicCollection(measurementTemplateId)) {
    return AC_AGGREGATIONS;
  }

  return AGGREGATIONS;
};
