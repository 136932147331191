import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { ReportWidgetTemplateHeader } from "../../graphql";
import Dropdown from "../Dropdown";
import Hoverable from "../Hoverable";
import { IconButton } from "../IconButton";
import { PrintIcon, ThreeDotsIcon } from "../Icons";
import { OptionItemText, StyledOptionItem } from "../Report/styled";

type AthleteHeaderOptionsForAthleteProfile = {
  onEdit?(): void;
  title?: string;
  tabTemplates?: any[];
  headerWidget?: ReportWidgetTemplateHeader;
  canEdit: boolean;
};

const AthleteHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export function AthleteHeaderOptions({
  onEdit = () => {},
  title,
  tabTemplates = [],
  headerWidget,
  canEdit,
}: AthleteHeaderOptionsForAthleteProfile) {
  const { t } = useTranslation();
  const {
    actions: { openModal },
  } = useModalContext();

  const openPrintModal = () => {
    openModal({
      title: "",
      modal: ModalType.PRINT_ATHLETE_REPORT,
      params: { reportName: title ?? "", tabTemplates, headerWidget },
    });
  };

  return (
    <AthleteHeaderWrapper>
      <IconButton icon={<PrintIcon />} onClick={openPrintModal} />
      {canEdit && (
        <Dropdown
          dropdownContainerStyle={{
            padding: 0,
            marginRight: 0,
            width: 170,
          }}
          dropdownContentStyle={{
            padding: "5px 0",
            top: 10,
            right: 10,
          }}
          component={({ toggle, show }) => (
            <IconButton
              icon={<ThreeDotsIcon isActive={show} />}
              enforceActiveState={show}
              onClick={toggle}
            />
          )}
        >
          <Hoverable>
            {(isHovered) => (
              <StyledOptionItem onClick={onEdit} isHovered={isHovered}>
                <OptionItemText>{t("editReport")}</OptionItemText>
              </StyledOptionItem>
            )}
          </Hoverable>
        </Dropdown>
      )}
    </AthleteHeaderWrapper>
  );
}
