import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../../colors";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { Loader } from "../Loader";

import { AthleteRow } from "./AthleteRow";

export type Option = {
  id: string;
  firstName?: string;
  lastName?: string;
  label: string;
  thumb?: string;
  isExpired: boolean;
  name?: string;
};

type AthleteSelectProps = {
  options: Option[];
  value: string;
  loading: boolean;
};

const AthleteSelectWrapper = styled.div`
  margin-top: 10px;
  padding: 0 15px 10px 15px;
`;

const EmptyWrapper = styled.div`
  padding: 30px 15px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

const EmptyLabel = styled.span`
  font-size: 12px;
  text-align: center;
  color: ${COLOR_TEXT_DARK};
`;

export function AthleteSelect({ options, value, loading }: AthleteSelectProps) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const onAthleteSelect = (athlete: string | string[]) => {
    if (typeof athlete === "string") {
      const newSearchParams: Record<string, string> = {
        ...getSearchParamsObject(searchParams),
        focusedAthleteId: athlete,
      };

      if (newSearchParams.focusedGroupId) {
        delete newSearchParams.focusedGroupId;
      }

      if (!athlete) {
        delete newSearchParams.focusedAthleteId;
      }

      setSearchParams(newSearchParams);
    }
  };

  if (loading) {
    return (
      <EmptyWrapper>
        <Loader size="small" color={COLOR_BLUE} />
      </EmptyWrapper>
    );
  }

  if (!options?.length) {
    return (
      <EmptyWrapper>
        <EmptyLabel>{t("noAthletesAvailable")}</EmptyLabel>
      </EmptyWrapper>
    );
  }

  return (
    <AthleteSelectWrapper>
      {options.map((option) => (
        <AthleteRow
          key={option.id}
          data={option}
          value={value}
          onChange={onAthleteSelect}
        />
      ))}
    </AthleteSelectWrapper>
  );
}
