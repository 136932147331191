import React from "react";

import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import { useFolderNodeQuery } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import StyledText from "../StyledText";

import { TabLabel } from "./model";

type Props = {
  id?: string;
  collectionId?: string;
  folderName?: string;
};

const FolderTitleWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

export function FolderTitle({ id, collectionId, folderName }: Props) {
  const user = useUserContext();

  const skipQuery =
    !collectionId ||
    !id ||
    collectionId === TabLabel.ALL ||
    Boolean(folderName) ||
    !isRealTrainer(user);

  const { data } = useFolderNodeQuery({
    variables: {
      id: `folder:${collectionId}:${id}`,
    },
    skip: skipQuery,
  });

  // @ts-ignore For the folders of type Collection and Document doesn't care about props name
  const title = folderName || data?.node?.name;

  return (
    <FolderTitleWrapper>
      <StyledText fontSize={12} semibold>
        {title?.toUpperCase()}
      </StyledText>
    </FolderTitleWrapper>
  );
}
