import { Dayjs } from "dayjs";

export enum AttendaceStatus {
  ATTENDED = 1,
  INJURED = 2,
  SICK = 3,
  OTHER = 4,
}

export interface AttendanceLogDataItem {
  time: Dayjs;
  status: AttendaceStatus;
  comment: string;
  label: string;
  groupName: string;
  athleteComment?: string;
}
