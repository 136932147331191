import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import { useReportBuildingGroupsQuery } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { Checkbox } from "../Checkbox";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { SelectField } from "../SelectField";
import { DraggableLineWrapper } from "../styled";

const LineWrapper = styled(DraggableLineWrapper)`
  justify-content: space-between;
`;

export enum PerformerSelectionMode {
  ATHLETE_SELECTION = "selection",
  ATHLETE_FIXED = "fixed",
  TEAM_SELECTION = "teamSelection",
  TEAM_FIXED = "teamFixed",
}

export enum PerformerSelectionWidgetType {
  GROUP = "group",
  ATHLETE = "athlete",
}

interface PerformerSectionProps {
  performerSelectionMode: PerformerSelectionMode;
  performerSelectionWidgetType: PerformerSelectionWidgetType;
  withTeamSelection?: boolean;
  setFieldValue(name: string, value?: PerformerSelectionMode | string): void;
}

export const PerformerSection = ({
  setFieldValue,
  performerSelectionMode,
  performerSelectionWidgetType: tableType,
  withTeamSelection = false,
}: PropsWithChildren<PerformerSectionProps>) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const isGroupReport = tableType === PerformerSelectionWidgetType.GROUP;

  const { data: reportBuildingGroups } = useReportBuildingGroupsQuery({
    variables: { sessionId, language, accountId: selectedAccountId },
  });

  const usersOptions = reportBuildingGroups?.getTheGroupsImIn.flatMap((group) =>
    group.athletes.map((athlete) => ({
      label: athlete.name,
      value: athlete.id,
    }))
  );

  const groupsOptions = reportBuildingGroups?.getTheGroupsImIn.flatMap(
    (group) => {
      const groupList = [{ label: group.name, value: group.id }];

      if (group.subGroups?.length) {
        groupList.push(
          ...group.subGroups.map((subGroup) => ({
            label: `${group.name} - ${subGroup.name}`,
            value: subGroup.id,
          }))
        );
      }

      return groupList;
    }
  );

  // TODO: find way to differentiate between ids of users and groups (e.g. prefix and parsing)
  const options = [...(usersOptions || []), ...(groupsOptions || [])].filter(
    (option, index, array) =>
      array.findIndex((optionItem) => optionItem.value === option.value) ===
      index
  );

  const title = isGroupReport
    ? withTeamSelection
      ? t("athletes")
      : `${t("athletes")} / ${t("team")}`
    : t("athlete");

  return (
    <Section>
      <SectionTitle title={title} />

      <LineWrapper>
        <Checkbox
          text={t("useSelectionInReport")}
          typeIcon="circle"
          check={() => {
            setFieldValue(
              "performerSelectionMode",
              PerformerSelectionMode.ATHLETE_SELECTION
            );
            setFieldValue(isGroupReport ? "athletes" : "athlete", undefined);
            setFieldValue("teams", undefined);
          }}
          checked={
            performerSelectionMode === PerformerSelectionMode.ATHLETE_SELECTION
          }
        />
        <Checkbox
          text={isGroupReport ? t("fixedAthletes") : t("fixedAthlete")}
          typeIcon="circle"
          check={() => {
            setFieldValue(
              "performerSelectionMode",
              PerformerSelectionMode.ATHLETE_FIXED
            );
            setFieldValue("teams", undefined);
          }}
          checked={
            performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED
          }
        />
      </LineWrapper>
      {performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED &&
        !isGroupReport && (
          <Field name="athlete">
            {({ field, form, meta }: FieldProps) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={options}
              />
            )}
          </Field>
        )}
      {performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED &&
        isGroupReport && (
          <Field name="athletes">
            {({ field, form, meta }: FieldProps) => (
              <SelectField
                isMulti={true}
                field={field}
                meta={meta}
                form={form}
                options={options}
              />
            )}
          </Field>
        )}

      {withTeamSelection && (
        <>
          <LineWrapper>
            <Checkbox
              text={t("useSelectionInReportTeam")}
              typeIcon="circle"
              check={() => {
                setFieldValue(
                  "performerSelectionMode",
                  PerformerSelectionMode.TEAM_SELECTION
                );
                setFieldValue("teams", undefined);
                setFieldValue(
                  isGroupReport ? "athletes" : "athlete",
                  undefined
                );
              }}
              checked={
                performerSelectionMode === PerformerSelectionMode.TEAM_SELECTION
              }
            />
            <Checkbox
              text={t("fixedTeam")}
              typeIcon="circle"
              check={() => {
                setFieldValue(
                  "performerSelectionMode",
                  PerformerSelectionMode.TEAM_FIXED
                );
                setFieldValue(
                  isGroupReport ? "athletes" : "athlete",
                  undefined
                );
              }}
              checked={
                performerSelectionMode === PerformerSelectionMode.TEAM_FIXED
              }
            />
          </LineWrapper>
          {performerSelectionMode === PerformerSelectionMode.TEAM_FIXED && (
            <Field name="teams">
              {({ field, form, meta }: FieldProps) => (
                <SelectField
                  isMulti={true}
                  field={field}
                  meta={meta}
                  form={form}
                  options={groupsOptions}
                />
              )}
            </Field>
          )}
        </>
      )}
    </Section>
  );
};
