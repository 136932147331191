import React from "react";

import { AhtleteProfileHeader } from "../components/AthleteProfile/AhtleteProfileHeader";
import { AthleteProfileReport } from "../components/AthleteProfile/AthleteProfileReport";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { AthleteProfileContextProvider } from "../contexts/athleteProfile";
import { ApRerouteProvider } from "../contexts/athleteProfile/ApRerouteProvider";
import { useAutoSelectAthlete } from "../hooks/report/useAutoSelectAthlete";

export const AthleteProfileScreen = () => {
  useAutoSelectAthlete();

  return (
    <AthleteProfileContextProvider>
      <ApRerouteProvider>
        <ScreenContainer>
          <Header />
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <AhtleteProfileHeader />
            <AthleteProfileReport />
          </div>
        </ScreenContainer>
      </ApRerouteProvider>
    </AthleteProfileContextProvider>
  );
};
