import React, { createContext, PropsWithChildren, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_RED,
  COLOR_WHITE,
  COLOR_YELLOW_WARNING,
} from "../../colors";
import "./customToastifyStyles.css";

type NotificationsContextModel = {
  showSuccessNotification: (message: string) => void;
  showWarningNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
};

const NotificationsContext = createContext<NotificationsContextModel>(null);

export function NotificationsContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const showSuccessNotification = (message: string) => {
    toast.success(message, {
      style: { backgroundColor: COLOR_GREEN, color: COLOR_WHITE },
    });
  };

  const showWarningNotification = (message: string) => {
    toast.warn(message, {
      progressStyle: { backgroundColor: COLOR_ORANGE },
      style: { backgroundColor: COLOR_YELLOW_WARNING, color: COLOR_WHITE },
    });
  };

  const showErrorNotification = (message: string) => {
    toast.error(message, {
      style: { backgroundColor: COLOR_RED, color: COLOR_WHITE },
    });
  };

  return (
    <NotificationsContext.Provider
      value={{
        showErrorNotification,
        showSuccessNotification,
        showWarningNotification,
      }}
    >
      {children}
      <ToastContainer style={{ zIndex: 100001 }} position="bottom-center" />
    </NotificationsContext.Provider>
  );
}

export const useNotificationsContext = () => useContext(NotificationsContext);
