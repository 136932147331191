import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import StyledText from "../StyledText";

const WidgetNoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #aaa;
  pointer-events: none;
`;

export function WidgetNoData() {
  const { t } = useTranslation();

  return (
    <WidgetNoDataContainer>
      <StyledText>{t("noData")}</StyledText>
    </WidgetNoDataContainer>
  );
}
