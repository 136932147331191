import { useUserContext } from "../../contexts/User";
import { useReportBuildingBodyChartTemplatesQuery } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../useGetSelectedAccountIdInMonitoring";

export const useReportBuildingBodyChart = (
  options?: Omit<
    Parameters<typeof useReportBuildingBodyChartTemplatesQuery>[0],
    "variables"
  >
) => {
  const { sessionId, language } = useUserContext();
  const accountId = useGetSelectedAccountIdInMonitoring();

  return useReportBuildingBodyChartTemplatesQuery({
    variables: {
      sessionId,
      language,
      accountId,
    },
    fetchPolicy: "cache-first",
    ...options,
    skip: options?.skip || !accountId,
  });
};
