const REPORTS_PREFIX = "reports";
const WIDGETS_PREFIX = "widgets";
const GROUP_REPORT_PREFIX = "groupReport";
const REPORT_PREFIX = "report";
const ATHLETES_PREFIX = "athletes";
const MONITORING_PREFIX = "athlete/profile";

export const MONITORING_MODULE_PREFIXES = [
  `/${REPORTS_PREFIX}`,
  `/${WIDGETS_PREFIX}`,
  `/${GROUP_REPORT_PREFIX}`,
  `/${REPORT_PREFIX}`,
  `/${MONITORING_PREFIX}`,
  `/${ATHLETES_PREFIX}`,
];

export const BASE_ROUTES = {
  activityTracker: "activityTracker",
  messages: "messages",
  collections: "collections",
  ResourceCalendar: "calendar/resources",
  AthleteProfile: MONITORING_PREFIX,
  ListReports: REPORTS_PREFIX,
  teams: "teams",
  Athletes: ATHLETES_PREFIX,
};

export const ROUTING_CONFIG = {
  ...BASE_ROUTES,

  myXPS: "myXPS",
  calendar: "calendar",
  embed: "embed",
  login: "login",
  forgot: "forgot",
  reset: "reset/:guidOfReset",
  session: "session",
  trainingStatus: "training-status",
  questionnaire: "questionnaire",

  store: "store",
  electronicIDAuth: "electronicIDAuth",

  teamsAthlete: "teams/athlete",

  AthleteCollections: "athlete/profile/library",
  AthleteReports: "athlete/profile/reports",
  AthleteTeam: "athlete/team",

  Report: `${REPORTS_PREFIX}/${REPORT_PREFIX}/:reportId`,
  GroupReport: `${REPORTS_PREFIX}/${GROUP_REPORT_PREFIX}/:reportId`,

  ChartEditor: `${REPORTS_PREFIX}/:reportId/chart`,
  AthleteTableEditor: `${REPORTS_PREFIX}/:reportId/athlete-table`,
  GroupTableEditor: `${REPORTS_PREFIX}/:reportId/group-table`,
  KeyValuesEditor: `${REPORTS_PREFIX}/:reportId/key-values`,
  CombinedTestEditor: `${REPORTS_PREFIX}/:reportId/combined-test`,
  InformationLogEditor: `${REPORTS_PREFIX}/:reportId/information`,
  ActivityLogEditor: `${REPORTS_PREFIX}/:reportId/activity`,
  AttendanceLogEditor: `${REPORTS_PREFIX}/:reportId/attendance-log`,
  AcuteChronicEditor: `${REPORTS_PREFIX}/:reportId/acute-chronic`,
  AthleteTablePeriodizationEditorScreen: `${REPORTS_PREFIX}/:reportId/athlete-table-periodization`,
  GroupTablePeriodizationEditorScreen: `${REPORTS_PREFIX}/:reportId/group-table-periodization`,
  BodyChartEditor: `${REPORTS_PREFIX}/:reportId/body-chart`,

  ChartWidgetEditor: `${WIDGETS_PREFIX}/chart`,
  AthleteTableWidgetEditor: `${WIDGETS_PREFIX}/athlete-table`,
  GroupTableWidgetEditor: `${WIDGETS_PREFIX}/group-table`,
  KeyValuesWidgetEditor: `${WIDGETS_PREFIX}/key-values`,
  CombinedTestWidgetEditor: `${WIDGETS_PREFIX}/combined-test`,
  InformationLogWidgetEditor: `${WIDGETS_PREFIX}/information`,
  ActivityLogWidgetEditor: `${WIDGETS_PREFIX}/activity`,
  AttendanceLogWidgetEditor: `${WIDGETS_PREFIX}/attendance-log`,
  AcuteChronicWidgetEditor: `${WIDGETS_PREFIX}/acute-chronic`,
  AthleteTablePeriodizationWidgetEditor: `${WIDGETS_PREFIX}/athlete-table-periodization`,
  GroupTablePeriodizationWidgetEditor: `${WIDGETS_PREFIX}/group-table-periodization`,
  BodyChartWidgetEditor: `${WIDGETS_PREFIX}/body-chart`,

  workoutEditor: "workout-editor",
};
