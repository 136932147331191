import { useCallback } from "react";

import { useUserContext } from "../../../contexts/User";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { parsePreferences } from "../../../utils/preferences";
import { WidgetMode } from "../WidgetWithData";

import { useSaveWidgetToServer } from "./useSaveWidgetToServer";

export const useDuplicateWidget = (mode: WidgetMode) => {
  const { sessionId } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { saveWidgetToServer } = useSaveWidgetToServer(mode);

  const duplicateWidget = useCallback(
    (widget, reportTemplateId: string) => {
      const sharedVariables = {
        sessionId,
        accountId: selectedAccountId,
        input: {
          id: null,
          reportTemplateId,
          name: widget.name,
        },
      };

      return saveWidgetToServer(
        widget,
        sharedVariables,
        parsePreferences(widget.preferences, {})
      );
    },
    [sessionId, selectedAccountId, saveWidgetToServer]
  );

  return { duplicateWidget };
};
