import { Period } from "../../../models/reports";
import { PerformerSelectionMode } from "../PerformerSection";

export enum ChartType {
  /**
   * Supported for athlete report only
   */
  RADAR = "radar",
  CIRCLE = "circle",
}

export enum CompareTo {
  PREVIOUS_DAY = "previous_day",
  LATEST = "latest",
  NOTHING = "nothing",
}

export type CombinedFormValue = {
  title: string;
  collectionId: string;
  templateId: string;
  templateName: string;
  chartType: ChartType;
  showTeamAverage: boolean; // only for athlete report
  compareTo: CompareTo;
  visibleSubTests: string[];
  performerSelectionMode: PerformerSelectionMode;
  athlete: string;
  athletes: string[];
  widgetBorders: boolean;
  period: Period;
};
