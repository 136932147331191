import styled from "styled-components";

import {
  COLOR_DARK_BLUE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
  COLOR_YELLOW_LINK,
} from "../../colors";

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${COLOR_MEDIUM_GRAY};
`;

export const ActionLinkWrapper = styled.div`
  border-bottom: 1px solid ${COLOR_MEDIUM_GRAY};
  width: 100%;
  padding: 20px 0px;
  justify-content: center;
  display: flex;
`;

export const ActionLink = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${COLOR_YELLOW_LINK};
  cursor: pointer;
`;

export const Wrapper = styled.div`
  background-color: ${COLOR_DARK_BLUE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  margin-bottom: 30px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: default;
`;

export const Title = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: ${COLOR_WHITE};
  margin-bottom: 15px;
  cursor: default;
`;

export const Description = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${COLOR_WHITE};
  text-align: center;
  margin-bottom: 35px;
`;

export const FormWrapper = styled.div`
  width: 322px;
  position: relative;
`;
