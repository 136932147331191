import React from "react";
import { components, MenuListProps } from "react-select";

import styled from "styled-components";

import { ShowFolderStructure } from "./ShowFolderStructure";

const StyledMenuList = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`;

export const MenuListWithFolderStructure = ({
  children,
  ...props
}: MenuListProps) => {
  return (
    <components.MenuList {...props}>
      <ShowFolderStructure {...props} />
      <StyledMenuList>{children}</StyledMenuList>
    </components.MenuList>
  );
};
