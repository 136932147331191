import * as api from "./api";
import {
  JsonProgramGenerationRequest,
  JsonWorkoutCopyRequest,
} from "./endpoints";

export function copyWorkoutsToCalendar({ user, params }) {
  return api.post(JsonWorkoutCopyRequest, params, user);
}

export async function generateCalendar({ user, params }) {
  return await api.post(JsonProgramGenerationRequest, params, user);
}
