import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isRealTrainer } from "../../utils/isRealTrainer";
import Avatar from "../Avatar";
import { MessageIcon } from "../Icons";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import {
  AthleteAdditionalInfo,
  IAthleteAdditionalInfo,
} from "./AthleteAdditionalInfo";

const AthleteMainCard = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 30px;
  border-top-width: 0;
  border-radius: 0;
  background-color: ${COLOR_WHITE};
  ${({ fullWidth }) => (fullWidth ? `width: 100%;` : "")}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 8px;
`;

const EditButton = styled.button`
  height: 35px;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  border: none;
  color: ${COLOR_BLUE};
  background: transparent;
  cursor: pointer;
`;

const AthleteBaseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
  justify-content: center;
  width: 100%;
`;

const LoaderContainer = styled.div`
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 70px;
`;

interface AthleteBaseInfoType extends IAthleteAdditionalInfo {
  id: string;
  name: string;
  isExpired?: boolean;
  firstName?: string;
  lastName?: string;
  profileImageUrl?: string;
  focusedAthlete?: string;
  isAthleteEditable?: boolean;
  refetchFamilyMembers?: () => void;
  userRefetch?: () => void;
  fullWidth?: boolean;
  isLoading?: boolean;
  isTrainer?: boolean;
}

export const AthleteBaseInfo = ({
  id,
  name,
  isExpired,
  firstName,
  lastName,
  profileImageUrl,
  focusedAthlete,
  isAthleteEditable,
  refetchFamilyMembers,
  userRefetch,
  phoneNumber,
  email,
  city,
  birthDay,
  fullWidth,
  isLoading,
  isTrainer,
}: AthleteBaseInfoType) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const navigate = useNavigate();
  const currentUserAsAthlete = useUserContext();
  const [searchParams] = useSearchParams();
  const isUserTypeTrainer =
    searchParams.get("userType") === "Trainer" || isTrainer;

  const onMessagePress = () => {
    modalActions.closeModal();

    navigate({
      pathname: `/${ROUTING_CONFIG.messages}`,
      search: `?id=${id}`,
    });
  };

  function handleEdit() {
    modalActions.openModal({
      modal: ModalType.EDIT_USER_PROFILE,
      title: t("editProfile"),
      params: {
        isEditableByUser: isAthleteEditable,
        refetchFamilyMembers,
        childBasicProfile: {
          profileImageUrl,
          name,
          firstName,
          lastName,
          focusedAthlete,
        },
        userRefetch,
      },
    });
  }

  const canMessage = currentUserAsAthlete.id !== id && !isExpired;

  return (
    <AthleteMainCard fullWidth={fullWidth}>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <Row>
          <Avatar userName={name} size={105} userGuid={id} isThumb />
          <AthleteBaseInfoWrapper>
            <StyledText fontSize={30} fontWeight="bold">
              {name}
            </StyledText>

            {!isRealTrainer(currentUserAsAthlete) && !isUserTypeTrainer ? (
              <StyledText
                fontSize={12}
                fontWeight="semibold"
                color={COLOR_MEDIUM_GRAY}
              >
                {t("athlete").toUpperCase()}
              </StyledText>
            ) : isUserTypeTrainer ? (
              <StyledText
                fontSize={12}
                fontWeight="semibold"
                color={COLOR_MEDIUM_GRAY}
              >
                {t("trainer").toUpperCase()}
              </StyledText>
            ) : null}
            {isRealTrainer(currentUserAsAthlete) ? (
              <Row>
                {!isUserTypeTrainer ? (
                  <AthleteAdditionalInfo
                    phoneNumber={phoneNumber}
                    email={email}
                    city={city}
                    birthDay={birthDay}
                  />
                ) : null}
                {canMessage ? (
                  <MessageIcon
                    style={{ marginLeft: !isUserTypeTrainer ? 50 : "auto" }}
                    cursor="pointer"
                    onClick={onMessagePress}
                  />
                ) : null}
              </Row>
            ) : null}
          </AthleteBaseInfoWrapper>
        </Row>
      )}
      {isAthleteEditable && (
        <EditButton onClick={handleEdit}>{t("edit")}</EditButton>
      )}
    </AthleteMainCard>
  );
};
