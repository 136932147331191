import React, { useMemo } from "react";

import dayjs from "dayjs";
import Highcharts, { Options, SeriesLineOptions } from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import ReactHighcharts from "highcharts-react-official";

import { COLOR_WHITE } from "../../../../colors";
import { MeasurementTemplateForReports } from "../../../../graphql";
import { FetchJsonCombinedTestResultsResponse } from "../../../../services/statistics";

HighchartsMore(Highcharts);

type AthleteCombinedTestRadarChartProps = {
  subTests?: Pick<MeasurementTemplateForReports, "id" | "name">[];
  data: {
    _theAthlete?: FetchJsonCombinedTestResultsResponse;
    _theTeam?: FetchJsonCombinedTestResultsResponse;
  };
  height: number;
  width: number;
};

export function AthleteCombinedTestRadarChart({
  subTests = [],
  data,
  height,
  width,
}: AthleteCombinedTestRadarChartProps) {
  const polarChartOptions = useMemo(() => {
    const athleteResults = getResults(data?._theAthlete) ?? [];
    const teamResults = getResults(data?._theTeam) ?? [];
    const athleteTotalScore = getTotalScore(data?._theAthlete);
    const teamTotalScore = getTotalScore(data?._theTeam);

    const categories = subTests.map((subTest) => subTest.name ?? subTest.id);

    const athleteSubTestResults = new Map(
      athleteResults.map((subResult) => [subResult._template, subResult._score])
    );
    const teamSubTestResults = new Map(
      teamResults.map((subResult) => [
        subResult._template,
        Math.round(subResult._score * 10) / 10,
      ])
    );
    const athleteValues = subTests.map(
      (subTest) => athleteSubTestResults.get(subTest.id) ?? null
    );
    const teamValues = subTests.map(
      (subTest) => teamSubTestResults.get(subTest.id) ?? null
    );

    const series: SeriesLineOptions[] = [];

    series.push({
      type: "line",
      name: "Athlete" + (athleteTotalScore ? ` (${athleteTotalScore})` : ""),
      color: "#4ba8de",
      data: athleteValues,
      pointPlacement: "on",
      animation: false,
    });

    if (!isNaN(teamTotalScore)) {
      series.push({
        type: "line",
        name: "Team" + (teamTotalScore ? ` (${teamTotalScore})` : ""),
        color: "#ec555a",
        data: teamValues,
        pointPlacement: "on",
        animation: false,
      });
    }

    const config: Options = {
      chart: {
        polar: true,
        type: "line",
        animation: false,
      },

      title: {
        text: null,
      },
      subtitle: {
        text:
          (data?._theAthlete?._resultsByAthlete?.[0]?._results?.[0]?._date &&
            dayjs(
              data._theAthlete._resultsByAthlete[0]._results[0]._date
            ).format("MMM D YYYY")) ??
          null,
      },

      xAxis: {
        categories,
        gridLineColor: COLOR_WHITE,
        tickmarkPlacement: "on",
        lineWidth: 0,
      },

      yAxis: {
        gridLineInterpolation: "circle",
        gridLineColor: COLOR_WHITE,
        lineWidth: 0,
        min: Math.min(...athleteResults.map((subResult) => subResult._min)),
        max: Math.max(...athleteResults.map((subResult) => subResult._max)),
      },

      pane: {
        background: [
          {
            backgroundColor: "#edf2fa",
            borderColor: COLOR_WHITE,
          },
        ],
      },

      credits: {
        enabled: false,
      },

      plotOptions: {
        line: {},
      },

      series,
    };

    // Fix chart dimension on widget resize
    if (height && config.chart) {
      config.chart.height = height;
    }
    if (width && config.chart) {
      config.chart.width = width;
    }

    return config;
  }, [data?._theAthlete, data?._theTeam, height, width, subTests]);

  return (
    <ReactHighcharts
      highcharts={Highcharts}
      options={polarChartOptions}
      immutable
    />
  );
}

function getTotalScore(data?: FetchJsonCombinedTestResultsResponse) {
  const scoreProp = data?._totalScoreIsSum ? "_scoreAbsolute" : "_score";

  return Math.round(data?._resultsByAthlete?.[0]?._results?.[0]?.[scoreProp]);
}

function getResults(data?: FetchJsonCombinedTestResultsResponse) {
  return data?._resultsByAthlete?.[0]?._results?.[0]?._subResults;
}
