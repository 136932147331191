import { useState, useEffect } from "react";

interface ImageSize {
  width: number;
  height: number;
}

const useImageSize = (
  imageSrc: string,
  targetWidth: number = 60
): ImageSize | undefined => {
  const [imageSize, setImageSize] = useState<ImageSize>();

  useEffect(() => {
    if (!imageSrc) return;

    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const logoHeight = (img.height / img.width) * targetWidth;
      setImageSize({ width: targetWidth, height: logoHeight });
    };
  }, [imageSrc, targetWidth]);

  return imageSize;
};

export default useImageSize;
