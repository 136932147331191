import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

const MobileNavSideContext = createContext<{
  isMobileSidebarOpen: boolean;
  setIsMobileSideBarOpen: Dispatch<SetStateAction<boolean>>;
}>({
  isMobileSidebarOpen: false,
  setIsMobileSideBarOpen: () => ({}),
});

export function MobileNavSideContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [isMobileSidebarOpen, setIsMobileSideBarOpen] = useState(false);

  return (
    <MobileNavSideContext.Provider
      value={{
        isMobileSidebarOpen,
        setIsMobileSideBarOpen,
      }}
    >
      {children}
    </MobileNavSideContext.Provider>
  );
}

export const useMobileNavSideContext = () => useContext(MobileNavSideContext);
