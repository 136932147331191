import { useUserContext } from "../../contexts/User";
import { ResourcesUsageInput, useCreateTeamEventMutation } from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useCreateTeamEvent = () => {
  const { sessionId, language, timezone } = useUserContext();

  const [createTeamEvent] = useCreateTeamEventMutation();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const createNewTeamEvent = async (
    label: string,
    start: number,
    durationMinutes: number,
    attendedMinutesBefore: number,
    googleLocationId: string,
    location: string,
    xtraLocation: string,
    notes: string,
    colorHexCode: string,
    groupID: string,
    resourcesUsage: ResourcesUsageInput,
    refetchQueries: () => string[]
  ) => {
    const createTeamEventResult = await createTeamEvent({
      variables: {
        timezone,
        language,
        sessionId,
        input: {
          label,
          start,
          durationMinutes,
          attendedMinutesBefore,
          googleLocationId,
          location,
          xtraLocation,
          colorHexCode,
          notes,
          groupID,
          resourcesUsage,
        },
      },

      refetchQueries,
    });

    handleSendManualNotificationAfterAction(
      { ...createTeamEventResult?.data?.createTeamEvent },
      createTeamEventResult?.data?.createTeamEvent?.notificationToSuggest
    );
  };

  return { createNewTeamEvent };
};
