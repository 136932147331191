import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";

export const MembersTitleDivider = styled.div`
  display: flex;
  min-height: 35px;
  height: 35px;
  background-color: ${COLOR_GRAY};
  padding: 0 15px;
  align-items: center;

  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e8eb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e8eb;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
`;
