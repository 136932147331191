import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_SECONDARY_GREY } from "../../../colors";
import { ButtonColor, GeneralButton } from "../../Button";

type Props = {
  note: string;
  setNote: Dispatch<SetStateAction<string>>;
  onConfirmClick: () => void;
  onCloseClick: () => void;
};

const Divider = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${COLOR_GREY_ACTIVE};
  margin: 0 -20px 20px;
`;

const Input = styled.textarea`
  min-height: 150px;
  margin-bottom: 20px;
  padding: 10px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${COLOR_SECONDARY_GREY};
  }
  :-ms-input-placeholder {
    color: ${COLOR_SECONDARY_GREY};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 15px;
`;

function AttendanceForm({
  note,
  setNote,
  onConfirmClick,
  onCloseClick,
}: Props) {
  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  return (
    <>
      <Input
        placeholder={`${t("writeNoteOptional")}...`}
        onChange={handleChange}
        value={note}
        autoFocus={true}
      />
      <Divider />
      <ButtonsContainer>
        <GeneralButton
          colorVariant={ButtonColor.Light}
          label={t("cancel")}
          onClick={onCloseClick}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("confirm")}
          onClick={onConfirmClick}
        />
      </ButtonsContainer>
    </>
  );
}

export default AttendanceForm;
