export type Accounts = {
  [guid: string]: Partial<{
    guid: string;
    id: string;
    name: string;
    types: string[];
  }>;
};

export enum TabLabel {
  ALL = "all",
  DRILLS = "drills",
  DRILL = "drill",
  EXERCISES = "exercises",
  EXERCISE_SET = "exercise_set",
  DOCUMENTS = "documents",
  DOCUMENT = "document",
  WORKOUTS = "workouts",
  WORKOUT_TEMPLATE = "workout_template",
  TEST = "test",
  TESTS = "tests",
  TEAM_SESSIONS = "team sessions",
  TEAM_PRACTICES = "team practices",
  PRACTICE_TEMPLATE_2020 = "practice_template_2020",
  QUESTIONNAIRE_COLLECTION = "questionnaire_collection",
  FORMS = "forms",
}
