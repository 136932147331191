import React, { useState } from "react";

import { canUseDOM } from "fbjs/lib/ExecutionEnvironment";

interface HoverableProps {
  onHoverIn?(): void;
  onHoverOut?(): void;
  children?: React.ReactNode | ((isHovered: boolean) => React.ReactNode);
}

let isEnabled = false;

if (canUseDOM) {
  /**
   * Web browsers emulate mouse events (and hover states) after touch events.
   * This code infers when the currently-in-use modality supports hover
   * (including for multi-modality devices) and considers "hover" to be enabled
   * if a mouse movement occurs more than 1 second after the last touch event.
   * This threshold is long enough to account for longer delays between the
   * browser firing touch and mouse events on low-powered devices.
   */
  const HOVER_THRESHOLD_MS = 1000;
  let lastTouchTimestamp = 0;

  const enableHover = () => {
    if (isEnabled || Date.now() - lastTouchTimestamp < HOVER_THRESHOLD_MS) {
      return;
    }
    isEnabled = true;
  };

  const disableHover = () => {
    lastTouchTimestamp = Date.now();
    if (isEnabled) {
      isEnabled = false;
    }
  };

  document.addEventListener("touchstart", disableHover, true);
  document.addEventListener("touchmove", disableHover, true);
  document.addEventListener("mousemove", enableHover, true);
}

function Hoverable({ onHoverIn, onHoverOut, children }: HoverableProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [hover, showHover] = useState(true);

  const onMouseEnter = () => {
    if (isEnabled && !isHovered) {
      onHoverIn?.();
      setIsHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (isHovered) {
      onHoverOut?.();
      setIsHovered(false);
    }
  };

  const child =
    typeof children === "function" ? children(hover && isHovered) : children;

  return React.cloneElement(React.Children.only(child) as any, {
    onMouseEnter,
    onMouseLeave,
    // prevent hover showing while responder
    onResponderGrant: () => showHover(false),
    onResponderRelease: () => showHover(true),
    // if child is button
    onPressIn: () => showHover(false),
    onPressOut: () => showHover(true),
  });
}

export default Hoverable;
