import { useMemo } from "react";

import dayjs from "dayjs";

import { useUserContext } from "../../../contexts/User";
import {
  useEventQuery,
  useGameQuery,
  useGetPracticeQuery,
  useWorkoutQuery,
} from "../../../graphql";

export enum SessionType {
  Practice = "Practice",
  Event = "Event",
  Game = "Game",
  Workout = "Workout",
}

interface UseSessionDetailQueryParams {
  sessionType: SessionType;
  sessionGuid: string;
}

export const useSessionDetailQuery = ({
  sessionType,
  sessionGuid,
}: UseSessionDetailQueryParams) => {
  const { sessionId, language, timezone } = useUserContext();

  const variables = useMemo(
    () => ({
      sessionGuid,
      timezone: timezone || dayjs.tz.guess(),
      language,
      sessionId,
    }),
    [language, sessionGuid, sessionId, timezone]
  );

  const {
    data: workoutData,
    loading: workoutLoading,
    refetch: workoutRefetch,
  } = useWorkoutQuery({
    variables,
    skip: sessionType !== SessionType.Workout,
  });

  const {
    data: eventData,
    loading: eventLoading,
    refetch: eventRefetch,
  } = useEventQuery({
    variables,
    skip: sessionType !== SessionType.Event,
  });

  const {
    data: practiceData,
    loading: practiceLoading,
    refetch: practiceRefetch,
  } = useGetPracticeQuery({
    variables,
    skip: sessionType !== SessionType.Practice,
    fetchPolicy: "no-cache",
  });

  const {
    data: gameData,
    loading: gameLoading,
    refetch: gameRefetch,
  } = useGameQuery({
    variables,
    skip: sessionType !== SessionType.Game,
  });

  const globalData = {
    Workout: {
      data: workoutData,
      loading: workoutLoading,
      refetch: workoutRefetch,
    },
    Event: { data: eventData, loading: eventLoading, refetch: eventRefetch },
    Practice: {
      data: practiceData,
      loading: practiceLoading,
      refetch: practiceRefetch,
    },
    Game: { data: gameData, loading: gameLoading, refetch: gameRefetch },
  } as const;

  return globalData[sessionType];
};
