import dayjs, { Dayjs } from "dayjs";

export function isValidDate(date?: Date | Dayjs): boolean {
  return (
    date &&
    (date instanceof Date || dayjs.isDayjs(date)) &&
    !isNaN(date.valueOf())
  );
}

// In case you want to validate some random date string
// since 'new Date()' in the codebase can throw
export function isValidDateString(dateString: string | number): boolean {
  try {
    const date = new Date(dateString);
    return (
      date &&
      (date instanceof Date || dayjs.isDayjs(date)) &&
      !isNaN(date.valueOf())
    );
  } catch {
    return false;
  }
}

export function getTodayDate(): Dayjs {
  const now = new Date(Date.now());
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  return dayjs(today.valueOf());
}

export const removeYearFromDateString = (dateString) => {
  if (dateString) {
    const year = dayjs().format("YYYY");
    const regExp = new RegExp(`[,/]*[ ]+${year}`);
    return dateString.replace(regExp, "").replace(year, "");
  }
};

export const convertUnixToHoursMinutes = (timestamp: number) => {
  return `${
    dayjs(timestamp).hour() === 0
      ? "00"
      : dayjs(timestamp).hour() < 10
        ? `0${dayjs(timestamp).hour()}`
        : dayjs(timestamp).hour()
  }:${
    dayjs(timestamp).minute() === 0
      ? "00"
      : dayjs(timestamp).minute() < 10
        ? `0${dayjs(timestamp).minute()}`
        : dayjs(timestamp).minute()
  }`;
};

export const addMinutesToUnix = (timestamp: number, minutes: number) => {
  return timestamp + minutes * 60 * 1000;
};

export const getDateFormatString = (lang: string = "default") => {
  const formatObj = new Intl.DateTimeFormat(lang).formatToParts(new Date());

  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        default:
          return obj.value;
      }
    })
    .join("");
};

/*
 * removes the last character if it is a dot or a slash
 * */
export const prettifyShortDate = (date: string = "") => {
  if ([".", "/"].includes(date.charAt(date.length - 1))) {
    return date.slice(0, -1);
  }
  return date;
};
