import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const FolderIcon = memo(
  ({
    width = 15,
    height = 15,
    isActive = false,
    color = "#8A939B",
    border = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 12.5V2.5C0.5 1.94772 0.947715 1.5 1.5 1.5H6.17157C6.5694 1.5 6.95093 1.65804 7.23223 1.93934L8.35355 3.06066C8.63486 3.34196 9.01639 3.5 9.41421 3.5H13.5C14.0523 3.5 14.5 3.94772 14.5 4.5V12.5C14.5 13.0523 14.0523 13.5 13.5 13.5H1.5C0.947715 13.5 0.5 13.0523 0.5 12.5Z"
        fill={color}
        stroke={border}
      />
      <path
        opacity="0.35"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4V2.5C1 2.22386 1.22386 2 1.5 2H6.17157C6.43679 2 6.69114 2.10536 6.87868 2.29289L8 3.41421C8.37507 3.78929 8.88378 4 9.41421 4H1Z"
        fill={border}
      />
    </svg>
  )
);
