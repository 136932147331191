import React from "react";

import dayjs from "dayjs";

import { useDefaultDateFormat } from "../../../hooks/useDateFormat";
import { WidgetType } from "../../ReportCreateTable/models";

import { BodyChartWidgetComponent } from "./BodyChartWidgetComponent";
import { BodyChartWidgetModel, CreateBodyChartFormValue } from "./model";
import { MOCKED_REGISTRATIONS } from "./utils";

type BodyChartWidgetPreviewProps = {
  // TODO: consider better typing here
  widget: Omit<BodyChartWidgetModel, "type, aggregation"> &
    CreateBodyChartFormValue;
};

export function BodyChartWidgetPreview({
  widget,
}: BodyChartWidgetPreviewProps) {
  const { dateFormat } = useDefaultDateFormat();

  return (
    <BodyChartWidgetComponent
      widget={{
        ...widget,
        type: WidgetType.BODY_CHART,
        informationTemplates:
          widget.informationTemplates ||
          widget.rows?.map((row) => ({
            id: row.measurementTemplateId,
            name: row.measurementTemplateName,
          })),
      }}
      registrations={MOCKED_REGISTRATIONS}
      date={dayjs().format(dateFormat)}
      informationTemplateValues={{}}
      isLoading={false}
    />
  );
}
