import React, { createContext, ReactNode, useContext, useReducer } from "react";

import actionsCreator from "../../../utils/actionsCreator";

import * as fromActions from "./actions";
import reducer, {
  initialTopModalContextState,
  TopModelContextInitialStateValue,
} from "./reducers";

type Actions = {
  openTopModal(
    object: Partial<Omit<TopModelContextInitialStateValue, "open" | "actions">>
  ): void;
  closeTopModal(): void;
};

const initialActions: Actions = {
  openTopModal: () => {},
  closeTopModal: () => {},
};

export const TopModalContext = createContext<
  TopModelContextInitialStateValue & { actions: Actions }
>({
  ...initialTopModalContextState,
  actions: initialActions,
});

export const TopModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialTopModalContextState);
  const actions = actionsCreator(fromActions, dispatch);

  return (
    <TopModalContext.Provider value={{ ...state, actions }}>
      {children}
    </TopModalContext.Provider>
  );
};

export const useTopModalContext = () => useContext(TopModalContext);

export { TopModalType } from "./constants";
