import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { COLOR_GRAY } from "../../colors";
import { ButtonVariant, GeneralButton } from "../../components/Button/";
import { ControlledInput } from "../../components/HookForm/ControlledInput";

import { FormWrapper } from "./styles";

interface PinFormModel {
  pin: number;
}

interface PinFormProps {
  onSubmit: (data: PinFormModel) => Promise<void>;
}

export const PinForm = ({ onSubmit }: PinFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, ...formMethods } = useForm<PinFormModel>({
    defaultValues: {
      pin: undefined,
    },
  });

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formMethods} handleSubmit={handleSubmit}>
        <ControlledInput
          labelColor={COLOR_GRAY}
          name="pin"
          label={t("pin")}
          type="password"
          rules={{
            required: t("pinRequired"),
            minLength: {
              value: 4,
              message: t("pinLengthWarning"),
            },
            maxLength: {
              value: 4,
              message: t("pinLengthWarning"),
            },
          }}
        />
        <GeneralButton
          fullWidth
          height={45}
          label={t("logIn")}
          onClick={handleSubmit(onSubmit)}
          type={ButtonVariant.Submit}
        />
      </FormProvider>
    </FormWrapper>
  );
};
