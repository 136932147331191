import React, { memo } from "react";

export const SendMessagesIcon = memo(() => (
  <svg
    width="54"
    height="44"
    viewBox="0 0 54 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.28 2C9.36394 2 7 4.36393 7 7.28V26.72C7 29.6361 9.36394 32 12.28 32H21V42L31 32H41.72C44.6361 32 47 29.6361 47 26.72V7.28C47 4.36394 44.6361 2 41.72 2H12.28Z"
      fill="#A0BEEC"
    />
  </svg>
));
