import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { COLOR_BLUE, COLOR_SECONDARY_GREY } from "../../../colors";
import { useNotificationsContext } from "../../../contexts/notifications";
import { useReportsContext } from "../../../contexts/Reports";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplate,
  ReportType,
  useIsReportSharedWithAthletesInGroupQuery,
  useSaveReportTemplateMutation,
  useSetReportSharedWithAthletesInGroupMutation,
} from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks";
import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import Dropdown from "../../Dropdown";
import { HoverableItem } from "../../HoverableItem";
import { IconButton } from "../../IconButton";
import { ThreeDotsIcon, UserIcon } from "../../Icons";
import { Loader } from "../../Loader";

import { HoverableItemWithCheckbox } from "./HoverableItemWithCheckbox";
import { SharableWithAthleteModal } from "./SharableWithAthleteModal";

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UserIconWrapper = styled.div`
  padding: 0 7px;
`;

export const EditAthleteProfileTabsOptionsWithDropdown = ({
  report = {},
  isAddedToProfile = false,
}: {
  report: ReportTemplate;
  isAddedToProfile?: boolean;
}) => {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { showErrorNotification } = useNotificationsContext();
  const { isMobile } = useTheme();
  const navigate = useNavigate();

  const { sessionId } = useUserContext();
  const [{ selectedGroup }] = useReportsContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const [searchParams] = useSearchParams();

  const [isSharableTicked, setIsSharableTicked] = useState(false);
  const [isSharableModalVisible, setIsSharableModalVisible] = useState(false);

  const activeProfileTabId =
    searchParams.get("activeProfileTabId") || undefined;

  const [setReportSharedWithAthletesInGroupMutations] =
    useSetReportSharedWithAthletesInGroupMutation();
  const [saveReportTemplate] = useSaveReportTemplateMutation();
  const { data, loading } = useIsReportSharedWithAthletesInGroupQuery({
    variables: { sessionId, reportId: report?.id, groupId: selectedGroup?.id },
    skip: !isAddedToProfile,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setIsSharableTicked(data.isReportSharedWithAthletesInGroup);
    }
  }, [data]);

  const onEditReport = (report) => {
    navigate({
      pathname:
        report.reportType === ReportType.GroupReport
          ? `/reports/groupReport/${report.id}`
          : `/reports/report/${report.id}`,
      search: createSearchParamsWithoutUndefinedValues({
        isEditMode: "true",
        activeProfileTabId,
      }),
    });

    modal.actions.closeModal();
  };

  const handleShareableChange = async (shared: boolean) => {
    // TODO: If it is AthleteReport or TabReport modify it to be shareable as it has no checkbox like group report
    // This is UGLY HACK while API will be fixed - save report template should be removed then
    if (
      report.reportType !== ReportType.GroupReport &&
      !report.shareableWithAthletes
    ) {
      await saveReportTemplate({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          input: {
            id: report.id,
            name: report.name,
            parentId:
              "parentId" in report ? (report as any).parentId : undefined,
            position: report.position,
            preferences: report.preferences,
            reportType: report.reportType,
            shareableWithAthletes: true,
          },
        },
      });
    }

    await setReportSharedWithAthletesInGroupMutations({
      variables: {
        sessionId,
        reportId: report?.id,
        groupId: selectedGroup?.id,
        shared,
      },
    });

    // We need to share also tab report of athlete report if it is the only one in the report
    if (
      report.reportType === ReportType.AthleteReport &&
      report.tabs.length === 1
    ) {
      // TODO: If it is AthleteReport or TabReport modify it to be shareable as it has no checkbox like group report
      // This is UGLY HACK while API will be fixed - save report template should be removed then
      await saveReportTemplate({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          input: {
            id: report.tabs[0].id,
            name: report.tabs[0].name,
            parentId:
              "parentId" in report.tabs[0]
                ? (report.tabs[0] as any).parentId
                : undefined,
            position: report.tabs[0].position,
            preferences: report.tabs[0].preferences,
            reportType: report.tabs[0].reportType,
            shareableWithAthletes: true,
          },
        },
      });

      await setReportSharedWithAthletesInGroupMutations({
        variables: {
          sessionId,
          reportId: report.tabs[0].id,
          groupId: selectedGroup?.id,
          shared,
        },
      });
    }

    setIsSharableTicked(shared);
  };

  const onShareClick = async (checked) => {
    if (
      report.reportType === ReportType.AthleteReport &&
      report.tabs.length > 1
    ) {
      return setIsSharableModalVisible(!isSharableModalVisible);
    }

    try {
      await handleShareableChange(checked);
    } catch {
      showErrorNotification(t("reportNotShareableError"));
    }
  };

  const onShareTabReport = async (hasSharedTabs: boolean) => {
    if (!hasSharedTabs && isSharableTicked) {
      await handleShareableChange(false);
    }

    if (hasSharedTabs && !isSharableTicked) {
      await handleShareableChange(true);
    }
  };

  return (
    <Dropdown
      dropdownContainerStyle={{
        width: 200,
      }}
      dropdownContentStyle={{
        padding: "5px 0",
        top: 20,
        right: 50,
      }}
      component={({ toggle, show }) => (
        <IconsWrapper>
          {loading && <Loader size={21} color={COLOR_BLUE} />}
          {isSharableTicked && (
            <UserIconWrapper>
              <UserIcon tintColor={COLOR_SECONDARY_GREY} />
            </UserIconWrapper>
          )}
          <IconButton
            icon={<ThreeDotsIcon isActive={show} />}
            enforceActiveState={show}
            onClick={toggle}
          />
        </IconsWrapper>
      )}
    >
      <>
        {!isMobile && (
          <HoverableItem
            key="edit"
            label={t("editReport")}
            onClick={() => onEditReport(report)}
          />
        )}

        {isAddedToProfile && (
          <HoverableItemWithCheckbox
            text={t("sharedWithAthletes")}
            key="sharedWithAthletes"
            onClick={onShareClick}
            isChecked={isSharableTicked}
            initialLoading={loading}
            showCheckbox={report.reportType === ReportType.GroupReport}
          />
        )}

        {(isSharableModalVisible ||
          report.reportType === ReportType.AthleteReport) && (
          <SharableWithAthleteModal
            reportTabs={report.tabs}
            parentId={report.id}
            onShareTabReport={onShareTabReport}
          />
        )}
      </>
    </Dropdown>
  );
};
