import React from "react";

import styled from "styled-components";

import Avatar from "../../Avatar";
import { CheckIcon } from "../../Icons";
import StyledText from "../../StyledText";

const Container = styled.div`
  padding-bottom: 10px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const MemberContainer = styled.div`
  padding-left: 20px;
  padding-top: 10px;
`;

const NameContainer = styled.div`
  flex-direction: row;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const MembersSection = ({
  title,
  members,
  selectedMembers,
  handleMemberPress,
  handleAllMembersPress,
}) => {
  const allMembersAreSelected = !members?.some(
    (item) => !selectedMembers?.includes(item)
  );

  return (
    <Container>
      <Row>
        <StyledText bold>{title}</StyledText>
        <CheckIcon
          style={{ cursor: "pointer" }}
          isActive={allMembersAreSelected}
          onClick={() => handleAllMembersPress(members, allMembersAreSelected)}
        />
      </Row>
      {members?.map((member) => {
        const isSelected = selectedMembers?.find((sm) => sm?.id === member?.id);
        return (
          <MemberContainer key={member?.id}>
            <Row>
              <NameContainer>
                <Avatar
                  userName={member?.name}
                  size={30}
                  isThumb={true}
                  userGuid={member?.id}
                />
                <StyledText bold>{member?.name}</StyledText>
              </NameContainer>
              <CheckIcon
                style={{ cursor: "pointer" }}
                isActive={isSelected}
                onClick={() => handleMemberPress(member, isSelected)}
              />
            </Row>
          </MemberContainer>
        );
      })}
    </Container>
  );
};

export default MembersSection;
