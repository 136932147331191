import React from "react";

import styled from "styled-components";

import { COLOR_WHITE } from "../../colors";
import StyledText from "../StyledText";

type TooltipTitleProps = {
  contactName: string;
  avatarsLength: number;
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const ChatTitleTooltipHeading = styled.p`
  color: ${COLOR_WHITE};
  font-size: 14px;
  font-weight: semibold;
  margin: 0 1em 0 0;
`;

export function TooltipTitle({
  contactName,
  avatarsLength,
}: TooltipTitleProps) {
  return (
    <Wrapper>
      <ChatTitleTooltipHeading>{contactName}</ChatTitleTooltipHeading>
      <StyledText color={COLOR_WHITE} fontWeight="bold" fontSize={14}>
        {avatarsLength}
      </StyledText>
    </Wrapper>
  );
}
