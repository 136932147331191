import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_GRAY } from "../../../../colors";
import { useUserContext } from "../../../../contexts/User";
import { Chatter, XpsUser } from "../../../../graphql";
import Avatar from "../../../Avatar";
import { BasicButton } from "../../../Button";
import { CrossIcon } from "../../../Icons";

const AvatarContainer = styled.div`
  max-width: 45px;
  position: relative;
  z-index: 1;
`;

const AvatarRow = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: row;
  width: calc(100% - 40px);
  padding: 15px 20px 20px;
  gap: 10px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const CrossIconContainer = styled(BasicButton)`
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  background-color: ${COLOR_GRAY};
  width: 15px;
  height: 15px;
  top: -5px;
  right: -5px;
  z-index: 1;
  cursor: pointer;
`;

interface SelectedUsersRowProps {
  attendingUsers?: (Chatter | XpsUser)[];
  setAttendingUsers?: Dispatch<SetStateAction<(Chatter | XpsUser)[]>>;
}

export const SelectedUsersRow = ({
  attendingUsers,
  setAttendingUsers,
}: SelectedUsersRowProps) => {
  const { id } = useUserContext();

  return (
    <AvatarRow>
      {attendingUsers.map((au: Chatter) => {
        const canDeleteMember = au?.id !== id;

        return (
          <AvatarContainer key={`attending-${au.id}`}>
            {canDeleteMember && (
              <CrossIconContainer
                onClick={() => {
                  const currentAttendingUsers = attendingUsers.filter(
                    (auser) => auser.id !== au.id
                  );
                  setAttendingUsers(currentAttendingUsers);
                }}
              >
                <CrossIcon
                  tintColor={COLOR_MEDIUM_GRAY}
                  style={{ width: 13, height: 13 }}
                />
              </CrossIconContainer>
            )}
            <Avatar
              size={35}
              source={{ uri: au.avatarThumbURL }}
              userName={au.name}
            />
          </AvatarContainer>
        );
      })}
    </AvatarRow>
  );
};
