import { useUserContext } from "../../contexts/User";
import { useCreateSingleSessionPracticeTemplateMutation } from "../../graphql";

export const useCreateSingleSessionPracticeTemplate = () => {
  const { sessionId, language, id } = useUserContext();

  const [createSingleSessionPracticeTemplate] =
    useCreateSingleSessionPracticeTemplateMutation();

  const saveSessionAsTemplate = async (
    workoutOrPracticeID: string,
    programName: string,
    refetchQueries: () => string[]
  ) => {
    await createSingleSessionPracticeTemplate({
      variables: {
        language,
        sessionId,
        input: {
          workoutOrPracticeID,
          ownerOfCollection: id,
          programName,
        },
      },

      refetchQueries,
    });
  };

  return { saveSessionAsTemplate };
};
