import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { CrossFilledIcon, SearchIcon } from "../../Icons";
import { StyledTextInput } from "../../StyledTextInput";

const Container = styled.div`
  height: 45px;
  background: ${COLOR_GRAY};
  padding: 12px;
  margin: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
`;

const SearchWrapper = styled.div`
  margin-right: 15px;
`;

const SearchInput = styled(StyledTextInput)`
  width: 100%;
  height: 25px;
  padding: 0;
  font-weight: normal;
`;

const ClearSearchButton = styled.div`
  cursor: pointer;
`;

interface AddCoachOrAthleteToGroupHeaderProps {
  search: string;
  setSearch: (text: string) => void;
}

export const AddCoachOrAthleteToGroupHeader = ({
  search,
  setSearch,
}: AddCoachOrAthleteToGroupHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <SearchWrapper>
        <SearchIcon />
      </SearchWrapper>
      <SearchInput
        onChangeText={setSearch}
        value={search}
        placeholder={t("search")}
        placeholderTextColor={COLOR_TEXT_TABLE_LABEL}
        autoFocus
      />
      {!!search && (
        <ClearSearchButton onClick={() => setSearch("")}>
          <CrossFilledIcon />
        </ClearSearchButton>
      )}
    </Container>
  );
};
