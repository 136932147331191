import React, {
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputActionMeta, OptionsOrGroups } from "react-select";

import debounce from "lodash/debounce";
import AsyncCreatableSelect from "react-select/async-creatable";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { useGoogleLocation } from "../../hooks/useGoogleLocation";
import { useLabelAndLocationQuery } from "../../services/hooks";
import { SessionType } from "../../services/sessions";
import { getFieldSelectStyles } from "../../utils/selectStyle";
import { LocationIcon } from "../Icons";

import { DropdownIndicator } from "./DropdownIndicator";
import { IconOption } from "./IconOption";
import { InputError } from "./InputError";
import { Option } from "./model";
import { ReactSelectInput } from "./ReactSelectInput";
import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

type ControlledLocationSelectProps = UseControllerProps & {
  name: string;
  label?: string;
  isLabel?: boolean;
  placeholder?: string;
  sessionType: SessionType;
  selectedGoogleLocationId?: string;
  googleMapsUrl?: string;
  setSelectedGoogleLocationId: (value: string) => void;
  marginBottom?: number;
  maxMenuHeight?: number;
  additionalDropdownOption?: Option;
  onMenuToggle?: (isOpen: boolean) => void;
};

const stylesOverrides = {
  container: {
    borderWidth: 0,
    flex: 1,
    overflow: "visible",
  },
  control: {
    backgroundColor: COLOR_GRAY,
  },
  valueContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  singleValue: {
    color: COLOR_TEXT_DARK,
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  menu: {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

const SelectContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

export function ControlledLocationSelect({
  name,
  label,
  rules,
  sessionType,
  setSelectedGoogleLocationId,
  selectedGoogleLocationId,
  googleMapsUrl,
  placeholder = "",
  isLabel = false,
  marginBottom,
  maxMenuHeight = 300,
  additionalDropdownOption,
  onMenuToggle,
}: ControlledLocationSelectProps) {
  const user = useUserContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [isSetCustomLocation, setIsSetCustomLocation] = useState(
    !selectedGoogleLocationId
  );

  const selectorRef = useRef(null);
  const selectedLocationUrl = useGoogleLocation(selectedGoogleLocationId);

  const formContext = useFormContext();
  const { formState } = formContext || {};

  const hasError = Boolean(formState?.errors[name]);
  const errorMessage = hasError && `${formState?.errors[name]?.message}`;

  const { data, isLoading } = useLabelAndLocationQuery({
    session: user,
    isLabel,
    sessionType,
  });

  const hasUrl = googleMapsUrl || selectedLocationUrl || selectedValue?.url;

  const onInputChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === "menu-close") {
      setIsMenuOpen(false);
      onMenuToggle?.(false);
    }

    if (action === "input-change") {
      if (!isSetCustomLocation) {
        setIsSetCustomLocation(true);
      }
      setInputValue(inputValue);
    }
  };

  const options = useMemo(() => {
    const optionsToReturn: Option[] = [];

    if (additionalDropdownOption) {
      optionsToReturn.push(additionalDropdownOption);
    }

    if (!isLoading && data?.length) {
      data?.map((item) => {
        optionsToReturn.push({
          value: item?.g?.i,
          label: item?.pt || `${item?.g?.n},${item?.g?.fa}`,
          url: item?.g?.mu || "",
        });
      });
    }

    return optionsToReturn;
  }, [data, isLoading, additionalDropdownOption]);

  const {
    field: { value: savedFieldValue, onChange },
  } = useController({ rules, name });

  const handleChange = (item: Option) => {
    if (isSetCustomLocation) {
      setIsSetCustomLocation(false);
    }
    if (item?.onClick) {
      item?.onClick();
    } else {
      setSelectedValue(item);
      setSelectedGoogleLocationId(item?.value);
      setInputValue(item?.label);
      onChange(item.label);
    }
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const inputValue = event.target.value.trim();

    if (inputValue) {
      onChange(inputValue);
    }
  };

  const handleFocus = () => {
    if (selectedValue) {
      const inputValueEnd = inputValue.length;
      selectorRef?.current?.inputRef?.setSelectionRange(
        inputValueEnd,
        inputValueEnd
      );
    }
  };

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
    onMenuToggle?.(!isMenuOpen);
  };

  const selectStyles = useMemo(
    () =>
      getFieldSelectStyles({
        ...stylesOverrides,
        ...{
          container: {
            ...stylesOverrides.container,
            maxWidth:
              hasUrl && !isSetCustomLocation ? `calc(100% - 40px)` : "100%",
            width: "100%",
            fontWeight: 700,
          },
          menu: {
            ...stylesOverrides.menu,
            left: 1,
          },
        },
      }),
    [hasUrl, isSetCustomLocation]
  );

  useEffect(() => {
    if (savedFieldValue) {
      setInputValue(savedFieldValue);
    }
  }, [savedFieldValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadPlacesAutocomplete = useCallback(
    debounce(
      (
        input: string,
        callback: (
          options: OptionsOrGroups<{ label: string; value: string }, never>
        ) => void
      ) => {
        // @ts-ignore
        if (!window.google) {
          // Google Maps API not loaded yet
          return callback([]);
        }

        const autocompleteService =
          // @ts-ignore
          new window.google.maps.places.AutocompleteService();
        setIsMenuOpen(!isMenuOpen);
        onMenuToggle?.(!isMenuOpen);

        autocompleteService.getPlacePredictions(
          { input },
          (predictions, status) => {
            // @ts-ignore
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const options = predictions.map((prediction) => ({
                value: prediction.place_id,
                label: prediction.description,
              }));

              return callback(options);
            } else {
              console.error("Error fetching suggestions:", status);
              return callback([]);
            }
          }
        );
      },
      500
    ),
    []
  );

  return (
    <StyledInputWrapper zIndexValue="5" marginBottom={marginBottom}>
      {label ? <LabelText>{label.toUpperCase()}</LabelText> : null}
      <SelectContainer>
        <AsyncCreatableSelect
          ref={selectorRef}
          styles={selectStyles}
          placeholder={placeholder}
          value={selectedValue}
          loadOptions={loadPlacesAutocomplete}
          defaultOptions={options}
          onInputChange={onInputChange}
          inputValue={inputValue}
          onChange={handleChange}
          menuIsOpen={isMenuOpen}
          onBlur={handleOnBlur}
          onFocus={handleFocus}
          maxMenuHeight={maxMenuHeight}
          controlShouldRenderValue={false}
          components={{
            Input: ReactSelectInput,
            Option: (props) => <IconOption {...props} isLabel={isLabel} />,
            DropdownIndicator: (props) => (
              <DropdownIndicator {...props} onClick={handleClick} />
            ),
          }}
        />
        {hasUrl && !isSetCustomLocation && (
          <a target="_blank" href={hasUrl} style={{ marginLeft: "8px" }}>
            <LocationIcon />
          </a>
        )}
      </SelectContainer>
      <InputError errorMessage={errorMessage} />
    </StyledInputWrapper>
  );
}
