import React from "react";

import styled from "styled-components";

import { useNavigateToPreviousPage } from "../../hooks/useNavigateToPreviousPage";
import { BackButton } from "../BackButton";

const BackButtonWrapper = styled.div`
  margin-right: 30px;
`;

export function ReportBackButton() {
  const handleNavigateToPreviousPage = useNavigateToPreviousPage();

  return (
    <BackButtonWrapper>
      <BackButton onBack={handleNavigateToPreviousPage} />
    </BackButtonWrapper>
  );
}
