import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_DARK_BLUE } from "../../colors";
import {
  LogoAcesNationNetworkIcon,
  LogoXpsNetworkIcon,
} from "../../components/Icons";
import { Loader } from "../../components/Loader";
import {
  ActionLink,
  ActionLinkWrapper,
  Divider,
} from "../../components/PasswordForm/styles";
import { useNotificationsContext } from "../../contexts/notifications";
import { useUIContext } from "../../contexts/UI";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import {
  useFetchGeoIP,
  usePrepareDokobitRedirect,
} from "../../services/statistics/hooks";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";

import {
  LoadingWrapper,
  LoadingMessage,
  FormHeaderErrorWrapper,
  FormHeaderErrorText,
} from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);

interface LoginScreenLayoutProps {
  inModalView?: boolean;
  error: string;
  isLoading: boolean;
  children: ReactNode;
  isPinFormVisible: boolean;
}

const LoginScreenWrapper = styled.div<{ inModalView: boolean }>`
  display: flex;
  flex: 1;
  background-color: ${COLOR_DARK_BLUE};
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: ${({ inModalView }) => (inModalView ? 20 : 0)}px;
  height: ${({ inModalView }) => (inModalView ? "100%" : "100vh")};
  width: 100%;
`;

const LogoXpsNetworkIconWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  margin-top: 40px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginScreenLayout = ({
  inModalView,
  error,
  isLoading,
  children,
  isPinFormVisible,
}: LoginScreenLayoutProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutateAsync: prepareDokobitRedirectMutateAsync } =
    usePrepareDokobitRedirect();
  const { actions: modalActions } = useModalContext();
  const ui = useUIContext();
  const { showErrorNotification } = useNotificationsContext();

  const { data: geoipData } = useFetchGeoIP();

  const handleResetPassword = () => {
    if (inModalView) {
      return modalActions.openModal({
        modal: ModalType.FORGOT_PASSWORD,
        title: null,
        style:
          ui.width > 500
            ? {
                maxWidth: 422,
                minWidth: 422,
                minHeight: 450,
              }
            : {
                minHeight: 450,
              },
      });
    }
    navigate(`/${ROUTING_CONFIG.forgot}`);
  };

  const handleHowToFindPin = () => {
    window.open(
      "https://sidelinesports.zendesk.com/hc/en-us/articles/360040834311",
      "_blank"
    );
  };

  const handleDokobitLogin = async (event) => {
    event.preventDefault();
    const { data } = (await prepareDokobitRedirectMutateAsync(null)) || {};
    if (data) {
      const { data: redirectData } = data || {};
      const { status, url } = redirectData || {};
      if (status === "ok" && url) {
        window.location.href = redirectData.url;
      } else {
        showErrorNotification(t("unknownServerError"));
      }
    } else {
      showErrorNotification(t("unknownServerError"));
    }
  };

  const isLoggingFromIceland =
    geoipData?.data?.country_code === "IS" || i18next.language === "is";

  return (
    <LoginScreenWrapper inModalView={inModalView}>
      <div>
        {error && (
          <FormHeaderErrorWrapper>
            <FormHeaderErrorText>{error}</FormHeaderErrorText>
          </FormHeaderErrorWrapper>
        )}

        <LogoXpsNetworkIconWrapper>
          {isAcesNationEnv() ? (
            <LogoAcesNationNetworkIcon
              style={inModalView ? { width: "80%", maxWidth: 200 } : {}}
            />
          ) : (
            <LogoXpsNetworkIcon
              style={inModalView ? { width: "80%", maxWidth: 200 } : {}}
            />
          )}
        </LogoXpsNetworkIconWrapper>

        {isLoading ? (
          <LoadingWrapper>
            <Loader size={31} color={COLOR_BLUE} />
            <LoadingMessage>{t("pleaseWait")}</LoadingMessage>
          </LoadingWrapper>
        ) : (
          <Card>
            <FormWrapper>
              {children}
              <Divider />
              {isPinFormVisible ? (
                <ActionLinkWrapper onClick={handleHowToFindPin}>
                  <ActionLink>{t("howToFindPin")}</ActionLink>
                </ActionLinkWrapper>
              ) : (
                <>
                  <ActionLinkWrapper>
                    <ActionLink onClick={handleResetPassword}>
                      {t("forgotPassword")}
                    </ActionLink>
                  </ActionLinkWrapper>
                  {isLoggingFromIceland ? (
                    <ActionLinkWrapper>
                      <ActionLink onClick={handleDokobitLogin}>
                        {t("electronicId")}
                      </ActionLink>
                    </ActionLinkWrapper>
                  ) : null}
                </>
              )}
            </FormWrapper>
          </Card>
        )}
      </div>
    </LoginScreenWrapper>
  );
};
