import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import { Avatar as AvatarModel } from "../../../graphql";
import Avatar from "../../Avatar";
import StyledText from "../../StyledText";

interface ChildrenForSessionProps {
  avatarsOfChildren: AvatarModel[];
}

const ChildrenForSessionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ChildForSessionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ChildrenForSession = ({
  avatarsOfChildren,
}: ChildrenForSessionProps) => {
  return (
    <ChildrenForSessionWrapper>
      {avatarsOfChildren.map((child) => (
        <ChildForSessionWrapper>
          <Avatar
            size={25}
            userName={child.name}
            source={{
              uri: child.thumb,
            }}
          />
          <StyledText color={COLOR_TEXT_DEFAULT} fontWeight="bold">
            {child.name}
          </StyledText>
        </ChildForSessionWrapper>
      ))}
    </ChildrenForSessionWrapper>
  );
};
