import { Dayjs } from "dayjs";

export enum ACAggregation {
  Current = "Current",
  Latest = "Latest",
  Min = "Min",
  Max = "Max",
}

export enum PeriodType {
  SELECTION = "selection",
  FIXED = "fixed",
  DYNAMIC = "dynamic",
}

export type Period = {
  type: PeriodType;
  from: Dayjs;
  to: Dayjs;
  isTodaySetAsEnd?: boolean;
};
