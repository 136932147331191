import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { UserPreferenceKeys } from "../../constants";
import {
  Group,
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
  XpsUser,
} from "../../graphql";
import { useAccessLevelsContext } from "../accessLevels";
import { useReportsContext } from "../Reports";
import { useUserContext } from "../User";

export const ATHLETE_TAB_ID = "athletes-selector-athlete";
export const TEAM_TAB_ID = "athletes-selector-teams";

export type LastActiveTab = typeof ATHLETE_TAB_ID | typeof TEAM_TAB_ID;

export type SelectedPerformersContextModel = {
  attendingUsers: XpsUser[];
  attendingTeams: Partial<Group>[];
  lastActiveTab: LastActiveTab;
  setAttendingUsers: React.Dispatch<React.SetStateAction<XpsUser[]>>;
  setAttendingTeams: React.Dispatch<React.SetStateAction<Partial<Group>[]>>;
  setLastActiveTab: React.Dispatch<React.SetStateAction<LastActiveTab>>;
  confirmSelectedAthletes: (athletes: XpsUser[]) => void;
  confirmSelectedTeams: (teams: Partial<Group>[]) => void;
};

const SelectedPerformersContext = createContext<SelectedPerformersContextModel>(
  {
    attendingUsers: [],
    attendingTeams: [],
    lastActiveTab: ATHLETE_TAB_ID,
    setAttendingUsers: () => ({}),
    setAttendingTeams: () => ({}),
    setLastActiveTab: () => ({}),
    confirmSelectedAthletes: () => ({}),
    confirmSelectedTeams: () => ({}),
  }
);

export function SelectedPerformersContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { sessionId } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();
  const [{ selectedGroup }] = useReportsContext();

  const [attendingUsers, setAttendingUsers] = useState<XpsUser[]>([]);
  const [attendingTeams, setAttendingTeams] = useState<Partial<Group>[]>([]);
  const [lastActiveTab, setLastActiveTab] =
    useState<LastActiveTab>(ATHLETE_TAB_ID);
  const [initalStateLoaded, setInitialStateLoaded] = useState(false);

  const [setPreferences] = usePutPreferenceMutation();
  const [getPreference] = useGetPreferenceLazyQuery();

  useEffect(() => {
    (async () => {
      if (sessionId) {
        const preferencesResponse = selectedAccount?.id
          ? await getPreference({
              variables: {
                key: `${UserPreferenceKeys.PERFORMERS_REPORTS_STATE}-${selectedAccount.id}`,
                sessionId,
              },
            })
          : ({} as any);

        const {
          attendingTeams: loadedAttendingTeams = [],
          attendingUsers: loadedAttendingUsers = [],
          selectedAccountId: loadedSelectedAccountId,
        } = JSON.parse(preferencesResponse?.data?.getPreference ?? "{}");

        if (
          (loadedAttendingTeams.length === 0 &&
            loadedAttendingUsers.length === 0) ||
          loadedSelectedAccountId !== selectedAccount?.id
        ) {
          setAttendingUsers(selectedGroup?.athletes ?? []);
          setAttendingTeams([]);
        } else {
          setAttendingTeams(loadedAttendingTeams);
          setAttendingUsers(loadedAttendingUsers);
        }

        setInitialStateLoaded(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, selectedGroup, selectedAccount?.id]);

  useEffect(() => {
    if (sessionId && initalStateLoaded && selectedAccount?.id) {
      setPreferences({
        variables: {
          key: `${UserPreferenceKeys.PERFORMERS_REPORTS_STATE}-${selectedAccount.id}`,
          value: JSON.stringify({
            attendingTeams,
            attendingUsers,
            selectedAccountId: selectedAccount.id,
          }),
          sessionId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    attendingUsers,
    attendingTeams,
    selectedAccount?.id,
    initalStateLoaded,
    sessionId,
  ]);

  useEffect(() => {
    // Reset on logout
    if (!sessionId) {
      setAttendingTeams([]);
      setAttendingUsers([]);
    }
  }, [sessionId]);

  const confirmSelectedAthletes = (athletes: XpsUser[]) => {
    setAttendingUsers(athletes);
  };

  const confirmSelectedTeams = (teams: Partial<Group>[]) => {
    setAttendingTeams(teams);
  };

  return (
    <SelectedPerformersContext.Provider
      value={{
        attendingUsers,
        attendingTeams,
        lastActiveTab,
        setAttendingTeams,
        setAttendingUsers,
        setLastActiveTab,
        confirmSelectedAthletes,
        confirmSelectedTeams,
      }}
    >
      {children}
    </SelectedPerformersContext.Provider>
  );
}

export const useSelectedPerformersContext = () =>
  useContext(SelectedPerformersContext);
