import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AwayIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
      <circle
        cx="17.5"
        cy="17.5"
        r="12.5"
        fill={isActive ? "#FF6562" : ""}
        stroke={isActive ? "" : "#B1B7BC"}
      />
      <path
        d="M13.5 21.5L21.5 13.5"
        stroke={isActive ? "white" : "#64707A"}
        strokeWidth="1.5"
      />
      <path
        d="M13.5 13.5L21.5 21.5"
        stroke={isActive ? "white" : "#64707A"}
        strokeWidth="1.5"
      />
    </svg>
  )
);
