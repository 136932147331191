import { useUserContext } from "../../contexts/User";
import { useReportBuildingMeasurementTemplatesQuery } from "../../graphql";

interface IUseReportBuildingMeasurementsProps {
  collectionId: string;
  isCombinedTest?: boolean;
}

export const useReportBuildingMeasurements = ({
  collectionId,
  isCombinedTest,
}: IUseReportBuildingMeasurementsProps) => {
  const { sessionId, language } = useUserContext();

  return useReportBuildingMeasurementTemplatesQuery({
    variables: { sessionId, collectionId, language, isCombinedTest },
    skip: !collectionId,
    fetchPolicy: "cache-first",
  });
};
