import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import { XpsUser, usePracticeMembersQuery } from "../../graphql";
import { sortList } from "../../utils/sortList";
import { Loader } from "../Loader";
import { SideBar } from "../SideBar";

import { SessionEditMemberAttendanceList } from "./components/SessionEditMemberAttendanceList";
import { SessionEditMemberList } from "./components/SessionEditMemberList";

const ContentWrapper = styled.div`
  display: flex;
`;

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MembersWrapper = styled.div`
  padding: 10px 30px 15px 20px;
  width: 100%;
  max-width: 680px;
  min-height: calc(100vh - 300px);
`;

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SessionEditMember = ({ sessionGuid }) => {
  const { t } = useTranslation();
  const { sessionId, timezone, language } = useUserContext();

  const {
    refetch: refetchPracticeMembers,
    loading,
    data,
  } = usePracticeMembersQuery({
    variables: {
      id: sessionGuid,
      sessionId,
      timezone,
      language,
    },
    // Due to infinite query loading and cache mismatch between data loaded using practice members and practice query - needs fix in the future
    fetchPolicy: "no-cache",
  });

  const practice = data?.practice;

  if (!practice?.members?.athletes && !practice?.members?.trainers && loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <ContentWrapper>
      <SideBarWrapper>
        <SideBar
          label={t("coaches")}
          count={practice?.members?.trainersNotSelected?.length}
          style={{
            minWidth: "min(20vw, 340px)",
          }}
          collapseStyle={{
            maxHeight: "min(50vh, 320px)",
            minHeight: 100,
          }}
        >
          {(searchValue) => (
            <SessionEditMemberList
              data={sortList(practice?.members?.trainersNotSelected, language)}
              searchValue={searchValue}
              practice={practice}
              refetch={refetchPracticeMembers}
              emptyText={t("noCoachesFound")}
            />
          )}
        </SideBar>
        <SideBar
          label={t("athletes")}
          count={practice?.members?.athletesNotSelected?.length}
          style={{ minWidth: "min(20vw, 340px)" }}
          withMaxHeight
        >
          {(searchValue) => (
            <SessionEditMemberList
              data={sortList(practice?.members?.athletesNotSelected, language)}
              searchValue={searchValue}
              practice={practice}
              refetch={refetchPracticeMembers}
              emptyText={t("noAthletesFound")}
            />
          )}
        </SideBar>
      </SideBarWrapper>
      <MembersWrapper>
        <SessionEditMemberAttendanceList
          data={[
            ...sortList(practice?.members?.trainers as XpsUser[], language),
            ...sortList(practice?.members?.athletes as XpsUser[], language),
          ]}
          practice={practice}
          refetch={refetchPracticeMembers}
        />
      </MembersWrapper>
    </ContentWrapper>
  );
};

export default SessionEditMember;
