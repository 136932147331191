import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import { PracticeMembersQuery, XpsUser } from "../../../graphql";
import StyledText from "../../StyledText";
import { Team } from "../Components/Team";

import { TabContainer } from "./TabContainer";

export const SelectAthletesFromGroupBody = ({
  search,
  selectedAthletes = [],
  setSelectedAthletes,
  teamName,
  availableAthletes,
}: {
  search: string;
  selectedAthletes: XpsUser[];
  setSelectedAthletes: Dispatch<SetStateAction<XpsUser[]>>;
  teamName: string;
  availableAthletes: PracticeMembersQuery["practice"]["members"]["athletes"];
}) => {
  const { t } = useTranslation();

  const groupedUsers = useMemo(
    () =>
      teamName && availableAthletes
        ? {
            [teamName]: {
              value: availableAthletes,
              name: teamName,
            },
          }
        : {},
    [teamName, availableAthletes]
  );

  const groupsCount = useMemo(
    () => Object.keys(groupedUsers || {}).length,
    [groupedUsers]
  );

  return (
    <TabContainer loading={false}>
      <>
        {groupedUsers &&
          Object.keys(groupedUsers)
            .sort()
            .map((gu, index) => (
              <Team
                key={`team-${index}`}
                isCreateGroupChat
                setAttendingUsers={setSelectedAthletes}
                search={search}
                onClick={async (user, isAttending) => {
                  let currentAttendingUsers = [];
                  if (isAttending) {
                    currentAttendingUsers = selectedAthletes.filter(
                      (au) => au.id !== user.id
                    );
                  } else {
                    currentAttendingUsers = selectedAthletes.slice();
                    currentAttendingUsers.push(user);
                  }
                  setSelectedAthletes(currentAttendingUsers);
                }}
                currentKey={gu}
                groupsCount={groupsCount}
                // @ts-ignore
                groups={groupedUsers}
                attendingUsers={selectedAthletes}
                index={index}
                showAttendingImages
              />
            ))}
        {groupedUsers && Object.keys(groupedUsers).length === 0 && (
          <StyledText
            style={{ textAlign: "center" }}
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
          >
            {t("noOne")}
          </StyledText>
        )}
      </>
    </TabContainer>
  );
};
