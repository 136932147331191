import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const BagIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {isActive ? (
        <>
          <mask id="path-1-inside-1_4785_56403" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 6V4.5C15 2.01472 12.9853 0 10.5 0C8.01472 0 6 2.01472 6 4.5V6H6.25606H14.7439H15Z"
            />
          </mask>
          <path
            d="M15 6V7H16V6H15ZM6 6H5V7H6V6ZM14 4.5V6H16V4.5H14ZM10.5 1C12.433 1 14 2.567 14 4.5H16C16 1.46243 13.5376 -1 10.5 -1V1ZM7 4.5C7 2.567 8.567 1 10.5 1V-1C7.46243 -1 5 1.46243 5 4.5H7ZM7 6V4.5H5V6H7ZM6 7H6.25606V5H6V7ZM6.25606 7H14.7439V5H6.25606V7ZM14.7439 7H15V5H14.7439V7Z"
            fill="white"
            mask="url(#path-1-inside-1_4785_56403)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 5C2.89543 5 2 5.89543 2 7V19C2 20.1046 2.89543 21 4 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H4ZM6 8H7V9V9.5C7 11.433 8.567 13 10.5 13C12.433 13 14 11.433 14 9.5V9V8H15V9V9.5C15 11.9853 12.9853 14 10.5 14C8.01472 14 6 11.9853 6 9.5V9V8Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <rect
            x="2.5"
            y="5.5"
            width="16"
            height="15"
            rx="1.5"
            stroke="white"
          />
          <mask id="path-2-inside-1_4785_56393" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 6V4.5C15 2.01472 12.9853 0 10.5 0C8.01472 0 6 2.01472 6 4.5V6H6.25606H14.7439H15Z"
            />
          </mask>
          <path
            d="M15 6V7H16V6H15ZM6 6H5V7H6V6ZM14 4.5V6H16V4.5H14ZM10.5 1C12.433 1 14 2.567 14 4.5H16C16 1.46243 13.5376 -1 10.5 -1V1ZM7 4.5C7 2.567 8.567 1 10.5 1V-1C7.46243 -1 5 1.46243 5 4.5H7ZM7 6V4.5H5V6H7ZM6 7H6.25606V5H6V7ZM6.25606 7H14.7439V5H6.25606V7ZM14.7439 7H15V5H14.7439V7Z"
            fill="white"
            mask="url(#path-2-inside-1_4785_56393)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 8H6V9V9.5C6 11.9853 8.01472 14 10.5 14C12.9853 14 15 11.9853 15 9.5V9V8H14V9V9.5C14 11.433 12.433 13 10.5 13C8.567 13 7 11.433 7 9.5V9V8Z"
            fill="white"
          />
        </>
      )}
    </svg>
  )
);
