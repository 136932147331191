import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const MinimizeIcon = memo((props: SvgIconProps) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 10.5L8.5 6.5L4.5 2.5" stroke="#0073D9" />
    <path d="M8 6.5H0" stroke="#0073D9" />
    <path d="M10.5 0V13" stroke="#0073D9" />
  </svg>
));
