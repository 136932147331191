import React, { memo } from "react";

export const FilterIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <circle cx="11.5" cy="5.5" r="1.5" fill="#3C434C" />
    <circle cx="15.5" cy="10.5" r="1.5" fill="#3C434C" />
    <circle cx="6.5" cy="15.5" r="1.5" fill="#3C434C" />
    <rect x="4" y="15" width="3" height="1" fill="#3C434C" />
    <rect x="9" y="15" width="8" height="1" fill="#3C434C" />
    <rect x="4" y="10" width="12" height="1" fill="#3C434C" />
    <rect x="4" y="5" width="8" height="1" fill="#3C434C" />
    <rect x="14" y="5" width="3" height="1" fill="#3C434C" />
  </svg>
));
