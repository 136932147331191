import React, { useMemo } from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { SubGroup, XpsUser } from "../../../graphql";
import { GroupValue } from "../../GroupSelector";

import { UserItem } from "./UserItem";

type UserListProps = {
  data: XpsUser[];
  group?: GroupValue;
  searchValue?: string;
  subGroups?: SubGroup[];
  grayOnHidden?: boolean;
  canHide?: boolean;
  canRemove?: boolean;
  shouldShowOptionalBadge?: boolean;
  canDrag?: boolean;
  inCollapsibleCard?: boolean;
  onUserPress: (type: string, id: string, groupId: string) => void;
  parentGroupId?: string;
  emptyText?: string;
  emptyTextAlign?: string;
};

const EmptyTextWrapper = styled.div`
  padding: 30px 15px;
  text-align: center;
`;

const EmptyText = styled.div`
  font-size: 12px;
  text-align: center;
  color: ${COLOR_TEXT_DARK};
`;

export function UserList({
  data,
  searchValue,
  subGroups,
  group,
  grayOnHidden,
  canHide,
  canRemove,
  canDrag,
  shouldShowOptionalBadge,
  inCollapsibleCard,
  onUserPress,
  parentGroupId,
  emptyText,
  emptyTextAlign = "center",
}: UserListProps) {
  const userList = useMemo(() => {
    if (searchValue === undefined) {
      return data;
    }

    return (
      data?.filter((user) =>
        user?.name?.toLowerCase().includes(searchValue?.toLowerCase())
      ) ?? []
    );
  }, [data, searchValue]);

  if (emptyText && !userList?.length) {
    return (
      <EmptyTextWrapper>
        <EmptyText>{emptyText}</EmptyText>
      </EmptyTextWrapper>
    );
  }

  return (
    <>
      {userList?.map((user, index) => (
        <UserItem
          key={user?.id}
          user={user}
          subGroups={subGroups}
          isFirst={index === 0}
          isAthlete={user.type === "Athlete"}
          group={group}
          grayOnHidden={grayOnHidden}
          canHide={canHide}
          canRemove={canRemove}
          shouldShowOptionalBadge={shouldShowOptionalBadge}
          canDrag={canDrag}
          inCollapsibleCard={inCollapsibleCard}
          onUserPress={onUserPress}
          parentGroupId={parentGroupId}
        />
      ))}
    </>
  );
}
