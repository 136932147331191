import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { CSSObjectWithLabel, Props } from "react-select";

import { useTheme } from "styled-components";

import { COLOR_BLUE, COLOR_SELECT_BORDER_HOVER } from "../colors";
import { getSelectStyles } from "../utils/selectStyle";

import { CustomDropdownIndicator } from "./CustomDropdownIndicator";
import Hoverable from "./Hoverable";
import { SelectOption } from "./SelectField";

const WIDTH_OF_DROPDOWN_AND_PADDING = 60;
type OptionType = SelectOption & { options?: SelectOption[] };

type GroupedOptionType = {
  id: number;
  label: string;
  options?: OptionType[];
};

export type StylesOverrides = {
  container?: CSSObjectWithLabel;
  control?: CSSObjectWithLabel;
  dropdownIndicator?: CSSObjectWithLabel;
  singleValue?: CSSObjectWithLabel;
  valueContainer?: CSSObjectWithLabel;
  menu?: CSSObjectWithLabel;
  menuList?: CSSObjectWithLabel;
  option?: CSSObjectWithLabel;
  placeholder?: CSSObjectWithLabel;
  indicatorsContainer?: CSSObjectWithLabel;
};

type StyledSelectProps = Omit<
  Props<OptionType, boolean, any>,
  "value" | "options" | "onChange"
> & {
  options: OptionType[] | GroupedOptionType[];
  value: string | string[] | null;
  loading?: boolean;
  disableSearchable?: boolean;
  onChange(
    value: string | string[] | null,
    actionMeta?: { action: string }
  ): void;
  onDelete?: (valueToRemove: string | string[] | null) => void;
  onOpenChange?(isOpen: boolean): void;
  stylesOverrides?: StylesOverrides;
};

export function StyledSelect({
  options,
  value,
  loading = false,
  disableSearchable = false,
  onChange,
  onOpenChange,
  stylesOverrides,
  isMulti,
  components,
  onDelete,
  ...rest
}: StyledSelectProps) {
  const { isMobile } = useTheme();
  const wrapperRef = useRef<any>();
  const { t } = useTranslation();

  const [isSelected, setSelected] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [input, setInput] = useState("");

  const selectStyles = useMemo(
    () =>
      getSelectStyles({
        ...stylesOverrides,
        container: {
          ...(isSelected
            ? {
                borderColor: COLOR_BLUE,
              }
            : isHovered
              ? {
                  borderColor: COLOR_SELECT_BORDER_HOVER,
                }
              : {}),
          ...stylesOverrides?.container,
        },
      }),
    [stylesOverrides, isHovered, isSelected]
  );

  const expandedOptionsWithSubOptions = [
    ...options,
    ...options.flatMap((option) =>
      "options" in option ? option?.options : []
    ),
  ];

  const selectedValue =
    isMulti && Array.isArray(value)
      ? value.map((val) =>
          (options as OptionType[]).find(
            (option) => "value" in option && option.value === val
          )
        )
      : expandedOptionsWithSubOptions.find(
          (option: SelectOption) => option?.value === value
        ) ?? null;

  useEffect(() => {
    // We need this due to WebView click not working for Android in mobile app
    // We simply need to have input over divs due to app integration - be careful when changing isSearchable prop (need to remove transform)
    if (isMobile && selectedValue && wrapperRef.current) {
      const input = document.getElementById(
        "react-select-2-input"
      ) as HTMLInputElement;

      if (input?.style) {
        input.style.width = `${
          wrapperRef.current?.offsetWidth - WIDTH_OF_DROPDOWN_AND_PADDING
        }px`;
      }
    }
  }, [isMobile, selectedValue]);

  const defaultOnSelectChange = (
    newValue: SelectOption | SelectOption[],
    actionMeta
  ) => {
    if (actionMeta?.action === "remove-value" && onDelete) {
      onDelete(actionMeta.removedValue.value);
    } else {
      if (Array.isArray(newValue)) {
        onChange(
          newValue.map((option) => option.value),
          actionMeta
        );
      } else {
        onChange(newValue.value, actionMeta);
      }
      setInput("");
    }
  };

  const handleInputChange = (value, action) => {
    action.action === "input-change" && setInput(value);
  };

  return (
    <div ref={wrapperRef}>
      <Hoverable
        onHoverIn={() => setHovered(true)}
        onHoverOut={() => setHovered(false)}
      >
        <Select
          onMenuOpen={() => {
            setSelected(true);
            onOpenChange?.(true);
          }}
          onMenuClose={() => {
            setSelected(false);
            onOpenChange?.(false);
          }}
          options={options}
          styles={selectStyles}
          value={selectedValue}
          inputValue={input}
          onInputChange={(value, action) => handleInputChange(value, action)}
          placeholder={`${t("selectPlaceholder")}...`}
          onChange={defaultOnSelectChange}
          menuPortalTarget={document.body}
          isLoading={loading}
          openMenuOnFocus={true}
          // BE CAREFUL - see effect
          isSearchable={!disableSearchable}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            ...components,
          }}
          noOptionsMessage={() => t("noOptions")}
          isMulti={isMulti}
          {...rest}
        />
      </Hoverable>
    </div>
  );
}
