import React, { useState } from "react";

import dayjs from "dayjs";
import { useFormikContext } from "formik";

import { AggregateFunction } from "../../../../graphql";
import { InformationWidgetFormModel } from "../../../../screens/InformationWidgetEditor";
import { getDummyTextData } from "../../../../utils/dummyData";
import { WidgetContainer } from "../../styled";

import { InformationTable } from "./InformationTable";
import { WidgetPreviewTitle } from "./styled";

const MAX_COUNT_OF_DUMMY_ITEMS = 3;

export const InformationWidgetPreview = () => {
  const { values } =
    useFormikContext<
      Pick<
        InformationWidgetFormModel,
        "informations" | "name" | "widgetBorders"
      >
    >();

  const [dummyValues, setDummyValue] = useState<Map<string, string>>(
    new Map<string, string>()
  );

  const prepareDummyValue = (
    templateId: string,
    index: number,
    agg: AggregateFunction
  ) => {
    const id = `${templateId}-${index}-${agg}`;
    const alreadyGeneratedValue = dummyValues.get(id);

    if (!alreadyGeneratedValue) {
      const generatedValue = getDummyTextData();

      setDummyValue(new Map(dummyValues.set(id, generatedValue)));
    }

    return alreadyGeneratedValue;
  };

  const dummyData = values.informations.map((information) => ({
    title: information.measurementTemplateName,
    templateId: information.measurementTemplateId,
    data: Array.from(
      Array(
        information.aggregation === AggregateFunction.Each
          ? Math.floor(Math.random() * MAX_COUNT_OF_DUMMY_ITEMS) + 1 // We do not want empty data in preview mode
          : 1
      )
    ).map((_, index) => ({
      id: index.toString(),
      value: prepareDummyValue(
        information.measurementTemplateId,
        index,
        information.aggregation
      ),
      date: dayjs().subtract(index, "day"),
    })),
  }));

  return (
    <WidgetContainer showBorders={values.widgetBorders}>
      <WidgetPreviewTitle>{values.name}</WidgetPreviewTitle>

      {dummyData.map((information) => (
        <InformationTable
          information={information}
          isPreviewMode={true}
          expandedByDefault={dummyData.length === 1}
          order={0}
        />
      ))}
    </WidgetContainer>
  );
};
