import React, {
  useState,
  ReactNode,
  FunctionComponent,
  useEffect,
} from "react";

import styled from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_TEXT_DEFAULT } from "../colors";

import Hoverable from "./Hoverable";

interface TooltipProps {
  title(): ReactNode;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  bottom?: boolean;
  right?: boolean;
  onHoverIn?(): void;
  onHoverOut?(): void;
  component(hovered: boolean): ReactNode;
  isHoverable?: boolean;
  zIndex?: number;
}

const Wrapper = styled.div<{ show: boolean; zIndex: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${({ show, zIndex }) => (show ? zIndex : 0)};
`;

const HoverableWrapper = styled.div<{ isHoverable: boolean }>`
  cursor: ${({ isHoverable }) => (isHoverable ? "pointer" : "default")};
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
`;

const TooltipContainer = styled.div<{
  show: boolean;
  bottom?: boolean;
  right?: boolean;
  height: number;
  width: number;
  zIndex: number;
}>`
  position: absolute;
  background-color: ${COLOR_ALMOST_BLACK};
  border-radius: 6px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  z-index: ${({ show, zIndex }) => (show ? zIndex : 0)};
  ${({ bottom, height }) =>
    bottom ? `bottom: ${height + 10}px;` : `top: ${height + 10}px;`}
  ${({ right }) => (right ? `right: 10px;` : `left: 0;`)}
`;

const TooltipTitle = styled.div<{ hasChildren: boolean }>`
  flex: 1;
  background-color: ${COLOR_TEXT_DEFAULT};
  padding: 13px;
  ${({ hasChildren }) =>
    hasChildren
      ? `
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  `
      : `
    border-radius: 6px;
  `}
`;

const Tooltip: FunctionComponent<React.PropsWithChildren<TooltipProps>> = ({
  title,
  style,
  containerStyle,
  component,
  bottom,
  right,
  onHoverIn,
  onHoverOut,
  children,
  isHoverable = true,
  zIndex = 0,
}) => {
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [show, onShow] = useState(false);

  useEffect(() => {
    const tooltip = document.getElementById("tooltip");

    const updateDimensions = () => {
      const { offsetHeight: height, offsetWidth: width } = tooltip;
      setDimensions({ height, width });
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  return (
    <Wrapper id="tooltip" show={show} zIndex={zIndex}>
      <Hoverable
        onHoverIn={async () => {
          onHoverIn?.();
          isHoverable && onShow(true);
        }}
        onHoverOut={async () => {
          onHoverOut?.();
          onShow(false);
        }}
      >
        {(hovered) => (
          <HoverableWrapper isHoverable={isHoverable} style={style}>
            {component(hovered)}
          </HoverableWrapper>
        )}
      </Hoverable>
      {show && (
        <TooltipContainer
          show={show}
          bottom={bottom}
          right={right}
          height={dimensions.height}
          width={dimensions.width}
          style={containerStyle}
          zIndex={zIndex}
        >
          {title && (
            <TooltipTitle hasChildren={!!children}>{title()}</TooltipTitle>
          )}
          {children ? <ChildrenWrapper>{children}</ChildrenWrapper> : null}
        </TooltipContainer>
      )}
    </Wrapper>
  );
};

export default Tooltip;
