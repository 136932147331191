import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useNotificationsContext } from "../../contexts/notifications";
import { useModalContext } from "../../contexts/UI/Modal";
import { useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import {
  Questionnaire as QuestionnaireModel,
  Section,
  useQuestionnaireQuery,
} from "../../graphql";
import { Loader } from "../Loader";
import { QuestionnaireSection } from "../Questionnaire/Section";
import { Step } from "../Questionnaire/Step";

type QuestionnaireProps = {
  questionnaireId: string;
  refetch: () => void;
  isInTopModal?: boolean;
};

export const QuestionnaireWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100%;
`;

const QuestionnaireSectionsWrapper = styled.div`
  width: 100%;
  margin: 15px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Questionnaire = ({
  questionnaireId,
  refetch,
  isInTopModal,
}: QuestionnaireProps) => {
  const { timezone, language, sessionId } = useUserContext();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(NaN);
  const { t } = useTranslation();
  const { showErrorNotification } = useNotificationsContext();
  const {
    actions: { closeModal },
  } = useModalContext();
  const {
    actions: { closeTopModal },
  } = useTopModalContext();

  const { data, loading, error } = useQuestionnaireQuery({
    variables: { id: questionnaireId, timezone, language, sessionId },
    fetchPolicy: "cache-and-network",
  });

  const handleCloseModal = useCallback(() => {
    if (isInTopModal) {
      closeTopModal();
    } else {
      closeModal();
    }
  }, [closeModal, closeTopModal, isInTopModal]);

  const onBackPress = useCallback(() => {
    handleCloseModal();
    refetch?.();
  }, [handleCloseModal, refetch]);

  useEffect(() => {
    if (error) {
      handleCloseModal();
      showErrorNotification(t("unknownServerError"));
    }
  }, [error, handleCloseModal, showErrorNotification, t]);

  const shouldShowSectionsView =
    data?.questionnaire?.sections?.length > 1 && isNaN(currentSectionIndex);

  const shouldRedirectToSectionsOverview =
    data?.questionnaire?.sections?.length > 1;

  const currentSection =
    data?.questionnaire?.sections?.length > 1
      ? data.questionnaire.sections[currentSectionIndex]
      : data?.questionnaire?.sections[0];

  return (
    <QuestionnaireWrapper>
      {loading || !data?.questionnaire ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : shouldShowSectionsView ? (
        <QuestionnaireSectionsWrapper>
          {data.questionnaire.sections.map((section, index) => (
            <QuestionnaireSection
              key={section.id}
              section={section as Section}
              sectionIndex={index}
              setCurrentSectionIndex={setCurrentSectionIndex}
            />
          ))}
        </QuestionnaireSectionsWrapper>
      ) : (
        <Step
          sectionId={currentSection?.id}
          questionnaire={data?.questionnaire as QuestionnaireModel}
          maxSteps={(currentSection as Section)?.maxSteps}
          sectionName={(currentSection as Section)?.name}
          onBackPress={onBackPress}
          shouldRedirectToSectionsOverview={shouldRedirectToSectionsOverview}
          showProgress={true}
          setCurrentSectionIndex={setCurrentSectionIndex}
        />
      )}
    </QuestionnaireWrapper>
  );
};
