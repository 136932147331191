import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { ApolloClient, useApolloClient } from "@apollo/client";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";
import { ChatSearchBar } from "../components/Agenda/components/ChatSearchBar";
import { MessageButton } from "../components/Button";
import ChatList from "../components/Communication/ChatList";
import { ChatScreen } from "../components/Communication/ChatScreen";
import { MessagesOptionsDropdown } from "../components/Communication/components/MessagesOptionsDropdown";
import { Header, HEIGHT_OF_MESSAGES_HEADER } from "../components/Header";
import { EditPencilSquaredIcon } from "../components/Icons";
import StyledText from "../components/StyledText";
import { CHAT_LIST_PAGE_SIZE } from "../constants/Messages";
import { useUIContext } from "../contexts/UI";
import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import {
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryResult,
  MessageOverviewPaginatedQueryVariables,
  useFindChattableUsersLazyQuery,
  useMessageOverviewPaginatedQuery,
  WhoCanPost,
} from "../graphql";
import { MessageOverviewPaginated } from "../graphql/queries/MessageOverviewPaginated";

export type AllMessageOverviewData =
  MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  background-color: ${COLOR_GRAY};
`;

const PageWrapper = styled.div`
  flex: 1;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 10px 12px;
  box-sizing: border-box;
  z-index: 2;
`;

const NewMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
`;

const ChatWrapper = styled.div<{ embed: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: ${({ embed }) =>
    `calc(100vh - ${embed ? HEIGHT_OF_MESSAGES_HEADER : 120}px)`};
`;

const ChatListWrapper = styled.div<{ isAvatarHovered: boolean }>`
  border-right-color: ${COLOR_GREY_ACTIVE};
  border-right-style: solid;
  border-right-width: 1px;
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  overflow: auto;
  z-index: ${({ isAvatarHovered }) => (!isAvatarHovered ? 99 : 0)};
  background-color: ${COLOR_WHITE};
  padding-top: 5px;
`;

const ChatScreenWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  min-width: 0;
`;

// This structure is important for cache match - test like writting new message to user without any message and reload page
function getNewContact(user): EnforceRequired<
  MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"] & {
    seen: any;
  }
> {
  return {
    __typename: "Chatter",
    licence: null,
    allFriendsOf: null,
    avatarThumbURL: user.avatarThumbURL,
    avatarURL: user.avatarUrl || "",
    canLoggedOnUserAddParticipants: true,
    canLoggedOnUserEditName: true,
    canLoggedOnUserRemoveParticipants: true,
    canLoggedOnUserDelete: !!user.lastMessage,
    canPost: true,
    chatterType: user.chatterType,
    chatterType2: user.chatterType2,
    firstName: user.firstName,
    groupOwner: null,
    hidden: false,
    id: user.id,
    lastChatTime: new Date().valueOf(),
    lastMessage: user.lastMessage || "",
    lastName: user.lastName,
    linkedGroup: null,
    muted: false,
    name: user.name,
    onlyTrainersCanPost: user.onlyTrainersCanPost,
    seen: { __typename: "Seen", numerator: 0, denominator: 2, who: Array(0) },
    unreadByMeInGroup: 0,
    unread: false,
    whoCanPost: WhoCanPost.All,
  };
}

const addContactToBeginningOfMessageOverviewCache = ({
  client,
  messageOverviewVariables,
  messageOverviewData,
  contact,
}: {
  client: ApolloClient<object>;
  messageOverviewVariables: MessageOverviewPaginatedQueryVariables;
  contact;
  messageOverviewData: MessageOverviewPaginatedQuery;
}) => {
  const messageOverviewChatterItem =
    messageOverviewData?.messageOverview?.contactsPaginated?.edges?.find(
      (c) => c.node?.id === contact.id
    )?.node;

  client.writeQuery<
    MessageOverviewPaginatedQuery,
    MessageOverviewPaginatedQueryVariables
  >({
    query: MessageOverviewPaginated,
    variables: messageOverviewVariables,
    data: {
      messageOverview: {
        ...messageOverviewData.messageOverview,
        contactsPaginated: {
          ...messageOverviewData.messageOverview.contactsPaginated,
          edges: [
            {
              node: getNewContact({
                ...contact,
                lastMessage: messageOverviewChatterItem?.lastMessage,
                lastChatTime: messageOverviewChatterItem?.lastChatTime,
              }),
              cursor: "",
            },
            ...messageOverviewData.messageOverview.contactsPaginated.edges.filter(
              (edge) => edge.node?.id !== contact.id
            ),
          ],
        },
      },
    },
  });
};

const useHandleChatAutoSelect = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { sessionId, language } = useUserContext();

  const [lastItem, setLastItem] = useState<null | string>(null);
  const [shouldFetchAllMessages, setShouldFetchAllMessages] =
    useState<boolean>(false);
  const [search, setSearch] = useState("");

  const client = useApolloClient();

  const selectedChatterId = searchParams.get("id");

  const messageOverviewVariables = {
    sessionId,
    language,
    showAll: true,
    includeHidden: true,
    ignoreContactsWithoutChats: true,
  };

  const [fetchChattableUsers, { data: chattableUsersData }] =
    useFindChattableUsersLazyQuery({
      variables: { sessionId },
    });

  const { data, loading, fetchMore } = useMessageOverviewPaginatedQuery({
    variables: {
      ...messageOverviewVariables,
      first: CHAT_LIST_PAGE_SIZE,
    },
  });

  const nextPageHandler = (allNextMessages?: boolean) => {
    if (data?.messageOverview?.contactsPaginated?.pageInfo.hasNextPage) {
      if (data?.messageOverview?.contactsPaginated?.pageInfo?.endCursor) {
        setLastItem(data.messageOverview.contactsPaginated.pageInfo.endCursor);
        setShouldFetchAllMessages(allNextMessages);
      }
    }
  };

  useEffect(() => {
    if (lastItem) {
      fetchMore({
        variables: {
          after: lastItem,
          first: shouldFetchAllMessages ? undefined : CHAT_LIST_PAGE_SIZE,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastItem, shouldFetchAllMessages]);

  useEffect(() => {
    if (
      data?.messageOverview?.contactsPaginated?.pageInfo?.endCursor &&
      search
    ) {
      setLastItem(data.messageOverview.contactsPaginated.pageInfo.endCursor);
      setShouldFetchAllMessages(true);
    }
  }, [data?.messageOverview?.contactsPaginated?.pageInfo?.endCursor, search]);

  const selectedChatter =
    data?.messageOverview?.contactsPaginated?.edges?.find(
      (chattableUser) =>
        chattableUser?.node?.id === selectedChatterId ||
        chattableUser?.node?.linkedGroup === selectedChatterId
    )?.node ||
    chattableUsersData?.findChattableUsers?.find(
      (chattableUser) => chattableUser.id === selectedChatterId
    );

  const firstHistoryChatWithChatter =
    !selectedChatter?.id &&
    selectedChatterId &&
    data?.messageOverview?.contactsPaginated?.edges?.length > 0;

  useEffect(() => {
    if (firstHistoryChatWithChatter) {
      fetchChattableUsers();
    }
  }, [fetchChattableUsers, firstHistoryChatWithChatter]);

  // If you have chat to user you never chat before add this user as the first item to list
  if (
    selectedChatter &&
    (!("lastChatTime" in selectedChatter) || !selectedChatter?.lastChatTime) &&
    selectedChatterId &&
    data.messageOverview?.contactsPaginated?.edges?.length &&
    chattableUsersData?.findChattableUsers
  ) {
    addContactToBeginningOfMessageOverviewCache({
      contact: selectedChatter,
      messageOverviewData: data,
      messageOverviewVariables,
      client,
    });
  }

  useLayoutEffect(() => {
    // If you have no id in URL or invalid id select the first visible chatter
    if (
      (!selectedChatterId &&
        data?.messageOverview?.contactsPaginated?.edges?.length) ||
      (selectedChatterId &&
        chattableUsersData?.findChattableUsers &&
        !selectedChatter)
    ) {
      setSearchParams(
        {
          id: [...(data?.messageOverview?.contactsPaginated?.edges || [])]
            .filter((edge) => !edge?.node?.hidden)
            ?.sort((a, b) => b?.node?.lastChatTime - a?.node?.lastChatTime)?.[0]
            ?.node?.id,
        },
        { replace: true }
      );
    }
  }, [
    chattableUsersData?.findChattableUsers,
    data?.messageOverview?.contactsPaginated?.edges,
    selectedChatter,
    selectedChatterId,
    setSearchParams,
  ]);

  return {
    selectedChatter,
    messageOverviewData: data,
    nextPageHandler,
    isLoading: loading,
    search,
    setSearch,
  };
};

export const MessagesScreen = () => {
  const { t } = useTranslation();

  const [, setSearchParams] = useSearchParams();
  const [activeContact, setActiveContact] = useState(null);
  const UI = useUIContext();
  const modal = useModalContext();
  const [isAvatarHovered, setIsAvatarHovered] = useState(false);

  const {
    selectedChatter,
    messageOverviewData,
    nextPageHandler,
    isLoading,
    search,
    setSearch,
  } = useHandleChatAutoSelect();

  useEffect(() => {
    if (selectedChatter?.id) {
      setActiveContact(selectedChatter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChatter?.id]);

  const onRowClick = (
    contact: MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"]
  ) => {
    setSearchParams({ id: contact.id });
    setActiveContact(contact);
  };

  const activateNextContact = (
    contact: MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"]
  ) => {
    const loadedVisibleEdges =
      messageOverviewData?.messageOverview?.contactsPaginated?.edges.filter(
        (edge) => !edge?.node?.hidden
      );
    const loadedEdgesLength = loadedVisibleEdges?.length;

    const currentContactIndex = loadedVisibleEdges?.findIndex(
      (allContact) => allContact?.node?.id === contact?.id
    );

    const nextContactIndex = currentContactIndex + 1;

    let nextContact = loadedVisibleEdges?.[0]?.node;

    if (currentContactIndex > -1 && loadedEdgesLength > nextContactIndex) {
      nextContact = loadedVisibleEdges?.[nextContactIndex]?.node;
    }

    if (nextContact && nextContact?.id !== contact?.id) {
      onRowClick(nextContact);
    }
  };

  const onNewChatRowPress = (
    contact: MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"]
  ) => {
    onRowClick(contact);
  };

  const renderChatScreen = useMemo(
    () =>
      activeContact ? (
        <ChatScreenWrapper>
          <ChatScreen
            contact={activeContact}
            onRowPress={onRowClick as any}
            setIsAvatarHovered={setIsAvatarHovered}
            activateNextContact={activateNextContact}
          />
        </ChatScreenWrapper>
      ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [UI.embed, activeContact, selectedChatter]
  );

  const threads = useMemo(
    () =>
      messageOverviewData?.messageOverview?.contactsPaginated?.edges?.map(
        (edge) => edge?.node
      ),
    [messageOverviewData?.messageOverview?.contactsPaginated?.edges]
  );

  const onNewMessageClick = () =>
    modal.actions.openModal({
      modal: ModalType.CREATE_NEW_CHAT,
      title: t("newMessage"),
      params: { onRowPress: onNewChatRowPress, disableCancel: true },
    });

  return (
    <Wrapper>
      <Header />

      <PageWrapper>
        <TopSectionWrapper>
          <StyledText
            fontWeight="bold"
            color={COLOR_TEXT_DEFAULT}
            fontSize={20}
            style={{ marginLeft: 20 }}
          >
            {t("messages")}
          </StyledText>
          <NewMessageWrapper>
            <MessageButton
              label={t("newMessage")}
              icon={<EditPencilSquaredIcon />}
              onClick={onNewMessageClick}
            />
            <MessagesOptionsDropdown threads={threads} />
          </NewMessageWrapper>
        </TopSectionWrapper>
        <ChatWrapper embed={UI.embed}>
          <ChatListWrapper isAvatarHovered={isAvatarHovered}>
            <ChatSearchBar setSearch={setSearch} search={search} />
            <ChatList
              data={threads}
              loading={isLoading}
              search={search}
              activeContact={activeContact}
              onRowPress={onRowClick}
              activateNextContact={activateNextContact}
              nextPageHandler={nextPageHandler}
            />
          </ChatListWrapper>
          {renderChatScreen}
        </ChatWrapper>
      </PageWrapper>
    </Wrapper>
  );
};
