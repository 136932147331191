import dayjs, { Dayjs } from "dayjs";

import * as api from "../api";
import * as endpoints from "../endpoints";

export interface EventLogQueryItem {
  _entries: {
    _time: number;
    _subject: string;
    _text: string;
  }[];
}

export type UseEventLog2022QueryArgs = {
  athleteId: string;
  data: {
    workoutsCompleted: boolean;
    testsRegistered: boolean;
    informationRegistered: boolean;
    somethingRead: boolean;
    maxCount: number;
  };
  from: Dayjs;
  session: api.Session;
  reportTemplateId: string;
  focusedGroup?: string;
  focusedUserAccount?: string;
};

export const fetchEventLogQuery2022 = ({
  athleteId,
  reportTemplateId,
  from,
  data,
  session,
  focusedUserAccount,
  focusedGroup,
}: UseEventLog2022QueryArgs) => {
  const params = {
    _focusedAthlete: athleteId,
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
    from: dayjs(from).valueOf(),
    reportTemplateId,
    ...data,
  };

  return api.post<EventLogQueryItem>(
    endpoints.JsonEventLogQuery2022,
    params,
    session
  );
};
