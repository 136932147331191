import React from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  styled as muistyled,
} from "@mui/material";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
} from "../../../colors";

export function Selection({
  label,
  defaultValue,
  options,
  onChange,
  value,
}: {
  label?: string;
  defaultValue?: string;
  options: { label: string; value: string }[];
  value?: unknown;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
}) {
  return (
    <>
      <FormControl>
        {label && <StyledFormLabel>{label}</StyledFormLabel>}
        {!!options?.length && (
          <RadioGroup
            defaultValue={defaultValue || ""}
            onChange={onChange}
            value={value}
          >
            {options?.map((o) => (
              <StyledFormControlLabel
                key={o.value}
                value={o.value}
                control={<StyledRadio />}
                label={o.label}
              />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    </>
  );
}

const StyledFormControlLabel = muistyled(FormControlLabel)(() => ({
  ".MuiFormControlLabel-label": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "inherit",
  },
}));
const StyledRadio = muistyled(Radio)(() => ({
  margin: "7.5px 12px",
  padding: "4px",
  border: `1px solid ${COLOR_GREY_ACTIVE}`,
  backgroundColor: COLOR_GRAY,
  "& .MuiSvgIcon-root": {
    width: "13px",
    height: "13px",
    backgroundColor: "transparent",
    borderRadius: "1em",
    fill: "none",
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      backgroundColor: COLOR_BLUE,
    },
  },
}));

const StyledFormLabel = muistyled(FormLabel)(() => ({
  fontWeight: 700,
  color: COLOR_TEXT_DARK,
  "&.Mui-focused": {
    color: COLOR_TEXT_DARK,
  },
}));
