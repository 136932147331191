import * as React from "react";

import styled from "styled-components";

import { COLOR_ALMOST_BLACK, COLOR_WHITE } from "../../../colors";
import Avatar from "../../Avatar";
import Hoverable from "../../Hoverable";
import StyledText from "../../StyledText";

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  position: relative;
  z-index: 100;
`;

const UserNameTooltip = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 27px;
  background-color: ${COLOR_ALMOST_BLACK};
  border-radius: 6px;
  height: 25px;
  align-self: center;
  padding: 0 6px;
  justify-content: center;
`;

const UserName = styled(StyledText)`
  color: ${COLOR_WHITE};
  white-space: nowrap;
  font-size: 12px;
`;

interface HoverableUserAvatarProps {
  avatarUrl: string;
  fromName: string;
  onHoverIn?: () => void;
  onHoverOut?: () => void;
}

export const HoverableUserAvatar = ({
  avatarUrl,
  fromName,
  onHoverIn,
  onHoverOut,
}: HoverableUserAvatarProps) => (
  <Hoverable onHoverIn={onHoverIn} onHoverOut={onHoverOut}>
    {(isHovered) => (
      <AvatarWrapper>
        {isHovered && (
          <UserNameTooltip>
            <UserName>{fromName}</UserName>
          </UserNameTooltip>
        )}
        <Avatar size="small" source={{ uri: avatarUrl }} userName={fromName} />
      </AvatarWrapper>
    )}
  </Hoverable>
);
