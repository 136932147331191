import { getValidUnitName } from "../../../../../utils/statistics";

import { COMPARISON_AGGREGATION_LABEL } from "./constants";
import { findAttribute } from "./findAttribute";
import { shouldReturnAggregationName } from "./headerDisplayChecker";
import { GroupTableStatRow } from "./models";
import { prepareTrendData } from "./prepareTrendData";

export const generateEmptyDataWithStructure = ({
  column,
  dataForTrend,
  toDate,
  shouldAttributeBeVisible,
  attributes,
}): GroupTableStatRow => ({
  label: column.attributes[0].name,
  attributes: column.attributes.map((attribute) => ({
    values: [
      ...attribute.aggregateFunctions,
      ...(attribute.comparison?.show ? [COMPARISON_AGGREGATION_LABEL] : []),
    ].map((aggregation) => ({
      id: `${column.templateId}-${aggregation}`,
      aggregation: shouldReturnAggregationName(
        attribute.attributeTemplate?.id,
        aggregation
      )
        ? aggregation
        : "",
      value: NaN,
      unitName: getValidUnitName(attribute.attributeTemplate?.unitName),
      attributeName: shouldAttributeBeVisible
        ? findAttribute(attributes, attribute.attributeTemplate?.id)
        : "",
    })),
    trendValues: attribute.showTrend
      ? prepareTrendData(
          dataForTrend,
          toDate,
          getValidUnitName(attribute.attributeTemplate?.unitName),
          attribute.attributeTemplate?.id,
          shouldAttributeBeVisible
            ? findAttribute(attributes, attribute.attributeTemplate?.id)
            : "TREND"
        )
      : [],
  })),
});
