import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CalendarIcon = memo(
  ({
    width = 15,
    height = 15,
    color = "#0073D9",
    isActive = false,
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      color={color}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0H5V1H10V0H11V1H12C13.6569 1 15 2.34315 15 4V12C15 13.6569 13.6569 15 12 15H3C1.34315 15 0 13.6569 0 12V4C0 2.34314 1.34315 1 3 1H4V0ZM10 2V3H11V2H12C13.1046 2 14 2.89543 14 4V12C14 13.1046 13.1046 14 12 14H3C1.89543 14 1 13.1046 1 12V4C1 2.89543 1.89543 2 3 2H4V3H5V2H10ZM3 7H4V8H3V7ZM6 7H5V8H6V7ZM7 7H8V8H7V7ZM10 7H9V8H10V7ZM11 7H12V8H11V7ZM12 5H11V6H12V5ZM9 5H10V6H9V5ZM8 5H7V6H8V5ZM5 9H6V10H5V9ZM6 11H5V12H6V11ZM7 11H8V12H7V11ZM4 11H3V12H4V11ZM3 9H4V10H3V9ZM8 9H7V10H8V9ZM9 9H10V10H9V9ZM12 9H11V10H12V9Z"
        fill={color}
      />
    </svg>
  )
);
