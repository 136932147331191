import React, {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import { COLOR_WHITE } from "../../colors";
import { GroupTablePeriodizationDataPerAthleteItem } from "../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";
import { SummaryStatsAlignment } from "../Report/PeriodizationTable/model";
import { WidgetTitle, WidgetTitleContainer } from "../Report/styled";
import { GroupTableStats } from "../ReportCreateTable/hooks/useGroupStatsData/helpers/models";
import { HeaderTitleTextWrapper } from "../ReportTableWidget/styled";
import { PeriodizationTableType } from "../TablePeriodization/Widget/Table";

import { Item } from "./Item";
import { SummaryStatsItem } from "./SummaryStatsItem";

type StickyColumnProps = {
  alternatingRow?: boolean;
  children: ReactNode;
  colorIndicator?: string;
  groupTableRowsData?: GroupTableStats[];
  hiddenHeaderHeight?: number;
  hiddenItemContainerRef: MutableRefObject<any>;
  scrollContainerRef: MutableRefObject<any>;
  setShouldDisplayLongName: Dispatch<SetStateAction<boolean>>;
  shouldDisplayLongName: boolean;
  showSummaryStats?: boolean;
  summaryStatsAlignment?: string;
  groupTablePeriodizationRowsData?: GroupTablePeriodizationDataPerAthleteItem[];
  title: string;
  type?: PeriodizationTableType;
  tableRowArray?: number[];
};

const HiddenColumnInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  z-index: 11;
  box-shadow: 0 10px 12px #000000bf;
`;

const AthleteHeaderContainer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
  background: ${COLOR_WHITE};
  padding-left: 15px;
`;

const AthleteTitleTextWrapper = styled(HeaderTitleTextWrapper)`
  justify-content: flex-start;
  height: 100%;
  padding: 5px 0 10px 0;
`;

// When using this component for sticky table column you need to pass tableRowArray if it is possible to have rows with different than default height
// Before re-render of the table it is needed to reset tableRowArray - see GroupTable.tsx for reference
export function StickyColumn({
  alternatingRow,
  children,
  colorIndicator,
  hiddenHeaderHeight,
  hiddenItemContainerRef,
  groupTableRowsData,
  groupTablePeriodizationRowsData,
  scrollContainerRef,
  setShouldDisplayLongName,
  shouldDisplayLongName,
  showSummaryStats = false,
  summaryStatsAlignment,
  tableRowArray,
  title,
  type,
}: StickyColumnProps) {
  const { t } = useTranslation();
  const { isMobile } = useTheme();

  const handleScroll = (event: any) => {
    setShouldDisplayLongName(event.target.scrollLeft <= 0);
  };

  return (
    <>
      <WidgetTitleContainer style={{ paddingBottom: 0, marginBottom: 8 }}>
        <WidgetTitle style={{ paddingLeft: isMobile ? "15px" : "30px" }}>
          {title}
        </WidgetTitle>
      </WidgetTitleContainer>
      <div
        ref={scrollContainerRef}
        style={{ width: "100%", overflowX: "auto" }}
        onScroll={handleScroll}
      >
        {!shouldDisplayLongName && (
          <HiddenColumnInnerContainer ref={hiddenItemContainerRef}>
            <AthleteHeaderContainer height={hiddenHeaderHeight}>
              <AthleteTitleTextWrapper>{t("athlete")}</AthleteTitleTextWrapper>
            </AthleteHeaderContainer>
            {showSummaryStats &&
              summaryStatsAlignment === SummaryStatsAlignment.TOP && (
                <SummaryStatsItem />
              )}
            {groupTableRowsData?.length &&
              groupTableRowsData.map(
                ({ stats, athleteId, athleteName }, index) => {
                  let rgbIndicator = "DCDCDC";
                  stats.forEach((stat) => {
                    stat.attributes.forEach((attribute) => {
                      attribute.values.forEach((value) => {
                        if (value.id === colorIndicator) {
                          rgbIndicator = value.rgb ?? "DCDCDC";
                        }
                      });
                    });
                  });

                  return (
                    <Item
                      key={athleteId}
                      itemHeight={tableRowArray[index]}
                      alternatingRow={alternatingRow}
                      athleteId={athleteId}
                      athleteName={athleteName}
                      colorIndicator={colorIndicator}
                      index={index}
                      rgbIndicator={rgbIndicator}
                    />
                  );
                }
              )}
            {groupTablePeriodizationRowsData?.length &&
              groupTablePeriodizationRowsData.map(({ athleteName }, index) => {
                const rgbIndicator = "DCDCDC";

                return (
                  <Item
                    key={`${athleteName}-${index}`}
                    alternatingRow={alternatingRow}
                    athleteName={athleteName}
                    colorIndicator={colorIndicator}
                    index={index}
                    rgbIndicator={rgbIndicator}
                    type={type}
                  />
                );
              })}
            {showSummaryStats &&
              summaryStatsAlignment === SummaryStatsAlignment.BOTTOM && (
                <SummaryStatsItem />
              )}
          </HiddenColumnInnerContainer>
        )}
        {children}
      </div>
    </>
  );
}
