import React from "react";
import { components, OptionProps } from "react-select";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";

import { BasicButton } from "./Button";
import { RoundedGroupProfileIcon } from "./Icons";
import { SelectOption } from "./StyledSelectField";

const Container = styled(BasicButton)`
  flex-direction: row;
  align-items: center;
  height: 25px;
  width: 100%;
`;

const WrapperIcon = styled.div`
  margin-right: 10px;
`;

const Label = styled.p<{ color: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  color: ${({ color }) => color};
  text-align: left;
`;

const Spacer = styled.div`
  padding-left: 35px;
`;

export function CustomTeamOption(
  props: OptionProps<SelectOption & { isGroup?: boolean }>
) {
  const { data, selectProps } = props;

  const onOptionClick = (event) => {
    event.stopPropagation();
    const { onChange, onMenuClose } = selectProps;

    onChange(props.data, undefined);
    onMenuClose();
  };

  return (
    <components.Option {...props}>
      <Container onClick={(event) => onOptionClick(event)}>
        {!data.isGroup ? (
          <Spacer />
        ) : (
          <WrapperIcon>
            <RoundedGroupProfileIcon width={25} height={25} />
          </WrapperIcon>
        )}
        <Label color={props.isSelected ? COLOR_WHITE : COLOR_TEXT_DARK}>
          {data.label}
        </Label>
      </Container>
    </components.Option>
  );
}
