import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_TABLE_LABEL, COLOR_WHITE } from "../../colors";
import { Drill } from "../../graphql";
import { BasicButton } from "../Button";
import Card from "../Card";
import { ReorderIcon } from "../Icons";
import StyledText from "../StyledText";

interface CustomDrill extends Drill {
  start?: number;
}

interface DrillItemProps {
  drill: CustomDrill;
  index: number;
  isLast?: boolean;
  isFirst?: boolean;
  hideStartTime?: boolean;
  drillMinComponent?: any;
  showDragIndicator?: boolean;
  onDrillItemPress: (index: number) => void;
}

const DrillWrapper = styled(BasicButton)`
  display: block;
  width: 100%;
`;

const DrillStart = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${COLOR_TEXT_TABLE_LABEL};
  margin-left: 12px;
`;

const DrillNameText = styled(StyledText)`
  flex: 1;
  font-size: 12px;
  overflow: hidden;
  margin-left: 12px;
`;

const DurationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 55px;
  height: 45px;
  border-radius: 3px;
  background-color: ${COLOR_GRAY};
  margin: 0 5px;
  align-items: center;
  justify-content: center;
`;

const DragIconWrapper = styled.div`
  display: flex;
  margin-left: 12px;
`;

const DrillCard = styled(Card)<{ isFirst: boolean; isLast: boolean }>`
  display: flex;
  flex-direction: row;
  height: 55px;
  background-color: ${COLOR_WHITE};
  align-items: center;
  justify-content: space-between;

  border-color: #dee2e9;

  border-width: 1px;
  border-bottom-width: ${({ isLast }) => (isLast ? 1 : 0)}px;
  border-bottom-style: solid;
  border-radius: 0;
  border-bottom-right-radius: ${({ isLast }) => (isLast ? "6px" : 0)};
  border-bottom-left-radius: ${({ isLast }) => (isLast ? "6px" : 0)};
  border-top-left-radius: ${({ isFirst }) => (isFirst ? "6px" : 0)};
  border-top-right-radius: ${({ isFirst }) => (isFirst ? "6px" : 0)};
`;

export const DrillItem = ({
  showDragIndicator,
  drill,
  index,
  isLast,
  isFirst,
  drillMinComponent,
  onDrillItemPress,
}: DrillItemProps) => {
  const { t } = useTranslation();

  return (
    <DrillWrapper onClick={() => onDrillItemPress(index)}>
      <DrillCard isLast={isLast} isFirst={isFirst}>
        {!showDragIndicator && (
          <DragIconWrapper>
            <ReorderIcon />
          </DragIconWrapper>
        )}
        <DrillStart>{dayjs(drill.start).format("HH:mm")}</DrillStart>
        <DrillNameText fontWeight="bold">{drill.name}</DrillNameText>
        <DurationContainer>
          {drillMinComponent ? (
            drillMinComponent
          ) : (
            <StyledText fontSize={18} fontWeight="bold">
              {drill.minutes}
            </StyledText>
          )}
          <StyledText
            fontSize={8}
            fontWeight="semibold"
            color={COLOR_TEXT_TABLE_LABEL}
          >
            {t("min").toUpperCase()}
          </StyledText>
        </DurationContainer>
      </DrillCard>
    </DrillWrapper>
  );
};
