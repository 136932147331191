import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { useFormikContext } from "formik";

import { useUserContext } from "../../../../contexts/User";
import { getDummyTextData, getRandomInt } from "../../../../utils/dummyData";
import { WidgetContainer } from "../../styled";
import { AttendanceLogWidgetFormValue } from "../Editor/model";

import { AttendanceLogTable } from "./AttendanceLogTable";
import { AttendanceLogDataItem } from "./model";

export const AttendanceLogWidgetPreview = () => {
  const { t } = useTranslation();
  const user = useUserContext();
  const isGolfer = user?.settings?._isGolfer;

  const { values } = useFormikContext<AttendanceLogWidgetFormValue>();

  const data: AttendanceLogDataItem[] = useMemo(
    () =>
      [
        ...(values.showEvents
          ? [
              {
                comment: getDummyTextData(),
                label: t("demoEvent"),
              },
            ]
          : []),
        ...(values.showGames
          ? [
              {
                comment: "",
                label: t(isGolfer ? "demoCompetition" : "demoGame"),
              },
            ]
          : []),
        ...(values.showPractices
          ? [{ comment: getDummyTextData(), label: t("demoPractice") }]
          : []),
      ].map((item, index) => ({
        ...item,
        status: getRandomInt({ min: 1, max: 4 }),
        time: dayjs().subtract(index, "days"),
        groupName: "XPS Test Team",
      })),
    [values.showEvents, values.showGames, values.showPractices, isGolfer, t]
  );

  return (
    <WidgetContainer showBorders={values.widgetBorders}>
      <AttendanceLogTable
        data={data}
        name={values.name}
        columnDividers={values.columnDividers}
        rowDividers={values.rowDividers}
        alternatingRow={values.alternatingValues}
        sortingOrder={values.sortingOrder}
        maxCount={values.maxCount}
      />
    </WidgetContainer>
  );
};
