import React from "react";
import { HeaderProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_SECONDARY_GREY,
  COLOR_LIGHT_BLUE,
  COLOR_TEXT_DARK,
} from "../../colors";

dayjs.extend(isToday);

const WeekHeaderWrapper = styled.div<{ isToday?: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-content: center;
  line-height: 20px;
  padding-bottom: 4px;
  padding-right: 4px;

  ${({ isToday }) =>
    isToday
      ? `
      border-bottom: 2px solid ${COLOR_BLUE};
  `
      : ""}
`;

const DaySpan = styled.span<{ isToday?: boolean }>`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  font-size: 10px;
  font-weight: 500;
  color: ${({ isToday }) =>
    isToday ? COLOR_LIGHT_BLUE : COLOR_SECONDARY_GREY};
`;

const TodaySpan = styled.span<{ isToday?: boolean }>`
  margin-left: 10px;
  font-weight: 500;
  font-size: 10px;
  color: ${COLOR_LIGHT_BLUE};
`;

const DateSpan = styled.span<{ isToday?: boolean }>`
  font-size: 16px;
  font-weight: bold;
  color: ${({ isToday }) => (isToday ? COLOR_BLUE : COLOR_TEXT_DARK)};
  margin-left: 10px;
`;

export const CustomCalendarWeekHeader = ({ label, date }: HeaderProps) => {
  const { t } = useTranslation();
  const [dayNumber, day] = label.split(" ");

  const isToday = dayjs(date).isToday();

  return (
    <WeekHeaderWrapper isToday={isToday}>
      {isToday && <TodaySpan>{t("today").toUpperCase()}</TodaySpan>}
      <DaySpan isToday={isToday}>{day.toUpperCase()}</DaySpan>
      <DateSpan isToday={isToday}>{dayNumber}</DateSpan>
    </WeekHeaderWrapper>
  );
};
