import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_SECONDARY_RED,
  COLOR_WHITE,
} from "../../../colors";
import { useSettingsContext } from "../../../contexts/settingsContext";
import { useUserContext } from "../../../contexts/User";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { getAvatarUrl } from "../../../utils/getAvatarUrl";
import Avatar from "../../Avatar";
import { formatRelativeDate } from "../../Communication/utils";
import StyledText from "../../StyledText";

type Receiver = {
  thumb?: string;
  profile?: {
    userName?: string;
    firstName?: string;
    lastName?: string;
    profileImageUrl?: string;
  };
};

type NotificationListItemProps = {
  isLastItem: boolean;
  cancelled: boolean;
  time: number;
  userName: string;
  profileImg: string;
  groupName: string;
  texts: string[];
  title: string;
  teamImg: string;
  teamName: string;
  receivers: Receiver[];
  sessionId: string;
  sessionType: "Practice" | "Workout" | "Game" | "Event" | "Questionnaire";
};

/*const Circle = styled.div`
  height: 9px;
  width: 9px;
  border-radius: 4.5px;
  background-color: ${COLOR_SECONDARY_RED};
  margin-right: 8px;
  margin-top: 5px;
`;*/

const ItemWrapper = styled.div<{ isLastItem?: boolean; clickable: boolean }>`
  flex: 1;
  display: flex;
  background-color: ${COLOR_WHITE};
  padding: 10px 15px 20px 15px;
  border-radius: ${({ isLastItem }) => (isLastItem ? 6 : 0)}px;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom-width: ${({ isLastItem }) => (isLastItem ? 0 : 1)}px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "default")};
`;

const ItemContentWrapper = styled.div`
  flex: 1;
`;

const AvatarWrapper = styled.div`
  padding-right: 15px;
  padding-top: 5px;
`;

const ReceiverWrapper = styled.div`
  margin-bottom: 5px;
`;

const TeamAvatarWrapper = styled.div`
  margin-left: 10px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 5px;
`;

const EventStateWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FirstRowWrapper = styled.div`
  flex: 1;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
`;

const RightContentWrapper = styled.div`
  flex: 1;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const NotificationListItem = ({
  isLastItem,
  cancelled,
  time,
  userName,
  profileImg,
  groupName,
  texts,
  title,
  teamImg,
  teamName,
  receivers,
  sessionId,
  sessionType,
}: NotificationListItemProps) => {
  const { t } = useTranslation();
  const { timeFormatTemplate } = useSettingsContext();
  const { isFamilyMember } = useUserContext();
  const navigate = useNavigate();

  const onNotificationClick = () => {
    if (sessionId && sessionType) {
      navigate({
        pathname: `/${ROUTING_CONFIG.session}`,
        search: `?sessionGuid=${sessionId}&sessionType=${sessionType}`,
      });
    }
  };

  return (
    <ItemWrapper
      clickable={!!(sessionId && sessionType)}
      isLastItem={isLastItem}
      onClick={onNotificationClick}
    >
      <AvatarWrapper>
        {isFamilyMember ? (
          receivers?.length ? (
            <>
              {receivers.map((receiver, index) => (
                <ReceiverWrapper key={`${receiver?.profile.userName}-${index}`}>
                  <Avatar
                    userName={`${receiver?.profile?.firstName} ${receiver?.profile?.lastName}`}
                    size={25}
                    uri={getAvatarUrl(receiver)}
                  />
                </ReceiverWrapper>
              ))}
            </>
          ) : (
            <Avatar userName={userName} size={25} uri={profileImg} />
          )
        ) : (
          <Avatar
            userName={teamName}
            size={25}
            uri={teamImg}
            shouldBeHidden={!teamName && !teamImg}
          />
        )}
      </AvatarWrapper>
      <ItemContentWrapper>
        <FirstRowWrapper>
          <StyledText fontSize={14}>{groupName}</StyledText>
          <RightContentWrapper>
            <StyledText fontSize={10} color={COLOR_SECONDARY_GREY_HOVER}>
              {formatRelativeDate(time, timeFormatTemplate).toUpperCase()}
            </StyledText>
            {isFamilyMember ? (
              <TeamAvatarWrapper>
                <Avatar userName={teamName} size={25} uri={teamImg} />
              </TeamAvatarWrapper>
            ) : null}
          </RightContentWrapper>
        </FirstRowWrapper>
        {cancelled && (
          <EventStateWrapper>
            <StyledText
              fontSize={12}
              fontWeight="bold"
              color={COLOR_SECONDARY_RED}
            >
              {t("eventCancelled")}
            </StyledText>
          </EventStateWrapper>
        )}
        {title && (
          <TitleWrapper>
            <StyledText fontWeight="bold">{title}</StyledText>
          </TitleWrapper>
        )}
        {texts?.length ? (
          <>
            <StyledText fontSize={14} color={COLOR_MEDIUM_GRAY}>
              {texts.map((text) => text)}
            </StyledText>
          </>
        ) : null}
      </ItemContentWrapper>
    </ItemWrapper>
  );
};
