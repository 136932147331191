import React from "react";
import { TFunction, useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../../colors";
import { Checkbox } from "../../../Checkbox";
import { StyledSwitch } from "../../../StyledSwitch";
import StyledText from "../../../StyledText";
import {
  ComparisonBase,
  ComparisonViewType,
  GroupColumn as GroupColumnModel,
} from "../../models";
import { SwitchWrapper } from "../../styled";

import { SubSectionRow } from "./styled";
import { getPropertyNameInForm } from "./utils";

interface IComparisonSubSectionProps {
  columnIndex: number;
}

const getComparisonBase = (t: TFunction<"common">) => [
  { label: t("dayBefore"), value: ComparisonBase.DAY_BEFORE },
  { label: t("latestMeasurement"), value: ComparisonBase.LATEST },
  {
    label: t("firstMeasurementInPeriod"),
    value: ComparisonBase.FIRST_IN_PERIOD,
  },
];

const getComparisonView = (t: TFunction<"common">) => [
  { label: t("asPercentage"), value: ComparisonViewType.PERCENTAGE },
  { label: t("asValues"), value: ComparisonViewType.VALUES },
];

const CheckboxWrapper = styled.div<{ isBig?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isBig ? "40%" : "auto")};
`;

const SubSectionTitle = styled(StyledText)`
  font-size: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  height: 12px;
  margin-left: 30px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const ComparisonSubSection = ({
  columnIndex,
}: IComparisonSubSectionProps) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<{
    columns: GroupColumnModel[];
  }>();

  const currentColumn = values.columns[columnIndex];

  const onBaseChange = (baseValue: ComparisonBase) => () =>
    setFieldValue(
      getPropertyNameInForm("comparison.base", columnIndex),
      baseValue
    );

  const onViewChange = (viewValue: ComparisonViewType) => () =>
    setFieldValue(
      getPropertyNameInForm("comparison.view", columnIndex),
      viewValue
    );

  const onShowComparisonClick = (isActive: boolean) =>
    setFieldValue(getPropertyNameInForm("comparison", columnIndex), {
      show: isActive,
      view: isActive ? ComparisonViewType.VALUES : "",
      base: isActive ? ComparisonBase.DAY_BEFORE : "",
    });

  return (
    <>
      <SwitchWrapper>
        <StyledSwitch
          value={currentColumn.comparison.show}
          onValueChange={onShowComparisonClick}
          primary={true}
          label="Show Comparison"
        />
      </SwitchWrapper>

      {currentColumn.comparison.show && (
        <>
          <SubSectionRow
            style={{ marginBottom: "20px", justifyContent: "space-between" }}
          >
            {getComparisonBase(t).map((base, index) => (
              <CheckboxWrapper
                key={`${base.value}-${index}`}
                isBig={base.value === ComparisonBase.FIRST_IN_PERIOD}
              >
                <Checkbox
                  text={base.label}
                  typeIcon="circle"
                  check={onBaseChange(base.value)}
                  checked={currentColumn.comparison.base === base.value}
                />
              </CheckboxWrapper>
            ))}
          </SubSectionRow>

          <SubSectionTitle>{t("view").toUpperCase()}</SubSectionTitle>

          <SubSectionRow style={{ justifyContent: "flex-start" }}>
            {getComparisonView(t).map((view, index) => (
              <CheckboxWrapper
                key={`${view.value}-${index}`}
                style={{ marginRight: "15px" }}
              >
                <Checkbox
                  text={view.label}
                  typeIcon="circle"
                  check={onViewChange(view.value)}
                  checked={currentColumn.comparison.view === view.value}
                  withMargin={false}
                />
              </CheckboxWrapper>
            ))}
          </SubSectionRow>
        </>
      )}
    </>
  );
};
