import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const RemoveIcon = memo(
  ({ width = 13, height = 13, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="highlight"
      {...props}
    >
      {isActive ? (
        <>
          <rect width="13" height="13" rx="6.5" fill="white" />
          <rect
            x="10"
            y="6"
            width="1"
            height="7"
            transform="rotate(90 10 6)"
            fill="#FF6562"
          />
        </>
      ) : (
        <>
          <rect width="13" height="13" rx="6.5" fill="#FF6562" />
          <rect
            x="10"
            y="6"
            width="1"
            height="7"
            transform="rotate(90 10 6)"
            fill="white"
            className="symbol"
          />
        </>
      )}
    </svg>
  )
);
