import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../../colors";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { useRegisterQuestionnaireInformationMutation } from "../../../../graphql";
import { GeneralButton } from "../../../Button/";
import { StyledSelect } from "../../../StyledSelect";
import { StyledTextInput } from "../../../StyledTextInput";

interface EditRowProps {
  informationRegistrationId: string;
  defaultValue: string;
  measurementTemplateId: string;
  options?: string[];
  handleClose: () => void;
  onOpenChange?: (isOpen: boolean) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 0;
  padding: 0 30px;
  gap: 8px;
  z-index: 100;
`;

const EditInput = styled(StyledTextInput)`
  background-color: ${COLOR_WHITE};
  flex: 1;
  border: 1px solid ${COLOR_GREY_ACTIVE};
`;

const EditSelectWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const EditRow = ({
  informationRegistrationId,
  defaultValue,
  measurementTemplateId,
  options = [],
  handleClose,
  onOpenChange,
}: EditRowProps) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const [{ selectedAthlete }] = useReportsContext();

  const [newValue, setNewValue] = useState("");

  const [registerQuestionnaireInformationMutation] =
    useRegisterQuestionnaireInformationMutation();

  useEffect(() => {
    setNewValue(defaultValue);
  }, [informationRegistrationId, defaultValue]);

  const onSavePress = async () => {
    try {
      await registerQuestionnaireInformationMutation({
        variables: {
          sessionId,
          language,
          focusedAthlete: selectedAthlete?.id,
          input: {
            id: informationRegistrationId,
            templateId: measurementTemplateId,
            values: [newValue],
          },
        },
      });

      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeValue = (newValue: string) => setNewValue(newValue);

  return (
    <Wrapper>
      {options.length > 0 ? (
        <EditSelectWrapper>
          <StyledSelect
            options={options.map((option) => ({
              label: option,
              value: option,
            }))}
            value={newValue}
            onChange={onChangeValue}
            onOpenChange={onOpenChange}
          />
        </EditSelectWrapper>
      ) : (
        <EditInput
          value={newValue}
          onChange={(event) => onChangeValue(event.currentTarget.value)}
        />
      )}
      <GeneralButton height={45} label={t("save")} onClick={onSavePress} />
    </Wrapper>
  );
};
