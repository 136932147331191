import { ENVIRONMENT } from "@env";

import Storage from "./storage";
import { StorageKeys } from "./storageKeys";

export const isTestEnv = (): boolean => {
  // For initial page load and REST API requests
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const isTestFromParams = !!params.get("isTest");

  // Mostly for embedded version of XPS Desktop / mobile
  const isTestFromStorage =
    Storage.getItemSession<string>(StorageKeys.isTestEnv) === "true";

  // For local development
  const isTestFromDotEnv = ENVIRONMENT && ENVIRONMENT !== "prod";

  return isTestFromParams || isTestFromDotEnv || isTestFromStorage;
};
