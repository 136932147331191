import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled, { css, useTheme } from "styled-components";

import { COLOR_TEXT_TABLE_LABEL, COLOR_WHITE } from "../../colors";
import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useAthleteProfileContext } from "../../contexts/athleteProfile";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import {
  ReportTemplate,
  ReportWidgetTemplateHeader,
  useWidgetTemplatesQuery,
} from "../../graphql";
import { useIsMonitoring } from "../../hooks";
import useAthleteReportTabs from "../../hooks/data/useAthleteReportTabs";
import { useAutoSelectAthlete } from "../../hooks/report/useAutoSelectAthlete";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { AthleteHeader } from "../Athlete/AthleteHeader";
import { AthleteHeaderOptions } from "../Athlete/AthleteHeaderOptions";
import { AthleteSelector } from "../Athlete/AthleteSelector";
import { ButtonColor, GeneralButton } from "../Button/";
import { EditPencilIcon } from "../Icons";
import { AthleteWidget } from "../ReportCreateTable/models";
import StyledText from "../StyledText";
import { Tab } from "../Tabs";

import { useEditReportWarning } from "./hooks/useEditReportWarning";
import { TabReport } from "./TabReport";

type AthleteReportProps = {
  reportTemplate: ReportTemplate;
  setReportEditMode(isEditMode: boolean): void;
  isReportInEditMode?: boolean;
  monitoringTabOptions?: (Tab & { isShared: boolean; isVisible: boolean })[];
};

const AthleteReportWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  padding-bottom: 4rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
    `};
`;

const NoAthletesErrorText = styled(StyledText)`
  text-align: center;
  font-size: 16px;
  margin-top: 16px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const OptionWrapper = styled.div`
  margin-left: 20px;
`;

const ReportContent = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: all 0.25s ease-out;
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  max-width: 100%;
`;

const ReportContentContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const AthleteSelectorWrapper = styled.div<{
  headerSettingsIsOpen?: boolean;
  isMobile: boolean;
}>`
  margin-right: ${({ isMobile }) => (!isMobile ? 15 : 0)}px;
  height: 100%;
  width: auto;
  z-index: ${({ isMobile }) => (isMobile ? 2 : 0)};
`;

const AthleteHeaderOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export function AthleteReport({
  reportTemplate,
  isReportInEditMode = false,
  setReportEditMode,
  monitoringTabOptions,
}: AthleteReportProps) {
  const { t } = useTranslation();
  const user = useUserContext();
  const { isTrainer, id: currentUserId, isFamilyMember } = user;
  const hasAthleteProfileContext = useAthleteProfileContext() !== null;
  const { isMobile } = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const isMonitoringReport = useIsMonitoring();
  const shouldUseReportTabsFromMonitoring =
    isMonitoringReport && searchParams.get("isEditMode") !== "true";

  const tabReportTemplates = reportTemplate?.tabs;
  const activeTabId = searchParams.get("activeTabId") ?? "";
  const { athleteReportTabOptions: reportTabOptions } = useAthleteReportTabs(
    tabReportTemplates,
    shouldUseReportTabsFromMonitoring
  );

  // We need this due to refreshing of shared icon of the report tab in monitoring
  const athleteReportTabOptions = shouldUseReportTabsFromMonitoring
    ? monitoringTabOptions
    : reportTabOptions;

  const [{ selectedAthlete, selectedGroup }, { selectAthlete }] =
    useReportsContext();
  const canEdit = useCanEditStatistics();
  const { sortedAthletes } = useAutoSelectAthlete();

  const { sessionId, language } = useUserContext();

  const handleEditReport = useEditReportWarning({
    reportTemplate,
    onEdit: () => setReportEditMode(true),
  });

  const setActiveTab = useCallback(
    (tab: Tab) => {
      setSearchParams({
        ...getSearchParamsObject(searchParams),
        activeTabId: tab.id,
      });
    },
    [setSearchParams, searchParams]
  );

  useEffect(() => {
    athleteReportTabOptions?.length &&
      setActiveTab(
        athleteReportTabOptions?.find((t) => t.id === activeTabId) ??
          athleteReportTabOptions[0] ??
          null
      );
  }, [athleteReportTabOptions, activeTabId, setActiveTab]);

  useEffect(() => {
    // Athlete should not see other athletes selector - use always current athlete
    if (!isTrainer && !selectedAthlete) {
      const athleteUser = selectedGroup?.requiredAthletes?.find(
        (athlete) => athlete.id === currentUserId
      );
      selectAthlete(athleteUser);
    }
  }, [
    selectedAthlete,
    isTrainer,
    selectedGroup?.requiredAthletes,
    currentUserId,
    selectAthlete,
  ]);

  const activeTabReportTemplate = tabReportTemplates.find(
    (template) => template?.id === activeTabId
  );

  const { data: generalWidgetsData, refetch: refetchGeneralWidgets } =
    useWidgetTemplatesQuery({
      variables: {
        sessionId,
        accountId: selectedGroup?.owner?.id,
        parentReport: null,
        language,
      },
      fetchPolicy: "cache-and-network",
    });

  const generalWidgets: AthleteWidget[] = React.useMemo(
    () =>
      (generalWidgetsData?.reportWidgetTemplates || [])
        .map((widget) => ({
          ...widget,
          ...("preferences" in widget
            ? JSON.parse(widget.preferences)
            : undefined),
        }))
        .filter(
          (widget) =>
            widget.__typename !== "ReportWidgetTemplateGroupTable" &&
            !widget.isGroupWidget
        ),
    [generalWidgetsData?.reportWidgetTemplates]
  );

  const headerWidget = useMemo<ReportWidgetTemplateHeader | undefined>(() => {
    const headerWidget = reportTemplate?.widgets?.find(
      (widget) => widget.__typename === "ReportWidgetTemplateHeader"
    );

    return headerWidget && (headerWidget as ReportWidgetTemplateHeader);
  }, [reportTemplate?.widgets]);

  return (
    <AthleteReportWrapper isMobile={isMobile}>
      {!isMobile && isRealTrainer({ isTrainer, isFamilyMember }) && (
        <AthleteSelectorWrapper isMobile={isMobile}>
          <AthleteSelector />
        </AthleteSelectorWrapper>
      )}
      <ReportContentContainer>
        <ReportContent id="report-id">
          {selectedAthlete && (
            <AthleteHeaderOptionsWrapper>
              <AthleteHeader
                isEditMode={isReportInEditMode}
                athlete={selectedAthlete}
                headerTemplate={headerWidget}
                tabs={athleteReportTabOptions}
                tabReports={reportTemplate?.tabs}
                activeTab={athleteReportTabOptions?.find(
                  ({ id }) => id === activeTabId
                )}
                onTabChange={setActiveTab}
                parentReportId={reportTemplate.id}
                headerOptions={
                  !isMobile && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      {hasAthleteProfileContext &&
                        isTrainer &&
                        !isReportInEditMode && (
                          <AthleteHeaderOptions
                            onEdit={() => setReportEditMode(true)}
                            title={reportTemplate.name}
                            tabTemplates={tabReportTemplates}
                            headerWidget={headerWidget}
                            canEdit={canEdit}
                          />
                        )}
                      {isReportInEditMode && (
                        <OptionWrapper>
                          <GeneralButton
                            colorVariant={ButtonColor.Blue}
                            label={t("stopEditing")}
                            onClick={() => setReportEditMode(false)}
                          />
                        </OptionWrapper>
                      )}
                      {!isReportInEditMode &&
                        !hasAthleteProfileContext &&
                        canEdit && (
                          <OptionWrapper>
                            <GeneralButton
                              icon={<EditPencilIcon tintColor={COLOR_WHITE} />}
                              label={t("edit")}
                              onClick={handleEditReport}
                            />
                          </OptionWrapper>
                        )}
                    </div>
                  )
                }
              />
            </AthleteHeaderOptionsWrapper>
          )}

          {sortedAthletes?.length > 0 ? (
            <TabReport
              parentReportId={reportTemplate.id}
              tabReportTemplates={activeTabReportTemplate}
              generalReportWidgetTemplates={generalWidgets}
              isEditMode={isReportInEditMode}
              refetchGeneralWidgets={refetchGeneralWidgets}
            />
          ) : (
            <NoAthletesErrorText>
              {t("noAthletes").toUpperCase()}
            </NoAthletesErrorText>
          )}
        </ReportContent>
      </ReportContentContainer>
    </AthleteReportWrapper>
  );
}
