import React from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { SubGroup } from "../../graphql";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import StyledText from "../StyledText";

import { GroupMainCard } from "./GroupListCard";

const AthleteBaseInfoWrapper = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
`;

export const SubGroupListCard = ({
  group,
  isLast,
}: {
  group: SubGroup;
  isLast?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <GroupMainCard
      onClick={() =>
        navigate(`/${ROUTING_CONFIG.AthleteTeam}`, { state: group })
      }
      noBottomMargin={isLast}
    >
      <AthleteBaseInfoWrapper>
        <StyledText fontSize={16} fontWeight="bold">
          {group?.name}
        </StyledText>
      </AthleteBaseInfoWrapper>
    </GroupMainCard>
  );
};
