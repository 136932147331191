import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_LINK_BLUE } from "../colors";

import { BasicButton } from "./Button";
import StyledText from "./StyledText";

const Link = styled(StyledText)<{ size?: number }>`
  font-weight: 700;
  font-size: ${({ size }) => size}px;
  color: ${COLOR_LINK_BLUE};
  border-bottom: 1px solid ${COLOR_LINK_BLUE};
`;

interface LinkUrlProps {
  url: string;
  size?: number;
  wrapperStyle?: CSSProperties;
}

export const LinkUrl = ({ url, size, wrapperStyle }: LinkUrlProps) => {
  const { t } = useTranslation();

  const processedUrl = url.startsWith("http")
    ? url.trim()
    : `https://${url.trim()}`;

  return (
    <BasicButton
      onClick={() => window.open(new URL(processedUrl).toString(), "_blank")}
      style={wrapperStyle}
    >
      <Link size={size || 14}>{t("clickToView")}</Link>
    </BasicButton>
  );
};
