import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_RED_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { useUIContext } from "../../contexts/UI";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useLogOut } from "../../hooks/useLogOut";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../../utils/createSearchParamsWithoutUndefinedValues";
import { BasicButton } from "../Button";
import {
  LogoutIcon,
  ProfileIcon,
  SettingsGearIcon,
  SwitchAccountIcon,
} from "../Icons";
import StyledText from "../StyledText";

const LevelsDivider = styled.div`
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  margin: 5px 0;
`;

const StyledDropdownItem = styled(BasicButton)`
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 0 15px;
  height: 45px;
`;

const StyledImageWrapper = styled.div<{ bgColor?: string }>`
  border-radius: 50%;
  width: 35px;
  height: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: ${({ bgColor }) => bgColor || COLOR_GRAY};
`;

export const UserProfileOptionsDropdown = ({ toggle }) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const ui = useUIContext();
  const modal = useModalContext();
  const topModal = useTopModalContext();
  const { isTrainer, isFamilyMember, id, isTemporary } = useUserContext();
  const isAthlete = !isTrainer && !isFamilyMember;
  const navigate = useNavigate();

  const { handleLogout, handleSwitchAccount } = useLogOut({ toggle });

  const onEditProfileClick = () => {
    if (isAthlete) {
      navigate({
        pathname: `/${ROUTING_CONFIG.Athletes}`,
        search: createSearchParamsWithoutUndefinedValues({
          athleteId: id,
          viewAs: "athlete",
        }),
      });
      return;
    }
    modalActions.openModal({
      modal: ModalType.EDIT_USER_PROFILE,
      title: t("editProfile"),
    });
  };

  const onAccountPress = async (sessionId: string | null) => {
    if (sessionId === null) {
      return topModal.actions.openTopModal({
        modal: TopModalType.CONFIRM_ACCOUNT_SWITCH,
        title: t("confirmAccountSwitch"),
        params: {
          onConfirm: () =>
            modalActions.openModal({
              modal: ModalType.LOGIN,
              title: null,
              style:
                ui.width > 500
                  ? {
                      maxWidth: 422,
                      minWidth: 422,
                      minHeight: 450,
                    }
                  : {
                      minHeight: 450,
                    },
            }),
        },
      });
    }
    if (!isTemporary) {
      await handleSwitchAccount(sessionId);
    } else {
      topModal.actions.openTopModal({
        modal: TopModalType.CONFIRM_ACCOUNT_SWITCH,
        title: t("confirmAccountSwitch"),
        params: {
          onConfirm: () => handleSwitchAccount(sessionId),
        },
      });
    }
  };

  const onSwitchAccountsClick = () => {
    toggle();
    modalActions.openModal({
      modal: ModalType.SWITCH_ACCOUNTS,
      title: t("switchAccounts"),
      params: {
        handleSwitchAccount: (sessionId) => onAccountPress(sessionId),
      },
      style:
        ui.width > 500
          ? {
              maxWidth: 435,
              minWidth: 435,
            }
          : {},
    });
  };

  const handleSettingsClick = () => {
    modal.actions.openModal({
      modal: ModalType.SETTINGS,
      style: { minWidth: 375, minHeight: 252 },
      title: t("settings"),
      params: {},
    });
  };

  const firstLevelItems = [
    {
      label: isAthlete ? t("profile") : t("editProfile"),
      onClick: onEditProfileClick,
      icon: <ProfileIcon />,
    },
    {
      label: t("settings"),
      onClick: handleSettingsClick,
      icon: <SettingsGearIcon />,
    },
  ];

  const secondLevelItems = [
    {
      label: t("switchAccounts"),
      onClick: onSwitchAccountsClick,
      icon: <SwitchAccountIcon />,
    },
    {
      label: t("logOut"),
      onClick: handleLogout,
      icon: <LogoutIcon />,
      bgColor: COLOR_RED_ACTIVE,
      tintColor: COLOR_WHITE,
    },
  ];

  return (
    <>
      {firstLevelItems.map((item) => (
        <StyledDropdownItem key={item.label} onClick={item.onClick}>
          {item.icon && <StyledImageWrapper>{item.icon}</StyledImageWrapper>}
          <StyledText fontSize={14} fontWeight="bold" numberOfLines={1}>
            {item.label}
          </StyledText>
        </StyledDropdownItem>
      ))}

      <LevelsDivider />

      {secondLevelItems.map((item) => (
        <StyledDropdownItem key={item.label} onClick={item.onClick}>
          {item.icon && (
            <StyledImageWrapper bgColor={item.bgColor}>
              {item.icon}
            </StyledImageWrapper>
          )}
          <StyledText fontSize={14} fontWeight="bold" numberOfLines={1}>
            {item.label}
          </StyledText>
        </StyledDropdownItem>
      ))}
    </>
  );
};
