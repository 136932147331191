import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_LIGHT_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { NewChatProvider } from "../../contexts/messaging/NewChatProvider";
import { useUIContext } from "../../contexts/UI";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useHandleClickOutside } from "../../hooks";
import { PrintAthleteReportModal } from "../../print/athleteReport/PrintAthleteReportModal";
import { PrintGroupReportModal } from "../../print/groupReport/PrintGroupReportModal";
import { ForgotPasswordPage } from "../../screens/ForgotPasswordPage";
import LoginScreen from "../../screens/LoginScreen";
import { InvitationModal } from "../../screens/LoginScreen/InvitationsModal";
import { TermsModal } from "../../screens/LoginScreen/TermsModal";
import { AddAthleteHeaderInfoModal } from "../Athlete/AddAthleteHeaderInfoModal";
import { AddTabReportModal } from "../Athlete/AddTabReportModal";
import { Collections } from "../Collections";
import { IconButton } from "../IconButton";
import { CrossIcon, NextIcon } from "../Icons";
import { FolderStructureModal } from "../Report/FolderStructure/FolderStructureModal";
import StyledText from "../StyledText";

import { AddCoachOrAthleteToGroupModal } from "./AddCoachOrAthleteToGroupModal";
import { AddToCalendar } from "./AddToCalendar";
import { AthletesSelector } from "./AthletesSelector";
import Attendance from "./Attendance";
import { EditCustomChatGroupModal } from "./ChatModals/EditCustomChatGroupModal";
import { EditTeamChatGroupModal } from "./ChatModals/EditTeamChatGroupModal";
import { CollectionQuestionnaire } from "./CollectionQuestionnaire";
import { ConfirmationModal } from "./ConfirmationModal";
import { CreateEventOrGameModal } from "./CreateEventOrGameModal";
import CreateNewChat from "./CreateNewChat";
import CreateNewGroupChat from "./CreateNewGroupChat";
import { CreatePracticeModal } from "./CreatePracticeModal";
import { CreateWidget } from "./CreateWidget";
import Document from "./Document.web";
import { DuplicateWorkoutSelectAthleteModal } from "./DuplicateWorkoutSelectAthleteModal";
import { EditAthleteProfileTabs } from "./EditAthleteProfileTabs";
import { EditProfileModal } from "./EditProfileModal";
import { Media } from "./Media";
import { ProfileModal } from "./ProfileModal";
import { Questionnaire } from "./Questionnaire";
import { RenameWidget } from "./RenameWidget";
import { ResourceModal } from "./ResourceModal";
import { SaveGroup } from "./SaveGroup";
import { SeenByModal } from "./SeenByModal";
import { SelectAthletesFromGroupModal } from "./SelectAthletesFromGroupModal";
import { SelectSessionGroupModal } from "./SelectSessionGroupModal";
import { SendManualNotificationModal } from "./SendManualNotificationModal";
import { SessionPayment } from "./SessionPayment";
import { Settings } from "./Settings";
import { SwitchAccountsModal } from "./SwitchAccounts/SwitchAccountsModal";
import {
  EXCLUDED_AUTO_CLOSE_MODAL_LIST,
  getMaxWidth,
  getMinWidth,
  MODALS_ALLOWED_ROUTES,
} from "./utils";

interface ModalComponentProps {
  title?: ReactNode;
  children?: ((props) => ReactNode) | ReactNode;
}

export const ModalWrapper = styled.div<{
  isMedia?: boolean;
  center?: boolean;
  smallVerticalOffset?: boolean;
  isTop?: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 0;
  align-items: center;
  z-index: ${({ isTop }) => (isTop ? 100005 : 100000)};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: ${({ smallVerticalOffset }) => (smallVerticalOffset ? 2 : 5)}vh;
  justify-content: ${({ center, isMedia }) =>
    center || isMedia ? "center" : "flex-start"};
  padding-bottom: ${({ smallVerticalOffset }) =>
    smallVerticalOffset ? 2 : 5}vh;
  background-color: ${({ isMedia }) =>
    `rgba(0, 0, 0, ${isMedia ? "0.8" : "0.5"})`};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TitleContainer = styled.div<{
  titleAlignment?: "center" | "flex-start" | "flex-end";
}>`
  display: flex;
  min-height: 45px;
  flex: -1;
  width: auto;
  align-items: center;
  justify-content: ${({ titleAlignment = "center" }) => titleAlignment};
  padding-left: ${({ titleAlignment }) => (titleAlignment ? `30px` : 0)};
  padding-right: ${({ titleAlignment }) => (titleAlignment ? `30px` : 0)};
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_LIGHT_GRAY};
  border-bottom-style: solid;
  z-index: 999;
  padding-right: ${({ titleAlignment }) => (titleAlignment ? 30 : 0)}px;
  padding-left: ${({ titleAlignment }) => (titleAlignment ? 30 : 0)}px;
`;

const MediaCloseButtonWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 3;
`;

export const BlockWithoutScrollBar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 998;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const BottomBarWrapper = styled.div`
  position: sticky;
  bottom: 0;
`;

export const BackButton = styled.button`
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 20px;
  display: flex;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
`;

export const BackButtonLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  font-family: "Inter", sans-serif;
  color: ${COLOR_BLUE};
  margin: 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const CollectionModalWrapper = styled.div<{ screenWidth: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ screenWidth }) => (screenWidth < 1000 ? 90 : 80)}vw;
  min-height: 80vh;
`;

export const getModalWidth = (
  modalType: ModalType | TopModalType,
  uiWidth: number
): { minWidth: string; maxWidth: string } => {
  switch (modalType) {
    case ModalType.CREATE_EVENT_OR_GAME:
    case ModalType.EDIT_USER_PROFILE:
    case ModalType.USER_PROFILE:
      return {
        minWidth: getMinWidth(900),
        maxWidth: getMaxWidth(1200),
      };
    case ModalType.DOCUMENT:
      return {
        minWidth: getMinWidth(600),
        maxWidth: getMaxWidth(1200),
      };
    case ModalType.PROGRAM_ITEM_WORKOUT:
    case TopModalType.PROGRAM_ITEM_WORKOUT:
    case TopModalType.SESSION_DETAIL:
      return { minWidth: getMinWidth(600), maxWidth: getMaxWidth(600) };
    case ModalType.INVITATIONS:
      return { minWidth: getMinWidth(300), maxWidth: getMaxWidth(500) };
    case ModalType.SAVE_GROUP:
    case ModalType.CREATE_NEW_GROUP_CHAT:
    case ModalType.CREATE_NEW_CHAT:
      return { minWidth: getMinWidth(640), maxWidth: getMaxWidth(640) };
    case ModalType.ADD_COACH_OR_ATHLETE_TO_GROUP:
      return { minWidth: getMinWidth(900), maxWidth: getMaxWidth(900) };
    case ModalType.WIDGET_FOLDER_STRUCTURE:
      return { minWidth: getMinWidth(450), maxWidth: getMaxWidth(450) };
    case ModalType.ADD_TO_CALENDAR:
      return { minWidth: getMinWidth(600), maxWidth: getMaxWidth(900) };
    case ModalType.CREATE_PRACTICE_SESSION:
      return { minWidth: "90vw", maxWidth: "90vw" };
  }

  const isConfirmModal =
    modalType === ModalType.CONFIRM || modalType === TopModalType.CONFIRM;

  // TODO: rewrite to switch
  const maxWidth =
    uiWidth < 1000
      ? isConfirmModal
        ? "94vw"
        : "97.5vw"
      : isConfirmModal
        ? "400px"
        : modalType === ModalType.PRINT_GROUP_REPORT ||
            modalType === ModalType.PRINT_ATHLETE_REPORT
          ? "95vw"
          : "80vw";

  const minWidth =
    uiWidth < 1000
      ? isConfirmModal
        ? "94vw"
        : "97.5vw"
      : isConfirmModal
        ? "400px"
        : "40vw";

  return { minWidth, maxWidth };
};

export const getModalHeight = (
  modalType: ModalType | TopModalType
): { minHeight: string; maxHeight: string } => {
  switch (modalType) {
    case ModalType.CREATE_EVENT_OR_GAME:
    case ModalType.CREATE_PRACTICE_SESSION:
      return { minHeight: "96vh", maxHeight: "96vh" };
    case ModalType.USER_PROFILE:
    case ModalType.PROGRAM_ITEM_WORKOUT:
    case ModalType.DOCUMENT:
    case ModalType.ADD_COACH_OR_ATHLETE_TO_GROUP:
    case ModalType.SELECT_SESSION_GROUP:
    case TopModalType.PROGRAM_ITEM_WORKOUT:
    case TopModalType.SESSION_DETAIL:
    case ModalType.CREATE_NEW_GROUP_CHAT:
    case ModalType.WIDGET_FOLDER_STRUCTURE:
      return { minHeight: "90vh", maxHeight: "90vh" };
    case TopModalType.CONFIRM:
    case ModalType.CONFIRM:
      return { minHeight: "260px", maxHeight: "90vh" };
  }

  return { minHeight: "30vh", maxHeight: "90vh" };
};

export const wrapperStyle: any = {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  overflow: "hidden",
  zIndex: 9999,
  shadowColor: COLOR_TEXT_DEFAULT,
  borderRadius: 12,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.2,
  shadowRadius: 6,
};

const ModalComponent: FunctionComponent<ModalComponentProps> = ({
  children,
}) => {
  const {
    open,
    modal,
    params,
    actions,
    style = {},
    title,
    titleAlignment,
    onBack,
  } = useModalContext();
  const { open: topModalOpen } = useTopModalContext();
  const generalModalRef = useRef();
  const { isMobile } = useTheme();
  const ui = useUIContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [modalOriginalPathname, setModalOriginalPathname] = useState(null);

  // close modal on route change
  useEffect(() => {
    if (
      modal &&
      ((MODALS_ALLOWED_ROUTES[modal] &&
        !MODALS_ALLOWED_ROUTES[modal].includes(pathname.substring(1))) ||
        (modalOriginalPathname && pathname !== modalOriginalPathname))
    ) {
      actions.closeModal();
      params?.closeModal?.();
      setModalOriginalPathname(null);
    }
    if (modal && modalOriginalPathname === null) {
      setModalOriginalPathname(pathname);
    }
  }, [modal, pathname, actions, params, modalOriginalPathname]);

  useEffect(() => {
    if (!open) {
      setModalOriginalPathname(null);
    }
  }, [open]);

  const isSmallVerticalOffset =
    [ModalType.CREATE_PRACTICE_SESSION, ModalType.CREATE_EVENT_OR_GAME].indexOf(
      modal
    ) > -1;

  const isMedia = modal === ModalType.MEDIA;

  const handleClickOutsideOfModal = () => {
    if (!EXCLUDED_AUTO_CLOSE_MODAL_LIST.includes(modal) && !topModalOpen) {
      actions.closeModal();
    }
  };

  useHandleClickOutside(
    generalModalRef,
    !topModalOpen ? () => handleClickOutsideOfModal() : undefined
  );

  if (!open) {
    return null;
  }

  const shouldBeVerticallyCentered =
    [
      ModalType.SWITCH_ACCOUNTS,
      ModalType.LOGIN,
      ModalType.FORGOT_PASSWORD,
    ].indexOf(modal) > -1;

  return (
    <ModalWrapper
      isMedia={isMedia}
      center={shouldBeVerticallyCentered}
      smallVerticalOffset={isSmallVerticalOffset}
      isTop={params?.aboveTop}
    >
      {isMedia && (
        <MediaCloseButtonWrapper>
          <IconButton
            icon={
              <CrossIcon
                strokeWidth="1"
                tintColor={COLOR_TEXT_DARK}
                style={{ width: 32, height: 32 }}
              />
            }
            bgColor={COLOR_WHITE}
            activeBgColor={COLOR_MEDIUM_GRAY}
            onClick={actions.closeModal}
          />
        </MediaCloseButtonWrapper>
      )}
      <div
        style={{
          ...wrapperStyle,
          backgroundColor: isMedia ? "transparent" : COLOR_WHITE,
          ...getModalHeight(modal),
          ...getModalWidth(modal, ui.width),
          ...style,
        }}
        ref={generalModalRef}
      >
        <ContentWrapper>
          {!!title && !isMedia && (
            <TitleContainer titleAlignment={titleAlignment}>
              {onBack && (
                <BackButton onClick={onBack}>
                  <NextIcon
                    direction="left"
                    tintColor={COLOR_BLUE}
                    style={{ width: 19, height: 19 }}
                  />{" "}
                  <BackButtonLabel>{t("back").toUpperCase()}</BackButtonLabel>
                </BackButton>
              )}
              {typeof title === "string" ? (
                <StyledText
                  fontSize={14}
                  fontWeight={700}
                  color={COLOR_TEXT_DEFAULT}
                  style={{ lineHeight: "17px" }}
                  cursor="default"
                >
                  {title}
                </StyledText>
              ) : (
                title
              )}
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  padding: 10,
                  ...(isMobile ? { top: 0 } : {}),
                }}
              >
                {!params?.disableCancel && (
                  <IconButton
                    icon={
                      <CrossIcon
                        strokeWidth="1"
                        tintColor={COLOR_TEXT_DARK}
                        style={{ width: 32, height: 32 }}
                      />
                    }
                    activeBgColor={COLOR_MEDIUM_GRAY}
                    onClick={() => {
                      if (params.closeModal) {
                        params.closeModal();
                      } else {
                        actions.closeModal();
                      }
                      params.onShow?.();
                    }}
                  />
                )}
              </div>
            </TitleContainer>
          )}
          {typeof children === "function" ? (
            children({ params, closeModal: actions.closeModal })
          ) : (
            <BlockWithoutScrollBar>
              {(() => {
                switch (modal) {
                  case ModalType.SETTINGS:
                    return <Settings />;
                  case ModalType.CONFIRM:
                    return (
                      <ConfirmationModal
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                  case ModalType.ATTENDANCE:
                    return (
                      <Attendance closeModal={actions.closeModal} {...params} />
                    );
                  case ModalType.CREATE_NEW_CHAT:
                    return (
                      <NewChatProvider
                        defaultGroupForInitGroupName={
                          params?.defaultGroupForInitGroupName
                        }
                        defaultChatState={params?.defaultChatState}
                      >
                        <CreateNewChat
                          closeModal={actions.closeModal}
                          {...params}
                        />
                      </NewChatProvider>
                    );
                  case ModalType.ATHLETES_SELECTOR:
                    return (
                      <AthletesSelector
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                  case ModalType.CREATE_NEW_GROUP_CHAT:
                    return (
                      <NewChatProvider>
                        <CreateNewGroupChat
                          closeModal={actions.closeModal}
                          {...params}
                        />
                      </NewChatProvider>
                    );
                  case ModalType.DOCUMENT:
                  case ModalType.PROGRAM_ITEM_WORKOUT:
                    return (
                      <Document closeModal={actions.closeModal} {...params} />
                    );
                  case ModalType.COLLECTIONS:
                    return (
                      <CollectionModalWrapper screenWidth={ui.width}>
                        <Collections
                          closeModal={actions.closeModal}
                          {...params}
                        />
                      </CollectionModalWrapper>
                    );
                  case ModalType.CREATE_PRACTICE_SESSION:
                    return <CreatePracticeModal {...params} />;
                  case ModalType.RENAME_WIDGET:
                    return <RenameWidget {...params} />;
                  case ModalType.EDIT_ATHLETE_PROFILE_TABS:
                    return <EditAthleteProfileTabs {...params} />;
                  case ModalType.RESOURCE:
                    return <ResourceModal {...params} />;
                  case ModalType.CREATE_WIDGET:
                    return <CreateWidget {...params} />;
                  case ModalType.MEDIA:
                    return <Media {...params} />;
                  case ModalType.ADD_TAB_REPORT_MODAL:
                    return <AddTabReportModal {...params} />;
                  case ModalType.ADD_ATHLETE_HEADER_INFO_MODAL:
                    return <AddAthleteHeaderInfoModal {...params} />;
                  case ModalType.PRINT_GROUP_REPORT:
                    return <PrintGroupReportModal {...params} />;
                  case ModalType.PRINT_ATHLETE_REPORT:
                    return <PrintAthleteReportModal {...params} />;
                  case ModalType.EDIT_USER_PROFILE:
                    return <EditProfileModal {...params} />;
                  case ModalType.USER_PROFILE:
                    return <ProfileModal {...params} />;
                  case ModalType.SEEN_BY:
                    return <SeenByModal {...params} />;
                  case ModalType.EDIT_GROUP_CHAT:
                    return <EditCustomChatGroupModal {...params} />;
                  case ModalType.EDIT_TEAM_CHAT:
                    return <EditTeamChatGroupModal {...params} />;
                  case ModalType.SWITCH_ACCOUNTS:
                    return <SwitchAccountsModal {...params} />;
                  case ModalType.LOGIN:
                    return <LoginScreen inModalView={true} />;
                  case ModalType.FORGOT_PASSWORD:
                    return <ForgotPasswordPage inModalView={true} />;
                  case ModalType.CREATE_EVENT_OR_GAME:
                    return <CreateEventOrGameModal {...params} />;
                  case ModalType.QUESTIONNAIRE:
                    return <Questionnaire {...params} />;
                  case ModalType.COLLECTION_QUESTIONNAIRE:
                    return <CollectionQuestionnaire {...params} />;
                  case ModalType.TERMS:
                    return <TermsModal {...params} />;
                  case ModalType.INVITATIONS:
                    return <InvitationModal {...params} />;
                  case ModalType.SESSION_PAYMENT:
                    return <SessionPayment {...params} />;
                  case ModalType.SAVE_GROUP:
                    return <SaveGroup {...params} />;
                  case ModalType.ADD_COACH_OR_ATHLETE_TO_GROUP:
                    return (
                      <AddCoachOrAthleteToGroupModal
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                  case ModalType.DUPLICATE_WORKOUT_SELECT_ATHLETE:
                    return (
                      <DuplicateWorkoutSelectAthleteModal
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                  case ModalType.SELECT_SESSION_GROUP:
                    return (
                      <SelectSessionGroupModal
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                  case ModalType.SEND_MANUAL_NOTIFICATION:
                    return <SendManualNotificationModal {...params} />;
                  case ModalType.SELECT_ATHLETES_FROM_GROUP:
                    return <SelectAthletesFromGroupModal {...params} />;
                  case ModalType.ADD_TO_CALENDAR:
                    return <AddToCalendar {...params} />;
                  case ModalType.WIDGET_FOLDER_STRUCTURE:
                    return (
                      <FolderStructureModal
                        closeModal={actions.closeModal}
                        {...params}
                      />
                    );
                }
              })()}
            </BlockWithoutScrollBar>
          )}
        </ContentWrapper>
        {params.bottomBar ? (
          <BottomBarWrapper>{params.bottomBar}</BottomBarWrapper>
        ) : null}
      </div>
    </ModalWrapper>
  );
};

export default ModalComponent;
