import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import i18next from "i18next";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../colors";
import Card from "../components/Card";
import StyledText from "../components/StyledText";
import { useUserProfileLazyQuery } from "../graphql";
import { DokobitUser, UserCategory } from "../services/dokobitLogin";
import {
  useDokobitLoginRequest,
  useDokobitWebAuthorizeQuery,
} from "../services/statistics/hooks";
import Storage from "../utils/storage";
import { StorageKeys } from "../utils/storageKeys";

import { useLoginWithUserProfile } from "./LoginScreen/hooks/useLoginWithUserProfile";
import { LoginScreenLayout } from "./LoginScreen/LoginScreenLayout";
import { PinForm } from "./LoginScreen/PinForm";

const SelectAccountWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const ContentWrapper = styled.div`
  width: 322px;
  margin-bottom: 10px;
`;

const UserCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
`;

export function ElectronicIDAuthPage() {
  const { t } = useTranslation();
  const [loadedSessionId, setLoadedSessionId] = useState("");
  const [isExecutingAutoLogIn, setIsExecutingAutoLogIn] = useState(false);
  const [shouldShowPinForm, setShouldShowPinForm] = useState(false);
  const [pinForUser, setPinForUser] = useState(null);
  const [loginError, setLoginError] = useState(undefined);

  const [searchParams] = useSearchParams();
  const userSession = searchParams.get("user_session");
  const sessionToken = searchParams.get("session_token");

  const { data, isLoading, error } = useDokobitWebAuthorizeQuery(
    {
      userSession,
      sessionToken,
    },
    { enabled: !!userSession && !!sessionToken }
  );

  const { mutateAsync: loginUserMutateAsync } = useDokobitLoginRequest();
  const [getUserProfile, { data: userProfileData }] = useUserProfileLazyQuery();

  // const data = { data: { users: MOCKED_USERS } };
  // const isLoading = false;
  // const error = "";

  const onUserLogin = useCallback(
    async (user: DokobitUser, pin?: number) => {
      if (user.usesPin) {
        setLoginError(undefined);
        setPinForUser({ ...user, usesPin: false });
        setShouldShowPinForm(true);
      } else {
        const loggedInUser = await loginUserMutateAsync({
          userSession,
          userGuid: user.guid,
          pin,
        });

        if (
          loggedInUser?.data?._failureTextForUser &&
          !loggedInUser?.data?._sessionId
        ) {
          setLoginError(loggedInUser.data._failureTextForUser);
        } else if (loggedInUser?.data?._sessionId) {
          setLoadedSessionId(loggedInUser.data._sessionId);

          await getUserProfile({
            variables: {
              sessionId: loggedInUser?.data?._sessionId,
              timezone: dayjs.tz.guess(),
              language: i18next.language || "en",
              accessLevelsForDisplayingInGlobalAccountSelector: true,
            },
          });
        }
      }
    },
    [getUserProfile, loginUserMutateAsync, userSession]
  );

  useLoginWithUserProfile({
    userProfileData,
    sessionId: loadedSessionId,
    inModalView: false,
    rememberMe: Storage.getItem(StorageKeys.rememberMe),
  });

  useEffect(() => {
    (async () => {
      if (data?.data?.users?.length === 1 && !isExecutingAutoLogIn) {
        setIsExecutingAutoLogIn(true);
        await onUserLogin(data?.data?.users[0]);
        setIsExecutingAutoLogIn(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.users, onUserLogin]);

  const getUserTypeByUserCategory = (userCategory: string) => {
    switch (userCategory) {
      case UserCategory.Trainers:
        return t("trainer");
      case UserCategory.Athletes:
        return t("athlete");
      case UserCategory.Family:
        return t("familyMember");
      default:
        return userCategory;
    }
  };

  return (
    <LoginScreenLayout
      // We need this due to auto-login of single user - we do not want to see list while useEffect is executed
      isLoading={isLoading || isExecutingAutoLogIn}
      error={(error as string) || loginError}
      inModalView={false}
      isPinFormVisible={shouldShowPinForm}
    >
      <ContentWrapper>
        {shouldShowPinForm ? (
          <PinForm onSubmit={(data) => onUserLogin(pinForUser, data.pin)} />
        ) : (
          <>
            <SelectAccountWrapper>
              <StyledText bold color={COLOR_WHITE} fontSize={17}>
                {t("selectAccount")}
              </StyledText>
            </SelectAccountWrapper>
            {data?.data?.users?.map((user) => (
              <UserCard key={user.guid} onClick={() => onUserLogin(user)}>
                <StyledText bold>{user.username}</StyledText>
                <StyledText bold color={COLOR_BLUE}>
                  {getUserTypeByUserCategory(
                    user.userCategory.toLowerCase()
                  ).toUpperCase()}
                </StyledText>
              </UserCard>
            ))}
          </>
        )}
      </ContentWrapper>
    </LoginScreenLayout>
  );
}
