import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";

import { COLOR_WHITE, COLOR_BLUE, COLOR_MEDIUM_GRAY } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { useUnreadMessageCountQuery } from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { MessagesIcon } from "../../Icons";
import StyledText from "../../StyledText";
import { NavIcon } from "../NavIcon";

interface MessagesNavSideSectionProps {
  isCompact: boolean;
}

const AgendaBadge = styled(StyledText)<{ active: boolean }>`
  border-radius: 20px;
  background-color: ${({ active }) =>
    active ? COLOR_WHITE : COLOR_MEDIUM_GRAY};
  font-size: 12px;
  font-weight: bold;
  color: ${({ active }) => (active ? COLOR_BLUE : COLOR_WHITE)};
  text-align: center;
  padding: 3px;
  height: 21px;
  min-width: 35px;
  white-space: nowrap;
`;

export const MessagesNavSideSection = ({
  isCompact,
}: MessagesNavSideSectionProps) => {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { sessionId, timezone, language } = useUserContext();

  const { data, refetch: refetchUnreadMessagesCount } =
    useUnreadMessageCountQuery({
      variables: { sessionId, language, timezone },
    });

  useEffect(() => {
    refetchUnreadMessagesCount();
  }, [pathname, search, refetchUnreadMessagesCount]);

  const navigateToMessages = useCallback(
    () => navigate(`/${ROUTING_CONFIG.messages}`),
    [navigate]
  );

  const isActive = Boolean(matchPath(ROUTING_CONFIG.messages, pathname));

  const hasUnreadMessages = !!data?.messageOverview?.unreadAll;

  return (
    <NavIcon
      active={isActive}
      isCompact={isCompact}
      text={t("messages")}
      onClick={navigateToMessages}
      iconSvg={
        <MessagesIcon isActive={isActive} isCircleActive={hasUnreadMessages} />
      }
    >
      {({ active }) =>
        data?.messageOverview?.unreadAll &&
        data.messageOverview.unreadAll > 0 ? (
          <AgendaBadge active={active}>
            {data.messageOverview.unreadAll ?? ""}
          </AgendaBadge>
        ) : null
      }
    </NavIcon>
  );
};
