import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../colors";
import { Loader, LoaderWrapper } from "../Loader";
import { PracticeLines } from "../Practice/PracticeLines";
import { SessionLines } from "../SessionPlan/SessionLines";

import {
  SessionType,
  useSessionDetailQuery,
} from "./hooks/useSessionDetailQuery";
import { getPracticeWithDrillTimes } from "./utils";

type SessionPlanProps = {
  sessionType: SessionType;
  sessionGuid: string;
};

const SessionPlanWrapper = styled.div<{ isWorkout?: boolean }>`
  max-width: ${({ isWorkout }) => (isWorkout ? 500 : 800)}px;
  padding: 10px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const EmptyLabel = styled.div`
  color: ${COLOR_TEXT_DEFAULT};
  font-size: 20px;
  text-align: center;
  white-space: nowrap;
  font-weight: 700;
  padding: 15px 0;
`;

export function SessionPlan({ sessionType, sessionGuid }: SessionPlanProps) {
  const { t } = useTranslation();
  const { data, loading } = useSessionDetailQuery({
    sessionType,
    sessionGuid,
  });

  const practiceData = data && "practice" in data && data?.practice;

  const updatedPracticeData = useMemo(() => {
    if (!loading && practiceData) {
      return getPracticeWithDrillTimes(practiceData, practiceData.start);
    }
  }, [loading, practiceData]);

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const hasWorkoutLines =
    data && "workout" in data && data?.workout?.workoutLines;
  const hasPracticeLines =
    data && "practice" in data && data?.practice?.practiceLines;

  if (
    (hasWorkoutLines && data?.workout?.workoutLines?.length === 0) ||
    (hasPracticeLines && data?.practice?.practiceLines?.length === 0)
  ) {
    return (
      <EmptyWrapper>
        <EmptyLabel>{t("noDrills")}</EmptyLabel>
      </EmptyWrapper>
    );
  }

  return (
    <SessionPlanWrapper isWorkout={Boolean(hasWorkoutLines)}>
      {hasWorkoutLines ? (
        <SessionLines data={data.workout.workoutLines} hideActionButtons />
      ) : null}
      {hasPracticeLines ? (
        <PracticeLines lines={updatedPracticeData.practiceLines} />
      ) : null}
    </SessionPlanWrapper>
  );
}
