import { Dayjs } from "dayjs";

import { AggregateFunction } from "../../../../../graphql";
import { getValidUnitName } from "../../../../../utils/statistics";
import { StatisticsDataItem } from "../../../../Report/models";

import { TREND_LENGTH } from "./constants";

const datesAreOnSameDay = (first: Date, second: Date): boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export const prepareTrendData = (
  dataForColumn: StatisticsDataItem[],
  toDate: Dayjs,
  unitName = "",
  attributeId: string,
  attributeName = "",
  min = NaN,
  max = NaN
) => {
  const stats =
    dataForColumn?.filter(
      (data) =>
        data.attribute.id === attributeId &&
        data.aggregateFunction === AggregateFunction.Each
    ) ?? [];

  const daysArray = new Array(TREND_LENGTH).fill(undefined);

  if (stats.length === 0) {
    return daysArray.map((_, index) => ({
      value: NaN,
      percentageOfMax: 0,
      date: new Date(
        toDate.toDate().setDate(toDate.toDate().getDate() - (index + 1))
      ),
      unitName,
      attributeName,
    }));
  }

  return stats.flatMap((attribute) => {
    const localMaximum = (
      attribute.values.length ? attribute.values : [{ val: 0 }]
    ).reduce((prev, current) => (prev.val > current.val ? prev : current));

    return daysArray.map((_, index) => {
      const dateOfToDate = toDate.toDate();
      // We need to add +1 because getDate returns next date because we want to take all data till midnight
      const dateOfTrend = new Date(
        dateOfToDate.setDate(
          dateOfToDate.getDate() - (TREND_LENGTH - index) + 1
        )
      );
      const trendOfDay = attribute.values.find((value) =>
        datesAreOnSameDay(new Date(value.time), dateOfTrend)
      );

      const intervalSize =
        !isNaN(min) && !isNaN(max) && min !== undefined && max !== undefined
          ? max - min + 1
          : localMaximum.val;

      return {
        value: trendOfDay ? trendOfDay.val : NaN,
        percentageOfMax: trendOfDay ? trendOfDay.val / intervalSize : 0,
        date: dateOfTrend,
        unitName: getValidUnitName(unitName),
        attributeName,
        color: trendOfDay?.rgb,
        txt: trendOfDay?.txt,
        measurementTemplateId: attribute.template?.id,
        attributeId: attribute.attribute?.id,
      };
    });
  });
};
