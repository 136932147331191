import React, { useMemo, useCallback, useEffect } from "react";
import { Calendar, Views, dayjsLocalizer } from "react-big-calendar";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { usePracticeContext } from "../contexts/practice/PracticeProvider";
import { useResourceCalendarContext } from "../contexts/resourceCalendar/ResourceCalendarProvider";
import { useSettingsContext } from "../contexts/settingsContext";
import { useUIContext } from "../contexts/UI";
import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import { useResourceCalendarQuery, useResourceGroupsQuery } from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { StyledCard } from "../StyledCard";
import { isTvEnv } from "../utils/isTvEnv";
import Storage from "../utils/storage";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../components/Calendar/customCalendar.css";
import "../components/Calendar/customResourceCalendar.css";
import { StorageKeys } from "../utils/storageKeys";

import { CustomResourceEvent } from "./Calendar/model";
import { getModifiedEvent, isTomorrow } from "./Calendar/utils";
import { Loader } from "./Loader";
import { CALENDAR_COMPONENTS } from "./ResourceCalendarComponents";
import StyledText from "./StyledText";

const ONE_MINUTE = 60000;
const localizer = dayjsLocalizer(dayjs);

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const EmptyResourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const EmptyResource = styled(StyledText)`
  margin-bottom: 10px;
  line-height: 24px;
  font-size: 20px;
  color: ${COLOR_TEXT_DARK};
`;

const Account = styled(StyledText)`
  line-height: 24px;
  font-size: 20px;
  color: ${COLOR_BLUE};
`;

const CalendarCard = styled(StyledCard)`
  height: 100%;
  position: relative;
  margin: 10px;
  border: 1px solid rgba(230, 232, 235, 0.35);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 12px;
  overflow-y: hidden;
`;

const fiveMinutes = 300000;

export function ResourceCalendar() {
  const modal = useModalContext();
  const navigate = useNavigate();
  const isTv = isTvEnv();
  const { embed } = useUIContext();
  const { t } = useTranslation();
  const { timeFormatTemplate } = useSettingsContext();
  const { sessionId, timezone } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();
  const { setSelectedSlot } = usePracticeContext();

  const {
    toDate,
    fromDate,
    resourceList,
    preferenceData,
    selectedDay,
    onSetDay,
    originalResourceGroupList,
    loadingResourceGroupsData,
    loadingSelectedResources,
    onSelectResource,
  } = useResourceCalendarContext();

  const { startPolling: startPollingResourceGroups } = useResourceGroupsQuery({
    variables: {
      sessionId,
      club: selectedAccount?.id,
    },
    skip: !sessionId || !selectedAccount?.id,
    fetchPolicy: "cache-first",
  });

  const {
    data: resourceCalendarData,
    startPolling: startPollingResourceCalendar,
    refetch: refetchResourceCalendarData,
  } = useResourceCalendarQuery({
    variables: {
      sessionId,
      timezone: timezone || dayjs.tz.guess(),
      from: fromDate,
      to: toDate,
    },
    skip: !sessionId || !fromDate || !toDate,
  });

  const { visiblePractices, visibleGames, visibleEvents, inaccessible } =
    resourceCalendarData?.resourceCalendar || {};

  const eventList = useMemo(
    () => [
      ...getModifiedEvent(visiblePractices),
      ...getModifiedEvent(visibleGames),
      ...getModifiedEvent(visibleEvents),
      ...getModifiedEvent(inaccessible),
    ],
    [visiblePractices, visibleGames, visibleEvents, inaccessible]
  );

  const eventListStart = useMemo(
    () =>
      eventList?.map((event) =>
        event.start.getTime() < fromDate ? 0 : event.start.getHours()
      ),
    [eventList, fromDate]
  );

  useEffect(
    function setDateAccordingToPreviousUsage() {
      const storedDate = Storage.getItem(StorageKeys.selectedDateResource);

      if (storedDate) {
        onSetDay(dayjs(storedDate as Date));
      } else {
        onSetDay(dayjs(new Date()));
      }
    },
    [onSetDay]
  );

  const soonestStart = Math.min(...eventListStart, 6);
  const startOfDay = new Date(0, 0, 0, soonestStart, 0, 0);

  useEffect(
    function initiatePooling() {
      startPollingResourceCalendar(fiveMinutes);
      startPollingResourceGroups(fiveMinutes);
    },
    [startPollingResourceCalendar, startPollingResourceGroups]
  );

  useEffect(
    function setNewDateAfterMidnightOnTv() {
      if (isTv) {
        const timer = setInterval(() => {
          const alreadyTomorrow = isTomorrow(selectedDay.toDate());

          if (alreadyTomorrow) {
            onSetDay(dayjs(new Date()));
          }
        }, ONE_MINUTE);

        return () => {
          clearInterval(timer);
        };
      }
    },
    [selectedDay, isTv, onSetDay]
  );

  useEffect(() => {
    if (selectedAccount?.id === ALL_ACCOUNTS_ID) {
      navigate(`/${ROUTING_CONFIG.calendar}`);
    }
  }, [navigate, selectedAccount?.id]);

  const onSelectSlot = (slot) => {
    if (slot.action === "doubleClick") {
      setSelectedSlot(slot);
      onSelectResource(slot.resourceId);
      modal.actions.openModal({
        modal: ModalType.CREATE_PRACTICE_SESSION,
        title: "",
      });
    }
  };

  const onSelectEvent = useCallback(
    (event: CustomResourceEvent) => {
      if (isTv) {
        return null;
      }

      if (embed && event.selectedGroupId && event.eventId) {
        window.location.href = `http://open.xps.teamsession.sidelinesports.com/${event.selectedGroupId}/${event.eventId}`;
        window["refetchResourceCalendarData"] = refetchResourceCalendarData;
        return;
      }

      if (event.type !== "InaccessibleSession") {
        Storage.setItem(StorageKeys.selectedDateResource, selectedDay);
        navigate({
          pathname: `/${ROUTING_CONFIG.session}`,
          search: `?sessionGuid=${event.eventId}&sessionType=${event.type}`,
        });
      }
    },
    [navigate, selectedDay, embed, isTv, refetchResourceCalendarData]
  );

  const onNavigate = useCallback(
    (date: Date) => {
      const dayjsDate = dayjs(date);

      Storage.setItem(StorageKeys.selectedDateResource, dayjsDate);
      onSetDay(dayjsDate);
    },
    [onSetDay]
  );

  if (
    loadingSelectedResources ||
    (!resourceList?.length &&
      (loadingResourceGroupsData || !preferenceData || !selectedAccount))
  ) {
    return (
      <LoaderWrapper>
        <Loader size="large" />
      </LoaderWrapper>
    );
  }

  if (!originalResourceGroupList?.length && !loadingResourceGroupsData) {
    return (
      <EmptyResourceWrapper>
        <EmptyResource>
          <Trans
            t={t}
            i18nKey="noResourceFor"
            values={{ x: selectedAccount?.name }}
            components={{ account: <Account /> }}
          />
        </EmptyResource>
      </EmptyResourceWrapper>
    );
  }

  return (
    <CalendarCard>
      <Calendar
        key={soonestStart}
        selectable={false}
        components={CALENDAR_COMPONENTS}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        date={selectedDay}
        onNavigate={onNavigate}
        formats={{
          eventTimeRangeFormat: () => null,
          eventTimeRangeStartFormat: () => null,
          eventTimeRangeEndFormat: () => null,
          timeGutterFormat: (date: Date) =>
            dayjs(date).format(timeFormatTemplate),
        }}
        scrollToTime={startOfDay}
        slotPropGetter={(date) => {
          const hour = dayjs(date).get("hour");
          const outsideMainTime = hour < 6 || hour >= 23;

          return {
            style: {
              backgroundColor: outsideMainTime ? "#f1f1f1" : "none",
            },
          };
        }}
        defaultView={Views.DAY}
        events={eventList}
        localizer={localizer}
        resources={resourceList}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        // Due to events ending after midnight
        showMultiDayTimes
      />
    </CalendarCard>
  );
}
