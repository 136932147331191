import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE } from "../../../colors";
import Avatar from "../../Avatar";
import { CheckmarkRoundedDoneIcon } from "../../Icons";
import StyledText from "../../StyledText";

const SwitchAccountButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const LeftContentWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const LeftContentTextWrapper = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const NameWrapper = styled.div`
  margin-bottom: 5px;
`;

export const SwitchAccountDialogItem = ({
  onClick,
  fullName,
  isFamilyMember,
  isTrainer,
  isLoggedIn,
  userGuid,
  sessionId,
}) => {
  const { t } = useTranslation();

  return (
    <SwitchAccountButton onClick={onClick} disabled={isLoggedIn}>
      <ItemWrapper>
        <LeftContentWrapper>
          <Avatar
            userGuid={userGuid}
            isThumb
            userName={fullName}
            size={35}
            userSessionId={sessionId}
          />
          <LeftContentTextWrapper>
            <NameWrapper>
              <StyledText fontWeight="bold" fontSize={14}>
                {fullName}
              </StyledText>
            </NameWrapper>
            <StyledText fontWeight="bold" fontSize={10} color={COLOR_BLUE}>
              {t(
                isFamilyMember
                  ? "familyMember"
                  : isTrainer
                    ? "trainer"
                    : "athlete"
              ).toUpperCase()}
            </StyledText>
          </LeftContentTextWrapper>
        </LeftContentWrapper>
        {isLoggedIn && <CheckmarkRoundedDoneIcon />}
      </ItemWrapper>
    </SwitchAccountButton>
  );
};
