import gql from "graphql-tag";

export const ExerciseSets = gql`
  query ExerciseSets(
    $sessionId: String
    $timezone: String!
    $language: String
    $id: ID!
  ) {
    exercise(
      sessionId: $sessionId
      timezone: $timezone
      language: $language
      id: $id
    ) {
      id
      attributeTemplates {
        id
        name
        unitName
        isTime
        isWeight
      }
      sets {
        id
        deleted
        cells {
          id
          valuePrescribed
          valuePerformed
          options
        }
      }
    }
  }
`;
