import { TFunction } from "react-i18next";

export const getSortingOptions = (
  t: TFunction<"common">,
  showEmpty = false
) => {
  const baseOptions = [
    { label: t("ascending"), value: 1 },
    { label: t("descending"), value: -1 },
  ];

  return showEmpty
    ? [{ label: t("clearSelection"), value: undefined }, ...baseOptions]
    : baseOptions;
};
