import { LazyQueryResult } from "@apollo/client";

import { ReportBuildingAttributeTemplatesQuery } from "../../../../../graphql";
import {
  isAcuteChronicCollection,
  isSoccerStatsCollection,
  isSumAttribute,
} from "../../../../../utils/reports";

import { COMPARISON_AGGREGATION_LABEL } from "./constants";

export const shouldReturnAggregationName = (
  attributeId: string,
  aggregation: string
) =>
  !isSumAttribute(attributeId) || aggregation === COMPARISON_AGGREGATION_LABEL;

export const shouldReturnAttributeName = (
  attributes: LazyQueryResult<ReportBuildingAttributeTemplatesQuery, unknown>,
  collectionId: string
) =>
  attributes?.data?.reportBuildingAttributeTemplates?.length > 1 ||
  isAcuteChronicCollection(collectionId) ||
  isSoccerStatsCollection(collectionId);
