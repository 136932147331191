import React, { useMemo } from "react";
import { useDrag } from "react-dnd";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK, COLOR_WHITE } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { Document } from "../../../graphql";
import { handleSingleDoubleClick } from "../../../utils/handleSingleDoubleClick";
import { DocumentIcon, DrillIcon, StarIcon } from "../../Icons";
import { rgbToHex } from "../utils";

import { ItemTypes } from "./model";

export type FileType = Pick<
  Document,
  "modularContentUrl" | "url" | "color" | "name" | "id" | "jsonUrl"
>;

type FileItemProps = {
  file: FileType;
  collectionId: string;
  isFavorite?: boolean;
  isSelected?: boolean;
  onSingleClick?: (file: FileType) => void;
  onDoubleClick?: (file: FileType) => void;
};

const File = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const FileName = styled.div<{ isSelected?: boolean; color?: string }>`
  padding: 5px;
  color: ${({ color, isSelected }) =>
    isSelected
      ? COLOR_WHITE
      : color
        ? color?.includes(",")
          ? rgbToHex(color)
          : color
        : COLOR_TEXT_DARK};
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
  border-radius: ${({ isSelected }) => (isSelected ? "3px" : "0px")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
`;

export function FileItem({
  file,
  collectionId,
  isFavorite,
  isSelected,
  onSingleClick,
  onDoubleClick,
}: FileItemProps) {
  const { setPracticeLines, practiceLines } = usePracticeContext();
  const { actions: topModalActions } = useTopModalContext();

  const [, drag] = useDrag(() => ({
    type: ItemTypes.FILE,
    options: {
      dropEffect: "copy",
    },
    item: { file, collectionId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const onFileDoubleClick =
    onDoubleClick ??
    ((file: FileType) =>
      setPracticeLines([...practiceLines, { ...file, localDuration: 10 }]));

  const isMediaFile = useMemo(() => {
    const urlIncludes = [".pdf"];
    return (
      file?.modularContentUrl ||
      urlIncludes.some((option) => file?.url.includes(option))
    );
  }, [file]);

  const onFileSingleClick =
    onSingleClick ??
    ((file: FileType) => {
      //@ts-ignore
      if (file.url || file.jsonUrl || file.mcUrl) {
        topModalActions.openTopModal({
          title: file.name,
          modal: TopModalType.DOCUMENT,
          params: {
            document: {
              ...file,
              mcUrl: file.modularContentUrl,
              itemName: file.name,
            },
          },
        });
      }
    });

  return (
    <File
      ref={drag}
      title={file.name}
      onClick={() =>
        handleSingleDoubleClick(
          () => onFileSingleClick(file),
          () => onFileDoubleClick(file)
        )
      }
    >
      {isFavorite ? (
        <StarIcon isActive width={18} style={{ marginLeft: -2 }} />
      ) : isMediaFile ? (
        <DrillIcon />
      ) : (
        <DocumentIcon />
      )}
      <FileName isSelected={isSelected} color={file?.color}>
        {file.name}
      </FileName>
    </File>
  );
}
