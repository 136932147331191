import React from "react";
import { useTranslation } from "react-i18next";

import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { Avatar, Chatter } from "../../../graphql";
import Hoverable from "../../Hoverable";
import { EditPencilIcon } from "../../Icons";
import { StyledOptionItem } from "../../Report/styled";
import { RoundIcon } from "../../RoundIcon";
import StyledText from "../../StyledText";

interface EditMembersItemProps {
  avatars: Avatar[];
  contact: Chatter;
  onRowPress: (contact: Chatter) => void;
  messageListRefetch: () => void;
}
export const EditChatOption = ({
  avatars,
  contact,
  onRowPress,
  messageListRefetch,
}: EditMembersItemProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();
  const isAnnouncement = !!contact?.allFriendsOf?.id;

  return (
    <Hoverable>
      {(isHovered) => (
        <StyledOptionItem
          isHovered={isHovered}
          height={45}
          onClick={async () => {
            if (contact.linkedGroup || isAnnouncement) {
              // REAL GROUP
              modalActions.openModal({
                modal: ModalType.EDIT_TEAM_CHAT,
                title: t("editChatGroup"),
                params: { contact, avatars, onRowPress },
              });
            } else {
              modalActions.openModal({
                modal: ModalType.EDIT_GROUP_CHAT,
                title: t("editChatGroup"),
                params: { contact, avatars, onRowPress, messageListRefetch },
              });
              // CUSTOM GROUP
            }
          }}
        >
          <RoundIcon svgIcon={<EditPencilIcon />} isHovered={isHovered} />
          <StyledText
            fontWeight="bold"
            fontSize={14}
            numberOfLines={1}
            style={{ maxWidth: 200 }}
          >
            {t("edit")}
          </StyledText>
        </StyledOptionItem>
      )}
    </Hoverable>
  );
};
