import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import StyledText from "../StyledText";
import { Tab } from "../Tabs";

import { HoverablePlusIconButton } from "./HoverablePlusIconButton";

const AddReportMessageWrapper = styled(StyledText)`
  background-color: ${COLOR_WHITE};
  height: 35px;
  padding: 10px 17px;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;

  border-width: 1px;
  border-style: solid;
  border-color: ${COLOR_GREY_ACTIVE};
  border-left-width: 0px;
  border-top-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 6px;

  box-shadow: 0px 2px 6px rgba(34, 62, 106, 0.05);
`;

const AddReportMessageOuterArrow = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 0;
  height: 0;

  border-top-width: 17.5px;
  border-top-style: solid;
  border-top-color: transparent;
  border-bottom-width: 17.5px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  border-right-width: 17.5px;
  border-right-style: solid;
  border-right-color: ${COLOR_GREY_ACTIVE};

  margin-left: 8px;
`;

const AddReportMessageInnerArrow = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 0;
  height: 0;
  border-top-width: 16.5px;
  border-top-style: solid;
  border-top-color: transparent;
  border-bottom-width: 16.5px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  border-right-width: 16.5px;
  border-right-style: solid;
  border-right-color: ${COLOR_WHITE};

  top: -16.5px;
  right: -1px;
`;

const AddReportWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: ${({ theme }) => (!theme.isDesktop ? "flex-end" : "")};
  margin-bottom: 5px;
  height: 30px;
`;

interface SelectReportsToShowButtonProps {
  configureTabs: () => void;
  groupTabs: (Tab & {
    isShared: boolean;
    isVisible: boolean;
  })[];
}

export const SelectReportsToShowButton = ({
  groupTabs,
  configureTabs,
}: SelectReportsToShowButtonProps) => {
  const { t } = useTranslation();
  const canEdit = useCanEditStatistics();

  return canEdit ? (
    <AddReportWrapper>
      <HoverablePlusIconButton onClick={configureTabs} />
      {groupTabs.length === 0 && (
        <>
          <AddReportMessageOuterArrow>
            <AddReportMessageInnerArrow />
          </AddReportMessageOuterArrow>
          <AddReportMessageWrapper>
            {t("selectReportsToShow")}
          </AddReportMessageWrapper>
        </>
      )}
    </AddReportWrapper>
  ) : null;
};
