import React from "react";

import _round from "lodash/round";

import { FetchJsonCombinedTestResultsResponse } from "../../../../services";
import { roundToTwo } from "../../../../utils/number";
import StyledText from "../../../StyledText";

import { CircleProgress } from "./CircleProgress";
import {
  CombinedTestCircleChartResult,
  CombinedTestCircleChartResultTitle,
  CombinedTestCircleChartSubresult,
  CombinedTestCircleChartSubresults,
  CombinedTestCircleChartWrapper,
  CombinedTestCircleTextWrapper,
} from "./styled";

type CombinedTestCircleChartProps = {
  widgetName: string;
  visibleSubTests: string[];
  data: {
    _theAthlete?: FetchJsonCombinedTestResultsResponse;
  };
  showDiffs: boolean;
  widgetWidth: number;
};

const WIDTH_FOR_SMALL_DESKTOP = 560;
const WIDTH_FOR_BIG_DESKTOP = 890;
const LIMIT_FOR_ROUNDING_PROGRESS = 20;

export function CombinedTestCircleChart({
  widgetName,
  visibleSubTests,
  data,
  showDiffs,
  widgetWidth,
}: CombinedTestCircleChartProps) {
  const result = data?._theAthlete?._resultsByAthlete?.[0]?._results?.[0];
  const totalScoreIsSum = data?._theAthlete?._totalScoreIsSum;

  const minScore = result?._min ?? -100;
  const maxScore = result?._max ?? 100;
  const progress = mapScoreToProgress(minScore, maxScore, result?._score ?? 0);

  function getCircleProgressColor(max?: number, score?: number) {
    const percentage = (score / max) * 100;
    if (percentage >= 50) {
      return "35CC00";
    }
    if (percentage < 50 && percentage >= 25) {
      return "FCC400";
    }
    return "FB4C48";
  }

  const subResultsContainerWidth =
    widgetWidth >= WIDTH_FOR_BIG_DESKTOP
      ? WIDTH_FOR_BIG_DESKTOP
      : WIDTH_FOR_SMALL_DESKTOP;

  const totalProgress = totalScoreIsSum ? result?._scoreAbsolute : progress;

  return (
    <CombinedTestCircleChartWrapper width={subResultsContainerWidth}>
      <CombinedTestCircleChartResult>
        <CircleProgress
          color={
            result?._rgb ??
            getCircleProgressColor(result?._maxAbsolute, result?._scoreAbsolute)
          }
          useColorForText={true}
          isHalf={true}
          size={160}
          radius={65}
          progress={_round(result?._score, 1)}
          text={_round(
            totalProgress,
            totalProgress < LIMIT_FOR_ROUNDING_PROGRESS ? 1 : 0
          )}
          strokeWidth={25}
          diff={showDiffs ? roundToTwo(result?._diffAbsolute) : undefined}
          textSize={45}
        />
        <CombinedTestCircleChartResultTitle>
          {widgetName}
        </CombinedTestCircleChartResultTitle>
      </CombinedTestCircleChartResult>
      <CombinedTestCircleChartSubresults width={subResultsContainerWidth}>
        {(result?._subResults ?? [])
          .filter((subResult) => visibleSubTests.includes(subResult._template))
          .map((subResult, index) => {
            const score = subResult._score;
            const progress = mapScoreToProgress(
              subResult._min,
              subResult._max,
              score
            );

            return (
              <CombinedTestCircleChartSubresult
                key={subResult._template}
                style={index < 3 ? { marginTop: 0 } : {}}
                width={widgetWidth}
              >
                <CircleProgress
                  color={
                    subResult._rgb ??
                    getCircleProgressColor(subResult._max, subResult._score)
                  }
                  size={64}
                  radius={28}
                  progress={_round(progress, 1)}
                  strokeWidth={7}
                  text={_round(score, 1)}
                />
                <CombinedTestCircleTextWrapper>
                  <StyledText
                    style={{ textAlign: "center" }}
                    fontWeight="normal"
                    fontSize={12}
                  >
                    {subResult._name}
                  </StyledText>
                </CombinedTestCircleTextWrapper>
              </CombinedTestCircleChartSubresult>
            );
          })}
      </CombinedTestCircleChartSubresults>
    </CombinedTestCircleChartWrapper>
  );
}

function mapScoreToProgress(min: number, max: number, score: number) {
  const outMax = 100;
  const outMin = -100;

  if (!score && score !== 0) {
    return 0;
  }

  return (outMax - outMin) * ((score - min) / (max - min)) + outMin;
}
