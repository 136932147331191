import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { COLOR_GREEN_ACTIVE, COLOR_WHITE } from "../../colors";
import { useAgendaDataContext } from "../../contexts/agenda/AgendaDataProvider";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";

import { LeftSection } from "./components/LeftSection";
import { RightSection } from "./components/RightSection";
import { AgendaSession, RefetchAgendaFunc } from "./model";

const ItemContainer = styled.div`
  z-index: 0;
  &:hover {
    z-index: 1;
    cursor: pointer;
  }
`;

const Item = styled.div<{ cancelled: boolean }>`
  display: flex;
  background-color: ${COLOR_WHITE};
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
  flex-direction: row;
  border-radius: 6px;
  padding: 0;
  margin-bottom: 5px;
  position: relative;
  ${({ cancelled }) => (cancelled ? "opacity: 0.4;" : "opacity: 1;")}
`;

const Stripe = styled.div<{ rgb: string; cancelled: boolean }>`
  display: flex;
  width: 5px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  background-color: ${({ rgb, cancelled }) =>
    cancelled ? "rgb(177, 183, 188)" : rgb};
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 0;
`;

interface AgendaItemProps {
  refetch: RefetchAgendaFunc;
  session: AgendaSession;
}

const AgendaItem = ({ session, refetch }: AgendaItemProps) => {
  const navigate = useNavigate();
  const modal = useModalContext();
  const { setLastScrollPosition } = useAgendaDataContext();

  const onItemClick = useCallback(async () => {
    const openQuestionnaireModal = () => {
      modal.actions.openModal({
        modal: ModalType.QUESTIONNAIRE,
        title: session.name,
        params: {
          questionnaireId: session.id,
          refetch,
        },
      });
    };
    if (session.__typename === "Questionnaire") {
      return openQuestionnaireModal();
    }

    if (window.location.pathname === `/${ROUTING_CONFIG.calendar}`) {
      const agendaWrapperRef = document.querySelector("#agenda-wrapper");
      if (agendaWrapperRef) {
        setLastScrollPosition(agendaWrapperRef.scrollTop);
      }
    }

    const timeout = setTimeout(() => {
      navigate({
        pathname: `/${ROUTING_CONFIG.session}`,
        search: `?sessionGuid=${session.id}&sessionType=${session.__typename}`,
      });
      return () => clearTimeout(timeout);
    }, 50);
  }, [
    modal.actions,
    navigate,
    refetch,
    session.__typename,
    session.id,
    session.name,
    setLastScrollPosition,
  ]);

  return (
    <ItemContainer key={session.id} onClick={onItemClick}>
      <Item cancelled={session.cancelled}>
        <Stripe
          cancelled={session.cancelled}
          // We need special handling of isGreenInCalendar due to questionnaires
          rgb={
            session &&
            "isGreenInCalendar" in session &&
            session.isGreenInCalendar
              ? COLOR_GREEN_ACTIVE
              : `rgb(${session.rgb})`
          }
        />
        <LeftSection {...session} />
        <RightSection refetch={refetch} session={session} />
      </Item>
    </ItemContainer>
  );
};

export default AgendaItem;
