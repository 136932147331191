const BODY_TORSO_WIDTH = 185;
const BODY_TORSO_HEIGHT = 400;
const BODY_TORSO_SCALE = 0.5;

const MOCKED_REGISTRATIONS = {
  64: {
    categoryId: "-5387016198409018467",
    categoryNum: 2,
    color: "rgb(255, 0, 0)",
  },

  67: {
    categoryId: "-5387016198409018467",
    categoryNum: 2,
    color: "rgb(255, 0, 0)",
  },
  115: {
    categoryId: "-5387016198409018467",
    categoryNum: 2,
    color: "rgb(255, 0, 0)",
  },
  116: {
    categoryId: "-5387016198409018467",
    categoryNum: 2,
    color: "rgb(255, 0, 0)",
  },
};

export {
  BODY_TORSO_WIDTH,
  BODY_TORSO_HEIGHT,
  BODY_TORSO_SCALE,
  MOCKED_REGISTRATIONS,
};
