import React from "react";

import { COLOR_BLUE } from "../../colors";
import Hoverable from "../../components/Hoverable";
import {
  LaptopAppleIcon,
  LaptopWinIcon,
  NextIcon,
} from "../../components/Icons";

import {
  SectionBox,
  RightSectionBoxWrapper,
  RightSectionRowWrapper,
  RightSectionColumnWrapper,
  RightSectionTitle,
  RightSectionText,
  RightSectionIconWrapper,
} from "./styles";

export const RightSectionBox = ({
  title,
  text,
  withSpecialIcon = false,
  icon,
  url,
}) => {
  const isWindows = navigator.platform.indexOf("Win") !== -1;
  return (
    <Hoverable>
      {(isHovered) => (
        <SectionBox
          isHovered={isHovered}
          withoutPadding={true}
          onClick={() => window.open(url, "_blank")}
        >
          <RightSectionBoxWrapper>
            <RightSectionIconWrapper>
              {withSpecialIcon ? (
                isWindows ? (
                  <LaptopWinIcon width={55} height={31} />
                ) : (
                  <LaptopAppleIcon width={55} height={31} />
                )
              ) : (
                icon
              )}
            </RightSectionIconWrapper>
            <RightSectionRowWrapper>
              <RightSectionColumnWrapper>
                <RightSectionTitle>{title}</RightSectionTitle>
                <RightSectionText>{text}</RightSectionText>
              </RightSectionColumnWrapper>
              <NextIcon
                color={COLOR_BLUE}
                style={{
                  minWidth: "21px",
                  padding: "30px",
                  alignSelf: "center",
                }}
              />
            </RightSectionRowWrapper>
          </RightSectionBoxWrapper>
        </SectionBox>
      )}
    </Hoverable>
  );
};
