import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { COLOR_GRAY } from "../../colors";
import { ControlledInput } from "../HookForm/ControlledInput";

export function ResetPasswordForm({ formMethods }) {
  const { t } = useTranslation();

  const isPasswordValid = (value: string) => {
    if (!/^.*[a-zA-Z]+.*$/.test(value)) {
      return t("passwordContainLetter");
    }

    if (!/^.*\d.*$/.test(value)) {
      return t("passwordContainNumber");
    }

    return null;
  };

  const areEqual = (value: string) =>
    value === formMethods?.getValues("newPassword") || t("passwordsNotMatched");

  return (
    <FormProvider {...formMethods}>
      <ControlledInput
        labelColor={COLOR_GRAY}
        type="password"
        name="newPassword"
        label={t("newPassword")}
        rules={{
          required: t("newPasswordRequired"),
          minLength: {
            value: 8,
            message: t("passwordLength"),
          },
          validate: isPasswordValid,
        }}
      />
      <ControlledInput
        labelColor={COLOR_GRAY}
        type="password"
        name="confirmPassword"
        label={t("confirmPassword")}
        rules={{
          validate: {
            areEqual,
          },
        }}
      />
    </FormProvider>
  );
}
