import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AnnouncementIcon = memo(
  ({ width = 45, height = 45, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E6E8EB" />
      <circle
        cx="22.5"
        cy="22.5"
        r="22.25"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
      <path
        d="M11 17C11 16.4477 11.4477 16 12 16H18V25H12C11.4477 25 11 24.5523 11 24V17Z"
        fill="#B1B7BC"
      />
      <path
        d="M32 11C27.6923 15.4706 24.3846 16 19 16V25C24.3846 25 27.6923 25.5294 32 30V11Z"
        fill="#B1B7BC"
      />
      <path d="M15 34L13 25H16.5L18.5 34H15Z" fill="#B1B7BC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 23.6632C34.1825 23.1014 35 21.8962 35 20.5C35 19.1038 34.1825 17.8985 33 17.3368V23.6632Z"
        fill="#B1B7BC"
      />
    </svg>
  )
);
