import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { AggregateFunction } from "../../../graphql";
import { BasicButton } from "../../Button";
import { Arrow, SortIconWrapper, Direction } from "../../styled";
import {
  GroupTableHeaderCell,
  HeaderTitleTextWrapper,
  SimpleHeaderWrapper,
  StyledTh,
} from "../styled";
import { SortByConfig } from "../utils";

import { MakeNonBreakingString } from "./utils";

interface AlternateHeaderRowProps {
  sortByConfig: SortByConfig;
  setSortByConfig: (config: SortByConfig) => void;
  onChangeSort?: (sortBy: AggregateFunction, order: number) => void;
  colNames: AggregateFunction[];
  alternatingColumns?: boolean;
}

const TableHeaderButton = styled(BasicButton)`
  justify-content: center;
  width: 100%;
`;

export const TableHeaderRow = ({
  sortByConfig,
  setSortByConfig,
  onChangeSort,
  alternatingColumns,
  colNames,
}: AlternateHeaderRowProps) => {
  const { t } = useTranslation();

  const onHeaderTitlePress = (aggregation: AggregateFunction) => {
    const newOrder =
      sortByConfig.col === aggregation ? -1 * sortByConfig.order : -1;

    setSortByConfig({
      col: aggregation,
      order: newOrder,
    });

    if (onChangeSort) {
      onChangeSort(aggregation, newOrder);
    }
  };

  const directionIndicator =
    sortByConfig.order === 1 ? Direction.Up : Direction.Down;

  return (
    <tr style={{ zIndex: 10 }}>
      <th key="th-title--1" />
      {colNames.map((colName, i) => (
        <StyledTh
          key={`th-${colName}-${i}`}
          withBackground={alternatingColumns && i % 2 === 0}
        >
          <TableHeaderButton
            key={`button-${colName}-${i}`}
            onClick={() => onHeaderTitlePress(colName)}
          >
            <SimpleHeaderWrapper>
              <GroupTableHeaderCell>
                <HeaderTitleTextWrapper>
                  {MakeNonBreakingString(
                    t(`aggregation${colName}`, {
                      defaultValue: colName,
                    }).toString()
                  )}
                  {sortByConfig.col === colName && (
                    <SortIconWrapper isIconVisible>
                      <Arrow direction={directionIndicator} />
                    </SortIconWrapper>
                  )}
                </HeaderTitleTextWrapper>
              </GroupTableHeaderCell>
            </SimpleHeaderWrapper>
          </TableHeaderButton>
        </StyledTh>
      ))}
    </tr>
  );
};
