import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_TABLE_LABEL } from "../../colors";
import { BasicButton } from "../Button";
import Hoverable from "../Hoverable";
import { PlusIcon } from "../Icons";

const PlusButtonWrapper = styled(BasicButton)<{ isHovered: boolean }>`
  flex-direction: row;
  align-items: center;
  background-color: ${({ isHovered }) =>
    isHovered ? COLOR_GREY_ACTIVE : "transparent"};
`;

const EditText = styled.div<{ isHovered: boolean }>`
  margin: 0 12px;
  text-transform: uppercase;
  color: ${({ isHovered, theme }) =>
    isHovered || theme.isMobile ? COLOR_TEXT_TABLE_LABEL : "transparent"};
  font-weight: bold;
  font-size: 12px;
`;

const PlusContainer = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface HoverablePlusIconButtonProps {
  onClick: () => void;
}

export const HoverablePlusIconButton = ({
  onClick,
}: HoverablePlusIconButtonProps) => {
  const { t } = useTranslation();

  return (
    <Hoverable>
      {(isHovered) => (
        <PlusButtonWrapper onClick={onClick} isHovered={isHovered}>
          <>
            <PlusContainer>
              <PlusIcon
                width={13}
                height={13}
                tintColor={COLOR_TEXT_TABLE_LABEL}
              />
            </PlusContainer>
            <EditText isHovered={isHovered}>{t("edit")}</EditText>
          </>
        </PlusButtonWrapper>
      )}
    </Hoverable>
  );
};
