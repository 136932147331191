import React, { memo } from "react";

export const AthleteProfileIcon = memo(() => (
  <svg
    width="54"
    height="42"
    viewBox="0 0 54 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45 3H9C5.6863 3 3 5.6862 3 9V33C3 36.3138 5.6863 39 9 39H15V37C15 35.8954 15.8953 35 17.0001 35C18.1046 35 19 35.8954 19 37V39H35V37C35 35.8954 35.8953 35 37.0001 35C38.1046 35 39 35.8954 39 37V39H45C48.3139 39 51 36.3138 51 33V9C51 5.6862 48.3139 3 45 3ZM24.9703 26.9999C24.4991 24.6872 22.4499 22.9416 20.0001 22.9416C17.5503 22.9416 15.5009 24.6872 15.0296 26.9999H13C13.4681 23.7558 16.1036 21.2159 19.4033 20.9398C16.9267 20.6417 15 18.557 15 16.0001C15 13.2386 17.2386 11 20.0001 11C22.7614 11 25 13.2386 25 16.0001C25 18.557 23.0734 20.6416 20.5969 20.9398C23.8965 21.2159 26.5318 23.7558 27.0001 26.9999H24.9703ZM41 21H37.0001V25H35V21H31V19H35V15H37.0001V19H41V21ZM23 16.0001C23 14.3458 21.6542 13 20.0001 13C18.3458 13 17.0001 14.3458 17.0001 16.0001C17.0001 17.6541 18.3458 19 20.0001 19C21.6542 19 23 17.6541 23 16.0001Z"
      fill="#A0BEEC"
    />
  </svg>
));
