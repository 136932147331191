import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import { TreeType, useFolderListQuery } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { Loader, LoaderWrapper } from "../Loader";
import StyledText from "../StyledText";
import Tabs, { Tab } from "../Tabs";

import { Folder } from "./Folder";

type FolderSelectorProps = {
  folder: string;
  setFolder: Dispatch<SetStateAction<string>>;
  foldersTab: Tab;
  setFoldersTab: Dispatch<SetStateAction<Tab>>;
  collectionId: string;
  collectionType: TreeType;
  setCollectionType: Dispatch<SetStateAction<string>>;
  isModal: boolean;
  treeData: any; // TODO: fix this type
  tab: Tab;
};

export const FOLDER_SELECTOR_ELEMENT_ID = "folderSelectorScrollSection";

const FolderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 305px;
  background-color: ${COLOR_WHITE};
  padding: 25px 0 0 15px;
  max-height: 100%;
  border-right: 1px solid ${COLOR_GREY_ACTIVE};
`;

const FolderItem = styled.div`
  display: flex;
  height: 45px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const FoldersWrapper = styled.div<{ isModal: boolean }>`
  padding-bottom: 15px;
  height: ${({ isModal }) => `calc(${isModal ? 90 : 100}vh - 258px)`};
  overflow: auto;
`;

function FolderSelector({
  foldersTab,
  setFoldersTab,
  setFolder,
  folder,
  collectionId,
  collectionType,
  setCollectionType,
  isModal,
  treeData = null,
  tab,
}: FolderSelectorProps) {
  const { t } = useTranslation();
  const user = useUserContext();

  const { data, loading } = useFolderListQuery({
    variables: {
      collectionId,
      collectionType,
    },
    skip: Boolean(treeData) || !collectionId || user.isFamilyMember,
  });

  const foldersTabItems = useMemo(() => {
    if (isRealTrainer(user)) {
      return [
        {
          id: TreeType.Folders,
          title: t("folders").toUpperCase(),
        },
        { id: TreeType.Tags, title: t("tags").toUpperCase() },
      ];
    } else {
      return [];
    }
  }, [user, t]);

  useEffect(() => {
    if (!foldersTab) setFoldersTab(foldersTabItems[0]);
  }, [foldersTab, foldersTabItems, setFoldersTab]);

  const folders = useMemo(() => {
    if (treeData) {
      return treeData;
    }

    if (data?.user?.collection?.tree?.children)
      return data.user.collection.tree.children;
    return [];
  }, [treeData, data]);

  const isTagFolder = foldersTab?.title === TreeType.Tags;
  const shouldDisplayFavoriteFolder =
    !isTagFolder &&
    !user.isFamilyMember &&
    !isRealTrainer(user) &&
    !!folders.length;
  const shouldDisplayUnreadFolder =
    !user.isFamilyMember && !isRealTrainer(user) && !!folders.length;

  // We probably do not need this as server returns what needs to be displayed - it just messy for different accounts

  // const findRealTimeCrumbNotDummySharingCrumb = (crumb) =>
  //   crumb.id.includes("folder");
  // const filteredFolders = useMemo(() => {
  //   if (tab?.id === TabLabel.ALL) {
  //     const orderedByCrumbs = folders.reduce((acc, obj) => {
  //       // Due to sharing crumbs for athlete login we want first real folder crumb - in other words team crumb
  //       const key = obj.crumbs.find(
  //         findRealTimeCrumbNotDummySharingCrumb
  //       )?.name;
  //       const curGroup = acc[key] ?? [];

  //       return { ...acc, [key]: [...curGroup, obj] };
  //     }, {});

  //     const crumbsKey = Object.keys(orderedByCrumbs);
  //     const crumbList = crumbsKey.map((crumb) => {
  //       const finedCrumb = folders.find(
  //         (folder) =>
  //           folder.crumbs.find(findRealTimeCrumbNotDummySharingCrumb)?.name ===
  //           crumb
  //       );

  //       return finedCrumb.crumbs.find(findRealTimeCrumbNotDummySharingCrumb);
  //     });

  //     return crumbList.map((crumb) => {
  //       return {
  //         ...crumb,
  //         hasSubfolders: true,
  //         children: orderedByCrumbs[crumb.name],
  //       };
  //     });
  //   }

  //   return folders
  //     ?.filter((folder) => {
  //       if (tab?.id === TabLabel.ALL) {
  //         return folder;
  //       }

  //       if (
  //         tab?.id.toLowerCase() === folder?.id.toLowerCase() ||
  //         tab?.title.toLowerCase() === folder?.name.toLowerCase()
  //       ) {
  //         return folder;
  //       }
  //     })
  //     ?.flatMap(({ children }) => children);
  // }, [folders, tab?.id, tab?.title]);

  const foldersToDisplay = useMemo(() => {
    return [
      shouldDisplayFavoriteFolder && {
        id: `favorites`,
        hasSubfolders: false,
        name: "Favorites",
      },
      shouldDisplayUnreadFolder && {
        id: `unread`,
        hasSubfolders: false,
        name: "Unread",
      },
      ...folders,
    ].filter(Boolean);
  }, [folders, shouldDisplayFavoriteFolder, shouldDisplayUnreadFolder]);

  return (
    <FolderWrapper>
      {foldersTabItems.length > 0 ? (
        <FolderItem>
          <Tabs
            style={{ alignItems: "flex-start" }}
            tab={foldersTab}
            setTab={async (item) => {
              setFoldersTab(item);
              setCollectionType(item.id);
              setFolder(null);
            }}
            tabs={foldersTabItems}
            tabStyle={{ marginBottom: 0 }}
          />
        </FolderItem>
      ) : null}
      {loading && (
        <LoaderWrapper>
          <Loader size="small" color={COLOR_BLUE} />
        </LoaderWrapper>
      )}
      {!loading && (
        <FoldersWrapper id={FOLDER_SELECTOR_ELEMENT_ID} isModal={isModal}>
          {foldersToDisplay?.map(
            (folderItem: {
              id: string;
              name: string;
              hasSubfolders: boolean;
            }) => (
              <Folder
                key={folderItem?.id}
                folder={folderItem}
                selectedFolder={folder}
                setFolder={setFolder}
                collectionType={tab}
              />
            )
          )}
          {!folders.length && (
            <EmptyWrapper>
              <StyledText color={COLOR_MEDIUM_GRAY} fontSize={12}>
                {collectionType !== TreeType.Shared &&
                  t(
                    collectionType === TreeType.Folders ? "noFolders" : "noTags"
                  )}
              </StyledText>
            </EmptyWrapper>
          )}
        </FoldersWrapper>
      )}
    </FolderWrapper>
  );
}

type MemoizedProps = Pick<
  FolderSelectorProps,
  "collectionId" | "folder" | "foldersTab" | "collectionType"
>;

function folderPropsAreEqual(
  prevProps: MemoizedProps,
  nextProps: MemoizedProps
) {
  return (
    prevProps.collectionId === nextProps.collectionId &&
    prevProps.collectionType === nextProps.collectionType &&
    prevProps.folder === nextProps.folder &&
    prevProps.foldersTab.id === nextProps.foldersTab.id
  );
}

export const MemoizedFolderSelector = memo(FolderSelector, folderPropsAreEqual);
