import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_GRAY } from "../../../colors";
import { GroupAvailableForSession } from "../../../contexts/agenda/AgendaProvider";
import Avatar from "../../Avatar";
import { CheckIcon, RoundedGroupProfileIcon } from "../../Icons";

const Container = styled.div<{ isSelected: boolean; isSubgroup: boolean }>`
  display: flex;
  align-items: center;
  height: 35px;
  padding: 5px 20px;
  justify-content: space-between;
  background: ${({ isSelected }) => (isSelected ? COLOR_GRAY : "transparent")};
  cursor: pointer;
  padding-left: ${({ isSubgroup }) => (isSubgroup ? 55 : 20)}px;

  &:hover {
    background: ${COLOR_GRAY};
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-right: 15px;
  flex-shrink: 0;
  padding-block: 10px;
  margin-left: 10px;
`;

interface SelectSessionGroupRowProps {
  data: GroupAvailableForSession;
  isSelected: boolean;
  onClick: () => void;
  onDoubleClick: () => void;
}

export const SelectSessionGroupRow = ({
  data,
  isSelected,
  onClick,
  onDoubleClick,
}: SelectSessionGroupRowProps) => {
  return (
    <Container
      isSubgroup={data.isSubgroup}
      isSelected={isSelected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <NameWrapper>
        {data.isSubgroup ? null : data.owner?.thumb ? (
          <Avatar size="small" source={{ uri: data.owner?.thumb }} />
        ) : (
          <RoundedGroupProfileIcon height={25} width={25} />
        )}
        <Text>{data.name}</Text>
      </NameWrapper>
      {isSelected && <CheckIcon isActive={true} />}
    </Container>
  );
};
