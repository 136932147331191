import React from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_DARK } from "../../colors";

const CategoryListWrapper = styled.div``;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
`;

const CategoryColorBox = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 20px;
  height: 20px;
  margin-right: 4px;
  border: 1px solid ${COLOR_GREY_ACTIVE};
`;

const Label = styled.span`
  font-size: 12px;
  color: ${COLOR_TEXT_DARK};
`;

export function Legend({ categories }) {
  return (
    <CategoryListWrapper>
      {categories.map(
        ({ categoryId, description, red255, green255, blue255 }) => (
          <CategoryWrapper key={categoryId}>
            <CategoryColorBox
              backgroundColor={`rgb(${red255}, ${green255}, ${blue255})`}
            />
            <Label>{description}</Label>
          </CategoryWrapper>
        )
      )}
    </CategoryListWrapper>
  );
}
