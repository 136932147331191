import { useMemo } from "react";

import { useUserContext } from "../contexts/User";
import { useSessionColorsQuery } from "../graphql";

export enum SessionTypeNumber {
  Workout = 3,
  Practice = 4,
  Game = 8,
  Event = 10,
}

export const useSessionColors = (sessionType: number) => {
  const { sessionId } = useUserContext();

  const { data: sessionColorsData } = useSessionColorsQuery({
    variables: {
      sessionId,
      sessionType,
    },
  });

  const colorList = useMemo(
    () => sessionColorsData?.sessionColors?.map((color) => `#${color}`),
    [sessionColorsData]
  );

  return {
    colorList,
  };
};
