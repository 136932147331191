import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { GetTheGroupsImInListQuery } from "../../../graphql";
import { sortList } from "../../../utils/sortList";
import { Loader } from "../../Loader";

import { ProfileItem } from "./ProfileItem";

type Props = {
  teams: GetTheGroupsImInListQuery["getTheGroupsImIn"];
  loading: boolean;
  style?: CSSProperties;
};

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 10px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLOR_WHITE};
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 12px;
`;

export function TeamsList({ teams, loading, style }: Props) {
  const { t } = useTranslation();
  const { language } = useUserContext();

  if (loading) {
    return <Loader size="large" color={COLOR_BLUE} />;
  }

  return (
    <div style={style}>
      <Title>{t("teams").toUpperCase()}</Title>
      {teams?.map((group, index) => (
        <InfoContainer
          key={group.id}
          style={index !== 0 ? { marginTop: 5 } : undefined}
        >
          <ProfileItem
            value={group.name}
            index={0}
            image={{ uri: group?.owner?.thumb }}
          />
          {sortList([...group.subGroups], language).map((subGroup) => (
            <ProfileItem
              key={subGroup.id}
              value={subGroup.name}
              index={1}
              isSmall
            />
          ))}
        </InfoContainer>
      ))}
    </div>
  );
}
