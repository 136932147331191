import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { UserPreferenceKeys } from "../../constants";
import {
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
} from "../../graphql";
import { useIsMonitoring } from "../../hooks";
import { BASE_ROUTES } from "../../router/RoutingConfig";
import { useUserContext } from "../User";

import { ReportsContextState } from "./types";

type UseDateHandlerProps = Pick<
  ReportsContextState,
  | "selectedEndDate"
  | "selectedPeriod"
  | "selectedSingleDay"
  | "selectedStartDate"
  | "isDefaultState"
> & {
  loadDateData: (
    state: Pick<
      ReportsContextState,
      | "selectedEndDate"
      | "selectedSingleDay"
      | "selectedStartDate"
      | "selectedPeriod"
    >
  ) => void;
};

export const useDateHandler = ({
  selectedEndDate,
  selectedPeriod,
  selectedSingleDay,
  selectedStartDate,
  isDefaultState,
  loadDateData,
}: UseDateHandlerProps) => {
  const { sessionId } = useUserContext();
  const { pathname } = useLocation();
  const isFormMonitoring = useIsMonitoring();

  const [lastLoadedRouteName, setLastLoadedRouteName] = useState<string>();

  const [getPreference, { loading: loadingDates, called: datesQueryCalled }] =
    useGetPreferenceLazyQuery({ fetchPolicy: "network-only" });
  const [setPreferences] = usePutPreferenceMutation();

  const currentRouteName = useMemo(() => {
    const isInReportModule =
      pathname.includes(BASE_ROUTES.ListReports) ||
      pathname.includes("report") ||
      pathname.includes("groupReport");

    return pathname.includes(BASE_ROUTES.AthleteProfile)
      ? "AthleteProfile"
      : isInReportModule
        ? "ListReports"
        : undefined;
  }, [pathname]);

  const stringifiedSelectedStartDate = selectedStartDate.toString();
  const stringifiedSelectedEndDate = selectedEndDate.toString();
  const stringifiedSelectedSingleDay = selectedSingleDay.toString();

  useEffect(() => {
    if (Boolean(sessionId) && currentRouteName !== lastLoadedRouteName) {
      setLastLoadedRouteName(currentRouteName);
      getPreference({
        variables: {
          key: isFormMonitoring
            ? UserPreferenceKeys.DATE_ATHLETE_PROFILE_STATE
            : UserPreferenceKeys.DATE_REPORTS_STATE,
          sessionId,
        },
      }).then((result) => {
        if (result?.data?.getPreference) {
          loadDateData(JSON.parse(result.data.getPreference));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRouteName, sessionId, lastLoadedRouteName]);

  useEffect(() => {
    if (sessionId && currentRouteName && !isDefaultState) {
      setPreferences({
        variables: {
          key: isFormMonitoring
            ? UserPreferenceKeys.DATE_ATHLETE_PROFILE_STATE
            : UserPreferenceKeys.DATE_REPORTS_STATE,
          value: JSON.stringify({
            selectedEndDate: stringifiedSelectedEndDate,
            selectedSingleDay: stringifiedSelectedSingleDay,
            selectedStartDate: stringifiedSelectedStartDate,
            selectedPeriod,
          }),
          sessionId,
        },
      });
    }
  }, [
    currentRouteName,
    isDefaultState,
    sessionId,
    stringifiedSelectedStartDate,
    stringifiedSelectedEndDate,
    stringifiedSelectedSingleDay,
    selectedPeriod,
    setPreferences,
    isFormMonitoring,
  ]);

  return {
    datesQueryCalled,
    loadingDates,
  };
};
