import React from "react";

import { SvgIconProps } from "./model";

export const CancelSearchIcon = (props: SvgIconProps) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#B1B7BC" />
      <path d="M4 11L11 4" stroke="white" strokeWidth="1.5" />
      <path d="M4 4L11 11" stroke="white" strokeWidth="1.5" />
    </svg>
  );
};
