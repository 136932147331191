import React, { ReactNode } from "react";

import { AccessLevelsContextProvider } from "./accessLevels";
import { FolderStructureContextProvider } from "./folderStructure";
import { MobileNavSideContextProvider } from "./mobileNavSide";
import { NotificationsContextProvider } from "./notifications";
import { SettingsContextProvider } from "./settingsContext";
import { UIProvider } from "./UI";
import { UserProvider } from "./User";

export default ({ children }: { children: ReactNode }) => (
  <UserProvider>
    <UIProvider>
      <AccessLevelsContextProvider>
        <MobileNavSideContextProvider>
          <NotificationsContextProvider>
            <SettingsContextProvider>
              <FolderStructureContextProvider>
                {children}
              </FolderStructureContextProvider>
            </SettingsContextProvider>
          </NotificationsContextProvider>
        </MobileNavSideContextProvider>
      </AccessLevelsContextProvider>
    </UIProvider>
  </UserProvider>
);
