import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { AggregateFunction } from "../../../graphql";
import {
  isAcuteChronicCollection,
  isSumAttribute,
} from "../../../utils/reports";
import { Checkbox } from "../../Checkbox";
import { TablePeriodizationForm } from "../../Report/PeriodizationTable/model";
import {
  CheckboxWrapper,
  SubSectionRow,
} from "../../ReportCreateTable/GroupColumnsSection/components/styled";

import { getAggregationFunctionsByItemId } from "./utils";

interface IValueSubSectionProps {
  columnIndex: number;
  isMultiSelectDisable?: boolean;
}

const AggregationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export function DataSelectionItemAggregationSelection({
  columnIndex,
  isMultiSelectDisable,
}: IValueSubSectionProps) {
  const { t } = useTranslation();

  const { values, setFieldValue } = useFormikContext<TablePeriodizationForm>();

  const currentColumn = values.columnHeaders[columnIndex];

  const supportedAggregationFunctions = getAggregationFunctionsByItemId(
    currentColumn.itemId ?? ""
  );

  const onCheckboxCheck =
    (aggregationName: AggregateFunction) => (isChecked: boolean) => {
      const newValues = isAcuteChronicCollection(currentColumn.collectionId)
        ? [aggregationName]
        : isMultiSelectDisable
          ? [aggregationName]
          : [...currentColumn.aggregateFunctions, aggregationName];

      setFieldValue(
        `columnHeaders[${columnIndex}].aggregateFunctions`,
        isChecked
          ? newValues
          : currentColumn.aggregateFunctions.filter(
              (agg) => agg !== aggregationName
            )
      );
    };

  return (
    !isSumAttribute(currentColumn.attributeTemplateId) && (
      <AggregationWrapper>
        <SubSectionRow>
          {supportedAggregationFunctions.map((aggregation, index) => (
            <CheckboxWrapper key={aggregation} isFirst={index === 0}>
              <Checkbox
                text={t(`aggregation${aggregation}`, {
                  defaultValue: aggregation,
                })}
                check={onCheckboxCheck(aggregation)}
                checked={currentColumn.aggregateFunctions.includes(aggregation)}
                {...(isAcuteChronicCollection(currentColumn.collectionId)
                  ? { typeIcon: "circle" }
                  : {})}
              />
            </CheckboxWrapper>
          ))}
        </SubSectionRow>
      </AggregationWrapper>
    )
  );
}
