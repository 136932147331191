import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE_SECONDARY, COLOR_TEXT_DARK, COLOR_WHITE } from "../colors";
import { useAgendaContext } from "../contexts/agenda/AgendaProvider";

import Avatar from "./Avatar";
import { useGetAgendaFiltersData } from "./Filters/hooks/useGetAgendaFiltersData";

const FocusedAthleteWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 1;
`;

const Badge = styled.div`
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 9px;
  background-color: ${COLOR_BLUE_SECONDARY};
  font-weight: 600;
  color: ${COLOR_WHITE};
  text-transform: uppercase;
  margin-bottom: 5px;
  width: fit-content;
`;

const NameWrapper = styled.div`
  cursor: default;
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

export function FocusedAthlete() {
  const { t } = useTranslation();
  const { selectedAthleteId, selectedVisibleGroupIds } = useAgendaContext();
  const { athletes: allSelectedGroupsUniqueAthletes } = useGetAgendaFiltersData(
    { selectedVisibleGroupIds }
  );

  const selectedAthlete = useMemo(() => {
    if (selectedAthleteId) {
      return allSelectedGroupsUniqueAthletes?.find(
        (athlete) => athlete?.id === selectedAthleteId
      );
    }
  }, [allSelectedGroupsUniqueAthletes, selectedAthleteId]);

  if (!selectedAthleteId || !selectedAthlete) {
    return null;
  }

  const fullName = `${selectedAthlete.firstName} ${selectedAthlete.lastName}`;

  return (
    <FocusedAthleteWrapper>
      <Avatar
        size={35}
        style={{ marginRight: 10 }}
        source={{ uri: selectedAthlete.thumb as string }}
        userName={fullName}
      />
      <NameWrapper>
        <Badge>{t("viewingAthlete")}</Badge>
        <Name>{fullName}</Name>
      </NameWrapper>
    </FocusedAthleteWrapper>
  );
}
