import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { Header } from "../components/Header";
import { CombinedTestEditor } from "../components/Report/CombinedTest/CombinedTestEditor";
import { CombinedFormValue } from "../components/Report/CombinedTest/models";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { useUserContext } from "../contexts/User";
import {
  useSaveWidgetTemplateCombinedTestMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport } from "../hooks/data/useAddWidgetToReport";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useNavigateToPreviousPage } from "../hooks/useNavigateToPreviousPage";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { isRouteParamTrue } from "../utils/route";
import {
  buildPreferencesString,
  parseCombinedTestPreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export function CombinedTestEditorScreen() {
  const { t } = useTranslation();
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
    searchParams,
  } = useWidgetEditorUrlParams();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const isGroupWidget = isRouteParamTrue(searchParams.get("isGroupWidget"));
  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { sessionId, language, firstName, lastName } = useUserContext();

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const [saveWidgetTemplateCombinedTest, { loading: isSavingWidget }] =
    useSaveWidgetTemplateCombinedTestMutation();

  const { data: widgetData, loading: loadingWidgeData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const widgetModel =
    widgetData?.reportWidgetTemplateById?.__typename ===
    "ReportWidgetTemplateCombinedTest"
      ? {
          ...widgetData.reportWidgetTemplateById,
          ...parseCombinedTestPreferencesObject(
            widgetData?.reportWidgetTemplateById?.preferences
          ),
        }
      : undefined;

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const onSubmit = async (values: CombinedFormValue) => {
    const { title, templateId, ...rest } = values;

    try {
      const response = await saveWidgetTemplateCombinedTest({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              title ||
              (isWidgetTemplate ? t("defaultCombinedTestWidgetName") : ""),
            templateID: templateId,
            preferences: buildPreferencesString({
              type: WidgetType.COMBINED_TEST,
              isGroupWidget,
              updatedAt: dayjs(),
              createdBy: `${firstName} ${lastName}`,
              ...rest,
            }),
            reportTemplateId: reportId || parentReportId,
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateCombinedTest?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateCombinedTest.id
        );
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  if (loadingWidgeData) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <CombinedTestEditor
        widget={widgetModel}
        isGroupWidget={isGroupWidget || widgetModel?.isGroupWidget}
        isSavingWidget={isSavingWidget}
        onSubmit={onSubmit}
        onCancel={navigateToPreviousPage}
      />
    </ScreenContainer>
  );
}
