import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChartColumnIcon = memo(
  ({
    width = 25,
    height = 25,
    isActive = false,
    tintColor = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <>
          <rect width="1" height="25" fill="white" />
          <rect y="24" width="25" height="1" fill="white" />
          <rect x="4" y="13" width="3" height="9" fill="white" />
          <rect x="9" y="3" width="3" height="19" fill="white" />
          <rect x="14" y="7" width="3" height="15" fill="white" />
          <rect x="19" y="17" width="3" height="5" fill="white" />
        </>
      ) : (
        <>
          <rect width="1" height="25" fill={tintColor} />
          <rect y="24" width="25" height="1" fill={tintColor} />
          <rect x="4" y="13" width="3" height="9" fill={tintColor} />
          <rect x="9" y="3" width="3" height="19" fill={tintColor} />
          <rect x="14" y="7" width="3" height="15" fill={tintColor} />
          <rect x="19" y="17" width="3" height="5" fill={tintColor} />
        </>
      )}
    </svg>
  )
);
