import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../../../colors";
import { requiredValidation } from "../../../../utils/form";
import { Label } from "../../../Label";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { SelectField, SelectOption } from "../../../SelectField";
import StyledText from "../../../StyledText";
import {
  ChartType,
  ChartWidgetModel,
  TimeLine,
  TimeLinePeriod,
} from "../model";

import { ChartValuesAndLimitsSections } from "./ChartValuesAndLimitsSections";

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Content = styled(Box)`
  flex-direction: row;
`;

const SelectContainer = styled(Box)`
  width: 213px;
`;

const TimeLineContainer = styled(Box)`
  margin-left: 10px;
  flex: 1;
`;

interface ChartWhatToShowSectionProps {
  isGroupWidget: boolean;
}

export function ChartWhatToShowSection({
  isGroupWidget,
}: ChartWhatToShowSectionProps) {
  const { values, setFieldValue } = useFormikContext<ChartWidgetModel>();
  const { t } = useTranslation();

  const onTimeLineChange = (option: SelectOption) => {
    const timeLinePeriod =
      option.value === TimeLine.PERIOD
        ? TimeLinePeriod.DAYS
        : option.value === TimeLine.PERFORMERS
          ? TimeLinePeriod.NONE
          : undefined;

    setFieldValue("timeLine", option.value);
    setFieldValue("timeLinePeriod", timeLinePeriod);
  };

  useEffect(() => {
    if (
      values.timeLine === TimeLine.CONTINUOUS &&
      values.chartType !== ChartType.LINE
    ) {
      setFieldValue("timeLine", TimeLine.PERIOD);
      setFieldValue("timeLinePeriod", TimeLinePeriod.DAYS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <Section style={{ borderBottomWidth: 0 }}>
        <SectionTitle title={t("whatToShow")} />
        <Content>
          <StyledText
            color={COLOR_TEXT_DEFAULT}
            fontSize={10}
            fontWeight="bold"
            style={{ marginTop: 32.5, marginRight: 25 }}
          >
            {t("range")}
          </StyledText>

          <SelectContainer>
            <Label>{t("type").toUpperCase()}</Label>
            <Field name="timeLine" validate={requiredValidation}>
              {({ field, form, meta }: FieldProps<ChartWidgetModel>) => (
                <SelectField
                  field={field}
                  form={form}
                  meta={meta}
                  options={[
                    {
                      label: t("time"),
                      value: TimeLine.CONTINUOUS,
                      enabled() {
                        return values.chartType === ChartType.LINE;
                      },
                    },
                    { label: t("timeFrames"), value: TimeLine.PERIOD },
                    {
                      label: t("performers"),
                      value: TimeLine.PERFORMERS,
                      enabled() {
                        return (
                          isGroupWidget &&
                          (values.chartType === ChartType.COLUMN ||
                            values.chartType === ChartType.BAR)
                        );
                      },
                    },
                  ].filter(({ enabled = () => true }) => enabled())}
                  onChange={onTimeLineChange}
                />
              )}
            </Field>
          </SelectContainer>

          {(values.timeLine === TimeLine.PERIOD ||
            values.timeLine === TimeLine.PERFORMERS) && (
            <TimeLineContainer>
              <Label> </Label>
              <Field name="timeLinePeriod" validate={requiredValidation}>
                {({ field, form, meta }: FieldProps<ChartWidgetModel>) => (
                  <SelectField
                    field={field}
                    form={form}
                    meta={meta}
                    options={[
                      { label: t("noPeriod"), value: TimeLinePeriod.NONE },
                      { label: t("days"), value: TimeLinePeriod.DAYS },
                      { label: t("weeks"), value: TimeLinePeriod.WEEKS },
                      { label: t("months"), value: TimeLinePeriod.MONTHS },
                      { label: t("years"), value: TimeLinePeriod.YEARS },
                    ]}
                  />
                )}
              </Field>
            </TimeLineContainer>
          )}
        </Content>
      </Section>

      <ChartValuesAndLimitsSections />
    </>
  );
}
