// This is too hacky solution due to issue with infinite scroll with apollo - we should consider usage of react-query graphl infinite query in the future
export function enforceUniqueArrayValues(
  newerArray,
  olderArray,
  shouldUseIdAsIdentity = false,
  idPath = "id"
) {
  // Create a Set to store unique objects
  const uniqueObjects = new Set();

  // Add objects from arr1 to the Set
  newerArray.forEach((obj) => {
    uniqueObjects.add(
      shouldUseIdAsIdentity ? obj[idPath] : JSON.stringify(obj)
    );
  });

  // Add objects from arr2 to the Set
  olderArray.forEach((obj) => {
    uniqueObjects.add(
      shouldUseIdAsIdentity ? obj[idPath] : JSON.stringify(obj)
    );
  });

  let mergedArray = [];

  // Convert the Set back to an array of objects
  if (shouldUseIdAsIdentity) {
    mergedArray = Array.from(uniqueObjects).map(
      (uniqueId) =>
        newerArray.find((obj) => obj[idPath] === uniqueId) ??
        olderArray.find((obj) => obj[idPath] === uniqueId)
    );
  } else {
    mergedArray = Array.from(uniqueObjects).map((item: string) =>
      JSON.parse(item)
    );
  }

  return mergedArray;
}
