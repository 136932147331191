import { useEffect, useState } from "react";

import { fetchJsonContent } from "../services";

function getFullJsonContentUrl(jsonContentUrl: string) {
  return jsonContentUrl.replace(".s.json", ".json");
}

export function useModularContentFetching(document) {
  const [isLoading, setIsLoading] = useState(false);
  const [modularContent, setModularContent] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (document) {
        const { jsonUrl, urlOldJsonStyle } = document || {};

        const url = jsonUrl || urlOldJsonStyle;
        try {
          setIsLoading(true);
          let content = null;
          //   if (mcUrl) {
          //     content = await fetchModularContent(mcUrl);
          //  } else // TODO: fix mcURL
          if (url) {
            content = await fetchJsonContent(getFullJsonContentUrl(url));
          }
          setModularContent(content);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [document]);

  return { isLoading, modularContent };
}
