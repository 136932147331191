import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_MEDIUM_GRAY } from "../../colors";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { UserContextState, useUserContext } from "../../contexts/User";
import {
  setClientParam,
  TreeType,
  useFolderDocumentsLazyQuery,
  useFolderDocumentsQuery,
  useSharedDocumentsForFamilyMemberQuery,
} from "../../graphql";
import { getServerBasePath } from "../../utils/getServerBasePath";
import { isRealTrainer } from "../../utils/isRealTrainer";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import StyledText from "../StyledText";
import { Tab } from "../Tabs";

import { CollectionList } from "./CollectionList";
import { CollectionTabs } from "./CollectionTabs";
import { CollectionTrainerTabs } from "./CollectionTrainerTabs";
import { MemoizedFolderSelector } from "./FolderSelector";
import { Accounts, TabLabel } from "./model";
import { Search } from "./Search";
import {
  COLLECTION_ROOT_TYPES,
  COLLECTION_TYPES,
  collectionTypes,
  getTypename,
  swapTypes,
} from "./utils";

dayjs.extend(utc);
dayjs.extend(timezone);

const CollectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${COLOR_GRAY};
`;

const Title = styled(StyledText)`
  font-size: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 30px;
`;

const StyledBox = styled.div<{ user?: UserContextState }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ user }) =>
    user.isFamilyMember || !user.isTrainer ? "0px" : "30px"};
`;

const CollectionBodyWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const CollectionListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`;

export function Collections({
  onClick,
  focusedAthleteId,
  isFromMessaging,
}: {
  onClick?(node?: any): void;
  focusedAthleteId?: string;
  isFromMessaging?: boolean;
}) {
  const user = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();
  const { t } = useTranslation();

  const [search, setSearch] = useState("");
  const [collections, setCollections] = useState<Accounts>({});
  const [tab, setTab] = useState<Tab>({ id: "", title: "" });
  const [tabs, setTabs] = useState<Tab[]>([]);
  // TODO: check why we need this - isn't it enough to use collectionType ?
  const [foldersTab, setFoldersTab] = useState<Tab>(
    isRealTrainer(user)
      ? { id: TreeType.Folders, title: t("folders").toUpperCase() }
      : { id: TreeType.Tags, title: t("shared").toUpperCase() }
  );

  // We do not want to display empty content if there are no tags -> we still want to show items from folders
  const [collectionTypeOverride, setCollectionTypeOverride] =
    useState<TreeType | null>(null);

  const [collectionType, setCollectionType] = useState<TreeType>(
    isRealTrainer(user) ? TreeType.Folders : TreeType.Shared
  );
  const [folder, setFolder] = useState<string>(null);

  const isFavoriteFolder = folder === "favorites";
  const isRecentFolder = folder === "recent";
  const isUnreadFolder = folder === "unread";
  const isCustomFolder =
    !isFavoriteFolder && !isRecentFolder && !isUnreadFolder;

  const [collection, setCollection] = useState<Accounts[""]>({});

  const shouldSkipFolderDocumentsQuery =
    !collection.id || !tab.id || Boolean(focusedAthleteId);

  const {
    data: folderDocumentsData,
    loading: isLoadingFolderDocuments,
    fetchMore: fetchMoreFolderDocuments,
    variables: folderDocumentsVariable,
    networkStatus: folderDocumentsNetworkStatus,
    refetch: folderDocumentsRefetch,
  } = useFolderDocumentsQuery({
    variables: {
      filter: search,
      folder: isCustomFolder ? folder : null,
      favorite: isFavoriteFolder,
      recent: isRecentFolder,
      unread: isUnreadFolder,
      collectionId: collection.id ? tab.id : null,
      collectionType: collectionTypeOverride ?? collectionType,
    },
    skip: shouldSkipFolderDocumentsQuery,
    notifyOnNetworkStatusChange: true,
  });

  const [fetchTagsTree] = useFolderDocumentsLazyQuery({
    variables: {
      collectionType: TreeType.Tags,
      collectionId: collection.id ? tab.id : null,
    },
  });

  const {
    data: sharedDocumentsData,
    previousData: sharedDocumentsPrevData,
    loading: isLoadingSharedDocuments,
    fetchMore: fetchMoreSharedDocuments,
    variables: sharedDocumentsVariable,
    networkStatus: sharedDocumentsNetworkStatus,
    refetch: sharedDocumentsRefetch,
  } = useSharedDocumentsForFamilyMemberQuery({
    variables: {
      collectionId: `false.SHARED.${focusedAthleteId}`,
      type: TreeType.Shared,
      filter: search,
      currentFolderId: isFavoriteFolder || isUnreadFolder ? null : folder,
      first: 50,
      favorite: isFavoriteFolder,
      unread: isUnreadFolder,
      contentType: !tab.id || tab.id === TabLabel.ALL ? null : tab.id,
    },
    skip: !focusedAthleteId,
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  // We need this because useSharedDocumentsForFamilyMemberQuery need to have sessionId as a part of URL query params
  if (focusedAthleteId) {
    setClientParam("sessionId", user.sessionId);
  }

  useEffect(() => {
    // We need to prevent tabs with ids like false.DOCUMENT.undefined otherwise server is confused
    if (collection && collection.guid && isRealTrainer(user)) {
      const types = collection.types ? [...collection.types] : [];
      if (types.length) {
        const swipedTypes = swapTypes(
          types,
          (TabLabel.WORKOUT_TEMPLATE || TabLabel.WORKOUTS).toUpperCase(),
          (
            TabLabel.PRACTICE_TEMPLATE_2020 || TabLabel.TEAM_SESSIONS
          ).toUpperCase()
        );
        const newTabs = swipedTypes.map((type) => ({
          id: `false.${type}.${collection.guid}`,
          title: getTypename(type.toLocaleLowerCase(), t).toUpperCase(),
        }));
        const currentTabExists = newTabs.find((t) => {
          if (t.title === tab.title) {
            setTab(t);
            return true;
          }
        });
        if (!currentTabExists) setTab(newTabs[0]);
        setTabs(newTabs);
      }
    }
  }, [collection, t, tab?.id, tab?.title, tabs?.length, user]);

  useEffect(() => {
    if (tab.id && !shouldSkipFolderDocumentsQuery) {
      folderDocumentsRefetch({
        ...folderDocumentsVariable,
        collectionId: tab.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab.id, shouldSkipFolderDocumentsQuery]);

  useEffect(() => {
    // This is for the case when you switch from e.g. fav documents to fav workouts
    if (tab.id && focusedAthleteId && (isFavoriteFolder || isUnreadFolder)) {
      sharedDocumentsRefetch({
        currentFolderId: null,
        favorite: isFavoriteFolder,
        unread: isUnreadFolder,
      });
    }
  }, [
    focusedAthleteId,
    isFavoriteFolder,
    isUnreadFolder,
    sharedDocumentsRefetch,
    tab.id,
  ]);

  useEffect(() => {
    async function loadAccounts() {
      const data = await fetch(
        `${getServerBasePath()}/xpsweb?charset=UTF-8&gzip=true&_languageCode=en&json=is.sideline.apps.xps.server.web.json.messages.JsonSharingQuery`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            _timeZoneOffset: -new Date().getTimezoneOffset() * 60 * 1000,
            _timeZoneName: dayjs.tz.guess(),
            _withReceivedDetails: false,
            _withCollectionsDetails: false,
            _onlyItemsThatNeedAttention: false,
            _selectedAccount: true,
            _sessionId: user.sessionId,
          }),
        }
      ).then((res) => res.json());

      const accounts: Accounts = COLLECTION_ROOT_TYPES.reduce((acc, cur) => {
        if (data[cur]?._children?.length) {
          data[cur]._children.forEach(
            (c: { _loadingPointId: string; _name: string }) => {
              const guid = c._loadingPointId.split(".")[2];
              if (acc[guid])
                collectionTypes[cur].forEach((t) => acc[guid].types.add(t));
              else
                acc[guid] = {
                  id: data[cur]._guid,
                  name: c._name,
                  guid,
                  types: new Set(collectionTypes[cur]),
                };
            }
          );
        }
        return acc;
      }, {});
      setCollections(
        Object.fromEntries(
          Object.entries(accounts).map(([key, value]) => [
            key,
            { ...value, types: Array.from(value.types ?? []) },
          ])
        )
      );
    }

    if (user.sessionId && user.id) {
      setClientParam("sessionId", user.sessionId);
      loadAccounts();
    }
  }, [user.sessionId, user.id]);

  useEffect(() => {
    (async () => {
      const keys = Object.keys(collections);
      if (user.isTrainer) {
        if (keys.length) {
          const selectedCollection = Storage.getItem<Accounts>(
            StorageKeys.selectedCollection
          );
          if (selectedCollection) {
            const newValue = selectedCollection;
            if (Array.isArray(newValue.types)) {
              const selectedTab = await Storage.getItem<Tab>(
                StorageKeys.selectedCollectionTab
              );
              const collection: Accounts[""] = {
                ...newValue,
                types: Array.from(new Set(newValue.types)),
              };
              if (selectedTab && collection.id) setTab(selectedTab);
              setCollection(collection);
            }
          } else {
            const collectionsValues = Object.values(collections);
            const isUserFocused = selectedAccount?.id ?? user.id;
            const selectedCollection = collectionsValues.find(
              (collection) => collection.guid === isUserFocused
            );

            if (!selectedAccount?.id) {
              const latestCollectionStr = await Storage.getItem(
                StorageKeys.lastSelectedCollectionForAllAccounts
              );
              if (latestCollectionStr) {
                setCollection(latestCollectionStr);
                return;
              }
            }

            setCollection(selectedCollection ?? collectionsValues[0]);
          }
        }
      } else if (user.id) {
        setCollection({
          id: `false.${tab.title}.${user.id}`,
          name: "shared",
          guid: user.id,
          types: COLLECTION_TYPES,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections, user, selectedAccount?.id]);

  useEffect(() => {
    const treeData = sharedDocumentsData?.user?.collection?.distinctSharedTypes;

    if (treeData && treeData.length) {
      const fetchedTabs = treeData.map((type) => ({
        id: type,
        title: getTypename(type.toLowerCase(), t),
        type,
      }));

      const extendedTabs = [
        { id: "all", title: t("all").toUpperCase() },
        ...fetchedTabs,
      ];

      if (!tab?.id) {
        setTab(extendedTabs[0]);
      }

      setTabs(extendedTabs);
    }
  }, [sharedDocumentsData, t, tab]);

  const onCollectionChange = useCallback(
    async (treeType: TreeType) => {
      setCollectionTypeOverride(null);

      if (collectionType !== TreeType.Tags && treeType === TreeType.Tags) {
        const { data: tagsTreeData } = await fetchTagsTree();

        if (!tagsTreeData?.user?.collection?.tree) {
          setCollectionTypeOverride(collectionType);
        }
      }

      setCollectionType(treeType);
    },
    [collectionType, fetchTagsTree]
  );

  const onSetTab = useCallback(
    (tab: Tab) => {
      if (
        isRealTrainer({
          isFamilyMember: user.isFamilyMember,
          isTrainer: user.isTrainer,
        })
      ) {
        setCollectionTypeOverride(null);
        setCollectionType(TreeType.Folders);
        setFoldersTab({
          id: TreeType.Folders,
          title: t("folders").toUpperCase(),
        });
      }

      setTab(tab);
    },
    [t, user.isFamilyMember, user.isTrainer]
  );

  const handleSharedDocumentsRefetch = () => {
    sharedDocumentsRefetch({
      currentFolderId: null,
      favorite: isFavoriteFolder,
      unread: isUnreadFolder,
    });
  };

  return (
    <CollectionWrapper>
      {!selectedAccount?.id && user.isTrainer ? (
        <Title>{t("library").toUpperCase()}</Title>
      ) : (
        <StyledBox user={user} />
      )}
      {isRealTrainer(user) ? (
        <CollectionTrainerTabs
          isFromMessaging={isFromMessaging}
          selectedAccount={selectedAccount}
          setFolder={setFolder}
          setTab={onSetTab}
          tab={tab}
          tabs={tabs}
        />
      ) : (
        <CollectionTabs
          tab={tab}
          tabs={tabs}
          setTab={setTab}
          setFolder={setFolder}
        />
      )}
      <CollectionBodyWrapper>
        <MemoizedFolderSelector
          isModal={!!onClick}
          folder={folder}
          foldersTab={foldersTab}
          setFoldersTab={setFoldersTab}
          collectionId={tab.id}
          collectionType={collectionType}
          setCollectionType={onCollectionChange}
          setFolder={setFolder}
          treeData={
            user.isFamilyMember
              ? sharedDocumentsData?.user?.collection?.tree?.children ||
                sharedDocumentsPrevData?.user?.collection?.tree?.children
              : undefined
          }
          tab={tab}
        />
        <CollectionListWrapper>
          <Search searchValue={search} setSearchValue={setSearch} />
          <CollectionList
            fetchMoreFolderDocuments={fetchMoreFolderDocuments}
            fetchMoreSharedDocuments={fetchMoreSharedDocuments}
            folderDocumentsData={folderDocumentsData}
            sharedDocumentsData={sharedDocumentsData}
            folderDocumentsVariable={folderDocumentsVariable}
            sharedDocumentsVariable={sharedDocumentsVariable}
            isLoadingFolderDocuments={isLoadingFolderDocuments}
            isLoadingSharedDocuments={isLoadingSharedDocuments}
            folderDocumentsNetworkStatus={folderDocumentsNetworkStatus}
            sharedDocumentsNetworkStatus={sharedDocumentsNetworkStatus}
            onClick={onClick}
            search={search}
            tab={tab}
            focusedAthleteId={focusedAthleteId}
            handleSharedDocumentsRefetch={handleSharedDocumentsRefetch}
            isFromMessaging={isFromMessaging}
          />
        </CollectionListWrapper>
      </CollectionBodyWrapper>
    </CollectionWrapper>
  );
}
