import React from "react";

import { useFormikContext } from "formik";

import { CreateGroupTableFormValue } from "../models";

import { RulesSection } from "./RulesSection";
import { useConditionsData } from "./useConditionsData";

export const GroupTableRulesSection = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateGroupTableFormValue>();
  const { rules, columns } = values ?? {};

  const conditionsData = useConditionsData(
    columns,
    (column) => column.aggregateFunctions
  );

  return (
    <RulesSection
      fieldArrayName="rules"
      rules={rules}
      onChange={setFieldValue}
      conditionsData={conditionsData}
    />
  );
};
