import React from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";

import { getSortingOptions } from "../../../../utils/sortingOrderOptions";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { SelectField, SelectOption } from "../../../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../../../styled";

import { AttendanceLogWidgetFormValue } from "./model";

export const DefaultSortingSectionAttendanceLog = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<Pick<AttendanceLogWidgetFormValue, "sortingOrder">>();

  return (
    <Section>
      <SectionTitle title={t("defaultSorting")} />
      <DraggableLineWrapper>
        <FieldWrapper>
          <Field name="sortingOrder">
            {(props) => (
              <SelectField
                {...props}
                value={values.sortingOrder}
                options={getSortingOptions(t, Boolean(values.sortingOrder))}
                onChange={(option: SelectOption<number>) =>
                  setFieldValue("sortingOrder", option?.value)
                }
              />
            )}
          </Field>
        </FieldWrapper>
      </DraggableLineWrapper>
    </Section>
  );
};
