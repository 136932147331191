import React from "react";

import styled from "styled-components";

import { COLOR_WHITE } from "../../../colors";
import { XpsUser } from "../../../graphql";

import { SectionHeader } from "./SectionHeader";
import { TeamHeader } from "./TeamHeader";
import { TeamItem } from "./TeamItem";

type TableListingProps = {
  data: XpsUser[];
  groupId: string;
  sectionLabel: string;
};

const TableWrapper = styled.table`
  width: 100%;
  background-color: ${COLOR_WHITE};
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 12px;
  margin-bottom: 15px;
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
`;

export function TeamList({ data, groupId, sectionLabel }: TableListingProps) {
  const count = data?.length;

  if (!count) {
    return null;
  }

  return (
    <>
      <SectionHeader sectionLabel={sectionLabel} count={count} />
      <TableWrapper>
        <thead>
          <TeamHeader titleRowShouldBeVisible={count} />
        </thead>
        <tbody>
          {data?.map((item) => (
            <TeamItem key={item.id} data={item} groupId={groupId} />
          ))}
        </tbody>
      </TableWrapper>
    </>
  );
}
