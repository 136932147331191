import { initReactI18next } from "react-i18next";

import dayjs from "dayjs";
import i18next, {
  BackendModule,
  InitOptions,
  ReadCallback,
  Services,
} from "i18next";
import _get from "lodash/get";
import moment from "moment";

import { dayjsAvailableLocales } from "../constants/DayJsLocales";

import { getServerBasePath } from "./getServerBasePath";
import Storage from "./storage";
import { StorageKeys } from "./storageKeys";

const STRINGS = require("../locals/translations.json");

const STRINGS_VALUES = Object.values(STRINGS);
const STRINGS_KEYS = Object.keys(STRINGS);

const COMMENT_FOR_TRANSLATORS = "(as used in chat)";

const getDefaultLanguage = () => {
  // Language stored from user settings - default option if not overriden by search param
  const storedLanguage = Storage.getItem<string>(
    StorageKeys.currentTranslation
  );

  // Stored language for current session - due to mobile app - it arrived as query param for first request - need to be used for whole session
  const storedLanguageParamOverride = Storage.getItemSession<string>(
    StorageKeys.translationParamOverride
  );

  // New language in URL -> highest priority
  const searchParams = new URLSearchParams(window.location.search);
  const languageOverrideFromUrl = searchParams.get("language");

  return (
    languageOverrideFromUrl ||
    storedLanguageParamOverride ||
    storedLanguage ||
    window.navigator.language ||
    "en"
  );
};

const getLanguage = (language: string) => language.slice(0, 2);

const TranslationsLoader: BackendModule = {
  type: "backend",
  init(
    services: Services,
    backendOptions: object,
    i18nextOptions: InitOptions
  ): void {},
  read(language: string, namespace: string, callback: ReadCallback): void {
    if (language === "en") {
      return callback(null, STRINGS);
    }

    fetchTranslations(getLanguage(language)).then((translations) => {
      callback(null, translations);
    });
  },
};

i18next
  .use(TranslationsLoader)
  .use(initReactI18next)
  .init({
    lng: getLanguage(getDefaultLanguage()),
    fallbackLng: "en",
    ns: ["common"],
    defaultNS: "common",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

moment.locale(getDefaultLanguage());

const parsedDefaultLanguage = getDefaultLanguage().split("-")[0];
if (dayjsAvailableLocales.includes(parsedDefaultLanguage)) {
  dayjs.locale(parsedDefaultLanguage);
}

async function fetchTranslations(lang: string) {
  const result = await fetch(
    `${getServerBasePath()}/xpsweb/?charset=UTF-8&gzip=true&_languageCode=en&json=is.sideline.apps.xps.server.web.json.messages.JsonTranslate`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _lang: lang,
        _texts: STRINGS_VALUES,
      }),
    }
  );

  const jsonResult: any = await result.json();
  const translated = {};
  if (jsonResult._texts) {
    jsonResult._texts.forEach((value, index) => {
      const key = STRINGS_KEYS[index];
      translated[key] = value.includes(COMMENT_FOR_TRANSLATORS)
        ? value.replace(COMMENT_FOR_TRANSLATORS, "").trim()
        : value;
    });
  }

  return translated;
}

export function getTextFromLangText(langText) {
  const locale = i18next.language || getDefaultLanguage();

  return getTranslation(langText, locale) || getTranslation(langText, "");
}

const getTranslation = (langText, langCode: string) => {
  const languages =
    _get(langText, "languages", false) || _get(langText, "_langs", false) || [];
  const translation = languages.filter(
    (t) => t.langCode === langCode || t.l === langCode
  );

  return _get(translation, "0.text", null) || _get(translation, "0.t", null);
};
