import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useParams } from "react-router-dom";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../colors";
import { GroupReport } from "../components/GroupReport/GroupReport";
import { ReportHeader } from "../components/GroupReport/ReportHeader";
import { SelectedAthletesList } from "../components/GroupReport/SelectedAthletesList";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import StyledText from "../components/StyledText";
import { ReportContextProvider } from "../contexts/report";
import { useNavigateToPreviousPage } from "../hooks";
import { getSearchParamsObject } from "../utils/getSearchParamsObject";
import { isRouteParamTrue } from "../utils/route";

const ReportWrapper = styled.div`
  padding: ${({ theme }) => (!theme.isMobile ? "15px 30px 30px 30px" : "15px")};
  flex: 1;
  position: relative;
  z-index: 0;
`;

const SelectedAthletesWrapper = styled.div`
  padding: 0 10px;
`;

export const GroupReportScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reportId } = useParams();

  const isEditMode = isRouteParamTrue(searchParams.get("isEditMode"));

  const { t } = useTranslation();
  const navigateToPreviousPage = useNavigateToPreviousPage();

  const toggleEditMode = () => {
    if (!isEditMode) {
      setSearchParams({
        ...getSearchParamsObject(searchParams),
        isEditMode: "true",
      });
    } else {
      navigateToPreviousPage();
    }
  };

  return (
    <ReportContextProvider reportId={reportId}>
      <ScreenContainer>
        <Header />

        <ReportHeader />

        <SelectedAthletesWrapper>
          <SelectedAthletesList confirmOnDeletion />
        </SelectedAthletesWrapper>

        <ReportWrapper>
          {!reportId ? (
            <StyledText
              color={COLOR_TEXT_DEFAULT}
              fontSize={18}
              style={{ lineHeight: "24px", alignSelf: "center" }}
            >
              {t("noReportSelected").toUpperCase()}
            </StyledText>
          ) : (
            <GroupReport
              isEditMode={isEditMode}
              toggleEditMode={toggleEditMode}
            />
          )}
        </ReportWrapper>
      </ScreenContainer>
    </ReportContextProvider>
  );
};
