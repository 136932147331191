import { XpsUser } from "../graphql";

import { sortListIcelandic } from "./sortListIcelandic";

const defaultGetText = <T = XpsUser>(item: T): string => {
  if (
    typeof item === "object" &&
    "name" in item &&
    typeof item.name === "string"
  ) {
    return item.name;
  }

  if (typeof item === "string" || typeof item === "number") {
    return String(item);
  }

  return "";
};

export function sortList<T = XpsUser>(
  list: T[],
  language?: string,
  getText: (item: T) => string = defaultGetText
): T[] {
  if (!list?.filter((item) => item)?.length) {
    return [];
  }

  if (language === "is") {
    return sortListIcelandic(list, getText);
  }

  const collator = new Intl.Collator(language, { sensitivity: "accent" });
  return list
    .filter((user) => user)
    .sort((user1, user2) => collator.compare(getText(user1), getText(user2)));
}
