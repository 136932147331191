import React, { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../colors";
import { BodyChartCategory } from "../../graphql";
import { Registrations } from "../Questionnaire/BodyChartItem";
import { SwitchSelector } from "../SwitchSelector";

import { BodyChartSvg } from "./BodyChartSvg";
import { Legend } from "./Legend";
import { mockedInjuriesData } from "./mockData";

const BodyChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const SwitchSelectorWrapper = styled.div`
  width: 150px;
  padding: 5px;
`;

const LegendWrapper = styled.div`
  margin-top: 40px;
`;

const getSwitchOptions = (t: TFunction<"common">) => [
  { label: t("frontSide"), id: "front" },
  { label: t("backSide"), id: "back" },
];

function getInitialData() {
  const registrations = {};
  const parts = mockedInjuriesData.bcRegistration?.parts;
  const categories = mockedInjuriesData.bcTemplate?.categories;

  parts?.map((part) => {
    const categoryNum = categories.findIndex(
      (cat) => cat.categoryId === part.categoryId
    );
    const category = categories[categoryNum];

    registrations[part.bodypartId] = {
      categoryId: part.categoryId,
      categoryNum,
      color: `rgb(${category.red255}, ${category.green255}, ${category.blue255})`,
    };
  });

  return {
    categories,
    registrations,
  };
}

interface IBodyChartProps {
  name: string;
  onBodyPartSelect: (id: number) => void;
  categories: BodyChartCategory[];
  registrations: Registrations;
}

export function BodyChart({
  categories = [],
  registrations = {},
  onBodyPartSelect,
  name,
}: IBodyChartProps) {
  const { t } = useTranslation();

  const { categories: initialCategories, registrations: initialRegistrations } =
    getInitialData();
  const categoryCount =
    categories.length !== 0 ? categories.length : initialCategories.length;

  const [side, setSide] = useState<"front" | "back">("front");
  const [localRegistrations, setLocalRegistrations] = useState(
    Object.keys(registrations).length > 0 ? registrations : initialRegistrations
  );

  useEffect(() => {
    setLocalRegistrations(registrations);
  }, [registrations]);

  const bodyPartSelected = (id: number) => {
    onBodyPartSelect && onBodyPartSelect(id);
    const prevCategoryNum =
      localRegistrations[id]?.categoryNum ?? categoryCount;

    const newCategoryNum = (prevCategoryNum + 1) % (categoryCount + 1);
    const newCategory = categories[newCategoryNum];

    if (newCategory) {
      setLocalRegistrations({
        ...localRegistrations,
        [id]: {
          categoryId: newCategory.categoryId,
          categoryNum: newCategoryNum,
          color: `rgb(${newCategory.red255}, ${newCategory.green255}, ${newCategory.blue255})`,
        },
      });
    } else {
      const updatedRegistrations = { ...localRegistrations };
      delete updatedRegistrations[id];

      setLocalRegistrations(updatedRegistrations);
    }
  };

  return (
    <BodyChartWrapper>
      <TopSection>
        <SwitchSelectorWrapper>
          <TitleWrapper>
            <Title>{name}</Title>
          </TitleWrapper>
          <SwitchSelector
            onSwitch={setSide}
            selected={side}
            options={getSwitchOptions(t)}
          />
        </SwitchSelectorWrapper>
        <LegendWrapper>
          <Legend categories={categories} />
        </LegendWrapper>
      </TopSection>
      <BodyChartSvg
        registrations={localRegistrations}
        bodyPartSelected={bodyPartSelected}
        side={side}
      />
    </BodyChartWrapper>
  );
}
