import { EventType } from "../components/Filters/model";
import { Calendar } from "../graphql/generated";

export const getAllEvents = (
  calendar: Partial<Calendar>,
  selectedEventTypes: EventType[]
) => [
  ...(selectedEventTypes.length === 0 ||
  (selectedEventTypes.includes(EventType.EVENT) && calendar.events)
    ? calendar.events
    : []),
  ...(selectedEventTypes.length === 0 ||
  (selectedEventTypes.includes(EventType.GAME) && calendar.games)
    ? calendar.games
    : []),
  ...(selectedEventTypes.length === 0 ||
  (selectedEventTypes.includes(EventType.PRACTICE) && calendar.practices)
    ? calendar.practices
    : []),
  ...(selectedEventTypes.length === 0 ||
  (selectedEventTypes.includes(EventType.WORKOUT) && calendar.workouts)
    ? calendar.workouts
    : []),
];
