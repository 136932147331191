import styled from "styled-components";

import {
  COLOR_LIGHT_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DEFAULT,
} from "../../../../colors";
import StyledText from "../../../StyledText";

export const KeyValuesWidgetValuesContainer = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: ${({ theme }) => (theme.isMobile ? "row" : "column")};
  flex-wrap: ${({ theme }) => (theme.isMobile ? "wrap" : "no-wrap")};
`;

export const KeyValuesWidgetValuesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-flow: ${({ theme }) => (theme.isMobile ? "wrap" : undefined)};
`;

export const KeyValuesWidgetValuesCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px 10px;
  min-width: 150px;
`;

export const KeyValuesWidgetValueTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const KeyValuesWidgetValueText = styled(StyledText)`
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  white-space: wrap;
  color: ${COLOR_TEXT_DEFAULT};
`;

export const KeyValuesWidgetValueUnitText = styled(StyledText)`
  margin-left: 8px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  text-align: center;
  color: ${COLOR_LIGHT_GRAY};
  overflow-wrap: normal;
  text-wrap: none;
  white-space: nowrap;
`;

export const KeyValuesWidgetValueDescriptionText = styled(StyledText)`
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-align: center;
  color: ${COLOR_MEDIUM_GRAY};
  margin-top: 5px;
`;
