import { useEffect, useMemo, useState } from "react";

import { Dayjs } from "dayjs";
import _uniqBy from "lodash/uniqBy";

import { useReportContext } from "../../../../contexts/report";
import { useUserContext } from "../../../../contexts/User";
import { useDummyChartData, useGetAthletesForTeam } from "../../../../hooks";
import { JsonStatisticsPeriod, parsePeriod } from "../../../../services";
import {
  useAthleteStatisticsQuery,
  useAttendanceStatisticsQuery,
  useAttendanceTeamStatisticsQuery,
  useSoccerStatisticsQuery,
  useSoccerTeamStatisticsQuery,
  useTeamAverageStatisticsQuery,
  useTeamStatisticsQuery,
  UseTeamStatisticsQueryArgs,
} from "../../../../services/statistics/hooks";
import { groupByCollection } from "../../../../utils/reports";
import { StatisticsData } from "../../models";
import {
  getStatisticsDataFromAttendanceStatistics,
  getStatisticsDataFromAttendanceTeamStatistics,
  getStatisticsDataFromMeasurements,
  getStatisticsDataFromSoccerStatistics,
  getStatisticsDataFromSoccerTeamStatistics,
  mergeStatisticsData,
  StatisticsItemModel,
} from "../../utils";
import {
  ChartItemModel,
  ChartPerformers,
  ChartWidgetModel,
  TimeLine,
  TimeLinePeriod,
} from "../model";

interface UseChartWidgetDataParams {
  widget: ChartWidgetModel;
  isGroupReport: boolean;
  isPreviewMode: boolean;
  fromDate: Dayjs;
  toDate: Dayjs;
  performers: ChartPerformers;
}

export function useChartWidgetData({
  widget,
  isGroupReport,
  isPreviewMode,
  fromDate,
  toDate,
  performers,
}: UseChartWidgetDataParams): {
  data: {
    athletesData: StatisticsData | null;
    teamAverageData: StatisticsData | null;
  } | null;
  loading: boolean;
} {
  const { generateDummyData } = useDummyChartData(
    fromDate,
    toDate,
    widget.timeLinePeriod
  );

  const athleteData = useAthleteChartWidgetData({
    widget,
    enabled: !isPreviewMode && !isGroupReport,
    fromDate,
    toDate,
    performers,
  });
  const groupData = useGroupChartWidgetData({
    widget,
    enabled: !isPreviewMode && isGroupReport,
    fromDate,
    toDate,
    performers,
  });

  const dummyData = useMemo(() => {
    const data = isPreviewMode
      ? generateDummyData(
          widget?.items,
          isGroupReport ? ["John Doe", "Jane Doe"] : ["John Doe"]
        )
      : null;

    return {
      data,
      loading: false,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget?.items, isGroupReport, isPreviewMode]);

  if (isPreviewMode) {
    return dummyData;
  }

  return !isGroupReport ? athleteData : groupData;
}

function useAthleteChartWidgetData({
  widget,
  enabled,
  fromDate,
  toDate,
  performers: {
    athleteGuids: athletes,
    teamAthleteGuids: teamAthletes,
    focusedAthleteGuid: focusedAthleteId,
  },
}: {
  widget: ChartWidgetModel;
  enabled: boolean;
  fromDate: Dayjs;
  toDate: Dayjs;
  performers: ChartPerformers;
}) {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const period: JsonStatisticsPeriod =
    widget?.timeLine === TimeLine.PERIOD &&
    widget.timeLinePeriod !== TimeLinePeriod.NONE
      ? widget.timeLinePeriod
      : "continuous";
  const periodization = parsePeriod(period);

  const { soccerStatisticsItems, attendanceItems, statisticsItems } =
    groupByCollection(
      widget?.items?.map((item) => ({
        ...item,
        collectionId: item.dataType,
      })) ?? []
    );

  const aggregations = widget.items.map((item) => item.aggregation);
  const {
    data: athletesStatisticsData = null,
    isLoading: athletesIsLoading,
    fetchStatus: athletesFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      session: { sessionId },
      focusedAthlete: focusedAthleteId,
      participants: athletes.map((id) => ({ id })),
      items: statisticsItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        statisticsItems.length > 0,
    }
  );

  const {
    data: attendanceStatisticsData = null,
    isLoading: attendanceStatisticsIsLoading,
    fetchStatus: attendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: { sessionId },
      fromDate,
      toDate,
      participants: athletes.map((id) => ({ id })),
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        attendanceItems.length > 0,
    }
  );

  const {
    data: soccerStatisticsData = null,
    isLoading: soccerStatisticsIsLoading,
    fetchStatus: soccerStatisticsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      session: { sessionId },
      from: fromDate,
      to: toDate,
      athleteGuids: athletes,
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
      withAllTimeResults: aggregations.some((aggregate) =>
        aggregate?.includes("Forever")
      ),
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        soccerStatisticsItems.length > 0,
    }
  );

  const teamAverageItems = _uniqBy(
    statisticsItems.filter(({ showTeamAverage }) => showTeamAverage),
    ({ dataType, templateGuid, attribute, aggregation }) =>
      `${dataType}:${templateGuid}:${attribute}:${aggregation}`
  );
  const {
    data: teamAverageStatisticsData = null,
    isLoading: teamAverageIsLoading,
    fetchStatus: teamAverageFetchStatus,
  } = useTeamAverageStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      participants: teamAthletes.map((id) => ({ id })),
      session: { sessionId },
      focusedAthlete: focusedAthleteId,
      items: teamAverageItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        teamAthletes.length > 0 &&
        teamAverageItems.length > 0,
    }
  );

  const athletesData = useMemo<StatisticsData>(() => {
    const chartStatistics = getStatisticsDataFromMeasurements(
      athletesStatisticsData
    );
    const attendanceChartStatistics = getStatisticsDataFromAttendanceStatistics(
      chartItemModelsToStatisticsItemModels(attendanceItems),
      attendanceStatisticsData?.data
    );
    const soccerChartStatistics = getStatisticsDataFromSoccerStatistics(
      chartItemModelsToStatisticsItemModels(soccerStatisticsItems),
      soccerStatisticsData?.data,
      widget.timeLine === TimeLine.CONTINUOUS
    );

    return mergeStatisticsData([
      chartStatistics,
      attendanceChartStatistics,
      soccerChartStatistics,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    athletesStatisticsData,
    attendanceStatisticsData?.data,
    soccerStatisticsData?.data,
  ]);

  const teamAverageData = useMemo<StatisticsData>(() => {
    const chartStatistics = getStatisticsDataFromMeasurements(
      teamAverageStatisticsData
    );

    return chartStatistics;
  }, [teamAverageStatisticsData]);

  const isLoading =
    (athletesFetchStatus !== "idle" && athletesIsLoading) ||
    (teamAverageFetchStatus !== "idle" && teamAverageIsLoading) ||
    (attendanceStatisticsFetchStatus !== "idle" &&
      attendanceStatisticsIsLoading) ||
    (soccerStatisticsFetchStatus !== "idle" && soccerStatisticsIsLoading);

  return {
    loading: isLoading,
    data: {
      athletesData,
      teamAverageData,
    },
  };
}

function useGroupChartWidgetData({
  widget,
  enabled,
  fromDate,
  toDate,
  performers: {
    teamGuids: teamPerformers,
    athleteGuids: athletes,
    teamAthleteGuids: teamAthletes,
  },
}: {
  widget: ChartWidgetModel;
  enabled: boolean;
  fromDate: Dayjs;
  toDate: Dayjs;
  performers: ChartPerformers;
}) {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const { getAthletesForTeam } = useGetAthletesForTeam();

  const period: JsonStatisticsPeriod =
    (widget?.timeLine === TimeLine.PERIOD ||
      widget?.timeLine === TimeLine.PERFORMERS) &&
    widget.timeLinePeriod !== TimeLinePeriod.NONE
      ? widget.timeLinePeriod
      : "continuous";
  const periodization = parsePeriod(period);

  const aggregations = widget.items.map((item) => item.aggregation);

  const { soccerStatisticsItems, attendanceItems, statisticsItems } =
    groupByCollection(
      widget?.items?.map((item) => ({
        ...item,
        collectionId: item.dataType,
      })) ?? []
    );

  const {
    data: athletesStatisticsData = null,
    isLoading: athletesStatisticsIsLoading,
    fetchStatus: athletesStatisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      session: { sessionId },
      participants: athletes.map((id) => ({ id })),
      items: statisticsItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        statisticsItems.length > 0,
    }
  );

  const {
    data: athletesAttendanceStatisticsData = null,
    isLoading: athletesAttendanceStatisticsIsLoading,
    fetchStatus: athletesAttendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: { sessionId },
      fromDate,
      toDate,
      participants: athletes.map((id) => ({ id })),
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        attendanceItems.length > 0,
    }
  );

  const {
    data: athletesSoccerStatisticsData = null,
    isLoading: athletesSoccerStatisticsIsLoading,
    fetchStatus: athletesSoccerStatisticsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      session: { sessionId },
      from: fromDate,
      to: toDate,
      athleteGuids: athletes,
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
      withAllTimeResults: aggregations.some((aggregate) =>
        aggregate?.includes("Forever")
      ),
    },
    {
      enabled:
        sessionId &&
        enabled &&
        athletes.length > 0 &&
        soccerStatisticsItems.length > 0,
    }
  );

  const [teams, setTeams] = useState<UseTeamStatisticsQueryArgs["teams"]>([]);

  useEffect(() => {
    Promise.all(
      teamPerformers.map((teamGuid) => getAthletesForTeam(teamGuid))
    ).then((teams) =>
      setTeams(
        teams.map(({ id, name, athletesIds }) => ({
          id,
          name,
          participants: athletesIds,
        }))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamPerformers]);

  const teamStatisticsItems = _uniqBy(
    statisticsItems,
    ({ dataType, templateGuid, attribute, aggregation }) =>
      `${dataType}:${templateGuid}:${attribute}:${aggregation}`
  );

  const {
    data: teamPerformersData = null,
    isLoading: teamPerformersIsLoading,
    fetchStatus: teamPerformersFetchStatus,
  } = useTeamStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      session: { sessionId },
      teams,
      items: teamStatisticsItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        teams.length > 0 &&
        teamStatisticsItems.length > 0,
    }
  );

  const {
    data: teamPerformersAttendanceStatisticsData = null,
    isLoading: teamPerformersAttendanceStatisticsIsLoading,
    fetchStatus: teamPerformersAttendanceStatisticsFetchStatus,
  } = useAttendanceTeamStatisticsQuery(
    {
      session: { sessionId },
      fromDate,
      toDate,
      groupGuids: teams.map(({ id }) => id),
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId && enabled && teams.length > 0 && attendanceItems.length > 0,
    }
  );

  const {
    data: teamPerformersSoccerStatisticsData = null,
    isLoading: teamPerformersSoccerStatisticsIsLoading,
    fetchStatus: teamPerformersSoccerStatisticsFetchStatus,
  } = useSoccerTeamStatisticsQuery(
    {
      session: { sessionId },
      from: fromDate,
      to: toDate,
      groupGuids: teams.map(({ id }) => id),
      periodization: periodization !== "Continuous" ? periodization : "No",
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        enabled &&
        teams.length > 0 &&
        soccerStatisticsItems.length > 0,
    }
  );

  const teamAverageItems = _uniqBy(
    statisticsItems.filter(({ showTeamAverage }) => showTeamAverage),
    ({ dataType, templateGuid, attribute, aggregation }) =>
      `${dataType}:${templateGuid}:${attribute}:${aggregation}`
  );
  const {
    data: teamAverageData = null,
    isLoading: teamAverageIsLoading,
    fetchStatus: teamAverageFetchStatus,
  } = useTeamAverageStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      participants: teamAthletes.map((id) => ({ id })),
      session: { sessionId },
      items: teamAverageItems,
      reportTemplateId: report?.id,
    },
    {
      enabled: sessionId && enabled && teamAverageItems.length > 0,
    }
  );

  const data = useMemo(() => {
    const athletesChartStatistics = getStatisticsDataFromMeasurements(
      athletesStatisticsData
    );
    const attendanceChartStatistics = getStatisticsDataFromAttendanceStatistics(
      chartItemModelsToStatisticsItemModels(attendanceItems),
      athletesAttendanceStatisticsData?.data
    );
    const soccerChartStatistics = getStatisticsDataFromSoccerStatistics(
      chartItemModelsToStatisticsItemModels(soccerStatisticsItems),
      athletesSoccerStatisticsData?.data,
      false
    );

    const teamPerformersChartStatistics =
      getStatisticsDataFromMeasurements(teamPerformersData);
    const attendanceTeamChartStatistics =
      getStatisticsDataFromAttendanceTeamStatistics(
        chartItemModelsToStatisticsItemModels(attendanceItems),
        teamPerformersAttendanceStatisticsData?.data
      );
    const soccerTeamChartStatistics = getStatisticsDataFromSoccerTeamStatistics(
      chartItemModelsToStatisticsItemModels(soccerStatisticsItems),
      teamPerformersSoccerStatisticsData?.data
    );

    const teamAverageChartStatistics =
      getStatisticsDataFromMeasurements(teamAverageData);

    return {
      athletesData: {
        perPerformer: [
          ...mergeStatisticsData([
            athletesChartStatistics,
            attendanceChartStatistics,

            soccerChartStatistics,
          ]).perPerformer,
          ...mergeStatisticsData([
            teamPerformersChartStatistics,
            attendanceTeamChartStatistics,
            soccerTeamChartStatistics,
          ]).perPerformer,
        ],
      },
      teamAverageData: teamAverageChartStatistics,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    athletesStatisticsData,
    athletesAttendanceStatisticsData?.data,
    athletesSoccerStatisticsData?.data,
    teamPerformersAttendanceStatisticsData?.data,
    teamPerformersSoccerStatisticsData?.data,
    teamPerformersData,
    teamAverageData,
    periodization,
  ]);

  const loading =
    (athletesStatisticsFetchStatus !== "idle" && athletesStatisticsIsLoading) ||
    (athletesAttendanceStatisticsFetchStatus !== "idle" &&
      athletesAttendanceStatisticsIsLoading) ||
    (athletesSoccerStatisticsFetchStatus !== "idle" &&
      athletesSoccerStatisticsIsLoading) ||
    (teamPerformersFetchStatus !== "idle" && teamPerformersIsLoading) ||
    (teamPerformersAttendanceStatisticsFetchStatus !== "idle" &&
      teamPerformersAttendanceStatisticsIsLoading) ||
    (teamPerformersSoccerStatisticsFetchStatus !== "idle" &&
      teamPerformersSoccerStatisticsIsLoading) ||
    (teamAverageFetchStatus !== "idle" && teamAverageIsLoading);

  return { loading, data };
}

function chartItemModelsToStatisticsItemModels(
  items: ChartItemModel[]
): StatisticsItemModel[] {
  return items.map(
    ({
      dataType,
      templateGuid,
      templateName,
      attribute,
      attributeName,
      aggregation,
    }) => ({
      collectionId: dataType,
      templateId: templateGuid,
      templateName,
      attributeId: attribute,
      attributeName,
      aggregation,
    })
  );
}
