import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Formik } from "formik";

import { useUserContext } from "../../../../contexts/User";
import { PeriodType } from "../../../../models";
import { ConnectedFocusError } from "../../../FormikFocusError";
import { AppearanceSection } from "../../../ReportCreateTable/AppearanceSection";
import {
  AttendanceLogWidgetModel,
  WidgetType,
} from "../../../ReportCreateTable/models";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { StyledNumberInput } from "../../../StyledNumberInput";
import { PerformerSelectionMode } from "../../PerformerSection";
import { PeriodSection } from "../../PeriodSection";
import { PreviewButtonSection } from "../../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../../styled";
import { WidgetEditorTitleSection } from "../../WidgetEditorTitleSection";
import { AttendanceLogWidgetPreview } from "../Widget/Preview";

import { DataSection } from "./DataSection";
import { AttendanceLogWidgetFormValue } from "./model";
import { DefaultSortingSectionAttendanceLog } from "./SortingSection";

interface AttendanceLogWidgetEditorProps {
  widget: AttendanceLogWidgetModel;
  isSavingWidget: boolean;
  onSubmit: (values: AttendanceLogWidgetModel) => Promise<void>;
  onCancel: () => void;
}

const AttendanceLogWidgetEditor = ({
  widget,
  isSavingWidget,
  onSubmit,
  onCancel,
}: AttendanceLogWidgetEditorProps) => {
  const { t } = useTranslation();

  const { firstName, lastName } = useUserContext();

  const initialFormValues: AttendanceLogWidgetFormValue = {
    name: widget?.name ?? "",
    showEvents: widget?.showEvents ?? false,
    showGames: widget?.showGames ?? false,
    showPractices: widget?.showPractices ?? false,
    allGroups: widget?.allGroups ?? false,
    period: widget?.period ?? {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
    sortingOrder: widget?.sortingOrder ?? null,
    alternatingValues: widget?.alternatingValues ?? false,
    rowDividers: widget?.rowDividers ?? false,
    columnDividers: widget?.columnDividers ?? false,
    widgetBorders: widget?.widgetBorders ?? false,
    performerSelectionMode:
      widget?.performerSelectionMode ??
      PerformerSelectionMode.ATHLETE_SELECTION,
    maxCount: widget?.maxCount,
  };

  const handleSubmit = (values: AttendanceLogWidgetFormValue) => {
    onSubmit({
      ...values,
      type: WidgetType.ATTENDANCE_LOG,
      createdBy: widget?.createdBy ?? `${firstName} ${lastName}`,
      updatedAt: widget?.updatedAt ?? dayjs(),
    });
  };

  return (
    <Formik<AttendanceLogWidgetFormValue>
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validate={(values) => {
        if (values.maxCount < 0) {
          return { maxCount: "minValue" };
        }
      }}
      validateOnMount
    >
      {({ values, handleChange, submitForm, setFieldValue, errors }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <WidgetEditorTitleSection
              value={values.name}
              name="name"
              handleChange={handleChange}
            />

            <Section>
              <SectionTitle title={t("maxEntryCount")} />
              <StyledNumberInput
                value={values.maxCount}
                onChange={(value) => setFieldValue("maxCount", value)}
                min={0}
                name="maxCount"
                isInvalid={Boolean(errors.maxCount)}
              />
            </Section>

            <DataSection />

            <DefaultSortingSectionAttendanceLog />

            <AppearanceSection
              widgetType={WidgetType.ATTENDANCE_LOG}
              widgetBorders={values?.widgetBorders}
              alternatingValues={values?.alternatingValues}
              rowDividers={values?.rowDividers}
              columnDividers={values?.columnDividers}
              setFieldValue={setFieldValue}
            />

            <PeriodSection
              setFieldValue={setFieldValue}
              period={values.period}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            withoutPadding
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <AttendanceLogWidgetPreview />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
};

export default AttendanceLogWidgetEditor;
