import React from "react";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import { ChevronRightIcon } from "../../Icons";
import { FirstLevelOptionsContentWrapper, OptionItemText } from "../styled";

interface LeftOrientedFirstLevelOptionsWrapperProps {
  optionItemText: string;
  isHovered: boolean;
}

export const LeftOrientedFirstLevelOptionsWrapper = ({
  optionItemText,
  isHovered,
}: LeftOrientedFirstLevelOptionsWrapperProps) => (
  <FirstLevelOptionsContentWrapper isHovered={isHovered}>
    <OptionItemText>{optionItemText}</OptionItemText>
    <ChevronRightIcon
      tintColor={COLOR_TEXT_DEFAULT}
      strokeWidth="1"
      style={{ marginLeft: "auto", marginRight: 5, width: 17, height: 17 }}
    />
  </FirstLevelOptionsContentWrapper>
);
