import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_RED, COLOR_WHITE } from "../../../colors";
import { useUIContext } from "../../../contexts/UI";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { Account, getAccounts } from "../../../utils/switchAccounts";
import { PlusInRoundIcon } from "../../Icons";
import StyledText from "../../StyledText";

import { SwitchAccountDialogItem } from "./SwitchAccountsModalItem";

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

const AddAccountButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

const AddAccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PlusIconWrapper = styled.div`
  display: flex;
  padding: 7px;
  margin-right: 10px;
  background-color: ${COLOR_GRAY};
  border-radius: 50px;
`;

const ErrorWrapper = styled.div`
  background-color: ${COLOR_RED};
  border-radius: 6px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0px;
  margin-bottom: 20px;
`;

export const SwitchAccountsModal = ({ handleSwitchAccount }) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [error, setError] = useState(null);
  const { isTemporary } = useUserContext();

  const { t } = useTranslation();

  const { actions: modalActions } = useModalContext();
  const ui = useUIContext();

  useEffect(() => {
    function getSavedAccounts() {
      const savedAccounts = getAccounts();
      setAccounts(savedAccounts || []);
    }

    if (!accounts.length) {
      getSavedAccounts();
    }
  }, [accounts.length]);

  const handleAddAccount = () => {
    if (isTemporary) {
      return onAccountClick(null);
    }
    modalActions.openModal({
      modal: ModalType.LOGIN,
      title: null,
      style:
        ui.width > 500
          ? {
              maxWidth: 422,
              minWidth: 422,
              minHeight: 450,
            }
          : {
              minHeight: 450,
            },
    });
  };

  const onAccountClick = async (account: Account | null) => {
    const error = await handleSwitchAccount(account ? account.sessionId : null);
    error && setError(error);
  };

  return (
    <ModalContentWrapper>
      {error && (
        <ErrorWrapper>
          <StyledText fontSize={12} fontWeight="bold" color={COLOR_WHITE}>
            {error}
          </StyledText>
        </ErrorWrapper>
      )}
      {accounts?.length
        ? accounts
            .sort((a, b) =>
              b.isLoggedIn === a.isLoggedIn ? 0 : a.isLoggedIn ? -1 : 1
            )
            .map((account) => (
              <SwitchAccountDialogItem
                key={account.id}
                onClick={() => onAccountClick(account)}
                fullName={account.fullName}
                userGuid={account.id}
                sessionId={account.sessionId}
                isFamilyMember={account.isFamilyMember}
                isTrainer={account.isTrainer}
                isLoggedIn={account.isLoggedIn}
              />
            ))
        : null}
      <AddAccountButton onClick={handleAddAccount}>
        <AddAccountWrapper>
          <PlusIconWrapper>
            <PlusInRoundIcon />
          </PlusIconWrapper>
          <StyledText fontSize={16}>{t("addAccount")}</StyledText>
        </AddAccountWrapper>
      </AddAccountButton>
    </ModalContentWrapper>
  );
};
