import { WidgetLimit } from "../../../contexts/Reports";
import {
  AggregateFunction,
  ReportWidgetTemplateChart,
  XpsUser,
} from "../../../graphql";
import { Period } from "../../../models/reports";
import { WidgetType } from "../../ReportCreateTable/models";
import { PerformerSelectionMode } from "../PerformerSection";

export enum ChartType {
  BAR = "bar",
  LINE = "line",
  COLUMN = "column",
}

export enum TimeLinePeriod {
  NONE = "none",
  DAYS = "days",
  WEEKS = "weeks",
  MONTHS = "months",
  YEARS = "years",
}

export enum TimeLine {
  PERIOD = "period",
  CONTINUOUS = "continuous",
  /**
   * For Group Reports and Bar or Column chart types only
   */
  PERFORMERS = "performers",
}

export type ChartItemModel = {
  aggregation: AggregateFunction;
  attribute: string;
  attributeName?: string;
  dataType: string;
  templateGuid: string;
  templateName?: string;
  chartTypeOverride?: ChartType;
  showTeamAverage?: boolean;
  teamAverageChartType?: ChartType;
};

export type ChartWidgetPreferences = {
  performerSelectionMode?: PerformerSelectionMode;
  // Used by athlete chart
  athlete: string;
  // Used by group chart
  athletes: string[];
  teams: string[];
  chartType: ChartType;
  includeZero?: boolean;
  createdBy: string;
  items: ChartItemModel[];
  limits: WidgetLimit[];
  participants: XpsUser[];
  period: Period;
  series: any;
  showLegend: boolean;
  timeLine: TimeLine;
  timeLinePeriod?: TimeLinePeriod;
  widgetBorders: boolean;
  type: WidgetType;
  updatedAt: string;
  isGroupWidget: boolean;
};

export type ChartWidgetModel = ReportWidgetTemplateChart &
  ChartWidgetPreferences;

export type ChartPerformers = {
  teamGuids: string[];
  athleteGuids: string[];
  /**
   * For computing Team Average statistics
   */
  teamAthleteGuids: string[];
  focusedAthleteGuid?: string;
  focusedGroupGuid?: string;
};

export const TEXT_SOCCER_STATS_ATTRIBUTES = [
  "SoccerStat.MinutesInPosition",
  "SoccerStat.CustomColumn",
  "SoccerStat.CustomTextColumn",
];
