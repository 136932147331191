import React, { useEffect, useRef, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Event, Game, Practice, Workout } from "../../graphql";
import { useUpdateWorkout } from "../../hooks/data/useUpdateWorkout";
import { SessionTabs } from "../../models/tabs";
import { isRealTrainer } from "../../utils/isRealTrainer";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { PaymentSection } from "../Agenda/components/PaymentSection";
import { AgendaSession } from "../Agenda/model";
import AttendeeItem from "../AttendeeItem";
import { Loader, LoaderWrapper } from "../Loader";
import { SessionMembersList } from "../Members/SessionMembersList";
import Tabs, { Tab } from "../Tabs";
import { WorkoutDoneButton } from "../WorkoutDoneButton";

import {
  useSessionDetailQuery,
  SessionType,
} from "./hooks/useSessionDetailQuery";
import { SessionAttachments } from "./SessionAttachments";
import { SessionAttendance } from "./SessionAttendance";
import SessionAttendanceButtons from "./SessionAttendanceButtons";
import SessionEditMember from "./SessionEditMember";
import { SessionInfo } from "./SessionInfo";
import { SessionPlan } from "./SessionPlan";

interface SessionDetailProps {
  sessionGuid: string;
}

const TabContainer = styled.div<{ hasNoTabs: boolean }>`
  display: flex;
  background-color: ${COLOR_WHITE};
  padding: 10px 20px 10px 10px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  flex-direction: row;
  justify-content: ${({ hasNoTabs }) =>
    hasNoTabs ? "flex-end" : "space-between"};
  align-items: center;
`;

const TabContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SessionDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
  padding: 10px;
`;

const SessionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: 1px solid #e8e9ec;
  min-height: 100%;
`;

const getTabItems = ({
  t,
  hasPlan,
  shouldShowAttendanceAndEditMembersTab,
  shouldShowMembersListTab,
}: {
  t: TFunction<"common">;
  hasPlan: boolean;
  shouldShowAttendanceAndEditMembersTab: boolean;
  shouldShowMembersListTab: boolean;
}) => {
  const commonTabs = [
    ...(shouldShowAttendanceAndEditMembersTab
      ? [
          { id: SessionTabs.ATTENDANCE, title: t("attendance").toUpperCase() },
          {
            id: SessionTabs.EDIT_MEMBER,
            title: t("editMembers").toUpperCase(),
          },
        ]
      : shouldShowMembersListTab
        ? [{ id: SessionTabs.MEMBERS, title: t("members").toUpperCase() }]
        : []),
  ];

  if (!hasPlan) {
    return commonTabs;
  }

  return [
    { id: SessionTabs.PLAN, title: t("plan").toUpperCase() },
    ...commonTabs,
  ];
};

const getSessionDetailView = (
  sessionType: SessionType,
  sessionGuid: string,
  { id },
  showMembersAttendance: boolean
) => {
  switch (id) {
    case SessionTabs.PLAN:
      return (
        <SessionPlan sessionType={sessionType} sessionGuid={sessionGuid} />
      );
    case SessionTabs.ATTENDANCE:
      return <SessionAttendance sessionGuid={sessionGuid} />;
    case SessionTabs.EDIT_MEMBER:
      return <SessionEditMember sessionGuid={sessionGuid} />;
    case SessionTabs.MEMBERS:
      return (
        <SessionMembersList
          sessionGuid={sessionGuid}
          showMembersAttendance={showMembersAttendance}
        />
      );
    default:
      return null;
  }
};

export function SessionDetail({ sessionGuid }: SessionDetailProps) {
  const didMountRef = useRef(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!didMountRef.current) {
      Storage.removeItem(StorageKeys.practiceForm);
      Storage.removeItem(StorageKeys.gameOrEvent);
      didMountRef.current = true;
    }
  });

  const { isFamilyMember, isTrainer } = useUserContext();
  const [tab, setTab] = useState<Tab | any>(null);
  const [tabItems, setTabItems] = useState([]);

  const isUserRealTrainer = isRealTrainer({ isFamilyMember, isTrainer });

  const sessionType = searchParams.get("sessionType") as SessionType;

  const { loading, data, refetch } = useSessionDetailQuery({
    sessionType,
    sessionGuid,
  });

  const { updateWorkoutMutation } = useUpdateWorkout();

  const sessionData = data?.[
    sessionType === SessionType.Event ? "event2" : sessionType.toLowerCase()
  ] as Event | Game | Practice | Workout | undefined;

  const isGreenInCalendar = sessionData?.isGreenInCalendar ?? false;

  const showMembersAttendance =
    sessionData?.everybodyCanSeeParticipants && sessionData?.attSelfReg;

  useEffect(() => {
    const tabItems = getTabItems({
      t,
      shouldShowAttendanceAndEditMembersTab:
        isUserRealTrainer && sessionData?.__typename !== "Workout",
      hasPlan:
        (sessionData?.__typename === SessionType.Practice &&
          "practice" in data) ||
        (sessionData?.__typename === SessionType.Workout && "workout" in data),
      shouldShowMembersListTab:
        !isUserRealTrainer && sessionData?.__typename !== "Workout",
    });
    const activeTab = tabItems[0];
    setTabItems(tabItems);
    setTab(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData?.id]);

  const onCompletedPress = async () => {
    await updateWorkoutMutation({
      id: sessionGuid,
      refetchQueries: () => ["Workout", "SessionDetail"],
      input: { completed: !isGreenInCalendar },
    });
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const isExpired = dayjs(sessionData?.replyBefore).isBefore();
  const editable = dayjs(sessionData?.start).isAfter();

  return (
    <SessionDetailWrapper>
      {sessionData && (
        <SessionInfoContainer>
          <SessionInfo session={sessionData} refetch={refetch} />
          {sessionData.attachments ? (
            <SessionAttachments data={sessionData.attachments} />
          ) : null}
          {sessionType === SessionType.Workout && sessionData ? (
            <WorkoutDoneButton
              onClick={onCompletedPress}
              isGreenInCalendar={isGreenInCalendar}
            />
          ) : null}
          <TabContainer hasNoTabs={tabItems.length <= 1}>
            {tabItems.length > 1 ? (
              <Tabs tab={tab} setTab={setTab as any} tabs={tabItems} />
            ) : null}
            {!isUserRealTrainer && "attendance" in sessionData ? (
              isFamilyMember ? (
                <table>
                  <tbody>
                    {sessionData?.attendanceEditable
                      ? sessionData.attendance.map((attendee) => (
                          <AttendeeItem
                            showAthleteName
                            key={attendee?.athleteGuid}
                            avatarsOfChildren={sessionData.avatarsOfChildren}
                            withRegistration={!!sessionData.replyBefore}
                            attendance={attendee}
                            sessionGuid={sessionGuid}
                            refetch={refetch}
                            isSessionDetail
                            isExpired={isExpired}
                          />
                        ))
                      : null}
                    {sessionData?.payments?.length ? (
                      <PaymentSection
                        session={sessionData as AgendaSession}
                        refetch={refetch}
                        showAthleteName
                      />
                    ) : null}
                  </tbody>
                </table>
              ) : (
                <SessionAttendanceButtons
                  sessionGuid={sessionGuid}
                  refetch={refetch}
                  avatarsOfChildren={sessionData.avatarsOfChildren}
                  attendance={sessionData.attendance[0]}
                  isExpired={isExpired}
                  editable={editable}
                />
              )
            ) : null}
          </TabContainer>
          {tab?.id && (
            <TabContentContainer>
              {getSessionDetailView(
                sessionType,
                sessionGuid,
                tab,
                showMembersAttendance
              )}
            </TabContentContainer>
          )}
        </SessionInfoContainer>
      )}
    </SessionDetailWrapper>
  );
}
