import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";

import { changePaginatedContactInMessages } from "../../../cacheUpdates/changePaginatedContactInMessages";
import { COLOR_TEXT_DARK } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { useMuteMutation } from "../../../graphql";
import Hoverable from "../../Hoverable";
import { MuteIcon } from "../../Icons";
import { StyledOptionItem } from "../../Report/styled";
import { RoundIcon } from "../../RoundIcon";
import { StyledSwitch } from "../../StyledSwitch";
import StyledText from "../../StyledText";

export const MuteChatOption = ({ contact, contactMuted, setContactMuted }) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();

  const [mute] = useMuteMutation();

  const onMuteClick = useCallback(
    async (muted) => {
      await mute({
        variables: {
          sessionId,
          chatterId: contact.id,
          muted: !muted,
        },
        optimisticResponse: {
          setMuted: !muted,
        },
        update: (proxy) => {
          changePaginatedContactInMessages({
            proxy,
            sessionId,
            language,
            contactId: contact.id,
            edgeModifier: (node) => ({ ...node, muted: !muted }),
          });
        },
      });

      setContactMuted(!muted);
    },
    [contact, setContactMuted, language, mute, sessionId]
  );

  return (
    <Hoverable>
      {(isHovered) => (
        <StyledOptionItem
          isHovered={isHovered}
          height={45}
          onClick={() => onMuteClick(contactMuted)}
        >
          <RoundIcon
            svgIcon={<MuteIcon color={COLOR_TEXT_DARK} />}
            isHovered={isHovered}
          />
          <StyledText
            fontWeight="bold"
            fontSize={14}
            numberOfLines={1}
            style={{ maxWidth: 200 }}
          >
            {t("mute")}
          </StyledText>
          <SwitchContainer>
            <StyledSwitch
              value={contactMuted}
              onValueChange={() => onMuteClick(contactMuted)}
              label={""}
            />
          </SwitchContainer>
        </StyledOptionItem>
      )}
    </Hoverable>
  );
};

const SwitchContainer = styled.div(() => {
  return css`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: end;
    padding-right: 15px;
  `;
});
