import React from "react";

import { useFormikContext } from "formik";

import { CreateAthleteTableFormValue } from "../models";

import { RulesSection } from "./RulesSection";
import { useConditionsData } from "./useConditionsData";

export const AthleteTableRulesSection = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateAthleteTableFormValue>();
  const { rules, rows, columns: aggregations } = values ?? {};

  const conditionsData = useConditionsData(rows, () => aggregations);

  return (
    <RulesSection
      fieldArrayName="rules"
      rules={rules}
      onChange={setFieldValue}
      conditionsData={conditionsData}
    />
  );
};
