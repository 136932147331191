import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  SessionTypeNumber,
  useSessionColors,
} from "../../../hooks/useSessionColors";
import { ControlledCheckbox } from "../../HookForm/ControlledCheckbox";
import { ControlledColorPicker } from "../../HookForm/ControlledColorPicker";
import { ControlledInput } from "../../HookForm/ControlledInput";

const Row = styled.div<{ zIndexValue?: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
`;

const RowItem = styled.div<{ flex: number; marginLeft?: number }>`
  display: flex;
  flex: ${({ flex }) => flex};
  margin-left: ${({ marginLeft }) => marginLeft ?? 0}px;
`;

const ColorPickerWrapper = styled.div`
  width: 76px;
  margin-left: 20px;
`;

export const SaveGroupForm = ({ formMethods, showShortName }) => {
  const { t } = useTranslation();
  const { colorList } = useSessionColors(SessionTypeNumber.Practice);

  return (
    <FormProvider {...formMethods}>
      <Row zIndexValue={2}>
        <RowItem flex={5}>
          <ControlledInput
            name="name"
            label={t("saveGroupFormName")}
            rules={{
              required: t("nameRequired"),
            }}
            autoFocus={true}
          />
        </RowItem>
        {showShortName ? (
          <RowItem flex={2} marginLeft={20}>
            <ControlledInput
              name="shortName"
              label={t("saveGroupFormShortName")}
              rules={{
                maxLength: {
                  value: 3,
                  message: t("shortNameMax"),
                },
              }}
            />
          </RowItem>
        ) : null}
        <ColorPickerWrapper>
          <ControlledColorPicker
            name="colorHexCode"
            label={t("color")}
            colorList={colorList}
          />
        </ColorPickerWrapper>
      </Row>
      <Row>
        <ControlledCheckbox
          name="personalChatAllowed"
          text={t("saveGroupFormPersonalChatAllowed")}
          marginBottom={20}
        />
      </Row>
      <Row>
        <ControlledCheckbox
          name="groupChatAllowed"
          text={t("saveGroupFormGroupChatAllowed")}
          marginBottom={20}
        />
      </Row>
      <Row>
        <ControlledCheckbox
          name="selfRegistered"
          text={t("saveGroupFormSelfRegistered")}
          marginBottom={20}
        />
      </Row>
      <Row>
        <ControlledCheckbox
          name="defaultAttended"
          text={t("saveGroupFormDefaultAttended")}
          marginBottom={20}
        />
      </Row>
      <Row>
        <ControlledCheckbox
          name="hidePracticeContentFromAthletes"
          text={t("saveGroupFormHidePracticeContentFromAthletes")}
          marginBottom={0}
        />
      </Row>
    </FormProvider>
  );
};
