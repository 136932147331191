/**
 * Delays the execution of subsequent code.
 *
 * @param {number} ms - The amount of time to delay in milliseconds.
 * @returns {Promise} - A promise that resolves after the specified delay.
 *
 * @example
 * // Usage
 * async function exampleFunction() {
 *   console.log('Start');
 *   await delay(2000); // Delay of 2 seconds
 *   console.log('End');
 * }
 *
 * exampleFunction();
 */
export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
