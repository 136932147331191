import React, { CSSProperties, memo } from "react";

import styled, { StyledFunction } from "styled-components";

import { COLOR_BLUE, COLOR_GRAY, COLOR_GREY_ACTIVE } from "../../colors";

interface Props extends Partial<Omit<StyledFunction<"div">, "style">> {
  isChecked?: boolean;
  variant?: "default" | "disabled";
  style?: CSSProperties;
  onClick?: () => void;
}

const EmptyRadio = styled.div<{
  borderColor?: string;
  opacity?: number;
}>`
  width: 21px;
  height: 21px;
  border: 1px solid ${({ borderColor }) => borderColor};
  background: ${COLOR_GRAY};
  opacity: ${({ opacity }) => opacity ?? 1};
  border-radius: 30px;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RadioInner = styled.div`
  width: 13px;
  height: 13px;
  background: ${COLOR_BLUE};
  border-radius: 30px;
  flex-shrink: 0;
`;

export const RadioIcon = memo(
  ({ isChecked, style, variant = "default", ...props }: Props) => {
    const colors = (): {
      borderColor?: string;
      opacity?: number;
    } => {
      switch (variant) {
        case "disabled":
          return {
            opacity: 0.35,
            borderColor: COLOR_GREY_ACTIVE,
          };
        default:
          return {
            borderColor: COLOR_GREY_ACTIVE,
          };
      }
    };
    return (
      <EmptyRadio {...colors()} style={style} id="radioIcon" {...props}>
        {isChecked ? <RadioInner /> : null}
      </EmptyRadio>
    );
  }
);
