import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_PRIMARY_BLUE,
  COLOR_TEXT_DARK,
} from "../colors";

type option = {
  id: string;
  label: string;
};

type SwitchSelectorProps = {
  onSwitch: Dispatch<SetStateAction<string>>;
  options: option[];
  selected: string;
};

const SwitchWrapper = styled.div`
  display: flex;
  border-radius: 50px;
  overflow: hidden;
`;

const Option = styled.div<{ isSelected: boolean }>`
  flex: 1;
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? COLOR_PRIMARY_BLUE : COLOR_GREY_ACTIVE)};
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_PRIMARY_BLUE : COLOR_GREY_ACTIVE};
  color: ${({ isSelected }) =>
    isSelected ? COLOR_GREY_ACTIVE : COLOR_TEXT_DARK};
  transition: background-color 0.3s;
`;

export function SwitchSelector({
  options,
  selected,
  onSwitch,
}: SwitchSelectorProps) {
  return (
    <SwitchWrapper>
      {options.map((option, index) => {
        const isSelected = selected === option.id;

        return (
          <Option
            key={index}
            isSelected={isSelected}
            onClick={() => onSwitch(option.id)}
          >
            {option.label}
          </Option>
        );
      })}
    </SwitchWrapper>
  );
}
