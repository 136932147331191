import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { ApolloError } from "@apollo/client";
import dayjs from "dayjs";
import moment from "moment";

import { dayjsAvailableLocales } from "../../constants/DayJsLocales";
import { useGetAvailableLanguagesQuery } from "../../graphql";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { useUserContext } from "../User";

import { TimeFormat } from "./types";

const SettingsContext = createContext({
  timeFormat: TimeFormat.TwentyFourHour,
  timeFormatTemplate: "",
  changeTimeFormat: (_f: TimeFormat) => null,
  language: "",
  languageOptions: [],
  languagesLoading: false,
  languagesFetchError: null as ApolloError | null,
  changeLanguage: (_l: string) => null,
  isLangOverrideActive: false,
});

export function SettingsContextProvider({
  children,
}): ReactElement<React.ReactNode> {
  const formatFromLocalStorage = Storage.getItem<TimeFormat>(
    StorageKeys.timeFormat
  );

  const [timeFormat, setTimeFormat] = useState<TimeFormat>(
    formatFromLocalStorage || TimeFormat.TwentyFourHour
  );

  const storedLanguageParamOverride = Storage.getItemSession<string>(
    StorageKeys.translationParamOverride
  );

  const { sessionId } = useUserContext();
  const { i18n } = useTranslation();
  const {
    loading: languagesLoading,
    data,
    error: languagesFetchError,
  } = useGetAvailableLanguagesQuery({
    variables: { sessionId },
    skip: !sessionId?.length,
    fetchPolicy: "cache-first",
  });

  const languages = useMemo(() => {
    let languagesMap = {};
    let languageOptions = [];
    if (data?.languages?.length) {
      languageOptions =
        data?.languages?.map((l) => {
          languagesMap = {
            ...languagesMap,
            [l.code?.toLocaleLowerCase()]: l.name,
          };
          return {
            label: l.name,
            value: l.code?.toLowerCase(),
          };
        }) || [];
    }
    return {
      languagesMap,
      languageOptions,
    };
  }, [data?.languages]);

  const timeFormatTemplate: string = useMemo(() => {
    let template;
    if (timeFormat === TimeFormat.TwelveHour) template = "h:mm A";
    if (timeFormat === TimeFormat.TwentyFourHour) template = "HH:mm";
    return template;
  }, [timeFormat]);

  const checkIsSupportedLanguage = useCallback(
    (language: string) => !!languages.languagesMap?.[language] || false,
    [languages.languagesMap]
  );

  const changeLanguage = useCallback(
    (language: string) => {
      if (storedLanguageParamOverride?.length) return;
      if (
        checkIsSupportedLanguage(language) &&
        i18n?.language !== language &&
        i18n.isInitialized
      ) {
        i18n.changeLanguage(language);
        moment.locale(language);

        if (dayjsAvailableLocales.includes(language)) {
          dayjs.locale(language);
        }

        Storage.setItem(StorageKeys.currentTranslation, language);
        window.location.reload();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkIsSupportedLanguage,
      i18n,
      i18n.isInitialized,
      storedLanguageParamOverride,
    ]
  );

  function changeTimeFormat(format: TimeFormat) {
    setTimeFormat(format);
    Storage.setItem(StorageKeys.timeFormat, format);
  }

  return (
    <SettingsContext.Provider
      value={{
        timeFormat,
        changeTimeFormat,
        timeFormatTemplate,
        language: i18n?.language,
        changeLanguage,
        languageOptions: languages.languageOptions,
        languagesLoading,
        languagesFetchError,
        isLangOverrideActive: !!storedLanguageParamOverride?.length,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export const useSettingsContext = () => useContext(SettingsContext);
