import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CancelRoundedIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <g clipPath="url(#clip0_3051_4722)">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
        <circle cx="17.5" cy="17.5" r="8" stroke="#64707A" />
        <path d="M12 23L23 12" stroke="#64707A" />
      </g>
      <defs>
        <clipPath id="clip0_3051_4722">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
