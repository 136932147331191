import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { DragDropContainer } from "../Wrappers/DragDropContainer";

import { AthleteTableWidgetRow } from "./models";
import { RowSection } from "./RowSection";

export function RowsSection() {
  const { t } = useTranslation();

  const { values } = useFormikContext<{
    rows: AthleteTableWidgetRow[];
  }>();

  return (
    <Section>
      <SectionTitle title={t("rows")} />
      <DragDropContainer
        fieldArrayName="rows"
        buttonText={t("addRow")}
        items={values.rows.map(
          (row, index) =>
            ({ remove, push, dragHandleProps }) => (
              <RowSection
                key={`${row.measurementTemplateId}-${row.attributeTemplateId}-${index}`}
                rowIndex={index}
                remove={remove}
                push={push}
                dragHandleProps={dragHandleProps}
              />
            )
        )}
      />
    </Section>
  );
}
