import React from "react";
import { DropdownIndicatorProps, components } from "react-select";

import { COLOR_BLUE, COLOR_GRAY, COLOR_WHITE } from "../colors";

import { ChevronRightIcon } from "./Icons";

// See Custom Component with selectProps Example section here https://react-select.com/components#defining-components
export function CustomDropdownIndicator(
  props: DropdownIndicatorProps & {
    isHovered?: boolean;
    isSelected?: boolean;
    backgroundColor?: string;
    hoverBackgroundColor?: string;
  }
) {
  // @ts-ignore
  const { isHovered, isSelected } = props.selectProps;

  return (
    <components.DropdownIndicator {...props}>
      <div
        style={{
          display: "flex",
          borderRadius: 12,
          ...(isHovered && {
            backgroundColor: props.hoverBackgroundColor ?? COLOR_GRAY,
          }),
          ...(isSelected && { backgroundColor: COLOR_BLUE }),
        }}
      >
        <ChevronRightIcon
          direction="bottom"
          tintColor={isSelected ? COLOR_WHITE : COLOR_BLUE}
        />
      </div>
    </components.DropdownIndicator>
  );
}
