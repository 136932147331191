import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { AggregateFunction, ReportItemType } from "../../../../graphql";
import { InformationWidgetFormModel } from "../../../../screens/InformationWidgetEditor";
import {
  isCustomTextSoccerAttribute,
  isSoccerStatsCollection,
} from "../../../../utils/reports";
import { Checkbox } from "../../../Checkbox";
import { IconButton } from "../../../IconButton";
import { BinIcon, ReorderIcon } from "../../../Icons";
import {
  IconButtonWrapper,
  ReorderImageWrapper,
} from "../../../ReportCreateTable/styled";
import { DraggableLineWrapper } from "../../../styled";
import { DragHandlePropsType } from "../../../Wrappers/DragDropContainer";
import { DataSourceSelectorRow } from "../../DataSourceSelector";

import { AggregationRow } from "./styled";

interface IRowSectionProps {
  index: number;
  dragHandleProps: DragHandlePropsType;
  remove(index: number): void;
}

const AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LatestForever,
  AggregateFunction.Each,
] as const;

export function DataSection({
  index,
  dragHandleProps,
  remove,
}: IRowSectionProps) {
  const { values, setFieldValue } =
    useFormikContext<Pick<InformationWidgetFormModel, "informations">>();
  const { t } = useTranslation();

  const currentInformationRow = values.informations[index];

  const onChangeRowValues = useCallback(
    (newValues: typeof currentInformationRow) => {
      setFieldValue(`informations[${index}]`, {
        ...currentInformationRow,
        ...newValues,
      });
    },
    [setFieldValue, index, currentInformationRow]
  );

  return (
    <>
      <DraggableLineWrapper>
        <ReorderImageWrapper {...dragHandleProps}>
          <ReorderIcon />
        </ReorderImageWrapper>

        <DataSourceSelectorRow
          currentDataSource={currentInformationRow}
          dataSourceArrayItemAccessor={`informations[${index}]`}
          onChangeDataSourceValues={onChangeRowValues}
          customMeasurementsMapper={(items) =>
            items.filter(
              (item) =>
                !isSoccerStatsCollection(item.id) ||
                isCustomTextSoccerAttribute(item.id)
            )
          }
          allowedReportItemTypes={[
            ReportItemType.Information,
            ReportItemType.SoccerStat,
          ]}
        />

        {values?.informations?.length > 1 && (
          <IconButtonWrapper>
            <IconButton
              icon={<BinIcon />}
              onClick={(event) => {
                event.preventDefault();
                remove(index);
              }}
            />
          </IconButtonWrapper>
        )}
      </DraggableLineWrapper>

      <AggregationRow>
        {AGGREGATIONS.map((aggregation) => (
          <Checkbox
            key={aggregation}
            text={
              aggregation === AggregateFunction.Each
                ? t("allEntriesInPeriod")
                : t(`aggregation${aggregation}`, { defaultValue: aggregation })
            }
            typeIcon="circle"
            check={() =>
              onChangeRowValues({ ...currentInformationRow, aggregation })
            }
            checked={currentInformationRow.aggregation === aggregation}
            withMargin={false}
          />
        ))}
      </AggregationRow>
    </>
  );
}
