import { Reducer } from "react";

import actions from "./constants";

const reducer: Reducer<{ open: boolean }, any> = (state, action) => {
  switch (action.type) {
    case actions.OPEN:
      return { ...state, open: true };
    case actions.CLOSE:
      return { ...state, open: false };
    default:
      return state;
  }
};

export default reducer;
