import { MutableRefObject, useEffect } from "react";

export function useHandleClickOutside(
  ref: MutableRefObject<HTMLElement>,
  onClickOutside?: () => void,
  addListener: boolean = true,
  elementId: string | null = "uniqueRootView"
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        onClickOutside
      ) {
        event.preventDefault();
        event.stopPropagation();

        onClickOutside();
      }
    }

    if (addListener) {
      const documentWithElement = elementId
        ? document.getElementById(elementId)
        : document;

      documentWithElement?.addEventListener("mousedown", handleClickOutside);

      return () => {
        documentWithElement?.removeEventListener(
          "mousedown",
          handleClickOutside
        );
      };
    }
  }, [ref, onClickOutside, addListener, elementId]);
}
