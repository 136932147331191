import React, { useState } from "react";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";

import Card from "./Card";
import { ChevronRightIcon } from "./Icons";

type CardCollapsibleProps = {
  title: string;
  children: any;
  defaultIsCollapsed?: boolean;
  titleBadge?: string;
};

const Count = styled.div`
  align-items: center;
  background-color: ${COLOR_SECONDARY_GREY_HOVER};
  border-radius: 15px;
  color: ${COLOR_WHITE};
  height: 21px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  margin-left: 15px;
  padding: 0 10px;
  display: flex;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px;
  flex: 1;
  border-bottom: solid 1px ${COLOR_GREY_ACTIVE};
`;

const BodyContainer = styled.div`
  border-top-width: 1px;
  border-top-color: ${COLOR_GREY_ACTIVE};
`;

const StyledCard = styled(Card)`
  flex-direction: column;
`;

const CardTitle = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${COLOR_TEXT_DEFAULT};
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardCollapsible = ({
  title,
  children,
  defaultIsCollapsed = true,
  titleBadge,
}: CardCollapsibleProps) => {
  const [isCollapsed, setIsCollapsed] = useState(!!defaultIsCollapsed);

  return (
    <StyledCard>
      <TitleContainer onClick={() => setIsCollapsed(!isCollapsed)}>
        <ChevronRightIcon
          style={{ marginRight: 10, cursor: "pointer" }}
          tintColor={COLOR_TEXT_DARK}
          direction={isCollapsed ? "right" : "bottom"}
        />
        <CardTitle>
          {title} {titleBadge ? <Count>{titleBadge}</Count> : null}
        </CardTitle>
      </TitleContainer>
      {!isCollapsed && <BodyContainer>{children}</BodyContainer>}
    </StyledCard>
  );
};
