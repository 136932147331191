import styled from "styled-components";

import {
  COLOR_LIGHT_BLUE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
} from "../../../colors";
import { BasicButton } from "../../Button";
import { HeaderTitleTextWrapper } from "../../ReportTableWidget/styled";

const BORDER_COLOR_SUMMARY_ROW = COLOR_LIGHT_BLUE;

export const HeaderLikeLabel = styled(HeaderTitleTextWrapper)`
  margin: 0 30px;
  justify-content: flex-start;
  color: ${COLOR_MEDIUM_GRAY};
`;

export const DataRow = styled.tr`
  height: 45px;
`;

export const SummaryStatsTd = styled.td<{
  isLastRow?: boolean;
  isAboveTable?: boolean;
}>`
  border-color: ${BORDER_COLOR_SUMMARY_ROW} !important;
  border-bottom-color: ${({ isLastRow, isAboveTable }) =>
    isLastRow && isAboveTable
      ? COLOR_TEXT_DARK
      : BORDER_COLOR_SUMMARY_ROW} !important;
`;

export const HeaderTitleButton = styled(BasicButton)`
  font-weight: 700;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
