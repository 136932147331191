import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { COLOR_LIGHT_GRAY } from "../colors";

import { Checkbox } from "./Checkbox";

type CheckboxListProps = {
  items: string[];
  selectAllLabel?: string;
  value?: string[];
  onChange(items: string[]): void;
  getDisplayValue?(item: string): string | undefined;
};

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectAllWrapper = styled.div`
  padding: 0px 15px 15px;
  margin: -10px -15px 0px;
  border-bottom: 1px solid ${COLOR_LIGHT_GRAY};
`;

export function CheckboxList({
  items = [],
  value,
  onChange,
  selectAllLabel,
  getDisplayValue,
}: CheckboxListProps) {
  const [checkedItems, setCheckedItems] = useState(value ?? []);
  const checkedItemsSet = useMemo(() => new Set(checkedItems), [checkedItems]);

  const allSelected = checkedItems.length === items.length;

  const handleAllSelected = () => {
    setCheckedItems((checkedItems) => {
      if (checkedItems.length === items.length) {
        return [];
      }

      return [...items];
    });
  };

  const handleItemCheck = (item: string, checked: boolean) => {
    setCheckedItems((checkedItems) => {
      const nextCheckedState = checked
        ? [...checkedItems, item]
        : checkedItems.filter((i) => i !== item);

      onChange?.(nextCheckedState);

      return nextCheckedState;
    });
  };

  useEffect(() => {
    setCheckedItems(value ?? []);
  }, [value]);

  return (
    <ListWrapper>
      {selectAllLabel && (
        <SelectAllWrapper>
          <Checkbox
            text={selectAllLabel}
            checked={allSelected}
            check={handleAllSelected}
          />
        </SelectAllWrapper>
      )}
      {items?.map((item) => (
        <Checkbox
          key={item}
          text={getDisplayValue?.(item) ?? item}
          check={(value) => handleItemCheck(item, value)}
          checked={checkedItemsSet.has(item)}
        />
      ))}
    </ListWrapper>
  );
}
