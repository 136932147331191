import { useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { sortList } from "../../utils/sortList";

export const useAutoSelectAthlete = () => {
  const [searchParams] = useSearchParams();
  const [{ selectedAthlete, selectedGroup }, actions] = useReportsContext();
  const user = useUserContext();

  const athletes = selectedGroup?.requiredAthletes;
  const selectedAthleteIdFromMobileApp =
    searchParams.get("selectedAthleteIdFromMobileApp") ||
    searchParams.get("focusedAthleteId") ||
    "";

  const sortedAthletes = useMemo(
    () => sortList([...(athletes ?? [])], user.language),
    [athletes, user.language]
  );

  useEffect(() => {
    const currentUser = sortedAthletes.find(
      (athlete) => athlete.id === user.id
    );

    if (selectedAthleteIdFromMobileApp) {
      const selectedAthlete = sortedAthletes.find(
        (athlete) => athlete.id === selectedAthleteIdFromMobileApp
      );
      actions.selectAthlete(selectedAthlete);

      return;
    }

    if (!user.isTrainer) {
      if (currentUser) {
        actions.selectAthlete(currentUser);
        return;
      }

      if (user) {
        actions.selectAthlete({
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          type: user.isFamilyMember ? "Family" : "Athlete",
          name: `${user.firstName} ${user.lastName}`,
        });
      }
    } else {
      if (
        !selectedAthlete ||
        !sortedAthletes.some((athlete) => athlete.id === selectedAthlete.id)
      ) {
        actions.selectAthlete(sortedAthletes[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAthlete?.id,
    sortedAthletes,
    user,
    selectedAthleteIdFromMobileApp,
  ]);

  return { sortedAthletes };
};
