import React from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DEFAULT } from "../colors";
import { makeLinksClickable } from "../utils/makeLinksClickable";

import { Loader } from "./Loader";
import StyledText from "./StyledText";

type Paragraph = {
  text?: string;
  title?: string;
};

type ExerciseInfoProps = {
  isLoading: boolean;
  paragraphs: Paragraph[];
};

const ParagraphWrapper = styled.div`
  margin: 15px 20px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled(StyledText)`
  font-size: 20px;
  color: ${COLOR_BLUE};
`;

const Text = styled(StyledText)`
  font-size: 16px;
  color: ${COLOR_TEXT_DEFAULT};
  text-align: justify;

  & > a {
    color: ${COLOR_TEXT_DEFAULT};
    text-decoration: underline;
  }
`;

export function ExerciseInfo({ isLoading, paragraphs }: ExerciseInfoProps) {
  if (isLoading) {
    return <Loader style={{ marginTop: 50 }} />;
  }

  return (
    <div>
      {paragraphs?.map(({ text, title }, index) => (
        <ParagraphWrapper key={index}>
          {!!title && <Title fontWeight="bold">{title}</Title>}
          {!!text && (
            <>
              <Text
                fontWeight="normal"
                dangerouslySetInnerHTML={{
                  __html: makeLinksClickable(text),
                }}
              />
            </>
          )}
        </ParagraphWrapper>
      ))}
    </div>
  );
}
