import styled from "styled-components";

export const HeaderButton = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
