import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const MuteIcon = memo(
  ({ width = 21, height = 21, color = "#64707A", ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...props}
    >
      <path
        d="M13 17C13 18.3807 11.8807 19.5 10.5 19.5C9.11929 19.5 8 18.3807 8 17"
        stroke="currentColor"
      />
      <path
        d="M11.6181 4C11.8556 3.73462 12 3.38418 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.38418 9.14443 3.73462 9.38195 4"
        stroke="currentColor"
      />
      <rect
        x="17.071"
        y="3.51465"
        width="1"
        height="22"
        transform="rotate(45 17.071 3.51465)"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 3.5C6.91039 3.5 4 6.41012 4 10V12.3243C4 13.5848 3.49928 14.7936 2.60798 15.6849L2.35355 15.9393C2.12718 16.1657 2 16.4727 2 16.7929C2 17.4596 2.54044 18 3.20711 18H4.00012L5.00012 17H3.20711C3.09272 17 3 16.9073 3 16.7929C3 16.738 3.02182 16.6853 3.06066 16.6464L3.31509 16.392C4.39392 15.3132 5 13.85 5 12.3243V10C5 6.96244 7.46265 4.5 10.5 4.5C12.4021 4.5 14.0788 5.46571 15.0665 6.93362L15.785 6.21516C14.6054 4.57105 12.6777 3.5 10.5 3.5ZM16.3173 7.09704L15.564 7.85033C15.8447 8.51069 16 9.23721 16 10V12.3243C16 13.85 16.6061 15.3132 17.6849 16.392L17.9393 16.6464C17.9782 16.6853 18 16.738 18 16.7929C18 16.9073 17.9073 17 17.7929 17H6.41434L5.41434 18H17.7929C18.4596 18 19 17.4596 19 16.7929C19 16.4727 18.8728 16.1657 18.6464 15.9393L18.392 15.6849C17.5007 14.7936 17 13.5848 17 12.3243V10C17 8.95668 16.7542 7.97078 16.3173 7.09704Z"
        fill="currentColor"
      />
    </svg>
  )
);
