import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { InlineComment, Split } from "../../../graphql";
//import { EyeIcon } from "../../Icons/EyeIcon";
//import { PrintIcon } from "../../Icons/PrintIcon";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOR_WHITE};
  padding: 5px 20px 5px 30px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
`;

const SectionWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

/*const IconButton = styled.button`
  padding: 7px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;*/

const ButtonLabel = styled.span`
  color: ${COLOR_BLUE};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

// const ArrowDownIcon = styled.img`
//   transform: rotate(180deg);
// `;

export function PlanTabSubHeader() {
  const { t } = useTranslation();
  const { actions: topModalActions } = useTopModalContext();

  const { practiceLines, setPracticeLines /*onClearPlanSection */ } =
    usePracticeContext();

  const onAddTextClick = () => {
    setPracticeLines([
      ...practiceLines,
      {
        id: new Date().valueOf().toString(),
        txt: "",
        __typename: "InlineComment",
        isDraft: true,
      } as InlineComment,
    ]);
  };

  const createArrayOfPickedNumber = (number: number) => {
    const newArray = [...Array(number)].map(() => {
      const id = `${Math.floor(Math.random() * 100)}`;

      return {
        athletes: [],
        id,
        comment: "",
        label: null,
        practiceLines: [],
        __typename: "SubPractice",
        isDraft: true,
      };
    });

    setPracticeLines([
      ...practiceLines,
      {
        id: new Date().valueOf().toString(),
        __typename: "Split",
        subPractices: newArray,
        isDraft: true,
      } as Split,
    ]);
  };

  const onSplitAttachments = () => {
    const params = {
      content: `${t("sessionsNumber")}:`,
      onOK: (value: number) => createArrayOfPickedNumber(value),
      closeModal: () => topModalActions.closeTopModal(),
      onCancel: () => topModalActions.closeTopModal(),
      okButtonText: t("confirm"),
      cancelButtonText: t("cancel"),
      hasInput: true,
    };

    topModalActions.openTopModal({
      modal: TopModalType.CONFIRM,
      title: t("splitPractice"),
      params,
    });
  };
  return (
    <Wrapper>
      <SectionWrapper>
        <Button onClick={onAddTextClick}>
          <ButtonLabel>{t("addText")}</ButtonLabel>
        </Button>
        <Button onClick={onSplitAttachments}>
          <ButtonLabel>{t("addSplit")}</ButtonLabel>
        </Button>
        {/* <IconButton onClick={() => console.log("Move up")}>
          <img src={ARROW_UP_ICON} />
        </IconButton>
        <IconButton onClick={() => console.log("Move down")}>
          <ArrowDownIcon src={ARROW_UP_ICON} />
        </IconButton> */}
        {/*  <Button onClick={onClearPlanSection}>
          <ButtonLabel>{t("remove")}</ButtonLabel>
        </Button>*/}
      </SectionWrapper>
      {/* <SectionWrapper>
        <IconButton onClick={() => console.log("Move up")}>
          <EyeIcon />
        </IconButton>
        <IconButton onClick={() => console.log("Move down")}>
          <PrintIcon />
        </IconButton>
      </SectionWrapper>*/}
    </Wrapper>
  );
}
