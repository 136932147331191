import React from "react";

import { COLOR_TEXT_DEFAULT } from "../../../colors";
import { ChevronRightIcon } from "../../Icons";
import { FirstLevelOptionsContentWrapper, OptionItemText } from "../styled";

interface RightOrientedFirstLevelOptionsWrapperProps {
  optionItemText: string;
  isHovered: boolean;
}

export const RightOrientedFirstLevelOptionsWrapper = ({
  optionItemText,
  isHovered,
}: RightOrientedFirstLevelOptionsWrapperProps) => (
  <FirstLevelOptionsContentWrapper isHovered={isHovered}>
    <ChevronRightIcon
      tintColor={COLOR_TEXT_DEFAULT}
      direction="left"
      strokeWidth="1"
      style={{ marginRight: "auto", marginLeft: -5, width: 17, height: 17 }}
    />
    <OptionItemText rightOriented={true}>{optionItemText}</OptionItemText>
  </FirstLevelOptionsContentWrapper>
);
