import { XpsUser } from "../graphql";

export function sortListIcelandic<T = XpsUser>(
  list: T[],
  getText: (item: T) => string
): T[] {
  const collator = new Intl.Collator("en", { sensitivity: "accent" });
  const comparer = makeComparer();

  return list
    .filter((user) => user)
    .sort((user1, user2) => collator.compare(getText(user1), getText(user2)))
    .sort((user1, user2) =>
      comparer(
        getText(user1).toLocaleLowerCase("is"),
        getText(user2).toLocaleLowerCase("is")
      )
    );
}

const IS_ALPHABET = "0123456789aábcdðeéfghiíjklmnoóprstuúvxyýzþæö";

const makeComparer = () => {
  const ap = Array.prototype;

  const max = IS_ALPHABET.length + 2;
  // mapping from character -> precedence
  const orderMap: Record<string, number> = {};
  ap.forEach.call(IS_ALPHABET, function (char, idx) {
    orderMap[char] = idx + 1;
  });

  function compareChars(l, r) {
    const lOrder = orderMap[l] || max,
      rOrder = orderMap[r] || max;

    return lOrder - rOrder;
  }

  function compareStrings(l, r) {
    const minLength = Math.min(l.length, r.length);
    const result = ap.reduce.call(
      l.substring(0, minLength),
      function (prev, _, i) {
        return prev || compareChars(l[i], r[i]);
      },
      0
    );

    return result || l.length - r.length;
  }

  return compareStrings;
};
