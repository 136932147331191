import React from "react";

import { TextareaAutosize, TextareaAutosizeProps } from "@mui/base";
import { styled as muistyled } from "@mui/material";

import { COLOR_GRAY, COLOR_BLACK, COLOR_GREY_ACTIVE } from "../../../colors";

export function TextInput(props: TextareaAutosizeProps) {
  return (
    <>
      <StyledTextarea
        {...props}
        minRows={props.minRows || 5}
        maxRows={props.maxRows || 100}
        draggable={props.draggable || false}
      />
    </>
  );
}

const StyledTextarea = muistyled(TextareaAutosize as any)(() => ({
  lineHeight: "17px",
  border: "none",
  borderRadius: "6px",
  fontSize: "14px",
  fontFamily: "'Inter', sans-serif",
  fontWeight: 400,
  resize: "none",
  outline: `1px solid ${COLOR_GREY_ACTIVE}`,
  color: COLOR_BLACK,
  backgroundColor: COLOR_GRAY,
  width: "98%",
  padding: "6px",
}));
