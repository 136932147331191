import { useState, useEffect, useCallback } from "react";

import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { useGetIdsOfSharedReports } from "../report/useGetIdsOfSharedReports";

function useAthleteReportTabs(tabReportTemplates, skip = false) {
  const user = useUserContext();

  const [athleteReportTabOptions, setAthleteReportTabOptions] = useState([]);

  const { getIdsOfSharedTabReports } = useGetIdsOfSharedReports({
    reportIds: tabReportTemplates?.map((tabReport) => tabReport.id),
  });

  const fetchSharedIds = useCallback(async () => {
    const sharedIds = await getIdsOfSharedTabReports();

    const newTabOptions = (tabReportTemplates || [])
      .map((childReport) => ({
        id: childReport.id,
        title: childReport.name.toLocaleUpperCase(),
        isVisible: isRealTrainer(user)
          ? true
          : sharedIds?.includes(childReport.id),
        isShared: isRealTrainer(user) && sharedIds?.includes(childReport.id),
      }))
      .filter((tab) => tab.isVisible);

    setAthleteReportTabOptions(newTabOptions);
  }, [getIdsOfSharedTabReports, tabReportTemplates, user]);

  useEffect(() => {
    if (!skip) {
      fetchSharedIds();
    }
  }, [fetchSharedIds, tabReportTemplates, skip]);

  return { athleteReportTabOptions, refreshAthleteReportTabs: fetchSharedIds };
}

export default useAthleteReportTabs;
