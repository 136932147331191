import React, { useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../../contexts/accessLevels";
import { XpsUser } from "../../../graphql";
import Hoverable from "../../Hoverable";
import {
  CheckmarkDoneIcon,
  ChevronRightIcon,
  RoundedGroupProfileIcon,
} from "../../Icons";
import StyledText from "../../StyledText";

interface ITeamRowProps {
  isCreateGroupChat: boolean;
  isGroup?: boolean;
  groupsCount: number;
  currentKey: string;
  showAttendingImages?: boolean;
  collapsed?: boolean;
  setCollapsed?: (newCollapsed: boolean) => void;
  onSelectAllMembersOfGroupClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onRowClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  selectedAll: boolean;
  owner: XpsUser;
  name?: string;
  withArrow?: boolean;
  withOwnerName?: boolean;
  withGroupIcon?: boolean;
  isCreateChat?: boolean;
  teamIsEmpty?: boolean;
}

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Team = styled.button`
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
  padding: 0;
`;

const StageOneWrapper = styled.div<{ hovered: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  cursor: pointer;
  background-color: ${(props) => (props.hovered ? COLOR_GRAY : COLOR_WHITE)};
`;

const IconWrapper = styled.div`
  margin-right: 15px;
`;

const CircleIconWrapper = styled.button`
  padding-left: 10px;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
`;

const EmptyCircle = styled.div`
  width: 19px;
  height: 19px;
  background: ${COLOR_GRAY};
  border-radius: 50%;
  border: 1px solid ${COLOR_GREY_ACTIVE};
`;

export const TeamRow = ({
  currentKey,
  isCreateGroupChat,
  groupsCount = 0,
  showAttendingImages = false,
  isGroup = false,
  collapsed = false,
  selectedAll,
  owner,
  name,
  withArrow = true,
  setCollapsed = () => ({}),
  onSelectAllMembersOfGroupClick,
  onRowClick,
  withOwnerName,
  withGroupIcon = true,
  isCreateChat,
  teamIsEmpty,
}: ITeamRowProps) => {
  const { t } = useTranslation();
  const { selectedAccount } = useAccessLevelsContext();

  const handleRowClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (onRowClick) {
      onRowClick(e);
    } else {
      setCollapsed(!collapsed);
    }
  };

  const teamName = useMemo(
    (): string => name || currentKey,
    [name, currentKey]
  );

  return (
    <Hoverable>
      {(hovered) => (
        <Team onClick={handleRowClick}>
          <StageOneWrapper hovered={hovered}>
            <RowWrapper>
              {withArrow ? (
                <ChevronRightIcon
                  style={{ marginRight: 10 }}
                  tintColor={COLOR_TEXT_DARK}
                  direction={collapsed ? "right" : "bottom"}
                />
              ) : withGroupIcon ? (
                <IconWrapper>
                  <RoundedGroupProfileIcon width={25} height={25} />
                </IconWrapper>
              ) : null}
              <StyledText
                color={isCreateChat ? COLOR_TEXT_DARK : COLOR_MEDIUM_GRAY}
                fontSize={14}
                style={{
                  fontFamily: "Inter",
                  fontWeight: isCreateChat ? "700" : "600",
                }}
                numberOfLines={1}
              >
                {(!!groupsCount &&
                  !!owner &&
                  selectedAccount?.id === ALL_ACCOUNTS_ID) ||
                (withOwnerName && owner)
                  ? `${owner.firstName} ${owner.lastName} - `
                  : ""}
                {teamName === "all"
                  ? t("outsideOfTeams")
                  : teamName.toUpperCase()}
              </StyledText>
            </RowWrapper>
            {!isGroup && (
              <CircleIconWrapper onClick={onSelectAllMembersOfGroupClick}>
                {isCreateGroupChat &&
                  showAttendingImages &&
                  (selectedAll ? (
                    <CheckmarkDoneIcon
                      tintColor={COLOR_GREEN}
                      strokeColor={COLOR_WHITE}
                    />
                  ) : teamIsEmpty ? null : (
                    <EmptyCircle />
                  ))}
              </CircleIconWrapper>
            )}
          </StageOneWrapper>
        </Team>
      )}
    </Hoverable>
  );
};
