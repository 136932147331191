import dayjs, { Dayjs, ManipulateType } from "dayjs";

import { TimeLinePeriod } from "../../../../../components/Report/ChartWIdget/model";
import { prettifyShortDate } from "../../../../../utils/date";
import { getPeriodStartEndUnit } from "../../../../../utils/getPeriodStartEndUnit";

export function generatePeriodCategories(
  startDate: Dayjs,
  endDate: Dayjs,
  timeLinePeriod: TimeLinePeriod,
  dateFormat: string | undefined = "MMM D"
) {
  const dateCategories: Dayjs[] = [];

  let date = dayjs(startDate).startOf(getPeriodStartEndUnit(timeLinePeriod));
  const stopDate = dayjs(endDate).endOf(getPeriodStartEndUnit(timeLinePeriod));

  while (date.isBefore(stopDate)) {
    dateCategories.push(
      date.isBefore(startDate) ? startDate.clone() : date.clone()
    );
    date = date.add(1, timeLinePeriod as ManipulateType);
  }

  return dateCategories
    .map((startDate, i) => {
      switch (timeLinePeriod) {
        case TimeLinePeriod.DAYS:
          return {
            label: prettifyShortDate(startDate.format(dateFormat)),
            start: startDate,
            end: startDate.endOf("day"),
          };
        case TimeLinePeriod.WEEKS:
          return {
            label: `${startDate.format("MMM D")} - ${startDate
              .endOf("isoWeek")
              .format("MMM D")}`,
            start: startDate,
            end: startDate.endOf("isoWeek"),
          };
        case TimeLinePeriod.MONTHS:
          return {
            label: startDate.format("MMMM YYYY").toUpperCase(),
            // Hack due to 1 second issue
            start: startDate.add(1, "hour").startOf("month"),
            end: startDate.add(1, "hour").endOf("month"),
          };
        case TimeLinePeriod.YEARS:
          return {
            label: startDate.format("YYYY"),
            start: startDate,
            end: startDate.endOf("year"),
          };
        default:
          return { label: i.toString(), start: dayjs(), end: dayjs() };
      }
    })
    .filter((value) => Boolean(value));
}
