import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useMobileModalContext } from "../../../../contexts/mobileModal/MobileModalProvider";
import { useAutoSelectAthlete } from "../../../../hooks/report/useAutoSelectAthlete";
import { BasicButton } from "../../../Button";
import { SearchBar } from "../../../SearchBar";
import {
  CloseTextButton,
  HeaderTitle,
  ModalHeaderContainer,
  ModalHeaderContent,
  ModalWrapper,
  SpacingContainer,
} from "../styled";

import { AthletePickerRow } from "./AthletePickerRow";

const AthletePickerWrapper = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const AthletePickerModal = () => {
  const { t } = useTranslation();
  const { hideModal } = useMobileModalContext();
  const { sortedAthletes } = useAutoSelectAthlete();
  const [search, setSearch] = useState<string>("");

  const filteredAthletes = sortedAthletes.filter((athlete) =>
    athlete.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <ModalWrapper>
      <ModalHeaderContainer>
        <ModalHeaderContent>
          <HeaderTitle>{t("selectAthlete")}</HeaderTitle>
        </ModalHeaderContent>
        <BasicButton onClick={hideModal}>
          <CloseTextButton>{t("close").toUpperCase()}</CloseTextButton>
        </BasicButton>
      </ModalHeaderContainer>
      <SpacingContainer>
        <SearchBar
          value={search}
          onClear={() => setSearch("")}
          onChangeText={(value) => setSearch(value)}
          iconColor={"#64707A"}
        />
      </SpacingContainer>
      <AthletePickerWrapper>
        {filteredAthletes?.map((athlete, index) => (
          <AthletePickerRow key={index} athlete={athlete} />
        ))}
      </AthletePickerWrapper>
    </ModalWrapper>
  );
};
