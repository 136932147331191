import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const InjuredIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
      <circle
        cx="17.5"
        cy="17.5"
        r="12"
        fill={isActive ? "white" : ""}
        stroke={isActive ? "#E6E8EB" : "#B1B7BC"}
      />
      <rect
        x="16"
        y="12"
        width="3"
        height="11"
        fill={isActive ? "#FF6562" : "#64707A"}
      />
      <rect
        x="23"
        y="16"
        width="3"
        height="11"
        transform="rotate(90 23 16)"
        fill={isActive ? "#FF6562" : "#64707A"}
      />
    </svg>
  )
);
