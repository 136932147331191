import dayjs, { Dayjs } from "dayjs";

import { AggregateFunction } from "../../graphql";
import * as api from "../api";
import * as endpoints from "../endpoints";

import { CombineTestResult, CompareLatestTo } from "./common";

export type FetchJsonCombinedTestResultsQueryArgs = {
  athleteGuids: string[];
  combinedTestGuid: string;
  focusedGroup?: string;
  focusedUserAccount?: string;
  fromDate: Dayjs;
  toDate: Dayjs;
  session: api.Session;
  compareLatestTo?: CompareLatestTo;
  reportTemplateId: string;
} & (
  | {
      teamAggregation: AggregateFunction;
    }
  | {
      aggregationPerAthlete: AggregateFunction;
    }
);

type CombineTestResultByAthlete = {
  _athleteGuid?: string;
  _athleteName: string;
  _firstName?: string;
  _lastName?: string;
  _results: CombineTestResult[];
};

export type FetchJsonCombinedTestResultsResponse = {
  _testName: string;
  _resultsByAthlete: CombineTestResultByAthlete[];
  _totalScoreIsSum: boolean;
};

export function fetchJsonCombinedTestResults({
  athleteGuids,
  combinedTestGuid,
  focusedGroup,
  focusedUserAccount,
  fromDate,
  toDate,
  session,
  compareLatestTo,
  reportTemplateId,
  ...rest
}: FetchJsonCombinedTestResultsQueryArgs) {
  const params: any = {
    _range: {
      //_onlyReturnLast: true
      _from: dayjs(fromDate).valueOf(),
      _to: dayjs(toDate).valueOf(),
      _onlyReturnLatest: true,
    },
    _athleteGuids: athleteGuids,
    _combinedTestGuid: combinedTestGuid,
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
    reportTemplateId,
  };

  if ("aggregationPerAthlete" in rest) {
    params._range._aggregationPerAthlete = rest.aggregationPerAthlete;
  }

  if ("teamAggregation" in rest) {
    params._aggregation = {
      _aggregateFunction: rest.teamAggregation,
    };
  }

  if (compareLatestTo) {
    params._compareLatestTo = compareLatestTo;
  }

  return api.post<FetchJsonCombinedTestResultsResponse>(
    endpoints.JsonCombinedTestResultsQuery,
    params,
    session
  );
}
