import React, { ChangeEvent } from "react";

import styled from "styled-components";

import { COLOR_RED } from "../colors";
import { roundToOne } from "../utils/number";

import { BasicButton } from "./Button";
import { ChevronDownIcon } from "./Icons";
import { Label } from "./Label";

type StyledNumberInputProps = {
  value: number;
  onChange: (newValue: number) => void;
  label?: string;
  step?: number;
  roundPrecision?: number;
  min?: number;
  max?: number;
  defaultValueOnArrowClick?: number;
  inputWidth?: number;
  name?: string;
  isInvalid?: boolean;
  displayZero?: boolean;
};

const InputWithStyles = styled.input<{ inputWidth?: number }>`
  height: 100%;
  border: 0;
  background-color: #f5f6f8;
  font-weight: bold;
  box-sizing: border-box;
  flex: 1;
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "auto")};

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 45px;
  min-width: 95px;
  background-color: #f5f6f8;
  border-radius: 6px;
  padding: 0 10px 0 20px;
  border-width: 1px;
  border-color: transparent;
`;

const ArrowSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
`;

export const StyledNumberInput = ({
  onChange,
  value,
  label = "",
  step = 1,
  min = -1,
  max,
  defaultValueOnArrowClick,
  inputWidth,
  name,
  isInvalid,
  displayZero = false,
}: StyledNumberInputProps) => {
  const handleInputChange = (
    newValue: number,
    changedByArrow: boolean = false
  ) => {
    if (
      value === undefined &&
      defaultValueOnArrowClick !== undefined &&
      changedByArrow
    ) {
      onChange(defaultValueOnArrowClick);
      return;
    }
    if (newValue < min || newValue > max) {
      return;
    }

    onChange(newValue);
  };

  const onArrowUpClick = () =>
    handleInputChange(roundToOne(isNaN(value) ? step : value + step), true);

  const onArrowDownClick = () =>
    handleInputChange(roundToOne(isNaN(value) ? -step : value - step), true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value
      ? Number(event.currentTarget.value)
      : null;
    handleInputChange(newValue);
  };

  return (
    <>
      {label && <Label>{label}</Label>}

      <InputWrapper style={isInvalid ? { borderColor: COLOR_RED } : {}}>
        <InputWithStyles
          onChange={handleChange}
          value={
            ((value === 0 && !displayZero) || isNaN(value) ? "" : value) ?? ""
          }
          type="number"
          min={min}
          max={max}
          inputWidth={inputWidth}
          name={name}
        />
        <ArrowSectionWrapper>
          <BasicButton onClick={onArrowUpClick}>
            <ChevronDownIcon height={11} direction="top" />
          </BasicButton>
          <BasicButton onClick={onArrowDownClick}>
            <ChevronDownIcon height={11} />
          </BasicButton>
        </ArrowSectionWrapper>
      </InputWrapper>
    </>
  );
};
