import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const NewDocumentIcon = memo(
  ({
    width = 13,
    height = 15,
    tintColor = "#3C434C",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path
        d="M10.1464 2.85355L10.5 3.20711L11.2071 2.5L10.8536 2.14645L10.1464 2.85355ZM8.93934 0.93934L8.58579 1.29289L8.93934 0.93934ZM2 1H7.87868V0H2V1ZM11 14H2V15H11V14ZM1 13V2H0V13H1ZM12 8V13H13V8H12ZM8.58579 1.29289L10.1464 2.85355L10.8536 2.14645L9.29289 0.585786L8.58579 1.29289ZM2 14C1.44772 14 1 13.5523 1 13H0C0 14.1046 0.895431 15 2 15V14ZM11 15C12.1046 15 13 14.1046 13 13H12C12 13.5523 11.5523 14 11 14V15ZM7.87868 1C8.1439 1 8.39825 1.10536 8.58579 1.29289L9.29289 0.585786C8.91782 0.210714 8.40911 0 7.87868 0V1ZM2 0C0.89543 0 0 0.895431 0 2H1C1 1.44772 1.44772 1 2 1V0Z"
        fill={tintColor}
      />
      <path
        d="M8 4.5V5H9V4.5H8ZM9 1C9 0.723858 8.77614 0.5 8.5 0.5C8.22386 0.5 8 0.723858 8 1H9ZM9 4.5V1H8V4.5H9Z"
        fill={tintColor}
      />
      <path d="M3.5 4.5H5.5" stroke={tintColor} strokeLinecap="square" />
      <path d="M3.5 7.5H9.5" stroke={tintColor} strokeLinecap="square" />
      <path d="M3.5 10.5H9.5" stroke={tintColor} strokeLinecap="square" />
    </svg>
  )
);
