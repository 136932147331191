import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../colors";
import { ButtonColor, GeneralButton } from "../../Button/";

const Container = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${COLOR_WHITE};
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;

export const SelectAthletesFromGroupFooter = ({
  closeModal,
  onClear,
  onSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <GeneralButton
        colorVariant={ButtonColor.Red}
        label={t("clear")}
        onClick={onClear}
      />
      <Row>
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={closeModal}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("ok")}
          onClick={onSubmit}
        />
      </Row>
    </Container>
  );
};
