import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import { COLOR_BLUE, COLOR_GREY_ACTIVE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { useReportTemplatesQuery } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { sortList } from "../../utils/sortList";
import { Loader } from "../Loader";

import ReportsListItem from "./ReportsListItem";
import { ColumnNameText, NameText, NoDataForListingMessage } from "./styled";

const EmptyListWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const EmptyHeaderItem = styled.div`
  display: flex;
  width: 21px;
  height: 21px;
`;

const ReportListContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  border-radius: 12px;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  shadow-opacity: 0.05;
  shadow-radius: 6px;
  shadow-offset: 0px 2px;
  shadow-color: #223e6a;
`;

const ReportListContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 11px 20px;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
`;

const ReportsList = ({ reportType, searchQuery }) => {
  const [curItem, setCurItem] = useState(null);
  const { sessionId, language } = useUserContext();
  const { t } = useTranslation();
  const { isMobile } = useTheme();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const {
    data: reportsData,
    loading: loadingReports,
    refetch: refetchReports,
  } = useReportTemplatesQuery({
    variables: {
      sessionId,
      accountId: selectedAccountId,
      reportType,
      language,
    },
  });

  const onDeleteReportFinish = () => {
    refetchReports();
  };

  const reports = useMemo(
    () =>
      sortList(
        reportsData?.reportTemplates.filter((report) =>
          report.name.toLowerCase().includes(searchQuery.toLowerCase())
        ) ?? [],
        language
      ),
    [language, reportsData?.reportTemplates, searchQuery]
  );

  return (
    <ReportListContainer>
      <ReportListContentWrapper>
        <NameText>{t("name").toUpperCase()}</NameText>
        <ColumnNameText>{t("createdBy").toUpperCase()}</ColumnNameText>
        {!isMobile && (
          <ColumnNameText>{t("lastUpdated").toUpperCase()}</ColumnNameText>
        )}
        {!isMobile && <EmptyHeaderItem />}
      </ReportListContentWrapper>
      {!loadingReports && reports.length > 0 ? (
        reports.map((report, index) => (
          <ReportsListItem
            // @ts-ignore
            report={report}
            onDeleteReportFinish={onDeleteReportFinish}
            deletingReport={false}
            isLast={index === reports.length - 1}
            key={report.id}
            curItem={curItem}
            setCurItem={setCurItem}
          />
        ))
      ) : (
        <EmptyListWrapper>
          {loadingReports ? (
            <Loader color={COLOR_BLUE} size="small" />
          ) : (
            <NoDataForListingMessage>
              {t("noReportsFound").toUpperCase()}
            </NoDataForListingMessage>
          )}
        </EmptyListWrapper>
      )}
    </ReportListContainer>
  );
};

export default ReportsList;
