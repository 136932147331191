import styled from "styled-components";

import { COLOR_WHITE } from "../colors";

const Card = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 6px rgba(41, 28, 10, 0.05);
  border: 1px solid #e8e9ec;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  box-sizing: border-box;
`;

export default Card;
