import React from "react";

import styled from "styled-components";

import {
  MobileModalTypes,
  useMobileModalContext,
} from "../../../contexts/mobileModal/MobileModalProvider";
import { UserGroupIcon } from "../../Icons";

const ImageContainer = styled.div`
  width: 35px;
  height: 35px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 50%;
  background-color: white;
`;

export function AthleteSelectorOnMobile() {
  const { showModal } = useMobileModalContext();

  return (
    <ImageContainer
      onClick={() => {
        showModal(MobileModalTypes.ATHLETE_PICKER);
      }}
    >
      <UserGroupIcon isActive />
    </ImageContainer>
  );
}
