import React from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";

import { Checkbox } from "../../../Checkbox";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { StyledNumberInput } from "../../../StyledNumberInput";

import { ActivityLogWidgetFormValue } from "./model";

const DATA_SELECTORS = [
  "workoutsCompleted",
  "testsRegistered",
  "informationRegistered",
  "somethingRead",
] as const;

export const DataSection = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<ActivityLogWidgetFormValue>();

  return (
    <>
      <Section>
        <SectionTitle title={t("maxEntryCount")} />
        <Field
          name="maxCount"
          validate={(value) => {
            if (value <= 0) {
              return "required";
            }
          }}
        >
          {({ field, form, meta }) => (
            <StyledNumberInput
              value={field.value}
              onChange={(value) => form.setFieldValue(field.name, value)}
              name={field.name}
              isInvalid={Boolean(meta.error)}
            />
          )}
        </Field>
      </Section>
      <Section>
        <SectionTitle title={t("data")} />
        {DATA_SELECTORS?.map((selector) => (
          <Checkbox
            key={selector}
            text={t(selector)}
            check={(value) => setFieldValue(selector, value)}
            checked={values[selector]}
          />
        ))}
      </Section>
    </>
  );
};
