import React from "react";

import { ReportWidgetTemplateHeader } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks/useGetSelectedAccountIdInMonitoring";
import { ProfileInfoSettingsSection } from "../Report/AthleteHeaderSettings/ProfileInfoSettingsSection";

interface AddAthleteHeaderInfoModalProps {
  parentReportId: string;
  headerTemplate: ReportWidgetTemplateHeader;
}

export const AddAthleteHeaderInfoModal = ({
  parentReportId,
  headerTemplate,
}: AddAthleteHeaderInfoModalProps) => {
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  return (
    <ProfileInfoSettingsSection
      parentReportId={parentReportId}
      accountId={selectedAccountId}
      headerTemplate={headerTemplate}
    />
  );
};
