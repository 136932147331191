import { useNavigate } from "react-router-dom";

import { useIsMonitoring } from "../../hooks/useIsMonitoring";
import { BASE_ROUTES } from "../RoutingConfig";

export const useRedirectToModuleBaseScreen = () => {
  const navigate = useNavigate();
  const isFromMonitoring = useIsMonitoring();

  // We should move to nested routing in the future and benefit of nested navigation
  const redirectToModuleBaseScreen = () => {
    // Keep in mind, we do not want to throw user away from edit report if it is visited from monitoring
    // as it would throw him to reports screen and it is messy
    // we need to pass fromMonitoring url search param through navigation if it is the case
    if (isFromMonitoring) {
      return;
    }

    const { pathname } = window.location;

    const baseScreen = pathname.startsWith("/groupReport")
      ? BASE_ROUTES.ListReports
      : Object.values(BASE_ROUTES).find((prefix) =>
          pathname.startsWith(`/${prefix}`)
        );

    baseScreen && navigate(`/${baseScreen}`);
  };

  return redirectToModuleBaseScreen;
};
