import React, { memo } from "react";

export const LogoutIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path d="M16.5 13.5L19.5 10.5L16.5 7.5" stroke="white" />
    <path d="M19.5 10.5H9" stroke="white" />
    <path
      d="M14.5 7L14.5 3.5C14.5 2.94772 14.0523 2.5 13.5 2.5L5.5 2.5C4.94772 2.5 4.5 2.94771 4.5 3.5L4.5 17.5C4.5 18.0523 4.94772 18.5 5.5 18.5L13.5 18.5C14.0523 18.5 14.5 18.0523 14.5 17.5L14.5 14"
      stroke="white"
    />
  </svg>
));
