import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { Field, Formik } from "formik";

import { ConnectedFocusError } from "../components/FormikFocusError";
import { Header } from "../components/Header";
import { Loader } from "../components/Loader";
import {
  PerformerSection,
  PerformerSelectionMode,
  PerformerSelectionWidgetType,
} from "../components/Report/PerformerSection";
import { PeriodSection } from "../components/Report/PeriodSection";
import { PreviewButtonSection } from "../components/Report/PreviewButtonSection";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import {
  FieldErrorMessage,
  FormContainer,
  FormWrapper,
  MaxPerformersCountWrapper,
} from "../components/Report/styled";
import { WidgetEditorTitleSection } from "../components/Report/WidgetEditorTitleSection";
import { AppearanceSection } from "../components/ReportCreateTable/AppearanceSection";
import { ColorIndicatorSection } from "../components/ReportCreateTable/ColorIndicatorSection";
import { DefaultSortingSectionGroupTable } from "../components/ReportCreateTable/DefaultSortingSection/GroupTable";
import {
  DEFAULT_COLUMN,
  GroupColumnsSection,
} from "../components/ReportCreateTable/GroupColumnsSection/GroupColumnsSection";
import { GroupTablePreview } from "../components/ReportCreateTable/GroupTablePreview";
import {
  CreateGroupTableFormValue,
  WidgetType,
} from "../components/ReportCreateTable/models";
import { GroupTableRulesSection } from "../components/ReportCreateTable/Rules/GroupTableRulesSection";
import { SectionWrapperForColorPicker } from "../components/ReportCreateTable/styled";
import { SummaryStatsSection } from "../components/ReportCreateTable/SummaryStatsSection";
import ScreenContainer from "../components/ScreenContainer";
import { Section } from "../components/Section";
import { SectionTitle } from "../components/SectionTitle";
import { BackgroundColoringSection } from "../components/SharedWidgetEditorSections/BackgroundColoringSection";
import { StyledNumberInput } from "../components/StyledNumberInput";
import { useAccessLevelsContext } from "../contexts/accessLevels";
import { useUserContext } from "../contexts/User";
import {
  AggregateFunction,
  useSaveWidgetTemplateGroupTableMutation,
  useWidgetTemplateQuery,
  WidgetTemplatesDocument,
} from "../graphql";
import {
  useAddWidgetToReport,
  useGetSelectedAccountIdInMonitoring,
  useNavigateToPreviousPage,
  useWidgetEditorUrlParams,
} from "../hooks";
import { useTableAppearancePreference } from "../hooks/table/useTableAppearancePreference";
import { PeriodType } from "../models";
import { isAttendanceCollection } from "../utils/reports";
import { getValidUnitNameFromReportWidgetCell } from "../utils/statistics";
import {
  buildPreferencesString,
  GroupTablePreferences,
  parseGroupTablePreferencesObject,
} from "../utils/widgetPreferencesBuilder";
export function GroupTableEditor() {
  const { t } = useTranslation();
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
  } = useWidgetEditorUrlParams();

  const { appearancePreferences, saveTableAppearancePreference } =
    useTableAppearancePreference();

  const formikRef = React.useRef<any>();
  const { selectedAccount } = useAccessLevelsContext();
  const { sessionId, language, firstName, lastName } = useUserContext();
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(true);

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );
  const redirectToPreviousPage = useNavigateToPreviousPage();

  const [saveWidgetTemplateGroupTable, { loading: isSavingWidget }] =
    useSaveWidgetTemplateGroupTableMutation({
      refetchQueries: [
        {
          query: WidgetTemplatesDocument,
          variables: {
            sessionId,
            accountId: selectedAccountId,
            parentReport: parentReportId || reportId,
          },
        },
      ],
    });

  const { data: templateData, loading: loadingGroupTableData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const hasLoadedAllTemplatesData = useCallback(
    (values: CreateGroupTableFormValue) => {
      values.columns.every(
        ({ measurementTemplateId }) => measurementTemplateId
      );
    },
    []
  );

  useEffect(() => {
    if (hasLoadedAllTemplatesData) {
      setIsLoadingInitialData(false);
    }
  }, [hasLoadedAllTemplatesData]);

  if (loadingGroupTableData) {
    return <ReportEditorLoading />;
  }

  const getInitialFormValues = (): CreateGroupTableFormValue => {
    if (widgetId && templateData) {
      const reportWidget = templateData.reportWidgetTemplateById;
      const preferences = parseGroupTablePreferencesObject(
        reportWidget.__typename === "ReportWidgetTemplateGroupTable"
          ? reportWidget.preferences
          : "{}"
      );

      return {
        // @ts-ignore
        title: reportWidget.name,
        columns: (reportWidget.__typename === "ReportWidgetTemplateGroupTable"
          ? reportWidget.columnHeaders
          : []
        ).map((column, index) => ({
          collectionId: column.collectionId,
          aggregateFunctions: column.aggregateFunctions,
          measurementTemplateId: column.templateId,
          measurementTemplateName: "",
          attributeTemplateId:
            "attributeTemplate" in column
              ? column.attributeTemplate.id
              : undefined,
          attributeTemplateName:
            "attributeTemplate" in column
              ? column.attributeTemplate.name
              : undefined,
          attributeTemplateUnitName:
            getValidUnitNameFromReportWidgetCell(column),
          ...preferences.additionalColumnData[index],
        })),
        ...preferences,
      };
    } else {
      return {
        title: "",
        columns: [DEFAULT_COLUMN],
        sortBy: {
          attributeIndex: null,
          valueIndex: null,
          statsIndex: null,
        },
        rgbIndicator: null,
        sortingOrder: null,
        averageRow: false,
        averageWithoutZeroes: false,
        sumRow: false,
        coloringConfig: [],
        period: {
          type: PeriodType.SELECTION,
          from: null,
          to: null,
        },
        performerSelectionMode: PerformerSelectionMode.ATHLETE_SELECTION,
        athletes: [],
        colorStartDate: null,
        ...appearancePreferences,
      };
    }
  };

  const onSubmit = async (values: CreateGroupTableFormValue) => {
    const { title, columns, ...rest } = values;

    const appearancePreferences = {
      alternatingValues: rest.alternatingValues,
      alternatingColumns: rest.alternatingColumns,
      widgetBorders: rest.widgetBorders,
      rowDividers: rest.rowDividers,
      columnDividers: rest.columnDividers,
      outsideBorderDividers: rest.outsideBorderDividers,
      showValuesAsText: rest.showValuesAsText,
    };

    const preferences: GroupTablePreferences = {
      sortingOrder: rest.sortingOrder,
      period: rest.period,
      performerSelectionMode: rest.performerSelectionMode,
      athletes: rest.athletes,
      teams: rest.teams,
      rules: rest.rules,
      sortBy: rest.sortBy,
      rgbIndicator: rest.rgbIndicator,
      sumRow: rest.sumRow,
      averageRow: rest.averageRow,
      averageWithoutZeroes: rest.averageWithoutZeroes,
      maxCount: rest.maxCount,
      coloringConfig: rest.coloringConfig,
      colorStartDate: rest.colorStartDate,
      ...appearancePreferences,
    };

    try {
      const response = await saveWidgetTemplateGroupTable({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              title ||
              (isWidgetTemplate ? t("defaultTeamTableWidgetName") : ""),
            columnHeaders: columns.map(
              ({
                measurementTemplateId,
                attributeTemplateId,
                collectionId,
                aggregateFunctions,
              }) => ({
                itemId: measurementTemplateId,
                collectionId,
                attributeTemplateId,
                aggregateFunctions,
              })
            ),
            preferences: buildPreferencesString({
              ...preferences,
              type: WidgetType.TABLE,
              additionalColumnData: columns.map(
                ({ showValue, showTrend, comparison }, index) => ({
                  id: index,
                  showValue,
                  showTrend,
                  comparison,
                })
              ),
              updatedAt: dayjs(),
              createdBy: `${firstName} ${lastName}`,
            }),
            reportTemplateId: reportId || parentReportId,
          },
        },
      });

      await saveTableAppearancePreference(appearancePreferences);

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateGroupTable?.id
      ) {
        await addWidgetToReport(response.data.saveWidgetTemplateGroupTable.id);
      }

      redirectToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <Formik<CreateGroupTableFormValue>
        enableReinitialize={false} // FIXME
        initialValues={getInitialFormValues()}
        innerRef={formikRef}
        onSubmit={onSubmit}
        validate={(values) => {
          if (values.columns?.length < 1) {
            return { columns: t("allFieldsOfColumnsRequired") };
          }

          if (
            !isNaN(values.maxCount) &&
            (!values.sortBy || !values.sortingOrder)
          ) {
            return {
              sortBy: t("notDefinedSorting"),
            };
          }
        }}
      >
        {({ values, handleChange, submitForm, setFieldValue }) => {
          hasLoadedAllTemplatesData(values);

          return (
            <>
              {!hasLoadedAllTemplatesData &&
                isLoadingInitialData &&
                widgetId && <Loader size="large" />}
              <FormContainer
                hasLoadedAllTemplatesData={
                  !!(
                    hasLoadedAllTemplatesData ||
                    !widgetId ||
                    !isLoadingInitialData
                  )
                }
              >
                <FormWrapper shouldBeWider>
                  <ConnectedFocusError />

                  <WidgetEditorTitleSection
                    value={values.title}
                    handleChange={handleChange}
                  />

                  <GroupColumnsSection />

                  <SummaryStatsSection />

                  <SectionWrapperForColorPicker>
                    <GroupTableRulesSection />
                  </SectionWrapperForColorPicker>

                  <DefaultSortingSectionGroupTable />

                  <ColorIndicatorSection />

                  {selectedAccount?.poscitt ? (
                    <BackgroundColoringSection
                      dataAccessor="columns"
                      hasPeriod
                      measurementTemplateIdAccessor="measurementTemplateId"
                      filterOptions={({ aggregation, collectionId }) =>
                        (aggregation === AggregateFunction.Latest ||
                          aggregation === AggregateFunction.LastDayInRange) &&
                        !isAttendanceCollection(collectionId)
                      }
                    />
                  ) : null}

                  <Section>
                    <SectionTitle title={t("maximalCountOfPerformers")} />
                    <Field
                      name="maxCount"
                      validate={(value) => {
                        if (value < 0) {
                          return t("minLimit", {
                            y: 1,
                            x: t("maximalCountOfPerformers").toLowerCase(),
                          });
                        }
                      }}
                    >
                      {({ field, form, meta }) => (
                        <>
                          <MaxPerformersCountWrapper>
                            <StyledNumberInput
                              value={field.value ?? undefined}
                              onChange={(value) =>
                                form.setFieldValue(field.name, value)
                              }
                              name={field.name}
                              min={0}
                              inputWidth={70}
                              defaultValueOnArrowClick={10}
                              isInvalid={Boolean(meta.error)}
                            />
                          </MaxPerformersCountWrapper>
                          <FieldErrorMessage>{meta.error}</FieldErrorMessage>
                        </>
                      )}
                    </Field>
                  </Section>

                  <AppearanceSection
                    widgetType={WidgetType.TABLE}
                    alternatingValues={values.alternatingValues}
                    alternatingColumns={values.alternatingColumns}
                    rowDividers={values.rowDividers}
                    columnDividers={values.columnDividers}
                    widgetBorders={values.widgetBorders}
                    outsideBorderDividers={values.outsideBorderDividers}
                    showValuesAsText={values.showValuesAsText}
                    setFieldValue={setFieldValue}
                  />

                  <PerformerSection
                    performerSelectionWidgetType={
                      PerformerSelectionWidgetType.GROUP
                    }
                    setFieldValue={setFieldValue}
                    performerSelectionMode={values.performerSelectionMode}
                    withTeamSelection
                  />

                  <PeriodSection
                    setFieldValue={setFieldValue}
                    period={values.period}
                    showCalendarAboveInput={false}
                  />
                </FormWrapper>

                <PreviewButtonSection
                  isSaving={isSavingWidget}
                  onCancelClick={redirectToPreviousPage}
                  onSaveClick={submitForm}
                  withAutoWidth
                >
                  <GroupTablePreview />
                </PreviewButtonSection>
              </FormContainer>
            </>
          );
        }}
      </Formik>
    </ScreenContainer>
  );
}

export default GroupTableEditor;
