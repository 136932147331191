import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { ApRerouteContext } from "../../contexts/athleteProfile/ApRerouteProvider";
import { getAthleteInitials } from "../../utils/statistics";
import { BasicButton } from "../Button";
import { ColorIndicator } from "../ReportTableWidget/styled";
import { PeriodizationTableType } from "../TablePeriodization/Widget/Table";

type ItemProps = {
  alternatingRow?: boolean;
  athleteId?: string;
  athleteName: string;
  rgbIndicator?: string;
  colorIndicator?: string;
  index: number;
  type?: PeriodizationTableType;
  itemHeight?: number;
};

const AthleteContainer = styled.div<{
  color: string;
  type?: PeriodizationTableType;
  itemHeight?: string | number;
}>`
  min-height: ${({ type, itemHeight }) => `${type ? 46 : itemHeight}px`};
  max-height: ${({ type, itemHeight }) => `${type ? 46 : itemHeight}px`};
  padding-left: 15px;
  padding-right: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
  background-color: ${({ color }) => color};
  color: ${COLOR_TEXT_DEFAULT};
`;

const Label = styled.p`
  white-space: nowrap;
  font-weight: 700;
  font-size: 12px;
  color: ${COLOR_TEXT_DARK};
  font-family: "Inter", sans-serif;
`;

export function Item({
  alternatingRow,
  athleteId,
  athleteName,
  colorIndicator,
  index,
  rgbIndicator,
  type,
  itemHeight,
}: ItemProps) {
  const { t } = useTranslation();
  const apReroute = useContext(ApRerouteContext);
  const rowColor = alternatingRow && index % 2 === 0 ? COLOR_GRAY : COLOR_WHITE;

  const onRowItemClick = () =>
    apReroute?.onReroute && apReroute.onReroute(athleteId);

  const displayName = useMemo(() => {
    if (athleteName.toLocaleLowerCase() === t("average").toLowerCase()) {
      return "AVG";
    }

    if (athleteName.toLocaleLowerCase() === t("sumRow").toLowerCase()) {
      return athleteName;
    }

    return getAthleteInitials(athleteName);
  }, [athleteName, t]);

  return (
    <AthleteContainer color={rowColor} type={type} itemHeight={itemHeight}>
      {colorIndicator && <ColorIndicator color={`#${rgbIndicator}`} />}
      <BasicButton onClick={onRowItemClick}>
        <Label>{displayName}</Label>
      </BasicButton>
    </AthleteContainer>
  );
}
