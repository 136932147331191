export enum EventType {
  EVENT = "event",
  TEAM_EVENT = "teamevent",
  GAME = "game",
  TEAM_GAME = "teamgame",
  PRACTICE = "practice",
  WORKOUT = "workout",
}

export const FOLDER = "Folder2023";
export const GROUP = "Group";
export const SUB_GROUP = "SubGroup";
