import React, { useState } from "react";

import styled from "styled-components";

import { usePracticeContext } from "../../contexts/practice/PracticeProvider";
import { useUpdateSubPracticeParticipationMutation } from "../../graphql";

import { SelectAthletesFromGroupBody } from "./SelectAthletesFromGroup/Body";
import { SelectAthletesFromGroupFooter } from "./SelectAthletesFromGroup/Footer";
import { SelectAthletesFromGroupHeader } from "./SelectAthletesFromGroup/Header";

const Container = styled.div`
  flex: 1;
  height: 90vh;
  width: auto;
  overflow-y: auto;

  @media (min-width: 1000px) {
    width: 40vw;
  }
`;

export const SelectAthletesFromGroupModal = ({
  closeModal,
  attendingAthletes = [],
  teamName,
  subPracticeId,
  isExistingSession,
  availableAthletes,
  isDraft,
}) => {
  const [search, setSearch] = useState("");
  const [selectedAthletes, setSelectedAthletes] = useState(
    attendingAthletes ?? []
  );
  const { setPracticeLines } = usePracticeContext();
  const [updateSubPracticeParticipationMutation] =
    useUpdateSubPracticeParticipationMutation();

  const onSubmit = async () => {
    if (subPracticeId) {
      if (isExistingSession && !isDraft) {
        await updateSubPracticeParticipationMutation({
          variables: {
            input: {
              added: selectedAthletes
                .filter(
                  (selectedAthlete) =>
                    !attendingAthletes.some(
                      (attendingAthlete) =>
                        attendingAthlete.id === selectedAthlete.id
                    )
                )
                .map((a) => a.id),
              removed: attendingAthletes
                .filter(
                  (attendingAthlete) =>
                    !selectedAthletes.some(
                      (selectedAthlete) =>
                        attendingAthlete.id === selectedAthlete.id
                    )
                )
                .map((a) => a.id),
              subPracticeID: subPracticeId,
            },
          },
        });
      }

      setPracticeLines((current) => {
        const updated = [...current];
        const itemIndex = updated.findIndex(
          (line) =>
            "subPractices" in line &&
            line?.subPractices &&
            line.subPractices.some((sp) => sp.id === subPracticeId)
        );

        if (itemIndex > -1) {
          const updatedItem = { ...updated[itemIndex] };
          if ("subPractices" in updatedItem) {
            updatedItem.subPractices = [
              ...(updatedItem.subPractices ?? []),
            ].map((subPractice) => {
              if (subPractice.id === subPracticeId) {
                return {
                  ...subPractice,
                  athletes: selectedAthletes,
                };
              }
              return subPractice;
            });
          }
          updated[itemIndex] = updatedItem;
        }
        return updated;
      });

      closeModal();
    }
  };

  return (
    <Container>
      <SelectAthletesFromGroupHeader setSearch={setSearch} search={search} />
      <SelectAthletesFromGroupBody
        search={search}
        selectedAthletes={selectedAthletes}
        setSelectedAthletes={setSelectedAthletes}
        teamName={teamName}
        availableAthletes={availableAthletes}
      />
      <SelectAthletesFromGroupFooter
        closeModal={closeModal}
        onSubmit={onSubmit}
        onClear={() => setSelectedAthletes([])}
      />
    </Container>
  );
};
