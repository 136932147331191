import styled from "styled-components";

import {
  COLOR_ALMOST_BLACK,
  COLOR_LIGHT_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../colors";

// Modal section
export const ModalWrapper = styled.div`
  height: 85vh;
  width: 85vw;
  display: flex;
  flex-direction: row;
`;

export const PreviewSection = styled.div`
  display: inline-block;
  width: 70%;
  overflow-y: auto;
  background-color: ${COLOR_LIGHT_GRAY};
`;

export const PreviewWrapper = styled.div<{ landscapeOrientation?: boolean }>`
  width: 70%;
  margin: 10% auto;
  background-color: ${COLOR_WHITE};
  min-height: ${({ landscapeOrientation }) =>
    landscapeOrientation ? 210 : 297}mm;
  width: ${({ landscapeOrientation }) => (landscapeOrientation ? 297 : 210)}mm;
`;

// NEVER CHANGE DISPLAY AND WIDTH IN THIS COMPONENT AS IT IS IMPORTANT FOR CORRECT PRINTING BECAUSE IT IS ROOT OF PRINTING
export const Preview = styled.div`
  display: inline-block;
  width: 100%;
`;
// ----

// Print header section
export const ReportTitle = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${COLOR_ALMOST_BLACK};
  margin: 0;
`;

export const PeriodTitle = styled.p`
  font-size: 16px;
  color: ${COLOR_MEDIUM_GRAY};
  margin: 0;
`;

export const SecondaryTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_ALMOST_BLACK};
  margin: 32px 0 6px 0;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const LogoImage = styled.img<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const HeaderWrapper = styled.div<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: row;
  border-bottom-width: ${({ noBorder }) => (noBorder ? 0 : 1)}px;
  border-bottom-color: ${COLOR_LIGHT_GRAY};
  border-bottom-style: solid;
  padding: 20px 40px 0;
`;
// ----

// Control section
export const ControlSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  background-color: ${COLOR_WHITE};
  padding: 30px 10px;
  justify-content: space-between;
  flex: 1;
`;

export const ControlsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  margin-bottom: 30px;
  padding: 0 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 20px;
  gap: 15px;
`;

export const Header = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #3c434c;
  margin: 0;
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
`;

export const Title = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #64707a;
  text-transform: uppercase;
  margin: 0;
`;

export const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
// ----
