import { Action, State } from "./BodyChartItem";

type Part = {
  bodypartId: string;
  categoryId: string;
  comment: string;
};

export enum ActionTypes {
  PART_PRESSED = "PART_PRESSED",
  INIT = "INIT",
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.PART_PRESSED: {
      const prevCategoryNum =
        state.registrations[action.id]?.categoryNum ?? state.categoryCount;
      const newCategoryNum = (prevCategoryNum + 1) % (state.categoryCount + 1);
      const newCategory = state.categories[newCategoryNum];
      const registrations = { ...state.registrations };

      if (newCategory) {
        registrations[action.id] = {
          categoryId: newCategory.categoryId,
          categoryNum: newCategoryNum,
          color: `rgb(${newCategory.red255}, ${newCategory.green255}, ${newCategory.blue255})`,
        };
      } else {
        delete registrations[action.id];
      }

      return {
        ...state,
        registrations,
      };
    }
    case ActionTypes.INIT: {
      const registrations = {};
      const parts = action.item.bcRegistration?.parts;
      const categories = action.item.bcTemplate?.categories;

      parts?.map((part: Part) => {
        const categoryNum = categories.findIndex(
          (cat) => cat.categoryId === part.categoryId
        );
        const category = categories[categoryNum];

        if (!category) {
          console.warn(
            `BodyChart category "${part?.categoryId}" not found in template "${action?.item?.bcTemplate?.templateGuid}"`
          );
          return null;
        }

        registrations[part.bodypartId] = {
          categoryId: part.categoryId,
          categoryNum,
          color: `rgb(${category.red255}, ${category.green255}, ${category.blue255})`,
        };
      });

      return {
        isInitialized: true,
        categoryCount: categories?.length || 0,
        categories,
        registrations,
      };
    }
  }

  return state;
};
