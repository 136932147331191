import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { useReportBuildingBodyChart } from "../../../hooks/data/useReportBuildingBodyChart";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField } from "../../SelectField";

const FieldWrapper = styled.div`
  display: block;
  width: 50%;
`;

export function BodyChartSection() {
  const { t } = useTranslation();
  const { data } = useReportBuildingBodyChart();

  const bodyChartOptions = useMemo(
    () =>
      data?.reportBuildingBodyChartTemplates?.map((bodyChartTemplate) => {
        return {
          label: bodyChartTemplate?.name,
          value: bodyChartTemplate?.templateGuid,
        };
      }),
    [data?.reportBuildingBodyChartTemplates]
  );

  return (
    <Section>
      <SectionTitle title={t("bodyChart")} />
      <FieldWrapper>
        <Field name="bodyChartTemplateID">
          {(props: FieldProps) => (
            <SelectField
              {...props}
              // @ts-ignore
              options={bodyChartOptions}
            />
          )}
        </Field>
      </FieldWrapper>
    </Section>
  );
}
