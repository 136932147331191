import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import { COLOR_GRAY, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../../../colors";
import { unbreakableCss } from "../../../PrintUnbreakableElement";
import { Arrow, Direction, SortIconWrapper } from "../../../styled";
import { WidgetTitle, WidgetTitleContainer } from "../../styled";
import { WidgetNoData } from "../../WidgetNoData";

import { AttendanceLogTableRow } from "./AttendanceLogTableRow";
import { AttendanceLogDataItem } from "./model";
import { HeaderCell } from "./styled";

interface AttendanceLogTableProps {
  data: AttendanceLogDataItem[];
  name: string;
  alternatingRow: boolean;
  rowDividers: boolean;
  columnDividers: boolean;
  sortingOrder: number;
  resizerRef?: any;
  maxCount?: number;
}

const HeaderRow = styled.tr`
  height: 35px;
  text-transform: uppercase;
  padding: 10px 0;

  ${unbreakableCss};
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const StyledTableComponent = styled.table`
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
  height: fit-content;

  & td > *,
  & th > *:first-child {
    padding: 0 30px;
  }
`;

export const AttendanceLogTable = ({
  data,
  name,
  rowDividers,
  columnDividers,
  sortingOrder,
  resizerRef,
  maxCount,
}: AttendanceLogTableProps) => {
  const { t } = useTranslation();
  const { isMobile } = useTheme();

  const [sortOrder, setSortOrder] = useState<number>(sortingOrder ?? -1);

  useEffect(() => {
    setSortOrder(sortingOrder);
  }, [sortingOrder]);

  const orderedData = useMemo(
    () =>
      data
        .sort((a, b) => (a.time.valueOf() - b.time.valueOf()) * sortOrder)
        .slice(0, maxCount || undefined),
    [sortOrder, data, maxCount]
  );

  const onHeaderTitlePress = () => setSortOrder((old) => -1 * old);

  const directionIndicator = sortOrder === 1 ? Direction.Up : Direction.Down;

  return (
    <div
      ref={resizerRef}
      style={
        isMobile
          ? {
              height: orderedData.length === 0 ? "300px" : "auto",
            }
          : {}
      }
    >
      <WidgetTitleContainer style={{ paddingBottom: 15 }}>
        <WidgetTitle>{name}</WidgetTitle>
      </WidgetTitleContainer>

      {orderedData.length === 0 && <WidgetNoData />}

      {orderedData.length > 0 && (
        <div style={{ overflowX: "scroll" }}>
          <StyledTableComponent>
            <tbody>
              <HeaderRow>
                <HeaderCell onClick={onHeaderTitlePress}>
                  <ColumnWrapper>
                    {t("date")}
                    <SortIconWrapper height={13} isIconVisible>
                      <Arrow direction={directionIndicator} />
                    </SortIconWrapper>
                  </ColumnWrapper>
                </HeaderCell>

                {(["title", "status", "team", "comment"] as const).map(
                  (col) => (
                    <HeaderCell key={col} color={COLOR_MEDIUM_GRAY}>
                      <ColumnWrapper>{t(col)}</ColumnWrapper>
                    </HeaderCell>
                  )
                )}
              </HeaderRow>
              {orderedData.map((item, index) => (
                <AttendanceLogTableRow
                  key={`${item.time.valueOf()}:${index}`}
                  item={item}
                  backgroundColor={
                    /* alternatingRow && */ index % 2 === 0
                      ? COLOR_GRAY
                      : COLOR_WHITE
                  }
                  rowDividers={rowDividers}
                  columnDividers={columnDividers}
                />
              ))}
            </tbody>
          </StyledTableComponent>
        </div>
      )}
    </div>
  );
};
