import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AttendanceCrossIcon = memo(
  ({
    width = 13,
    height = 13,
    isActive = false,
    circleColor = "#E6E8EB",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="6.5" cy="6.5" r="6.5" fill={circleColor} />
      <path d="M4 9L9 4" stroke="#3C434C" strokeWidth="1.5" />
      <path d="M4 4L9 9" stroke="#3C434C" strokeWidth="1.5" />
    </svg>
  )
);
