import React from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../colors";

import { BasicButton } from "./Button";
import { NextIcon } from "./Icons";

interface IArrowButton {
  onPrevClick(): void;
  onNextClick(): void;
}

const NavButton = styled(BasicButton)<{
  isLeft?: boolean;
}>`
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  ${(props) =>
    props.isLeft ? "border-right-width: 0" : "border-left-width: 0"};
  background-color: ${COLOR_WHITE};
  border-bottom-left-radius: ${({ isLeft }) => (isLeft ? 6 : 0)}px;
  border-top-left-radius: ${({ isLeft }) => (isLeft ? 6 : 0)}px;
  border-bottom-right-radius: ${({ isLeft }) => (!isLeft ? 6 : 0)}px;
  border-top-right-radius: ${({ isLeft }) => (!isLeft ? 6 : 0)}px;
  border-color: #ecedef;

  &:hover {
    border-color: #d9dadd;
  }

  &:active {
    opacity: 1;
    background-color: ${COLOR_BLUE};

    & svg > path {
      stroke: ${COLOR_WHITE};
    }
  }
`;

const AgendaWrapper = styled.div`
  height: 37px;
  display: flex;
  flex-direction: row;
`;

const ButtonDivider = styled.div`
  height: 37px;
  width: 1px;
  background-color: #ecedef;

  &:hover {
    background-color: #d9dadd;
  }
  &:active {
    background-color: #d9dadd;
  }
`;

export function ArrowButtons({ onPrevClick, onNextClick }: IArrowButton) {
  return (
    <AgendaWrapper>
      <NavButton onClick={onPrevClick} isLeft>
        <NextIcon
          direction="left"
          width={15}
          height={15}
          tintColor={COLOR_BLUE}
        />
      </NavButton>
      <ButtonDivider />
      <NavButton onClick={onNextClick}>
        <NextIcon width={15} height={15} tintColor={COLOR_BLUE} />
      </NavButton>
    </AgendaWrapper>
  );
}
