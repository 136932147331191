import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_TEXT_TABLE_LABEL } from "../../../colors";
import { Exercise, Set, Cell } from "../../../graphql";

import { TableInput } from "./TableInput";
import { TableSelect } from "./TableSelect";

type WorkoutTableProps = {
  exerciseData: Exercise;
  sets: Set[];
  onCellUpdated: (cell: Cell, value: string, setId: string) => void;
};

const Header = styled.thead`
  display: block;
`;

const Body = styled.tbody`
  display: block;
`;

const HeaderCell = styled.div`
  width: 65px;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${COLOR_TEXT_TABLE_LABEL};
  margin-right: 15px;
`;

const DataCell = styled.div`
  width: 65px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  margin-right: 15px;
`;

export function WorkoutTable({
  exerciseData,
  sets,
  onCellUpdated,
}: WorkoutTableProps) {
  const { t } = useTranslation();

  return (
    <table>
      <Header>
        <tr>
          <th>
            <HeaderCell>{t("set")}</HeaderCell>
          </th>
          {exerciseData?.attributeTemplates?.map((attributeTemplate) => {
            return (
              <th key={attributeTemplate?.id}>
                <HeaderCell key={attributeTemplate?.id}>
                  {attributeTemplate?.name}{" "}
                  {attributeTemplate.unitName &&
                    attributeTemplate.unitName !== " " &&
                    `(${attributeTemplate?.unitName})`}
                </HeaderCell>
              </th>
            );
          })}
        </tr>
      </Header>
      <Body>
        {sets?.map((set, index) => {
          return (
            <tr key={set?.id}>
              <td>
                <DataCell>{index + 1}</DataCell>
              </td>

              {set?.cells?.map((cell) => {
                if (cell.options && cell.options.length) {
                  return (
                    <td key={cell?.id}>
                      <TableSelect
                        cell={cell}
                        onCellUpdated={(cell, newText) =>
                          onCellUpdated(cell, newText, set.id)
                        }
                      />
                    </td>
                  );
                }

                return (
                  <td key={cell?.id}>
                    <TableInput
                      cell={cell}
                      onCellUpdated={(cell, newText) =>
                        onCellUpdated(cell, newText, set.id)
                      }
                    />
                  </td>
                );
              })}
            </tr>
          );
        })}
      </Body>
    </table>
  );
}
