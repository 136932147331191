import { useMemo } from "react";

import dayjs from "dayjs";

import { useReportPeriod } from "../../hooks/useReportPeriod";
import { ChartWidgetModel, TimeLine, TimeLinePeriod } from "../model";

export function useChartWidgetReportPeriod(
  widget: ChartWidgetModel,
  isPreviewMode?: boolean
) {
  const reportPeriod = useReportPeriod(widget.period);

  const dummyPeriod = useMemo(() => {
    if (
      widget.timeLine === TimeLine.PERIOD ||
      widget.timeLine === TimeLine.PERFORMERS
    ) {
      const countOfPeriods = widget.timeLine === TimeLine.PERFORMERS ? 3 : 8;

      switch (widget.timeLinePeriod) {
        // Weeks Time Frames range - period of the last 8 weeks
        case TimeLinePeriod.WEEKS:
          return {
            fromDate: dayjs().subtract(countOfPeriods, "weeks").startOf("day"),
            toDate: dayjs().endOf("day"),
          };
        // Months Time Frames range - period of the last 8 months
        case TimeLinePeriod.MONTHS:
          return {
            fromDate: dayjs().subtract(countOfPeriods, "months").startOf("day"),
            toDate: dayjs().endOf("day"),
          };
        case TimeLinePeriod.YEARS:
          return {
            fromDate: dayjs().subtract(2, "years").startOf("day"),
            toDate: dayjs().endOf("day"),
          };
      }
    }

    const countOfDays = widget.timeLine === TimeLine.PERFORMERS ? 5 : 30;

    // Days Time Frames range - period of the last 30 days
    // Time range - period of the last 30 days
    return {
      fromDate: dayjs().subtract(countOfDays, "days").startOf("day"),
      toDate: dayjs().endOf("day"),
    };
  }, [widget.timeLine, widget.timeLinePeriod]);

  return !isPreviewMode ? reportPeriod : dummyPeriod;
}
