import { LinearColoring } from "../../Report/PeriodizationTable/model";

export const getColoringConfig = (coloring: LinearColoring) => {
  switch (coloring) {
    case LinearColoring.GREEN_WHITE:
      return ["#FCFCFF", "#63BE7B"];
    case LinearColoring.WHITE_GREEN:
      return ["#63BE7B", "#FCFCFF"];
    case LinearColoring.RED_YELLOW_GREEN:
      return ["#63BE7B", "#FFEB83", "#F8696B"];
    case LinearColoring.GREEN_YELLOW_RED:
      return ["#F8696B", "#FFEB83", "#63BE7B"];
  }
};
