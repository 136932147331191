import { Reducer } from "react";

import actions from "./constants";

const reducer: Reducer<
  {
    embed: boolean;
    loading: boolean;
    width: number;
    height: number;
    bp: "xxl" | "xl" | "md" | "sm" | "xs";
  },
  any
> = (state, action) => {
  switch (action.type) {
    case actions.LOADING:
      return { ...state, loading: action.payload };
    case actions.RESIZE:
      return { ...state, ...action.payload };
    case actions.SET_EMBED:
      return { ...state, embed: action.payload };
    default:
      return state;
  }
};

export default reducer;
