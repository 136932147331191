import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_SECONDARY_LIGHT_GRAY } from "../../colors";
import { Drill, PracticeInlineComment, Split } from "../../graphql";

import { PracticeLines } from "./PracticeLines";

type Participation = {
  id: string;
  name: string;
  athleteGuid: string;
};

export type SubPractice = {
  __typename: "SubPractice";
  id: string;
  label: string;
  comment: string;
  athletes: Participation[];
  practiceLines: (Drill | PracticeInlineComment)[];
};

type PracticeSplitProps = {
  split: Split;
};

const PracticeSplitWrapper = styled.div`
  background-color: ${COLOR_SECONDARY_LIGHT_GRAY};
  margin: 5px 0;
  padding: 5px 2.5px;
  border-radius: 10px;
`;

const SplitLabel = styled.div`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 12px;
  font-weight: 400;
  padding: 13px 20px 12px;
`;

const SubPracticesWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SubPracticeWrapper = styled.div`
  flex: 1;
  margin: 0 2.5px;
`;

const SplitComment = styled.div`
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 15px;
  font-size: 12px;
  color: ${COLOR_MEDIUM_GRAY};
`;

export function PracticeSplit({ split }: PracticeSplitProps) {
  const { t } = useTranslation();

  return (
    <PracticeSplitWrapper>
      <SplitLabel>{t("sessionSplit").toUpperCase()}</SplitLabel>
      <SubPracticesWrapper>
        {split.subPractices.map((subPractice) => (
          <SubPracticeWrapper key={subPractice.id}>
            {subPractice.comment ? (
              <SplitComment>{subPractice.comment}</SplitComment>
            ) : null}
            <PracticeLines lines={subPractice.practiceLines} />
          </SubPracticeWrapper>
        ))}
      </SubPracticesWrapper>
    </PracticeSplitWrapper>
  );
}
