import React, { CSSProperties } from "react";

import styled from "styled-components";

import { useResourceCalendarContext } from "../contexts/resourceCalendar/ResourceCalendarProvider";
import { ResourcesQuery } from "../graphql";

import { SelectableResource } from "./Modals/AddResourceModal";
import { SelectedResourceTag } from "./SelectedResourceTag";

const ResourceTagWrapper = styled.div<{ hasItems?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  max-width: 100%;
`;

export function SelectedResourceTagList({
  resourcesData,
  localResourceList,
  updateLocalResourceList,
  additionalStyle = {},
}: {
  resourcesData: ResourcesQuery;
  localResourceList?: SelectableResource[];
  updateLocalResourceList?: (id: string) => void;
  additionalStyle?: CSSProperties;
}) {
  const { selectableResourceList, onSelectResource } =
    useResourceCalendarContext();
  const resourceList = localResourceList?.length
    ? localResourceList
    : selectableResourceList;

  const removeResource = localResourceList?.length
    ? updateLocalResourceList
    : onSelectResource;

  const getIsOccupied = (guid: string) => {
    const resourceToCheck = resourcesData?.resources?.find(
      (resource) => resource?.guid === guid
    );

    return !!(
      resourceToCheck &&
      resourceToCheck?.conflicts &&
      resourceToCheck?.conflicts?.items?.length
    );
  };

  const hasSelectedResources = resourceList?.filter((resource) => {
    if (resource?.isSelected) {
      return resource;
    }
  });

  return (
    <ResourceTagWrapper
      hasItems={!!hasSelectedResources?.length}
      style={additionalStyle}
    >
      {resourceList?.map((resource) => {
        const isOccupied = getIsOccupied(resource.resourceId);

        return (
          <SelectedResourceTag
            key={resource.resourceId}
            resource={{ ...resource, isOccupied }}
            removeResource={removeResource}
          />
        );
      })}
    </ResourceTagWrapper>
  );
}
