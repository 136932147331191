import React from "react";
import { useTranslation } from "react-i18next";

import { useAgendaDataContext } from "../../../contexts/agenda/AgendaDataProvider";
import { HoverableItem } from "../../HoverableItem";

interface TeamsDropdownContentProps {
  toggle?: () => void;
}

export const TeamsDropdownContent = ({ toggle }: TeamsDropdownContentProps) => {
  const { t } = useTranslation();
  const { checkAllGroups } = useAgendaDataContext();
  return (
    <>
      <HoverableItem
        label={t("uncheckAllTeams")}
        onClick={() => {
          checkAllGroups("uncheck");
          toggle?.();
        }}
      />
      <HoverableItem
        label={t("checkAllTeams")}
        onClick={() => {
          checkAllGroups("check");
          toggle?.();
        }}
      />
    </>
  );
};
