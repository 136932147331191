import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CheckIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
      <g opacity={isActive ? "1" : "0.35"}>
        <circle
          cx="17.5"
          cy="17.5"
          r="12"
          stroke={isActive ? "transparent" : "#B1B7BC"}
          fill={isActive ? "#77CC00" : "transparent"}
        />
        <path
          d="M13.5 17.5L16.5 20.5L22.5 14.5"
          stroke={isActive ? "white" : "#64707A"}
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
);
