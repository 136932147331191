import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import StyledText from "./StyledText";

const ErrorBoundaryContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const ErrorBoundaryContent = () => {
  const { t } = useTranslation();

  return (
    <ErrorBoundaryContentWrapper>
      <StyledText>{t("processingError")}</StyledText>
    </ErrorBoundaryContentWrapper>
  );
};
