import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChartLineSmallIcon = memo(
  ({
    width = 13,
    height = 13,
    isActive = false,
    tintColor = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <>
          <rect x="1" y="10" width="2" height="2" fill="white" />
          <rect x="4" y="5" width="2" height="2" fill="white" />
          <rect x="7" y="8" width="2" height="2" fill="white" />
          <rect x="10" y="2" width="2" height="2" fill="white" />
          <path d="M2 11L5 6L8 9L11 3" stroke="white" />
        </>
      ) : (
        <>
          <rect x="1" y="10" width="2" height="2" fill={tintColor} />
          <rect x="4" y="5" width="2" height="2" fill={tintColor} />
          <rect x="7" y="8" width="2" height="2" fill={tintColor} />
          <rect x="10" y="2" width="2" height="2" fill={tintColor} />
          <path d="M2 11L5 6L8 9L11 3" stroke={tintColor} />
        </>
      )}
    </svg>
  )
);
