import React, { useRef, useState } from "react";

import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_GREEN_HOVER,
  COLOR_GREEN_SELECTED,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../../colors";
import { useDefaultDateFormat, useHandleClickOutside } from "../../../../hooks";
import { isURL } from "../../../../utils/isURL";
import { isSoccerStatsCollection } from "../../../../utils/reports";
import { BasicButton } from "../../../Button";
import { IconButton } from "../../../IconButton";
import { EditPencilSquaredIcon, PlusIcon } from "../../../Icons";
import { LinkUrl } from "../../../LinkUrl";
import StyledText from "../../../StyledText";

import { EditRow } from "./EditRow";
import { InformationWidgetDataModel } from "./model";
import {
  DataItemRow,
  HoverImageWrapper,
  InformationTableWrapper,
  InformationTitle,
  TitleRow,
  TitleTextWrapper,
} from "./styled";

interface InformationTableProps {
  information: InformationWidgetDataModel;
  order: number;
  isPreviewMode?: boolean;
  expandedByDefault?: boolean;
  refetchData?: () => void;
}

const Triangle = styled.div<{ isDown?: boolean }>`
  width: 0;
  height: 0;
  border-bottom: ${({ isDown }) => (isDown ? 0 : "6px solid transparent")};
  border-right: ${({ isDown }) => (isDown ? "6px solid transparent" : 0)};
  border-top: ${({ isDown }) =>
    isDown ? `10px solid ${COLOR_SECONDARY_GREY}` : "6px solid transparent"};
  border-left: ${({ isDown }) =>
    isDown ? "6px solid transparent" : `10px solid ${COLOR_SECONDARY_GREY}`};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 25px;
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InformationTable = ({
  information,
  order,
  isPreviewMode = false,
  expandedByDefault = false,
  refetchData,
}: InformationTableProps) => {
  const tableRef = useRef();

  const [isExpanded, setIsExpanded] = useState(expandedByDefault);
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [editModeRow, setEditModeRow] = useState(null);
  const [isEditSelectOpen, setIsEditSelectOpen] = useState(false);
  const { dateFormat } = useDefaultDateFormat();

  useHandleClickOutside(
    tableRef,
    () => {
      if (!isEditSelectOpen) {
        setIsAddingNewItem(false);
        setEditModeRow(null);
      }
    },
    isAddingNewItem || editModeRow !== null
  );

  const dataItems = isExpanded
    ? information.data
    : information.data.length > 0
      ? [information.data[0]]
      : [];

  const enableAddingAndEditing = !isSoccerStatsCollection(
    information.templateId
  );

  const onLeaveEditMode = (isNewItem: boolean) => {
    if (isNewItem) {
      setIsAddingNewItem(false);
    } else {
      setEditModeRow(null);
    }

    if (typeof refetchData === "function") {
      refetchData();
    }
  };

  const onEnterAddNewMode = () => {
    setEditModeRow(null);
    setIsAddingNewItem(true);
  };

  const onEnterEditMode = (row) => {
    setIsAddingNewItem(false);
    setEditModeRow(row);
  };

  return (
    <InformationTableWrapper ref={tableRef} order={order}>
      <TitleRow>
        <TitleTextWrapper>
          {information.data.length > 1 && (
            <BasicButton onClick={() => setIsExpanded((old) => !old)}>
              <Triangle isDown={isExpanded} />
            </BasicButton>
          )}
          <InformationTitle>{information.title}</InformationTitle>
        </TitleTextWrapper>

        {enableAddingAndEditing && (
          <HoverImageWrapper style={{ marginRight: 6 }}>
            <IconButton
              icon={
                <PlusIcon
                  isActive
                  style={{ width: 12, height: 12, transform: `scale(0.5)` }}
                />
              }
              size={12}
              bgColor={COLOR_GREEN}
              hoverBgColor={COLOR_GREEN_HOVER}
              activeBgColor={COLOR_GREEN_SELECTED}
              onClick={onEnterAddNewMode}
            />
          </HoverImageWrapper>
        )}
      </TitleRow>

      {(isAddingNewItem || editModeRow) && !isPreviewMode && (
        <EditRow
          informationRegistrationId={editModeRow?.id}
          defaultValue={isAddingNewItem ? "" : editModeRow.value}
          measurementTemplateId={information.templateId}
          handleClose={() => onLeaveEditMode(isAddingNewItem)}
          options={information.options}
          onOpenChange={setIsEditSelectOpen}
        />
      )}

      {dataItems.map((dataItem, index) => (
        <DataItemRow key={index} withBackground={index % 2 === 0}>
          <StyledText
            color={COLOR_TEXT_TABLE_LABEL}
            fontWeight="semibold"
            fontSize={10}
            style={{ alignSelf: "center", lineHeight: "12px" }}
          >
            {dataItem.date.format(dateFormat)}
          </StyledText>
          <ItemWrapper>
            {Boolean(dataItem.label) && (
              <StyledText
                color={COLOR_TEXT_TABLE_LABEL}
                fontWeight="semibold"
                fontSize={10}
                style={{ lineHeight: "12px" }}
              >
                {dataItem.label}
              </StyledText>
            )}
            <ValueWrapper>
              {isURL(dataItem.value) ? (
                <LinkUrl
                  size={12}
                  url={dataItem.value}
                  wrapperStyle={{ alignSelf: "center" }}
                />
              ) : (
                <StyledText
                  color={COLOR_TEXT_DARK}
                  fontWeight="normal"
                  fontSize={12}
                  style={{
                    alignSelf: "center",
                    lineHeight: "15px",
                  }}
                >
                  {dataItem.value}
                </StyledText>
              )}
              {enableAddingAndEditing && (
                <HoverImageWrapper style={{ marginLeft: 8 }}>
                  <IconButton
                    onClick={() => onEnterEditMode({ id: index, ...dataItem })}
                    icon={<EditPencilSquaredIcon height={13} width={13} />}
                    size={25}
                  />
                </HoverImageWrapper>
              )}
            </ValueWrapper>
          </ItemWrapper>
        </DataItemRow>
      ))}
    </InformationTableWrapper>
  );
};
