import React from "react";
import { OptionProps, components } from "react-select";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { LocationPointIcon } from "../Icons/LocationPointIcon";

import { Option } from "./model";

type IconOptionProps = OptionProps & {
  isLabel: boolean;
};

const OptionWrapper = styled.div<{ isLabel: boolean }>`
  width: auto;
  white-space: nowrap;
  padding-left: ${({ isLabel }) => (!isLabel ? 5 : 0)}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LocationIconWrapper = styled.div`
  position: absolute;
  left: 3px;
`;

export function IconOption(props: IconOptionProps) {
  const { url, label } = (props?.data as Option) || {};
  const hasUrl = url || "";

  return (
    <components.Option {...props}>
      <OptionWrapper isLabel={props.isLabel}>
        {hasUrl && (
          <LocationIconWrapper>
            <LocationPointIcon color={COLOR_MEDIUM_GRAY} />
          </LocationIconWrapper>
        )}
        <span>{label || ""}</span>
      </OptionWrapper>
    </components.Option>
  );
}
