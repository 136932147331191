import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import StyledText from "../../StyledText";

const SportTypeContainer = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`;

const PrimaryLabel = styled(StyledText)<{ noBottomMargin?: boolean }>`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: ${({ noBottomMargin }) => (noBottomMargin ? 0 : 5)}px;
  color: ${COLOR_TEXT_DARK};
`;

const SecondaryLabel = styled(StyledText)`
  font-size: 12px;
  font-weight: 400;
  color: ${COLOR_TEXT_DARK};
`;

export function SportTypeSection({ parentGroupName, teamName }) {
  return parentGroupName ? (
    <SportTypeContainer>
      <PrimaryLabel>{parentGroupName}</PrimaryLabel>
      {teamName && <SecondaryLabel>{teamName}</SecondaryLabel>}
    </SportTypeContainer>
  ) : (
    <SportTypeContainer>
      {teamName && <PrimaryLabel noBottomMargin>{teamName}</PrimaryLabel>}
    </SportTypeContainer>
  );
}
