import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";
import { useModalContext, ModalType } from "../../contexts/UI/Modal";
import { Drill, PracticeInlineComment, Split } from "../../graphql";
import { BottomBar } from "../BottomBar";
import { ButtonColor } from "../Button/";

import { CommentItem } from "./CommentItem";
import { DrillItem } from "./DrillItem";
import { PracticeSplit } from "./PracticeSplit";

type PracticeLinesProps = {
  lines: (Drill | PracticeInlineComment | Split)[];
};

const BottomBarWrapper = styled.div`
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  padding: 15px 20px;
`;

export function PracticeLines({ lines }: PracticeLinesProps) {
  const currentDrillIndexRef = useRef(0);
  const {
    actions: { openModal, closeModal },
  } = useModalContext();
  const { t } = useTranslation();

  const openDrillModal = (index) => {
    currentDrillIndexRef.current = index;
    const line = lines[currentDrillIndexRef.current] as Drill;

    openModal({
      modal: ModalType.DOCUMENT,
      title: " ",
      params: {
        document: {
          ...line,
          mcUrl: line.urlModularContent,
        },
        hidePopularButton: true,
        showDocumentName: true,
        bottomBar: (
          <BottomBarWrapper>
            <BottomBar
              primary={{
                text: t("next"),
                variant: ButtonColor.Blue,
                onClick: showNextDrill,
                disabled:
                  index === lines.length - 1 ||
                  (lines[index + 1] && lines[index + 1].__typename !== "Drill"),
              }}
              secondary={{
                text: t("previous"),
                onClick: showPrevDrill,
                disabled:
                  index === 0 ||
                  (lines[index - 1] && lines[index - 1].__typename !== "Drill"),
              }}
            />
          </BottomBarWrapper>
        ),
      },
    });
  };

  const isFirstDrill = (index: number) => {
    return (
      index === 0 ||
      (lines[index - 1] && lines[index - 1].__typename !== "Drill")
    );
  };

  const isLastDrill = (index: number) => {
    return (
      index === lines.length - 1 ||
      (lines[index + 1] && lines[index + 1].__typename !== "Drill")
    );
  };

  const showNextDrill = () => {
    if (!isLastDrill(currentDrillIndexRef.current)) {
      currentDrillIndexRef.current += 1;
      closeModal();
      openDrillModal(currentDrillIndexRef.current);
    }
  };

  const showPrevDrill = () => {
    if (!isFirstDrill(currentDrillIndexRef.current)) {
      currentDrillIndexRef.current -= 1;
      closeModal();
      openDrillModal(currentDrillIndexRef.current);
    }
  };

  return (
    <div>
      {lines.map((line, index) => {
        if (line.__typename === "PracticeInlineComment") {
          return <CommentItem key={index} comment={line} />;
        } else if (line.__typename === "Drill") {
          return (
            <DrillItem
              key={index}
              index={index}
              drill={line}
              isFirst={!!isFirstDrill(index)}
              isLast={!!isLastDrill(index)}
              onDrillItemPress={openDrillModal}
            />
          );
        } else if (line.__typename === "Split") {
          return <PracticeSplit key={index} split={line} />;
        } else {
          return null;
        }
      })}
    </div>
  );
}
