import React, { useCallback } from "react";

import styled from "styled-components";

import { useMobileModalContext } from "../../../../contexts/mobileModal/MobileModalProvider";
import { useReportsContext } from "../../../../contexts/Reports";
import { Group } from "../../../../graphql";
import { BasicButton } from "../../../Button";
import { GroupValue } from "../../../GroupSelector";
import { RoundedGroupProfileIcon } from "../../../Icons";
import { SelectRowContainer, SelectText } from "../styled";

interface GroupPickerRowProps {
  group: Group & { isSubGroup?: boolean };
}

const PickerButton = styled(BasicButton)`
  display: block;
`;

const WrapperIcon = styled.div`
  margin-right: 5px;
`;

const Spacer = styled.div`
  display: flex;
  padding-left: 30px;
`;

export const GroupPickerRow = ({ group }: GroupPickerRowProps) => {
  const [{ selectedGroup, handleGroupChange }] = useReportsContext();
  const { hideModal } = useMobileModalContext();

  const isSelected = group.id === selectedGroup?.id;

  const setSelectedOption = useCallback(
    (value: GroupValue) => {
      if (value) {
        handleGroupChange(value.id);
      }
      hideModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleGroupChange, hideModal]
  );

  return (
    <PickerButton onClick={() => setSelectedOption(group)}>
      <SelectRowContainer isSelected={isSelected}>
        {group.isSubGroup ? (
          <Spacer />
        ) : (
          <WrapperIcon>
            <RoundedGroupProfileIcon width={25} height={25} />
          </WrapperIcon>
        )}
        <SelectText fontSize={12} fontWeight="bold" isSelected={isSelected}>
          {group.name}
        </SelectText>
      </SelectRowContainer>
    </PickerButton>
  );
};
