import { useTranslation } from "react-i18next";

import { useNotificationsContext } from "../../../contexts/notifications";
import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplate,
  useDeleteReportTemplateMutation,
} from "../../../graphql";

import { useReportWarningModal } from "./useReportWarningModal";

export const useDeleteReportWarning = ({
  reportTemplate,
  onCloseModal,
}: {
  reportTemplate: ReportTemplate;
  onCloseModal: () => void;
}) => {
  const { t } = useTranslation();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const { sessionId } = useUserContext();

  const [deleteReportMutation] = useDeleteReportTemplateMutation({
    variables: {
      reportId: reportTemplate?.id,
      sessionId,
    },
  });

  const deleteReport = async () => {
    try {
      await deleteReportMutation();
      onCloseModal();
      showSuccessNotification(t("deleteReportSuccess"));
    } catch {
      showErrorNotification(t("deleteReportError"));
    }
  };

  const createReportWarningModal = useReportWarningModal({
    title: t("deleteReport"),
    cancelButtonText: t("cancel"),
    okButtonText: t("delete"),
    confirmationQuestion: t("reportDeleteConfirm"),
    reportTemplate,
    onCancel: () => ({}),
    onOK: deleteReport,
  });

  return createReportWarningModal;
};
