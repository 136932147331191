import { TFunction } from "react-i18next";

import dayjs from "dayjs";

import { Chatter } from "../graphql";

type GetGroupNameProps = {
  recipients: string;
  contact?: Chatter;
  session?: any;
  t: TFunction;
};

function getSuffix(recipients: string, t: TFunction): string {
  switch (recipients) {
    case "athletesOnly":
      return t("athletes");
    case "familyMembersOnly":
      return t("familyMembers");
    default:
      return "";
  }
}

function getFormattedDate(session: any): string {
  const dateFormat = dayjs(session.start).format("DD.MMM");
  const dateParts = dateFormat.split(".");
  const formattedDate = `${dateParts[0]}.${dateParts[1]
    .charAt(0)
    .toUpperCase()}${dateParts[1].slice(1).toLowerCase()}`;

  return formattedDate;
}

export function getGroupName({
  recipients,
  contact,
  session,
  t,
}: GetGroupNameProps) {
  const suffix = getSuffix(recipients, t);
  let name = "";

  if (contact) {
    name = `${contact.name}`;
  } else if (session) {
    const teamName =
      session.parentGroupName && session.parentGroupName !== ""
        ? `${session.parentGroupName}-${session.teamName}`
        : session.teamName;

    const nickname =
      session.ownerUser?.country === "Iceland"
        ? session.ownerUser?.nickName
        : "";
    const sessionType = session.__typename;
    const formattedDate = getFormattedDate(session);

    const sessionTypeText =
      sessionType === "Practice"
        ? t("practiceChatTitle")
        : t(sessionType.toLowerCase());

    name = `${nickname} ${teamName} ${sessionTypeText.trim()}-${formattedDate}`;
  }

  if (suffix) {
    name = `${name} (${suffix})`;
  }

  return name.trim();
}
