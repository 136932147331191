import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_SECONDARY_GREY, COLOR_WHITE } from "../../../colors";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { Group, SubGroup, useSaveGroupMutation } from "../../../graphql";
import { BottomBar } from "../../BottomBar";
import { ButtonColor } from "../../Button/";

import { SaveGroupForm } from "./Form";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  flex: 1;
  padding: 25px 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  width: inherit;
  background-color: ${COLOR_WHITE};
  padding-bottom: 30px;
  padding-right: 30px;
  padding-top: 15px;
`;

type SaveGroupProps = {
  parentGroup?: string;
  parentGroupOwnerId?: string;
  groupToEdit?: Group | SubGroup;
  isTeam?: boolean;
  onSetGroup?: (group: Group) => void;
  refetch: () => Promise<any>;
};

type SaveGroupFormState = {
  name: string;
  shortName?: string;
  colorHexCode: string;
  personalChatAllowed: boolean;
  groupChatAllowed: boolean;
  selfRegistered: boolean;
  defaultAttended: boolean;
  hidePracticeContentFromAthletes: boolean;
};

const saveGroupDefaultValues: SaveGroupFormState = {
  name: "",
  colorHexCode: COLOR_SECONDARY_GREY,
  personalChatAllowed: true,
  groupChatAllowed: true,
  selfRegistered: false,
  defaultAttended: false,
  hidePracticeContentFromAthletes: false,
};

const getGroupValuesToEdit = (groupToEdit, isSubGroup): SaveGroupFormState => {
  const groupValuesToEdit = {
    name: groupToEdit.name,
    colorHexCode: `#${groupToEdit.practiceColor}`,
    personalChatAllowed: groupToEdit.personalChat,
    groupChatAllowed: groupToEdit.groupChat,
    selfRegistered: groupToEdit.selfRegistration,
    defaultAttended: groupToEdit.defaultAttended,
    hidePracticeContentFromAthletes:
      groupToEdit.hidePracticeContentFromAthletes,
  };
  return isSubGroup
    ? { ...groupValuesToEdit, shortName: groupToEdit.shortName }
    : groupValuesToEdit;
};

export const SaveGroup = ({
  parentGroup,
  parentGroupOwnerId,
  groupToEdit,
  isTeam = false,
  onSetGroup,
  refetch,
}: SaveGroupProps) => {
  const { t } = useTranslation();
  const modal = useModalContext();
  const { sessionId, language, timezone } = useUserContext();

  const [saveGroupMutation, { loading }] = useSaveGroupMutation();

  const groupToEditIsSubGroup = groupToEdit?.__typename === "SubGroup";

  const {
    handleSubmit,
    formState: { isValid },
    ...formMethods
  } = useForm<SaveGroupFormState>({
    mode: "all",
    defaultValues: groupToEdit
      ? getGroupValuesToEdit(groupToEdit, groupToEditIsSubGroup)
      : saveGroupDefaultValues,
  });

  const saveGroup = async (data) => {
    const variables = groupToEdit
      ? { id: groupToEdit?.id }
      : {
          parentGroup,
          trainer: parentGroupOwnerId,
        };

    const savedGroup = await saveGroupMutation({
      variables: {
        ...data,
        ...variables,
        sessionId,
        language,
        timezone,
      },
    });

    await onSetGroup?.({
      id: isTeam ? savedGroup?.data?.saveGroup?.id : parentGroup,
    } as Group);

    refetch();

    handleCancel();
  };

  const handleCancel = () => {
    modal.actions.closeModal();
  };

  return (
    <Wrapper>
      <FormWrapper>
        <SaveGroupForm
          showShortName={(groupToEditIsSubGroup || !groupToEdit) && !isTeam}
          formMethods={formMethods}
        />
      </FormWrapper>
      <ButtonWrapper>
        <BottomBar
          primary={{
            text: t("ok"),
            variant: ButtonColor.Blue,
            isLoading: loading,
            disabled: !isValid,
            onClick: handleSubmit(saveGroup),
          }}
          secondary={{
            text: t("cancel"),
            onClick: () => handleCancel(),
          }}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};
