import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { useUserContext } from "../../../../contexts/User";
import { Checkbox } from "../../../Checkbox";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";

import { AttendanceLogWidgetFormValue } from "./model";

const AllGroupsCheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const DataSection = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } =
    useFormikContext<AttendanceLogWidgetFormValue>();
  const user = useUserContext();
  const isGolfer = user?.settings?._isGolfer;

  const DATA_SELECTORS = [
    "showPractices",
    isGolfer ? "showCompetitions" : "showGames",
    "showEvents",
  ] as const;

  return (
    <Section>
      <SectionTitle title={t("data")} />
      {DATA_SELECTORS?.map((selector) => (
        <Checkbox
          key={selector}
          text={t(selector)}
          check={(value) => setFieldValue(selector, value)}
          checked={values[selector]}
        />
      ))}

      <AllGroupsCheckboxWrapper>
        <Checkbox
          text={t("allTeams")}
          check={(value) => setFieldValue("allGroups", value)}
          checked={values.allGroups}
        />
      </AllGroupsCheckboxWrapper>
    </Section>
  );
};
