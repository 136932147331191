import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const FolderFullIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <path
          d="M1 4V16C1 17.1046 1.89543 18 3 18H18C19.1046 18 20 17.1046 20 16V7C20 5.89543 19.1046 5 18 5H12.2071C12.0745 5 11.9473 4.94732 11.8536 4.85355L9.58579 2.58579C9.21071 2.21071 8.70201 2 8.17157 2H3C1.89543 2 1 2.89543 1 4Z"
          fill="white"
        />
      ) : (
        <>
          <path
            d="M1.5 16V4C1.5 3.17157 2.17157 2.5 3 2.5H8.17157C8.5694 2.5 8.95093 2.65804 9.23223 2.93934L11.5 5.20711C11.6875 5.39464 11.9419 5.5 12.2071 5.5H18C18.8284 5.5 19.5 6.17157 19.5 7V16C19.5 16.8284 18.8284 17.5 18 17.5H3C2.17157 17.5 1.5 16.8284 1.5 16Z"
            stroke="white"
          />
          <path d="M2 5.5H12" stroke="white" />
        </>
      )}
    </svg>
  )
);
