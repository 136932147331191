import React from "react";
import { components, OptionProps } from "react-select";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../colors";

import Avatar from "./Avatar";
import { RoundedGroupProfileIcon } from "./Icons";
import { SelectOption } from "./StyledSelectField";

const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  background-color: ${COLOR_WHITE};
  border-radius: 3px;
  padding: 5px 10px 5px 5px;
  align-items: center;
  gap: 2px;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  margin-right: 5px;
  max-width: 25%;
`;

const WrapperGroupIcon = styled.div`
  width: 25px;
  height: 25px;
`;

export function MultiValueContainer(
  props: OptionProps<
    SelectOption & {
      isGroup?: boolean;
      isAvatar?: boolean;
    }
  >
) {
  const { data } = props;
  const { isGroup, isAvatar, value, label } = data;

  return (
    <Wrapper>
      {isGroup && (
        <WrapperGroupIcon>
          <RoundedGroupProfileIcon width={25} height={25} />
        </WrapperGroupIcon>
      )}
      {isAvatar && (
        <Avatar size={25} userGuid={value} userName={label} isThumb={true} />
      )}
      <components.MultiValueContainer {...props} />
    </Wrapper>
  );
}
