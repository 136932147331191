import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChevronDownIcon = memo(
  ({
    width = 13,
    height = 13,
    isActive = false,
    color = "#0073D9",
    style,
    direction = "bottom",
    ...props
  }: SvgIconProps) => {
    const rotation = () => {
      switch (direction) {
        case "left":
          return "90";
        case "top":
          return "180";
        case "right":
          return "270";
        default:
          return "0";
      }
    };

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ transform: `rotate(${rotation()}deg)`, ...style }}
        {...props}
      >
        <g id="chevron_up_13px">
          <path
            id="Vector"
            d="M10.5 4.5L6.5 8.5L2.5 4.5"
            stroke={color}
            strokeWidth="1.5"
          />
        </g>
      </svg>
    );
  }
);
