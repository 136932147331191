import * as React from "react";

import styled from "styled-components";

import { Section } from "../../graphql";
import Card from "../Card";

interface QuestionnaireSectionProps {
  section: Section;
  sectionIndex: number;
  setCurrentSectionIndex: (sectionId: number) => void;
}

const QuestionnaireSectionWrapper = styled.div`
  margin: 5px;
  cursor: pointer;
`;

const SectionTitle = styled.span`
  font-size: 16px;
  padding: 10px 15px;
  font-weight: 500;
`;

export const QuestionnaireSection = ({
  section,
  sectionIndex,
  setCurrentSectionIndex,
}: QuestionnaireSectionProps) => {
  return (
    <QuestionnaireSectionWrapper
      onClick={() => setCurrentSectionIndex(sectionIndex)}
    >
      <Card>
        <SectionTitle>{section.name}</SectionTitle>
      </Card>
    </QuestionnaireSectionWrapper>
  );
};
