import { useUserContext } from "../../contexts/User";
import { useReportBuildingCollectionsQuery } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../useGetSelectedAccountIdInMonitoring";

export const useReportBuildingCollections = (
  options?: Omit<
    Parameters<typeof useReportBuildingCollectionsQuery>[0],
    "variables"
  >
) => {
  const { sessionId, language } = useUserContext();

  const accountId = useGetSelectedAccountIdInMonitoring();

  return useReportBuildingCollectionsQuery({
    variables: {
      sessionId,
      language,
      accountId,
    },
    fetchPolicy: "cache-first",
    ...options,
    skip: options?.skip || !accountId,
  });
};
