import { LAYOUT_SIZE, MAX_COLUMN_COUNT } from "../constants/ReportGrid";

export const generateDefaultLayout = () => {
  const layout = [];

  for (let i = 0; i < LAYOUT_SIZE; i += 1) {
    layout.push({
      x: i % MAX_COLUMN_COUNT,
      y: Math.floor(i / MAX_COLUMN_COUNT),
      w: 1,
      h: 1,
      i: `placeholder-${i}`,
      isDraggable: false,
      isResizable: false,
      moved: false,
      static: false,
    });
  }

  return layout;
};
