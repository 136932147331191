import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import styled, { useTheme } from "styled-components";

import { COLOR_TEXT_DEFAULT, COLOR_TEXT_TABLE_LABEL } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import {
  ReportTemplate,
  ReportWidgetTemplateHeader,
  XpsUser,
} from "../../graphql";
import { isURL } from "../../utils/isURL";
import { roundToTwo } from "../../utils/number";
import { HoverablePlusIconButton } from "../AthleteProfile/HoverablePlusIconButton";
import Avatar from "../Avatar";
import { LinkUrl } from "../LinkUrl";
import { Loader } from "../Loader";
import { isHighPrecisionNumberInCanada } from "../Report/utils/isHighPrecisionNumberInCanada";
import StyledText from "../StyledText";
import Tabs, { Tab } from "../Tabs";

import { useAthleteHeaderData } from "./useAthleteHeaderData";

interface AthleteHeaderProps {
  headerTemplate?: ReportWidgetTemplateHeader;
  tabs?: Tab[];
  tabReports?: ReportTemplate[];
  activeTab?: Tab;
  athlete: XpsUser;
  headerOptions?: React.ReactNode;
  parentReportId: string;
  isEditMode?: boolean;
  onTabChange?: (tab: Tab) => void;
}

const HeaderContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding: ${({ isMobile }) => (isMobile ? "20px 20px 5px" : "15px 15px 5px")};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 100%;
  box-sizing: border-box;
`;

const DataContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserDataContainer = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const HeaderTitle = styled(StyledText)`
  margin-top: 10px;
  color: ${COLOR_TEXT_DEFAULT};
  height: 35px;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

const HeaderOptionsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 50px;
  z-index: 100;
`;

export const UserInfoItemContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 100%;
  margin-right: ${({ isMobile }) => (isMobile ? "20px" : "40px")};
  &:last-child {
    margin-right: 0;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  margin-top: 24px;
`;

export const UserInfoItemLabelText = styled(StyledText)`
  color: ${COLOR_TEXT_TABLE_LABEL};
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 4px;
  text-transform: uppercase;
`;

export const UserInfoItemValueText = styled(StyledText)`
  color: ${COLOR_TEXT_DEFAULT};
  font-weight: bold;
  font-size: 14px;
`;

const AddReportButtonWrapper = styled.div`
  padding-top: 2px;
`;

const AddProfileInfoButtonWrapper = styled.div`
  margin-top: -6px;
`;

const TabsWrapper = styled.div<{
  leftOverflow: boolean;
  rightOverflow: boolean;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  margin-top: ${({ isMobile }) => (!isMobile ? "36px" : "24px")};
  padding: ${({ isMobile }) => (!isMobile ? "0 10px" : "0")};

  ${({ leftOverflow, rightOverflow, isMobile }) => {
    if (isMobile) {
      return `
        mask-image: linear-gradient(to right, transparent 0, black ${
          leftOverflow ? "9" : "0"
        }%, black ${rightOverflow ? "90" : "100"}%, transparent 100%);
        -webkitmask-image: linear-gradient(to right, transparent 0, black ${
          leftOverflow ? "9" : "0"
        }%, black ${rightOverflow ? "90" : "100"}%, transparent 100%);
      `;
    }
  }}
`;

export const AthleteHeader = ({
  athlete,
  headerTemplate,
  tabs,
  activeTab,
  headerOptions,
  parentReportId,
  tabReports,
  isEditMode,
  onTabChange,
}: AthleteHeaderProps) => {
  const { t } = useTranslation();

  const { actions } = useModalContext();
  const { data, loading } = useAthleteHeaderData({ headerTemplate, athlete });
  const { isMobile } = useTheme();

  const [leftOverflow, setLeftOverflow] = useState<boolean>();
  const [rightOverflow, setRightOverflow] = useState<boolean>(true);

  const handleScroll = useCallback((e) => {
    setLeftOverflow(e.target.scrollLeft !== 0);
    setRightOverflow(
      e.target.scrollWidth > e.target.clientWidth + e.target.scrollLeft
    );
  }, []);

  return (
    <HeaderContainer isMobile={isMobile}>
      <DataContainer>
        <Avatar
          size={!isMobile ? 85 : 64}
          userName={athlete.name}
          uri={athlete.img}
        />
        <UserDataContainer>
          <HeaderTitle>
            {athlete.name} {loading && <Loader />}
          </HeaderTitle>

          <HeaderOptionsContainer>{headerOptions}</HeaderOptionsContainer>

          <UserInfoContainer>
            {data
              ? data.map((item, index) => {
                  const value = item?.value;
                  // Value may be 95 kg and we want to display also kg in this case
                  const isValueValidNumberWithoutUnitName = !isNaN(
                    Number(item?.value)
                  );
                  const valueAsNumber = parseFloat(value);
                  const finalValue = isNaN(valueAsNumber)
                    ? value
                    : valueAsNumber;
                  const valueToShow =
                    typeof finalValue === "number" &&
                    isValueValidNumberWithoutUnitName
                      ? isHighPrecisionNumberInCanada(finalValue)
                        ? finalValue
                        : roundToTwo(finalValue)
                      : value;

                  return (
                    <UserInfoItemContainer
                      key={`${item.label}-${value}-${index}`}
                      isMobile={isMobile}
                    >
                      <UserInfoItemLabelText>
                        {item.label}
                      </UserInfoItemLabelText>
                      {isURL(value || "") ? (
                        <LinkUrl url={value} />
                      ) : (
                        <UserInfoItemValueText>
                          {valueToShow}
                        </UserInfoItemValueText>
                      )}
                    </UserInfoItemContainer>
                  );
                })
              : null}
            {isEditMode && (
              <AddProfileInfoButtonWrapper>
                <HoverablePlusIconButton
                  onClick={() =>
                    actions.openModal({
                      modal: ModalType.ADD_ATHLETE_HEADER_INFO_MODAL,
                      title: t("profileInfo"),
                      titleAlignment: "flex-start",
                      params: { parentReportId, headerTemplate },
                    })
                  }
                />
              </AddProfileInfoButtonWrapper>
            )}
          </UserInfoContainer>
        </UserDataContainer>
      </DataContainer>

      <TabsWrapper
        leftOverflow={leftOverflow}
        rightOverflow={rightOverflow}
        isMobile={isMobile}
      >
        <Tabs
          tab={activeTab}
          setTab={onTabChange}
          tabs={tabs}
          onScroll={handleScroll}
          afterLastTabComponent={
            isEditMode ? (
              <AddReportButtonWrapper>
                <HoverablePlusIconButton
                  onClick={() =>
                    actions.openModal({
                      modal: ModalType.ADD_TAB_REPORT_MODAL,
                      title: t("editTabs"),
                      titleAlignment: "flex-start",
                      params: { parentReportId, tabReports },
                    })
                  }
                />
              </AddReportButtonWrapper>
            ) : null
          }
        />
      </TabsWrapper>
    </HeaderContainer>
  );
};
