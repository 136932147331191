import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { AggregateFunction } from "../../../graphql";
import { TimeLine } from "../../Report/ChartWIdget/model";
import {
  AthleteTablePeriodizationColumnHeader,
  TablePeriodizationColumnHeader,
  TablePeriodizationForm,
} from "../../Report/PeriodizationTable/model";
import { FieldErrorMessage } from "../../Report/styled";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import {
  DragDropContainer,
  RemoveFunction,
} from "../../Wrappers/DragDropContainer";

import { DataSelectionItem } from "./DataSelectionItem";

export function TablePeriodizationDataSelectionSection({
  isGroup,
}: {
  isGroup?: boolean;
}) {
  const { t } = useTranslation();
  const { values, errors, setFieldValue } =
    useFormikContext<TablePeriodizationForm>();

  const defaultColumn = useMemo<
    AthleteTablePeriodizationColumnHeader | TablePeriodizationColumnHeader
  >(
    () => ({
      attributeTemplateId: "",
      collectionId: "",
      itemId: "",
      aggregateFunctions: [
        values.timeLine === TimeLine.CONTINUOUS
          ? AggregateFunction.Each
          : AggregateFunction.Latest,
      ],
    }),
    [values.timeLine]
  );

  const handleRemoveOfDataItem =
    (remove: RemoveFunction) => (index: number) => {
      const itemToRemove = values.columnHeaders[index];
      const allPossibleConfigValues = itemToRemove.aggregateFunctions.map(
        (agg) =>
          `${itemToRemove.itemId}:${itemToRemove.attributeTemplateId}:${agg}`
      );

      const newColoringConfig = values.coloringConfig.filter(
        (config) => !allPossibleConfigValues.includes(config.id)
      );

      // Remove coloring config for removed item
      setFieldValue("coloringConfig", newColoringConfig);

      // Remove item
      remove(index);
    };

  return (
    <Section>
      <SectionTitle title={t("columns")} />
      <DragDropContainer
        fieldArrayName="columnHeaders"
        buttonText={t("addColumn")}
        hideButton={isGroup && values.columnHeaders.length >= 1}
        defaultNewEntity={defaultColumn}
        items={values.columnHeaders.map(
          (currentColumn, index) =>
            ({ dragHandleProps, remove }) => (
              <DataSelectionItem
                key={
                  isGroup
                    ? index
                    : `${currentColumn.itemId}-${currentColumn.attributeTemplateId}-${index}`
                }
                isGroup={isGroup}
                columnIndex={index}
                currentColumn={currentColumn}
                dragHandleProps={dragHandleProps}
                remove={isGroup ? remove : handleRemoveOfDataItem(remove)}
              />
            )
        )}
      />
      {typeof errors.columnHeaders === "string" && (
        <FieldErrorMessage>{errors.columnHeaders}</FieldErrorMessage>
      )}
    </Section>
  );
}
