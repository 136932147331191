import React, { createContext, ReactNode, useContext, useReducer } from "react";

import actionsCreator from "../../../utils/actionsCreator";

import * as fromActions from "./actions";
import reducer, {
  initialModalContextState,
  ModelContextInitialStateValue,
} from "./reducers";

type Actions = {
  openModal(
    object: Partial<Omit<ModelContextInitialStateValue, "open" | "actions">>
  ): void;
  closeModal(): void;
  renameModal({ title }: Pick<ModelContextInitialStateValue, "title">): void;
};

const initialActions: Actions = {
  openModal: () => {},
  closeModal: () => {},
  renameModal: () => {},
};

export const ModalContext = createContext<
  ModelContextInitialStateValue & { actions: Actions }
>({
  ...initialModalContextState,
  actions: initialActions,
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialModalContextState);
  const actions = actionsCreator(fromActions, dispatch);

  return (
    <ModalContext.Provider value={{ ...state, actions }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);

export { ModalType } from "./constants";
