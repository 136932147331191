import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormProvider } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isNumber } from "lodash";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import { useResourceCalendarContext } from "../../../contexts/resourceCalendar/ResourceCalendarProvider";
import { useDefaultDateFormat } from "../../../hooks";
import {
  SessionTypeNumber,
  useSessionColors,
} from "../../../hooks/useSessionColors";
import { SessionType } from "../../../services/sessions";
import { StorageKeys } from "../../../utils/storageKeys";
import { ControlledCalendar } from "../../HookForm/ControlledCalendar";
import { ControlledColorPicker } from "../../HookForm/ControlledColorPicker";
import { ControlledInput } from "../../HookForm/ControlledInput";
import { ControlledLocationSelect } from "../../HookForm/ControlledLocationSelect";
import { ControlledTextArea } from "../../HookForm/ControlledTextArea";
import { ControlledTitleSelect } from "../../HookForm/ControlledTitleSelect";
import { ResourceLocationSelector } from "../../HookForm/ResourceLocationSelector";
import { ColumnOfRowWrapper } from "../../HookForm/styled";
import { LabelText } from "../../HookForm/StyledInputComponents";
import { IconButton } from "../../IconButton";
import { ChevronRightIcon } from "../../Icons";
import { Spinner } from "../../Spinner";
import StyledText from "../../StyledText";

dayjs.extend(customParseFormat);

type PracticeFormProps = {
  formMethods;
  isSectionHidden: boolean;
  setSelectedGoogleLocationId: Dispatch<SetStateAction<string>>;
  selectedGoogleLocationId: string;
  googleMapsUrl?: string;
  toggleSectionVisibility: () => void;
  setManuallyToggle?: () => void;
  practiceId?: string;
};

const SpinnerWrapper = styled.div`
  height: 50vh;
  align-items: center;
  display: flex;
`;

const FormWrapper = styled.div<{
  shouldExpand?: boolean;
  isSectionHidden?: boolean;
  bottomPadding: number;
}>`
  padding: 30px 20px 0 30px;
  padding-bottom: ${({ bottomPadding }) => bottomPadding}px;
  overflow: ${({ shouldExpand, isSectionHidden }) =>
    shouldExpand ? "visible" : isSectionHidden ? "hidden" : "auto"};
  display: flex;
  flex-direction: column;
`;

const Row = styled.div<{ zIndexValue?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
  height: 80px;
`;

const InputInfoText = styled(StyledText)<{
  isLeft?: boolean;
  isRight?: boolean;
  margin?: string;
  width?: number;
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  align-self: center;
  margin-left: ${({ isRight, margin = "10px" }) => (isRight ? margin : 0)};
  margin-right: ${({ isLeft, margin = "10px" }) => (isLeft ? margin : 0)};
  color: ${COLOR_TEXT_DARK};
  ${({ width }) => (isNumber(width) ? `width: ${width}px` : "")};
`;

const SmallInputWrapper = styled.div`
  width: 70px;
`;

const ToggleButtonContainer = styled.div`
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const HidingSectionContainer = styled.div<{ isSectionHidden: boolean }>`
  transition: height 0.6s;
  height: ${({ isSectionHidden }) => `${isSectionHidden ? 0 : 190}px`};
`;

const DEFAULT_NUM_ROWS = 3;
const MAX_NUM_ROWS = 6;
const DEFAULT_AREA_HEIGHT = 54;
const LINE_HEIGHT = 18;

export function PracticeForm({
  formMethods,
  isSectionHidden,
  selectedGoogleLocationId,
  setSelectedGoogleLocationId,
  googleMapsUrl,
  toggleSectionVisibility,
  setManuallyToggle,
  practiceId,
}: PracticeFormProps) {
  const coachNotesTextAreaRef = useRef(null);
  const notesTextAreaRef = useRef(null);
  const [notesNumRows, setNotesNumRows] = useState(0);
  const [coachNotesNumRows, setCoachNotesNumRows] = useState(0);
  const [calendarIsShow, setCalendarIsShow] = useState(false);
  const [titleMenuIsOpen, setTitleMenuIsOpen] = useState(false);
  const [locationMenuIsOpen, setLocationMenuIsOpen] = useState(false);
  const { selectableResourceList } = useResourceCalendarContext();

  const { t } = useTranslation();
  const { dateFormat } = useDefaultDateFormat();

  const { colorList } = useSessionColors(SessionTypeNumber.Practice);
  const { sessionDataLoading, practiceTotalDuration } = usePracticeContext();

  const numRows = Math.max(coachNotesNumRows, notesNumRows);

  const { watch, resetField, setValue, ...methods } = formMethods;
  useFormPersist(StorageKeys.practiceForm, {
    watch,
    setValue,
    storage: window.localStorage,
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === "change" && name === "time") {
        const [hour, minute] = value.time.split(":");
        const updatedDate = value.date.set("hour", hour).set("minute", minute);

        setValue("date", updatedDate);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, practiceTotalDuration]);

  useEffect(() => {
    const currentEventDuration = watch("duration");

    if (
      !!practiceTotalDuration &&
      practiceTotalDuration !== currentEventDuration
    ) {
      setValue("duration", practiceTotalDuration);
    }
  }, [practiceTotalDuration, setValue, watch]);

  // TODO refactor if possible
  const currentEventDate = watch("date");
  const currentEventTime = watch("time");
  const currentEventDuration = watch("duration");

  const [hour, minute] = currentEventTime?.split(":");

  const updatedStartDate = dayjs(currentEventDate)
    .set("hour", hour)
    .set("minute", minute);
  const updatedEndDate = dayjs(updatedStartDate).add(
    currentEventDuration,
    "minute"
  );

  if (sessionDataLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const resizeCoachNotesTextArea = () => {
    if (numRows <= DEFAULT_NUM_ROWS) {
      notesTextAreaRef.current.style.height = `${DEFAULT_AREA_HEIGHT}px`;
      coachNotesTextAreaRef.current.style.height = `${DEFAULT_AREA_HEIGHT}px`;
    }

    if (numRows > DEFAULT_NUM_ROWS && numRows <= MAX_NUM_ROWS) {
      const textAreaHeight =
        DEFAULT_AREA_HEIGHT + (numRows - DEFAULT_NUM_ROWS) * LINE_HEIGHT;

      notesTextAreaRef.current.style.height = `${textAreaHeight}px`;
      coachNotesTextAreaRef.current.style.height = `${textAreaHeight}px`;
    }
  };

  const toggleWithButton = () => {
    toggleSectionVisibility();
    setManuallyToggle();
  };

  const shouldExpand = calendarIsShow || locationMenuIsOpen || titleMenuIsOpen;

  const formWrapperExtraBottomPadding =
    numRows > DEFAULT_NUM_ROWS && !isSectionHidden
      ? Math.min(numRows, MAX_NUM_ROWS) * 10
      : 0;

  return (
    <FormProvider
      watch={watch}
      resetField={resetField}
      setValue={setValue}
      {...methods}
    >
      <FormWrapper
        shouldExpand={shouldExpand}
        isSectionHidden={isSectionHidden}
        bottomPadding={formWrapperExtraBottomPadding}
      >
        <Row zIndexValue="4">
          <ColumnOfRowWrapper width={40} rightMargin>
            <ControlledTitleSelect
              name="title"
              label={t("title")}
              sessionType={SessionType.PRACTICE}
              isLabel
              onOpen={setTitleMenuIsOpen}
            />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={30} rightMargin style={{ zIndex: 999 }}>
            <ControlledCalendar
              name="date"
              label={t("date")}
              styledForRight={-30}
              dateFormat={dateFormat}
              onCalendarToggle={(show) => {
                setCalendarIsShow(show);
                if (isSectionHidden) {
                  toggleSectionVisibility();
                }
              }}
            />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={10} rightMargin maxWidth={200} withoutFlex>
            <ControlledInput name="time" label={t("time")} type="time" />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={15} rightMargin maxWidth={130}>
            <SmallInputWrapper>
              <ControlledInput
                name="duration"
                label={t("duration")}
                type="number"
                min="0"
                max="999"
              />
            </SmallInputWrapper>
            <InputInfoText isRight margin="5px" style={{ marginRight: 10 }}>
              {t("min")}
            </InputInfoText>
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={10} rightMargin maxWidth={100}>
            <ControlledColorPicker
              name="color"
              label={t("color")}
              colorList={colorList}
              toggleColorPicker={(isOpen) =>
                isOpen && isSectionHidden && toggleSectionVisibility()
              }
            />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={5} rightMargin flexColumn>
            <LabelText>&nbsp;</LabelText>
            <ToggleButtonContainer>
              <IconButton
                icon={
                  <ChevronRightIcon
                    direction={isSectionHidden ? "bottom" : "top"}
                  />
                }
                hoverBgColor={COLOR_GRAY}
                bgColor={COLOR_GRAY}
                onClick={toggleWithButton}
              />
            </ToggleButtonContainer>
          </ColumnOfRowWrapper>
        </Row>
        <HidingSectionContainer isSectionHidden={isSectionHidden}>
          <Row zIndexValue="2">
            <ColumnOfRowWrapper
              rightMargin
              style={{ width: "calc(50% - 100px)" }}
            >
              {selectableResourceList?.length ? (
                <ResourceLocationSelector
                  from={updatedStartDate.valueOf()}
                  to={updatedEndDate.valueOf()}
                  setSelectedGoogleLocationId={setSelectedGoogleLocationId}
                  selectedGoogleLocationId={selectedGoogleLocationId}
                  googleMapsUrl={googleMapsUrl}
                  practiceId={practiceId}
                  onMenuToggle={(isOpen) => setLocationMenuIsOpen(isOpen)}
                  maxMenuHeight={210}
                />
              ) : (
                <ControlledLocationSelect
                  name="location"
                  label={t("location")}
                  sessionType={SessionType.PRACTICE}
                  setSelectedGoogleLocationId={setSelectedGoogleLocationId}
                  selectedGoogleLocationId={selectedGoogleLocationId}
                  googleMapsUrl={googleMapsUrl}
                  maxMenuHeight={210}
                  onMenuToggle={(isOpen) => setLocationMenuIsOpen(isOpen)}
                />
              )}
            </ColumnOfRowWrapper>
            <ColumnOfRowWrapper width={35} rightMargin withoutFlex>
              <ControlledInput
                name="locationDescription"
                label={t("locationDescription")}
              />
            </ColumnOfRowWrapper>
            <ColumnOfRowWrapper
              width={15}
              rightMargin
              justifyContent="center"
              maxWidth={300}
            >
              <InputInfoText isLeft margin="5px">
                {t("arrive")}
              </InputInfoText>
              <SmallInputWrapper style={{ marginTop: 12 }}>
                <ControlledInput
                  name="attendance"
                  label=""
                  type="number"
                  min="0"
                  max="999"
                />
              </SmallInputWrapper>
              <InputInfoText width={30} isRight margin="5px">
                {t("minBefore").toLocaleLowerCase()}
              </InputInfoText>
            </ColumnOfRowWrapper>
          </Row>
          <Row>
            <ColumnOfRowWrapper width={90} rightMargin withoutFlex>
              <ControlledTextArea
                name="notes"
                label={t("notes")}
                ref={notesTextAreaRef}
                resizeTextArea={resizeCoachNotesTextArea}
                numberOfRows={DEFAULT_NUM_ROWS}
                setNumOfRows={setNotesNumRows}
              />
            </ColumnOfRowWrapper>
            <ColumnOfRowWrapper width={90} rightMargin withoutFlex>
              <ControlledTextArea
                name="coachNotes"
                label={t("coachNotes")}
                ref={coachNotesTextAreaRef}
                resizeTextArea={resizeCoachNotesTextArea}
                numberOfRows={DEFAULT_NUM_ROWS}
                setNumOfRows={setCoachNotesNumRows}
              />
            </ColumnOfRowWrapper>
          </Row>
        </HidingSectionContainer>
      </FormWrapper>
    </FormProvider>
  );
}
