import { useTranslation } from "react-i18next";

import { EventType } from "../../components/Filters/model";
import { getUpdatedDate } from "../../components/Modals/ChatModals/utils";
import { CreateOrUpdateFormState } from "../../components/Modals/CreateEventOrGameModal";
import { useNotificationsContext } from "../../contexts/notifications";
import { ResourcesUsageInput } from "../../graphql";
import { useCreateEvent } from "../data/useCreateEvent";
import { useCreateTeamEvent } from "../data/useCreateTeamEvent";
import { useCreateTeamGame } from "../data/useCreateTeamGame";
import { useCreateWorkout } from "../data/useCreateWorkout";
import { useUpdateSession } from "../data/useUpdateSession";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useCreateOrUpdateSession = () => {
  const { t } = useTranslation();

  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const { createNewTeamGame } = useCreateTeamGame();
  const { createNewTeamEvent } = useCreateTeamEvent();
  const { createNewIndividualEvent } = useCreateEvent();
  const { updateSessionMutation } = useUpdateSession();
  const { createWorkout } = useCreateWorkout();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const createOrUpdateSession = async ({
    data,
    selectedGoogleLocationId: googleLocationId,
    session,
    eventTypename,
    resourcesUsage,
    groupId,
  }: {
    data: CreateOrUpdateFormState;
    selectedGoogleLocationId: string;
    session?: any;
    eventTypename: EventType;
    resourcesUsage?: ResourcesUsageInput;
    groupId?: string;
  }) => {
    try {
      const start = getUpdatedDate(data.startDate, data.startTime);
      const durationMinutes = data?.durationMinutes ?? 0;

      let createdId: string | null = null;

      if (session) {
        updateSessionMutation(session, () => ["agenda", "calendar"], {
          label: data.label || data.title,
          start,
          durationMinutes,
          attendedMinutesBefore: Number(data.attendance),
          googleLocationId,
          location: data.location,
          xtraLocation: data.locationDescription,
          notes: data.notes,
          specialNotes: data.specialNotes,
          colorHexCode: data.color,
          resourcesUsage,
        });
      } else {
        if (eventTypename.toLowerCase() === EventType.TEAM_EVENT) {
          createNewTeamEvent(
            data.title,
            start,
            durationMinutes,
            Number(data.attendance),
            googleLocationId,
            data.location,
            data.locationDescription,
            data.notes,
            data.color,
            groupId,
            resourcesUsage,
            () => ["agenda", "calendar"]
          );
        }

        if (eventTypename.toLowerCase() === EventType.EVENT) {
          createNewIndividualEvent(
            data.title,
            start,
            durationMinutes,
            Number(data.attendance),
            googleLocationId,
            data.location,
            data.locationDescription,
            data.notes,
            data.color,
            resourcesUsage,
            () => ["agenda", "calendar"]
          );
        }

        if (eventTypename.toLowerCase() === EventType.TEAM_GAME) {
          createNewTeamGame(
            data.title,
            start,
            durationMinutes,
            Number(data.attendance),
            googleLocationId,
            data.location,
            data.locationDescription,
            data.notes,
            data.color,
            groupId,
            resourcesUsage,
            () => ["agenda", "calendar"]
          );
        }

        if (eventTypename.toLowerCase() === EventType.WORKOUT) {
          const {
            label,
            location,
            notes,
            color,
            focusedAthlete,
            locationDescription,
            specialNotes,
          } = data;

          const res = await createWorkout(
            {
              label,
              start,
              durationMinutes,
              attendedMinutesBefore: Number(data.attendance),
              googleLocationId,
              location,
              xtraLocation: locationDescription,
              notes,
              specialNotes,
              colorHexCode: color,
              groupID: groupId,
              resourcesUsage,
              focusedAthlete,
            },
            () => ["agenda", "calendar"]
          );
          if (res?.data?.createWorkout?.id) {
            createdId = res.data.createWorkout.id;
          }
          handleSendManualNotificationAfterAction(
            { ...res?.data?.createWorkout },
            res?.data?.createWorkout?.notificationToSuggest
          );
        }
      }
      if (session) {
        showSuccessNotification(t("updateSessionSuccess"));
      } else {
        showSuccessNotification(t("createSessionSuccess"));
      }
      return { createdId };
    } catch {
      showErrorNotification(t("createSessionError"));
    }
  };

  return { createOrUpdateSession };
};
