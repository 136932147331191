import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../../colors";

const TableHeaderRow = styled.tr`
  height: 35px;
  background-color: ${COLOR_WHITE};
`;

const TableHeader = styled.th`
  text-align: left;
  &:first-child {
    border-top-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
  }
`;

const TableHeaderContent = styled.div`
  border-left-width: 1px;
  border-left-color: ${COLOR_GREY_ACTIVE};
  height: 15px;
`;

const Label = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-left: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  cursor: default;
`;

export function TeamHeader({ titleRowShouldBeVisible }) {
  const { t } = useTranslation();

  if (!titleRowShouldBeVisible) {
    return null;
  }

  return (
    <TableHeaderRow>
      <TableHeader />
      <TableHeader>
        <TableHeaderContent>
          <Label>{t("phoneNumber").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader>
        <TableHeaderContent>
          <Label>{t("email").toUpperCase()}</Label>
        </TableHeaderContent>
      </TableHeader>
      <TableHeader />
    </TableHeaderRow>
  );
}
