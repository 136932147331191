import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const RoundedGroupProfileIcon = memo(
  ({ width = 45, height = 45, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="22.5" cy="22.5" r="22.5" fill="#E6E8EB" />
      <circle
        cx="22.5"
        cy="22.5"
        r="22.25"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
      <circle cx="22.5" cy="20.5" r="2.5" fill="#B1B7BC" />
      <circle cx="18.5" cy="15.5" r="2.5" fill="#B1B7BC" />
      <circle cx="26.5" cy="15.5" r="2.5" fill="#B1B7BC" />
      <path
        d="M19 27.5C19 25.567 20.567 24 22.5 24C24.433 24 26 25.567 26 27.5V30C26 31.1046 25.1046 32 24 32H21C19.8954 32 19 31.1046 19 30V27.5Z"
        fill="#B1B7BC"
      />
      <circle cx="14.5" cy="20.5" r="2.5" fill="#B1B7BC" />
      <path
        d="M11 27.5C11 25.567 12.567 24 14.5 24C16.433 24 18 25.567 18 27.5V30C18 31.1046 17.1046 32 16 32H13C11.8954 32 11 31.1046 11 30V27.5Z"
        fill="#B1B7BC"
      />
      <circle cx="30.5" cy="20.5" r="2.5" fill="#B1B7BC" />
      <path
        d="M27 27.5C27 25.567 28.567 24 30.5 24C32.433 24 34 25.567 34 27.5V30C34 31.1046 33.1046 32 32 32H29C27.8954 32 27 31.1046 27 30V27.5Z"
        fill="#B1B7BC"
      />
    </svg>
  )
);
