import React, { useEffect, useMemo, useState } from "react";
import { useDrop } from "react-dnd";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import debounce from "lodash/debounce";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK, COLOR_WHITE } from "../../../colors";
import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { useUserContext } from "../../../contexts/User";
import {
  Document,
  Drill,
  InlineComment,
  PracticeInlineComment,
  SubPractice,
  useGetTheGroupsImInLazyQuery,
  usePracticeMembersLazyQuery,
} from "../../../graphql";
import { useModularContentFetching } from "../../../hooks/useModularContentFetching";
import { ArrowIcon, RemoveIcon } from "../../Icons";
import { ModularContent } from "../../ModularContent/ModularContent";
import { StyledTextInput } from "../../StyledTextInput";

import { ItemTypes, PracticeLine } from "./model";

type PlanItemProps = {
  practiceLine?: PracticeLine;
  inlineComment?: (PracticeInlineComment | InlineComment | SubPractice) & {
    comment?: string;
    isDraft?: boolean;
  };
  index: number;
  splitAreaId?: string;
  splitItemId?: string;
};

type PlanItemFormState = {
  notes: string;
  name: string;
  heading: string;
  minutes: number;
  time: string;
  intensity: string;
  text1: string;
  title1: string;
};

const Wrapper = styled.div`
  border-radius: 6px;
  border: 1px solid rgba(60, 67, 76, 0.04);
  background: ${COLOR_WHITE};
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
  margin: 8px 6px 0 6px;
  padding: 20px 20px 20px 30px;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
`;

const Title = styled.div`
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  font-weight: 700;
`;

const ActionWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const OpenButton = styled.button<{ isLast?: boolean }>`
  padding: ${({ isLast }) => (isLast ? "10px 0 10px 15px" : "10px 15px")};
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_BLUE};
  cursor: pointer;
`;

const MoveButtonWrapper = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  display: flex;
  align-items: center;
`;

const RemoveIconWrapper = styled.div`
  padding: 10px;
  cursor: pointer;
`;

export function PlanItem({
  practiceLine,
  inlineComment,
  index,
  splitAreaId,
  splitItemId,
}: PlanItemProps) {
  const { t } = useTranslation();
  const topModal = useTopModalContext();
  const {
    practiceSession,
    practiceLines,
    setPracticeLines,
    handleRemovePlanItem,
    handleMoveItem,
    handleMoveSplitAreaItem,
    handleCommentTextChange,
    handleRemovePlanItemSplitView,
    currentGroupId,
  } = usePracticeContext();
  const { sessionId, language, timezone } = useUserContext();
  const [getPracticeMembers] = usePracticeMembersLazyQuery();
  const [getGroupsImIn] = useGetTheGroupsImInLazyQuery();

  const handleDrop = (item) => {
    if (splitAreaId) {
      const updatedPracticeLines = practiceLines?.map(
        (practiceLine: PracticeLine) => {
          if (
            "subPractices" in practiceLine &&
            practiceLine?.subPractices?.length
          ) {
            const updatedSubPractices = practiceLine?.subPractices?.map(
              (subPractice) => {
                if (subPractice.id === splitAreaId) {
                  return {
                    ...subPractice,
                    practiceLines: [
                      ...subPractice?.practiceLines.slice(0, index),
                      {
                        ...item?.file,
                        localDuration: 10,
                      },
                      ...subPractice?.practiceLines.slice(index),
                    ],
                  };
                }
                return subPractice;
              }
            );
            return { ...practiceLine, subPractices: updatedSubPractices };
          }
          return practiceLine;
        }
      );

      setPracticeLines(updatedPracticeLines as PracticeLine[]);
    } else {
      const updatedPracticeLines = [
        ...practiceLines.slice(0, index),
        {
          ...item?.file,
          localDuration: 10,
        },
        ...practiceLines.slice(index),
      ];

      setPracticeLines(updatedPracticeLines);
    }
  };

  const [, drop] = useDrop({
    accept: [ItemTypes.FILE],
    drop: handleDrop,
  });

  const localPracticeLength = useMemo(() => {
    if (splitItemId) {
      let length = 0;

      practiceLines?.map(
        (practiceLine) =>
          "subPractices" in practiceLine &&
          practiceLine.subPractices?.map((subPractice) => {
            if (subPractice.id === splitAreaId) {
              length = subPractice?.practiceLines?.length;
            }
          })
      );

      return length;
    } else {
      return practiceLines?.length;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitItemId, practiceLines]);

  const inputText = inlineComment
    ? inlineComment.comment || ("text" in inlineComment && inlineComment.text)
    : "";
  const isSplitSection = splitAreaId && splitAreaId === inlineComment?.id;

  const [inputValue, setInputValue] = useState(inputText || "");

  const duration = practiceLine?.localDuration || practiceLine?.minutes;
  const durationToRemove = dayjs.duration(duration, "minute");
  const rawStartTime = dayjs(practiceLine?.endTime, "HH:mm");
  const practiceLineStartTime = rawStartTime
    .subtract(durationToRemove)
    .format("HH:mm");
  const { modularContent } = useModularContentFetching(practiceLine);
  const textValues = useMemo(
    () =>
      modularContent?.paragraphs?.reduce((acc, cur, i) => {
        acc[`text${i + 1}`] = cur?.text;
        acc[`title${i + 1}`] = cur?.title;
        return acc;
      }, {}),
    [modularContent?.paragraphs]
  );
  const { getValues, reset, ...formMethods } = useForm<PlanItemFormState>({
    mode: "all",
    defaultValues: {
      name: practiceLine && "name" in practiceLine ? practiceLine?.name : "",
      notes: "",
      minutes: duration,
      ...textValues,
      time: `${practiceLineStartTime} - ${practiceLine?.endTime}`,
      intensity: "",
    },
  });

  useEffect(() => {
    formMethods.setValue(
      "time",
      `${practiceLineStartTime} - ${practiceLine?.endTime}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practiceLineStartTime, practiceLine?.endTime]);

  useEffect(() => {
    if (textValues) {
      reset({
        ...getValues(),
        ...("text1" in textValues ? textValues : { text1: "", title1: "" }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textValues]);

  const handleOpenModal = () => {
    if (practiceLine) {
      topModal.actions.openTopModal({
        modal: TopModalType.DOCUMENT,
        title: " ",
        params: {
          hidePopularButton: true,
          showDocumentName: true,
          document: {
            ...practiceLine,
            mcUrl: (practiceLine as Drill)?.urlModularContent,
          },
        },
      });
    }
  };

  const debouncedHandleChange = useMemo(
    () =>
      debounce((value: string) => {
        if (inlineComment?.id) {
          handleCommentTextChange(inlineComment.id, value, splitAreaId);
        }
      }, 250),
    [handleCommentTextChange, inlineComment?.id, splitAreaId]
  );

  const handleInputChange = (event) => {
    const value = event.currentTarget.value;
    setInputValue(value);
    debouncedHandleChange(value);
  };

  const handleOpenAthletesModal = async () => {
    let practiceMembers = [];
    let teamName = practiceSession?.teamName ?? "";
    let attendingAthletes =
      practiceLine && "athletes" in practiceLine
        ? practiceLine.athletes
        : inlineComment && "athletes" in inlineComment
          ? inlineComment.athletes
          : [];

    if (practiceSession?.id) {
      const res = await getPracticeMembers({
        variables: {
          id: practiceSession.id,
          sessionId,
          timezone,
          language,
        },
      });
      if (res.data) {
        practiceMembers = res.data.practice.members.athletes;
      }
    } else if (currentGroupId) {
      const res = await getGroupsImIn({
        variables: {
          sessionId,
          timezone,
          language,
        },
      });
      if (res?.data) {
        res.data.getTheGroupsImIn.forEach((group) => {
          if (group.id === currentGroupId) {
            teamName = group.name;
            practiceMembers = group.athletes;
          } else {
            const subGroup = group.subGroups.find(
              (sg) => sg.id === currentGroupId
            );
            if (subGroup) {
              teamName = subGroup.name;
              practiceMembers = subGroup.athletes;
            }
          }
        });
      }

      practiceLines?.forEach((line) => {
        if ("subPractices" in line) {
          line.subPractices?.forEach((subPractice) => {
            if (
              subPractice.id === inlineComment?.id ||
              subPractice.id === practiceLine?.id
            ) {
              attendingAthletes = subPractice.athletes;
            }
          });
        }
      });
    }

    topModal.actions.openTopModal({
      modal: TopModalType.SELECT_ATHLETES_FROM_GROUP,
      title: t("selectAthletes"),
      params: {
        groupId: currentGroupId,
        subPracticeId: practiceLine?.id || inlineComment?.id,
        availableAthletes: practiceMembers,
        attendingAthletes,
        teamName,
        isDraft: !!inlineComment?.isDraft,
        isExistingSession: !!practiceSession?.id,
      },
    });
  };

  const onMove = (direction: string) => {
    if (splitItemId) {
      handleMoveSplitAreaItem(index, direction, splitAreaId);
    } else {
      handleMoveItem(index, direction);
    }
    const event = new CustomEvent("movePracticeLine", {
      detail: {
        id: practiceLine?.id,
      },
    });
    document.dispatchEvent(event);
  };

  const onRemove = () => {
    if (splitItemId) {
      handleRemovePlanItemSplitView(
        (practiceLine ?? inlineComment).id,
        splitAreaId
      );
    } else {
      handleRemovePlanItem((practiceLine ?? inlineComment).id, splitAreaId);
    }
  };

  return (
    <FormProvider
      watch={formMethods.watch}
      resetField={formMethods.resetField}
      setValue={formMethods.setValue}
      getValues={getValues}
      reset={reset}
      {...formMethods}
    >
      <Wrapper id={`planItem-${practiceLine?.id}`} ref={drop}>
        <TitleRow>
          <Title>
            {practiceLine && "name" in practiceLine ? practiceLine?.name : ""}
          </Title>
          <ActionWrapper>
            {practiceLine && (
              <OpenButton onClick={handleOpenModal}>{t("open")}</OpenButton>
            )}
            {localPracticeLength > 1 && (
              <>
                <MoveButtonWrapper onClick={() => onMove("up")}>
                  <ArrowIcon />
                </MoveButtonWrapper>
                <MoveButtonWrapper onClick={() => onMove("down")}>
                  <ArrowIcon direction="bottom" />
                </MoveButtonWrapper>
              </>
            )}
            <RemoveIconWrapper onClick={onRemove}>
              <RemoveIcon />
            </RemoveIconWrapper>
            {isSplitSection && (
              <OpenButton onClick={handleOpenAthletesModal} isLast>
                {t("athletes")}
              </OpenButton>
            )}
          </ActionWrapper>
        </TitleRow>

        {practiceLine && (
          <ModularContent
            collection={practiceLine as Document | Drill}
            formMethods={formMethods}
            splitAreaId={splitAreaId}
            isSplit={!!splitItemId}
          />
        )}

        {inlineComment && (
          <StyledTextInput
            placeholder={t("addNotes")}
            onChange={handleInputChange}
            value={inputValue}
            autoFocus={true}
          />
        )}
      </Wrapper>
    </FormProvider>
  );
}
