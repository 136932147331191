import React from "react";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_GREY_ACTIVE } from "../../../colors";

import { WorkoutLine } from "./WorkoutLine";

const Wrapper = styled.div`
  background-color: ${COLOR_GRAY};
  padding: 20px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  border-top-width: 1px;
  border-top-color: ${COLOR_GREY_ACTIVE};
  border-top-style: solid;
`;

export const WorkoutLines = ({ workoutLines }) => {
  return (
    <Wrapper>
      {workoutLines?.map((workoutLine) => (
        <WorkoutLine key={workoutLine.id} workoutLine={workoutLine} />
      ))}
    </Wrapper>
  );
};
