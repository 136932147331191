import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_RED,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Game, Practice, Workout, Event } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import Avatar from "../Avatar";
import {
  ArrowInCircleIcon,
  ChevronRightIcon,
  LocationPointIcon,
  TimeIcon,
} from "../Icons";
import { SessionDropdown } from "../SessionDropdown";
import StyledText from "../StyledText";

import { SessionTime } from "./SessionTime";

dayjs.extend(localizedFormat);

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
`;

const SessionDropdownWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 30px;
  top: 10px;
  z-index: 2;
`;

const SessionLine = styled.div<{ isThumb: boolean; rgb: string }>`
  margin-top: ${({ isThumb }) => (isThumb ? 10 : 20)}px;
  margin-bottom: 20px;
  border-radius: 4px;
  height: 3px;
  background-color: ${({ rgb }) => `rgb(${rgb})`};
`;

const InfoContainer = styled.div<{ isThumb?: boolean }>`
  padding: ${({ isThumb }) => (isThumb ? "10px 20px 0" : "20px 20px 0")};
  background-color: ${COLOR_WHITE};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  z-index: 10;
  position: relative;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 15px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 20px;
  color: ${COLOR_TEXT_DARK};
`;

const CancelledTitle = styled(Title)`
  color: ${COLOR_RED};
  text-transform: uppercase;
  font-size: 20px;
`;

const InfoText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
`;

const InfoTextExtra = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-left: 10px;
  color: ${COLOR_TEXT_DARK};
`;

const NoteText = styled.p<{ isExpanded: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  overflow: hidden;
  transition: max-height 300ms;
  margin: 0;

  ${({ isExpanded }) =>
    isExpanded
      ? `
      white-space: pre-wrap;
      overflow-wrap: break-word;`
      : `
      height: 17px;
      white-space: nowrap;
      text-overflow: ellipsis;`};
`;

const ShowOnMapText = styled.span`
  font-weight: 700;
  font-size: 12px;
  color: ${COLOR_BLUE};
`;

const ShowOnMapWrapper = styled.div`
  display: flex;
  padding-left: 6px;
  cursor: pointer;
  align-items: center;
`;

const NoteSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const SpecialNoteLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  margin-bottom: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

interface SessionInfoProps {
  session: Event | Game | Practice | Workout;
  refetch?: () => void;
}

export function SessionInfo({ session, refetch }: SessionInfoProps) {
  const { t } = useTranslation();
  const { isFamilyMember, isTrainer } = useUserContext();
  const [isNoteOpen, setNoteOpen] = useState(true);
  const [isSpecialNoteOpen, setSpecialNoteOpen] = useState(true);

  const isProgramPreview = false;
  const programDayInfo = null;

  let supTitle;
  if (isProgramPreview) {
    supTitle = programDayInfo;
  } else {
    const parentGroupName =
      "parentGroupName" in session ? session.parentGroupName : "";

    supTitle = (
      <TextContentWrapper>
        {parentGroupName ? (
          <Fragment>
            <StyledText bold fontSize={16} style={{ marginBottom: 7 }}>
              {parentGroupName.toUpperCase()}
            </StyledText>
            <StyledText
              semibold
              color={COLOR_TEXT_DEFAULT}
              fontSize={16}
              style={{ marginBottom: 7 }}
            >
              {" - "}
            </StyledText>
          </Fragment>
        ) : null}
        {!!session.teamName && (
          <StyledText fontSize={16} style={{ marginBottom: 7 }}>
            {session.teamName}
          </StyledText>
        )}
      </TextContentWrapper>
    );
  }

  return (
    <InfoContainer isThumb={!!session?.ownerUser?.thumb}>
      {isRealTrainer({ isFamilyMember, isTrainer }) && (
        <SessionDropdownWrapper>
          <SessionDropdown
            session={session}
            shouldNavigateBack={true}
            isSessionRoute={true}
            isProgramPreview={isProgramPreview}
            refetch={refetch}
          />
        </SessionDropdownWrapper>
      )}
      <HeaderContent>
        {session?.ownerUser?.thumb && (
          <StyledAvatar source={{ uri: session.ownerUser.thumb }} size={35} />
        )}
        <SessionTime
          attendedMinutesBefore={session.attendedMinutesBefore}
          sessionStart={session.start}
          duration={session.durationMinutes}
        />
      </HeaderContent>
      <SessionLine isThumb={!!session?.ownerUser?.thumb} rgb={session.rgb} />
      <div>
        <TitleWrapper>
          <Title>{session.label}</Title>
          {session.cancelled ? (
            <CancelledTitle>{t("cancelled")}</CancelledTitle>
          ) : null}
        </TitleWrapper>
        {supTitle}
        {!!session.location && (
          <TextContentWrapper>
            <IconWrapper>
              <LocationPointIcon />
            </IconWrapper>
            <InfoText>{session.location}</InfoText>
            {!!session.xtraLocation && (
              <InfoTextExtra>{session.xtraLocation}</InfoTextExtra>
            )}
            {session.googleMapsUrl ? (
              <ShowOnMapWrapper
                onClick={() => window.open(session.googleMapsUrl, "_blank")}
              >
                <ShowOnMapText>{t("showOnMap")}</ShowOnMapText>
                <ChevronRightIcon />
              </ShowOnMapWrapper>
            ) : null}
          </TextContentWrapper>
        )}
        {!!session.durationMinutes && (
          <TextContentWrapper>
            <IconWrapper>
              <TimeIcon />
            </IconWrapper>
            <InfoText>
              {t("minutesDuration", { x: session.durationMinutes })}
            </InfoText>
          </TextContentWrapper>
        )}
        {!!session.notes && (
          <NoteSection>
            <IconWrapper onClick={() => setNoteOpen(!isNoteOpen)}>
              <ArrowInCircleIcon direction={!isNoteOpen ? "right" : "bottom"} />
            </IconWrapper>
            <NoteText isExpanded={isNoteOpen}>{session.notes}</NoteText>
          </NoteSection>
        )}
        {!!session.specialNotes &&
        isRealTrainer({ isFamilyMember, isTrainer }) ? (
          <div>
            <SpecialNoteLabel>{t("coachNotes")}</SpecialNoteLabel>
            <NoteSection>
              <IconWrapper
                onClick={() => setSpecialNoteOpen(!isSpecialNoteOpen)}
              >
                <ArrowInCircleIcon
                  direction={!isSpecialNoteOpen ? "right" : "bottom"}
                />
              </IconWrapper>
              <NoteText isExpanded={isSpecialNoteOpen}>
                {session?.specialNotes?.trim()}
              </NoteText>
            </NoteSection>
          </div>
        ) : null}
      </div>
    </InfoContainer>
  );
}
