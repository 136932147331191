import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const DuplicateIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#3C434C",
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C1.89543 7 1 7.89543 1 9V18C1 19.1046 1.89543 20 3 20H12C13.1046 20 14 19.1046 14 18V9C14 7.89543 13.1046 7 12 7H3ZM3 6C1.34315 6 0 7.34315 0 9V18C0 19.6569 1.34315 21 3 21H12C13.6569 21 15 19.6569 15 18V9C15 7.34315 13.6569 6 12 6H3Z"
        fill={tintColor}
      />
      <path
        d="M9 0C7.34326 0 6 1.34314 6 3H7C7 1.89545 7.89551 1 9 1V0Z"
        fill={tintColor}
      />
      <path d="M11 1V0H16V1H11Z" fill={tintColor} />
      <path d="M20 10V5H21V10H20Z" fill={tintColor} />
      <path d="M14 14H16V15H14V14Z" fill={tintColor} />
      <path d="M7 5V7H6V5H7Z" fill={tintColor} />
      <path
        d="M18 15C19.6567 15 21 13.6569 21 12H20C20 13.1046 19.1045 14 18 14V15Z"
        fill={tintColor}
      />
      <path
        d="M21 3C21 1.34314 19.6567 0 18 0V1C19.1045 1 20 1.89545 20 3H21Z"
        fill={tintColor}
      />
      <rect x="7" y="11" width="1" height="5" fill={tintColor} />
      <rect
        x="10"
        y="13"
        width="1"
        height="5"
        transform="rotate(90 10 13)"
        fill={tintColor}
      />
    </svg>
  )
);
