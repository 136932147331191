import React from "react";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { XpsUser } from "../../../graphql";
import Avatar from "../../Avatar";
import { CheckmarkDoneIcon } from "../../Icons";
import { TheWho } from "../../Teams/components/TeamGroups";

const Container = styled.div<{ isLast?: boolean }>`
  display: flex;
  min-height: 45px;
  align-items: center;
  padding-left: 15px;
  border-bottom: ${({ isLast }) =>
    isLast ? "none" : `1px solid ${COLOR_GREY_ACTIVE}`};
  cursor: pointer;
  &:hover {
    background: rgba(245, 246, 248, 0.5);
  }
  position: relative;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 0;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-right: 15px;
  flex-shrink: 0;
`;

const EmptyCircle = styled.div`
  width: 21px;
  height: 21px;
  background: ${COLOR_GRAY};
  border-radius: 50%;
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
`;

const SelectButton = styled.div`
  position: absolute;
  right: 15px;
`;

interface AddGroupOrAthleteToGroupRowProps {
  data: Pick<XpsUser, "name" | "profile" | "thumb"> & { groups: any };
  who: TheWho;
  isLast?: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export const AddGroupOrAthleteToGroupRow = ({
  isLast,
  data,
  who,
  isSelected,
  onClick,
}: AddGroupOrAthleteToGroupRowProps) => {
  return (
    <Container isLast={isLast} onClick={onClick}>
      <Content>
        <Avatar
          source={{ uri: data.thumb }}
          size="small"
          userName={data.name}
        />
        <Text style={{ width: 200, marginLeft: 10 }}>{data.name}</Text>
        {who === "athlete" ? (
          <Text style={{ width: 60 }}>{data.profile?.yearOfBirth}</Text>
        ) : null}
        <Text
          style={{
            maxWidth: `calc(100% - ${who === "athlete" ? 365 : 305}px)`,
            display: "block",
          }}
        >
          {data.groups.map((group) => group?.name)?.join(", ")}
        </Text>
      </Content>
      <SelectButton>
        {isSelected ? (
          <CheckmarkDoneIcon
            tintColor={COLOR_GREEN}
            strokeColor={COLOR_WHITE}
          />
        ) : (
          <EmptyCircle />
        )}
      </SelectButton>
    </Container>
  );
};
