import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Attendance } from "../../../../graphql";
import {
  AttendanceCheckmarkIcon,
  AttendanceCrossIcon,
  AttendanceQuestionMarkIcon,
} from "../../../Icons";

import { CoachAttendanceItem } from "./CoachAttendanceItem";

type CoachAttendanceContainerProps = {
  attendance: Attendance[];
  index?: number;
};

const CoachAttendanceRow = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
`;

function getAttendanceGroupedByStatus(
  attendance: Attendance[],
  attendanceStatus: string | string[]
) {
  let attended = [];

  if (!attendance) {
    return [];
  }

  if (Array.isArray(attendanceStatus)) {
    attendanceStatus.map((status) => {
      const attendanceArray = (attendance || []).filter(
        ({ attendance: attendanceStatus }) => attendanceStatus === status
      );

      if (attendanceArray && attendanceArray.length) {
        attended = [...attended, ...attendanceArray];
      }
    });

    return attended;
  }

  return attendance.filter(({ attendance }) => attendance === attendanceStatus);
}

export function CoachAttendanceContainer({
  attendance,
  index = 0,
}: CoachAttendanceContainerProps) {
  const { t } = useTranslation();

  return (
    <CoachAttendanceRow>
      <CoachAttendanceItem
        attendance={getAttendanceGroupedByStatus(attendance, "Attended")}
        tooltipTitle={t("attending")}
        icon={<AttendanceCheckmarkIcon />}
        index={index}
      />
      <CoachAttendanceItem
        attendance={getAttendanceGroupedByStatus(attendance, [
          "Sick",
          "Injured",
          "Other",
        ])}
        icon={<AttendanceCrossIcon />}
        tooltipTitle={t("unavailable")}
        right
        index={index}
      />
      <CoachAttendanceItem
        attendance={getAttendanceGroupedByStatus(attendance, ["Unknown", null])}
        icon={<AttendanceQuestionMarkIcon />}
        tooltipTitle={t("notAnswered")}
        right
        index={index}
      />
    </CoachAttendanceRow>
  );
}
