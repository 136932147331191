import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useUpdateDocumentMutation } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { DocumentTile } from "../DocumentTile";
import StyledText from "../StyledText";
import { Tab } from "../Tabs";

import { DocumentToShow } from "./CollectionList";
import { FolderTitle } from "./FolderTitle";
import { TabLabel } from "./model";

type Props = {
  documents: DocumentToShow[];
  sharerName: string | null;
  documentToShowIndex: number;
  onClick: (node: any) => void;
  tab: Tab;
  focusedAthleteId?: string;
  handleSharedDocumentsRefetch?: () => void;
};

const SharerWrapper = styled.div<{ isFirst: boolean }>`
  display: flex;
  margin-bottom: 5px;
  margin-top: ${({ isFirst }) => (isFirst ? 0 : "10px")};
`;

const TileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
`;

const TileWrapperComponent = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export function CollectionItem({
  documents,
  sharerName,
  documentToShowIndex,
  onClick,
  tab,
  focusedAthleteId,
  handleSharedDocumentsRefetch,
}: Props) {
  const { t } = useTranslation();
  const topModal = useTopModalContext();

  const user = useUserContext();

  const [updateDocumentMutation] = useUpdateDocumentMutation();

  const shouldShowFavoriteIcon = !user.isFamilyMember && !isRealTrainer(user);
  const shouldShowUnreadIcon = !user.isFamilyMember && !isRealTrainer(user);

  const onUnreadPress = async (node) => {
    await updateDocumentMutation({
      variables: {
        sessionId: user.sessionId,
        input: {
          id: node.id,
          needsAttention: false,
        },
      },
      optimisticResponse: {
        updateDocument: {
          ...node,
          needsAttention: false,
        },
      },
    });
    handleSharedDocumentsRefetch?.();
  };

  const handleOpenModal = (node) => {
    const idParts = node.id.split(":");

    const isWorkout = node.type === "WORKOUT_TEMPLATE";
    const isPractice = node.type === "PRACTICE_TEMPLATE_2020";
    const isQuestionnaire =
      node.type === "QUESTIONNAIRE_COLLECTION" ||
      node.type === "QUESTIONNAIRE_ITEM";

    const displayProgram = isWorkout || isPractice;

    const program = displayProgram
      ? {
          name: node.name,
          guid: idParts[idParts.length - 1],
        }
      : null;

    if (node.needsAttention) {
      onUnreadPress(node);
    }

    if (isQuestionnaire) {
      topModal.actions.openTopModal({
        modal: TopModalType.COLLECTION_QUESTIONNAIRE,
        title: node.name,
        params: {
          node,
          focusedAthleteId,
          hidePopularButton: !shouldShowFavoriteIcon,
        },
      });
    } else {
      topModal.actions.openTopModal({
        title: displayProgram
          ? t(isWorkout ? "workoutProgram" : "practiceProgram")
          : node.name,
        modal: displayProgram
          ? TopModalType.PROGRAM_ITEM_WORKOUT
          : TopModalType.DOCUMENT,
        params: {
          onSend: onClick
            ? () => {
                onClick?.(node);
              }
            : undefined,
          program,
          document: {
            ...node,
            mcUrl: node.modularContentUrl,
            itemName: node.name,
          },
          hidePopularButton: !shouldShowFavoriteIcon,
        },
      });
    }
  };

  return (
    <>
      {sharerName ? (
        <SharerWrapper isFirst={documentToShowIndex === 0}>
          <StyledText fontSize={14} bold>
            {sharerName.toUpperCase()}
          </StyledText>
        </SharerWrapper>
      ) : null}
      {documents?.map((document, index) => (
        <React.Fragment key={index}>
          {tab.id !== TabLabel.ALL ? (
            <FolderTitle
              id={document.documentId}
              collectionId={tab.id}
              folderName={document.folderName}
            />
          ) : null}
          <TileWrapperComponent
            condition={tab.id !== TabLabel.ALL}
            wrapper={(children) => <TileWrapper>{children}</TileWrapper>}
          >
            {document?.edges?.map(({ node }, index) => (
              <DocumentTile
                key={`${node.id}-${node.folderGuid}-${node.name}-${index}`}
                {...node}
                onClick={() => handleOpenModal(node)}
                shouldShowFavoriteIcon={shouldShowFavoriteIcon}
                shouldShowUnreadIcon={shouldShowUnreadIcon}
              />
            ))}
          </TileWrapperComponent>
        </React.Fragment>
      ))}
    </>
  );
}
