import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const FolderPlusIcon = memo((props: SvgIconProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 6V15C3.5 15.8284 4.17157 16.5 5 16.5H16C16.8284 16.5 17.5 15.8284 17.5 15V11V8C17.5 7.17157 16.8284 6.5 16 6.5H11.4142C11.149 6.5 10.8946 6.39464 10.7071 6.20711L9.58579 5.08579C9.21071 4.71071 8.70201 4.5 8.17157 4.5H5C4.17157 4.5 3.5 5.17157 3.5 6Z"
      stroke="#0073D9"
    />
    <path d="M10.5 9.5V13.5" stroke="#0073D9" strokeLinecap="square" />
    <path d="M12.5 11.5H8.5" stroke="#0073D9" strokeLinecap="square" />
  </svg>
));
