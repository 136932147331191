import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const EditPencilIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#3C434C",
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        d="M4 14L2.5 18.5L7 17L18.6515 5.34853C19.1201 4.8799 19.1201 4.1201 18.6515 3.65147L17.3485 2.34853C16.8799 1.8799 16.1201 1.8799 15.6515 2.34853L4 14Z"
        stroke={tintColor}
      />
      <path d="M14 4L17 7" stroke={tintColor} />
      <path d="M5 13L8 16" stroke={tintColor} />
    </svg>
  )
);
