import { useEffect, useMemo, useState } from "react";

import { UseTeamStatisticsQueryArgs } from "../../services/statistics/hooks";

import { useGetAthletesForTeam } from "./useGetAthletesForTeam";

export function useTeamsAthletes(
  participantTeams: string[]
): UseTeamStatisticsQueryArgs["teams"] {
  const [teams, setTeams] = useState<UseTeamStatisticsQueryArgs["teams"]>([]);

  const { getAthletesForTeam } = useGetAthletesForTeam();
  const joinedTeamsString = useMemo(
    () => (participantTeams ?? []).join(":"),
    [participantTeams]
  );

  useEffect(() => {
    setTeams([]);

    Promise.all(
      (participantTeams ?? []).map((teamGuid) => getAthletesForTeam(teamGuid))
    ).then((data) => {
      const teams = data.map(({ id, name, athletesIds }) => {
        return {
          id,
          name,
          participants: athletesIds,
        };
      });
      setTeams(teams);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [joinedTeamsString]);

  return teams;
}
