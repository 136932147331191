import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../../colors";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { useCanEditStatistics } from "../../contexts/accessLevels/useCanEditStatistics";
import { useAthleteProfileContext } from "../../contexts/athleteProfile";
import { useReportsContext } from "../../contexts/Reports";
import { XpsUser } from "../../graphql";
import { isRouteParamTrue } from "../../utils/route";
import { ButtonColor, GeneralButton } from "../Button/";
import GroupSelector, { GroupValue } from "../GroupSelector";
import { EmptyMagnifyingGlassIcon } from "../Icons";
import { CreateItemDropdown } from "../Report/CreateItemDropdown";
import { StylesOverrides } from "../StyledSelect";
import StyledText from "../StyledText";

const NoReportWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Label = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
`;

const SubLabel = styled(StyledText)`
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const stylesOverrides: StylesOverrides = {
  container: {
    borderWidth: 0,
  },
  control: {
    backgroundColor: "transparent",
    cursor: "pointer",
    minHeight: "auto",
    height: 24,
    paddingRight: 5,
    paddingLeft: 5,
    marginBottom: 2,
    marginTop: 5,
  },
  valueContainer: {
    borderBottom: 1,
    borderBottomStyle: "solid",
    borderColor: COLOR_BLUE,
  },
  singleValue: {
    color: COLOR_BLUE,
    fontSize: 20,
    fontWeight: 400,
    paddingRight: 5,
    paddingBottom: 2,
  },
  menu: {
    width: 260,
  },
  menuList: {
    maxHeight: 85,
  },
  indicatorsContainer: {
    borderBottom: 1,
    borderBottomStyle: "solid",
    borderColor: COLOR_BLUE,
  },
};

export function EmptyReport() {
  const { t } = useTranslation();
  const { configureTabs } = useAthleteProfileContext();
  const { selectedAccount, setSelectedAccount } = useAccessLevelsContext();
  const [{ selectedGroup }, { selectGroup }] = useReportsContext();

  const params = new URLSearchParams(window.location.search);
  const isMobile = isRouteParamTrue(params.get("isMobile"));

  const canEdit = useCanEditStatistics();

  const setSelectedOption = useCallback(
    (value: GroupValue) => {
      if (value) {
        selectGroup({ ...value, owner: value.owner || ({} as XpsUser) });

        // We have no account selector in mobile app so we take owner of group as selected account
        if (isMobile) {
          setSelectedAccount(value.owner.id);
        }
      }
    },
    [selectGroup, setSelectedAccount, isMobile]
  );

  return (
    <NoReportWrapper>
      <EmptyMagnifyingGlassIcon style={{ marginBottom: 28 }} />
      <TitleWrapper>
        <Label>
          <Trans
            t={t}
            i18nKey="noReportsIn"
            components={[
              <GroupSelector
                key={0}
                value={selectedGroup}
                onChange={setSelectedOption}
                accountId={isMobile ? undefined : selectedAccount?.id}
                disableSearchable
                unsetWidth
                hideLabel
                stylesOverrides={stylesOverrides}
              />,
            ]}
          />
        </Label>
      </TitleWrapper>
      {canEdit ? (
        <>
          <SubLabel>{t("tryExistingReport")}.</SubLabel>
          <ButtonWrapper>
            <GeneralButton
              colorVariant={ButtonColor.Gray}
              label={t("addExisting")}
              outlined
              onClick={configureTabs}
            />
            <CreateItemDropdown
              buttonLabel={t("createNew")}
              hideCreateWidget
              isFromMonitoring
            />
          </ButtonWrapper>
        </>
      ) : null}
    </NoReportWrapper>
  );
}
