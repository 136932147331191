import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { XpsUser } from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import Avatar from "../../Avatar";
import { BasicButton } from "../../Button";
import { IconButton } from "../../IconButton";
import { MessageIcon } from "../../Icons";

type TeamListProps = {
  data: XpsUser;
  groupId: string;
};

const Row = styled.tr`
  height: 45px;
  background-color: ${COLOR_WHITE};
  padding: 10px;
`;

const Item = styled.td`
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  text-align: left;
  &:first-child {
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-bottom-right-radius: 12px;
  }
  cursor: default;
`;

const ItemContent = styled(BasicButton)`
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

const Label = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-left: 11px;
  color: ${COLOR_TEXT_DARK};
`;

const Badge = styled.span`
  margin-left: 20px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_BLUE};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 8px;
`;

export function TeamItem({ data, groupId }: TeamListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useUserContext();
  const { id, name, thumb, type, profile, isExpired } = data || {};

  const isTrainer = type === "Trainer";

  const onMessageClick = () =>
    navigate({
      pathname: `/${ROUTING_CONFIG.messages}`,
      search: `?id=${id}`,
    });

  const onUserClick = () =>
    navigate({
      pathname: `/${ROUTING_CONFIG.teamsAthlete}`,
      search: createSearchParamsWithoutUndefinedValues({
        athleteId: id,
        teamGuids: groupId,
        userType: type,
        navigateFrom: `/${ROUTING_CONFIG.teams}?tab=members`,
      }),
    });

  const canMessage = user?.id !== id && !isExpired;

  return (
    <Row>
      <Item width="40%">
        <ItemContent onClick={onUserClick}>
          <Avatar userName={name} source={{ uri: thumb || "" }} size={25} />
          <Label>{name}</Label>
          {isTrainer && <Badge>{t("coach").toUpperCase()}</Badge>}
        </ItemContent>
      </Item>
      <Item width="20%">
        {profile?.phoneNumber ? <Label>{profile?.phoneNumber}</Label> : null}
      </Item>
      <Item width="20%">
        {profile?.emailAddress ? <Label>{profile?.emailAddress}</Label> : null}
      </Item>
      <Item width="20%">
        {canMessage ? (
          <IconWrapper>
            <IconButton icon={<MessageIcon />} onClick={onMessageClick} />
          </IconWrapper>
        ) : null}
      </Item>
    </Row>
  );
}
