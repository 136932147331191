import { getServerBasePath } from "../utils/getServerBasePath";

let baseUrl = getServerBasePath();

baseUrl =
  baseUrl + "/xpsweb/?json=is.sideline.apps.xps.server.web.json.messages.";

// QUESTIONNAIRE
export const JsonQuestionnaireDetailsQuery =
  baseUrl + "JsonQuestionnaireDetailsQuery";
export const JsonFindOrCreateQuestionnaire =
  baseUrl + "JsonFindOrCreateQuestionnaire";
export const JsonIntensityPreferencesQuery =
  baseUrl + "JsonIntensityPreferencesQuery";
export const JsonInformationUpdateRequest =
  baseUrl + "JsonInformationUpdateRequest";
export const JsonInfoOrTestUpdateRequest =
  baseUrl + "JsonInfoOrTestUpdateRequest";
export const JsonBodyChartUpdate = baseUrl + "JsonBodyChartUpdate";
export const JsonTrainingLoadUpdateRequest =
  baseUrl + "JsonTrainingLoadUpdateRequest";

// STATISTICS
export const JsonCollectionQueryForRelativeTrainer =
  baseUrl + "JsonCollectionQueryForRelativeTrainer";
export const JsonStatisticsForMeasurementsQuery =
  baseUrl + "JsonStatisticsForMeasurementsQuery";
export const JsonViewableGroupsQuery = baseUrl + "JsonViewableGroupsQuery";
export const JsonMyClientsQuery = baseUrl + "JsonMyClientsQuery";
export const JsonCombinedTestResultsQuery =
  baseUrl + "JsonCombinedTestResultsQuery";
export const JsonInformationQuery = baseUrl + "JsonInformationQuery";
export const JsonInformationQuery2022 = baseUrl + "JsonInformationQuery2022";
export const JsonInformationQuery2023 = baseUrl + "JsonInformationQuery2023";
export const JsonAttributeQuery = baseUrl + "JsonAttributeQuery";
export const JsonTrainingLoadTemplatesQuery =
  baseUrl + "JsonTrainingLoadTemplatesQuery";
export const JsonProgramAvailabilityQuery =
  baseUrl + "JsonProgramAvailabilityQuery";
export const JsonEventLogQuery2022 = baseUrl + "JsonEventLogQuery2022";
export const JsonAttendanceStatisticsQuery =
  baseUrl + "JsonAttendanceStatisticsQuery";
export const JsonSoccerQuery = baseUrl + "JsonSoccerQuery";
export const JsonXpsMagicInfoQuery = baseUrl + "JsonXpsMagicInfoQuery";

// SETTINGS
export const JsonAllPreferencesQuery = baseUrl + "JsonAllPreferencesQuery";

// COLLECTIONS
export const JsonSharingQuery = baseUrl + "JsonSharingQuery";

// SESSIONS
export const JsonLabelAndLocationQuery = `${baseUrl}JsonLabelAndLocationQuery`;

// PASSWORD
export const JsonPwdResetQuery = `${baseUrl}JsonPwdResetRequest`;
export const JsonCredChangeRequest2 = `${baseUrl}JsonCredChangeRequest2`;

// ATHLETE PROFILE
export const JsonProfileQuery = baseUrl + "JsonProfileQuery";
export const JsonTemporaryHeaderQuery = baseUrl + "JsonTemporaryHeaderQuery";
export const JsonTemporarySoccerQuery = baseUrl + "JsonTemporarySoccerQuery";
export const JsonTemporaryActivityLogQuery =
  baseUrl + "JsonTemporaryActivityLogQuery";
export const JsonTemporaryAttendanceLogQuery =
  baseUrl + "JsonPlayerAttendanceReportQuery2022";
export const JsonTemporaryPerformanceQuery =
  baseUrl + "JsonTemporaryPerformanceQuery";

export const JsonPlayerCardStructureQuery =
  baseUrl + "JsonPlayerCardStructureQuery";
export const JsonPlayerCardWidgetQuery = baseUrl + "JsonPlayerCardWidgetQuery";

// HACK
export const JsonSimpleAppInfoQuery = baseUrl + "JsonSimpleAppInfoQuery";

// PARENTS
export const JsonMyAthletesQuery = baseUrl + "JsonMyClientsQuery";
export const JsonProfileChangeRequest = baseUrl + "JsonProfileChangeRequest";
export const JsonCredChangeRequest = baseUrl + "JsonCredChangeRequest";
export const JsonSaveAvatar = `${baseUrl.split("?")[0]}?operation=saveavatar`;

// DOKOBIT LOGIN
export const JsonDokobitLoginStart = baseUrl + "JsonDokobitLoginStart";

export const JsonDokobitWebAuthorize = baseUrl + "JsonDokobitWebAuthorize";

export const JsonDokobitLoginRequest = baseUrl + "JsonDokobitLoginRequest";

// DOCUMENT
export const JsonProgramSimpleQuery = baseUrl + "JsonProgramSimpleQuery";

// TERMS
export const JsonTermsAcceptanceQuery = baseUrl + "JsonTermsAcceptanceQuery";
export const JsonTermsAcceptanceUpdate = baseUrl + "JsonTermsAcceptanceUpdate";

// INVITATIONS
export const JsonInvitationQuery = baseUrl + "JsonInvitationQuery";
export const JsonInvitationDecision = baseUrl + "JsonInvitationDecision";

// GROUPS
export const JsonGroupCheckUpdateRequest =
  baseUrl + "JsonGroupCheckUpdateRequest";

// CALENDAR
export const JsonWorkoutCopyRequest = baseUrl + "JsonWorkoutCopyRequest";
export const JsonProgramGenerationRequest =
  baseUrl + "JsonProgramGenerationRequest";
