import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import { useFolderNodeWithoutColorLazyQuery } from "../../graphql";
import { useHandleClickOutside } from "../../hooks";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { BasicButton } from "../Button";
import { ChevronRightIcon, FolderIcon, StarIcon } from "../Icons";
import { Loader } from "../Loader";
import { rgbToHex } from "../Modals/utils";
import StyledText from "../StyledText";
import { Tab } from "../Tabs";

import { FOLDER_SELECTOR_ELEMENT_ID } from "./FolderSelector";
import { getFolderColors, getFolderTypeFromFolderId } from "./utils";

const Wrapper = styled.div`
  display: flex;
  margin: 0 10px;
  flex-direction: column;
`;

const FoldersWrapper = styled.div`
  display: flex;
`;

const FolderList = styled.div`
  display: flex;
  flex-direction: column;
`;

const FolderRow = styled.div`
  display: flex;
  align-items: center;
`;

const FolderButton = styled(BasicButton)`
  align-items: center;
`;

const FolderIconWrapper = styled.div<{ hasSubfolders: boolean }>`
  margin-left: ${({ hasSubfolders }) => (hasSubfolders ? 0 : 15)}px;
  margin-right: 5px;
  display: flex;
`;

const FolderIndicator = styled(BasicButton)`
  padding-right: 6.5px;
`;

const FolderLabelWrapper = styled.div`
  display: flex;
`;

const FolderLabel = styled(StyledText)<{ isSelected: boolean; color: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-overflow: ${({ isSelected }) => (isSelected ? "hidden" : "ellipsis")};
  border-radius: ${({ isSelected }) => (isSelected ? "3px" : "0px")};
  color: ${({ isSelected, color }) =>
    isSelected ? COLOR_WHITE : color || COLOR_TEXT_DARK};
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR_BLUE : "transparent"};
  padding: 5px;
  word-wrap: break-word;
`;

type FolderPropsTypes = {
  id: string;
  name: string;
  color?: string;
  hasSubfolders: boolean;
};

type FolderProps = {
  folder: FolderPropsTypes;
  selectedFolder: string;
  collectionType: Tab;
  setFolder: Dispatch<SetStateAction<string>>;
};

export function Folder({
  folder,
  selectedFolder,
  collectionType,
  setFolder,
}: FolderProps) {
  const [fetchFolder, { data, loading }] = useFolderNodeWithoutColorLazyQuery();
  const [toggled, setToggled] = useState(false);
  const folderRef = useRef();
  const user = useUserContext();

  const handleClickOutside = () => {
    setFolder(null);
  };

  const isSelected = useMemo(() => {
    if (selectedFolder) return selectedFolder === folder.id;
    return false;
  }, [selectedFolder, folder?.id]);

  useHandleClickOutside(
    folderRef,
    handleClickOutside,
    isSelected,
    FOLDER_SELECTOR_ELEMENT_ID
  );

  const fillColor = folder?.color
    ? folder.color.includes(",")
      ? rgbToHex(folder.color)
      : folder.color
    : null;

  const onFolderClick = async () => {
    if (folder.hasSubfolders) {
      setToggled(!toggled);
      await fetchFolder({
        variables: {
          id: folder.id,
          type: getFolderTypeFromFolderId(folder.id),
          removeEmptyFolders: true,
        },
      });
    } else {
      setFolder(folder.id);
    }
  };

  const folderColor = useMemo(
    () =>
      isRealTrainer({
        isFamilyMember: user.isFamilyMember,
        isTrainer: user.isTrainer,
      })
        ? getFolderColors(collectionType.id)
        : null,
    [collectionType.id, user.isFamilyMember, user.isTrainer]
  );

  return (
    <Wrapper key={folder.id}>
      <FolderRow>
        {folder.hasSubfolders && (
          <FolderIndicator onClick={onFolderClick}>
            <ChevronRightIcon
              tintColor={COLOR_SECONDARY_GREY}
              direction={toggled ? "bottom" : "right"}
              style={{ width: 8, height: 8 }}
            />
          </FolderIndicator>
        )}
        <FolderButton
          ref={folderRef}
          onClick={() => {
            if (isSelected) setFolder(null);
            else {
              setFolder(folder.id);
            }
          }}
        >
          <FolderIconWrapper hasSubfolders={folder.hasSubfolders}>
            {folder.id === "favorites" ? (
              <StarIcon isActive />
            ) : (
              <FolderIcon
                color={folderColor?.color}
                border={folderColor?.border}
              />
            )}
          </FolderIconWrapper>
          <FolderLabelWrapper>
            <FolderLabel isSelected={isSelected} color={fillColor}>
              {folder.name}
            </FolderLabel>
          </FolderLabelWrapper>
        </FolderButton>
      </FolderRow>

      <>
        {toggled && (
          <FoldersWrapper>
            {loading && <Loader size="small" color={COLOR_BLUE} />}
            {!loading && data?.node && (
              <FolderList>
                {data.node.__typename === "Folder" &&
                  data.node.children &&
                  data.node.children.map((child) => (
                    <Folder
                      key={child.id}
                      selectedFolder={selectedFolder}
                      folder={child}
                      setFolder={setFolder}
                      collectionType={collectionType}
                    />
                  ))}
              </FolderList>
            )}
          </FoldersWrapper>
        )}
      </>
    </Wrapper>
  );
}
