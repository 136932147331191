import React, { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../colors";
import { useAgendaDataContext } from "../../contexts/agenda/AgendaDataProvider";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { useUserContext } from "../../contexts/User";
import { GetGroupTreeQuery } from "../../graphql";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { sortList } from "../../utils/sortList";
import { BasicButton } from "../Button";
import { Checkbox } from "../Checkbox";
import { ChevronRightIcon, FolderIcon } from "../Icons";
import StyledText from "../StyledText";

import { GROUP, SUB_GROUP } from "./model";
import { hasNameInChildren, normalizeString } from "./utils";

export type GroupFolderProps = {
  data: GetGroupTreeQuery["getGroupTree"][0] & {
    children?: GetGroupTreeQuery["getGroupTree"];
    subGroups?: {
      id: string;
      name: string;
    }[];
  };
  value: string;
  hasSearchValue?: boolean;
  leftOffset?: number;
  searchValue?: string;
};

const FolderWrapper = styled.div`
  display: flex;
  margin-left: 14.5px;
  margin-bottom: 5px;
  flex-direction: row;
  align-items: center;
`;

const FolderIndicatorButton = styled(BasicButton)`
  padding: 10px 7.5px 10px 0;
`;

const FolderButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FolderIconWrapper = styled.div`
  margin-right: 3px;
`;

const FolderLabel = styled(StyledText)<{
  selectedValue?: boolean;
  color?: string;
}>`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 5px;
  border-radius: 3px;
  background-color: ${({ selectedValue }) => selectedValue && COLOR_BLUE};
  color: ${({ selectedValue, color }) =>
    selectedValue ? COLOR_WHITE : `#${color}` || COLOR_TEXT_DARK};
`;

export function GroupFolder({
  data,
  searchValue,
  value,
  leftOffset = 0,
  hasSearchValue,
}: GroupFolderProps) {
  const { id, name, color } = data;
  const [isFolderOpen, setFolderOpen] = useState(true);
  const { focusedAgendaGroupId, selectedVisibleGroupIds } = useAgendaContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useUserContext();
  const { onVisibleGroupsFilterChange } = useAgendaDataContext();

  const onTextClick = (id: string) => {
    const newSearchParams: Record<string, string> = {
      ...getSearchParamsObject(searchParams),
      focusedGroupId: id,
    };

    if (newSearchParams.focusedAthleteId) {
      delete newSearchParams.focusedAthleteId;
    }

    if (focusedAgendaGroupId === id) {
      delete newSearchParams.focusedGroupId;
    }

    setSearchParams(newSearchParams);
  };

  const hasSubGroups = data?.children && data.children.length > 0;
  const isNotFolder = [GROUP, SUB_GROUP].includes(data?.__typename);

  const isInSearch = useMemo(() => {
    if (!data || !hasSearchValue || !searchValue) {
      return false;
    }
    const hasSomeChildWithValue = hasNameInChildren(data, searchValue);

    return (
      hasSomeChildWithValue ||
      normalizeString(data?.name).includes(normalizeString(searchValue))
    );
  }, [searchValue, data, hasSearchValue]);

  if (hasSearchValue && searchValue && !isInSearch) {
    return null;
  }

  const isFocused = !!(value?.includes(id) && focusedAgendaGroupId === id);

  return (
    <>
      {isNotFolder ? (
        <Checkbox
          text={name}
          key={id}
          checked={selectedVisibleGroupIds?.includes(id)}
          onCheckboxClick={() => onVisibleGroupsFilterChange([id])}
          style={{ marginLeft: leftOffset, marginBottom: 5 }}
          textStyle={color && !isFocused ? { color: `#${color}` } : undefined}
          buttonStyle={{ maxWidth: "100%" }}
          checkboxSize="small"
          isGrey={false}
          isFocused={isFocused}
          onTextClick={() => onTextClick(id)}
          showGroupIcon
        />
      ) : (
        <FolderWrapper style={{ marginLeft: leftOffset }}>
          {hasSubGroups && (
            <FolderIndicatorButton onClick={() => setFolderOpen(!isFolderOpen)}>
              <ChevronRightIcon
                tintColor={COLOR_SECONDARY_GREY}
                direction={isFolderOpen ? "bottom" : "right"}
                style={{ width: 8, height: 8 }}
              />
            </FolderIndicatorButton>
          )}
          <FolderButton>
            <FolderIconWrapper>
              <FolderIcon />
            </FolderIconWrapper>
            <FolderLabel
              numberOfLines={1}
              selectedValue={value === id}
              color={color}
            >
              {name}
            </FolderLabel>
          </FolderButton>
        </FolderWrapper>
      )}
      {isFolderOpen &&
        data?.children?.map((item, index) => (
          <GroupFolder
            key={`${item.id}-${index}`}
            data={item}
            value={value}
            leftOffset={leftOffset + 20}
            searchValue={searchValue}
            hasSearchValue={hasSearchValue}
          />
        ))}
      {sortList([...(data?.subGroups || [])], language).map((item) => {
        const isFocused = !!(
          value?.includes(item.id) && focusedAgendaGroupId === item.id
        );
        return (
          <Checkbox
            text={item.name}
            key={item.id}
            checked={selectedVisibleGroupIds?.includes(item.id)}
            onCheckboxClick={() => onVisibleGroupsFilterChange([item.id])}
            style={{ marginLeft: leftOffset + 20, marginBottom: 5 }}
            textStyle={
              "color" in item && !isFocused
                ? { color: `#${item.color}` }
                : undefined
            }
            buttonStyle={{ maxWidth: "100%" }}
            checkboxSize="small"
            isGrey={false}
            isFocused={isFocused}
            onTextClick={() => onTextClick(item.id)}
          />
        );
      })}
    </>
  );
}
