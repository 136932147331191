import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

import { DraggableModalHeader } from "../../components/Modals/DraggableModal/Header";
import { Tab } from "../../components/Tabs";
import { useAthleteProfileTabs } from "../../hooks";
import useAthleteReportTabs from "../../hooks/data/useAthleteReportTabs";
import { getSearchParamsObject } from "../../utils/getSearchParamsObject";
import { ReportContextProvider, useReportContext } from "../report";
import { useReportsContext } from "../Reports";
import { ModalType, useModalContext } from "../UI/Modal";

type AthleteProfileContextValue = {
  groupTabs: (Tab & { isShared: boolean; isVisible: boolean })[];
  activeTab: Tab | null;
  loading: boolean;
  selectTab(tab: Tab): void;
  configureTabs(): void;
  athleteReportTabOptions: (Tab & { isShared: boolean; isVisible: boolean })[];
};

const AthleteProfileContext = createContext<AthleteProfileContextValue>(null);

export function AthleteProfileContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldRefetchSharedInfo, setShouldRefetchSharedInfo] = useState(false);
  const activeTabId = searchParams.get("activeProfileTabId") ?? {};

  const [{ selectedGroup }] = useReportsContext();
  const modal = useModalContext();
  const { report } = useReportContext();

  const { tabsPreference, groupTabs, loading, refetchAthleteProfileTabs } =
    useAthleteProfileTabs();
  const { athleteReportTabOptions, refreshAthleteReportTabs } =
    useAthleteReportTabs(report?.tabs);

  const selectTab = (newTab?: Tab) => {
    setSearchParams({
      ...getSearchParamsObject(searchParams),
      activeProfileTabId: newTab?.id,
    });
  };

  const handleTabsRefresh = () => {
    refetchAthleteProfileTabs();
    // We need to handle this using state as proxy due to closure of the onClose prop for EDIT_ATHLETE_PROFILE_TABS
    // We need to reload data due to shared icon
    setShouldRefetchSharedInfo(true);
  };

  const configureTabs = () => {
    modal.actions.openModal({
      modal: ModalType.EDIT_ATHLETE_PROFILE_TABS,
      title: <DraggableModalHeader />,
      params: {
        preferences: tabsPreference,
        groupId: selectedGroup?.id,
        onClose: handleTabsRefresh,
      },
    });
  };

  useEffect(() => {
    if (shouldRefetchSharedInfo) {
      refreshAthleteReportTabs();
      setShouldRefetchSharedInfo(false);
    }
  }, [refreshAthleteReportTabs, shouldRefetchSharedInfo]);

  useEffect(() => {
    if (groupTabs.length) {
      selectTab(groupTabs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTabs, selectedGroup?.id]);

  useEffect(() => {
    let activeProfileTab = groupTabs[0] ?? null;

    if (groupTabs && activeTabId) {
      activeProfileTab = groupTabs.find((tab) => tab.id === activeTabId);
    }

    if (activeProfileTab) {
      selectTab(activeProfileTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupTabs, activeTabId]);

  const activeTab =
    groupTabs.find((tab) => tab.id === activeTabId) ??
    (groupTabs.length ? groupTabs[0] : null);

  return (
    <AthleteProfileContext.Provider
      value={{
        activeTab,
        groupTabs,
        loading,
        selectTab,
        configureTabs,
        athleteReportTabOptions,
      }}
    >
      <ReportContextProvider reportId={activeTab?.id}>
        {children}
      </ReportContextProvider>
    </AthleteProfileContext.Provider>
  );
}

export function useAthleteProfileContext() {
  return useContext(AthleteProfileContext);
}
