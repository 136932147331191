import React, { useState } from "react";

import styled from "styled-components";

import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import { LogoAcesNationIcon, LogoXpsIcon } from "../Icons";

import { COLORS, convertIdToVariant } from "./utils";

type ThumbnailProps = {
  source?: { uri?: string };
  id: string;
  src?: string;
  withoutDefaultDimensions?: boolean;
  withoutBorderRadius?: boolean;
};

const Wrapper = styled.div<{ withoutDefaultDimensions?: boolean }>`
  height: ${({ withoutDefaultDimensions = false }) =>
    withoutDefaultDimensions ? "100%" : "140px"};
  width: ${({ withoutDefaultDimensions = false }) =>
    withoutDefaultDimensions ? "auto" : "160px"};
`;

const XpsImageWrapper = styled.div<{
  colors: string[];
  withoutBorderRadius?: boolean;
}>`
  object-fit: cover;
  border-radius: ${({ withoutBorderRadius = false }) =>
    withoutBorderRadius ? "none" : "6px 6px 0 0"};
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: ${({ colors }) =>
    `linear-gradient(131.186deg, ${colors[0]} 0%, ${colors[1]} 100%)`}
}
`;

const StyledImage = styled.img`
  height: 140px;
  width: 160px;
  object-fit: cover;
`;

export function Thumbnail({
  id,
  src,
  withoutDefaultDimensions,
  withoutBorderRadius,
}: ThumbnailProps) {
  const colors = COLORS[convertIdToVariant(id)];
  const [showXPSLogo, setShowXPSLogo] = useState(false);

  return (
    <Wrapper withoutDefaultDimensions={withoutDefaultDimensions}>
      {src && !showXPSLogo ? (
        <StyledImage
          src={src}
          loading="lazy"
          onError={() => setShowXPSLogo(true)}
        />
      ) : (
        <XpsImageWrapper
          withoutBorderRadius={withoutBorderRadius}
          colors={colors}
        >
          {isAcesNationEnv() ? <LogoAcesNationIcon /> : <LogoXpsIcon />}
        </XpsImageWrapper>
      )}
    </Wrapper>
  );
}
