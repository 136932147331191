import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

import styled from "styled-components";

import { COLOR_WHITE } from "../../colors";
import { useMobileModalContext } from "../../contexts/mobileModal/MobileModalProvider";

interface MobileModalProps {
  children: ReactNode;
}

const ModalOverlay = styled.div`
  background-color: #00000080;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: ${COLOR_WHITE};
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 15vh;
  position: relative;
  z-index: 1001;
`;

export const MobileModal = ({ children }: MobileModalProps) => {
  const { visible, hideModal } = useMobileModalContext();

  if (!visible) {
    return null;
  }

  return createPortal(
    <ModalOverlay onClick={hideModal}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalOverlay>,
    document.getElementById("mobile-modal-root")
  );
};
