import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE } from "../../../colors";
import StyledText from "../../StyledText";

export const CloseTextButton = styled(StyledText)`
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
  color: #0073d9;
  cursor: pointer;
`;

export const ModalHeaderContainer = styled.div`
  padding: 15px;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6eb;
  border-bottom-style: solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 99;
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 85vh;
`;

export const HeaderTitle = styled(StyledText)`
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #3c434c;
`;

export const SpacingContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
`;

export const SelectRowContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 25px;
  align-items: center;
  padding: 10px 15px;

  ${(props) => {
    if (props.isSelected) {
      return `
        background-color: ${COLOR_BLUE};
      `;
    }
  }}
`;

export const SelectText = styled(StyledText)<{ isSelected?: boolean }>`
  margin-left: 12px;
  white-space: nowrap;

  ${(props) => {
    if (props.isSelected) {
      return `
        color: ${COLOR_WHITE};
      `;
    }
  }}
`;
