import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../../colors";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { useUserContext } from "../../../contexts/User";
import { UserProfile, XpsUser } from "../../../graphql";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { sortList } from "../../../utils/sortList";
import { EnvelopeRoundedIcon, MessageIcon } from "../../Icons";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";

import { ProfileItem } from "./ProfileItem";

export type FamilyMember = {
  id?: string;
  userID?: string;
  name: string;
  thumb?: string;
  profile?: UserProfile;
};

type Props = {
  isTrainer: boolean;
  isFamilyMember: boolean;
  currentAthlete: XpsUser;
  loading: boolean;
  familyMembers: FamilyMember[];
  onMessageClick: (id: string) => void;
};

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 10px;
`;

const SendGroupMessageWrapper = styled.div`
  cursor: pointer;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLOR_WHITE};
  border: 1px solid rgba(230, 232, 235, 0.35);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 12px;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export function ProfileList({
  isTrainer,
  isFamilyMember,
  currentAthlete,
  loading,
  familyMembers,
  onMessageClick,
}: Props) {
  const { t } = useTranslation();
  const title = isTrainer ? t("personalInformation") : t("familyMembers");
  const user = useUserContext();
  const modal = useTopModalContext();

  if (!isTrainer && loading) {
    return (
      <LoaderContainer>
        <Loader size="large" color={COLOR_BLUE} />
      </LoaderContainer>
    );
  }

  const shouldShowPhoneNumber = Boolean(currentAthlete?.profile?.phoneNumber);
  const shouldShowEmail =
    Boolean(currentAthlete?.profile?.emailAddress) && !isFamilyMember;
  const shouldShowFamilyMembers = familyMembers.length > 0;

  const shouldShowTitle = isTrainer
    ? shouldShowEmail || shouldShowPhoneNumber
    : shouldShowFamilyMembers;

  const familyMembersToSendGroupMessage = familyMembers?.filter(
    (familyMember) => familyMember.id !== user.id
  );

  const shouldShowSendGroupMessage =
    familyMembersToSendGroupMessage?.length > 0 && isRealTrainer(user);

  const handleSendGroupMessage = () => {
    modal.actions.openTopModal({
      modal: TopModalType.CREATE_CHAT_GROUP,
      title: t("createChatGroup"),
      params: {
        familyMembers: familyMembersToSendGroupMessage.map((fm) => ({
          ...fm,
          id: fm.userID,
        })),
        athletes: [currentAthlete],
      },
    });
  };

  return (
    <>
      <TitleWrapper>
        {shouldShowTitle ? <Title>{title.toUpperCase()}</Title> : null}
        {shouldShowSendGroupMessage ? (
          <SendGroupMessageWrapper onClick={handleSendGroupMessage}>
            <StyledText color={COLOR_BLUE} fontSize={12} fontWeight={500}>
              {t("sendGroupMessage").toUpperCase()}
            </StyledText>
          </SendGroupMessageWrapper>
        ) : null}
      </TitleWrapper>
      <InfoContainer>
        {isTrainer ? (
          <>
            {shouldShowPhoneNumber ? (
              <ProfileItem
                value={currentAthlete?.profile?.phoneNumber}
                header={t("phoneNumber").toUpperCase()}
              />
            ) : null}
            {shouldShowEmail ? (
              <ProfileItem
                value={currentAthlete?.profile?.emailAddress}
                header={t("email").toUpperCase()}
                icon={<EnvelopeRoundedIcon />}
                index={1}
              />
            ) : null}
          </>
        ) : (
          sortList(familyMembers, user.language)?.map(
            ({ id, userID, name, thumb, profile }, index) => {
              const currentUserId = user?.id;
              const canMessage =
                userID !== currentUserId || id !== currentUserId;
              return (
                <ProfileItem
                  key={id || userID}
                  value={name}
                  secondaryValue={
                    isFamilyMember && canMessage && profile?.phoneNumber
                  }
                  secondaryValueTitle={t("phoneNumber")}
                  index={index}
                  image={{ uri: thumb }}
                  icon={
                    isFamilyMember && canMessage ? (
                      <MessageIcon
                        cursor="pointer"
                        onClick={() => onMessageClick(id || userID)}
                      />
                    ) : null
                  }
                />
              );
            }
          )
        )}
      </InfoContainer>
    </>
  );
}
