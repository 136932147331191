import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const SearchIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    color = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9" cy="9" r="5.5" stroke={color} />
      <path d="M13 13L18 18" stroke={color} />
    </svg>
  )
);
