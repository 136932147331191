import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { GroupValue } from "../../components/GroupSelector";
import { getSelectedTeamIdKey } from "../../constants";
import {
  useGetTeamsQuery,
  usePutPreferenceMutation,
  GetGroupWithSubgroupsQuery,
  useGetGroupWithSubgroupsLazyQuery,
  GetTeamsQuery,
  useGetPreferenceLazyQuery,
} from "../../graphql";
import { ALL_ACCOUNTS_ID, useAccessLevelsContext } from "../accessLevels";
import { useUserContext } from "../User";

type TeamsGroup = GetTeamsQuery["getGroups"][0];
type GroupDetail = GetGroupWithSubgroupsQuery["getGroup"];

export type TeamsContextModel = {
  group: GroupValue;
  onSetGroup: (value: Pick<TeamsGroup, "id">) => void;
  refetch: () => void;
  removeAthleteFromGroup: (
    removeAthleteId: string,
    removeFromGroupId: string
  ) => void;
  removeTrainerFromGroup: (
    removeTrainerId: string,
    removeFromGroupId: string
  ) => void;
  myGroups: TeamsGroup[];
  loadingGroups: boolean;
  groupDetailLoading: boolean;
};

const TeamsContext = createContext<TeamsContextModel>(null);

export function TeamContextProvider({ children }: { children: ReactNode }) {
  const { sessionId, language, timezone } = useUserContext();
  const [group, setGroup] = useState<GroupDetail>();
  const { selectedAccount } = useAccessLevelsContext();

  const [getGroup, { loading: groupDetailLoading }] =
    useGetGroupWithSubgroupsLazyQuery();

  const [getPreference] = useGetPreferenceLazyQuery();

  const [setPreferences] = usePutPreferenceMutation();

  const getTheGroupsImInVariables = useMemo(
    () => ({
      sessionId,
      language,
      requireEditability: false,
      membershipFilter: 0,
      multiAccount: selectedAccount?.id === ALL_ACCOUNTS_ID,
      trainer:
        selectedAccount?.id === ALL_ACCOUNTS_ID
          ? undefined
          : selectedAccount?.id,
    }),
    [sessionId, language, selectedAccount]
  );

  const {
    data: { getGroups } = {},
    loading: loadingGroups,
    refetch,
  } = useGetTeamsQuery({
    variables: getTheGroupsImInVariables,
    fetchPolicy: "cache-and-network",
    skip: !sessionId,
  });

  useEffect(() => {
    const getSelectedTeam = async () => {
      try {
        const { data } = await getPreference({
          variables: {
            key: getSelectedTeamIdKey({
              accountId: selectedAccount?.id,
            }),
            sessionId,
          },
          fetchPolicy: "network-only",
        });

        const selectedTeamId = data?.getPreference;

        const selectedTeamToSet = getGroups.find(
          (theGroupsImIn) => theGroupsImIn.id === selectedTeamId
        );

        if (selectedTeamId && selectedTeamToSet) {
          const res = await getGroup({
            variables: {
              sessionId,
              language,
              id: selectedTeamToSet?.id,
              timezone,
            },
            fetchPolicy: "cache-and-network",
          });
          if (res?.data?.getGroup) {
            setGroup(res.data.getGroup);
            setPreferences({
              variables: {
                key: getSelectedTeamIdKey({
                  accountId: selectedAccount?.id,
                }),
                value: res?.data?.getGroup?.id,
                sessionId,
              },
            });
          }
        } else {
          const res = await getGroup({
            variables: {
              sessionId,
              language,
              id: getGroups[0].id,
              timezone,
            },
            fetchPolicy: "cache-and-network",
          });
          if (res?.data?.getGroup) {
            return setGroup(res.data.getGroup);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (getGroups?.length) {
      getSelectedTeam();
    }
  }, [
    getGroups,
    getPreference,
    selectedAccount?.id,
    sessionId,
    getGroup,
    setPreferences,
    language,
    timezone,
  ]);

  const onSetGroup = useCallback(
    async (value: TeamsGroup) => {
      const res = await getGroup({
        variables: {
          sessionId,
          language,
          id: value.id,
          timezone,
        },
        fetchPolicy: "network-only",
      });

      if (res?.data?.getGroup) {
        await setPreferences({
          variables: {
            key: getSelectedTeamIdKey({
              accountId: selectedAccount?.id,
            }),
            value: value.id,
            sessionId,
          },
        });
        setGroup(res.data.getGroup);
      }
    },
    [
      sessionId,
      language,
      getGroup,
      timezone,
      setPreferences,
      selectedAccount?.id,
    ]
  );

  const getGroupForEditAction = useCallback(
    (removeFromGroupId: string) => {
      const isRemovingFromSubgroup = group.id !== removeFromGroupId;

      const removedFromGroupOrSubGroup =
        group.id === removeFromGroupId
          ? group
          : group.subGroups?.find(
              (subgroup) => subgroup.id === removeFromGroupId
            );

      return { isRemovingFromSubgroup, removedFromGroupOrSubGroup };
    },
    [group]
  );

  // TODO: try to handle this using apollo cache
  const removeAthleteFromGroup = useCallback(
    (removeAthleteId: string, removeFromGroupId: string) => {
      const { removedFromGroupOrSubGroup, isRemovingFromSubgroup } =
        getGroupForEditAction(removeFromGroupId);

      if (
        "athleteAccess" in removedFromGroupOrSubGroup &&
        removedFromGroupOrSubGroup?.athleteAccess?.length &&
        removeAthleteId
      ) {
        const updatedAthleteAccess =
          removedFromGroupOrSubGroup.athleteAccess.filter(
            (requiredAthlete) => requiredAthlete.user.id !== removeAthleteId
          );

        const updatedRequiredAthletes =
          removedFromGroupOrSubGroup.requiredAthletes.filter(
            (requiredAthlete) => requiredAthlete.id !== removeAthleteId
          );

        let groupSubGroups = group?.subGroups;

        if (!isRemovingFromSubgroup) {
          groupSubGroups = group?.subGroups?.map((sg) => ({
            ...sg,
            athleteAccess: sg.athleteAccess.filter(
              (sgAthleteAccess) => sgAthleteAccess.user.id !== removeAthleteId
            ),
            requiredAthletes: sg.requiredAthletes.filter(
              (sgAthleteAccess) => sgAthleteAccess.id !== removeAthleteId
            ),
          }));
        }

        const modifiedGroup = isRemovingFromSubgroup
          ? {
              ...group,
              subGroups: group.subGroups?.map((subgroup) =>
                subgroup.id === removeFromGroupId
                  ? {
                      ...subgroup,
                      athleteAccess: updatedAthleteAccess,
                      requiredAthletes: updatedRequiredAthletes,
                    }
                  : subgroup
              ),
            }
          : {
              ...group,
              athleteAccess: updatedAthleteAccess,
              requiredAthletes: updatedRequiredAthletes,
              subGroups: groupSubGroups,
            };

        setGroup(modifiedGroup);
        refetch();
      }
    },
    [getGroupForEditAction, refetch, group]
  );

  // TODO: try to handle this using apollo cache
  const removeTrainerFromGroup = useCallback(
    (removeTrainerId: string, removeFromGroupId: string) => {
      const { removedFromGroupOrSubGroup, isRemovingFromSubgroup } =
        getGroupForEditAction(removeFromGroupId);

      if (
        "trainerAccess" in removedFromGroupOrSubGroup &&
        removedFromGroupOrSubGroup?.trainerAccess?.length &&
        removeTrainerId
      ) {
        const updatedTrainers = removedFromGroupOrSubGroup.trainerAccess.filter(
          (requiredTrainer) => requiredTrainer.user.id !== removeTrainerId
        );

        let groupSubGroups = group?.subGroups;

        if (!isRemovingFromSubgroup) {
          groupSubGroups = group?.subGroups?.map((sg) => ({
            ...sg,
            trainerAccess: sg.trainerAccess.filter(
              (sgAthleteAccess) => sgAthleteAccess.user.id !== removeTrainerId
            ),
          }));
        }

        const modifiedGroup = isRemovingFromSubgroup
          ? {
              ...group,
              subGroups: group.subGroups?.map((subgroup) =>
                subgroup.id === removeFromGroupId
                  ? { ...subgroup, trainerAccess: updatedTrainers }
                  : subgroup
              ),
            }
          : {
              ...group,
              trainerAccess: updatedTrainers,
              subGroups: groupSubGroups,
            };

        setGroup(modifiedGroup);
        refetch();
      }
    },
    [getGroupForEditAction, refetch, group]
  );

  const values: TeamsContextModel = useMemo(
    () => ({
      group,
      onSetGroup,
      refetch,
      removeAthleteFromGroup,
      removeTrainerFromGroup,
      groupDetailLoading,
      myGroups: getGroups,
      loadingGroups: loadingGroups && !getGroups,
    }),
    [
      getGroups,
      group,
      refetch,
      removeAthleteFromGroup,
      removeTrainerFromGroup,
      groupDetailLoading,
      loadingGroups,
      onSetGroup,
    ]
  );

  return (
    <TeamsContext.Provider value={values}>{children}</TeamsContext.Provider>
  );
}

export const useTeamsContext = () => useContext(TeamsContext);
