import {
  useSearchParams,
  useNavigate,
  useParams,
  useLocation,
  matchPath,
} from "react-router-dom";

import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../utils/createSearchParamsWithoutUndefinedValues";
import { getSearchParamsObject } from "../utils/getSearchParamsObject";
import { isRouteParamTrue } from "../utils/route";

export type WidgetEditorScreens =
  | "Report"
  | "GroupReport"
  | "ChartEditor"
  | "CombinedTestEditor"
  | "AthleteTableEditor"
  | "KeyValuesEditor"
  | "GroupTableEditor"
  | "ActivityLogEditor"
  | "AttendanceLogEditor"
  | "InformationLogEditor"
  | "AcuteChronicEditor"
  | "AthleteTablePeriodizationEditorScreen"
  | "GroupTablePeriodizationEditorScreen"
  | "BodyChartEditor";

export function useNavigateToPreviousPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reportId, widgetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToReport = (isGroup: boolean) => {
    const goToReportId = searchParams.get("parentReportId") || reportId;

    navigate({
      pathname: isGroup
        ? `/reports/groupReport/${goToReportId}`
        : `/reports/report/${goToReportId}`,
      search: createSearchParamsWithoutUndefinedValues({
        isEditMode: "true",
        layoutIndex: searchParams.get("layoutIndex") || undefined,
        activeTabId: searchParams.get("activeTabId") || undefined,
        activeProfileTabId: searchParams.get("activeProfileTabId") || undefined,
        fromMonitoring: searchParams.get("fromMonitoring") || undefined,
      }),
    });
  };

  const navigateToAthleteProfile = () => {
    navigate({
      pathname: `/${ROUTING_CONFIG.AthleteProfile}`,
      search: createSearchParamsWithoutUndefinedValues({
        layoutIndex: searchParams.get("layoutIndex") || undefined,
        activeTabId: searchParams.get("activeTabId") || undefined,
        activeProfileTabId: searchParams.get("activeProfileTabId") || undefined,
        fromMonitoring: searchParams.get("fromMonitoring") || undefined,
        isEditMode: "true",
      }),
    });
  };

  /**
   * Covered route stacks
   *
   * 1. AP(GR) -> eGR -> WE
   * 2. AP(AR) -> WE
   * 3. RL -> GR -> eGR -> WE
   * 4. RL -> AR -> eAR -> WE
   * 5. RL -> WE
   *
   *  AP - AthleteProfile screen
   *  AR - AthleteReport screen
   * eAR - AR in edit mode
   *  GR - GroupReport screen
   * eGR - GR in edit mode
   *  WE - WidgetEditor screen (chart, table, etc.)
   *  RL - ReportsList screen
   */
  const navigateFromWidgetScreen = () => {
    const hasWidgetId = Boolean(widgetId);
    const editReportWidget = isRouteParamTrue(
      searchParams.get("editReportWidget")
    );
    const isWidgetTemplate = isRouteParamTrue(
      searchParams.get("isWidgetTemplate")
    );

    const isEditingWidgetTemplate =
      (hasWidgetId && editReportWidget) || isWidgetTemplate;

    if (isEditingWidgetTemplate && !reportId) {
      navigate(`/${ROUTING_CONFIG.ListReports}`);

      return;
    }

    const isGroupWidget =
      Boolean(
        matchPath(
          `${ROUTING_CONFIG.GroupTableEditor}/:widgetId`,
          location.pathname
        )
      ) ||
      Boolean(matchPath(ROUTING_CONFIG.GroupTableEditor, location.pathname)) ||
      isRouteParamTrue(searchParams.get("isGroupWidget"));

    navigateToReport(isGroupWidget);
  };

  /**
   * Covered route stacks
   *
   * 1. AP(GR) -> eGR
   * 2. RL -> GR -> eGR
   * 3. RL -> AR -> eAR
   *
   *  AP - AthleteProfile screen
   *  AR - AthleteReport screen
   * eAR - AR in edit mode
   *  GR - GroupReport screen
   * eGR - GR in edit mode
   *  RL - ReportsList screen
   */
  const navigateFromReportScreen = () => {
    const isEditMode = isRouteParamTrue(searchParams.get("isEditMode"));
    const activeProfileTabId =
      searchParams.get("activeProfileTabId") || undefined;

    if (isEditMode && activeProfileTabId) {
      navigateToAthleteProfile();

      return;
    }

    if (isEditMode) {
      setSearchParams({
        ...getSearchParamsObject(searchParams),
        isEditMode: undefined,
      });

      return;
    }

    navigate(`/${ROUTING_CONFIG.ListReports}`);
  };

  return () => {
    if (
      [
        ROUTING_CONFIG.ChartEditor,
        ROUTING_CONFIG.CombinedTestEditor,
        ROUTING_CONFIG.AthleteTableEditor,
        ROUTING_CONFIG.KeyValuesEditor,
        ROUTING_CONFIG.GroupTableEditor,
        ROUTING_CONFIG.ActivityLogEditor,
        ROUTING_CONFIG.AttendanceLogEditor,
        ROUTING_CONFIG.InformationLogEditor,
        ROUTING_CONFIG.AcuteChronicEditor,
        ROUTING_CONFIG.AthleteTablePeriodizationEditorScreen,
        ROUTING_CONFIG.GroupTablePeriodizationEditorScreen,
        ROUTING_CONFIG.BodyChartEditor,
      ].some(
        (path) =>
          matchPath(path, location.pathname) ||
          matchPath(`${path}/:widgetId`, location.pathname)
      )
    ) {
      return navigateFromWidgetScreen();
    }

    if (
      [ROUTING_CONFIG.Report, ROUTING_CONFIG.GroupReport].some((path) =>
        matchPath(path, location.pathname)
      )
    ) {
      return navigateFromReportScreen();
    }

    return navigate(`/${ROUTING_CONFIG.ListReports}`);
  };
}
