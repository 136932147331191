import React from "react";

import styled from "styled-components";

import StyledText from "./StyledText";

const Title = styled(StyledText)`
  height: 35px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export function SectionTitle({ title }: { title: string }) {
  return <Title>{title}</Title>;
}
