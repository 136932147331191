import React from "react";

import styled from "styled-components";

import { Loader } from "../../../components/Loader";
import { useGetBlogArticles } from "../../../hooks/useGetBlogArticles";

import { BlogItem } from "./BlogItem";

export const BlogWrapper = styled.div`
  min-height: 300px;
  height: 100%;
  width: 100%;
  border: none;
  overflow-y: scroll;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export const BlogContainer = () => {
  const { data, isLoading } = useGetBlogArticles();

  return (
    <BlogWrapper>
      {isLoading ? (
        <LoadingWrapper>
          <Loader />
        </LoadingWrapper>
      ) : (
        data?.data?.posts.map((post, index) => (
          <BlogItem blogPost={post} key={index} />
        ))
      )}
    </BlogWrapper>
  );
};
