import React, { useEffect, useMemo, useState } from "react";

import styled from "styled-components";

import { useReportsContext } from "../contexts/Reports";
import { AggregateFunction } from "../graphql";
import { IWidgetResizeProps } from "../hooks";
import { useTableSortingPreference } from "../hooks/table/useTableSortingPreference";
import { getValidUnitNameFromReportWidgetCell } from "../utils/statistics";

import { useReportPeriod } from "./Report/hooks/useReportPeriod";
import { WidgetLoader } from "./Report/WidgetLoader";
import {
  AthleteTableWidgetModel,
  AthleteTableWidgetRow,
} from "./ReportCreateTable/models";
import { useAthleteStatsData } from "./ReportCreateTable/useAthleteStatsData";
import { Table } from "./ReportTableWidget/AthleteTable/Table";

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

interface ITableWidgetProps extends IWidgetResizeProps {
  widget: AthleteTableWidgetModel;
  readOnly?: boolean;
}

export const TableWidget = ({ widget, ...rest }: ITableWidgetProps) => {
  const [reportContextState] = useReportsContext();
  const [sortingOverride, setSortingOverride] = useState<{
    sortBy: AggregateFunction;
    sortingOrder: number;
  }>();

  const {
    saveTableSortingPreference,
    getTableSortingPreference,
    loadingTablePreference,
  } = useTableSortingPreference<AggregateFunction>();

  const rows = useMemo<AthleteTableWidgetRow[]>(
    () =>
      widget.rowHeaders.map((row) => ({
        attributeTemplateId:
          "attributeTemplate" in row ? row.attributeTemplate?.id : "",
        attributeTemplateName:
          "attributeTemplate" in row ? row.attributeTemplate?.name : "",
        attributeTemplateUnitName: getValidUnitNameFromReportWidgetCell(row),
        collectionId: row.collectionId,
        measurementTemplateId: row.templateId,
        measurementTemplateName: row.name,
      })),
    [widget.rowHeaders]
  );

  const { fromDate, toDate } = useReportPeriod(widget.period);

  const { rows: tableRows, isLoading } = useAthleteStatsData({
    rows,
    focusedAthlete: reportContextState.selectedAthlete,
    aggregation: widget.columns,
    fromDate,
    toDate,
    performerSelectionMode: widget.performerSelectionMode,
    athlete: widget.athlete,
  });

  useEffect(() => {
    (async () => {
      const preference = await getTableSortingPreference(widget.id);
      setSortingOverride(preference);
    })();
  }, [widget.id, getTableSortingPreference]);

  const onChangeSort = async (
    sortBy: AggregateFunction,
    sortingOrder: number
  ) => {
    const { id: tableId } = widget;

    await saveTableSortingPreference(tableId, { sortBy, sortingOrder });
  };

  if (!widget.rowHeaders) {
    return null;
  }

  if (isLoading || loadingTablePreference) {
    return <WidgetLoader />;
  }

  return (
    <TableContainer>
      <Table
        id={widget.id}
        title={widget.name}
        colNames={widget.columns}
        rows={tableRows}
        rules={widget.rules ?? []}
        alternatingRow={widget.alternatingValues}
        alternatingColumns={widget.alternatingColumns}
        rowDividers={widget.rowDividers}
        columnDividers={widget.columnDividers}
        outsideBorderDividers={widget.outsideBorderDividers}
        showValuesAsText={widget.showValuesAsText}
        widgetBorders={widget.widgetBorders}
        sortBy={sortingOverride?.sortBy ?? widget.sortBy}
        sortingOrder={sortingOverride?.sortingOrder ?? widget.sortingOrder}
        onChangeSort={onChangeSort}
        {...rest}
      />
    </TableContainer>
  );
};
