import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
} from "../../colors";
import { useAgendaDataContext } from "../../contexts/agenda/AgendaDataProvider";
import { useUserContext } from "../../contexts/User";
import {
  AttendanceInput,
  usePracticeMembersQuery,
  useUpdateAttendance2Mutation,
} from "../../graphql";
import { useAttendanceData } from "../../hooks/data/useAttendanceData";
import { AttendanceStatus } from "../../models/attendance";
import { BasicButton } from "../Button";
import {
  CheckmarkAwayIcon,
  CheckmarkDoneIcon,
  CheckmarkNotAnsweredIcon,
} from "../Icons";
import { Loader } from "../Loader";
import Tooltip from "../Tooltip";

import { AttendanceItem } from "./components/AttendanceItem";
import { AttendanceTableHeader } from "./components/AttendanceTableHeader";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
  color: ${COLOR_WHITE};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const TitleValue = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${COLOR_WHITE};
`;

const AthleteName = styled.div`
  color: ${COLOR_WHITE};
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

const TableWrapper = styled.div`
  padding: 0 10px;
`;

const Table = styled.table`
  background-color: ${COLOR_WHITE};
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 12px;
  margin-bottom: 15px;
  border: 1px solid rgba(60, 67, 76, 0.04);
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  width: 100%;
`;

const Button = styled.div`
  color: ${COLOR_BLUE};
  font-size: 12px;
  font-weight: 700;
  margin-right: 35px;
`;

const AttendanceHeader = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ButtonsWrapper = styled.div`
  flex-direction: row;
  display: flex;
  margin-left: 25px;
`;

const NumberText = styled.div`
  color: ${COLOR_TEXT_DARK};
  margin-right: 4px;
  margin-left: 5px;
  font-weight: 600;
`;

const AthletesText = styled.div`
  color: ${COLOR_MEDIUM_GRAY};
  font-weight: 500;
  font-size: 14px;
`;

const Badge = styled.div`
  border-radius: 20px;
  background-color: ${COLOR_TEXT_TABLE_LABEL};
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_WHITE};
  height: 21px;
  width: 35px;
  white-space: nowrap;
  margin: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AttendanceRowItem = styled.div<{ marginRight?: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : 0)}px;
`;

export const SessionAttendance = ({ sessionGuid }) => {
  const { t } = useTranslation();
  const { sessionId, timezone, language } = useUserContext();

  const [updateAttendanceMutation] = useUpdateAttendance2Mutation();
  const { refetchAgendaHandler } = useAgendaDataContext();

  const {
    refetch: refetchPracticeMembers,
    loading,
    data,
  } = usePracticeMembersQuery({
    variables: {
      id: sessionGuid,
      sessionId,
      timezone,
      language,
    },
    // Due to infinite query loading and cache mismatch between data loaded using practice members and practice query - needs fix in the future
    fetchPolicy: "no-cache",
  });

  const handleRefetch = async () => {
    await Promise.all([refetchPracticeMembers(), refetchAgendaHandler()]);
  };

  const {
    athletes,
    attending,
    away,
    notResponded,
    attendingAthletes,
    awayAthletes,
    notRespondedAthletes,
  } = useAttendanceData(data);

  const markAllAsAttending = async () => {
    try {
      const inputs: AttendanceInput[] = [];
      athletes?.map(({ attendance, athleteGuid }) => {
        if (attendance === AttendanceStatus.UNKNOWN) {
          inputs.push({
            practiceGuid: sessionGuid,
            athleteGuid,
            attendance: AttendanceStatus.ATTENDED,
          });
        }
      });
      if (inputs.length) {
        await updateAttendanceMutation({
          variables: {
            sessionId,
            timezone,
            inputs,
          },
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      await handleRefetch();
    }
  };

  const clearAttendanceForAll = async () => {
    try {
      const inputs: AttendanceInput[] = [];
      athletes?.map(({ athleteGuid }) => {
        inputs.push({
          practiceGuid: sessionGuid,
          athleteGuid,
          attendance: AttendanceStatus.UNKNOWN,
          comment: "",
        });
      });
      await updateAttendanceMutation({
        variables: {
          sessionId,
          timezone,
          inputs,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      await handleRefetch();
    }
  };

  const buttons = useMemo(
    () => [
      {
        label: t("attending"),
        numberText: attending,
        Icon: CheckmarkDoneIcon,
        tooltipAthletes: attendingAthletes,
      },
      {
        label: t("away"),
        numberText: away,
        Icon: CheckmarkAwayIcon,
        tooltipAthletes: awayAthletes,
      },
      {
        label: t("notResponded"),
        numberText: notResponded,
        Icon: CheckmarkNotAnsweredIcon,
        tooltipAthletes: notRespondedAthletes,
      },
    ],
    [
      attending,
      away,
      notResponded,
      t,
      attendingAthletes,
      awayAthletes,
      notRespondedAthletes,
    ]
  );

  if (!athletes.length && loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <AttendanceHeader>
        <ButtonsWrapper>
          {athletes?.length ? (
            <>
              <BasicButton onClick={markAllAsAttending}>
                <Button>{t("selectAll").toUpperCase()}</Button>
              </BasicButton>
              <BasicButton onClick={clearAttendanceForAll}>
                <Button>{t("deselectAll").toUpperCase()}</Button>
              </BasicButton>
            </>
          ) : null}
        </ButtonsWrapper>
        <AttendanceRowItem
          marginRight={20}
          style={{ zIndex: 9, position: "relative" }}
        >
          <AttendanceRowItem marginRight={50}>
            <AthletesText>{t("athletes")}</AthletesText>
            <Badge>{athletes.length}</Badge>
          </AttendanceRowItem>
          {buttons.map((button, index, array) => (
            <Tooltip
              title={() => (
                <TitleContainer>
                  <Title>{button.label}</Title>
                  <TitleValue>{button.numberText}</TitleValue>
                </TitleContainer>
              )}
              component={() => (
                <AttendanceRowItem
                  key={index}
                  marginRight={index !== array.length - 1 ? 25 : undefined}
                >
                  <button.Icon />
                  <NumberText>{button.numberText}</NumberText>
                  <AthletesText>{button.label}</AthletesText>
                </AttendanceRowItem>
              )}
              right
              style={{
                zIndex: 9,
              }}
              containerStyle={{
                minWidth: 200,
                zIndex: 9,
              }}
            >
              {button.tooltipAthletes.length
                ? button.tooltipAthletes.map((attend) => (
                    <AthleteName
                      key={`tooltip-agenda-attendance-${attend._id}`}
                    >
                      {attend.athleteName}
                    </AthleteName>
                  ))
                : null}
            </Tooltip>
          ))}
        </AttendanceRowItem>
      </AttendanceHeader>
      {athletes?.length ? (
        <TableWrapper>
          <Table>
            <thead>
              <AttendanceTableHeader />
            </thead>
            <tbody>
              {athletes.map((item) => (
                <AttendanceItem
                  key={item.id}
                  sessionGuid={sessionGuid}
                  data={{
                    athleteComment: item.athleteComment,
                    athleteGuid: item.athleteGuid,
                    athleteName: item.athleteName,
                    attendance: item.attendance,
                    comment: item.comment,
                    id: item.id,
                  }}
                  practice={data?.practice}
                  refetch={handleRefetch}
                />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      ) : null}
    </>
  );
};
