import * as api from "./api";
import * as endpoints from "./endpoints";

export enum SessionType {
  GAME = "Game",
  EVENT = "Event",
  WORKOUT = "Workout",
  PRACTICE = "Practice",
}

type PracticeLocations2021 = {
  pt: string;
};

export type JsonLabelAndLocationQueryResponse = Partial<{
  _practiceLocations: string[];
  _practiceLocations2021: PracticeLocations2021[];
  _practiceLabels: string[];
}>;

export type JsonLabelAndLocationQueryModifiedResponse =
  | string[]
  | PracticeLocations2021[];

export type FetchJsonLabelAndLocationArgs = {
  session: api.Session;
  isLabel: boolean;
  sessionType: SessionType;
};

export async function fetchLabelAndLocation({
  session,
  isLabel,
  sessionType,
}: FetchJsonLabelAndLocationArgs) {
  const propName = `_${sessionType.toLocaleLowerCase()}${
    isLabel ? "Labels" : "Locations"
  }`;

  const params = {
    [propName]: true,
  };

  const response = await api.post<JsonLabelAndLocationQueryResponse>(
    endpoints.JsonLabelAndLocationQuery,
    params,
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return isLabel
    ? response?.data?.[propName]
    : response?.data?.[propName + "2021"];
}
