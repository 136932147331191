import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";

import styled from "styled-components";

import { COLOR_GRAY } from "../colors";

const ScreenWrapper = styled.div`
  height: 100vh;
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_GRAY};
  overflow: scroll;
  overflow-x: auto;
  position: relative;
`;

const ScreenContainer = ({
  children,
  style,
  scrollPersist,
  onScrollPersist,
  scrollToValue,
}: PropsWithChildren<{
  style?: React.CSSProperties;
  scrollPersist?: boolean;
  onScrollPersist?: (newScrollPosition: number) => void;
  scrollToValue?: number;
}>) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    onScrollPersist(scrollRef?.current?.scrollTop);
  }, [onScrollPersist]);

  useEffect(() => {
    if (scrollPersist) {
      const instance = scrollRef.current;
      instance.addEventListener("scroll", onScroll, true);

      return () => {
        instance.removeEventListener("scroll", onScroll);
      };
    }
  }, [scrollPersist, onScroll]);

  useEffect(() => {
    if (scrollPersist && scrollToValue) {
      scrollRef.current.scrollTo({ top: scrollToValue, behavior: "smooth" });
    }
  }, [scrollPersist, scrollToValue]);

  return (
    <ScreenWrapper style={style} ref={scrollRef}>
      {children}
    </ScreenWrapper>
  );
};

export default ScreenContainer;
