import { useTranslation } from "react-i18next";

import { WidgetMode } from "../components/Report/WidgetWithData";
import { WidgetType } from "../components/ReportCreateTable/models";

export const useWidgetTypes = () => {
  const { t } = useTranslation();

  return (mode: WidgetMode) => [
    { label: t("chart"), type: WidgetType.CHART },
    { label: t("table"), type: WidgetType.TABLE },
    { label: t("combinedTest"), type: WidgetType.COMBINED_TEST },
    { label: t("keyValues"), type: WidgetType.KEY_VALUES },
    {
      label: t("tablePeriodization"),
      type:
        mode === WidgetMode.ATHLETE
          ? WidgetType.ATHLETE_TABLE_PERIODIZATION
          : WidgetType.GROUP_TABLE_PERIODIZATION,
    },
    ...(mode === WidgetMode.ATHLETE
      ? [
          { label: t("attendanceLog"), type: WidgetType.ATTENDANCE_LOG },
          { label: t("informationLog"), type: WidgetType.INFORMATION_LOG },
          { label: t("activityLog"), type: WidgetType.ACTIVITY_LOG },
          { label: t("acuteChronicChart"), type: WidgetType.ACUTE_CHRONIC },
          { label: t("bodyChart"), type: WidgetType.BODY_CHART },
        ]
      : []),
  ];
};
