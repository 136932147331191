import React from "react";
import { useTranslation } from "react-i18next";

import { FieldArray } from "formik";
import styled from "styled-components";

import { COLOR_GREEN } from "../../../colors";
import { GeneralButton } from "../../Button";
import { PlusIcon } from "../../Icons";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { Comparator, ConditionStyling, IRule } from "../models";

import { ConditionsData } from "./model";
import { RuleRow } from "./RuleRow";

type RulesSectionProps = {
  conditionsData: ConditionsData;
  fieldArrayName: string;
  onChange(field: string, value: IRule): void;
  rules: IRule[];
};

const AddButtonWrapper = styled.div`
  font-size: 12px;
  width: fit-content;
  padding-top: 10px;
`;

export const RulesSection = ({
  conditionsData,
  fieldArrayName,
  onChange,
  rules = [],
}: RulesSectionProps) => {
  const { t } = useTranslation();

  const getNewRule = () => ({
    measurementTemplateId: "",
    attributeTemplateId: "",
    aggregation: "",
    comparator: {
      name: Comparator.EQUAL,
      value: "",
    },
    conditionStyle: {
      name: ConditionStyling.COLOR,
      value: COLOR_GREEN,
    },
  });

  return (
    <Section>
      <SectionTitle title={t("rules")} />

      <FieldArray name={fieldArrayName}>
        {({ push, remove }) => (
          <>
            {rules.map((rule, index) => {
              const ruleRowValue = {
                templateId: `${rule.measurementTemplateId}:${rule.attributeTemplateId}`,
                measurementTemplateId: rule.measurementTemplateId,
                attributeTemplateId: rule.attributeTemplateId,
                aggregation: rule.aggregation,
                comparator: rule.comparator,
                conditionStyle: rule.conditionStyle,
              };

              return (
                <RuleRow
                  key={`${rule.attributeTemplateId}-${rule.measurementTemplateId}-${index}`}
                  index={index}
                  conditionsData={conditionsData}
                  value={ruleRowValue}
                  onChange={({
                    templateId,
                    aggregation,
                    comparator,
                    conditionStyle,
                  }) => {
                    const [measurementTemplateId, attributeTemplateId] =
                      templateId.split(":");

                    onChange(`${fieldArrayName}[${index}]`, {
                      measurementTemplateId,
                      attributeTemplateId,
                      aggregation,
                      comparator,
                      conditionStyle,
                    });
                  }}
                  onClone={() => push(ruleRowValue)}
                  onRemove={() => remove(index)}
                />
              );
            })}
            <AddButtonWrapper>
              <GeneralButton
                label={t("addRule")}
                icon={<PlusIcon isActive />}
                onClick={() => push(getNewRule())}
              />
            </AddButtonWrapper>
          </>
        )}
      </FieldArray>
    </Section>
  );
};
