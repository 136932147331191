import React from "react";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../../../../colors";
import { useTimeAgoInfo } from "../../../../hooks";
import { isURL } from "../../../../utils/isURL";
import { LinkUrl } from "../../../LinkUrl";
import { StyledTableComponent } from "../../../ReportTableWidget/styled";
import StyledText from "../../../StyledText";
import {
  WidgetContainer,
  WidgetTitle,
  WidgetTitleContainer,
} from "../../styled";
import { WidgetNoData } from "../../WidgetNoData";

import { ActivityDataItem } from "./model";

const DateColumn = styled(StyledText)`
  display: flex;
  color: ${COLOR_TEXT_TABLE_LABEL};
  align-items: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 10px;
  margin-left: 30px;
  margin-right: 25px;
  justify-content: flex-start;
  line-height: 12px;
`;

const SubjectText = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
`;

const ValueText = styled(StyledText)`
  color: ${COLOR_BLUE};
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-left: 8px;
  padding-right: 30px;
`;

const StyledTr = styled.tr<{ color?: string }>`
  ${(props) => {
    if (props.color) {
      return `
        background-color: ${props.color};
      `;
    }
  }}
  page-break-inside: avoid;
  page-break-after: auto;
  height: 45px;
`;

interface ActivityWidgetTableProps {
  data: ActivityDataItem[];
  title: string;
  widgetBorders: boolean;
  resizeRef?: any;
}

export const ActivityWidgetTable = ({
  data,
  title,
  widgetBorders,
  resizeRef,
}: ActivityWidgetTableProps) => {
  const { getDateInfo } = useTimeAgoInfo();
  const { isMobile } = useTheme();

  const rowValueWidth =
    isMobile && Math.max(...data?.map((row) => row?.value?.length ?? 0)) > 150
      ? "100vw"
      : "auto";

  return (
    <WidgetContainer showBorders={widgetBorders}>
      <div
        ref={resizeRef}
        style={
          isMobile
            ? {
                height: !data?.length ? "300px" : "auto",
              }
            : {}
        }
      >
        <WidgetTitleContainer style={{ paddingBottom: 25, paddingTop: 5 }}>
          <WidgetTitle>{title}</WidgetTitle>
        </WidgetTitleContainer>
        {!data?.length ? (
          <WidgetNoData />
        ) : (
          <div style={{ width: "100%", overflowX: "auto", paddingBottom: 10 }}>
            <StyledTableComponent id="activity-table-container-table-el">
              <colgroup>
                <col style={{ width: 155 }} />
                <col />
                <col />
              </colgroup>
              <tbody>
                {data.map((row, index) => {
                  const color = index % 2 === 0 ? COLOR_GRAY : COLOR_WHITE;
                  return (
                    <StyledTr key={`${row.time}-${row.subject}`} color={color}>
                      <td>
                        <DateColumn>
                          {getDateInfo(row.time).toUpperCase()}
                        </DateColumn>
                      </td>
                      <td>
                        <SubjectText>{row.subject}</SubjectText>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            padding: "8px",
                            boxSizing: "border-box",
                            width: rowValueWidth,
                          }}
                        >
                          {isURL(row.value || "") ? (
                            <LinkUrl
                              size={12}
                              url={row.value}
                              wrapperStyle={{ marginLeft: 8, paddingRight: 30 }}
                            />
                          ) : (
                            <ValueText>{row.value}</ValueText>
                          )}
                        </div>
                      </td>
                    </StyledTr>
                  );
                })}
              </tbody>
            </StyledTableComponent>
          </div>
        )}
      </div>
    </WidgetContainer>
  );
};
