import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE } from "../colors";

import { NextIcon } from "./Icons";

interface BackButtonProps {
  onBack?(): void;
}

const Button = styled.button`
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

const Label = styled.div`
  color: ${COLOR_BLUE};
  font-size: 14px;
  font-weight: 700;
  margin-left: 7px;
`;

export function BackButton({ onBack }: BackButtonProps) {
  const { t } = useTranslation();

  return (
    <Button onClick={onBack}>
      <NextIcon
        direction="left"
        tintColor={COLOR_BLUE}
        style={{ width: 19, height: 19 }}
      />
      <Label>{t("back")}</Label>
    </Button>
  );
}
