import React from "react";

import styled from "styled-components";

import { COLOR_GREEN, COLOR_GREY_ACTIVE } from "../../colors";

interface StepHeaderProps {
  sectionName: string;
  maxSteps: number;
  currentStep: number;
  stepName: string;
}

const BackgroundProgressStatus = styled.div`
  height: 5px;
  background-color: ${COLOR_GREY_ACTIVE};
  border-radius: 10px;
  margin: 15px 0;
`;

const ActiveProgressStatus = styled.div<{ width: string }>`
  height: 5px;
  background-color: ${COLOR_GREEN};
  border-radius: 10px;
  width: ${({ width }) => width};
`;

const StepTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const ProgressText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const AdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const StepHeader = ({
  sectionName,
  maxSteps,
  currentStep,
  stepName,
}: StepHeaderProps) => {
  return (
    <>
      <StepTitle>{sectionName}</StepTitle>
      {maxSteps > 1 ? (
        <>
          <BackgroundProgressStatus>
            <ActiveProgressStatus
              width={`${100 * (currentStep / maxSteps)}%`}
            />
          </BackgroundProgressStatus>
          <AdditionalInfoWrapper>
            <span>{stepName}</span>
            <ProgressText>{`${currentStep}/${maxSteps}`}</ProgressText>
          </AdditionalInfoWrapper>
        </>
      ) : null}
    </>
  );
};
