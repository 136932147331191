import { Dayjs } from "dayjs";

import { XpsUser } from "../../graphql";

import {
  DateSubState,
  ExtendedPeriod,
  Group,
  ReportsContextState,
} from "./index";

type LoadReportsAction = {
  type: "LOAD_REPORTS";
  state: ReportsContextState;
};

type LoadDateDataAction = {
  type: "LOAD_DATE_DATA";
  state: DateSubState;
};

type SelectGroupAction = {
  type: "SELECT_GROUP";
  group: Group;
};

type SeleteAthleteAction = {
  type: "SELECT_ATHLETE";
  athlete: XpsUser;
};

type SelectPeriodAction = {
  type: "SELECT_PERIOD";
  period: ExtendedPeriod;
};

type SelectStartDateAction = {
  type: "SELECT_START_DATE";
  date: Dayjs;
};

type SelectEndDateAction = {
  type: "SELECT_END_DATE";
  date: Dayjs;
};

type SelectNextPeriodAction = {
  type: "SELECT_NEXT_PERIOD";
  isSingleDay: boolean;
};

type SelectPrevPeriodAction = {
  type: "SELECT_PREV_PERIOD";
  isSingleDay: boolean;
};

type SelectSingleDayAction = {
  type: "SELECT_SINGLE_DAY";
  date: Dayjs;
};

type SelectLastSelectedAccountForAllAccountsAction = {
  type: "SELECT_LAST_SELECTED_ACCOUNT_FOR_ALL_ACCOUNTS";
  account: any;
};

type ResetReportsAction = {
  type: "RESET_REPORTS";
};

export type Actions =
  | LoadReportsAction
  | SelectGroupAction
  | SeleteAthleteAction
  | SelectPeriodAction
  | SelectStartDateAction
  | SelectEndDateAction
  | SelectNextPeriodAction
  | SelectPrevPeriodAction
  | SelectSingleDayAction
  | SelectLastSelectedAccountForAllAccountsAction
  | LoadDateDataAction
  | ResetReportsAction;

export function loadReportsAction(
  state: ReportsContextState
): LoadReportsAction {
  return {
    type: "LOAD_REPORTS",
    state,
  };
}

export function loadDateData(
  state: Pick<
    ReportsContextState,
    | "selectedEndDate"
    | "selectedSingleDay"
    | "selectedStartDate"
    | "selectedPeriod"
  >
): LoadDateDataAction {
  return {
    type: "LOAD_DATE_DATA",
    state,
  };
}

export function selectGroupAction(group: Group): SelectGroupAction {
  return {
    type: "SELECT_GROUP",
    group,
  };
}

export function selectAthleteAction(athlete: XpsUser): SeleteAthleteAction {
  return {
    type: "SELECT_ATHLETE",
    athlete,
  };
}

export function selectPeriodAction(period: ExtendedPeriod): SelectPeriodAction {
  return {
    type: "SELECT_PERIOD",
    period,
  };
}

export function selectStartDateAction(date: Dayjs): SelectStartDateAction {
  return {
    type: "SELECT_START_DATE",
    date,
  };
}

export function selectEndDateAction(date: Dayjs): SelectEndDateAction {
  return {
    type: "SELECT_END_DATE",
    date,
  };
}

export function selectNextPeriodAction(
  isSingleDay = false
): SelectNextPeriodAction {
  return { type: "SELECT_NEXT_PERIOD", isSingleDay };
}

export function selectPrevPeriodAction(
  isSingleDay = false
): SelectPrevPeriodAction {
  return { type: "SELECT_PREV_PERIOD", isSingleDay };
}

export function selecSingleDayAction(date: Dayjs): SelectSingleDayAction {
  return {
    type: "SELECT_SINGLE_DAY",
    date,
  };
}

export function selectLastSelectedAccountForAllAccountsAction(
  account: any
): SelectLastSelectedAccountForAllAccountsAction {
  return {
    type: "SELECT_LAST_SELECTED_ACCOUNT_FOR_ALL_ACCOUNTS",
    account,
  };
}

export function resetReports() {
  return {
    type: "RESET_REPORTS",
  } as const;
}
