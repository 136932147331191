import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { SwitchWrapper } from "../../ReportCreateTable/styled";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { DraggableLineWrapper } from "../../styled";
import { StyledSwitch } from "../../StyledSwitch";

type AppearanceSectionProps = {
  value: boolean;
  setFieldValue(name: string, value: boolean): void;
};

const LineWrapper = styled(DraggableLineWrapper)`
  justify-content: space-between;
`;

export function BodyChartAppearanceSection({
  value,
  setFieldValue,
}: PropsWithChildren<AppearanceSectionProps>) {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle title={t("appearance")} />
      <LineWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={value}
            onValueChange={(value) => setFieldValue("widgetBorders", value)}
            label={t("widgetBorders")}
          />
        </SwitchWrapper>
      </LineWrapper>
    </Section>
  );
}
