import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../colors";
import { ButtonColor, GeneralButton } from "../../components/Button/";
import { Checkbox } from "../../components/Checkbox";
import { StyledTextInput } from "../../components/StyledTextInput";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { usePrintAthleteReportContext } from "../../contexts/Print/PrintAthleteReportProvider";
import { useModalContext } from "../../contexts/UI/Modal";
import { ReportWidgetTemplateHeader } from "../../graphql";
import {
  ButtonsContainer,
  CheckboxesContainer,
  ControlContainer,
  ControlsContainer,
  ControlSection,
  Header,
  Title,
} from "../styled";

import { HeaderDropdown } from "./HeaderDropdown";
import { TabsDropdown } from "./TabsDropdown";

const CustomStyledTextInput = styled(StyledTextInput)`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  padding-left: 15px;
  padding-right: 15px;
`;

interface AthleteControlSectionProps {
  onPrint: () => void;
  headerWidget: ReportWidgetTemplateHeader;
}

export const AthleteControlSection = ({
  onPrint,
  headerWidget,
}: AthleteControlSectionProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();

  const {
    mainTitle,
    logoFlag,
    periodFlag,
    experimentalGridFlag,
    landscapeOrientation,
    setMainTitle,
    setLogoFlag,
    setPeriodFlag,
    setExperimentalGridFlag,
    setLandscapeOrientationFlag,
  } = usePrintAthleteReportContext();

  const { selectedAccount } = useAccessLevelsContext();

  const headerCells = (
    headerWidget
      ? [
          headerWidget.cell1,
          headerWidget.cell2,
          headerWidget.cell3,
          headerWidget.cell4,
          headerWidget.cell5,
          headerWidget.cell6,
        ]
      : []
  )
    .filter((cell) => Boolean(cell))
    .map((cell) => ({ id: cell.id, title: cell.name }));

  return (
    <ControlSection>
      <ControlsContainer>
        <Header>{t("printAthleteReport")}</Header>
        <ControlContainer>
          <Title>{t("title")}</Title>
          <CustomStyledTextInput
            value={mainTitle}
            onChangeText={(text) => setMainTitle(text)}
          />
        </ControlContainer>
        <ControlContainer style={{ zIndex: 2 }}>
          <Title>{t("includeReports")}</Title>
          <TabsDropdown />
        </ControlContainer>
        {headerCells.length > 0 && (
          <ControlContainer style={{ zIndex: 1 }}>
            <Title>{t("headers")}</Title>
            <HeaderDropdown headerCells={headerCells} />
          </ControlContainer>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Title style={{ marginBottom: "17px" }}>{t("header")}</Title>
          <CheckboxesContainer>
            {selectedAccount?.img && (
              <Checkbox
                text={t("includeLogo")}
                checked={logoFlag}
                check={setLogoFlag}
                withMargin={false}
              />
            )}
            <Checkbox
              text={t("includePeriod")}
              checked={periodFlag}
              check={setPeriodFlag}
              withMargin={false}
            />
          </CheckboxesContainer>

          <Title style={{ marginTop: "34px", marginBottom: "17px" }}>
            {t("experimentalFeatures")}
          </Title>
          <CheckboxesContainer>
            <Checkbox
              text={t("printGrid")}
              checked={experimentalGridFlag}
              check={setExperimentalGridFlag}
              withMargin={false}
            />
            <Checkbox
              text={t("landscapeOrientation")}
              checked={landscapeOrientation}
              check={setLandscapeOrientationFlag}
              withMargin={false}
            />
          </CheckboxesContainer>
        </div>
      </ControlsContainer>
      <ButtonsContainer>
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={modalActions.closeModal}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("confirm")}
          onClick={onPrint}
        />
      </ButtonsContainer>
    </ControlSection>
  );
};
