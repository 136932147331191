export const isHighPrecisionNumberInCanada = (
  number: number | null
): boolean => {
  if (number === null) {
    return false;
  }

  const d: number = number;
  if (d < 0.5 || d > 1.5) {
    return false;
  }

  const x100: number = d * 100.0;
  const areTwoDecimalsEnough: boolean =
    Math.abs(x100 - Math.floor(x100)) < 0.00000001;
  if (areTwoDecimalsEnough) {
    return false;
  }

  const x1000: number = d * 1000.0;
  return Math.abs(x1000 - Math.floor(x1000)) < 0.00000001;
};
