import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldArray, useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../../colors";
import { WidgetLimit } from "../../../../contexts/Reports";
import { AggregateFunction } from "../../../../graphql";
import { isAttendanceCollection } from "../../../../utils/reports";
import { GeneralButton } from "../../../Button/";
import { IconButton } from "../../../IconButton";
import { PlusIcon, RemoveIcon } from "../../../Icons";
import { Label } from "../../../Label";
import { ColorPicker } from "../../../ReportCreateTable/ColorPicker";
import { Section } from "../../../Section";
import { SelectField } from "../../../SelectField";
import { StyledNumberInput } from "../../../StyledNumberInput";
import StyledText from "../../../StyledText";
import { ChartItemModel, ChartWidgetModel, TimeLine } from "../model";

import { ChartValuesItem } from "./ChartValuesItem";

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  background-color: ${COLOR_GRAY};
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  height: 35px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleText = styled(StyledText)`
  font-weight: bold;
  font-size: 10px;
`;

const LimitWrapper = styled.div<{ index: number }>`
  display: flex;
  flex-direction: column;
  z-index: ${({ index }) => 100 - index};
`;

const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomTypeWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const ColorPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const HighLowTypeWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  min-width: 0px;
`;

const HighLowInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0px;
`;

const HighLowColorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export function ChartValuesAndLimitsSections() {
  const { t } = useTranslation();
  const formik = useFormikContext<ChartWidgetModel>();
  const { values, setFieldValue } = formik;

  useEffect(() => {
    const items = values.items
      .filter(
        (item) =>
          values.timeLine !== TimeLine.CONTINUOUS ||
          // filter out attendance templates
          !isAttendanceCollection(item.dataType)
      )
      .map((item) => ({
        ...item,
        aggregation:
          values.timeLine === TimeLine.CONTINUOUS
            ? AggregateFunction.Each
            : item.aggregation !== AggregateFunction.Each
              ? item.aggregation
              : "",
      }));

    setFieldValue("items", items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.timeLine]);

  return (
    <FieldArray name="limits">
      {({ push: pushLimit, remove: removeLimit }) => (
        <FieldArray name="items">
          {({ push: pushItem, remove: removeItem }) => (
            <Box>
              {values.items.map((item, index) => (
                <ChartValuesItem
                  key={`${item.dataType}-${item.templateGuid}-${item.attribute}-${index}`}
                  item={item}
                  index={index}
                  removeItem={removeItem}
                />
              ))}

              {values.limits.map((limit, index) => (
                <LimitWrapper
                  index={index}
                  key={`${limit.type}-${limit.color}-${index}`}
                >
                  <TitleRow>
                    <TitleText>
                      {t("limit")} {index + 1}
                    </TitleText>
                    <IconButton
                      size={25}
                      bgColor="transparent"
                      hoverBgColor={COLOR_WHITE}
                      icon={<RemoveIcon />}
                      onClick={() => removeLimit(index)}
                    />
                  </TitleRow>
                  <Section style={{ borderBottomWidth: 0 }}>
                    <TypeWrapper>
                      <Box style={{ marginRight: 10 }}>
                        <Label>{t("type").toUpperCase()}</Label>
                        <Field
                          name={`limits.${index}.type`}
                          component={SelectField}
                          options={[
                            { label: t("customValue"), value: "custom" },
                            { label: t("highLowZone"), value: "highLow" },
                          ]}
                        />
                      </Box>

                      {limit.type === "custom" && (
                        <CustomTypeWrapper>
                          <Box style={{ marginRight: 10, flex: 1 }}>
                            <StyledNumberInput
                              inputWidth={120}
                              label={t("value").toUpperCase()}
                              value={limit.value}
                              onChange={(newValue) =>
                                setFieldValue(`limits.${index}.value`, newValue)
                              }
                            />
                          </Box>

                          <Box style={{ marginRight: 10, flex: 1 }}>
                            <Label>{t("stroke").toUpperCase()}</Label>
                            <Field
                              name={`limits.${index}.stroke`}
                              component={SelectField}
                              options={[
                                { label: t("dashed"), value: "Dash" },
                                { label: t("solid"), value: "Solid" },
                                { label: t("dotted"), value: "Dot" },
                              ]}
                            />
                          </Box>

                          <Box>
                            <Label>{t("color").toUpperCase()}</Label>

                            <ColorPickerWrapper>
                              <ColorPicker
                                currentColor={limit.color}
                                onColorChange={(colorHex) => {
                                  setFieldValue(
                                    `limits[${index}].color`,
                                    colorHex
                                  );
                                }}
                                graySectionSize={45}
                                colorSectionSize={35}
                                isRightZero
                              />
                            </ColorPickerWrapper>
                          </Box>
                        </CustomTypeWrapper>
                      )}
                      {limit.type === "highLow" && (
                        <HighLowTypeWrapper>
                          <HighLowInputWrapper>
                            <StyledNumberInput
                              inputWidth={120}
                              label={t("lowValue").toUpperCase()}
                              value={limit.lowValue}
                              onChange={(newLowValue) =>
                                setFieldValue(
                                  `limits.${index}.lowValue`,
                                  newLowValue
                                )
                              }
                            />
                          </HighLowInputWrapper>
                          <HighLowInputWrapper>
                            <StyledNumberInput
                              inputWidth={120}
                              label={t("highValue").toUpperCase()}
                              value={limit.highValue}
                              onChange={(newHighValue) =>
                                setFieldValue(
                                  `limits.${index}.highValue`,
                                  newHighValue
                                )
                              }
                            />
                          </HighLowInputWrapper>
                          <HighLowColorWrapper>
                            <Label>{t("color").toUpperCase()}</Label>

                            <ColorPickerWrapper>
                              <ColorPicker
                                currentColor={limit.color}
                                onColorChange={(colorHex) => {
                                  setFieldValue(
                                    `limits[${index}].color`,
                                    colorHex
                                  );
                                }}
                                graySectionSize={45}
                                colorSectionSize={35}
                                isRightZero
                              />
                            </ColorPickerWrapper>
                          </HighLowColorWrapper>
                        </HighLowTypeWrapper>
                      )}
                    </TypeWrapper>
                  </Section>
                </LimitWrapper>
              ))}

              <Section
                style={{ display: "flex", flexDirection: "row", gap: 15 }}
              >
                <GeneralButton
                  label={t("addData")}
                  icon={<PlusIcon isActive />}
                  onClick={() =>
                    pushItem(
                      values.timeLine === TimeLine.CONTINUOUS
                        ? {
                            ...initialItemValues,
                            aggregation: AggregateFunction.Each,
                          }
                        : initialItemValues
                    )
                  }
                />
                <GeneralButton
                  label={t("addLimit")}
                  icon={<PlusIcon isActive />}
                  onClick={() => pushLimit(initialLimitValues)}
                />
              </Section>
            </Box>
          )}
        </FieldArray>
      )}
    </FieldArray>
  );
}

const initialItemValues = {
  aggregation: undefined,
  dataType: "",
  templateGuid: "",
  attribute: "",
  chartTypeOverride: undefined,
} as ChartItemModel;

const initialLimitValues = {
  type: "custom",
  value: 0,
  lowValue: "",
  highValue: "",
  color: "#FF6900",
  stroke: "Dash",
} as WidgetLimit;
