import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import {
  COLOR_ALMOST_BLACK,
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_WHITE,
} from "../../colors";
import { useMobileNavSideContext } from "../../contexts/mobileNavSide";
import { useUIContext } from "../../contexts/UI";
import { useUserContext } from "../../contexts/User";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { BellIcon, LogoAcesNationIcon, LogoXpsIcon } from "../Icons";

import { HeaderNavigation } from "./HeaderNavigation";
import { NotificationsList } from "./Notifications/NotificationsList";
import Topbar from "./Topbar";

export const HEIGHT_OF_MESSAGES_HEADER = 65;

const Container = styled.div<{ isMobile?: boolean }>`
  min-height: 54px;
  max-height: 54px;
  background-color: ${COLOR_WHITE};
  display: flex;
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  align-items: center;
  flex-direction: row;
  padding: 0 ${({ isMobile }) => (isMobile ? `15px` : `30px`)};
  z-index: 10000;
`;

const MobileIconButton = styled(BasicButton)`
  top: 0;
  left: 0;
  position: absolute;
  height: ${HEIGHT_OF_MESSAGES_HEADER}px;
  width: 70px;
  background-color: ${COLOR_ALMOST_BLACK};
`;

const MobileIconWrapper = styled.div`
  margin: 27.5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotificationButton = styled.button<{
  show: boolean;
}>`
  display: flex;
  border: none;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ show }) => (show ? COLOR_BLUE : "transparent")};
  cursor: pointer;
`;

interface HeaderProps {
  onBack?: () => void;
}

export function Header({ onBack }: HeaderProps) {
  const { pathname } = useLocation();
  const { sessionId } = useUserContext();
  const UI = useUIContext();
  const { isMobile } = useTheme();
  const { isMobileSidebarOpen, setIsMobileSideBarOpen } =
    useMobileNavSideContext();

  if (UI.embed) return null;

  const checkPathMatch = (paths: string[]) =>
    paths.some((path) => matchPath(path, pathname));

  return (
    <Container>
      {!isMobileSidebarOpen && isMobile && (
        <MobileIconButton onClick={() => setIsMobileSideBarOpen((old) => !old)}>
          <MobileIconWrapper>
            {isAcesNationEnv() ? (
              <LogoAcesNationIcon width={40} height={30} />
            ) : (
              <LogoXpsIcon width={40} height={10} />
            )}
          </MobileIconWrapper>
        </MobileIconButton>
      )}
      {!isMobile &&
      checkPathMatch([
        ROUTING_CONFIG.session,
        ROUTING_CONFIG.teamsAthlete,
        ROUTING_CONFIG.AthleteTeam,
        ROUTING_CONFIG.AthleteCollections,
        ROUTING_CONFIG.AthleteReports,
      ]) ? (
        <HeaderNavigation onBack={onBack} />
      ) : null}
      <div style={{ flex: 1 }} />
      <Topbar />
      <Dropdown
        dropdownContainerStyle={{
          zIndex: 2,
          minWidth: isMobile ? 325 : 375,
          top: 35,
        }}
        // @ts-ignore
        dropdownContentStyle={{
          padding: "10px 0",
          paddingBottom: 0,
          maxHeight: "calc(100vh - 60px)",
          overflow: "auto",
        }}
        component={({ toggle, show }) => (
          <NotificationButton onClick={toggle} show={show}>
            <BellIcon isActive={show} />
          </NotificationButton>
        )}
      >
        <NotificationsList sessionId={sessionId} />
      </Dropdown>
    </Container>
  );
}
