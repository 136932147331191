import React from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_BLUE_ATTENDANCE_BG,
  COLOR_RED_ATTENDANCE_BG,
  COLOR_RED_SECONDARY,
} from "../colors";

import { IconButton } from "./IconButton";
import { CrossFilledIcon } from "./Icons";
import { SelectableResource } from "./Modals/AddResourceModal";

type SelectedResourceTagProps = {
  resource: SelectableResource;
  removeResource: (id: string) => void;
};

const FacilityWrapper = styled.div<{ isOccupied: boolean }>`
  display: flex;
  flex-direction: row;
  background-color: ${({ isOccupied }) =>
    isOccupied ? COLOR_RED_ATTENDANCE_BG : COLOR_BLUE_ATTENDANCE_BG};
  border-radius: 12px;
  padding: 3px 3px 3px 10px;
`;

const Title = styled.span<{ isOccupied: boolean }>`
  color: ${({ isOccupied }) => (isOccupied ? COLOR_RED_SECONDARY : COLOR_BLUE)};
  font-weight: 600;
  font-size: 9px;
  line-height: 13px;
  margin-right: 6px;
`;

export function SelectedResourceTag({
  resource,
  removeResource,
}: SelectedResourceTagProps) {
  if (!resource?.isSelected) {
    return null;
  }

  return (
    <FacilityWrapper isOccupied={resource.isOccupied}>
      <Title isOccupied={resource.isOccupied}>{resource.resourceTitle}</Title>
      <IconButton
        icon={
          <CrossFilledIcon
            style={{ opacity: 0.5 }}
            color={resource.isOccupied ? COLOR_RED_SECONDARY : COLOR_BLUE}
          />
        }
        size={12}
        onClick={() => removeResource(resource.resourceId)}
      />
    </FacilityWrapper>
  );
}
