import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { Dayjs } from "dayjs";
import styled, { useTheme } from "styled-components";

import { getTodayDate } from "../../utils/date";
import { ButtonColor, ButtonType, GeneralButton } from "../Button/";

const DateButtonsRowStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export function DateButtonsRow({ children }: PropsWithChildren<unknown>) {
  const { isMobile } = useTheme();

  return isMobile ? (
    <>{children}</>
  ) : (
    <DateButtonsRowStyled>{children}</DateButtonsRowStyled>
  );
}

const getStartDayOfInterval = (today: Dayjs, countOfDays: number) =>
  today.subtract(countOfDays - 1, "days");

const WEEK_INTERVAL_LENGTH = 7;
const MONTH_INTERVAL_LENGTH = 30;

export function ReportPeriodDateButtons({
  setStartDate,
  setEndDate,
}: {
  setStartDate(date): void;
  setEndDate(date): void;
}) {
  const { t } = useTranslation();
  const { isMobile } = useTheme();
  const today = getTodayDate();

  return (
    <Container
      style={
        isMobile
          ? {
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              padding: 5,
              gap: 10,
            }
          : {}
      }
    >
      <DateButtonsRow>
        <GeneralButton
          label={t("today")}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(today);
            setEndDate(today);
          }}
        />
        <GeneralButton
          label={t("lastXDays", { x: WEEK_INTERVAL_LENGTH })}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(getStartDayOfInterval(today, WEEK_INTERVAL_LENGTH));
            setEndDate(today);
          }}
        />
        <GeneralButton
          label={t("lastXDays", { x: MONTH_INTERVAL_LENGTH })}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(getStartDayOfInterval(today, MONTH_INTERVAL_LENGTH));
            setEndDate(today);
          }}
        />
      </DateButtonsRow>
      <DateButtonsRow>
        <GeneralButton
          label={t("thisWeek")}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(today.startOf("isoWeek"));
            setEndDate(today.endOf("isoWeek"));
          }}
        />
        <GeneralButton
          label={t("thisMonth")}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(today.startOf("month"));
            setEndDate(today.endOf("month"));
          }}
        />
        <GeneralButton
          label={t("thisYear")}
          colorVariant={ButtonColor.Blue}
          sizeType={ButtonType.Tiny}
          outlined
          onClick={() => {
            setStartDate(today.startOf("year"));
            setEndDate(today.endOf("year"));
          }}
        />
      </DateButtonsRow>
    </Container>
  );
}
