import dayjs, { Dayjs } from "dayjs";

import { AggregateFunction } from "../../graphql";
import * as api from "../api";
import * as endpoints from "../endpoints";

export type JsonInformationQueryResponse = {
  _templates: {
    _templateGuid: string;
    _name: string;
    _items: {
      _athleteGuid: string;
      _value: string;
      _time: number;
    }[];
  }[];
};

interface FetchInformationsArgs {
  athleteGuids: string[];
  informationGuids: string[];
  session: api.Session;
  focusedGroup?: string;
  focusedUserAccount?: string;
  reportTemplateId: string;
}

export function fetchInformations({
  athleteGuids,
  informationGuids,
  session,
  focusedGroup,
  focusedUserAccount,
  reportTemplateId,
}: FetchInformationsArgs) {
  const params = {
    _athleteGuids: athleteGuids,
    _informationTemplateGuids: informationGuids,
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
    reportTemplateId,
  };

  return api.post<JsonInformationQueryResponse>(
    endpoints.JsonInformationQuery,
    params,
    session
  );
}

export interface JsonInformation2022QueryResponse {
  templates: {
    cooking: string;
    templateGuid: string;
    name: string;
    options?: string[];
    items: { _guid: string; _time: number; _value: string; _label?: string }[];
  }[];
}

export type InformationCooking =
  | "AllEntriesInPeriod"
  | "AllTimeLatest"
  | "LastDayInPeriod"
  | "LatestInPeriod";

export function getCookingValue(
  aggregation:
    | AggregateFunction.Each
    | AggregateFunction.Latest
    | AggregateFunction.LatestForever
    | AggregateFunction.LastDayInRange
): InformationCooking {
  switch (aggregation) {
    case AggregateFunction.Each:
      return "AllEntriesInPeriod";
    case AggregateFunction.LatestForever:
      return "AllTimeLatest";
    case AggregateFunction.LastDayInRange:
      return "LastDayInPeriod";
    case AggregateFunction.Latest:
    default:
      return "LatestInPeriod";
  }
}

export function getAggregationValueFromCooking(cooking: InformationCooking) {
  switch (cooking) {
    case "AllEntriesInPeriod":
      return AggregateFunction.Each;
    case "AllTimeLatest":
      return AggregateFunction.LatestForever;
    case "LatestInPeriod":
      return AggregateFunction.Latest;
    case "LastDayInPeriod":
      return AggregateFunction.LastDayInRange;
    default:
      return null;
  }
}

export type UseInformations2022QueryArgs = {
  athleteGuid: string;
  templates: { cooking: InformationCooking; guid: string }[];
  range?: { from: Dayjs; to: Dayjs };
  session: api.Session;
  reportTemplateId: string;
  focusedGroup?: string;
  focusedUserAccount?: string;
};

export function fetchInformation2022({
  athleteGuid,
  templates,
  range,
  session,
  reportTemplateId,
  focusedGroup,
  focusedUserAccount,
}: UseInformations2022QueryArgs) {
  const params = {
    athleteId: athleteGuid,
    templates,
    reportTemplateId,
    range: range
      ? {
          _from: dayjs(range.from).valueOf(),
          _to: dayjs(range.to).valueOf(),
        }
      : undefined,
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
  };

  return api.post<JsonInformation2022QueryResponse>(
    endpoints.JsonInformationQuery2022,
    params,
    session
  );
}

export type InformationNewDataTeam = {
  byAthletes: ({
    athleteFullName: string;
    athleteGuid: string;
  } & JsonInformation2022QueryResponse)[];
};
export type UseInformations2023QueryArgs = {
  athleteIds: string[];
  templates: { cooking: InformationCooking; guid: string }[];
  range: { from: Dayjs; to: Dayjs };
  session: api.Session;
  reportTemplateId: string;
  focusedGroup?: string;
  focusedUserAccount?: string;
};

export function fetchInformation2023({
  athleteIds,
  templates,
  range,
  session,
  reportTemplateId,
  focusedGroup,
  focusedUserAccount,
}: UseInformations2023QueryArgs) {
  const params = {
    athleteIds,
    templates,
    reportTemplateId,
    range: {
      _from: dayjs(range.from).valueOf(),
      _to: dayjs(range.to).valueOf(),
    },
    _focusedGroup: focusedGroup,
    _focusedUserAccount: focusedUserAccount,
  };

  return api.post<InformationNewDataTeam>(
    endpoints.JsonInformationQuery2023,
    params,
    session
  );
}
