import React from "react";
import { useController } from "react-hook-form";

import { ColorPicker } from "../ColorPicker/ColorPicker";

import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

interface TextInputHookProps {
  name: string;
  label: string;
  colorList: string[];
  defaultValue?: string;
  spacing?: {
    marginBottom?: number;
  };
  toggleColorPicker?: (isOpen: boolean) => void;
}

export function ControlledColorPicker({
  name,
  label,
  colorList,
  defaultValue,
  spacing,
  toggleColorPicker,
}: TextInputHookProps) {
  const {
    field: { value, onChange },
  } = useController({ defaultValue, name });

  return (
    <StyledInputWrapper marginBottom={spacing?.marginBottom}>
      <LabelText>{label.toUpperCase()}</LabelText>
      <ColorPicker
        colorList={colorList}
        hasArrow
        currentColor={value}
        onColorChange={onChange}
        toggleColorPicker={toggleColorPicker}
      />
    </StyledInputWrapper>
  );
}
