import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { ReportTemplateInput } from "../../../graphql";
import { IconButton } from "../../IconButton";
import { RemoveIcon, ReorderIcon } from "../../Icons";
import { ReorderImageWrapper } from "../../ReportCreateTable/styled";
import { DraggableLineWrapper, FieldWrapper } from "../../styled";

import { StyledField } from "./styled";

export function TabRow({
  dragHandleProps,
  onDeletePress,
  tab,
  index,
  shouldBeTextSelected,
}: {
  dragHandleProps: any;
  onDeletePress: () => void;
  tab: ReportTemplateInput;
  index: number;
  shouldBeTextSelected: boolean;
}) {
  const { t } = useTranslation();
  const { values } = useFormikContext<{ tabs: ReportTemplateInput[] }>();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && shouldBeTextSelected) {
      inputRef.current.select();
    }
  }, [shouldBeTextSelected]);

  return (
    <DraggableLineWrapper key={tab.id}>
      <ReorderImageWrapper {...dragHandleProps}>
        <ReorderIcon />
      </ReorderImageWrapper>
      <FieldWrapper>
        <StyledField
          name={`tabs.[${index}].name`}
          innerRef={inputRef}
          style={{ height: 40 }}
          placeholder={t("tab")}
          onClick={() => inputRef.current && inputRef.current.focus()}
        />
      </FieldWrapper>
      {values?.tabs?.length > 1 && (
        <IconButton icon={<RemoveIcon />} onClick={onDeletePress} />
      )}
    </DraggableLineWrapper>
  );
}
