import React from "react";
import { useTranslation } from "react-i18next";

import { changePaginatedContactInMessages } from "../../../cacheUpdates/changePaginatedContactInMessages";
import { COLOR_TEXT_DARK } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { useHideMutation } from "../../../graphql";
import Hoverable from "../../Hoverable";
import { HideIcon, UnhideIcon } from "../../Icons";
import { StyledOptionItem } from "../../Report/styled";
import { RoundIcon } from "../../RoundIcon";
import StyledText from "../../StyledText";

export const HideChatOption = ({
  contact,
  contactHidden,
  hideContact,
  activateNextContact,
  onRowPress,
  toggle,
}) => {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();

  const [hide] = useHideMutation();

  const handlePress = async () => {
    await hide({
      variables: {
        sessionId,
        chatterId: contact.id,
        hidden: !contactHidden,
      },
      update: (proxy) => {
        changePaginatedContactInMessages({
          proxy,
          sessionId,
          language,
          contactId: contact.id,
          edgeModifier: (node) => ({ ...node, hidden: !contactHidden }),
        });
      },
    });

    hideContact(!contactHidden);
    await toggle(false);

    if (!contactHidden) {
      activateNextContact(contact);
    } else {
      await onRowPress(contact);
    }
  };

  return (
    <Hoverable>
      {(isHovered) => (
        <StyledOptionItem
          isHovered={isHovered}
          height={45}
          onClick={handlePress}
        >
          <RoundIcon
            svgIcon={
              contactHidden ? (
                <UnhideIcon color={COLOR_TEXT_DARK} />
              ) : (
                <HideIcon color={COLOR_TEXT_DARK} />
              )
            }
            isHovered={isHovered}
          />
          <StyledText
            fontWeight="bold"
            fontSize={14}
            numberOfLines={1}
            style={{ maxWidth: 200 }}
          >
            {contactHidden ? t("unhide") : t("hide")}
          </StyledText>
        </StyledOptionItem>
      )}
    </Hoverable>
  );
};
