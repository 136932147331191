import { AccessToTrainer, XpsUser } from "../../../graphql";

export const generateAccessLevelLocalOverrideAccount = (
  accessLevels: AccessToTrainer[],
  newSelectedAccountId: string
) => ({
  user: { id: newSelectedAccountId } as XpsUser,
  statistics: {
    viewAll: accessLevels.some(
      (accessLevel) => accessLevel.statistics?.viewAll
    ),
    edit: accessLevels.some((accessLevel) => accessLevel.statistics?.edit),
  },
});
