import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const CrossFilledIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    color = "#B1B7BC",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill={color} />
      <path d="M6 15L15 6" stroke="white" strokeWidth="1.5" />
      <path d="M6 6L15 15" stroke="white" strokeWidth="1.5" />
    </svg>
  )
);
