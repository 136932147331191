import { CSSProperties, ReactNode } from "react";

import { TopModalActionType, TopModalType } from "./constants";

export interface TopModelContextInitialStateValue {
  open: boolean;
  modal: TopModalType;
  title: ReactNode;
  params: any;
  style: CSSProperties;
  onBack?: () => void;
}

export const initialTopModalContextState = {
  open: false,
  modal: undefined,
  title: "",
  style: {},
  params: {},
};

export default (state: TopModelContextInitialStateValue, action) => {
  switch (action.type) {
    case TopModalActionType.OPEN:
      return { ...initialTopModalContextState, ...action.payload, open: true };
    case TopModalActionType.CLOSE:
      return {
        ...initialTopModalContextState,
      };
    default:
      return state;
  }
};
