import dayjs from "dayjs";

import { COLOR_SECONDARY_MAGENTA } from "../../colors";
import { Workout } from "../../graphql";
import { convertUnixToHoursMinutes } from "../../utils/date";
import { rgbToHex } from "../Modals/utils";

type SessionType = Pick<
  Workout,
  | "label"
  | "start"
  | "durationMinutes"
  | "attendedMinutesBefore"
  | "rgb"
  | "location"
  | "xtraLocation"
  | "notes"
  | "specialNotes"
> & {
  allDay?: boolean;
};

export const createInfoFormDefaultValues = (
  session?: SessionType | null,
  defaultStartDate?: string
) => {
  const today = dayjs();

  return {
    label: session ? session.label : "",
    startDate: session ? dayjs(session.start) : defaultStartDate || today,
    startTime: session ? convertUnixToHoursMinutes(session.start) : "09:30",
    durationMinutes: session ? session.durationMinutes : 0,
    attendance: session ? session.attendedMinutesBefore : undefined,
    color: session ? rgbToHex(session.rgb) : COLOR_SECONDARY_MAGENTA,
    allDay: session ? session.allDay : false,
    location: session ? session.location : "",
    locationDescription: session ? session.xtraLocation : "",
    notes: session ? session.notes : "",
    specialNotes: session ? session.specialNotes : "",
  };
};
