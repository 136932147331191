import styled from "styled-components";

export const BasicButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;

  &:active {
    opacity: 0.35;
  }
`;
