import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChartBarIcon = memo(
  ({
    width = 25,
    height = 25,
    isActive = false,
    tintColor = "#64707A",
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12154_31125)">
        {isActive ? (
          <>
            <rect width="1" height="25" fill="white" />
            <rect y="24" width="25" height="1" fill="white" />
            <rect x="4" y="18" width="16" height="3" fill="white" />
            <rect x="4" y="13" width="7" height="3" fill="white" />
            <rect x="4" y="8" width="20" height="3" fill="white" />
          </>
        ) : (
          <>
            <rect width="1" height="25" fill={tintColor} />
            <rect y="24" width="25" height="1" fill={tintColor} />
            <rect x="4" y="18" width="16" height="3" fill={tintColor} />
            <rect x="4" y="13" width="7" height="3" fill={tintColor} />
            <rect x="4" y="8" width="20" height="3" fill={tintColor} />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0_12154_31125">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
