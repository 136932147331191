import dayjs from "dayjs";

import * as api from "../../services/api";
import * as endpoints from "../../services/endpoints";

export const fetchAllCollections = async (sessionId: string) => {
  return api.post(
    endpoints.JsonSharingQuery,
    {
      _sessionId: sessionId,
      _timeZoneOffset: -new Date().getTimezoneOffset() * 60 * 1000,
      _timeZoneName: dayjs.tz.guess(),
      _withReceivedDetails: false,
      _withCollectionsDetails: false,
      _onlyItemsThatNeedAttention: false,
      _selectedAccount: true,
    },
    { sessionId }
  );
};
