import React from "react";

import { AhtleteProfileHeader } from "../components/AthleteProfile/AhtleteProfileHeader";
import { AthleteProfileReport } from "../components/AthleteProfile/AthleteProfileReport";
import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";
import { AthleteProfileContextProvider } from "../contexts/athleteProfile";
import { useAutoSelectAthlete } from "../hooks/report/useAutoSelectAthlete";

export function AthleteReportsScreen() {
  useAutoSelectAthlete();

  return (
    <AthleteProfileContextProvider>
      <ScreenContainer>
        <Header />
        <AhtleteProfileHeader isAthleteProfileReport />
        <AthleteProfileReport />
      </ScreenContainer>
    </AthleteProfileContextProvider>
  );
}
