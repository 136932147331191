import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import { useFormikContext } from "formik";

import { ActivityLogWidgetFormValue } from "../Editor/model";

import { ActivityDataItem } from "./model";
import { ActivityWidgetTable } from "./Table";

const getTimeEntityFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return "second";
    case 1:
      return "minute";
    case 2:
      return "hour";
    default:
      return "day";
  }
};

const FEW_SECONDS_AGO = 30;

export const ActivityWidgetPreview = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<ActivityLogWidgetFormValue>();

  const dummyData: ActivityDataItem[] = [
    ...(values.testsRegistered
      ? [
          {
            subject: `${t("dummyJuggling")} ${t("count").toLowerCase()} ${t(
              "registered"
            ).toLowerCase()}`,
            value: "69 #",
          },
          {
            subject: `${t("dummyJuggling")} ${t("time").toLowerCase()} ${t(
              "registered"
            ).toLowerCase()}`,
            value: "20 sec.",
          },
        ]
      : []),
    ...(values.informationRegistered
      ? [
          {
            subject: `${t("dummyCovidSymptoms")} ${t("updated").toLowerCase()}`,
            value: t("yes"),
          },
          {
            subject: `${t("dummyCovidSymptoms")} ${t("updated").toLowerCase()}`,
            value: t("no"),
          },
        ]
      : []),
    ...(values.workoutsCompleted
      ? [
          {
            subject: `${t("workout")} ${t("completed").toLowerCase()}`,
            value: "Workout",
          },
        ]
      : []),
    ...(values.somethingRead
      ? [{ subject: t("somethingRead"), value: "Workout" }]
      : []),
  ].map((item, index) => ({
    ...item,
    time: dayjs().subtract(
      index === 0 ? FEW_SECONDS_AGO : index,
      getTimeEntityFromIndex(index)
    ),
  }));

  return (
    <ActivityWidgetTable
      data={dummyData}
      title={values.name}
      widgetBorders={values.widgetBorders}
    />
  );
};
