import { Dayjs } from "dayjs";
import _partition from "lodash/partition";
import { std, mean } from "mathjs";

import { TimeLinePeriod } from "../../../components/Report/ChartWIdget/model";
import { GrouppedColumnHeader } from "../../../components/TablePeriodization/Widget";
import { useReportContext } from "../../../contexts/report";
import { useReportsContext } from "../../../contexts/Reports";
import { useUserContext } from "../../../contexts/User";
import { AggregateFunction, ReportWidgetCellNumerical } from "../../../graphql";
import { JsonStatisticsPeriod, TestStatistics } from "../../../services";
import {
  useAttendanceStatisticsQuery,
  useSoccerStatisticsQuery,
  useAthleteStatisticsQuery,
} from "../../../services/statistics/hooks";
import { roundToTwo } from "../../../utils/number";
import {
  getAttendanceStatisticsDataKey,
  getSoccerStatisticsDataKey,
  isAttendanceCollection,
  isSoccerStatsCollection,
} from "../../../utils/reports";
import { getAllAthleteStats, getDataKey } from "../../../utils/statistics";
import { useDefaultDateFormat } from "../../useDateFormat";
import { useIsMonitoring } from "../../useIsMonitoring";

import {
  getSummaryStatitisticForSoccerOrAttendanceForPeriods,
  getStatsMinMaxForSoccerOrAttendanceData,
  getSoccerStatsDataItemsForPeriods,
  generatePeriodCategories,
} from "./helpers/helpers";
import { getAttendanceStatsDataItems } from "./helpers/helpers/getAttendanceStatsDataItems";
import { getPeriodizationForSoccerStats } from "./helpers/helpers/getPeriodizationForSoccerAndAttendanceStats";
import { getStatisticsDataItemsForPeriods } from "./helpers/helpers/getStatisticsDataItemsForPeriods";

type UseAthleteDataForPeriodizationTableParams = {
  widgetId: string;
  fromDate: Dayjs;
  toDate: Dayjs;
  period: JsonStatisticsPeriod;
  grouppedColumnHeaders: GrouppedColumnHeader[];
  sortOrder: number;
};

const getSummaryStatisticsDataForPeriods = (allStats: TestStatistics[]) =>
  allStats.flatMap((stat) =>
    stat._attributes.flatMap((statAttribute) => {
      const values = statAttribute._values.map((value) => value._val);
      const hasTxtValues = statAttribute._values.some((value) => value._txt);

      const displayedUnitName = hasTxtValues
        ? ""
        : statAttribute._unitName ?? "";

      return {
        id: getDataKey(stat._guid, statAttribute._guid, stat._agg),
        average: values?.length
          ? `${roundToTwo(mean(values))} ${displayedUnitName}`
          : "",
        stdDev: values?.length
          ? `${roundToTwo(
              std(values) as unknown as number
            ).toString()}  ${displayedUnitName}`
          : "",
      };
    })
  );

enum AthletePeriodizationQueryKeys {
  STATS = "Stats",
  SOCCER_STATS = "Soccer-stats",
  ATTENDANCE_STATS = "Attendance-stats",
}

export const useAthleteDataForPeriodizationTable = ({
  widgetId,
  fromDate,
  toDate,
  period,
  grouppedColumnHeaders,
  sortOrder,
}: UseAthleteDataForPeriodizationTableParams) => {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete, selectedGroup }] = useReportsContext();
  const { report } = useReportContext();
  const isMonitoring = useIsMonitoring();

  const [soccerStatsColumnHeaders, notSoccerColumnHeaders] = _partition(
    grouppedColumnHeaders,
    (row) => isSoccerStatsCollection(row.attributes[0].collectionId)
  );

  const [attendanceStatsColumnHeaders, statisticsStatsColumnHeaders] =
    _partition(notSoccerColumnHeaders, (row) =>
      isAttendanceCollection(row.attributes[0].collectionId)
    );

  const getStatsQueryKey = (statType: AthletePeriodizationQueryKeys) => [
    statType,
    widgetId,
    selectedAthlete?.id,
    fromDate.toString(),
    toDate.toString(),
  ];

  const {
    data: athleteStatisticsData = null,
    isLoading: athleteStatisticsIsLoading,
    fetchStatus: athleteStatisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period,
      session: { sessionId },
      items: statisticsStatsColumnHeaders
        .flatMap((grouppedHeader) => grouppedHeader.attributes)
        .map(
          ({
            collectionId,
            templateId,
            attributeTemplate,
            aggregateFunctions,
          }: ReportWidgetCellNumerical) => ({
            dataType: collectionId,
            attribute: attributeTemplate?.id,
            templateGuid: templateId,
            aggregation:
              period === "continuous"
                ? AggregateFunction.Each
                : aggregateFunctions,
          })
        ),
      focusedAthlete: selectedAthlete?.id,
      participants: [{ id: selectedAthlete?.id }],
      reportTemplateId: report?.id,
    },
    {
      queryKey: getStatsQueryKey(AthletePeriodizationQueryKeys.STATS),
      enabled:
        sessionId && selectedAthlete?.id && grouppedColumnHeaders.length > 0,
    }
  );

  const {
    data: athleteSoccerStatData = null,
    isLoading: athleteSoccerStatsIsLoading,
    fetchStatus: athletesSoccerStatsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete:
          period === "continuous" ? undefined : selectedAthlete?.id,
        focusedGroup:
          period === "continuous" || !isMonitoring
            ? undefined
            : selectedGroup?.id,
      },
      periodization:
        period !== "continuous" ? getPeriodizationForSoccerStats(period) : "No",
      from: fromDate,
      to: toDate,
      reportTemplateId: report?.id,
      athleteGuids: [selectedAthlete?.id],
      withAllTimeResults: false,
    },
    {
      queryKey: getStatsQueryKey(AthletePeriodizationQueryKeys.SOCCER_STATS),
      enabled: sessionId && soccerStatsColumnHeaders?.length > 0,
    }
  );

  const {
    data: attendanceStatisticsData = null,
    isLoading: attendanceStatisticsIsLoading,
    fetchStatus: attendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: { sessionId },
      fromDate,
      toDate,
      participants: [selectedAthlete],
      periodization:
        period !== "continuous"
          ? getPeriodizationForSoccerStats(period)
          : undefined,
      reportTemplateId: report?.id,
    },
    {
      queryKey: getStatsQueryKey(
        AthletePeriodizationQueryKeys.ATTENDANCE_STATS
      ),
      enabled:
        sessionId &&
        selectedAthlete?.id &&
        attendanceStatsColumnHeaders.length > 0,
    }
  );

  const { shortWordDateFormat } = useDefaultDateFormat();
  if (
    athleteStatisticsFetchStatus !== "idle" &&
    athleteStatisticsIsLoading &&
    athletesSoccerStatsFetchStatus !== "idle" &&
    athleteSoccerStatsIsLoading &&
    attendanceStatisticsFetchStatus !== "idle" &&
    attendanceStatisticsIsLoading
  ) {
    return { loading: true, data: [] };
  }
  const periods = generatePeriodCategories(
    fromDate,
    toDate,
    period === "continuous" ? TimeLinePeriod.DAYS : (period as TimeLinePeriod),
    shortWordDateFormat
  ).sort(() => sortOrder);

  const allStats = getAllAthleteStats(
    athleteStatisticsData?._athletes[0],
    true
  );

  const soccerMinMax = getStatsMinMaxForSoccerOrAttendanceData(
    soccerStatsColumnHeaders,
    getSoccerStatisticsDataKey,
    period === "continuous"
      ? athleteSoccerStatData?.data?.perAthletes[0]?.games
      : athleteSoccerStatData?.data?.perAthletes[0]?.periods,
    period !== "continuous"
  );

  const attendanceMinMax = getStatsMinMaxForSoccerOrAttendanceData(
    attendanceStatsColumnHeaders,
    getAttendanceStatisticsDataKey,
    attendanceStatisticsData?.data?.perAthletes[0]?.attendanceInPeriodOfTime
  );

  const data = periods.map((dataPeriod) => ({
    label: dataPeriod.label,
    data: getStatisticsDataItemsForPeriods(dataPeriod, allStats),
    soccerStats: getSoccerStatsDataItemsForPeriods(
      dataPeriod,
      period === "continuous" ? "days" : period,
      soccerStatsColumnHeaders,
      period === "continuous"
        ? athleteSoccerStatData?.data?.perAthletes[0]?.games
        : athleteSoccerStatData?.data?.perAthletes[0]?.periods,
      soccerMinMax,
      period === "continuous"
    ),
    attendanceStats: getAttendanceStatsDataItems(
      dataPeriod,
      attendanceStatsColumnHeaders,
      attendanceStatisticsData?.data?.perAthletes[0]?.attendanceInPeriodOfTime,
      attendanceMinMax
    ),
  }));

  const summaryStatsData = {
    statisticsDataSummaryStats: getSummaryStatisticsDataForPeriods(allStats),
    soccerStatsDataSummaryStasts:
      getSummaryStatitisticForSoccerOrAttendanceForPeriods(
        soccerStatsColumnHeaders,
        data.map((dataItem) => dataItem.soccerStats)
      ),
    attendanceStatsDataSummaryStasts:
      getSummaryStatitisticForSoccerOrAttendanceForPeriods(
        attendanceStatsColumnHeaders,
        data.map((dataItem) => dataItem.attendanceStats)
      ),
  };

  const sortedData = data.map((periodData) => ({
    label: periodData.label,
    data: grouppedColumnHeaders.flatMap((columnHeader) =>
      columnHeader.attributes.flatMap(
        (columnAttribute: ReportWidgetCellNumerical) =>
          columnAttribute.aggregateFunctions.map((currentAggregation) => {
            const filteredValues = [
              ...periodData.data,
              ...periodData.soccerStats,
              ...periodData.attendanceStats,
            ].filter(
              (dataItem) =>
                dataItem.id ===
                getDataKey(
                  columnHeader.templateId,
                  columnAttribute.attributeTemplate?.id,
                  currentAggregation
                )
            );

            return filteredValues.length > 0
              ? filteredValues
              : [
                  {
                    id: getDataKey(
                      columnHeader.templateId,
                      columnAttribute.attributeTemplate?.id,
                      currentAggregation
                    ),
                    value: NaN,
                    min: -100,
                    max: 100,
                    txt: "-",
                    unitName: "",
                    time: 0,
                  },
                ];
          })
      )
    ),
  }));

  const sortedStatsData = grouppedColumnHeaders.flatMap((columnHeader) =>
    columnHeader.attributes.flatMap(
      (columnAttribute: ReportWidgetCellNumerical) =>
        columnAttribute.aggregateFunctions.flatMap((currentAggregation) => {
          const columnHeaderId = getDataKey(
            columnHeader.templateId,
            columnAttribute.attributeTemplate?.id,
            currentAggregation
          );

          return (
            [
              ...summaryStatsData.statisticsDataSummaryStats,
              ...summaryStatsData.soccerStatsDataSummaryStasts,
              ...summaryStatsData.attendanceStatsDataSummaryStasts,
            ].find((statData) => statData.id === columnHeaderId) ?? {
              id: columnHeaderId,
              average: "-",
              stdDev: "-",
            }
          );
        })
    )
  );

  return {
    loading: false,
    data: sortedData,
    statsData: sortedStatsData,
  };
};
