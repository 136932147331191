import { useState } from "react";

export type ItemToResize = { itemId: string; scrollHeight: number };

export const useBottomWidgetResizeData = ({
  isEditMode,
}: {
  isEditMode: boolean;
}) => {
  const [itemsToResize, setItemsToResize] = useState<ItemToResize[]>([]);
  const [itemsToHideInViewMode, setItemsToHideInViewMode] = useState<string[]>(
    []
  );

  const onSetItemToResize = (newItemToResize: ItemToResize) => {
    const oldResizeItems = itemsToResize.filter(
      (item) => item.itemId !== newItemToResize.itemId
    );

    setItemsToResize([...oldResizeItems, newItemToResize]);
  };

  const onSetItemsToHideInViewMode = (itemsToHidden: string[]) =>
    setItemsToHideInViewMode((old) => [...old, ...itemsToHidden]);

  const includeInViewModeFilter = (itemId: string) =>
    isEditMode ? true : !itemsToHideInViewMode.includes(itemId);

  return {
    itemsToResize,
    itemsToHideInViewMode,
    onSetItemToResize,
    onSetItemsToHideInViewMode,
    includeInViewModeFilter,
    resetItemsToResize: () => setItemsToResize([]),
  };
};
