import React, { CSSProperties, useRef } from "react";
import {
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";
import { Calendar } from "react-native-calendars";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { CalendarIcon, ChevronRightIcon } from "../Icons";
import StyledText from "../StyledText";

import { InputError } from "./InputError";
import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

type Props = UseControllerProps & {
  name: string;
  defaultValue?: string;
  label: string;
  dateFormat: string;
  styledForRight?: number;
  fromDate?: Dayjs;
  spacing?: {
    marginBottom?: number;
  };
  hideCalendarIcon?: boolean;
  hideArrowIcon?: boolean;
  onCalendarToggle?: (show: boolean) => void;
  containerStyle?: CSSProperties;
};

const CalendarButton = styled(BasicButton)`
  width: 100%;
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  height: 45px;
  padding: 0 16px 0 17px;
  justify-content: space-between;
`;

const StyledDateText = styled(StyledText)<{ noMargin?: boolean }>`
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  margin-left: ${({ noMargin }) => (noMargin ? 0 : 12)}px;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-right: 15px;
`;

// We need this otherwise calendar cannot parse date
const SELECTED_DATE_FORMAT = "YYYY-MM-DD";

export function ControlledCalendar({
  label,
  name,
  rules,
  defaultValue,
  dateFormat,
  styledForRight = 0,
  fromDate,
  spacing,
  hideCalendarIcon,
  hideArrowIcon,
  onCalendarToggle,
  containerStyle,
}: Props) {
  const formContext = useFormContext();
  const { formState } = formContext || {};
  const hasError = Boolean(formState?.errors[name]);

  const closeCalendarRef = useRef<() => void>();

  const {
    field: { value, onChange },
  } = useController({ defaultValue, name, rules });

  const errorMessage = hasError && `${formState?.errors[name]?.message}`;
  const activeDateValue = (dayjs(value) ?? dayjs()).format(
    SELECTED_DATE_FORMAT
  );

  return (
    <StyledInputWrapper
      zIndexValue="6"
      marginBottom={spacing?.marginBottom}
      style={containerStyle}
    >
      <LabelText>{label.toUpperCase()}</LabelText>
      <Dropdown
        dropdownContainerStyle={{ right: styledForRight }}
        onClickOutside={() => onCalendarToggle?.(false)}
        component={({ toggle, show }) => {
          closeCalendarRef.current = toggle;

          return (
            <CalendarButton
              onClick={() => {
                toggle();
                onCalendarToggle?.(!show);
              }}
            >
              <ValueWrapper>
                {!hideCalendarIcon ? (
                  <CalendarIcon color={COLOR_TEXT_TABLE_LABEL} />
                ) : null}
                <StyledDateText noMargin={hideCalendarIcon}>
                  {value && dayjs(value).format(dateFormat)}
                </StyledDateText>
              </ValueWrapper>
              {!hideArrowIcon ? <ChevronRightIcon direction="bottom" /> : null}
            </CalendarButton>
          );
        }}
      >
        <Calendar
          firstDay={1}
          value={value}
          initialDate={activeDateValue}
          fromDate={fromDate}
          markedDates={{
            [activeDateValue]: {
              selected: true,
              selectedColor: COLOR_BLUE,
            },
          }}
          onDayPress={({ timestamp }) => {
            timestamp && onChange(dayjs(timestamp));
            closeCalendarRef?.current?.();
            onCalendarToggle?.(false);
          }}
          theme={{
            // @ts-ignore
            "stylesheet.calendar.header": {
              dayHeader: {
                fontSize: 8,
                fontWeight: 600,
                textTransform: "uppercase",
                color: "#8A939B",
                paddingBottom: 15,
              },
              monthText: {
                margin: 0,
              },
            },
            weekVerticalMargin: 2.5,
            borderRadius: 30,
          }}
          style={{
            paddingBottom: 5,
            width: 245,
          }}
          renderArrow={(direction) => (
            <ChevronRightIcon
              direction={direction === "left" ? "left" : "right"}
              style={{ cursor: "pointer" }}
            />
          )}
        />
      </Dropdown>
      <InputError errorMessage={errorMessage} />
    </StyledInputWrapper>
  );
}
