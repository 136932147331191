import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import _debounce from "lodash/debounce";
import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_PRIMARY_BLUE,
  COLOR_RED,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import {
  useRegisterQuestionnaireTrainingLoadMutation,
  useDeleteQuestionnaireTrainingLoadMutation,
  CalendarDocument,
  AgendaDocument,
  StepQueryDocument,
} from "../../graphql";
import { TimeInput } from "../Athlete/Form/TimeInput";
import { VerticalSlider } from "../Athlete/Form/VerticalSlider";

import { TrainingLoadItemProps } from "./TrainingLoadItem";

export const NameText = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

export const ErrorText = styled(NameText)`
  color: ${COLOR_RED};
  marign-top: 5px;
`;

export const ItemButton = styled.div`
  background: ${COLOR_PRIMARY_BLUE};
  border-radius: 6px;
  text-align: center;
  margin: 15px 0;
  padding: 10px 60px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const DurationText = styled(NameText)`
  margin-top: 5px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const DurationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const RemoveButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  background-color: ${COLOR_RED};

  &:hover {
    cursor: pointer;
  }
`;

const AddButton = styled(RemoveButton)`
  background-color: ${COLOR_GREEN};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 0 0 40px 0;
`;

interface TrainingLoadRegistrationProps extends TrainingLoadItemProps {
  onAddPress: () => void;
  registration: any;
  index: number;
}

const DEFAULT_LEVEL_COUNT = 10;

export const TrainingLoadRegistration = ({
  item,
  questionnaireId,
  questionnaire,
  registration,
  index,
  onAddPress,
  onRemovePress,
}: TrainingLoadRegistrationProps) => {
  const [isRemoved, setIsRemoved] = useState(false);
  const [showMissingMinutesWarning, setShowMissingMinutesWarning] =
    useState(false);
  const [savedIntensity, setSavedIntensity] = useState(
    registration?.intensity || 0
  );
  const [durationMinutes, setDurationMinutes] = useState(
    registration?.minutes || questionnaire.defaultDurationMinutes || ""
  );

  const { language, timezone, sessionId } = useUserContext();
  const { t } = useTranslation();

  const [register, { error }] = useRegisterQuestionnaireTrainingLoadMutation();
  const [remove] = useDeleteQuestionnaireTrainingLoadMutation();

  const intensityItems = questionnaire.intensityItems?.map((i) => i).reverse();
  const levelCount = intensityItems.length || DEFAULT_LEVEL_COUNT;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTrainingLoadChange = useCallback(
    _debounce(async (minutes, intensity) => {
      if (minutes > 0 && item?.tlTemplate?.templateGuid) {
        await register({
          variables: {
            input: {
              id: registration.id,
              templateId: item.tlTemplate.templateGuid,
              questionnaireId,
              minutes,
              intensity,
            },
            sessionId,
            timezone,
            language,
          },
          refetchQueries: () => [CalendarDocument, AgendaDocument],
        });
        setShowMissingMinutesWarning(false);
      } else {
        setShowMissingMinutesWarning(true);
      }
    }, 1000),
    []
  );

  const onDurationChange = (minutes: number) => {
    setDurationMinutes(Math.round(minutes));
    onTrainingLoadChange(Math.round(minutes), savedIntensity);
  };

  const handleRemovePress = async () => {
    await remove({
      variables: {
        id: registration.id,
        sessionId,
        timezone,
        language,
      },
      refetchQueries: () => [
        CalendarDocument,
        AgendaDocument,
        StepQueryDocument,
      ],
    });

    setSavedIntensity(0);
    setDurationMinutes(0);
    setIsRemoved(true);

    if (onRemovePress && typeof onRemovePress === "function") {
      onRemovePress();
    }
  };

  const onIntensityChange = (intensity) => {
    setSavedIntensity(intensity);
    onTrainingLoadChange(durationMinutes, intensity);
  };

  const errorMessage = (error?.graphQLErrors?.[0] as any)?.exception
    ?.detailMessage;

  if (isRemoved) {
    return item.registrations?.length === 0 && index === 0 ? (
      <ItemButton onClick={onAddPress}>{item?.tlTemplate?.name}</ItemButton>
    ) : null;
  }

  return (
    <Wrapper>
      <div>
        <div>
          <div>
            <NameText>{item?.tlTemplate?.name}</NameText>
            {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
            <DurationText>{t("duration")}</DurationText>
          </div>

          <DurationWrapper>
            <TimeInput
              onTimeChange={({ hours, minutes }) =>
                onDurationChange(hours * 60 + minutes)
              }
              hoursValue={Math.floor(Number(durationMinutes) / 60)}
              minutesValue={Number(durationMinutes) % 60}
            />
            <ButtonsWrapper>
              <RemoveButton onClick={handleRemovePress}>-</RemoveButton>
              <AddButton onClick={onAddPress}>+</AddButton>
            </ButtonsWrapper>
          </DurationWrapper>

          {showMissingMinutesWarning && (
            <ErrorText>{t("pleaseEnterDuration")}</ErrorText>
          )}
        </div>
      </div>

      <VerticalSlider
        intensityItems={intensityItems}
        value={savedIntensity}
        onChange={onIntensityChange}
        difficulty="dynamic"
        max={levelCount - 1}
      />
    </Wrapper>
  );
};
