import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT, COLOR_WHITE } from "../colors";

import { BasicButton } from "./Button";
import StyledText from "./StyledText";

interface IStyledSwitch {
  label: string;
  value: boolean;
  onValueChange: (value: boolean) => void;
  primary?: boolean;
  disabled?: boolean;
}

const SwitchText = styled(StyledText)`
  display: flex;
  align-items: center;
  margin-right: 13px;
  font-size: 12px;
  line-height: 15px;
  font-weight: bold;
  color: ${COLOR_TEXT_DEFAULT};
`;

const PrimarySwitchText = styled(SwitchText)`
  font-size: 14px;
  line-height: 17px;
  margin-left: 15px;
`;

const SwitchWrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  box-sizing: content-box;
  min-width: 25px;
  width: 25px;
  height: 15px;
  background: ${({ isActive }) =>
    !isActive ? "rgba(138, 147, 155, 0.05)" : "#76D672"};
  border: 1px solid
    ${({ isActive }) => (!isActive ? "rgba(177, 183, 188, 0.5)" : "#76D672")};
  border-radius: 14px;
  transition: all 0.1s linear;
  user-select: none;
`;

const ToggleCircle = styled.span<{ isActive?: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 14px;
  box-sizing: border-box;
  background: ${COLOR_WHITE};
  border: 1px solid rgba(100, 112, 122, 0.1);
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: ${({ isActive }) => (!isActive ? "0" : "10px")};
  user-select: none;
`;

const Wrapper = styled(BasicButton)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StyledSwitch = ({
  value,
  primary,
  label,
  onValueChange,
  disabled,
}: IStyledSwitch) => {
  return (
    <Wrapper
      onClick={(event) => {
        event.preventDefault();
        onValueChange(!value);
      }}
      disabled={disabled}
    >
      {!primary && <SwitchText>{label}</SwitchText>}

      <SwitchWrapper isActive={value}>
        <ToggleCircle isActive={value} />
      </SwitchWrapper>

      {primary && <PrimarySwitchText>{label}</PrimarySwitchText>}
    </Wrapper>
  );
};
