import { createSearchParams } from "react-router-dom";

export const createSearchParamsWithoutUndefinedValues = (
  params: Record<string, string | undefined>
) => {
  const definedParams = {};

  Object.keys(params).forEach((paramKey) => {
    if (params[paramKey] !== undefined && params[paramKey] !== null) {
      definedParams[paramKey] = params[paramKey];
    }
  });

  return `?${createSearchParams(definedParams)}`;
};
