import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const MessagesIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    isCircleActive,
    ...props
  }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <>
          <rect x="2" y="3" width="17" height="13" rx="3" fill="white" />
          <path d="M15 13H7V21L15 13Z" fill="white" />
        </>
      ) : (
        <>
          <mask id="path-1-inside-1_4713_51306" fill="white">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 3C3.34315 3 2 4.34315 2 6V13C2 14.6569 3.34315 16 5 16H7V21L12 16H16C17.6569 16 19 14.6569 19 13V6C19 4.34315 17.6569 3 16 3H5Z"
            />
          </mask>
          <path
            d="M7 16H8V15H7V16ZM7 21H6V23.4142L7.70711 21.7071L7 21ZM12 16V15H11.5858L11.2929 15.2929L12 16ZM3 6C3 4.89543 3.89543 4 5 4V2C2.79086 2 1 3.79086 1 6H3ZM3 13V6H1V13H3ZM5 15C3.89543 15 3 14.1046 3 13H1C1 15.2091 2.79086 17 5 17V15ZM7 15H5V17H7V15ZM6 16V21H8V16H6ZM7.70711 21.7071L12.7071 16.7071L11.2929 15.2929L6.29289 20.2929L7.70711 21.7071ZM16 15H12V17H16V15ZM18 13C18 14.1046 17.1046 15 16 15V17C18.2091 17 20 15.2091 20 13H18ZM18 6V13H20V6H18ZM16 4C17.1046 4 18 4.89543 18 6H20C20 3.79086 18.2091 2 16 2V4ZM5 4H16V2H5V4Z"
            fill="white"
            mask="url(#path-1-inside-1_4713_51306)"
          />
        </>
      )}
      {isCircleActive ? (
        <circle cx="17.5" cy="4.5" r="3.5" fill="#FF6562" />
      ) : null}
    </svg>
  )
);
