import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Field } from "formik";

import { AggregateFunction } from "../../graphql";
import { requiredValidation } from "../../utils/form";
import { IconButton } from "../IconButton";
import { BinIcon, ReorderIcon } from "../Icons";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { SelectField } from "../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../styled";
import { DragDropContainer } from "../Wrappers/DragDropContainer";

import { IconButtonWrapper, ReorderImageWrapper } from "./styled";

interface ColumnsSectionProps {
  columns: AggregateFunction[];
  aggregations: AggregateFunction[];
}

const initialColumnValue = "Average";

export const ColumnsSection = React.memo(
  ({ columns, aggregations }: ColumnsSectionProps) => {
    const { t } = useTranslation();

    const aggregationOptions = useMemo(
      () =>
        aggregations.map((aggregation) => ({
          label: t(`aggregation${aggregation}`, { defaultValue: aggregation }),
          value: aggregation,
        })),
      [aggregations, t]
    );

    return (
      <Section>
        <SectionTitle title={t("columns")} />
        <DragDropContainer
          fieldArrayName="columns"
          buttonText={t("addColumn")}
          defaultNewEntity={initialColumnValue}
          items={columns.map((_, index) => ({ remove, dragHandleProps }) => (
            <DraggableLineWrapper>
              <ReorderImageWrapper {...dragHandleProps}>
                <ReorderIcon />
              </ReorderImageWrapper>
              <FieldWrapper>
                <Field name={`columns.${index}`} validate={requiredValidation}>
                  {(props) => (
                    <SelectField {...props} options={aggregationOptions} />
                  )}
                </Field>
              </FieldWrapper>

              {columns.length > 1 && (
                <IconButtonWrapper>
                  <IconButton
                    icon={<BinIcon />}
                    onClick={() => remove(index)}
                  />
                </IconButtonWrapper>
              )}
            </DraggableLineWrapper>
          ))}
        />
      </Section>
    );
  }
);
