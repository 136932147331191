import React from "react";

import dayjs from "dayjs";
import styled from "styled-components";

import { usePracticeContext } from "../../../contexts/practice/PracticeProvider";

import { DroppableArea } from "./DroppableArea";
import { SpecificPracticeLine } from "./SpecificPracticeLine";

type SplitItemProps = {
  practiceLine;
  index: number;
};

const SplitListWrapper = styled.div`
  display: flex;
`;

const SplitItemWrapper = styled.div<{ numberOfLines?: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: ${({ numberOfLines }) => (numberOfLines ? 100 / numberOfLines : 100)}%;
  align-self: stretch;
`;

export function SplitItem({ practiceLine, index }: SplitItemProps) {
  const { practiceSession, selectedSlot } = usePracticeContext();
  const numberOfLines = practiceLine?.subPractices?.length;
  const startDate = practiceSession?.start || selectedSlot?.start;

  let localEndTime = dayjs(startDate);

  return (
    <SplitListWrapper>
      {practiceLine?.subPractices?.map((subPractice) => (
        <SplitItemWrapper key={subPractice.id} numberOfLines={numberOfLines}>
          <SpecificPracticeLine
            key={subPractice.id}
            splitAreaId={subPractice.id}
            practiceLine={subPractice}
            index={index}
          />
          {subPractice?.practiceLines?.map((practiceLine, index: number) => {
            const duration =
              practiceLine.localDuration || practiceLine?.minutes;
            const durationToAdd = dayjs.duration(duration, "minute");

            localEndTime = localEndTime.add(durationToAdd);
            return (
              <SpecificPracticeLine
                key={practiceLine.id}
                splitItemId={practiceLine.id}
                splitAreaId={subPractice.id}
                practiceLine={{
                  ...practiceLine,
                  endTime: localEndTime.format("HH:mm"),
                }}
                index={index}
              />
            );
          })}
          <DroppableArea id={subPractice.id} />
        </SplitItemWrapper>
      ))}
    </SplitListWrapper>
  );
}
