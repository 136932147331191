import { CSSProperties } from "react";

import { AggregateFunction } from "../../graphql";
import { roundToTwo } from "../../utils/number";
import { isHighPrecisionNumberInCanada } from "../Report/utils/isHighPrecisionNumberInCanada";
import { COMPARISON_AGGREGATION_LABEL } from "../ReportCreateTable/hooks/useGroupStatsData/helpers/constants";
import { Comparator, IRule } from "../ReportCreateTable/models";
import { ANY_VALUE_ID } from "../ReportCreateTable/Rules/useConditionsData";

const isTextValue = (value: string) =>
  typeof value === "string" && isNaN(parseFloat(value));

const isTimeFormat = (value: string) => {
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return timeRegex.test(value);
};

const getValueFromTimeFormat = (value: string): number => {
  const parts = value.split(":");

  const number = parseInt(parts[0], 10);
  const secondNumber = parseInt(parts[1], 10);

  const calculatedSecondNumber = (secondNumber * 100) / 60;

  return parseFloat(number + "." + calculatedSecondNumber.toFixed(2));
};

const getValueFromNoTimeFormat = (value: string): number => {
  return parseFloat(value?.replace(",", "."));
};

export const isValueMatchingRule = (
  value: string,
  rule: Pick<IRule, "comparator">,
  rgb?: string
) => {
  const ruleComparatorValue = rule?.comparator?.value;
  const ruleComparatorValueTo = rule?.comparator?.valueTo;
  const ruleComparatorName = rule?.comparator?.name;

  // Handle text info for OBSERVATIONs
  if (isTextValue(value) && isTextValue(ruleComparatorValue)) {
    switch (ruleComparatorName) {
      case Comparator.EQUAL:
        return value.toLowerCase() === ruleComparatorValue.toLowerCase();
      case Comparator.INCLUDES:
        return value.toLowerCase().includes(ruleComparatorValue.toLowerCase());
      default:
        return false;
    }
  }

  let numericRule;
  let numericToRule;
  let numericValue;

  if (isTimeFormat(value)) {
    numericValue = getValueFromTimeFormat(value);
  } else {
    numericValue = getValueFromNoTimeFormat(value);
  }

  if (isTimeFormat(ruleComparatorValue)) {
    numericRule = getValueFromTimeFormat(ruleComparatorValue);
  } else {
    numericRule = getValueFromNoTimeFormat(ruleComparatorValue);
  }

  if (isTimeFormat(ruleComparatorValueTo)) {
    numericToRule = getValueFromTimeFormat(ruleComparatorValueTo);
  } else {
    numericToRule = getValueFromNoTimeFormat(ruleComparatorValueTo);
  }

  switch (rule.comparator.name) {
    case Comparator.EQUAL:
      return numericValue === numericRule;
    case Comparator.GREATER_THAN:
      return numericValue > numericRule;
    case Comparator.LESS_THAN:
      return numericValue < numericRule;
    case Comparator.BETWEEN:
      return numericValue >= numericRule && numericValue <= numericToRule;
    case Comparator.COLOR_RANGE:
      return !!value && !!rgb;
    default:
      return false;
  }
};

const buildStyleObject = ({
  conditionStyle,
}: Pick<IRule, "conditionStyle">): CSSProperties => {
  return {
    [conditionStyle.name]: conditionStyle.value,
  };
};

export function getRulesStyle(
  item: {
    measurementTemplateId: string;
    attributeTemplateId: string;
    aggregation: AggregateFunction;
    value: string;
    rgb?: string;
    isInfo?: boolean;
  },
  rules: IRule[]
) {
  return rules
    .filter(
      (rule) =>
        (rule.measurementTemplateId === ANY_VALUE_ID ||
          (rule.measurementTemplateId === item.measurementTemplateId &&
            (item.isInfo ||
              rule.attributeTemplateId === item.attributeTemplateId) &&
            rule.aggregation === item.aggregation)) &&
        isValueMatchingRule(
          item.value,
          {
            comparator: rule.comparator,
          },
          item.rgb
        )
    )
    .map((rule) =>
      buildStyleObject(
        rule.comparator.name === Comparator.COLOR_RANGE
          ? {
              ...rule,
              conditionStyle: { ...rule.conditionStyle, value: item.rgb },
            }
          : rule
      )
    )
    .reduce((acc, css) => ({ ...acc, ...css }), {} as CSSProperties);
}

export type SortByConfig = {
  col: AggregateFunction;
  order: number;
};

export function getValueToDisplay(
  showValuesAsText: boolean,
  value: {
    value?: string | number;
    txt?: string;
    tifn?: boolean;
    aggregation?: string;
    date?: string;
  }
) {
  if (
    value.aggregation === COMPARISON_AGGREGATION_LABEL &&
    typeof value.value === "number"
  ) {
    return value.value === 0
      ? value.value
      : value.value > 0
        ? `+${value.value}`
        : value.value;
  }

  if (value.date) {
    return value.date;
  }

  if (
    ((showValuesAsText || value.tifn) && value.txt) ||
    (!value.value && value.value !== 0 && value.txt)
  ) {
    return value.txt;
  }

  if (!value.value && value.value !== 0) {
    return "-";
  }

  const valueAsNumber = Number(value.value);

  return isNaN(valueAsNumber)
    ? value.value
    : isHighPrecisionNumberInCanada(valueAsNumber)
      ? valueAsNumber
      : roundToTwo(valueAsNumber);
}
