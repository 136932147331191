import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../../colors";
import { useDebounceHandler } from "../../../hooks/useDebounceHandler";
import { CrossFilledIcon, SearchIcon } from "../../Icons";
import { StyledTextInput } from "../../StyledTextInput";

type SideBarSearchInputProps = {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  onClearPress: () => void;
  smaller?: boolean;
};

const SearchInputWrapper = styled.div<{ smaller?: boolean }>`
  background: ${COLOR_GRAY};
  border-radius: 6px;
  padding: 10px;
  flex-direction: row;
  display: flex;
  align-items: center;
  width: ${({ smaller }) => (smaller ? 175 : 190)}px;
  margin-left: -10px;
  margin-right: 1px;
`;

const StyledSidebarSearchInput = styled(StyledTextInput)`
  height: unset;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
  margin-right: 18px;
  padding: 0;
  border-radius: 0;
  width: 90%;
`;

const SearchIconWrapper = styled.div`
  padding-left: 2px;
  margin-right: 10px;
  display: flex;
`;

const ResetIconWrapper = styled.div`
  cursor: pointer;
`;

export function SideBarSearchInput({
  searchValue,
  setSearchValue,
  onClearPress,
  smaller,
}: SideBarSearchInputProps) {
  const { inputValue, setInputValue } = useDebounceHandler({
    value: searchValue,
    setValue: setSearchValue,
  });

  return (
    <SearchInputWrapper smaller={smaller}>
      <SearchIconWrapper>
        <SearchIcon width={15} height={15} />
      </SearchIconWrapper>
      <StyledSidebarSearchInput
        autoFocus
        value={inputValue}
        onChangeText={setInputValue}
      />
      <ResetIconWrapper onClick={onClearPress}>
        <CrossFilledIcon style={{ width: 15, height: 15 }} />
      </ResetIconWrapper>
    </SearchInputWrapper>
  );
}
