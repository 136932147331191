import styled, { css } from "styled-components";

export const unbreakableCss = css`
  break-inside: avoid;
  page-break-inside: avoid;
`;

export const PrintUnbreakableDiv = styled.div`
  ${unbreakableCss};
`;
