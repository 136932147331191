import React, { FocusEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputActionMeta } from "react-select";

import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import { useLabelAndLocationQuery } from "../../services/hooks";
import { SessionType } from "../../services/sessions";
import {
  getFieldSelectStyles,
  textStylesOverrides,
} from "../../utils/selectStyle";

import { DropdownIndicator } from "./DropdownIndicator";
import { InputError } from "./InputError";
import { Option } from "./model";
import { ReactSelectInput } from "./ReactSelectInput";
import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

type ControlledTitleSelectProps = UseControllerProps & {
  name: string;
  label: string;
  isLabel?: boolean;
  placeholder?: string;
  sessionType: SessionType;
  onOpen?: (isOpen?: boolean) => void;
  spacing?: {
    marginBottom?: number;
  };
};

const SelectContainer = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  width: 100%;
`;

export function ControlledTitleSelect({
  name,
  label,
  rules,
  sessionType,
  placeholder = "",
  isLabel = false,
  spacing,
  onOpen,
}: ControlledTitleSelectProps) {
  const user = useUserContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<Option | null>(null);
  const [inputValue, setInputValue] = useState("");
  const selectorRef = useRef(null);

  const formContext = useFormContext();
  const { formState } = formContext || {};

  const hasError = Boolean(formState?.errors[name]);
  const errorMessage = hasError && `${formState?.errors[name]?.message}`;

  const { data, isLoading } = useLabelAndLocationQuery({
    session: user,
    isLabel,
    sessionType,
  });

  const onInputChange = (inputValue: string, { action }: InputActionMeta) => {
    if (action === "menu-close") {
      setIsMenuOpen(false);
    }

    if (action === "input-change") {
      setInputValue(inputValue);
    }
  };

  const options = useMemo(() => {
    if (!isLoading && data?.length) {
      return data?.map((item): Option => {
        return {
          value: item,
          label: item,
        };
      });
    }
  }, [data, isLoading]);

  const {
    field: { value: savedFieldValue, onChange },
  } = useController({ rules, name });

  const handleChange = (item: Option) => {
    setSelectedValue(item);
    setInputValue(item?.label);
    onChange(item.value);
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    const inputValue = event.target.value.trim();

    if (inputValue) {
      onChange(inputValue);
    }
  };

  const handleFocus = () => {
    if (selectedValue) {
      const inputValueEnd = inputValue.length;
      selectorRef?.current?.inputRef?.setSelectionRange(
        inputValueEnd,
        inputValueEnd
      );
    }
  };

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
    onOpen?.(!isMenuOpen);
  };

  const selectStyles = useMemo(
    () => getFieldSelectStyles({ ...textStylesOverrides }),
    []
  );

  useEffect(() => {
    if (savedFieldValue) {
      setInputValue(savedFieldValue);
    }
  }, [savedFieldValue]);

  return (
    <StyledInputWrapper zIndexValue="8" marginBottom={spacing?.marginBottom}>
      <LabelText>{label.toUpperCase()}</LabelText>
      <SelectContainer>
        <CreatableSelect
          ref={selectorRef}
          styles={selectStyles}
          placeholder={placeholder}
          options={options}
          value={selectedValue}
          onInputChange={onInputChange}
          inputValue={inputValue}
          onChange={handleChange}
          menuIsOpen={isMenuOpen}
          onBlur={handleOnBlur}
          onFocus={handleFocus}
          controlShouldRenderValue={false}
          components={{
            Input: ReactSelectInput,
            DropdownIndicator: (props) => (
              <DropdownIndicator {...props} onClick={handleClick} />
            ),
          }}
        />
      </SelectContainer>
      <InputError errorMessage={errorMessage} />
    </StyledInputWrapper>
  );
}
