import { useSearchParams, useParams } from "react-router-dom";

import { isRouteParamTrue } from "../utils/route";

export const useWidgetEditorUrlParams = () => {
  const [searchParams] = useSearchParams();
  const { widgetId, reportId } = useParams();

  const parentReportId = searchParams.get("parentReportId");
  const layoutIndex = searchParams.get("layoutIndex");
  const editReportWidget = isRouteParamTrue(
    searchParams.get("editReportWidget")
  );
  const isWidgetTemplate = isRouteParamTrue(
    searchParams.get("isWidgetTemplate")
  );

  return {
    widgetId,
    reportId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
    searchParams,
  };
};
