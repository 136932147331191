import { useRef } from "react";

import { useUserContext } from "../../contexts/User";
import { useGetGroupLazyQuery } from "../../graphql";

export const useGetAthletesForTeam = () => {
  const athletesByGroup = useRef<
    Record<string, { athletesIds: string[]; id: string; name: string }>
  >({});

  const { sessionId, language } = useUserContext();

  const [getGroupQuery] = useGetGroupLazyQuery();

  const getAthletesForTeam = async (teamGuid: string) => {
    const group = await getGroupQuery({
      variables: { id: teamGuid, sessionId, language },
    });

    const athletesIds = group.data?.getGroup?.requiredAthletes.map(
      (athlete) => athlete.id
    );
    athletesByGroup.current[teamGuid] = {
      athletesIds,
      id: group.data?.getGroup?.id,
      name: group.data?.getGroup?.name,
    };

    return athletesByGroup.current[teamGuid];
  };

  return { getAthletesForTeam };
};
