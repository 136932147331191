import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { useTeamsContext } from "../../../contexts/teams";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { SubGroup } from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import { sortList } from "../../../utils/sortList";
import { GroupWithAccessValue } from "../../GroupSelector";
import { SideBar } from "../../SideBar";

import { CollapsibleGroupCard } from "./CollapsibleGroupCard";
import { SectionHeader } from "./SectionHeader";
import { UserList } from "./UserList";

export type TheWho = "athlete" | "coach";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  margin-bottom: 15px;
`;

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  flex: 2;
`;

const TeamGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  padding: 15px 35px 15px 10px;
`;

const CURRENT_GROUP = 1;

export function TeamGroups() {
  const { t } = useTranslation();
  const { language } = useUserContext();
  const modal = useModalContext();
  const navigate = useNavigate();
  const { group: contextGroup, onSetGroup } = useTeamsContext();
  const group = contextGroup as GroupWithAccessValue;

  const subGroups = group?.subGroups;
  const groupCount = subGroups?.length + CURRENT_GROUP || CURRENT_GROUP;

  const { athletes, trainers } = useMemo(
    () => ({
      athletes: sortList(
        group?.athleteAccess?.map((athlete) => ({
          ...athlete.user,
          active: athlete.active,
        })) ??
          group?.requiredAthletes ??
          [],
        language
      ),

      trainers: sortList(
        group?.trainerAccess?.map((trainer) => ({
          ...trainer.user,
          active: trainer.active,
          name: trainer.user?.name ?? "",
        })) ??
          group?.requiredTrainers ??
          [],
        language
      ),
    }),
    [
      group?.athleteAccess,
      group?.requiredAthletes,
      group?.requiredTrainers,
      group?.trainerAccess,
      language,
    ]
  );

  const getAlreadyAddedMembers = (who: TheWho, subGroup?: SubGroup) => {
    if (!subGroup) {
      return who === "coach" ? trainers : athletes;
    } else {
      const subGroupToAdd = subGroups.find((sg) => sg.id === subGroup.id);
      if (subGroupToAdd) {
        if (who === "athlete") {
          return subGroup?.requiredAthletes ?? subGroup?.athleteAccess ?? [];
        } else {
          return subGroup?.requiredTrainers ?? subGroup?.trainerAccess ?? [];
        }
      }
    }
  };

  const onAddToGroupPress = (who: TheWho, groupToAdd, isSubGroup?: boolean) => {
    modal.actions.openModal({
      modal: ModalType.ADD_COACH_OR_ATHLETE_TO_GROUP,
      title: who === "coach" ? t("addCoaches") : t("addAthletes"),
      params: {
        who,
        groupId: groupToAdd.id,
        parentGroup: group,
        ownerId: groupToAdd.owner?.id,
        alreadyAddedMembers: isSubGroup
          ? getAlreadyAddedMembers(who, groupToAdd)
          : getAlreadyAddedMembers(who),
        onSetGroup,
      },
    });
  };

  const onUserPress = (type, id, groupId) =>
    navigate({
      pathname: `/${ROUTING_CONFIG.teamsAthlete}`,
      search: createSearchParamsWithoutUndefinedValues({
        athleteId: id,
        teamGuids: groupId,
        userType: type,
        navigateFrom: `/${ROUTING_CONFIG.teams}?tab=groups`,
      }),
    });

  return (
    <ContentWrapper>
      <SideBarWrapper>
        <SideBar
          label={t("coaches")}
          style={{ maxHeight: 240, width: "auto", minHeight: 45 }}
          count={trainers?.length}
          onAddPress={() => onAddToGroupPress("coach", group)}
        >
          {(searchValue) => (
            <UserList
              emptyText={t("noCoachesFound")}
              data={trainers}
              searchValue={searchValue}
              group={group}
              grayOnHidden
              canHide
              canRemove={true}
              shouldShowOptionalBadge={false}
              canDrag
              onUserPress={onUserPress}
            />
          )}
        </SideBar>
        <SideBar
          label={t("athletes")}
          style={{ width: "auto", marginBottom: 0, minHeight: 45 }}
          count={athletes?.length}
          onAddPress={() => onAddToGroupPress("athlete", group)}
        >
          {(searchValue) => (
            <UserList
              emptyText={t("noAthletesFound")}
              data={athletes}
              searchValue={searchValue}
              group={group}
              subGroups={subGroups}
              grayOnHidden
              canHide
              canRemove={true}
              shouldShowOptionalBadge={false}
              canDrag
              onUserPress={onUserPress}
            />
          )}
        </SideBar>
      </SideBarWrapper>
      <TeamGroupWrapper>
        <SectionHeader sectionLabel={t("groups")} count={groupCount} />
        <CollapsibleGroupCard
          onAddToGroupPress={(who) => onAddToGroupPress(who, group)}
          data={group}
          order={subGroups?.length + 1 || 1}
          onUserPress={onUserPress}
        />
        {subGroups?.length > 0 &&
          sortList([...subGroups], language).map((subGroup, index) => (
            <CollapsibleGroupCard
              key={subGroup?.id}
              order={subGroups?.length - index || 1}
              isSubGroup={true}
              parentGroup={group}
              data={subGroup}
              onAddToGroupPress={(who) =>
                onAddToGroupPress(who, subGroup, true)
              }
              onUserPress={onUserPress}
            />
          ))}
      </TeamGroupWrapper>
    </ContentWrapper>
  );
}
