import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREEN_ACTIVE,
  COLOR_RED_SECONDARY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../../colors";
import { useDefaultDateFormat } from "../../../../hooks/useDateFormat";
import { unbreakableCss } from "../../../PrintUnbreakableElement";

import { AttendaceStatus, AttendanceLogDataItem } from "./model";
import { DataCell } from "./styled";

interface AttendanceLogTableRowProps {
  item: AttendanceLogDataItem;
  backgroundColor?: string;
  rowDividers?: boolean;
  columnDividers?: boolean;
}

const RowWrapper = styled.tr<{ backgroundColor: string }>`
  height: 45px;
  padding: 15px 0;
  background-color: ${(props) => props.backgroundColor};

  ${unbreakableCss};
`;

export const AttendanceLogTableRow = ({
  item,
  backgroundColor,
  ...rest
}: AttendanceLogTableRowProps) => {
  const { t } = useTranslation();
  const { dateFormat } = useDefaultDateFormat();

  const textColor = useMemo(() => {
    switch (item.status) {
      case AttendaceStatus.ATTENDED:
        return COLOR_GREEN_ACTIVE;
      case AttendaceStatus.INJURED:
      case AttendaceStatus.SICK:
        return COLOR_RED_SECONDARY;
      default:
        return COLOR_TEXT_DARK;
    }
  }, [item.status]);

  const statusText = useMemo(() => {
    switch (item.status) {
      case AttendaceStatus.ATTENDED:
        return t("attended");
      case AttendaceStatus.INJURED:
        return t("injured");
      case AttendaceStatus.SICK:
        return t("sick");
      case AttendaceStatus.OTHER:
        return t("other");
      default:
        return t("unknown");
    }
  }, [item.status, t]);

  return (
    <RowWrapper backgroundColor={backgroundColor}>
      <DataCell
        {...rest}
        color={COLOR_TEXT_TABLE_LABEL}
        fontWeight={400}
        fontSize={10}
      >
        {item.time.format(dateFormat).toUpperCase()}
      </DataCell>

      <DataCell {...rest} color={textColor}>
        {item.label}
      </DataCell>

      <DataCell {...rest} color={textColor}>
        {statusText}
      </DataCell>

      <DataCell {...rest} color={textColor}>
        {item.groupName}
      </DataCell>

      <DataCell {...rest} color={COLOR_TEXT_DARK} fontWeight={500}>
        {item.comment}
      </DataCell>
    </RowWrapper>
  );
};
