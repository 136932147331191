import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";

import { CommonAttributesIds } from "../../../constants";
import { useUserContext } from "../../../contexts/User";
import {
  AggregateFunction,
  ReportDocument,
  ReportWidgetTemplateHeader,
  useSaveWidgetTemplateHeaderMutation,
  WidgetCell,
  WidgetTemplateHeaderInput,
} from "../../../graphql";
import { AutoSubmitFormikForm } from "../../AutoSubmitFormikForm";
import { IconButton } from "../../IconButton";
import { RemoveIcon, ReorderIcon } from "../../Icons";
import { ReorderImageWrapper } from "../../ReportCreateTable/styled";
import { DraggableLineWrapper } from "../../styled";
import { DragDropContainer } from "../../Wrappers/DragDropContainer";

import { ProfileInfoSettingsRow } from "./ProfileInfoSettingsRow";
import { SettingsErrorMessage } from "./styled";

export type ProfileInfoSettingsFormValues = {
  cells: WidgetCell[];
};

type ProfileInfoSettingsSectionProps = {
  parentReportId: string;
  headerTemplate?: ReportWidgetTemplateHeader;
  accountId?: string;
};

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 25px 30px;
`;

export function ProfileInfoSettingsSection({
  parentReportId,
  headerTemplate,
  accountId,
}: ProfileInfoSettingsSectionProps) {
  const { sessionId } = useUserContext();
  const { t } = useTranslation();

  const [newCreatedHeaderWidgetId, setNewCreatedHeaderWidgetId] = useState<
    string | undefined
  >(undefined);

  const [saveWidgetTemplateHeader] = useSaveWidgetTemplateHeaderMutation();

  const formik = useFormik<ProfileInfoSettingsFormValues>({
    initialValues: {
      cells: [],
    },
    async onSubmit(values) {
      function validateAndGetWidgetCell(cell?: WidgetCell): WidgetCell | null {
        const { collectionId, itemId, attributeTemplateId, aggregateFunction } =
          cell ?? {};
        if (
          !collectionId ||
          !itemId ||
          !attributeTemplateId ||
          !aggregateFunction
        ) {
          return null;
        }

        return cell;
      }

      const widgetCell = values.cells
        .map(validateAndGetWidgetCell)
        .filter((cell) => cell !== null);

      if (hasSameHeaderCells(headerTemplate ?? {}, widgetCell)) {
        return;
      }

      const {
        id,
        copyOf,
        preferences,
        name = `Athlete header (reportId: ${parentReportId})`,
      } = headerTemplate ?? {};
      const input: WidgetTemplateHeaderInput = {
        name,
        id: id ?? newCreatedHeaderWidgetId,
        reportTemplateId: parentReportId,
        copyOf,
        preferences,
      };

      for (let index = 1; index <= 6; index++) {
        input[`cell${index}`] = widgetCell[index - 1] ?? null;
      }

      //  Update/Create header
      const headerTemplateResponse = await saveWidgetTemplateHeader({
        variables: {
          sessionId,
          accountId,
          input,
        },
        refetchQueries: [ReportDocument],
      });

      setNewCreatedHeaderWidgetId(
        headerTemplateResponse.data?.saveWidgetTemplateHeader?.id
      );
    },
  });

  const { values, setFieldValue, resetForm } = formik;

  useLayoutEffect(() => {
    const headerCells = getHeaderCells(headerTemplate);

    resetForm({
      values: {
        cells: headerCells.length !== 0 ? headerCells : [getCellNewEntity()],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormikProvider value={formik}>
      <Section>
        <DragDropContainer
          fieldArrayName="cells"
          buttonText={t("addField")}
          defaultNewEntity={getCellNewEntity()}
          hideButton={values.cells.length >= 6}
          items={values.cells.map(
            (cell, index) =>
              ({ remove, dragHandleProps }) => (
                <DraggableLineWrapper
                  key={`${cell.collectionId}-${cell.itemId}-${cell.attributeTemplateId}-${index}`}
                >
                  <ReorderImageWrapper {...dragHandleProps}>
                    <ReorderIcon />
                  </ReorderImageWrapper>
                  <ProfileInfoSettingsRow
                    value={cell}
                    accountId={accountId}
                    onChange={(cell) => {
                      setFieldValue(`cells.[${index}]`, {
                        ...(values.cells[index] ?? {}),
                        collectionId: cell?.collectionId ?? "",
                        itemId: cell?.itemId ?? "",
                        attributeTemplateId: cell?.attributeTemplateId ?? "",
                        aggregateFunction:
                          cell?.aggregateFunction ?? AggregateFunction.Latest,
                      });
                    }}
                  />
                  <IconButton
                    icon={<RemoveIcon />}
                    onClick={() => remove(index)}
                  />
                </DraggableLineWrapper>
              )
          )}
        />

        {values.cells.length >= 6 && (
          <SettingsErrorMessage>
            {t("maxCountOfProfileInfoWarning")}
          </SettingsErrorMessage>
        )}
      </Section>
      <AutoSubmitFormikForm isEnables={true} delay={300} />
    </FormikProvider>
  );
}

function getHeaderCells(
  headerTemplate?: ReportWidgetTemplateHeader
): WidgetCell[] {
  return [
    headerTemplate?.cell1,
    headerTemplate?.cell2,
    headerTemplate?.cell3,
    headerTemplate?.cell4,
    headerTemplate?.cell5,
    headerTemplate?.cell6,
  ]
    .filter((w) => !!w)
    .map((w) => ({
      id: w.id,
      collectionId: w.collectionId,
      aggregateFunction: w.aggregateFunctions[0] ?? AggregateFunction.Latest,
      itemId: w.templateId,
      attributeTemplateId:
        w.__typename === "ReportWidgetCellNumerical"
          ? w.attributeTemplate.id
          : CommonAttributesIds.TEXT_ATTRIBUTE_ID,
    }));
}

function isCellsEqual(cell1: WidgetCell, cell2: WidgetCell): boolean {
  return (
    cell1.collectionId === cell2.collectionId &&
    cell1.itemId === cell2.itemId &&
    cell1.attributeTemplateId === cell2.attributeTemplateId &&
    cell1.aggregateFunction === cell2.aggregateFunction
  );
}

function hasSameHeaderCells(
  headerTemplate: ReportWidgetTemplateHeader,
  cells: WidgetCell[]
): boolean {
  const headerCells = getHeaderCells(headerTemplate);

  return (
    headerCells.length === cells.length &&
    headerCells.every((cell, index) => isCellsEqual(cell, cells[index]))
  );
}

function getCellNewEntity(): WidgetCell {
  return {
    collectionId: "",
    itemId: "",
    attributeTemplateId: "",
    aggregateFunction: AggregateFunction.Latest,
  };
}
