import React from "react";

import chroma from "chroma-js";
import styled from "styled-components";

import { ValueCellText } from "../../ReportTableWidget/styled";
import { getValueToDisplay } from "../../ReportTableWidget/utils";
import { getColoringConfig } from "../../SharedWidgetEditorSections/utils/getColoringConfig";
import {
  AthleteTablePeriodizationWidget,
  DataRow,
  HeaderLikeLabel,
} from "../../TablePeriodization/Widget";

type AthleteTablePeriodizationRowProps = Pick<
  AthleteTablePeriodizationWidget,
  "coloringConfig" | "columnHeaders" | "showValuesAsText"
> & {
  periodData: {
    label: string;
    data: {
      id: string;
      value: string | number;
      txt?: string;
      min?: number;
      max?: number;
      unitName?: string;
      time?: number;
    }[][];
  };
};

const DataRowColumn = styled.div`
  height: 45px;
`;

const MultiValueTd = styled.td`
  padding: 0;
  vertical-align: text-top;
`;

const StyledValueCellText = styled(ValueCellText)`
  padding: 0 8px;
  white-space: nowrap;
`;

export const AthleteTablePeriodizationRow = ({
  periodData,
  coloringConfig,
  showValuesAsText,
}: AthleteTablePeriodizationRowProps) => {
  return (
    <DataRow>
      <MultiValueTd key={periodData.label}>
        <HeaderLikeLabel>{periodData.label}</HeaderLikeLabel>
      </MultiValueTd>
      {periodData.data.map((dataItem, index) => {
        if (dataItem.length === 0) {
          return null;
        }

        const coloring = coloringConfig?.find(
          (item) => item.id === dataItem[0].id
        );
        const hasColoring = Boolean(coloring);

        const scale = hasColoring
          ? chroma
              .scale(getColoringConfig(coloring.coloring))
              .domain([dataItem[0].min, dataItem[0].max])
          : undefined;

        return (
          <MultiValueTd key={`${dataItem.length}-${index}`}>
            {dataItem.map((dataValueItem, index) => (
              <DataRowColumn
                key={`${dataValueItem.id} ${index}`}
                style={{
                  backgroundColor:
                    dataValueItem.txt === "-" || !hasColoring
                      ? "transparent"
                      : scale(dataValueItem.value).hex(),
                }}
              >
                <StyledValueCellText>
                  {getValueToDisplay(showValuesAsText, dataValueItem)}
                  {dataValueItem.txt ? "" : dataValueItem.unitName ?? ""}
                </StyledValueCellText>
              </DataRowColumn>
            ))}
          </MultiValueTd>
        );
      })}
    </DataRow>
  );
};
