import React, {
  CSSProperties,
  FunctionComponent,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLUE,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";
import { useHandleClickOutside } from "../hooks";

import { BasicButton } from "./Button";
import Hoverable from "./Hoverable";
import { ChevronRightIcon } from "./Icons";
import StyledText from "./StyledText";

interface DropdownProps {
  text?: string;
  dropdownMainContainerStyle?: CSSProperties;
  dropdownContainerStyle?: CSSProperties;
  dropdownContentStyle?: CSSProperties;
  component?: FunctionComponent<React.PropsWithChildren<ComponentProps>>;
  onClickOutside?(): void;
  children?: ((props) => ReactNode) | ReactNode;
}

type ComponentProps = {
  hovered: boolean;
  show: boolean;
  toggle(): void;
};

const Wrapper = styled.div`
  display: block;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
`;

const ChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  padding: 15px 20px;
  position: relative;
  top: 10px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
`;

const DropdownTextWrapper = styled.div<{ hovered: boolean }>`
  align-items: center;
  background-color: ${({ hovered }) => (hovered ? COLOR_BLUE : COLOR_WHITE)};
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 11px 11px 11px 15px;
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  border-radius: 6px;
`;

const Dropdown: FunctionComponent<DropdownProps> = ({
  component,
  dropdownMainContainerStyle,
  dropdownContainerStyle,
  dropdownContentStyle,
  text,
  onClickOutside,
  children,
}) => {
  const { isMobile } = useTheme();

  const [show, setShow] = useState(false);
  const drowdownRef = useRef<HTMLDivElement>();

  const childrenWithProps =
    typeof children === "function"
      ? children({
          show,
          toggle: () => setShow((old) => !old),
        })
      : React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              show,
              toggle: setShow,
            } as any);
          }
        });

  const onClickOutsideRef = useRef(onClickOutside);
  onClickOutsideRef.current = onClickOutside;

  const handleClickOutside = useCallback(() => {
    onClickOutsideRef.current?.();
    setShow(false);
  }, []);

  useHandleClickOutside(
    drowdownRef,
    handleClickOutside,
    true,
    isMobile ? null : "uniqueRootView"
  );
  return (
    <div
      // We need to handle click outside only if dropdown is opened. Otherwise, it prevents other elements from receiving clicks
      ref={show ? drowdownRef : null}
      style={{ ...dropdownMainContainerStyle, zIndex: show ? 1 : 0 }}
    >
      {!text ? (
        <Hoverable>
          {(hovered) =>
            component({ hovered, show, toggle: () => setShow((show) => !show) })
          }
        </Hoverable>
      ) : (
        <Hoverable>
          {(hovered) => (
            <BasicButton onClick={() => setShow((show) => !show)}>
              <DropdownTextWrapper hovered={hovered}>
                <StyledText
                  fontSize={12}
                  color={hovered ? COLOR_WHITE : COLOR_TEXT_DEFAULT}
                  fontWeight={hovered ? "bold" : 500}
                >
                  {text}
                </StyledText>
                <ChevronRightIcon
                  style={{ marginLeft: 10 }}
                  tintColor={COLOR_TEXT_DARK}
                  direction="bottom"
                />
              </DropdownTextWrapper>
            </BasicButton>
          )}
        </Hoverable>
      )}
      {show && (
        <Wrapper style={dropdownContainerStyle}>
          <ChildrenWrapper style={dropdownContentStyle}>
            {childrenWithProps}
          </ChildrenWrapper>
        </Wrapper>
      )}
    </div>
  );
};

export default Dropdown;
