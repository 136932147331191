import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EventType } from "../../components/Filters/model";
import { CreateOrUpdateFormState } from "../../components/Modals/CreateEventOrGameModal";
import { createInfoFormDefaultValues } from "../../components/WorkoutEditor/utils";
import {
  Cell,
  FolderDocumentsQuery,
  FolderTreeDocumentsQuery,
  Set,
  TreeType,
  useAppendSetMutation,
  useCreateExerciseReturnDataMutation,
  useUpdateAttributeMutation,
  useWorkoutQuery,
  WorkoutQuery,
} from "../../graphql";
import { useCreateOrUpdateSession } from "../../hooks/sessions/useCreateOrUpdateSession";
import { useNavigateBack } from "../../hooks/useNavigateBack";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { useNotificationsContext } from "../notifications";
import { useUserContext } from "../User";

export type DocumentPreview =
  FolderDocumentsQuery["user"]["collection"]["tree"]["documents"]["edges"][0]["node"] & {
    key?: string;
  };

export type FormValue = {
  key: string;
  minutes: number;
  time: string;
  intensity: string;
};

export type FilteredDocuments =
  FolderTreeDocumentsQuery["user"]["collection"]["tree"]["documents"]["edges"];

export type WorkoutEditorContextState = {
  workoutData: WorkoutQuery;
  selectedDocuments: DocumentPreview[];
  addDocument: (document: DocumentPreview) => void;
  removeDocument: (document: DocumentPreview) => void;
  sessionGuid: string | undefined;
  formMethods: UseFormReturn<CreateOrUpdateFormState, any, undefined>;
  onMove: (direction: "up" | "down", document: DocumentPreview) => void;
  changeFormValue: (formValue: FormValue) => void;
  timeFormValues: FormValue[];
  sets: Record<string, Set[]>;
  onAddSet: (documentId: string) => void;
  onRemoveSet: (documentId: string) => void;
  updateCell: (data: {
    documentKey: string;
    setKey: string;
    cell: Cell;
    value: string;
  }) => void;
  onSubmit: (data: CreateOrUpdateFormState) => void;
  setFilteredDocuments?: (documents: FilteredDocuments) => void;
  filteredDocuments?: FilteredDocuments;
  searchValue: string;
  setSearchValue: (value: string) => void;
  currentFolderTreeStructure: FolderTreeDocumentsQuery;
  setCurrentFolderTreeStructure: (data: FolderTreeDocumentsQuery) => void;
  selectedFolder: string | null;
  setSelectedFolder: Dispatch<SetStateAction<string | null>>;
  collectionType: TreeType;
  changeTabOrCollectionType: (collectionType?: TreeType) => void;
  collectionId: string | null;
  setCollectionId: Dispatch<SetStateAction<string | null>>;
  selectedFolderFullId: string;
  setSelectedFolderFullId: Dispatch<SetStateAction<string>>;
};

const WorkoutEditorContext = createContext<WorkoutEditorContextState>(null);

export function WorkoutEditorContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { t } = useTranslation();
  const { showErrorNotification } = useNotificationsContext();
  const [searchParams] = useSearchParams();
  const sessionGuid = searchParams.get("sessionGuid") || null;
  const athleteId = searchParams.get("athleteId") || null;
  const selectedStartDate = searchParams.get("selectedStartDate") || null;

  const [selectedDocuments, setSelectedDocuments] = useState<DocumentPreview[]>(
    []
  );
  const [filteredDocuments, setFilteredDocuments] = useState<
    FolderTreeDocumentsQuery["user"]["collection"]["tree"]["documents"]["edges"]
  >([]);
  const [sets, setSets] = useState<Record<string, Set[]>>({});
  const [createExercise] = useCreateExerciseReturnDataMutation();
  const [appendSetMutation] = useAppendSetMutation();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const [updateAttributeMutation] = useUpdateAttributeMutation();
  const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [currentFolderTreeStructure, setCurrentFolderTreeStructure] =
    useState<FolderTreeDocumentsQuery | null>(null);
  const [collectionType, setCollectionType] = useState(TreeType.Folders);
  const [collectionId, setCollectionId] = useState<string | null>(null);
  const [selectedFolderFullId, setSelectedFolderFullId] = useState<string>("");

  const [timeFormValues, setTimeFormValues] = useState<FormValue[]>([]);

  const { sessionId, timezone, language } = useUserContext();

  const { data: workoutData } = useWorkoutQuery({
    variables: {
      sessionId,
      sessionGuid,
      timezone,
      language,
    },
    skip: !sessionGuid,
  });

  const { createOrUpdateSession } = useCreateOrUpdateSession();

  const formMethods = useForm<CreateOrUpdateFormState>({
    mode: "all",
    defaultValues: createInfoFormDefaultValues(null, selectedStartDate),
  });

  const { reset, handleSubmit, watch, setValue, resetField } = formMethods;

  const onAddSet = useCallback(
    (documentId: string) => {
      setSets((prev) => ({
        ...prev,
        [documentId]: [
          ...prev[documentId],
          {
            id: `${documentId}-set-${Math.round(
              Math.random() * 100
            ).toString()}`,
            __typename: "Set",
            deleted: false,
            cells: [...sets[documentId][0].cells],
          },
        ],
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sets]
  );

  const onRemoveSet = useCallback(
    (documentId: string) => {
      setSets((current) => ({
        ...current,
        [documentId]: [...(current[documentId] ?? [])].slice(0, -1),
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sets]
  );

  const updateCell = useCallback(
    ({
      documentKey,
      cell,
      value,
      setKey,
    }: {
      documentKey: string;
      setKey: string;
      cell: Cell;
      value: string;
    }) => {
      setSets((current) => {
        const index =
          current[documentKey]?.findIndex((set) => {
            return set.id === setKey;
          }) ?? -1;

        if (index !== -1) {
          const newSets = [...current[documentKey]];
          const newSet = { ...newSets[index] };
          const cellIndex = newSet.cells.findIndex(
            (setCell) => setCell.id === cell.id
          );
          if (cellIndex !== -1) {
            const newCells = [...newSet.cells];
            newCells[cellIndex] = {
              ...newCells[cellIndex],
              valuePerformed: value,
            };
            newSet.cells = newCells;
            newSets[index] = newSet;
            return {
              ...current,
              [documentKey]: newSets,
            };
          }
        }
        return current;
      });
    },
    [setSets]
  );

  useEffect(() => {
    if (workoutData && "workout" in workoutData) {
      reset(createInfoFormDefaultValues(workoutData?.workout));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workoutData]);

  const addDocument = (document: DocumentPreview) => {
    setSelectedDocuments((current) => [...current, document]);
    setSets({
      ...sets,
      [document.key]: [
        {
          id: `${document.key}-set-${Math.round(
            Math.random() * 100
          ).toString()}`,
          __typename: "Set",
          deleted: false,
          cells: document?.attributeTemplates?.map((attributeTemplate) => ({
            id: attributeTemplate.id,
            __typename: "Cell",
            valuePrescribed: null,
            valuePerformed: null,
          })),
        },
      ],
    });
  };

  const changeFormValue = useCallback(
    (formValue: FormValue) => {
      if (timeFormValues.length === 0) {
        setTimeFormValues([formValue]);
      } else {
        const index = timeFormValues.findIndex(
          (item) => item.key === formValue.key
        );
        if (index !== -1) {
          const newTimeFormValues = [...timeFormValues];
          newTimeFormValues[index] = formValue;
          setTimeFormValues(newTimeFormValues);
        } else {
          setTimeFormValues((current) => [...current, formValue]);
        }
      }
    },
    [timeFormValues]
  );

  const removeDocument = (document: DocumentPreview) => {
    const identifier = document?.key ? "key" : "id";
    setSelectedDocuments((current) => {
      const index = current.findIndex(
        (doc) => doc?.[identifier] === document?.[identifier]
      );
      if (index !== -1) {
        const newDocuments = [...current];
        newDocuments.splice(index, 1);
        return newDocuments;
      }
      return current;
    });
  };

  const onMove = useCallback(
    (direction: "up" | "down", document: DocumentPreview) => {
      const identifier = document?.key ? "key" : "id";
      if (document?.id) {
        const index = selectedDocuments.findIndex(
          (doc) => doc?.[identifier] === document?.[identifier]
        );
        if (
          index !== -1 &&
          ((direction === "up" && index !== 0) ||
            (direction === "down" && index !== selectedDocuments.length - 1))
        ) {
          const newDocuments = [...selectedDocuments];
          const temp = newDocuments[index];
          if (direction === "up") {
            newDocuments[index] = newDocuments[index - 1];
            newDocuments[index - 1] = temp;
          } else {
            newDocuments[index] = newDocuments[index + 1];
            newDocuments[index + 1] = temp;
          }
          setSelectedDocuments(newDocuments);
        }
      }
    },
    [selectedDocuments]
  );

  const onSubmit: SubmitHandler<CreateOrUpdateFormState> = async ({
    selectedGoogleLocationId,
    ...data
  }) => {
    try {
      if (sessionGuid) {
        await createOrUpdateSession({
          session: workoutData?.workout,
          data,
          selectedGoogleLocationId,
          eventTypename: EventType.WORKOUT,
        });
      } else {
        const res = await createOrUpdateSession({
          data: {
            ...data,
            focusedAthlete: athleteId,
            durationMinutes: Number(data.durationMinutes),
          },
          selectedGoogleLocationId,
          eventTypename: EventType.WORKOUT,
        });

        if (res && selectedDocuments?.length) {
          for await (const [
            documentIndex,
            document,
          ] of selectedDocuments.entries()) {
            const exerciseRes = await createExercise({
              variables: {
                sessionId,
                language,
                timezone,
                input: {
                  templateId: document.id,
                  workoutId: res?.createdId,
                },
              },
              ...(documentIndex === selectedDocuments.length - 1
                ? { refetchQueries: () => ["Workout", "ExerciseSets"] }
                : {}),
            });
            const exerciseId = exerciseRes?.data?.createExercise?.id;
            if (exerciseId) {
              const exerciseSets = sets[document.key];
              const idOfSetToCopy =
                exerciseRes?.data?.createExercise?.sets?.[
                  exerciseRes?.data?.createExercise?.sets.length - 1
                ]?.id;
              if (exerciseSets && idOfSetToCopy) {
                const createdSets = {
                  "1": exerciseRes?.data?.createExercise?.sets[0],
                };
                const resAllAppendSet = await Promise.all(
                  [...Array(exerciseSets.length - 1)].map(() =>
                    appendSetMutation({
                      variables: {
                        sessionId,
                        language,
                        timezone,
                        input: {
                          idOfSetToCopy,
                        },
                      },
                    })
                  )
                );
                resAllAppendSet.forEach((res, index) => {
                  createdSets[index + 2] = res?.data?.appendSet;
                });

                if (Object.keys(createdSets).length === exerciseSets?.length) {
                  for await (const [setIndex, set] of Object.keys(createdSets)
                    .sort((a, b) => {
                      return Number(a) - Number(b);
                    })
                    .map((setKey) => createdSets[setKey])
                    .entries()) {
                    if (set.cells.length) {
                      for await (const [
                        cellIndex,
                        cell,
                      ] of set.cells.entries()) {
                        const cellValue =
                          exerciseSets[setIndex]?.cells?.[cellIndex]
                            ?.valuePerformed;
                        await updateAttributeMutation({
                          variables: {
                            sessionId,
                            language,
                            timezone,
                            input: {
                              id: cell?.id,
                              value: cellValue,
                            },
                          },
                        });
                        await new Promise((resolve) => {
                          setTimeout(resolve, 50);
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      if (sessionGuid) {
        // We need this due to reload of workout data
        navigate(
          `/${ROUTING_CONFIG.session}?sessionGuid=${sessionGuid}&focusedAthleteId=${athleteId}&sessionType=Workout`,
          { replace: true }
        );
      } else {
        navigateBack();
      }
    } catch {
      showErrorNotification(t("formError"));
    }
  };

  const changeTabOrCollectionType = (newCollectionType?: TreeType) => {
    if (newCollectionType) {
      setCollectionType(newCollectionType);
    }
    setSelectedFolderFullId("");
    setSelectedFolder(null);
    setCurrentFolderTreeStructure(null);
  };

  const value: WorkoutEditorContextState = useMemo(
    () => ({
      workoutData,
      addDocument,
      removeDocument,
      selectedDocuments,
      sessionGuid,
      formMethods,
      onMove,
      changeFormValue,
      timeFormValues,
      sets,
      onAddSet,
      onRemoveSet,
      updateCell,
      onSubmit,
      filteredDocuments,
      setFilteredDocuments,
      searchValue,
      setSearchValue,
      currentFolderTreeStructure,
      setCurrentFolderTreeStructure,
      selectedFolder,
      setSelectedFolder,
      changeTabOrCollectionType,
      collectionType,
      collectionId,
      setCollectionId,
      selectedFolderFullId,
      setSelectedFolderFullId,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      workoutData,
      selectedDocuments,
      sessionGuid,
      formMethods,
      onMove,
      changeFormValue,
      timeFormValues,
      sets,
      onAddSet,
      onRemoveSet,
      updateCell,
      onSubmit,
      filteredDocuments,
      setFilteredDocuments,
      searchValue,
      setSearchValue,
      currentFolderTreeStructure,
      setCurrentFolderTreeStructure,
      selectedFolder,
      setSelectedFolder,
      changeTabOrCollectionType,
      collectionType,
      collectionId,
      setCollectionId,
      selectedFolderFullId,
      setSelectedFolderFullId,
    ]
  );

  return (
    <WorkoutEditorContext.Provider value={value}>
      <FormProvider
        handleSubmit={handleSubmit}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        reset={reset}
        {...formMethods}
      >
        {children}
      </FormProvider>
    </WorkoutEditorContext.Provider>
  );
}

export const useWorkoutEditorContext = () => useContext(WorkoutEditorContext);
