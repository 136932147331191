import React from "react";
import { useTranslation } from "react-i18next";

import styled from "@emotion/styled";
import dayjs from "dayjs";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { useDefaultDateFormat } from "../../hooks";
import StyledText from "../StyledText";

export interface IAthleteAdditionalInfo {
  phoneNumber?: string;
  email?: string;
  city?: string;
  birthDay?: string;
}

const AdditionalInfoItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AdditionalInfoWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

export const AthleteAdditionalInfo = ({
  phoneNumber,
  email,
  city,
  birthDay,
}: IAthleteAdditionalInfo) => {
  const { t } = useTranslation();
  const { dateFormat } = useDefaultDateFormat();

  return (
    <AdditionalInfoWrapper>
      <AdditionalInfoItemWrapper>
        <StyledText
          fontSize={10}
          fontWeight="semibold"
          color={COLOR_MEDIUM_GRAY}
        >
          {t("phoneNumber").toUpperCase()}
        </StyledText>
        <StyledText fontSize={14} fontWeight="bold">
          {phoneNumber || ""}
        </StyledText>
      </AdditionalInfoItemWrapper>

      <AdditionalInfoItemWrapper>
        <StyledText
          fontSize={10}
          fontWeight="semibold"
          color={COLOR_MEDIUM_GRAY}
        >
          {t("email").toUpperCase()}
        </StyledText>
        <StyledText fontSize={14} fontWeight="bold">
          {email || ""}
        </StyledText>
      </AdditionalInfoItemWrapper>

      <AdditionalInfoItemWrapper>
        <StyledText
          fontSize={10}
          fontWeight="semibold"
          color={COLOR_MEDIUM_GRAY}
        >
          {t("city").toUpperCase()}
        </StyledText>
        <StyledText fontSize={14} fontWeight="bold">
          {city || ""}
        </StyledText>
      </AdditionalInfoItemWrapper>

      <AdditionalInfoItemWrapper>
        <StyledText
          fontSize={10}
          fontWeight="semibold"
          color={COLOR_MEDIUM_GRAY}
        >
          {t("dateOfBirth").toUpperCase()}
        </StyledText>
        <StyledText fontSize={14} fontWeight="bold">
          {birthDay ? dayjs(birthDay).format(dateFormat) : ""}
        </StyledText>
      </AdditionalInfoItemWrapper>
    </AdditionalInfoWrapper>
  );
};
