import dayjs, { OpUnitType } from "dayjs";

import { GrouppedColumnHeader } from "../../../../../components/TablePeriodization/Widget";
import { ReportWidgetCellNumerical } from "../../../../../graphql";
import { AttendanceInPeriodOfTimeDataItem } from "../../../../../services";
import { roundToTwo } from "../../../../../utils/number";
import { getAttendanceStatisticsDataKey } from "../../../../../utils/reports";
import { getDataKey } from "../../../../../utils/statistics";
import { Period } from "../../model";

import { CalculatedMinAndMaxValues } from "./getSoccerStatsDataItemsForPeriods";

export const getAttendanceStatsDataItems = (
  period: Period,
  grouppedColumnHeaders: GrouppedColumnHeader[],
  athleteAttendanceStats: AttendanceInPeriodOfTimeDataItem[],
  minAndMaxValues?: CalculatedMinAndMaxValues
) => {
  return grouppedColumnHeaders.flatMap((columnHeader) =>
    columnHeader.attributes.flatMap(
      (columnAttribute: ReportWidgetCellNumerical) =>
        columnAttribute.aggregateFunctions
          .flatMap((currentAggregation) => {
            if (!columnHeader.attributes?.length) {
              return null;
            }

            const requestedData = columnHeader
              .attributes[0] as ReportWidgetCellNumerical;
            const requestedDataId = getDataKey(
              requestedData.templateId,
              requestedData.attributeTemplate?.id,
              currentAggregation
            );

            const attendanceStats =
              athleteAttendanceStats?.find((item) => {
                return (
                  dayjs(item.period?._start).isSame(
                    period.start,
                    item.period?._frame.toLowerCase() as OpUnitType
                  ) && dayjs(item.period?._start).isSame(period.start, "year")
                );
              }) || {};

            const currentTemplateId = getAttendanceStatisticsDataKey(
              requestedData.templateId
            );

            const isPercentageValue = currentTemplateId.includes("Perc");
            const value = attendanceStats?.[currentTemplateId];
            const valueToDisplay = !isNaN(Number(value))
              ? roundToTwo(isPercentageValue ? value * 100 : value)
              : "-";

            return value
              ? {
                  id: requestedDataId,
                  value: valueToDisplay,
                  ...(minAndMaxValues?.find(
                    (minMax) => minMax.id === requestedDataId
                  ) ?? { min: 0, max: 0 }),
                }
              : null;
          })
          .filter((value) => Boolean(value))
    )
  );
};
