import gql from "graphql-tag";

export const UnreadMessageCountQuery = gql`
  query unreadMessageCount(
    $sessionId: String!
    $language: String
    $timezone: String
  ) {
    messageOverview(
      sessionId: $sessionId
      timezone: $timezone
      language: $language
    ) {
      unreadAll
    }
  }
`;
