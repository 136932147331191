import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useMobileModalContext } from "../../../contexts/mobileModal/MobileModalProvider";
import { useAthleteProfileTabs } from "../../../hooks";
import { EditAthleteProfileTabs } from "../EditAthleteProfileTabs";

import { ApplyTabsFrom } from "./ApplyTabsFrom";
import {
  CloseTextButton,
  ModalHeaderContainer,
  ModalHeaderContent,
  ModalWrapper,
} from "./styled";

const TabsWrapper = styled.div`
  height: 80vh;
  overflow: scroll;
  position: relative;
  z-index: 1;
`;

export const EditTabsModal = () => {
  const { t } = useTranslation();
  const { hideModal } = useMobileModalContext();

  const { tabsPreference, refetchAthleteProfileTabs } = useAthleteProfileTabs();

  return (
    <ModalWrapper>
      <ModalHeaderContainer>
        <ModalHeaderContent>
          <ApplyTabsFrom />
        </ModalHeaderContent>
        <div onClick={hideModal}>
          <CloseTextButton>{t("close").toUpperCase()}</CloseTextButton>
        </div>
      </ModalHeaderContainer>
      {/* If we encounter any weird issue here check:
      See: https://github.com/atlassian/react-beautiful-dnd/issues/485#issuecomment-385816391 */}
      <TabsWrapper>
        <EditAthleteProfileTabs
          preferences={tabsPreference}
          onClose={refetchAthleteProfileTabs}
          onSave={refetchAthleteProfileTabs}
        />
      </TabsWrapper>
    </ModalWrapper>
  );
};
