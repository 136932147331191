import React from "react";

import styled from "styled-components";

import { useUserContext } from "../../../contexts/User";
import {
  Exercise,
  useDeleteExerciseMutation,
  useUpdateExerciseMutation,
} from "../../../graphql";
import { ArrowIcon } from "../../Icons/ArrowIcon";
import { RemoveIcon } from "../../Icons/RemoveIcon";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  min-width: 115px;
`;

const Button = styled.div`
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:not(:last-child) {
    margin-right: 5px;
  }
`;

interface Props {
  data: Exercise | undefined;
  index: number;
}

export const WorkoutEditModeSection = ({ data, index }: Props) => {
  const [updateExercise] = useUpdateExerciseMutation();
  const [deleteExercise] = useDeleteExerciseMutation();
  const { sessionId, language, timezone } = useUserContext();

  const onArrowPress = (direction: "up" | "down") => {
    if (data) {
      updateExercise({
        variables: {
          sessionId,
          language,
          timezone,
          input: {
            id: data.id,
            idx: direction === "up" ? index - 1 : index + 1,
          },
        },
        refetchQueries: () => ["Workout", "ExerciseSets"],
      });
    }
  };

  const onRemovePress = () => {
    if (data) {
      deleteExercise({
        variables: {
          sessionId,
          language,
          timezone,
          id: data.id,
        },
        refetchQueries: () => ["Workout", "ExerciseSets"],
      });
    }
  };

  return (
    <Container>
      <Button onClick={() => onArrowPress("up")}>
        <ArrowIcon />
      </Button>
      <Button onClick={() => onArrowPress("down")}>
        <ArrowIcon direction="bottom" />
      </Button>
      <Button onClick={onRemovePress}>
        <RemoveIcon />
      </Button>
    </Container>
  );
};
