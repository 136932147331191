import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const MonitoringIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {isActive ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3C1.89543 3 1 3.89543 1 5V16C1 17.1046 1.89543 18 3 18H18C19.1046 18 20 17.1046 20 16V5C20 3.89543 19.1046 3 18 3H3ZM12.9472 10.7236C13.0707 10.4766 12.9706 10.1763 12.7236 10.0528C12.4766 9.92929 12.1763 10.0294 12.0528 10.2764L10.0528 14.2764C9.92929 14.5234 10.0294 14.8237 10.2764 14.9472C10.5234 15.0707 10.8237 14.9706 10.9472 14.7236L12.9472 10.7236ZM10 7.02277C9.44914 7.07306 8.90805 7.20625 8.39524 7.41866C7.72795 7.69506 7.12164 8.10019 6.61091 8.61091C6.10019 9.12163 5.69506 9.72795 5.41866 10.3952C5.20625 10.908 5.07306 11.4491 5.02277 12H6V13H4V12.5V12H4.01926C4.0719 11.3176 4.23208 10.6468 4.49478 10.0126C4.82144 9.22394 5.30023 8.50739 5.90381 7.90381C6.50739 7.30022 7.22394 6.82144 8.01256 6.49478C8.80117 6.16813 9.64641 6 10.5 6C11.3536 6 12.1988 6.16813 12.9874 6.49478C13.7761 6.82144 14.4926 7.30023 15.0962 7.90381C15.6998 8.50739 16.1786 9.22394 16.5052 10.0126C16.7679 10.6468 16.9281 11.3176 16.9807 12H17V12.5V13H15V12H15.9772C15.9269 11.4491 15.7937 10.908 15.5813 10.3952C15.3049 9.72795 14.8998 9.12164 14.3891 8.61091C13.8784 8.10019 13.272 7.69506 12.6048 7.41866C12.092 7.20625 11.5509 7.07306 11 7.02277V8H10V7.02277Z"
          fill="white"
        />
      ) : (
        <>
          <path
            d="M1.5 5C1.5 4.17157 2.17157 3.5 3 3.5H18C18.8284 3.5 19.5 4.17157 19.5 5V16C19.5 16.8284 18.8284 17.5 18 17.5H3C2.17157 17.5 1.5 16.8284 1.5 16V5Z"
            stroke="white"
          />
          <path d="M10.5 14.5L12.5 10.5" stroke="white" strokeLinecap="round" />
          <path
            d="M4.5 12.5C4.5 11.7121 4.65519 10.9319 4.95672 10.2039C5.25825 9.47595 5.70021 8.81451 6.25736 8.25736C6.81451 7.70021 7.47595 7.25825 8.2039 6.95672C8.93185 6.65519 9.71207 6.5 10.5 6.5C11.2879 6.5 12.0681 6.65519 12.7961 6.95672C13.5241 7.25825 14.1855 7.70021 14.7426 8.25736C15.2998 8.81451 15.7417 9.47595 16.0433 10.2039C16.3448 10.9319 16.5 11.7121 16.5 12.5"
            stroke="white"
          />
          <path d="M15 12.5H17" stroke="white" />
          <path d="M4 12.5H6" stroke="white" />
          <path d="M10.5 6.5V8" stroke="white" />
        </>
      )}
    </svg>
  )
);
