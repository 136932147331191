import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { DraggableLineWrapper } from "../styled";
import { StyledSwitch } from "../StyledSwitch";

import { CreateGroupTableFormValue } from "./models";
import { SwitchWrapper } from "./styled";

const LineWrapper = styled(DraggableLineWrapper)`
  justify-content: space-between;
`;

export const SummaryStatsSection = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<CreateGroupTableFormValue>();

  return (
    <Section>
      <SectionTitle title={t("summaryStats")} />
      <LineWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.sumRow}
            onValueChange={(value) => setFieldValue("sumRow", value)}
            label={t("sumRow")}
          />
        </SwitchWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.averageRow}
            onValueChange={(value) => {
              setFieldValue("averageRow", value);

              if (value && values.averageWithoutZeroes) {
                setFieldValue("averageWithoutZeroes", false);
              }
            }}
            label={t("averageRow")}
          />
        </SwitchWrapper>
        <SwitchWrapper>
          <StyledSwitch
            value={values.averageWithoutZeroes}
            onValueChange={(value) => {
              setFieldValue("averageWithoutZeroes", value);

              if (value && values.averageRow) {
                setFieldValue("averageRow", false);
              }
            }}
            label={t("averageWithoutZeroes")}
          />
        </SwitchWrapper>
      </LineWrapper>
    </Section>
  );
};
