import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ThreeDotsIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#0073D9",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <circle
        cx="5.5"
        cy="10.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <circle
        cx="10.5"
        cy="10.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
      <circle
        cx="15.5"
        cy="10.5"
        r="1.5"
        fill={isActive ? "#FFFFFF" : tintColor}
      />
    </svg>
  )
);
