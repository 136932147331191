import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { useNotificationsContext } from "../../contexts/notifications";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { useSendSessionPushNotificationsMutation } from "../../graphql";
import { ButtonColor, GeneralButton } from "../Button/";
import { ControlledCheckbox } from "../HookForm/ControlledCheckbox";
import { ControlledTextArea } from "../HookForm/ControlledTextArea";
import { LabelText } from "../HookForm/StyledInputComponents";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

export type SendManualNotificationFormState = {
  text: string;
  forCoaches: boolean;
  forAthletes: boolean;
  forFamilyMembers: boolean;
};

const focusedAthleteNameRegex = /\(([^)]+)\)/;

const MainContainer = styled.div`
  padding: 20px 30px;
`;

const TitleContainer = styled.div`
  margin-bottom: 15px;
  flex: 1;
  flex-direction: column;
  display: flex;
`;

const BottomContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 15px;
`;

export const SendManualNotificationModal = ({
  sessionGuid,
  defaultTitle,
  defaultText,
  forIndividualAthlete,
  focusedAthleteName,
}) => {
  const { t } = useTranslation();
  const {
    actions: { closeModal },
  } = useModalContext();
  const { sessionId } = useUserContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const { handleSubmit, reset, resetField, watch, setValue, ...formMethods } =
    useForm<SendManualNotificationFormState>({
      mode: "all",
      defaultValues: {
        text: defaultText,
        forCoaches: !forIndividualAthlete,
        forAthletes: true,
        forFamilyMembers: true,
      },
    });

  const [sendSessionPushNotifications, { loading }] =
    useSendSessionPushNotificationsMutation();

  const onSubmit = async ({
    text,
    forCoaches,
    forAthletes,
    forFamilyMembers,
  }) => {
    try {
      await sendSessionPushNotifications({
        variables: {
          sessionId,
          sessionGuid,
          title: defaultTitle,
          text,
          trainers: forCoaches,
          athletes: forAthletes,
          family: forFamilyMembers,
        },
      });
      closeModal();
      showSuccessNotification(t("sendManualNotificationSuccess"));
    } catch {
      closeModal();
      showErrorNotification(t("sendManualNotificationError"));
    }
  };

  const focusedAthleteNameFallback = defaultTitle.match(
    focusedAthleteNameRegex
  )?.[1];

  return (
    <MainContainer>
      <TitleContainer>
        <LabelText>{t("title").toUpperCase()}</LabelText>
        <StyledText fontWeight="bold">{defaultTitle}</StyledText>
      </TitleContainer>
      <FormProvider
        handleSubmit={handleSubmit}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        reset={reset}
        {...formMethods}
      >
        <ControlledTextArea
          name="text"
          label={t("manualNotificationTextLabel")}
          marginBottom={15}
        />
        {!forIndividualAthlete ? (
          <>
            <ControlledCheckbox
              name="forCoaches"
              text={t("coaches")}
              marginBottom={15}
            />
            <ControlledCheckbox
              name="forAthletes"
              text={t("athletes")}
              marginBottom={15}
            />
          </>
        ) : (
          <ControlledCheckbox
            name="forAthletes"
            text={`${t("sendTo")} - ${
              focusedAthleteName || focusedAthleteNameFallback
            }`}
            marginBottom={15}
          />
        )}

        <ControlledCheckbox
          name="forFamilyMembers"
          text={t("familyMembers")}
          marginBottom={15}
        />
      </FormProvider>
      <BottomContainer>
        {loading ? (
          <Loader size="small" color={COLOR_BLUE} />
        ) : (
          <>
            <GeneralButton
              label={t("cancel")}
              colorVariant={ButtonColor.Gray}
              onClick={closeModal}
            />
            <GeneralButton label={t("done")} onClick={handleSubmit(onSubmit)} />
          </>
        )}
      </BottomContainer>
    </MainContainer>
  );
};
