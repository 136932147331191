import React from "react";

interface Props {
  color?: string;
  direction?: "top" | "bottom" | "left" | "right";
}

export const ArrowIcon = ({ color = "#0073D9", direction = "top" }: Props) => {
  const rotation = () => {
    switch (direction) {
      case "right":
        return "90";
      case "bottom":
        return "180";
      case "left":
        return "270";
      default:
        return "0";
    }
  };

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotation()}deg)` }}
    >
      <path d="M16 11L10.5 5.5L5 11" stroke={color} />
      <path d="M10.5 6V16" stroke={color} />
    </svg>
  );
};
