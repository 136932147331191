import { LazyQueryResult } from "@apollo/client";

import { ReportBuildingAttributeTemplatesQuery } from "../../../../../graphql";

export const findAttribute = (
  attributes: LazyQueryResult<ReportBuildingAttributeTemplatesQuery, unknown>,
  attributeId: string
): string =>
  attributes?.data?.reportBuildingAttributeTemplates?.find(
    (attr) => attr.id === attributeId
  )?.name || "";
