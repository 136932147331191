import React from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../../../colors";
import { requiredValidation } from "../../../../utils/form";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { SelectField } from "../../../SelectField";
import StyledText from "../../../StyledText";
import { ChartType, CombinedFormValue } from "../models";

const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FiledWrapper = styled.div`
  width: 213px;
`;

export function CombinedTestEditorWhatToShowSection({
  isGroupWidget,
}: {
  isGroupWidget: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Section>
      <SectionTitle title={t("whatToShow")} />
      <TypeWrapper>
        <StyledText
          color={COLOR_TEXT_DEFAULT}
          fontSize={10}
          fontWeight="bold"
          style={{ marginTop: 16.5, marginRight: 25 }}
        >
          {t("type").toUpperCase()}
        </StyledText>

        <FiledWrapper>
          <Field name="chartType" validate={requiredValidation}>
            {({ field, form, meta }: FieldProps<CombinedFormValue>) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={
                  isGroupWidget
                    ? [{ label: t("circleChart"), value: ChartType.CIRCLE }]
                    : [
                        { label: t("radarChart"), value: ChartType.RADAR },
                        { label: t("circleChart"), value: ChartType.CIRCLE },
                      ]
                }
              />
            )}
          </Field>
        </FiledWrapper>
      </TypeWrapper>
    </Section>
  );
}
