import dayjs, { Dayjs } from "dayjs";

import * as api from "../api";
import * as endpoints from "../endpoints";

export type AttendanceInPeriodOfTimeDataItem = {
  period?: {
    _start: number;
    _frame: "Day" | "Week" | "Month" | "Year";
    _endExcl?: number;
  };
  gamesTotal?: number;
  gamesPlayed?: number;
  gamesPlayedPerc?: number;
  gamesPlayedMinutes?: number;
  practicesTotal?: number;
  practicesAttended?: number;
  practicesAttendedPerc?: number;
  practicesTotalMinutes?: number;
  practicesAttendedMinutes?: number;
  practicesMinutesPerc?: number;
  workoutsTotal?: number;
  workoutsCompleted?: number;
  workoutsPerc?: number;
  workoutsCompletedMinutes?: number;
  totalSessionsAttended?: number;
  totalSessionsAttendedMinutes?: number;
  totalRestDays?: number;
  gamesMissed?: number;
  practicesMissed?: number;
  gamesMissedSick?: number;
  gamesMissedInjured?: number;
  gamesMissedOther?: number;
  practicesMissedSick?: number;
  practicesMissedInjured?: number;
  practicesMissedOther?: number;
  individualGamesWon?: number;
  individualGamesLost?: number;
  individualGamesWonPerc?: number;
  individualGameSetsWon?: number;
  individualGameSetsLost?: number;
  individualGameSetsWonPerc?: number;
};

export type FetchAttendanceStatisticsArgs = {
  session: api.Session;
  fromDate: Dayjs;
  toDate: Dayjs;
  periodization?: "No" | "Day" | "Week" | "Month" | "Year";
  participants: {
    id: string;
  }[];
  groupGuids?: string[];
  allGroups?: boolean;
  reportTemplateId: string;
  focusedUserAccount?: string;
};

export type JsonAttendanceStatisticsQueryResponse = {
  perAthletes: {
    athleteId: string;
    athleteName: string;
    attendanceInPeriodOfTime: AttendanceInPeriodOfTimeDataItem[];
  }[];
};

export function fetchAttendanceStatistics({
  session,
  fromDate,
  toDate,
  periodization,
  participants,
  allGroups = false,
  groupGuids,
  reportTemplateId,
  focusedUserAccount,
}: FetchAttendanceStatisticsArgs) {
  const { focusedGroup, ...sessionRest } = session;

  const params = {
    periodization,
    _from: dayjs(fromDate).valueOf(),
    _to: dayjs(toDate).valueOf(),
    _athleteGuids: participants.map((p: { id: string }) => p?.id),
    _groupGuids: groupGuids,
    _focusedGroup: allGroups ? undefined : focusedGroup,
    _focusedUserAccount: focusedUserAccount,
    reportTemplateId,
  };

  return api.post<JsonAttendanceStatisticsQueryResponse>(
    endpoints.JsonAttendanceStatisticsQuery,
    params,
    sessionRest
  );
}

export type FetchAttendanceTeamStatisticsArgs = {
  session: api.Session;
  fromDate: Dayjs;
  toDate: Dayjs;
  periodization?: "No" | "Day" | "Week" | "Month" | "Year";
  groupGuids: string[];
  reportTemplateId: string;
  focusedUserAccount?: string;
};

export type JsonAttendanceTeamStatisticsQueryResponse = {
  perTeams: {
    groupId: string;
    groupName: string;
    attendanceInPeriodOfTime: AttendanceInPeriodOfTimeDataItem[];
  }[];
};

export function fetchAttendanceTeamStatistics({
  session,
  fromDate,
  toDate,
  periodization,
  groupGuids,
  reportTemplateId,
  focusedUserAccount,
}: FetchAttendanceTeamStatisticsArgs) {
  const { focusedGroup, ...sessionRest } = session;

  const params = {
    periodization,
    _from: dayjs(fromDate).valueOf(),
    _to: dayjs(toDate).valueOf(),
    _groupGuids: groupGuids,
    reportTemplateId,
    _focusedUserAccount: focusedUserAccount,
  };

  return api.post<JsonAttendanceTeamStatisticsQueryResponse>(
    endpoints.JsonAttendanceStatisticsQuery,
    params,
    sessionRest
  );
}

export interface AttendanceLogQueryResult {
  items: {
    time: number;
    status: number;
    comment: string;
    label: string;
    athleteComment: string;
    groupName: string;
  }[];
}

export type UseAttendanceLog2022QueryArgs = {
  athleteGuid: string;
  groupGuid?: string;
  data: {
    events: boolean;
    games: boolean;
    practices: boolean;
    allGroups: boolean;
  };
  range: { from: Dayjs; to: Dayjs };
  session: api.Session;
  reportTemplateId: string;
  focusedGroup?: string;
  focusedUserAccount?: string;
};

export function fetchAttendanceLogQuery2022({
  athleteGuid,
  groupGuid,
  range,
  data,
  session,
  reportTemplateId,
  focusedUserAccount,
}: UseAttendanceLog2022QueryArgs) {
  const params = {
    _focusedAthlete: athleteGuid,
    _focusedGroup: groupGuid,
    _focusedUserAccount: focusedUserAccount,
    from: dayjs(range.from).valueOf(),
    to: dayjs(range.to).valueOf(),
    reportTemplateId,
    ...data,
  };

  return api.post<AttendanceLogQueryResult>(
    endpoints.JsonTemporaryAttendanceLogQuery,
    params,
    session
  );
}
