import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled, { css, useTheme } from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { isRouteParamTrue } from "../../utils/route";
import { ButtonColor, GeneralButton } from "../Button/";
import { ReportPeriodOnMobile } from "../Report/mobile/ReportPeriodOnMobile";
import { ReportPeriodSelection } from "../Report/ReportPeriodSelection";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => (!theme.isMobile ? 30 : 15)}px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  z-index: 1;
`;

const HeaderLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;

  ${({ theme }) =>
    theme.isMobile &&
    css`
      flex-wrap: wrap;
    `}
`;

export function ReportHeader() {
  const modal = useModalContext();
  const { t } = useTranslation();
  const { isMobile } = useTheme();
  const [searchParams] = useSearchParams();

  const isEditMode = isRouteParamTrue(searchParams.get("isEditMode"));

  const onSelectPerformersClick = () => {
    modal.actions.openModal({
      modal: ModalType.ATHLETES_SELECTOR,
      title: t("selectPerformers"),
    });
  };

  return (
    <HeaderWrapper>
      <HeaderLineWrapper>
        {!isMobile && <ReportPeriodSelection />}
        {isMobile && <ReportPeriodOnMobile />}
        {!isMobile && <div style={{ flex: 1 }} />}
        {!isEditMode && (
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            label={t("selectPerformers")}
            onClick={onSelectPerformersClick}
          />
        )}
      </HeaderLineWrapper>
    </HeaderWrapper>
  );
}
