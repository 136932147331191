import { AggregateFunction } from "../../../../graphql";

export const getAggregationFunctionsByItemId = (itemId: string) => {
  switch (itemId) {
    case "SoccerStat.GamesPlayed":
      return [AggregateFunction.Sum];
    case "SoccerStat.MinutesPerGame":
      return [AggregateFunction.Average];
    case "SoccerStat.CleanSheets":
      return [AggregateFunction.Sum, AggregateFunction.Average];
    case "SoccerStat.RedCards":
    case "SoccerStat.YellowCards":
      return [
        AggregateFunction.Latest,
        AggregateFunction.Sum,
        AggregateFunction.Average,
      ];
    default:
      return undefined;
  }
};
