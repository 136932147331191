import React, { memo, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_GRAY, COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../../colors";
import { useReportsContext } from "../../../../contexts/Reports";
import {
  AggregateFunction,
  MeasurementTemplateForReports,
  ReportItemType,
} from "../../../../graphql";
import { useHasSoccer } from "../../../../hooks/useHasSoccer";
import { requiredValidation } from "../../../../utils/form";
import {
  isAttendanceCollection,
  isCustomTextSoccerAttribute,
  isSoccerStatsCollection,
} from "../../../../utils/reports";
import { Checkbox } from "../../../Checkbox";
import { IconButton } from "../../../IconButton";
import { RemoveIcon } from "../../../Icons";
import { Label } from "../../../Label";
import { Section } from "../../../Section";
import { SelectField } from "../../../SelectField";
import StyledText from "../../../StyledText";
import { DataSource, DataSourceSelectorRow } from "../../DataSourceSelector";
import {
  ChartItemModel,
  ChartType,
  ChartWidgetModel,
  TimeLine,
} from "../model";

import { ChartIconButton } from "./ChartIconButton";
import { getAggregationFunctionsByItemId } from "./utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  background-color: ${COLOR_GRAY};
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  height: 35px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TitleText = styled(StyledText)`
  font-weight: bold;
  font-size: 10px;
`;

const ChartTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SourceSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const CalculationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 10px;
  max-width: 50%;
  box-sizing: border-box;
`;

const AverageLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const AverageField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface ChartValuesItemProps {
  index: number;
  item: ChartItemModel;
  removeItem: <T>(index: number) => T;
}

export const ChartValuesItem = memo(
  ({ index, item, removeItem }: ChartValuesItemProps) => {
    const { t } = useTranslation();
    const formik = useFormikContext<ChartWidgetModel>();
    const [{ aggregations }] = useReportsContext();
    const { values, setFieldValue } = formik;

    const hasSoccer = useHasSoccer();

    const aggregationOptions = useMemo(
      () =>
        (aggregations ?? [])
          .filter((aggregation) => {
            if (isSoccerStatsCollection(item.templateGuid)) {
              const supportedAggregations = getAggregationFunctionsByItemId(
                item.templateGuid
              );

              return (
                aggregation !== AggregateFunction.Each &&
                (!supportedAggregations ||
                  supportedAggregations.some(
                    (supportedAggregation) =>
                      supportedAggregation === aggregation
                  ))
              );
            }

            return aggregation !== AggregateFunction.Each;
          })
          .map((aggregation) => ({
            label: t(`aggregation${aggregation}`, {
              defaultValue: aggregation,
            }),
            value: aggregation,
          })),
      [aggregations, item.templateGuid, t]
    );

    const allowedReportItemTypes = useMemo(
      (): ReportItemType[] => [
        ReportItemType.Drill,
        ReportItemType.Exercise,
        ReportItemType.Test,
        ReportItemType.PlayertekCatapult,
        ReportItemType.Catapult,
        ReportItemType.Polar,
        ReportItemType.Garmin,
        ReportItemType.Kinexon,
        ReportItemType.Firstbeat,
        ReportItemType.Statsports,
        ReportItemType.TrainingLoad,
        ReportItemType.Pubg,
        ...(values.timeLine !== TimeLine.CONTINUOUS
          ? [ReportItemType.Attendance]
          : []),
        ...(hasSoccer ? [ReportItemType.SoccerStat] : []),
      ],
      [values.timeLine, hasSoccer]
    );

    const onChangeRowValues = useCallback(
      (newValues: Partial<DataSource>) => {
        setFieldValue(`items[${index}]`, {
          ...values.items[index],
          ...newValues,
          ...(isAttendanceCollection((newValues as any).dataType)
            ? { aggregation: AggregateFunction.Latest }
            : {}),
        });
      },
      [index, setFieldValue, values.items]
    );

    const filterOutCustomSoccerTextMeasurements = useCallback(
      (item: MeasurementTemplateForReports) =>
        !isCustomTextSoccerAttribute(item.id),
      []
    );

    const replaceMinutesInPositionWithGlobalMeasurement = useCallback(
      (reportBuildingMeasurementTemplates: MeasurementTemplateForReports[]) => {
        const minutesInPositionItem = reportBuildingMeasurementTemplates.find(
          (item) => item.id.includes("MinutesInPosition")
        );

        if (minutesInPositionItem) {
          return [
            ...reportBuildingMeasurementTemplates.filter(
              (item) => !item.id.includes("MinutesInPosition")
            ),
            {
              ...minutesInPositionItem,
              name: t("minutesInPosition"),
              id: "SoccerStat.MinutesInPosition",
            },
          ];
        }

        return reportBuildingMeasurementTemplates;
      },
      [t]
    );

    return (
      <Wrapper>
        <TitleRow>
          <TitleText>
            {t("value")} {index + 1}
          </TitleText>

          {index !== 0 && (
            <ChartTypeWrapper>
              <ChartIconButton
                currentChartTypeOverride={item.chartTypeOverride as ChartType}
                chartTypeOverride={ChartType.COLUMN}
                index={index}
              />

              <ChartIconButton
                currentChartTypeOverride={item.chartTypeOverride as ChartType}
                chartTypeOverride={ChartType.LINE}
                index={index}
              />
              <IconButton
                size={25}
                bgColor="transparent"
                hoverBgColor={COLOR_WHITE}
                icon={<RemoveIcon />}
                onClick={() => removeItem(index)}
              />
            </ChartTypeWrapper>
          )}
        </TitleRow>
        <Section style={{ borderBottomWidth: 0 }}>
          <SourceSelectorWrapper>
            <DataSourceSelectorRow
              currentDataSource={item}
              onChangeDataSourceValues={onChangeRowValues}
              customVisibleMeasurementsFilter={
                filterOutCustomSoccerTextMeasurements
              }
              customMeasurementsMapper={
                replaceMinutesInPositionWithGlobalMeasurement
              }
              dataSourceArrayItemAccessor={`items[${index}]`}
              customCollectionIdAccessor="dataType"
              customMeasurementTemplateIdAccessor="templateGuid"
              customMeasurementTemplateNameAccessor="templateName"
              customAttributeTemplateIdAccessor="attribute"
              customAttributeTemplateNameAccessor="attributeName"
              allowedReportItemTypes={allowedReportItemTypes}
            />
          </SourceSelectorWrapper>

          {Boolean(item.dataType) &&
            !isAttendanceCollection(item.dataType) &&
            values.timeLine !== TimeLine.CONTINUOUS && (
              <>
                <CalculationWrapper>
                  <FieldWrapper>
                    <Label>{t("calculation")}</Label>
                    <Field
                      name={`items.${index}.aggregation`}
                      validate={requiredValidation}
                    >
                      {({ field, meta, form }) => (
                        <SelectField
                          field={field}
                          form={form}
                          meta={meta}
                          options={aggregationOptions}
                          onChange={(
                            option: (typeof aggregationOptions)[number]
                          ) => {
                            form.setFieldValue(field.name, option.value, true);
                          }}
                        />
                      )}
                    </Field>
                  </FieldWrapper>
                  {!isSoccerStatsCollection(item.dataType) && (
                    <FieldWrapper>
                      <Checkbox
                        text={t("showTeamAverage")}
                        check={(showTeamAverage) =>
                          setFieldValue(
                            `items.${index}.showTeamAverage`,
                            showTeamAverage
                          )
                        }
                        checked={item.showTeamAverage ?? false}
                      />
                    </FieldWrapper>
                  )}
                </CalculationWrapper>

                <AverageLineWrapper>
                  <FieldWrapper />
                  {item.showTeamAverage &&
                    values.chartType !== ChartType.LINE && (
                      <AverageField>
                        <Label>{t("averageType")}</Label>
                        <Field
                          name={`items.${index}.teamAverageChartType`}
                          component={SelectField}
                          options={[
                            {
                              label: t(ChartType.LINE),
                              value: ChartType.LINE,
                            },
                            {
                              label: t(values.chartType),
                              value: values.chartType,
                            },
                          ]}
                        />
                      </AverageField>
                    )}
                </AverageLineWrapper>
              </>
            )}
        </Section>
      </Wrapper>
    );
  }
);
