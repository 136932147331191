export async function getMoreDocuments(data, variable, fetchMore) {
  const hasNextPage =
    data?.user?.collection?.tree?.documents?.pageInfo?.hasNextPage;

  if (hasNextPage) {
    await fetchMore({
      variables: {
        ...variable,
        after: data.user.collection.tree.documents.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult.user.collection.tree.documents.edges.length)
          return prev;
        return {
          user: {
            ...prev.user,
            collection: {
              ...prev.user.collection,
              tree: {
                ...prev.user.collection?.tree,
                documents: {
                  ...prev.user.collection?.tree.documents,
                  pageInfo:
                    fetchMoreResult.user.collection?.tree.documents.pageInfo,
                  edges: [
                    ...prev?.user?.collection?.tree.documents?.edges,
                    ...fetchMoreResult.user.collection?.tree.documents?.edges,
                  ],
                },
              },
            },
          },
        };
      },
    });
  }
}
