import { v4 as uuidv4 } from "uuid";

import { APP_VERSION } from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { isTvEnv } from "../utils/isTvEnv";

import * as api from "./api";
import * as endpoints from "./endpoints";

export type DokobitWebAuthorizeProps = {
  userSession: string;
  sessionToken: string;
};

// Just for demo purpose of electronicIDAuth page e.g. to see list of users
export const MOCKED_USERS = [
  {
    tokenFromIslandIs: uuidv4(),
    guid: uuidv4(),
    username: "user.nopin",
    name: "User without pin",
    userCategory: "Family",
    isExpired: false,
    imageUrl: "",
    usesPin: false,
  },
  {
    tokenFromIslandIs: uuidv4(),
    guid: uuidv4(),
    username: "user.pin",
    name: "User with pin",
    userCategory: "Family",
    isExpired: false,
    imageUrl: "",
    usesPin: true,
  },
];

export enum UserCategory {
  Trainers = "trainers",
  Athletes = "athletes",
  Family = "family",
}

export type DokobitUser = {
  tokenFromIslandIs: string;
  guid: string;
  username: string;
  name: string;
  userCategory: UserCategory;
  isExpired: boolean;
  imageUrl: string;
  usesPin: boolean;
};

export type DokobitLoginProps = {
  userSession: string;
  userGuid: string;
  pin?: number;
};

type DokobitLoginRequestResponse = {
  _failureTextForUser: string;
  _resultCode: "PIN_Missing" | "PIN_Wrong";
  _sessionId: string;
};

export type DokobitWebAuthorizeQueryResponse = {
  users: DokobitUser[];
};

const APP_NAME = isTvEnv() ? "xps-expo-web-tv" : "xps-expo-web";

export async function prepareDokobitRedirect() {
  const params = {
    app: APP_NAME,
    version: APP_VERSION,
    redirectToUrl: `${window.location.origin}/${ROUTING_CONFIG.electronicIDAuth}`,
  };

  const response = await api.post(endpoints.JsonDokobitLoginStart, params);

  if (response.status !== 200) {
    return null;
  }

  return response;
}

export async function dokobitWebAuthorize({
  userSession,
  sessionToken,
}: DokobitWebAuthorizeProps) {
  const params = {
    user_session: userSession,
    return_token: sessionToken,
  };

  const response = await api.post<DokobitWebAuthorizeQueryResponse>(
    endpoints.JsonDokobitWebAuthorize,
    params
  );

  if (response.status !== 200) {
    return null;
  }

  return response;
}

export async function fetchDokobitLoginRequest({
  userSession,
  userGuid,
  pin = null,
}: DokobitLoginProps) {
  const params = {
    secretUUID: userSession,
    userGuid,
    version: APP_VERSION,
    platform: window.navigator.userAgent,
    deviceType: "web",
    app: APP_NAME,
    _pin: pin,
    pin,
  };

  return await api.post<DokobitLoginRequestResponse>(
    endpoints.JsonDokobitLoginRequest,
    params,
    undefined,
    undefined,
    false
  );
}

export async function fetchGeoip() {
  return await api.get<{ country_code: string }>(
    "https://api.ipstack.com/check?access_key=182ff10741432976d045ffeb8b246f45"
  );
}
