import {
  isCustomTextSoccerAttribute,
  isInformationCollection,
  isXpsMagicAgeAttribute,
  isXpsMagicBirthDayAttribute,
  isXpsMagicCollection,
} from "../../../../../utils/reports";
import { getValidUnitName } from "../../../../../utils/statistics";
import { StatisticsDataItem } from "../../../../Report/models";

import { shouldReturnAggregationName } from "./headerDisplayChecker";
import { ColumnHeader, GroupTableStatsValue } from "./models";

export const prepareValues = (
  dataForColumn: StatisticsDataItem[],
  column: ColumnHeader,
  attributeName: string
): GroupTableStatsValue[] =>
  column.aggregateFunctions.map((aggregation) => {
    const stats = dataForColumn.find(
      (data) =>
        (data.attribute.id === column.attributeTemplate?.id ||
          isInformationCollection(column.collectionId)) &&
        data.aggregateFunction === aggregation
    );
    const customTextSoccerAttribute = isCustomTextSoccerAttribute(
      column.templateId
    );

    let value: number = NaN;
    let count: number = NaN;
    let txt: string = undefined;
    let unitName = null;

    if (stats?.values?.length > 0) {
      value = stats.values[0].val;
      count = stats.values[0].val ? 1 : 0;
      if (customTextSoccerAttribute) {
        txt = String(stats.values[0].val);
      }

      if (stats.values[0].txt) {
        txt = stats.values[0].txt;
      } else {
        unitName = getValidUnitName(stats?.attribute?.unitName);
      }
    }

    return {
      id: `${column.templateId}-${column.attributeTemplate?.id}-${aggregation}`,
      measurementTemplateId: column.templateId,
      attributeTemplateId: stats?.attribute?.id,
      aggregation: shouldReturnAggregationName(
        column.attributeTemplate?.id,
        aggregation
      )
        ? aggregation
        : "",
      // Values is array only for AggregateFunction.Each which is handled above
      value,
      count,
      txt,
      unitName,
      attributeName,
      timeUnit: stats?.attribute?.timeUnit,
      tifn: stats?.values?.length > 0 ? stats.values[0].tifn : false,
      pos: stats?.values?.length > 0 ? stats.values[0].pos : undefined,
      rgb: stats?.values?.length > 0 ? stats.values[0].rgb : undefined,
      date: stats?.values?.[0]?.date,
      isInfo:
        isInformationCollection(column.collectionId) ||
        isCustomTextSoccerAttribute(column.templateId) ||
        (isXpsMagicCollection(column.collectionId) &&
          !isXpsMagicAgeAttribute(column.templateId) &&
          !isXpsMagicBirthDayAttribute(column.templateId)),
    };
  });
