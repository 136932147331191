import React from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import { PeriodType } from "../../../models";
import { ConnectedFocusError } from "../../FormikFocusError";
import { AppearanceSection } from "../../ReportCreateTable/AppearanceSection";
import {
  KeyValuesWidgetModel,
  WidgetType,
} from "../../ReportCreateTable/models";
import {
  PerformerSection,
  PerformerSelectionMode,
  PerformerSelectionWidgetType,
} from "../PerformerSection";
import { PeriodSection } from "../PeriodSection";
import { PreviewButtonSection } from "../PreviewButtonSection";
import { FormContainer, FormWrapper } from "../styled";
import { WidgetEditorTitleSection } from "../WidgetEditorTitleSection";

import { KeyValuesDataSection } from "./components/KeyValuesDataSection";
import { KeyValuesWidgetPreview } from "./KeyValuesWidgetPreview";
import { KeyValuesWidgetFormValue } from "./models";

type KeyValuesEditorProps = {
  widget: KeyValuesWidgetModel;
  isSavingWidget: boolean;
  isGroupWidget: boolean;
  onSubmit: (values: KeyValuesWidgetFormValue) => void;
  onCancel: () => void;
};

export function KeyValuesEditor({
  widget,
  isSavingWidget,
  isGroupWidget,
  onSubmit,
  onCancel,
}: KeyValuesEditorProps) {
  const { t } = useTranslation();

  const initialFormValues: KeyValuesWidgetFormValue = {
    title: widget?.name ?? "",
    numberOfColumns: widget?.numberOfColumns ?? 3,
    items: widget?.grid
      ?.flat()
      .filter((cell) => !!cell)
      .map((cell, index) => {
        const numericalCell =
          cell.__typename === "ReportWidgetCellNumerical" ? cell : null;

        return {
          collectionId: cell.collectionId ?? "",
          measurementTemplateId: cell.templateId ?? "",
          measurementTemplateName: cell.name ?? "",
          attributeTemplateId: numericalCell?.attributeTemplate?.id ?? "",
          attributeTemplateName: numericalCell?.attributeTemplate?.name ?? "",
          attributeTemplateUnitName:
            numericalCell?.attributeTemplate?.unitName ?? "",
          aggregateFunction: cell.aggregateFunctions?.[0],
          customTitle: widget.itemsSettings?.[index]?.customTitle ?? "",
          forAllGroups: widget.itemsSettings?.[index]?.forAllGroups ?? false,
          compareLatestTo: widget.itemsSettings?.[index]?.compareLatestTo,
        };
      }) ?? [
      {
        collectionId: "",
        measurementTemplateId: "",
        measurementTemplateName: "",
        attributeTemplateId: "",
        attributeTemplateName: "",
        attributeTemplateUnitName: "",
        aggregateFunction: undefined,
        customTitle: "",
        forAllGroups: false,
      },
    ],
    period: widget?.period ?? {
      type: PeriodType.SELECTION,
      from: null,
      to: null,
    },
    widgetBorders: false,
    showValuesAsText: widget?.showValuesAsText ?? false,
    performerSelectionMode:
      widget?.performerSelectionMode ??
      PerformerSelectionMode.ATHLETE_SELECTION,
    athlete: widget?.athlete ?? "",
    athletes: widget?.athletes ?? [],
    teams: widget?.teams ?? [],
  };

  return (
    <Formik<KeyValuesWidgetFormValue>
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validate={(values) => {
        if (
          typeof values.numberOfColumns !== "number" ||
          isNaN(values.numberOfColumns) ||
          values.numberOfColumns <= 0
        ) {
          return {
            numberOfColumns: t("minLimit", {
              y: 1,
              x: "number of columns",
            }),
          };
        }
      }}
    >
      {({ values, handleChange, submitForm, setFieldValue }) => (
        <FormContainer>
          <FormWrapper>
            <ConnectedFocusError />

            <WidgetEditorTitleSection
              value={values.title}
              handleChange={handleChange}
            />

            <KeyValuesDataSection isGroupWidget={isGroupWidget} />

            <AppearanceSection
              widgetType={WidgetType.KEY_VALUES}
              widgetBorders={values.widgetBorders}
              setFieldValue={setFieldValue}
              showValuesAsText={values.showValuesAsText}
            />

            <PerformerSection
              performerSelectionWidgetType={
                isGroupWidget
                  ? PerformerSelectionWidgetType.GROUP
                  : PerformerSelectionWidgetType.ATHLETE
              }
              setFieldValue={setFieldValue}
              performerSelectionMode={values.performerSelectionMode}
              withTeamSelection={isGroupWidget}
            />

            <PeriodSection
              setFieldValue={setFieldValue}
              period={values.period}
            />
          </FormWrapper>

          <PreviewButtonSection
            isSaving={isSavingWidget}
            onCancelClick={onCancel}
            onSaveClick={submitForm}
          >
            <KeyValuesWidgetPreview values={values} />
          </PreviewButtonSection>
        </FormContainer>
      )}
    </Formik>
  );
}
