import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const SickIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
      <circle
        cx="17.5"
        cy="17.5"
        r="12"
        fill={isActive ? "white" : ""}
        stroke={isActive ? "#E6E8EB" : "#B1B7BC"}
      />
      <mask id="path-3-inside-1_1017_726" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5 10C16.1193 10 15 11.1193 15 12.5V19.0505C14.3814 19.6818 14 20.5463 14 21.5C14 23.433 15.567 25 17.5 25C19.433 25 21 23.433 21 21.5C21 20.5463 20.6186 19.6818 20 19.0505V12.5C20 11.1193 18.8807 10 17.5 10Z"
        />
      </mask>
      <path
        d="M15 19.0505L15.7142 19.7504L16 19.4588V19.0505H15ZM20 19.0505H19V19.4588L19.2858 19.7504L20 19.0505ZM16 12.5C16 11.6716 16.6716 11 17.5 11V9C15.567 9 14 10.567 14 12.5H16ZM16 19.0505V12.5H14V19.0505H16ZM15 21.5C15 20.8185 15.2714 20.2023 15.7142 19.7504L14.2858 18.3506C13.4914 19.1612 13 20.2742 13 21.5H15ZM17.5 24C16.1193 24 15 22.8807 15 21.5H13C13 23.9853 15.0147 26 17.5 26V24ZM20 21.5C20 22.8807 18.8807 24 17.5 24V26C19.9853 26 22 23.9853 22 21.5H20ZM19.2858 19.7504C19.7286 20.2023 20 20.8185 20 21.5H22C22 20.2742 21.5086 19.1612 20.7142 18.3506L19.2858 19.7504ZM19 12.5V19.0505H21V12.5H19ZM17.5 11C18.3284 11 19 11.6716 19 12.5H21C21 10.567 19.433 9 17.5 9V11Z"
        fill={isActive ? "#B1B7BC" : "#64707A"}
        mask="url(#path-3-inside-1_1017_726)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 14H17V20.0854C16.4174 20.2913 16 20.8469 16 21.5C16 22.3284 16.6716 23 17.5 23C18.3284 23 19 22.3284 19 21.5C19 20.8469 18.5826 20.2913 18 20.0854V14Z"
        fill={isActive ? "#FF6562" : "#64707A"}
      />
    </svg>
  )
);
