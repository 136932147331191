import { useEffect, useMemo, useState } from "react";

import { ChartWidgetModel } from "../model";
import {
  ChartItemAttributes,
  prepareAttributes,
} from "../utils/prepareAttributes";

import { useChartMeasurementAttributeData } from "./useChartMeasurementAttributeData";

export function useChartAttributes(widget: ChartWidgetModel): {
  chartAttributes: ChartItemAttributes[];
} {
  const [templatesMap, setTemplatesMap] = useState<
    Map<
      string,
      {
        templateName: string;
        attributeUnitName: string;
      }
    >
  >(new Map());

  const { loadDataForChartAxis } = useChartMeasurementAttributeData();

  useEffect(() => {
    Promise.all(
      widget.items.map(async (item) => {
        const templateData = await loadDataForChartAxis(
          item.dataType,
          item.templateGuid,
          item.attribute
        );

        return [
          [item.dataType, item.templateGuid, item.attribute].join(":"),
          templateData,
        ] as const;
      })
    )
      .catch((err) => {
        console.error(err);

        return [];
      })
      .then((templatesData) => {
        setTemplatesMap(new Map(templatesData));
      });
  }, [widget.items, loadDataForChartAxis]);

  const chartAttributes = useMemo<ChartItemAttributes[]>(
    () => prepareAttributes(widget.items, templatesMap),
    [widget.items, templatesMap]
  );

  return { chartAttributes };
}
