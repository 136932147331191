export const getPeriodizationForSoccerStats = (
  period: "days" | "weeks" | "months" | "years"
) => {
  switch (period) {
    case "days":
      return "Day";
    case "weeks":
      return "Week";
    case "months":
      return "Month";
    case "years":
      return "Year";
  }
};
