import { ChatterType, useFindChattableUsersQuery } from "../../graphql";

export const useFindChattableUsers = ({
  sessionId,
  chatterType,
}: {
  sessionId: string;
  chatterType: ChatterType;
}) => {
  const { data, loading } = useFindChattableUsersQuery({
    variables: {
      sessionId,
    },
  });

  const users = data?.findChattableUsers ?? [];

  const athletes = users.filter(
    (user) => user?.chatterType2 === ChatterType.Athlete
  );
  const trainers = users.filter(
    (user) => user?.chatterType2 === ChatterType.Trainer
  );
  const familyMembers = users.filter(
    (user) => user?.chatterType2 === ChatterType.Family
  );

  let currentChatterTypeUsers = users;

  switch (chatterType) {
    case ChatterType.Athlete:
      currentChatterTypeUsers = athletes;
      break;
    case ChatterType.Family:
      currentChatterTypeUsers = familyMembers;
      break;
    case ChatterType.Trainer:
      currentChatterTypeUsers = trainers;
      break;
    case ChatterType.RealGroup:
      currentChatterTypeUsers = [];
      break;
    default:
      currentChatterTypeUsers = users;
  }

  return {
    athletes,
    trainers,
    familyMembers,
    loading,
    data,
    currentChatterTypeUsers,
  };
};
