import React, { CSSProperties, DOMAttributes } from "react";
import { UseControllerProps, useController } from "react-hook-form";

import styled, { css } from "styled-components";

import {
  COLOR_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import { CalendarIcon } from "../Icons";

import { InputError } from "./InputError";
import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

type Props = UseControllerProps & {
  label: string;
  name: string;
  min?: string;
  max?: string;
  type?: string;
  labelColor?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  spacing?: {
    marginBottom?: number;
  };
  autoFocus?: boolean;
  onClick?: () => void;
  hideLabel?: boolean;
  containerStyle?: CSSProperties;
  style?: CSSProperties;
  grayBgOnHover?: boolean;
  className?: string;
  onKeyDownCapture?: DOMAttributes<HTMLInputElement>["onKeyDownCapture"];
  onWheel?: (e: any) => void;
  showCalendarIcon?: boolean;
};

const StyledTextInput = styled.input<{
  disabled?: boolean;
  grayBgOnHover?: boolean;
}>`
  background-color: ${({ grayBgOnHover }) =>
    grayBgOnHover ? "transparent" : COLOR_GRAY};
  ${({ grayBgOnHover }) =>
    grayBgOnHover
      ? css`
          &:hover,
          &:focus {
            background-color: ${COLOR_GRAY};
          }
        `
      : ""};
  color: ${({ disabled }) =>
    disabled ? COLOR_SECONDARY_GREY : COLOR_TEXT_DARK};
  border-radius: 6px;
  height: 45px;
  padding: 0 15px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  ::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
  ,
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${COLOR_GRAY};
`;

const CalendarIconWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-left: 15px;
`;

export function ControlledInput({
  name,
  label,
  rules,
  defaultValue,
  labelColor,
  placeholder,
  spacing,
  autoFocus,
  hideLabel,
  containerStyle,
  grayBgOnHover,
  onWheel,
  showCalendarIcon,
  ...inputProps
}: Props) {
  const {
    field: { value, onChange, onBlur },
    formState,
  } = useController({ defaultValue, rules, name });

  const hasError = Boolean(formState?.errors?.[name]);
  const errorMessage = hasError && `${formState?.errors[name]?.message}`;

  return (
    <StyledInputWrapper
      marginBottom={spacing?.marginBottom}
      style={containerStyle}
    >
      {!hideLabel ? (
        <LabelText labelColor={labelColor}>{label.toUpperCase()}</LabelText>
      ) : null}
      {showCalendarIcon ? (
        <ValueWrapper>
          <CalendarIconWrapper>
            <CalendarIcon color={COLOR_TEXT_TABLE_LABEL} />
          </CalendarIconWrapper>
          <StyledTextInput
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus={autoFocus}
            onWheel={onWheel}
            {...inputProps}
          />
        </ValueWrapper>
      ) : (
        <StyledTextInput
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          onWheel={onWheel}
          {...inputProps}
        />
      )}
      <InputError errorMessage={errorMessage} />
    </StyledInputWrapper>
  );
}
