import React from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import { useSelectedPerformersContext } from "../../contexts/selectedAthletes";
import { useUserContext } from "../../contexts/User";
import { useRedirectToModuleBaseScreen } from "../../router/redirects/useRedirectToModuleBaseScreen";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { formatName } from "../../utils/user";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import { TickIcon } from "../Icons";
import StyledText from "../StyledText";

const StyledContainerView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledOption = styled(BasicButton)<{ isSelected?: boolean }>`
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 45px;
  padding: 0 15px;
  border-radius: 6px;
  background: ${({ isSelected }) => (isSelected ? COLOR_BLUE : COLOR_WHITE)};

  &:hover {
    background: ${({ isSelected }) => (isSelected ? COLOR_BLUE : COLOR_GRAY)};
  }
`;

const NameLabel = styled(StyledText)<{ isSelected?: boolean }>`
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  font-size: 14px;
  font-weight: 700;
  color: ${({ isSelected }) => (isSelected ? COLOR_WHITE : COLOR_TEXT_DEFAULT)};
`;

export function Accesslevels({ toggle }: { toggle?(): void }) {
  const user = useUserContext();
  const redirectToModuleBaseScreen = useRedirectToModuleBaseScreen();
  const { setAttendingUsers, setAttendingTeams } =
    useSelectedPerformersContext();
  const {
    setSelectedAccount,
    accessLevels,
    selectedAccount,
    isAllAccountOverrideActive,
  } = useAccessLevelsContext();

  return (
    <StyledContainerView>
      {accessLevels.map((accessLevel) => {
        const formattedName = formatName(accessLevel.user.name);
        const isCurrentOptionSelected =
          (selectedAccount?.id === accessLevel?.user?.id &&
            !isAllAccountOverrideActive) ||
          (accessLevel?.user?.id === ALL_ACCOUNTS_ID &&
            isAllAccountOverrideActive);

        return (
          <StyledOption
            isSelected={isCurrentOptionSelected}
            key={accessLevel.user.id}
            onClick={() => {
              setSelectedAccount(accessLevel.user.id);
              setAttendingTeams([]);
              setAttendingUsers([]);

              setTimeout(() => {
                toggle?.();
                Storage.removeItem(StorageKeys.selectedCollection);
                Storage.setItem(StorageKeys.sessionId, {
                  sessionId: user.sessionId,
                });
                Storage.setItem(
                  StorageKeys.sessionId,
                  {
                    sessionId: user.sessionId,
                  },
                  window.sessionStorage
                );
              });

              redirectToModuleBaseScreen();
            }}
          >
            <Avatar
              userGuid={accessLevel.user.id}
              isThumb={true}
              size={25}
              userName={`${formattedName}`}
            />
            <NameLabel isSelected={isCurrentOptionSelected} numberOfLines={1}>
              {formattedName}
            </NameLabel>
            {isCurrentOptionSelected ? (
              <TickIcon
                tintColor={COLOR_WHITE}
                style={{ transform: `scale(1.35)` }}
              />
            ) : (
              <div />
            )}
          </StyledOption>
        );
      })}
    </StyledContainerView>
  );
}
