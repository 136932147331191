import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { Attachment, Maybe } from "../../graphql";
import { LabelText } from "../HookForm/StyledInputComponents";

import { AttachmentItem } from "./components/AttachmentItem";

interface SessionAttachmentsProps {
  data: Maybe<Maybe<Attachment>[]>;
}

const Container = styled.div`
  background: ${COLOR_WHITE};
  padding: 0 20px 20px;
`;

const AttachmentsContainer = styled.div`
  display: flex;
  gap: 5px;
`;

export const SessionAttachments = ({ data }: SessionAttachmentsProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();

  const openAttachment = (attachment: Attachment) => {
    if (attachment.jsonContentUrl) {
      return modalActions.openModal({
        modal: ModalType.DOCUMENT,
        title: attachment.name,
        params: {
          document: {
            jsonUrl: attachment.jsonContentUrl,
            itemName: attachment.name,
          },
        },
      });
    }

    if (attachment.url) {
      window.open(attachment.url, "_blank");
    }
  };

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Container>
      <LabelText
        labelColor={COLOR_MEDIUM_GRAY}
        style={{ marginBottom: 10, display: "block" }}
      >
        {t("attachments").toUpperCase()}
      </LabelText>
      <AttachmentsContainer>
        {data.map((item) => (
          <AttachmentItem
            key={item.id}
            data={item}
            onClick={() => openAttachment(item)}
          />
        ))}
      </AttachmentsContainer>
    </Container>
  );
};
