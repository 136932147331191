import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ChevronRightRoundedIcon = memo(
  ({ width = 15, height = 15, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <circle cx="7.5" cy="7.5" r="7.5" fill="#ECF2FB" />
      <path d="M6.5 10.5L9.5 7.5L6.5 4.5" stroke="#0073D9" strokeWidth="1.5" />
    </svg>
  )
);
