import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const EmptyMagnifyingGlassIcon = memo(
  ({ width = 250, height = 210, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12196_58190)">
        <path
          d="M124.904 210C182.788 210 229.718 162.986 229.718 105C229.718 47.0138 182.788 0 124.904 0C67.02 0 20.0894 47.0138 20.0894 105C20.0894 162.986 67.02 210 124.904 210Z"
          fill="url(#paint0_linear_12196_58190)"
        />
        <path
          d="M166.428 30.24H85.5986C77.8802 30.24 71.6233 36.508 71.6233 44.24V151.891C71.6233 159.623 77.8802 165.891 85.5986 165.891H166.428C174.146 165.891 180.403 159.623 180.403 151.891V44.24C180.403 36.508 174.146 30.24 166.428 30.24Z"
          fill="url(#paint1_linear_12196_58190)"
        />
        <path
          d="M120.624 47.74H85.5111C83.1474 47.74 81.2312 49.6596 81.2312 52.0275C81.2312 54.3954 83.1474 56.315 85.5111 56.315H120.624C122.988 56.315 124.904 54.3954 124.904 52.0275C124.904 49.6596 122.988 47.74 120.624 47.74Z"
          fill="#B1B7BC"
        />
        <path
          d="M164.297 72.065H85.5111C83.1474 72.065 81.2312 73.9846 81.2312 76.3525C81.2312 78.7204 83.1474 80.64 85.5111 80.64H164.297C166.66 80.64 168.577 78.7204 168.577 76.3525C168.577 73.9846 166.66 72.065 164.297 72.065Z"
          fill="#E6E8EB"
        />
        <path
          d="M164.297 96.39H85.5111C83.1474 96.39 81.2312 98.3096 81.2312 100.677C81.2312 103.045 83.1474 104.965 85.5111 104.965H164.297C166.66 104.965 168.577 103.045 168.577 100.677C168.577 98.3096 166.66 96.39 164.297 96.39Z"
          fill="#E6E8EB"
        />
        <path
          d="M164.297 120.715H85.5111C83.1474 120.715 81.2312 122.635 81.2312 125.003C81.2312 127.37 83.1474 129.29 85.5111 129.29H164.297C166.66 129.29 168.577 127.37 168.577 125.003C168.577 122.635 166.66 120.715 164.297 120.715Z"
          fill="#E6E8EB"
        />
        <path
          d="M85.5111 145.04H164.297C166.664 145.04 168.577 146.956 168.577 149.328C168.577 151.699 166.664 153.615 164.297 153.615H85.5111C83.1441 153.615 81.2312 151.699 81.2312 149.328C81.2312 146.956 83.1441 145.04 85.5111 145.04Z"
          fill="#E6E8EB"
        />
        <path
          d="M245.572 15.2425H193.094C190.649 15.2425 188.666 17.3338 188.666 19.9063V45.9113C188.666 48.4925 190.649 50.5838 193.094 50.5838H245.572C248.017 50.5838 250 48.4925 250 45.9113V19.9063C250 17.325 248.017 15.2425 245.572 15.2425Z"
          fill="white"
        />
        <path
          d="M201.768 38.5C204.659 38.5 207.009 36.1462 207.009 33.25C207.009 30.3537 204.659 28 201.768 28C198.877 28 196.527 30.3537 196.527 33.25C196.527 36.1462 198.877 38.5 201.768 38.5Z"
          fill="#B1B7BC"
        />
        <path
          d="M219.237 28H236.706C239.597 28 241.947 30.3537 241.947 33.25C241.947 36.1462 239.597 38.5 236.706 38.5H219.237C216.346 38.5 213.996 36.1462 213.996 33.25C213.996 30.3537 216.346 28 219.237 28Z"
          fill="#E6E8EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.571 171.99C166.821 171.99 177.276 168.578 185.967 162.741L221.517 196.735L234.409 181.755L200.108 148.951C206.441 140.017 210.171 129.098 210.171 117.311C210.171 87.1063 185.731 62.6238 155.58 62.6238C125.428 62.6238 100.989 87.1063 100.989 117.311C100.989 147.516 125.428 171.999 155.58 171.999L155.571 171.99ZM202.135 117.303C202.135 142.87 181.443 163.599 155.92 163.599C130.398 163.599 109.706 142.87 109.706 117.303C109.706 91.735 130.398 71.0063 155.92 71.0063C181.443 71.0063 202.135 91.735 202.135 117.303Z"
          fill="#B1B7BC"
        />
        <path
          d="M164.139 117.25L175.66 105.752C176.743 104.615 177.329 103.101 177.311 101.535C177.294 99.9687 176.656 98.4812 175.547 97.37C174.438 96.2675 172.935 95.6287 171.363 95.6112C169.791 95.5937 168.271 96.18 167.127 97.2562L155.58 108.754L144.059 97.2562C143.509 96.67 142.836 96.2062 142.102 95.8825C141.36 95.5587 140.565 95.3837 139.761 95.375C138.958 95.3662 138.154 95.515 137.403 95.8125C136.652 96.1187 135.979 96.565 135.403 97.1337C134.835 97.7025 134.381 98.3762 134.075 99.12C133.77 99.8637 133.621 100.66 133.63 101.465C133.638 102.27 133.813 103.057 134.136 103.801C134.46 104.536 134.931 105.201 135.516 105.752L147.055 117.25L135.516 128.747C134.931 129.299 134.46 129.964 134.136 130.699C133.813 131.434 133.638 132.23 133.63 133.035C133.621 133.84 133.77 134.636 134.075 135.38C134.381 136.124 134.826 136.797 135.403 137.366C135.971 137.935 136.652 138.381 137.403 138.687C138.154 138.994 138.949 139.142 139.761 139.125C140.574 139.107 141.369 138.941 142.102 138.617C142.845 138.294 143.509 137.83 144.059 137.244L155.606 125.746L167.153 137.244C168.306 138.25 169.799 138.792 171.337 138.74C172.874 138.687 174.333 138.057 175.416 136.981C176.499 135.896 177.128 134.444 177.171 132.912C177.215 131.381 176.674 129.894 175.652 128.747L164.131 117.25H164.139Z"
          fill="#3C434C"
        />
        <path
          d="M221.508 196.735L234.4 181.746L236.339 183.601C238.226 185.404 239.352 187.915 239.475 190.601C239.597 193.279 238.706 195.904 237.003 197.89C235.291 199.876 232.906 201.066 230.365 201.197C227.823 201.329 225.334 200.392 223.456 198.59L221.517 196.735H221.508Z"
          fill="#B1B7BC"
        />
        <path
          d="M242.82 140.875C245.715 140.875 248.061 138.524 248.061 135.625C248.061 132.726 245.715 130.375 242.82 130.375C239.926 130.375 237.58 132.726 237.58 135.625C237.58 138.524 239.926 140.875 242.82 140.875Z"
          fill="#E9EBED"
        />
        <path
          d="M28.0173 33.0822C32.3816 33.0822 35.9196 29.5403 35.9196 25.1712C35.9196 20.8021 32.3816 17.2603 28.0173 17.2603C23.653 17.2603 20.115 20.8021 20.115 25.1712C20.115 29.5403 23.653 33.0822 28.0173 33.0822Z"
          fill="#E9EBED"
        />
        <path
          d="M56.9648 191.733C59.8592 191.733 62.2056 189.382 62.2056 186.483C62.2056 183.583 59.8592 181.233 56.9648 181.233C54.0705 181.233 51.7241 183.583 51.7241 186.483C51.7241 189.382 54.0705 191.733 56.9648 191.733Z"
          fill="#B1B7BC"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12196_58190"
          x1="124.092"
          y1="-34.1862"
          x2="126.248"
          y2="334.556"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E8EB" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12196_58190"
          x1="126.013"
          y1="30.24"
          x2="126.013"
          y2="165.891"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.72" stopColor="#FAFAFA" />
        </linearGradient>
        <clipPath id="clip0_12196_58190">
          <rect width="250" height="210" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
