import React, { useCallback, useMemo, useState } from "react";

import _debounce from "lodash/debounce";
import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import {
  AgendaDocument,
  CalendarDocument,
  useRegisterQuestionnaireInformationMutation,
} from "../../graphql";
import { Selection } from "../Athlete/Form/Selection";
import { TextInput } from "../Athlete/Form/TextInput";
import { Checkbox } from "../Checkbox";

const NameText = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const TextInputWrapper = styled.div`
  padding: 20px 0;
`;

export const InformationItem = ({ item, questionnaireId }) => {
  const { timezone, language, sessionId } = useUserContext();
  const registeredValues: string[] = item.informationRegistration?.values || [];
  const isMultiChoice: boolean = item.informationTemplate?.multiChoice;
  const [registerQuestionnaireInformationMutation, { error }] =
    useRegisterQuestionnaireInformationMutation();
  const [values, setValues] = useState<string[]>(registeredValues);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    _debounce<any>((values) => {
      registerQuestionnaireInformationMutation({
        variables: {
          timezone,
          language: language || "",
          sessionId,
          input: {
            id: item.informationRegistration?.id || null,
            templateId: item.informationTemplate?.templateGuid || null,
            values,
            questionnaireId,
          },
        },
        refetchQueries: () => [CalendarDocument, AgendaDocument],
      });
    }, 1000),
    [item, questionnaireId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTextInputChnage = useCallback(
    _debounce<any>((e) => {
      onChange(e.target.value);
    }, 1000),
    []
  );

  const onCheckBoxPress = (value) => {
    let newValues;

    if (isMultiChoice) {
      const index = values.findIndex((v) => v === value);

      if (index === -1) {
        newValues = [...values, value];
      } else {
        newValues = values.filter((v) => v !== value);
      }
    } else {
      newValues = [value];
    }

    setValues(newValues); // update UI immediately
    onChange(newValues); // mutation is debounced
  };

  const errorMessage = (error?.graphQLErrors?.[0] as any)?.exception
    ?.detailMessage;

  const options = useMemo(
    () =>
      item.informationTemplate?.textsToChooseFrom
        ? item.informationTemplate?.textsToChooseFrom.map((option) => {
            return {
              label: option,
              value: option,
            };
          })
        : [],
    [item.informationTemplate?.textsToChooseFrom]
  );

  return (
    <div>
      <div>
        <NameText>{item.informationTemplate?.name}</NameText>
        {errorMessage ? <p>{errorMessage}</p> : null}
      </div>

      {item.informationTemplate?.textsToChooseFrom ? (
        isMultiChoice ? (
          <div>
            {item.informationTemplate?.textsToChooseFrom?.map((text, index) => (
              <Checkbox
                key={index}
                check={() => onCheckBoxPress(text)}
                checked={values.findIndex((v) => v === text) !== -1}
                text={text}
              />
            ))}
          </div>
        ) : (
          <Selection
            options={options}
            value={
              options.filter((v) => values.includes(v.value))[0]?.value ?? null
            }
            onChange={(_event, value) => onCheckBoxPress(value)}
          />
        )
      ) : (
        <TextInputWrapper>
          <TextInput
            onChange={(text) => onTextInputChnage(text)}
            defaultValue={values[0] || ""}
          />
        </TextInputWrapper>
      )}
    </div>
  );
};
