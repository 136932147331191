import React, { Fragment } from "react";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../../colors";
import { PlanItemForm } from "../Modals/CreateDrill/PlanItemForm";
import { WorkoutPreviewTimeForm } from "../WorkoutEditor/WorkoutPreviewTimeForm";

import { TextType } from "./model";
import { replaceWithBr } from "./utils";

type TextModuleProps = {
  data: TextType[];
  formMethods;
  id?: string;
  isPreviewWorkout?: boolean;
  splitAreaId?: string;
};

const ModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Wrapper = styled.div`
  margin-bottom: 20px;
`;

const TitleWrapper = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${COLOR_BLUE};
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${COLOR_TEXT_DARK};
`;

export function TextModule({
  data,
  formMethods,
  id,
  isPreviewWorkout,
  splitAreaId,
}: TextModuleProps) {
  return (
    <ModuleWrapper>
      <Wrapper>
        {data && data.length
          ? data.map(({ title, text }, index) => (
              <Fragment key={index}>
                <TitleWrapper>
                  <Title>{title}</Title>
                </TitleWrapper>
                <Text
                  dangerouslySetInnerHTML={{ __html: replaceWithBr(text) }}
                />
              </Fragment>
            ))
          : null}
        {formMethods ? (
          isPreviewWorkout ? (
            <WorkoutPreviewTimeForm formMethods={formMethods} id={id} />
          ) : (
            <PlanItemForm
              formMethods={formMethods}
              id={id}
              splitAreaId={splitAreaId}
            />
          )
        ) : null}
      </Wrapper>
    </ModuleWrapper>
  );
}
