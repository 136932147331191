import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { AllAgendaData } from "../../contexts/agenda/AgendaDataProvider";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { AgendaQuery } from "../../graphql";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { EventType } from "../Filters/model";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import { DayBlock } from "./components/DayBlock";
import { MonthsWithDays, RefetchAgendaFunc } from "./model";
import { groupBy } from "./utils/groupBy";

type Props = {
  data: AgendaQuery | AllAgendaData;
  loading: boolean;
  refetch: RefetchAgendaFunc;
};

const AgendaWrapper = styled.div<{ hasDaysToShow: boolean }>`
  margin-top: 10px;
  width: 100%;
  height: ${({ hasDaysToShow }) => (hasDaysToShow ? "auto" : "100%")};
  display: ${({ hasDaysToShow }) => (hasDaysToShow ? "block" : "flex")};
`;

const DayBlockWrapper = styled.div`
  padding: 0 10px 10px 10px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

export function Agenda({ data, loading, refetch }: Props) {
  const didMountRef = useRef(false);
  const { t } = useTranslation();
  const { selectedEventTypes } = useAgendaContext();
  let monthsWithDays: MonthsWithDays = [];

  useEffect(() => {
    if (!didMountRef.current) {
      Storage.removeItem(StorageKeys.practiceForm);
      Storage.removeItem(StorageKeys.gameOrEvent);
      didMountRef.current = true;
    }
  });

  if (data && data.agenda) {
    const agendaData =
      data && data.agenda ? data.agenda.edges.map((edge) => edge.node) : [];

    const filteredAgendaData = agendaData
      .filter((item) =>
        selectedEventTypes?.length
          ? selectedEventTypes.includes(
              item.__typename.toLowerCase() as EventType
            )
          : true
      )
      .map((agendaItem) =>
        agendaItem.__typename === "Questionnaire"
          ? { ...agendaItem, start: agendaItem.calTime }
          : agendaItem
      )
      .sort((a: any, b: any) =>
        a.start > b.start
          ? 1
          : a.start === b.start
            ? a.label?.localeCompare(b.label)
            : -1
      )
      .map((agendaItem, index) => ({ ...agendaItem, index }));

    const gropedByMonth = groupBy(filteredAgendaData, "MMMM");

    monthsWithDays = Object.keys(gropedByMonth).map((month) => {
      const groupedByDays = groupBy(gropedByMonth[month], "YYYY-MM-DD");

      return { month, days: groupedByDays };
    });
  }

  const hasDaysToShow = !loading && monthsWithDays?.length > 0;

  return (
    <AgendaWrapper hasDaysToShow={hasDaysToShow}>
      {hasDaysToShow ? (
        monthsWithDays?.map(({ month, days }, index) => (
          <DayBlockWrapper
            key={month}
            style={{
              zIndex: index === 0 ? 2 : 1,
            }}
          >
            {Object.keys(days).map((day) => (
              <DayBlock key={day} refetch={refetch} day={day} days={days} />
            ))}
          </DayBlockWrapper>
        ))
      ) : (
        <EmptyWrapper>
          {loading ? (
            <Loader size="large" />
          ) : (
            <StyledText>{t("noSessionsFound")}</StyledText>
          )}
        </EmptyWrapper>
      )}
    </AgendaWrapper>
  );
}
