import { useUserContext } from "../../contexts/User";
import { useReportBuildingAttributeTemplatesQuery } from "../../graphql";

interface IUseReportBuildingMeasurementsProps {
  collectionId: string;
  measurementTemplateId: string;
  shouldSkip?: boolean;
}

export const useReportBuildingAttributes = ({
  collectionId,
  measurementTemplateId,
  shouldSkip = false,
}: IUseReportBuildingMeasurementsProps) => {
  const { sessionId, language } = useUserContext();

  return useReportBuildingAttributeTemplatesQuery({
    variables: { sessionId, collectionId, measurementTemplateId, language },
    skip: shouldSkip,
    fetchPolicy: "no-cache",
  });
};
