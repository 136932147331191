import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export type PrintState = {
  mainTitle: string;
  reportIDs: string[];
  logoFlag: boolean;
  periodFlag: boolean;
  teamNameFlag: boolean;
  athleteListFlag: boolean;
  experimentalGrid: boolean;
  landscapeOrientation: boolean;
};

type FlagSetter = (flag: boolean) => void;

interface PrintGroupReportContextValue extends PrintState {
  setMainTitle: (title: string) => void;
  setReportIDs: (ids: string[]) => void;
  setLogoFlag: FlagSetter;
  setPeriodFlag: FlagSetter;
  setTeamNameFlag: FlagSetter;
  setAthleteListFlag: FlagSetter;
  setExperimentalGridFlag: FlagSetter;
  setLandscapeOrientationFlag: FlagSetter;
}

const PrintGroupReportContext = createContext<PrintGroupReportContextValue>({
  mainTitle: "",
  reportIDs: [],
  logoFlag: true,
  periodFlag: true,
  teamNameFlag: true,
  athleteListFlag: true,
  experimentalGrid: false,
  landscapeOrientation: false,
  setMainTitle: () => ({}),
  setReportIDs: () => ({}),
  setLogoFlag: () => ({}),
  setPeriodFlag: () => ({}),
  setTeamNameFlag: () => ({}),
  setAthleteListFlag: () => ({}),
  setExperimentalGridFlag: () => ({}),
  setLandscapeOrientationFlag: () => ({}),
});

export function PrintGroupReportContextProvider({
  children,
}: PropsWithChildren<object>) {
  const [printState, setPrintState] = useState<PrintState>({
    mainTitle: "",
    reportIDs: [],
    logoFlag: true,
    periodFlag: true,
    teamNameFlag: true,
    athleteListFlag: true,
    experimentalGrid: false,
    landscapeOrientation: false,
  });

  const actions = useMemo(
    () => ({
      setMainTitle: (mainTitle: string) =>
        setPrintState((old) => ({ ...old, mainTitle })),
      setReportIDs: (reportIDs: string[]) =>
        setPrintState((old) => ({ ...old, reportIDs })),
      setLogoFlag: (logoFlag: boolean) =>
        setPrintState((old) => ({ ...old, logoFlag })),
      setPeriodFlag: (periodFlag: boolean) =>
        setPrintState((old) => ({ ...old, periodFlag })),
      setTeamNameFlag: (teamNameFlag: boolean) =>
        setPrintState((old) => ({ ...old, teamNameFlag })),
      setAthleteListFlag: (athleteListFlag: boolean) =>
        setPrintState((old) => ({ ...old, athleteListFlag })),
      setExperimentalGridFlag: (experimentalGrid: boolean) =>
        setPrintState((old) => ({ ...old, experimentalGrid })),
      setLandscapeOrientationFlag: (landscapeOrientation: boolean) =>
        setPrintState((old) => ({ ...old, landscapeOrientation })),
    }),
    []
  );

  return (
    <PrintGroupReportContext.Provider value={{ ...printState, ...actions }}>
      {children}
    </PrintGroupReportContext.Provider>
  );
}

export const usePrintGroupReportContext = () =>
  useContext(PrintGroupReportContext);
