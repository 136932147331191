import { getServerBasePath } from "../utils/getServerBasePath";

import * as api from "./api";

export interface BlogPost {
  title: string;
  info: string;
  body: string;
  imageElement: string;
  link: string;
}

export interface BlogPostsData {
  posts: BlogPost[];
}

export async function fetchBlogArticles() {
  const response = await api.get<BlogPostsData>(
    `${getServerBasePath()}/xpsweb/latest-blog?language=en&safe=false&pretty=true`
  );

  if (response.status !== 200) {
    return null;
  }

  return response;
}
