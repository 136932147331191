import React from "react";
import { HeaderProps } from "react-big-calendar";

import styled from "styled-components";

import { COLOR_SECONDARY_GREY } from "../../colors";

const MonthHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-content: center;
  font-weight: 500;
  font-size: 10px;
  color: ${COLOR_SECONDARY_GREY};

  margin: 0 6px 6px 0;
`;

export const CustomCalendarMonthHeader = ({ label }: HeaderProps) => {
  return <MonthHeaderWrapper>{label.toUpperCase()}</MonthHeaderWrapper>;
};
