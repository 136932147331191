import React, { useRef } from "react";
import { ColorResult, SketchPicker } from "react-color";

import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";
import { BasicButton } from "../Button";
import { ChevronRightIcon } from "../Icons";

interface ColorPickerProps {
  onColorChange: (colorHex: string) => void;
  currentColor: string;
  graySectionSize?: number;
  colorSectionSize?: number;
  isRightZero?: boolean;
  hasArrow?: boolean;
}

const PRESET_COLORS = [
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#8B572A",
  "#7ED321",
  "#417505",
  "#BD10E0",
  "#9013FE",
  "#4A90E2",
  "#50E3C2",
  "#B8E986",
  "#000000",
  "#4A4A4A",
  "#9B9B9B",
  "#FFFFFF",
];

const ColorPickerWrapper = styled.div`
  position: relative;
`;

const Wrapper = styled.div<{
  currentColor: string;
  graySectionSize: number;
  hasArrow: boolean;
}>`
  display: flex;
  flex-direction: ${({ hasArrow }) => (hasArrow ? "row" : "column")};
  justify-content: center;
  align-items: center;
  width: ${({ graySectionSize, hasArrow }) =>
    hasArrow ? "auto" : graySectionSize}px;
  height: ${({ graySectionSize, hasArrow }) =>
    hasArrow ? 45 : graySectionSize}px;
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  padding: ${({ hasArrow }) => (hasArrow ? "0 10px" : 0)};
`;

const PickerWrapper = styled.div<{ top: number; isRightZero?: boolean }>`
  display: flex;
  cursor: pointer;
  position: absolute;
  top: ${({ top }) => top}px;
  right: ${({ isRightZero }) => (isRightZero ? `0px` : undefined)};
`;

const Color = styled.div<{
  currentColor: string;
  colorSectionSize: number;
  hasArrow: boolean;
}>`
  display: flex;
  border-radius: 6px;
  background-color: ${({ currentColor }) => currentColor};
  margin-right: ${({ hasArrow }) => (hasArrow ? "16px" : 0)};
  width: ${({ colorSectionSize }) => colorSectionSize}px;
  height: ${({ colorSectionSize }) => colorSectionSize}px;
`;

export const ColorPicker = ({
  onColorChange,
  currentColor,
  graySectionSize = 40,
  colorSectionSize = 30,
  isRightZero = false,
  hasArrow,
}: ColorPickerProps) => {
  const [isPickerOpen, setIsPickerOpen] = React.useState(false);
  const [presetColors, setPresetColors] = React.useState(PRESET_COLORS);
  const colorPickerRef = useRef(null);

  useHandleClickOutside(colorPickerRef, () => setIsPickerOpen(false));

  const handleColorChange = (color: ColorResult) => {
    const newPresetColors = [...presetColors];
    newPresetColors.pop();
    setPresetColors([
      color.hex,
      ...newPresetColors.filter(
        (col) => col.toUpperCase() !== color.hex.toUpperCase()
      ),
    ]);
    onColorChange(color.hex);
  };

  return (
    <ColorPickerWrapper>
      <BasicButton onClick={() => setIsPickerOpen((isOpen) => !isOpen)}>
        <Wrapper
          currentColor={currentColor}
          graySectionSize={graySectionSize}
          hasArrow={hasArrow}
        >
          <Color
            currentColor={currentColor}
            colorSectionSize={colorSectionSize}
            hasArrow={hasArrow}
          />
          {hasArrow && <ChevronRightIcon direction="bottom" />}
        </Wrapper>
      </BasicButton>
      {isPickerOpen && (
        <PickerWrapper
          top={graySectionSize}
          isRightZero={isRightZero}
          ref={colorPickerRef}
        >
          <SketchPicker
            disableAlpha
            color={currentColor}
            onChangeComplete={handleColorChange}
            presetColors={presetColors}
          />
        </PickerWrapper>
      )}
    </ColorPickerWrapper>
  );
};
