import { Reducer } from "react";

import actions from "./constants";
import { UserContextState } from "./types";

const reducer: Reducer<UserContextState, any> = (state, action) => {
  switch (action.type) {
    case actions.SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_USER_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      };
    case actions.LOGOUT:
      return {
        sessionId: null,
        timezone: null,
        language: null,
      };
    default:
      return state;
  }
};

export default reducer;
