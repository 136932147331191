import React, { PropsWithChildren, CSSProperties } from "react";

import { isNumber } from "lodash";
import styled from "styled-components";

import {
  COLOR_TEXT_DARK,
  COLOR_PRIMARY_BLUE,
  COLOR_SECONDARY_GREY,
  COLOR_WHITE,
} from "../colors";
import { getDeviceType } from "../utils/getDeviceType";

export interface StyledTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  // fontWeight props
  light?: boolean;
  regular?: boolean;
  semibold?: boolean;
  medium?: boolean;
  bold?: boolean;
  fontWeight?: "light" | "normal" | "medium" | "semibold" | "bold" | number;

  // color props
  white?: boolean;
  grey?: boolean;
  blue?: boolean;
  dark?: boolean;
  color?: string;

  // rest
  fontSize?: number;
  centerText?: boolean;
  numberOfLines?: number;
  ellipsizeMode?: "head" | "tail";
  deviceType?: string;
  cursor?: CSSProperties["cursor"];
}

export const StyledText = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<StyledTextProps>
>((props, ref) => {
  const {
    // fontWeight props
    light,
    regular,
    semibold,
    medium,
    bold,
    fontWeight: fontWeightProp,

    // color props
    white,
    grey,
    blue,
    dark,
    color: colorProp,

    // rest
    ...restProps
  } = props;

  const deviceType = getDeviceType();

  let fontWeight: StyledTextProps["fontWeight"];
  switch (true) {
    case !!fontWeightProp:
      fontWeight = fontWeightProp;
      break;
    case light:
      fontWeight = deviceType === "web" ? 200 : "light";
      break;
    case regular:
      fontWeight = "normal";
      break;
    case semibold:
    case medium:
      fontWeight = deviceType === "web" ? 600 : "light";
      break;
    case bold:
      fontWeight = "bold";
      break;
    default:
      fontWeight = deviceType === "web" ? 400 : "normal";
  }

  let color: StyledTextProps["color"];
  switch (true) {
    case !!colorProp:
      color = colorProp;
      break;
    case white:
      color = COLOR_WHITE;
      break;
    case grey:
      color = COLOR_SECONDARY_GREY;
      break;
    case blue:
      color = COLOR_PRIMARY_BLUE;
      break;
    case dark:
    default:
      color = COLOR_TEXT_DARK;
      break;
  }

  return (
    <Text
      ref={ref as any}
      fontWeight={fontWeight}
      color={color}
      deviceType={deviceType}
      {...restProps}
    >
      {props.children}
    </Text>
  );
});

const Text = styled.div<StyledTextProps>`
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid black;
  box-sizing: border-box;
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: start;
  text-decoration: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  ${({ color }) => `color: ${color}`};
  ${({ fontSize }) => (isNumber(fontSize) ? `font-size: ${fontSize}px` : "")}};
  ${({ cursor }) => (cursor ? `cursor: ${cursor}` : "")}};
  ${({ fontWeight, deviceType }) =>
    deviceType === "web"
      ? `font-weight: ${fontWeight}`
      : `font-family: ${fontFamilyRecord[fontWeight]}`};

  ${({ numberOfLines }) =>
    numberOfLines === 1
      ? `
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;

    white-space: nowrap;
    overflow-wrap: normal;
  `
      : numberOfLines > 1
        ? `
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${numberOfLines};
    `
        : ""};
  ${({ numberOfLines, ellipsizeMode }) => (numberOfLines >= 1 && ellipsizeMode === "tail" ? "direction: rtl" : "")}};
`;

const fontFamilyRecord = {
  light: "open-sans-light",
  normal: "open-sans-regular",
  semibold: "open-sans-semibold",
  bold: "open-sans-bold",
};

export default StyledText;
