import { useEffect, useState } from "react";

import { UserPreferenceKeys } from "../../../constants";
import { useUserContext } from "../../../contexts/User";
import {
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
} from "../../../graphql";

export const useToggleShowOptionalAthletes = () => {
  const { sessionId } = useUserContext();
  const [localShowOptionalAthletes, setLocalShowOptionalAthletes] = useState<
    boolean | null
  >(null);
  const [getPreference] = useGetPreferenceLazyQuery({
    variables: {
      key: UserPreferenceKeys.SHOW_OPTIONAL_ATHLETES,
      sessionId,
    },
  });
  const [setPreference] = usePutPreferenceMutation();

  useEffect(() => {
    (async () => {
      if (localShowOptionalAthletes === null) {
        const res = await getPreference();
        const parsedRes = JSON.parse(res.data?.getPreference ?? "");
        if (typeof parsedRes === "boolean") {
          setLocalShowOptionalAthletes(parsedRes);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localShowOptionalAthletes]);

  const toggleShowOptionalAthletes = async () => {
    const newValue = !localShowOptionalAthletes;

    setLocalShowOptionalAthletes(newValue);
    const res = await setPreference({
      variables: {
        key: UserPreferenceKeys.SHOW_OPTIONAL_ATHLETES,
        value: JSON.stringify(newValue),
        sessionId,
      },
    });
    const parsedRes = JSON.parse(res.data?.putPreference ?? "");

    if (typeof parsedRes === "boolean") {
      setLocalShowOptionalAthletes(parsedRes);
    }
  };

  return {
    showOptionalAthletes: localShowOptionalAthletes,
    toggleShowOptionalAthletes,
  };
};
