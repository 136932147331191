import React from "react";

import { PracticeLine } from "./model";
import { PlanItem } from "./PlanItem";
import { SplitItem } from "./SplitItem";

type SpecificPracticeLineProps = {
  practiceLine: PracticeLine;
  index: number;
  splitAreaId?: string;
  splitItemId?: string;
};

export function SpecificPracticeLine({
  practiceLine,
  index,
  splitItemId,
  splitAreaId,
}: SpecificPracticeLineProps) {
  const type = practiceLine.__typename;

  if (type === "Split") {
    return (
      <SplitItem
        key={practiceLine.id}
        practiceLine={practiceLine}
        index={index}
      />
    );
  }

  if (
    type === "PracticeInlineComment" ||
    type === "InlineComment" ||
    type === "SubPractice"
  ) {
    return (
      <PlanItem
        key={practiceLine.id}
        inlineComment={practiceLine}
        index={index}
        splitAreaId={splitAreaId}
        splitItemId={splitItemId}
      />
    );
  }

  return (
    <PlanItem
      key={practiceLine.id}
      practiceLine={practiceLine}
      index={index}
      splitAreaId={splitAreaId}
      splitItemId={splitItemId}
    />
  );
}
