import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../colors";
import { StyledCard } from "../StyledCard";

import { SideBarSearchInput } from "./Agenda/components/SideBarSearchInput";
import Dropdown from "./Dropdown";
import { ChevronRightIcon, PlusIcon, SearchIcon, ThreeDotsIcon } from "./Icons";
import StyledText from "./StyledText";

type Props = {
  label: string;
  count?: number;
  withoutTopMargin?: boolean;
  withMaxHeight?: boolean;
  children(searchValue: string, isSelectAll: boolean): ReactNode;
  style?: CSSProperties;
  collapseStyle?: CSSProperties;
  width?: number;
  showDots?: boolean;
  dropdownContent?: ReactNode;
  onAddPress?: () => void;
  customDropdownContainerStyle?: CSSProperties;
};

const Card = styled(StyledCard)<{
  isCollapsed: boolean;
  withoutTopMargin: boolean;
  withMaxHeight: boolean;
  width?: number;
}>`
  width: ${({ width = 240 }) => width}px;
  border-radius: 6px;
  height: 100%;
  margin: ${({ withoutTopMargin }) =>
    withoutTopMargin ? "0 0 10px 10px" : "10px 0 10px 10px"};
  ${({ isCollapsed }) => !isCollapsed && `height:  45px`};
  ${({ withMaxHeight }) => (withMaxHeight ? `max-height: 350px` : "")};
  overflow: auto;
  border: 1px solid rgba(230, 232, 235, 0.35);
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
`;

const CollapsibleWrapper = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  box-sizing: border-box;
  height: 45px;
  border-bottom-width: ${({ isCollapsed }) => `${isCollapsed ? 1 : 0}px`};
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 18.5px;
  z-index: 2;
  position: sticky;
  top: 0;
  background-color: ${COLOR_WHITE};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChevronButtonWrapper = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
  margin-right: 14px;
  padding: 0;
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconButtonWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const Label = styled(StyledText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
  margin-right: 15px;
  cursor: default;
`;

const CountWrapper = styled.div`
  background-color: ${COLOR_TEXT_TABLE_LABEL};
  padding: 0 10px;
  border-radius: 15px;
  height: 21px;
`;

const Count = styled.span`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 21px;
  color: ${COLOR_WHITE};
`;

const CardContent = styled.div`
  display: block;
  z-index: 1;
`;

const PlusButtonWrapper = styled.div`
  cursor: pointer;
  height: 35px;
  width: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export function SideBar({
  label,
  count,
  withoutTopMargin = false,
  withMaxHeight = false,
  children,
  style,
  collapseStyle,
  width,
  showDots = false,
  dropdownContent,
  onAddPress,
  customDropdownContainerStyle,
}: Props) {
  const [isCollapsed, setCollapsed] = useState(true);
  const [isSelectAll] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const handleClearPress = useCallback(() => {
    if (!searchValue) {
      setSearchVisible(false);
    }

    setSearchValue("");
  }, [searchValue, setSearchVisible]);

  useEffect(() => {
    if (searchValue && !isCollapsed) {
      setCollapsed(true);
    }
  }, [isCollapsed, searchValue]);

  return (
    <Card
      isCollapsed={isCollapsed}
      withoutTopMargin={withoutTopMargin}
      withMaxHeight={withMaxHeight}
      width={width}
      style={{ ...style, ...(isCollapsed ? collapseStyle : {}) }}
    >
      <CollapsibleWrapper isCollapsed={isCollapsed}>
        {!isSearchVisible && (
          <LabelWrapper>
            <ChevronButtonWrapper onClick={() => setCollapsed(!isCollapsed)}>
              <ChevronRightIcon
                tintColor={COLOR_SECONDARY_GREY_HOVER}
                direction={isCollapsed ? "bottom" : "right"}
              />
            </ChevronButtonWrapper>
            <Label>{label}</Label>
            {count > 0 && (
              <CountWrapper>
                <Count>{count}</Count>
              </CountWrapper>
            )}
          </LabelWrapper>
        )}
        <ButtonWrapper>
          {isSearchVisible && (
            <SideBarSearchInput
              smaller={showDots}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              onClearPress={handleClearPress}
            />
          )}
          {!isSearchVisible && (
            <IconButtonWrapper
              onClick={() => setSearchVisible(!isSearchVisible)}
            >
              <SearchIcon width={16} height={16} color={COLOR_BLUE} />
            </IconButtonWrapper>
          )}
          {onAddPress ? (
            <PlusButtonWrapper onClick={onAddPress}>
              <PlusIcon />
            </PlusButtonWrapper>
          ) : null}
          {showDots ? (
            <Dropdown
              dropdownContainerStyle={{
                marginRight: 0,
                marginTop: 25,
                padding: 0,
                width: "fit-content",
                minWidth: 120,
                ...customDropdownContainerStyle,
              }}
              dropdownContentStyle={{
                padding: "5px 0",
              }}
              component={({ toggle, show }) => (
                <IconButtonWrapper
                  onClick={() => {
                    toggle();
                    !show && !isCollapsed && setCollapsed(true);
                  }}
                  style={{ marginLeft: 5 }}
                >
                  <ThreeDotsIcon />
                </IconButtonWrapper>
              )}
            >
              {dropdownContent}
            </Dropdown>
          ) : null}
        </ButtonWrapper>
      </CollapsibleWrapper>
      {isCollapsed && (
        <CardContent>{children(searchValue, isSelectAll)}</CardContent>
      )}
    </Card>
  );
}
