import React from "react";

import { IWidgetResizeProps } from "../../../hooks";
import { KeyValuesWidgetModel } from "../../ReportCreateTable/models";
import { WidgetLoader } from "../WidgetLoader";

import { KeyValuesWidgetComponent } from "./components/KeyValuesWidgetComponent";
import { useKeyValuesWidgetData } from "./hooks/useKeyValuesWidgetData";

type KeyValuesWidgetProps = {
  widget: KeyValuesWidgetModel;
  readOnly: boolean;
} & IWidgetResizeProps;

export function KeyValuesWidget({
  widget,
  readOnly,
  ...resizeProps
}: KeyValuesWidgetProps) {
  const { data, isLoading } = useKeyValuesWidgetData(widget);

  if (isLoading) {
    return <WidgetLoader />;
  }

  return (
    <KeyValuesWidgetComponent
      widget={widget}
      data={data}
      isReportViewMode={readOnly}
      {...resizeProps}
    />
  );
}
