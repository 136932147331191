import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { BlogPost } from "../../../services/blog";

export const BlogWrapper = styled.div`
  min-height: 300px;
  height: 100%;
  width: 100%;
  border: none;
  overflow-y: scroll;
`;

const BlogItemTitle = styled.h2`
  font-size: 30px;
  font-weight: 600;
  color: #172d4f;
`;

const Separator = styled.div`
  position: relative;
  display: block;
  height: 1px;
  border-bottom: 1px solid #b2b2b2;
  margin: 10px 0;
`;

const BlogPreview = styled.p`
  font-size: 16px;
  line-height: 28px;
  color: #666666;
`;

const BlogPostInfo = styled.p`
  font-size: 12px;
  padding: 5px 0px;
  text-transform: uppercase;
  font-weight: 500;
  color: #abbfdc;
`;

const BlogPostLink = styled.a`
  margin: 20px 0;
  color: #172d4f !important;

  :hover {
    color: #d6de23 !important;
  }
`;

const ImageWrapperLink = styled.a`
  img {
    max-width: 100%;
    height: auto;
  }
`;

export const BlogItem = ({ blogPost }: { blogPost: BlogPost }) => {
  const { t } = useTranslation();

  return (
    <div>
      <BlogItemTitle>{blogPost.title}</BlogItemTitle>
      <Separator />
      <BlogPostInfo>{blogPost.info}</BlogPostInfo>
      <ImageWrapperLink
        href={blogPost.link}
        target="_blank"
        dangerouslySetInnerHTML={{ __html: blogPost.imageElement }}
      />
      <BlogPreview>{blogPost.body}</BlogPreview>
      <BlogPostLink href={blogPost.link} target="_blank">
        {t("visitOurBlog")}
      </BlogPostLink>
    </div>
  );
};
