import React, { memo } from "react";

export const CameraIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <mask id="path-1-inside-1_3898_47290" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 6L7.4 4.8C7.77771 4.29639 8.37049 4 9 4H12C12.6295 4 13.2223 4.29639 13.6 4.8L14.5 6H17C18.1046 6 19 6.89543 19 8V15C19 16.1046 18.1046 17 17 17H4C2.89543 17 2 16.1046 2 15V8C2 6.89543 2.89543 6 4 6H6.5Z"
      />
    </mask>
    <path
      d="M7.4 4.8L6.6 4.2L6.6 4.2L7.4 4.8ZM6.5 6V7H7L7.3 6.6L6.5 6ZM13.6 4.8L12.8 5.4V5.4L13.6 4.8ZM14.5 6L13.7 6.6L14 7H14.5V6ZM6.6 4.2L5.7 5.4L7.3 6.6L8.2 5.4L6.6 4.2ZM9 3C8.05573 3 7.16656 3.44458 6.6 4.2L8.2 5.4C8.38885 5.14819 8.68524 5 9 5V3ZM12 3H9V5H12V3ZM14.4 4.2C13.8334 3.44458 12.9443 3 12 3V5C12.3148 5 12.6111 5.14819 12.8 5.4L14.4 4.2ZM15.3 5.4L14.4 4.2L12.8 5.4L13.7 6.6L15.3 5.4ZM14.5 7H17V5H14.5V7ZM17 7C17.5523 7 18 7.44772 18 8H20C20 6.34315 18.6569 5 17 5V7ZM18 8V15H20V8H18ZM18 15C18 15.5523 17.5523 16 17 16V18C18.6569 18 20 16.6569 20 15H18ZM17 16H4V18H17V16ZM4 16C3.44772 16 3 15.5523 3 15H1C1 16.6569 2.34315 18 4 18V16ZM3 15V8H1V15H3ZM3 8C3 7.44772 3.44772 7 4 7V5C2.34315 5 1 6.34315 1 8H3ZM4 7H6.5V5H4V7Z"
      fill="#64707A"
      mask="url(#path-1-inside-1_3898_47290)"
    />
    <circle cx="10.5" cy="11.5" r="3" stroke="#64707A" />
  </svg>
));
