import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL } from "../../colors";

export function DescriptionCell({
  value,
  comment,
}: {
  value: string;
  comment: string;
}) {
  return (
    <DescriptionCellWrap>
      <DescriptionValue>{value}</DescriptionValue>
      <DescriptionComment>{comment}</DescriptionComment>
    </DescriptionCellWrap>
  );
}

const DescriptionCellWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 700;
  cursor: default;
`;

const DescriptionValue = styled.div`
  display: block;
  width: 100%;
  text-transform: uppercase;
`;

const DescriptionComment = styled.div`
  display: block;
  width: 100%;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;
