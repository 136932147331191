import React from "react";

import styled from "styled-components";

import { useSelectedPerformersContext } from "../../contexts/selectedAthletes";
import { useUserContext } from "../../contexts/User";
import { Group, XpsUser } from "../../graphql";
import { sortList } from "../../utils/sortList";

import { SelectedPerformerCircle } from "./SelectedAthleteCircle";

interface SelectedAthletesListProps {
  confirmOnDeletion?: boolean;
}

const Wrapper = styled.div`
  z-index: 0;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) =>
    !theme.isMobile ? "15px 20px 20px 20px" : "15px 5px 0"};
`;

const AthleteListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
`;

export const SelectedAthletesList = ({
  confirmOnDeletion = false,
}: SelectedAthletesListProps) => {
  const { language } = useUserContext();
  const {
    attendingUsers,
    attendingTeams,
    setAttendingUsers,
    setAttendingTeams,
    confirmSelectedAthletes,
    confirmSelectedTeams,
  } = useSelectedPerformersContext();

  const onDeleteAthletePress = (userToRemove: XpsUser) => {
    const currentAttendingUsers = attendingUsers.filter(
      (auser) => auser.id !== userToRemove.id
    );

    if (confirmOnDeletion) {
      confirmSelectedAthletes(currentAttendingUsers);
    } else {
      setAttendingUsers(currentAttendingUsers);
    }
  };

  const onDeleteGroupPress = (teamToRemove: Partial<Group>) => {
    const currentAttendingTeams = attendingTeams.filter(
      (attendingTeam) => attendingTeam.id !== teamToRemove.id
    );

    if (confirmOnDeletion) {
      confirmSelectedTeams(currentAttendingTeams);
    } else {
      setAttendingTeams(currentAttendingTeams);
    }
  };

  return (
    (!!attendingUsers.length || !!attendingTeams.length) && (
      <Wrapper>
        <AthleteListWrapper>
          {sortList([...attendingTeams], language).map((group) => (
            <SelectedPerformerCircle
              key={group.id}
              onDeleteClick={() => onDeleteGroupPress(group)}
              performer={group}
            />
          ))}

          {sortList([...attendingUsers], language).map((user) => (
            <SelectedPerformerCircle
              key={user.id}
              onDeleteClick={() => onDeleteAthletePress(user)}
              performer={user}
            />
          ))}
        </AthleteListWrapper>
      </Wrapper>
    )
  );
};
