import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY } from "../../../colors";
import { SelectedAthletesList } from "../../GroupReport/SelectedAthletesList";
import { SearchIcon, CrossFilledIcon } from "../../Icons";

const SearchBarWrapper = styled.div`
  margin: 20px;
  height: 45px;
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  display: flex;
`;

const SearchIconWrapper = styled.div`
  margin-right: 10px;
`;

const Input = styled.input`
  width: 100%;
  height: 25px;
  background-color: transparent;
  border: none;
`;

const CancelButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  background: transparent;
  right: 20px;
  cursor: pointer;
`;

export const AthletesSelectorHeader = ({ search, setSearch }) => {
  const { t } = useTranslation();

  return (
    <>
      <SearchBarWrapper>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder={t("search")}
          autoFocus
        />
        {!!search && (
          <CancelButton onClick={() => setSearch("")}>
            <CrossFilledIcon />
          </CancelButton>
        )}
      </SearchBarWrapper>
      <SelectedAthletesList />
    </>
  );
};
