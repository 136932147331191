import { useNavigate, useSearchParams } from "react-router-dom";

import { createSearchParamsWithoutUndefinedValues } from "../../../utils/createSearchParamsWithoutUndefinedValues";
import { WidgetType } from "../../ReportCreateTable/models";
import { WidgetMode } from "../WidgetWithData";

interface IWidgetNavigationData {
  id: string;
  type: string;
}

interface NavigateToWidgetDetailParams {
  widget: IWidgetNavigationData;
  mode: WidgetMode;
  reportId?: string;
  parentReportId?: string;
  layoutIndex?: number;
  activeTabId?: string;
  activeProfileTabId?: string;
  editReportWidget?: boolean;
}

export const useNavigateToWidgetDetail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateToWidgetDetail = ({
    widget,
    mode,
    reportId,
    parentReportId,
    editReportWidget,
    layoutIndex,
    ...rest
  }: NavigateToWidgetDetailParams) => {
    const toWidgetDetail = Boolean(reportId || parentReportId);
    const widgetId = widget.id;

    const baseParams = {
      parentReportId,
      isWidgetTemplate: !toWidgetDetail ? "true" : undefined,
      editReportWidget: editReportWidget ? "true" : undefined,
      layoutIndex: layoutIndex?.toString(),
      ...(searchParams.get("fromMonitoring") ? { fromMonitoring: "true" } : {}),
      ...rest,
    };

    switch (widget.type) {
      case WidgetType.CHART:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/chart/${widgetId}`
            : `/widgets/chart/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues({
            ...baseParams,
            isGroupWidget: mode === WidgetMode.GROUP ? "true" : undefined,
          }),
        });

      case WidgetType.TABLE: {
        let redirectPathname;

        if (mode === WidgetMode.GROUP) {
          redirectPathname = toWidgetDetail
            ? `/reports/${reportId}/group-table/${widgetId}`
            : `/widgets/group-table/${widgetId}`;
        } else {
          redirectPathname = toWidgetDetail
            ? `/reports/${reportId}/athlete-table/${widgetId}`
            : `/widgets/athlete-table/${widgetId}`;
        }

        return navigate({
          pathname: redirectPathname,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });
      }
      case WidgetType.KEY_VALUES:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/key-values/${widgetId}`
            : `/widgets/key-values/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues({
            ...baseParams,
            isGroupWidget: mode === WidgetMode.GROUP ? "true" : undefined,
          }),
        });
      case WidgetType.COMBINED_TEST:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/combined-test/${widgetId}`
            : `/widgets/combined-test/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues({
            ...baseParams,
            isGroupWidget: mode === WidgetMode.GROUP ? "true" : undefined,
          }),
        });
      case WidgetType.INFORMATION_LOG:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/information/${widgetId}`
            : `/widgets/information/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });
      case WidgetType.ACTIVITY_LOG:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/activity/${widgetId}`
            : `/widgets/activity/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });

      case WidgetType.ATTENDANCE_LOG:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/attendance-log/${widgetId}`
            : `/widgets/attendance-log/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });

      case WidgetType.ACUTE_CHRONIC:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/acute-chronic/${widgetId}`
            : `/widgets/acute-chronic/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });

      case WidgetType.ATHLETE_TABLE_PERIODIZATION:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/athlete-table-periodization/${widgetId}`
            : `/widgets/athlete-table-periodization/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });

      case WidgetType.GROUP_TABLE_PERIODIZATION:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/group-table-periodization/${widgetId}`
            : `/widgets/group-table-periodization/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues({
            ...baseParams,
            isGroupWidget: mode === WidgetMode.GROUP ? "true" : undefined,
          }),
        });

      case WidgetType.BODY_CHART:
        return navigate({
          pathname: toWidgetDetail
            ? `/reports/${reportId}/body-chart/${widgetId}`
            : `/widgets/body-chart/${widgetId}`,
          search: createSearchParamsWithoutUndefinedValues(baseParams),
        });
    }
  };

  return {
    navigateToWidgetDetail,
  };
};
