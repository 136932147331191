import * as api from "./api";
import * as endpoints from "./endpoints";

export type TrainerViewableGroupsQueryArgs = {
  session: api.Session;
};

export type TrainerViewableGroupsQueryResponse = {
  tree: {
    _checked: boolean;
    _editable: boolean;
    _folder: boolean;
    _groupChat: boolean;
    _groupGuid: string;
    _name: string;
    _owner: string;
    _ownerFirstName: string;
    _ownerLastName: string;
    _ownerName: string;
    _polar: boolean;
    _teamAthleteGuid: string;
  }[];
};

export async function fetchTrainerViewableGroups(session: api.Session) {
  const response = await api.post<TrainerViewableGroupsQueryResponse>(
    endpoints.JsonViewableGroupsQuery,
    {
      _supportTrainerToTrainer: true,
    },
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
}
