import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK, COLOR_WHITE } from "../../../colors";
import {
  TopModalType,
  useTopModalContext,
} from "../../../contexts/UI/TopModal";
import { useUserContext } from "../../../contexts/User";
import {
  Exercise,
  Cell,
  useAppendSetMutation,
  useDeleteSetMutation,
  useExerciseSetsQuery,
  useUpdateAttributeMutation,
} from "../../../graphql";
import { ExerciseSets } from "../../../graphql/queries/ExerciseSets";
import { isAcesNationEnv } from "../../../utils/isAcesNationEnv";
import {
  ButtonType,
  ButtonColor,
  GeneralButton,
  AddRemoveButton,
} from "../../Button/";
import { COLORS, convertIdToVariant } from "../../Collections/utils";
import Hoverable from "../../Hoverable";
import { LogoAcesNationIcon, LogoXpsIcon } from "../../Icons";

import { endsWithPunctuation } from "./utils";
import { WorkoutEditModeSection } from "./WorkoutEditModeSection";
import { WorkoutTable } from "./WorkoutTable";

type WorkoutItemProps = {
  data: Exercise;
  bottomOffset?: boolean;
  hideActionButtons?: boolean;
  isWorkoutEditMode?: boolean;
  index: number;
  onBack?: () => void;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid rgba(230, 232, 235, 0.35);
  background: ${COLOR_WHITE};
  box-shadow: 0 2px 6px 0 rgba(34, 62, 106, 0.05);
  margin-bottom: 5px;
  padding: 15px;
  overflow: auto;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: ${COLOR_TEXT_DARK};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
`;

const Image = styled.img`
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 15px;
`;

const XpsImageWrapper = styled.div<{ colors: string[] }>`
  object-fit: cover;
  border-radius: 6px;
  margin-right: 15px;
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ colors }) =>
    `linear-gradient(131.186deg, ${colors[0]} 0%, ${colors[1]} 100%)`}
}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export function WorkoutItem({
  data,
  bottomOffset,
  hideActionButtons,
  isWorkoutEditMode,
  index,
  onBack,
}: WorkoutItemProps) {
  const { t } = useTranslation();
  const [isImageBroken, setImageBroken] = useState(false);
  const {
    actions: { openTopModal },
  } = useTopModalContext();
  const { sessionId, language, timezone } = useUserContext();
  const [appendSetMutation] = useAppendSetMutation();
  const [deleteSetMutation] = useDeleteSetMutation();
  const [updateAttributeMutation] = useUpdateAttributeMutation();

  const { data: exerciseData } = useExerciseSetsQuery({
    variables: {
      sessionId,
      language,
      timezone,
      id: data?.id,
    },
    skip: !data?.id,
  });

  const hasUrl =
    !!data?.urlModularContent ||
    !!data?.urlOldJsonStyle ||
    !!data?.urlCustomContent;
  const sets = exerciseData?.exercise?.sets;
  const disableDelete = sets?.length === 1;
  const colors = COLORS[convertIdToVariant(data?.id)];

  const onDeleteSet = useCallback(() => {
    const lastSet = sets[sets?.length - 1];
    const variables = {
      sessionId,
      language,
      timezone,
      id: lastSet?.id,
    };

    deleteSetMutation({
      variables,
      optimisticResponse: {
        deleteSet: 1,
      },
      refetchQueries: [
        "SessionDetail",
        {
          query: ExerciseSets,
          variables: {
            sessionId,
            language,
            timezone,
            id: data?.id,
          },
        },
      ],
    });
  }, [sessionId, language, sets, data, timezone, deleteSetMutation]);

  const onAddSet = useCallback(() => {
    const lastSet = sets[sets?.length - 1];

    const variables = {
      sessionId,
      language,
      timezone,
      input: {
        idOfSetToCopy: lastSet?.id,
      },
    };

    appendSetMutation({
      variables,
      optimisticResponse: {
        appendSet: {
          ...lastSet,
        },
      },
      refetchQueries: [
        "SessionDetail",
        {
          query: ExerciseSets,
          variables: {
            sessionId,
            language,
            timezone,
            id: data?.id,
          },
        },
      ],
    });
  }, [sessionId, language, sets, data, timezone, appendSetMutation]);

  const onCellUpdated = (cell: Cell, value: string) => {
    if (endsWithPunctuation(value)) {
      return;
    }

    const variables = {
      sessionId,
      language,
      timezone,
      input: {
        id: cell.id,
        value,
      },
    };

    updateAttributeMutation({
      variables,
      optimisticResponse: {
        updateAttribute: {
          ...cell,
          valuePerformed: value,
        },
      },
    });
  };

  const handleOpenInfo = () => {
    if (hasUrl) {
      openTopModal({
        title: data?.name,
        modal: TopModalType.DOCUMENT,
        onBack: onBack ? () => onBack() : undefined,
        params: {
          document: {
            ...data,
            mcUrl: data?.urlModularContent,
            itemName: data?.name,
          },
          hidePopularButton: true,
        },
      });

      return;
    }

    if (data?.urlCustomContent) {
      window.open(data?.urlCustomContent, "_blank");
    }
  };

  const getWorkoutImage = () => {
    if (data?.thumb && !isImageBroken) {
      return <Image src={data?.thumb} onError={() => setImageBroken(true)} />;
    }

    if (isImageBroken || !data?.thumb) {
      return (
        <XpsImageWrapper colors={colors}>
          {isAcesNationEnv() ? (
            <LogoAcesNationIcon width={45} height={34} />
          ) : (
            <LogoXpsIcon width={45} height={45} />
          )}
        </XpsImageWrapper>
      );
    }
  };

  const lastSetCellCount =
    bottomOffset && sets && sets[sets?.length - 1]?.cells
      ? sets[sets.length - 1].cells[sets[sets.length - 1].cells.length - 1]
          ?.options?.length
      : 0;

  return (
    <Hoverable>
      {(isHovered) => (
        <Wrapper
          style={{
            marginBottom: lastSetCellCount ? lastSetCellCount * 33 : undefined,
          }}
        >
          <InfoWrapper>
            {getWorkoutImage()}
            <div
              style={{ marginRight: isWorkoutEditMode && !isHovered ? 115 : 0 }}
            >
              <Title>{data?.name}</Title>
              <ButtonWrapper>
                <GeneralButton
                  colorVariant={ButtonColor.Blue}
                  label={t("info")}
                  isDisabled={!hasUrl}
                  sizeType={ButtonType.Tiny}
                  outlined
                  onClick={handleOpenInfo}
                />
                {hideActionButtons ? null : (
                  <>
                    <AddRemoveButton label={t("addSet")} onClick={onAddSet} />
                    <AddRemoveButton
                      isDisabled={disableDelete}
                      label={t("removeSet")}
                      onClick={onDeleteSet}
                      sizeType={ButtonType.Remove}
                    />
                  </>
                )}
              </ButtonWrapper>
            </div>
            {isWorkoutEditMode && isHovered ? (
              <WorkoutEditModeSection data={data} index={index} />
            ) : null}
          </InfoWrapper>
          <WorkoutTable
            exerciseData={data}
            sets={sets}
            onCellUpdated={onCellUpdated}
          />
        </Wrapper>
      )}
    </Hoverable>
  );
}
