import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_DARK_BLUE, COLOR_WHITE } from "../../colors";
import { ButtonColor, GeneralButton } from "../../components/Button/";
import { LogoAcesNationIcon, LogoXpsIcon } from "../../components/Icons";
import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  InvitationsQueryResponse,
  answerInvitation,
} from "../../services/login/invitations";
import { isAcesNationEnv } from "../../utils/isAcesNationEnv";

const InvitationsModalWrapper = styled.div`
  background-color: ${COLOR_DARK_BLUE};
  padding: 30px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const InvitationTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 14px;
  text-align: center;
  color: ${COLOR_WHITE};
`;

const InvitationSubTitle = styled.span`
  font-size: 16px;
  margin-bottom: 20px;
  color: ${COLOR_WHITE};
`;

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`;

interface InvitationModalProps {
  invitations: InvitationsQueryResponse["_invitations"];
}

export const InvitationModal = ({ invitations }: InvitationModalProps) => {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const {
    actions: { closeModal },
  } = useModalContext();
  const [indexOfCurrentInvitation, setIndexOfCurrentInvitation] = useState(0);

  const currentInvitation = invitations[indexOfCurrentInvitation];

  const moveToNextTerm = () => {
    if (indexOfCurrentInvitation < invitations.length - 1) {
      setIndexOfCurrentInvitation((old) => old + 1);
    } else {
      closeModal();
    }
  };

  const onAcceptInvitation = async () => {
    await answerInvitation({
      session: { sessionId },
      guid: currentInvitation._guid,
      isAccepted: true,
    });

    moveToNextTerm();
  };

  const onRejectInvitation = async () => {
    await answerInvitation({
      session: { sessionId },
      guid: currentInvitation._guid,
      isAccepted: false,
    });

    moveToNextTerm();
  };

  return (
    <InvitationsModalWrapper>
      {isAcesNationEnv() ? (
        <LogoAcesNationIcon width={128} height={98} />
      ) : (
        <LogoXpsIcon width={128} height={34} />
      )}
      <InfoWrapper>
        <InvitationTitle>{currentInvitation._title}</InvitationTitle>
        <InvitationSubTitle>{currentInvitation._message}</InvitationSubTitle>
      </InfoWrapper>
      <ActionButtonsWrapper>
        <GeneralButton label={t("accept")} onClick={onAcceptInvitation} />
        <GeneralButton
          colorVariant={ButtonColor.Red}
          label={t("decline")}
          onClick={onRejectInvitation}
        />
      </ActionButtonsWrapper>
    </InvitationsModalWrapper>
  );
};
