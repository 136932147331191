import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

import { Header } from "../components/Header";
import { KeyValuesEditor } from "../components/Report/KeyValues/KeyValuesEditor";
import { KeyValuesWidgetFormValue } from "../components/Report/KeyValues/models";
import { mapKeyValuesWidgetFormDataItemsToWidgetCellGrid } from "../components/Report/KeyValues/utils";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { useUserContext } from "../contexts/User";
import {
  useSaveWidgetTemplateKeyValuesMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport } from "../hooks/data/useAddWidgetToReport";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useNavigateToPreviousPage } from "../hooks/useNavigateToPreviousPage";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { isRouteParamTrue } from "../utils/route";
import {
  buildPreferencesString,
  parseKeyValuesPreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export function KeyValuesEditorScreen() {
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
    searchParams,
  } = useWidgetEditorUrlParams();

  const isGroupWidget = isRouteParamTrue(searchParams.get("isGroupWidget"));
  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { t } = useTranslation();

  const { sessionId, language, firstName, lastName } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const [saveWidgetTemplateKeyValues, { loading: isSavingWidget }] =
    useSaveWidgetTemplateKeyValuesMutation();

  const { data: widgetData, loading: loadingWidgetData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const widgetModel =
    widgetData?.reportWidgetTemplateById?.__typename ===
    "ReportWidgetTemplateKeyValues"
      ? {
          ...widgetData.reportWidgetTemplateById,
          ...parseKeyValuesPreferencesObject(
            widgetData?.reportWidgetTemplateById?.preferences
          ),
        }
      : undefined;

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const onSubmit = async (values: KeyValuesWidgetFormValue) => {
    const { title, items, ...rest } = values;

    try {
      const response = await saveWidgetTemplateKeyValues({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              title ||
              (isWidgetTemplate ? t("defaultKeyValuesWidgetName") : ""),
            grid: mapKeyValuesWidgetFormDataItemsToWidgetCellGrid(
              items,
              rest.numberOfColumns
            ),
            preferences: buildPreferencesString({
              type: WidgetType.KEY_VALUES,
              isGroupWidget,
              itemsSettings: items.map((item) => ({
                customTitle: item?.customTitle,
                forAllGroups: item?.forAllGroups,
                compareLatestTo: item?.compareLatestTo,
              })),
              updatedAt: dayjs(),
              createdBy: `${firstName} ${lastName}`,
              ...rest,
            }),
            reportTemplateId: reportId || parentReportId,
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateKeyValues?.id
      ) {
        await addWidgetToReport(response.data.saveWidgetTemplateKeyValues.id);
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  if (loadingWidgetData) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <KeyValuesEditor
        isSavingWidget={isSavingWidget}
        isGroupWidget={isGroupWidget || widgetModel?.isGroupWidget}
        widget={widgetModel}
        onSubmit={onSubmit}
        onCancel={navigateToPreviousPage}
      />
    </ScreenContainer>
  );
}
