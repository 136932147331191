import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";

import { PracticeLine } from "../../components/Modals/CreateDrill/model";
import { CreatePracticeFormState } from "../../components/Modals/CreatePracticeModal";
import { useNotificationsContext } from "../../contexts/notifications";
import { PracticeLineWithDraft } from "../../contexts/practice/PracticeProvider";
import { useUserContext } from "../../contexts/User";
import {
  ResourcesUsageInput,
  Split,
  useUpdateSubPracticeMutation,
  useCreatePracticeMutation,
  useCreateSplitMutation,
  useUpdateSessionMutation,
  useUpdateSubPracticeParticipationMutation,
  Maybe,
  SubPractice,
  useDeleteSplitMutation,
  useDeleteSubPracticeMutation,
} from "../../graphql";
import { delay } from "../../utils/delay";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

import { useDeleteDrill } from "./useDeleteDrill";
import { useSaveDrillOrComment } from "./useSaveDrillOrComment";
import { useUpdateDrillOrComment } from "./useUpdateDrillOrComment";

type SplitWithLinesToRemove = Omit<Split, "subPractices"> & {
  subPractices?: Maybe<
    (Maybe<SubPractice> & {
      practiceLinesToRemove?: { id: string }[];
    })[]
  >;
};

export const useCreatePracticeWithDrills = () => {
  const { t } = useTranslation();
  const { sessionId, timezone, language } = useUserContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const client = useApolloClient();

  const [createPracticeMutation] = useCreatePracticeMutation();
  const [addSplitToPracticeMutation] = useCreateSplitMutation();
  const [updateSession] = useUpdateSessionMutation();

  const { saveDrillOrComment } = useSaveDrillOrComment();
  const { updateDrillOrComment } = useUpdateDrillOrComment();
  const { deleteDrill } = useDeleteDrill();
  const [deleteSplit] = useDeleteSplitMutation();
  const [deleteSubPractice] = useDeleteSubPracticeMutation();

  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();
  const [updateSubPracticeParticipationMutation] =
    useUpdateSubPracticeParticipationMutation();
  const [updateSubPracticeMutation] = useUpdateSubPracticeMutation();
  const [isLoading, setIsLoading] = useState(false);

  const createPracticeWithDrills = async ({
    data,
    practiceId,
    selectedGoogleLocationId,
    resourcesUsage,
    practiceLines,
    groupId,
    practiceLinesToRemove,
    originalPracticeLines,
  }: {
    data: CreatePracticeFormState;
    practiceId: string | null;
    selectedGoogleLocationId: string;
    resourcesUsage: ResourcesUsageInput;
    practiceLines: PracticeLineWithDraft[];
    groupId: string;
    practiceLinesToRemove?: PracticeLine[];
    originalPracticeLines?: PracticeLine[];
  }) => {
    setIsLoading(true);
    try {
      const [timeHour, timeMinute] = data.time.split(":");
      const updatedHours = dayjs(data?.date).set("hour", Number(timeHour));
      const finalDate = updatedHours.set("minute", Number(timeMinute));

      if (practiceId) {
        const updateSessionResult: any = await updateSession({
          variables: {
            sessionId,
            timezone,
            language,
            input: {
              id: practiceId,
              groupID: groupId,
              label: data?.title,
              location: data?.location,
              xtraLocation: data?.locationDescription,
              notes: data?.notes,
              specialNotes: data?.coachNotes,
              start: finalDate.valueOf(),
              colorHexCode: data?.color,
              durationMinutes: data?.duration,
              attendedMinutesBefore: data?.attendance,
              googleLocationId: selectedGoogleLocationId,
              resourcesUsage,
            },
          },
        });

        const existingPracticeLines = practiceLines
          ?.map((line, index) => ({
            ...line,
            index: line.isEdited ? index : null,
          }))
          ?.filter((line) => line?.__typename !== "Document" && !line.isDraft);

        const nonExistingPracticeLines = practiceLines
          ?.map((line, index) => ({
            ...line,
            index,
          }))
          ?.filter((line) => line?.__typename === "Document" || line.isDraft);

        if (practiceLinesToRemove?.length) {
          try {
            await deleteDrill({
              practiceLines: practiceLinesToRemove,
              practiceId,
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (existingPracticeLines?.length > 0) {
          try {
            await updateDrillOrComment({
              practiceID: practiceId,
              practiceLines: existingPracticeLines,
              originalPracticeLines,
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (nonExistingPracticeLines?.length) {
          try {
            await saveDrillOrComment({
              practiceID: practiceId,
              practiceLines: nonExistingPracticeLines,
            });
          } catch (e) {
            console.error(e);
          }
        }

        const practiceLinesWithIndex = practiceLines?.map((line, index) => ({
          ...line,
          index,
        }));

        const existingSplits = practiceLinesWithIndex?.filter(
          (line) => line?.__typename === "Split" && !line?.isDraft
        );

        const nonExistingSplits = practiceLinesWithIndex?.filter(
          (line) => line?.__typename === "Split" && line?.isDraft
        );

        if (nonExistingSplits?.length) {
          // @ts-ignore
          await Promise.all(
            // @ts-ignore
            nonExistingSplits.map(async (split: SplitWithLinesToRemove) => {
              try {
                const response = await addSplitToPracticeMutation({
                  variables: {
                    sessionId,
                    input: {
                      practiceGuid: practiceId,
                      nrOfSubPractices: split.subPractices?.length,
                      splitID: null,
                    },
                  },
                });
                await delay(200);

                const subPracticesIds =
                  response?.data?.createSplit?.subPractices?.map(
                    (subPractice) => subPractice.id
                  );
                // For each split add all drills in it
                for await (const [
                  practiceSplitIndex,
                  practiceSplit,
                ] of split.subPractices.entries()) {
                  await saveDrillOrComment({
                    practiceID: practiceId,
                    subPracticeID: subPracticesIds[practiceSplitIndex],
                    practiceLines: practiceSplit.practiceLines,
                  });
                  if (practiceSplit.comment) {
                    await updateSubPracticeMutation({
                      variables: {
                        sessionId,
                        input: {
                          id: subPracticesIds[practiceSplitIndex],
                          notes: practiceSplit.comment,
                        },
                      },
                    });
                  }
                  if (practiceSplit.athletes?.length) {
                    await updateSubPracticeParticipationMutation({
                      variables: {
                        input: {
                          added: practiceSplit.athletes.map(
                            (athlete) => athlete.id
                          ),
                          subPracticeID: subPracticesIds[practiceSplitIndex],
                        },
                      },
                    });
                  }
                }
              } catch (e) {
                console.error(e);
              }
            })
          );
        }

        if (existingSplits?.length) {
          await Promise.all(
            // @ts-ignore
            existingSplits.map(async (split: SplitWithLinesToRemove) => {
              const subPracticesIds = split?.subPractices?.map(
                (subPractice) => subPractice.id
              );

              if (!subPracticesIds.length) {
                await deleteSplit({ variables: { splitID: split.id } });
              }

              const originalPracticeLine: any = originalPracticeLines.find(
                (original) => original?.id === split?.id
              );

              const originalPracticeLinesSubPracticesIds =
                originalPracticeLine?.subPractices?.map(
                  (subPractice) => subPractice.id
                );

              const subPracticeToDelete =
                originalPracticeLinesSubPracticesIds?.filter(
                  (item) => !subPracticesIds.includes(item)
                );

              if (subPracticeToDelete?.length) {
                subPracticeToDelete?.map(async (item) => {
                  await deleteSubPractice({
                    variables: { sessionId, subPracticeID: item },
                  });
                });
              }

              // For each split add all drills in it
              for await (const [
                practiceSplitIndex,
                practiceSplit,
              ] of split.subPractices.entries()) {
                try {
                  await updateSubPracticeMutation({
                    variables: {
                      sessionId,
                      input: {
                        id: subPracticesIds[practiceSplitIndex],
                        notes: practiceSplit.comment,
                      },
                    },
                  });
                } catch (e) {
                  console.error(e);
                } finally {
                  try {
                    if (practiceSplit.athletes?.length) {
                      await updateSubPracticeParticipationMutation({
                        variables: {
                          input: {
                            added: practiceSplit.athletes.map(
                              (athlete) => athlete.id
                            ),
                            subPracticeID: subPracticesIds[practiceSplitIndex],
                          },
                        },
                      });
                    }
                  } catch (e) {
                    console.error(e);
                  } finally {
                    if (practiceSplit.practiceLinesToRemove?.length) {
                      await deleteDrill({
                        practiceLines: practiceSplit.practiceLinesToRemove,
                      });
                    }
                    const draftPracticeLines = practiceSplit.practiceLines
                      .map((line, index) => ({ ...line, index }))
                      ?.filter(
                        // @ts-ignore
                        (practiceLine) => practiceLine.__typename === "Document"
                      );

                    if (draftPracticeLines.length) {
                      await saveDrillOrComment({
                        practiceID: practiceId,
                        subPracticeID: practiceSplit.id,
                        practiceLines: draftPracticeLines,
                      });
                    }

                    const editedLines = practiceSplit.practiceLines?.filter(
                      (practiceLine) =>
                        ("isEdited" in practiceLine && practiceLine.isEdited) ||
                        ("localDuration" in practiceLine &&
                          practiceLine.localDuration !== undefined &&
                          // @ts-ignore
                          practiceLine.__typename !== "Document")
                    );

                    if (editedLines.length) {
                      await updateDrillOrComment({
                        practiceID: practiceId,
                        subPracticeID: subPracticesIds[practiceSplitIndex],
                        practiceLines: editedLines,
                        originalPracticeLines,
                      });
                    }
                  }
                }
              }
            })
          );
        }

        showSuccessNotification(t("updatePracticeSuccess"));

        handleSendManualNotificationAfterAction(
          {
            ...data,
            id: practiceId,
          },
          updateSessionResult?.data?.updateSession?.notificationToSuggest
        );
      } else {
        // Create practice session
        const { data: createdPracticeData } = await createPracticeMutation({
          variables: {
            sessionId,
            timezone,
            language,
            input: {
              id: practiceId,
              groupID: groupId,
              label: data?.title,
              location: data?.location,
              xtraLocation: data?.locationDescription,
              notes: data?.notes,
              specialNotes: data?.coachNotes,
              start: finalDate.valueOf(),
              colorHexCode: data?.color,
              durationMinutes: data?.duration,
              attendedMinutesBefore: data?.attendance,
              googleLocationId: selectedGoogleLocationId,
              resourcesUsage,
              useTemplateForGroup: false,
            },
          },
          refetchQueries: [],
        });

        const subPractices = practiceLines
          ?.filter(
            (practiceLine) =>
              "subPractices" in practiceLine &&
              practiceLine.__typename === "Split"
          )
          .map((split) =>
            "subPractices" in split && split?.subPractices?.length
              ? split?.subPractices
              : []
          );

        // Add existing splits to the practice
        if (subPractices?.length) {
          await Promise.all(
            subPractices.map(async (subPractice) => {
              try {
                const response = await addSplitToPracticeMutation({
                  variables: {
                    sessionId,
                    input: {
                      practiceGuid: createdPracticeData?.createPractice?.id,
                      nrOfSubPractices: subPractice?.length,
                      splitID: null,
                    },
                  },
                });

                const subPracticesIds =
                  response?.data?.createSplit?.subPractices?.map(
                    (subPractice) => subPractice.id
                  );

                // For each split add all drills in it
                for await (const [
                  practiceSplitIndex,
                  practiceSplit,
                ] of subPractice.entries()) {
                  if (practiceSplit.comment) {
                    await updateSubPracticeMutation({
                      variables: {
                        sessionId,
                        input: {
                          id: subPracticesIds[practiceSplitIndex],
                          notes: practiceSplit.comment,
                        },
                      },
                    });
                  }

                  try {
                    await saveDrillOrComment({
                      practiceID: createdPracticeData?.createPractice?.id,
                      subPracticeID: subPracticesIds[practiceSplitIndex],
                      practiceLines: practiceSplit.practiceLines,
                    });
                  } catch (e) {
                    console.error(e);
                  }

                  try {
                    await updateSubPracticeMutation({
                      variables: {
                        sessionId,
                        input: {
                          id: subPracticesIds[practiceSplitIndex],
                          notes: practiceSplit.comment,
                        },
                      },
                    });
                  } catch (e) {
                    console.error(e);
                  }
                  if (practiceSplit.athletes?.length) {
                    try {
                      await updateSubPracticeParticipationMutation({
                        variables: {
                          input: {
                            added: practiceSplit.athletes.map(
                              (athlete) => athlete.id
                            ),
                            removed: [],
                            subPracticeID: subPracticesIds[practiceSplitIndex],
                          },
                        },
                      });
                    } catch (e) {
                      console.error(e);
                    }
                  }
                }
              } catch (e) {
                console.error(e);
              }
            })
          );
        }

        // Add items to drill / comment items to the plan of practice
        await saveDrillOrComment({
          practiceID: createdPracticeData?.createPractice?.id,
          practiceLines,
        });

        showSuccessNotification(t("createPracticeSuccess"));

        handleSendManualNotificationAfterAction(
          { ...createdPracticeData?.createPractice },
          createdPracticeData?.createPractice?.notificationToSuggest
        );
      }

      // refetch queries after all operations
      client.refetchQueries({
        include: ["agenda", "resourceCalendar", "SessionDetail"],
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      if (practiceId) {
        showErrorNotification(t("updatePracticeError"));
      }
      showErrorNotification(t("createPracticeError"));
    }
  };

  return { createPracticeWithDrills, createPracticeLoading: isLoading };
};
