import React from "react";
import { components, MenuProps } from "react-select";

import styled from "styled-components";

const StyledMenuWrapper = styled.div<{ menuHidden?: boolean }>`
  display: ${({ menuHidden }) => (menuHidden ? "none" : "block")};
`;

type MenuWithFolderStructureProps = MenuProps & {
  selectProps: {
    folderStructureProps?: {
      menuHidden?: boolean;
    };
  };
};

export const MenuWithFolderStructure = ({
  children,
  ...props
}: MenuWithFolderStructureProps) => {
  const menuHidden = props.selectProps?.folderStructureProps?.menuHidden;

  return (
    <StyledMenuWrapper menuHidden={menuHidden}>
      <components.Menu {...props}>{children}</components.Menu>
    </StyledMenuWrapper>
  );
};
