import Storage from "./storage";
import { StorageKeys } from "./storageKeys";
import { getAccounts } from "./switchAccounts";

/**
 * Remove the account that is not remembered from the list of accounts to switch.
 */
export const removeUnrememberedAccount = () => {
  const savedAccounts = getAccounts();
  if (savedAccounts) {
    const newAccounts = savedAccounts.filter((account) => !account.isTemporary);
    Storage.setItem(StorageKeys.accountsToSwitch, newAccounts);
  }
};
