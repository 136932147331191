import ReactGridLayout from "react-grid-layout";
import { useTranslation } from "react-i18next";

import { useUserContext } from "../../../contexts/User";
import {
  ReportTemplate,
  ReportType,
  useSaveReportTemplateMutation,
  ReportTemplatesDocument,
} from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { parsePreferences } from "../../../utils/preferences";
import { WidgetType } from "../../ReportCreateTable/models";
import { WidgetMode } from "../WidgetWithData";

import { useDuplicateWidget } from "./useDuplicateWidget";

/**
 * Duplicates group report or athlete report with all subreports and widgets.
 */
export const useDuplicateReport = (widgetMode: WidgetMode) => {
  const { t } = useTranslation();
  const { sessionId } = useUserContext();
  const { duplicateWidget } = useDuplicateWidget(widgetMode);
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const [saveReport] = useSaveReportTemplateMutation({
    refetchQueries: [ReportTemplatesDocument],
  });

  /**
   * Duplicates report which contains grid so basically it is used for duplication of group and tab reports.
   * Athletes reports require more complex handling because they contain more tab reports and no grid.
   * @param report to be duplicated
   * @param parentId id of report to assing tabReport to
   * @returns function which executes duplication of passed report
   */
  const duplicateReportWithWidgets = async (
    report: ReportTemplate,
    parentId = null
  ) => {
    const nameSuffix =
      report.reportType === ReportType.Tab ? "" : ` (${t("copied")})`;

    const saveReportVariables = {
      sessionId,
      accountId: selectedAccountId,
      input: {
        id: null,
        parentId,
        name: `${report.name}${nameSuffix}`,
        reportType: report.reportType,
        preferences: report.preferences,
        position: report.position,
      },
    };

    const duplicatedReportResponse = await saveReport({
      variables: saveReportVariables,
    });
    const duplicatedReportId =
      duplicatedReportResponse.data?.saveReportTemplate?.id;

    if (duplicatedReportId) {
      // Create new instances of widgets from original report
      const duplicatedWidgets = await Promise.all(
        report.widgets?.map((widget) =>
          duplicateWidget(
            { ...widget, ...parsePreferences(widget.preferences, {}) },
            duplicatedReportId
          )
        )
      );

      const { layout = [], ...rest } = parsePreferences(
        report.preferences,
        {}
      ) as {
        layout: ReactGridLayout.Layout[];
      };

      // Update ids of layout items so report contains new generated widgets
      const transformedLayout = layout.map((item) => {
        const index = report.widgets.findIndex(
          (widget) => String(item.i) === String(widget.id)
        );

        if (index !== -1) {
          const keysOfDuplicateResponse = Object.keys(
            duplicatedWidgets.at(index).data ?? {}
          );
          const idAccessor = keysOfDuplicateResponse.length
            ? keysOfDuplicateResponse[0]
            : "";

          if (idAccessor) {
            return {
              ...item,
              i: duplicatedWidgets.at(index).data[idAccessor].id,
            };
          }
        }

        return item;
      });

      // Save report one more time but now with updated layout pointing to new widgets
      await saveReport({
        variables: {
          ...saveReportVariables,
          input: {
            ...saveReportVariables.input,
            id: duplicatedReportId,
            preferences: JSON.stringify({ ...rest, layout: transformedLayout }),
          },
        },
      });

      return duplicatedReportId;
    }
  };

  const duplicateReport = async (report: ReportTemplate) => {
    if (report.reportType === ReportType.AthleteReport) {
      // Copy athlete report itself
      const duplicatedAthleteReportResponse = await saveReport({
        variables: {
          sessionId,
          accountId: selectedAccountId,
          input: {
            id: null,
            parentId: null,
            name: `${report.name} (${t("copied")})`,
            reportType: report.reportType,
            preferences: report.preferences,
          },
        },
      });
      const duplicatedReportId =
        duplicatedAthleteReportResponse.data?.saveReportTemplate?.id;

      if (duplicatedReportId) {
        // Copy header widget
        const headerWidget = report.widgets?.find(
          (widget) => widget.__typename === "ReportWidgetTemplateHeader"
        );

        if (headerWidget) {
          const { __typename, ...rest } = headerWidget;
          await duplicateWidget(
            { ...rest, type: WidgetType.HEADER },
            duplicatedReportId
          );
        }

        // Copy all report tabs
        await Promise.all(
          report.tabs.map((tabReport) =>
            duplicateReportWithWidgets(tabReport, duplicatedReportId)
          )
        );

        return duplicatedReportId;
      }
    } else {
      // Copy whole group report in once with all widgets
      return duplicateReportWithWidgets(report);
    }
  };

  return { duplicateReport };
};
