import React from "react";
import { useInView } from "react-intersection-observer";

import chroma from "chroma-js";
import styled from "styled-components";

import { DataPerPeriod } from "../../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";
import { LinearColoring } from "../../Report/PeriodizationTable/model";
import { ValueCellText } from "../../ReportTableWidget/styled";
import { getColoringConfig } from "../../SharedWidgetEditorSections/utils/getColoringConfig";

type TablePeriodizationCellProps = {
  dataPerPeriod: DataPerPeriod;
  hasColoring: boolean;
  valueToDisplay: string | number;
  coloring: { id: string; coloring: LinearColoring };
};

const DataRowColumn = styled.div`
  height: 45px;
`;

const MultiValueTd = styled.td`
  padding: 0;
  vertical-align: text-top;
`;

const StyledValueCellText = styled(ValueCellText)`
  padding: 0 8px;
  white-space: nowrap;
`;

export function TablePeriodizationCell({
  dataPerPeriod,
  hasColoring,
  valueToDisplay,
  coloring,
}: TablePeriodizationCellProps) {
  const { ref, inView } = useInView({ triggerOnce: true });

  const scale = hasColoring
    ? chroma
        .scale(getColoringConfig(coloring.coloring))
        .domain([dataPerPeriod.min, dataPerPeriod.max])
    : undefined;

  return (
    <MultiValueTd ref={ref}>
      {inView ? (
        <DataRowColumn
          style={{
            backgroundColor:
              valueToDisplay === "-" || !hasColoring
                ? "transparent"
                : scale(dataPerPeriod.value).hex(),
          }}
        >
          <StyledValueCellText>
            {valueToDisplay}
            {dataPerPeriod.txt ? "" : ` ${dataPerPeriod.unitName || ""}` ?? ""}
          </StyledValueCellText>
        </DataRowColumn>
      ) : null}
    </MultiValueTd>
  );
}
