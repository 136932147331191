import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import { isRealTrainer } from "../utils/isRealTrainer";

const useSendManualNotificationAfterAction = () => {
  const modal = useModalContext();
  const { t } = useTranslation();
  const user = useUserContext();

  const handleSendManualNotificationAfterAction = useCallback(
    (session, notificationToSuggest) => {
      if (isRealTrainer(user) && notificationToSuggest) {
        setTimeout(() => {
          modal.actions.openModal({
            modal: ModalType.CONFIRM,
            title: t("confirm"),
            params: {
              content: t("sendManualNotificationAlertText"),
              okButtonText: t("yes"),
              onOK: async () => {
                modal.actions.closeModal();

                setTimeout(() => {
                  const sessionType = session?.__typename || session?.type;
                  const forIndividualAthlete =
                    sessionType === "Workout" ||
                    (sessionType === "Event" && !session.teamName);
                  const focusedAthleteName = session?.ownerUser?.name;

                  modal.actions.openModal({
                    modal: ModalType.SEND_MANUAL_NOTIFICATION,
                    title: t("sendManualNotification"),
                    params: {
                      defaultTitle: notificationToSuggest?.title,
                      defaultText: notificationToSuggest?.text,
                      sessionGuid: session.id,
                      forIndividualAthlete,
                      focusedAthleteName,
                    },
                  });
                }, 500);
              },
            },
          });
        }, 500);
      }
    },
    [modal.actions, t, user]
  );

  return { handleSendManualNotificationAfterAction };
};

export default useSendManualNotificationAfterAction;
