import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { UserContextState } from "../contexts/User";

import {
  fetchTrainerViewableGroups,
  TrainerViewableGroupsQueryArgs,
  TrainerViewableGroupsQueryResponse,
} from "./agenda";
import * as api from "./api";
import {
  fetchMyAthletes,
  fetchUserProfile,
  JsonMyAthletesQueryResponse,
  JsonProfileQueryResponse,
} from "./athletes";
import { copyWorkoutsToCalendar, generateCalendar } from "./calendar";
import {
  fetchProgramDetails,
  ProgramDetailsQueryArgs,
  ProgramDetailsQueryResponse,
} from "./document";
import {
  FetchJsonLabelAndLocationArgs,
  fetchLabelAndLocation,
  JsonLabelAndLocationQueryModifiedResponse,
} from "./sessions";
import { fetchClients } from "./statistics";

export type UseMyAthletesQueryArgs = {
  session: api.Session;
};

type UseFetchUserProfileQueryArgs = {
  session: api.Session;
  focusedAthleteGuid: string;
};

export function useFetchUserProfile(
  args: UseFetchUserProfileQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonProfileQueryResponse>>,
    "queryFn"
  > = {}
) {
  return useQuery<AxiosResponse<JsonProfileQueryResponse>>(
    queryKey ?? ["userProfile", args.focusedAthleteGuid],
    async () => fetchUserProfile(args.session, args.focusedAthleteGuid),
    {
      ...queryOptions,
    }
  );
}

export function useMyAthletesQuery(
  args: UseMyAthletesQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<AxiosResponse<JsonMyAthletesQueryResponse>>,
    "queryFn"
  > = {}
) {
  return useQuery<AxiosResponse<JsonMyAthletesQueryResponse>>(
    queryKey ?? ["myAthletesQuery", {}],
    async () => fetchMyAthletes(args.session),
    {
      ...queryOptions,
    }
  );
}

export function useLabelAndLocationQuery(
  args: FetchJsonLabelAndLocationArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<
    UseQueryOptions<JsonLabelAndLocationQueryModifiedResponse | null>,
    "queryFn"
  > = {}
) {
  return useQuery<JsonLabelAndLocationQueryModifiedResponse | null>(
    queryKey ?? ["labelAndLocationQuery", { ...args }],
    async () => fetchLabelAndLocation({ ...args }),
    {
      ...queryOptions,
    }
  );
}

export function useProgramDetailsQuery(
  args: ProgramDetailsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<UseQueryOptions<ProgramDetailsQueryResponse>, "queryFn"> = {}
) {
  return useQuery<ProgramDetailsQueryResponse | null>(
    queryKey ?? ["programDetailsQuery", args.programGuid],
    async () => fetchProgramDetails({ ...args }),
    {
      ...queryOptions,
    }
  );
}

export function useTrainerViewableGroupsQuery(
  args: TrainerViewableGroupsQueryArgs,
  {
    queryKey,
    ...queryOptions
  }: Omit<UseQueryOptions<TrainerViewableGroupsQueryResponse>, "queryFn"> = {}
) {
  const { data, ...rest } = useQuery<TrainerViewableGroupsQueryResponse>(
    queryKey ?? ["trainerViewableGroups", args.session.sessionId],
    async () => fetchTrainerViewableGroups(args.session),
    {
      ...queryOptions,
    }
  );

  return { data: data?.tree?.filter((team) => team._checked), ...rest };
}

export function useTrainerClientsQuery(args, { ...queryOptions } = {}) {
  const { data } = useQuery(
    ["trainerClients", args.session.sessionId],
    async () => fetchClients(args.session),
    {
      ...queryOptions,
    }
  );

  return { data };
}

type GenerateCalendarParams = {
  _firstDayDDMMYYYY: string;
  _focusedAthletes?: string[];
  _teamAthlete?: string;
  _programGuid: string;
  _regularity: {
    _weekDaysFreysiStyle: {
      _dayIndex1to7: number;
      _timeHHMM: string;
      _weekDayCode: string;
    }[];
  };
};

type CopyWorkoutParams = {
  _items: { guid: string; date: number }[];
  _focusedAthletes?: string[];
  _teamAthlete?: string;
};

export function useCopyWorkoutsToCalendar() {
  return useMutation(
    (args: { user: UserContextState; params: CopyWorkoutParams }) =>
      copyWorkoutsToCalendar(args)
  );
}

export function useGenerateCalendar() {
  return useMutation(
    (args: { user: UserContextState; params: GenerateCalendarParams }) =>
      generateCalendar(args)
  );
}
