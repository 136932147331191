import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Options, XAxisOptions } from "highcharts";
import { useTheme } from "styled-components";

import { Period, useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { useGetTheGroupsImInQuery } from "../../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../../hooks";
import { ChartWidgetModel, TimeLine, TimeLinePeriod } from "../model";
import {
  generatePerformerCategories,
  generatePeriodCategories,
} from "../utils/generateCategories";
import {
  generateContinuousChartOptions,
  generatePerformersChartOptions,
  generatePerformersPeriodChartOptions,
  generatePeriodChartOptions,
  GroupedCategoriesOptions,
  setColorsOption,
} from "../utils/generateChartOptions";
import { generateLimits } from "../utils/generateLimits";
import {
  generateDatetimeSeries,
  generatePerformerCategorySeries,
  generatePerformerPeriodCategorySeries,
  generatePeriodCategorySeries,
} from "../utils/generateSeries";

import { useChartAttributes } from "./useChartAttributes";
import { useChartWidgetData } from "./useChartWidgetData";
import { useChartWidgetReportPerformers } from "./useChartWidgetReportPerformers";
import { useChartWidgetReportPeriod } from "./useChartWidgetReportPeriod";

export function useChartOptions({
  widget,
  isGroupReport,
  isPreviewMode,
}: {
  widget: ChartWidgetModel;
  isGroupReport: boolean;
  isPreviewMode?: boolean;
}): {
  options?: Options | GroupedCategoriesOptions;
  isLoading: boolean;
} {
  const { t } = useTranslation();
  const { sessionId, language, timezone } = useUserContext();
  const [{ selectedPeriod }] = useReportsContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { isMobile } = useTheme();
  // height of widget title (55px)
  // on mobile widget height of widget title (40px)
  const chartMarginTop = !isMobile ? 55 : 40;

  const { data: groupsData, loading: groupsIsLoading } =
    useGetTheGroupsImInQuery({
      variables: { sessionId, accountId: selectedAccountId, language },
    });

  const { fromDate, toDate } = useChartWidgetReportPeriod(
    widget,
    isPreviewMode
  );

  const performers = useChartWidgetReportPerformers({
    widget,
    isGroupReport,
    isPreviewMode,
  });

  const { data, loading: dataIsLoading } = useChartWidgetData({
    widget,
    isGroupReport,
    isPreviewMode,
    fromDate,
    toDate,
    performers,
  });

  const { chartAttributes } = useChartAttributes(widget);

  const chartOptions = useMemo<Options | GroupedCategoriesOptions>(() => {
    switch (widget.timeLine) {
      case TimeLine.CONTINUOUS: {
        const coloredOptions = setColorsOption({
          ...generateContinuousChartOptions({
            widget,
            fromDate,
            toDate,
            selectedPeriod: selectedPeriod as Period,
            chartMarginTop,
            timezone,
          }),
          ...generateDatetimeSeries({
            attributes: chartAttributes,
            chartType: widget.chartType,
            stats: data?.athletesData,
            includeZero: widget.includeZero,
            isGroupReport,
            isMobile,
            t,
          }),
        });

        return generateLimits(widget.limits, coloredOptions);
      }
      case TimeLine.PERIOD: {
        const baseChartOptions = generatePeriodChartOptions({
          widget,
          fromDate,
          toDate,
          chartMarginTop,
        });
        const coloredOptions = setColorsOption({
          ...baseChartOptions,
          ...generatePeriodCategorySeries({
            attributes: chartAttributes,
            chartType: widget.chartType,
            stats: data?.athletesData,
            teamAverageStats: data?.teamAverageData,
            categoriesLength: (baseChartOptions.xAxis as XAxisOptions)
              .categories.length,
            fromDate,
            timeLinePeriod: widget.timeLinePeriod,
            includeZero: widget.includeZero,
            isGroupReport,
            isMobile,
            t,
          }),
        });

        return generateLimits(widget.limits, coloredOptions);
      }
      case TimeLine.PERFORMERS: {
        const performerNames = generatePerformerCategories(
          performers,
          widget.items.some(({ showTeamAverage }) => showTeamAverage)
        );

        const guidToName = new Map(
          (groupsData?.getTheGroupsImIn ?? []).flatMap<[string, string]>(
            ({ id, name, athletes }) => [
              [id, name],
              ...athletes.map<[string, string]>(({ id, name }) => [id, name]),
            ]
          )
        );

        if (widget.timeLinePeriod === TimeLinePeriod.NONE) {
          const coloredOptions = setColorsOption({
            ...generatePerformersChartOptions({
              widget,
              categories: performerNames.categories.map((guid) =>
                guid === "teamAverage"
                  ? t("teamAverage")
                  : guidToName.get(guid) ?? guid
              ),
              chartMarginTop,
            }),
            ...generatePerformerCategorySeries({
              performerCategories: performerNames,
              attributes: chartAttributes,
              chartType: widget.chartType,
              stats: data?.athletesData,
              teamAverageStats: data?.teamAverageData,
              includeZero: widget.includeZero,
              t,
            }),
          });

          return generateLimits(widget.limits, coloredOptions);
        } else {
          const periodCategories = generatePeriodCategories(
            fromDate,
            toDate,
            widget.timeLinePeriod
          );
          const performerCategories = periodCategories.map(
            (periodCategory) => ({
              name: periodCategory,
              categories: performerNames.categories,
            })
          );

          const coloredOptions = setColorsOption({
            ...generatePerformersPeriodChartOptions({
              widget,
              categories: performerCategories.map((periodSerie) => ({
                name: periodSerie.name,
                categories: periodSerie.categories.map((guid) =>
                  guid === "teamAverage"
                    ? t("teamAverage")
                    : guidToName.get(guid) ?? guid
                ),
              })),
              chartMarginTop,
            }),
            ...generatePerformerPeriodCategorySeries({
              performerCategories,
              attributes: chartAttributes,
              chartType: widget.chartType,
              stats: data?.athletesData,
              teamAverageStats: data?.teamAverageData,
              includeZero: widget.includeZero,
              hasTeamAverage: performerNames.hasTeamAverage,
              fromDate,
              timeLinePeriod: widget.timeLinePeriod,
              t,
            }),
          });

          return generateLimits(widget.limits, coloredOptions);
        }
      }
    }
  }, [
    data?.athletesData,
    data?.teamAverageData,
    groupsData,
    selectedPeriod,
    fromDate,
    toDate,
    chartAttributes,
    chartMarginTop,
    isGroupReport,
    isMobile,
    widget,
    performers,
    t,
    timezone,
  ]);
  return { options: chartOptions, isLoading: dataIsLoading || groupsIsLoading };
}
