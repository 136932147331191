import { useMemo } from "react";

import dayjs from "dayjs";

import { useUserContext } from "../contexts/User";
import {
  Attendance,
  Event,
  PaymentRequest,
  PaymentStatus,
  useAgendaWithRegistrationQuery,
} from "../graphql";
import { AttendanceStatus } from "../models/attendance";
import { isRealTrainer } from "../utils/isRealTrainer";

export function useAgendaWithRegistration() {
  const { language, timezone, sessionId, isTrainer, isFamilyMember } =
    useUserContext();

  const {
    data: agendaWithRegistrationData,
    refetch: agendaWithRegistrationRefetch,
  } = useAgendaWithRegistrationQuery({
    variables: { sessionId, timezone, language },
  });

  const edges = useMemo(
    () =>
      agendaWithRegistrationData?.agenda?.edges?.map((edge) => ({
        ...edge?.node,
      })) || [],
    [agendaWithRegistrationData?.agenda?.edges]
  );

  const agendaListNeedAttention = useMemo(() => {
    return isRealTrainer({ isTrainer, isFamilyMember })
      ? []
      : edges.filter(
          (edge: any) =>
            dayjs(edge.replyBefore).isAfter() &&
            (edge.payments?.some(
              (payment: PaymentRequest) =>
                payment?.status === PaymentStatus.Pending
            ) ||
              edge.attendance?.some(
                (att: Attendance) =>
                  att?.attendance === AttendanceStatus.UNKNOWN
              ))
        );
  }, [edges, isFamilyMember, isTrainer]);

  const agendaListNotNeedAttention = useMemo(() => {
    return edges.filter((edge: any) => {
      const existsInEdgesWithNeedAttention = agendaListNeedAttention?.some(
        (edgeWithAttention: any) => edgeWithAttention.id === edge?.id
      );
      return !existsInEdgesWithNeedAttention;
    });
  }, [edges, agendaListNeedAttention]);

  return {
    agendaWithRegistrationRefetch,
    agendaListNeedAttention: agendaListNeedAttention as Event[],
    agendaListNotNeedAttention: agendaListNotNeedAttention as Event[],
  };
}
