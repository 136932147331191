import { useEffect } from "react";

import { useTopModalContext } from "../../contexts/UI/TopModal";

export const TopModalErrorComponent = () => {
  const { actions, open } = useTopModalContext();

  useEffect(() => {
    if (open) {
      actions.closeTopModal();
    }
  }, [actions, open]);

  return null;
};
