import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../colors";
import { useNotificationsContext } from "../../contexts/notifications";
import { useUserContext } from "../../contexts/User";
import {
  useCopyWorkoutsMutation,
  useGetTheGroupsImInQuery,
  XpsUser,
} from "../../graphql";
import { BottomBar } from "../BottomBar";

import { DuplicateWorkoutSelectAthleteContent } from "./DuplicateWorkoutSelectAthlete/Content";

const BottomSection = styled.div`
  background: ${COLOR_WHITE};
  padding: 15px 20px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
`;

export type DuplicateWorkoutGroup = { groupName: string; athletes: XpsUser[] };

interface Props {
  initialData?: XpsUser[];
  closeModal: () => void;
  groupId: string;
  ownerId: string;
  alreadyAddedMembers: XpsUser[];
  workoutId: string;
  start: number;
}

export const DuplicateWorkoutSelectAthleteModal = ({
  closeModal,
  workoutId,
  start,
}: Props) => {
  const { sessionId, language, timezone } = useUserContext();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { t } = useTranslation();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();

  const getTheGroupsImInVariables = {
    sessionId,
    language,
    requireEditability: false,
  };

  const { data = {}, refetch: refetchGroupsImIn } = useGetTheGroupsImInQuery({
    variables: getTheGroupsImInVariables,
  });

  const [copyWorkouts] = useCopyWorkoutsMutation({
    variables: {
      timezone,
      language,
      sessionId,
      id: workoutId,
      focusedAthletes: selectedUsers,
      start,
    },
  });

  const groupedData = useMemo(() => {
    if (!data?.getTheGroupsImIn) {
      return [];
    }
    const finalData: DuplicateWorkoutGroup[] = [];
    data.getTheGroupsImIn.forEach((group) => {
      finalData.push({ groupName: group.name, athletes: group.athletes });
      if (group.subGroups && group.subGroups.length > 0) {
        group.subGroups.map((subGroup) =>
          finalData.push({
            groupName: subGroup.name,
            athletes: subGroup.athletes,
          })
        );
      }
    });
    return finalData.filter((group) => group.athletes.length > 0);
  }, [data]);

  const onUserSelect = (id: string) => {
    setSelectedUsers((current) => {
      const currentIndex = current.indexOf(id);
      // deselect
      if (currentIndex > -1) {
        const newUsers = [...current];
        newUsers.splice(currentIndex, 1);
        return newUsers;
      }
      //select
      return [...current, id];
    });
  };

  const onSubmit = async () => {
    try {
      await copyWorkouts();
      await refetchGroupsImIn();
      showSuccessNotification(t("workoutDuplicatedSuccessful"));
    } catch {
      showErrorNotification(t("workoutDuplicatedError"));
    } finally {
      closeModal();
    }
  };

  const selectAllGroup = (ids: string[], select: boolean) => {
    setSelectedUsers((current) => {
      if (!select) {
        return [...current].filter(
          (currentId) => ids.indexOf(currentId) === -1
        );
      }
      return !current
        ? ids
        : [...current, ...ids].filter(
            (id, index, array) => array.indexOf(id) === index
          );
    });
  };

  return (
    <>
      <DuplicateWorkoutSelectAthleteContent
        data={groupedData}
        onUserSelect={onUserSelect}
        selectedUsers={selectedUsers}
        onWholeGroupSelect={selectAllGroup}
      />
      <BottomSection>
        <BottomBar
          secondary={{
            text: t("cancel"),
            onClick: closeModal,
          }}
          primary={{
            text: t("done"),
            onClick: onSubmit,
          }}
        />
      </BottomSection>
    </>
  );
};
