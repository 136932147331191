import * as api from "../api";
import * as endpoints from "../endpoints";

export function updateUserProfileComplex(
  user: {
    firstName: string;
    lastName: string;
    email?: string;
    username?: string;
    phoneNumber?: string;
  },
  sessionId: string,
  _focusedAthlete?: string
) {
  return api.post(
    endpoints.JsonProfileChangeRequest,
    {
      _sessionId: sessionId,
      _focusedAthlete: _focusedAthlete || undefined,
      _firstName: user.firstName,
      _lastName: user.lastName,
      _emailAddress: user.email,
      _userName: user.username,
      _phoneNumber: user.phoneNumber,
    },
    { sessionId }
  );
}

export function updateCredentials(
  sessionId,
  username,
  oldPassword,
  newPassword
) {
  return api.post(
    endpoints.JsonCredChangeRequest,
    {
      _sessionId: sessionId,
      _username: username,
      _oldPwd: oldPassword,
      _newPwd1: newPassword,
      _newPwd2: newPassword,
    },
    { sessionId }
  );
}

export async function postAvatar(
  sessionId: string,
  avatarUri: string,
  fileName: string,
  focusedAthleteGuid?: string
) {
  const form = new FormData();
  const url = `${endpoints.JsonSaveAvatar}&sessionId=${sessionId}${
    focusedAthleteGuid ? `&userGuid=${focusedAthleteGuid}` : ""
  }`;

  form.append("uploadedFile", DataURIToBlob(avatarUri), fileName);

  const result = await fetch(url, {
    method: "POST",
    body: form,
  });

  return await result.text();
}

function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}
