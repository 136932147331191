import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_TABLE_LABEL } from "../../colors";
import { AggregateFunction } from "../../graphql";
import {
  isSoccerStatsCollection,
  isXpsMagicCollection,
} from "../../utils/reports";
import { BasicButton } from "../Button";
import { TeamsAthletesDataComposition } from "../ReportCreateTable/hooks/useGroupStatsData";
import { COMPARISON_AGGREGATION_LABEL } from "../ReportCreateTable/hooks/useGroupStatsData/helpers/constants";
import { GroupTableStatsData } from "../ReportCreateTable/hooks/useGroupStatsData/helpers/models";
import { GroupTableSortBy } from "../ReportCreateTable/models";
import { Arrow, Direction, SortIconWrapper } from "../styled";

import {
  AthleteColumnTh,
  GroupTableHeaderCell,
  HeaderTitleTextWrapper,
  StyledTh,
} from "./styled";

interface IGroupTableHeaderProps {
  tableData: GroupTableStatsData;
  dataComposition: TeamsAthletesDataComposition;
  setSortBy: (sort: GroupTableSortBy) => void;
  sortBy: GroupTableSortBy & { order: number };
  lastDayInRange?: Dayjs;
  isSingleDayReport?: boolean;
  alternatingColumns?: boolean;
  shouldDisplayLongName?: boolean;
  setHiddenHeaderHeight?: (height: number) => void;
}

export const HeaderLine = styled.div`
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${COLOR_GREY_ACTIVE};
`;

export const ComplexHeaderValueWrapper = styled.div`
  color: ${COLOR_TEXT_TABLE_LABEL};
  font-size: 10px;
  text-transform: uppercase;
  line-height: 13px;
  background-color: inherit;
  padding: 6px 0;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const AggregationHeaderWrapper = styled.div`
  min-width: 50px;
`;

const AthleteTitleTextWrapper = styled(HeaderTitleTextWrapper)`
  justify-content: flex-start;
  height: 100%;
  padding: 5px 0;
`;

const TableHeaderButton = styled(BasicButton)<{ isCentred?: boolean }>`
  justify-content: ${({ isCentred }) => (isCentred ? "center" : "flex-start")};
  width: 100%;
`;

const TREND_COLUMN_AGGREGATION = "TREND";

export const GroupTableHeader = ({
  tableData,
  dataComposition,
  sortBy,
  setSortBy,
  lastDayInRange,
  isSingleDayReport = false,
  shouldDisplayLongName = true,
  setHiddenHeaderHeight,
}: IGroupTableHeaderProps) => {
  const { t } = useTranslation();
  const firstRowRef = useRef();
  const secondRowRef = useRef();

  const suitableColumnName: string = useMemo(() => {
    if (dataComposition === "athletes") return t("athlete");
    if (dataComposition === "mixed") return t("performer");
    if (dataComposition === "teams") return t("team");
    return t("athlete");
  }, [t, dataComposition]);

  useEffect(() => {
    if (setHiddenHeaderHeight) {
      // @ts-ignore
      const firstHiddenHeight = firstRowRef?.current?.clientHeight ?? 0;
      // @ts-ignore
      const secondHiddenHeight = secondRowRef?.current?.clientHeight ?? 0;
      setHiddenHeaderHeight(firstHiddenHeight + secondHiddenHeight);
    }
  }, [setHiddenHeaderHeight]);

  const headerDefinition =
    tableData?.length > 0
      ? tableData[0].stats.map((stat) => ({
          measurement: stat.label,
          subTitles: stat.attributes.flatMap((attribute, attributeIndex) => {
            const aggregations = attribute.values.map((value, valueIndex) => ({
              aggregation: value.aggregation,
              attributeName: isSoccerStatsCollection(value.id)
                ? ""
                : value.attributeName,
              attributeIndex,
              valueIndex,
              isInfo: value.isInfo,
              isSoccerStat: isSoccerStatsCollection(value.id),
              isXpsMagicStat: isXpsMagicCollection(value.id),
            }));

            const trend =
              attribute.trendValues?.length > 0
                ? {
                    aggregation: TREND_COLUMN_AGGREGATION,
                    attributeName: attribute.trendValues[0].attributeName,
                    attributeIndex: -1,
                    valueIndex: -1,
                    isInfo: false,
                    isSoccerStat: false,
                    isXpsMagicStat: false,
                  }
                : null;

            return trend ? [...aggregations, trend] : aggregations;
          }),
        }))
      : null;

  const directionIndicator = sortBy.order === 1 ? Direction.Up : Direction.Down;

  const getHeaderTitle = (isInfo: boolean) => {
    if (isInfo) {
      return t("current");
    }

    return dayjs().isSame(lastDayInRange, "day")
      ? t("today")
      : lastDayInRange.format("DD/MM");
  };

  const getTrendTitle = (attributeName: string) =>
    attributeName || TREND_COLUMN_AGGREGATION;

  const headerRow1 = [
    <AthleteColumnTh
      key="athlete-column"
      rowSpan={2}
      visible={shouldDisplayLongName}
      className="athlete-column-custom-padding-left"
    >
      <div style={{ display: "flex" }}>
        <TableHeaderButton
          onClick={() =>
            setSortBy({
              statsIndex: "athlete",
              valueIndex: 0,
              attributeIndex: 0,
            })
          }
        >
          <GroupTableHeaderCell>
            <AthleteTitleTextWrapper>
              {suitableColumnName}
              {sortBy.statsIndex === "athlete" && (
                <SortIconWrapper isIconVisible>
                  <Arrow direction={directionIndicator} />
                </SortIconWrapper>
              )}
            </AthleteTitleTextWrapper>
          </GroupTableHeaderCell>
        </TableHeaderButton>
      </div>
    </AthleteColumnTh>,
  ];
  const headerRow2 = [];

  headerDefinition?.forEach((headerDefinition, i) => {
    if (headerDefinition.subTitles.length === 0) {
      return null;
    }

    const isSimpleLatestStat =
      headerDefinition.subTitles.length === 1 &&
      headerDefinition.subTitles[0].aggregation === AggregateFunction.Latest &&
      !headerDefinition.subTitles[0].isInfo &&
      !isSingleDayReport;

    const isSoccerStat =
      headerDefinition.subTitles.length === 1 &&
      headerDefinition.subTitles[0].isSoccerStat;

    const isXpsMagicStat =
      headerDefinition.subTitles.length === 1 &&
      headerDefinition.subTitles[0].isXpsMagicStat;

    if (isSimpleLatestStat || isSoccerStat || isXpsMagicStat) {
      headerRow1.push(
        <StyledTh
          key={`${headerDefinition.measurement}-${i}`}
          rowSpan={2}
          style={{
            minWidth: 100,
          }}
        >
          <TableHeaderButton
            isCentred
            onClick={() =>
              setSortBy({ statsIndex: i, valueIndex: 0, attributeIndex: 0 })
            }
          >
            <GroupTableHeaderCell>
              <HeaderTitleTextWrapper>
                {headerDefinition.measurement}
                {sortBy.statsIndex === i && sortBy.valueIndex === 0 && (
                  <SortIconWrapper isIconVisible>
                    <Arrow direction={directionIndicator} />
                  </SortIconWrapper>
                )}
              </HeaderTitleTextWrapper>
            </GroupTableHeaderCell>
          </TableHeaderButton>
        </StyledTh>
      );
    } else {
      const subHeaders = headerDefinition.subTitles.map(
        (
          { aggregation, attributeName, attributeIndex, valueIndex, isInfo },
          index
        ) => (
          <StyledTh
            key={`${headerDefinition.measurement}-${index}-${aggregation}-${attributeName}`}
          >
            <TableHeaderButton
              isCentred
              onClick={() =>
                aggregation !== TREND_COLUMN_AGGREGATION &&
                setSortBy({ statsIndex: i, valueIndex, attributeIndex })
              }
            >
              <ComplexHeaderValueWrapper>
                <HeaderTitleTextWrapper>
                  {(() => {
                    switch (aggregation) {
                      case TREND_COLUMN_AGGREGATION:
                        return getTrendTitle(attributeName);
                      case COMPARISON_AGGREGATION_LABEL:
                        return (
                          <AggregationHeaderWrapper>
                            +&nbsp;/&nbsp;-
                          </AggregationHeaderWrapper>
                        );
                      default:
                        return `${
                          lastDayInRange &&
                          (aggregation === AggregateFunction.LastDayInRange ||
                            (isSingleDayReport &&
                              aggregation === AggregateFunction.Latest))
                            ? getHeaderTitle(isInfo)
                            : t(
                                `aggregation${
                                  aggregation as AggregateFunction
                                }`,
                                {
                                  defaultValue: aggregation,
                                }
                              )
                        } ${attributeName || ""}`;
                    }
                  })()}
                  {sortBy.statsIndex === i &&
                    sortBy.valueIndex === valueIndex &&
                    sortBy.attributeIndex === attributeIndex && (
                      <SortIconWrapper isIconVisible>
                        <Arrow direction={directionIndicator} />
                      </SortIconWrapper>
                    )}
                </HeaderTitleTextWrapper>
              </ComplexHeaderValueWrapper>
            </TableHeaderButton>
          </StyledTh>
        )
      );

      headerRow2.push(subHeaders);
      headerRow1.push(
        <th
          key={`${headerDefinition.measurement}-${i}`}
          colSpan={subHeaders.length}
        >
          <div>
            <ComplexHeaderValueWrapper>
              <span>{headerDefinition.measurement}</span>
            </ComplexHeaderValueWrapper>
            <HeaderLine />
          </div>
        </th>
      );
    }
  });

  return (
    <>
      <tr ref={firstRowRef} style={{ zIndex: 10 }}>
        {headerRow1}
      </tr>
      <tr ref={secondRowRef} style={{ zIndex: 10 }}>
        {headerRow2}
      </tr>
    </>
  );
};
