import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { updateCredentials } from "../../services/settings/userProfile";
import { ButtonColor, GeneralButton } from "../Button/";
import { ControlledInput } from "../HookForm/ControlledInput";
import StyledText from "../StyledText";

interface ChangePasswordFormModel {
  currentPassword: string;
  newPassword: string;
  repeatPassword: string;
}

const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;
`;

const ButtonWrapper = styled.div`
  min-width: 40%;
  margin-bottom: 25px;
`;

const InputsWrapper = styled.div`
  width: calc(100% - 60px);
  margin: 20px 30px;
`;

export const ChangePasswordModal = () => {
  const { t } = useTranslation();
  const { sessionId, userName } = useUserContext();
  const { actions: modalActions } = useTopModalContext();
  const { watch, ...methods } = useForm<ChangePasswordFormModel>();

  const data = watch();
  const { newPassword, repeatPassword, currentPassword } = data;

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");

  const onSaveNewCredentials = async () => {
    if (data.newPassword !== data.repeatPassword) {
      setPasswordMismatch(true);
      setServerErrorMessage("");
      return;
    }

    passwordMismatch && setPasswordMismatch(false);

    try {
      await updateCredentials(
        sessionId,
        userName,
        data.currentPassword,
        data.newPassword
      );

      modalActions.closeTopModal();
    } catch (error) {
      setServerErrorMessage(error);
    }
  };

  const doneIsDisabled = !newPassword || !repeatPassword || !currentPassword;

  return (
    <FormProvider watch={watch} {...methods}>
      <InputsWrapper>
        <ControlledInput
          name="currentPassword"
          label={t("currentPassword")}
          type="password"
          autoFocus={true}
        />
        <ControlledInput
          name="newPassword"
          label={t("newPassword")}
          type="password"
        />
        <ControlledInput
          name="repeatPassword"
          label={t("repeatPassword")}
          type="password"
        />

        {passwordMismatch && (
          <SpacingWrapper>
            <StyledText centerText color="#FF6562" bold fontSize={12}>
              {t("passwordsNotMatched")}
            </StyledText>
          </SpacingWrapper>
        )}

        {serverErrorMessage && (
          <SpacingWrapper>
            <StyledText centerText color="#FF6562" bold fontSize={12}>
              {serverErrorMessage}
            </StyledText>
          </SpacingWrapper>
        )}

        <SpacingWrapper>
          <StyledText centerText bold fontSize={12}>
            {t("passwordRequirements")}
          </StyledText>
        </SpacingWrapper>

        <SpacingWrapper>
          <ButtonWrapper>
            <GeneralButton
              colorVariant={ButtonColor.Gray}
              label={t("cancel")}
              fullWidth
              onClick={modalActions.closeTopModal}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <GeneralButton
              label={t("done")}
              fullWidth
              onClick={onSaveNewCredentials}
              isDisabled={doneIsDisabled}
            />
          </ButtonWrapper>
        </SpacingWrapper>
      </InputsWrapper>
    </FormProvider>
  );
};
