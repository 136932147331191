import { TimeLinePeriod } from "../components/Report/ChartWIdget/model";

export const getPeriodStartEndUnit = (timeLinePeriod: TimeLinePeriod) => {
  switch (timeLinePeriod) {
    case TimeLinePeriod.WEEKS:
      return "isoWeek";
    case TimeLinePeriod.NONE:
      return "days";
    default:
      return timeLinePeriod;
  }
};

export const getPeriodStartEndUnitFromString = (
  timeLinePeriod: "week" | "day" | "month"
) => {
  switch (timeLinePeriod) {
    case "week":
      return "isoWeek";
    default:
      return timeLinePeriod;
  }
};
