import React, { useEffect } from "react";

import styled, { useTheme } from "styled-components";

import {
  HeaderTitle,
  UserInfoContainer,
  UserInfoItemContainer,
  UserInfoItemLabelText,
  UserInfoItemValueText,
} from "../../components/Athlete/AthleteHeader";
import { useAthleteHeaderData } from "../../components/Athlete/useAthleteHeaderData";
import Avatar from "../../components/Avatar";
import { usePrintAthleteReportContext } from "../../contexts/Print/PrintAthleteReportProvider";
import { useReportsContext } from "../../contexts/Reports";
import { ReportWidgetTemplateHeader } from "../../graphql";
import useImageSize from "../../hooks/useImageSize";
import {
  HeaderWrapper,
  LogoImage,
  PeriodTitle,
  ReportTitle,
  TextWrapper,
} from "../styled";

interface AthleteReportPrintHeaderProps {
  title: string;
  period: string;
  accountLogo: string;
  headerWidget: ReportWidgetTemplateHeader;
}

const AthleteDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 40px 0;
`;

const AthleteDataTextWrapper = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;

export const AthleteReportPrintHeader = ({
  title,
  period,
  accountLogo,
  headerWidget,
}: AthleteReportPrintHeaderProps) => {
  const { mainTitle, periodFlag, logoFlag, headerIds, setMainTitle } =
    usePrintAthleteReportContext();
  const { isMobile } = useTheme();
  const imageSize = useImageSize(accountLogo);

  const [{ selectedAthlete }] = useReportsContext();

  const getSelectedCell = (cell: string) =>
    headerIds.includes(headerWidget && headerWidget[cell]?.id)
      ? headerWidget[cell]
      : undefined;

  const { data } = useAthleteHeaderData({
    athlete: selectedAthlete,
    headerTemplate: {
      ...headerWidget,
      cell1: getSelectedCell("cell1"),
      cell2: getSelectedCell("cell2"),
      cell3: getSelectedCell("cell3"),
      cell4: getSelectedCell("cell4"),
      cell5: getSelectedCell("cell5"),
      cell6: getSelectedCell("cell6"),
    },
  });

  useEffect(() => {
    setMainTitle(title);
  }, [title, setMainTitle]);

  return (
    <HeaderWrapper noBorder>
      <TextWrapper>
        {mainTitle && <ReportTitle>{mainTitle}</ReportTitle>}
        {periodFlag && <PeriodTitle>{period}</PeriodTitle>}

        <AthleteDataWrapper>
          <Avatar
            size={85}
            userName={selectedAthlete.name}
            uri={selectedAthlete.img}
          />

          <AthleteDataTextWrapper>
            <HeaderTitle style={{ marginTop: 0 }}>
              {selectedAthlete.name}
            </HeaderTitle>

            {data.length > 0 && (
              <UserInfoContainer style={{ marginTop: "16px" }}>
                {data.map((item) => (
                  <UserInfoItemContainer isMobile={isMobile}>
                    <UserInfoItemLabelText>{item.label}</UserInfoItemLabelText>
                    <UserInfoItemValueText>{item.value}</UserInfoItemValueText>
                  </UserInfoItemContainer>
                ))}
              </UserInfoContainer>
            )}
          </AthleteDataTextWrapper>
        </AthleteDataWrapper>
      </TextWrapper>

      {logoFlag && imageSize && (
        <LogoImage
          src={accountLogo}
          width={imageSize?.width}
          height={imageSize?.height}
        />
      )}
    </HeaderWrapper>
  );
};
