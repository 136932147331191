import React from "react";

import styled from "styled-components";

import { useMobileModalContext } from "../../../../contexts/mobileModal/MobileModalProvider";
import { useReportsContext } from "../../../../contexts/Reports";
import { XpsUser } from "../../../../graphql";
import Avatar from "../../../Avatar";
import { BasicButton } from "../../../Button";
import { SelectRowContainer, SelectText } from "../styled";

interface AthletePickerRowProps {
  athlete: XpsUser;
}

const PickerButton = styled(BasicButton)`
  display: block;
`;

export const AthletePickerRow = ({ athlete }: AthletePickerRowProps) => {
  const { hideModal } = useMobileModalContext();
  const [{ selectedAthlete }, actions] = useReportsContext();
  const isSelected = athlete.id === selectedAthlete?.id;

  return (
    <PickerButton
      key={athlete.id}
      onClick={() => {
        actions.selectAthlete(athlete);
        hideModal();
      }}
    >
      <SelectRowContainer isSelected={isSelected}>
        <Avatar
          size={25}
          userName={athlete.name}
          uri={athlete?.thumb ?? athlete?.img}
        />

        <SelectText fontSize={12} fontWeight="bold" isSelected={isSelected}>
          {athlete.name}
        </SelectText>
      </SelectRowContainer>
    </PickerButton>
  );
};
