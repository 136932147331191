import React, {
  Fragment,
  MutableRefObject,
  useContext,
  useMemo,
  useRef,
} from "react";

import chroma from "chroma-js";
import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../../colors";
import { ApRerouteContext } from "../../contexts/athleteProfile/ApRerouteProvider";
import { AggregateFunction } from "../../graphql";
import { isURL } from "../../utils/isURL";
import { BasicButton } from "../Button";
import Hoverable from "../Hoverable";
import { LinkUrl } from "../LinkUrl";
import {
  GroupTableStats,
  GroupTableStatsValue,
} from "../ReportCreateTable/hooks/useGroupStatsData/helpers/models";
import { ColoringConfig, IRule } from "../ReportCreateTable/models";
import { ANY_VALUE_ID } from "../ReportCreateTable/Rules/useConditionsData";
import { getColoringConfig } from "../SharedWidgetEditorSections/utils/getColoringConfig";
import StyledText from "../StyledText";

import {
  AthleteColumnTd,
  BorderedCellTd,
  ColorIndicator,
  ValueCellText,
} from "./styled";
import { getRulesStyle, getValueToDisplay, isValueMatchingRule } from "./utils";

const DEFAULT_ROW_HEIGHT = 45;

interface IGroupTableRowProps {
  item: GroupTableStats;
  isFirstRow: boolean;
  isLastRow: boolean;
  color: string;
  rowDividers?: boolean;
  columnDividers?: boolean;
  outsideBorderDividers?: boolean;
  alternatingColumns?: boolean;
  colorIndicator?: string;
  shouldDisplayLongName?: boolean;
  showValuesAsText?: boolean;
  rules: IRule[];
  coloringConfig: ColoringConfig;
  tableRowRef?: MutableRefObject<number[]>;
  index: number;
}

const TREND_COLUMN_HEIGHT = 24;

const TrendColumn = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  width: 4px;
  height: ${TREND_COLUMN_HEIGHT}px;
  background-color: ${(props) => props.color || "#ffeeee"};
  margin: 0 1px;
`;

const EmptyTrendOverlay = styled.div<{
  percentageOfMax: number;
  value: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => (!isNaN(props.value) ? "#eee" : "#ffeeee")};
  height: ${(props) => (1 - props.percentageOfMax) * TREND_COLUMN_HEIGHT}px;
`;

const AthleteContainer = styled.div<{ visibilityFlag: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  visibility: ${(props) => (props.visibilityFlag ? "visible" : "hidden")};
`;

const ButtonLabel = styled.p`
  white-space: nowrap;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

enum TrendTooltipDirection {
  LEFT = "left",
  RIGHT = "right",
}

export const TrendDetailWrapper = styled.div<{
  position?: TrendTooltipDirection;
}>`
  z-index: 100;
  position: absolute;
  border-radius: 4px;
  top: 75%;
  width: 120px;
  background-color: ${COLOR_WHITE};
  border-width: 1px;
  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  ${({ position = TrendTooltipDirection.LEFT }) =>
    position === TrendTooltipDirection.LEFT ? "left: 0" : "right: 0"};
`;

export const GroupTableRow = ({
  item,
  color,
  alternatingColumns,
  rules,
  colorIndicator,
  outsideBorderDividers,
  columnDividers,
  rowDividers,
  shouldDisplayLongName = false,
  isFirstRow,
  isLastRow,
  showValuesAsText,
  coloringConfig,
  tableRowRef,
  index,
  ...rest
}: IGroupTableRowProps) => {
  const cellOrderRef = useRef(0);

  const apReroute = useContext(ApRerouteContext);

  const hasNoValue = (value: GroupTableStatsValue) =>
    value === undefined ||
    value === null ||
    (typeof value.value === "number" && isNaN(value.value as number));

  cellOrderRef.current = 0;

  const rgbIndicator = useMemo(() => {
    let result = "DCDCDC";
    item.stats.forEach((stat) => {
      stat.attributes.forEach((attribute) => {
        attribute.values.forEach((value) => {
          if (value.id === colorIndicator) {
            result = value.rgb ?? "DCDCDC";
          }
        });
      });
    });
    return "#" + result;
  }, [item.stats, colorIndicator]);

  const getTrendColor = (
    value: number,
    trendMeasurementId: string,
    trendAttributeId: string,
    oldColor: string
  ) => {
    const usedRules = rules.filter(
      (rule) =>
        rule.measurementTemplateId === ANY_VALUE_ID ||
        (rule.measurementTemplateId === trendMeasurementId &&
          rule.attributeTemplateId === trendAttributeId)
    );

    const matchingRule = usedRules?.find((rule) =>
      isValueMatchingRule(`${value}`, rule)
    );

    if (matchingRule) {
      return matchingRule.conditionStyle.value;
    }

    return oldColor ? `#${oldColor}` : COLOR_BLUE;
  };

  if (!item.stats.length) {
    return null;
  }

  return (
    <tr
      style={{
        backgroundColor: color,
        height: 45,
      }}
      ref={(element) => {
        const rightHeight =
          element?.clientHeight > DEFAULT_ROW_HEIGHT
            ? element.clientHeight
            : DEFAULT_ROW_HEIGHT;

        if (tableRowRef.current.length > index) {
          tableRowRef.current[index] = rightHeight;
        } else {
          tableRowRef.current.push(rightHeight);
        }
      }}
    >
      <AthleteColumnTd
        key="athlete-column"
        style={{
          backgroundColor: color,
          color: COLOR_TEXT_DEFAULT,
        }}
        {...rest}
        shouldDisplayLeftBorder={outsideBorderDividers}
        shouldDisplayRightBorder={columnDividers}
        shouldDisplayTopBorder={outsideBorderDividers && isFirstRow}
        shouldDisplayBottomBorder={
          (rowDividers && !isLastRow) || (outsideBorderDividers && isLastRow)
        }
        className="athlete-column-custom-padding-left"
      >
        <AthleteContainer visibilityFlag={shouldDisplayLongName}>
          {colorIndicator && <ColorIndicator color={rgbIndicator} />}
          <BasicButton
            onClick={() =>
              apReroute?.onReroute && apReroute.onReroute(item.athleteId)
            }
          >
            <ButtonLabel>{item.athleteName}</ButtonLabel>
          </BasicButton>
        </AthleteContainer>
      </AthleteColumnTd>
      {item.stats?.map((stat, statsIndex) => (
        <Fragment key={`$${statsIndex}`}>
          {stat.attributes.map((attribute, attributeIndex) => {
            const values = attribute.values.map((value, valueIndex) => {
              const isLastColumnInRow =
                statsIndex === item.stats?.length - 1 &&
                attributeIndex === stat.attributes.length - 1 &&
                valueIndex === attribute.values.length - 1 &&
                attribute.trendValues?.length === 0;

              const coloring = coloringConfig.length
                ? coloringConfig.find(
                    (config) => config.id.replaceAll(":", "-") === value.id
                  )
                : null;

              const scale = coloring
                ? chroma
                    .scale(getColoringConfig(coloring.coloring))
                    .domain([0, 1])
                : undefined;

              const valueToDisplay = getValueToDisplay(showValuesAsText, value);

              return (
                <BorderedCellTd
                  key={`${item.athleteName}:${valueIndex}:${value.aggregation}:${value.attributeName}:${value.unitName}`}
                  withBackground={
                    alternatingColumns && cellOrderRef.current++ % 2 === 0
                  }
                  style={
                    value.txt !== "-" &&
                    coloring &&
                    value.pos !== undefined &&
                    scale
                      ? {
                          backgroundColor: scale(value.pos).hex(),
                        }
                      : {}
                  }
                  {...rest}
                  shouldDisplayLeftBorder={false}
                  shouldDisplayTopBorder={outsideBorderDividers && isFirstRow}
                  shouldDisplayRightBorder={
                    (columnDividers && !isLastColumnInRow) ||
                    (outsideBorderDividers && isLastColumnInRow)
                  }
                  shouldDisplayBottomBorder={
                    (rowDividers && !isLastRow) ||
                    (outsideBorderDividers && isLastRow)
                  }
                >
                  {isURL(value.txt || "") ? (
                    <LinkUrl
                      size={12}
                      url={value.txt}
                      wrapperStyle={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    />
                  ) : (
                    <ValueCellText
                      style={
                        item.isSummaryStatistic
                          ? {}
                          : getRulesStyle(
                              {
                                measurementTemplateId:
                                  value.measurementTemplateId,
                                attributeTemplateId: value.attributeTemplateId,
                                isInfo: value.isInfo,
                                aggregation:
                                  value.aggregation as AggregateFunction,
                                value:
                                  value.tifn &&
                                  value.value !== undefined &&
                                  value.value !== null
                                    ? value.value.toString()
                                    : getValueToDisplay(
                                        false,
                                        value
                                      ).toString(),
                                rgb: value.rgb ? `#${value.rgb}` : undefined,
                              },
                              rules
                            )
                      }
                    >{`${
                      !value.txt && hasNoValue(value)
                        ? "-"
                        : `${valueToDisplay}\u00A0${
                            (!value.txt && typeof value.value === "number") ||
                            valueToDisplay === value.value
                              ? value.unitName || ""
                              : ""
                          }`
                    }`}</ValueCellText>
                  )}
                </BorderedCellTd>
              );
            });

            const trend =
              attribute.trendValues?.length > 0 ? (
                <BorderedCellTd
                  key={`${item.athleteName}:${stat.label}:${attribute.trendValues[0]?.attributeName}TREND`}
                  withBackground={
                    alternatingColumns && cellOrderRef.current++ % 2 === 0
                  }
                  {...rest}
                  shouldDisplayLeftBorder={false}
                  shouldDisplayTopBorder={outsideBorderDividers && isFirstRow}
                  shouldDisplayRightBorder={
                    // We need to check whether it is the last column
                    columnDividers &&
                    (outsideBorderDividers ||
                      statsIndex !== item.stats?.length - 1)
                  }
                  shouldDisplayBottomBorder={
                    (rowDividers && !isLastRow) ||
                    (outsideBorderDividers && isLastRow)
                  }
                >
                  <ValueCellText>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {!item.hideTrend &&
                        attribute.trendValues?.map((trend, index) => (
                          <Hoverable key={index}>
                            {(isHovered) => (
                              <div
                                style={{
                                  zIndex: isHovered ? 100 : 1,
                                }}
                              >
                                <TrendColumn
                                  color={getTrendColor(
                                    trend.value,
                                    trend.measurementTemplateId,
                                    trend.attributeId,
                                    trend.color
                                  )}
                                >
                                  <EmptyTrendOverlay
                                    percentageOfMax={
                                      isNaN(trend.percentageOfMax)
                                        ? 0
                                        : trend.percentageOfMax
                                    }
                                    value={trend.value}
                                  />
                                </TrendColumn>
                                {isHovered && (
                                  <TrendDetailWrapper
                                    position={
                                      index < 2
                                        ? TrendTooltipDirection.LEFT
                                        : TrendTooltipDirection.RIGHT
                                    }
                                  >
                                    <StyledText>{`${trend.date?.toLocaleDateString()} ${
                                      trend.txt
                                        ? trend.txt
                                        : isNaN(trend.value)
                                          ? "-"
                                          : `${trend.value} ${
                                              trend.unitName || ""
                                            }`
                                    }`}</StyledText>
                                  </TrendDetailWrapper>
                                )}
                              </div>
                            )}
                          </Hoverable>
                        ))}
                    </div>
                  </ValueCellText>
                </BorderedCellTd>
              ) : null;

            return trend ? [...values, trend] : values;
          })}
        </Fragment>
      ))}
    </tr>
  );
};
