import React, { ReactNode } from "react";
import { EventWrapperProps } from "react-big-calendar";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_WHITE, COLOR_ALMOST_BLACK } from "../../colors";

import { CustomEvent } from "./model";

const StyledCustomCalendarEventWrapper = styled.div<{
  backgroundColor?: string;
  cancelled?: boolean;
}>`
  border-radius: 12px;
  margin: 0 4px 2px;
  background-color: ${({ backgroundColor, cancelled }) =>
    backgroundColor
      ? `rgb(${backgroundColor})`
      : cancelled
        ? "transparent"
        : COLOR_BLUE};
  color: ${({ backgroundColor, cancelled }) =>
    backgroundColor
      ? COLOR_WHITE
      : cancelled
        ? `rgb(${backgroundColor})`
        : COLOR_ALMOST_BLACK};
`;

export const CustomCalendarEventWrapper = ({
  event,
  children,
}: EventWrapperProps<CustomEvent> & { children: ReactNode }) => {
  return (
    <StyledCustomCalendarEventWrapper
      backgroundColor={event.color}
      cancelled={event.cancelled}
    >
      {children}
    </StyledCustomCalendarEventWrapper>
  );
};
