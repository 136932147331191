import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const SmallGroupProfileIcon = memo(
  ({ width = 15, height = 15, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_11684_23731)">
        <circle cx="3.5" cy="5.5" r="2" stroke="#8A939B" />
        <circle cx="7.5" cy="2.5" r="2" stroke="#8A939B" />
        <path
          d="M0.5 11.5C0.5 9.84315 1.84315 8.5 3.5 8.5C5.15685 8.5 6.5 9.84315 6.5 11.5V14C6.5 14.2761 6.27614 14.5 6 14.5H1C0.723858 14.5 0.5 14.2761 0.5 14V11.5Z"
          stroke="#8A939B"
        />
        <circle cx="11.5" cy="5.5" r="2" stroke="#8A939B" />
        <path
          d="M8.5 11.5C8.5 9.84315 9.84315 8.5 11.5 8.5C13.1569 8.5 14.5 9.84315 14.5 11.5V14C14.5 14.2761 14.2761 14.5 14 14.5H9C8.72386 14.5 8.5 14.2761 8.5 14V11.5Z"
          stroke="#8A939B"
        />
        <mask id="path-6-inside-1_11684_23731" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 9.49982V8.5C10 7.11929 8.88071 6 7.5 6C6.11929 6 5 7.11929 5 8.5L5 9.49982C4.70929 9.28146 4.36944 9.125 4 9.05001V8.5C4 6.567 5.567 5 7.5 5C9.433 5 11 6.567 11 8.5V9.05001C10.6306 9.125 10.2907 9.28146 10 9.49982ZM9.05001 11H5.94999C5.98278 11.1616 6 11.3288 6 11.5V12H9V11.5C9 11.3288 9.01722 11.1616 9.05001 11Z"
          />
        </mask>
        <path
          d="M10 9.49982H9V11.5016L10.6006 10.2994L10 9.49982ZM5 8.5H6H5ZM5 9.49982L4.39942 10.2994L6 11.5016L6 9.49982H5ZM4 9.05001H3V9.86741L3.80107 10.03L4 9.05001ZM11 9.05001L11.1989 10.03L12 9.86741V9.05001H11ZM9.05001 11L10.03 11.1989L10.2734 10H9.05001V11ZM5.94999 11V10H4.72661L4.96998 11.1989L5.94999 11ZM6 12H5V13H6V12ZM9 12V13H10V12H9ZM9 8.5V9.49982H11V8.5H9ZM7.5 7C8.32843 7 9 7.67157 9 8.5H11C11 6.567 9.433 5 7.5 5V7ZM6 8.5C6 7.67157 6.67157 7 7.5 7V5C5.567 5 4 6.567 4 8.5H6ZM6 9.49982L6 8.5H4L4 9.49982H6ZM3.80107 10.03C4.02132 10.0747 4.22459 10.1681 4.39942 10.2994L5.60058 8.70025C5.194 8.39486 4.71756 8.17527 4.19893 8.07L3.80107 10.03ZM3 8.5V9.05001H5V8.5H3ZM7.5 4C5.01472 4 3 6.01472 3 8.5H5C5 7.11929 6.11929 6 7.5 6V4ZM12 8.5C12 6.01472 9.98528 4 7.5 4V6C8.88071 6 10 7.11929 10 8.5H12ZM12 9.05001V8.5H10V9.05001H12ZM10.8011 8.07C10.2824 8.17527 9.806 8.39486 9.39942 8.70025L10.6006 10.2994C10.7754 10.1681 10.9787 10.0747 11.1989 10.03L10.8011 8.07ZM9.05001 10H5.94999V12H9.05001V10ZM4.96998 11.1989C4.98956 11.2954 5 11.396 5 11.5H7C7 11.2616 6.97601 11.0277 6.93 10.8011L4.96998 11.1989ZM5 11.5V12H7V11.5H5ZM6 13H9V11H6V13ZM8 11.5V12H10V11.5H8ZM8.07 10.8011C8.02399 11.0277 8 11.2616 8 11.5H10C10 11.396 10.0104 11.2954 10.03 11.1989L8.07 10.8011Z"
          fill="#8A939B"
          mask="url(#path-6-inside-1_11684_23731)"
        />
      </g>
      <defs>
        <clipPath id="clip0_11684_23731">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
