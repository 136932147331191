export enum StorageKeys {
  agendaTab = "agendaTab",
  accountsToSwitch = "accountsToSwitch",
  currentTranslation = "currentTranslation",
  isTestEnv = "isTest",
  isTv = "isTv",
  acesNation = "acesnation",
  lastSelectedCollectionForAllAccounts = "lastSelectedCollectionForAllAccounts",
  practiceForm = "practiceForm",
  gameOrEvent = "gameOrEvent",
  selectedCollection = "selectedCollection",
  selectedCollectionTab = "selectedCollectionTab",
  selectedDateResource = "selectedDateResource",
  sessionId = "sessionId",
  teamMembersLastScrollPosition = "teamMembersLastScrollPosition",
  timeFormat = "timeFormat",
  translationParamOverride = "translationParamOverride",
  rememberMe = "rememberMe",
  currentCalendarDate = "currentCalendarDate",
}
