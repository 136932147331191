import * as api from "../api";
import * as endpoints from "../endpoints";

export type FetchXpsMagicInfoStatisticsArgs = {
  session: api.Session;
  reportTemplateId: string;
  athleteIds: string[];
  templates: { id: string }[];
  dateFormat?: string;
};

type JsonXpsMagicInfoAthlete = {
  athleteFullName: string;
  athleteFirstName: string;
  athleteLastName: string;
  athleteGuid: string;
  templates: { id: string; name?: string; value?: string }[];
};

export type JsonXpsMagicInfoStatisticsQueryResponse = {
  byAthletes: JsonXpsMagicInfoAthlete[];
};

export function fetchXpsMagicInfoStatistics({
  session,
  dateFormat = "d. M. YYYY",
  ...params
}: FetchXpsMagicInfoStatisticsArgs) {
  return api.post<JsonXpsMagicInfoStatisticsQueryResponse>(
    endpoints.JsonXpsMagicInfoQuery,
    { ...params, dateFormat },
    session
  );
}
