import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const NextIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    tintColor = "#3C434C",
    strokeWidth = "1.5",
    direction,
    style,
    ...props
  }: SvgIconProps) => {
    const rotation = () => {
      switch (direction) {
        case "left":
          return "180";
        default:
          return "0";
      }
    };

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ ...style, transform: `rotate(${rotation()}deg)` }}
        {...props}
      >
        <path
          d="M7 18L14.5 10.5L7 3"
          stroke={tintColor}
          strokeWidth={strokeWidth}
        />
      </svg>
    );
  }
);
