import React from "react";
import { useTranslation } from "react-i18next";

import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { StyledTextInput } from "../StyledTextInput";

interface WidgetEditorTitleSectionProps {
  handleChange(e: React.ChangeEvent): void;
  value: string;
  name?: string;
  asSection?: boolean;
}
export const WidgetEditorTitleSection = ({
  value,
  handleChange,
  name = "title",
  asSection = true,
}: WidgetEditorTitleSectionProps) => {
  const { t } = useTranslation();

  const Wrapper = asSection ? Section : React.Fragment;

  return (
    <Wrapper>
      <SectionTitle title={t("title")} />
      <StyledTextInput name={name} value={value} onChange={handleChange} />
    </Wrapper>
  );
};
