import * as api from "./api";
import * as endpoints from "./endpoints";

export async function resetPassword(email: string) {
  const params = { _emailAddress: email, _sendEmailIfNotFound: false };

  const response = await api.post(endpoints.JsonPwdResetQuery, params);

  if (response.status !== 200) {
    return null;
  }

  return response;
}

export async function changePasswordWithReset({
  password,
  guidOfReset,
}: {
  password: string;
  guidOfReset: string;
}) {
  const params = { _newPwd: password, _guidOfResetFromEmail: guidOfReset };

  const response = await api.post(endpoints.JsonCredChangeRequest2, params);

  if (response.status !== 200) {
    return null;
  }

  return response;
}
