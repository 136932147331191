import React, { CSSProperties, ReactNode } from "react";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../colors";
import Avatar from "../../Avatar";

type Props = {
  value: string;
  secondaryValue?: string;
  secondaryValueTitle?: string;
  header?: string;
  image?: { uri: string };
  index?: number;
  isSmall?: boolean;
  style?: CSSProperties;
  icon?: ReactNode;
};

const ItemContainer = styled.div<{
  shouldShowBorder?: boolean;
  isSmall?: boolean;
}>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex: 1 1 auto;
  height: ${({ isSmall }) => (isSmall ? 45 : 65)}px;
  padding: 10px 15px;
  align-items: center;
  justify-content: space-between;
  cursor: default;
  border-top: ${({ shouldShowBorder }) =>
    shouldShowBorder ? `1px solid ${COLOR_GREY_ACTIVE}` : 0};
`;

const ValueContainer = styled.div<{
  hasImage?: boolean;
  hasSecondaryValue: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: ${({ hasImage }) => (hasImage ? "row" : "column")};
  align-items: ${({ hasImage }) => (hasImage ? "center" : "flex-start")};
  width: ${({ hasSecondaryValue }) => (hasSecondaryValue ? "50%" : "auto")};
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_TEXT_TABLE_LABEL};
  margin-bottom: 10px;
`;

const ItemValue = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
`;

const SecondaryItemWrapper = styled.div``;

const SecondaryItemTitle = styled.span`
  font-size: 10px;
  text-transform: uppercase;
`;

export function ProfileItem({
  header,
  value,
  secondaryValue,
  secondaryValueTitle,
  image,
  index,
  isSmall,
  style,
  icon,
}: Props) {
  return (
    <ItemContainer shouldShowBorder={!!index} isSmall={isSmall} style={style}>
      <ValueContainer
        hasImage={!!image}
        hasSecondaryValue={Boolean(secondaryValue)}
      >
        {image && (
          <Avatar
            // no need for first/lastName - image defined
            userName={value}
            source={image}
            size={45}
            style={{ marginRight: 20 }}
          />
        )}
        {header ? <Header>{header}</Header> : null}
        <ItemValue>{value}</ItemValue>
      </ValueContainer>
      {secondaryValue ? (
        <SecondaryItemWrapper>
          {secondaryValueTitle ? (
            <SecondaryItemTitle>{secondaryValueTitle}</SecondaryItemTitle>
          ) : null}
          <ItemValue>{secondaryValue}</ItemValue>
        </SecondaryItemWrapper>
      ) : null}

      {icon}
    </ItemContainer>
  );
}
