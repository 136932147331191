export const getPracticeWithDrillTimes = (practice, start) => {
  const practiceStart = start;
  const lines = practice.practicelines || practice.practiceLines || [];
  let lastEnd = practiceStart;

  const lineMap = (line) => {
    if (line.__typename === "Drill") {
      const end = lastEnd + line.minutes * 60 * 1000;
      const startToReturn = lastEnd;
      lastEnd = end;

      return {
        ...line,
        start: startToReturn,
        end,
      };
    }

    if (line.__typename === "Split") {
      const newLine = {
        ...line,
        start: lastEnd,
        subPractices: line.subPractices.map((subPractice) =>
          getPracticeWithDrillTimes(subPractice, lastEnd)
        ),
      };

      newLine.subPractices.forEach((subPractice) => {
        const lastDrillIndex = subPractice.practiceLines.length - 1;
        const lastDrill = subPractice.practiceLines[lastDrillIndex];

        if (lastDrill && lastDrill.end > lastEnd) {
          lastEnd = lastDrill.end;
        }
      });

      return newLine;
    }

    if (line.__typename === "PracticeInlineComment") {
      return line;
    }
  };

  return {
    ...practice,
    practicelines: undefined,
    practiceLines: lines.map(lineMap),
  };
};
