import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Chatter } from "../../graphql";
import Avatar from "../Avatar";
import { RemoveIcon } from "../Icons";
import StyledText from "../StyledText";

import { ChatMemberTypeText } from "./ChatMemberTypeText";

export const ChatMemberListItemWrapper = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 45px;
  align-items: center;
  padding: 0 15px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e8eb;
  cursor: ${({ clickable }) => (clickable ? "pointer" : "inherit")};
`;

const AvatarNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const ImageWrapper = styled.div`
  cursor: pointer;
`;

interface ChatMemberListItemProps {
  chatter: Pick<
    Chatter,
    "avatarThumbURL" | "name" | "id" | "chatterType2" | "firstName" | "lastName"
  > & {
    userID?: string;
  };
  isEditChatMode?: boolean;
  onRemoveClick?: (userToRemoveId: string) => void;
}

export const ChatMemberListItem = ({
  chatter,
  isEditChatMode,
  onRemoveClick,
}: ChatMemberListItemProps) => {
  const { id } = useUserContext();

  const onRemove = () => onRemoveClick?.(chatter.id || chatter.userID);

  const canDeleteMember = isEditChatMode && chatter?.id !== id;

  return (
    <ChatMemberListItemWrapper clickable={false}>
      <AvatarNameWrapper>
        <Avatar
          size={25}
          source={{
            uri: chatter?.avatarThumbURL,
          }}
          userName={chatter.name}
          style={{ marginRight: 10 }}
        />
        <StyledText
          fontWeight="bold"
          fontSize={14}
          color={COLOR_TEXT_DEFAULT}
          cursor="default"
        >
          {chatter.name}
        </StyledText>
      </AvatarNameWrapper>

      <ChatMemberTypeText withPadding={canDeleteMember} user={chatter} />

      {canDeleteMember && (
        <ImageWrapper onClick={onRemove}>
          <RemoveIcon />
        </ImageWrapper>
      )}
    </ChatMemberListItemWrapper>
  );
};
