import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../colors";
import { ModalType, useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import { Attendance, Avatar as AvatarModel } from "../graphql";

import { AttendanceButton } from "./AttendanceButton";
import Avatar from "./Avatar";

const AttendeeContainer = styled.tr<{ isSessionDetail?: boolean }>`
  margin-bottom: 5px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

const AthleteName = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: ${COLOR_TEXT_DARK};
  margin-left: 10px;
`;

interface AttendanceSectionProps {
  sessionGuid: string;
  attendance?: Attendance;
  avatarsOfChildren: AvatarModel[];
  showAthleteName?: boolean;
  isSessionDetail?: boolean;
  refetch: () => Promise<any>;
  withRegistration: boolean;
  isExpired?: boolean;
}

function AttendeeItem({
  sessionGuid,
  showAthleteName,
  attendance,
  avatarsOfChildren,
  refetch,
  isSessionDetail,
  withRegistration,
  isExpired,
}: AttendanceSectionProps) {
  const {
    athleteGuid,
    athleteName,
    attendance: attendanceStatus,
  } = attendance || {};

  const {
    userID,
    name: childrenName,
    img: childrenImage,
  } = avatarsOfChildren?.[0] || {};

  const { isFamilyMember, profileImageUrlThumb } = useUserContext();

  const avatarUrl =
    avatarsOfChildren.find((avatar) => avatar.userID === athleteGuid)?.thumb ||
    childrenImage;

  const modal = useModalContext();
  const { t } = useTranslation();

  const attendanceParams = attendance || {
    athleteGuid: userID,
    athleteName: childrenName,
    attendance,
  };

  const onSelectPerformersClick = (event) => {
    // do not open session detail on attendance button click
    event.stopPropagation();
    event.preventDefault();

    modal.actions.openModal({
      modal: ModalType.ATTENDANCE,
      style: { minWidth: 375 },
      title: t("attendance"),
      params: { sessionGuid, refetch, ...attendanceParams },
    });
  };

  if (!athleteGuid) {
    return null; // we need to decide how to proceed here
  }

  return (
    <AttendeeContainer isSessionDetail={isSessionDetail}>
      <td>
        <NameWrapper>
          {isFamilyMember ? (
            <Avatar
              size={25}
              userGuid={athleteGuid}
              userName={athleteName || childrenName}
              isThumb
              source={{
                uri: isFamilyMember ? avatarUrl : profileImageUrlThumb,
              }}
            />
          ) : null}
          {showAthleteName && (
            <AthleteName>{athleteName || childrenName}</AthleteName>
          )}
        </NameWrapper>
      </td>
      <td>
        <AttendanceButton
          onClick={onSelectPerformersClick}
          attendanceStatus={attendanceStatus}
          withRegistration={withRegistration}
          isExpired={isExpired}
        />
      </td>
    </AttendeeContainer>
  );
}

export default AttendeeItem;
