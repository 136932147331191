import { useCallback } from "react";

import { useMeasurementAndAttributeDetails } from "./useMeasurementAndAttributeDetails";

export const useChartMeasurementAttributeData = () => {
  const { getDetails } = useMeasurementAndAttributeDetails();

  const loadDataForChartAxis = useCallback(
    async (
      collectionId: string,
      measurementTemplateId: string,
      attributeId: string
    ) => {
      const { measurementTemplate, attributeTemplate } = await getDetails(
        collectionId,
        measurementTemplateId,
        attributeId
      );

      return {
        templateName: measurementTemplate?.name ?? "",
        attributeUnitName: attributeTemplate?.unitName ?? "",
      };
    },
    [getDetails]
  );

  return { loadDataForChartAxis };
};
