import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_SECONDARY_GREY_SELECTED,
} from "../../../colors";
import { ChevronRightIcon } from "../../Icons/ChevronRightIcon";
import { DuplicateWorkoutGroup } from "../DuplicateWorkoutSelectAthleteModal";

import { DuplicateWorkoutSelectAthleteRow } from "./Row";

const Container = styled.div<{ isFirst?: boolean }>`
  border-top: ${({ isFirst }) =>
    isFirst ? `1px solid ${COLOR_GREY_ACTIVE}` : "none"};
  position: relative;
`;

const Content = styled.div<{ isClosed: boolean }>`
  overflow: hidden;
  max-height: ${({ isClosed }) => (isClosed ? "0" : "unset")};
  border-top: ${({ isClosed }) =>
    isClosed ? `1px solid ${COLOR_GREY_ACTIVE}` : "none"};
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  color: ${COLOR_SECONDARY_GREY_SELECTED};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding-inline: 15px;
  flex-shrink: 0;
  padding-block: 10px;
  text-transform: uppercase;
  cursor: pointer;
`;

const SelectAllText = styled.div`
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  color: ${COLOR_BLUE};
  padding-inline: 15px;
  margin-left: auto;
  cursor: pointer;
`;

interface DuplicateWorkoutSelectAthleteGroupProps {
  data: DuplicateWorkoutGroup;
  selectedUsers: string[];
  isFirst?: boolean;
  onUserSelect: (id: string) => void;
  onAllUsersSelect: (select: boolean) => void;
}

export const DuplicateWorkoutSelectAthleteGroup = ({
  data,
  selectedUsers,
  onUserSelect,
  onAllUsersSelect,
}: DuplicateWorkoutSelectAthleteGroupProps) => {
  const [isClosed, setIsClosed] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const { t } = useTranslation();

  const selectAll = () => {
    onAllUsersSelect(!allSelected);
    setAllSelected((current) => !current);
  };

  return (
    <Container>
      <GroupHeader>
        <Text onClick={() => setIsClosed((current) => !current)}>
          {data.groupName}
        </Text>
        <ChevronRightIcon
          direction={isClosed ? "bottom" : "top"}
          style={{ cursor: "pointer" }}
          onClick={() => setIsClosed((current) => !current)}
        />
        <SelectAllText onClick={selectAll}>
          {allSelected ? t("clearAll") : t("selectAll")}
        </SelectAllText>
      </GroupHeader>
      <Content isClosed={isClosed}>
        {data.athletes.map((athlete, index) => (
          <DuplicateWorkoutSelectAthleteRow
            isFirst={index === 0}
            data={athlete}
            key={athlete.id}
            isSelected={selectedUsers.indexOf(athlete.id) > -1}
            onClick={() => onUserSelect(athlete.id)}
          />
        ))}
      </Content>
    </Container>
  );
};
