import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_BLUE, COLOR_GREY_ACTIVE, COLOR_TEXT_DARK } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import {
  ReportTemplatesDocument,
  ReportType,
  useSaveReportTemplateMutation,
} from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { useAddReportToMonitoring } from "../../hooks/report/useAddReportToMonitoring";
import { ROUTING_CONFIG } from "../../router/RoutingConfig";
import { createSearchParamsWithoutUndefinedValues } from "../../utils/createSearchParamsWithoutUndefinedValues";
import { generateDefaultLayout } from "../../utils/generateDefaultLayout";
import { GeneralButton } from "../Button/";
import Dropdown from "../Dropdown";
import { HoverableItem } from "../HoverableItem";
import { PlusIcon } from "../Icons";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const HeaderTitle = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 20px;
  margin-right: 24px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${COLOR_GREY_ACTIVE};
  margin: 5px 0;
`;

interface CreateItemDropdownProps {
  buttonLabel?: string;
  hideCreateWidget?: boolean;
  isFromMonitoring?: boolean;
  onOptionClick?: () => void;
}

export function CreateItemDropdown({
  buttonLabel,
  hideCreateWidget = false,
  isFromMonitoring = false,
  onOptionClick,
}: CreateItemDropdownProps) {
  const navigate = useNavigate();
  const [createReport, { loading: savingReport }] =
    useSaveReportTemplateMutation({
      refetchQueries: [ReportTemplatesDocument],
    });

  const { addReportToMonitoringGroup } = useAddReportToMonitoring();
  const user = useUserContext();
  const { t } = useTranslation();
  const modal = useModalContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  // Button Function
  const onCreateAthleteReportPress = async () => {
    // Create child report with grid items initialized
    // TODO: Can this be done on the backend?
    const createChildReport = async (name: string, reportId: string) => {
      const layout = generateDefaultLayout();

      await createReport({
        variables: {
          sessionId: user.sessionId,
          accountId: selectedAccountId,
          input: {
            id: null,
            parentId: reportId,
            name,
            reportType: ReportType.Tab,
            preferences: JSON.stringify({
              layout,
              createdBy: `${user.firstName} ${user.lastName}`,
              updatedAt: dayjs(),
            }),
          },
        },
      });
    };
    const createResponse = await createReport({
      variables: {
        sessionId: user.sessionId,
        accountId: selectedAccountId,
        input: {
          id: null,
          name: t("athleteReport"),
          reportType: ReportType.AthleteReport,
          preferences: JSON.stringify({
            layout: [],
            createdBy: `${user.firstName} ${user.lastName}`,
            updatedAt: dayjs(),
          }),
        },
      },
    });

    if (onOptionClick) {
      onOptionClick();
    }

    if (createResponse.data?.saveReportTemplate) {
      await createChildReport(
        "Tab 1",
        createResponse.data.saveReportTemplate.id
      );

      if (isFromMonitoring) {
        addReportToMonitoringGroup({
          reportId: createResponse.data.saveReportTemplate.id,
          reportName: createResponse.data.saveReportTemplate.name,
        });
      }

      navigate({
        pathname: `/reports/report/${createResponse.data.saveReportTemplate.id}`,
        search: createSearchParamsWithoutUndefinedValues({
          isEditMode: "true",
          fromMonitoring: isFromMonitoring ? "true" : undefined,
          activeProfileTabId: isFromMonitoring
            ? createResponse.data.saveReportTemplate.id
            : undefined,
        }),
      });
    } else {
      navigate("/reports");
    }
  };

  // Button Function
  const onCreateTeamReportPress = async () => {
    const layout = generateDefaultLayout();

    const createResponse = await createReport({
      variables: {
        sessionId: user.sessionId,
        accountId: selectedAccountId,
        input: {
          name: "Report",
          reportType: ReportType.GroupReport,
          preferences: JSON.stringify({
            layout,
            createdBy: `${user.firstName} ${user.lastName}`,
            updatedAt: dayjs(),
          }),
        },
      },
    });

    if (onOptionClick) {
      onOptionClick();
    }

    if (createResponse.data?.saveReportTemplate) {
      if (isFromMonitoring) {
        addReportToMonitoringGroup({
          reportId: createResponse.data.saveReportTemplate.id,
          reportName: createResponse.data.saveReportTemplate.name,
        });
      }

      navigate({
        pathname: `/reports/groupReport/${createResponse.data.saveReportTemplate.id}`,
        search: createSearchParamsWithoutUndefinedValues({
          isEditMode: "true",
          activeProfileTabId: isFromMonitoring
            ? createResponse.data.saveReportTemplate.id
            : undefined,
        }),
      });
    } else {
      navigate(ROUTING_CONFIG.ListReports);
    }
  };

  const onCreateWidgetPress = () => {
    modal.actions.openModal({
      modal: ModalType.CREATE_WIDGET,
      title: <HeaderTitle>{t("createWidget")}</HeaderTitle>,
    });
  };

  return (
    <Wrapper>
      {savingReport ? (
        <Loader color={COLOR_BLUE} size="large" />
      ) : (
        <Dropdown
          dropdownContainerStyle={{
            width: 185,
            height: 126,
            marginRight: 0,
            marginTop: 25,
            padding: 0,
          }}
          dropdownContentStyle={{
            padding: "5px 0",
          }}
          component={({ toggle }) => (
            <GeneralButton
              icon={<PlusIcon isActive />}
              label={buttonLabel ?? t("create")}
              onClick={toggle}
            />
          )}
        >
          <HoverableItem
            label={t("createAthleteReport")}
            onClick={() => {
              onCreateAthleteReportPress();
            }}
          />

          <HoverableItem
            label={t("createTeamReport")}
            onClick={() => {
              onCreateTeamReportPress();
            }}
          />

          {!hideCreateWidget && (
            <>
              <Divider />
              <HoverableItem
                label={t("createWidget")}
                onClick={() => {
                  onCreateWidgetPress();
                }}
              />
            </>
          )}
        </Dropdown>
      )}
    </Wrapper>
  );
}
