import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../../colors";
import { Group } from "../../../contexts/Reports";
import { useSelectedPerformersContext } from "../../../contexts/selectedAthletes";
import { XpsUser } from "../../../graphql";
import { ButtonColor, GeneralButton } from "../../Button/";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const AthletesSelectorFooter = ({ closeModal, onShow }) => {
  const { t } = useTranslation();

  const {
    attendingUsers,
    attendingTeams,
    confirmSelectedAthletes,
    confirmSelectedTeams,
  } = useSelectedPerformersContext();

  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [cleared, setCleared] = useState<boolean>(false);

  const onConfirm = () => {
    setConfirmed(true);
    setCleared(false);
  };

  const onClear = () => {
    setCleared(true);
  };

  const initAttendingUsers = useMemo(
    (): XpsUser[] => attendingUsers,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const initAttendingTeams = useMemo(
    (): Partial<Group>[] => attendingTeams,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    confirmed && !cleared && closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, cleared]);

  useEffect(() => {
    if (cleared) {
      confirmSelectedAthletes([]);
      confirmSelectedTeams([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleared]);

  useLayoutEffect(() => {
    return () => {
      if (!cleared) {
        confirmSelectedAthletes(
          confirmed ? attendingUsers : initAttendingUsers
        );
        confirmSelectedTeams(confirmed ? attendingTeams : initAttendingTeams);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmed, cleared]);

  useEffect(() => {
    if ((attendingUsers.length > 0 || attendingTeams.length > 0) && cleared) {
      setCleared(false);
    }
  }, [attendingUsers, attendingTeams, cleared]);

  return (
    <Wrapper>
      <GeneralButton
        colorVariant={ButtonColor.Red}
        label={t("clear")}
        onClick={onClear}
      />
      <ButtonsWrapper>
        <GeneralButton
          colorVariant={ButtonColor.Gray}
          label={t("cancel")}
          onClick={() => {
            closeModal();
            onShow && onShow();
          }}
        />
        <GeneralButton
          colorVariant={ButtonColor.Blue}
          label={t("ok")}
          onClick={onConfirm}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};
