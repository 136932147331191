import React, { useCallback } from "react";

import { useFormikContext } from "formik";

import { COLOR_MEDIUM_GRAY } from "../../colors";
import { AggregateFunction, ReportItemType } from "../../graphql";
import { useHasSoccer } from "../../hooks/useHasSoccer";
import { isInformationCollection } from "../../utils/reports";
import { IconButton } from "../IconButton";
import { BinIcon, DuplicateIcon, ReorderIcon } from "../Icons";
import {
  DataSourceSelectorRow,
  DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
} from "../Report/DataSourceSelector";
import { DraggableLineWrapper } from "../styled";
import { DragHandlePropsType } from "../Wrappers/DragDropContainer";

import { CreateAthleteTableFormValue } from "./models";
import { IconButtonWrapper, ReorderImageWrapper } from "./styled";

interface IRowSectionProps {
  rowIndex: number;
  push(item: object): void;
  remove(rowIndex: number): void;
  dragHandleProps: DragHandlePropsType;
}

export function RowSection({
  rowIndex,
  dragHandleProps,
  push,
  remove,
}: IRowSectionProps) {
  const { values, setFieldValue } =
    useFormikContext<Pick<CreateAthleteTableFormValue, "rows" | "columns">>();
  const hasSoccer = useHasSoccer();

  const currentRow = values.rows[rowIndex];

  const customCollectionChange = useCallback(
    (collectionId: string) => {
      if (isInformationCollection(collectionId)) {
        if (!values.columns.includes(AggregateFunction.Latest)) {
          setFieldValue("columns", [
            ...values.columns,
            AggregateFunction.Latest,
          ]);
        }
      }
    },
    [values.columns, setFieldValue]
  );

  const clone = useCallback(() => {
    push(currentRow);
  }, [currentRow, push]);

  const onChangeRowValues = useCallback(
    (newValues: typeof currentRow) => {
      setFieldValue(`rows[${rowIndex}]`, {
        ...currentRow,
        ...newValues,
      });
    },
    [currentRow, rowIndex, setFieldValue]
  );

  return (
    <DraggableLineWrapper>
      <ReorderImageWrapper {...dragHandleProps}>
        <ReorderIcon />
      </ReorderImageWrapper>
      <DataSourceSelectorRow
        currentDataSource={currentRow}
        dataSourceArrayItemAccessor={`rows[${rowIndex}]`}
        customCollectionChange={customCollectionChange}
        onChangeDataSourceValues={onChangeRowValues}
        allowedReportItemTypes={[
          ...DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
          ReportItemType.XpsMagic,
          ReportItemType.Attendance,
          ...(hasSoccer ? [ReportItemType.SoccerStat] : []),
        ]}
      />

      <IconButtonWrapper>
        <IconButton
          icon={<DuplicateIcon tintColor={COLOR_MEDIUM_GRAY} />}
          onClick={clone}
        />
        {values?.rows?.length > 1 && (
          <IconButton icon={<BinIcon />} onClick={() => remove(rowIndex)} />
        )}
      </IconButtonWrapper>
    </DraggableLineWrapper>
  );
}
