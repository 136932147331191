import React, { CSSProperties } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import styled from "styled-components";

import { GeneralButton, ButtonColor } from "./Button/";

type Button = {
  onClick?: () => void;
  text: string;
  isLoading?: boolean;
  variant?: ButtonColor;
  disabled?: boolean;
  getRootProps?: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps?: <T extends DropzoneInputProps>(props?: T) => T;
};

type Props = {
  primary?: Button;
  secondary?: Button;
  additional?: Button;
  style?: CSSProperties;
};

const ButtonContainer = styled.div<{ hasAdditionalButton?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ hasAdditionalButton }) =>
    hasAdditionalButton ? "space-between" : "flex-end"};
`;

const MainButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
`;

export function BottomBar({ primary, secondary, additional, style }: Props) {
  return (
    <ButtonContainer hasAdditionalButton={!!additional} style={style}>
      {additional && (
        <div {...additional.getRootProps()}>
          <label htmlFor="contained-button-file">
            <GeneralButton
              label={additional.text}
              colorVariant={additional.variant ?? ButtonColor.Blue}
              outlined
            />
          </label>
          <input
            type="file"
            accept="*"
            style={{ display: "none" }}
            {...additional.getInputProps()}
            id="contained-button-file"
          />
        </div>
      )}
      <MainButtons>
        {secondary && (
          <GeneralButton
            label={secondary.text}
            colorVariant={secondary.variant ?? ButtonColor.Light}
            onClick={secondary.onClick}
            isDisabled={secondary.disabled}
          />
        )}
        {primary && (
          <GeneralButton
            label={primary.text}
            colorVariant={primary.variant}
            isLoading={primary.isLoading}
            isDisabled={primary.disabled}
            onClick={primary.onClick}
          />
        )}
      </MainButtons>
    </ButtonContainer>
  );
}
