import * as React from "react";

export const LandscapeOrientationStyleEnforcement = ({
  landscapeOrientation,
}: {
  landscapeOrientation: boolean;
}) => (
  <>
    {landscapeOrientation && (
      <style type="text/css" media="print">
        {
          "\
@page { size: landscape; }\
"
        }
      </style>
    )}
  </>
);
