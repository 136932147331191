import { useMemo } from "react";

import _uniq from "lodash/uniq";

import { useReportContext } from "../../../../contexts/report";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { AggregateFunction } from "../../../../graphql";
import {
  useGroupWidgetParticipants,
  useTeamsAthletes,
} from "../../../../hooks";
import {
  CompareLatestTo,
  FetchJsonCombinedTestResultsResponse,
} from "../../../../services/statistics";
import { useCombineTestStatisticsQuery } from "../../../../services/statistics/hooks";
import { ReportWidgetTemplateCombinedTestPreferences } from "../../../../utils/widgetPreferencesBuilder";
import { CombinedTestWidgetModel } from "../../../ReportCreateTable/models";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import { PerformerSelectionMode } from "../../PerformerSection";
import { CompareTo } from "../models";

import { useGenerateCombinedTestDummyData } from "./useGenerateCombinedTestDummyData";

type CombineTestWidgetData = {
  isLoading?: boolean;
  _theAthlete?: FetchJsonCombinedTestResultsResponse;
  _theTeam?: FetchJsonCombinedTestResultsResponse;
};

export function useCombinedTestData({
  widget,
  isGroupReport,
  useDummyData,
}: {
  widget: CombinedTestWidgetModel;
  isGroupReport: boolean;
  useDummyData?: boolean;
}): CombineTestWidgetData {
  const generateCombinedTestDummyData = useGenerateCombinedTestDummyData();
  const athleteData = useAthleteCombinedTestData({
    widget,
    disabled: useDummyData || isGroupReport,
  });
  const groupData = useGroupCombinedTestData({
    widget,
    disabled: useDummyData || !isGroupReport,
  });

  const dummyData = useMemo<CombineTestWidgetData>(() => {
    return isGroupReport
      ? {
          _theAthlete: generateCombinedTestDummyData(widget, true),
          isLoading: false,
        }
      : {
          _theAthlete: generateCombinedTestDummyData(widget, false),
          _theTeam: widget.showTeamAverage
            ? generateCombinedTestDummyData(widget, true)
            : undefined,
          isLoading: false,
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget, isGroupReport]);

  if (useDummyData) {
    return dummyData;
  }

  return isGroupReport ? groupData : athleteData;
}

export function useAthleteCombinedTestData({
  widget,
  disabled,
}: {
  widget: CombinedTestWidgetModel;
  disabled?: boolean;
}): CombineTestWidgetData {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const [{ selectedAthlete, selectedGroup }] = useReportsContext();
  const period = useReportPeriod(widget.period);

  const athleteGuid =
    widget.performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED
      ? widget.athlete
      : selectedAthlete?.id;

  const {
    data: athleteCombineTestStatisticsResponse,
    isLoading: athleteCombineTestStatisticsIsLoading,
    fetchStatus: athleteCombineTestStatisticsFetchStatus,
  } = useCombineTestStatisticsQuery(
    {
      combinedTestGuid: widget.testTemplateId,
      aggregationPerAthlete: AggregateFunction.Latest,
      athleteGuids: [athleteGuid],
      session: {
        sessionId,
        focusedAthlete: athleteGuid,
      },
      compareLatestTo: getCompareLatestTo(widget.compareTo),
      reportTemplateId: report?.id,
      ...period,
    },
    {
      enabled: Boolean(
        sessionId && !disabled && widget.testTemplateId && athleteGuid
      ),
      isDataEqual(oldData, newData) {
        return JSON.stringify(oldData?.data) === JSON.stringify(newData?.data);
      },
    }
  );

  const {
    data: teamCombineTestStatisticsResponse,
    isLoading: teamCombineTestStatisticsIsLoading,
    fetchStatus: teamCombineTestStatisticsFetchStatus,
  } = useCombineTestStatisticsQuery(
    {
      combinedTestGuid: widget.testTemplateId,
      teamAggregation: AggregateFunction.Average,
      athleteGuids:
        selectedGroup?.requiredAthletes?.map((athlete) => athlete.id) ?? [],
      session: {
        sessionId,
        focusedAthlete: athleteGuid,
      },
      compareLatestTo: getCompareLatestTo(widget.compareTo),
      reportTemplateId: report?.id,
      ...period,
    },
    {
      enabled:
        sessionId &&
        !disabled &&
        widget.testTemplateId &&
        widget.showTeamAverage &&
        selectedGroup?.requiredAthletes?.length > 0,
      isDataEqual(oldData, newData) {
        return JSON.stringify(oldData?.data) === JSON.stringify(newData?.data);
      },
    }
  );

  return {
    _theAthlete: athleteCombineTestStatisticsResponse?.data,
    _theTeam: widget.showTeamAverage
      ? teamCombineTestStatisticsResponse?.data
      : undefined,
    isLoading:
      (athleteCombineTestStatisticsFetchStatus !== "idle" &&
        athleteCombineTestStatisticsIsLoading) ||
      (teamCombineTestStatisticsFetchStatus !== "idle" &&
        teamCombineTestStatisticsIsLoading),
  };
}

export function useGroupCombinedTestData({
  widget,
  disabled,
}: {
  widget: CombinedTestWidgetModel;
  disabled?: boolean;
}): CombineTestWidgetData {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();
  const period = useReportPeriod(widget.period);

  const { participantTeams, participants: participantsIds } =
    useGroupWidgetParticipants(widget);
  const teams = useTeamsAthletes(participantTeams);

  const athleteGuids = useMemo(
    () =>
      _uniq([
        ...teams.flatMap((team) => team.participants),
        ...participantsIds,
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams.map((team) => team.id).join(":"), participantsIds.join(":")]
  );

  const {
    data: teamCombineTestStatisticsResponse,
    isLoading: teamCombineTestStatisticsIsLoading,
    fetchStatus: teamCombineTestStatisticsFetchStatus,
  } = useCombineTestStatisticsQuery(
    {
      combinedTestGuid: widget.testTemplateId,
      teamAggregation: AggregateFunction.Average,
      athleteGuids,
      session: {
        sessionId,
      },
      compareLatestTo: getCompareLatestTo(widget.compareTo),
      reportTemplateId: report?.id,
      ...period,
    },
    {
      enabled:
        sessionId &&
        !disabled &&
        widget.testTemplateId &&
        athleteGuids.length > 0,
      isDataEqual(oldData, newData) {
        return JSON.stringify(oldData?.data) === JSON.stringify(newData?.data);
      },
    }
  );

  return {
    _theAthlete: teamCombineTestStatisticsResponse?.data,
    isLoading:
      teamCombineTestStatisticsFetchStatus !== "idle" &&
      teamCombineTestStatisticsIsLoading,
  };
}

function getCompareLatestTo(
  compareTo: ReportWidgetTemplateCombinedTestPreferences["compareTo"]
): CompareLatestTo {
  const map = {
    [CompareTo.LATEST]: CompareLatestTo.LATEST,
    [CompareTo.PREVIOUS_DAY]: CompareLatestTo.DAY_BEFORE,
  };

  return map[CompareTo[compareTo?.toUpperCase()]];
}
