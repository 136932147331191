import React from "react";
import { useTranslation } from "react-i18next";

import { LeftSectionBox } from "./LeftSectionBox";

export const LeftSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <LeftSectionBox withBlog />
      <LeftSectionBox title={t("followXPS")} withSocialMediaIcons />
    </>
  );
};
