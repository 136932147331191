import React, { useState } from "react";

import styled from "styled-components";

import { useUIContext } from "../../contexts/UI";

import { AthletesSelectorBody } from "./AthletesSelector/Body";
import { AthletesSelectorFooter } from "./AthletesSelector/Footer";
import { AthletesSelectorHeader } from "./AthletesSelector/Header";

const Block = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AthletesSelector = ({ closeModal, onShow }) => {
  const ui = useUIContext();

  const [search, setSearch] = useState("");

  return (
    <Block
      style={{
        width: ui.width < 1000 ? "auto" : "40vw",
      }}
    >
      <AthletesSelectorHeader setSearch={setSearch} search={search} />
      <AthletesSelectorBody search={search} />
      <AthletesSelectorFooter closeModal={closeModal} onShow={onShow} />
    </Block>
  );
};
