import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import styled from "styled-components";

import { NewChatProvider } from "../../../contexts/messaging/NewChatProvider";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  Chatter,
  Avatar,
  useFindChattableUsersQuery,
  useUpdateChatGroupMutation,
  WhoCanPost,
  ChatterType,
} from "../../../graphql";
import { MessageList2 } from "../../../graphql/queries/MessageList2";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { ChatMemberListItem } from "../../Communication/ChatMemberListItem";
import { PostRestriction } from "../../Communication/components/PostRestriction";
import { MembersTitleDivider } from "../../Communication/styled";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";
import { StyledTextInput } from "../../StyledTextInput";

import { BottomBar } from "./BottomBar";

interface EditTeamChatGroupModalProps {
  contact: Chatter;
  avatars: Avatar[];
  onRowPress: (contact: Chatter) => void;
}

const ControlSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 20px 20px 20px;
`;

const PostRestrictionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export function EditTeamChatGroupModal({
  contact,
  avatars,
  onRowPress,
}: EditTeamChatGroupModalProps) {
  const { t } = useTranslation();
  const { id, sessionId, isFamilyMember, isTrainer } = useUserContext();
  const { actions: modalActions } = useModalContext();
  const client = useApolloClient();

  const [currentName, setCurrentName] = useState(contact.name);

  const [whoCanPost, setWhoCanPost] = useState(
    contact.whoCanPost || WhoCanPost.All
  );
  const onlyAdminsCanPost = useMemo(
    () => whoCanPost === WhoCanPost.Admins,
    [whoCanPost]
  );
  const onlyTrainersCanPost = useMemo(
    () => whoCanPost === WhoCanPost.Trainers,
    [whoCanPost]
  );

  const { data, loading } = useFindChattableUsersQuery({
    variables: { sessionId },
  });

  const [updateChatGroup] = useUpdateChatGroupMutation();

  const usersInChat = useMemo(
    () =>
      avatars.map((avatar) => {
        if (avatar.userID === id) {
          return {
            ...avatar,
            chatterType2: isRealTrainer({ isFamilyMember, isTrainer })
              ? ChatterType.Trainer
              : ChatterType.Family,
          };
        }

        return (
          data?.findChattableUsers?.find(
            (user) => user.id === avatar.userID
          ) ?? { ...avatar }
        );
      }) ?? [],
    [avatars, data, id, isFamilyMember, isTrainer]
  ) as unknown as Chatter[];

  const onSaveClick = async () => {
    const res = await updateChatGroup({
      variables: {
        sessionId,
        chatterId: contact.id,
        input: { name: currentName, onlyAdminsCanPost, onlyTrainersCanPost },
      },
    });

    await client.refetchQueries({
      include: [MessageList2],
    });

    onRowPress({ ...contact, ...res?.data?.updateChatGroup });
    modalActions.closeModal();
  };

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <NewChatProvider>
      <ControlSectionWrapper>
        <StyledText fontSize={10} fontWeight="semibold" cursor="default">
          {t("title").toUpperCase()}
        </StyledText>
        <StyledTextInput
          onChangeText={setCurrentName}
          value={currentName}
          placeholder={t("groupName")}
          style={{ marginTop: 8 }}
        />
        <PostRestrictionWrapper>
          <PostRestriction
            whoCanPost={whoCanPost}
            onWhoCanPostUpdate={setWhoCanPost}
          />
        </PostRestrictionWrapper>
      </ControlSectionWrapper>

      <MembersTitleDivider>
        <StyledText fontSize={10} fontWeight="semibold" cursor="default">
          {t("members").toUpperCase()}
        </StyledText>
      </MembersTitleDivider>

      <MembersWrapper>
        {usersInChat.map((user) => (
          <ChatMemberListItem chatter={user} />
        ))}
      </MembersWrapper>

      <BottomBar
        variant="group"
        closeModal={() => {
          modalActions.closeModal();
        }}
        done={onSaveClick}
        disableDone={!currentName?.trim()}
        isMultistage
        editMode
        count={usersInChat?.length}
      />
    </NewChatProvider>
  );
}
