import React, { useCallback, useMemo } from "react";

import dayjs from "dayjs";

import { useReportContext } from "../../../contexts/report";
import { useReportsContext } from "../../../contexts/Reports";
import { useUserContext } from "../../../contexts/User";
import { AggregateFunction, useBodyChartReportQuery } from "../../../graphql";
import { IWidgetResizeProps } from "../../../hooks";
import { useDefaultDateFormat } from "../../../hooks/useDateFormat";
import { WidgetType } from "../../ReportCreateTable/models";
import { useReportPeriod } from "../hooks/useReportPeriod";

import { BodyChartWidgetComponent } from "./BodyChartWidgetComponent";
import { BodyChartWidgetModel } from "./model";

interface BodyChartWidgetProps extends IWidgetResizeProps {
  widget: BodyChartWidgetModel;
  readOnly?: boolean;
}

export function BodyChartWidget({ widget }: BodyChartWidgetProps) {
  const { sessionId, language, timezone } = useUserContext();
  const { report } = useReportContext();
  const [{ selectedAthlete }] = useReportsContext();
  const { dateFormat } = useDefaultDateFormat();

  const informationTemplateIds = widget?.informationTemplates.map(
    ({ id }) => id
  );

  const { fromDate, toDate } = useReportPeriod(widget.period);

  const { data, loading } = useBodyChartReportQuery({
    variables: {
      sessionId,
      from:
        widget.aggregation === AggregateFunction.LastDayInRange
          ? toDate.startOf("day").valueOf()
          : fromDate.valueOf(),
      to: toDate.valueOf(),
      language,
      timezone: timezone ?? dayjs.tz.guess(),
      focusedAthlete: selectedAthlete?.id,
      bodyChartTemplateId: widget?.bodyChartTemplate?.templateGuid,
      informationTemplateIds,
      reportTemplateId: report?.id,
      onlyReturnLatestInPeriod: true,
    },
    fetchPolicy: "cache-first",
    skip: !widget?.id,
  });

  const bodyChartCategories = useMemo(
    () => widget.bodyChartTemplate.categories,
    [widget]
  );

  const rawRegistrations = data?.bodyChartReport?.registrations[0];

  const registrations = useMemo(
    () =>
      rawRegistrations?.bodyChart?.parts.reduce((result, currentItem) => {
        const id = currentItem.bodypartId;
        const category = bodyChartCategories.find(
          ({ categoryId }) => categoryId === currentItem.categoryId
        );

        result[id] = {
          categoryId: category.categoryId,
          categoryNum: category.severity,
          color: `rgb(${category.red255}, ${category.green255}, ${category.blue255})`,
        };

        return result;
      }, {}) || {},
    [rawRegistrations, bodyChartCategories]
  );

  const getInformationTemplateValue = useCallback(
    (id: string) => {
      const foundInformation = rawRegistrations?.infoOnSameDay?.find(
        ({ template }) => template?.templateGuid === id
      );

      return foundInformation?.values || [];
    },
    [rawRegistrations?.infoOnSameDay]
  );

  const informationTemplateValues = useMemo(
    () =>
      widget?.informationTemplates?.reduce(
        (accumulator, informationTemplate) => {
          accumulator[informationTemplate?.id] = {
            value: getInformationTemplateValue(informationTemplate.id),
          };
          return accumulator;
        },
        {}
      ),
    [widget.informationTemplates, getInformationTemplateValue]
  );

  const date = rawRegistrations?.formTime
    ? dayjs(rawRegistrations?.formTime).format(dateFormat)
    : fromDate.format(dateFormat);

  return (
    <BodyChartWidgetComponent
      widget={{
        ...widget,
        type: WidgetType.BODY_CHART,
        informationTemplates: widget.informationTemplates,
      }}
      informationTemplateValues={informationTemplateValues}
      registrations={registrations}
      date={date}
      isLoading={loading}
    />
  );
}
