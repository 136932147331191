import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { useMobileModalContext } from "../../../contexts/mobileModal/MobileModalProvider";
import { useReportsContext } from "../../../contexts/Reports";
import { ArrowButtons } from "../../ArrowButtons";
import { BasicButton } from "../../Button";
import { PeriodSelector } from "../../Report/PeriodSelector";
import { ReportPeriodCalendarComponent } from "../../Report/ReportPeriodCalendarComponent";
import { ReportSingleDayCalendar } from "../../Report/ReportSingleDayCalendar";

import { CloseTextButton, ModalHeaderContainer, ModalWrapper } from "./styled";

const HeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  align-items: center;
`;

const ContainerWithShadow = styled.div<{ rightMargin?: number }>`
  box-shadow: 0 2px 6px rgba(34, 62, 106, 0.05);
  margin-right: ${({ rightMargin = 0 }) => `${rightMargin}px`};
`;

interface DateSelectorModalProps {
  isSingleDayReport?: boolean;
}

export const DateSelectorModal = ({
  isSingleDayReport,
}: DateSelectorModalProps) => {
  const { t } = useTranslation();
  const { hideModal } = useMobileModalContext();
  const [
    { selectedPeriod, selectedStartDate, selectedEndDate, selectedSingleDay },
    {
      selectStartDate,
      selectEndDate,
      selectPrevPeriod,
      selectNextPeriod,
      selectPeriod,
      selectSingleDay,
    },
  ] = useReportsContext();

  const setSingleDay = (date) => {
    selectSingleDay(dayjs(date));
  };

  return (
    <ModalWrapper style={{ overflow: "scroll" }}>
      <ModalHeaderContainer>
        <HeaderButtonsContainer>
          {!isSingleDayReport && (
            <ContainerWithShadow rightMargin={15}>
              <PeriodSelector />
            </ContainerWithShadow>
          )}
          <ContainerWithShadow>
            <ArrowButtons
              onPrevClick={() => selectPrevPeriod(isSingleDayReport)}
              onNextClick={() => selectNextPeriod(isSingleDayReport)}
            />
          </ContainerWithShadow>
        </HeaderButtonsContainer>
        <BasicButton onClick={hideModal}>
          <CloseTextButton>{t("close").toUpperCase()}</CloseTextButton>
        </BasicButton>
      </ModalHeaderContainer>
      <BodyContainer>
        {isSingleDayReport ? (
          <ReportSingleDayCalendar
            selectedSingleDay={selectedSingleDay}
            setSingleDay={setSingleDay}
          />
        ) : (
          <ReportPeriodCalendarComponent
            selectedPeriod={selectedPeriod}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            firstInputLabel={t("from").toUpperCase()}
            secondInputLabel={t("to").toUpperCase()}
            setStartDate={(date) => {
              selectStartDate(dayjs(date));
            }}
            setEndDate={(date) => {
              selectEndDate(dayjs(date));
            }}
            selectPeriod={selectPeriod}
          />
        )}
      </BodyContainer>
    </ModalWrapper>
  );
};
