import React from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { TablePeriodizationForm } from "../../Report/PeriodizationTable/model";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { StyledSwitch } from "../../StyledSwitch";

const ControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
`;

export function TablePeriodizationAppearanceSection() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<TablePeriodizationForm>();

  return (
    <Section>
      <SectionTitle title={t("appearance")} />

      <ControlsWrapper>
        <StyledSwitch
          value={values.verticalHeader}
          onValueChange={(value) => setFieldValue("verticalHeader", value)}
          label={t("verticalHeader")}
        />
        <StyledSwitch
          value={values.showValuesAsText}
          onValueChange={(value) => setFieldValue("showValuesAsText", value)}
          label={t("showValuesAsText")}
        />
      </ControlsWrapper>
    </Section>
  );
}
