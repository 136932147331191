import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const ArrowBoldIcon = memo(
  ({
    width = 35,
    height = 35,
    isActive = false,
    direction = "top",
    tintColor = "#0073D9",
    ...props
  }: SvgIconProps) => {
    const rotation = () => {
      switch (direction) {
        case "right":
          return "90";
        case "bottom":
          return "180";
        case "left":
          return "270";
        case "top":
        default:
          return "0";
      }
    };

    return (
      <svg
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        style={{ transform: `rotate(${rotation()}deg)` }}
        {...props}
      >
        {isActive ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.5605 17.4396L23.4392 19.5609L18.9999 15.1215L18.9999 25.0002L15.9999 25.0002L15.9999 15.1215L11.5605 19.5609L9.43923 17.4396L17.4999 9.37891L25.5605 17.4396Z"
            fill="white"
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.5605 17.4396L23.4392 19.5609L18.9999 15.1215L18.9999 25.0002L15.9999 25.0002L15.9999 15.1215L11.5605 19.5609L9.43923 17.4396L17.4999 9.37891L25.5605 17.4396Z"
            fill={tintColor}
          />
        )}
      </svg>
    );
  }
);
