import React from "react";
import { ToolbarProps } from "react-big-calendar";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_GREY_ACTIVE, COLOR_MEDIUM_GRAY } from "../../colors";
import { useAgendaContext } from "../../contexts/agenda/AgendaProvider";
import { EventTypeFilter } from "../Filters/EventTypeFilter";

import { CalendarChevronLeftIcon } from "./CalendarChevronLeftIcon";
import { CalendarChevronRightIcon } from "./CalendarChevronRightIcon";

const ToolbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const PeriodSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledToolbarButton = styled.button`
  height: 35px;
  width: 35px;
  background-color: ${COLOR_GRAY};
  cursor: pointer;
`;

const ChevronButton = styled(StyledToolbarButton)<{
  orientation: "left" | "right";
}>`
  border: none;
  border-radius: ${({ orientation }) =>
    orientation === "left" ? "6px 0 0 6px" : "0 6px 6px 0"};
  svg {
    display: block;
  }
  svg path {
    transition: stroke 300ms;
  }
  &:hover svg path {
    stroke: #292e32;
  }
`;

const TodayButton = styled(StyledToolbarButton)`
  border: 1px solid ${COLOR_GREY_ACTIVE};
  border-bottom: none;
  border-top: none;
  width: auto;
  font-weight: bold;
  font-size: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  transition: color 300ms;
  &:hover {
    color: #292e32;
  }
`;

const EventTypeWrapper = styled.div`
  position: relative;
  top: -10px;
  right: -15px;
`;

const DateLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: 20px;
  cursor: default;
`;

const DateLabel = styled.span<{ bold?: boolean; withLeftMargin?: boolean }>`
  font-size: 24px;
  line-height: 35px;
  font-weight: ${({ bold }) => (bold ? 400 : 200)};
  margin-left: ${({ withLeftMargin }) => (withLeftMargin ? 10 : 0)}px;
`;

enum NavigateAction {
  PREV = "PREV",
  NEXT = "NEXT",
  TODAY = "TODAY",
  DATE = "DATE",
}

export const CustomCalendarToolbar = ({
  onNavigate,
  label,
}: ToolbarProps<CustomEvent>) => {
  const { t } = useTranslation();
  const { selectedEventTypes, setSelectedEventTypes } = useAgendaContext();

  const [month, year] = label.split(" ");

  return (
    <ToolbarWrapper>
      <PeriodSwitcherWrapper>
        <ChevronButton
          orientation="left"
          onClick={() => onNavigate(NavigateAction.PREV)}
        >
          <CalendarChevronLeftIcon />
        </ChevronButton>
        <TodayButton onClick={() => onNavigate(NavigateAction.TODAY)}>
          {t("today").toUpperCase()}
        </TodayButton>
        <ChevronButton
          orientation="right"
          onClick={() => onNavigate(NavigateAction.NEXT)}
        >
          <CalendarChevronRightIcon />
        </ChevronButton>
      </PeriodSwitcherWrapper>

      <DateLabelWrapper>
        <DateLabel bold>{month}</DateLabel>
        <DateLabel withLeftMargin>{year}</DateLabel>
      </DateLabelWrapper>

      <EventTypeWrapper>
        <EventTypeFilter
          value={selectedEventTypes}
          onChange={setSelectedEventTypes}
        />
      </EventTypeWrapper>
    </ToolbarWrapper>
  );
};
