import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_SECONDARY_GREY,
  COLOR_TEXT_DARK,
} from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Questionnaire, useStepQueryQuery } from "../../graphql";
import { ButtonColor, GeneralButton } from "../Button/";
import { CheckmarkRoundedDoneIcon } from "../Icons";
import { Loader } from "../Loader";

import { BodyChartItem } from "./BodyChartItem";
import { InformationItem } from "./InformationItem";
import { StepHeader } from "./StepHeader";
import { TestItem } from "./TestItem";
import { TrainingLoadItem } from "./TrainingLoadItem";

const Components = {
  BodyChartItem,
  TestItem,
  InformationItem,
  TrainingLoadItem,
};

type StepState = {
  sectionId: string | null;
  after: string | null;
  before: string | null;
  maxSteps?: number;
};

const Wrapper = styled.div`
  width: 100%;
  background-color: #fff;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;

const FormLabelText = styled.span`
  color: ${COLOR_TEXT_DARK};
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 15px;
`;

const StyledText = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: bold;
`;

const BackButton = styled(FlexCenter)`
  background-color: ${COLOR_SECONDARY_GREY};
  border-radius: 6px;
  cursor: pointer;
  width: fit-content;
  padding: 9px 24px;
`;

const Divider = styled.div`
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid ${COLOR_GRAY};
`;

const ContentWrapper = styled.div<{ isDone?: boolean }>`
  padding: 20px;
  height: calc(100% - 105px);
  overflow: auto;

  ${({ isDone }) =>
    isDone
      ? `
    display: flex;
    justify-content: center;
    align-items: center;
  `
      : ""};
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const StyledGeneralButton = styled(GeneralButton)`
  margin-left: auto;
`;

interface StepProps {
  sectionId: string;
  sectionName: string;
  maxSteps: number;
  showProgress: boolean;
  onBackPress: () => void;
  setCurrentSectionIndex: (sectionId: number) => void;
  questionnaire: Questionnaire;
  shouldRedirectToSectionsOverview: boolean;
}

export const Step = ({
  sectionId,
  sectionName,
  maxSteps,
  showProgress,
  onBackPress,
  setCurrentSectionIndex,
  questionnaire,
  shouldRedirectToSectionsOverview,
}: StepProps) => {
  const [stepState, setStepState] = useState<StepState>({
    sectionId,
    after: null,
    before: null,
  });
  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

  const { sessionId, timezone, language } = useUserContext();
  const { t } = useTranslation();

  const { data, loading: _loading } = useStepQueryQuery({
    variables: {
      questionnaireGuid: questionnaire.guid,
      sessionId,
      timezone,
      language,
      ...stepState,
    },
    fetchPolicy: "network-only",
    onCompleted: () => setHasBeenLoaded(true),
  });

  const loading = _loading && !hasBeenLoaded;
  const isLoading = loading || isFakeLoading;
  const isDone = !isLoading && data && data?.step == null;
  // @ts-ignore
  const isLast = !!data?.step?.definitelyLast;
  // @ts-ignore
  const currentStep = data?.step?.idxInSectionAbsolute;
  const isFirst = currentStep === 0;

  const onNextStepPress = () => {
    if (!isLast) {
      setIsFakeLoading(true);

      setTimeout(() => {
        setStepState({ after: data?.step?.id, sectionId: null, before: null });
        setIsFakeLoading(false);
      }, 1230);
    }

    if (isDone || isLast) {
      if (shouldRedirectToSectionsOverview) {
        setCurrentSectionIndex(NaN);
      } else {
        onBackPress();
      }
    }
  };

  const onBackStepPress = () => {
    setStepState({ before: data?.step?.id, after: null, sectionId: null });
  };

  return (
    <Wrapper>
      {loading || isFakeLoading ? (
        <FlexCenter>
          <Loader />
        </FlexCenter>
      ) : (
        <>
          <ContentWrapper isDone={isDone}>
            {isDone ? (
              <CheckmarkRoundedDoneIcon style={{ width: 100, height: 100 }} />
            ) : null}
            {data?.step?.__typename === "FormLabel" ? (
              <div>
                <FormLabelText>{data?.step?.text}</FormLabelText>
              </div>
            ) : null}

            {data?.step?.__typename === "Step" ? (
              <div>
                <>
                  <StepHeader
                    sectionName={sectionName}
                    maxSteps={maxSteps}
                    currentStep={(data?.step?.idxInSectionAbsolute || 0) + 1}
                    stepName={data?.step?.name}
                  />
                  <Divider />
                </>
                {data?.step?.items?.map((item, index) => {
                  const Component = Components[item.__typename] || null;
                  // @ts-ignore
                  const isLast = data?.step?.items?.length === index + 1;

                  if (item.__typename === "FormLabel") {
                    return (
                      <div key={item.id}>
                        <FormLabelText>{item.text}</FormLabelText>
                        <Divider />
                      </div>
                    );
                  }

                  return (
                    Component !== null && (
                      <div key={index}>
                        <Component
                          item={item}
                          questionnaireId={questionnaire.guid}
                          questionnaire={questionnaire}
                        />
                        {!isLast ? <Divider /> : null}
                      </div>
                    )
                  );
                })}
              </div>
            ) : null}
          </ContentWrapper>
          <BottomWrapper>
            <Divider style={{ margin: 0 }} />
            <ButtonsWrapper>
              {!isFirst && !!showProgress && !isDone ? (
                <BackButton onClick={onBackStepPress}>
                  <StyledText>{t("back").toUpperCase()}</StyledText>
                </BackButton>
              ) : null}

              <StyledGeneralButton
                colorVariant={
                  isDone || isLast ? ButtonColor.Green : ButtonColor.Blue
                }
                label={t(isDone || isLast ? "done" : "next")}
                onClick={onNextStepPress}
              />
            </ButtonsWrapper>
          </BottomWrapper>
        </>
      )}
    </Wrapper>
  );
};
