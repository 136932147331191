import React from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import styled from "styled-components";

import { useUserContext } from "../../../contexts/User";
import { useDefaultDateFormat } from "../../../hooks";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { ControlledCalendar } from "../../HookForm/ControlledCalendar";
import { ControlledInput } from "../../HookForm/ControlledInput";

dayjs.extend(customParseFormat);

const FormWrapper = styled.div<{ withTopMargin: boolean }>`
  padding: 0 20px 0 30px;
  flex-direction: row;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  margin-top: ${({ withTopMargin }) => (withTopMargin ? 25 : 0)}px;
`;

const containerStyle = { width: 164 };
const spacing = {
  marginBottom: 25,
};

export function DateTime({ methods, isProgram }) {
  const { t } = useTranslation();
  const { dateFormat } = useDefaultDateFormat();
  const user = useUserContext();

  return (
    <FormProvider {...methods}>
      <FormWrapper withTopMargin={!isRealTrainer(user)}>
        <ControlledCalendar
          name="date"
          label={t("date")}
          dateFormat={dateFormat}
          hideArrowIcon={true}
          containerStyle={containerStyle}
          styledForRight={-110}
          spacing={spacing}
        />
        {!isProgram ? (
          <ControlledInput
            name="time"
            label={t("time")}
            type="time"
            showCalendarIcon={true}
            containerStyle={containerStyle}
            spacing={spacing}
          />
        ) : null}
      </FormWrapper>
    </FormProvider>
  );
}
