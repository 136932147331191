import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";

import { FieldErrorMessage } from "../Report/styled";
import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { SelectField, SelectOption } from "../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../styled";

import { CreateGroupTableFormValue } from "./models";

export const ColorIndicatorSection = () => {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } =
    useFormikContext<
      Pick<CreateGroupTableFormValue, "columns" | "rgbIndicator">
    >();

  const generatedOptions = useMemo(() => {
    const headerStructure = [];

    values.columns.forEach((column) => {
      const alreadyCreatedMainHeaderIndex = headerStructure.findIndex(
        (header) => header.measurementId === column.measurementTemplateId
      );

      if (alreadyCreatedMainHeaderIndex !== -1) {
        headerStructure[alreadyCreatedMainHeaderIndex] = {
          ...headerStructure[alreadyCreatedMainHeaderIndex],
          attributes: [
            ...headerStructure[alreadyCreatedMainHeaderIndex].attributes,
            {
              attributeId: column.attributeTemplateId,
              attributeName: column.attributeTemplateName,
              aggregations: column.aggregateFunctions,
            },
          ],
        };

        return;
      }

      headerStructure.push({
        measurementId: column.measurementTemplateId,
        measurementName: column.measurementTemplateName,
        isTestWithColorRanges: column.isTestWithColorRanges,
        attributes: [
          {
            attributeId: column.attributeTemplateId,
            attributeName: column.attributeTemplateName,
            aggregations: column.aggregateFunctions,
          },
        ],
      });
    });

    const filteredHeaderStructure = headerStructure.filter(
      (measurement) => measurement.isTestWithColorRanges
    );

    return filteredHeaderStructure.flatMap((measurement) =>
      measurement.attributes.flatMap((attribute) =>
        attribute.aggregations.map((aggregation) => ({
          label: `${measurement.measurementName} ${
            measurement.attributes.length > 1
              ? `- ${attribute.attributeName}`
              : ""
          } - ${t(
            // @ts-ignore
            `aggregation${aggregation}`,
            {
              defaultValue: aggregation,
            }
          )}`,
          value:
            measurement.measurementId +
            "-" +
            attribute.attributeId +
            "-" +
            aggregation,
        }))
      )
    );
  }, [values, t]);

  const onIndicatorColumnChange = (option: SelectOption) => {
    const indicatorObject = option && option.value ? option.value : null;

    setFieldValue("rgbIndicator", indicatorObject);
  };

  if (generatedOptions.length === 0) {
    return null;
  }

  const generatedOptionsToShow = values.rgbIndicator
    ? [{ label: t("clearSelection"), value: null }, ...generatedOptions]
    : generatedOptions;

  return (
    <Section>
      <SectionTitle title={t("colorIndicator")} />
      <DraggableLineWrapper>
        <FieldWrapper>
          <Field name="rgbIndicator">
            {(props) => (
              <SelectField
                {...props}
                value={values.rgbIndicator}
                options={generatedOptionsToShow as SelectOption[]}
                onChange={onIndicatorColumnChange}
              />
            )}
          </Field>
        </FieldWrapper>
      </DraggableLineWrapper>
      {errors.rgbIndicator && (
        <FieldErrorMessage>{errors.rgbIndicator}</FieldErrorMessage>
      )}
    </Section>
  );
};
