import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { LocaleConfig } from "react-native-calendars";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import Highcharts from "highcharts";
import moment from "moment";

import { useUserProfileLazyQuery } from "../../graphql";
import { fetchAllPreferences } from "../../services";
import actionsCreator from "../../utils/actionsCreator";
import Storage from "../../utils/storage";
import { StorageKeys } from "../../utils/storageKeys";
import { getAccounts } from "../../utils/switchAccounts";

import * as fromActions from "./actions";
import rootReducer from "./reducers";
import { UserContextState, UserContextStateBase } from "./types";

dayjs.extend(localeData);

const initialState: UserContextState = {
  id: "",
  firstName: "",
  lastName: "",
  profileImageUrl: "",
  profileImageUrlThumb: "",
  country: "",
  stateUS: "",
  city: "",
  gender: "",
  phoneNumber: "",
  yearOfBirth: 0,
  birthDay: "",
  emailAddress: "",
  userName: "",
  brandingImageUrlBig: "",
  brandingImageUrl: "",
  measurementSystem: "",
  isWeightRegistrationPossible: false,
  isHeightRegistrationPossible: false,
  isTrainer: false,
  isFamilyMember: false,
  sessionId: null,
  timezone: null,
  language: null,
  poscitt: false,
  defaultUser: {},
  loading: true,
  settings: {
    _canUseGoogleCalendar: false,
    _firstNameFirst: false,
    _isInClubWithFamilyLicenses: false,
    _isSweFloorball: false,
    _isGolfer: false,
    _map: {},
    _speaksOfClients: false,
    _usesSoccer: false,
    _usesTeamSessions: false,
    blogInternal: "",
    blogFull: "",
  },
  userWasSwitched: false,
  actions: {
    setUser: () => null,
    setUserSettings: () => null,
    logout: () => null,
    refetchUserProfile: () => null as any,
  },
  isTemporary: false,
};

export * from "./types";

export const UserContext = createContext<UserContextState>(initialState);

export const UserConsumer = UserContext.Consumer;

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const { i18n } = useTranslation();

  const [getUserProfile, { data: userProfileData, loading, error }] =
    useUserProfileLazyQuery();
  const actions: UserContextState["actions"] = useMemo(
    () => actionsCreator(fromActions, dispatch),
    [dispatch]
  );

  // change language for labels on axis in charts
  const handleLanguageSetting = (currentLocale: string) => {
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort(),
      },
    });
  };

  const getInitialValues = async (currentLocale: string) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const language = i18n.language;
    const localStorageUser = await Storage.getItem<UserContextStateBase>(
      StorageKeys.sessionId
    );
    const sessionStorageUser = await Storage.getItem<UserContextStateBase>(
      StorageKeys.sessionId,
      window.sessionStorage
    );
    const user = sessionStorageUser || localStorageUser;

    if (user && user.sessionId) {
      const accounts = getAccounts();
      const savedUserAccount = accounts.find(
        (account) => account.sessionId === user.sessionId
      );
      try {
        await getUserProfile({
          variables: {
            sessionId: user.sessionId,
            timezone,
            language,
            accessLevelsForDisplayingInGlobalAccountSelector: true,
          },
        });
        actions.setUser({
          sessionId: user.sessionId,
          timezone,
          // force boolean for backwards compatibility
          isTemporary: !!savedUserAccount?.isTemporary,
        });
      } catch {
        actions.setUser({
          sessionId: undefined,
          timezone,
          // force boolean for backwards compatibility
          isTemporary: !!savedUserAccount?.isTemporary,
        });
      } finally {
        handleLanguageSetting(currentLocale);
      }
    }
  };

  useEffect(() => {
    LocaleConfig.locales["default"] = {
      monthNames: dayjs.months(),
      monthNamesShort: dayjs.monthsShort(),
      dayNames: dayjs.weekdays(),
      dayNamesShort: dayjs.weekdaysShort(),
      today: "Today",
    };

    LocaleConfig.defaultLocale = "default";
  }, []);

  useEffect(() => {
    if (i18n.language) {
      moment().locale(i18n.language);
      getInitialValues(i18n.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    actions.setUser({ language: i18n.language });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    (() => {
      const user = Storage.getItem(StorageKeys.sessionId);
      const sessionStorageUser = Storage.getItem(
        StorageKeys.sessionId,
        window.sessionStorage
      );

      if (userProfileData?.userProfile && userProfileData?.userAccessLevels) {
        const defaultUser = userProfileData.userAccessLevels[0]?.user ?? {};

        actions.setUser({
          ...userProfileData.userProfile,
          defaultUser,
          loading: false,
        });
      }

      if ((!loading && error) || (!user && !sessionStorageUser)) {
        actions.setUser({ loading: false });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData, error, loading]);

  useEffect(() => {
    state.sessionId &&
      fetchAllPreferences({ sessionId: state.sessionId }).then((response) => {
        actions.setUserSettings(response?.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sessionId]);

  const contextActions = useMemo(
    () => ({ ...actions, refetchUserProfile: getUserProfile }),
    [actions, getUserProfile]
  );

  return (
    <UserContext.Provider
      value={{
        ...state,
        actions: contextActions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserContext() {
  return useContext(UserContext);
}
