import React, { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useTeamsContext } from "../contexts/teams";
import { useUserContext } from "../contexts/User";
import { Group, GroupOrSubGroup } from "../graphql";
import { sortList } from "../utils/sortList";

import { CustomTeamOption } from "./CustomTeamOption";
import { Label } from "./Label";
import { StyledSelect, StylesOverrides } from "./StyledSelect";

export type GroupValue = Partial<GroupOrSubGroup> & Pick<Group, "id" | "name">;

interface GroupSelectorProps {
  value: GroupValue | null;
  onChange(option: GroupValue): void;
  accountId?: string | null;
  requireEditability?: boolean;
  hideLabel?: boolean;
  unsetWidth?: boolean;
  disableSearchable?: boolean;
  stylesOverrides?: StylesOverrides;
  shouldLoadAllGroups?: boolean;
  shouldDisplaySubgroups?: boolean;
  isLoading?: boolean;
}

// Function to group options by ownerName
const groupBy = (array, key) => {
  return array.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
};

const Wrapper = styled.div<{ unsetWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ theme, unsetWidth }) => {
    if (unsetWidth) {
      return "auto";
    }

    return !theme.isMobile ? "260px" : "auto";
  }};
`;

function TeamsGroupSelector({
  value,
  onChange,
  hideLabel = false,
  unsetWidth = false,
  disableSearchable = false,
  stylesOverrides,
  isLoading = false,
}: GroupSelectorProps) {
  const { t } = useTranslation();
  const { myGroups, loadingGroups } = useTeamsContext();
  const { language } = useUserContext();
  const { selectedAccount } = useAccessLevelsContext();

  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const options = useMemo(() => {
    return (
      myGroups?.map((group) => ({
        value: group.id,
        label: group.name,
        ownerName: group.owner?.name,
        isGroup: true,
      })) ?? []
    );
  }, [myGroups]);

  const onSelectChange = useCallback(
    (value: string) => {
      const newValue = myGroups.find((group) => group.id === value) ?? null;

      onChangeRef.current(newValue);
    },
    [myGroups]
  );

  const groupedOptions = groupBy(options, "ownerName");

  const groupedSelectOptions = Object.keys(groupedOptions).map(
    (group, key) => ({
      id: key,
      label: group,
      options: sortList<any>(
        groupedOptions[group] ?? [],
        language,
        (option) => option.label
      ),
      isGroup: true,
    })
  );

  const accountBasedOptions =
    selectedAccount?.id !== ALL_ACCOUNTS_ID ? options : groupedSelectOptions;

  return (
    <Wrapper unsetWidth={unsetWidth}>
      {!hideLabel && <Label>{t("team").toUpperCase()}</Label>}
      <StyledSelect
        // @ts-ignore
        options={accountBasedOptions}
        value={value?.id}
        loading={loadingGroups}
        onChange={onSelectChange}
        components={{ Option: CustomTeamOption }}
        stylesOverrides={stylesOverrides}
        disableSearchable={disableSearchable}
        isLoading={isLoading}
      />
    </Wrapper>
  );
}

export default TeamsGroupSelector;
