import React from "react";
import { useTranslation } from "react-i18next";

import { Field, FieldProps, useFormikContext } from "formik";
import styled from "styled-components";

import { AggregateFunction } from "../../../graphql";
import { requiredValidation } from "../../../utils/form";
import {
  isAttendanceCollection,
  isNoteOrOpponentSoccerAttribute,
  isSoccerStatsCollection,
} from "../../../utils/reports";
import { Checkbox } from "../../Checkbox";
import { Label } from "../../Label";
import { TimeLine, TimeLinePeriod } from "../../Report/ChartWIdget/model";
import { TablePeriodizationForm } from "../../Report/PeriodizationTable/model";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField, SelectOption } from "../../SelectField";

import { getAggregationFunctionsByItemId } from "./utils";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
`;

const TimeType = styled.div`
  width: 213px;
`;

const TimePeriod = styled.div`
  margin-left: 10px;
  flex: 1;
  min-width: 130px;
`;

export function TablePeriodizationWhatToShowSection({
  isGroup = false,
}: {
  isGroup?: boolean;
}) {
  const { values, setFieldValue } = useFormikContext<TablePeriodizationForm>();
  const { t } = useTranslation();

  const onTimeLineChange = (option: SelectOption) => {
    const timeLinePeriod =
      option.value === TimeLine.PERIOD ? TimeLinePeriod.DAYS : undefined;

    setFieldValue("timeLine", option.value);
    setFieldValue("timeLinePeriod", timeLinePeriod);
    isGroup && setFieldValue("coloringConfig", []);
    setFieldValue(
      "columnHeaders",
      values.columnHeaders
        // Remove attendance stats as it is not supported for continuous type
        .filter(
          (columnHeader) =>
            option.value !== TimeLine.CONTINUOUS ||
            !isAttendanceCollection(columnHeader.collectionId)
        )
        .map((columnHeader) => {
          // remove unsupported soccer attributes if time line isn't continuous
          const isUnsupportedColumn =
            option.value !== TimeLine.CONTINUOUS &&
            isNoteOrOpponentSoccerAttribute(columnHeader.itemId);

          return {
            ...(isUnsupportedColumn
              ? { collectionId: columnHeader.collectionId }
              : columnHeader),
            aggregateFunctions: [
              isSoccerStatsCollection(columnHeader.collectionId)
                ? isGroup
                  ? AggregateFunction.Sum
                  : getAggregationFunctionsByItemId(columnHeader.itemId)?.[0] ??
                    AggregateFunction.Latest
                : option.value === TimeLine.CONTINUOUS
                  ? AggregateFunction.Each
                  : AggregateFunction.Latest,
            ],
          };
        })
    );
  };

  return (
    <Section>
      <SectionTitle title={t("whatToShow")} />
      <Container>
        <TimeType>
          <Label>{t("type").toUpperCase()}</Label>
          <Field name="timeLine" validate={requiredValidation}>
            {({ field, form, meta }: FieldProps<TablePeriodizationForm>) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={[
                  {
                    label: t("time"),
                    value: TimeLine.CONTINUOUS,
                  },
                  { label: t("timeFrames"), value: TimeLine.PERIOD },
                ]}
                onChange={onTimeLineChange}
              />
            )}
          </Field>
        </TimeType>

        {values.timeLine === TimeLine.PERIOD && (
          <TimePeriod style={{ flex: 1 }}>
            <Label> </Label>
            <Field name="timeLinePeriod" validate={requiredValidation}>
              {({ field, form, meta }: FieldProps<TablePeriodizationForm>) => (
                <SelectField
                  field={field}
                  form={form}
                  meta={meta}
                  options={[
                    { label: t("days"), value: TimeLinePeriod.DAYS },
                    { label: t("weeks"), value: TimeLinePeriod.WEEKS },
                    { label: t("months"), value: TimeLinePeriod.MONTHS },
                    { label: t("years"), value: TimeLinePeriod.YEARS },
                  ]}
                />
              )}
            </Field>
          </TimePeriod>
        )}
        <Checkbox
          text={
            isGroup ? t("ignoreColumnsWithNoData") : t("ignoreRowsWithNoData")
          }
          check={() =>
            setFieldValue(
              "ignoreColumnsWithNoData",
              !values.ignoreColumnsWithNoData
            )
          }
          checked={values.ignoreColumnsWithNoData}
        />
      </Container>
    </Section>
  );
}
