import React from "react";

import styled from "styled-components";

import { GroupTablePeriodizationDataPerAthleteItem } from "../../../hooks/data/periodizationTable/useGroupDataForPeriodizationTable";
import { getValueToDisplay } from "../../ReportTableWidget/utils";

import { TablePeriodizationWidget } from "./model";
import { DataRow } from "./styled";
import { TablePeriodizationCell } from "./TableCell";

type TablePeriodizationRowProps = Pick<
  TablePeriodizationWidget,
  "coloringConfig" | "showValuesAsText"
> & {
  periodData: GroupTablePeriodizationDataPerAthleteItem;
};

const AthleteNameTd = styled.td`
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  line-height: 15px;
  text-align: center;
  min-width: 120px;
`;

export function TablePeriodizationRow({
  periodData,
  coloringConfig,
  showValuesAsText,
}: TablePeriodizationRowProps) {
  return (
    <DataRow>
      <AthleteNameTd>{periodData.athleteName}</AthleteNameTd>

      {periodData.data.map(({ dataPerPeriod }, index) => {
        if (!dataPerPeriod?.id) {
          return null;
        }

        const coloring = coloringConfig?.find(
          (item) => item.id === dataPerPeriod.id
        );
        const hasColoring =
          Boolean(coloring) && dataPerPeriod.min !== dataPerPeriod.max;

        const valueToDisplay = getValueToDisplay(
          showValuesAsText,
          dataPerPeriod
        );

        return (
          <TablePeriodizationCell
            key={`${dataPerPeriod.id}-${index}`}
            valueToDisplay={valueToDisplay}
            dataPerPeriod={dataPerPeriod}
            coloring={coloring}
            hasColoring={hasColoring}
          />
        );
      })}
    </DataRow>
  );
}
