import React from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DEFAULT,
} from "../colors";

import { CrossFilledIcon, SearchIcon } from "./Icons";
import { StyledTextInput } from "./StyledTextInput";

interface SearchBarProps {
  value: string;
  withInitialFocus?: boolean;
  iconColor?: string;
  onClear: () => void;
  onChangeText: (text: string) => void;
}

const SearchBarWrapper = styled.div`
  width: calc(100% - 12px);
  height: 35px;
  background-color: ${COLOR_GREY_ACTIVE};
  border-radius: 4px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding-left: 5px;
  padding-right: 12px;
`;

const SearchIconWrapper = styled.div`
  margin-right: 10px;
  margin-left: 5px;
`;

const Input = styled(StyledTextInput)`
  width: 100%;
  height: 25px;
  color: ${COLOR_TEXT_DEFAULT};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
  background-color: transparent;
`;

const CancelButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const SearchBar = ({
  value,
  withInitialFocus,
  iconColor,
  onClear,
  onChangeText,
}: SearchBarProps) => (
  <SearchBarWrapper>
    <SearchIconWrapper>
      <SearchIcon color={iconColor ?? COLOR_BLUE} />
    </SearchIconWrapper>
    <Input
      onChangeText={onChangeText}
      value={value}
      autoFocus={withInitialFocus}
    />
    {!!value && (
      <CancelButton onClick={onClear}>
        <CrossFilledIcon
          style={{ width: 13, height: 13 }}
          color={COLOR_MEDIUM_GRAY}
        />
      </CancelButton>
    )}
  </SearchBarWrapper>
);
