import React from "react";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import styled from "styled-components";

import { COLOR_TEXT_TABLE_LABEL, COLOR_WHITE } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import { useWorkoutEditorContext } from "../../../contexts/workout/WorkoutEditorProvider";
import { useDefaultDateFormat } from "../../../hooks";
import { useNavigateBack } from "../../../hooks/useNavigateBack";
import {
  SessionTypeNumber,
  useSessionColors,
} from "../../../hooks/useSessionColors";
import { SessionType } from "../../../services/sessions";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { BottomBar } from "../../BottomBar";
import { ButtonColor } from "../../Button/";
import { ControlledCalendar } from "../../HookForm/ControlledCalendar";
import { ControlledColorPicker } from "../../HookForm/ControlledColorPicker";
import { ControlledInput } from "../../HookForm/ControlledInput";
import { ControlledLocationSelect } from "../../HookForm/ControlledLocationSelect";
import { ControlledTextArea } from "../../HookForm/ControlledTextArea";
import { ControlledTitleSelect } from "../../HookForm/ControlledTitleSelect";
import { ColumnOfRowWrapper } from "../../HookForm/styled";
import StyledText from "../../StyledText";
import { createInfoFormDefaultValues } from "../utils";

const Row = styled.div<{ zIndexValue?: string }>`
  display: flex;
  justify-content: flex-start;
  z-index: ${({ zIndexValue }) => zIndexValue ?? 0};
  gap: 15px;
`;

const Container = styled.div<{ isHidden: boolean }>`
  padding: 10px 20px;
  background: ${COLOR_WHITE};
  flex: 1;
  display: ${({ isHidden }) => (isHidden ? "none" : "flex")};
  flex-direction: column;
  justify-content: space-between;

  input,
  textarea {
    font-weight: 700 !important;
  }
`;

const SmallCheckboxWrapper = styled.div`
  width: 70px;
`;

const SmallLabelText = styled(StyledText)`
  align-self: center;
  font-size: 10px;
  font-weight: 500;
  margin-left: 10px;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

// TODO ATTACHMENTS
// const AttachmentsContainer = styled.div`
//   margin-top: 15px;
//   border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
//   width: 100%;
//   padding-bottom: 10px;
//   margin-bottom: 10px;
// `;
//
// const AttachmentsNames = styled.div`
//   margin-top: 10px;
//   display: flex;
//   gap: 5px;
//   flex-wrap: wrap;
// `;

const FormWrapper = styled.div``;

interface Props {
  // still render, only with `display:none`
  isHidden: boolean;
}

export const InfoForm = ({ isHidden }: Props) => {
  const { t } = useTranslation();

  const today = dayjs();

  const { colorList } = useSessionColors(SessionTypeNumber.Workout);
  const user = useUserContext();
  const {
    formMethods: { reset, handleSubmit, setValue, watch },
    workoutData,
    onSubmit,
  } = useWorkoutEditorContext();
  const navigateBack = useNavigateBack();
  const selectedGoogleLocationId = watch("selectedGoogleLocationId");
  const { dateFormat } = useDefaultDateFormat();

  // TODO ATTACHMENTS
  // const [files, setFiles] = useState([]);
  // const [filesBlobs, setFilesBlobs] = useState([]);
  // const [uploading, setUploading] = useState(false);
  // const enforceToggleCallback = useRef<() => void>();

  // TODO ATTACHMENTS
  // const onFileUpload = useCallback(async () => {
  //   if (files?.length) {
  //     setUploading(true);
  //     const filesBlobsPromise = await Promise.all(
  //       files.map(async (f) => {
  //         const data = await fetch(f.url);
  //         const blob = await data.blob();
  //         const upload = await fetch(
  //           `${getServerBasePath()}/xpsweb/savechatattachment/?sessionId=${
  //             user?.sessionId
  //           }&mime=${f.mime}&filename=${f.localName}`,
  //           {
  //             method: "POST",
  //             body: blob,
  //           }
  //         );
  //         const response = await upload.json();
  //         if (upload.ok && upload.status === 200 && response?.url) {
  //           return Promise.resolve({ ...f, url: response.url });
  //         }
  //
  //         setUploading(false);
  //         showErrorNotification(t("uploadErrorMessage"));
  //         return Promise.resolve();
  //       })
  //     );
  //     setUploading(false);
  //     setFilesBlobs(filesBlobsPromise);
  //   }
  // }, [files, user?.sessionId, t, showErrorNotification]);

  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     acceptedFiles.forEach((file) => {
  //       const reader = new FileReader();
  //
  //       reader.onload = () => {
  //         const binaryStr = reader.result;
  //         //@ts-ignore
  //         const base64 = btoa(
  //           new Uint8Array(binaryStr as ArrayBuffer).reduce(
  //             (data, byte) => data + String.fromCharCode(byte),
  //             ""
  //           )
  //         );
  //
  //         if (base64) {
  //           const url = `data:${file.type};base64,${base64}`;
  //           setFiles([
  //             ...files,
  //             {
  //               localName: file.name.replace(/\s/g, "_"),
  //               mime: file.type,
  //               content: base64,
  //               url,
  //             },
  //           ]);
  //         }
  //       };
  //       onFileUpload();
  //
  //       reader.readAsArrayBuffer(file);
  //     });
  //
  //     if (
  //       enforceToggleCallback?.current &&
  //       typeof enforceToggleCallback.current === "function"
  //     ) {
  //       enforceToggleCallback.current();
  //     }
  //   },
  //   [files, onFileUpload]
  // );
  //
  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  // });

  const handleCancel = () => {
    reset(createInfoFormDefaultValues());
    navigateBack();
  };

  return (
    <Container isHidden={isHidden}>
      <FormWrapper>
        <ControlledTitleSelect
          name="label"
          label={t("title")}
          sessionType={SessionType.WORKOUT}
          isLabel
          spacing={{ marginBottom: 15 }}
        />
        <Row zIndexValue="6">
          <ColumnOfRowWrapper width={55}>
            <ControlledCalendar
              name="startDate"
              fromDate={today}
              label={t("date")}
              styledForRight={-30}
              dateFormat={dateFormat}
              spacing={{ marginBottom: 15 }}
            />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={30}>
            <ControlledInput
              name="startTime"
              label={t("startTime")}
              type="time"
              spacing={{ marginBottom: 15 }}
            />
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={15}>
            <SmallCheckboxWrapper>
              <ControlledColorPicker
                name="color"
                label={t("color")}
                colorList={colorList}
                spacing={{ marginBottom: 15 }}
              />
            </SmallCheckboxWrapper>
          </ColumnOfRowWrapper>
        </Row>
        <Row zIndexValue="2">
          <ColumnOfRowWrapper width={30}>
            <SmallCheckboxWrapper>
              <ControlledInput
                name="attendance"
                label={t("arrive")}
                type="number"
                min="0"
                max="999"
                spacing={{ marginBottom: 0 }}
              />
            </SmallCheckboxWrapper>
            <SmallLabelText style={{ marginTop: 17 }}>
              {t("minutesBefore")}
            </SmallLabelText>
          </ColumnOfRowWrapper>
          <ColumnOfRowWrapper width={30}>
            <SmallCheckboxWrapper>
              <ControlledInput
                name="durationMinutes"
                label={t("duration")}
                type="number"
                spacing={{ marginBottom: 15 }}
              />
            </SmallCheckboxWrapper>
            <SmallLabelText>{t("min")}</SmallLabelText>
          </ColumnOfRowWrapper>
        </Row>
        <ControlledLocationSelect
          name="location"
          label={t("location")}
          sessionType={SessionType.WORKOUT}
          setSelectedGoogleLocationId={(value) =>
            setValue("selectedGoogleLocationId", value)
          }
          selectedGoogleLocationId={selectedGoogleLocationId}
          googleMapsUrl={workoutData?.workout?.googleMapsUrl}
          marginBottom={15}
        />
        <ControlledInput
          name="locationDescription"
          label={t("locationDescription")}
          spacing={{ marginBottom: 15 }}
        />
        <ControlledTextArea name="notes" label={t("notes")} marginBottom={15} />
        {isRealTrainer(user) ? (
          <ControlledTextArea
            name="specialNotes"
            label={t("coachNotes")}
            marginBottom={15}
          />
        ) : null}
      </FormWrapper>
      {/* TODO ATTACHMENTS */}
      {/*<LabelText>{t("attachments").toUpperCase()}</LabelText>*/}
      {/*{files && files.length > 0 ? (*/}
      {/*  <AttachmentsNames>*/}
      {/*    {files.map((file, index) => (*/}
      {/*      <AttachmentItem*/}
      {/*        key={`${file?.localName}-${index}`}*/}
      {/*        data={{ name: file.localName }}*/}
      {/*        style={{ cursor: "default" }}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </AttachmentsNames>*/}
      {/*) : null}*/}
      {/*<AttachmentsContainer {...getRootProps()}>*/}
      {/*  <label htmlFor="contained-button-file">*/}
      {/* <GeneralButton
        colorVariant={ButtonColor.Blue}
        label={t("addAttachment")}
        outlined
      /> */}
      {/*  </label>*/}
      {/*  <input*/}
      {/*    type="file"*/}
      {/*    accept="*"*/}
      {/*    style={{ display: "none" }}*/}
      {/*    {...getInputProps()}*/}
      {/*    id="contained-button-file"*/}
      {/*  />*/}
      {/*</AttachmentsContainer>*/}
      <BottomBar
        primary={{
          text: t("finish"),
          variant: ButtonColor.Blue,
          onClick: handleSubmit(onSubmit),
        }}
        secondary={{
          text: t("cancel"),
          onClick: handleCancel,
        }}
      />
    </Container>
  );
};
