import React, { useCallback, useMemo } from "react";

import { useFormikContext } from "formik";

import {
  AggregateFunction,
  MeasurementTemplateForReports,
  ReportItemType,
} from "../../../graphql";
import {
  isAttendanceCollection,
  isCustomTextSoccerAttribute,
  isNoteOrOpponentSoccerAttribute,
  isSoccerStatsCollection,
} from "../../../utils/reports";
import { IconButton } from "../../IconButton";
import { BinIcon, ReorderIcon } from "../../Icons";
import { TimeLine } from "../../Report/ChartWIdget/model";
import {
  DataSource,
  DataSourceSelectorRow,
  DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
} from "../../Report/DataSourceSelector";
import {
  TablePeriodizationColumnHeader,
  TablePeriodizationForm,
} from "../../Report/PeriodizationTable/model";
import {
  IconButtonWrapper,
  ReorderImageWrapper,
} from "../../ReportCreateTable/styled";
import { DraggableLineWrapper } from "../../styled";
import { DragHandlePropsType } from "../../Wrappers/DragDropContainer";

import { DataSelectionItemAggregationSelection } from "./DataSelectionItemAggregationSelection";
import { getAggregationFunctionsByItemId } from "./utils";

interface DataSelectionItemProps {
  columnIndex: number;
  currentColumn: TablePeriodizationColumnHeader;
  dragHandleProps: DragHandlePropsType;
  isGroup?: boolean;
  remove(rowIndex: number): void;
}

export function DataSelectionItem({
  currentColumn,
  columnIndex,
  dragHandleProps,
  remove,
  isGroup,
}: DataSelectionItemProps) {
  const { setFieldValue, values } = useFormikContext<TablePeriodizationForm>();

  const onChangeColumnValues = useCallback(
    (newValues: Partial<DataSource>) => {
      const defaultAggregationFunction =
        newValues.collectionId &&
        isSoccerStatsCollection(newValues.collectionId) &&
        isGroup
          ? AggregateFunction.Sum
          : isSoccerStatsCollection(newValues?.itemId ?? "")
            ? getAggregationFunctionsByItemId(newValues.itemId)?.[0]
            : undefined;

      setFieldValue(`columnHeaders[${columnIndex}]`, {
        ...currentColumn,
        ...newValues,
        ...(defaultAggregationFunction
          ? { aggregateFunctions: [defaultAggregationFunction] }
          : {}),
      });
    },
    [columnIndex, isGroup, setFieldValue, currentColumn]
  );

  const allowedReportItemTypes = useMemo(() => {
    const baseItems = [
      ...DEFAULT_ALLOWED_REPORT_ITEM_TYPES.filter(
        (itemType) => itemType !== ReportItemType.Information
      ),
      ReportItemType.SoccerStat,
    ];

    if (values.timeLine === TimeLine.PERIOD) {
      return [...baseItems, ReportItemType.Attendance];
    }

    return baseItems;
  }, [values.timeLine]);

  const filterOutCustomSoccerTextMeasurements = useCallback(
    (item: MeasurementTemplateForReports) => {
      return (
        !isCustomTextSoccerAttribute(item.id) ||
        (!isGroup &&
          values.timeLine === TimeLine.CONTINUOUS &&
          isNoteOrOpponentSoccerAttribute(item.id))
      );
    },
    [isGroup, values.timeLine]
  );

  const shouldShowAggregationSelectorForCollection = !isAttendanceCollection(
    values.columnHeaders[columnIndex].collectionId
  );

  return (
    <>
      <DraggableLineWrapper>
        <ReorderImageWrapper {...dragHandleProps}>
          <ReorderIcon />
        </ReorderImageWrapper>
        <DataSourceSelectorRow
          currentDataSource={currentColumn}
          dataSourceArrayItemAccessor={`columnHeaders[${columnIndex}]`}
          onChangeDataSourceValues={onChangeColumnValues}
          customVisibleMeasurementsFilter={
            filterOutCustomSoccerTextMeasurements
          }
          customMeasurementTemplateIdAccessor="itemId"
          allowedReportItemTypes={allowedReportItemTypes}
        />

        {values?.columnHeaders?.length > 1 && (
          <IconButtonWrapper>
            <IconButton
              icon={<BinIcon />}
              onClick={() => remove(columnIndex)}
            />
          </IconButtonWrapper>
        )}
      </DraggableLineWrapper>

      {values.timeLine !== TimeLine.CONTINUOUS &&
        shouldShowAggregationSelectorForCollection && (
          <DataSelectionItemAggregationSelection
            columnIndex={columnIndex}
            isMultiSelectDisable={isGroup}
          />
        )}
    </>
  );
}
