import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_BLUE } from "../../../../colors";
import { ReportItemType } from "../../../../graphql";
import { Checkbox } from "../../../Checkbox";
import { ColorPicker } from "../../../ReportCreateTable/ColorPicker";
import { Section } from "../../../Section";
import { SectionTitle } from "../../../SectionTitle";
import { StyledNumberInput } from "../../../StyledNumberInput";
import { DataSourceSelectorRow } from "../../DataSourceSelector";

import { AcuteChronicWidgetFormValue } from "./model";

const DATA_SELECTORS = [
  "showBarsForDailyLoad",
  "showAcuteAverageLine",
  "showChronicAverageLine",
] as const;

export const DEFAULT_OVERLOAD_COLOR = COLOR_BLUE;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TrainingInputWrapper = styled.div`
  margin-right: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const OverLoadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const OverLoadInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ColorPickerWrapper = styled.div`
  margin-left: 10px;
  margin-top: 18px;
`;

const OverTrainingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const OverTrainingInputWrapper = styled.div`
  margin-right: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
`;

export const DataSection = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, setValues } =
    useFormikContext<AcuteChronicWidgetFormValue>();

  const onChangeDataSourceValues = useCallback(
    (newValues: Partial<AcuteChronicWidgetFormValue>) =>
      setValues({ ...values, ...newValues }, false),
    [setValues, values]
  );

  return (
    <>
      <Section>
        <SectionTitle title={t("data")} />
        <SectionWrapper>
          <DataSourceSelectorRow
            dataSourceArrayItemAccessor=""
            currentDataSource={values}
            onChangeDataSourceValues={onChangeDataSourceValues}
            allowedReportItemTypes={[ReportItemType.AcuteChronic]}
            customMeasurementTemplateIdAccessor="templateId"
            customMeasurementTemplateNameAccessor="templateName"
            shouldHideAttributeSelector
          />
        </SectionWrapper>
        {DATA_SELECTORS?.map((selector) => (
          <Checkbox
            key={selector}
            text={t(selector)}
            check={(value) => setFieldValue(selector, value)}
            checked={values[selector]}
          />
        ))}
      </Section>
      <Section>
        <SectionTitle title={t("tresholds")} />
        <SectionWrapper>
          <TrainingInputWrapper>
            <StyledNumberInput
              label={t("undertraining")}
              value={values.undertrainingTreshold}
              onChange={(value) =>
                setFieldValue("undertrainingTreshold", value)
              }
              step={0.1}
            />
          </TrainingInputWrapper>
          <OverLoadWrapper>
            <OverLoadInputWrapper>
              <StyledNumberInput
                label={t("overload")}
                value={values.overloadTreshold}
                onChange={(value) => setFieldValue("overloadTreshold", value)}
                step={0.1}
                displayZero
              />
            </OverLoadInputWrapper>
            {!isNaN(values.overloadTreshold) && (
              <ColorPickerWrapper>
                <ColorPicker
                  onColorChange={(value) =>
                    setFieldValue("overloadTresholdColor", value)
                  }
                  currentColor={
                    values.overloadTresholdColor || DEFAULT_OVERLOAD_COLOR
                  }
                  graySectionSize={45}
                  colorSectionSize={35}
                  isRightZero
                />
              </ColorPickerWrapper>
            )}
          </OverLoadWrapper>
        </SectionWrapper>
        <OverTrainingWrapper>
          <OverTrainingInputWrapper>
            <StyledNumberInput
              label={t("overtraining")}
              value={values.overtrainingTreshold}
              onChange={(value) => setFieldValue("overtrainingTreshold", value)}
              step={0.1}
            />
          </OverTrainingInputWrapper>
        </OverTrainingWrapper>
      </Section>
    </>
  );
};
