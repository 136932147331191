export enum UserPreferenceKeys {
  XPS_REPORTS_CONTEXT_STATE = "XPS_REPORTS_CONTEXT_STATE",
  ATHLETE_PROFILE_TABS = "AthleteProfileTabs",
  PERFORMERS_REPORTS_STATE = "PERFORMERS_REPORTS_STATE",
  DATE_ATHLETE_PROFILE_STATE = "DATE_ATHLETE_PROFILE_STATE",
  DATE_REPORTS_STATE = "DATE_REPORTS_STATE",
  SELECTED_ACCOUNT_ID = "SELECTED_ACCOUNT_ID",
  TABLE_APPEARANCE_PREFERENCE = "TABLE_APPEARANCE_PREFERENCE",
  TABLE_SORTING_PREFERENCE = "TABLE_SORTING_PREFERENCE",
  SELECTED_GROUP_ID = "SELECTED_GROUP_ID",
  SELECTED_TEAM_ID = "SELECTED_TEAM_ID",
  SELECTED_RESOURCES = "SELECTED_RESOURCES",
  SHOW_OPTIONAL_ATHLETES = "SHOW_OPTIONAL_ATHLETES",
}

export const getSelectedResourceskey = ({ userId }: { userId: string }) =>
  `${UserPreferenceKeys.SELECTED_RESOURCES}-${userId}`;

export const getSelectedGroupIdKey = ({
  accountId,
  isMobile,
  isTrainer,
}: {
  accountId: string;
  isMobile: boolean;
  isTrainer: boolean;
}) =>
  isTrainer
    ? isMobile
      ? `${UserPreferenceKeys.SELECTED_GROUP_ID}-mobile-app`
      : `${UserPreferenceKeys.SELECTED_GROUP_ID}-${accountId}`
    : UserPreferenceKeys.SELECTED_GROUP_ID;

export const getSelectedTeamIdKey = ({ accountId }: { accountId: string }) =>
  `${UserPreferenceKeys.SELECTED_TEAM_ID}-${accountId}`;
