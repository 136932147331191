import { AggregateFunction, ReportWidgetCell } from "../graphql";
import { JsonStatisticsForMeasurementsResponse } from "../services";

export function getDataKey(
  measurementTemplateId: string,
  attributeTemplateId: string,
  aggregation: AggregateFunction
): string {
  return [measurementTemplateId, attributeTemplateId, aggregation].join(":");
}

export function getValidUnitName(unitName?: string): string {
  return unitName && unitName !== "#" ? unitName : "";
}

export function getValidUnitNameFromReportWidgetCell(
  cell: ReportWidgetCell
): string {
  if (cell.__typename === "ReportWidgetCellNumerical") {
    return getValidUnitName(cell.attributeTemplate?.unitName);
  }

  return "";
}

export const getAllAthleteStats = (
  athlete: JsonStatisticsForMeasurementsResponse["_athletes"][0],
  includeAcute = false
) => [
  ...(athlete?._tests ?? []),
  ...(athlete?._exercises ?? []),
  ...(athlete?._trainingLoad ?? []),
  ...(athlete?._drills ?? []),
  ...(includeAcute ? athlete?._acuteChronics ?? [] : []),
];

export function getAthleteInitials(athleteName: string) {
  const [firstName, lastName] = athleteName.split(" ");
  const firstNameInitials = firstName ? `${firstName.substring(0, 2)}.` : "";
  const lastNameInitials = lastName ? lastName.substring(0, 3) : "";

  return `${firstNameInitials} ${lastNameInitials}`.trim();
}
