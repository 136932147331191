import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const UnhideIcon = memo(
  ({ width = 21, height = 21, color = "#64707A", ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.38755 10.931C4.40709 12.1811 5.73751 13.1661 7.26063 13.7685C6.63092 12.9943 6.25345 12.0067 6.25345 10.931C6.25345 9.85523 6.63091 8.86765 7.26062 8.09346C5.73751 8.69587 4.40709 9.68087 3.38755 10.931ZM10.7534 15.431C7.18534 15.431 4.03292 13.6512 2.1355 10.931C4.03292 8.21075 7.18535 6.43097 10.7535 6.43097C14.3216 6.43097 17.474 8.21074 19.3714 10.931C17.474 13.6512 14.3216 15.431 10.7534 15.431ZM18.1194 10.931C17.0998 9.68085 15.7694 8.69584 14.2463 8.09344C14.876 8.86763 15.2534 9.85522 15.2534 10.931C15.2534 12.0067 14.876 12.9943 14.2463 13.7685C15.7694 13.1661 17.0998 12.1811 18.1194 10.931ZM14.2534 10.931C14.2534 12.864 12.6864 14.431 10.7535 14.431C8.82045 14.431 7.25345 12.864 7.25345 10.931C7.25345 8.99797 8.82045 7.43097 10.7535 7.43097C12.6864 7.43097 14.2534 8.99797 14.2534 10.931Z"
        fill="currentColor"
      />
    </svg>
  )
);
