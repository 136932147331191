import React from "react";

import dayjs from "dayjs";
import styled, { useTheme } from "styled-components";

import { Resource } from "../../Calendar/model";
import { PlusIcon } from "../../Icons";

import { HoverableWrapper, HoverableItem, Name } from "./styled";

type AvailableItemProps = {
  resource?: Resource;
  report?: any;
  index: number;
  onAdd: (item: Resource | any) => void;
  showOwner?: boolean;
};

const ButtonWrapper = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: none;
  background: transparent;
  flex: 1;
  align-items: center;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
`;

const ReportCreated = styled.div`
  font-weight: 700;
  font-size: 12px;
  min-width: 160px;
  margin-left: 16px;
`;

const ReportDate = styled.div`
  font-weight: 700;
  font-size: 12px;
  min-width: 100px;
  margin: 0 12px;
`;

export function AvailableItem({
  resource,
  report,
  index,
  onAdd,
  showOwner,
}: AvailableItemProps) {
  const { isMobile } = useTheme();

  const title = resource?.resourceTitle || report?.name;
  const item = resource || report;

  return (
    <HoverableWrapper index={index}>
      <HoverableItem>
        <ButtonWrapper onClick={() => onAdd(item)}>
          <IconWrapper>
            <PlusIcon />
          </IconWrapper>
          <Name>{title}</Name>
          {!isMobile && report && (
            <>
              <ReportCreated>
                {showOwner ? report.owner?.name : report.createdBy?.name}
              </ReportCreated>
              <ReportDate>
                {dayjs(report.createdTime).toDate().toLocaleDateString()}
              </ReportDate>
            </>
          )}
        </ButtonWrapper>
      </HoverableItem>
    </HoverableWrapper>
  );
}
