import React, { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY } from "../../../colors";
import { CrossFilledIcon, SearchIcon } from "../../Icons";

type ChatSearchBarProps = {
  setSearch: Dispatch<SetStateAction<string>>;
  search: string;
};

const Wrapper = styled.div`
  background-color: ${COLOR_GRAY};
  border-radius: 6px;
  padding: 10px;
  margin: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SearchIconWrapper = styled.div`
  margin-right: 15px;
  transform: scale(0.8);
`;

const StyledInput = styled.input`
  width: 100%;
  height: 25px;
  background-color: transparent;
  border: none;
`;

const ClearSearchButton = styled.div`
  cursor: pointer;
  border-radius: 100px;
`;

export function ChatSearchBar({ setSearch, search }: ChatSearchBarProps) {
  const { t } = useTranslation();

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  return (
    <Wrapper>
      <InputWrapper>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInput
          onChange={onChangeText}
          value={search}
          placeholder={t("search")}
        />
        {!!search && (
          <>
            <ClearSearchButton onClick={() => setSearch("")}>
              <CrossFilledIcon />
            </ClearSearchButton>
          </>
        )}
      </InputWrapper>
    </Wrapper>
  );
}
