import { COLOR_GRAY, COLOR_WHITE } from "../../../colors";

export const multiSelectStyles = {
  control: (base, { selectProps }) => ({
    ...base,
    backgroundColor: COLOR_GRAY,
    padding: selectProps.value?.length ? "5px" : "5px 15px",
    borderWidth: 0,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  multiValueLabel: (base) => ({
    ...base,
    backgroundColor: COLOR_WHITE,
    fontWeight: 700,
    fontSize: 12,
  }),
  multiValueRemove: (base) => ({
    ...base,
    backgroundColor: COLOR_WHITE,
    cursor: "pointer",
    paddingLeft: 5,
    "&:hover": {
      backgroundColor: COLOR_WHITE,
    },
  }),
};
