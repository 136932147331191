import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../colors";
import { useNotificationsContext } from "../../../contexts/notifications";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useTopModalContext } from "../../../contexts/UI/TopModal";
import { useUserContext } from "../../../contexts/User";
import {
  CreateGroupChatMutationVariables,
  useCreateGroupChatMutation,
  useMessageOverviewPaginatedLazyQuery,
} from "../../../graphql";
import { ROUTING_CONFIG } from "../../../router/RoutingConfig";
import { sortList } from "../../../utils/sortList";
import { ButtonColor, GeneralButton } from "../../Button/";
import { StyledSwitch } from "../../StyledSwitch";
import {
  Container as BottomContainer,
  Counter,
  CounterRow,
} from "../ChatModals/BottomBar";
import { createGroupUpdateCb } from "../ChatModals/misc";

import MembersSection from "./MembersSection";

const Container = styled.div<{ showSwitcher: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ showSwitcher }) => (showSwitcher ? "30vh" : "90vh")};
`;

const MembersSectionContainer = styled.div`
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: flex-end;
  flex: 1;
`;

const SwitchersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 30px 30px 0 30px;
`;

const SwitcherWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const EmptySwitcherText = styled.p`
  color: ${COLOR_MEDIUM_GRAY};
  font-size: 12px;
`;

const CreateChatGroupModal = ({
  coaches = [],
  familyMembers = [],
  athletes = [],
  groupName = "",
  shouldShowSwitcher = false,
}) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const { sessionId, language } = useUserContext();
  const navigate = useNavigate();
  const modal = useModalContext();
  const topModal = useTopModalContext();

  const sortedCoaches = sortList(coaches, language);
  const sortedFamilyMembers = sortList(familyMembers, language);
  const sortedAthletes = sortList(athletes, language);

  const coachesSwitcherIsDisabled = sortedCoaches?.length === 0;
  const familyMembersSwitcherIsDisabled = sortedFamilyMembers?.length === 0;

  const [fetchMessageOverview] = useMessageOverviewPaginatedLazyQuery({
    variables: {
      sessionId,
      language,
      showAll: true,
      includeHidden: true,
      ignoreContactsWithoutChats: true,
    },
  });

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [showSwitcher, setShowSwitcher] = useState(shouldShowSwitcher);
  const [showCoaches, setShowCoaches] = useState(
    showSwitcher ? !coachesSwitcherIsDisabled : true
  );
  const [showFamilyMembers, setShowFamilyMembers] = useState(
    showSwitcher ? coachesSwitcherIsDisabled : true
  );

  const [createGroup, { loading }] = useCreateGroupChatMutation();

  const { showErrorNotification } = useNotificationsContext();

  const isPossibleSendMessage = selectedMembers.length;
  const count = selectedMembers.length;

  const handleMemberPress = (member, isSelected) => {
    if (isSelected) {
      const newSelectedMembers = selectedMembers.filter(
        (selectedMember) => selectedMember.id !== member.id
      );
      setSelectedMembers([...newSelectedMembers]);
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleAllMembersPress = (members, areSelected) => {
    if (areSelected) {
      const newSelectedMembers = selectedMembers.filter(
        (selectedMember) => !members.includes(selectedMember)
      );
      setSelectedMembers(newSelectedMembers);
    } else {
      const newSelectedMembers = selectedMembers || [];
      members.forEach((item) => {
        if (!newSelectedMembers.includes(item)) {
          newSelectedMembers.push(item);
        }
      });
      setSelectedMembers([...newSelectedMembers]);
    }
  };

  const getChatGroupName = (): string => {
    const useFirstNameFirst = user?.settings?._firstNameFirst;
    const nameArray = selectedMembers?.map((selectedMember) =>
      useFirstNameFirst
        ? selectedMember.firstName || selectedMember.name.split(" ")[0]
        : selectedMember.lastName || selectedMember.name.split(" ")[1]
    );

    if (familyMembers.length === 0 && athletes.length === 0) {
      const creatorName = useFirstNameFirst ? user.firstName : user.lastName;
      return `${groupName}: ${creatorName}, ${
        nameArray.length > 2 ? t("coaches") : nameArray?.join(", ")
      }`;
    } else {
      const isInFamily = familyMembers?.find(
        (familyMember) => familyMember.id === user.id
      );
      const firstName = isInFamily
        ? ""
        : useFirstNameFirst
          ? `${user.firstName},`
          : `${user.lastName},`;
      return `${firstName} ${nameArray?.join(", ")}`;
    }
  };

  const createChatGroup = async () => {
    const chatGroupName = getChatGroupName();

    const variables: CreateGroupChatMutationVariables = {
      sessionId,
      name: chatGroupName,
      participants: [
        ...selectedMembers?.map((selectedMember) => selectedMember?.id),
      ],
    };

    try {
      await createGroup({
        variables,
        update: createGroupUpdateCb(
          sessionId,
          language,
          chatGroupName,
          async (newContact) => {
            topModal.actions.closeTopModal();
            modal.actions.closeModal();
            if (newContact?.id) {
              navigate({
                pathname: `/${ROUTING_CONFIG.messages}`,
                search: `?id=${newContact?.id}`,
              });
            }
          },
          async () => {
            await fetchMessageOverview();
          }
        ),
      });
    } catch (error) {
      const errorMessage =
        error?.graphQLErrors?.[0]?.exception?.detailMessage ||
        t("createChatGroupErrorMessage");

      showErrorNotification(errorMessage);
    }
  };

  const handleDonePress = async () => {
    if (showSwitcher) {
      setShowSwitcher(false);
    } else {
      await createChatGroup();
    }
  };

  const handleShowFamilyMembers = () => {
    setShowFamilyMembers(!showFamilyMembers);
    !coachesSwitcherIsDisabled && setShowCoaches(showFamilyMembers);
  };

  const handleShowCoaches = () => {
    setShowCoaches(!showCoaches);
    !familyMembersSwitcherIsDisabled && setShowFamilyMembers(showCoaches);
  };

  return (
    <Container showSwitcher={showSwitcher}>
      {showSwitcher ? (
        <SwitchersWrapper>
          <SwitcherWrapper>
            <StyledSwitch
              value={showCoaches}
              onValueChange={handleShowCoaches}
              label={t("coaches")}
              disabled={coachesSwitcherIsDisabled}
            />
            {coachesSwitcherIsDisabled && (
              <EmptySwitcherText>{t("noCoachesToAdd")}</EmptySwitcherText>
            )}
          </SwitcherWrapper>
          <SwitcherWrapper>
            <StyledSwitch
              value={showFamilyMembers}
              onValueChange={handleShowFamilyMembers}
              label={t("familyMembers")}
              disabled={familyMembersSwitcherIsDisabled}
            />
            {familyMembersSwitcherIsDisabled && (
              <EmptySwitcherText>{t("noFamilyMembersToAdd")}</EmptySwitcherText>
            )}
          </SwitcherWrapper>
        </SwitchersWrapper>
      ) : (
        <MembersSectionContainer>
          {sortedCoaches?.length && showCoaches ? (
            <MembersSection
              title={t("coaches")}
              members={sortedCoaches}
              selectedMembers={selectedMembers}
              handleMemberPress={handleMemberPress}
              handleAllMembersPress={handleAllMembersPress}
            />
          ) : null}
          {sortedFamilyMembers?.length && showFamilyMembers ? (
            <MembersSection
              title={t("familyMembers")}
              members={sortedFamilyMembers}
              selectedMembers={selectedMembers}
              handleMemberPress={handleMemberPress}
              handleAllMembersPress={handleAllMembersPress}
            />
          ) : null}
          {sortedAthletes?.length ? (
            <MembersSection
              title={t("athletes")}
              members={sortedAthletes}
              selectedMembers={selectedMembers}
              handleMemberPress={handleMemberPress}
              handleAllMembersPress={handleAllMembersPress}
            />
          ) : null}
        </MembersSectionContainer>
      )}
      {
        <BottomContainer>
          <Row>
            {!showSwitcher && (
              <CounterRow>
                {t("messaging")}
                <Counter>&nbsp;{count ?? 0}&nbsp;</Counter>
                {t(count !== 1 ? "members" : "member").toLowerCase()}
              </CounterRow>
            )}
            <ButtonsWrapper>
              <GeneralButton
                label={t("cancel")}
                colorVariant={ButtonColor.Light}
                onClick={topModal.actions.closeTopModal}
              />
              <GeneralButton
                label={t("done")}
                isLoading={loading}
                isDisabled={
                  showSwitcher
                    ? !showCoaches && !showFamilyMembers
                    : !isPossibleSendMessage || loading
                }
                onClick={handleDonePress}
              />
            </ButtonsWrapper>
          </Row>
        </BottomContainer>
      }
    </Container>
  );
};

export default CreateChatGroupModal;
