import React, { createContext, useContext, useEffect, useReducer } from "react";

import actionsCreator from "../../utils/actionsCreator";

import * as actionsForm from "./actions";
import { ModalProvider } from "./Modal";
import rootReducer from "./reducers";
import { SidebarProvider } from "./Sidebar";
import { TopModalProvider } from "./TopModal";

const breakpoint = (width: number): "xxl" | "xl" | "md" | "sm" | "xs" => {
  if (width > 1200) {
    return "xxl";
  } else if (width > 1000 && width < 1200) {
    return "xl";
  } else if (width > 800 && width < 1000) {
    return "md";
  } else if (width > 600 && width < 800) {
    return "sm";
  } else {
    return "xs";
  }
};

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const initialValue = {
  embed: false,
  loading: false,
  ...getWindowDimensions(),
  bp: breakpoint(window.innerWidth),
  actions: {
    loading: () => {},
    resize: () => {},
    setEmbed: () => {},
  },
};

export const UIContext = createContext<{
  embed: boolean;
  loading: boolean;
  width: number;
  height: number;
  bp: "xxl" | "xl" | "md" | "sm" | "xs";
  actions: {
    loading(value: boolean): void;
    setEmbed(value: boolean): void;
    resize(payload: {
      width: number;
      height: number;
      bp: "xxl" | "xl" | "md" | "sm" | "xs";
    }): void;
  };
}>(initialValue);

export const UIProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialValue);
  const actions = actionsCreator(actionsForm, dispatch);

  useEffect(() => {
    const handleResize = () => {
      const { width, height } = getWindowDimensions();
      const bp = breakpoint(width);
      actions.resize({ width, height, bp });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <UIContext.Provider value={{ ...state, actions }}>
      <SidebarProvider>
        <TopModalProvider>
          <ModalProvider>{children}</ModalProvider>
        </TopModalProvider>
      </SidebarProvider>
    </UIContext.Provider>
  );
};

export const useUIContext = () => useContext(UIContext);
