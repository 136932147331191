import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "@apollo/client";
import styled from "styled-components";

import { changePaginatedContactInMessages } from "../../cacheUpdates/changePaginatedContactInMessages";
import { updateUnreadAll } from "../../cacheUpdates/updateUnreadAll";
import { COLOR_BLUE, COLOR_MEDIUM_GRAY, COLOR_WHITE } from "../../colors";
import { useNotificationsContext } from "../../contexts/notifications";
import { useUIContext } from "../../contexts/UI";
import { useUserContext } from "../../contexts/User";
import {
  MessageEdge,
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryVariables,
  MessageSeenByDocument,
  useDeleteMessageMutation,
  useMarkMessageReadMutation,
  useMessageList2Query,
  useMessageSeenByQuery,
  useSaveChatMessageMutation,
} from "../../graphql";
import { MessageList2 } from "../../graphql/queries/MessageList2";
import { MessageOverviewPaginated } from "../../graphql/queries/MessageOverviewPaginated";
import documentTypes from "../../utils/documentTypes";
import { enforceUniqueArrayValues } from "../../utils/enforceUniqueArrayValues";
import { getServerBasePath } from "../../utils/getServerBasePath";
import Avatar from "../Avatar";
import Dropdown from "../Dropdown";
import { IconButton } from "../IconButton";
import {
  AnnouncementIcon,
  RoundedGroupProfileIcon,
  SettingsHorizontalIcon,
} from "../Icons";
import StyledText from "../StyledText";
import Tooltip from "../Tooltip";

import { AvatarList } from "./AvatarList";
import { Chat } from "./Chat";
import { DeleteChatOption } from "./components/DeleteChatOption";
import { EditChatOption } from "./components/EditChatOption";
import { HideChatOption } from "./components/HideChatOption";
import { MuteChatOption } from "./components/MuteChatOption";
import { TooltipTitle } from "./TooltipTitle";

const onUrlPress = (url: string, isModular?: boolean) => {
  if (!url) return null;
  if (url.includes("mc.html") || isModular) {
    // TODO: No page ModularContent defined - what is this?
    // const parsedUrl = URL.parse(url, true);
    // navigation.push("ModularContent", {
    //   uri: isModular ? url : parsedUrl.query.mcUrl,
    // });
  } else {
    window.open(url, "_blank");
  }
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  position: relative;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: ${COLOR_WHITE};
  align-items: center;
  padding: 10px;
  z-index: 1;
`;

const Header = styled.div<{ isGroupChat: boolean }>`
  display: flex;
  flex: 1;
  align-items: ${({ isGroupChat }) => (isGroupChat ? "stretch" : "center")};
  padding-right: 35px;
`;

const ChatTitleWrapper = styled.div`
  width: calc(100% - 25px);
`;

const ChatTitleHeading = styled.p`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  overflow: hidden;
  padding-right: 15px;
`;

export function ChatScreen({
  contact,
  onRowPress,
  activateNextContact,
  setIsAvatarHovered,
}: {
  contact: any;
  onRowPress(contact: any): Promise<void>;
  activateNextContact(contact: any): void;
  setIsAvatarHovered: (isHovered: boolean) => void;
}) {
  const { t } = useTranslation();
  const [contactName, setContactName] = useState(contact.name);
  const [contactHidden, setContactHidden] = useState(contact.hidden);
  const [contactMuted, setContactMuted] = useState(contact.muted);
  const [uploading, setUploading] = useState(false);
  const { sessionId, language, timezone, isTrainer, isFamilyMember, id } =
    useUserContext();
  const [fetchingMore, setFetchingMore] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoUri, setVideoUri] = useState<string | null>(null);
  const UI = useUIContext();
  const { showErrorNotification } = useNotificationsContext();
  const isAnnouncement = !!contact?.allFriendsOf?.id;
  const isLoggedInUserOwner = contact?.groupOwner?.id === id;

  const client = useApolloClient();
  const messageListVariables = {
    sessionId,
    groupOrFriend: contact.id as string,
    first: 20,
  };
  const {
    data,
    loading,
    fetchMore,
    variables,
    refetch: messageListRefetch,
  } = useMessageList2Query({
    variables: messageListVariables,
    fetchPolicy: "network-only",
    skip: !contact?.id,
  });
  const { data: seenByData, refetch: seenByRefetch } = useMessageSeenByQuery({
    variables: { sessionId, id: contact?.id, timezone, language },
    fetchPolicy: "network-only",
    skip: !contact?.id,
  });

  useEffect(() => {
    if (contact.id) {
      seenByRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.id]);

  const edges = enforceUniqueArrayValues(
    data?.messageList2?.messages2?.edges ?? [],
    []
  ).reverse() as MessageEdge[];

  const newestMessage = useMemo(
    () =>
      data?.messageList2?.messages2?.edges?.length
        ? data?.messageList2?.messages2?.edges?.[0]?.msg
        : null,
    [data?.messageList2?.messages2?.edges]
  );

  const hasChatNextPage = useMemo(
    () => data?.messageList2.messages2.pageInfo?.hasNextPage,
    [data]
  );

  const getLastMessage = useCallback(
    (newestMessage) => {
      if (newestMessage?.oldLinks?.length) {
        return `${t("links")}`;
      }
      if (newestMessage?.attachedFiles?.length) {
        if (newestMessage?.attachedFiles?.length === 1) {
          const ext = newestMessage?.attachedFiles?.[0]?.mime.split("/")[0];
          if (ext === "image") {
            return `${t("photo")}`;
          } else if (ext === "video") {
            return `${t("video")}`;
          } else {
            return `${t("attachment")}`;
          }
        } else return `${t("manyaAttachments")}`;
      } else {
        return newestMessage.messageBody;
      }
    },
    [t]
  );

  useEffect(() => {
    if (contact?.id && newestMessage?.id) {
      changePaginatedContactInMessages({
        proxy: client as any,
        sessionId,
        language,
        contactId: contact.id,
        edgeModifier: (edge) => ({
          ...edge,
          lastMessage: getLastMessage(newestMessage),
        }),
      });
    }
  }, [contact.id, language, sessionId, client, newestMessage, getLastMessage]);

  const avatars = useMemo(() => {
    return data?.messageList2?.avatars2 ?? [];
  }, [data]);

  const [sendMessage, { loading: sendingMessage }] =
    useSaveChatMessageMutation();
  const [
    deleteMessage,
    { loading: deletingMessage, error: deleteMessageError },
  ] = useDeleteMessageMutation();
  const [markRead] = useMarkMessageReadMutation();

  const numberMembersCols = () => {
    let n = 1;
    switch (true) {
      case UI.height > 800:
        n = 40;
        break;
      case UI.height > 700 && UI.height < 800:
        n = 30;
        break;
      default:
        n = 25;
    }
    return Math.round(avatars.length / n + 0.5);
  };

  const getMembersContainerPosition = () => {
    const numberOfMembersColumns = numberMembersCols();
    const memberColumnWidth = 200;
    const chatWidth = UI.width / 2.5;
    const allColumnsWidth = memberColumnWidth * numberOfMembersColumns;
    if (chatWidth > allColumnsWidth) {
      return 0;
    } else {
      return chatWidth - allColumnsWidth;
    }
  };

  const onFetchMore = async () => {
    if (data.messageList2.messages2.pageInfo.hasNextPage && !fetchingMore) {
      await setFetchingMore(true);
      await fetchMore({
        variables: {
          ...variables,
          after: data.messageList2.messages2.pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setFetchingMore(false);
          if (!fetchMoreResult.messageList2.messages2.edges.length) {
            return prev;
          }
          return {
            messageList2: {
              ...prev.messageList2,
              messages2: {
                ...fetchMoreResult.messageList2.messages2,
                edges: [
                  ...prev.messageList2.messages2.edges,
                  ...fetchMoreResult.messageList2.messages2.edges,
                ],
              },
            },
          };
        },
      });
    }
  };

  useEffect(() => {
    if (contact) {
      setContactHidden(contact.hidden);
      setContactName(contact.name);
      setContactMuted(contact.muted);
      if (contact.unread || contact.unreadByMeInGroup > 0) {
        markRead({
          variables: {
            sessionId,
            input: {
              chatterIds: [contact.id],
            },
          },
          update: (proxy) => {
            const variables = {
              sessionId,
              language,
              showAll: true,
              includeHidden: true,
              ignoreContactsWithoutChats: true,
            };
            const data = proxy.readQuery<
              MessageOverviewPaginatedQuery,
              MessageOverviewPaginatedQueryVariables
            >({
              query: MessageOverviewPaginated,
              variables,
            });
            const contacts =
              data?.messageOverview.contactsPaginated.edges.slice();
            const index = contacts?.findIndex((c) => c.node.id === contact.id);
            const currentContact = index !== -1 ? contacts[index] : null;

            if (currentContact) {
              contacts.splice(index, 1, {
                ...currentContact,
                node: {
                  ...currentContact.node,
                  unreadByMeInGroup: 0,
                },
              });

              proxy.writeQuery<
                MessageOverviewPaginatedQuery,
                MessageOverviewPaginatedQueryVariables
              >({
                query: MessageOverviewPaginated,
                variables,
                data: {
                  ...data,
                  messageOverview: {
                    ...data.messageOverview,
                    contactsPaginated: {
                      ...data.messageOverview.contactsPaginated,
                      edges: contacts,
                    },
                  },
                },
              });
            }
            updateUnreadAll(proxy, { sessionId, timezone, language });
          },
        });
      }
    }
  }, [contact, language, markRead, sessionId, timezone]);

  const onSendMessage = async ({ message, files, attachments }) => {
    if (message || files.length || attachments.length) {
      let filesBlobs = [];

      if (files?.length) {
        setUploading(true);
        filesBlobs = await Promise.all(
          files.map(async (f) => {
            const data = await fetch(f.url);
            const blob = await data.blob();
            const upload = await fetch(
              `${getServerBasePath()}/xpsweb/savechatattachment/?sessionId=${sessionId}&mime=${
                f.mime
              }&filename=${f.localName}`,
              {
                method: "POST",
                body: blob,
              }
            );
            const response = await upload.json();

            if (upload.ok && upload.status === 200 && response?.url) {
              return Promise.resolve({ ...f, url: response.url });
            }

            setUploading(false);
            showErrorNotification(
              response?._failureTextForUser || t("uploadErrorMessage")
            );
            return Promise.resolve();
          })
        );
        setUploading(false);
      }

      if (
        !filesBlobs.some((f) => Boolean(f)) &&
        !message &&
        attachments.length === 0
      ) {
        return;
      }

      await sendMessage({
        variables: {
          sessionId,
          input: {
            text: message,
            receiver: contact.id,
            attachments: attachments.map((a) => ({
              guid: a.id,
              typeOfData: documentTypes[a.type],
            })),
            files: filesBlobs
              .filter((f) => Boolean(f))
              .map((f) => ({
                url: f.url,
                mime: f.mime,
                localName: f.localName,
              })),
          },
        },
        optimisticResponse: {
          saveChatMessage: {
            id: "-1",
            guidS: `${Math.round(Math.random() * 100000000)}`,
            guid: Math.round(Math.random() * 100000000),
            sent: Date.now(),
            forwarded: false,
            fromImgUrl: null,
            toGuid: null,
            isPrivateNote: null,
            fromGuid: null,
            fromGuidS: null,
            fromName: null,
            toGuidS: null,
            toName: null,
            toImgUrl: null,
            inReplyTo: null,
            inReplyToS: null,
            anonym: null,
            isRead: null,
            videoUrl: null,
            videoThumbUrl: null,
            imageUrl: null,
            imageThumbUrl: null,
            cursor: null,
            attachedFiles: [],
            oldLinks: [],
            messageBody: message,
            __typename: "Message",
          },
        },
        refetchQueries: () => [MessageSeenByDocument],
        update: (proxy, { data: { saveChatMessage } }: any) => {
          const variables = {
            sessionId,
            language,
            showAll: true,
            includeHidden: true,
            ignoreContactsWithoutChats: true,
          };
          const contactsData = proxy.readQuery<
            MessageOverviewPaginatedQuery,
            MessageOverviewPaginatedQueryVariables
          >({
            query: MessageOverviewPaginated,
            variables,
          });
          const lastMessage = () => {
            if (
              (attachments.length > 0 && files.length > 0) ||
              attachments.length > 0
            )
              return `${t("links")}`;
            else if (files.length > 0) {
              if (files.length === 1) {
                const ext = files[0].mime.split("/")[0];
                if (ext === "image") {
                  return `${t("photo")}`;
                } else if (ext === "video") {
                  return `${t("video")}`;
                } else {
                  return `${t("attachment")}`;
                }
              } else return `${t("manyaAttachments")}`;
            } else {
              return saveChatMessage.messageBody;
            }
          };

          const messagesData: any = proxy.readQuery({
            query: MessageList2,
            variables: messageListVariables,
          });
          const messages = messagesData.messageList2.messages2.edges.slice();
          const contacts =
            contactsData?.messageOverview.contactsPaginated.edges.slice();

          const index = contacts?.findIndex((c) => c.node.id === contact.id);
          const currentContact = index !== -1 ? contacts[index] : null;

          messages.unshift({
            msg: saveChatMessage,
            cursor: null,
            __typename: "MessageEdge",
          });
          const newData = {
            messageList2: {
              ...messagesData.messageList2,
              messages2: {
                ...messagesData.messageList2.messages2,
                totalCount: messagesData.messageList2.messages2.totalCount + 1,
                edges: messages,
              },
            },
          };
          if (currentContact) {
            if (index !== 0) {
              const [removedContact] = contacts.splice(index, 1);
              contacts.unshift({
                ...removedContact,
                node: {
                  ...removedContact.node,
                  lastMessage: saveChatMessage.messageBody || lastMessage(),
                  hidden: false,
                  muted: false,
                  whoCanPost: null,
                  lastChatTime: Date.now(),
                },
              });
            }
          } else {
            const newContact = {
              ...contact,
              node: {
                ...contact.node,
                muted: false,
                hidden: false,
                lastMessage: saveChatMessage.messageBody
                  ? saveChatMessage.messageBody
                  : lastMessage(),
                lastChatTime: Date.now(),
                unreadByMeInGroup: 0,
              },
            };
            if (newContact.node.jointMainGroups)
              delete newContact.node.jointMainGroups;
            contacts?.unshift(newContact);
          }

          proxy.writeQuery({
            query: MessageList2,
            variables: messageListVariables,
            data: newData,
          });

          proxy.writeQuery<
            MessageOverviewPaginatedQuery,
            MessageOverviewPaginatedQueryVariables
          >({
            query: MessageOverviewPaginated,
            variables,
            data: {
              ...contactsData,
              messageOverview: {
                ...contactsData.messageOverview,
                contactsPaginated: {
                  ...contactsData.messageOverview.contactsPaginated,
                  edges: contacts,
                },
              },
            },
          });
        },
      }).then(() => {
        //Fire onRowPress to update the contact prop with the updated info from store
        onRowPress(contact);
      });
      markRead({
        variables: {
          sessionId,
          input: {
            chatterIds: [contact.id],
          },
        },
        update: (proxy) => {
          updateUnreadAll(proxy, { sessionId, timezone, language });

          const variables = {
            sessionId,
            language,
            showAll: true,
            includeHidden: true,
            ignoreContactsWithoutChats: true,
          };
          const data = proxy.readQuery<
            MessageOverviewPaginatedQuery,
            MessageOverviewPaginatedQueryVariables
          >({
            query: MessageOverviewPaginated,
            variables,
          });
          const contacts =
            data?.messageOverview.contactsPaginated.edges.slice();

          const index = contacts?.findIndex((c) => c.node.id === contact.id);
          const currentContact = index !== -1 ? contacts[index] : null;

          if (currentContact) {
            contacts.splice(index, 1, {
              ...currentContact,
              node: {
                ...currentContact.node,
                unreadByMeInGroup: 0,
              },
            });

            proxy.writeQuery<
              MessageOverviewPaginatedQuery,
              MessageOverviewPaginatedQueryVariables
            >({
              query: MessageOverviewPaginated,
              variables,
              data: {
                ...data,
                messageOverview: {
                  ...data.messageOverview,
                  contactsPaginated: {
                    ...data.messageOverview.contactsPaginated,
                    edges: contacts,
                  },
                },
              },
            });
          }
        },
      });
    }
  };

  const onDeleteMessage = async (messageId) => {
    await deleteMessage({
      variables: {
        sessionId,
        messageId,
      },
      update: (proxy, { data: { deleteMessage } }: any) => {
        const variables = {
          sessionId,
          language,
          showAll: true,
          includeHidden: true,
          ignoreContactsWithoutChats: true,
        };

        const contactsData = proxy.readQuery<
          MessageOverviewPaginatedQuery,
          MessageOverviewPaginatedQueryVariables
        >({
          query: MessageOverviewPaginated,
          variables,
        });
        const messagesData: any = proxy.readQuery({
          query: MessageList2,
          variables: messageListVariables,
        });
        const messages = messagesData.messageList2.messages2.edges.slice();
        const contacts =
          contactsData?.messageOverview.contactsPaginated.edges.slice();
        const index = contacts?.findIndex((c) => c.node.id === contact.id);
        const currentContact = index !== -1 ? contacts[index] : null;

        const newMessages = messages.filter(
          (m) => m?.msg?.id !== deleteMessage
        );
        const newData = {
          messageList2: {
            ...messagesData.messageList2,
            messages2: {
              ...messagesData.messageList2.messages2,
              totalCount: messagesData.messageList2.messages2.totalCount + 1,
              edges: newMessages,
            },
          },
        };
        proxy.writeQuery({
          query: MessageList2,
          variables: messageListVariables,
          data: newData,
        });
        if (currentContact) {
          contacts.splice(index, 1, {
            ...currentContact,
            node: {
              ...currentContact.node,
              lastMessage:
                newMessages.length > 2
                  ? newMessages[1].msg.messageBody ?? null
                  : null,
            },
          });
          proxy.writeQuery<
            MessageOverviewPaginatedQuery,
            MessageOverviewPaginatedQueryVariables
          >({
            query: MessageOverviewPaginated,
            variables,
            data: {
              ...contactsData,
              messageOverview: {
                ...contactsData.messageOverview,
                contactsPaginated: {
                  ...contactsData.messageOverview.contactsPaginated,
                  edges: contacts,
                },
              },
            },
          });
        }
      },
    }).catch((e) => {
      console.error(e);
      showErrorNotification(t("deleteMessageError"));
    });
  };
  const isGroupChat =
    contact.chatterType === "ChatGroup" || contact.chatterType === "Group";
  const isTeam = !!contact?.linkedGroup || !!contact?.allFriendsOf?.id;
  const groupOwnerThumb = contact?.groupOwner?.thumb;
  const groupHasOwnerAvatar = isTeam && groupOwnerThumb;
  const showDefaultGroupIcon = isGroupChat && !groupHasOwnerAvatar;

  const contactWithSeen = useMemo(
    () => ({
      ...contact,
      seen: seenByData?.chatter?.seen,
    }),
    [contact, seenByData]
  );

  const canDeleteOrUpdate = contact?.canLoggedOnUserDelete;
  const avatarsLength = avatars.length + (isLoggedInUserOwner ? 1 : 0);

  return (
    <Wrapper>
      <HeaderWrapper>
        <Header isGroupChat={isGroupChat}>
          {showDefaultGroupIcon ? (
            <IconWrapper>
              {isAnnouncement ? (
                <AnnouncementIcon />
              ) : (
                <RoundedGroupProfileIcon />
              )}
            </IconWrapper>
          ) : (
            <Fragment>
              {isGroupChat ? (
                <Avatar
                  size={45}
                  style={{ marginRight: 15 }}
                  uri={groupOwnerThumb}
                  userGuid={contact?.groupOwner?.id}
                  isThumb={true}
                />
              ) : (
                avatars.map((avatar) => (
                  <Avatar
                    key={avatar.userID}
                    size={45}
                    style={{ marginRight: 15 }}
                    userName={avatar.name as string}
                    userGuid={avatar.userID}
                    isThumb={true}
                  />
                ))
              )}
            </Fragment>
          )}
          <ChatTitleWrapper>
            <ChatTitleHeading
              style={{ marginTop: isGroupChat && !loading ? 5 : 0 }}
            >
              {contactName}
            </ChatTitleHeading>
            {isGroupChat && !loading && (
              <Tooltip
                onHoverIn={() => setIsAvatarHovered(true)}
                onHoverOut={() => setIsAvatarHovered(false)}
                isHoverable={contact.canPost}
                containerStyle={{
                  left: getMembersContainerPosition(),
                }}
                title={() => (
                  <TooltipTitle
                    contactName={contactName}
                    avatarsLength={avatarsLength}
                  />
                )}
                component={(hovered) => (
                  <StyledText
                    color={
                      hovered && contact.canPost
                        ? COLOR_BLUE
                        : COLOR_MEDIUM_GRAY
                    }
                    fontSize={10}
                    fontWeight="semibold"
                  >
                    {avatarsLength} {t("members").toUpperCase()}
                  </StyledText>
                )}
              >
                <AvatarList
                  isLoggedInUserOwner={isLoggedInUserOwner}
                  numberMembersCols={numberMembersCols}
                  avatars={avatars}
                />
              </Tooltip>
            )}
          </ChatTitleWrapper>
        </Header>
        <Dropdown
          dropdownContainerStyle={{
            minWidth: 170,
          }}
          dropdownContentStyle={{
            top: 35,
            right: 0,
            padding: "5px 0",
          }}
          component={({ show, toggle }) => (
            <IconButton
              enforceActiveState={show}
              icon={<SettingsHorizontalIcon isActive={show} />}
              onClick={toggle}
            />
          )}
        >
          {({ toggle }) => (
            <>
              <MuteChatOption
                contact={contact}
                contactMuted={contactMuted}
                setContactMuted={setContactMuted}
              />
              <HideChatOption
                contact={contact}
                hideContact={setContactHidden}
                onRowPress={onRowPress}
                activateNextContact={activateNextContact}
                contactHidden={contactHidden}
                toggle={toggle}
              />
              {isGroupChat &&
                (contact.canLoggedOnUserAddParticipants ||
                  contact.canLoggedOnUserRemoveParticipants) && (
                  <EditChatOption
                    contact={contact}
                    avatars={avatars}
                    onRowPress={onRowPress}
                    messageListRefetch={messageListRefetch}
                  />
                )}
              {isGroupChat &&
                isTrainer &&
                !isFamilyMember &&
                canDeleteOrUpdate && (
                  <DeleteChatOption
                    // TODO check if group owner is current user - validate it with Agust
                    chatGroupId={contact?.id}
                  />
                )}
            </>
          )}
        </Dropdown>
      </HeaderWrapper>
      <Chat
        onRowPress={onRowPress}
        allAvatarsInChat={avatars}
        isGroupChat={
          contact?.chatterType2 === "ChatGroup" ||
          contact?.chatterType2 === "RealGroup"
        }
        edges={edges}
        loading={loading}
        fetchingMore={fetchingMore}
        onFetchMore={onFetchMore}
        hasChatNextPage={hasChatNextPage}
        contact={contactWithSeen}
        onSendMessage={onSendMessage}
        sendingMessage={sendingMessage}
        uploading={uploading}
        onDeleteMessage={onDeleteMessage}
        deletingMessage={deletingMessage}
        messageError={deleteMessageError}
        onVideoPress={(video) => setVideoUri(video)}
        onUrlPress={(url, isModular) => onUrlPress(url, isModular)}
        setIsAvatarHovered={setIsAvatarHovered}
      />
    </Wrapper>
  );
}

ChatScreen.navigationOptions = () => ({
  header: null,
});
