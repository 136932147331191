import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../../components/Header";
import { ChevronRightIcon } from "../../components/Icons";
import { useUIContext } from "../../contexts/UI";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { AgendaScreen } from "../AgendaScreen";

import { LeftSection } from "./LeftSection";
import { RightSection } from "./RightSection";
import {
  ScreenWrapper,
  Wrapper,
  SectionWrapper,
  SectionTitleWrapper,
  SectionTitle,
  SectionSubTitle,
  SectionLink,
  SectionLinkWrapper,
} from "./styles";

export const MyXPSScreen = () => {
  const user = useUserContext();
  const { t } = useTranslation();
  const ui = useUIContext();
  const userSettings = user?.settings;

  const sectionToFullWidth = ui.width < 1000;

  return isRealTrainer(user) ? (
    <ScreenWrapper scrollable={sectionToFullWidth}>
      <Header />
      <Wrapper direction={sectionToFullWidth ? "column" : "row"}>
        <SectionWrapper width={sectionToFullWidth ? 100 : 60}>
          <SectionTitleWrapper marginLeft={sectionToFullWidth ? 0 : 30}>
            <SectionTitle>{t("fromOurBlog")}</SectionTitle>
            <SectionLinkWrapper target="_blank" href={userSettings?.blogFull}>
              <SectionLink>{t("visitOurBlog")}</SectionLink>
              <ChevronRightIcon />
            </SectionLinkWrapper>
          </SectionTitleWrapper>
          <LeftSection />
        </SectionWrapper>
        <SectionWrapper width={sectionToFullWidth ? 100 : 40}>
          <SectionTitleWrapper>
            <SectionTitle>{t("gettingStarted")}</SectionTitle>
            <SectionSubTitle>{t("learMore")}</SectionSubTitle>
          </SectionTitleWrapper>
          <RightSection />
        </SectionWrapper>
      </Wrapper>
    </ScreenWrapper>
  ) : (
    <AgendaScreen />
  );
};
