import { useTranslation } from "react-i18next";

import _flow from "lodash/flow";
import _sumBy from "lodash/sumBy";

import { AggregateFunction } from "../../../graphql";
import { isInformationCollection } from "../../../utils/reports";

import { ConditionData, ConditionsData, TableData } from "./model";

export const ANY_VALUE_ID = "ANY";
export const ANY_VALUE_CONDITION: Omit<ConditionData, "templateName"> = {
  aggregations: [],
  templateId: `${ANY_VALUE_ID}:${ANY_VALUE_ID}`,
  isInfo: false,
  isTestWithColorRanges: true,
};

export const useConditionsData = <T extends TableData>(
  list: T[],
  getAggregationsFn: (T) => AggregateFunction[]
): ConditionsData => {
  const { t } = useTranslation();

  return [
    { ...ANY_VALUE_CONDITION, templateName: t("anyValue") },
    ...list.map((data) => {
      const moreMeasurement = _sumBy(
        list,
        _flow(
          ({ measurementTemplateId }) =>
            measurementTemplateId === data.measurementTemplateId,
          Number
        )
      );

      return {
        aggregations: getAggregationsFn(data),
        templateId: `${data.measurementTemplateId}:${data.attributeTemplateId}`,
        templateName: `${data.measurementTemplateName}${
          moreMeasurement > 1 ? ` (${data.attributeTemplateName})` : ""
        }`,
        isInfo: isInformationCollection(data.collectionId),
        isTestWithColorRanges: data.isTestWithColorRanges,
      };
    }),
  ];
};
