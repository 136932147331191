import { useEffect } from "react";

import { useModalContext } from "../../contexts/UI/Modal";

export const ModalErrorComponent = () => {
  const { actions } = useModalContext();

  useEffect(() => {
    actions.closeModal();
  }, [actions]);

  return null;
};
