import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const HomeIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive,
    color = "#FFFFFF",
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      {isActive ? (
        <path
          d="M8 21H3C2.44772 21 2 20.5523 2 20V9.32843C2 8.79799 2.21071 8.28929 2.58579 7.91421L9.72218 0.777817C10.1518 0.348241 10.8482 0.348241 11.2778 0.777818L18.4142 7.91421C18.7893 8.28929 19 8.79799 19 9.32843V20C19 20.5523 18.5523 21 18 21H13C12.4477 21 12 20.5523 12 20V15C12 14.7239 11.7761 14.5 11.5 14.5H9.5C9.22386 14.5 9 14.7239 9 15V20C9 20.5523 8.55228 21 8 21Z"
          fill={color}
        />
      ) : (
        <path
          d="M8 20.5H3C2.72386 20.5 2.5 20.2761 2.5 20V9.32843C2.5 8.9306 2.65804 8.54907 2.93934 8.26777L10.0757 1.13137C10.3101 0.897057 10.6899 0.897056 10.9243 1.13137L18.0607 8.26777C18.342 8.54907 18.5 8.9306 18.5 9.32843V20C18.5 20.2761 18.2761 20.5 18 20.5H13C12.7239 20.5 12.5 20.2761 12.5 20V15C12.5 14.4477 12.0523 14 11.5 14H9.5C8.94772 14 8.5 14.4477 8.5 15V20C8.5 20.2761 8.27614 20.5 8 20.5Z"
          stroke={color}
        />
      )}
    </svg>
  )
);
