import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
  COLOR_WHITE,
} from "../../colors";
import { useAccessLevelsContext } from "../../contexts/accessLevels";
import { useNotificationsContext } from "../../contexts/notifications/index";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useProgramDetailsQuery } from "../../services/hooks";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { CalendarIcon } from "../Icons";
import { Spinner, SpinnerSize } from "../Spinner";
import StyledText from "../StyledText";

import { LibraryHeader } from "./LibraryHeader";

type ProgramListProps = {
  program: {
    name: string;
    guid: string;
  };
  onSend: () => void;
  closeModal: () => void;
  onBack: () => void;
  isTeamPractice: boolean;
};

const ProgramListWrapper = styled.div`
  background-color: ${COLOR_GRAY};
  height: 100%;
`;

const ProgramWrapper = styled.div<{ paddingBottom: number }>`
  padding: 20px 20px ${({ paddingBottom }) => paddingBottom}px 20px;
  background-color: ${COLOR_GRAY};
`;

const WeekWrapper = styled.div`
  margin-bottom: 15px;
`;

const Week = styled.div`
  font-size: 10px;
  font-weight: 500;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 10px;
`;

const Session = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: ${COLOR_WHITE};
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(21, 31, 59, 0.08);
  border: 0.5px solid rgba(60, 67, 76, 0.05);
  margin-bottom: 5px;
  height: 55px;
  cursor: pointer;
`;

const Day = styled.div`
  font-size: 10px;
  font-weight: 600;
  color: ${COLOR_TEXT_TABLE_LABEL};
  margin-right: 16px;
`;

const Workout = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${COLOR_TEXT_DARK};
`;

const Time = styled.div`
  font-weight: 500;
  font-size: 10px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-top: 5px;
`;

const SendContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  background: ${COLOR_WHITE};
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
`;

const SendButton = styled.button`
  padding: 10px 25px;
  border-radius: 6px;
  background-color: ${COLOR_BLUE};
  border-width: 0;
  cursor: pointer;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export function ProgramList({
  program,
  onSend,
  closeModal,
  onBack,
  isTeamPractice,
}: ProgramListProps) {
  const { t } = useTranslation();
  const user = useUserContext();
  const {
    actions: { openTopModal, closeTopModal },
  } = useTopModalContext();
  const { actions } = useModalContext();
  const { getTeamSessionsRight, selectedAccount } = useAccessLevelsContext();
  const { showWarningNotification } = useNotificationsContext();

  const { data, isLoading } = useProgramDetailsQuery({
    session: user,
    programGuid: program?.guid,
  });

  const weeks = useMemo(() => {
    if (data && data?._sessions?.length) {
      return data._sessions.reduce((acc, session) => {
        // array index starts with 0
        const weekNum = Math.ceil(session._dayOfProgram / 7) - 1;

        if (!acc[weekNum]) {
          acc[weekNum] = [];
        }

        acc[weekNum].push(session);
        return acc;
      }, []);
    }
  }, [data]);

  const weeksLabel =
    weeks?.length > 0
      ? `${t(weeks.length === 1 ? "oneWeek" : "weeksWithCount", {
          x: weeks.length,
        }).toLocaleLowerCase()}`
      : null;

  const onSessionClick = (session) => {
    openTopModal({
      modal: TopModalType.SESSION_DETAIL,
      title: t(isTeamPractice ? "practice" : "workout"),
      onBack,
      params: {
        session,
        isTeamPractice,
        onBack: () => {
          actions.closeModal();
          openTopModal({
            modal: TopModalType.SESSION_DETAIL,
            title: t(isTeamPractice ? "practice" : "workout"),
            onBack,
            params: {
              session,
              onBack,
              aboveTop: true,
            },
          });
        },
        aboveTop: true,
      },
    });
  };

  const onAddPress = () => {
    const { edit } = getTeamSessionsRight(selectedAccount?.id);

    if (!edit && isRealTrainer(user)) {
      const label = isTeamPractice
        ? "cannotCreateTeamSession"
        : "cannotCreateAthleteSession";

      return showWarningNotification(t(label));
    }

    actions.closeModal();
    closeTopModal();
    actions.openModal({
      modal: ModalType.ADD_TO_CALENDAR,
      title: t("addToCalendar"),
      onBack: () => onBack(),
      params: {
        isTeamPractice,
        title: program?.name,
        period: weeksLabel,
        isProgram: true,
        programDetails: {
          ...data,
          guid: program?.guid,
        },
      },
    });
  };

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Spinner size={SpinnerSize.SMALL} />
      </LoadingWrapper>
    );
  }

  return (
    <ProgramListWrapper>
      <LibraryHeader
        title={program?.name}
        icon={<CalendarIcon />}
        period={weeksLabel}
        isProgram={true}
        onAdd={onAddPress}
      />
      <ProgramWrapper paddingBottom={onSend ? 75 : 25}>
        {weeks?.length > 0
          ? weeks.map((sessions, index) => {
              const weekLabel = `${t("week").toUpperCase()} ${index + 1}`;

              return sessions?.length > 0 ? (
                <WeekWrapper key={index}>
                  <Week>{weekLabel}</Week>
                  {sessions.map((session, index) => {
                    // Because of modulo, (0 should be 7)
                    const dayOfProgram = session?._dayOfProgram % 7 || 7;

                    const dayLabel = `${t(
                      "day"
                    ).toLocaleUpperCase()} ${dayOfProgram}`;

                    return (
                      <Session
                        key={session?._guid}
                        onClick={() => onSessionClick(session)}
                      >
                        <Day>{dayLabel}</Day>
                        <div>
                          <Workout>
                            {session._label || `${t("session")} ${index + 1}`}
                          </Workout>
                          {session?._durationMinutes ? (
                            <Time>
                              {t("minutesDuration", {
                                x: session._durationMinutes,
                              })}
                            </Time>
                          ) : null}
                        </div>
                      </Session>
                    );
                  })}
                </WeekWrapper>
              ) : null;
            })
          : null}
      </ProgramWrapper>
      {onSend ? (
        <SendContainer>
          <SendButton
            onClick={() => {
              onSend();
              closeModal();
            }}
          >
            <StyledText fontSize={12} fontWeight="bold" color={COLOR_WHITE}>
              {t("send").toUpperCase()}
            </StyledText>
          </SendButton>
        </SendContainer>
      ) : null}
    </ProgramListWrapper>
  );
}
