import * as api from "./api";
import * as endpoints from "./endpoints";

export interface ProgramDetailsQueryResponse {
  _programRegularity: {
    _weekDaysFreysiStyle: {
      _dayIndex1to7: number;
      _timeHHMM: string;
    }[];
  };
  _sessions: {
    _dayOfProgram: number;
    _durationMinutes: number;
    _guid: string;
    _label: string;
    _minuteOfDay: number;
    _sessionType: number;
  }[];
  _teamSessins: boolean;
  _thumb: string;
}

export type ProgramDetailsQueryArgs = {
  session: api.Session;
  programGuid: string;
};

export async function fetchProgramDetails({
  session,
  programGuid,
}: ProgramDetailsQueryArgs) {
  const params = {
    _programGuid: programGuid,
  };

  const response = await api.post<ProgramDetailsQueryResponse>(
    endpoints.JsonProgramSimpleQuery,
    params,
    session
  );

  if (response.status !== 200 || !response.data) {
    return null;
  }

  return response?.data;
}
