import dayjs from "dayjs";

export const groupBy = (objectArray, format) => {
  return objectArray.reduce((acc, obj) => {
    const month = dayjs(obj.start).format(format);
    const curGroup = acc[month] ?? [];

    return { ...acc, [month]: [...curGroup, obj] };
  }, {});
};
