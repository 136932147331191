import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREEN,
  COLOR_BLUE,
  COLOR_BLUE_ATTENDANCE_BG,
  COLOR_MEDIUM_GRAY,
  COLOR_RED_ACTIVE,
  COLOR_WHITE,
} from "../colors";
import { AttendanceStatus } from "../models/attendance";

import { CrossIcon, TickIcon } from "./Icons";

export const StyledAttendanceButton = styled.button<{
  bgColor: string;
  color: string;
  hasIcon: boolean;
  hasBorder?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: ${({ bgColor }) => bgColor};

  font-weight: 700;
  font-size: 12px;
  color: ${({ color }) => color};

  border: 1px solid
    ${({ color, hasBorder }) => (hasBorder ? color : "transparent")};
  border-radius: 6px;

  height: 35px;
  min-width: 107px;
  padding: ${({ hasIcon }) => (hasIcon ? "7px 10px 7px 5px" : "7px 10px")};
  width: 100%;

  span {
    width: 100%;
  }
`;

interface AttendanceButtonProps {
  attendanceStatus: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  withRegistration?: boolean;
  isExpired?: boolean;
}

export const AttendanceButton = ({
  attendanceStatus,
  onClick,
  withRegistration,
  isExpired,
}: AttendanceButtonProps) => {
  const { t } = useTranslation();

  const color = useMemo(() => {
    switch (attendanceStatus) {
      case AttendanceStatus.ATTENDED:
        return COLOR_GREEN;
      case AttendanceStatus.SICK:
      case AttendanceStatus.INJURED:
      case AttendanceStatus.OTHER:
        return COLOR_RED_ACTIVE;
      case AttendanceStatus.UNKNOWN:
        return COLOR_BLUE;
      default:
        return COLOR_MEDIUM_GRAY;
    }
  }, [attendanceStatus]);

  const bgColor = useMemo(() => {
    switch (attendanceStatus) {
      case AttendanceStatus.UNKNOWN:
        return COLOR_BLUE_ATTENDANCE_BG;
      default:
        return "transparent";
    }
  }, [attendanceStatus]);

  const icon = useMemo(() => {
    switch (attendanceStatus) {
      case AttendanceStatus.ATTENDED:
        return <TickIcon height={30} width={30} />;
      case AttendanceStatus.SICK:
      case AttendanceStatus.INJURED:
      case AttendanceStatus.OTHER:
        return <CrossIcon style={{ height: 30, width: 30 }} />;
      case AttendanceStatus.UNKNOWN:
      default:
        return null;
    }
  }, [attendanceStatus]);

  const message = useMemo(() => {
    switch (attendanceStatus) {
      case AttendanceStatus.ATTENDED:
        return t("attending");
      case AttendanceStatus.SICK:
      case AttendanceStatus.INJURED:
      case AttendanceStatus.OTHER:
        return t("notAttending");
      case AttendanceStatus.UNKNOWN:
      default:
        return t("respond");
    }
  }, [attendanceStatus, t]);

  const withUnknownRegistrationStyles =
    withRegistration && attendanceStatus === AttendanceStatus.UNKNOWN;

  if (isExpired && withUnknownRegistrationStyles) {
    return null;
  }

  return (
    <StyledAttendanceButton
      onClick={onClick}
      bgColor={withUnknownRegistrationStyles ? COLOR_GREEN : bgColor}
      color={withUnknownRegistrationStyles ? COLOR_WHITE : color}
      hasIcon={Boolean(icon)}
      hasBorder={attendanceStatus !== AttendanceStatus.UNKNOWN}
    >
      {icon}
      <span>{message.toUpperCase()}</span>
    </StyledAttendanceButton>
  );
};
