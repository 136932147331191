import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";

import Highcharts from "highcharts";
import ReactHighcharts from "highcharts-react-official";
import styled from "styled-components";

import { roundToTwo } from "../../../../utils/number";
import { AcuteChronicWidgetModel } from "../../../ReportCreateTable/models";
import { WidgetContainer, WidgetTitle } from "../../styled";
import { WidgetNoData } from "../../WidgetNoData";
import { DEFAULT_OVERLOAD_COLOR } from "../Editor/DataSection";

type Point = {
  t: number;
  y: number;
};

export type AcuteChronicWidgetChartProps = {
  data: {
    acRatio: Point[];
    dailyBars?: Point[];
    acuteLine?: Point[];
    chronicLine?: Point[];
  };
  widget: AcuteChronicWidgetModel;
};

const Container = styled.div`
  height: 100%;
  overflow: auto;
`;

const AcutChronicWidgetTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const AcuteChronicWidgetChart = ({
  data,
  widget,
}: AcuteChronicWidgetChartProps) => {
  const { t } = useTranslation();
  const {
    undertrainingTreshold,
    overtrainingTreshold,
    overloadTreshold,
    overloadTresholdColor,
    showAcuteAverageLine,
    showBarsForDailyLoad,
    showChronicAverageLine,
  } = widget;

  // Fix chart dimension on widget resize
  const { height, ref } = useResizeDetector();

  const chartConfig = useMemo(() => {
    const plotLines = [];
    const series = [];
    const yAxis = [];

    const minHeight = 355;
    const chartHeight = Math.max(height ?? 0, minHeight);

    if (undertrainingTreshold) {
      plotLines.push({
        value: undertrainingTreshold,
        color: "yellow",
        dashStyle: "dash",
        width: 2,
        label: {
          text: t("undertraining"),
        },
      });
    }

    if (overloadTreshold) {
      plotLines.push({
        value: overloadTreshold,
        color: overloadTresholdColor || DEFAULT_OVERLOAD_COLOR,
        dashStyle: "dash",
        width: 2,
        label: {
          text: t("overload"),
        },
      });
    }

    if (overtrainingTreshold) {
      plotLines.push({
        value: overtrainingTreshold,
        color: "red",
        dashStyle: "dash",
        width: 2,
        label: {
          text: t("overtraining"),
        },
      });
    }

    if (data?.acRatio) {
      const points = data.acRatio;
      yAxis.push({
        min: 0,
        title: {
          text: t("chartACRation"),
        },
        plotLines,
        opposite: true,
      });
      series.push({
        zIndex: 2,
        yAxis: 0,
        name: "A:C",
        data: points.map((point) => [point.t, roundToTwo(point.y)]),
        animation: false,
      });
    }

    if (showAcuteAverageLine && data?.acuteLine) {
      const points = data.acuteLine;
      if (yAxis.length < 2) {
        yAxis.push({
          title: {
            text: t("chartLoad"),
          },
        });
      }
      series.push({
        zIndex: 2,
        yAxis: 1,
        name: t("chartAcuteLoad"),
        data: points.map((point) => [point.t, roundToTwo(point.y)]),
        animation: false,
      });
    }

    if (showChronicAverageLine && data?.chronicLine) {
      const points = data.chronicLine;
      if (yAxis.length < 2) {
        yAxis.push({
          title: {
            text: t("chartLoad"),
          },
        });
      }
      series.push({
        zIndex: 2,
        yAxis: 1,
        name: t("chartChronicLoad"),
        data: points.map((point) => [point.t, roundToTwo(point.y)]),
        animation: false,
      });
    }

    if (showBarsForDailyLoad && data?.dailyBars) {
      const points = data.dailyBars;
      if (yAxis.length < 2) {
        yAxis.push({
          title: {
            text: t("chartLoad"),
          },
        });
      }
      series.push({
        zIndex: 1,
        yAxis: 1,
        type: "column",
        name: t("chartTraningLoad"),
        data: points.map((point) => [point.t, roundToTwo(point.y)]),
        maxPointWidth: 10,
        animation: false,
      });
    }

    return {
      title: {
        text: null,
        subtitle: null,
      },
      chart: {
        type: "spline",
        height: chartHeight - 55,
        animation: false,
      },
      yAxis,
      xAxis: [
        {
          type: "datetime",
          dateTimeLabelFormats: {
            month: "%e. %b",
            year: "%b",
          },
          title: {
            text: t("date"),
          },
        },
      ],
      series,
      tooltip: {
        xDateFormat: "%A, %e. %b",
      },
      credits: {
        enabled: false,
      },
    };
  }, [
    data?.acRatio,
    data?.acuteLine,
    data?.chronicLine,
    data?.dailyBars,
    showAcuteAverageLine,
    showChronicAverageLine,
    showBarsForDailyLoad,
    height,
    overtrainingTreshold,
    undertrainingTreshold,
    overloadTreshold,
    overloadTresholdColor,
    t,
  ]);

  const noData =
    chartConfig?.series?.length === 0 ||
    chartConfig?.series?.every(
      (options) =>
        "data" in options &&
        (options.data?.length === 0 ||
          options.data?.every((item) => item.length === 2 && item[1] === 0))
    );

  return (
    <WidgetContainer ref={ref} showBorders={widget?.widgetBorders}>
      <Container>
        <AcutChronicWidgetTitleContainer>
          <WidgetTitle>{widget?.name ?? " "}</WidgetTitle>
        </AcutChronicWidgetTitleContainer>

        {noData ? (
          <WidgetNoData />
        ) : (
          <ReactHighcharts
            highcharts={Highcharts}
            options={chartConfig}
            immutable
          />
        )}
      </Container>
    </WidgetContainer>
  );
};
