import React from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";

import { getSortingOptions } from "../../../utils/sortingOrderOptions";
import { FieldErrorMessage } from "../../Report/styled";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField, SelectOption } from "../../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../../styled";
import { CreateGroupTableFormValue } from "../models";

export const DefaultSortingSectionGroupTable = () => {
  const { t } = useTranslation();

  const { values, errors, setFieldValue } =
    useFormikContext<
      Pick<CreateGroupTableFormValue, "columns" | "sortBy" | "sortingOrder">
    >();

  const generateOptions = (showEmpty: boolean) => {
    const headerStructure = [];

    values.columns.forEach((column) => {
      const alreadyCreatedMainHeaderIndex = headerStructure.findIndex(
        (header) => header.measurementId === column.measurementTemplateId
      );

      if (alreadyCreatedMainHeaderIndex !== -1) {
        headerStructure[alreadyCreatedMainHeaderIndex] = {
          ...headerStructure[alreadyCreatedMainHeaderIndex],
          attributes: [
            ...headerStructure[alreadyCreatedMainHeaderIndex].attributes,
            {
              attributeId: column.attributeTemplateId,
              attributeName: column.attributeTemplateName,
              aggregations: column.aggregateFunctions,
            },
          ],
        };

        return;
      }

      headerStructure.push({
        measurementId: column.measurementTemplateId,
        measurementName: column.measurementTemplateName,
        attributes: [
          {
            attributeId: column.attributeTemplateId,
            attributeName: column.attributeTemplateName,
            aggregations: column.aggregateFunctions,
          },
        ],
      });
    });

    const options = headerStructure.flatMap((measurement, measurementIndex) =>
      measurement.attributes.flatMap((attribute, attrIndex) =>
        attribute.aggregations.map((aggregation, aggIndex) => ({
          label: `${measurement.measurementName} ${
            measurement.attributes.length > 1
              ? `- ${attribute.attributeName}`
              : ""
          } - ${t(
            // @ts-ignore
            `aggregation${aggregation}`,
            {
              defaultValue: aggregation,
            }
          )}`,
          value: JSON.stringify({
            statsIndex: measurementIndex,
            valueIndex: aggIndex,
            attributeIndex: attrIndex,
          }),
        }))
      )
    );

    return showEmpty
      ? [{ value: null, label: t("clearSelection") }, ...options]
      : options;
  };

  const onSortColumnChange = (option: SelectOption) => {
    const sortingObject =
      option && option.value
        ? JSON.parse(option.value)
        : {
            attributeIndex: null,
            valueIndex: null,
            statsIndex: null,
          };

    setFieldValue("sortBy", sortingObject);

    if (!option.value) {
      setFieldValue("sortingOrder", null);
    }
  };

  return (
    <Section>
      <SectionTitle title={t("defaultSorting")} />
      <DraggableLineWrapper>
        <FieldWrapper>
          <Field name="sortBy">
            {(props) => (
              <SelectField
                {...props}
                value={JSON.stringify(values.sortBy)}
                options={generateOptions(values.sortBy?.statsIndex !== null)}
                onChange={onSortColumnChange}
              />
            )}
          </Field>
        </FieldWrapper>

        <FieldWrapper>
          <Field name="sortingOrder">
            {(props) => (
              <SelectField
                {...props}
                value={values.sortingOrder}
                options={getSortingOptions(t, Boolean(values.sortingOrder))}
                onChange={(option: SelectOption<number>) =>
                  setFieldValue("sortingOrder", option ? option.value : null)
                }
              />
            )}
          </Field>
        </FieldWrapper>
      </DraggableLineWrapper>
      {(errors.sortBy || errors.sortingOrder) && (
        <FieldErrorMessage>
          {String(errors.sortBy || errors.sortingOrder)}
        </FieldErrorMessage>
      )}
    </Section>
  );
};
