import styled from "styled-components";

import { COLOR_RED, COLOR_WHITE } from "../../colors";
import StyledText from "../../components/StyledText";

export const FormWrapper = styled.form`
  width: 322px;
`;

export const LoadingWrapper = styled.div({
  height: 273,
  paddingTop: 100,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const LoadingMessage = styled(StyledText)({
  color: COLOR_WHITE,
  fontSize: "14px",
  fontWeight: 700,
  lineHeight: "17px",
  textAlign: "center",
  marginTop: "103px",
});

export const FormHeaderErrorWrapper = styled.div`
  background-color: ${COLOR_RED};
  border-radius: 6px;
  width: 100%;
  height: 65px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: flex;
  margin-bottom: 10px;
`;

export const FormHeaderErrorText = styled(StyledText)({
  color: COLOR_WHITE,
  fontSize: "12px",
  fontWeight: 700,
});
