import React from "react";

import { GetGroupQuery } from "../../graphql";

import { MembersList } from "./MembersList";

interface TeamMembersListProps {
  group: GetGroupQuery["getGroup"];
  isSubGroup: boolean;
}

export function TeamMembersList({ group, isSubGroup }: TeamMembersListProps) {
  return (
    <MembersList
      athletes={(isSubGroup ? group?.requiredAthletes : group?.athletes) ?? []}
      trainers={(isSubGroup ? group?.requiredTrainers : group?.trainers) ?? []}
      hideAdditionalInfo={false}
      group={group}
      forTeamDetail={true}
    />
  );
}
