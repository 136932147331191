import React, { useState } from "react";

import {
  styled as muistyled,
  IconButton,
  InputAdornment,
  Input,
} from "@mui/material";

import {
  COLOR_GREY_ACTIVE,
  COLOR_GRAY,
  COLOR_TEXT_DARK,
} from "../../../colors";
import { MinusInRoundIcon, PlusInRoundIcon } from "../../Icons";

type StepInputProps = {
  value?: number;
  onValueChange?: (val: number) => void;
};

export function StepInput({ value, onValueChange }: StepInputProps) {
  const [val, setVal] = useState<number | "">(value || "");

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = parseInt(e?.currentTarget?.value, 10) || 0;
    setVal(newValue);
    onValueChange?.(newValue);
  }
  function handleAddClick() {
    const newValue = (val || 0) + 1;
    setVal(newValue);
    onValueChange?.(newValue);
  }
  function handleSubstractClick() {
    const newValue = (val || 0) - 1;
    setVal(newValue);
    onValueChange?.(newValue);
  }

  return (
    <>
      <StyledInput
        disableUnderline
        type="number"
        onChange={onChange}
        value={val}
        startAdornment={
          <InputAdornment position="start">
            <IconButton edge="end" onClick={handleSubstractClick}>
              <MinusInRoundIcon backgroundColor={COLOR_GRAY} />
            </IconButton>
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="start" onClick={handleAddClick}>
              <PlusInRoundIcon backgroundColor={COLOR_GRAY} />
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}

const StyledInput = muistyled(Input)(() => ({
  padding: "0 9px",
  border: `1px solid ${COLOR_GREY_ACTIVE}`,
  width: "164px",
  height: "55px",
  borderRadius: "6px",
  backgroundColor: COLOR_GRAY,
  "& .MuiInputBase-input": {
    color: COLOR_TEXT_DARK,
    fontSize: "25px",
    fontWeight: 700,
    lineHeight: "30px",
    textAlign: "center",
  },
  "input::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "textfield",
    margin: 0,
  },
  "input::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    "-moz-appearance": "textfield",
    margin: 0,
  },
}));
