import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";

import { useAccessLevelsContext } from "../accessLevels";

export type AthleteReportPrintState = {
  mainTitle: string;
  headerIds: string[];
  tabIDs: string[];
  logoFlag: boolean;
  periodFlag: boolean;
  experimentalGridFlag: boolean;
  landscapeOrientation: boolean;
};

type FlagSetter = (flag: boolean) => void;

interface PrintAthleteReportContextValue extends AthleteReportPrintState {
  setMainTitle: (title: string) => void;
  setHeaderIDs: (ids: string[]) => void;
  setTabIDs: (ids: string[]) => void;
  setLogoFlag: FlagSetter;
  setPeriodFlag: FlagSetter;
  setExperimentalGridFlag: FlagSetter;
  setLandscapeOrientationFlag: FlagSetter;
}

const initialPrintState: AthleteReportPrintState = {
  mainTitle: "",
  headerIds: [],
  tabIDs: [],
  logoFlag: true,
  periodFlag: true,
  experimentalGridFlag: false,
  landscapeOrientation: false,
};

const PrintAthleteReportContext =
  createContext<PrintAthleteReportContextValue>(null);

export const PrintAthleteReportContextProvider = ({
  children,
}: PropsWithChildren<object>) => {
  const { selectedAccount } = useAccessLevelsContext();

  const [printState, setPrintState] =
    useState<AthleteReportPrintState>(initialPrintState);

  useEffect(() => {
    setPrintState({
      ...initialPrintState,
      logoFlag: !!selectedAccount?.img,
    });
  }, [selectedAccount?.img]);

  const actions = useMemo(
    () => ({
      setMainTitle: (mainTitle: string) =>
        setPrintState((old) => ({ ...old, mainTitle })),
      setHeaderIDs: (headerIds: string[]) =>
        setPrintState((old) => ({ ...old, headerIds })),
      setTabIDs: (tabIDs: string[]) =>
        setPrintState((old) => ({ ...old, tabIDs })),
      setLogoFlag: (logoFlag: boolean) =>
        setPrintState((old) => ({ ...old, logoFlag })),
      setPeriodFlag: (periodFlag: boolean) =>
        setPrintState((old) => ({ ...old, periodFlag })),
      setExperimentalGridFlag: (experimentalGridFlag: boolean) =>
        setPrintState((old) => ({ ...old, experimentalGridFlag })),
      setLandscapeOrientationFlag: (landscapeOrientation: boolean) =>
        setPrintState((old) => ({ ...old, landscapeOrientation })),
    }),
    []
  );

  return (
    <PrintAthleteReportContext.Provider value={{ ...printState, ...actions }}>
      {children}
    </PrintAthleteReportContext.Provider>
  );
};

export const usePrintAthleteReportContext = () =>
  useContext(PrintAthleteReportContext);
