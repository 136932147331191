import { useCallback, useEffect, useMemo, useState } from "react";

import { UserPreferenceKeys } from "../../constants";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { useGetPreferenceQuery, useReportTemplatesQuery } from "../../graphql";
import { AthleteProfileTabsPreference } from "../../models";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { parsePreferences } from "../../utils/preferences";
import { useGetIdsOfSharedReports } from "../report/useGetIdsOfSharedReports";
import { useGetSelectedAccountIdInMonitoring } from "../useGetSelectedAccountIdInMonitoring";

export function useAthleteProfileTabs() {
  const [{ selectedGroup }] = useReportsContext();
  const user = useUserContext();
  const { sessionId, language, isTrainer, isFamilyMember } = user;
  const [sharedReportsIds, setSharedReportsIds] = useState([]);
  const accountId = useGetSelectedAccountIdInMonitoring();

  const {
    data,
    refetch,
    loading: loadingTabs,
  } = useGetPreferenceQuery({
    variables: {
      sessionId,
      accountId,
      key: UserPreferenceKeys.ATHLETE_PROFILE_TABS,
    },
  });

  const { getIdsOfSharedTabReports } = useGetIdsOfSharedReports({
    reportIds: selectedGroup?.id
      ? parsePreferences<AthleteProfileTabsPreference>(
          data?.getPreference,
          null
        )?.[selectedGroup.id]?.map((report) => report.id)
      : [],
  });

  const { data: { reportTemplates } = {}, loading: loadingReports } =
    useReportTemplatesQuery({
      variables: {
        sessionId,
        accountId,
        language,
        // We want to get all reports in account for trainer but only shared one of specific group for athletes
        groupIdForAthleteSharing: isRealTrainer({ isTrainer, isFamilyMember })
          ? undefined
          : selectedGroup?.id,
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup?.owner?.id]);

  useEffect(() => {
    (async () => {
      const newSharedReportsIds = await getIdsOfSharedTabReports();
      setSharedReportsIds(newSharedReportsIds);
    })();
  }, [getIdsOfSharedTabReports]);

  const { tabsPreference, groupTabs } = useMemo(() => {
    const tabsPreference = parsePreferences<AthleteProfileTabsPreference>(
      data?.getPreference,
      {}
    );
    const reportTemplatesMap = new Map(
      reportTemplates?.map((report) => [report.id, report]) || []
    );

    const groupTabs = (tabsPreference[selectedGroup?.id] ?? [])
      .filter((tab) => reportTemplatesMap.has(tab.id) || !isRealTrainer(user))
      .filter((tab) => sharedReportsIds.includes(tab.id) || isRealTrainer(user))
      .map((tab) => ({
        ...tab,
        title:
          reportTemplatesMap.get(tab.id)?.name?.toUpperCase() ??
          tab.title.toUpperCase(),
        isVisible: isRealTrainer(user)
          ? true
          : sharedReportsIds.includes(tab.id),
        isShared: isRealTrainer(user) && sharedReportsIds.includes(tab.id),
      }));

    return { tabsPreference, groupTabs };
  }, [data, selectedGroup?.id, reportTemplates, sharedReportsIds, user]);

  const refetchAthleteProfileTabs = useCallback(async () => {
    refetch({ accountId: selectedGroup?.owner?.id });

    const newSharedReportsIds = await getIdsOfSharedTabReports();
    setSharedReportsIds(newSharedReportsIds);
  }, [refetch, getIdsOfSharedTabReports, selectedGroup]);
  return {
    tabsPreference,
    groupTabs,
    refetchAthleteProfileTabs,
    loading: loadingReports || loadingTabs,
  };
}
