import React from "react";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { Loader } from "../Loader";

const WidgetLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export function WidgetLoader() {
  return (
    <WidgetLoaderWrapper>
      <Loader size="large" color={COLOR_BLUE} />
    </WidgetLoaderWrapper>
  );
}
