import { AggregateFunction } from "../../../graphql";
import {
  isAcuteChronicCollection,
  isSoccerStatsCollection,
} from "../../../utils/reports";

const AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.LastDayInRange,
  AggregateFunction.Sum,
  AggregateFunction.Average,
  AggregateFunction.Min,
  AggregateFunction.Max,
];

const AC_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.Min,
  AggregateFunction.Max,
];

const SOCCER_AGGREGATIONS = [
  AggregateFunction.Latest,
  AggregateFunction.Min,
  AggregateFunction.Max,
  AggregateFunction.Sum,
  AggregateFunction.Average,
];

export const getAggregationFunctionsByItemId = (itemId: string) => {
  if (isAcuteChronicCollection(itemId)) {
    return AC_AGGREGATIONS;
  }

  if (isSoccerStatsCollection) {
    switch (itemId) {
      case "SoccerStat.GamesPlayed":
        return [AggregateFunction.Sum];
      case "SoccerStat.MinutesPerGame":
        return [AggregateFunction.Average];
      case "SoccerStat.CleanSheets":
        return [AggregateFunction.Sum, AggregateFunction.Average];
      case "SoccerStat.RedCards":
      case "SoccerStat.YellowCards":
        return [
          AggregateFunction.Latest,
          AggregateFunction.Sum,
          AggregateFunction.Average,
        ];
      default:
        return SOCCER_AGGREGATIONS;
    }
  }

  return AGGREGATIONS;
};
