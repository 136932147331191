import * as React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../colors";
import StyledText from "../StyledText";

const ChatAnnouncementOnlySectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 65px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  padding: 0 20px;
`;

interface ChatAnnouncementOnlySectionProps {
  showAnnouncementText?: boolean;
}

export function ChatAnnouncementOnlySection({
  showAnnouncementText,
}: ChatAnnouncementOnlySectionProps) {
  const { t } = useTranslation();

  return (
    <ChatAnnouncementOnlySectionWrapper>
      {showAnnouncementText ? (
        <StyledText fontWeight="bold" fontSize={14} color={COLOR_TEXT_DARK}>
          {t("announcementChannel")}
        </StyledText>
      ) : null}
      <StyledText
        fontSize={14}
        color={COLOR_TEXT_TABLE_LABEL}
        style={{ paddingTop: showAnnouncementText ? 5 : 0 }}
      >
        {t("doNotPostChannel")}
      </StyledText>
    </ChatAnnouncementOnlySectionWrapper>
  );
}
