import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { Avatar, Chatter } from "../../../graphql";
import { ChevronRightIcon } from "../../Icons";
import StyledText from "../../StyledText";

interface SeenByForGroupProps {
  allAvatarsInChat: Avatar[];
  contact: Chatter;
}

const SeenByWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 5px;
`;

export const SeenByForGroup = ({
  allAvatarsInChat,
  contact,
}: SeenByForGroupProps) => {
  const { t } = useTranslation();
  const { actions: modalActions } = useModalContext();

  return (
    <SeenByWrapper
      onClick={() =>
        modalActions?.openModal({
          modal: ModalType.SEEN_BY,
          title: t("seenBy"),
          params: {
            seenByAvatars: contact.seen.who,
            allAvatarsInChat,
          },
        })
      }
    >
      <StyledText fontWeight="semibold" fontSize={10} color={COLOR_MEDIUM_GRAY}>
        {allAvatarsInChat.length === contact.seen.who.length
          ? t("seenByEveryone")
          : t("seenBy")}
      </StyledText>
      <ChevronRightIcon />
    </SeenByWrapper>
  );
};
