import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_GRAY } from "../colors";
import { useNewChatContext } from "../contexts/messaging/NewChatProvider";
import { GetCreateNewChatGroupsQuery, Group, SubGroup } from "../graphql";

import { BasicButton } from "./Button";
import Dropdown from "./Dropdown";
import { GroupSubGroupItem } from "./GroupSubGroupItem";
import { ChevronRightIcon } from "./Icons";
import StyledText from "./StyledText";

interface GroupSubGroupSelectorProps {
  groups: GetCreateNewChatGroupsQuery["getTheGroupsImIn"];
}

const DropdownSelectComponent = styled(BasicButton)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  background: ${COLOR_GRAY};
  border-radius: 6px;
  padding: 15px;
  font-size: 14px;
`;

const DropdownArrowWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const StyledGroupName = styled.div`
  display: flex;
  justify-content: center;
  height: 45px;
`;

export const GroupSubGroupSelector = ({
  groups,
}: GroupSubGroupSelectorProps) => {
  const { t } = useTranslation();
  const {
    announce,
    forAllAutoAddedFriendsOfChatter,
    setAnnounce,
    selectedGroupId,
    setSelectedGroupId,
  } = useNewChatContext();

  const [parentGroupId, setParentGroupId] = useState("");
  const enforceToggleCallback = useRef<() => void>();

  const currentGroup =
    groups.find((group) => group.id === parentGroupId) ??
    groups.find((group) => group.id === announce?.id);
  const displayGroups = currentGroup ? [currentGroup] : [];

  const closeDropdown = useCallback(() => {
    if (
      enforceToggleCallback?.current &&
      typeof enforceToggleCallback.current === "function"
    ) {
      enforceToggleCallback.current();
    }
  }, []);

  return !currentGroup ? (
    <StyledGroupName>
      <StyledText fontWeight="bold">
        {forAllAutoAddedFriendsOfChatter?.name} - {t("everyone")}
      </StyledText>
    </StyledGroupName>
  ) : (
    <Dropdown
      component={({ toggle }) => {
        enforceToggleCallback.current = toggle;

        return (
          <DropdownSelectComponent onClick={toggle}>
            <StyledText fontWeight="bold">{announce.name}</StyledText>
            <DropdownArrowWrapper>
              <ChevronRightIcon direction="bottom" />
            </DropdownArrowWrapper>
          </DropdownSelectComponent>
        );
      }}
      dropdownContainerStyle={{
        top: 35,
        width: "100%",
        height: "calc(100% - 35px)",
      }}
      // @ts-ignore - due to height not matching dimensions type

      dropdownContentStyle={{
        padding: "10px",
        overflow: "scroll",
        height: "calc(100% - 10px)",
      }}
    >
      {displayGroups.map((group) => (
        <>
          <GroupSubGroupItem
            key={group.id}
            isMainGroup
            onClick={() => {
              setAnnounce(group as Group);
              setSelectedGroupId(group.id);
              closeDropdown();
            }}
            id={group.id}
            isChecked={selectedGroupId === group.id}
            name={group.name}
          />

          {group.subGroups?.map((subGroup) => (
            <GroupSubGroupItem
              key={subGroup.id}
              onClick={() => {
                setParentGroupId(group.id);
                setAnnounce(subGroup as SubGroup);
                setSelectedGroupId(subGroup.id);
                closeDropdown();
              }}
              isChecked={selectedGroupId === subGroup.id}
              name={subGroup.name}
            />
          ))}
        </>
      ))}
    </Dropdown>
  );
};
