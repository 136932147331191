import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const AttendedIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <>
      <svg
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        width={width}
        height={height}
      >
        {isActive ? (
          <>
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
            <circle cx="17.5" cy="17.5" r="12.5" fill="#77CC00" />
            <path
              d="M13.5 17.5L16.5 20.5L22.5 14.5"
              stroke="white"
              strokeWidth="1.5"
            />
          </>
        ) : (
          <>
            <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
            <circle cx="17.5" cy="17.5" r="12.5" stroke="#B1B7BC" />
            <path
              d="M13.5 17.5L16.5 20.5L22.5 14.5"
              stroke="#64707A"
              strokeWidth="2"
            />
          </>
        )}
      </svg>
    </>
  )
);
