import { AggregateFunction } from "../../../../../graphql";
import { CompareLatestTo } from "../../../../../services/statistics";
import { ComparisonBase } from "../../../models";

export const getAdditionalAggregations = (
  shouldLoadTrend: boolean
): AggregateFunction[] => {
  const additionalAggregations = [];

  if (shouldLoadTrend) {
    additionalAggregations.push(AggregateFunction.Each);
  }

  return additionalAggregations;
};

export const getCompareLatestToValue = (comparisonBase: ComparisonBase) => {
  switch (comparisonBase) {
    case ComparisonBase.DAY_BEFORE:
      return CompareLatestTo.DAY_BEFORE;
    case ComparisonBase.FIRST_IN_PERIOD:
      return CompareLatestTo.FIRST_IN_PERIOD;
    case ComparisonBase.LATEST:
    default:
      return CompareLatestTo.NEXT_BEFORE;
  }
};
