import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_GRAY_BORDER,
  COLOR_GRAY_SEPARATOR,
  COLOR_MEDIUM_GRAY,
  COLOR_WHITE,
} from "../../colors";
import { useModalContext } from "../../contexts/UI/Modal";
import { useWidgetTypes } from "../../utils/widgetTypes";
import { BasicButton } from "../Button";
import { NextIcon } from "../Icons";
import { useNavigateToWidgetDetail } from "../Report/hooks/useNavigateToWidgetDetail";
import { OptionItemText } from "../Report/styled";
import { WidgetMode } from "../Report/WidgetWithData";
import StyledText from "../StyledText";

const ContentContainer = styled.div`
  background-color: ${COLOR_GRAY};
  height: 100%;
  overflow: auto;
`;

const SectionWrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
`;

const WidgetRow = styled(BasicButton)`
  align-items: center;
  margin-bottom: 5px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid ${COLOR_GRAY_BORDER};
  background-color: ${COLOR_WHITE};
  min-height: 55px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${COLOR_GRAY_SEPARATOR};
  margin-bottom: 5px;
`;

export const CreateWidget = () => {
  const { t } = useTranslation();
  const { navigateToWidgetDetail } = useNavigateToWidgetDetail();
  const modal = useModalContext();

  const getWidgetTypes = useWidgetTypes();

  const onWidgetClick = useCallback((type, widgetId = "", mode) => {
    navigateToWidgetDetail({
      widget: { id: widgetId, type },
      mode,
    });
    modal.actions.closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentContainer>
      <SectionWrapper>
        <SectionTitle>{t("athlete")}</SectionTitle>
        {getWidgetTypes(WidgetMode.ATHLETE).map(({ label, type }) => (
          <WidgetRow
            key={`${WidgetMode.ATHLETE}-${type}`}
            onClick={() => onWidgetClick(type, "", WidgetMode.ATHLETE)}
          >
            <OptionItemText>{label}</OptionItemText>
            <NextIcon
              strokeWidth="2"
              style={{
                width: 15,
                height: 15,
                marginLeft: "auto",
                marginRight: 7,
              }}
            />
          </WidgetRow>
        ))}
      </SectionWrapper>
      <Separator />
      <SectionWrapper>
        <SectionTitle>{t("team")}</SectionTitle>
        {getWidgetTypes(WidgetMode.GROUP).map(({ label, type }) => (
          <WidgetRow
            key={`${WidgetMode.GROUP}-${type}`}
            onClick={() => onWidgetClick(type, "", WidgetMode.GROUP)}
          >
            <OptionItemText>{label}</OptionItemText>
            <NextIcon
              strokeWidth="2"
              style={{
                width: 15,
                height: 15,
                marginLeft: "auto",
                marginRight: 7,
              }}
            />
          </WidgetRow>
        ))}
      </SectionWrapper>
    </ContentContainer>
  );
};

const SectionTitle = styled(StyledText)`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-bottom: 10px;
  margin-left: 10px;
  text-transform: uppercase;
`;
