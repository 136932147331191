import React, { useState } from "react";

import { Slider, styled as muistyled, alpha } from "@mui/material";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../colors";
import { IntensityItem } from "../../../graphql";

import { assignColor, Difficulty } from "./HorizontalSlider";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0 15px 0;
  gap: 22px;
`;

const StyledMarkersLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -12.5px 0 -7.5px 0;
  font-family: "Inter", sans-serif;
  & div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const StyledMarkersRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: -12.5px 0 -7.5px 0;
  font-family: "Inter", sans-serif;
  gap: 16px;
  & div {
    display: flex;
    justify-content: flex-start;
  }
`;

const StyledSlider = muistyled(Slider)<{
  difficulty: Difficulty;
  height: number;
  observedValue: number;
  min: number;
  step: number;
}>(({ difficulty, height, observedValue, min, step }) => ({
  minHeight: "330px",
  height: height || "initial",
  width: "15px",
  borderRadius: "10px",
  color: assignColor(difficulty, observedValue, min, step),
  "& .MuiSlider-rail": {
    opacity: 1,
    color: COLOR_GREY_ACTIVE,
    margin: "-12.5px 0",
  },
  "& .MuiSlider-track": {
    margin: "-12.5px 0",
  },
  "& .MuiSlider-thumb": {
    color: COLOR_WHITE,
    width: "31px",
    height: "31px",
    boxShadow:
      "0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 9px rgba(0, 0, 0, 0.15)",
    "&:hover, &.Mui-focusVisible": {
      boxShadow: `0px 0px 0px 8px ${alpha(
        assignColor(difficulty, observedValue, min, step),
        0.16
      )}`,
    },
    "&.Mui-active": {
      boxShadow: `0px 0px 0px 14px ${alpha(
        assignColor(difficulty, observedValue, min, step),
        0.16
      )}`,
    },
  },
}));

type VerticalSliderProps = {
  difficulty?: Difficulty;
  height?: number;
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  intensityItems?: IntensityItem[];
};

export function VerticalSlider({
  difficulty,
  height,
  defaultValue,
  value = 0,
  onChange,
  min = 0,
  max = 9,
  step = 1,
  intensityItems,
}: VerticalSliderProps) {
  const [observedValue, setObservedValue] = useState(value);

  function handleChange(_, value): void {
    const val = parseInt(value, 10);
    setObservedValue(val as number);
    onChange?.(val);
  }

  return (
    <StyledWrapper>
      <StyledMarkersLeft>
        {intensityItems?.map((intensityItem) => (
          <div>{intensityItem.value}</div>
        ))}
      </StyledMarkersLeft>
      <StyledSlider
        difficulty={difficulty}
        height={height}
        observedValue={observedValue}
        orientation="vertical"
        onChange={handleChange}
        defaultValue={defaultValue}
        value={value}
        step={step}
        max={max}
        min={min}
      />
      <StyledMarkersRight>
        {intensityItems?.map((intensityItem) => (
          <div>{intensityItem.name}</div>
        ))}
      </StyledMarkersRight>
    </StyledWrapper>
  );
}
