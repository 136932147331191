import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useResourceCalendarContext } from "../../../contexts/resourceCalendar/ResourceCalendarProvider";
import { Resource } from "../../Calendar/model";

import { AvailableItem } from "./AvailableItem";
import { EmptyList } from "./EmptyList";

type AvailableResourceListProps = {
  localResourceList: Resource[];
  searchValue: string;
  onAddResource: (resource: Resource) => void;
};

export function AvailableResourceList({
  localResourceList,
  searchValue,
  onAddResource,
}: AvailableResourceListProps) {
  const { t } = useTranslation();
  const { resourceGroupList } = useResourceCalendarContext();

  const resourceList =
    resourceGroupList?.map((resource) => ({
      resourceId: resource.guid,
      resourceTitle: resource.name,
    })) ?? [];

  const localResourceIds = useMemo(
    () => localResourceList?.map((resource) => resource.resourceId),
    [localResourceList]
  );

  const resourceAvailableList = resourceList?.filter(
    (resource) =>
      !localResourceIds.includes(resource?.resourceId) &&
      resource?.resourceTitle
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase())
  );

  if (!resourceAvailableList?.length) {
    return <EmptyList label={t("noAvailableResourcesToAdd")} />;
  }

  return (
    <>
      {resourceAvailableList?.map((resource, index) => (
        <AvailableItem
          key={resource.resourceId}
          resource={resource}
          index={index}
          onAdd={() => onAddResource(resource)}
        />
      ))}
    </>
  );
}
