import { Field } from "formik";
import styled from "styled-components";

import StyledText from "../../StyledText";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 10px 15px 30px;
`;

export const SettingsErrorMessage = styled(StyledText)`
  margin-top: 30px;
  font-size: 12px;
  font-weight: bold;
`;

export const StyledField = styled(Field)`
  outline: 0;
  height: 45px;
  padding: 0 20px;
  border: 0;
  background-color: #f5f6f8;
  font-weight: bold;
  border-radius: 6px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
    font-size: 14px;
    font-weight: 300;
  }
  :-ms-input-placeholder {
    color: #808080;
    font-size: 14px;
    font-weight: 300;
  }
`;
