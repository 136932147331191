import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_GRAY, COLOR_WHITE } from "../../colors";
import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../../contexts/accessLevels";
import Avatar from "../Avatar";
import { BasicButton } from "../Button";
import Dropdown from "../Dropdown";
import { ChevronRightIcon } from "../Icons";
import StyledText from "../StyledText";

import { Accesslevels } from "./Accesslevels";

export const StyledWrapper = styled(BasicButton)<{
  isOpen?: boolean;
  withBorder?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 10px;
  min-height: 45px;
  border-radius: 6px;
  padding: 5px ${({ withBorder }) => (withBorder ? "0" : "10px")};
  background-color: ${({ isOpen, withBorder }) =>
    isOpen && !withBorder ? COLOR_GRAY : "transparent"};
`;

export const StyledChevronWrapper = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background: ${({ isOpen }) => (isOpen ? COLOR_BLUE : "transparent")};
`;

export const AccountNameWrapper = styled.div`
  cursor: pointer;
`;

export function AccessLevelDropdown() {
  const { t } = useTranslation();
  const { selectedAccount, isAllAccountOverrideActive } =
    useAccessLevelsContext();

  return (
    <Dropdown
      dropdownContainerStyle={{
        top: 35,
        right: 0,
        minWidth: 315,
      }}
      dropdownContentStyle={{
        padding: 10,
      }}
      component={({ toggle, show: isDropdownOpen }) => (
        <StyledWrapper onClick={toggle} isOpen={isDropdownOpen}>
          {selectedAccount?.id !== ALL_ACCOUNTS_ID &&
          !isAllAccountOverrideActive ? (
            <Avatar
              userGuid={selectedAccount?.id}
              isThumb={true}
              userName={selectedAccount?.name}
              size={35}
            />
          ) : (
            <div />
          )}
          <AccountNameWrapper>
            <StyledText fontSize={12} fontWeight="bold">
              {isAllAccountOverrideActive
                ? t("allAccounts")
                : selectedAccount?.name}
            </StyledText>
          </AccountNameWrapper>
          <StyledChevronWrapper isOpen={isDropdownOpen}>
            <ChevronRightIcon
              direction="bottom"
              tintColor={isDropdownOpen ? COLOR_WHITE : COLOR_BLUE}
            />
          </StyledChevronWrapper>
        </StyledWrapper>
      )}
    >
      <Accesslevels />
    </Dropdown>
  );
}
