import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import i18next from "i18next";

dayjs.extend(relativeTime);

export const areEdgesClose = (edge1, edge2) => {
  if (edge1 && edge2 && Math.abs(edge1.msg.sent - edge2.msg.sent) < 3600000) {
    return true;
  } else {
    return false;
  }
};

export const formatRelativeDate = (
  date: number,
  timeFormatTemplate: string
) => {
  const dateM = dayjs(date);
  if (dayjs().isSame(dateM, "day")) {
    return dateM.format(timeFormatTemplate);
  } else if (dayjs().isSame(dateM.add(1, "day"))) {
    return i18next.t("yesterday").toUpperCase();
  }

  const years = dayjs().diff(date, "y");
  if (years > 0) {
    return dayjs(date).fromNow();
  }

  return dayjs(date).format("D. MMM").toUpperCase();
};

export const isContentVisible = (edge1, edge2) =>
  (edge1 && edge2 && edge1.msg?.fromGuidS !== edge2.msg?.fromGuidS) ||
  !areEdgesClose(edge1, edge2);

export const restrictNewLines = (text: string | undefined | null) => {
  if (!text) {
    return text;
  }
  return text.replace(/\n\n+/g, "\n\n");
};
