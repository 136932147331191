import React from "react";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { Loader } from "../Loader";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
`;

export const ReportEditorLoading = () => {
  return (
    <LoaderContainer>
      <Loader size="large" color={COLOR_BLUE} />
    </LoaderContainer>
  );
};
