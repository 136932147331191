import { useCallback, useState } from "react";

import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { useIsReportSharedWithAthletesInGroupLazyQuery } from "../../graphql";

export const useGetIdsOfSharedReports = ({
  reportIds = [],
}: {
  reportIds: string[];
}) => {
  const { sessionId, isTrainer } = useUserContext();
  const [{ selectedGroup }] = useReportsContext();
  const [isLoading, setIsLoading] = useState(false);

  const [loadIsReportShared] = useIsReportSharedWithAthletesInGroupLazyQuery();

  // Just to minimize network load when there is only new array, not content
  const identityOfReportsToCheck = reportIds.join(",");

  const getIdsOfSharedTabReports = useCallback(async () => {
    if (selectedGroup?.id) {
      setIsLoading(true);

      const areReportsSharedResponses = await Promise.all(
        reportIds.map((reportId) =>
          loadIsReportShared({
            variables: { sessionId, groupId: selectedGroup.id, reportId },
            fetchPolicy: "network-only",
          })
        )
      );

      const sharedReportsIds = areReportsSharedResponses
        .map((response, index) => {
          if (response.data?.isReportSharedWithAthletesInGroup) {
            return reportIds[index];
          } else {
            return null;
          }
        })
        .filter((id) => Boolean(id));

      setIsLoading(false);

      return sharedReportsIds;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, selectedGroup?.id, identityOfReportsToCheck, isTrainer]);

  return { getIdsOfSharedTabReports, loading: isLoading };
};
