import { AxisPlotBandsOptions, DashStyleValue, Options } from "highcharts";

import {
  WidgetLimit,
  WidgetLimitCustom,
  WidgetLimitHighLow,
} from "../../../../contexts/Reports";
import { hex2rgba } from "../../../../utils/color";

import { GroupedCategoriesOptions } from "./generateChartOptions";

export const generateLimits = (
  limits: WidgetLimit[] = [],
  options: Options | GroupedCategoriesOptions
) => {
  // Limit plot line
  const plotLines = limits
    .filter((l) => l.type === "custom")
    .map((limit: WidgetLimitCustom) => ({
      value: Number(limit.value),
      color: limit.color,
      dashStyle: limit.stroke as DashStyleValue,
      width: 1,
      zIndex: 5,
    }));

  // High-Low plot bands
  const plotBands = limits
    .filter((l) => l.type === "highLow")
    .map(
      (limit: WidgetLimitHighLow) =>
        ({
          from: limit.lowValue,
          to: limit.highValue,
          color: hex2rgba(limit.color),
        }) as AxisPlotBandsOptions
    );

  // Custom plot lines
  if (
    Array.isArray(options.yAxis) &&
    options.yAxis.length &&
    options.yAxis[0]
  ) {
    options.yAxis[0].plotBands = plotBands;
    options.yAxis[0].plotLines = plotLines;
  }

  return options;
};
