import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import { useGetTheGroupsImInListQuery } from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks";
import { getSearchParamsObject } from "../../../utils/getSearchParamsObject";
import { ButtonColor, GeneralButton } from "../../Button/";
import Dropdown from "../../Dropdown";
import { HoverableItem } from "../../HoverableItem";
import { CreateItemDropdown } from "../../Report/CreateItemDropdown";
import StyledText from "../../StyledText";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  align-items: ${({ theme }) => (theme.isMobile ? "flex-start" : "center")};
  flex: 1;
  justify-content: space-between;
  padding: 15px 35px 15px 0;
  gap: ${({ theme }) => (theme.isMobile ? 8 : 0)}px;
  position: relative;
`;

const HeaderTitle = styled(StyledText)`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 20px;
  margin-right: 24px;
`;

const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) => (theme.isMobile ? "column" : "row")};
  gap: ${({ theme }) => (theme.isMobile ? 8 : 0)}px;
  position: relative;
`;

const StyledGeneralButton = styled(GeneralButton)`
  margin-right: 12px;
`;

export function DraggableModalHeader() {
  const { isMobile } = useTheme();
  const { t } = useTranslation();
  const [searchParams, setParams] = useSearchParams();
  const modal = useModalContext();
  const { sessionId, language } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { data: { getTheGroupsImIn: myGroups } = {} } =
    useGetTheGroupsImInListQuery({
      variables: {
        sessionId,
        requireEditability: true,
        language,
        accountId: selectedAccountId,
      },
      fetchPolicy: "cache-first",
      skip: !sessionId,
    });

  return (
    <HeaderWrapper>
      <HeaderTitle>{t("editTabs")}</HeaderTitle>
      <ButtonGroupWrapper>
        <Dropdown
          dropdownContainerStyle={{
            left: 0,
            width: 225,
            maxHeight: 300,
            marginRight: 12,
            marginTop: 35,
            padding: 0,
            overflow: "auto",
            boxShadow: "0px 2px 6px rgba(34, 62, 106, 0.05)",
          }}
          dropdownContentStyle={{
            padding: "5px 0",
            top: 0,
          }}
          component={({ toggle }) => (
            <StyledGeneralButton
              label={t("applyFromAnotherTeam")}
              colorVariant={ButtonColor.Gray}
              outlined
              onClick={toggle}
            />
          )}
        >
          {({ toggle }) => (
            <>
              {(myGroups || []).map((group) => (
                <HoverableItem
                  key={group.id}
                  label={group.name}
                  onClick={() => {
                    setParams({
                      ...getSearchParamsObject(searchParams),
                      groupId: group.id,
                    });
                    toggle((old) => !old);
                  }}
                  containerStyle={{
                    display: "block",
                    alignContent: "center",
                  }}
                />
              ))}
            </>
          )}
        </Dropdown>

        {!isMobile && (
          <CreateItemDropdown
            buttonLabel={t("createNew")}
            onOptionClick={modal.actions?.closeModal}
            hideCreateWidget
            isFromMonitoring
          />
        )}
      </ButtonGroupWrapper>
    </HeaderWrapper>
  );
}
