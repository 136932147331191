import React from "react";

import styled from "styled-components";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";

import { BasicButton } from "./Button";
import Hoverable from "./Hoverable";
import { TickIcon } from "./Icons";
import { RoundedGroupProfileIcon } from "./Icons/RoundedGroupProfileIcon";
import StyledText from "./StyledText";

export interface GroupOrSubGroupSelectItem {
  id?: string;
  name: string;
  isMainGroup?: boolean;
  isChecked?: boolean;
  onClick?: () => void;
}

const GroupSubGroupItemWrapper = styled(BasicButton)<{
  isHovered: boolean;
  isChecked: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  padding: 0 20px;
  margin-bottom: 5px;
  border-radius: 6px;
  background-color: ${({ isChecked, isHovered }) =>
    isChecked ? COLOR_BLUE : isHovered ? COLOR_GRAY : COLOR_WHITE};
`;

const IconTextWrapper = styled.div<{ isMainGroup: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({ isMainGroup }) => (isMainGroup ? 0 : 35)}px;
`;

const WrapperIcon = styled.div`
  margin-right: 10px;
`;

export function GroupSubGroupItem({
  name,
  isChecked,
  isMainGroup,
  onClick,
}: GroupOrSubGroupSelectItem) {
  return (
    <Hoverable>
      {(isHovered) => (
        <GroupSubGroupItemWrapper
          onClick={onClick}
          isChecked={isChecked}
          isHovered={isHovered}
        >
          <>
            <IconTextWrapper isMainGroup={isMainGroup}>
              {isMainGroup && (
                <WrapperIcon>
                  <RoundedGroupProfileIcon width={25} height={25} />
                </WrapperIcon>
              )}

              <StyledText
                color={isChecked ? COLOR_WHITE : COLOR_TEXT_DEFAULT}
                fontSize={14}
                fontWeight="bold"
              >
                {name}
              </StyledText>
            </IconTextWrapper>

            {isChecked && (
              <TickIcon
                tintColor={COLOR_WHITE}
                style={{ width: 25, height: 25 }}
              />
            )}
          </>
        </GroupSubGroupItemWrapper>
      )}
    </Hoverable>
  );
}
