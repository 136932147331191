import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { updateUnreadAll } from "../../../cacheUpdates/updateUnreadAll";
import { COLOR_GRAY, COLOR_GREY_ACTIVE } from "../../../colors";
import { useUserContext } from "../../../contexts/User";
import {
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryVariables,
  useMarkMessageReadMutation,
} from "../../../graphql";
import { MessageOverviewPaginated } from "../../../graphql/queries/MessageOverviewPaginated";
import { AllMessageOverviewData } from "../../../screens/MessagesScreen";
import Dropdown from "../../Dropdown";
import Hoverable from "../../Hoverable";
import { IconButton } from "../../IconButton";
import { ThreeDotsIcon } from "../../Icons";
import { Loader } from "../../Loader";
import { StyledOptionItem } from "../../Report/styled";
import StyledText from "../../StyledText";

const LoaderWrapper = styled.div`
  height: 45px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  threads: AllMessageOverviewData[] | null;
}

export const MessagesOptionsDropdown = ({ threads }: Props) => {
  const { t } = useTranslation();
  const { sessionId, timezone, language } = useUserContext();
  const [markRead, { loading: markingAsRead }] = useMarkMessageReadMutation();

  const contactsUnseenMessages = useMemo(
    () =>
      (threads ?? [])
        .filter((contact) => contact.unreadByMeInGroup)
        .map((contact) => contact.id),
    [threads]
  );

  const markAllAsRead = async () => {
    await markRead({
      variables: {
        sessionId,
        input: {
          markAllRead: true,
        },
      },
      update: (proxy) => {
        const variables = {
          sessionId,
          language,
          showAll: true,
          includeHidden: true,
          ignoreContactsWithoutChats: true,
        };
        const data = proxy.readQuery<
          MessageOverviewPaginatedQuery,
          MessageOverviewPaginatedQueryVariables
        >({
          query: MessageOverviewPaginated,
          variables,
        });

        if (data) {
          const modifiedEdges =
            data?.messageOverview?.contactsPaginated?.edges?.map((edge) => ({
              ...edge,
              node: {
                ...edge.node,
                unreadByMeInGroup: 0,
              },
            }));

          proxy.writeQuery<
            MessageOverviewPaginatedQuery,
            MessageOverviewPaginatedQueryVariables
          >({
            query: MessageOverviewPaginated,
            variables,
            data: {
              ...data,
              messageOverview: {
                ...data.messageOverview,
                contactsPaginated: {
                  ...data.messageOverview.contactsPaginated,
                  edges: modifiedEdges,
                },
              },
            },
          });
        }
        updateUnreadAll(proxy, { sessionId, timezone, language });
      },
    });
  };

  return (
    <Dropdown
      dropdownContainerStyle={{
        minWidth: 220,
      }}
      dropdownContentStyle={{
        top: 10,
        right: 10,
        padding: "5px 10px 5px 0",
      }}
      component={({ show, toggle }) => (
        <IconButton
          icon={<ThreeDotsIcon isActive={show} />}
          bgColor={COLOR_GRAY}
          hoverBgColor={COLOR_GREY_ACTIVE}
          ml={20}
          enforceActiveState={show}
          onClick={toggle}
        />
      )}
    >
      {({ toggle }) =>
        markingAsRead ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <Hoverable>
            {(isHovered) => (
              <StyledOptionItem
                isHovered={isHovered}
                height={45}
                onClick={async () => {
                  if (contactsUnseenMessages.length) {
                    await markAllAsRead();
                  }
                  toggle();
                }}
              >
                <StyledText fontWeight="bold" fontSize={14} numberOfLines={1}>
                  {t("markAllAsRead")}
                </StyledText>
              </StyledOptionItem>
            )}
          </Hoverable>
        )
      }
    </Dropdown>
  );
};
