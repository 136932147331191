import { ApolloCache } from "@apollo/client";

import {
  Chatter,
  MessageOverviewPaginatedQuery,
  MessageOverviewPaginatedQueryVariables,
  XpsUser,
} from "../../../graphql";
import { MessageList2 } from "../../../graphql/queries/MessageList2";
import { MessageOverviewPaginated } from "../../../graphql/queries/MessageOverviewPaginated";
import { isImage, isVideo } from "../../../utils/handleMedia";

type SendMessageCbGen = (
  _: any,
  au: XpsUser | Chatter,
  files: any[],
  attachments: any[]
) => Promise<any>;

export const createChatWithMsg = async (
  msg: any,
  attendingUsers: (XpsUser | Chatter)[],
  onSendMessage: SendMessageCbGen
) => {
  const {
    attachedFiles = [],
    messageBody,
    oldLinks = [],
    imageUrl,
    videoUrl,
  } = msg;
  const extendedAttachedFiles = attachedFiles.slice();
  if (imageUrl) {
    const { valid, mime } = isImage(imageUrl);
    if (valid) extendedAttachedFiles.push({ url: imageUrl, mime });
  }
  if (videoUrl) {
    const { valid, mime } = isVideo(videoUrl);
    if (valid) extendedAttachedFiles.push({ url: videoUrl, mime });
  }
  const attachments = oldLinks
    ? oldLinks.map((ol) => ({
        typeOfData: ol.type,
        guid: ol.thorsGuid.toString(),
      }))
    : [];
  const files = [];
  if (extendedAttachedFiles) {
    await Promise.all(
      extendedAttachedFiles.map(async (a) => {
        const nameArr = a.url.split("/");
        const localName = nameArr[nameArr.length - 1];
        const url = a.url;
        if (url) files.push({ localName, mime: a.mime, url });
      })
    );
  }
  return Promise.all(
    attendingUsers.map(async (au) => {
      const newContact = {
        ...au,
        lastChatTime: Date.now(),
        lastMessage: msg ? messageBody : ``,
        hidden: false,
        muted: false,
        onlyTrainersCanPost: false,
        canLoggedOnUserAddParticipants: false,
        canLoggedOnUserRemoveParticipants: false,
        canLoggedOnUserEditName: false,
        unreadByMeInGroup: 0,
        canPost: true,
        allFriendsOf: null,
        linkedGroup: null,
        whoCanPost: null,
      };
      await onSendMessage(newContact, au, files, attachments);
    })
  );
};

export const sendMessageUpdateCb = (
  sessionId: string,
  language: any,
  newContact: any,
  au: any
) => {
  return (proxy: ApolloCache<any>, { data: { saveChatMessage } }) => {
    const variables = {
      sessionId,
      language,
      showAll: true,
      includeHidden: true,
      ignoreContactsWithoutChats: true,
    };
    const data = proxy.readQuery<
      MessageOverviewPaginatedQuery,
      MessageOverviewPaginatedQueryVariables
    >({
      query: MessageOverviewPaginated,
      variables,
    });

    const currentContact = data.messageOverview?.contactsPaginated?.edges?.find(
      (edge) => edge?.node?.id === newContact.id
    );

    const otherContacts =
      data?.messageOverview?.contactsPaginated?.edges?.filter(
        (edge) => edge?.node?.id !== newContact.id
      );

    let allContacts = [];

    if (currentContact?.node && currentContact?.node?.lastChatTime) {
      allContacts = [
        { ...currentContact, node: { ...currentContact?.node, ...newContact } },
        ...otherContacts,
      ];
    } else {
      delete newContact.groupOwner;
      delete newContact.jointMainGroups;
      delete newContact.parentGroupName;
      delete newContact.chatterType2;
      allContacts = [{ node: newContact, cursor: "" }, ...otherContacts];
    }

    proxy.writeQuery<
      MessageOverviewPaginatedQuery,
      MessageOverviewPaginatedQueryVariables
    >({
      query: MessageOverviewPaginated,
      variables,
      data: {
        messageOverview: {
          ...data.messageOverview,
          contactsPaginated: {
            ...data.messageOverview?.contactsPaginated,
            edges: allContacts,
          },
        },
      },
    });

    try {
      const messagesData: any = proxy.readQuery({
        query: MessageList2,
        variables: {
          sessionId,
          groupOrFriend: au.id as string,
          first: 20,
        },
      });
      if (messagesData?.messageList2?.messages2?.edges?.length) {
        const newMessages = messagesData.messageList2.messages2.edges.slice();
        newMessages.push(saveChatMessage);
        proxy.writeQuery({
          query: MessageList2,
          variables: {
            sessionId,
            groupOrFriend: au.id as string,
            first: 20,
          },
          data: {
            messageList2: {
              ...messagesData.messageList2,
              messages2: {
                ...messagesData.messageList2.messages2,
                edges: [
                  ...messagesData.messageList2.messages2.edges,
                  {
                    msg: saveChatMessage,
                    cursor: null,
                  },
                ],
              },
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const createGroupUpdateCb = (
  sessionId: string,
  language: any,
  groupName: string,
  cleanUp?: (_: any) => void,
  noCacheFallback?: () => void
) => {
  return async (proxy: ApolloCache<any>, { data: { createChatGroup } }) => {
    const overviewVariables = {
      sessionId,
      language,
      showAll: true,
      includeHidden: true,
      ignoreContactsWithoutChats: true,
    };

    const newContact = {
      ...createChatGroup,
      lastChatTime: Date.now(),
      allFriendsOf: null,
    };

    const data = getMessageOverviewCache(proxy, overviewVariables);

    if (data) {
      await handleChatCacheAfterCreateGroupChat(
        data,
        newContact,
        groupName,
        overviewVariables,
        cleanUp,
        proxy
      );
    } else {
      await noCacheFallback?.();
      const dataAfterRefetch = getMessageOverviewCache(
        proxy,
        overviewVariables
      );
      await handleChatCacheAfterCreateGroupChat(
        dataAfterRefetch,
        newContact,
        groupName,
        overviewVariables,
        cleanUp,
        proxy
      );
    }
  };
};

const getMessageOverviewCache = (
  proxy: ApolloCache<any>,
  overviewVariables
) => {
  return proxy.readQuery<
    MessageOverviewPaginatedQuery,
    MessageOverviewPaginatedQueryVariables
  >({
    query: MessageOverviewPaginated,
    variables: overviewVariables,
  });
};

const handleChatCacheAfterCreateGroupChat = async (
  data,
  newContact,
  groupName,
  overviewVariables,
  cleanUp,
  proxy: ApolloCache<any>
) => {
  const currentContact = data?.messageOverview?.contactsPaginated?.edges?.find(
    (edge) => edge?.node?.id === newContact.id
  );

  const otherContacts = data?.messageOverview?.contactsPaginated?.edges?.filter(
    (edge) => edge?.node?.id !== newContact.id
  );

  const newData = {
    ...data.messageOverview,
    contactsPaginated: {
      ...data.messageOverview?.contactsPaginated,
      edges: [
        ...(currentContact?.node?.id
          ? [
              {
                ...currentContact,
                node: { ...currentContact.node, ...newContact },
              },
            ]
          : [
              {
                node: { ...newContact },
                cursor: "",
              },
            ]),
        ...otherContacts,
      ],
    },
  };

  proxy.writeQuery<
    MessageOverviewPaginatedQuery,
    MessageOverviewPaginatedQueryVariables
  >({
    query: MessageOverviewPaginated,
    variables: overviewVariables,
    data: { messageOverview: newData },
  });

  cleanUp?.(newContact);
};
