import React, { ReactNode } from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_DARK } from "../../../colors";

const TagWrapper = styled.div<{ withMargin: boolean }>`
  display: flex;
  background-color: ${COLOR_GREY_ACTIVE};
  align-items: center;
  border-radius: 12px;
  padding: 2px 5px;
  margin-right: 2px;
  margin-left: ${({ withMargin }) => (withMargin ? 8 : 0)}px;
`;

const TagText = styled.span`
  color: ${COLOR_TEXT_DARK};
  font-weight: 700;
  font-size: 8px;
  line-height: normal;
`;

export const ShortNameTag = ({
  children,
  withMargin = false,
}: {
  children: ReactNode;
  withMargin?: boolean;
}) => (
  <TagWrapper withMargin={withMargin}>
    <TagText>{children}</TagText>
  </TagWrapper>
);
