import {
  ALL_ACCOUNTS_ID,
  useAccessLevelsContext,
} from "../contexts/accessLevels";
import { useReportsContext } from "../contexts/Reports";

export const useGetSelectedAccountIdInMonitoring = () => {
  const { selectedAccount } = useAccessLevelsContext();
  const [{ selectedGroup }] = useReportsContext();

  return selectedAccount?.id === ALL_ACCOUNTS_ID
    ? selectedGroup?.owner?.id
    : selectedAccount?.id;
};
