import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";
import styled from "styled-components";

import { COLOR_MEDIUM_GRAY } from "../../../../colors";
import { useReportsContext } from "../../../../contexts/Reports";
import {
  AggregateFunction,
  MeasurementTemplateForReports,
  ReportItemType,
} from "../../../../graphql";
import { useHasSoccer } from "../../../../hooks/useHasSoccer";
import { CompareLatestTo } from "../../../../services";
import {
  isAttendanceCollection,
  isCatapultCollection,
  isCustomTextSoccerAttribute,
  isDrillCollection,
  isExerciseSetCollection,
  isFirstbeatCollection,
  isGarminCollection,
  isKinexonCollection,
  isPlayertecCataplutCollection,
  isPolarCollection,
  isPubgCollection,
  isSoccerStatsCollection,
  isTestCollection,
  isTrainingLoadCollection,
  isXpsMagicCollection,
} from "../../../../utils/reports";
import { Checkbox } from "../../../Checkbox";
import { IconButton } from "../../../IconButton";
import { BinIcon, DuplicateIcon, ReorderIcon } from "../../../Icons";
import {
  IconButtonWrapper,
  ReorderImageWrapper,
} from "../../../ReportCreateTable/styled";
import { SelectField } from "../../../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../../../styled";
import { StyledTextInput } from "../../../StyledTextInput";
import { DragHandlePropsType } from "../../../Wrappers/DragDropContainer";
import { DataSourceSelectorRow } from "../../DataSourceSelector";
import {
  KeyValuesWidgetFormDataItem,
  KeyValuesWidgetFormValue,
} from "../models";
import { getAggregationOptions } from "../utils";

type KeyValuesDataItemSectionProps = {
  rowIndex: number;
  remove(push: number): void;
  push(item: KeyValuesWidgetFormDataItem): void;
  dragHandleProps: DragHandlePropsType;
  isGroupWidget?: boolean;
};

const ReorderWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

const RowWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DataSourceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const OtherFiledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const AggregationFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export function KeyValuesDataItemSection({
  rowIndex,
  dragHandleProps,
  remove,
  push,
  isGroupWidget,
}: KeyValuesDataItemSectionProps) {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } =
    useFormikContext<KeyValuesWidgetFormValue>();
  const [{ aggregations }] = useReportsContext();
  const hasSoccer = useHasSoccer();

  const currentRow = values.items[rowIndex];

  const aggregationOptions = (
    getAggregationOptions(currentRow, isGroupWidget, aggregations) ?? []
  )
    .map((aggregation) => ({
      label: t(`aggregation${aggregation}`, { defaultValue: aggregation }),
      value: aggregation,
    }))
    .filter(({ value }) => value !== AggregateFunction.Each);

  const clone = useCallback(() => {
    push(currentRow);
  }, [currentRow, push]);

  const onChangeRowValues = useCallback(
    (newValues: Partial<typeof currentRow>) => {
      const newRow = { ...currentRow, ...newValues };
      const supportedAggregations = getAggregationOptions(
        newRow,
        isGroupWidget,
        aggregations
      );

      const isSupportedAggregate = supportedAggregations.some(
        (supported) => supported === newRow.aggregateFunction
      );

      if (!isSupportedAggregate) {
        newRow.aggregateFunction = undefined;
      }

      setFieldValue(`items[${rowIndex}]`, newRow);
    },
    [currentRow, isGroupWidget, aggregations, setFieldValue, rowIndex]
  );

  const filterOutCustomSoccerTextMeasurements = useCallback(
    (item: MeasurementTemplateForReports) =>
      !isCustomTextSoccerAttribute(item.id),
    []
  );

  const hideAggregationSelect =
    isAttendanceCollection(currentRow?.collectionId) ||
    isXpsMagicCollection(currentRow.collectionId);

  const hasCompareToLatestOptions =
    currentRow.aggregateFunction === AggregateFunction.Latest &&
    (isExerciseSetCollection(currentRow.collectionId) ||
      isTestCollection(currentRow.collectionId) ||
      isPolarCollection(currentRow.collectionId) ||
      isGarminCollection(currentRow.collectionId) ||
      isKinexonCollection(currentRow.collectionId) ||
      isFirstbeatCollection(currentRow.collectionId) ||
      isPlayertecCataplutCollection(currentRow.collectionId) ||
      isCatapultCollection(currentRow.collectionId) ||
      isPubgCollection(currentRow.collectionId) ||
      isTrainingLoadCollection(currentRow.collectionId) ||
      isDrillCollection(currentRow.collectionId));

  return (
    <DraggableLineWrapper style={{ alignItems: "flex-start" }}>
      <ReorderWrapper>
        <ReorderImageWrapper {...dragHandleProps}>
          <ReorderIcon />
        </ReorderImageWrapper>
      </ReorderWrapper>

      <RowWrapper>
        <DataSourceWrapper>
          <DataSourceSelectorRow
            currentDataSource={currentRow}
            dataSourceArrayItemAccessor={`items[${rowIndex}]`}
            onChangeDataSourceValues={onChangeRowValues}
            customVisibleMeasurementsFilter={
              isGroupWidget ? filterOutCustomSoccerTextMeasurements : undefined
            }
            allowedReportItemTypes={[
              ReportItemType.Test,
              ReportItemType.Exercise,
              ReportItemType.Drill,
              ReportItemType.TrainingLoad,
              ReportItemType.PlayertekCatapult,
              ReportItemType.Catapult,
              ReportItemType.Polar,
              ReportItemType.Garmin,
              ReportItemType.Kinexon,
              ReportItemType.Firstbeat,
              ReportItemType.Statsports,
              ReportItemType.Pubg,
              ReportItemType.Attendance,
              ...(hasSoccer ? [ReportItemType.SoccerStat] : []),
              ...(!isGroupWidget
                ? [ReportItemType.Information, ReportItemType.XpsMagic]
                : []),
            ]}
          />
        </DataSourceWrapper>
        <AggregationFieldWrapper>
          {!hideAggregationSelect && (
            <FieldWrapper>
              <Field
                name={`items[${rowIndex}].aggregateFunction`}
                component={SelectField}
                options={aggregationOptions}
              />
            </FieldWrapper>
          )}
          {!isGroupWidget &&
            isAttendanceCollection(currentRow.collectionId) && (
              <FieldWrapper style={{ justifyContent: "center" }}>
                <Checkbox
                  text={t("allTeams")}
                  check={(checked) => {
                    onChangeRowValues({
                      forAllGroups: checked,
                    });
                  }}
                  checked={currentRow.forAllGroups}
                  withMargin={false}
                />
              </FieldWrapper>
            )}
          <FieldWrapper noMargin>
            <StyledTextInput
              name={`items[${rowIndex}].customTitle`}
              value={currentRow?.customTitle}
              onChange={handleChange}
              placeholder={t("overrideCellName")}
            />
          </FieldWrapper>
        </AggregationFieldWrapper>
        {!isGroupWidget && isSoccerStatsCollection(currentRow.collectionId) && (
          <OtherFiledWrapper>
            <FieldWrapper style={{ justifyContent: "center" }}>
              <Checkbox
                text={t("allTeams")}
                check={(checked) => {
                  onChangeRowValues({
                    forAllGroups: checked,
                  });
                }}
                checked={currentRow.forAllGroups}
              />
            </FieldWrapper>
          </OtherFiledWrapper>
        )}
        {hasCompareToLatestOptions && (
          <OtherFiledWrapper>
            <FieldWrapper>
              <Checkbox
                text={t("keyValuesCompareToNextBefore")}
                check={(checked) => {
                  onChangeRowValues({
                    compareLatestTo: checked
                      ? CompareLatestTo.NEXT_BEFORE
                      : undefined,
                  });
                }}
                checked={
                  currentRow.compareLatestTo === CompareLatestTo.NEXT_BEFORE
                }
              />
            </FieldWrapper>
            <FieldWrapper noMargin>
              <Checkbox
                text={t("keyValuesCompareToFirst")}
                check={(checked) => {
                  onChangeRowValues({
                    compareLatestTo: checked
                      ? CompareLatestTo.FIRST_IN_PERIOD
                      : undefined,
                  });
                }}
                checked={
                  currentRow.compareLatestTo === CompareLatestTo.FIRST_IN_PERIOD
                }
              />
            </FieldWrapper>
          </OtherFiledWrapper>
        )}
      </RowWrapper>

      <IconButtonWrapper mt={6}>
        <IconButton
          icon={<DuplicateIcon tintColor={COLOR_MEDIUM_GRAY} />}
          onClick={clone}
        />
        {values?.items?.length > 1 && (
          <IconButton icon={<BinIcon />} onClick={() => remove(rowIndex)} />
        )}
      </IconButtonWrapper>
    </DraggableLineWrapper>
  );
}
