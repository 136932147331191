import React, { CSSProperties } from "react";

import styled from "styled-components";

const DEFAULT_LOADER_COLOR = "#1976d2";

export interface LoaderProps {
  animating?: boolean;
  color?: string;
  size?:
    | "small"
    | "large"
    | React.CSSProperties["width"]
    | React.CSSProperties["height"];
  style?: CSSProperties;
}

const getSize = (size: LoaderProps["size"] = "small") => {
  if (typeof size === "number") {
    return `${size}px`;
  }

  switch (size) {
    case "small":
      return "20px";
    case "large":
      return "36px";
    default:
      return size;
  }
};
const getBorderSize = (size: LoaderProps["size"] = "small") => {
  if (typeof size === "number") {
    return `${size / 8}px`;
  }

  switch (size) {
    case "small":
      return "3px";
    case "large":
      return "5px";
    default:
      return size;
  }
};

export const LoaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
`;

const Spinner = styled.div<LoaderProps>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border-style: solid;
  border-color: ${({ color = DEFAULT_LOADER_COLOR }) => `${color}33`};
  border-bottom-color: ${({ color = DEFAULT_LOADER_COLOR }) => color};
  border-width: ${({ size }) => getBorderSize(size)};
  animation: spin 0.75s infinite linear;
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = ({ style, ...props }: LoaderProps) => {
  const animating = props.animating ?? true;
  return (
    <Wrapper style={style} {...props}>
      {animating && <Spinner {...props} />}
    </Wrapper>
  );
};
