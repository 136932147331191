import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const RedMinusIcon = memo(
  ({ width = 21, height = 21, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="10.5" cy="10.5" r="10.5" fill="#FF6562" />
      <path d="M5 10.5H16" stroke="white" />
    </svg>
  )
);
