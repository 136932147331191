import React, { ChangeEvent, useCallback, useMemo } from "react";

import _debounce from "lodash/debounce";
import styled from "styled-components";

import {
  COLOR_GRAY,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../colors";
import { Cell } from "../../../graphql";

import { shouldBePlaceholderSplit } from "./utils";

type TableInputProps = {
  cell: Cell;
  onCellUpdated: (cell: Cell, newText: string) => void;
};

const Input = styled.input`
  width: inherit;
  border: none;
  background-color: ${COLOR_GRAY};
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  padding: 5px 0;
  width: 65px;
`;

const Text = styled.div`
  font-size: 8px;
  font-weight: 700;
  color: ${COLOR_TEXT_TABLE_LABEL};
`;

const Wrapper = styled.div<{ hasPlaceholder: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ hasPlaceholder }) => (hasPlaceholder ? 10 : 0)}px;
  margin-right: 15px;
`;

export function TableInput({ cell, onCellUpdated }: TableInputProps) {
  const hasMultipleValues = shouldBePlaceholderSplit(cell);
  const placeholder = hasMultipleValues
    ? cell?.valuePrescribed?.split(" ")
    : [cell?.valuePrescribed];

  const debouncedUpdate = useMemo(
    () =>
      _debounce((newText: string) => {
        onCellUpdated(cell, newText);
      }, 500),
    [onCellUpdated, cell]
  );

  const onChangeText = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      debouncedUpdate(event.target.value),
    [debouncedUpdate]
  );

  const hasPlaceholder = hasMultipleValues && Boolean(placeholder?.[1]);

  return (
    <Wrapper hasPlaceholder={hasPlaceholder}>
      <Input
        defaultValue={cell?.valuePerformed}
        value={cell?.valuePerformed}
        placeholder={placeholder?.[0]}
        onChange={onChangeText}
      />
      {hasPlaceholder ? <Text>{placeholder[1]}</Text> : null}
    </Wrapper>
  );
}
