import React from "react";
import { useTranslation } from "react-i18next";

import { COLOR_BLUE_ATTENDANCE_BG } from "../../colors";
import {
  DataRow,
  HeaderLikeLabel,
  SummaryStatsTd,
} from "../TablePeriodization/Widget";

export function SummaryStatsItem() {
  const { t } = useTranslation();

  const STAT_CATEGORIES = [
    { id: "average", title: t("average").toUpperCase() },
    { id: "stdDev", title: t("stdDev").toUpperCase() },
  ];

  return (
    <>
      {STAT_CATEGORIES.map((category) => (
        <DataRow
          key={category.id}
          style={{
            backgroundColor: COLOR_BLUE_ATTENDANCE_BG,
            display: "flex",
            alignItems: "center",
          }}
        >
          <SummaryStatsTd>
            <HeaderLikeLabel>{category.title.toUpperCase()}</HeaderLikeLabel>
          </SummaryStatsTd>
        </DataRow>
      ))}
    </>
  );
}
