import React, { createContext, useReducer } from "react";

import actionsCreator from "../../../utils/actionsCreator";

import * as actionsForm from "./actions";
import reducer from "./reducers";

const initialValue = {
  open: true,
  actions: {
    open: () => {},
    close: () => {},
  },
};

export const SidebarContext = createContext<{
  open: boolean;
  actions: { open(): void; close(): void };
}>(initialValue);

export const SidebarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const actions = actionsCreator(actionsForm, dispatch);

  return (
    <SidebarContext.Provider value={{ ...state, actions }}>
      {children}
    </SidebarContext.Provider>
  );
};
