import React from "react";
import { useTranslation } from "react-i18next";

import { Formik } from "formik";

import { Header } from "../components/Header";
import {
  TimeLine,
  TimeLinePeriod,
} from "../components/Report/ChartWIdget/model";
import {
  SummaryStatsAlignment,
  TablePeriodizationForm,
} from "../components/Report/PeriodizationTable/model";
import { PeriodSection } from "../components/Report/PeriodSection";
import { PreviewButtonSection } from "../components/Report/PreviewButtonSection";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import { FormContainer, FormWrapper } from "../components/Report/styled";
import { WidgetEditorTitleSection } from "../components/Report/WidgetEditorTitleSection";
import { WidgetType } from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { BackgroundColoringSection } from "../components/SharedWidgetEditorSections/BackgroundColoringSection";
import {
  TablePeriodizationAppearanceSection,
  TablePeriodizationDataSelectionSection,
  TablePeriodizationWhatToShowSection,
  TablePeriodizationSortingSection,
  TablePeriodizationSummarySection,
} from "../components/TablePeriodization/Editor";
import { TablePeriodizationTablePreview } from "../components/TablePeriodization/Widget";
import { useUserContext } from "../contexts/User";
import {
  useSaveWidgetTemplateSchalkeTeamTableMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport, useNavigateToPreviousPage } from "../hooks";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import { PeriodType } from "../models";
import {
  buildPreferencesString,
  parseGroupTablePeriodizationPreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export function GroupTablePeriodizationEditorScreen() {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const redirectToPreviousPage = useNavigateToPreviousPage();
  const [saveWidget] = useSaveWidgetTemplateSchalkeTeamTableMutation();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const {
    reportId,
    widgetId,
    parentReportId,
    editReportWidget,
    isWidgetTemplate,
    layoutIndex,
  } = useWidgetEditorUrlParams();

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );

  const { data: templateData, loading: loadingTeamTableData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const initialFormValues: TablePeriodizationForm =
    widgetId && templateData
      ? {
          // @ts-ignore
          name: templateData.reportWidgetTemplateById?.name,
          columnHeaders:
            templateData.reportWidgetTemplateById?.__typename ===
            "ReportWidgetTemplateSchalkeTeamTable"
              ? [
                  {
                    collectionId:
                      templateData.reportWidgetTemplateById?.measurementTemplate
                        ?.collectionId,
                    itemId:
                      templateData.reportWidgetTemplateById?.measurementTemplate
                        ?.templateId,
                    attributeTemplateId:
                      templateData.reportWidgetTemplateById?.measurementTemplate
                        ?.attributeTemplate?.id,
                    aggregateFunctions:
                      templateData.reportWidgetTemplateById?.measurementTemplate
                        ?.aggregateFunctions,
                  },
                ]
              : [],
          ...parseGroupTablePeriodizationPreferencesObject(
            templateData.reportWidgetTemplateById?.__typename ===
              "ReportWidgetTemplateSchalkeTeamTable"
              ? templateData.reportWidgetTemplateById?.preferences
              : "{}"
          ),
        }
      : {
          name: "",
          columnHeaders: [],
          timeLine: TimeLine.CONTINUOUS,
          timeLinePeriod: TimeLinePeriod.NONE,
          coloringConfig: [],
          showSummaryStats: false,
          summaryStatsAlignment: SummaryStatsAlignment.TOP,
          sortOrder: -1,
          verticalHeader: true,
          showValuesAsText: false,
          period: {
            type: PeriodType.SELECTION,
            from: null,
            to: null,
            isTodaySetAsEnd: false,
          },
          ignoreColumnsWithNoData: false,
        };

  const onSubmit = async (values: TablePeriodizationForm) => {
    try {
      const response = await saveWidget({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              values.name ||
              (isWidgetTemplate ? t("defaultGroupTableWidgetName") : ""),
            reportTemplateId: reportId || parentReportId,
            template: {
              collectionId: values.columnHeaders[0].collectionId,
              itemId: values.columnHeaders[0].itemId,
              attributeTemplateId: values.columnHeaders[0].attributeTemplateId,
              aggregateFunction: values.columnHeaders[0].aggregateFunctions[0],
            },
            preferences: buildPreferencesString({
              type: WidgetType.GROUP_TABLE_PERIODIZATION,
              period: values.period,
              timeLine: values.timeLine,
              timeLinePeriod: values.timeLinePeriod,
              coloringConfig: values.coloringConfig,
              showSummaryStats: values.showSummaryStats,
              summaryStatsAlignment: values.summaryStatsAlignment,
              sortOrder: values.sortOrder,
              verticalHeader: values.verticalHeader,
              showValuesAsText: values.showValuesAsText,
              ignoreColumnsWithNoData: values.ignoreColumnsWithNoData,
            }),
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateSchalkeTeamTable?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateSchalkeTeamTable.id
        );
      }

      redirectToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  if (loadingTeamTableData) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <Formik<TablePeriodizationForm>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, setFieldValue, submitForm }) => (
          <FormContainer>
            <FormWrapper>
              <WidgetEditorTitleSection
                value={values.name}
                name="name"
                handleChange={handleChange}
              />
              <TablePeriodizationWhatToShowSection isGroup />
              <TablePeriodizationDataSelectionSection isGroup />
              <PeriodSection
                setFieldValue={setFieldValue}
                period={values.period}
                withDynamicSelection
                showCalendarAboveInput={false}
                sectionZIndex={100}
              />
              <BackgroundColoringSection isGroup />
              <TablePeriodizationSummarySection />
              <TablePeriodizationSortingSection />
              <TablePeriodizationAppearanceSection />
            </FormWrapper>
            <PreviewButtonSection
              isSaving={false}
              withAutoWidth
              onCancelClick={redirectToPreviousPage}
              onSaveClick={submitForm}
            >
              <TablePeriodizationTablePreview />
            </PreviewButtonSection>
          </FormContainer>
        )}
      </Formik>
    </ScreenContainer>
  );
}
