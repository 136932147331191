import { CSSObjectWithLabel, StylesConfig } from "react-select";

import {
  COLOR_BLUE,
  COLOR_GRAY,
  COLOR_MEDIUM_GRAY,
  COLOR_SELECT_BORDER,
  COLOR_TEXT_DARK,
  COLOR_TEXT_DEFAULT,
  COLOR_WHITE,
} from "../colors";

export const textStylesOverrides = {
  container: {
    borderWidth: 0,
    flex: 1,
    overflow: "visible",
    width: "100%",
  },
  control: {
    backgroundColor: COLOR_GRAY,
    fontWeight: 700,
  },
  singleValue: {
    color: COLOR_TEXT_DARK,
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  menu: {
    paddingBottom: 0,
    paddingTop: 0,
  },
};

export function getFieldSelectStyles(
  stylesOverrides: {
    container?: CSSObjectWithLabel;
    control?: CSSObjectWithLabel;
    dropdownIndicator?: CSSObjectWithLabel;
    singleValue?: CSSObjectWithLabel;
    valueContainer?: CSSObjectWithLabel;
    menu?: CSSObjectWithLabel;
    option?: CSSObjectWithLabel;
    input?: CSSObjectWithLabel;
  } = {}
): StylesConfig {
  return {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? COLOR_WHITE : COLOR_TEXT_DEFAULT,
      backgroundColor: state.isSelected
        ? COLOR_BLUE
        : state.isFocused
          ? COLOR_GRAY
          : COLOR_WHITE,
      fontWeight: 700,
      fontSize: 12,
      lineHeight: "15px",
      padding: "10px 15px",
      ...stylesOverrides.option,
    }),
    menu: (provided) => ({
      ...provided,
      paddingTop: 5,
      paddingBottom: 5,
      ...stylesOverrides.menu,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    container: (provided) => ({
      ...provided,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: COLOR_GRAY,
      borderRadius: 6,
      overflow: "hidden",
      ...stylesOverrides.container,
    }),
    control: (provided) => ({
      ...provided,
      height: 45,
      paddingLeft: 15,
      paddingRight: 10,
      backgroundColor: COLOR_GRAY,
      border: "none",
      boxShadow: "none",
      ...stylesOverrides.control,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: "Inter",
      fontWeight: "700",
      fontSize: 14,
      lineHeight: "17px",
      margin: 0,
      paddingRight: 10,
      color: COLOR_TEXT_DEFAULT,
      ...stylesOverrides.singleValue,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      ...stylesOverrides.valueContainer,
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
      ...stylesOverrides.input,
    }),
  };
}

export function getSelectStyles(
  stylesOverrides: {
    container?: CSSObjectWithLabel;
    control?: CSSObjectWithLabel;
    dropdownIndicator?: CSSObjectWithLabel;
    singleValue?: CSSObjectWithLabel;
    valueContainer?: CSSObjectWithLabel;
    menu?: CSSObjectWithLabel;
    menuList?: CSSObjectWithLabel;
    option?: CSSObjectWithLabel;
    placeholder?: CSSObjectWithLabel;
    indicatorsContainer?: CSSObjectWithLabel;
  } = {}
): StylesConfig {
  return {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? COLOR_WHITE : COLOR_TEXT_DEFAULT,
      backgroundColor: state.isSelected
        ? COLOR_BLUE
        : state.isFocused
          ? COLOR_GRAY
          : COLOR_WHITE,
      fontWeight: 700,
      fontSize: 12,
      lineHeight: "15px",
      padding: "10px 15px",
      cursor: "pointer",
      ...stylesOverrides.option,
    }),
    menu: (provided) => ({
      ...provided,
      paddingTop: 5,
      paddingBottom: 5,
      ...stylesOverrides.menu,
    }),
    container: (provided) => ({
      ...provided,
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: COLOR_SELECT_BORDER,
      borderRadius: 6,
      overflow: "hidden",
      ...stylesOverrides.container,
    }),
    control: (provided) => ({
      ...provided,
      height: 45,
      paddingLeft: 15,
      paddingRight: 10,
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      ...stylesOverrides.control,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Inter",
      fontWeight: "700",
      fontSize: 14,
      lineHeight: "17px",
      margin: 0,
      paddingRight: 10,
      color: COLOR_TEXT_DEFAULT,
      ...stylesOverrides.singleValue,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      ...stylesOverrides.valueContainer,
    }),
    input: (provided) => ({
      ...provided,
      margin: 0,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 0,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      ...stylesOverrides.indicatorsContainer,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 10000 }),
    menuList: (base) => ({ ...base, ...stylesOverrides.menuList }),
    placeholder: (base) => ({ ...base, ...stylesOverrides.placeholder }),
    group: (provided) => ({
      ...provided,
      color: COLOR_MEDIUM_GRAY,
      backgroundColor: COLOR_GRAY,
      padding: 0,
    }),
    groupHeading: (provided) => ({
      ...provided,
      paddingTop: 10,
      paddingBottom: 10,
    }),
  };
}
