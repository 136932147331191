import React from "react";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { useReportContext } from "../../contexts/report";
import { useReportsContext } from "../../contexts/Reports";
import { ArrowButtons } from "../ArrowButtons";
import { Loader } from "../Loader";

import { PeriodSelector } from "./PeriodSelector";
import { ReportBackButton } from "./ReportBackButton";
import { ReportPeriod } from "./ReportPeriod";

const PeriodSelectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PeriodSelectorWrapper = styled.div`
  margin-right: 15px;
`;

const PeriodButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

interface IReportPeriodSelectionProps {
  disableBackButton?: boolean;
}

export function ReportPeriodSelection({
  disableBackButton = false,
}: IReportPeriodSelectionProps) {
  const [{ datesQueryCalled, loadingDates }, actions] = useReportsContext();
  const { reportPreferences } = useReportContext();

  const isSingleDayReport = reportPreferences?.isSingleDay ?? false;

  const selectPrevPeriod = () => {
    actions.selectPrevPeriod(isSingleDayReport);
  };
  const selectNextPeriod = () => {
    actions.selectNextPeriod(isSingleDayReport);
  };

  if (!datesQueryCalled || loadingDates) {
    return (
      <Loader
        style={{ marginLeft: "10%", alignSelf: "flex-start" }}
        color={COLOR_BLUE}
        size="small"
      />
    );
  }

  return (
    <PeriodSelectionWrapper>
      {!disableBackButton && <ReportBackButton />}
      {!isSingleDayReport && (
        <PeriodSelectorWrapper>
          <PeriodSelector />
        </PeriodSelectorWrapper>
      )}
      <PeriodButtonsWrapper>
        <ArrowButtons
          onPrevClick={selectPrevPeriod}
          onNextClick={selectNextPeriod}
        />
      </PeriodButtonsWrapper>
      <ReportPeriod isSingleDay={isSingleDayReport} />
    </PeriodSelectionWrapper>
  );
}
