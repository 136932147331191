import { DocumentNode } from "graphql";

import { useModalContext } from "../../contexts/UI/Modal";
import { useUserContext } from "../../contexts/User";
import { useDeleteSession2023Mutation } from "../../graphql";
import useSendManualNotificationAfterAction from "../useSendManualNotificationAfterAction";

export const useDeleteSession = () => {
  const { sessionId, language, timezone } = useUserContext();
  const modal = useModalContext();

  const [deleteSession] = useDeleteSession2023Mutation();
  const { handleSendManualNotificationAfterAction } =
    useSendManualNotificationAfterAction();

  const deleteSessionMutation = async (
    sessionToDelete,
    refetchQueries: () => (string | DocumentNode)[]
  ) => {
    const deleteSessionResult = await deleteSession({
      variables: {
        sessionId,
        timezone,
        language,
        id: sessionToDelete.id,
      },
      refetchQueries,
    });

    await modal.actions.closeModal();

    handleSendManualNotificationAfterAction(
      sessionToDelete,
      deleteSessionResult?.data?.deleteSession2023?.notificationToSuggest
    );
  };

  return { deleteSessionMutation };
};
