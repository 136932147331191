import React from "react";

import styled from "styled-components";

import Avatar from "../../components/Avatar";

export function PlayerCell({
  thumbnail,
  name,
}: {
  thumbnail: string;
  name: string;
}) {
  return (
    <PlayerCellWrap>
      <Avatar uri={thumbnail} userName={name} size={45} />
      {name}
    </PlayerCellWrap>
  );
}

const PlayerCellWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-weight: 700;
  cursor: default;
`;
