import { useCallback } from "react";

import { UserPreferenceKeys } from "../../constants";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { useGetPreferenceQuery, usePutPreferenceMutation } from "../../graphql";
import { AthleteProfileTabsPreference } from "../../models";
import { parsePreferences } from "../../utils/preferences";
import { useGetSelectedAccountIdInMonitoring } from "../useGetSelectedAccountIdInMonitoring";

export const useAddReportToMonitoring = () => {
  const { sessionId } = useUserContext();
  const [{ selectedGroup }] = useReportsContext();
  const accountId = useGetSelectedAccountIdInMonitoring();

  const [setPreference] = usePutPreferenceMutation();
  const { data } = useGetPreferenceQuery({
    variables: {
      sessionId,
      accountId,
      key: UserPreferenceKeys.ATHLETE_PROFILE_TABS,
    },
  });

  const addReportToMonitoringGroup = useCallback(
    ({ reportId, reportName }: { reportId: string; reportName: string }) => {
      const tabsPreferences = parsePreferences<AthleteProfileTabsPreference>(
        data?.getPreference,
        {}
      );

      if (selectedGroup?.id && accountId) {
        const newPreferences = {
          ...tabsPreferences,
          [selectedGroup.id]: [
            ...(tabsPreferences[selectedGroup.id] || []),
            { id: reportId, name: reportName, title: reportName },
          ],
        };

        setPreference({
          variables: {
            sessionId,
            accountId,
            key: UserPreferenceKeys.ATHLETE_PROFILE_TABS,
            value: JSON.stringify(newPreferences),
          },
        });
      }
    },
    [
      data?.getPreference,
      selectedGroup?.id,
      accountId,
      setPreference,
      sessionId,
    ]
  );

  return { addReportToMonitoringGroup };
};
