import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";

import * as api from "./api";
import * as endpoints from "./endpoints";

dayjs.extend(localeData);

export function fetchProfile(session: api.Session) {
  return api.post(endpoints.JsonProfileQuery, {}, session);
}

export interface JsonProfileQueryResponse {
  _isFamilyMember: boolean;
  _isTrainer: boolean;
  _phoneNumber: string;
  _emailAddress: string;
  _family:
    | null
    | {
        firstName: string;
        lastName: string;
        thumb: string;
        name: string;
        userID: string;
      }[];
}

export function fetchUserProfile(
  session: api.Session,
  focusedAthleteGuid: string
) {
  return api.post<JsonProfileQueryResponse>(
    endpoints.JsonProfileQuery,
    {
      _withWeightAndHeight: false,
      _focusedAthlete: focusedAthleteGuid,
    },
    session
  );
}

export function fetchHeader(session, athleteGuid: string) {
  session = {
    ...session,
    focusedGuid: athleteGuid,
  };

  return api.post(endpoints.JsonTemporaryHeaderQuery, {}, session);
}

export function fetchActivityLog(
  session,
  athleteGuid: string,
  from: number,
  to: number
) {
  session = {
    ...session,
    focusedGuid: athleteGuid,
  };

  const params = {
    _from: from,
  };

  return api.post(endpoints.JsonTemporaryActivityLogQuery, params, session);
}

export function fetchPerformance(
  session: any,
  athleteGuid: string,
  focusedGroup: string,
  from: number,
  to: number
) {
  session = {
    ...session,
    focusedGuid: athleteGuid,
  };

  const params = {
    _from: from,
    _to: to,
    _focusedGroup: focusedGroup,
  };

  return api.post(endpoints.JsonTemporaryPerformanceQuery, params, session);
}

export function fetchStructure(session: any, focusedGroup: string) {
  const params = {
    _focusedGroup: focusedGroup,
  };

  return api.post(endpoints.JsonPlayerCardStructureQuery, params, session);
}

export function fetchWidgets(
  session: any,
  focusedGroup: string,
  focusedAthlete: string,
  userGuid: string,
  widgetGuids: string[],
  from: number,
  to: number
) {
  const params = {
    _focusedGroup: focusedGroup,
    _focusedAthlete: focusedAthlete,
    _guidsOfWidgetTemplates: widgetGuids,
    _includeMetaData: false,
    _range: {
      _from: from,
      _to: to,
    },
    _user: userGuid,
    _dateFormat: dayjs().localeData().longDateFormat("L"),
  };

  return api.post(endpoints.JsonPlayerCardWidgetQuery, params, session);
}

export function addAthleteComment(
  session: any,
  athleteGuid: string,
  templateGuid: string,
  comment: string,
  item: any
) {
  const prop = {
    _templateGuid: templateGuid,
    _guid: item ? item._guid : null,
    _time: item ? item._time : null,
    _value: comment,
  };

  const params = {
    _focusedAthlete: athleteGuid,
    _items: [prop],
  };

  return api.post(endpoints.JsonInformationUpdateRequest, params, session);
}

export interface JsonMyAthletesQueryResponse {
  _myAthletes: {
    _athleteFirstName: string;
    _athleteGuid: string;
    _athleteLastName: string;
    _athleteName: string;
    _editAccess: string;
    _exp: boolean;
    _groupsGuids: string[];
    _profileImageThumbUrl: string;
    _profileImageUrl: string;
  }[];
}

export function fetchMyAthletes(session: api.Session) {
  const params = {};

  return api.post<JsonMyAthletesQueryResponse>(
    endpoints.JsonMyAthletesQuery,
    params,
    session
  );
}
