import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { useUserContext } from "../../contexts/User";
import { useUpdatePracticeAttendanceMutation } from "../../graphql";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { ButtonColor, GeneralButton } from "../Button";
import { AttendingIcon, AwayIcon, InjuredIcon, SickIcon } from "../Icons";

import AttendanceForm from "./Components/AttendanceForm";
import AttendanceStatusItem from "./Components/AttendanceStatusItem";

export enum AttendanceStatus {
  ATTENDED = "Attended",
  INJURED = "Injured",
  SICK = "Sick",
  OTHER = "Other",
  UNKNOWN = "Unknown",
}

const AttendanceStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const AttendanceFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
`;

function Attendance({
  closeModal,
  athleteGuid,
  attendance,
  sessionGuid,
  athleteComment,
  withoutAttendingStatus = false,
  stage = 1,
  refetch,
  comment,
}) {
  const { t } = useTranslation();
  const { sessionId, isFamilyMember, isTrainer, timezone } = useUserContext();
  const [updatePracticeAttendanceMutation] =
    useUpdatePracticeAttendanceMutation();

  const [currentStage, setCurrentStage] = useState(stage);
  const [newAttendance, setNewAttendance] = useState(
    attendance || AttendanceStatus.UNKNOWN
  );
  const [note, setNote] = useState("");

  useEffect(() => {
    setNote(
      isRealTrainer({ isFamilyMember, isTrainer }) ? comment : athleteComment
    );
  }, [isFamilyMember, isTrainer, comment, athleteComment]);

  const onAttendanceClick = (currentAttendance: AttendanceStatus) => {
    if (currentAttendance === AttendanceStatus.ATTENDED) {
      onConfirm(true);
    } else {
      setNewAttendance(currentAttendance);
      setCurrentStage(2);
    }
  };

  const onConfirm = async (isAttending?: boolean) => {
    await updatePracticeAttendanceMutation({
      variables: {
        sessionId,
        timezone,
        input: {
          practiceGuid: sessionGuid,
          athleteGuid,
          attendance: isAttending ? AttendanceStatus.ATTENDED : newAttendance,
          ...(isRealTrainer({ isFamilyMember, isTrainer })
            ? { comment: note }
            : { athleteComment: note }),
        },
      },
    });

    await refetch();

    closeModal();
  };

  const handleClearSelection = async () => {
    setNewAttendance(AttendanceStatus.UNKNOWN);
    setNote("");
    setCurrentStage(1);
    await updatePracticeAttendanceMutation({
      variables: {
        sessionId,
        timezone,
        input: {
          practiceGuid: sessionGuid,
          athleteGuid,
          attendance: AttendanceStatus.UNKNOWN,
          athleteComment: "",
          comment: "",
        },
      },
    });

    await refetch();

    closeModal();
  };

  const handleCancelStageTwo = () => {
    setCurrentStage(1);
  };

  const attendanceStatus = {
    id: AttendanceStatus.ATTENDED,
    onClick: () => onAttendanceClick(AttendanceStatus.ATTENDED),
    title: t("attending"),
    svgIcon: (
      <AttendingIcon isActive={attendance === AttendanceStatus.ATTENDED} />
    ),
  };

  const unattendingStatuses = [
    {
      id: AttendanceStatus.SICK,
      onClick: () => onAttendanceClick(AttendanceStatus.SICK),
      title: t("sick"),
      svgIcon: <SickIcon isActive={attendance === AttendanceStatus.SICK} />,
    },
    {
      id: AttendanceStatus.INJURED,
      onClick: () => onAttendanceClick(AttendanceStatus.INJURED),
      title: t("injured"),
      svgIcon: (
        <InjuredIcon isActive={attendance === AttendanceStatus.INJURED} />
      ),
    },
    {
      id: AttendanceStatus.OTHER,
      onClick: () => onAttendanceClick(AttendanceStatus.OTHER),
      title: t("other"),
      svgIcon: <AwayIcon isActive={attendance === AttendanceStatus.OTHER} />,
    },
  ];

  const attendanceStatuses = withoutAttendingStatus
    ? unattendingStatuses
    : [attendanceStatus, ...unattendingStatuses];

  return (
    <>
      {currentStage === 1 && (
        <AttendanceStatusWrapper>
          {attendanceStatuses.map(({ id, ...rest }) => (
            <AttendanceStatusItem key={id} {...rest} />
          ))}
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            isDisabled={newAttendance === AttendanceStatus.UNKNOWN}
            outlined
            label={t("attendanceModalClearSelection")}
            onClick={handleClearSelection}
          />
        </AttendanceStatusWrapper>
      )}
      {currentStage === 2 && (
        <AttendanceFormWrapper>
          <AttendanceForm
            note={note}
            setNote={setNote}
            onCloseClick={handleCancelStageTwo}
            onConfirmClick={() => onConfirm(false)}
          />
        </AttendanceFormWrapper>
      )}
    </>
  );
}

export default Attendance;
