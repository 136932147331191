import React from "react";
import { useTranslation } from "react-i18next";

import { Header } from "../components/Header";
import { AcuteChronicWidgetEditor } from "../components/Report/AcuteChronicWidget/Editor";
import { ReportEditorLoading } from "../components/Report/ReportEditorLoading";
import {
  AcuteChronicWidgetModel,
  WidgetType,
} from "../components/ReportCreateTable/models";
import ScreenContainer from "../components/ScreenContainer";
import { useUserContext } from "../contexts/User";
import {
  useSaveWidgetTemplateAcuteChronicMutation,
  useWidgetTemplateQuery,
} from "../graphql";
import { useAddWidgetToReport } from "../hooks/data/useAddWidgetToReport";
import { useGetSelectedAccountIdInMonitoring } from "../hooks/useGetSelectedAccountIdInMonitoring";
import { useNavigateToPreviousPage } from "../hooks/useNavigateToPreviousPage";
import { useWidgetEditorUrlParams } from "../hooks/useWidgetEditorUrlParams";
import {
  buildPreferencesString,
  parseAcuteChronicPreferencesObject,
} from "../utils/widgetPreferencesBuilder";

export const AcuteChronicWidgetEditorScreen = () => {
  const { t } = useTranslation();
  const {
    reportId,
    widgetId,
    parentReportId,
    layoutIndex,
    editReportWidget,
    isWidgetTemplate,
  } = useWidgetEditorUrlParams();

  const isEditingWidgetTemplate =
    (Boolean(widgetId) && editReportWidget) || isWidgetTemplate;

  const { sessionId, language } = useUserContext();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { addWidgetToReport } = useAddWidgetToReport(
    reportId,
    parentReportId,
    layoutIndex
  );
  const [saveWidgetTemplateAcuteChronic, { loading: isSavingWidget }] =
    useSaveWidgetTemplateAcuteChronicMutation();

  const { data: widgetData, loading: loadingWidgetData } =
    useWidgetTemplateQuery({
      variables: {
        sessionId,
        id: widgetId,
        language,
      },
      skip: !widgetId,
      fetchPolicy: "network-only",
    });

  const widgetModel =
    widgetData?.reportWidgetTemplateById?.__typename ===
    "ReportWidgetTemplateAcuteChronic"
      ? {
          ...widgetData.reportWidgetTemplateById,
          ...parseAcuteChronicPreferencesObject(
            widgetData?.reportWidgetTemplateById?.preferences
          ),
        }
      : undefined;

  const navigateToPreviousPage = useNavigateToPreviousPage();

  const onSubmit = async (values: AcuteChronicWidgetModel) => {
    const { name, ...rest } = values;

    try {
      const response = await saveWidgetTemplateAcuteChronic({
        variables: {
          accountId: selectedAccountId,
          sessionId,
          input: {
            id: isEditingWidgetTemplate ? widgetId || null : null,
            name:
              name ||
              (isEditingWidgetTemplate
                ? t("defaultAcuteChronicWidgetName")
                : ""),
            preferences: buildPreferencesString({
              type: WidgetType.ACUTE_CHRONIC,
              ...rest,
            }),
            reportTemplateId: reportId || parentReportId,
          },
        },
      });

      if (
        !isEditingWidgetTemplate &&
        response.data?.saveWidgetTemplateAcuteChronic?.id
      ) {
        await addWidgetToReport(
          response.data.saveWidgetTemplateAcuteChronic.id
        );
      }

      navigateToPreviousPage();
    } catch (error) {
      console.log(error);
    }
  };

  if (loadingWidgetData) {
    return <ReportEditorLoading />;
  }

  return (
    <ScreenContainer style={{ height: "100vh" }}>
      <Header />
      <AcuteChronicWidgetEditor
        isSavingWidget={isSavingWidget}
        widget={widgetModel}
        onSubmit={onSubmit}
        onCancel={navigateToPreviousPage}
      />
    </ScreenContainer>
  );
};
