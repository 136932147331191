import _sample from "lodash/sample";

import { AggregateFunction } from "../graphql";

import { isOpponentSoccerAttribute } from "./reports";

export function getRandomInt({
  min = 0,
  max = 1,
}: { min?: number; max?: number } = {}) {
  return Math.floor(Math.random() * Math.abs(max - min) + min);
}

export function getDummyValue(
  unitName: string,
  isTextValue = false,
  aggregation: AggregateFunction = null,
  textOptions = null,
  enforceTextValue = false,
  measurementTemplateId = ""
) {
  if (isOpponentSoccerAttribute(measurementTemplateId)) {
    return getDummyOpponent();
  }

  if (textOptions?.length && enforceTextValue) {
    return _sample(textOptions);
  }

  if (isTextValue) {
    return aggregation === AggregateFunction.Latest ||
      aggregation === AggregateFunction.LatestForever ||
      aggregation === AggregateFunction.LastDayInRange
      ? getDummyTextData()
      : "-";
  }

  switch (unitName) {
    case "ms":
      return getRandomInt({ min: 100, max: 1000 });
    case "sec":
      return getRandomInt({ min: 10, max: 20 });
    case "min":
      return getRandomInt({ min: 2, max: 10 });
    case "hrs":
      return getRandomInt({ min: 6, max: 9 });

    case "km":
      return getRandomInt({ min: 2, max: 10 });
    case "m":
    case "cm":
    case "mm":
      return getRandomInt({ min: 50, max: 100 });

    case "kg":
    case "g":
      return getRandomInt({ min: 50, max: 100 });

    case "%":
      return getRandomInt({ min: 0, max: 50 });

    default:
      return getRandomInt({ min: 5, max: 100 });
  }
}

export const getDummyOpponent = () => {
  const DUMMY_OPPONENTS = [
    "Barcelona",
    "Liverpool",
    "Chelsea",
    "Real Madrid",
    "Girona",
  ];

  return DUMMY_OPPONENTS[Math.floor(Math.random() * DUMMY_OPPONENTS.length)];
};

export const getDummyTextData = () => {
  const DUMMY_TEXTS = [
    "Lorem ipsum",
    "dolor sit amet",
    "consectetur adipiscing elit",
    "sed do eiusmod tempor",
    "incididunt ut labore",
    "et dolore magna aliqua",
    "Ut enim ad minim veniam",
    "quis nostrud exercitation",
    "ullamco laboris nisi ut aliquip",
    "ex ea commodo consequat",
  ];

  return DUMMY_TEXTS[Math.floor(Math.random() * DUMMY_TEXTS.length)];
};
