import React from "react";
import ReactJWPlayer from "react-jw-player";

import styled from "styled-components";

import { VideoType } from "./model";

type VideoItemProps = {
  video: VideoType & { embedUrl?: string };
  type: string;
};

const VideoWrapper = styled.div`
  margin-bottom: 5px;
`;

const Caption = styled.p`
  margin-top: 5px;
`;

export function VideoItem({ video, type }: VideoItemProps) {
  const src = type === "videos" ? video.url : video.embedUrl;

  return (
    <VideoWrapper>
      {type === "youtubeVideos" || type === "vimeoVideos" ? (
        <iframe src={src} height="100%" width="100%" />
      ) : (
        <ReactJWPlayer
          playerId={src}
          playerScript="https://cdn.jwplayer.com/libraries/g9KQjRPV.js"
          file={src}
          image={type === "videos" ? video.thumbnailUrl : ""}
        />
      )}
      {video.caption && <Caption>{video.caption}</Caption>}
    </VideoWrapper>
  );
}
