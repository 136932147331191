import { AggregateFunction } from "../../graphql";
import {
  FetchSoccerStatisticsResponse,
  FetchSoccerTeamStatisticsResponse,
  getAggregationValueFromCooking,
  InformationCooking,
  InformationNewDataTeam,
  JsonAttendanceStatisticsQueryResponse,
  JsonAttendanceTeamStatisticsQueryResponse,
  JsonStatisticsForMeasurementsResponse,
} from "../../services";
import { JsonXpsMagicInfoStatisticsQueryResponse } from "../../services/statistics/xpsMagicInfo";
import { roundToTwo } from "../../utils/number";
import {
  getAttendanceStatisticsDataKey,
  getSoccerStatisticsDataKey,
  isXpsMagicAgeAttribute,
  isXpsMagicBirthDayAttribute,
} from "../../utils/reports";
import { getAllAthleteStats } from "../../utils/statistics";

import { StatisticsData, StatisticsDataItem } from "./models";
import { getSoccerStatsWithCustomCols } from "./utils/soccerStatsWithCustomCols";

export type StatisticsItemModel = {
  collectionId: string;
  templateId: string;
  templateName?: string;
  attributeId: string;
  attributeName?: string;
  aggregation: AggregateFunction;
};

export function getStatisticsDataFromMeasurements(
  statisticsData: JsonStatisticsForMeasurementsResponse
): StatisticsData {
  return {
    perPerformer:
      statisticsData?._athletes?.map((athlete) => ({
        id: athlete._guid,
        name: athlete._name,
        items:
          getAllAthleteStats(athlete, true).flatMap(
            (athleteStats) =>
              athleteStats._attributes?.map<StatisticsDataItem>(
                (attribute) => ({
                  template: {
                    id: athleteStats._guid,
                    name: athleteStats._name,
                  },
                  aggregateFunction: athleteStats._agg,
                  attribute: {
                    id: attribute._guid,
                    name: attribute._name,
                    unitName: attribute._unitName,
                    min: attribute._min,
                    max: attribute._max,
                    timeUnit: attribute._ut,
                  },
                  values:
                    attribute._values?.map((value) => ({
                      val: value._val,
                      time: value._time,
                      ctr: value.ctr,
                      txt: value._txt,
                      diff: value._diff,
                      diffPercent: value._diffPercent,
                      rgb: value._rgb,
                      pos: value.pos,
                      tifn: value.tifn,
                    })) ?? [],
                })
              ) ?? []
          ) ?? [],
      })) ?? [],
  };
}

export function getStatisticsDataFromXpsMagicInfo(
  items: StatisticsItemModel[],
  statisticsData: JsonXpsMagicInfoStatisticsQueryResponse
): StatisticsData {
  return {
    perPerformer: statisticsData?.byAthletes?.map((athlete) => ({
      id: athlete.athleteGuid,
      name: athlete.athleteFullName,
      items: items.map<StatisticsDataItem>((item) => {
        const value = athlete.templates?.find(
          (template) =>
            template.id ===
            item?.templateId?.slice(item?.templateId?.indexOf(".") + 1)
        )?.value;

        const values = value
          ? [
              {
                ...(!isXpsMagicAgeAttribute(item.templateId) &&
                !isXpsMagicBirthDayAttribute(item.templateId)
                  ? { txt: value }
                  : {}),
                ...(isXpsMagicAgeAttribute(item.templateId)
                  ? { val: +value }
                  : {}),
                ...(isXpsMagicBirthDayAttribute(item.templateId)
                  ? { date: value, val: new Date(value).getTime() }
                  : {}),
              },
            ]
          : [{}];

        return {
          template: {
            id: item.templateId,
            name: item.templateName,
          },
          attribute: {
            id: item.attributeId,
            name: item.attributeId,
            unitName: item.attributeName,
          },
          aggregateFunction: AggregateFunction.Latest,
          values,
        };
      }),
    })),
  };
}

export function getStatisticsDataFromSoccerStatistics(
  items: StatisticsItemModel[],
  statisticsData: FetchSoccerStatisticsResponse,
  gamesResult?: boolean
): StatisticsData {
  return {
    perPerformer:
      statisticsData?.perAthletes?.map((athlete) => ({
        id: athlete.athleteId,
        name: athlete.athleteName,
        items:
          items.map<StatisticsDataItem>((item) => {
            const athleteData =
              (gamesResult ? athlete.games : athlete.periods) ?? [];
            const values = athleteData
              .map((attribute) => ({
                time: (gamesResult ? attribute.date : attribute.start) as
                  | number
                  | undefined,
                val: getSoccerStatsWithCustomCols(
                  attribute,
                  item.templateId,
                  item.aggregation
                ) as number | undefined,
              }))
              .filter((value) => value.val !== undefined);

            return {
              template: {
                id: item.templateId,
                name: item.templateName,
              },
              attribute: {
                id: item.attributeId,
                name: item.attributeId,
                unitName: item.attributeName,
              },
              aggregateFunction: item.aggregation ?? AggregateFunction.Latest,
              values,
            };
          }) ?? [],
      })) ?? [],
  };
}

export function getStatisticsDataFromSoccerTeamStatistics(
  items: StatisticsItemModel[],
  statisticsData: FetchSoccerTeamStatisticsResponse
): StatisticsData {
  return {
    perPerformer:
      statisticsData?.perTeams?.map((team) => ({
        id: team.groupId,
        name: team.groupName,
        items:
          items.map<StatisticsDataItem>((item) => ({
            template: {
              id: item.templateId,
              name: item.templateName,
            },
            attribute: {
              id: item.attributeId,
              name: item.attributeName,
            },
            aggregateFunction: item.aggregation ?? AggregateFunction.Latest,
            values:
              team.periods?.map((value) => ({
                val: value[getSoccerStatisticsDataKey(item.templateId)],
                time: value.start,
              })) ?? [],
          })) ?? [],
      })) ?? [],
  };
}

export function getStatisticsDataFromAttendanceStatistics(
  items: StatisticsItemModel[],
  statisticsData: JsonAttendanceStatisticsQueryResponse
): StatisticsData {
  return {
    perPerformer:
      statisticsData?.perAthletes?.map((athlete) => ({
        id: athlete.athleteId,
        name: athlete.athleteName,
        items:
          items.map<StatisticsDataItem>((item) => ({
            template: {
              id: item.templateId,
              name: item.templateName,
            },
            attribute: {
              id: item.attributeId,
              name: item.attributeName,
            },
            aggregateFunction: item.aggregation ?? AggregateFunction.Latest,
            values:
              athlete.attendanceInPeriodOfTime?.map((value) => ({
                val:
                  item.templateId.includes("perc") ||
                  item.templateId.includes("Perc")
                    ? roundToTwo(
                        100 *
                          value[getAttendanceStatisticsDataKey(item.templateId)]
                      )
                    : value[getAttendanceStatisticsDataKey(item.templateId)],
                time: value.period?._start,
              })) ?? [],
          })) ?? [],
      })) ?? [],
  };
}

export function getStatisticsDataFromAttendanceTeamStatistics(
  items: StatisticsItemModel[],
  statisticsData: JsonAttendanceTeamStatisticsQueryResponse
): StatisticsData {
  return {
    perPerformer:
      statisticsData?.perTeams?.map((team) => ({
        id: team.groupId,
        name: team.groupName,
        items:
          items.map<StatisticsDataItem>((item) => ({
            template: {
              id: item.templateId,
              name: item.templateName,
            },
            attribute: {
              id: item.attributeId,
              name: item.attributeName,
            },
            aggregateFunction: item.aggregation ?? AggregateFunction.Latest,
            values:
              team.attendanceInPeriodOfTime?.map((value) => ({
                val:
                  item.templateId.includes("perc") ||
                  item.templateId.includes("Perc")
                    ? 100 *
                      value[getAttendanceStatisticsDataKey(item.templateId)]
                    : value[getAttendanceStatisticsDataKey(item.templateId)],
                time: value.period?._start,
              })) ?? [],
          })) ?? [],
      })) ?? [],
  };
}

export function getStatisticsDataFromInformations(
  items: StatisticsItemModel[],
  statisticsData: InformationNewDataTeam
): StatisticsData {
  return {
    perPerformer:
      statisticsData?.byAthletes?.map((athlete) => ({
        id: athlete.athleteGuid,
        name: athlete.athleteFullName,
        items:
          items.map<StatisticsDataItem>((item) => ({
            template: {
              id: item.templateId,
              name: item.templateName,
            },
            attribute: {
              id: item.attributeId,
              name: item.attributeName,
            },
            aggregateFunction: item.aggregation ?? AggregateFunction.Latest,
            values:
              athlete.templates
                .find(
                  (template) =>
                    getAggregationValueFromCooking(
                      template.cooking as InformationCooking
                    ) === item.aggregation &&
                    template.templateGuid === item.templateId
                )
                ?.items.map((item) => ({
                  txt: item._value,
                  time: item._time,
                })) ?? [],
          })) ?? [],
      })) ?? [],
  };
}

export function mergeStatisticsData(
  statsArray: StatisticsData[]
): StatisticsData {
  const [stats1, ...restStatsArray] = statsArray;

  restStatsArray?.forEach((stats) =>
    stats?.perPerformer?.forEach((athlete) => {
      const exists = stats1?.perPerformer?.find(({ id }) => id === athlete?.id);
      if (exists) {
        exists?.items?.push(...athlete.items);
      } else {
        stats1?.perPerformer?.push(athlete);
      }
    })
  );

  return stats1;
}
