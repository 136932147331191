import React, {
  Dispatch,
  Ref,
  SetStateAction,
  forwardRef,
  useEffect,
  CSSProperties,
} from "react";
import {
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";

import styled, { css } from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../colors";

import { InputError } from "./InputError";
import { LabelText, StyledInputWrapper } from "./StyledInputComponents";

type Props = UseControllerProps & {
  name: string;
  label: string;
  defaultValue?: string;
  resizeTextArea?: () => void;
  numberOfRows?: number;
  setNumOfRows?: Dispatch<SetStateAction<number>>;
  marginBottom?: number;
  hideLabel?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  grayBgOnHover?: boolean;
};

const StyledTextArea = styled.textarea<{
  grayBgOnHover?: boolean;
}>`
  background-color: ${({ grayBgOnHover }) =>
    grayBgOnHover ? "transparent" : COLOR_GRAY};
  ${({ grayBgOnHover }) =>
    grayBgOnHover
      ? css`
          &:hover,
          &:focus {
            background-color: ${COLOR_GRAY};
          }
        `
      : ""};
  color: ${COLOR_TEXT_DARK};
  border-radius: 6px;
  resize: none;
  padding: 14px 15px;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  outline: none;
`;

export const ControlledTextArea = forwardRef(function ControlledTextArea(
  {
    label,
    name,
    defaultValue,
    resizeTextArea,
    rules,
    numberOfRows = 3,
    setNumOfRows,
    marginBottom,
    hideLabel,
    style,
    placeholder,
    grayBgOnHover,
  }: Props,
  ref?: Ref<HTMLTextAreaElement>
) {
  const formContext = useFormContext();
  const { formState } = formContext || {};
  const hasError = Boolean(formState?.errors[name]);

  const {
    field: { value, onChange, onBlur },
  } = useController({ defaultValue, rules, name });

  const errorMessage = hasError && `${formState?.errors[name]?.message}`;

  useEffect(
    function countRows() {
      if (value && setNumOfRows && resizeTextArea) {
        const numRows = value.split("\n").length;
        setNumOfRows(numRows);
        resizeTextArea();
      }
    },
    [value, setNumOfRows, resizeTextArea]
  );

  return (
    <StyledInputWrapper marginBottom={marginBottom}>
      {!hideLabel ? <LabelText>{label.toUpperCase()}</LabelText> : null}
      <StyledTextArea
        ref={ref}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        rows={numberOfRows}
        style={style}
        placeholder={placeholder}
        grayBgOnHover={grayBgOnHover}
      />
      <InputError errorMessage={errorMessage} />
    </StyledInputWrapper>
  );
});
