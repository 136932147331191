import React from "react";

import styled from "styled-components";

import { FolderTreeDocumentsQuery } from "../../../graphql";
import { Spinner, SpinnerSize } from "../../Spinner";

import { FileType } from "./FileItem";
import { FolderItem } from "./FolderItem";

type SubfolderItemProps = {
  loading: boolean;
  data: FolderTreeDocumentsQuery["user"]["collection"]["tree"]["children"][0]["children"];
  documents: FolderTreeDocumentsQuery["user"]["collection"]["tree"]["documents"]["edges"];
  collectionId: string;
  isWorkout?: boolean;
  allOpen: boolean;
  searchValue?: string;
  onFolderDocumentsChange?: (res: FolderTreeDocumentsQuery) => void;
  selectedWorkoutFolder?: string | null;
  setSelectedWorkoutFolder?: (folderId: string | null) => void;
  selectedFile?: FileType;
  onFileSingleClick?: (file: FileType) => void;
  onFileDoubleClick?: (file: FileType) => void;
  controlledSelectedFolder?: string | null;
  controlledSetSelectedFolder?: (folderId: string) => void;
  controlledToggledFolderObject?: Record<string, boolean>;
  controlledSetToggledFolder?: (
    folderId: string
  ) => (newToggle: boolean) => void;
};

const Subfolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export function SubfolderItem({
  loading,
  data,
  collectionId,
  isWorkout,
  allOpen,
  searchValue,
  documents,
  onFolderDocumentsChange,
  selectedWorkoutFolder,
  setSelectedWorkoutFolder,
  selectedFile,
  onFileSingleClick,
  onFileDoubleClick,
  controlledToggledFolderObject,
  controlledSetToggledFolder,
  controlledSelectedFolder,
  controlledSetSelectedFolder,
}: SubfolderItemProps) {
  if (loading) {
    return <Spinner size={SpinnerSize.SMALL} />;
  }

  if (!loading && !data?.length) {
    return null;
  }

  return (
    <Subfolder>
      {data?.map((child) => (
        <FolderItem
          key={child.id}
          folder={child}
          collectionId={collectionId}
          isWorkout={isWorkout}
          allOpen={allOpen}
          searchValue={searchValue}
          documents={documents}
          onFolderDocumentsChange={onFolderDocumentsChange}
          selectedWorkoutFolder={selectedWorkoutFolder}
          setSelectedWorkoutFolder={setSelectedWorkoutFolder}
          withExtraPaddingForFiles={true}
          selectedFile={selectedFile}
          onFileSingleClick={onFileSingleClick}
          onFileDoubleClick={onFileDoubleClick}
          controlledToggledFolderObject={controlledToggledFolderObject}
          controlledSetToggledFolder={controlledSetToggledFolder}
          controlledSelectedFolder={controlledSelectedFolder}
          controlledSetSelectedFolder={controlledSetSelectedFolder}
        />
      ))}
    </Subfolder>
  );
}
