import { useEffect, useMemo, useState } from "react";

import { LazyQueryResult } from "@apollo/client";

import { useReportContext } from "../../../../contexts/report";
import { useUserContext } from "../../../../contexts/User";
import {
  AggregateFunction,
  ReportBuildingAttributeTemplatesQuery,
  useReportBuildingAttributeTemplatesLazyQuery,
} from "../../../../graphql";
import { useTeamsAthletes } from "../../../../hooks";
import { getCookingValue, JsonStatisticsPeriod } from "../../../../services";
import {
  useAthleteStatisticsQuery,
  useAttendanceStatisticsQuery,
  useAttendanceTeamStatisticsQuery,
  useInformations2023Query,
  useSoccerStatisticsQuery,
  useSoccerTeamStatisticsQuery,
  useTeamStatisticsQuery,
  useXpsMagicInfoStatisticsQuery,
} from "../../../../services/statistics/hooks";
import { groupByCollection } from "../../../../utils/reports";
import { sortList } from "../../../../utils/sortList";
import { getValidUnitName } from "../../../../utils/statistics";
import {
  getStatisticsDataFromAttendanceStatistics,
  getStatisticsDataFromAttendanceTeamStatistics,
  getStatisticsDataFromInformations,
  getStatisticsDataFromMeasurements,
  getStatisticsDataFromSoccerStatistics,
  getStatisticsDataFromSoccerTeamStatistics,
  mergeStatisticsData,
  StatisticsItemModel,
  getStatisticsDataFromXpsMagicInfo,
} from "../../../Report/utils";

import { TREND_LENGTH } from "./helpers/constants";
import { findAttribute } from "./helpers/findAttribute";
import { generateEmptyDataWithStructure } from "./helpers/generateEmptyDataWithStructure";
import { getGrouppedColumnHeader } from "./helpers/getGrouppedColumnHeader";
import { shouldReturnAttributeName } from "./helpers/headerDisplayChecker";
import {
  ColumnHeader,
  GrouppedColumnHeader,
  GroupTableStats,
  GroupTableStatsData,
  IUseGroupStatsDataVariables,
} from "./helpers/models";
import { prepareComparisonData } from "./helpers/prepareComparisonData";
import {
  getAdditionalAggregations,
  getCompareLatestToValue,
} from "./helpers/prepareFetchParams";
import { prepareTrendData } from "./helpers/prepareTrendData";
import { prepareValues } from "./helpers/prepareValues";

const getIdentityOfColumnDataConfiguration = (header: ColumnHeader) =>
  `${header.attributeTemplate?.id}-${header.aggregateFunctions?.join(",")}-${
    header.templateId
  }-${header.comparison?.show}-${header.comparison?.base}-${
    header.comparison?.view
  }-${header.showTrend}`;

export type TeamsAthletesDataComposition = "athletes" | "teams" | "mixed";

export const useGroupStatsData = ({
  columnHeaders,
  fromDate,
  toDate,
  participants,
  participantTeams,
  colorConfigMeasurementIds,
  colorStartDate,
}: IUseGroupStatsDataVariables): {
  tableData: GroupTableStatsData;
  isLoading: boolean;
  dataComposition: TeamsAthletesDataComposition;
} => {
  const { sessionId, language } = useUserContext();
  const { report } = useReportContext();

  // Needed for reloading table if athletes are changed or widget is edited
  // It is ugly hack to limitate number of rerenders
  const participantsArrayIdentity = [
    ...(participants || []),
    ...(participantTeams || []),
  ].join(",");
  const columnsIdentity = columnHeaders
    .map(getIdentityOfColumnDataConfiguration)
    .join(",");

  const teams = useTeamsAthletes(participantTeams);
  const grouppedColumnHeader = useMemo(
    () => getGrouppedColumnHeader(columnHeaders),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columnsIdentity]
  );
  const attributesMap = useTemplateAttributesMap(grouppedColumnHeader);

  const isPeriodLongEnoughForTrend =
    toDate.diff(fromDate, "days") >= TREND_LENGTH;

  const {
    statisticsItems: statisticsColumns,
    informationItems: informationColumns,
    attendanceItems: attendanceColumns,
    soccerStatisticsItems: soccerStatisticsColumns,
    xpsMagicInfoItems: xpsMagicInfoColumns,
  } = groupByCollection(columnHeaders);

  const {
    data: informationsResponse = null,
    isLoading: informationsIsLoading,
    fetchStatus: informationsFetchStatus,
  } = useInformations2023Query(
    {
      athleteIds: participants,
      templates: informationColumns.flatMap((infoCol) =>
        infoCol.aggregateFunctions.flatMap((agg) => ({
          guid: infoCol.templateId,
          cooking: getCookingValue(
            agg as
              | AggregateFunction.LastDayInRange
              | AggregateFunction.Latest
              | AggregateFunction.LatestForever
          ),
        }))
      ),
      reportTemplateId: report?.id,
      range: {
        from: fromDate,
        to: toDate,
      },
      session: { sessionId },
    },
    {
      enabled:
        sessionId && informationColumns.length > 0 && participants?.length > 0,
    }
  );

  const sharedJsonStatsParams = {
    toDate,
    fromDate,
    period: "continuous" as JsonStatisticsPeriod,
    session: { sessionId },
  };

  const statisticsItems = statisticsColumns.map(
    ({
      attributeTemplate,
      templateId,
      aggregateFunctions,
      collectionId,
      showTrend,
      comparison,
    }) => ({
      dataType: collectionId,
      attribute: attributeTemplate?.id,
      templateGuid: templateId,
      compareLatestTo: getCompareLatestToValue(comparison?.base),
      withPOS: colorConfigMeasurementIds.includes(templateId),
      aggregation: isPeriodLongEnoughForTrend
        ? Array.from(
            new Set([
              ...getAdditionalAggregations(showTrend),
              ...aggregateFunctions,
            ])
          )
        : aggregateFunctions,
    })
  );

  const {
    data: athletesStatisticsData = null,
    isLoading: athletesStatisticsIsLoading,
    fetchStatus: athletesStatisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      ...sharedJsonStatsParams,
      startOfColoringRange: colorStartDate,
      participants: participants.map((participant) => ({ id: participant })),
      items: statisticsItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId && statisticsItems.length > 0 && participants?.length > 0,
    }
  );

  const {
    data: attendanceStatisticsData = null,
    isLoading: attendanceStatisticsIsLoading,
    fetchStatus: attendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      fromDate: sharedJsonStatsParams.fromDate,
      toDate: sharedJsonStatsParams.toDate,
      session: sharedJsonStatsParams.session,
      participants: participants.map((participant) => ({ id: participant })),
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId && attendanceColumns.length > 0 && participants?.length > 0,
    }
  );

  const {
    data: teamPerformersAttendaceStatisticsData = null,
    isLoading: teamPerformersAttendaceStatisticsIsLoading,
    fetchStatus: teamPerformersAttendaceStatisticsFetchStatus,
  } = useAttendanceTeamStatisticsQuery(
    {
      fromDate: sharedJsonStatsParams.fromDate,
      toDate: sharedJsonStatsParams.toDate,
      session: sharedJsonStatsParams.session,
      groupGuids: teams.map((team) => team.id),
      reportTemplateId: report?.id,
    },
    { enabled: sessionId && attendanceColumns.length > 0 && teams?.length > 0 }
  );

  const {
    data: soccerStatisticsData = null,
    isLoading: soccerStatisticsIsLoading,
    fetchStatus: soccerStatisticsFetchStatus,
  } = useSoccerStatisticsQuery(
    {
      from: sharedJsonStatsParams.fromDate,
      to: sharedJsonStatsParams.toDate,
      session: sharedJsonStatsParams.session,
      athleteGuids: participants,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        soccerStatisticsColumns.length > 0 &&
        participants?.length > 0,
    }
  );

  const {
    data: teamPerformersSoccerStatisticsData = null,
    isLoading: teamPerformersSoccerStatisticsIsLoading,
    fetchStatus: teamPerformersSoccerStatisticsFetchStatus,
  } = useSoccerTeamStatisticsQuery(
    {
      from: sharedJsonStatsParams.fromDate,
      to: sharedJsonStatsParams.toDate,
      session: sharedJsonStatsParams.session,
      groupGuids: teams.map((team) => team.id),
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId && soccerStatisticsColumns.length > 0 && teams?.length > 0,
    }
  );

  const {
    data: xpsMagicInfoStatisticsData = null,
    isLoading: xpsMagicInfoStatisticsIsLoading,
    fetchStatus: xpsMagicInfoStatisticsFetchStatus,
  } = useXpsMagicInfoStatisticsQuery(
    {
      athleteIds: participants,
      reportTemplateId: report?.id,
      templates: xpsMagicInfoColumns.map((column) => ({
        id: column.templateId.slice(column.templateId.indexOf(".") + 1),
      })),
    },
    {
      enabled:
        sessionId && xpsMagicInfoColumns.length > 0 && participants?.length > 0,
    }
  );

  const {
    data: teamPerformersData = null,
    isLoading: teamPerformersIsLoading,
    fetchStatus: teamPerformersFetchStatus,
  } = useTeamStatisticsQuery(
    {
      ...sharedJsonStatsParams,
      items: statisticsItems,
      teams,
      reportTemplateId: report?.id,
    },
    { enabled: sessionId && statisticsItems.length > 0 && teams.length > 0 }
  );

  const statisticsTrendItems = statisticsColumns
    .filter(({ showTrend }) => showTrend)
    .map(({ attributeTemplate, templateId, collectionId }) => ({
      dataType: collectionId,
      attribute: attributeTemplate?.id,
      templateGuid: templateId,
      aggregation: [AggregateFunction.Each],
    }));

  const {
    data: athletesStatisticsTrendData = null,
    isLoading: athletesStatisticsTrendIsLoading,
    fetchStatus: athletesStatisticsTrendFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      ...sharedJsonStatsParams,
      fromDate: toDate.subtract(TREND_LENGTH, "days"),
      participants: participants.map((participant) => ({ id: participant })),
      items: statisticsTrendItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        statisticsTrendItems.length > 0 &&
        participants?.length > 0 &&
        !isPeriodLongEnoughForTrend,
    }
  );

  const {
    data: teamPerformersTrendData = null,
    isLoading: teamPerformersTrendIsLoading,
    fetchStatus: teamPerformersTrendFetchStatus,
  } = useTeamStatisticsQuery(
    {
      ...sharedJsonStatsParams,
      fromDate: toDate.subtract(TREND_LENGTH, "days"),
      period: "days",
      teams,
      items: statisticsTrendItems,
      reportTemplateId: report?.id,
    },
    {
      enabled:
        sessionId &&
        teams.length > 0 &&
        statisticsTrendItems.length > 0 &&
        !isPeriodLongEnoughForTrend,
    }
  );

  const trendData = useMemo(
    () => [
      ...getStatisticsDataFromMeasurements(athletesStatisticsTrendData)
        .perPerformer,
      ...getStatisticsDataFromMeasurements(teamPerformersTrendData)
        .perPerformer,
    ],
    [athletesStatisticsTrendData, teamPerformersTrendData]
  );

  const tableData = useMemo<GroupTableStats[]>(() => {
    const tableRowsData = [
      ...mergeStatisticsData([
        getStatisticsDataFromMeasurements(athletesStatisticsData),
        getStatisticsDataFromAttendanceStatistics(
          columnHeadersToStatisticsItemModels(attendanceColumns),
          attendanceStatisticsData?.data
        ),
        getStatisticsDataFromInformations(
          columnHeadersToStatisticsItemModels(informationColumns),
          informationsResponse?.data
        ),
        getStatisticsDataFromSoccerStatistics(
          columnHeadersToStatisticsItemModels(soccerStatisticsColumns),
          soccerStatisticsData?.data
        ),
        getStatisticsDataFromXpsMagicInfo(
          columnHeadersToStatisticsItemModels(xpsMagicInfoColumns),
          xpsMagicInfoStatisticsData?.data
        ),
      ]).perPerformer,
      ...mergeStatisticsData([
        getStatisticsDataFromMeasurements(teamPerformersData),
        getStatisticsDataFromAttendanceTeamStatistics(
          columnHeadersToStatisticsItemModels(attendanceColumns),
          teamPerformersAttendaceStatisticsData?.data
        ),
        getStatisticsDataFromSoccerTeamStatistics(
          columnHeadersToStatisticsItemModels(soccerStatisticsColumns),
          teamPerformersSoccerStatisticsData?.data
        ),
      ]).perPerformer,
    ];

    const stats = tableRowsData.map((athlete) => ({
      athleteId: athlete.id,
      athleteName: athlete.name,
      stats: grouppedColumnHeader.map((column) => {
        const dataForColumn =
          athlete.items?.filter(
            (item) => item.template.id === column.templateId
          ) || [];
        const dataForTrend = isPeriodLongEnoughForTrend
          ? dataForColumn
          : trendData
              .find((trendAthlete) => athlete.id === trendAthlete.id)
              ?.items?.filter((stat) => stat.template.id === column.templateId);

        const attributes = attributesMap.get(column.templateId);
        const shouldAttributeBeVisible = shouldReturnAttributeName(
          attributes,
          column.attributes[0].collectionId
        );

        // If measurement is of type statistics return statistics endpoint data
        if (dataForColumn.length) {
          return {
            label: dataForColumn[0].template.name,
            attributes: column.attributes.map((attribute) => ({
              values: [
                ...prepareValues(
                  dataForColumn,
                  attribute,
                  shouldAttributeBeVisible
                    ? findAttribute(attributes, attribute.attributeTemplate?.id)
                    : ""
                ),
                ...(attribute.comparison?.show
                  ? prepareComparisonData(
                      dataForColumn,
                      attribute.comparison.view,
                      attribute.attributeTemplate?.id,
                      shouldAttributeBeVisible
                        ? findAttribute(
                            attributes,
                            attribute.attributeTemplate?.id
                          )
                        : "",
                      attribute.aggregateFunctions
                    )
                  : []),
              ],
              trendValues: attribute.showTrend
                ? prepareTrendData(
                    dataForTrend,
                    toDate,
                    getValidUnitName(attribute.attributeTemplate?.unitName),
                    attribute.attributeTemplate?.id,
                    shouldAttributeBeVisible
                      ? findAttribute(
                          attributes,
                          attribute.attributeTemplate?.id
                        )
                      : "TREND",
                    dataForColumn[0].attribute?.min || undefined,
                    dataForColumn[0].attribute?.max || undefined
                  )
                : [],
            })),
          };
        }

        return generateEmptyDataWithStructure({
          column,
          shouldAttributeBeVisible,
          attributes,
          toDate,
          dataForTrend,
        });
      }),
    }));

    return sortList(stats, language, (stat) => stat.athleteName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    participantsArrayIdentity,
    athletesStatisticsData,
    teamPerformersData,
    attendanceStatisticsData?.data,
    teamPerformersAttendaceStatisticsData?.data,
    soccerStatisticsData?.data,
    teamPerformersSoccerStatisticsData?.data,
    xpsMagicInfoStatisticsData?.data,
    attendanceColumns,
    soccerStatisticsColumns,
    xpsMagicInfoColumns,
    grouppedColumnHeader,
    isPeriodLongEnoughForTrend,
    trendData,
    informationsResponse,
    attributesMap,
    language,
  ]);

  const isLoading =
    (informationsFetchStatus !== "idle" && informationsIsLoading) ||
    (athletesStatisticsFetchStatus !== "idle" && athletesStatisticsIsLoading) ||
    (attendanceStatisticsFetchStatus !== "idle" &&
      attendanceStatisticsIsLoading) ||
    (teamPerformersAttendaceStatisticsFetchStatus !== "idle" &&
      teamPerformersAttendaceStatisticsIsLoading) ||
    (soccerStatisticsFetchStatus !== "idle" && soccerStatisticsIsLoading) ||
    (teamPerformersSoccerStatisticsFetchStatus !== "idle" &&
      teamPerformersSoccerStatisticsIsLoading) ||
    (teamPerformersFetchStatus !== "idle" && teamPerformersIsLoading) ||
    (athletesStatisticsTrendFetchStatus !== "idle" &&
      athletesStatisticsTrendIsLoading) ||
    (teamPerformersTrendFetchStatus !== "idle" &&
      teamPerformersTrendIsLoading) ||
    (xpsMagicInfoStatisticsFetchStatus !== "idle" &&
      xpsMagicInfoStatisticsIsLoading);

  const dataComposition: TeamsAthletesDataComposition = participantTeams?.length
    ? participants?.length
      ? "mixed"
      : "teams"
    : "athletes";

  return {
    tableData,
    isLoading,
    dataComposition,
  };
};

function useTemplateAttributesMap(
  grouppedColumnHeader: GrouppedColumnHeader[]
) {
  const [attributesMap, setAttributesMap] = useState<
    Map<string, LazyQueryResult<ReportBuildingAttributeTemplatesQuery, unknown>>
  >(new Map());

  const { sessionId, language } = useUserContext();
  const [getAttributes] = useReportBuildingAttributeTemplatesLazyQuery();

  useEffect(() => {
    Promise.all(
      grouppedColumnHeader.map(async (column) => {
        const attributes = await getAttributes({
          variables: {
            collectionId: column.attributes[0].collectionId,
            measurementTemplateId: column.templateId,
            sessionId,
            language,
          },
        });

        return [column.templateId, attributes] as const;
      })
    ).then((results) => setAttributesMap(new Map(results)));
  }, [grouppedColumnHeader, sessionId, language, getAttributes]);

  return attributesMap;
}

function columnHeadersToStatisticsItemModels(
  items: ColumnHeader[]
): StatisticsItemModel[] {
  return items.flatMap(
    ({
      collectionId,
      templateId,
      name,
      attributeTemplate,
      aggregateFunctions,
    }) =>
      aggregateFunctions.map((aggregation) => ({
        collectionId,
        templateId,
        templateName: name,
        attributeId: attributeTemplate?.id,
        aggregation,
      }))
  );
}
