import { GroupFolderProps } from "./GroupFolder";

export const normalizeString = (
  text: string | undefined,
  config?: { keepCase?: boolean; type?: "NFC" | "NFD" | "NFKC" | "NFKD" }
): string | undefined => {
  const removeDiacritics = (str: string): string => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  if (text === undefined) {
    return undefined;
  }

  if (config?.keepCase) {
    return removeDiacritics(text)?.normalize(config?.type || "NFC");
  }

  return removeDiacritics(text)
    ?.normalize(config?.type || "NFC")
    ?.toLowerCase();
};

const hasSubGroupMatchingSearchCriteria = (currentItem, nameToFind: string) =>
  "subGroups" in currentItem &&
  currentItem?.subGroups?.some((subGroup) =>
    normalizeString(subGroup.name).includes(normalizeString(nameToFind))
  );

export function hasNameInChildren(
  obj: GroupFolderProps["data"],
  nameToFind?: string
) {
  if (obj.hasOwnProperty("children") && Array.isArray(obj.children)) {
    for (const currentChildren of obj.children) {
      if (
        currentChildren.name &&
        normalizeString(currentChildren.name).includes(
          normalizeString(nameToFind)
        )
      ) {
        return true;
      }

      if (
        hasSubGroupMatchingSearchCriteria(currentChildren, nameToFind) ||
        hasNameInChildren(currentChildren, nameToFind)
      ) {
        return true;
      }
    }
  }

  if (
    hasSubGroupMatchingSearchCriteria(obj, nameToFind) ||
    normalizeString(obj.name).includes(normalizeString(nameToFind))
  ) {
    return true;
  }

  return false;
}
