import React, { useMemo } from "react";

import dayjs from "dayjs";
import useResizeObserver from "use-resize-observer";

import { useReportContext } from "../../../../contexts/report";
import { useReportsContext } from "../../../../contexts/Reports";
import { useUserContext } from "../../../../contexts/User";
import { IWidgetResizeProps, useBottomWidgetResize } from "../../../../hooks";
import { useEventLog2022Query } from "../../../../services/statistics/hooks";
import { ActivityLogWidgetModel } from "../../../ReportCreateTable/models";
import { useReportPeriod } from "../../hooks/useReportPeriod";
import { WidgetLoader } from "../../WidgetLoader";

import { ActivityDataItem } from "./model";
import { ActivityWidgetTable } from "./Table";

interface ActivityWidgetProps extends IWidgetResizeProps {
  widget: ActivityLogWidgetModel;
  readOnly: boolean;
}

export const ActivityWidget = ({
  widget,
  gridItemId,
  setItemToResize,
  setItemsToHideInViewMode,
  layoutRef,
  readOnly,
}: ActivityWidgetProps) => {
  const { sessionId } = useUserContext();
  const [{ selectedAthlete }] = useReportsContext();
  const { report } = useReportContext();
  const { fromDate } = useReportPeriod();
  const {
    workoutsCompleted,
    testsRegistered,
    informationRegistered,
    somethingRead,
    maxCount,
  } = widget;

  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();

  const {
    data: eventLogResponse,
    isLoading: eventLogIsLoading,
    fetchStatus: eventLogFetchStatus,
  } = useEventLog2022Query(
    {
      athleteId: selectedAthlete?.id,
      data: {
        workoutsCompleted,
        testsRegistered,
        somethingRead,
        informationRegistered,
        maxCount,
      },
      from: fromDate,
      session: { sessionId },
      reportTemplateId: report?.id,
    },
    {
      enabled: Boolean(sessionId && selectedAthlete?.id),
    }
  );

  useBottomWidgetResize({
    setItemToResize,
    setItemsToHideInViewMode,
    gridItemId,
    widgetHeight,
    id: widget.id,
    shouldResize: readOnly,
    alwaysResize: true,
    layoutRef,
  });

  const data = useMemo<ActivityDataItem[]>(
    () =>
      (eventLogResponse?.data?._entries ?? []).map((entry) => ({
        time: dayjs(entry._time),
        subject: entry._subject,
        value: entry._text,
      })),
    [eventLogResponse?.data?._entries]
  );

  if (eventLogFetchStatus !== "idle" && eventLogIsLoading) {
    return <WidgetLoader />;
  }

  return (
    <ActivityWidgetTable
      data={data}
      title={widget.name}
      widgetBorders={widget.widgetBorders}
      resizeRef={tableRef}
    />
  );
};
