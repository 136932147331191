import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DEFAULT } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { Avatar, useFindChattableUsersQuery } from "../../graphql";
import { sortList } from "../../utils/sortList";
import { ChatMemberListItem } from "../Communication/ChatMemberListItem";
import { MembersTitleDivider } from "../Communication/styled";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

const NoOneContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 45px;
  align-items: center;
  padding: 0 15px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

interface SeenByModalProps {
  seenByAvatars: Avatar[];
  allAvatarsInChat: Avatar[];
}

export const SeenByModal = ({
  seenByAvatars,
  allAvatarsInChat,
}: SeenByModalProps) => {
  const { t } = useTranslation();
  const { sessionId, id: currentUserId, language } = useUserContext();

  const { data, loading } = useFindChattableUsersQuery({
    variables: { sessionId },
  });

  const seenByUsers = useMemo(
    () =>
      sortList(seenByAvatars, language)?.map(
        (avatar) =>
          data?.findChattableUsers?.find(
            (user) => user.id === avatar.userID
          ) ?? { ...avatar, id: avatar.userID, avatarThumbURL: avatar.thumb }
      ) ?? [],
    [seenByAvatars, language, data?.findChattableUsers]
  );

  const notSeenByUsers = useMemo(
    () =>
      sortList(allAvatarsInChat, language)
        .filter(
          (avatar) =>
            seenByAvatars.find(
              (seenByAvatar) => seenByAvatar.userID === avatar.userID
            ) === undefined && avatar.userID !== currentUserId
        )
        .map(
          (avatar) =>
            data?.findChattableUsers?.find(
              (user) => user.id === avatar.userID
            ) ?? { ...avatar, id: avatar.userID, avatarThumbURL: avatar.thumb }
        ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allAvatarsInChat, seenByAvatars, data?.findChattableUsers, language]
  );

  if (loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <div>
      <MembersTitleDivider>
        <StyledText fontSize={10} fontWeight="semibold">
          {t("seen").toUpperCase()}
        </StyledText>
      </MembersTitleDivider>

      {seenByUsers.map((chatter) => (
        <ChatMemberListItem key={chatter.id} chatter={chatter} />
      ))}

      <MembersTitleDivider>
        <StyledText fontSize={10} fontWeight="semibold">
          {t("notSeen").toUpperCase()}
        </StyledText>
      </MembersTitleDivider>

      {notSeenByUsers && notSeenByUsers.length > 0 ? (
        notSeenByUsers.map((chatter) => (
          <ChatMemberListItem key={chatter.id} chatter={chatter} />
        ))
      ) : (
        <NoOneContainer>
          <StyledText
            fontWeight="bold"
            fontSize={14}
            color={COLOR_TEXT_DEFAULT}
          >
            {t("noOne")}
          </StyledText>
        </NoOneContainer>
      )}
    </div>
  );
};
