import React from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE } from "../../colors";
import { useUserContext } from "../../contexts/User";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { SwitchAccountToast } from "../SwitchAccountToast";

import { AccessLevelDropdown } from "./AccessLevelDropdown";
import { UserDropdown } from "./UserDropdown";

const TopbarContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDivider = styled.div`
  width: 1px;
  height: 35px;
  margin: 0 10px;
  background: ${COLOR_GREY_ACTIVE};
`;

export default function Topbar() {
  const user = useUserContext();
  const isTrainer = isRealTrainer(user);

  return (
    <TopbarContainer>
      {isTrainer && (
        <>
          <AccessLevelDropdown />
          <StyledDivider />
        </>
      )}
      <UserDropdown />
      <SwitchAccountToast user={user} />
      <StyledDivider />
    </TopbarContainer>
  );
}
