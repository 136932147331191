import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MenuListProps } from "react-select";

import styled from "styled-components";

import { COLOR_GRAY, COLOR_TEXT_DARK } from "../../../colors";
import { ModalType, useModalContext } from "../../../contexts/UI/Modal";
import { Divider } from "../../PasswordForm/styles";

export const StyledShowFolderStructure = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px 15px;
  color: ${COLOR_TEXT_DARK};
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;

  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: ${COLOR_GRAY};
  }
`;

type ShowFolderStructureProps = Omit<MenuListProps, "children"> & {
  selectProps: {
    folderStructureProps?: {
      collectionId?: string;
      menuHidden?: boolean;
      setMenuHidden?: Dispatch<SetStateAction<boolean>>;
    };
  };
};

export const ShowFolderStructure = ({
  selectOption,
  selectProps,
}: ShowFolderStructureProps) => {
  const { t } = useTranslation();
  const modal = useModalContext();

  const { collectionId, setMenuHidden } =
    selectProps.folderStructureProps ?? {};

  const handleOpenModal = () => {
    setMenuHidden?.(true);
    modal.actions.openModal({
      modal: ModalType.WIDGET_FOLDER_STRUCTURE,
      params: {
        collectionId,
        selectOption,
      },
    });
  };

  useEffect(() => {
    return () => {
      setMenuHidden(false);
    };
  }, [setMenuHidden]);

  return (
    <>
      <StyledShowFolderStructure onClick={handleOpenModal}>
        {t("selectFromFolders")}
      </StyledShowFolderStructure>
      <Divider />
    </>
  );
};
