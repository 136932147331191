import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useNavigate } from "react-router-dom";

import styled, { useTheme } from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DEFAULT } from "../../colors";
import StyledText from "../../components/StyledText";
import { useAthleteProfileContext } from "../../contexts/athleteProfile";
import { useReportContext } from "../../contexts/report";
import { useReportsContext } from "../../contexts/Reports";
import { useUserContext } from "../../contexts/User";
import { ReportType, useGetTheGroupsImInListQuery } from "../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../hooks";
import { createSearchParamsWithoutUndefinedValues } from "../../utils/createSearchParamsWithoutUndefinedValues";
import { isRealTrainer } from "../../utils/isRealTrainer";
import { GroupReport } from "../GroupReport/GroupReport";
import { Loader } from "../Loader";
import { AthleteReport } from "../Report/AthleteReport";

import { EmptyReport } from "./EmptyReport";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
`;

const ReportContainer = styled.div`
  max-width: 100%;
  flex: 1;
`;

const NoReportContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const AthleteProfileReport = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isMobile } = useTheme();
    const user = useUserContext();
    const selectedAccountId = useGetSelectedAccountIdInMonitoring();

    const [searchParams] = useSearchParams();
    const activeTabId = searchParams.get("activeTabId") ?? "";
    const activeProfileTabId = searchParams.get("activeProfileTabId") ?? "";

    const { data: groupsData, loading: loadingGroupsData } =
      useGetTheGroupsImInListQuery({
        variables: {
          sessionId: user.sessionId,
          accountId: user.isFamilyMember ? undefined : selectedAccountId,
        },
      });

    const { groupTabs, loading: loadingAthleteProfile } =
      useAthleteProfileContext();
    const { report, reportLoading, reportError } = useReportContext();
    const { athleteReportTabOptions } = useAthleteProfileContext();

    const [{ selectedGroup }] = useReportsContext();

    if (groupsData?.getTheGroupsImIn.length === 0 && !loadingGroupsData) {
      return (
        <NoReportContainer>
          <StyledText
            color={COLOR_TEXT_DEFAULT}
            fontSize={14}
            fontWeight="bold"
          >
            {t("noTeamAvailable")}
          </StyledText>
        </NoReportContainer>
      );
    }

    // We need this here for case when used in mobile app and
    // selectedGroup needs to be loaded
    if (!selectedGroup?.id) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Loader color={COLOR_BLUE} size="large" />
        </div>
      );
    }

    if (groupTabs.length === 0 && !loadingAthleteProfile) {
      if (isRealTrainer(user)) {
        return (
          <NoReportContainer>
            {isMobile ? (
              <StyledText
                color={COLOR_TEXT_DEFAULT}
                fontSize={14}
                fontWeight="bold"
              >
                {t("noReportSelected")}
              </StyledText>
            ) : (
              <EmptyReport />
            )}
          </NoReportContainer>
        );
      }

      return (
        <NoReportContainer>
          <StyledText
            color={COLOR_TEXT_DEFAULT}
            fontSize={14}
            fontWeight="bold"
          >
            {t("noReportAvailable")}
          </StyledText>
        </NoReportContainer>
      );
    }

    if (reportLoading || loadingAthleteProfile || !report) {
      return (
        <div
          style={{
            flex: 1,
            paddingTop: 30,
            paddingBottom: 30,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Loader color={COLOR_BLUE} size="large" />
        </div>
      );
    }

    if (!report && reportError) {
      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            paddingTop: 30,
            paddingBottom: 30,
          }}
        >
          <StyledText>
            UNKNOWN REPORT (ID: <code>"{report?.id}"</code>)
          </StyledText>
        </div>
      );
    }

    return (
      <Container
        style={{
          ...(isMobile
            ? {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 15,
              }
            : {}),
        }}
        ref={ref}
      >
        <ReportContainer>
          {/* Athlete report */}
          {report?.reportType === ReportType.AthleteReport && (
            <AthleteReport
              // @ts-ignore
              reportTemplate={report}
              monitoringTabOptions={athleteReportTabOptions}
              setReportEditMode={() => {
                navigate({
                  pathname: `/reports/report/${report.id}`,
                  search: createSearchParamsWithoutUndefinedValues({
                    activeTabId,
                    activeProfileTabId,
                    isEditMode: "true",
                    fromMonitoring: "true",
                  }),
                });
              }}
            />
          )}

          {/* Group report */}
          {report?.reportType === ReportType.GroupReport && (
            <GroupReport
              toggleEditMode={() => {
                navigate({
                  pathname: `/reports/groupReport/${report.id}`,
                  search: createSearchParamsWithoutUndefinedValues({
                    activeTabId,
                    activeProfileTabId,
                    isEditMode: "true",
                    fromMonitoring: "true",
                  }),
                });
              }}
            />
          )}

          {/* Unknown report */}
          {report?.reportType !== ReportType.AthleteReport &&
            report?.reportType !== ReportType.GroupReport && (
              <StyledText>
                UNKNOWN REPORT TYPE (<code>{report?.reportType}</code>)
              </StyledText>
            )}
        </ReportContainer>
      </Container>
    );
  }
);
