import React, { memo } from "react";

import { COLOR_GREEN } from "../../colors";

import { SvgIconProps } from "./model";

export const TickIcon = memo(
  ({
    width = 21,
    height = 21,
    tintColor = COLOR_GREEN,
    ...props
  }: SvgIconProps) => (
    <svg
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <path d="M6 10L9 13L15 7" stroke={tintColor} strokeWidth="1.5" />
    </svg>
  )
);
