import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const PlusInRoundIcon = memo(
  ({
    width = 21,
    height = 21,
    isActive = false,
    backgroundColor,
    ...props
  }: SvgIconProps) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <circle
        cx="10.5"
        cy="10.5"
        r="10"
        fill={
          isActive ? "#E6E8EB" : backgroundColor ? backgroundColor : "#FFFFFF"
        }
        stroke="#8A939B"
      />
      <path d="M5 10.5H16" stroke="#8A939B" />
      <path d="M10.5 16V5" stroke="#8A939B" />
    </svg>
  )
);
