import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE } from "../../colors";
import { useNotificationsContext } from "../../contexts/notifications";
import { useModalContext } from "../../contexts/UI/Modal";
import { TopModalType, useTopModalContext } from "../../contexts/UI/TopModal";
import { useUserContext } from "../../contexts/User";
import { useMyAthletesQuery } from "../../services/hooks";
import {
  postAvatar,
  updateUserProfileComplex,
} from "../../services/settings/userProfile";
import { editActiveAccount } from "../../utils/switchAccounts";
import Avatar from "../Avatar";
import { BasicButton, ButtonColor, GeneralButton } from "../Button/";
import { ControlledInput } from "../HookForm/ControlledInput";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

const ChangeProfileWrapper = styled.div`
  cursor: pointer;
  z-index: 999;
`;

const ChangeProfileButton = styled(BasicButton)`
  margin-bottom: 35px;
`;

const ChangeProfileImageText = styled(StyledText)`
  margin-top: 15px;
  font-size: 12px;
  color: ${COLOR_BLUE};
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

const InputsWrapper = styled.div`
  width: calc(100% - 60px);
  margin: 0 30px;
`;

const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 25px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 55px);
  background: white;
  padding-top: 30px;
  height: 60px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 25px;
  margin-left: 15px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
  flex-direction: column;
  width: 100%;
`;

type EditProfileModalProps = {
  isEditableByUser: boolean;
  refetchFamilyMembers: () => void;
  userRefetch: () => void;
  childBasicProfile?: {
    profileImageUrl?: string;
    focusedAthlete?: string;
    name: string;
    firstName?: string;
    lastName?: string;
  };
};

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  username: string;
}

export const EditProfileModal = ({
  isEditableByUser,
  childBasicProfile,
  refetchFamilyMembers,
  userRefetch,
}: EditProfileModalProps) => {
  const { t } = useTranslation();
  const [uploadedImage, setUploadedImage] = useState("");
  const [isSavingAndRefreshingData, setIsSavingAndRefreshingData] =
    useState(false);

  const { actions: modalActions } = useModalContext();
  const { actions: modalTopActions } = useTopModalContext();

  const { showSuccessNotification, showErrorNotification } =
    useNotificationsContext();
  const {
    profileImageUrl: childImageUrl,
    name,
    firstName: childFirstName,
    lastName: childLastName,
    focusedAthlete,
  } = childBasicProfile || {};
  const [childFirstNameFallback, childLastNameFallback] =
    name?.split(" ") ?? [];

  const {
    profileImageUrl: userImageUrl,
    firstName: userFirstName,
    lastName: userLastName,
    sessionId,
    id,
    phoneNumber,
    emailAddress,
    userName,
    actions: { setUser, refetchUserProfile },
  } = useUserContext();

  const { refetch: refetchAthletes } = useMyAthletesQuery({
    session: { sessionId },
  });

  const firstName = isEditableByUser
    ? childFirstName ?? childFirstNameFallback
    : userFirstName;

  const lastName = isEditableByUser
    ? childLastName ?? childLastNameFallback
    : userLastName;

  const profileImageUrl = isEditableByUser ? childImageUrl : userImageUrl;

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          const binaryStr = reader.result;

          const base64 = btoa(
            new Uint8Array(binaryStr as ArrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          if (base64) {
            const url = `data:${file.type};base64,${base64}`;
            setIsSavingAndRefreshingData(true);
            const uploadedImage = await postAvatar(
              sessionId,
              url,
              file.name,
              isEditableByUser && focusedAthlete
            );

            setUploadedImage(uploadedImage);

            if (isEditableByUser) {
              refetchFamilyMembers();
              refetchAthletes();
            }

            await refetchUserProfile({ variables: { sessionId } });
            setIsSavingAndRefreshingData(false);
          }
        };
      });
    },
    [
      sessionId,
      refetchUserProfile,
      setIsSavingAndRefreshingData,
      isEditableByUser,
      focusedAthlete,
      refetchFamilyMembers,
      refetchAthletes,
    ]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const { watch, ...methods } = useForm<FormState>();
  const data = watch();

  const onPasswordInputClick = () => {
    modalTopActions.openTopModal({
      modal: TopModalType.CHANGE_PASSWORD,
      title: t("changePassword"),
    });
  };

  const onSaveButtonClick = async () => {
    try {
      await updateUserProfileComplex(
        data,
        sessionId,
        isEditableByUser ? focusedAthlete : id
      );
      await editActiveAccount({
        fullName: `${data.firstName} ${data.lastName}`,
      });

      !isEditableByUser &&
        setUser({
          firstName: data.firstName,
          lastName: data.lastName,
          emailAddress: data.email,
          phoneNumber: data.phoneNumber,
          userName: data.username,
        });

      showSuccessNotification(t("updateProfileSuccess"));

      if (isEditableByUser) {
        refetchFamilyMembers();
        userRefetch();
        refetchAthletes();
      }

      modalActions.closeModal();
    } catch {
      showErrorNotification(t("updateProfileError"));
    }
  };

  return (
    <ModalContentWrapper>
      <ContentWrapper>
        {isSavingAndRefreshingData ? (
          <Loader style={{ minHeight: 115 }} size={115} />
        ) : (
          <Avatar
            source={{ uri: uploadedImage || profileImageUrl }}
            size={115}
            userName={`${firstName} ${lastName}`}
          />
        )}
        <ChangeProfileWrapper {...getRootProps()}>
          <label htmlFor="avatar-button-file">
            <ChangeProfileButton>
              <ChangeProfileImageText fontWeight="bold">
                {t("changeImage").toUpperCase()}
              </ChangeProfileImageText>
            </ChangeProfileButton>
          </label>
          <input
            {...getInputProps()}
            type="file"
            style={{ display: "none" }}
            accept="image/jpeg"
            id="avatar-button-file"
          />
        </ChangeProfileWrapper>

        <FormProvider watch={watch} {...methods}>
          <InputsWrapper>
            <ControlledInput
              name="firstName"
              label={t("firstName")}
              defaultValue={firstName as string}
            />

            <ControlledInput
              name="lastName"
              label={t("lastName")}
              defaultValue={lastName as string}
            />
            {!isEditableByUser && (
              <>
                <ControlledInput
                  name="email"
                  label={t("email")}
                  defaultValue={emailAddress}
                />
                <ControlledInput
                  name="phoneNumber"
                  label={t("phoneNumber")}
                  defaultValue={phoneNumber}
                />
                <ControlledInput
                  name="username"
                  label={t("username")}
                  defaultValue={userName}
                />

                <ControlledInput
                  label={t("password")}
                  name="password"
                  defaultValue="********"
                  onClick={onPasswordInputClick}
                  type="password"
                />
              </>
            )}
          </InputsWrapper>
        </FormProvider>
      </ContentWrapper>
      <SpacingWrapper>
        <ButtonWrapper>
          <GeneralButton
            colorVariant={ButtonColor.Gray}
            label={t("cancel")}
            onClick={modalActions.closeModal}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <GeneralButton
            colorVariant={ButtonColor.Blue}
            label={t("done")}
            onClick={onSaveButtonClick}
          />
        </ButtonWrapper>
      </SpacingWrapper>
    </ModalContentWrapper>
  );
};
