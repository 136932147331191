import React, { useMemo } from "react";

import dayjs from "dayjs";
import { useFormikContext } from "formik";
import _random from "lodash/random";
import _rangeRight from "lodash/rangeRight";
import styled from "styled-components";

import { AcuteChronicWidgetFormValue } from "../Editor/model";

import { AcuteChronicWidgetChart, AcuteChronicWidgetChartProps } from "./Chart";

const Container = styled.div`
  min-height: 330px;
`;

export function AcuteChronicWidgetPreview() {
  const { values } = useFormikContext<AcuteChronicWidgetFormValue>();

  const today = dayjs().startOf("day");
  const ts = _rangeRight(30).map((i) => today.subtract(i, "days"));

  const dummyData: AcuteChronicWidgetChartProps["data"] = useMemo(() => {
    return {
      acRatio: ts.map((t) => ({ t: t.valueOf(), y: _random(0.5, 1.8, true) })),
      acuteLine: ts.map((t) => ({ t: t.valueOf(), y: _random(40, 320) })),
      chronicLine: ts.map((t) => ({ t: t.valueOf(), y: _random(40, 320) })),
      dailyBars: ts.map((t) => ({ t: t.valueOf(), y: _random(240, 380) })),
    };
    // We do not want to regenerate dummy data on change of any indicator because it is flickering then
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <AcuteChronicWidgetChart data={dummyData} widget={values} />
    </Container>
  );
}
