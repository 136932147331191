import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";

import { ApolloError } from "@apollo/client";

import { ReportQuery, useReportQuery } from "../../graphql";
import { parsePreferences } from "../../utils/preferences";
import { useUserContext } from "../User";

type ReportContextValue = {
  report: (ReportQuery["reportTemplate"] & Record<string, any>) | null;
  reportPreferences: Record<string, any>;
  reportLoading: boolean;
  reportQueryCalled?: boolean;
  reportError?: ApolloError;
};

const ReportContext = createContext<ReportContextValue>({
  report: null,
  reportPreferences: {},
  reportLoading: false,
  reportQueryCalled: false,
});

export function ReportContextProvider({
  reportId,
  children,
}: PropsWithChildren<{ reportId?: string }>) {
  const { sessionId, language } = useUserContext();

  const reportQueryVariables = useMemo(
    () => ({
      sessionId,
      reportId,
      language,
    }),
    [language, reportId, sessionId]
  );

  const {
    data: reportData,
    loading: reportLoading,
    previousData,
    error: reportError,
    called: reportQueryCalled,
  } = useReportQuery({
    variables: reportQueryVariables,
    skip: !reportId,
  });

  const reportTemplate =
    (reportData?.reportTemplate || previousData?.reportTemplate) ?? null;

  const { report, reportPreferences } = useMemo<
    Pick<ReportContextValue, "report" | "reportPreferences">
  >(() => {
    const reportPreferences = parsePreferences(reportTemplate?.preferences, {});

    return {
      report: reportTemplate && { ...reportTemplate, ...reportPreferences },
      reportPreferences,
    };
  }, [reportTemplate]);

  return (
    <ReportContext.Provider
      value={{
        report,
        reportPreferences,
        reportQueryCalled,
        reportLoading,
        reportError,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

export function useReportContext() {
  return useContext(ReportContext);
}
