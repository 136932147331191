import React, { memo } from "react";

export const PreviousIcon = memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path d="M14 3L6.5 10.5L14 18" stroke="#3C434C" strokeWidth="1.5" />
  </svg>
));
