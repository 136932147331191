import React, { useMemo, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_WHITE } from "../../../colors";
import { useNotificationsContext } from "../../../contexts/notifications";
import { useModalContext } from "../../../contexts/UI/Modal";
import { useUserContext } from "../../../contexts/User";
import {
  useCopyWorkoutsToCalendar,
  useGenerateCalendar,
} from "../../../services/hooks";
import { isRealTrainer } from "../../../utils/isRealTrainer";
import { BottomBar } from "../../BottomBar";
import { ButtonColor } from "../../Button/";
import { getTime } from "../utils";

import { DateTime } from "./DateTime";
import { Header } from "./Header";
import { TeamsOrAthletesSelect } from "./TeamOrAthletesSelect";
import { TrainingDaysSelector } from "./TrainingDaysSelector";
import { processWeekSessions } from "./utils/processWeekSessions";
import { WorkoutLines } from "./WorkoutLines";

type DateTimeFormState = {
  date: Dayjs;
  time: string;
};

const Wrapper = styled.div`
  padding-bottom: 66px;
`;

const BottomWrapper = styled.div`
  background: ${COLOR_WHITE};
  padding: 15px 20px;
  border-top: 1px solid ${COLOR_GREY_ACTIVE};
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
`;

export const AddToCalendar = ({
  title,
  period,
  isTeamPractice,
  isProgram,
  workoutLines,
  sessionGuid,
  programDetails,
}) => {
  const { t } = useTranslation();
  const user = useUserContext();
  const { showErrorNotification, showSuccessNotification } =
    useNotificationsContext();
  const {
    actions: { closeModal },
  } = useModalContext();

  const [selectedItemsToShow, setSelectedItemsToShow] = useState([]);
  const [defaultDays, setDefaultDays] = useState([]);

  const {
    mutateAsync: copyWorkoutsToCalendar,
    isLoading: copyWorkoutIsLoading,
  } = useCopyWorkoutsToCalendar();

  const {
    mutateAsync: generateCalendar,
    isLoading: generateCalendarIsLoading,
  } = useGenerateCalendar();

  const { handleSubmit, ...formMethods } = useForm<DateTimeFormState>({
    mode: "all",
    defaultValues: {
      date: dayjs(),
      time: getTime(dayjs().add(1, "hour").minute(0)),
    },
  });

  const startDate = formMethods.watch("date");

  const createParams = (params, isTeamPractice, selectedItemsToShow, user) => {
    return isTeamPractice
      ? selectedItemsToShow.map((selectedItem) => ({
          ...params,
          _teamAthlete: selectedItem,
        }))
      : [
          {
            ...params,
            _focusedAthletes: isRealTrainer(user)
              ? selectedItemsToShow
              : [user?.id],
          },
        ];
  };

  const handleCopyWorkout = async (date, time) => {
    const [hour, minute] = time.split(":");
    const dateTimestamp = dayjs(date).hour(hour).minute(minute).unix() * 1000;

    const params = {
      _items: [
        {
          guid: sessionGuid,
          date: dateTimestamp,
        },
      ],
    };

    const copyParams = createParams(
      params,
      isTeamPractice,
      selectedItemsToShow,
      user
    );

    await Promise.all(
      copyParams.map((params) => copyWorkoutsToCalendar({ user, params }))
    );
  };

  const handleGenerateProgram = async () => {
    const params = {
      _firstDayDDMMYYYY: defaultDays[0].date.format("DD.MM.YYYY"),
      _programGuid: programDetails.guid,
      _regularity: {
        _weekDaysFreysiStyle: defaultDays.map((day) => ({
          _dayIndex1to7: day.defaultDayOfWeek + 1,
          _timeHHMM: day.time,
          _weekDayCode: dayjs(day.date).locale("en").format("dddd"),
        })),
      },
    };

    const generateParams = createParams(
      params,
      isTeamPractice,
      selectedItemsToShow,
      user
    );

    await Promise.all(
      generateParams.map((params) => generateCalendar({ user, params }))
    );
  };

  const onSubmit = async ({ date, time }) => {
    try {
      if (!isProgram) {
        await handleCopyWorkout(date, time);
      } else {
        await handleGenerateProgram();
      }
      closeModal();
      showSuccessNotification(
        t(isProgram ? "programAddedSuccessfully" : "workoutAddedSuccessfully")
      );
    } catch {
      showErrorNotification(
        t(isProgram ? "cannotAddProgram" : "cannotAddWorkout")
      );
    }
  };

  const { ...methods } = formMethods;

  const weeks = useMemo(() => {
    if (!programDetails?._sessions) return [];

    return programDetails._sessions.reduce((acc, session) => {
      // array index starts with 0
      const weekNum = Math.ceil(session._dayOfProgram / 7) - 1;

      if (!acc[weekNum]) acc[weekNum] = [];
      acc[weekNum].push(session);
      return acc;
    }, []);
  }, [programDetails]);

  useEffect(() => {
    const weekSessions = weeks?.[0] || [];

    const defaultWeekDays = [];

    const weekDaysFreysiStyle =
      programDetails?._programRegularity?._weekDaysFreysiStyle || [];
    weekDaysFreysiStyle.forEach((day: { _dayIndex1to7: number }) => {
      const weekDay = day._dayIndex1to7 - 1;
      if (defaultWeekDays.indexOf(weekDay) === -1) {
        defaultWeekDays.push(weekDay);
      }
    });

    const newDays = processWeekSessions(
      weekSessions,
      startDate,
      defaultWeekDays
    );

    setDefaultDays(newDays);
  }, [
    programDetails?._programRegularity?._weekDaysFreysiStyle,
    startDate,
    weeks,
  ]);

  const updateDay = (dayIndex, updates) => {
    const newDays = [...defaultDays];
    newDays[dayIndex] = {
      ...newDays[dayIndex],
      ...updates,
    };
    setDefaultDays(newDays);
  };

  const onDayPress = (dayIndex, dayOfWeek, newDate) => {
    updateDay(dayIndex, { date: newDate, dayOfWeek });
  };

  const onTimeChange = (dayIndex, newTime) => {
    updateDay(dayIndex, { time: newTime });
  };

  return (
    <Wrapper>
      <Header title={title} period={period} />
      {isRealTrainer(user) ? (
        <TeamsOrAthletesSelect
          isTeamPractice={isTeamPractice}
          selectedItemsToShow={selectedItemsToShow}
          setSelectedItemsToShow={setSelectedItemsToShow}
        />
      ) : null}
      <DateTime methods={methods} isProgram={isProgram} />
      {!isProgram && workoutLines?.length ? (
        <WorkoutLines workoutLines={workoutLines} />
      ) : null}
      {isProgram ? (
        <TrainingDaysSelector
          days={defaultDays}
          onDayPress={onDayPress}
          onTimeChange={onTimeChange}
        />
      ) : null}
      <BottomWrapper>
        <BottomBar
          secondary={{
            text: t("cancel"),
            onClick: closeModal,
          }}
          primary={{
            text: t("addToCalendar"),
            onClick: handleSubmit(onSubmit),
            disabled: isRealTrainer(user) ? !selectedItemsToShow.length : false,
            variant: ButtonColor.Blue,
            isLoading: copyWorkoutIsLoading || generateCalendarIsLoading,
          }}
        />
      </BottomWrapper>
    </Wrapper>
  );
};
