import { TFunction } from "react-i18next";

import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import {
  DashStyleValue,
  PointOptionsObject,
  SeriesBarOptions,
  SeriesColumnOptions,
  SeriesLineOptions,
  SeriesSplineOptions,
  SeriesTooltipOptionsObject,
  YAxisOptions,
} from "highcharts";

import {
  COLOR_GREEN,
  COLOR_TEXT_DARK,
  COLOR_TEXT_TABLE_LABEL,
} from "../../../../colors";
import { AggregateFunction } from "../../../../graphql";
import { CombineTestResult } from "../../../../services";
import { getPeriodStartEndUnit } from "../../../../utils/getPeriodStartEndUnit";
import { roundToTwo } from "../../../../utils/number";
import {
  isAttendanceCollection,
  isSoccerStatsCollection,
  isTestCollection,
} from "../../../../utils/reports";
import { getAthleteInitials } from "../../../../utils/statistics";
import { StatisticsData } from "../../models";
import {
  ChartType,
  TEXT_SOCCER_STATS_ATTRIBUTES,
  TimeLinePeriod,
} from "../model";

import { generatePerformerCategories } from "./generateCategories";
import { GroupedCategoriesOptions } from "./generateChartOptions";
import { ChartItemAttributes } from "./prepareAttributes";

dayjs.extend(isoWeek);

type GenerateDatetimeSeriesParams = {
  chartType: ChartType;
  attributes: ChartItemAttributes[];
  stats: StatisticsData;
  isGroupReport: boolean;
  includeZero?: boolean;
  isMobile?: boolean;
  t: TFunction<"common", undefined>;
};

const AVERAGE_SHORT_VERSION_ID = "Avg";

export function generateDatetimeSeries({
  attributes,
  stats,
  chartType,
  isGroupReport,
  includeZero = false,
  isMobile = false,
  t,
}: GenerateDatetimeSeriesParams): {
  yAxis: YAxisOptions[];
  series: (
    | SeriesColumnOptions
    | SeriesBarOptions
    | SeriesLineOptions
    | SeriesSplineOptions
  )[];
} {
  if (!stats) {
    return { yAxis: [], series: [] };
  }

  const yAxis: YAxisOptions[] = [];
  const shortenAthleteName = isMobile && stats.perPerformer.length > 5;

  const series = stats.perPerformer.flatMap((athlete) =>
    attributes.map((measurement) => {
      const yAxisIndex = isGroupReport
        ? getOrCreateYAxisIndexByChartAttribute(yAxis, measurement, includeZero)
        : getOrCreateYAxisIndexByChartAttributeUnit(
            yAxis,
            measurement,
            includeZero
          );

      const stats = athlete.items?.find(
        ({ template, attribute, aggregateFunction }) =>
          template.id === measurement.measurementId &&
          attribute.id === measurement.attributeId &&
          aggregateFunction === measurement.aggregation
      );

      const data =
        stats?.values.map<PointOptionsObject>((value) => ({
          x: value.time,
          y: roundToTwo(value.val),
          custom: { ctr: value.ctr, text: value.txt },
        })) ?? [];

      return {
        id: `athleteId:${athlete.id}:templateId:${measurement.measurementId}:attributeId:${measurement.attributeId}:aggregation:${measurement.aggregation}`,
        name: isGroupReport
          ? shortenAthleteName
            ? getAthleteInitials(athlete.name)
            : athlete.name
          : getTemplateSeriesName({
              collectionId: measurement.collectionId,
              templateName: stats?.template?.name || measurement.name,
              unitName: stats?.attribute?.unitName || measurement.unit,
              t,
            }),
        data: data.sort((a, b) => a.x - b.x),
        yAxis: yAxisIndex,
        type: chartType,
        ...(measurement.chartTypeOverride
          ? { type: measurement.chartTypeOverride }
          : {}),
        ...(measurement.chartTypeOverride === "line" ? { zIndex: 100 } : {}),
        tooltip: {
          pointFormatter() {
            const { text, ctr }: { text?: string; ctr?: CombineTestResult } =
              this.options.custom ?? {};

            return `
                <span style="color:${this.color}">\u25CF</span> ${
                  this.series.name
                }: <b>${text || this.y}</b><br/>
                ${(ctr?._subResults ?? [])
                  .map((result) => {
                    return `- ${result._name}: ${
                      result._txt || Math.round(parseInt(result._val, 10))
                    }<br/>`;
                  })
                  .join("")}
              `;
          },
        } as SeriesTooltipOptionsObject,
      };
    })
  );

  return { series, yAxis };
}

type GeneratePeriodCategorySeriesParams = {
  attributes: ChartItemAttributes[];
  stats: StatisticsData;
  teamAverageStats: StatisticsData;
  timeLinePeriod: TimeLinePeriod;
  fromDate: Dayjs;
  categoriesLength: number;
  chartType: ChartType;
  isGroupReport: boolean;
  includeZero?: boolean;
  isMobile?: boolean;
  t: TFunction<"common", undefined>;
};

export function generatePeriodCategorySeries({
  attributes,
  timeLinePeriod,
  fromDate,
  stats,
  teamAverageStats,
  categoriesLength,
  chartType,
  isGroupReport,
  includeZero = false,
  isMobile = false,
  t,
}: GeneratePeriodCategorySeriesParams): {
  yAxis: YAxisOptions[];
  series: (
    | SeriesColumnOptions
    | SeriesBarOptions
    | SeriesLineOptions
    | SeriesSplineOptions
  )[];
} {
  const yAxis: YAxisOptions[] = [];
  const fromDateStartOfDay = dayjs(fromDate)
    .startOf(
      timeLinePeriod !== TimeLinePeriod.NONE
        ? getPeriodStartEndUnit(timeLinePeriod)
        : "day"
    )
    .valueOf();
  const shortenAthleteName = isMobile && stats.perPerformer.length > 5;

  const series = [
    ...stats.perPerformer.flatMap((athlete) =>
      attributes.map((measurement) => {
        const yAxisIndex = isGroupReport
          ? getOrCreateYAxisIndexByChartAttribute(
              yAxis,
              measurement,
              includeZero
            )
          : getOrCreateYAxisIndexByChartAttributeUnit(
              yAxis,
              measurement,
              includeZero
            );

        const stats = athlete.items?.find(
          ({ template, attribute, aggregateFunction }) =>
            template.id === measurement.measurementId &&
            (attribute.id === measurement.attributeId ||
              TEXT_SOCCER_STATS_ATTRIBUTES.some((txt) =>
                template.id.includes(txt)
              )) &&
            aggregateFunction === measurement.aggregation
        );

        const data: PointOptionsObject[] = new Array(categoriesLength).fill(
          null
        );

        if (timeLinePeriod !== TimeLinePeriod.NONE) {
          stats?.values
            .sort((a, b) => a.time - b.time)
            .forEach((value) => {
              // If you query one month / week data you do not get time
              const valueTime = value.time ? dayjs(value.time) : undefined;

              const categoryIndex =
                valueTime?.diff(fromDateStartOfDay, timeLinePeriod) || 0;

              // The issue is AVERAGE does not return time if we have week periodization and report is limited to one week
              // More generaly it seems time is not added if only one AVERAGE is returned
              // It causes overflow of array and drop of app
              if (categoryIndex < data.length) {
                data[categoryIndex] = {
                  y: roundToTwo(value.val),
                  custom: { txt: value.txt },
                };
              }
            });
        } else {
          data[0] = {
            y: roundToTwo(stats?.values?.[0]?.val),
            custom: { txt: stats?.values?.[0]?.txt },
          };
        }

        const agg =
          (stats?.aggregateFunction || measurement.aggregation) ===
          AggregateFunction.Average
            ? AVERAGE_SHORT_VERSION_ID
            : stats?.aggregateFunction || measurement.aggregation;
        const aggLabel =
          attributes.length > 1 &&
          !isAttendanceCollection(measurement.collectionId) &&
          agg
            ? ` (${t(`aggregation${agg}`, {
                defaultValue: agg,
              })})`
            : "";
        const unitLabel = !isSoccerStatsCollection(measurement.collectionId)
          ? stats?.attribute?.unitName || measurement.unit || ""
          : "";

        return {
          id: `athleteId:${athlete.id}`,
          name: isGroupReport
            ? `${
                shortenAthleteName
                  ? getAthleteInitials(athlete.name)
                  : athlete.name
              }${aggLabel}`
            : getTemplateSeriesName({
                collectionId: measurement.collectionId,
                templateName: stats?.template?.name || measurement.name,
                unitName: stats?.attribute?.unitName || measurement.unit,
                aggregateFunction: attributes.length > 1 ? agg : undefined,
                t,
              }),
          data,
          yAxis: yAxisIndex,
          type: chartType,

          ...(measurement.chartTypeOverride
            ? { type: measurement.chartTypeOverride }
            : {}),
          ...(measurement.chartTypeOverride === "line" ? { zIndex: 100 } : {}),
          connectNulls: true,
          tooltip: {
            pointFormatter() {
              return `<strong style="color: ${this.series.color};">${
                this.series.name
              }</strong>: ${this.custom?.txt || this.y} ${
                this.custom?.txt ? "" : unitLabel
              }<br/>`;
            },
          },
        };
      })
    ),

    ...(teamAverageStats?.perPerformer?.[0]?.items ?? [])
      .filter(({ template }) =>
        attributes.some(({ measurementId }) => measurementId === template.id)
      )
      .map((stats) => {
        const measurement = attributes.find(
          ({ measurementId }) => measurementId === stats.template.id
        );
        const yAxisIndex = isGroupReport
          ? getOrCreateYAxisIndexByChartAttribute(
              yAxis,
              measurement,
              includeZero
            )
          : getOrCreateYAxisIndexByChartAttributeUnit(
              yAxis,
              measurement,
              includeZero
            );

        const data = new Array(categoriesLength).fill(null);

        if (timeLinePeriod !== TimeLinePeriod.NONE) {
          stats?.values
            .sort((a, b) => a.time - b.time)
            .forEach((value) => {
              const valueTime = dayjs(value.time);

              const categoryIndex = valueTime.diff(
                fromDateStartOfDay,
                timeLinePeriod
              );

              // The issue is AVERAGE does not return time if we have week periodization and report is limited to one week
              // More generaly it seems time is not added if only one AVERAGE is returned
              // It causes overflow of array and drop of app
              if (categoryIndex < data.length) {
                data[categoryIndex] = roundToTwo(value.val);
              }
            });
        } else {
          data[0] = roundToTwo(stats?.values?.[0]?.val);
        }

        const agg =
          (stats?.aggregateFunction || measurement.aggregation) ===
          AggregateFunction.Average
            ? AVERAGE_SHORT_VERSION_ID
            : stats?.aggregateFunction || measurement.aggregation;

        const aggLabel =
          attributes.length > 1 && agg
            ? ` (${t(`aggregation${agg}`, {
                defaultValue: agg,
              })})`
            : "";
        const unitLabel = !isSoccerStatsCollection(measurement.collectionId)
          ? stats?.attribute?.unitName || measurement.unit || ""
          : "";

        return {
          id: `athleteId:teamAverage:templateId:${measurement.measurementId}:attributeId:${measurement.attributeId}:aggregation:${AggregateFunction.Average}`,
          name: isGroupReport
            ? `${
                shortenAthleteName
                  ? getAthleteInitials(t("teamAverage"))
                  : t("teamAverage")
              }${aggLabel}`
            : `${getTemplateSeriesName({
                collectionId: measurement.collectionId,
                templateName: stats?.template?.name || measurement.name,
                unitName: stats?.attribute?.unitName || measurement.unit,
                aggregateFunction: attributes.length > 1 ? agg : undefined,
                t,
              })} ${t("team")}`,
          data,
          yAxis: yAxisIndex,
          type: measurement.teamAverageChartType || chartType,
          dashStyle: "Dash",
          color: COLOR_GREEN,

          ...(measurement.chartTypeOverride
            ? { type: measurement.chartTypeOverride }
            : {}),
          ...(measurement.chartTypeOverride === "line" ? { zIndex: 100 } : {}),
          connectNulls: true,
          tooltip: {
            pointFormatter() {
              return `<strong style="color: ${this.series.color};">${this.series.name}</strong>: ${this.y} ${unitLabel}<br/>`;
            },
          },
        };
      }),
  ];

  return {
    series: series.filter(({ data }) =>
      data.some((value) => value !== null)
    ) as any,
    yAxis,
  };
}

type GeneratePerformerPeriodCategorySeriesParams = {
  attributes: ChartItemAttributes[];
  stats: StatisticsData;
  teamAverageStats: StatisticsData;
  chartType: ChartType;
  includeZero?: boolean;
  performerCategories: GroupedCategoriesOptions["xAxis"]["categories"];
  hasTeamAverage?: boolean;
  timeLinePeriod: TimeLinePeriod;
  fromDate: Dayjs;
  t: TFunction<"common", undefined>;
};

export function generatePerformerPeriodCategorySeries({
  attributes,
  stats,
  teamAverageStats,
  chartType,
  includeZero = false,
  performerCategories,
  hasTeamAverage,
  fromDate,
  timeLinePeriod,
  t,
}: GeneratePerformerPeriodCategorySeriesParams): {
  yAxis: YAxisOptions[];
  series: (
    | SeriesColumnOptions
    | SeriesBarOptions
    | SeriesLineOptions
    | SeriesSplineOptions
  )[];
} {
  // None is allowed only for performers graph
  if (!stats || timeLinePeriod === TimeLinePeriod.NONE) {
    return { yAxis: [], series: [] };
  }

  function getSerieId(
    measurementId: string,
    attributeId: string,
    aggregation: string,
    isTeamAverage: boolean
  ): string {
    return `templateId:${measurementId}:attributeId:${attributeId}:aggregation:${aggregation}:isTeamAverage:${isTeamAverage}`;
  }

  const mapAthleteGuidToCategoryIndex = new Map(
    performerCategories[0]?.categories.map((guid, index) => [guid, index])
  );

  const yAxis: YAxisOptions[] = [];

  const seriesIdAttributeMap = new Map(
    attributes.flatMap((attr) =>
      attr.showTeamAverage
        ? [
            [
              getSerieId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                false
              ),
              attr,
            ],
            [
              getSerieId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                true
              ),
              attr,
            ],
          ]
        : [
            [
              getSerieId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                false
              ),
              attr,
            ],
          ]
    )
  );

  const series = Array.from(seriesIdAttributeMap.entries()).map(
    ([seriesId, measurement]) => {
      const { name, collectionId, aggregation, unit, chartTypeOverride } =
        measurement;
      const yAxisIndex = getOrCreateYAxisIndexByChartAttributeUnit(
        yAxis,
        measurement,
        includeZero
      );
      const agg =
        aggregation === AggregateFunction.Average
          ? AVERAGE_SHORT_VERSION_ID
          : aggregation;

      const countOfValues = performerCategories.length
        ? performerCategories.length * performerCategories[0].categories?.length
        : 0;

      return {
        id: seriesId,
        name: getTemplateSeriesName({
          collectionId,
          templateName: name,
          unitName: unit,
          aggregateFunction: attributes.length > 1 ? agg : undefined,
          t,
        }),
        data: new Array(countOfValues).fill(null) as PointOptionsObject[],
        yAxis: yAxisIndex,
        type: chartType,
        dashStyle: "Solid" as DashStyleValue,

        ...(chartTypeOverride ? { type: chartTypeOverride } : {}),
        ...(chartTypeOverride === "line" ? { zIndex: 100 } : {}),
        connectNulls: true,
        tooltip: {
          pointFormatter() {
            return `<strong style="color: ${this.series.color};">${
              this.series.name
            }</strong>: ${this.custom?.txt || this.y} ${
              this.custom?.txt ? "" : unit || ""
            }<br/>`;
          },
        },
      };
    }
  );

  const seriesMapById = new Map(series.map((serie) => [serie.id, serie]));
  const fromDateStartOfDay = dayjs(fromDate)
    .startOf(getPeriodStartEndUnit(timeLinePeriod))
    .valueOf();
  const countOfAthletes = hasTeamAverage
    ? stats.perPerformer.length + 1
    : stats.perPerformer.length;

  stats.perPerformer.forEach((athlete) => {
    athlete.items?.forEach((stat) => {
      stat.values.forEach((value) => {
        const currentIndex = getIndexOfSpecificAthleteInSpecificPeriod({
          value,
          fromDateStartOfDay,
          timeLinePeriod,
          mapAthleteGuidToCategoryIndex,
          countOfAthletes,
          athleteId: athlete.id,
        });

        const seriesId = getSerieId(
          stat.template.id,
          stat.attribute.id,
          stat.aggregateFunction,
          false
        );
        const series = seriesMapById.get(seriesId);

        if (series) {
          series.data[currentIndex] = {
            y: roundToTwo(value.val),
            custom: { txt: value.txt },
          };
        }
      });
    });
  });

  hasTeamAverage &&
    teamAverageStats?.perPerformer?.[0]?.items?.forEach((teamStat) => {
      teamStat.values.map((value) => {
        const currentIndex = getIndexOfSpecificAthleteInSpecificPeriod({
          value,
          fromDateStartOfDay,
          timeLinePeriod,
          mapAthleteGuidToCategoryIndex,
          countOfAthletes,
          athleteId: "teamAverage",
        });

        const seriesId = getSerieId(
          teamStat.template?.id,
          teamStat.attribute?.id,
          teamStat.aggregateFunction,
          true
        );
        const series = seriesMapById.get(seriesId);
        const attributeItem = seriesIdAttributeMap.get(seriesId);

        if (series && attributeItem.showTeamAverage) {
          series.data[currentIndex] = {
            y: roundToTwo(value.val),
            custom: { txt: value.txt },
            color: COLOR_GREEN,
          };
          series.type = attributeItem.teamAverageChartType
            ? attributeItem.teamAverageChartType
            : series.type;
          series.dashStyle = "Dash";
          series.zIndex = 100;
        }
      });
    });

  return {
    series: series.filter(({ data }) => data.some((value) => value !== null)),
    yAxis,
  };
}

const getIndexOfSpecificAthleteInSpecificPeriod = ({
  value,
  fromDateStartOfDay,
  timeLinePeriod,
  mapAthleteGuidToCategoryIndex,
  countOfAthletes,
  athleteId,
}) => {
  const valueTime = dayjs(value.time);

  const periodIndex = valueTime.diff(fromDateStartOfDay, timeLinePeriod);
  const athleteIndex = mapAthleteGuidToCategoryIndex.get(athleteId);

  return periodIndex * countOfAthletes + athleteIndex;
};

type GeneratePerformerCategorySeriesParams = {
  attributes: ChartItemAttributes[];
  stats: StatisticsData;
  teamAverageStats: StatisticsData;
  chartType: ChartType;
  includeZero?: boolean;
  performerCategories: ReturnType<typeof generatePerformerCategories>;
  t: TFunction<"common", undefined>;
};

export function generatePerformerCategorySeries({
  attributes,
  stats,
  teamAverageStats,
  chartType,
  includeZero = false,
  performerCategories,
  t,
}: GeneratePerformerCategorySeriesParams): {
  yAxis: YAxisOptions[];
  series: (
    | SeriesColumnOptions
    | SeriesBarOptions
    | SeriesLineOptions
    | SeriesSplineOptions
  )[];
} {
  if (!stats) {
    return { yAxis: [], series: [] };
  }

  const mapAthleteGuidToCategoryIndex = new Map(
    performerCategories.categories.map((guid, index) => [guid, index])
  );

  const yAxis: YAxisOptions[] = [];

  const seriesIdAttributeMap = new Map(
    attributes.flatMap((attr) =>
      attr.showTeamAverage && attr.teamAverageChartType === ChartType.LINE
        ? [
            [
              getSeriesId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                false
              ),
              attr,
            ],
            [
              getSeriesId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                true
              ),
              attr,
            ],
          ]
        : [
            [
              getSeriesId(
                attr.measurementId,
                attr.attributeId,
                attr.aggregation,
                false
              ),
              attr,
            ],
          ]
    )
  );

  const series = Array.from(seriesIdAttributeMap.entries()).map(
    ([seriesId, measurement]) => {
      const { name, collectionId, aggregation, unit, chartTypeOverride } =
        measurement;
      const yAxisIndex = getOrCreateYAxisIndexByChartAttributeUnit(
        yAxis,
        measurement,
        includeZero
      );
      const agg =
        aggregation === AggregateFunction.Average
          ? AVERAGE_SHORT_VERSION_ID
          : aggregation;

      return {
        id: seriesId,
        name: getTemplateSeriesName({
          collectionId,
          templateName: name,
          unitName: unit,
          aggregateFunction: attributes.length > 1 ? agg : undefined,
          t,
        }),
        data: new Array(performerCategories.categories.length).fill(
          null
        ) as PointOptionsObject[],
        yAxis: yAxisIndex,
        type: chartType,

        ...(chartTypeOverride ? { type: chartTypeOverride } : {}),
        ...(chartTypeOverride === "line" ? { zIndex: 100 } : {}),
        connectNulls: true,
        tooltip: {
          pointFormatter() {
            return `<strong style="color: ${this.series.color};">${
              this.series.name
            }</strong>: ${this.custom?.txt || this.y} ${
              this.custom?.txt ? "" : unit || ""
            }<br/>`;
          },
        },
      };
    }
  );

  const seriesMapById = new Map(series.map((serie) => [serie.id, serie]));

  stats.perPerformer.forEach((athlete) => {
    const categoryIndex = mapAthleteGuidToCategoryIndex.get(athlete.id);

    athlete.items?.forEach((stat) => {
      const seriesId = getSeriesId(
        stat.template.id,
        stat.attribute.id,
        stat.aggregateFunction,
        false
      );
      const series = seriesMapById.get(seriesId);

      if (series) {
        series.data[categoryIndex] = {
          y: roundToTwo(stat.values[0]?.val),
          custom: { txt: stat.values[0]?.txt },
        };
      }
    });
  });

  performerCategories.hasTeamAverage &&
    teamAverageStats?.perPerformer?.[0]?.items?.forEach((teamStat) => {
      const attr = attributes.find(
        (att) =>
          att.measurementId === teamStat.template?.id &&
          att.attributeId === teamStat.attribute?.id
      );
      const seriesId = getSeriesId(
        teamStat.template?.id,
        teamStat.attribute?.id,
        teamStat.aggregateFunction,
        attr.teamAverageChartType === ChartType.LINE
      );
      const series = seriesMapById.get(seriesId);
      const attributeItem = seriesIdAttributeMap.get(seriesId);

      if (series && attributeItem.showTeamAverage) {
        series.data[series.data.length - 1] = {
          y: roundToTwo(teamStat.values[0]?.val),
          custom: { txt: teamStat.values[0]?.txt },
          color: COLOR_GREEN,
        };
        // @ts-ignore it works only typings are wrong
        series.dashStyle = "Dash";
        series.type = attributeItem.teamAverageChartType
          ? attributeItem.teamAverageChartType
          : series.type;
        series.zIndex = 100;
      }
    });

  return {
    series: series.filter(({ data }) => data.some((value) => value !== null)),
    yAxis,
  };
}

function getTemplateSeriesName({
  collectionId,
  templateName,
  unitName = "",
  aggregateFunction,
  t,
}: {
  collectionId: string;
  templateName: string;
  unitName?: string;
  aggregateFunction?: AggregateFunction | typeof AVERAGE_SHORT_VERSION_ID | "";
  t: TFunction<"common", undefined>;
}) {
  // FIXME: add another attribute to function by which we can select Minutes per Game
  if (templateName === "Minutes per Game") {
    return templateName;
  }
  const unitLabel =
    !isSoccerStatsCollection(collectionId) &&
    !isTestCollection(collectionId) &&
    // eslint-disable-next-line no-useless-escape
    !new RegExp(`${unitName}\s*\\)?$`).test(templateName?.trim())
      ? ` (${unitName})`
      : "";

  const aggLabel =
    !isAttendanceCollection(collectionId) && aggregateFunction
      ? ` (${t(`aggregation${aggregateFunction}`, {
          defaultValue: aggregateFunction,
        })})`
      : "";

  return `${templateName}${unitLabel}${aggLabel}`;
}

function getSeriesId(
  measurementId: string,
  attributeId: string,
  aggregation: string,
  isTeamAverage: boolean
): string {
  return `templateId:${measurementId}:attributeId:${attributeId}:aggregation:${aggregation}:isTeamAverage:${isTeamAverage}`;
}

// Get or create yAxis (group by unit)
function getOrCreateYAxisIndexByChartAttribute(
  yAxes: YAxisOptions[],
  attr: ChartItemAttributes,
  includeZero: boolean
) {
  const yAxisId = `templateId:${attr.measurementId}:attributeId:${attr.attributeId}`;

  const yAxisIndex = yAxes.findIndex((yAxis) => yAxis.id === yAxisId);

  return yAxisIndex === -1
    ? createYAxisAndReturnsIndex({
        yAxes,
        id: yAxisId,
        titleText: `${attr.name} ${attr.unit ? `(${attr.unit})` : ""}`,
        opposite: yAxes.length % 2 === 1,
        includeZero,
      })
    : yAxisIndex;
}

// Creates and returts yAxis (unit axis)
function getOrCreateYAxisIndexByChartAttributeUnit(
  yAxes: YAxisOptions[],
  attr: ChartItemAttributes,
  includeZero: boolean
) {
  const yAxisId = `unit:${attr.unit}`;
  const yAxisIndex = yAxes.findIndex((yAxis) => yAxis.id === yAxisId);

  return yAxisIndex === -1
    ? createYAxisAndReturnsIndex({
        yAxes,
        id: yAxisId,
        titleText: attr.unit,
        opposite: yAxes.length % 2 === 1,
        includeZero,
        placeTitleOnTop: true,
      })
    : yAxisIndex;
}

function createYAxisAndReturnsIndex({
  yAxes,
  id,
  titleText,
  opposite,
  includeZero,
  placeTitleOnTop,
}: {
  yAxes: YAxisOptions[];
  id: string;
  titleText: string;
  opposite: boolean;
  includeZero: boolean;
  placeTitleOnTop?: boolean;
}) {
  return (
    yAxes.push(
      createYAxis({ id, titleText, opposite, includeZero, placeTitleOnTop })
    ) - 1
  );
}

function createYAxis({
  id,
  titleText,
  opposite,
  includeZero,
  placeTitleOnTop = false,
}: {
  id: string;
  titleText: string;
  opposite: boolean;
  includeZero: boolean;
  placeTitleOnTop?: boolean;
}): YAxisOptions {
  return {
    id,
    opposite,
    title: {
      text: titleText,
      style: {
        fontWeight: "600",
        fontSize: "12px",
        color: COLOR_TEXT_DARK,
      },
      ...(placeTitleOnTop
        ? {
            align: "high",
            offset: 15,
            rotation: 0,
            y: -10,
          }
        : {}),
    },
    min: includeZero ? 0 : null,
    labels: {
      style: {
        fontWeight: "600",
        fontSize: "9px",
        color: COLOR_TEXT_TABLE_LABEL,
      },
    },
  };
}
