import React from "react";

import styled from "styled-components";

import {
  COLOR_MEDIUM_GRAY,
  COLOR_SECONDARY_GREY_HOVER,
  COLOR_WHITE,
} from "../../../colors";
import StyledText from "../../StyledText";

type SectionHeaderProps = {
  sectionLabel: string;
  count: number;
};

const SectionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
`;

const LabelWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SectionLabel = styled(StyledText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_MEDIUM_GRAY};
  margin-right: 10px;
  cursor: default;
`;

const Count = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_SECONDARY_GREY_HOVER};
  align-items: center;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  width: 36px;
  height: 21px;
  color: ${COLOR_WHITE};
  box-sizing: border-box;
  cursor: default;
  justify-content: center;
`;

export function SectionHeader({ sectionLabel, count }: SectionHeaderProps) {
  return (
    <SectionHeaderWrapper>
      <LabelWrapper>
        <SectionLabel>{sectionLabel}</SectionLabel>
        <Count>{count}</Count>
      </LabelWrapper>
    </SectionHeaderWrapper>
  );
}
