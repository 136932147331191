import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const LogoAcesNationNetworkIcon = memo(
  ({ width = 322, height = 29, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 2565 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometrictPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <g>
        <path
          fill="#1052a0"
          d="M 174.5,-0.5 C 176.833,-0.5 179.167,-0.5 181.5,-0.5C 195.982,1.15915 208.148,7.49248 218,18.5C 222.721,25.0479 227.221,31.7146 231.5,38.5C 244.43,58.6984 258.097,78.3651 272.5,97.5C 299.99,137.138 327.49,176.804 355,216.5C 355.667,217.5 355.667,218.5 355,219.5C 298.571,220.498 242.071,220.831 185.5,220.5C 198.084,201.243 211.084,182.243 224.5,163.5C 228.514,163.666 232.514,163.499 236.5,163C 217.384,134.727 197.717,106.894 177.5,79.5C 146.667,123.167 115.833,166.833 85,210.5C 82.872,213.63 80.372,216.463 77.5,219C 51.5892,220.482 25.5892,220.982 -0.5,220.5C -0.5,218.833 -0.5,217.167 -0.5,215.5C 46.1369,150.014 92.6369,84.3469 139,18.5C 148.461,7.49645 160.294,1.16311 174.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#ebeff1"
          d="M 281.5,-0.5 C 283.833,-0.5 286.167,-0.5 288.5,-0.5C 301.956,0.977525 313.456,6.64419 323,16.5C 357.053,64.3795 390.886,112.379 424.5,160.5C 437.113,178.954 449.947,197.287 463,215.5C 463.49,216.793 463.657,218.127 463.5,219.5C 438.67,220.663 413.67,220.83 388.5,220C 387.167,219.333 385.833,218.667 384.5,218C 351.662,171.671 318.662,125.504 285.5,79.5C 281.142,85.5182 276.809,91.5182 272.5,97.5C 258.097,78.3651 244.43,58.6984 231.5,38.5C 236.137,32.3471 240.637,26.0138 245,19.5C 254.464,7.68846 266.631,1.0218 281.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#adaead"
          d="M 561.5,-0.5 C 563.833,-0.5 566.167,-0.5 568.5,-0.5C 578.669,1.06261 588.003,4.89595 596.5,11C 600.663,13.8213 602.163,17.6546 601,22.5C 555.304,88.346 509.47,154.013 463.5,219.5C 463.657,218.127 463.49,216.793 463,215.5C 449.947,197.287 437.113,178.954 424.5,160.5C 458.224,113.409 491.724,66.0756 525,18.5C 534.832,7.50222 546.998,1.16889 561.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1051a0"
          d="M 964.5,26.5 C 997.178,24.8882 1025.18,35.3882 1048.5,58C 1037.5,69 1026.5,80 1015.5,91C 1014.5,91.6667 1013.5,91.6667 1012.5,91C 993.757,74.7193 972.757,71.0526 949.5,80C 933.761,88.9619 924.594,102.462 922,120.5C 919.072,141.048 924.905,158.548 939.5,173C 961.702,186.916 984.036,186.916 1006.5,173C 1010.61,170.191 1014.44,167.025 1018,163.5C 1029.04,174.867 1039.7,186.534 1050,198.5C 1049.91,201.642 1048.41,204.142 1045.5,206C 1018.99,226.462 988.994,234.462 955.5,230C 904.007,224.172 874.507,195.672 867,144.5C 862.375,104.285 875.542,71.452 906.5,46C 924.211,34.3744 943.544,27.8744 964.5,26.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 2239.5,26.5 C 2269.63,23.8878 2297.3,30.7211 2322.5,47C 2348.51,67.9851 2360.84,95.3184 2359.5,129C 2358.21,185.295 2329.87,218.628 2274.5,229C 2245.57,233.426 2217.9,229.426 2191.5,217C 2166.95,203.107 2151.79,182.273 2146,154.5C 2139.51,123.4 2144.51,94.4 2161,67.5C 2181.08,42.3729 2207.25,28.7062 2239.5,26.5 Z M 2242.5,74.5 C 2286.56,73.7294 2306.72,95.3961 2303,139.5C 2299.03,160.631 2286.87,174.464 2266.5,181C 2233.54,187.45 2211.71,174.95 2201,143.5C 2196.88,124.428 2200.55,107.095 2212,91.5C 2220.56,82.8067 2230.73,77.14 2242.5,74.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1051a0"
          d="M 1331.5,231.5 C 1322.5,231.5 1313.5,231.5 1304.5,231.5C 1276.19,229.68 1252.53,218.18 1233.5,197C 1243.49,184.845 1253.82,173.012 1264.5,161.5C 1287.94,182.953 1314.6,188.453 1344.5,178C 1352.25,171.76 1352.92,165.094 1346.5,158C 1343.45,155.806 1340.11,154.139 1336.5,153C 1317.67,150.596 1299.01,147.262 1280.5,143C 1248.16,131.506 1234.99,108.673 1241,74.5C 1246.54,55.289 1258.71,42.1223 1277.5,35C 1305.61,25.7667 1333.94,25.1001 1362.5,33C 1374.4,36.9456 1385.07,42.9456 1394.5,51C 1385.7,62.9593 1376.37,74.4593 1366.5,85.5C 1346.89,72.9247 1325.89,69.758 1303.5,76C 1295.6,80.3653 1293.77,86.532 1298,94.5C 1301.76,98.1304 1306.26,100.297 1311.5,101C 1331.09,102.32 1350.09,106.32 1368.5,113C 1402.98,130.093 1413.82,156.926 1401,193.5C 1389.74,212.236 1373.24,223.736 1351.5,228C 1344.71,229.297 1338.04,230.464 1331.5,231.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1051a0"
          d="M 732.5,31.5 C 751.743,31.1739 770.91,31.5072 790,32.5C 818.705,96.7411 847.538,160.908 876.5,225C 856.5,225.667 836.5,225.667 816.5,225C 812.005,215.012 808.005,204.846 804.5,194.5C 775.492,194.167 746.492,194.5 717.5,195.5C 713.629,205.405 709.629,215.238 705.5,225C 685.825,225.833 666.158,225.666 646.5,224.5C 675.453,160.263 704.12,95.9298 732.5,31.5 Z M 760.5,87.5 C 768.723,106.964 776.723,126.631 784.5,146.5C 768.848,147.499 753.181,147.833 737.5,147.5C 745.167,127.5 752.833,107.5 760.5,87.5 Z"
        />
      </g>
      <g>
        <path
          fill="#1051a0"
          d="M 1066.5,31.5 C 1117.17,31.5 1167.83,31.5 1218.5,31.5C 1218.5,47.1667 1218.5,62.8333 1218.5,78.5C 1186.17,78.5 1153.83,78.5 1121.5,78.5C 1121.5,87.1667 1121.5,95.8333 1121.5,104.5C 1152.17,104.5 1182.83,104.5 1213.5,104.5C 1213.5,119.833 1213.5,135.167 1213.5,150.5C 1182.83,150.5 1152.17,150.5 1121.5,150.5C 1121.5,159.833 1121.5,169.167 1121.5,178.5C 1155.17,178.5 1188.83,178.5 1222.5,178.5C 1222.5,194.167 1222.5,209.833 1222.5,225.5C 1170.5,225.5 1118.5,225.5 1066.5,225.5C 1066.5,160.833 1066.5,96.1667 1066.5,31.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 1482.5,31.5 C 1498.85,31.1672 1515.18,31.5006 1531.5,32.5C 1558,66.8333 1584.5,101.167 1611,135.5C 1609.71,100.901 1609.21,66.2344 1609.5,31.5C 1628.17,31.5 1646.83,31.5 1665.5,31.5C 1665.5,96.1667 1665.5,160.833 1665.5,225.5C 1649.5,225.667 1633.5,225.5 1617.5,225C 1591.45,191.076 1565.29,157.243 1539,123.5C 1539.29,157.492 1539.46,191.492 1539.5,225.5C 1520.5,225.5 1501.5,225.5 1482.5,225.5C 1482.5,160.833 1482.5,96.1667 1482.5,31.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 1761.5,31.5 C 1780.51,31.1671 1799.51,31.5004 1818.5,32.5C 1847.17,96.5 1875.83,160.5 1904.5,224.5C 1884.5,225.833 1864.5,225.833 1844.5,224.5C 1840.5,214.833 1836.5,205.167 1832.5,195.5C 1803.84,194.333 1775.17,194.167 1746.5,195C 1742,204.988 1738,215.154 1734.5,225.5C 1714.5,225.667 1694.5,225.5 1674.5,225C 1703.64,160.548 1732.64,96.0484 1761.5,31.5 Z M 1788.5,88.5 C 1789.79,89.0577 1790.62,90.0577 1791,91.5C 1798.11,110.177 1805.61,128.677 1813.5,147C 1797.49,147.833 1781.49,147.666 1765.5,146.5C 1773.57,127.29 1781.23,107.956 1788.5,88.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 1880.5,31.5 C 1936.5,31.5 1992.5,31.5 2048.5,31.5C 2048.5,46.8333 2048.5,62.1667 2048.5,77.5C 2029.83,77.5 2011.17,77.5 1992.5,77.5C 1992.5,126.833 1992.5,176.167 1992.5,225.5C 1973.83,225.5 1955.17,225.5 1936.5,225.5C 1936.5,176.167 1936.5,126.833 1936.5,77.5C 1917.83,77.5 1899.17,77.5 1880.5,77.5C 1880.5,62.1667 1880.5,46.8333 1880.5,31.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 2066.5,31.5 C 2084.83,31.5 2103.17,31.5 2121.5,31.5C 2121.5,96.1667 2121.5,160.833 2121.5,225.5C 2103.17,225.5 2084.83,225.5 2066.5,225.5C 2066.5,160.833 2066.5,96.1667 2066.5,31.5 Z"
        />
      </g>
      <g>
        <path
          fill="#acaeac"
          d="M 2564.5,31.5 C 2564.5,96.1667 2564.5,160.833 2564.5,225.5C 2548.5,225.667 2532.5,225.5 2516.5,225C 2489.97,191.438 2463.8,157.604 2438,123.5C 2438.09,157.496 2438.25,191.496 2438.5,225.5C 2419.5,225.5 2400.5,225.5 2381.5,225.5C 2381.5,160.833 2381.5,96.1667 2381.5,31.5C 2397.5,31.3334 2413.5,31.5001 2429.5,32C 2456.12,66.0763 2482.62,100.243 2509,134.5C 2508.63,100.178 2508.47,65.8442 2508.5,31.5C 2527.17,31.5 2545.83,31.5 2564.5,31.5 Z"
        />
      </g>
      <g>
        <path
          fill="#ebeff1"
          d="M 224.5,163.5 C 211.084,182.243 198.084,201.243 185.5,220.5C 159.833,220.5 134.167,220.5 108.5,220.5C 108.34,218.801 108.506,217.134 109,215.5C 133.71,179.913 158.544,144.413 183.5,109C 185.903,108.37 187.737,109.203 189,111.5C 201.054,128.714 212.888,146.047 224.5,163.5 Z"
        />
      </g>
    </svg>
  )
);
