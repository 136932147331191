import React, { PropsWithChildren } from "react";

import styled from "styled-components";

import { COLOR_WHITE } from "../../../colors";

const Container = styled.div`
  z-index: 1;
  background: ${COLOR_WHITE};
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  padding-bottom: 76px;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

interface TabContainerProps {
  loading: boolean;
}

export const TabContainer = ({
  children,
}: PropsWithChildren<TabContainerProps>) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
