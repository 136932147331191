import React from "react";

import { Header } from "../components/Header";
import ScreenContainer from "../components/ScreenContainer";

export const NotFoundScreen = () => (
  <ScreenContainer>
    <Header />
    <h1>Page not found or is currently under development</h1>
  </ScreenContainer>
);
