import React from "react";
import { useResizeDetector } from "react-resize-detector";

import styled, { useTheme } from "styled-components";

import {
  COLOR_BLACK,
  COLOR_GREY_ACTIVE,
  COLOR_MEDIUM_GRAY,
  COLOR_TEXT_DEFAULT,
} from "../../../colors";
import { BodyChartSvg } from "../../BodyParts/BodyChartSvg";
import { BodyRegistration } from "../../BodyParts/model";
import { WidgetContainer } from "../styled";
import { WidgetLoader } from "../WidgetLoader";
import { WidgetNoData } from "../WidgetNoData";

import { BodyChartWidgetModel, InformationTemplateValues } from "./model";
import { BODY_TORSO_HEIGHT, BODY_TORSO_SCALE, BODY_TORSO_WIDTH } from "./utils";
import { ValueItem } from "./ValueItem";

type BodyChartWidgetComponentProps = {
  widget: BodyChartWidgetModel;
  isLoading: boolean;
  registrations: BodyRegistration;
  informationTemplateValues: InformationTemplateValues;
  date: string;
};

const WidgetContent = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: stretch;
  flex: 1;
  overflow: ${({ isMobile }) => (isMobile ? "hidden" : "auto")};
  flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
`;

const BodyWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TitleWrapper = styled.div<{ hasInformationSection?: boolean }>`
  margin-bottom: 20px;

  ${({ hasInformationSection }) =>
    !hasInformationSection
      ? `
    justify-content: space-between;
    display: flex;
  `
      : ""};
`;

const BodyChartWrapper = styled.div<{ hasInformationSection?: boolean }>`
  flex: 1;
  display: flex;
  padding-right: 20px;
  justify-content: ${({ hasInformationSection }) =>
    hasInformationSection ? "" : "center"};
`;

const NoDataWrapper = styled.div`
  min-height: 100px;
`;

const InformationWrapper = styled.div<{ isMobile: boolean }>`
  padding-top: ${({ isMobile }) => (isMobile ? "20px" : "0px")};
  border-left: ${({ isMobile }) =>
    isMobile ? "0px" : `1px solid ${COLOR_GREY_ACTIVE}`};
  border-top: ${({ isMobile }) =>
    isMobile ? `1px solid ${COLOR_GREY_ACTIVE}` : "0px"};
  min-height: 100%;
  height: fit-content;
  width: ${({ isMobile }) => (isMobile ? 100 : 50)}%;
`;

const InformationHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  padding-left: 30px;
  color: ${COLOR_TEXT_DEFAULT};
`;

const Date = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${COLOR_BLACK};
  padding-right: 30px;
  padding-left: 30px;
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: ${COLOR_MEDIUM_GRAY};
  padding-left: 30px;
`;

export function BodyChartWidgetComponent({
  widget,
  isLoading,
  informationTemplateValues,
  registrations,
  date,
}: BodyChartWidgetComponentProps) {
  const { ref } = useResizeDetector();
  const { isMobile } = useTheme();

  if (isLoading) {
    return <WidgetLoader />;
  }

  const hasInformationSection = widget?.informationTemplates?.length > 0;

  return (
    <WidgetContainer ref={ref} showBorders={widget?.widgetBorders}>
      <WidgetContent isMobile={isMobile}>
        {Object.keys(registrations).length === 0 ? (
          <NoDataWrapper>
            <WidgetNoData />
          </NoDataWrapper>
        ) : (
          <>
            <BodyWrapper>
              <TitleWrapper>
                <Title>{widget?.name}</Title>
                {!hasInformationSection ? <Date>{date}</Date> : null}
              </TitleWrapper>
              <BodyChartWrapper hasInformationSection={hasInformationSection}>
                <BodyChartSvg
                  registrations={registrations}
                  customHeight={BODY_TORSO_HEIGHT}
                  customWidth={BODY_TORSO_WIDTH}
                  customScale={BODY_TORSO_SCALE}
                  sectionsVisible={false}
                  side="front"
                />
                <BodyChartSvg
                  registrations={registrations}
                  customHeight={BODY_TORSO_HEIGHT}
                  customWidth={BODY_TORSO_WIDTH}
                  customScale={BODY_TORSO_SCALE}
                  sectionsVisible={false}
                  side="back"
                />
              </BodyChartWrapper>
            </BodyWrapper>
            {hasInformationSection ? (
              <InformationWrapper isMobile={isMobile}>
                <InformationHeader>
                  <Date>{date}</Date>
                </InformationHeader>
                {widget?.informationTemplates?.map(
                  (informationTemplate, index) => (
                    <div key={`${informationTemplate.id}-${index}`}>
                      <Label>
                        {informationTemplate?.name?.toLocaleUpperCase()}
                      </Label>
                      <ValueItem
                        id={informationTemplate?.id}
                        informationTemplateValues={informationTemplateValues}
                      />
                    </div>
                  )
                )}
              </InformationWrapper>
            ) : null}
          </>
        )}
      </WidgetContent>
    </WidgetContainer>
  );
}
