import React from "react";

import styled from "styled-components";

import { COLOR_BLUE } from "../colors";

export enum SpinnerSize {
  SMALL = "small",
  LARGE = "large",
}

function getSizeValue(size?: string) {
  switch (size) {
    case SpinnerSize.LARGE:
      return 32;
    case SpinnerSize.SMALL:
      return 16;
    default:
      return 24;
  }
}

const SpinnerWrapper = styled.div<{ size?: SpinnerSize }>`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid ${COLOR_BLUE};
  border-radius: 50%;
  width: ${({ size }) => `${getSizeValue(size)}px`};
  height: ${({ size }) => `${getSizeValue(size)}px`};
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export function Spinner({ size }: { size?: SpinnerSize }) {
  return <SpinnerWrapper size={size} />;
}
