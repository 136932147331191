import React from "react";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../../colors";
import { LocationPointIcon } from "../../Icons";
import StyledText from "../../StyledText";

const PlaceContainer = styled.div<{ showRightPadding?: boolean }>`
  flex: 1;
  padding-right: ${({ showRightPadding }) => (showRightPadding ? "24px" : "0")};
  display: flex;
  flex-direction: column;
`;

const Location = styled.div`
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
  display: flex;
`;

const LocationIconWrapper = styled.div`
  margin-right: 5px;
`;

const PrimaryLabel = styled(StyledText)`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
`;

const SecondaryLabel = styled(StyledText)`
  font-size: 12px;
  font-weight: 400;
  color: ${COLOR_TEXT_DARK};
  padding-left: 20px;
`;

interface PlaceSectionProps {
  location: string;
  xtraLocation: string;
  showRightPadding?: boolean;
}

export function PlaceSection({
  location,
  xtraLocation,
  showRightPadding,
}: PlaceSectionProps) {
  return (
    <PlaceContainer showRightPadding={showRightPadding}>
      {location ? (
        <Location>
          <LocationIconWrapper>
            <LocationPointIcon />
          </LocationIconWrapper>
          <PrimaryLabel numberOfLines={2} ellipsizeMode="tail">
            {location}
          </PrimaryLabel>
        </Location>
      ) : null}
      <SecondaryLabel>{xtraLocation}</SecondaryLabel>
    </PlaceContainer>
  );
}
