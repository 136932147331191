import React from "react";

import { useUserContext } from "../../contexts/User";
import { usePracticeMembersQuery } from "../../graphql";

import { MembersList } from "./MembersList";

type SessionMembersListProps = {
  sessionGuid?: string;
  showMembersAttendance?: boolean;
};

export function SessionMembersList({
  sessionGuid,
  showMembersAttendance,
}: SessionMembersListProps) {
  const { sessionId, timezone, language } = useUserContext();

  const { data } = usePracticeMembersQuery({
    variables: {
      id: sessionGuid,
      sessionId,
      timezone,
      language,
    },
    // Due to infinite query loading and cache mismatch between data loaded using practice members and practice query - needs fix in the future
    fetchPolicy: "no-cache",
  });

  return (
    <MembersList
      athletes={
        data?.practice?.members?.athletes2023.map((athlete) => ({
          ...athlete.user,
          participates: athlete.participates,
        })) ?? []
      }
      trainers={data?.practice?.members?.trainers ?? []}
      hideAdditionalInfo={true}
      showMembersAttendance={showMembersAttendance}
    />
  );
}
