import React, { ReactNode } from "react";
import { useMediaQuery } from "react-responsive";

import { ThemeProvider } from "styled-components";

interface ExtendedStyledComponentThemeProviderProps {
  children: ReactNode;
}

const MOBILE_BREAKPOINT = 767;
const TABLET_BREAKPOINT = 992;

export const ExtendedStyledComponentThemeProvider = ({
  children,
}: ExtendedStyledComponentThemeProviderProps) => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_BREAKPOINT });
  const isTablet = useMediaQuery({
    minWidth: MOBILE_BREAKPOINT,
    maxWidth: TABLET_BREAKPOINT,
  });
  const isDesktop = useMediaQuery({ minWidth: TABLET_BREAKPOINT });

  return (
    <ThemeProvider theme={{ isDesktop, isMobile, isTablet }}>
      {children}
    </ThemeProvider>
  );
};
