import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useUserContext } from "../../../contexts/User";
import {
  AggregateFunction,
  ReportItemType,
  useReportBuildingAttributeTemplatesLazyQuery,
  useReportBuildingCollectionsQuery,
  useReportBuildingMeasurementTemplatesQuery,
  WidgetCell,
} from "../../../graphql";
import { FieldWrapper } from "../../styled";
import { StyledSelectField } from "../../StyledSelectField";

type Option = {
  label: string;
  value: string;
};

export function ProfileInfoSettingsRow({
  accountId,
  value,
  onChange,
}: {
  accountId?: string;
  value: WidgetCell | null;
  onChange?(value: WidgetCell | null): void;
}) {
  const user = useUserContext();
  const { sessionId, language } = user;

  const [outValue, setOutValue] = useState(value);
  const [
    { collectionId, itemId, attributeTemplateId, aggregateFunction },
    setValueState,
  ] = useState<WidgetCell>({
    attributeTemplateId: value.attributeTemplateId ?? "",
    collectionId: value.collectionId ?? "",
    itemId: value.itemId ?? "",
    aggregateFunction: value.aggregateFunction ?? AggregateFunction.Latest,
  });

  const { data: { reportBuildingCollections: collections } = {} } =
    useReportBuildingCollectionsQuery({
      variables: { sessionId, language, accountId },
      fetchPolicy: "cache-first",
    });
  const { data: { reportBuildingMeasurementTemplates: measurements } = {} } =
    useReportBuildingMeasurementTemplatesQuery({
      variables: { sessionId, language, collectionId },
      fetchPolicy: "cache-first",
      skip: !collectionId,
    });

  const collectionOptions = useMemo<Option[]>(
    () =>
      collections
        ?.filter(
          (collection) =>
            collection.reportItemType === ReportItemType.Test ||
            collection.reportItemType === ReportItemType.Information ||
            collection.reportItemType === ReportItemType.XpsMagic
        )
        .sort((collection1, collection2) =>
          collection1.reportItemType.localeCompare(collection2.reportItemType)
        )
        .map((c) => ({ label: c.name, value: c.id })) ?? [],
    [collections]
  );
  const measurementOptions = useMemo<Option[]>(
    () => measurements?.map((c) => ({ label: c.name, value: c.id })) ?? [],
    [measurements]
  );

  const [getAttributes] = useReportBuildingAttributeTemplatesLazyQuery({
    fetchPolicy: "cache-first",
  });

  const handleCollectionChange = useCallback((option: Option) => {
    setValueState((state) => ({
      ...state,
      collectionId: option.value,
      itemId: "",
      attributeTemplateId: "",
    }));
  }, []);
  const handleMeasurementChange = useCallback((option: Option) => {
    setValueState((state) => ({
      ...state,
      itemId: option.value,
      attributeTemplateId: "",
    }));
  }, []);

  useEffect(() => {
    async function fetchAttribute() {
      if (itemId) {
        const {
          data: { reportBuildingAttributeTemplates: [attribute] = [] } = {},
        } = await getAttributes({
          variables: {
            collectionId,
            measurementTemplateId: itemId,
            sessionId,
            language,
          },
        });

        setValueState((state) => ({
          ...state,
          attributeTemplateId: attribute?.id ?? "",
        }));
      }
    }

    fetchAttribute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (!!collectionId && !itemId) {
      setOutValue({
        collectionId,
        itemId: "",
        attributeTemplateId: "",
        aggregateFunction: AggregateFunction.Latest,
      });
    }

    if (
      !!collectionId &&
      !!itemId &&
      !!attributeTemplateId &&
      !!aggregateFunction
    ) {
      setOutValue({
        collectionId,
        itemId,
        attributeTemplateId,
        aggregateFunction,
      });
    }
  }, [collectionId, itemId, attributeTemplateId, aggregateFunction]);

  useEffect(() => {
    setOutValue((outValue) =>
      (value?.collectionId ?? "") !== (outValue?.collectionId ?? "") ||
      (value?.itemId ?? "") !== (outValue?.itemId ?? "") ||
      (value?.attributeTemplateId ?? "") !==
        (outValue?.attributeTemplateId ?? "") ||
      (value?.aggregateFunction ?? AggregateFunction.Latest) !==
        (outValue?.aggregateFunction ?? AggregateFunction.Latest)
        ? {
            attributeTemplateId: value?.attributeTemplateId ?? "",
            collectionId: value?.collectionId ?? "",
            itemId: value?.itemId ?? "",
            aggregateFunction:
              value?.aggregateFunction ?? AggregateFunction.Latest,
          }
        : outValue
    );
  }, [value]);

  useEffect(() => {
    onChange?.(outValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outValue]);

  return (
    <>
      <FieldWrapper>
        <StyledSelectField
          options={collectionOptions}
          value={collectionId}
          onChange={handleCollectionChange}
        />
      </FieldWrapper>
      <FieldWrapper>
        <StyledSelectField
          options={measurementOptions}
          value={itemId}
          onChange={handleMeasurementChange}
        />
      </FieldWrapper>
    </>
  );
}
