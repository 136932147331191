import { useMemo } from "react";

import { useReportsContext } from "../../../../contexts/Reports";
import { useSelectedPerformersContext } from "../../../../contexts/selectedAthletes";
import { useGetGroupWidgetParticipants } from "../../../../hooks/data/useGetGroupWidgetParticipants";
import { PerformerSelectionMode } from "../../PerformerSection";
import { ChartPerformers, ChartWidgetModel } from "../model";

export function useChartWidgetReportPerformers({
  widget,
  isPreviewMode,
  isGroupReport,
}: {
  widget: ChartWidgetModel;
  isGroupReport: boolean;
  isPreviewMode: boolean;
}): ChartPerformers {
  const [{ selectedAthlete, selectedGroup }] = useReportsContext();
  const selectedPerformersState = useSelectedPerformersContext();

  const { getGroupWidgetParticipants } = useGetGroupWidgetParticipants();

  const performerSelectionMode =
    widget.performerSelectionMode || (widget as any).athleteSelectionMode;

  return useMemo<ChartPerformers>(() => {
    if (isPreviewMode) {
      const dummyAthletes = ["John Doe", "Jane Doe"];

      return {
        teamGuids: [],
        athleteGuids: isGroupReport ? dummyAthletes : dummyAthletes.slice(0, 1),
        teamAthleteGuids: dummyAthletes,
      };
    }

    // Group Reports
    if (isGroupReport) {
      const { participants, participantTeams } =
        getGroupWidgetParticipants(widget);

      return {
        teamGuids: participantTeams,
        athleteGuids: participants,
        teamAthleteGuids: participants,
      };
    }

    // Athlete Reports
    const athletes =
      performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED
        ? [widget.athlete]
        : selectedAthlete
          ? [selectedAthlete.id]
          : [];

    return {
      teamGuids: [],
      athleteGuids: athletes,
      teamAthleteGuids:
        selectedGroup?.requiredAthletes.map(({ id }) => id) ?? [],
      focusedAthleteGuid: selectedAthlete?.id,
      focusedGroupGuid: selectedGroup?.id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPerformersState,
    selectedAthlete,
    selectedGroup,
    isPreviewMode,
    isGroupReport,
    performerSelectionMode,
    widget,
  ]);
}
