import { useCallback } from "react";

import { UserPreferenceKeys } from "../../constants";
import { useUserContext } from "../../contexts/User";
import {
  useGetPreferenceLazyQuery,
  usePutPreferenceMutation,
} from "../../graphql";

export const getTableSortingPreferenceKey = (userId: string, tableId: string) =>
  `${UserPreferenceKeys.TABLE_SORTING_PREFERENCE}-${userId}-${tableId}`;

type UseGroupTableSortingPreferenceReturnValue<SortByType> = {
  saveTableSortingPreference: (
    tableId: string,
    sortingData: { sortBy: SortByType; sortingOrder: number }
  ) => Promise<void>;
  getTableSortingPreference: (
    tableId: string
  ) => Promise<{ sortBy: SortByType; sortingOrder: number }>;
  loadingTablePreference: boolean;
};

export const useTableSortingPreference = <
  SortByType,
>(): UseGroupTableSortingPreferenceReturnValue<SortByType> => {
  const { id: userId, sessionId } = useUserContext();

  const [getPreference, { loading }] = useGetPreferenceLazyQuery();
  const [setPreferences] = usePutPreferenceMutation();

  const saveTableSortingPreference = useCallback(
    async (
      tableId: string,
      sortingData: { sortBy: SortByType; sortingOrder: number }
    ) => {
      if (tableId && sortingData && userId) {
        await setPreferences({
          variables: {
            key: getTableSortingPreferenceKey(userId, tableId),
            sessionId,
            value: JSON.stringify(sortingData || {}),
          },
        });
      }
    },
    [sessionId, setPreferences, userId]
  );

  const getTableSortingPreference = useCallback(
    async (tableId: string) => {
      if (tableId && userId) {
        const sortingPreferenceResponse = await getPreference({
          variables: {
            key: getTableSortingPreferenceKey(userId, tableId),
            sessionId,
          },
        });

        return sortingPreferenceResponse?.data?.getPreference
          ? JSON.parse(sortingPreferenceResponse.data.getPreference)
          : {};
      }

      return {};
    },
    [userId, getPreference, sessionId]
  );

  return {
    getTableSortingPreference,
    saveTableSortingPreference,
    loadingTablePreference: loading,
  };
};
