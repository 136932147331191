import React from "react";
import { useTranslation } from "react-i18next";

import { Field, useFormikContext } from "formik";

import { getSortingOptions } from "../../../utils/sortingOrderOptions";
import { Section } from "../../Section";
import { SectionTitle } from "../../SectionTitle";
import { SelectField, SelectOption } from "../../SelectField";
import { DraggableLineWrapper, FieldWrapper } from "../../styled";
import { CreateAthleteTableFormValue } from "../models";

export const DefaultSortingSectionAthleteTable = () => {
  const { t } = useTranslation();

  const { values, setFieldValue } =
    useFormikContext<
      Pick<CreateAthleteTableFormValue, "columns" | "sortBy" | "sortingOrder">
    >();

  const sortByOptions = values.columns.map((col) => ({
    label: t(`aggregation${col}`, {
      defaultValue: col,
    }),
    value: col,
  }));

  const onSortingValueChange =
    (fieldName) => (option: SelectOption<string | number>) => {
      setFieldValue(fieldName, option?.value);

      if (fieldName === "sortBy" && option?.value === null) {
        setFieldValue("sortingOrder", undefined);
      }
    };

  return (
    <Section>
      <SectionTitle title={t("defaultSorting")} />
      <DraggableLineWrapper>
        <FieldWrapper>
          <Field name="sortBy">
            {(props) => (
              <SelectField
                {...props}
                value={values.sortBy}
                options={
                  values.sortBy
                    ? [
                        { label: t("clearSelection"), value: null },
                        ...sortByOptions,
                      ]
                    : sortByOptions
                }
                onChange={onSortingValueChange("sortBy")}
              />
            )}
          </Field>
        </FieldWrapper>

        <FieldWrapper>
          <Field name="sortingOrder">
            {(props) => (
              <SelectField
                {...props}
                value={values.sortingOrder}
                options={getSortingOptions(t, Boolean(values.sortingOrder))}
                onChange={onSortingValueChange("sortingOrder")}
              />
            )}
          </Field>
        </FieldWrapper>
      </DraggableLineWrapper>
    </Section>
  );
};
