import React, { useEffect, useRef, useState } from "react";
import { ColorResult, TwitterPicker } from "react-color";

import styled from "styled-components";

import { COLOR_GRAY } from "../../colors";
import { useHandleClickOutside } from "../../hooks";
import { ChevronRightIcon } from "../Icons";

type Props = {
  colorList: string[];
  currentColor: string;
  hasArrow?: boolean;
  onColorChange: (colorHex: string) => void;
  toggleColorPicker?: (isOpen: boolean) => void;
};

const twitterStyle = {
  default: {
    input: {
      display: "none",
    },
    hash: {
      display: "none",
    },
  },
};

const ColorPickerWrapper = styled.div`
  cursor: pointer;
`;

const PickedColorButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  height: 35px;
  width: auto;
  border-radius: 6px;
  background-color: ${COLOR_GRAY};
  justify-content: space-around;
`;

const PickedColor = styled.div<{ currentColor?: string }>`
  border-radius: 6px;
  width: 25px;
  height: 25px;
  background-color: ${({ currentColor }) => currentColor};
`;

const IconWrapper = styled.div`
  margin-left: 5px;
`;

const PickerWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 75px;
  z-index: 9;
  right: 0;
`;

export function ColorPicker({
  colorList,
  hasArrow,
  currentColor,
  onColorChange,
  toggleColorPicker,
}: Props) {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const colorPickerRef = useRef(null);

  useHandleClickOutside(
    colorPickerRef,
    isPickerOpen ? () => setIsPickerOpen(false) : undefined
  );

  const handleChangeComplete = (color: ColorResult) => {
    onColorChange(color.hex);
    setIsPickerOpen(false);
  };

  useEffect(() => {
    toggleColorPicker?.(isPickerOpen);
  }, [isPickerOpen, toggleColorPicker]);

  return (
    <div ref={colorPickerRef}>
      <ColorPickerWrapper
        onClick={() => setIsPickerOpen((isPickerOpen) => !isPickerOpen)}
      >
        <PickedColorButton>
          <PickedColor currentColor={currentColor} />
          {hasArrow && (
            <IconWrapper>
              <ChevronRightIcon direction="bottom" />
            </IconWrapper>
          )}
        </PickedColorButton>
      </ColorPickerWrapper>
      {isPickerOpen && (
        <PickerWrapper>
          <TwitterPicker
            width="168px"
            onChangeComplete={handleChangeComplete}
            colors={colorList}
            color={currentColor}
            styles={twitterStyle}
            triangle="top-right"
          />
        </PickerWrapper>
      )}
    </div>
  );
}
