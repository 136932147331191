import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_BLUE, COLOR_TEXT_DARK } from "../../../colors";
import { useTeamsContext } from "../../../contexts/teams";
import { useUserContext } from "../../../contexts/User";
import { useGetGroupQuery } from "../../../graphql";
import { sortList } from "../../../utils/sortList";
import { Loader } from "../../Loader";
import StyledText from "../../StyledText";

import { TeamList } from "./TeamList";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 35px 15px 10px;
`;

const EmptyGroupWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const EmptyLabel = styled(StyledText)`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${COLOR_TEXT_DARK};
`;

export function TeamMembers({ onMembersLoaded }) {
  const { t } = useTranslation();
  const { sessionId, language } = useUserContext();
  const { group } = useTeamsContext();

  const groupId = group?.id;

  const { data, loading } = useGetGroupQuery({
    variables: {
      sessionId,
      id: groupId,
    },
    skip: !groupId,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!loading && data?.getGroup) {
      onMembersLoaded();
    }
  }, [loading, data?.getGroup, onMembersLoaded]);

  const familyMembers = useMemo(
    () =>
      data?.getGroup?.familyAccess.map(({ user }) => ({
        ...user,
      })),
    [data?.getGroup?.familyAccess]
  );

  const trainers = useMemo(
    () => sortList(data?.getGroup?.trainers, language),
    [data?.getGroup?.trainers, language]
  );

  const athletes = useMemo(
    () => sortList(data?.getGroup?.athletes, language),
    [data?.getGroup?.athletes, language]
  );

  const sortedFamilyMembers = useMemo(
    () => sortList(familyMembers, language),
    [familyMembers, language]
  );

  if (!groupId) {
    return (
      <EmptyGroupWrapper>
        <EmptyLabel>{t("noGroupSelected")}</EmptyLabel>
      </EmptyGroupWrapper>
    );
  }

  if (loading) {
    return (
      <EmptyGroupWrapper>
        <Loader color={COLOR_BLUE} />
      </EmptyGroupWrapper>
    );
  }

  return (
    <ContentWrapper>
      <TeamList data={trainers} sectionLabel={t("coaches")} groupId={groupId} />
      <TeamList
        data={athletes}
        sectionLabel={t("athletes")}
        groupId={groupId}
      />
      <TeamList
        data={sortedFamilyMembers}
        sectionLabel={t("familyMembers")}
        groupId={groupId}
      />
    </ContentWrapper>
  );
}
