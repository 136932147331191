import React from "react";

export const DrillIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5C1.5 0.947715 1.94772 0.5 2.5 0.5H9.37868C9.6439 0.5 9.89825 0.605357 10.0858 0.792893L13.2071 3.91421C13.3946 4.10175 13.5 4.3561 13.5 4.62132V13.5C13.5 14.0523 13.0523 14.5 12.5 14.5H2.5C1.94772 14.5 1.5 14.0523 1.5 13.5V1.5Z"
        fill="#CFD5DA"
        stroke="#8A939B"
      />
      <path d="M6 5V10L10 7.5L6 5Z" fill="#8A939B" />
      <path
        d="M13.2071 3.91421C13.3658 4.07293 13.4657 4.27949 13.4926 4.5H10.5C9.94772 4.5 9.5 4.05228 9.5 3.5V0.507383C9.72051 0.534329 9.92707 0.634182 10.0858 0.792893L13.2071 3.91421Z"
        fill="#CFD5DA"
        stroke="#8A939B"
      />
    </svg>
  );
};
