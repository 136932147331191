import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import dayjs from "dayjs";

import { useModalContext } from "../contexts/UI/Modal";
import { useUserContext } from "../contexts/User";
import { client, setClientParam, useUserProfileLazyQuery } from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { isAcesNationEnv } from "../utils/isAcesNationEnv";
import { removeUnrememberedAccount } from "../utils/removeUnrememberedAccount";
import Storage from "../utils/storage";
import { StorageKeys } from "../utils/storageKeys";
import {
  getAccounts,
  logoutFromAccounts,
  removeAccount,
} from "../utils/switchAccounts";

export const useLogOut = ({ toggle }: { toggle?: () => void } = {}) => {
  const { t } = useTranslation();
  const { actions } = useUserContext();
  const { actions: modalActions } = useModalContext();
  const navigate = useNavigate();

  const [getUserProfile] = useUserProfileLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleLogout = async () => {
    const savedAccounts = getAccounts() ?? [];
    const sortedAccounts = savedAccounts?.sort(
      (a, b) => Number(b.isLoggedIn) - Number(a.isLoggedIn)
    );

    if (sortedAccounts.length <= 1) {
      await client.clearStore();
      Storage.clear();
      Storage.clear(window.sessionStorage);
      actions.logout();
    } else {
      toggle();
      Storage.removeItem(
        StorageKeys.currentCalendarDate,
        window.sessionStorage
      );
      await removeAccount(sortedAccounts[0].id);
      await handleSwitchAccount(sortedAccounts[1].sessionId);
    }
  };

  const handleSwitchAccount = async (sessionId: string) => {
    await client.clearStore();
    setClientParam("sessionId", null);
    Storage.removeItem(StorageKeys.currentCalendarDate, window.sessionStorage);

    try {
      if (sessionId) {
        const timezone = dayjs.tz.guess();
        const newUserProfile = await getUserProfile({
          variables: {
            sessionId,
            timezone,
            language: "en",
            accessLevelsForDisplayingInGlobalAccountSelector: true,
          },
        });
        if (newUserProfile) {
          removeUnrememberedAccount();
          await setNewUserProfileAfterSwitch(newUserProfile.data, sessionId);
        }
      } else {
        return t("invalidCredentials");
      }
    } catch {
      return t("unknownLoginError");
    }
  };

  const setNewUserProfileAfterSwitch = useCallback(
    async (userProfileData, sessionId) => {
      if (userProfileData?.userProfile && sessionId) {
        const defaultUser = userProfileData?.userAccessLevels?.[0]?.user ?? {};

        await actions.setUser({
          sessionId,
          ...userProfileData.userProfile,
          loading: false,
          defaultUser,
        });

        await logoutFromAccounts(userProfileData?.userProfile.id);

        Storage.setItem(StorageKeys.sessionId, {
          sessionId,
        });
        Storage.removeItem(StorageKeys.sessionId, window.sessionStorage);

        const homeRoute = isAcesNationEnv()
          ? ROUTING_CONFIG.calendar
          : ROUTING_CONFIG.myXPS;

        modalActions.closeModal();
        navigate(`/${homeRoute}`);
        actions.setUser({ userWasSwitched: true });
      }
    },
    [actions, modalActions, navigate]
  );

  return { handleLogout, handleSwitchAccount };
};
