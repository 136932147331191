import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { Section } from "../Section";
import { SectionTitle } from "../SectionTitle";
import { StyledSwitch } from "../StyledSwitch";

import { WidgetType } from "./models";

const SwitchesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 24px 0;
`;

export const SwitchWrapper = styled.div<{ gridViewWidth: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  width: ${({ gridViewWidth }) => (gridViewWidth ? "33%" : "100%")};
`;

interface AppearanceSectionProps {
  widgetType: WidgetType;
  alternatingValues?: boolean;
  columnDividers?: boolean;
  rowDividers?: boolean;
  alternatingColumns?: boolean;
  widgetBorders: boolean;
  outsideBorderDividers?: boolean;
  showValuesAsText?: boolean;
  setFieldValue(name: string, value: boolean): void;
}

export const AppearanceSection = ({
  widgetType,
  alternatingValues,
  alternatingColumns,
  widgetBorders,
  outsideBorderDividers,
  columnDividers,
  rowDividers,
  setFieldValue,
  showValuesAsText,
}: PropsWithChildren<AppearanceSectionProps>) => {
  const { t } = useTranslation();

  const isTable = widgetType === WidgetType.TABLE;
  const isKeyValues = widgetType === WidgetType.KEY_VALUES;

  return (
    <Section>
      <SectionTitle title={t("appearance")} />
      <SwitchesWrapper>
        <SwitchWrapper gridViewWidth={isTable}>
          <StyledSwitch
            value={widgetBorders}
            onValueChange={(value) => setFieldValue("widgetBorders", value)}
            label={t("widgetBorders")}
          />
        </SwitchWrapper>
        {isTable && (
          <>
            <SwitchWrapper gridViewWidth={isTable}>
              <StyledSwitch
                value={columnDividers}
                onValueChange={(value) =>
                  setFieldValue("columnDividers", value)
                }
                label={t("columnDividers")}
              />
            </SwitchWrapper>
            <SwitchWrapper gridViewWidth={isTable}>
              <StyledSwitch
                value={rowDividers}
                onValueChange={(value) => setFieldValue("rowDividers", value)}
                label={t("rowDividers")}
              />
            </SwitchWrapper>
            <SwitchWrapper gridViewWidth={isTable}>
              <StyledSwitch
                value={alternatingValues}
                onValueChange={(value) =>
                  setFieldValue("alternatingValues", value)
                }
                label={t("alternatingRows")}
              />
            </SwitchWrapper>
            <SwitchWrapper gridViewWidth={isTable}>
              <StyledSwitch
                value={alternatingColumns}
                onValueChange={(value) =>
                  setFieldValue("alternatingColumns", value)
                }
                label={t("alternatingColumns")}
              />
            </SwitchWrapper>
            <SwitchWrapper gridViewWidth={isTable}>
              <StyledSwitch
                value={outsideBorderDividers}
                onValueChange={(value) =>
                  setFieldValue("outsideBorderDividers", value)
                }
                label={t("outsideBorderDividers")}
              />
            </SwitchWrapper>
          </>
        )}
        {(isTable || isKeyValues) && (
          <SwitchWrapper gridViewWidth={false}>
            <StyledSwitch
              value={showValuesAsText}
              onValueChange={(value) =>
                setFieldValue("showValuesAsText", value)
              }
              label={t("showValuesAsText")}
            />
          </SwitchWrapper>
        )}
      </SwitchesWrapper>
    </Section>
  );
};
