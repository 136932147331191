import React, { memo } from "react";

export const TeamAndGroupsIcon = memo(() => (
  <svg
    width="54"
    height="42"
    viewBox="0 0 54 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 10C39.5523 10 40 9.5523 40 9V1C40 0.4477 39.5523 0 39 0C38.4477 0 38 0.4477 38 1V9C38 9.5523 38.4477 10 39 10ZM27 10C27.5523 10 28 9.5523 28 9V1C28 0.4477 27.5523 0 27 0C26.4477 0 26 0.4477 26 1V9C26 9.5523 26.4477 10 27 10ZM46 4H41V9C41 10.1045 40.1045 11 39 11C37.8954 11 37 10.1045 37 9V4H29V9C29 10.1045 28.1046 11 27 11C25.8954 11 25 10.1045 25 9V4H17V9C17 10.1045 16.1045 11 15 11C13.8954 11 13 10.1045 13 9V4H8C5.7908 4 4 5.7909 4 8V36C4 38.2092 5.7908 40 8 40H46C48.2091 40 50 38.2092 50 36V8C50 5.7909 48.2091 4 46 4ZM36.0245 20.8358L26.7108 30.1495C26.3202 30.54 25.6871 30.54 25.2966 30.1495L20.2255 25.0785C19.8349 24.688 19.8349 24.0549 20.2255 23.6643C20.616 23.2738 21.2492 23.2738 21.6397 23.6643L26.0037 28.0283L34.6103 19.4217C35.0008 19.0311 35.634 19.0311 36.0245 19.4217C36.4151 19.8121 36.4151 20.4453 36.0245 20.8358ZM15 10C15.5522 10 16 9.5523 16 9V1C16 0.4477 15.5522 0 15 0C14.4477 0 14 0.4477 14 1V9C14 9.5523 14.4477 10 15 10Z"
      fill="#A0BEEC"
    />
  </svg>
));
