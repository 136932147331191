let countOfClick = 0;

export const handleSingleDoubleClick = (
  singleClickHandler,
  doubleClickHandler
) => {
  countOfClick += 1;
  setTimeout(() => {
    if (countOfClick === 2) {
      doubleClickHandler();
    } else if (countOfClick === 1) {
      singleClickHandler();
    }
    countOfClick = 0;
  }, 200);
};
