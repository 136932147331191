import { ROUTING_CONFIG } from "../../router/RoutingConfig";

const generateEditUrlOfEditor = (url) => `${url}/:widgetId`;

export const ROUTES_WITH_COMPACT_NAVSIDE = [
  ROUTING_CONFIG.Report,
  ROUTING_CONFIG.AthleteProfile,
  ROUTING_CONFIG.AthleteTableEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AthleteTableEditor),
  ROUTING_CONFIG.GroupTableEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.GroupTableEditor),
  ROUTING_CONFIG.ChartEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.ChartEditor),
  ROUTING_CONFIG.CombinedTestEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.CombinedTestEditor),
  ROUTING_CONFIG.AttendanceLogEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AttendanceLogEditor),
  ROUTING_CONFIG.AcuteChronicEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AcuteChronicEditor),
  ROUTING_CONFIG.AthleteTablePeriodizationEditorScreen,
  generateEditUrlOfEditor(ROUTING_CONFIG.AthleteTablePeriodizationEditorScreen),
  ROUTING_CONFIG.GroupTablePeriodizationEditorScreen,
  generateEditUrlOfEditor(ROUTING_CONFIG.GroupTablePeriodizationEditorScreen),
  ROUTING_CONFIG.ActivityLogEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.ActivityLogEditor),
  ROUTING_CONFIG.KeyValuesEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.KeyValuesEditor),
  ROUTING_CONFIG.InformationLogEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.InformationLogEditor),
  ROUTING_CONFIG.BodyChartEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.BodyChartEditor),
  ROUTING_CONFIG.ChartWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.ChartWidgetEditor),
  ROUTING_CONFIG.GroupTableWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.GroupTableWidgetEditor),
  ROUTING_CONFIG.AthleteTableWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AthleteTableWidgetEditor),
  ROUTING_CONFIG.CombinedTestWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.CombinedTestWidgetEditor),
  ROUTING_CONFIG.AttendanceLogWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AttendanceLogWidgetEditor),
  ROUTING_CONFIG.ActivityLogWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.ActivityLogWidgetEditor),
  ROUTING_CONFIG.KeyValuesWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.KeyValuesWidgetEditor),
  ROUTING_CONFIG.InformationLogWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.InformationLogWidgetEditor),
  ROUTING_CONFIG.AcuteChronicWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AcuteChronicWidgetEditor),
  ROUTING_CONFIG.AthleteTablePeriodizationWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.AthleteTablePeriodizationWidgetEditor),
  ROUTING_CONFIG.GroupTablePeriodizationWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.GroupTablePeriodizationWidgetEditor),
  ROUTING_CONFIG.ChartWidgetEditor,
  generateEditUrlOfEditor(ROUTING_CONFIG.BodyChartWidgetEditor),
];
