import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { createGroupUpdateCb } from "../components/Modals/ChatModals/misc";
import { useUserContext } from "../contexts/User";
import {
  CreateGroupChatMutationVariables,
  useCreateGroupChatMutation,
  useMessageOverviewPaginatedLazyQuery,
} from "../graphql";
import { ROUTING_CONFIG } from "../router/RoutingConfig";
import { getGroupName } from "../utils/getGroupName";

export const useCreateGroupChat = () => {
  const { t } = useTranslation();

  const [createGroup] = useCreateGroupChatMutation();
  const { sessionId, language } = useUserContext();

  const navigate = useNavigate();

  const [fetchMessageOverview] = useMessageOverviewPaginatedLazyQuery({
    variables: {
      sessionId,
      language,
      showAll: true,
      includeHidden: true,
      ignoreContactsWithoutChats: true,
    },
  });

  const createGroupChatForSession = useCallback(
    async (session) => {
      const name = getGroupName({ recipients: "everyone", session, t });

      return await createGroup({
        variables: {
          sessionId,
          name,
          autoAddTrainers: true,
          autoAddAthletes: true,
          autoAddFamily: true,
          onlyTrainersCanPost: true,
          linkedSession: session ? session.id : null,
        },
        update: createGroupUpdateCb(
          sessionId,
          language,
          name,
          async (newContact) => {
            navigate({
              pathname: `/${ROUTING_CONFIG.messages}`,
              search: `?id=${newContact?.id}`,
            });
          },
          async () => {
            await fetchMessageOverview();
          }
        ),
      });
    },
    [createGroup, fetchMessageOverview, language, navigate, sessionId, t]
  );

  const createGroupChatForTeam = useCallback(
    async (variables: CreateGroupChatMutationVariables) => {
      return await createGroup({
        variables,
        update: createGroupUpdateCb(
          sessionId,
          language,
          variables.name,
          async (newContact) => {
            navigate({
              pathname: `/${ROUTING_CONFIG.messages}`,
              search: `?id=${newContact?.id}`,
            });
          },
          async () => {
            await fetchMessageOverview();
          }
        ),
      });
    },
    [createGroup, fetchMessageOverview, language, navigate, sessionId]
  );

  return { createGroupChatForSession, createGroupChatForTeam };
};
