import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";

import { COLOR_TEXT_DARK } from "../../colors";
import { ModalType, useModalContext } from "../../contexts/UI/Modal";
import { Attendance, Avatar as AvatarModel } from "../../graphql";
import { AttendedIcon, NotAttendedIcon } from "../Icons";
import { AttendanceStatus } from "../Modals/Attendance";

const Container = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 5px;
  margin-left: 15px;
`;

const ItemLabel = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14.5px;
  color: ${COLOR_TEXT_DARK};
  margin-left: 10px;
`;

interface AttendanceSectionProps {
  sessionGuid: string;
  attendance?: Attendance;
  avatarsOfChildren: AvatarModel[];
  showAthleteName?: boolean;
  refetch: () => Promise<any>;
  isExpired?: boolean;
  editable?: boolean;
}

function SessionAttendanceButtons({
  sessionGuid,
  attendance,
  avatarsOfChildren,
  refetch,
  isExpired,
  editable,
}: AttendanceSectionProps) {
  const { athleteGuid } = attendance || {};

  const { userID, name: childrenName } = avatarsOfChildren?.[0] || {};

  const modal = useModalContext();
  const { t } = useTranslation();

  const attendanceParams = attendance || {
    athleteGuid: userID,
    athleteName: childrenName,
    attendance,
  };

  const onSelectPerformersClick = (event) => {
    // do not open session detail on attendance button click
    event.stopPropagation();
    event.preventDefault();

    modal.actions.openModal({
      modal: ModalType.ATTENDANCE,
      style: { minWidth: 375 },
      title: t("attendance"),
      params: { sessionGuid, refetch, ...attendanceParams },
    });
  };

  if (
    !athleteGuid ||
    (isExpired && attendance.attendance === AttendanceStatus.UNKNOWN)
  ) {
    return null;
  }

  return (
    <Container
      onClick={editable && onSelectPerformersClick}
      disabled={!editable}
    >
      <ItemContainer>
        <IconContainer>
          <AttendedIcon
            isActive={attendance.attendance === AttendanceStatus.ATTENDED}
            height={35}
            width={35}
          />
        </IconContainer>
        <ItemLabel>{t("attending")}</ItemLabel>
      </ItemContainer>
      <ItemContainer>
        <IconContainer>
          <NotAttendedIcon
            isActive={
              attendance.attendance !== AttendanceStatus.ATTENDED &&
              attendance.attendance !== AttendanceStatus.UNKNOWN
            }
            height={35}
            width={35}
          />
        </IconContainer>
        <ItemLabel>{t("away")}</ItemLabel>
      </ItemContainer>
    </Container>
  );
}

export default SessionAttendanceButtons;
