import ReactGridLayout from "react-grid-layout";

import { useUserContext } from "../../contexts/User";
import {
  useReportQuery,
  useSaveReportTemplateMutation,
  WidgetTemplatesDocument,
} from "../../graphql";
import { parsePreferences } from "../../utils/preferences";
import { useGetSelectedAccountIdInMonitoring } from "../useGetSelectedAccountIdInMonitoring";
import { usePrepareLayoutForSaving } from "../usePrepareLayoutForSaving";

export const useAddWidgetToReport = (
  reportId: string,
  parentReportId: string,
  layoutIndex: number | string
) => {
  const { sessionId, language } = useUserContext();

  const [saveReport] = useSaveReportTemplateMutation({
    refetchQueries(result) {
      return [WidgetTemplatesDocument];
    },
  });
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();

  const { normalizeLayout } = usePrepareLayoutForSaving();

  const { data: reportData } = useReportQuery({
    variables: {
      sessionId,
      reportId,
      language,
    },
    fetchPolicy: "cache-first",
  });

  const addWidgetToReport = async (widgetId: string) => {
    const preferences = parsePreferences<any>(
      reportData?.reportTemplate?.preferences,
      {}
    );

    const layout = normalizeLayout(
      preferences?.layout as ReactGridLayout.Layout[],
      layoutIndex,
      widgetId
    );

    await saveReport({
      variables: {
        sessionId,
        accountId: selectedAccountId,
        input: {
          preferences: JSON.stringify({ ...preferences, layout }),
          id: reportId || parentReportId,
        },
      },
    });
  };

  return { addWidgetToReport };
};
