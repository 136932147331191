import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

import { MobileModal } from "../../components/Modals/MobileModal";
import { AthletePickerModal } from "../../components/Modals/MobileModals/AthletePicker/AthletePickerModal";
import { DateSelectorModal } from "../../components/Modals/MobileModals/DateSelectorModal";
import { EditTabsModal } from "../../components/Modals/MobileModals/EditTabsModal";
import { GroupPickerModal } from "../../components/Modals/MobileModals/GroupPicker/GroupPickerModal";

export enum MobileModalTypes {
  ATHLETE_PICKER = "athletePicker",
  GROUP_PICKER = "groupPicker",
  DATE_PICKER = "datePicker",
  TAB_PICKER = "tabPicker",
}

type MobileModalContextType = {
  visible: boolean;
  hideModal: () => void;
  showModal: (type: MobileModalTypes, params?: Record<string, any>) => void;
};

export const MobileModalContext = createContext<MobileModalContextType>({
  visible: false,
  hideModal: () => {},
  showModal: (_) => {},
});

interface MobileModalProviderProps {
  children: ReactNode;
}

export const MobileModalProvider = (props: MobileModalProviderProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [modalParams, setModalParams] = useState<Record<string, any>>({});
  const [modalType, setModalType] = useState<MobileModalTypes | null>(null);

  const getModal = useCallback(
    (modalType: MobileModalTypes) => {
      switch (modalType) {
        case MobileModalTypes.ATHLETE_PICKER:
          return <AthletePickerModal />;
        case MobileModalTypes.GROUP_PICKER:
          return <GroupPickerModal />;
        case MobileModalTypes.DATE_PICKER:
          return <DateSelectorModal {...modalParams} />;
        case MobileModalTypes.TAB_PICKER:
          return <EditTabsModal />;
        default:
          return null;
      }
    },
    [modalParams]
  );

  return (
    <MobileModalContext.Provider
      value={{
        visible,
        hideModal: () => {
          setModalType(null);
          setVisible(false);
          setModalParams({});
        },
        showModal: (type: MobileModalTypes, params = {}) => {
          setModalType(type);
          setVisible(true);
          setModalParams(params);
        },
      }}
    >
      <MobileModal>{getModal(modalType)}</MobileModal>
      {props.children}
    </MobileModalContext.Provider>
  );
};

export const useMobileModalContext = () => {
  return useContext(MobileModalContext);
};
