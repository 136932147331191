export enum TopModalActionType {
  OPEN = "TOP_MODAL/OPEN",
  CLOSE = "TOP_MODAL/CLOSE",
}

export enum TopModalType {
  CONFIRM = "Confirm",
  ADD_RESOURCE = "AddResource",
  SELECT_ATHLETES_FROM_GROUP = "SelectAthletesFromGroup",
  CREATE_CHAT_GROUP = "CreateChatGroup",
  CHANGE_PASSWORD = "ChangePassword",
  DOCUMENT = "Document",
  PROGRAM_ITEM_WORKOUT = "ProgramItemWorkout",
  COLLECTION_QUESTIONNAIRE = "CollectionQuestionnaire",
  SESSION_DETAIL = "SessionDetail",
  QUESTIONNAIRE = "Questionnaire",
  CONFIRM_ACCOUNT_SWITCH = "ConfirmAccountSwitch",
}
