import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useNotificationsContext } from "../../../contexts/notifications";
import { useUserContext } from "../../../contexts/User";
import { WidgetTemplatesDocument } from "../../../graphql";
import { useGetSelectedAccountIdInMonitoring } from "../../../hooks/useGetSelectedAccountIdInMonitoring";
import { WidgetMode } from "../WidgetWithData";

import { useSaveWidgetToServer } from "./useSaveWidgetToServer";

export const useSaveAsWidget = (widget, mode: WidgetMode) => {
  const { sessionId, firstName, lastName } = useUserContext();
  const { t } = useTranslation();
  const selectedAccountId = useGetSelectedAccountIdInMonitoring();
  const { showSuccessNotification, showErrorNotification } =
    useNotificationsContext();

  const { saveWidgetToServer } = useSaveWidgetToServer(mode);

  const saveWidget = useCallback(
    async (
      templateName: string,
      parentReportId: string = "",
      isEditMode: boolean = false,
      showToastMessage: boolean = true
    ) => {
      const parsedPreferences = {
        ...JSON.parse(widget.preferences),
        createdBy: `${firstName} ${lastName}`,
        updatedAt: new Date(),
      };

      const sharedVariables = {
        sessionId,
        accountId: selectedAccountId,
        input: {
          ...(isEditMode ? { id: widget.id } : { copyOf: widget.id }),
          name: templateName,
          reportTemplateId: null,
        },
      };

      const refetchConfig = {
        refetchQueries: [
          {
            query: WidgetTemplatesDocument,
            variables: {
              sessionId,
              accountId: selectedAccountId,
              ...(isEditMode ? {} : { parentReport: parentReportId }),
            },
          },
        ],
      };

      try {
        await saveWidgetToServer(
          widget,
          sharedVariables,
          parsedPreferences,
          refetchConfig
        );
        showToastMessage &&
          showSuccessNotification(t("saveAsTemplateCompleted"));
      } catch (error) {
        console.error(error);
        showToastMessage && showErrorNotification(t("saveAsTemplateError"));
      }
    },
    [
      firstName,
      lastName,
      saveWidgetToServer,
      selectedAccountId,
      sessionId,
      showErrorNotification,
      showSuccessNotification,
      widget,
      t,
    ]
  );

  return { saveWidget };
};
