import React from "react";

import styled from "styled-components";

import {
  COLOR_GREY_ACTIVE,
  COLOR_TEXT_DARK,
  COLOR_WHITE,
} from "../../../colors";
import { CalendarIcon } from "../../Icons";

const HeaderWrapper = styled.div`
  padding: 20px 30px;
  border-bottom: 1px solid ${COLOR_GREY_ACTIVE};
  background-color: ${COLOR_WHITE};
`;

const Title = styled.div<{ withBottomPadding: boolean }>`
  font-weight: 700;
  font-size: 30px;
  color: ${COLOR_TEXT_DARK};
  margin-bottom: ${({ withBottomPadding }) => (withBottomPadding ? 30 : 0)}px;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Period = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${COLOR_TEXT_DARK};
  margin-left: 10px;
`;

export const Header = ({ title, period }) => {
  return (
    <HeaderWrapper>
      <Title withBottomPadding={!!period}>{title}</Title>
      {period ? (
        <PeriodWrapper>
          <CalendarIcon />
          <Period>{period}</Period>
        </PeriodWrapper>
      ) : null}
    </HeaderWrapper>
  );
};
