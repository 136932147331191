import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { usePrintAthleteReportContext } from "../../contexts/Print/PrintAthleteReportProvider";
import { useReportContext } from "../../contexts/report";

import { PrintSelector } from "./PrintSelector";

export const TabsDropdown = () => {
  const { t } = useTranslation();
  const { tabIDs, setTabIDs } = usePrintAthleteReportContext();
  const { report } = useReportContext();

  const [params] = useSearchParams();
  const activeTabId = params.get("activeTabId") ?? null;

  const tabs = useMemo(
    () =>
      (report?.tabs ?? []).map((childReport) => ({
        value: childReport.id,
        label: childReport.name.toLocaleUpperCase(),
      })),
    [report]
  );

  useEffect(() => {
    if (activeTabId) {
      setTabIDs([activeTabId]);
    }
  }, [setTabIDs, activeTabId, tabs]);

  return (
    <PrintSelector
      options={tabs}
      placeholder={t("chooseTabsPlaceholder")}
      selectedIds={tabIDs}
      setSectionIDs={setTabIDs}
    />
  );
};
