import { useMemo } from "react";

import { Dayjs } from "dayjs";
import _groupBy from "lodash/groupBy";

import { useReportContext } from "../../contexts/report";
import { useUserContext } from "../../contexts/User";
import { AggregateFunction, XpsUser } from "../../graphql";
import {
  AthleteSoccerStatisticsResponse,
  getAggregationValueFromCooking,
  getCookingValue,
  InformationCooking,
  JsonAttendanceStatisticsQueryResponse,
} from "../../services";
import {
  useAthleteSoccerStatisticsQuery,
  useAthleteStatisticsQuery,
  useAttendanceStatisticsQuery,
  useInformations2022Query,
  useXpsMagicInfoStatisticsQuery,
} from "../../services/statistics/hooks";
import { JsonXpsMagicInfoStatisticsQueryResponse } from "../../services/statistics/xpsMagicInfo";
import { roundToTwo } from "../../utils/number";
import {
  getAttendanceStatisticsDataKey,
  isAttendanceCollection,
  isCustomTextSoccerAttribute,
  isInformationCollection,
  isSoccerStatsCollection,
  isXpsMagicCollection,
} from "../../utils/reports";
import { getAllAthleteStats, getDataKey } from "../../utils/statistics";
import { PerformerSelectionMode } from "../Report/PerformerSection";
import { isHighPrecisionNumberInCanada } from "../Report/utils/isHighPrecisionNumberInCanada";
import { getAthleteSoccerStatsWithCustomCols } from "../Report/utils/soccerStatsWithCustomCols";
import { ITableRowModel } from "../ReportTableWidget/AthleteTable/model";

import { AthleteTableWidgetRow } from "./models";

type UseAthleteStatsDataProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
  focusedAthlete: XpsUser;
  aggregation: AggregateFunction[];
  rows: AthleteTableWidgetRow[];
  performerSelectionMode: PerformerSelectionMode;
  athlete?: string;
};

export function useAthleteStatsData({
  fromDate,
  toDate,
  focusedAthlete,
  aggregation,
  rows,
  performerSelectionMode,
  athlete,
}: UseAthleteStatsDataProps) {
  const { sessionId } = useUserContext();
  const { report } = useReportContext();

  const athleteGuid =
    performerSelectionMode === PerformerSelectionMode.ATHLETE_FIXED
      ? athlete
      : focusedAthlete?.id;

  const {
    attendanceRows = [],
    informationRows = [],
    soccerStatsRows = [],
    xpsMagicStatsRows = [],
    statisticsRows = [],
  } = _groupBy(rows, (row) => {
    if (isAttendanceCollection(row.collectionId)) {
      return "attendanceRows";
    }
    if (isInformationCollection(row.collectionId)) {
      return "informationRows";
    }
    if (isSoccerStatsCollection(row.collectionId)) {
      return "soccerStatsRows";
    }
    if (isXpsMagicCollection(row.collectionId)) {
      return "xpsMagicStatsRows";
    }

    return "statisticsRows";
  });

  const {
    data: informationsResponseData = null,
    isLoading: informationsResponseIsLoading,
    fetchStatus: informationsResponseFetchStatus,
  } = useInformations2022Query(
    {
      athleteGuid,
      templates: aggregation
        .filter(
          (agg) =>
            agg === AggregateFunction.Latest ||
            agg === AggregateFunction.Each ||
            agg === AggregateFunction.LatestForever
        )
        .flatMap((agg) =>
          informationRows.map((info) => ({
            guid: info.measurementTemplateId,
            cooking: getCookingValue(
              agg as
                | AggregateFunction.Each
                | AggregateFunction.Latest
                | AggregateFunction.LatestForever
            ),
          }))
        ),
      range: {
        from: fromDate,
        to: toDate,
      },
      session: { sessionId },
      reportTemplateId: report?.id,
    },
    { enabled: sessionId && athleteGuid && informationRows.length > 0 }
  );

  const {
    data: athleteStatisticsData = null,
    isLoading: athleteStatisticsIsLoading,
    fetchStatus: athleteStatisticsFetchStatus,
  } = useAthleteStatisticsQuery(
    {
      fromDate,
      toDate,
      period: "continuous",
      session: { sessionId },
      items: statisticsRows.map(
        ({ collectionId, attributeTemplateId, measurementTemplateId }) => ({
          dataType: collectionId,
          attribute: attributeTemplateId,
          templateGuid: measurementTemplateId,
          aggregation,
        })
      ),
      focusedAthlete: focusedAthlete?.id,
      participants: [{ id: athleteGuid }],
      reportTemplateId: report?.id,
    },
    { enabled: sessionId && athleteGuid && statisticsRows.length > 0 }
  );

  const {
    data: soccerStatsResponseData = null,
    isLoading: soccerStatsIsLoading,
    fetchStatus: soccerStatsFetchStatus,
  } = useAthleteSoccerStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: focusedAthlete?.id,
      },
      allGroups: false,
      from: fromDate,
      to: toDate,
      withAllTimeResults: aggregation.some((aggregate) =>
        aggregate?.includes("Forever")
      ),
    },
    { enabled: sessionId && soccerStatsRows?.length > 0 }
  );

  const {
    data: athletesAttendanceStatisticsResponse,
    isLoading: athletesAttendanceStatisticsIsLoading,
    fetchStatus: athletesAttendanceStatisticsFetchStatus,
  } = useAttendanceStatisticsQuery(
    {
      session: {
        sessionId,
        focusedAthlete: focusedAthlete?.id,
      },
      fromDate,
      toDate,
      participants: [{ id: athleteGuid }],
      reportTemplateId: report?.id,
    },
    { enabled: sessionId && attendanceRows?.length > 0 }
  );

  const {
    data: athletesXpsMagicInfoStatisticsResponse,
    isLoading: athletesXpsMagicInfoStatisticsIsLoading,
    fetchStatus: athletesXpsMagicInfoStatisticsFetchStatus,
  } = useXpsMagicInfoStatisticsQuery(
    {
      reportTemplateId: report?.id,
      templates: xpsMagicStatsRows.map((row) => ({
        id: row.measurementTemplateId?.slice(
          row.measurementTemplateId.indexOf(".") + 1
        ),
      })),
      athleteIds: [focusedAthlete.id],
    },
    { enabled: sessionId && xpsMagicStatsRows?.length > 0 }
  );

  const rowsData = useMemo(() => {
    const athleteStats = athleteStatisticsData?._athletes?.find(
      (athleteStats) => athleteStats._guid === athleteGuid
    );

    // parse stats
    const data = getAllAthleteStats(athleteStats).flatMap((measurement) =>
      (measurement._attributes ?? []).map((attribute) => {
        const id = getDataKey(
          measurement._guid,
          attribute._guid,
          measurement._agg
        );
        const value = attribute._values[0]?._val ?? NaN;
        const finalValue = isHighPrecisionNumberInCanada(value)
          ? value
          : roundToTwo(value);

        return [
          id,
          {
            txt: attribute._values[0]?._txt,
            value: finalValue,
            rgb: attribute._values[0]?._rgb,
            tifn: attribute._values[0]?.tifn,
          },
        ] as const;
      })
    );

    const infoData =
      informationsResponseData?.data.templates.flatMap((info) =>
        info.items.map((item) => {
          const id = getDataKey(
            info.templateGuid,
            "TEXT",
            getAggregationValueFromCooking(info.cooking as InformationCooking)
          );
          const value = item?._value;

          return [id, value] as const;
        })
      ) || [];

    const soccerStatsData = getSoccerStatsDataItems(
      soccerStatsRows,
      soccerStatsResponseData?.data,
      aggregation
    );
    const attendanceData = getAttendanceStatsDataItems(
      attendanceRows,
      athletesAttendanceStatisticsResponse?.data
    );
    const xpsMagicInfoData = getXpsMagicInfoStatsDataItems(
      xpsMagicStatsRows,
      athletesXpsMagicInfoStatisticsResponse?.data
    );

    const tableData = new Map<
      string,
      | string
      | number
      | { txt: string; value: number; rgb: string; tifn: boolean }
    >([
      ...data,
      ...infoData,
      ...soccerStatsData,
      ...attendanceData,
      ...xpsMagicInfoData,
    ]);

    return rows.map<ITableRowModel>((row, index) => ({
      measurementTemplateId: row.measurementTemplateId,
      attributeTemplateId: row.attributeTemplateId,
      attributeTemplateName: row.attributeTemplateName,
      attributeTemplateUnitName: row.attributeTemplateUnitName || "",
      label: row.measurementTemplateName,
      cells: aggregation.map((aggr) => {
        const value = tableData?.get(
          getDataKey(
            row.measurementTemplateId,
            row.attributeTemplateId
              ? row.attributeTemplateId
              : isCustomTextSoccerAttribute(row.measurementTemplateId)
                ? ""
                : "TEXT",
            aggr
          )
        );

        if (
          !value ||
          (typeof value === "object" &&
            !value.txt &&
            value.value !== 0 &&
            !value.value) ||
          value === "NaN" ||
          (typeof value === "number" && isNaN(value))
        ) {
          return { txt: "-" };
        }

        return {
          value:
            typeof value === "object"
              ? value.value
              : typeof value === "number" ||
                  (typeof value === "string" && !isNaN(parseFloat(value)))
                ? value
                : undefined,
          txt:
            typeof value === "object"
              ? value.txt
              : typeof value === "string"
                ? value
                : undefined,
          rgb:
            typeof value === "object"
              ? value.rgb
              : typeof value === "string"
                ? value
                : undefined,
          tifn: typeof value === "object" ? value.tifn : false,
          isInfo: !row.attributeTemplateId,
        };
      }),
      key: `${row.measurementTemplateId}:${row.measurementTemplateName}-${index}`,
    }));
  }, [
    athleteStatisticsData,
    attendanceRows,
    soccerStatsRows,
    xpsMagicStatsRows,
    aggregation,
    athleteGuid,
    informationsResponseData,
    soccerStatsResponseData,
    athletesAttendanceStatisticsResponse,
    athletesXpsMagicInfoStatisticsResponse,
    rows,
  ]);

  const isLoading =
    (athleteStatisticsFetchStatus !== "idle" && athleteStatisticsIsLoading) ||
    (informationsResponseFetchStatus !== "idle" &&
      informationsResponseIsLoading) ||
    (soccerStatsFetchStatus !== "idle" && soccerStatsIsLoading) ||
    (athletesAttendanceStatisticsFetchStatus !== "idle" &&
      athletesAttendanceStatisticsIsLoading) ||
    (athletesXpsMagicInfoStatisticsFetchStatus !== "idle" &&
      athletesXpsMagicInfoStatisticsIsLoading);

  return {
    isLoading,
    rows: rowsData,
  };
}

function getSoccerStatsDataItems(
  rows: AthleteTableWidgetRow[] = [],
  soccerStatsResponse?: AthleteSoccerStatisticsResponse,
  aggregation: AggregateFunction[] = [AggregateFunction.Latest]
  // soccerStatsForAllGroupsResponse?: any
): [string, string][] {
  return (
    aggregation.flatMap((aggr) =>
      rows?.map<[string, string]>((row) => {
        // const forAllGroup = cell.settings?.forAllGroups ?? false;

        const value = getAthleteSoccerStatsWithCustomCols(
          soccerStatsResponse,
          row.measurementTemplateId,
          aggr
        );
        /* forAllGroup ? soccerStatsForAllGroupsResponse : */

        return [
          getDataKey(row.measurementTemplateId, row.attributeTemplateId, aggr),
          value !== undefined ? String(value) : undefined,
        ];
      })
    ) || []
  );
}

function getAttendanceStatsDataItems(
  rows: AthleteTableWidgetRow[] = [],
  attendanceResponse?: JsonAttendanceStatisticsQueryResponse
): [string, string][] {
  return (
    rows?.map((row) => {
      const athleteAttendanceStats =
        attendanceResponse?.perAthletes?.[0]?.attendanceInPeriodOfTime?.[0];
      const currentTemplateId = getAttendanceStatisticsDataKey(
        row.measurementTemplateId
      );
      const isPercentageValue = currentTemplateId?.includes("Perc");
      const value = athleteAttendanceStats?.[currentTemplateId];
      const valueToDisplay = !isNaN(Number(value))
        ? roundToTwo(isPercentageValue ? value * 100 : value)
        : "-";

      return [
        getDataKey(
          row.measurementTemplateId,
          row.attributeTemplateId,
          AggregateFunction.Latest
        ),
        valueToDisplay.toString(),
      ];
    }) || []
  );
}

function getXpsMagicInfoStatsDataItems(
  rows: AthleteTableWidgetRow[] = [],
  xpsMagicInfoResponse: JsonXpsMagicInfoStatisticsQueryResponse
): [string, string][] {
  const xpsMagicInfoTemplates =
    xpsMagicInfoResponse?.byAthletes?.[0]?.templates ?? [];

  return rows?.map((row) => {
    const value =
      xpsMagicInfoTemplates.find(
        (template) =>
          template.id ===
          row.measurementTemplateId?.slice(
            row.measurementTemplateId.indexOf(".") + 1
          )
      )?.value ?? "-";

    return [
      getDataKey(row.measurementTemplateId, "TEXT", AggregateFunction.Latest),
      value,
    ];
  });
}
