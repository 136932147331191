import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const PrintIcon = memo(
  ({ width = 21, height = 21, color = "#0073D9", ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      color={color}
      {...props}
    >
      <path
        d="M5.5 4C5.5 3.17157 6.17157 2.5 7 2.5H14C14.8284 2.5 15.5 3.17157 15.5 4V10.5H5.5V4Z"
        stroke={color}
      />
      <path
        d="M5.5 15V16.5H4C3.17157 16.5 2.5 15.8284 2.5 15V10C2.5 9.17157 3.17157 8.5 4 8.5H5.5V10V10.5H6H15H15.5V10V8.5H17C17.8284 8.5 18.5 9.17157 18.5 10V15C18.5 15.8284 17.8284 16.5 17 16.5H15.5V15V14.5H15H6H5.5V15Z"
        stroke={color}
      />
      <path
        d="M5.5 14.5H15.5V17C15.5 17.8284 14.8284 18.5 14 18.5H7C6.17157 18.5 5.5 17.8284 5.5 17V14.5Z"
        stroke={color}
      />
      <path d="M15 12.5H17" stroke={color} />
      <path d="M7 5.5H14" stroke={color} />
      <path d="M7 7.5H14" stroke={color} />
    </svg>
  )
);
