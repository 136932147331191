import { useEffect, useState } from "react";

export function useGoogleLocation(locationId: string) {
  const [selectedLocationUrl, setSelectedLocationUrl] = useState("");

  useEffect(() => {
    if (locationId) {
      // @ts-ignore
      if (!window.google) {
        // Google Maps API not loaded yet
        return;
      }

      const service =
        // @ts-ignore
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
      const request = {
        placeId: locationId,
        fields: ["url"],
      };

      service.getDetails(request, (place, status) => {
        // @ts-ignore
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setSelectedLocationUrl(place?.url);
        } else {
          console.error("Error fetching place details:", status);
        }
      });
    } else {
      setSelectedLocationUrl("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  return selectedLocationUrl;
}
