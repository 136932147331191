import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const LogoXpsIcon = memo(
  ({ width = 64, height = 17, isActive = false, ...props }: SvgIconProps) => (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.8371 6.81126H50.3268C49.3623 6.81126 48.6247 6.02318 48.6247 5.12252C48.6247 4.16556 49.419 3.43377 50.3268 3.43377H61.9008C62.071 3.43377 62.2412 3.2649 62.2412 3.09603V0.337748C62.2412 0.168874 62.071 0 61.9008 0H50.3268C47.49 0 45.1638 2.30795 45.1638 5.12252C45.1638 7.93709 47.49 10.245 50.3268 10.245H58.8371C59.8016 10.245 60.5392 11.0331 60.5392 11.9338C60.5392 12.8907 59.7449 13.6225 58.8371 13.6225H45.561C45.3908 13.6225 45.2206 13.7914 45.2206 13.9603V16.6623C45.2206 16.8311 45.3908 17 45.561 17H58.8371C61.6739 17 64 14.6921 64 11.8775C64 9.11921 61.6739 6.81126 58.8371 6.81126ZM18.1577 0C17.9875 0 17.7038 0.112583 17.5904 0.225166L12.6544 5.12252C12.5976 5.17881 12.5976 5.29139 12.6544 5.34768L14.8103 7.48675C14.8671 7.54305 14.9805 7.54305 15.0373 7.48675L22.4129 0.168874C22.5831 0.112583 22.5264 0 22.3561 0H18.1577ZM15.094 9.45695C15.0373 9.40066 14.9238 9.40066 14.8671 9.45695L12.7111 11.596C12.6544 11.6523 12.6544 11.7649 12.7111 11.8212L17.6471 16.7185C17.7606 16.8311 18.0442 16.9437 18.2145 16.9437H22.3561C22.5264 16.9437 22.5831 16.8311 22.4696 16.7185L15.094 9.45695ZM38.5258 0H25.2497C25.0795 0 24.9092 0.168874 24.9092 0.337748V16.6623C24.9092 16.8311 25.0795 17 25.2497 17H27.973C28.1432 17 28.3134 16.8311 28.3134 16.6623V10.1887H38.5258C41.3626 10.1887 43.6887 7.88079 43.6887 5.06623C43.6887 2.30795 41.3626 0 38.5258 0ZM38.5258 6.81126H28.3134V3.37748H38.5258C39.4903 3.37748 40.2278 4.16556 40.2278 5.06623C40.2278 6.02318 39.4903 6.81126 38.5258 6.81126Z"
        fill="white"
      />
      <path
        d="M5.33544 0H0.172505C0.00229883 0 -0.0544367 0.112583 0.0590344 0.225166L8.39916 8.5L0.0590344 16.7748C-0.0544367 16.8874 0.00229883 17 0.172505 17H5.33544C5.50565 17 5.78933 16.8874 5.9028 16.7748C7.20771 15.4801 13.1082 9.62583 13.5621 9.1755C14.0727 8.66887 14.0727 8.33113 13.5621 7.8245C13.1082 7.37417 7.20771 1.51987 5.9028 0.225166C5.78933 0.112583 5.50565 0 5.33544 0Z"
        fill="#95B33C"
      />
      <path
        d="M10.6686 4.95361C11.1792 5.46024 11.1792 5.79798 10.6686 6.30461C10.4984 6.47348 9.30693 7.6556 7.94528 9.00659L0.0590344 16.7748C-0.0544367 16.8874 0.00229883 17 0.172505 17H5.33544C5.50565 17 5.78933 16.8874 5.9028 16.7748C7.20771 15.4801 13.1082 9.6258 13.5621 9.17547C14.0727 8.66884 14.0727 8.3311 13.5621 7.82447C13.3919 7.59931 12.1437 6.3609 10.6686 4.95361Z"
        fill="#D6DE23"
      />
    </svg>
  )
);
