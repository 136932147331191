import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Field } from "formik";
import _uniqBy from "lodash/uniqBy";
import styled from "styled-components";

import { COLOR_DANGER_BUTTON_RED } from "../../colors";
import {
  AggregateFunction,
  MeasurementTemplateForReports,
  ReportItemType,
} from "../../graphql";
import {
  useReportBuildingAttributes,
  useReportBuildingCollections,
  useReportBuildingMeasurements,
} from "../../hooks";
import { requiredValidation } from "../../utils/form";
import {
  isAttendanceCollection,
  isCatapultCollection,
  isFirstbeatCollection,
  isGarminCollection,
  isInformationCollection,
  isKinexonCollection,
  isPlayertecCataplutCollection,
  isPolarCollection,
  isPubgCollection,
  isSoccerStatsCollection,
  isTestCollection,
  isXpsMagicCollection,
} from "../../utils/reports";
import { getValidUnitName } from "../../utils/statistics";
import Loading from "../Loading";
import { SelectField, SelectOption } from "../SelectField";
import { FieldWrapper } from "../styled";
import StyledText from "../StyledText";

import { MenuListWithFolderStructure } from "./FolderStructure/MenuListWithFolderStructure";
import { MenuWithFolderStructure } from "./FolderStructure/MenuWithFolderStructure";

export type DataSource = {
  collectionId: string;
  measurementTemplateId: string;
  measurementTemplateName: string;
  attributeTemplateId: string;
  attributeTemplateName: string;
  attributeTemplateUnitName?: string;
  itemId?: string;
  textOptions?: string[];
  aggregateFunction?: AggregateFunction;
};

interface DataSourceSelectorRowProps {
  customCollectionChange?: (id: string) => void;
  customMeasurementChange?: (
    id: string,
    name: string,
    isTestWithColorRanges?: boolean
  ) => void;
  customAttributeChange?: (id: string, name: string, unitName: string) => void;
  customVisibleMeasurementsFilter?: (
    item: MeasurementTemplateForReports
  ) => boolean;
  customMeasurementsMapper?: (
    items: MeasurementTemplateForReports[]
  ) => MeasurementTemplateForReports[];
  onChangeDataSourceValues: (newValues: Partial<DataSource>) => void;
  allowedReportItemTypes?: ReportItemType[];
  dataSourceArrayItemAccessor: string;
  currentDataSource: (DataSource & Record<string, any>) | any;
  customCollectionIdAccessor?: string;
  customMeasurementTemplateIdAccessor?: string;
  customMeasurementTemplateNameAccessor?: string;
  customMeasurementTemplateIsTestWithColorRangesAccessor?: string;
  customAttributeTemplateIdAccessor?: string;
  customAttributeTemplateNameAccessor?: string;
  customAttributeTemplateUnitNameAccessor?: string;
  customMeasurementTextOptionsAccessor?: string;
  shouldHideAttributeSelector?: boolean;
}

export const DEFAULT_ALLOWED_REPORT_ITEM_TYPES = [
  ReportItemType.Test,
  ReportItemType.Exercise,
  ReportItemType.Drill,
  ReportItemType.TrainingLoad,
  ReportItemType.Information,
  ReportItemType.Polar,
  ReportItemType.Garmin,
  ReportItemType.Kinexon,
  ReportItemType.Firstbeat,
  ReportItemType.Statsports,
  ReportItemType.PlayertekCatapult,
  ReportItemType.Catapult,
  ReportItemType.Pubg,
];

const DRILL_COLLECTION_ID_PART = "DRILL";
const EXERCISE_SET_COLLECTION_ID_PART = "EXERCISE_SET";
const TEST_COLLECTION_ID_PART = "TEST";

export const COLLECTIONS_WITH_FOLDER_STRUCTURE = [
  DRILL_COLLECTION_ID_PART,
  EXERCISE_SET_COLLECTION_ID_PART,
  TEST_COLLECTION_ID_PART,
];

const DataSourceSelectorRowGrowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DataSourceSelectorRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export function DataSourceSelectorRow({
  customCollectionChange,
  customMeasurementChange,
  customAttributeChange,
  customVisibleMeasurementsFilter,
  customMeasurementsMapper,
  onChangeDataSourceValues,
  dataSourceArrayItemAccessor,
  currentDataSource,
  allowedReportItemTypes = DEFAULT_ALLOWED_REPORT_ITEM_TYPES,
  customCollectionIdAccessor = "collectionId",
  customMeasurementTemplateIdAccessor = "measurementTemplateId",
  customMeasurementTemplateNameAccessor = "measurementTemplateName",
  customAttributeTemplateIdAccessor = "attributeTemplateId",
  customAttributeTemplateNameAccessor = "attributeTemplateName",
  customAttributeTemplateUnitNameAccessor = "attributeTemplateUnitName",
  customMeasurementTemplateIsTestWithColorRangesAccessor = "isTestWithColorRanges",
  customMeasurementTextOptionsAccessor = "textOptions",
  shouldHideAttributeSelector = false,
}: DataSourceSelectorRowProps) {
  const { t } = useTranslation();
  const [
    isMeasurementMissingInApiRequest,
    setIsMeasurementMissingInApiRequest,
  ] = useState(false);
  const [menuHidden, setMenuHidden] = useState(false);

  const collectionId = currentDataSource[customCollectionIdAccessor];
  const measurementTemplateId =
    currentDataSource[customMeasurementTemplateIdAccessor];
  const measurementTemplateName =
    currentDataSource[customMeasurementTemplateNameAccessor];
  const attributeTemplateId =
    currentDataSource[customAttributeTemplateIdAccessor];
  const attributeTemplateName =
    currentDataSource[customAttributeTemplateNameAccessor];
  const attributeTemplateUnitName =
    currentDataSource[customAttributeTemplateUnitNameAccessor];

  const {
    data: reportBuildingCollectionsResponse,
    loading: loadingCollections,
  } = useReportBuildingCollections();

  const collectionOptions = useMemo(
    () =>
      reportBuildingCollectionsResponse?.reportBuildingCollections
        .filter(({ reportItemType }) =>
          allowedReportItemTypes.includes(reportItemType)
        )
        .map(({ name, id }) => ({
          label: name,
          value: id,
        })) ?? [],
    [
      reportBuildingCollectionsResponse?.reportBuildingCollections,
      allowedReportItemTypes,
    ]
  );

  const {
    data: reportBuildingMeasurementTemplatesResponse,
    loading: isLoadingMeasurements,
  } = useReportBuildingMeasurements({ collectionId });

  const {
    data: reportBuildingAttributeTemplatesResponse,
    loading: isLoadingAttributes,
  } = useReportBuildingAttributes({
    measurementTemplateId,
    collectionId,
    shouldSkip:
      !reportBuildingMeasurementTemplatesResponse ||
      (!measurementTemplateId &&
        reportBuildingMeasurementTemplatesResponse
          .reportBuildingMeasurementTemplates.length !== 0),
  });

  const onAttributeChange = useCallback(
    (
      attributeTemplateId: string,
      attributeTemplateName: string,
      attributeTemplateUnitName: string
    ) => {
      if (typeof customAttributeChange === "function") {
        customAttributeChange(
          attributeTemplateId,
          attributeTemplateName,
          attributeTemplateUnitName
        );
      }

      onChangeDataSourceValues({
        [customAttributeTemplateIdAccessor]: attributeTemplateId,
        [customAttributeTemplateNameAccessor]: attributeTemplateName,
        [customAttributeTemplateUnitNameAccessor]: attributeTemplateUnitName,
      });
    },
    [
      onChangeDataSourceValues,
      customAttributeChange,
      customAttributeTemplateIdAccessor,
      customAttributeTemplateNameAccessor,
      customAttributeTemplateUnitNameAccessor,
    ]
  );

  useEffect(
    () => {
      if (
        reportBuildingMeasurementTemplatesResponse
          ?.reportBuildingMeasurementTemplates?.length === 1
      ) {
        const measurementTemplate =
          reportBuildingMeasurementTemplatesResponse
            .reportBuildingMeasurementTemplates[0];

        onChangeDataSourceValues({
          [customMeasurementTemplateIdAccessor]: measurementTemplate.id,
          [customMeasurementTemplateNameAccessor]: measurementTemplate.name,
          [customMeasurementTemplateIsTestWithColorRangesAccessor]:
            measurementTemplate.isTestWithColorRanges,
          [customMeasurementTextOptionsAccessor]:
            measurementTemplate.textOptions,
        });
      }

      if (
        reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates &&
        measurementTemplateId &&
        !reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates.find(
          (measurement) => measurement.id === measurementTemplateId
        )
      ) {
        setIsMeasurementMissingInApiRequest(true);
        return;
      }

      if (
        reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates &&
        measurementTemplateId
      ) {
        const foundMeasurementTemplate =
          reportBuildingMeasurementTemplatesResponse.reportBuildingMeasurementTemplates.find(
            (template) => template.id === measurementTemplateId
          );
        const newMeasurementTemplateName = foundMeasurementTemplate?.name;
        const isTestWithColorIndicator =
          foundMeasurementTemplate?.isTestWithColorRanges;

        onChangeDataSourceValues({
          [customMeasurementTemplateIdAccessor]: measurementTemplateId,
          [customMeasurementTemplateNameAccessor]: newMeasurementTemplateName,
          [customMeasurementTemplateIsTestWithColorRangesAccessor]:
            isTestWithColorIndicator,
          [customMeasurementTextOptionsAccessor]:
            foundMeasurementTemplate.textOptions,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      measurementTemplateId,
      measurementTemplateName,
      reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates,
      customMeasurementTemplateIdAccessor,
      customMeasurementTemplateNameAccessor,
      customMeasurementTemplateIsTestWithColorRangesAccessor,
      customMeasurementTextOptionsAccessor,
      // onChangeDataSourceValues, <- otherwise infinite loop
    ]
  );

  useEffect(() => {
    if (
      attributeTemplateId &&
      attributeTemplateName &&
      attributeTemplateUnitName
    ) {
      return;
    }

    if (
      reportBuildingAttributeTemplatesResponse?.reportBuildingAttributeTemplates
        ?.length === 1 &&
      attributeTemplateId !==
        reportBuildingAttributeTemplatesResponse
          .reportBuildingAttributeTemplates[0].id
    ) {
      const { id, name, unitName } =
        reportBuildingAttributeTemplatesResponse
          .reportBuildingAttributeTemplates[0];

      onAttributeChange(
        id,
        name.toLowerCase() === "result"
          ? unitName
          : `${name} ${unitName ? `(${unitName})` : ""}`,
        unitName
      );

      return;
    }

    if (
      reportBuildingAttributeTemplatesResponse?.reportBuildingAttributeTemplates &&
      attributeTemplateId &&
      !attributeTemplateUnitName
    ) {
      const attributeTemplate =
        reportBuildingAttributeTemplatesResponse.reportBuildingAttributeTemplates.find(
          (attribute) => attribute.id === attributeTemplateId
        );

      if (
        attributeTemplate?.unitName &&
        getValidUnitName(attributeTemplate.unitName)
      ) {
        onAttributeChange(
          attributeTemplate.id,
          attributeTemplate.name,
          getValidUnitName(attributeTemplate.unitName)
        );
      }
    }
  }, [
    reportBuildingAttributeTemplatesResponse?.reportBuildingAttributeTemplates,
    attributeTemplateId,
    attributeTemplateName,
    attributeTemplateUnitName,
    onAttributeChange,
  ]);

  useEffect(() => {
    if (collectionOptions.length === 1 && !collectionId) {
      onCollectionChange(collectionOptions[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionOptions?.length, collectionId]);

  const onCollectionChange = (collectionId: string) => {
    if (typeof customCollectionChange === "function") {
      customCollectionChange(collectionId);
    }

    onChangeDataSourceValues({
      [customCollectionIdAccessor]: collectionId,
      [customMeasurementTemplateIdAccessor]: "",
      [customMeasurementTemplateNameAccessor]: "",
      [customMeasurementTemplateIsTestWithColorRangesAccessor]: false,
      [customAttributeTemplateIdAccessor]: "",
      [customAttributeTemplateNameAccessor]: "",
      [customAttributeTemplateUnitNameAccessor]: "",
    });
  };

  const onMeasurementChange = useCallback(
    (
      measurementTemplateId: string,
      measurementTemplateName: string,
      isTestWithColorRanges: boolean,
      textOptions = null
    ) => {
      if (typeof customMeasurementChange === "function") {
        customMeasurementChange(
          measurementTemplateId,
          measurementTemplateName,
          isTestWithColorRanges
        );
      }

      onChangeDataSourceValues({
        [customMeasurementTemplateIdAccessor]: measurementTemplateId,
        [customMeasurementTemplateNameAccessor]: measurementTemplateName,
        [customMeasurementTemplateIsTestWithColorRangesAccessor]:
          isTestWithColorRanges,
        [customMeasurementTextOptionsAccessor]: textOptions,
        [customAttributeTemplateIdAccessor]: "",
        [customAttributeTemplateNameAccessor]: "",
        [customAttributeTemplateUnitNameAccessor]: "",
      });
    },
    [
      onChangeDataSourceValues,
      customMeasurementChange,
      customMeasurementTemplateIdAccessor,
      customMeasurementTemplateNameAccessor,
      customMeasurementTemplateIsTestWithColorRangesAccessor,
      customMeasurementTextOptionsAccessor,
      customAttributeTemplateIdAccessor,
      customAttributeTemplateNameAccessor,
      customAttributeTemplateUnitNameAccessor,
    ]
  );
  if (loadingCollections) {
    return <Loading />;
  }

  const hideAttributeSelectField =
    !collectionId ||
    [
      isTestCollection,
      isInformationCollection,
      isSoccerStatsCollection,
      isAttendanceCollection,
      isPolarCollection,
      isKinexonCollection,
      isFirstbeatCollection,
      isGarminCollection,
      isSoccerStatsCollection,
      isPlayertecCataplutCollection,
      isCatapultCollection,
      isPubgCollection,
      isXpsMagicCollection,
    ].some((isCollection) => isCollection(collectionId)) ||
    shouldHideAttributeSelector;

  const measurementsItems =
    reportBuildingMeasurementTemplatesResponse?.reportBuildingMeasurementTemplates ??
    [];

  return (
    <DataSourceSelectorRowGrowWrapper>
      <DataSourceSelectorRowWrapper>
        <FieldWrapper>
          <Field
            name={`${
              dataSourceArrayItemAccessor
                ? `${dataSourceArrayItemAccessor}.`
                : ""
            }${customCollectionIdAccessor}`}
            validate={requiredValidation}
          >
            {({ field, form, meta }) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={collectionOptions}
                onChange={(option: SelectOption) =>
                  onCollectionChange(option.value)
                }
              />
            )}
          </Field>
        </FieldWrapper>
        <FieldWrapper noMargin={hideAttributeSelectField}>
          <Field
            name={`${
              dataSourceArrayItemAccessor
                ? `${dataSourceArrayItemAccessor}.`
                : ""
            }${customMeasurementTemplateIdAccessor}`}
            validate={requiredValidation}
          >
            {({ field, form, meta }) => (
              <SelectField
                field={field}
                form={form}
                meta={meta}
                options={(customMeasurementsMapper
                  ? customMeasurementsMapper(measurementsItems)
                  : measurementsItems
                )
                  .filter((item) =>
                    customVisibleMeasurementsFilter
                      ? customVisibleMeasurementsFilter(item)
                      : true
                  )
                  .map(
                    ({
                      id,
                      name,
                      isTestWithColorRanges,
                      textOptions,
                    }: MeasurementTemplateForReports) => ({
                      label: name,
                      value: id,
                      optionalFlag: isTestWithColorRanges,
                      textOptions,
                    })
                  )}
                onChange={(
                  option: SelectOption<string, { textOptions: string[] }>
                ) =>
                  onMeasurementChange(
                    option.value,
                    option.label,
                    option.optionalFlag,
                    option.textOptions
                  )
                }
                isLoading={isLoadingMeasurements}
                folderStructureProps={{
                  collectionId,
                  menuHidden,
                  setMenuHidden,
                }}
                components={
                  COLLECTIONS_WITH_FOLDER_STRUCTURE.some(
                    (treeStructureCollectionId) =>
                      collectionId?.includes(treeStructureCollectionId)
                  )
                    ? {
                        MenuList: MenuListWithFolderStructure,
                        Menu: MenuWithFolderStructure,
                      }
                    : {}
                }
              />
            )}
          </Field>
        </FieldWrapper>
        {!hideAttributeSelectField && (
          <FieldWrapper noMargin>
            <Field
              name={`${
                dataSourceArrayItemAccessor
                  ? `${dataSourceArrayItemAccessor}.`
                  : ""
              }${customAttributeTemplateIdAccessor}`}
              validate={requiredValidation}
            >
              {({ field, form, meta }) => (
                <SelectField
                  field={field}
                  form={form}
                  meta={meta}
                  options={
                    reportBuildingAttributeTemplatesResponse?.reportBuildingAttributeTemplates &&
                    _uniqBy(
                      reportBuildingAttributeTemplatesResponse.reportBuildingAttributeTemplates,
                      "id"
                    ).map(({ id, name, unitName }) => ({
                      label:
                        name.toLowerCase() === "result"
                          ? unitName
                          : `${name} ${unitName ? `(${unitName})` : ""}`,
                      value: id,
                      unitName,
                    }))
                  }
                  onChange={(option: SelectOption & { unitName: string }) =>
                    onAttributeChange(
                      option.value,
                      option.label,
                      option.unitName
                    )
                  }
                  isLoading={isLoadingAttributes}
                />
              )}
            </Field>
          </FieldWrapper>
        )}
      </DataSourceSelectorRowWrapper>
      {isMeasurementMissingInApiRequest &&
        measurementTemplateId !== "SoccerStat.MinutesInPosition" && (
          <StyledText
            fontWeight="bold"
            fontSize={12}
            color={COLOR_DANGER_BUTTON_RED}
            style={{ marginTop: 6 }}
          >
            {t("missingOrDeletedMeasurement")}
          </StyledText>
        )}
    </DataSourceSelectorRowGrowWrapper>
  );
}
