import React from "react";

import useResizeObserver from "use-resize-observer";

import { IWidgetResizeProps, useBottomWidgetResize } from "../../../hooks";
import { useAthleteDataForPeriodizationTable } from "../../../hooks/data/periodizationTable/useAthleteDataForPeriodizationTable";
import { Loader } from "../../Loader";
import { TimeLine, TimeLinePeriod } from "../../Report/ChartWIdget/model";
import { useReportPeriod } from "../../Report/hooks/useReportPeriod";
import {
  AthleteTablePeriodizationWidget,
  getGrouppedColumnHeader,
} from "../../TablePeriodization/Widget";

import { AthleteTablePeriodizationWidgetTable } from "./Table";

interface AthleteTablePeriodizationWidgetTableWithStatsDataProps
  extends IWidgetResizeProps {
  widget: AthleteTablePeriodizationWidget;
  readOnly?: boolean;
}

export const AthleteTablePeriodizationWidgetTableWithStatsData = ({
  widget,
  readOnly = true,
  ...resizeProps
}: AthleteTablePeriodizationWidgetTableWithStatsDataProps) => {
  const { fromDate, toDate } = useReportPeriod(widget.period);
  const { ref: tableRef, height: widgetHeight } =
    useResizeObserver<HTMLDivElement>();

  useBottomWidgetResize({
    id: widget.id,
    widgetHeight,
    alwaysResize: true,
    shouldResize: readOnly,
    ...resizeProps,
  });

  const period =
    widget.timeLine === TimeLine.CONTINUOUS
      ? widget.timeLine
      : widget.timeLinePeriod === TimeLinePeriod.NONE
        ? TimeLinePeriod.DAYS
        : widget.timeLinePeriod;

  const { loading, data, statsData } = useAthleteDataForPeriodizationTable({
    widgetId: widget.id,
    grouppedColumnHeaders: getGrouppedColumnHeader(widget.columnHeaders),
    sortOrder: widget.sortOrder,
    fromDate,
    toDate,
    period,
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <AthleteTablePeriodizationWidgetTable
      title={widget.name}
      columnHeaders={widget.columnHeaders}
      coloringConfig={widget.coloringConfig}
      timeLine={widget.timeLine}
      showSummaryStats={widget.showSummaryStats}
      summaryStatsAlignment={widget.summaryStatsAlignment}
      verticalHeader={widget.verticalHeader}
      showValuesAsText={widget.showValuesAsText}
      data={data}
      statsData={statsData}
      tableRef={tableRef}
      ignoreColumnsWithNoData={widget.ignoreColumnsWithNoData}
    />
  );
};
