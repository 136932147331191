import React, { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import styled, { css } from "styled-components";

import { CHAT_LIST_PAGE_SIZE } from "../../constants/Messages";
import { Chatter, MessageOverviewPaginatedQueryResult } from "../../graphql";
import { AllMessageOverviewData } from "../../screens/MessagesScreen";
import { SearchIcon } from "../Icons";
import { Loader } from "../Loader";
import StyledText from "../StyledText";

import ChatterItem from "./ChatterItem";

interface Props {
  data: AllMessageOverviewData[] | null;
  loading: boolean;
  onRowPress: (contact: AllMessageOverviewData) => void;
  activateNextContact: (contact: AllMessageOverviewData) => void;
  activeContact: MessageOverviewPaginatedQueryResult["data"]["messageOverview"]["contactsPaginated"]["edges"][0]["node"];
  search: string;
  nextPageHandler: (allNextMessages?: boolean) => void;
}

const LoaderWrapper = styled.div`
  flex: 1;
  margin-top: 40px;
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const ChattersWrapper = styled.div`
  flex: 1;
  position: relative;
`;

const NoResultsView = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  text-align: center;
`;

const Container = styled.div<{ isEmpty: boolean }>`
  ${({ isEmpty }) =>
    isEmpty
      ? css`
          flex: 1;
          display: flex;
          flex-direction: column;
        `
      : css`
          overflow-y: auto;
        `}
`;

const BottomSection = styled.div<{ higher: boolean }>`
  height: ${({ higher }) => (higher ? 400 : 200)}px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
`;

const ChatList = ({
  data,
  loading,
  onRowPress,
  activeContact,
  search,
  nextPageHandler,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const bottomSectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && containerRef.current) {
      const activeContactIndex = data.findIndex(
        (contact) => contact?.id === activeContact?.id
      );
      if (activeContactIndex > -1) {
        const el = document.querySelector(
          `div[id="chatListItem${activeContact.id}"]`
        );

        if (el) {
          const observer = new window.IntersectionObserver(
            ([entry]) => {
              if (!entry.isIntersecting) {
                containerRef?.current.scrollTo({
                  top: activeContactIndex * 85,
                });
              }
            },
            {
              root: null,
              threshold: 0.1,
            }
          );

          observer.observe(el);

          const observerTimeout = setTimeout(() => {
            observer.unobserve(el);
            return () => clearTimeout(observerTimeout);
          }, 100);

          return () => {
            if (el) {
              observer.unobserve(el);
            }
          };
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContact?.id]);

  const getSearchWords = (text: string) => text.trim().split(/\s+/);

  const threads = useMemo(() => {
    if (data) {
      const words = getSearchWords(search.toLowerCase());

      return [...data]
        .sort((c1, c2) =>
          c1 && c2 && c1.lastChatTime > c2.lastChatTime ? -1 : 1
        )
        .filter((contact) => !(!search && contact.hidden))
        .filter((contact) =>
          search
            ? words.every((word) => contact.name.toLowerCase().includes(word))
            : true
        );
    }
    return [];
  }, [data, search]);

  useEffect(() => {
    if (search) {
      nextPageHandler(true);
    }
  }, [nextPageHandler, search]);

  useEffect(() => {
    if (threads.length > 0 && !search) {
      const currentRef = bottomSectionRef.current;

      const observer = new IntersectionObserver((entries) => {
        const target = entries[0];
        if (target.isIntersecting) {
          nextPageHandler();
        }
      });

      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threads, search]);

  if (!data && loading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Container ref={containerRef} isEmpty={threads.length === 0}>
      <ChattersWrapper>
        {threads.length ? (
          threads.map(
            (contact) =>
              contact && (
                <ChatterItem
                  key={contact.id as string}
                  isActive={activeContact && activeContact.id === contact.id}
                  item={contact as Chatter}
                  onRowPress={onRowPress}
                />
              )
          )
        ) : (
          <NoResultsView>
            <SearchIcon width={42} height={42} style={{ marginBottom: 20 }} />
            <StyledText fontSize={16}>{t("noResultsEmpty")}</StyledText>
          </NoResultsView>
        )}
        {loading ? (
          <Loader style={{ marginTop: 20, marginBottom: 20 }} />
        ) : null}
        {threads.length > 0 ? (
          <BottomSection
            ref={bottomSectionRef}
            higher={threads.length > CHAT_LIST_PAGE_SIZE}
          />
        ) : null}
      </ChattersWrapper>
    </Container>
  );
};

export default ChatList;
