import { useCallback, useMemo, useRef } from "react";
import ReactGridLayout from "react-grid-layout";

import { v4 as uuidv4 } from "uuid";

import { WidgetType } from "../components/ReportCreateTable/models";
import {
  MIN_ROW_COUNT,
  MAX_COLUMN_COUNT,
  LAYOUT_SIZE,
} from "../constants/ReportGrid";

const DEFAULT_WIDGET_WIDTH = 4;
const DEFAULT_WIDGET_HEIGHT = 3;
const TOP_BOUNDARY_FOR_COUNT_OF_LAYOUT_ITEMS = 80;

export const usePrepareLayoutForSaving = () => {
  const alreadyOccupied = useRef<{ row: number; column: number }[]>([]);

  const getMaximalRowOfGrid = (layout: ReactGridLayout.Layout[]) => {
    let maxRow = MIN_ROW_COUNT;

    layout.forEach((item) => {
      const currentItemEndRow = item.h - 1 + item.y;

      if (currentItemEndRow > maxRow) maxRow = currentItemEndRow;
    });

    return maxRow;
  };

  const getIsSpaceFull = useCallback(
    (
      layout: ReactGridLayout.Layout[],
      row: number,
      column: number,
      checkIfPlaceholder = false
    ) => {
      return Boolean(
        layout.find(
          (item) =>
            item.y <= row &&
            item.y + (item.h - 1) >= row &&
            item.x <= column &&
            item.x + (item.w - 1) >= column &&
            (checkIfPlaceholder || !item.i.includes("placeholder"))
        )
      );
    },
    []
  );

  const calculateEmptySpaces = useCallback(
    (layout: ReactGridLayout.Layout[]) => {
      const maximalRow = getMaximalRowOfGrid(layout);
      alreadyOccupied.current = [];

      for (let row = 0; row < maximalRow + DEFAULT_WIDGET_HEIGHT; row++) {
        for (let column = 0; column < MAX_COLUMN_COUNT; column++) {
          const isSpaceFull = getIsSpaceFull(layout, row, column);

          if (
            !isSpaceFull &&
            !alreadyOccupied.current.find(
              (item) => item.row === row && item.column === column
            )
          ) {
            alreadyOccupied.current.push({ row, column });
          }
        }
      }

      return alreadyOccupied.current;
    },
    [getIsSpaceFull]
  );

  const generateOrRemoveLastRow = useCallback(
    (transformedLayout: ReactGridLayout.Layout[]) => {
      const lastRow = getMaximalRowOfGrid(transformedLayout);

      for (let row = 0; row <= lastRow; row++) {
        for (let column = 0; column < MAX_COLUMN_COUNT; column++) {
          const isSpaceFull = getIsSpaceFull(
            transformedLayout,
            row,
            column,
            true
          );

          if (!isSpaceFull) {
            transformedLayout.push({
              i: `placeholder-${uuidv4()}`,
              h: 1,
              w: 1,
              x: column,
              y: row,
            });
          }
        }
      }

      const lastRowItems = transformedLayout.filter(
        (item) => item.y === lastRow || item.y + item.h - 1 === lastRow
      );

      // Remove the last row if it is
      if (
        lastRowItems.every(
          (item) =>
            item.i.includes("placeholder") &&
            lastRow > MIN_ROW_COUNT &&
            transformedLayout.length > LAYOUT_SIZE
        )
      ) {
        transformedLayout = transformedLayout.filter(
          (item) => !lastRowItems.includes(item)
        );
      }

      return transformedLayout;
    },
    [getIsSpaceFull]
  );

  const fillEmptySpacesWithPlaceholders = useCallback(
    (transformedLayout: ReactGridLayout.Layout[]) => {
      const emptySpaces = calculateEmptySpaces(transformedLayout);
      let nextEmptySpaceIndex = 0;

      // Place placeholders to empty spaces from top of the report
      // Needed because of possible collision and not compact version of report
      return transformedLayout.map((item) => {
        if (
          item.i.includes("placeholder") &&
          nextEmptySpaceIndex < emptySpaces.length
        ) {
          const newItem = {
            ...item,
            h: 1,
            w: 1,
            x: emptySpaces[nextEmptySpaceIndex].column,
            y: emptySpaces[nextEmptySpaceIndex].row,
          };

          nextEmptySpaceIndex++;

          return newItem;
        }

        return item;
      });
    },
    [calculateEmptySpaces]
  );

  const enforceMaximalSizeOfGridLimitation = useCallback(
    (layout: ReactGridLayout.Layout[]) => {
      if (!layout || layout.length < TOP_BOUNDARY_FOR_COUNT_OF_LAYOUT_ITEMS) {
        return layout;
      }

      return layout.filter(
        (layoutItem, index) =>
          index < TOP_BOUNDARY_FOR_COUNT_OF_LAYOUT_ITEMS ||
          !layoutItem.i.toLowerCase().includes("placeholder")
      );
    },
    []
  );

  const normalizeLayout = useCallback(
    (
      layout: ReactGridLayout.Layout[],
      layoutIndex: string | number = undefined,
      widgetId: string = undefined
    ) => {
      let transformedLayout = layout.slice();

      if (layoutIndex !== undefined && widgetId !== undefined) {
        // Calculate correct size of widget when adding new one to prevent collisions
        transformedLayout = layout.map((l, i) => {
          if (
            layoutIndex.toString() === i.toString() &&
            l.i.includes("placeholder")
          ) {
            const widthRightBorder = l.x + DEFAULT_WIDGET_WIDTH - 1;
            const heightBottomBorder = l.y + DEFAULT_WIDGET_HEIGHT - 1;

            const itemsForWidget = layout
              .filter(
                (item) =>
                  l.x <= item.x &&
                  item.x <= widthRightBorder &&
                  l.y <= item.y &&
                  item.y <= heightBottomBorder
              )
              .filter((item) => item.i.toLowerCase().includes("placeholder"));

            return {
              ...l,
              i: widgetId,
              h:
                itemsForWidget.length ===
                DEFAULT_WIDGET_WIDTH * DEFAULT_WIDGET_HEIGHT
                  ? DEFAULT_WIDGET_HEIGHT
                  : 1,
              w:
                itemsForWidget.length ===
                DEFAULT_WIDGET_WIDTH * DEFAULT_WIDGET_HEIGHT
                  ? DEFAULT_WIDGET_WIDTH
                  : 1,
              isDraggable: !widgetId.includes(WidgetType.PLACEHOLDER),
              isResizable: !widgetId.includes(WidgetType.PLACEHOLDER),
            };
          }

          return l;
        });
      }

      transformedLayout = fillEmptySpacesWithPlaceholders(transformedLayout);
      transformedLayout = generateOrRemoveLastRow(transformedLayout);
      transformedLayout = enforceMaximalSizeOfGridLimitation(transformedLayout);

      return transformedLayout.sort((firstWidget, secondWidget) =>
        firstWidget.y === secondWidget.y
          ? firstWidget.x - secondWidget.x
          : firstWidget.y - secondWidget.y
      );
    },
    [
      fillEmptySpacesWithPlaceholders,
      enforceMaximalSizeOfGridLimitation,
      generateOrRemoveLastRow,
    ]
  );

  const publicActionsForLayoutManipulation = useMemo(
    () => ({ normalizeLayout }),
    [normalizeLayout]
  );

  return publicActionsForLayoutManipulation;
};
