import React, { memo } from "react";

import { SvgIconProps } from "./model";

export const EnvelopeRoundedIcon = memo(
  ({ width = 35, height = 35, isActive = false, ...props }: SvgIconProps) => (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...props}
    >
      <g clipPath="url(#clip0_4638_49847)">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#F5F6F8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 12H10C9.44772 12 9 12.4477 9 13V22C9 22.5523 9.44772 23 10 23H25C25.5523 23 26 22.5523 26 22V13C26 12.4477 25.5523 12 25 12ZM10 11C8.89543 11 8 11.8954 8 13V22C8 23.1046 8.89543 24 10 24H25C26.1046 24 27 23.1046 27 22V13C27 11.8954 26.1046 11 25 11H10Z"
          fill="#64707A"
        />
        <path
          d="M9 12L16.0858 19.0858C16.8668 19.8668 18.1332 19.8668 18.9142 19.0858L26 12"
          stroke="#64707A"
        />
      </g>
      <defs>
        <clipPath id="clip0_4638_49847">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
);
