import React, { Dispatch, SetStateAction } from "react";

import styled from "styled-components";

import { COLOR_GREY_ACTIVE, COLOR_TEXT_DARK, COLOR_WHITE } from "../../colors";
import Tabs, { Tab } from "../../components/Tabs";

type CollectionTabsProps = {
  tab: Tab;
  tabs: Tab[];
  setTab: Dispatch<SetStateAction<Tab>>;
  setFolder: Dispatch<SetStateAction<string>>;
};

const tabStyles = {
  justifyContent: "flex-start",
};

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  background-color: ${COLOR_WHITE};

  border-color: ${COLOR_GREY_ACTIVE};
  border-style: solid;
  border-width: 0 1px 1px 1px;
`;

const TabsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: ${COLOR_WHITE};
  border-bottom-width: 1px;
  border-bottom-color: ${COLOR_GREY_ACTIVE};
  border-bottom-style: solid;
  padding: 10px 30px 5px;
`;

const Label = styled.span`
  padding-left: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: ${COLOR_TEXT_DARK};
`;

export function CollectionTabs({
  tab,
  tabs,
  setTab,
  setFolder,
}: CollectionTabsProps) {
  const onSetTab = (tab: Tab) => {
    setTab(tab);
    setFolder(null);
  };

  if (!tabs?.length) {
    return null;
  }

  return (
    <TabContainer>
      <LabelContainer>
        <Label>{tab?.title.toLocaleLowerCase()}</Label>
      </LabelContainer>
      <TabsContainer>
        <Tabs tab={tab} tabs={tabs} setTab={onSetTab} style={tabStyles} />
      </TabsContainer>
    </TabContainer>
  );
}
